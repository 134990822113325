/* eslint-disable graphql/template-strings */
/* eslint-disable max-len */
export type Maybe<T> = T | null;

export type InputMaybe<T> = Maybe<T>;

export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };

export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };

export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };

export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigFloat: { input: string; output: string };
  BigInt: { input: string; output: string };
  Cursor: { input: string; output: string };
  Date: { input: string; output: string };
  Datetime: { input: string; output: string };
  InternetAddress: { input: string; output: string };
  JSON: { input: Record<string, any> | Array<Record<string, any>>; output: Record<string, any> | Array<Record<string, any>> };
  SqlIdentifier: { input: any; output: any };
  Time: { input: string; output: string };
};

export interface AllEmployeeWithYearQuarter {
  __typename?: 'AllEmployeeWithYearQuarter';
  employee: Employee;
  employeeId: Scalars['BigInt']['output'];
  employeeTpHistory: EmployeeTpHistory;
  employeeTpHistoryId: Scalars['BigInt']['output'];
  quarter: PerformanceReviewQuarter;
  rankLatest: Scalars['BigInt']['output'];
  year: Scalars['Int']['output'];
}

export interface AllEmployeeWithYearQuarterCondition {
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  rankLatest?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface AllEmployeeWithYearQuarterFilter {
  and?: InputMaybe<Array<AllEmployeeWithYearQuarterFilter>>;
  employee?: InputMaybe<EmployeeFilter>;
  employeeExists?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<BigIntFilter>;
  employeeTpHistory?: InputMaybe<EmployeeTpHistoryFilter>;
  employeeTpHistoryExists?: InputMaybe<Scalars['Boolean']['input']>;
  employeeTpHistoryId?: InputMaybe<BigIntFilter>;
  not?: InputMaybe<AllEmployeeWithYearQuarterFilter>;
  or?: InputMaybe<Array<AllEmployeeWithYearQuarterFilter>>;
  quarter?: InputMaybe<PerformanceReviewQuarterFilter>;
  rankLatest?: InputMaybe<BigIntFilter>;
  year?: InputMaybe<IntFilter>;
}

export interface AllEmployeeWithYearQuartersConnection {
  __typename?: 'AllEmployeeWithYearQuartersConnection';
  edges: Array<AllEmployeeWithYearQuartersEdge>;
  nodes: Array<AllEmployeeWithYearQuarter>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface AllEmployeeWithYearQuartersEdge {
  __typename?: 'AllEmployeeWithYearQuartersEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: AllEmployeeWithYearQuarter;
}

export enum AllEmployeeWithYearQuartersOrderBy {
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__COMPANY_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__COMPANY_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__COMPANY_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__COMPANY_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_CREATED_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_CREATED_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_CREATED_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_CREATED_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_UPDATED_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_UPDATED_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_UPDATED_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_UPDATED_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DEPARTMENT_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DEPARTMENT_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DEPARTMENT_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DEPARTMENT_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DIVISION_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DIVISION_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DIVISION_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DIVISION_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_CODE_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_CODE_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_CODE_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_CODE_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__JOB_TITLE_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__JOB_TITLE_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__JOB_TITLE_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__JOB_TITLE_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__OFFICE_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__OFFICE_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__OFFICE_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__OFFICE_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__REMARK_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__REMARK_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__REMARK_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__REMARK_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__TEAM_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__TEAM_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__TEAM_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__TEAM_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_CREATED_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_CREATED_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_CREATED_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_CREATED_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_UPDATED_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_UPDATED_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_UPDATED_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_UPDATED_DESC',
  EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORY_ID_DESC',
  NATURAL = 'NATURAL',
  QUARTER_ASC = 'QUARTER_ASC',
  QUARTER_DESC = 'QUARTER_DESC',
  RANK_LATEST_ASC = 'RANK_LATEST_ASC',
  RANK_LATEST_DESC = 'RANK_LATEST_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
}

export interface AllYearQuarter {
  __typename?: 'AllYearQuarter';
  quarter?: Maybe<PerformanceReviewQuarter>;
  year?: Maybe<Scalars['Int']['output']>;
  yearQuarterDateEnd?: Maybe<Scalars['Date']['output']>;
  yearQuarterDateStart?: Maybe<Scalars['Date']['output']>;
}

export interface AllYearQuarterCondition {
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  year?: InputMaybe<Scalars['Int']['input']>;
  yearQuarterDateEnd?: InputMaybe<Scalars['Date']['input']>;
  yearQuarterDateStart?: InputMaybe<Scalars['Date']['input']>;
}

export interface AllYearQuarterFilter {
  and?: InputMaybe<Array<AllYearQuarterFilter>>;
  not?: InputMaybe<AllYearQuarterFilter>;
  or?: InputMaybe<Array<AllYearQuarterFilter>>;
  quarter?: InputMaybe<PerformanceReviewQuarterFilter>;
  year?: InputMaybe<IntFilter>;
  yearQuarterDateEnd?: InputMaybe<DateFilter>;
  yearQuarterDateStart?: InputMaybe<DateFilter>;
}

export interface AllYearQuartersConnection {
  __typename?: 'AllYearQuartersConnection';
  edges: Array<AllYearQuartersEdge>;
  nodes: Array<AllYearQuarter>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface AllYearQuartersEdge {
  __typename?: 'AllYearQuartersEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: AllYearQuarter;
}

export enum AllYearQuartersOrderBy {
  NATURAL = 'NATURAL',
  QUARTER_ASC = 'QUARTER_ASC',
  QUARTER_DESC = 'QUARTER_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
  YEAR_QUARTER_DATE_END_ASC = 'YEAR_QUARTER_DATE_END_ASC',
  YEAR_QUARTER_DATE_END_DESC = 'YEAR_QUARTER_DATE_END_DESC',
  YEAR_QUARTER_DATE_START_ASC = 'YEAR_QUARTER_DATE_START_ASC',
  YEAR_QUARTER_DATE_START_DESC = 'YEAR_QUARTER_DATE_START_DESC',
}

export interface AppForceReload extends Node {
  __typename?: 'AppForceReload';
  appForceReloadId: Scalars['BigInt']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  newVersion: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
}

export interface AppForceReloadAppForceReloadPkeyConnect {
  appForceReloadId: Scalars['BigInt']['input'];
}

export interface AppForceReloadCondition {
  appForceReloadId?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  newVersion?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface AppForceReloadFilter {
  and?: InputMaybe<Array<AppForceReloadFilter>>;
  appForceReloadId?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  newVersion?: InputMaybe<StringFilter>;
  not?: InputMaybe<AppForceReloadFilter>;
  or?: InputMaybe<Array<AppForceReloadFilter>>;
  remark?: InputMaybe<StringFilter>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface AppForceReloadNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface AppForceReloadUserCreatedFkeyInverseInput {
  connectByAppForceReloadId?: InputMaybe<Array<AppForceReloadAppForceReloadPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<AppForceReloadNodeIdConnect>>;
}

export interface AppForceReloadUserUpdatedFkeyInverseInput {
  connectByAppForceReloadId?: InputMaybe<Array<AppForceReloadAppForceReloadPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<AppForceReloadNodeIdConnect>>;
}

export interface AppForceReloadsConnection {
  __typename?: 'AppForceReloadsConnection';
  edges: Array<AppForceReloadsEdge>;
  nodes: Array<AppForceReload>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface AppForceReloadsEdge {
  __typename?: 'AppForceReloadsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: AppForceReload;
}

export enum AppForceReloadsOrderBy {
  APP_FORCE_RELOAD_ID_ASC = 'APP_FORCE_RELOAD_ID_ASC',
  APP_FORCE_RELOAD_ID_DESC = 'APP_FORCE_RELOAD_ID_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  NATURAL = 'NATURAL',
  NEW_VERSION_ASC = 'NEW_VERSION_ASC',
  NEW_VERSION_DESC = 'NEW_VERSION_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface Bank extends Node {
  __typename?: 'Bank';
  bankCode?: Maybe<Scalars['String']['output']>;
  bankId: Scalars['BigInt']['output'];
  countryByCountryCodeAlpha3: Country;
  countryCodeAlpha3: Scalars['String']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  employees: EmployeesConnection;
  employeesByConsultingFeeBankId: EmployeesConnection;
  enabled: Scalars['Boolean']['output'];
  fullName: Scalars['String']['output'];
  fullNameInLocalLanguage?: Maybe<Scalars['String']['output']>;
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  swiftCode?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export type BankEmployeesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCondition>;
  filter?: InputMaybe<EmployeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeesOrderBy>>;
};

export type BankEmployeesByConsultingFeeBankIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCondition>;
  filter?: InputMaybe<EmployeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeesOrderBy>>;
};

export interface BankBankCountryCodeAlpha3FullNameKeyConnect {
  countryCodeAlpha3: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
}

export interface BankBankCountryCodeAlpha3FullNameKeyDelete {
  countryCodeAlpha3: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
}

export interface BankBankPkeyConnect {
  bankId: Scalars['BigInt']['input'];
}

export interface BankBankPkeyDelete {
  bankId: Scalars['BigInt']['input'];
}

export interface BankBankSwiftCodeKeyConnect {
  swiftCode: Scalars['String']['input'];
}

export interface BankBankSwiftCodeKeyDelete {
  swiftCode: Scalars['String']['input'];
}

export interface BankCondition {
  bankCode?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  countryCodeAlpha3?: InputMaybe<Scalars['String']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface BankCountryCodeAlpha3FkeyBankCreateInput {
  bankCode?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<BankCountryCodeAlpha3FkeyInput>;
  employeesToBankIdUsingBankId?: InputMaybe<EmployeeBankIdFkeyInverseInput>;
  employeesToConsultingFeeBankIdUsingBankId?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInverseInput>;
  enabled: Scalars['Boolean']['input'];
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BankUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface BankCountryCodeAlpha3FkeyCountryCreateInput {
  banks?: InputMaybe<BankCountryCodeAlpha3FkeyInverseInput>;
  cities?: InputMaybe<CityCountryCodeAlpha2FkeyInverseInput>;
  companies?: InputMaybe<CompanyCountryCodeAlpha2FkeyInverseInput>;
  countryCodeAlpha2: Scalars['String']['input'];
  countryCodeNumeric?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInverseInput>;
  employeeIdTypes?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInverseInput>;
  employeesToMobileNumberCountryIdUsingCountryCodeAlpha2?: InputMaybe<EmployeeMobileNumberCountryIdFkeyInverseInput>;
  employeesToNationalityUsingCountryCodeAlpha3?: InputMaybe<EmployeeNationalityFkeyInverseInput>;
  employeesToWorkingLocationCountryCodeAlpha2UsingCountryCodeAlpha2?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0InverseInput>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName: Scalars['String']['input'];
  telephoneCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface BankCountryCodeAlpha3FkeyInput {
  connectByCountryCodeAlpha2?: InputMaybe<CountryCountryPkeyConnect>;
  connectByCountryCodeAlpha3?: InputMaybe<CountryCountryCountryCodeAlpha3KeyConnect>;
  connectByCountryCodeNumeric?: InputMaybe<CountryCountryCountryCodeNumericKeyConnect>;
  connectByDisplayOrder?: InputMaybe<CountryCountryDisplayOrderKeyConnect>;
  connectByNodeId?: InputMaybe<CountryNodeIdConnect>;
  create?: InputMaybe<BankCountryCodeAlpha3FkeyCountryCreateInput>;
  deleteByCountryCodeAlpha2?: InputMaybe<CountryCountryPkeyDelete>;
  deleteByCountryCodeAlpha3?: InputMaybe<CountryCountryCountryCodeAlpha3KeyDelete>;
  deleteByCountryCodeNumeric?: InputMaybe<CountryCountryCountryCodeNumericKeyDelete>;
  deleteByDisplayOrder?: InputMaybe<CountryCountryDisplayOrderKeyDelete>;
  deleteByNodeId?: InputMaybe<CountryNodeIdDelete>;
  updateByCountryCodeAlpha2?: InputMaybe<CountryOnBankForBankCountryCodeAlpha3FkeyUsingCountryPkeyUpdate>;
  updateByCountryCodeAlpha3?: InputMaybe<CountryOnBankForBankCountryCodeAlpha3FkeyUsingCountryCountryCodeAlpha3KeyUpdate>;
  updateByCountryCodeNumeric?: InputMaybe<CountryOnBankForBankCountryCodeAlpha3FkeyUsingCountryCountryCodeNumericKeyUpdate>;
  updateByDisplayOrder?: InputMaybe<CountryOnBankForBankCountryCodeAlpha3FkeyUsingCountryDisplayOrderKeyUpdate>;
  updateByNodeId?: InputMaybe<BankOnBankForBankCountryCodeAlpha3FkeyNodeIdUpdate>;
}

export interface BankCountryCodeAlpha3FkeyInverseInput {
  connectByBankId?: InputMaybe<Array<BankBankPkeyConnect>>;
  connectByCountryCodeAlpha3AndFullName?: InputMaybe<Array<BankBankCountryCodeAlpha3FullNameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<BankNodeIdConnect>>;
  connectBySwiftCode?: InputMaybe<Array<BankBankSwiftCodeKeyConnect>>;
  create?: InputMaybe<Array<BankCountryCodeAlpha3FkeyBankCreateInput>>;
  deleteByBankId?: InputMaybe<Array<BankBankPkeyDelete>>;
  deleteByCountryCodeAlpha3AndFullName?: InputMaybe<Array<BankBankCountryCodeAlpha3FullNameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<BankNodeIdDelete>>;
  deleteBySwiftCode?: InputMaybe<Array<BankBankSwiftCodeKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByBankId?: InputMaybe<Array<BankOnBankForBankCountryCodeAlpha3FkeyUsingBankPkeyUpdate>>;
  updateByCountryCodeAlpha3AndFullName?: InputMaybe<Array<BankOnBankForBankCountryCodeAlpha3FkeyUsingBankCountryCodeAlpha3FullNameKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<CountryOnBankForBankCountryCodeAlpha3FkeyNodeIdUpdate>>;
  updateBySwiftCode?: InputMaybe<Array<BankOnBankForBankCountryCodeAlpha3FkeyUsingBankSwiftCodeKeyUpdate>>;
}

export interface BankFilter {
  and?: InputMaybe<Array<BankFilter>>;
  bankCode?: InputMaybe<StringFilter>;
  bankId?: InputMaybe<BigIntFilter>;
  countryByCountryCodeAlpha3?: InputMaybe<CountryFilter>;
  countryCodeAlpha3?: InputMaybe<StringFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  employees?: InputMaybe<BankToManyEmployeeFilter>;
  employeesByConsultingFeeBankId?: InputMaybe<BankToManyEmployeeFilter>;
  employeesByConsultingFeeBankIdExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeesExist?: InputMaybe<Scalars['Boolean']['input']>;
  enabled?: InputMaybe<BooleanFilter>;
  fullName?: InputMaybe<StringFilter>;
  fullNameInLocalLanguage?: InputMaybe<StringFilter>;
  not?: InputMaybe<BankFilter>;
  or?: InputMaybe<Array<BankFilter>>;
  remark?: InputMaybe<StringFilter>;
  shortName?: InputMaybe<StringFilter>;
  swiftCode?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface BankInput {
  bankCode?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<BankCountryCodeAlpha3FkeyInput>;
  countryCodeAlpha3?: InputMaybe<Scalars['String']['input']>;
  employeesToBankIdUsingBankId?: InputMaybe<EmployeeBankIdFkeyInverseInput>;
  employeesToConsultingFeeBankIdUsingBankId?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInverseInput>;
  enabled: Scalars['Boolean']['input'];
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BankUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface BankNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface BankNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface BankOnBankForBankCountryCodeAlpha3FkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CountryPatch;
}

export interface BankOnBankForBankCountryCodeAlpha3FkeyUsingBankCountryCodeAlpha3FullNameKeyUpdate {
  countryCodeAlpha3: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  patch: UpdateBankOnBankForBankCountryCodeAlpha3FkeyPatch;
}

export interface BankOnBankForBankCountryCodeAlpha3FkeyUsingBankPkeyUpdate {
  bankId: Scalars['BigInt']['input'];
  patch: UpdateBankOnBankForBankCountryCodeAlpha3FkeyPatch;
}

export interface BankOnBankForBankCountryCodeAlpha3FkeyUsingBankSwiftCodeKeyUpdate {
  patch: UpdateBankOnBankForBankCountryCodeAlpha3FkeyPatch;
  swiftCode: Scalars['String']['input'];
}

export interface BankOnBankForBankUserCreatedFkeyUsingBankCountryCodeAlpha3FullNameKeyUpdate {
  countryCodeAlpha3: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  patch: UpdateBankOnBankForBankUserCreatedFkeyPatch;
}

export interface BankOnBankForBankUserCreatedFkeyUsingBankPkeyUpdate {
  bankId: Scalars['BigInt']['input'];
  patch: UpdateBankOnBankForBankUserCreatedFkeyPatch;
}

export interface BankOnBankForBankUserCreatedFkeyUsingBankSwiftCodeKeyUpdate {
  patch: UpdateBankOnBankForBankUserCreatedFkeyPatch;
  swiftCode: Scalars['String']['input'];
}

export interface BankOnBankForBankUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface BankOnBankForBankUserUpdatedFkeyUsingBankCountryCodeAlpha3FullNameKeyUpdate {
  countryCodeAlpha3: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  patch: UpdateBankOnBankForBankUserUpdatedFkeyPatch;
}

export interface BankOnBankForBankUserUpdatedFkeyUsingBankPkeyUpdate {
  bankId: Scalars['BigInt']['input'];
  patch: UpdateBankOnBankForBankUserUpdatedFkeyPatch;
}

export interface BankOnBankForBankUserUpdatedFkeyUsingBankSwiftCodeKeyUpdate {
  patch: UpdateBankOnBankForBankUserUpdatedFkeyPatch;
  swiftCode: Scalars['String']['input'];
}

export interface BankOnEmployeeForEmployeeBankIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface BankOnEmployeeForEmployeeConsultingFeeBankIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface BankOnEmployeeForEmployeeConsultingFeeBankIdFkeyUsingBankCountryCodeAlpha3FullNameKeyUpdate {
  countryCodeAlpha3: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  patch: UpdateBankOnEmployeeForEmployeeConsultingFeeBankIdFkeyPatch;
}

export interface BankOnEmployeeForEmployeeConsultingFeeBankIdFkeyUsingBankPkeyUpdate {
  bankId: Scalars['BigInt']['input'];
  patch: UpdateBankOnEmployeeForEmployeeConsultingFeeBankIdFkeyPatch;
}

export interface BankOnEmployeeForEmployeeConsultingFeeBankIdFkeyUsingBankSwiftCodeKeyUpdate {
  patch: UpdateBankOnEmployeeForEmployeeConsultingFeeBankIdFkeyPatch;
  swiftCode: Scalars['String']['input'];
}

export interface BankPatch {
  bankCode?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<BankCountryCodeAlpha3FkeyInput>;
  countryCodeAlpha3?: InputMaybe<Scalars['String']['input']>;
  employeesToBankIdUsingBankId?: InputMaybe<EmployeeBankIdFkeyInverseInput>;
  employeesToConsultingFeeBankIdUsingBankId?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInverseInput>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BankUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface BankToManyEmployeeFilter {
  every?: InputMaybe<EmployeeFilter>;
  none?: InputMaybe<EmployeeFilter>;
  some?: InputMaybe<EmployeeFilter>;
}

export interface BankUserCreatedFkeyInverseInput {
  connectByBankId?: InputMaybe<Array<BankBankPkeyConnect>>;
  connectByCountryCodeAlpha3AndFullName?: InputMaybe<Array<BankBankCountryCodeAlpha3FullNameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<BankNodeIdConnect>>;
  connectBySwiftCode?: InputMaybe<Array<BankBankSwiftCodeKeyConnect>>;
  deleteByBankId?: InputMaybe<Array<BankBankPkeyDelete>>;
  deleteByCountryCodeAlpha3AndFullName?: InputMaybe<Array<BankBankCountryCodeAlpha3FullNameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<BankNodeIdDelete>>;
  deleteBySwiftCode?: InputMaybe<Array<BankBankSwiftCodeKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByBankId?: InputMaybe<Array<BankOnBankForBankUserCreatedFkeyUsingBankPkeyUpdate>>;
  updateByCountryCodeAlpha3AndFullName?: InputMaybe<Array<BankOnBankForBankUserCreatedFkeyUsingBankCountryCodeAlpha3FullNameKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnBankForBankUserCreatedFkeyNodeIdUpdate>>;
  updateBySwiftCode?: InputMaybe<Array<BankOnBankForBankUserCreatedFkeyUsingBankSwiftCodeKeyUpdate>>;
}

export interface BankUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnBankForBankUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<BankOnBankForBankUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnBankForBankUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface BankUserUpdatedFkeyInverseInput {
  connectByBankId?: InputMaybe<Array<BankBankPkeyConnect>>;
  connectByCountryCodeAlpha3AndFullName?: InputMaybe<Array<BankBankCountryCodeAlpha3FullNameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<BankNodeIdConnect>>;
  connectBySwiftCode?: InputMaybe<Array<BankBankSwiftCodeKeyConnect>>;
  deleteByBankId?: InputMaybe<Array<BankBankPkeyDelete>>;
  deleteByCountryCodeAlpha3AndFullName?: InputMaybe<Array<BankBankCountryCodeAlpha3FullNameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<BankNodeIdDelete>>;
  deleteBySwiftCode?: InputMaybe<Array<BankBankSwiftCodeKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByBankId?: InputMaybe<Array<BankOnBankForBankUserUpdatedFkeyUsingBankPkeyUpdate>>;
  updateByCountryCodeAlpha3AndFullName?: InputMaybe<Array<BankOnBankForBankUserUpdatedFkeyUsingBankCountryCodeAlpha3FullNameKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnBankForBankUserUpdatedFkeyNodeIdUpdate>>;
  updateBySwiftCode?: InputMaybe<Array<BankOnBankForBankUserUpdatedFkeyUsingBankSwiftCodeKeyUpdate>>;
}

export interface BanksConnection {
  __typename?: 'BanksConnection';
  edges: Array<BanksEdge>;
  nodes: Array<Bank>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface BanksEdge {
  __typename?: 'BanksEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: Bank;
}

export enum BanksOrderBy {
  BANK_CODE_ASC = 'BANK_CODE_ASC',
  BANK_CODE_DESC = 'BANK_CODE_DESC',
  BANK_ID_ASC = 'BANK_ID_ASC',
  BANK_ID_DESC = 'BANK_ID_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA3__COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA3__COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA3__COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA3__COUNTRY_CODE_ALPHA2_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA3__COUNTRY_CODE_ALPHA3_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA3__COUNTRY_CODE_ALPHA3_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA3__COUNTRY_CODE_ALPHA3_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA3__COUNTRY_CODE_ALPHA3_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA3__COUNTRY_CODE_NUMERIC_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA3__COUNTRY_CODE_NUMERIC_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA3__COUNTRY_CODE_NUMERIC_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA3__COUNTRY_CODE_NUMERIC_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA3__DATE_CREATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA3__DATE_CREATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA3__DATE_CREATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA3__DATE_CREATED_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA3__DATE_UPDATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA3__DATE_UPDATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA3__DATE_UPDATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA3__DATE_UPDATED_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA3__DISPLAY_ORDER_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA3__DISPLAY_ORDER_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA3__DISPLAY_ORDER_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA3__DISPLAY_ORDER_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA3__REMARK_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA3__REMARK_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA3__REMARK_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA3__REMARK_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA3__SHORT_NAME_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA3__SHORT_NAME_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA3__SHORT_NAME_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA3__SHORT_NAME_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA3__TELEPHONE_CODE_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA3__TELEPHONE_CODE_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA3__TELEPHONE_CODE_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA3__TELEPHONE_CODE_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA3__USER_CREATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA3__USER_CREATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA3__USER_CREATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA3__USER_CREATED_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA3__USER_UPDATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA3__USER_UPDATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA3__USER_UPDATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA3__USER_UPDATED_DESC',
  COUNTRY_CODE_ALPHA3_ASC = 'COUNTRY_CODE_ALPHA3_ASC',
  COUNTRY_CODE_ALPHA3_DESC = 'COUNTRY_CODE_ALPHA3_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEES_BY_BANK_ID__COUNT_ASC = 'EMPLOYEES_BY_BANK_ID__COUNT_ASC',
  EMPLOYEES_BY_BANK_ID__COUNT_DESC = 'EMPLOYEES_BY_BANK_ID__COUNT_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_APPROVER_ID_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_APPROVER_ID_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_APPROVER_ID_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_APPROVER_ID_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_BANK_ID_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_BANK_ID_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_BANK_ID_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_BANK_ID_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_BANK_REMARKS_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_BANK_REMARKS_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_BANK_REMARKS_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_BANK_REMARKS_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_DATE_CREATED_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_DATE_CREATED_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_DATE_CREATED_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_DATE_CREATED_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_DATE_UPDATED_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_DATE_UPDATED_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_DATE_UPDATED_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_DATE_UPDATED_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_FULL_NAME_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_FULL_NAME_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_FULL_NAME_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_FULL_NAME_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_GENDER_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_GENDER_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_GENDER_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_GENDER_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_ID_NUMBER_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_ID_NUMBER_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_ID_NUMBER_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_ID_NUMBER_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_ID_TYPE_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_ID_TYPE_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_ID_TYPE_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_ID_TYPE_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_JOIN_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_JOIN_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_JOIN_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_JOIN_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_NATIONALITY_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_NATIONALITY_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_NATIONALITY_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_NATIONALITY_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_NICKNAME_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_NICKNAME_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_NICKNAME_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_NICKNAME_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_PROBATION_END_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_PROBATION_END_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_PROBATION_END_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_PROBATION_END_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_REMARK_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_REMARK_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_REMARK_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_REMARK_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_STATUS_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_STATUS_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_STATUS_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_STATUS_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_TAX_CODE_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_TAX_CODE_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_TAX_CODE_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_TAX_CODE_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_USER_CREATED_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_USER_CREATED_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_USER_CREATED_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_USER_CREATED_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_USER_UPDATED_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_USER_UPDATED_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_USER_UPDATED_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_USER_UPDATED_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_VISA_NUMBER_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_VISA_NUMBER_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_VISA_NUMBER_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_VISA_NUMBER_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_VISA_TYPE_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_VISA_TYPE_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_VISA_TYPE_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_VISA_TYPE_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MAX_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_BANK_ID__MAX_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_BANK_ID__MAX_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_BANK_ID__MAX_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_APPROVER_ID_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_APPROVER_ID_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_APPROVER_ID_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_APPROVER_ID_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_BANK_ID_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_BANK_ID_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_BANK_ID_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_BANK_ID_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_BANK_REMARKS_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_BANK_REMARKS_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_BANK_REMARKS_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_BANK_REMARKS_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_DATE_CREATED_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_DATE_CREATED_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_DATE_CREATED_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_DATE_CREATED_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_DATE_UPDATED_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_DATE_UPDATED_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_DATE_UPDATED_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_DATE_UPDATED_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_FULL_NAME_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_FULL_NAME_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_FULL_NAME_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_FULL_NAME_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_GENDER_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_GENDER_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_GENDER_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_GENDER_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_ID_NUMBER_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_ID_NUMBER_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_ID_NUMBER_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_ID_NUMBER_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_ID_TYPE_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_ID_TYPE_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_ID_TYPE_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_ID_TYPE_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_JOIN_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_JOIN_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_JOIN_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_JOIN_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_NATIONALITY_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_NATIONALITY_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_NATIONALITY_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_NATIONALITY_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_NICKNAME_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_NICKNAME_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_NICKNAME_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_NICKNAME_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_PROBATION_END_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_PROBATION_END_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_PROBATION_END_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_PROBATION_END_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_REMARK_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_REMARK_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_REMARK_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_REMARK_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_STATUS_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_STATUS_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_STATUS_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_STATUS_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_TAX_CODE_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_TAX_CODE_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_TAX_CODE_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_TAX_CODE_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_USER_CREATED_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_USER_CREATED_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_USER_CREATED_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_USER_CREATED_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_USER_UPDATED_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_USER_UPDATED_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_USER_UPDATED_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_USER_UPDATED_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_VISA_NUMBER_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_VISA_NUMBER_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_VISA_NUMBER_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_VISA_NUMBER_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_VISA_TYPE_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_VISA_TYPE_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_VISA_TYPE_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_VISA_TYPE_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_BANK_ID__MIN_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_BANK_ID__MIN_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_BANK_ID__MIN_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_BANK_ID__MIN_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__COUNT_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__COUNT_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__COUNT_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__COUNT_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_APPROVER_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_APPROVER_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_APPROVER_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_APPROVER_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_BANK_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_BANK_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_BANK_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_BANK_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_BANK_REMARKS_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_BANK_REMARKS_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_BANK_REMARKS_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_BANK_REMARKS_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_DATE_CREATED_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_DATE_CREATED_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_DATE_CREATED_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_DATE_CREATED_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_DATE_UPDATED_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_DATE_UPDATED_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_DATE_UPDATED_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_DATE_UPDATED_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_FULL_NAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_FULL_NAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_FULL_NAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_FULL_NAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_GENDER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_GENDER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_GENDER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_GENDER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_ID_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_ID_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_ID_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_ID_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_ID_TYPE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_ID_TYPE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_ID_TYPE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_ID_TYPE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_JOIN_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_JOIN_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_JOIN_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_JOIN_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_NATIONALITY_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_NATIONALITY_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_NATIONALITY_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_NATIONALITY_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_NICKNAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_NICKNAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_NICKNAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_NICKNAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PROBATION_END_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PROBATION_END_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PROBATION_END_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_PROBATION_END_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_REMARK_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_REMARK_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_REMARK_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_REMARK_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_STATUS_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_STATUS_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_STATUS_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_STATUS_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_TAX_CODE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_TAX_CODE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_TAX_CODE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_TAX_CODE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_USER_CREATED_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_USER_CREATED_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_USER_CREATED_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_USER_CREATED_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_USER_UPDATED_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_USER_UPDATED_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_USER_UPDATED_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_USER_UPDATED_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_VISA_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_VISA_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_VISA_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_VISA_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_VISA_TYPE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_VISA_TYPE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_VISA_TYPE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_VISA_TYPE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MAX_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_APPROVER_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_APPROVER_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_APPROVER_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_APPROVER_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_BANK_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_BANK_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_BANK_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_BANK_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_BANK_REMARKS_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_BANK_REMARKS_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_BANK_REMARKS_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_BANK_REMARKS_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_DATE_CREATED_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_DATE_CREATED_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_DATE_CREATED_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_DATE_CREATED_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_DATE_UPDATED_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_DATE_UPDATED_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_DATE_UPDATED_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_DATE_UPDATED_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_FULL_NAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_FULL_NAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_FULL_NAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_FULL_NAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_GENDER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_GENDER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_GENDER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_GENDER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_ID_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_ID_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_ID_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_ID_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_ID_TYPE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_ID_TYPE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_ID_TYPE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_ID_TYPE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_JOIN_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_JOIN_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_JOIN_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_JOIN_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_NATIONALITY_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_NATIONALITY_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_NATIONALITY_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_NATIONALITY_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_NICKNAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_NICKNAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_NICKNAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_NICKNAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PROBATION_END_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PROBATION_END_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PROBATION_END_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_PROBATION_END_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_REMARK_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_REMARK_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_REMARK_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_REMARK_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_STATUS_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_STATUS_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_STATUS_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_STATUS_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_TAX_CODE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_TAX_CODE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_TAX_CODE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_TAX_CODE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_USER_CREATED_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_USER_CREATED_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_USER_CREATED_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_USER_CREATED_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_USER_UPDATED_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_USER_UPDATED_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_USER_UPDATED_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_USER_UPDATED_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_VISA_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_VISA_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_VISA_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_VISA_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_VISA_TYPE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_VISA_TYPE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_VISA_TYPE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_VISA_TYPE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_BANK_ID__MIN_WORK_PERMIT_NUMBER_DESC',
  ENABLED_ASC = 'ENABLED_ASC',
  ENABLED_DESC = 'ENABLED_DESC',
  FULL_NAME_ASC = 'FULL_NAME_ASC',
  FULL_NAME_DESC = 'FULL_NAME_DESC',
  FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  SHORT_NAME_ASC = 'SHORT_NAME_ASC',
  SHORT_NAME_DESC = 'SHORT_NAME_DESC',
  SWIFT_CODE_ASC = 'SWIFT_CODE_ASC',
  SWIFT_CODE_DESC = 'SWIFT_CODE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface BigFloatFilter {
  distinctFrom?: InputMaybe<Scalars['BigFloat']['input']>;
  equalTo?: InputMaybe<Scalars['BigFloat']['input']>;
  greaterThan?: InputMaybe<Scalars['BigFloat']['input']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['BigFloat']['input']>;
  in?: InputMaybe<Array<Scalars['BigFloat']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<Scalars['BigFloat']['input']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['BigFloat']['input']>;
  notDistinctFrom?: InputMaybe<Scalars['BigFloat']['input']>;
  notEqualTo?: InputMaybe<Scalars['BigFloat']['input']>;
  notIn?: InputMaybe<Array<Scalars['BigFloat']['input']>>;
}

export interface BigIntFilter {
  distinctFrom?: InputMaybe<Scalars['BigInt']['input']>;
  equalTo?: InputMaybe<Scalars['BigInt']['input']>;
  greaterThan?: InputMaybe<Scalars['BigInt']['input']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['BigInt']['input']>;
  in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<Scalars['BigInt']['input']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['BigInt']['input']>;
  notDistinctFrom?: InputMaybe<Scalars['BigInt']['input']>;
  notEqualTo?: InputMaybe<Scalars['BigInt']['input']>;
  notIn?: InputMaybe<Array<Scalars['BigInt']['input']>>;
}

export interface BigIntListFilter {
  anyEqualTo?: InputMaybe<Scalars['BigInt']['input']>;
  anyGreaterThan?: InputMaybe<Scalars['BigInt']['input']>;
  anyGreaterThanOrEqualTo?: InputMaybe<Scalars['BigInt']['input']>;
  anyLessThan?: InputMaybe<Scalars['BigInt']['input']>;
  anyLessThanOrEqualTo?: InputMaybe<Scalars['BigInt']['input']>;
  anyNotEqualTo?: InputMaybe<Scalars['BigInt']['input']>;
  containedBy?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  contains?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  distinctFrom?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  equalTo?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  greaterThan?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  greaterThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  notDistinctFrom?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  notEqualTo?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  overlaps?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
}

export interface BooleanFilter {
  distinctFrom?: InputMaybe<Scalars['Boolean']['input']>;
  equalTo?: InputMaybe<Scalars['Boolean']['input']>;
  greaterThan?: InputMaybe<Scalars['Boolean']['input']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<Scalars['Boolean']['input']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['Boolean']['input']>;
  notDistinctFrom?: InputMaybe<Scalars['Boolean']['input']>;
  notEqualTo?: InputMaybe<Scalars['Boolean']['input']>;
  notIn?: InputMaybe<Array<Scalars['Boolean']['input']>>;
}

export interface BusinessUnit extends Node {
  __typename?: 'BusinessUnit';
  businessUnitId: Scalars['BigInt']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  employeeCurrentPositions: EmployeeCurrentPositionsConnection;
  employeeTpHistories: EmployeeTpHistoriesConnection;
  googleWorkspaceOrgUnits: GoogleWorkspaceOrgUnitsConnection;
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  performanceReviewMasterCompetencyRoles: PerformanceReviewMasterCompetencyRolesConnection;
  relationBusinessUnitDivisionDepartments: RelationBusinessUnitDivisionDepartmentsConnection;
  relationBusinessUnitDivisions: RelationBusinessUnitDivisionsConnection;
  relationCompanyBusinessUnits: RelationCompanyBusinessUnitsConnection;
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export type BusinessUnitEmployeeCurrentPositionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCurrentPositionCondition>;
  filter?: InputMaybe<EmployeeCurrentPositionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeCurrentPositionsOrderBy>>;
};

export type BusinessUnitEmployeeTpHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeTpHistoryCondition>;
  filter?: InputMaybe<EmployeeTpHistoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeTpHistoriesOrderBy>>;
};

export type BusinessUnitGoogleWorkspaceOrgUnitsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GoogleWorkspaceOrgUnitCondition>;
  filter?: InputMaybe<GoogleWorkspaceOrgUnitFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GoogleWorkspaceOrgUnitsOrderBy>>;
};

export type BusinessUnitPerformanceReviewMasterCompetencyRolesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyRoleCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyRoleFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyRolesOrderBy>>;
};

export type BusinessUnitRelationBusinessUnitDivisionDepartmentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationBusinessUnitDivisionDepartmentCondition>;
  filter?: InputMaybe<RelationBusinessUnitDivisionDepartmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentsOrderBy>>;
};

export type BusinessUnitRelationBusinessUnitDivisionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationBusinessUnitDivisionCondition>;
  filter?: InputMaybe<RelationBusinessUnitDivisionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationBusinessUnitDivisionsOrderBy>>;
};

export type BusinessUnitRelationCompanyBusinessUnitsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationCompanyBusinessUnitCondition>;
  filter?: InputMaybe<RelationCompanyBusinessUnitFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationCompanyBusinessUnitsOrderBy>>;
};

export interface BusinessUnitBusinessUnitNameKeyConnect {
  name: Scalars['String']['input'];
}

export interface BusinessUnitBusinessUnitNameKeyDelete {
  name: Scalars['String']['input'];
}

export interface BusinessUnitBusinessUnitPkeyConnect {
  businessUnitId: Scalars['BigInt']['input'];
}

export interface BusinessUnitBusinessUnitPkeyDelete {
  businessUnitId: Scalars['BigInt']['input'];
}

export interface BusinessUnitCondition {
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface BusinessUnitFilter {
  and?: InputMaybe<Array<BusinessUnitFilter>>;
  businessUnitId?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  employeeCurrentPositions?: InputMaybe<BusinessUnitToManyEmployeeCurrentPositionFilter>;
  employeeCurrentPositionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeTpHistories?: InputMaybe<BusinessUnitToManyEmployeeTpHistoryFilter>;
  employeeTpHistoriesExist?: InputMaybe<Scalars['Boolean']['input']>;
  googleWorkspaceOrgUnits?: InputMaybe<BusinessUnitToManyGoogleWorkspaceOrgUnitFilter>;
  googleWorkspaceOrgUnitsExist?: InputMaybe<Scalars['Boolean']['input']>;
  isActive?: InputMaybe<BooleanFilter>;
  name?: InputMaybe<StringFilter>;
  not?: InputMaybe<BusinessUnitFilter>;
  or?: InputMaybe<Array<BusinessUnitFilter>>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<BusinessUnitToManyPerformanceReviewMasterCompetencyRoleFilter>;
  performanceReviewMasterCompetencyRolesExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<BusinessUnitToManyRelationBusinessUnitDivisionDepartmentFilter>;
  relationBusinessUnitDivisionDepartmentsExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationBusinessUnitDivisions?: InputMaybe<BusinessUnitToManyRelationBusinessUnitDivisionFilter>;
  relationBusinessUnitDivisionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationCompanyBusinessUnits?: InputMaybe<BusinessUnitToManyRelationCompanyBusinessUnitFilter>;
  relationCompanyBusinessUnitsExist?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface BusinessUnitInput {
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInverseInput>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BusinessUnitUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface BusinessUnitNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface BusinessUnitNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface BusinessUnitOnBusinessUnitForBusinessUnitUserCreatedFkeyUsingBusinessUnitNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateBusinessUnitOnBusinessUnitForBusinessUnitUserCreatedFkeyPatch;
}

export interface BusinessUnitOnBusinessUnitForBusinessUnitUserCreatedFkeyUsingBusinessUnitPkeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  patch: UpdateBusinessUnitOnBusinessUnitForBusinessUnitUserCreatedFkeyPatch;
}

export interface BusinessUnitOnBusinessUnitForBusinessUnitUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface BusinessUnitOnBusinessUnitForBusinessUnitUserUpdatedFkeyUsingBusinessUnitNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateBusinessUnitOnBusinessUnitForBusinessUnitUserUpdatedFkeyPatch;
}

export interface BusinessUnitOnBusinessUnitForBusinessUnitUserUpdatedFkeyUsingBusinessUnitPkeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  patch: UpdateBusinessUnitOnBusinessUnitForBusinessUnitUserUpdatedFkeyPatch;
}

export interface BusinessUnitOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey8NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeCurrentPositionPatch;
}

export interface BusinessUnitOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey8UsingBusinessUnitNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateBusinessUnitOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey8Patch;
}

export interface BusinessUnitOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey8UsingBusinessUnitPkeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  patch: UpdateBusinessUnitOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey8Patch;
}

export interface BusinessUnitOnEmployeeTpHistoryForEmployeeTpHistoryBusinessUnitIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeTpHistoryPatch;
}

export interface BusinessUnitOnEmployeeTpHistoryForEmployeeTpHistoryBusinessUnitIdFkeyUsingBusinessUnitNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateBusinessUnitOnEmployeeTpHistoryForEmployeeTpHistoryBusinessUnitIdFkeyPatch;
}

export interface BusinessUnitOnEmployeeTpHistoryForEmployeeTpHistoryBusinessUnitIdFkeyUsingBusinessUnitPkeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  patch: UpdateBusinessUnitOnEmployeeTpHistoryForEmployeeTpHistoryBusinessUnitIdFkeyPatch;
}

export interface BusinessUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitBusinessUnitIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: GoogleWorkspaceOrgUnitPatch;
}

export interface BusinessUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitBusinessUnitIdFkeyUsingBusinessUnitNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateBusinessUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitBusinessUnitIdFkeyPatch;
}

export interface BusinessUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitBusinessUnitIdFkeyUsingBusinessUnitPkeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  patch: UpdateBusinessUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitBusinessUnitIdFkeyPatch;
}

export interface BusinessUnitOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyRolePatch;
}

export interface BusinessUnitOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyUsingBusinessUnitNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateBusinessUnitOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyPatch;
}

export interface BusinessUnitOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyUsingBusinessUnitPkeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  patch: UpdateBusinessUnitOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyPatch;
}

export interface BusinessUnitOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationBusinessUnitDivisionDepartmentPatch;
}

export interface BusinessUnitOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyUsingBusinessUnitNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateBusinessUnitOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyPatch;
}

export interface BusinessUnitOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyUsingBusinessUnitPkeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  patch: UpdateBusinessUnitOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyPatch;
}

export interface BusinessUnitOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionBusinessUnitIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationBusinessUnitDivisionPatch;
}

export interface BusinessUnitOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionBusinessUnitIdFkeyUsingBusinessUnitNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateBusinessUnitOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionBusinessUnitIdFkeyPatch;
}

export interface BusinessUnitOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionBusinessUnitIdFkeyUsingBusinessUnitPkeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  patch: UpdateBusinessUnitOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionBusinessUnitIdFkeyPatch;
}

export interface BusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitBusinessUnitIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationCompanyBusinessUnitPatch;
}

export interface BusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitBusinessUnitIdFkeyUsingBusinessUnitNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateBusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitBusinessUnitIdFkeyPatch;
}

export interface BusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitBusinessUnitIdFkeyUsingBusinessUnitPkeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  patch: UpdateBusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitBusinessUnitIdFkeyPatch;
}

export interface BusinessUnitPatch {
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInverseInput>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BusinessUnitUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface BusinessUnitToManyEmployeeCurrentPositionFilter {
  every?: InputMaybe<EmployeeCurrentPositionFilter>;
  none?: InputMaybe<EmployeeCurrentPositionFilter>;
  some?: InputMaybe<EmployeeCurrentPositionFilter>;
}

export interface BusinessUnitToManyEmployeeTpHistoryFilter {
  every?: InputMaybe<EmployeeTpHistoryFilter>;
  none?: InputMaybe<EmployeeTpHistoryFilter>;
  some?: InputMaybe<EmployeeTpHistoryFilter>;
}

export interface BusinessUnitToManyGoogleWorkspaceOrgUnitFilter {
  every?: InputMaybe<GoogleWorkspaceOrgUnitFilter>;
  none?: InputMaybe<GoogleWorkspaceOrgUnitFilter>;
  some?: InputMaybe<GoogleWorkspaceOrgUnitFilter>;
}

export interface BusinessUnitToManyPerformanceReviewMasterCompetencyRoleFilter {
  every?: InputMaybe<PerformanceReviewMasterCompetencyRoleFilter>;
  none?: InputMaybe<PerformanceReviewMasterCompetencyRoleFilter>;
  some?: InputMaybe<PerformanceReviewMasterCompetencyRoleFilter>;
}

export interface BusinessUnitToManyRelationBusinessUnitDivisionDepartmentFilter {
  aggregates?: InputMaybe<RelationBusinessUnitDivisionDepartmentAggregatesFilter>;
  every?: InputMaybe<RelationBusinessUnitDivisionDepartmentFilter>;
  none?: InputMaybe<RelationBusinessUnitDivisionDepartmentFilter>;
  some?: InputMaybe<RelationBusinessUnitDivisionDepartmentFilter>;
}

export interface BusinessUnitToManyRelationBusinessUnitDivisionFilter {
  aggregates?: InputMaybe<RelationBusinessUnitDivisionAggregatesFilter>;
  every?: InputMaybe<RelationBusinessUnitDivisionFilter>;
  none?: InputMaybe<RelationBusinessUnitDivisionFilter>;
  some?: InputMaybe<RelationBusinessUnitDivisionFilter>;
}

export interface BusinessUnitToManyRelationCompanyBusinessUnitFilter {
  every?: InputMaybe<RelationCompanyBusinessUnitFilter>;
  none?: InputMaybe<RelationCompanyBusinessUnitFilter>;
  some?: InputMaybe<RelationCompanyBusinessUnitFilter>;
}

export interface BusinessUnitUserCreatedFkeyInverseInput {
  connectByBusinessUnitId?: InputMaybe<Array<BusinessUnitBusinessUnitPkeyConnect>>;
  connectByName?: InputMaybe<Array<BusinessUnitBusinessUnitNameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<BusinessUnitNodeIdConnect>>;
  deleteByBusinessUnitId?: InputMaybe<Array<BusinessUnitBusinessUnitPkeyDelete>>;
  deleteByName?: InputMaybe<Array<BusinessUnitBusinessUnitNameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<BusinessUnitNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByBusinessUnitId?: InputMaybe<Array<BusinessUnitOnBusinessUnitForBusinessUnitUserCreatedFkeyUsingBusinessUnitPkeyUpdate>>;
  updateByName?: InputMaybe<Array<BusinessUnitOnBusinessUnitForBusinessUnitUserCreatedFkeyUsingBusinessUnitNameKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnBusinessUnitForBusinessUnitUserCreatedFkeyNodeIdUpdate>>;
}

export interface BusinessUnitUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnBusinessUnitForBusinessUnitUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<BusinessUnitOnBusinessUnitForBusinessUnitUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnBusinessUnitForBusinessUnitUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface BusinessUnitUserUpdatedFkeyInverseInput {
  connectByBusinessUnitId?: InputMaybe<Array<BusinessUnitBusinessUnitPkeyConnect>>;
  connectByName?: InputMaybe<Array<BusinessUnitBusinessUnitNameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<BusinessUnitNodeIdConnect>>;
  deleteByBusinessUnitId?: InputMaybe<Array<BusinessUnitBusinessUnitPkeyDelete>>;
  deleteByName?: InputMaybe<Array<BusinessUnitBusinessUnitNameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<BusinessUnitNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByBusinessUnitId?: InputMaybe<Array<BusinessUnitOnBusinessUnitForBusinessUnitUserUpdatedFkeyUsingBusinessUnitPkeyUpdate>>;
  updateByName?: InputMaybe<Array<BusinessUnitOnBusinessUnitForBusinessUnitUserUpdatedFkeyUsingBusinessUnitNameKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnBusinessUnitForBusinessUnitUserUpdatedFkeyNodeIdUpdate>>;
}

export interface BusinessUnitsConnection {
  __typename?: 'BusinessUnitsConnection';
  edges: Array<BusinessUnitsEdge>;
  nodes: Array<BusinessUnit>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface BusinessUnitsEdge {
  __typename?: 'BusinessUnitsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: BusinessUnit;
}

export enum BusinessUnitsOrderBy {
  BUSINESS_UNIT_ID_ASC = 'BUSINESS_UNIT_ID_ASC',
  BUSINESS_UNIT_ID_DESC = 'BUSINESS_UNIT_ID_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__COUNT_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__COUNT_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__COUNT_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__COUNT_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_CITY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_CITY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_CITY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_CITY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_COMPANY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_COMPANY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_COMPANY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_COMPANY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_DEPARTMENT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_DEPARTMENT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_DEPARTMENT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_DEPARTMENT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_DIVISION_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_DIVISION_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_DIVISION_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_DIVISION_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_EFFECTIVE_DATE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_EFFECTIVE_DATE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_EFFECTIVE_DATE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_EFFECTIVE_DATE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_CODE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_CODE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_CODE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_CODE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_JOB_TITLE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_JOB_TITLE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_JOB_TITLE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_JOB_TITLE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_OFFICE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_OFFICE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_OFFICE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_OFFICE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_TEAM_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_TEAM_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_TEAM_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MAX_TEAM_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_CITY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_CITY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_CITY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_CITY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_COMPANY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_COMPANY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_COMPANY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_COMPANY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_DEPARTMENT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_DEPARTMENT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_DEPARTMENT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_DEPARTMENT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_DIVISION_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_DIVISION_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_DIVISION_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_DIVISION_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_EFFECTIVE_DATE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_EFFECTIVE_DATE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_EFFECTIVE_DATE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_EFFECTIVE_DATE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_CODE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_CODE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_CODE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_CODE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_JOB_TITLE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_JOB_TITLE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_JOB_TITLE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_JOB_TITLE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_OFFICE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_OFFICE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_OFFICE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_OFFICE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_TEAM_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_TEAM_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_TEAM_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_BUSINESS_UNIT_ID__MIN_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__COUNT_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__COUNT_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__COUNT_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__COUNT_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_COMPANY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_COMPANY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_COMPANY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_COMPANY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_DATE_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_DATE_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_DATE_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_DATE_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_DEPARTMENT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_DEPARTMENT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_DEPARTMENT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_DEPARTMENT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_DIVISION_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_DIVISION_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_DIVISION_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_DIVISION_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_EFFECTIVE_DATE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_EFFECTIVE_DATE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_EFFECTIVE_DATE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_EFFECTIVE_DATE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_JOB_TITLE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_JOB_TITLE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_JOB_TITLE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_JOB_TITLE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_OFFICE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_OFFICE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_OFFICE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_OFFICE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_REMARK_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_REMARK_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_REMARK_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_REMARK_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_TEAM_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_TEAM_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_TEAM_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_USER_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_USER_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_USER_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_USER_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_USER_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_USER_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_USER_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MAX_USER_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_COMPANY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_COMPANY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_COMPANY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_COMPANY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_DATE_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_DATE_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_DATE_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_DATE_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_DEPARTMENT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_DEPARTMENT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_DEPARTMENT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_DEPARTMENT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_DIVISION_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_DIVISION_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_DIVISION_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_DIVISION_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_EFFECTIVE_DATE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_EFFECTIVE_DATE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_EFFECTIVE_DATE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_EFFECTIVE_DATE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_JOB_TITLE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_JOB_TITLE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_JOB_TITLE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_JOB_TITLE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_OFFICE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_OFFICE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_OFFICE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_OFFICE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_REMARK_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_REMARK_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_REMARK_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_REMARK_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_TEAM_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_TEAM_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_TEAM_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_USER_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_USER_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_USER_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_USER_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_USER_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_USER_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_USER_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_BUSINESS_UNIT_ID__MIN_USER_UPDATED_DESC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__COUNT_ASC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__COUNT_ASC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__COUNT_DESC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__COUNT_DESC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_ASC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_ASC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_DESC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_DESC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MAX_COMPANY_ID_ASC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MAX_COMPANY_ID_ASC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MAX_COMPANY_ID_DESC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MAX_COMPANY_ID_DESC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MAX_GOOGLE_WORKSPACE_ORG_UNIT_ID_ASC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MAX_GOOGLE_WORKSPACE_ORG_UNIT_ID_ASC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MAX_GOOGLE_WORKSPACE_ORG_UNIT_ID_DESC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MAX_GOOGLE_WORKSPACE_ORG_UNIT_ID_DESC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MAX_ORG_UNIT_PATH_ASC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MAX_ORG_UNIT_PATH_ASC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MAX_ORG_UNIT_PATH_DESC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MAX_ORG_UNIT_PATH_DESC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MAX_PRIORITY_ASC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MAX_PRIORITY_ASC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MAX_PRIORITY_DESC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MAX_PRIORITY_DESC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_ASC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_ASC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_DESC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_DESC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MIN_COMPANY_ID_ASC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MIN_COMPANY_ID_ASC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MIN_COMPANY_ID_DESC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MIN_COMPANY_ID_DESC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MIN_GOOGLE_WORKSPACE_ORG_UNIT_ID_ASC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MIN_GOOGLE_WORKSPACE_ORG_UNIT_ID_ASC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MIN_GOOGLE_WORKSPACE_ORG_UNIT_ID_DESC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MIN_GOOGLE_WORKSPACE_ORG_UNIT_ID_DESC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MIN_ORG_UNIT_PATH_ASC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MIN_ORG_UNIT_PATH_ASC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MIN_ORG_UNIT_PATH_DESC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MIN_ORG_UNIT_PATH_DESC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MIN_PRIORITY_ASC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MIN_PRIORITY_ASC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MIN_PRIORITY_DESC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_BUSINESS_UNIT_ID__MIN_PRIORITY_DESC',
  IS_ACTIVE_ASC = 'IS_ACTIVE_ASC',
  IS_ACTIVE_DESC = 'IS_ACTIVE_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_DEPARTMENT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_DEPARTMENT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_DEPARTMENT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_DEPARTMENT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_DIVISION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_DIVISION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_DIVISION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_DIVISION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_ROLE_NAME_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_ROLE_NAME_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_ROLE_NAME_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_ROLE_NAME_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_DEPARTMENT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_DEPARTMENT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_DEPARTMENT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_DEPARTMENT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_DIVISION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_DIVISION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_DIVISION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_DIVISION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_ROLE_NAME_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_ROLE_NAME_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_ROLE_NAME_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_ROLE_NAME_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_BUSINESS_UNIT_ID__MIN_YEAR_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__COUNT_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__COUNT_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__COUNT_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__COUNT_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MAX_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_BUSINESS_UNIT_ID__MIN_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_COUNT_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_COUNT_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_COUNT_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_COUNT_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__COUNT_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__COUNT_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__COUNT_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__COUNT_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MAX_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_BUSINESS_UNIT_ID__MIN_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_COUNT_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_COUNT_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_COUNT_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_COUNT_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_USER_UPDATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__COUNT_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__COUNT_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__COUNT_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__COUNT_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_BUSINESS_UNIT_ID_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_COMPANY_ID_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_COMPANY_ID_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_COMPANY_ID_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_COMPANY_ID_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_DATE_CREATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_DATE_CREATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_DATE_CREATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_DATE_CREATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_DATE_UPDATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_DATE_UPDATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_DATE_UPDATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_DATE_UPDATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_REMARK_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_REMARK_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_REMARK_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_REMARK_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_USER_CREATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_USER_CREATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_USER_CREATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_USER_CREATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_USER_UPDATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_USER_UPDATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_USER_UPDATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MAX_USER_UPDATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_BUSINESS_UNIT_ID_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_COMPANY_ID_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_COMPANY_ID_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_COMPANY_ID_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_COMPANY_ID_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_DATE_CREATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_DATE_CREATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_DATE_CREATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_DATE_CREATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_DATE_UPDATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_DATE_UPDATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_DATE_UPDATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_DATE_UPDATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_REMARK_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_REMARK_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_REMARK_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_REMARK_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_USER_CREATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_USER_CREATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_USER_CREATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_USER_CREATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_USER_UPDATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_USER_UPDATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_USER_UPDATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_BUSINESS_UNIT_ID__MIN_USER_UPDATED_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface CitiesConnection {
  __typename?: 'CitiesConnection';
  edges: Array<CitiesEdge>;
  nodes: Array<City>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface CitiesEdge {
  __typename?: 'CitiesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: City;
}

export enum CitiesOrderBy {
  CITY_ID_ASC = 'CITY_ID_ASC',
  CITY_ID_DESC = 'CITY_ID_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_CREATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_CREATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_CREATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_CREATED_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_UPDATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_UPDATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_UPDATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_UPDATED_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__REMARK_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__REMARK_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__REMARK_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__REMARK_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__SHORT_NAME_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__SHORT_NAME_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__SHORT_NAME_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__SHORT_NAME_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_CREATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_CREATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_CREATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_CREATED_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_UPDATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_UPDATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_UPDATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_UPDATED_DESC',
  COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_CODE_ALPHA2_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__COUNT_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__COUNT_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__COUNT_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__COUNT_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_CITY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_CITY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_CITY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_CITY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_COMPANY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_COMPANY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_COMPANY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_COMPANY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_DEPARTMENT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_DEPARTMENT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_DEPARTMENT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_DEPARTMENT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_DIVISION_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_DIVISION_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_DIVISION_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_DIVISION_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_EFFECTIVE_DATE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_EFFECTIVE_DATE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_EFFECTIVE_DATE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_EFFECTIVE_DATE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_EMPLOYEE_CODE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_EMPLOYEE_CODE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_EMPLOYEE_CODE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_EMPLOYEE_CODE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_JOB_TITLE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_JOB_TITLE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_JOB_TITLE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_JOB_TITLE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_OFFICE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_OFFICE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_OFFICE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_OFFICE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_TEAM_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_TEAM_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_TEAM_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MAX_TEAM_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_CITY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_CITY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_CITY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_CITY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_COMPANY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_COMPANY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_COMPANY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_COMPANY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_DEPARTMENT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_DEPARTMENT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_DEPARTMENT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_DEPARTMENT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_DIVISION_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_DIVISION_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_DIVISION_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_DIVISION_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_EFFECTIVE_DATE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_EFFECTIVE_DATE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_EFFECTIVE_DATE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_EFFECTIVE_DATE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_EMPLOYEE_CODE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_EMPLOYEE_CODE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_EMPLOYEE_CODE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_EMPLOYEE_CODE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_JOB_TITLE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_JOB_TITLE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_JOB_TITLE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_JOB_TITLE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_OFFICE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_OFFICE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_OFFICE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_OFFICE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_TEAM_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_TEAM_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_TEAM_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CITY_ID__MIN_TEAM_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  OFFICES_BY_CITY_ID__COUNT_ASC = 'OFFICES_BY_CITY_ID__COUNT_ASC',
  OFFICES_BY_CITY_ID__COUNT_DESC = 'OFFICES_BY_CITY_ID__COUNT_DESC',
  OFFICES_BY_CITY_ID__MAX_CITY_ID_ASC = 'OFFICES_BY_CITY_ID__MAX_CITY_ID_ASC',
  OFFICES_BY_CITY_ID__MAX_CITY_ID_DESC = 'OFFICES_BY_CITY_ID__MAX_CITY_ID_DESC',
  OFFICES_BY_CITY_ID__MAX_DATE_CREATED_ASC = 'OFFICES_BY_CITY_ID__MAX_DATE_CREATED_ASC',
  OFFICES_BY_CITY_ID__MAX_DATE_CREATED_DESC = 'OFFICES_BY_CITY_ID__MAX_DATE_CREATED_DESC',
  OFFICES_BY_CITY_ID__MAX_DATE_UPDATED_ASC = 'OFFICES_BY_CITY_ID__MAX_DATE_UPDATED_ASC',
  OFFICES_BY_CITY_ID__MAX_DATE_UPDATED_DESC = 'OFFICES_BY_CITY_ID__MAX_DATE_UPDATED_DESC',
  OFFICES_BY_CITY_ID__MAX_LATITUDE_ASC = 'OFFICES_BY_CITY_ID__MAX_LATITUDE_ASC',
  OFFICES_BY_CITY_ID__MAX_LATITUDE_DESC = 'OFFICES_BY_CITY_ID__MAX_LATITUDE_DESC',
  OFFICES_BY_CITY_ID__MAX_LONGITUDE_ASC = 'OFFICES_BY_CITY_ID__MAX_LONGITUDE_ASC',
  OFFICES_BY_CITY_ID__MAX_LONGITUDE_DESC = 'OFFICES_BY_CITY_ID__MAX_LONGITUDE_DESC',
  OFFICES_BY_CITY_ID__MAX_NAME_ASC = 'OFFICES_BY_CITY_ID__MAX_NAME_ASC',
  OFFICES_BY_CITY_ID__MAX_NAME_DESC = 'OFFICES_BY_CITY_ID__MAX_NAME_DESC',
  OFFICES_BY_CITY_ID__MAX_OFFICE_ID_ASC = 'OFFICES_BY_CITY_ID__MAX_OFFICE_ID_ASC',
  OFFICES_BY_CITY_ID__MAX_OFFICE_ID_DESC = 'OFFICES_BY_CITY_ID__MAX_OFFICE_ID_DESC',
  OFFICES_BY_CITY_ID__MAX_REMARK_ASC = 'OFFICES_BY_CITY_ID__MAX_REMARK_ASC',
  OFFICES_BY_CITY_ID__MAX_REMARK_DESC = 'OFFICES_BY_CITY_ID__MAX_REMARK_DESC',
  OFFICES_BY_CITY_ID__MAX_USER_CREATED_ASC = 'OFFICES_BY_CITY_ID__MAX_USER_CREATED_ASC',
  OFFICES_BY_CITY_ID__MAX_USER_CREATED_DESC = 'OFFICES_BY_CITY_ID__MAX_USER_CREATED_DESC',
  OFFICES_BY_CITY_ID__MAX_USER_UPDATED_ASC = 'OFFICES_BY_CITY_ID__MAX_USER_UPDATED_ASC',
  OFFICES_BY_CITY_ID__MAX_USER_UPDATED_DESC = 'OFFICES_BY_CITY_ID__MAX_USER_UPDATED_DESC',
  OFFICES_BY_CITY_ID__MIN_CITY_ID_ASC = 'OFFICES_BY_CITY_ID__MIN_CITY_ID_ASC',
  OFFICES_BY_CITY_ID__MIN_CITY_ID_DESC = 'OFFICES_BY_CITY_ID__MIN_CITY_ID_DESC',
  OFFICES_BY_CITY_ID__MIN_DATE_CREATED_ASC = 'OFFICES_BY_CITY_ID__MIN_DATE_CREATED_ASC',
  OFFICES_BY_CITY_ID__MIN_DATE_CREATED_DESC = 'OFFICES_BY_CITY_ID__MIN_DATE_CREATED_DESC',
  OFFICES_BY_CITY_ID__MIN_DATE_UPDATED_ASC = 'OFFICES_BY_CITY_ID__MIN_DATE_UPDATED_ASC',
  OFFICES_BY_CITY_ID__MIN_DATE_UPDATED_DESC = 'OFFICES_BY_CITY_ID__MIN_DATE_UPDATED_DESC',
  OFFICES_BY_CITY_ID__MIN_LATITUDE_ASC = 'OFFICES_BY_CITY_ID__MIN_LATITUDE_ASC',
  OFFICES_BY_CITY_ID__MIN_LATITUDE_DESC = 'OFFICES_BY_CITY_ID__MIN_LATITUDE_DESC',
  OFFICES_BY_CITY_ID__MIN_LONGITUDE_ASC = 'OFFICES_BY_CITY_ID__MIN_LONGITUDE_ASC',
  OFFICES_BY_CITY_ID__MIN_LONGITUDE_DESC = 'OFFICES_BY_CITY_ID__MIN_LONGITUDE_DESC',
  OFFICES_BY_CITY_ID__MIN_NAME_ASC = 'OFFICES_BY_CITY_ID__MIN_NAME_ASC',
  OFFICES_BY_CITY_ID__MIN_NAME_DESC = 'OFFICES_BY_CITY_ID__MIN_NAME_DESC',
  OFFICES_BY_CITY_ID__MIN_OFFICE_ID_ASC = 'OFFICES_BY_CITY_ID__MIN_OFFICE_ID_ASC',
  OFFICES_BY_CITY_ID__MIN_OFFICE_ID_DESC = 'OFFICES_BY_CITY_ID__MIN_OFFICE_ID_DESC',
  OFFICES_BY_CITY_ID__MIN_REMARK_ASC = 'OFFICES_BY_CITY_ID__MIN_REMARK_ASC',
  OFFICES_BY_CITY_ID__MIN_REMARK_DESC = 'OFFICES_BY_CITY_ID__MIN_REMARK_DESC',
  OFFICES_BY_CITY_ID__MIN_USER_CREATED_ASC = 'OFFICES_BY_CITY_ID__MIN_USER_CREATED_ASC',
  OFFICES_BY_CITY_ID__MIN_USER_CREATED_DESC = 'OFFICES_BY_CITY_ID__MIN_USER_CREATED_DESC',
  OFFICES_BY_CITY_ID__MIN_USER_UPDATED_ASC = 'OFFICES_BY_CITY_ID__MIN_USER_UPDATED_ASC',
  OFFICES_BY_CITY_ID__MIN_USER_UPDATED_DESC = 'OFFICES_BY_CITY_ID__MIN_USER_UPDATED_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__COUNT_ASC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__COUNT_ASC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__COUNT_DESC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__COUNT_DESC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_CITY_ID_ASC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_CITY_ID_ASC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_CITY_ID_DESC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_CITY_ID_DESC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_COMPANY_ID_ASC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_COMPANY_ID_ASC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_COMPANY_ID_DESC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_COMPANY_ID_DESC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_DATE_CREATED_ASC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_DATE_CREATED_ASC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_DATE_CREATED_DESC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_DATE_CREATED_DESC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_DATE_UPDATED_ASC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_DATE_UPDATED_ASC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_DATE_UPDATED_DESC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_DATE_UPDATED_DESC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_REMARK_ASC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_REMARK_ASC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_REMARK_DESC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_REMARK_DESC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_USER_CREATED_ASC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_USER_CREATED_ASC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_USER_CREATED_DESC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_USER_CREATED_DESC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_USER_UPDATED_ASC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_USER_UPDATED_ASC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_USER_UPDATED_DESC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MAX_USER_UPDATED_DESC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_CITY_ID_ASC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_CITY_ID_ASC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_CITY_ID_DESC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_CITY_ID_DESC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_COMPANY_ID_ASC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_COMPANY_ID_ASC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_COMPANY_ID_DESC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_COMPANY_ID_DESC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_DATE_CREATED_ASC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_DATE_CREATED_ASC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_DATE_CREATED_DESC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_DATE_CREATED_DESC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_DATE_UPDATED_ASC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_DATE_UPDATED_ASC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_DATE_UPDATED_DESC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_DATE_UPDATED_DESC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_REMARK_ASC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_REMARK_ASC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_REMARK_DESC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_REMARK_DESC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_USER_CREATED_ASC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_USER_CREATED_ASC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_USER_CREATED_DESC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_USER_CREATED_DESC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_USER_UPDATED_ASC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_USER_UPDATED_ASC',
  RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_USER_UPDATED_DESC = 'RELATION_CITY_COMPANIES_BY_CITY_ID__MIN_USER_UPDATED_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
  UTC_OFFSET_ASC = 'UTC_OFFSET_ASC',
  UTC_OFFSET_DESC = 'UTC_OFFSET_DESC',
}

export interface City extends Node {
  __typename?: 'City';
  cityId: Scalars['BigInt']['output'];
  countryByCountryCodeAlpha2?: Maybe<Country>;
  countryCodeAlpha2?: Maybe<Scalars['String']['output']>;
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  employeeCount?: Maybe<Scalars['Int']['output']>;
  employeeCurrentPositions: EmployeeCurrentPositionsConnection;
  name: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  offices: OfficesConnection;
  relationCityCompanies: RelationCityCompaniesConnection;
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
  utcOffset?: Maybe<Interval>;
}

export type CityEmployeeCurrentPositionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCurrentPositionCondition>;
  filter?: InputMaybe<EmployeeCurrentPositionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeCurrentPositionsOrderBy>>;
};

export type CityOfficesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<OfficeCondition>;
  filter?: InputMaybe<OfficeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OfficesOrderBy>>;
};

export type CityRelationCityCompaniesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationCityCompanyCondition>;
  filter?: InputMaybe<RelationCityCompanyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationCityCompaniesOrderBy>>;
};

export interface CityCityCountryCodeAlpha2NameKeyConnect {
  countryCodeAlpha2: Scalars['String']['input'];
  name: Scalars['String']['input'];
}

export interface CityCityCountryCodeAlpha2NameKeyDelete {
  countryCodeAlpha2: Scalars['String']['input'];
  name: Scalars['String']['input'];
}

export interface CityCityPkeyConnect {
  cityId: Scalars['BigInt']['input'];
}

export interface CityCityPkeyDelete {
  cityId: Scalars['BigInt']['input'];
}

export interface CityCondition {
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  utcOffset?: InputMaybe<IntervalInput>;
}

export interface CityCountryCodeAlpha2FkeyCityCreateInput {
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<CityCountryCodeAlpha2FkeyInput>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey6InverseInput>;
  name: Scalars['String']['input'];
  offices?: InputMaybe<OfficeCityIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCityIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CityUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  utcOffset?: InputMaybe<IntervalInput>;
}

export interface CityCountryCodeAlpha2FkeyCountryCreateInput {
  banks?: InputMaybe<BankCountryCodeAlpha3FkeyInverseInput>;
  cities?: InputMaybe<CityCountryCodeAlpha2FkeyInverseInput>;
  companies?: InputMaybe<CompanyCountryCodeAlpha2FkeyInverseInput>;
  countryCodeAlpha3: Scalars['String']['input'];
  countryCodeNumeric?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInverseInput>;
  employeeIdTypes?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInverseInput>;
  employeesToMobileNumberCountryIdUsingCountryCodeAlpha2?: InputMaybe<EmployeeMobileNumberCountryIdFkeyInverseInput>;
  employeesToNationalityUsingCountryCodeAlpha3?: InputMaybe<EmployeeNationalityFkeyInverseInput>;
  employeesToWorkingLocationCountryCodeAlpha2UsingCountryCodeAlpha2?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0InverseInput>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName: Scalars['String']['input'];
  telephoneCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CityCountryCodeAlpha2FkeyInput {
  connectByCountryCodeAlpha2?: InputMaybe<CountryCountryPkeyConnect>;
  connectByCountryCodeAlpha3?: InputMaybe<CountryCountryCountryCodeAlpha3KeyConnect>;
  connectByCountryCodeNumeric?: InputMaybe<CountryCountryCountryCodeNumericKeyConnect>;
  connectByDisplayOrder?: InputMaybe<CountryCountryDisplayOrderKeyConnect>;
  connectByNodeId?: InputMaybe<CountryNodeIdConnect>;
  create?: InputMaybe<CityCountryCodeAlpha2FkeyCountryCreateInput>;
  deleteByCountryCodeAlpha2?: InputMaybe<CountryCountryPkeyDelete>;
  deleteByCountryCodeAlpha3?: InputMaybe<CountryCountryCountryCodeAlpha3KeyDelete>;
  deleteByCountryCodeNumeric?: InputMaybe<CountryCountryCountryCodeNumericKeyDelete>;
  deleteByDisplayOrder?: InputMaybe<CountryCountryDisplayOrderKeyDelete>;
  deleteByNodeId?: InputMaybe<CountryNodeIdDelete>;
  updateByCountryCodeAlpha2?: InputMaybe<CountryOnCityForCityCountryCodeAlpha2FkeyUsingCountryPkeyUpdate>;
  updateByCountryCodeAlpha3?: InputMaybe<CountryOnCityForCityCountryCodeAlpha2FkeyUsingCountryCountryCodeAlpha3KeyUpdate>;
  updateByCountryCodeNumeric?: InputMaybe<CountryOnCityForCityCountryCodeAlpha2FkeyUsingCountryCountryCodeNumericKeyUpdate>;
  updateByDisplayOrder?: InputMaybe<CountryOnCityForCityCountryCodeAlpha2FkeyUsingCountryDisplayOrderKeyUpdate>;
  updateByNodeId?: InputMaybe<CityOnCityForCityCountryCodeAlpha2FkeyNodeIdUpdate>;
}

export interface CityCountryCodeAlpha2FkeyInverseInput {
  connectByCityId?: InputMaybe<Array<CityCityPkeyConnect>>;
  connectByCountryCodeAlpha2AndName?: InputMaybe<Array<CityCityCountryCodeAlpha2NameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<CityNodeIdConnect>>;
  create?: InputMaybe<Array<CityCountryCodeAlpha2FkeyCityCreateInput>>;
  deleteByCityId?: InputMaybe<Array<CityCityPkeyDelete>>;
  deleteByCountryCodeAlpha2AndName?: InputMaybe<Array<CityCityCountryCodeAlpha2NameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<CityNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCityId?: InputMaybe<Array<CityOnCityForCityCountryCodeAlpha2FkeyUsingCityPkeyUpdate>>;
  updateByCountryCodeAlpha2AndName?: InputMaybe<Array<CityOnCityForCityCountryCodeAlpha2FkeyUsingCityCountryCodeAlpha2NameKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<CountryOnCityForCityCountryCodeAlpha2FkeyNodeIdUpdate>>;
}

export interface CityFilter {
  and?: InputMaybe<Array<CityFilter>>;
  cityId?: InputMaybe<BigIntFilter>;
  countryByCountryCodeAlpha2?: InputMaybe<CountryFilter>;
  countryByCountryCodeAlpha2Exists?: InputMaybe<Scalars['Boolean']['input']>;
  countryCodeAlpha2?: InputMaybe<StringFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  employeeCount?: InputMaybe<IntFilter>;
  employeeCurrentPositions?: InputMaybe<CityToManyEmployeeCurrentPositionFilter>;
  employeeCurrentPositionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<StringFilter>;
  not?: InputMaybe<CityFilter>;
  offices?: InputMaybe<CityToManyOfficeFilter>;
  officesExist?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<CityFilter>>;
  relationCityCompanies?: InputMaybe<CityToManyRelationCityCompanyFilter>;
  relationCityCompaniesExist?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
  utcOffset?: InputMaybe<IntervalFilter>;
}

export interface CityInput {
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<CityCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey6InverseInput>;
  name: Scalars['String']['input'];
  offices?: InputMaybe<OfficeCityIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCityIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CityUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  utcOffset?: InputMaybe<IntervalInput>;
}

export interface CityNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface CityNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface CityOnCityForCityCountryCodeAlpha2FkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CountryPatch;
}

export interface CityOnCityForCityCountryCodeAlpha2FkeyUsingCityCountryCodeAlpha2NameKeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  name: Scalars['String']['input'];
  patch: UpdateCityOnCityForCityCountryCodeAlpha2FkeyPatch;
}

export interface CityOnCityForCityCountryCodeAlpha2FkeyUsingCityPkeyUpdate {
  cityId: Scalars['BigInt']['input'];
  patch: UpdateCityOnCityForCityCountryCodeAlpha2FkeyPatch;
}

export interface CityOnCityForCityUserCreatedFkeyUsingCityCountryCodeAlpha2NameKeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  name: Scalars['String']['input'];
  patch: UpdateCityOnCityForCityUserCreatedFkeyPatch;
}

export interface CityOnCityForCityUserCreatedFkeyUsingCityPkeyUpdate {
  cityId: Scalars['BigInt']['input'];
  patch: UpdateCityOnCityForCityUserCreatedFkeyPatch;
}

export interface CityOnCityForCityUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface CityOnCityForCityUserUpdatedFkeyUsingCityCountryCodeAlpha2NameKeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  name: Scalars['String']['input'];
  patch: UpdateCityOnCityForCityUserUpdatedFkeyPatch;
}

export interface CityOnCityForCityUserUpdatedFkeyUsingCityPkeyUpdate {
  cityId: Scalars['BigInt']['input'];
  patch: UpdateCityOnCityForCityUserUpdatedFkeyPatch;
}

export interface CityOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey6NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeCurrentPositionPatch;
}

export interface CityOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey6UsingCityCountryCodeAlpha2NameKeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  name: Scalars['String']['input'];
  patch: UpdateCityOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey6Patch;
}

export interface CityOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey6UsingCityPkeyUpdate {
  cityId: Scalars['BigInt']['input'];
  patch: UpdateCityOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey6Patch;
}

export interface CityOnOfficeForOfficeCityIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: OfficePatch;
}

export interface CityOnOfficeForOfficeCityIdFkeyUsingCityCountryCodeAlpha2NameKeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  name: Scalars['String']['input'];
  patch: UpdateCityOnOfficeForOfficeCityIdFkeyPatch;
}

export interface CityOnOfficeForOfficeCityIdFkeyUsingCityPkeyUpdate {
  cityId: Scalars['BigInt']['input'];
  patch: UpdateCityOnOfficeForOfficeCityIdFkeyPatch;
}

export interface CityOnRelationCityCompanyForRelationCityCompanyCityIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationCityCompanyPatch;
}

export interface CityOnRelationCityCompanyForRelationCityCompanyCityIdFkeyUsingCityCountryCodeAlpha2NameKeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  name: Scalars['String']['input'];
  patch: UpdateCityOnRelationCityCompanyForRelationCityCompanyCityIdFkeyPatch;
}

export interface CityOnRelationCityCompanyForRelationCityCompanyCityIdFkeyUsingCityPkeyUpdate {
  cityId: Scalars['BigInt']['input'];
  patch: UpdateCityOnRelationCityCompanyForRelationCityCompanyCityIdFkeyPatch;
}

export interface CityPatch {
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<CityCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey6InverseInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  offices?: InputMaybe<OfficeCityIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCityIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CityUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  utcOffset?: InputMaybe<IntervalInput>;
}

export interface CityToManyEmployeeCurrentPositionFilter {
  every?: InputMaybe<EmployeeCurrentPositionFilter>;
  none?: InputMaybe<EmployeeCurrentPositionFilter>;
  some?: InputMaybe<EmployeeCurrentPositionFilter>;
}

export interface CityToManyOfficeFilter {
  every?: InputMaybe<OfficeFilter>;
  none?: InputMaybe<OfficeFilter>;
  some?: InputMaybe<OfficeFilter>;
}

export interface CityToManyRelationCityCompanyFilter {
  every?: InputMaybe<RelationCityCompanyFilter>;
  none?: InputMaybe<RelationCityCompanyFilter>;
  some?: InputMaybe<RelationCityCompanyFilter>;
}

export interface CityUserCreatedFkeyInverseInput {
  connectByCityId?: InputMaybe<Array<CityCityPkeyConnect>>;
  connectByCountryCodeAlpha2AndName?: InputMaybe<Array<CityCityCountryCodeAlpha2NameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<CityNodeIdConnect>>;
  deleteByCityId?: InputMaybe<Array<CityCityPkeyDelete>>;
  deleteByCountryCodeAlpha2AndName?: InputMaybe<Array<CityCityCountryCodeAlpha2NameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<CityNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCityId?: InputMaybe<Array<CityOnCityForCityUserCreatedFkeyUsingCityPkeyUpdate>>;
  updateByCountryCodeAlpha2AndName?: InputMaybe<Array<CityOnCityForCityUserCreatedFkeyUsingCityCountryCodeAlpha2NameKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnCityForCityUserCreatedFkeyNodeIdUpdate>>;
}

export interface CityUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnCityForCityUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<CityOnCityForCityUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnCityForCityUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface CityUserUpdatedFkeyInverseInput {
  connectByCityId?: InputMaybe<Array<CityCityPkeyConnect>>;
  connectByCountryCodeAlpha2AndName?: InputMaybe<Array<CityCityCountryCodeAlpha2NameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<CityNodeIdConnect>>;
  deleteByCityId?: InputMaybe<Array<CityCityPkeyDelete>>;
  deleteByCountryCodeAlpha2AndName?: InputMaybe<Array<CityCityCountryCodeAlpha2NameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<CityNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCityId?: InputMaybe<Array<CityOnCityForCityUserUpdatedFkeyUsingCityPkeyUpdate>>;
  updateByCountryCodeAlpha2AndName?: InputMaybe<Array<CityOnCityForCityUserUpdatedFkeyUsingCityCountryCodeAlpha2NameKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnCityForCityUserUpdatedFkeyNodeIdUpdate>>;
}

export interface CompaniesConnection {
  __typename?: 'CompaniesConnection';
  aggregates?: Maybe<CompanyAggregates>;
  edges: Array<CompaniesEdge>;
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
  nodes: Array<Company>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export type CompaniesConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: InputMaybe<CompanyHavingInput>;
};

export interface CompaniesEdge {
  __typename?: 'CompaniesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: Company;
}

export enum CompaniesOrderBy {
  ABBREVIATION_ASC = 'ABBREVIATION_ASC',
  ABBREVIATION_DESC = 'ABBREVIATION_DESC',
  COMPANY_CLOSED_DATE_ASC = 'COMPANY_CLOSED_DATE_ASC',
  COMPANY_CLOSED_DATE_DESC = 'COMPANY_CLOSED_DATE_DESC',
  COMPANY_ESTABLISHED_DATE_ASC = 'COMPANY_ESTABLISHED_DATE_ASC',
  COMPANY_ESTABLISHED_DATE_DESC = 'COMPANY_ESTABLISHED_DATE_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__COUNT_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__COUNT_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__COUNT_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__COUNT_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MAX_COMPANY_ID_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MAX_COMPANY_ID_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MAX_COMPANY_ID_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MAX_COMPANY_ID_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MAX_DATE_CREATED_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MAX_DATE_CREATED_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MAX_DATE_CREATED_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MAX_DATE_CREATED_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MAX_REMARK_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MAX_REMARK_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MAX_REMARK_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MAX_REMARK_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MAX_USER_CREATED_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MAX_USER_CREATED_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MAX_USER_CREATED_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MAX_USER_CREATED_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MIN_COMPANY_ID_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MIN_COMPANY_ID_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MIN_COMPANY_ID_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MIN_COMPANY_ID_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MIN_DATE_CREATED_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MIN_DATE_CREATED_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MIN_DATE_CREATED_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MIN_DATE_CREATED_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MIN_REMARK_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MIN_REMARK_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MIN_REMARK_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MIN_REMARK_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MIN_USER_CREATED_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MIN_USER_CREATED_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MIN_USER_CREATED_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_COMPANY_ID__MIN_USER_CREATED_DESC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__COMPANY_GROUP_ID_ASC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__COMPANY_GROUP_ID_ASC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__COMPANY_GROUP_ID_DESC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__COMPANY_GROUP_ID_DESC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__DATE_CREATED_ASC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__DATE_CREATED_ASC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__DATE_CREATED_DESC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__DATE_CREATED_DESC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__DATE_UPDATED_ASC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__DATE_UPDATED_ASC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__DATE_UPDATED_DESC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__DATE_UPDATED_DESC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__NAME_ASC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__NAME_ASC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__NAME_DESC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__NAME_DESC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__REMARK_ASC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__REMARK_ASC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__REMARK_DESC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__REMARK_DESC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__USER_CREATED_ASC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__USER_CREATED_ASC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__USER_CREATED_DESC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__USER_CREATED_DESC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__USER_UPDATED_ASC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__USER_UPDATED_ASC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__USER_UPDATED_DESC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__USER_UPDATED_DESC',
  COMPANY_GROUP_ID_ASC = 'COMPANY_GROUP_ID_ASC',
  COMPANY_GROUP_ID_DESC = 'COMPANY_GROUP_ID_DESC',
  COMPANY_ID_ASC = 'COMPANY_ID_ASC',
  COMPANY_ID_DESC = 'COMPANY_ID_DESC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__COUNT_ASC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__COUNT_ASC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__COUNT_DESC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__COUNT_DESC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_COMPANY_ID_ASC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_COMPANY_ID_ASC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_COMPANY_ID_DESC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_COMPANY_ID_DESC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_COMPANY_LOCAL_HR_ID_ASC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_COMPANY_LOCAL_HR_ID_ASC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_COMPANY_LOCAL_HR_ID_DESC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_COMPANY_LOCAL_HR_ID_DESC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_DATE_CREATED_ASC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_DATE_CREATED_ASC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_DATE_CREATED_DESC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_DATE_CREATED_DESC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_DATE_UPDATED_ASC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_DATE_UPDATED_ASC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_DATE_UPDATED_DESC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_DATE_UPDATED_DESC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_EMPLOYEE_ID_ASC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_EMPLOYEE_ID_ASC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_EMPLOYEE_ID_DESC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_EMPLOYEE_ID_DESC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_REMARK_ASC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_REMARK_ASC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_REMARK_DESC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_REMARK_DESC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_USER_CREATED_ASC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_USER_CREATED_ASC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_USER_CREATED_DESC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_USER_CREATED_DESC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_USER_UPDATED_ASC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_USER_UPDATED_ASC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_USER_UPDATED_DESC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MAX_USER_UPDATED_DESC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_COMPANY_ID_ASC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_COMPANY_ID_ASC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_COMPANY_ID_DESC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_COMPANY_ID_DESC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_COMPANY_LOCAL_HR_ID_ASC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_COMPANY_LOCAL_HR_ID_ASC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_COMPANY_LOCAL_HR_ID_DESC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_COMPANY_LOCAL_HR_ID_DESC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_DATE_CREATED_ASC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_DATE_CREATED_ASC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_DATE_CREATED_DESC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_DATE_CREATED_DESC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_DATE_UPDATED_ASC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_DATE_UPDATED_ASC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_DATE_UPDATED_DESC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_DATE_UPDATED_DESC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_EMPLOYEE_ID_ASC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_EMPLOYEE_ID_ASC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_EMPLOYEE_ID_DESC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_EMPLOYEE_ID_DESC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_REMARK_ASC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_REMARK_ASC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_REMARK_DESC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_REMARK_DESC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_USER_CREATED_ASC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_USER_CREATED_ASC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_USER_CREATED_DESC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_USER_CREATED_DESC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_USER_UPDATED_ASC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_USER_UPDATED_ASC',
  COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_USER_UPDATED_DESC = 'COMPANY_LOCAL_HRS_BY_COMPANY_ID__MIN_USER_UPDATED_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_CREATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_CREATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_CREATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_CREATED_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_UPDATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_UPDATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_UPDATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_UPDATED_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__REMARK_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__REMARK_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__REMARK_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__REMARK_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__SHORT_NAME_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__SHORT_NAME_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__SHORT_NAME_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__SHORT_NAME_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_CREATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_CREATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_CREATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_CREATED_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_UPDATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_UPDATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_UPDATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_UPDATED_DESC',
  COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_CODE_ALPHA2_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_CODE_PREFIX_ASC = 'EMPLOYEE_CODE_PREFIX_ASC',
  EMPLOYEE_CODE_PREFIX_DESC = 'EMPLOYEE_CODE_PREFIX_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__COUNT_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__COUNT_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__COUNT_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__COUNT_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_CITY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_CITY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_CITY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_CITY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_COMPANY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_COMPANY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_COMPANY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_COMPANY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_DEPARTMENT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_DEPARTMENT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_DEPARTMENT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_DEPARTMENT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_DIVISION_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_DIVISION_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_DIVISION_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_DIVISION_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_EFFECTIVE_DATE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_EFFECTIVE_DATE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_EFFECTIVE_DATE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_EFFECTIVE_DATE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_EMPLOYEE_CODE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_EMPLOYEE_CODE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_EMPLOYEE_CODE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_EMPLOYEE_CODE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_JOB_TITLE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_JOB_TITLE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_JOB_TITLE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_JOB_TITLE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_OFFICE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_OFFICE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_OFFICE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_OFFICE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_TEAM_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_TEAM_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_TEAM_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MAX_TEAM_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_CITY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_CITY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_CITY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_CITY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_COMPANY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_COMPANY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_COMPANY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_COMPANY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_DEPARTMENT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_DEPARTMENT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_DEPARTMENT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_DEPARTMENT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_DIVISION_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_DIVISION_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_DIVISION_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_DIVISION_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_EFFECTIVE_DATE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_EFFECTIVE_DATE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_EFFECTIVE_DATE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_EFFECTIVE_DATE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_EMPLOYEE_CODE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_EMPLOYEE_CODE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_EMPLOYEE_CODE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_EMPLOYEE_CODE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_JOB_TITLE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_JOB_TITLE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_JOB_TITLE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_JOB_TITLE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_OFFICE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_OFFICE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_OFFICE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_OFFICE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_TEAM_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_TEAM_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_TEAM_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COMPANY_ID__MIN_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__COUNT_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__COUNT_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__COUNT_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__COUNT_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_COMPANY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_COMPANY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_COMPANY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_COMPANY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_DATE_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_DATE_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_DATE_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_DATE_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_DEPARTMENT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_DEPARTMENT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_DEPARTMENT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_DEPARTMENT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_DIVISION_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_DIVISION_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_DIVISION_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_DIVISION_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_EFFECTIVE_DATE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_EFFECTIVE_DATE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_EFFECTIVE_DATE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_EFFECTIVE_DATE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_JOB_TITLE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_JOB_TITLE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_JOB_TITLE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_JOB_TITLE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_OFFICE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_OFFICE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_OFFICE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_OFFICE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_REMARK_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_REMARK_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_REMARK_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_REMARK_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_TEAM_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_TEAM_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_TEAM_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_USER_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_USER_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_USER_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_USER_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_USER_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_USER_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_USER_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MAX_USER_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_COMPANY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_COMPANY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_COMPANY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_COMPANY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_DATE_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_DATE_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_DATE_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_DATE_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_DEPARTMENT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_DEPARTMENT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_DEPARTMENT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_DEPARTMENT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_DIVISION_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_DIVISION_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_DIVISION_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_DIVISION_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_EFFECTIVE_DATE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_EFFECTIVE_DATE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_EFFECTIVE_DATE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_EFFECTIVE_DATE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_JOB_TITLE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_JOB_TITLE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_JOB_TITLE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_JOB_TITLE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_OFFICE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_OFFICE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_OFFICE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_OFFICE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_REMARK_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_REMARK_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_REMARK_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_REMARK_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_TEAM_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_TEAM_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_TEAM_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_USER_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_USER_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_USER_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_USER_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_USER_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_USER_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_USER_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_COMPANY_ID__MIN_USER_UPDATED_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__COUNT_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__COUNT_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__COUNT_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__COUNT_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_ALL_DATES_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_ALL_DATES_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_ALL_DATES_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_ALL_DATES_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_BASE_DATE_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_BASE_DATE_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_BASE_DATE_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_BASE_DATE_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_COMPANY_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_COMPANY_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_COMPANY_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_COMPANY_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_COMPANY_EMAIL_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_COMPANY_EMAIL_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_COMPANY_EMAIL_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_COMPANY_EMAIL_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_COMPANY_ID_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_COMPANY_ID_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_COMPANY_ID_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_COMPANY_ID_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_RANK_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_RANK_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_RANK_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_RANK_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_STATUS_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_STATUS_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_STATUS_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_STATUS_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_WORKING_DAYS_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_WORKING_DAYS_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_WORKING_DAYS_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MAX_WORKING_DAYS_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_ALL_DATES_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_ALL_DATES_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_ALL_DATES_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_ALL_DATES_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_BASE_DATE_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_BASE_DATE_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_BASE_DATE_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_BASE_DATE_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_COMPANY_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_COMPANY_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_COMPANY_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_COMPANY_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_COMPANY_EMAIL_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_COMPANY_EMAIL_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_COMPANY_EMAIL_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_COMPANY_EMAIL_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_COMPANY_ID_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_COMPANY_ID_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_COMPANY_ID_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_COMPANY_ID_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_RANK_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_RANK_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_RANK_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_RANK_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_STATUS_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_STATUS_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_STATUS_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_STATUS_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_WORKING_DAYS_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_WORKING_DAYS_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_WORKING_DAYS_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_COMPANY_ID__MIN_WORKING_DAYS_DESC',
  FULL_NAME_ASC = 'FULL_NAME_ASC',
  FULL_NAME_DESC = 'FULL_NAME_DESC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__COUNT_ASC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__COUNT_ASC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__COUNT_DESC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__COUNT_DESC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MAX_BUSINESS_UNIT_ID_ASC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MAX_BUSINESS_UNIT_ID_ASC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MAX_BUSINESS_UNIT_ID_DESC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MAX_BUSINESS_UNIT_ID_DESC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MAX_COMPANY_ID_ASC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MAX_COMPANY_ID_ASC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MAX_COMPANY_ID_DESC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MAX_COMPANY_ID_DESC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MAX_GOOGLE_WORKSPACE_ORG_UNIT_ID_ASC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MAX_GOOGLE_WORKSPACE_ORG_UNIT_ID_ASC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MAX_GOOGLE_WORKSPACE_ORG_UNIT_ID_DESC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MAX_GOOGLE_WORKSPACE_ORG_UNIT_ID_DESC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MAX_ORG_UNIT_PATH_ASC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MAX_ORG_UNIT_PATH_ASC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MAX_ORG_UNIT_PATH_DESC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MAX_ORG_UNIT_PATH_DESC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MAX_PRIORITY_ASC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MAX_PRIORITY_ASC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MAX_PRIORITY_DESC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MAX_PRIORITY_DESC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MIN_BUSINESS_UNIT_ID_ASC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MIN_BUSINESS_UNIT_ID_ASC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MIN_BUSINESS_UNIT_ID_DESC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MIN_BUSINESS_UNIT_ID_DESC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MIN_COMPANY_ID_ASC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MIN_COMPANY_ID_ASC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MIN_COMPANY_ID_DESC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MIN_COMPANY_ID_DESC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MIN_GOOGLE_WORKSPACE_ORG_UNIT_ID_ASC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MIN_GOOGLE_WORKSPACE_ORG_UNIT_ID_ASC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MIN_GOOGLE_WORKSPACE_ORG_UNIT_ID_DESC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MIN_GOOGLE_WORKSPACE_ORG_UNIT_ID_DESC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MIN_ORG_UNIT_PATH_ASC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MIN_ORG_UNIT_PATH_ASC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MIN_ORG_UNIT_PATH_DESC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MIN_ORG_UNIT_PATH_DESC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MIN_PRIORITY_ASC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MIN_PRIORITY_ASC',
  GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MIN_PRIORITY_DESC = 'GOOGLE_WORKSPACE_ORG_UNITS_BY_COMPANY_ID__MIN_PRIORITY_DESC',
  NATURAL = 'NATURAL',
  NOTIFY_PERIOD_CONTRACT_END_ASC = 'NOTIFY_PERIOD_CONTRACT_END_ASC',
  NOTIFY_PERIOD_CONTRACT_END_DESC = 'NOTIFY_PERIOD_CONTRACT_END_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_ASSESSMENT_COMPLETED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_ASSESSMENT_COMPLETED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_ASSESSMENT_COMPLETED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_ASSESSMENT_COMPLETED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_COMPANY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_COMPANY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_COMPANY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_COMPANY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_NEED_TO_BE_ASSESSED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_NEED_TO_BE_ASSESSED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_NEED_TO_BE_ASSESSED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_NEED_TO_BE_ASSESSED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_NEED_TO_SET_TARGET_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_NEED_TO_SET_TARGET_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_NEED_TO_SET_TARGET_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_NEED_TO_SET_TARGET_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_POSSIBLE_TO_LOCK_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_POSSIBLE_TO_LOCK_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_POSSIBLE_TO_LOCK_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_POSSIBLE_TO_LOCK_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_POSSIBLE_TO_LOCK_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_POSSIBLE_TO_LOCK_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_POSSIBLE_TO_LOCK_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_POSSIBLE_TO_LOCK_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_POSSIBLE_TO_UNLOCK_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_POSSIBLE_TO_UNLOCK_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_POSSIBLE_TO_UNLOCK_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_POSSIBLE_TO_UNLOCK_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_POSSIBLE_TO_UNLOCK_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_POSSIBLE_TO_UNLOCK_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_POSSIBLE_TO_UNLOCK_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_POSSIBLE_TO_UNLOCK_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_ASSESSMENT_COMPLETED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_ASSESSMENT_COMPLETED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_ASSESSMENT_COMPLETED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_ASSESSMENT_COMPLETED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_COMPANY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_COMPANY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_COMPANY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_COMPANY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_NEED_TO_BE_ASSESSED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_NEED_TO_BE_ASSESSED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_NEED_TO_BE_ASSESSED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_NEED_TO_BE_ASSESSED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_NEED_TO_SET_TARGET_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_NEED_TO_SET_TARGET_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_NEED_TO_SET_TARGET_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_NEED_TO_SET_TARGET_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_POSSIBLE_TO_LOCK_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_POSSIBLE_TO_LOCK_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_POSSIBLE_TO_LOCK_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_POSSIBLE_TO_LOCK_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_POSSIBLE_TO_LOCK_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_POSSIBLE_TO_LOCK_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_POSSIBLE_TO_LOCK_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_POSSIBLE_TO_LOCK_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_POSSIBLE_TO_UNLOCK_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_POSSIBLE_TO_UNLOCK_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_POSSIBLE_TO_UNLOCK_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_POSSIBLE_TO_UNLOCK_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_POSSIBLE_TO_UNLOCK_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_POSSIBLE_TO_UNLOCK_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_POSSIBLE_TO_UNLOCK_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_POSSIBLE_TO_UNLOCK_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COUNTS_BY_COMPANY_ID__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_COMPANY_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_COMPANY_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_COMPANY_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_COMPANY_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_PERFORMANCE_REVIEW_MASTER_USAGE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_PERFORMANCE_REVIEW_MASTER_USAGE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_PERFORMANCE_REVIEW_MASTER_USAGE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_PERFORMANCE_REVIEW_MASTER_USAGE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_USE_PERFORMANCE_REVIEW_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_USE_PERFORMANCE_REVIEW_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_USE_PERFORMANCE_REVIEW_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_USE_PERFORMANCE_REVIEW_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_COMPANY_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_COMPANY_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_COMPANY_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_COMPANY_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_PERFORMANCE_REVIEW_MASTER_USAGE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_PERFORMANCE_REVIEW_MASTER_USAGE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_PERFORMANCE_REVIEW_MASTER_USAGE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_PERFORMANCE_REVIEW_MASTER_USAGE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_USE_PERFORMANCE_REVIEW_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_USE_PERFORMANCE_REVIEW_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_USE_PERFORMANCE_REVIEW_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_USE_PERFORMANCE_REVIEW_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_COMPANY_ID__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_COMPANY_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_COMPANY_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_COMPANY_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_COMPANY_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_NAME_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_NAME_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_NAME_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_NAME_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_COMPANY_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_COMPANY_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_COMPANY_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_COMPANY_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_NAME_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_NAME_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_NAME_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_NAME_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_COMPANY_ID__MIN_YEAR_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__COUNT_ASC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__COUNT_ASC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__COUNT_DESC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__COUNT_DESC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_COMPANY_ID_ASC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_COMPANY_ID_ASC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_COMPANY_ID_DESC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_COMPANY_ID_DESC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_CONTRACT_TYPE_ID_ASC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_CONTRACT_TYPE_ID_ASC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_CONTRACT_TYPE_ID_DESC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_CONTRACT_TYPE_ID_DESC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_DEFAULT_PROBATION_PERIOD_ASC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_DEFAULT_PROBATION_PERIOD_ASC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_DEFAULT_PROBATION_PERIOD_DESC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_DEFAULT_PROBATION_PERIOD_DESC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_ENABLED_ASC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_ENABLED_ASC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_ENABLED_DESC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_ENABLED_DESC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_PROBATION_DATE_INTERVAL_ASC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_PROBATION_DATE_INTERVAL_ASC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_PROBATION_DATE_INTERVAL_DESC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_PROBATION_DATE_INTERVAL_DESC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_PROBATION_REVIEW_ENABLE_ID_ASC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_PROBATION_REVIEW_ENABLE_ID_ASC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_PROBATION_REVIEW_ENABLE_ID_DESC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_PROBATION_REVIEW_ENABLE_ID_DESC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_REMARK_ASC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_REMARK_ASC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_REMARK_DESC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_REMARK_DESC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_COMPANY_ID_ASC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_COMPANY_ID_ASC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_COMPANY_ID_DESC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_COMPANY_ID_DESC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_CONTRACT_TYPE_ID_ASC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_CONTRACT_TYPE_ID_ASC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_CONTRACT_TYPE_ID_DESC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_CONTRACT_TYPE_ID_DESC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_DEFAULT_PROBATION_PERIOD_ASC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_DEFAULT_PROBATION_PERIOD_ASC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_DEFAULT_PROBATION_PERIOD_DESC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_DEFAULT_PROBATION_PERIOD_DESC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_ENABLED_ASC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_ENABLED_ASC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_ENABLED_DESC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_ENABLED_DESC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_PROBATION_DATE_INTERVAL_ASC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_PROBATION_DATE_INTERVAL_ASC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_PROBATION_DATE_INTERVAL_DESC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_PROBATION_DATE_INTERVAL_DESC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_PROBATION_REVIEW_ENABLE_ID_ASC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_PROBATION_REVIEW_ENABLE_ID_ASC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_PROBATION_REVIEW_ENABLE_ID_DESC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_PROBATION_REVIEW_ENABLE_ID_DESC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_REMARK_ASC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_REMARK_ASC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_REMARK_DESC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_REMARK_DESC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_COMPANY_ID__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__COUNT_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__COUNT_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__COUNT_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__COUNT_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_COMPANY_ID_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_COMPANY_ID_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_COMPANY_ID_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_COMPANY_ID_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_CONTRACT_TYPE_ID_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_CONTRACT_TYPE_ID_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_CONTRACT_TYPE_ID_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_CONTRACT_TYPE_ID_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_COUNTRY_CODE_ALPHA2_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_COUNTRY_CODE_ALPHA2_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_COUNTRY_CODE_ALPHA2_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_COUNTRY_CODE_ALPHA2_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_DEFAULT_PROBATION_PERIOD_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_DEFAULT_PROBATION_PERIOD_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_DEFAULT_PROBATION_PERIOD_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_DEFAULT_PROBATION_PERIOD_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_ENABLED_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_ENABLED_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_ENABLED_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_ENABLED_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_PROBATION_DATE_INTERVAL_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_PROBATION_DATE_INTERVAL_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_PROBATION_DATE_INTERVAL_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_PROBATION_DATE_INTERVAL_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_PROBATION_REVIEW_ENABLE_ID_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_PROBATION_REVIEW_ENABLE_ID_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_PROBATION_REVIEW_ENABLE_ID_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MAX_PROBATION_REVIEW_ENABLE_ID_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_COMPANY_ID_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_COMPANY_ID_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_COMPANY_ID_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_COMPANY_ID_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_CONTRACT_TYPE_ID_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_CONTRACT_TYPE_ID_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_CONTRACT_TYPE_ID_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_CONTRACT_TYPE_ID_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_COUNTRY_CODE_ALPHA2_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_COUNTRY_CODE_ALPHA2_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_COUNTRY_CODE_ALPHA2_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_COUNTRY_CODE_ALPHA2_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_DEFAULT_PROBATION_PERIOD_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_DEFAULT_PROBATION_PERIOD_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_DEFAULT_PROBATION_PERIOD_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_DEFAULT_PROBATION_PERIOD_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_ENABLED_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_ENABLED_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_ENABLED_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_ENABLED_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_PROBATION_DATE_INTERVAL_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_PROBATION_DATE_INTERVAL_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_PROBATION_DATE_INTERVAL_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_PROBATION_DATE_INTERVAL_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_PROBATION_REVIEW_ENABLE_ID_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_PROBATION_REVIEW_ENABLE_ID_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_PROBATION_REVIEW_ENABLE_ID_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COMPANY_ID__MIN_PROBATION_REVIEW_ENABLE_ID_DESC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__COUNT_ASC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__COUNT_ASC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__COUNT_DESC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__COUNT_DESC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_CITY_ID_ASC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_CITY_ID_ASC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_CITY_ID_DESC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_CITY_ID_DESC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_COMPANY_ID_ASC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_COMPANY_ID_ASC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_COMPANY_ID_DESC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_COMPANY_ID_DESC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_DATE_CREATED_ASC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_DATE_CREATED_ASC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_DATE_CREATED_DESC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_DATE_CREATED_DESC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_DATE_UPDATED_ASC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_DATE_UPDATED_ASC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_DATE_UPDATED_DESC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_DATE_UPDATED_DESC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_REMARK_ASC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_REMARK_ASC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_REMARK_DESC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_REMARK_DESC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_USER_CREATED_ASC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_USER_CREATED_ASC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_USER_CREATED_DESC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_USER_CREATED_DESC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_USER_UPDATED_ASC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_USER_UPDATED_ASC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_USER_UPDATED_DESC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MAX_USER_UPDATED_DESC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_CITY_ID_ASC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_CITY_ID_ASC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_CITY_ID_DESC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_CITY_ID_DESC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_COMPANY_ID_ASC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_COMPANY_ID_ASC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_COMPANY_ID_DESC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_COMPANY_ID_DESC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_DATE_CREATED_ASC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_DATE_CREATED_ASC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_DATE_CREATED_DESC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_DATE_CREATED_DESC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_DATE_UPDATED_ASC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_DATE_UPDATED_ASC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_DATE_UPDATED_DESC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_DATE_UPDATED_DESC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_REMARK_ASC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_REMARK_ASC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_REMARK_DESC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_REMARK_DESC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_USER_CREATED_ASC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_USER_CREATED_ASC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_USER_CREATED_DESC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_USER_CREATED_DESC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_USER_UPDATED_ASC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_USER_UPDATED_ASC',
  RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_USER_UPDATED_DESC = 'RELATION_CITY_COMPANIES_BY_COMPANY_ID__MIN_USER_UPDATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__COUNT_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__COUNT_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__COUNT_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__COUNT_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_BUSINESS_UNIT_ID_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_BUSINESS_UNIT_ID_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_BUSINESS_UNIT_ID_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_BUSINESS_UNIT_ID_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_COMPANY_ID_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_COMPANY_ID_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_COMPANY_ID_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_COMPANY_ID_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_DATE_CREATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_DATE_CREATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_DATE_CREATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_DATE_CREATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_DATE_UPDATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_DATE_UPDATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_DATE_UPDATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_DATE_UPDATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_REMARK_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_REMARK_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_REMARK_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_REMARK_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_USER_CREATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_USER_CREATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_USER_CREATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_USER_CREATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_USER_UPDATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_USER_UPDATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_USER_UPDATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MAX_USER_UPDATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_BUSINESS_UNIT_ID_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_BUSINESS_UNIT_ID_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_BUSINESS_UNIT_ID_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_BUSINESS_UNIT_ID_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_COMPANY_ID_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_COMPANY_ID_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_COMPANY_ID_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_COMPANY_ID_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_DATE_CREATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_DATE_CREATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_DATE_CREATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_DATE_CREATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_DATE_UPDATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_DATE_UPDATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_DATE_UPDATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_DATE_UPDATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_REMARK_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_REMARK_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_REMARK_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_REMARK_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_USER_CREATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_USER_CREATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_USER_CREATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_USER_CREATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_USER_UPDATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_USER_UPDATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_USER_UPDATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_COMPANY_ID__MIN_USER_UPDATED_DESC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__COUNT_ASC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__COUNT_ASC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__COUNT_DESC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__COUNT_DESC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_COMPANY_ID_ASC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_COMPANY_ID_ASC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_COMPANY_ID_DESC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_COMPANY_ID_DESC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_DATE_CREATED_ASC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_DATE_CREATED_ASC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_DATE_CREATED_DESC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_DATE_CREATED_DESC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_DATE_UPDATED_ASC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_DATE_UPDATED_ASC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_DATE_UPDATED_DESC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_DATE_UPDATED_DESC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_OFFICE_ID_ASC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_OFFICE_ID_ASC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_OFFICE_ID_DESC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_OFFICE_ID_DESC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_REMARK_ASC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_REMARK_ASC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_REMARK_DESC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_REMARK_DESC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_USER_CREATED_ASC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_USER_CREATED_ASC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_USER_CREATED_DESC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_USER_CREATED_DESC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_USER_UPDATED_ASC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_USER_UPDATED_ASC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_USER_UPDATED_DESC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MAX_USER_UPDATED_DESC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_COMPANY_ID_ASC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_COMPANY_ID_ASC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_COMPANY_ID_DESC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_COMPANY_ID_DESC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_DATE_CREATED_ASC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_DATE_CREATED_ASC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_DATE_CREATED_DESC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_DATE_CREATED_DESC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_DATE_UPDATED_ASC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_DATE_UPDATED_ASC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_DATE_UPDATED_DESC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_DATE_UPDATED_DESC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_OFFICE_ID_ASC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_OFFICE_ID_ASC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_OFFICE_ID_DESC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_OFFICE_ID_DESC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_REMARK_ASC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_REMARK_ASC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_REMARK_DESC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_REMARK_DESC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_USER_CREATED_ASC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_USER_CREATED_ASC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_USER_CREATED_DESC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_USER_CREATED_DESC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_USER_UPDATED_ASC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_USER_UPDATED_ASC',
  RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_USER_UPDATED_DESC = 'RELATION_COMPANY_OFFICES_BY_COMPANY_ID__MIN_USER_UPDATED_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__COUNT_ASC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__COUNT_ASC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__COUNT_DESC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__COUNT_DESC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_COMPANY_ID_ASC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_COMPANY_ID_ASC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_COMPANY_ID_DESC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_COMPANY_ID_DESC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_DATE_CREATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_DATE_CREATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_DATE_CREATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_DATE_CREATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_DATE_UPDATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_DATE_UPDATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_DATE_UPDATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_DATE_UPDATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_REMARK_ASC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_REMARK_ASC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_REMARK_DESC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_REMARK_DESC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_USER_ACCOUNT_ID_ASC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_USER_ACCOUNT_ID_ASC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_USER_ACCOUNT_ID_DESC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_USER_ACCOUNT_ID_DESC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_USER_CREATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_USER_CREATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_USER_CREATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_USER_CREATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_USER_HR_MANAGING_COMPANY_ID_ASC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_USER_HR_MANAGING_COMPANY_ID_ASC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_USER_HR_MANAGING_COMPANY_ID_DESC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_USER_HR_MANAGING_COMPANY_ID_DESC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_USER_UPDATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_USER_UPDATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_USER_UPDATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MAX_USER_UPDATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_COMPANY_ID_ASC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_COMPANY_ID_ASC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_COMPANY_ID_DESC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_COMPANY_ID_DESC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_DATE_CREATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_DATE_CREATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_DATE_CREATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_DATE_CREATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_DATE_UPDATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_DATE_UPDATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_DATE_UPDATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_DATE_UPDATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_REMARK_ASC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_REMARK_ASC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_REMARK_DESC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_REMARK_DESC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_USER_ACCOUNT_ID_ASC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_USER_ACCOUNT_ID_ASC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_USER_ACCOUNT_ID_DESC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_USER_ACCOUNT_ID_DESC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_USER_CREATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_USER_CREATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_USER_CREATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_USER_CREATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_USER_HR_MANAGING_COMPANY_ID_ASC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_USER_HR_MANAGING_COMPANY_ID_ASC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_USER_HR_MANAGING_COMPANY_ID_DESC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_USER_HR_MANAGING_COMPANY_ID_DESC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_USER_UPDATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_USER_UPDATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_USER_UPDATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_COMPANY_ID__MIN_USER_UPDATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface Company extends Node {
  __typename?: 'Company';
  abbreviation?: Maybe<Scalars['String']['output']>;
  companyClosedDate?: Maybe<Scalars['Date']['output']>;
  companyEstablishedDate?: Maybe<Scalars['Date']['output']>;
  companyExternalAccountDisables: CompanyExternalAccountDisablesConnection;
  companyGroup?: Maybe<CompanyGroup>;
  companyGroupId?: Maybe<Scalars['BigInt']['output']>;
  companyId: Scalars['BigInt']['output'];
  companyLocalHrs: CompanyLocalHrsConnection;
  countryByCountryCodeAlpha2?: Maybe<Country>;
  countryCodeAlpha2?: Maybe<Scalars['String']['output']>;
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  employeeCodePrefix?: Maybe<Scalars['String']['output']>;
  employeeCurrentPositions: EmployeeCurrentPositionsConnection;
  employeeTpHistories: EmployeeTpHistoriesConnection;
  employeeWorkingDayStatisticsAlls: EmployeeWorkingDayStatisticsAllsConnection;
  fullName: Scalars['String']['output'];
  googleWorkspaceOrgUnits: GoogleWorkspaceOrgUnitsConnection;
  nodeId: Scalars['ID']['output'];
  notifyPeriodContractEnd?: Maybe<Scalars['Int']['output']>;
  performanceReviewEvaluationCounts: PerformanceReviewEvaluationCountsConnection;
  performanceReviewMasterUsages: PerformanceReviewMasterUsagesConnection;
  performanceReviewMasterWeightings: PerformanceReviewMasterWeightingsConnection;
  probationReviewEnableViews: ProbationReviewEnableViewsConnection;
  probationReviewEnables: ProbationReviewEnablesConnection;
  relationCityCompanies: RelationCityCompaniesConnection;
  relationCompanyBusinessUnits: RelationCompanyBusinessUnitsConnection;
  relationCompanyOffices: RelationCompanyOfficesConnection;
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userHrManagingCompanies: UserHrManagingCompaniesConnection;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export type CompanyCompanyExternalAccountDisablesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CompanyExternalAccountDisableCondition>;
  filter?: InputMaybe<CompanyExternalAccountDisableFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyExternalAccountDisablesOrderBy>>;
};

export type CompanyCompanyLocalHrsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CompanyLocalHrCondition>;
  filter?: InputMaybe<CompanyLocalHrFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyLocalHrsOrderBy>>;
};

export type CompanyEmployeeCurrentPositionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCurrentPositionCondition>;
  filter?: InputMaybe<EmployeeCurrentPositionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeCurrentPositionsOrderBy>>;
};

export type CompanyEmployeeTpHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeTpHistoryCondition>;
  filter?: InputMaybe<EmployeeTpHistoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeTpHistoriesOrderBy>>;
};

export type CompanyEmployeeWorkingDayStatisticsAllsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeWorkingDayStatisticsAllCondition>;
  filter?: InputMaybe<EmployeeWorkingDayStatisticsAllFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeWorkingDayStatisticsAllsOrderBy>>;
};

export type CompanyGoogleWorkspaceOrgUnitsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GoogleWorkspaceOrgUnitCondition>;
  filter?: InputMaybe<GoogleWorkspaceOrgUnitFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GoogleWorkspaceOrgUnitsOrderBy>>;
};

export type CompanyPerformanceReviewEvaluationCountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationCountCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationCountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationCountsOrderBy>>;
};

export type CompanyPerformanceReviewMasterUsagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterUsageCondition>;
  filter?: InputMaybe<PerformanceReviewMasterUsageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterUsagesOrderBy>>;
};

export type CompanyPerformanceReviewMasterWeightingsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterWeightingCondition>;
  filter?: InputMaybe<PerformanceReviewMasterWeightingFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterWeightingsOrderBy>>;
};

export type CompanyProbationReviewEnableViewsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEnableViewCondition>;
  filter?: InputMaybe<ProbationReviewEnableViewFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEnableViewsOrderBy>>;
};

export type CompanyProbationReviewEnablesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEnableCondition>;
  filter?: InputMaybe<ProbationReviewEnableFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEnablesOrderBy>>;
};

export type CompanyRelationCityCompaniesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationCityCompanyCondition>;
  filter?: InputMaybe<RelationCityCompanyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationCityCompaniesOrderBy>>;
};

export type CompanyRelationCompanyBusinessUnitsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationCompanyBusinessUnitCondition>;
  filter?: InputMaybe<RelationCompanyBusinessUnitFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationCompanyBusinessUnitsOrderBy>>;
};

export type CompanyRelationCompanyOfficesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationCompanyOfficeCondition>;
  filter?: InputMaybe<RelationCompanyOfficeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationCompanyOfficesOrderBy>>;
};

export type CompanyUserHrManagingCompaniesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserHrManagingCompanyCondition>;
  filter?: InputMaybe<UserHrManagingCompanyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserHrManagingCompaniesOrderBy>>;
};

export interface CompanyAggregates {
  __typename?: 'CompanyAggregates';
  average?: Maybe<CompanyAverageAggregates>;
  distinctCount?: Maybe<CompanyDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
  max?: Maybe<CompanyMaxAggregates>;
  min?: Maybe<CompanyMinAggregates>;
  stddevPopulation?: Maybe<CompanyStddevPopulationAggregates>;
  stddevSample?: Maybe<CompanyStddevSampleAggregates>;
  sum?: Maybe<CompanySumAggregates>;
  variancePopulation?: Maybe<CompanyVariancePopulationAggregates>;
  varianceSample?: Maybe<CompanyVarianceSampleAggregates>;
}

export interface CompanyAggregatesFilter {
  average?: InputMaybe<CompanyAverageAggregateFilter>;
  distinctCount?: InputMaybe<CompanyDistinctCountAggregateFilter>;
  filter?: InputMaybe<CompanyFilter>;
  max?: InputMaybe<CompanyMaxAggregateFilter>;
  min?: InputMaybe<CompanyMinAggregateFilter>;
  stddevPopulation?: InputMaybe<CompanyStddevPopulationAggregateFilter>;
  stddevSample?: InputMaybe<CompanyStddevSampleAggregateFilter>;
  sum?: InputMaybe<CompanySumAggregateFilter>;
  variancePopulation?: InputMaybe<CompanyVariancePopulationAggregateFilter>;
  varianceSample?: InputMaybe<CompanyVarianceSampleAggregateFilter>;
}

export interface CompanyAverageAggregateFilter {
  companyGroupId?: InputMaybe<BigFloatFilter>;
  companyId?: InputMaybe<BigFloatFilter>;
  notifyPeriodContractEnd?: InputMaybe<BigFloatFilter>;
  userCreated?: InputMaybe<BigFloatFilter>;
  userUpdated?: InputMaybe<BigFloatFilter>;
}

export interface CompanyAverageAggregates {
  __typename?: 'CompanyAverageAggregates';
  companyGroupId?: Maybe<Scalars['BigFloat']['output']>;
  companyId?: Maybe<Scalars['BigFloat']['output']>;
  notifyPeriodContractEnd?: Maybe<Scalars['BigFloat']['output']>;
  userCreated?: Maybe<Scalars['BigFloat']['output']>;
  userUpdated?: Maybe<Scalars['BigFloat']['output']>;
}

export interface CompanyCompanyAbbreviationKeyConnect {
  abbreviation: Scalars['String']['input'];
}

export interface CompanyCompanyAbbreviationKeyDelete {
  abbreviation: Scalars['String']['input'];
}

export interface CompanyCompanyCountryCodeAlpha2FullNameKeyConnect {
  countryCodeAlpha2: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
}

export interface CompanyCompanyCountryCodeAlpha2FullNameKeyDelete {
  countryCodeAlpha2: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
}

export interface CompanyCompanyGroupIdFkeyCompanyCreateInput {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName: Scalars['String']['input'];
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyCompanyGroupIdFkeyCompanyGroupCreateInput {
  companies?: InputMaybe<CompanyCompanyGroupIdFkeyInverseInput>;
  name: Scalars['String']['input'];
  relationCompanyGroupEmailDomains?: InputMaybe<RelationCompanyGroupEmailDomainCompanyGroupIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyGroupUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyCompanyGroupIdFkeyInput {
  connectByCompanyGroupId?: InputMaybe<CompanyGroupCompanyGroupPkeyConnect>;
  connectByName?: InputMaybe<CompanyGroupCompanyGroupNameKeyConnect>;
  connectByNodeId?: InputMaybe<CompanyGroupNodeIdConnect>;
  create?: InputMaybe<CompanyCompanyGroupIdFkeyCompanyGroupCreateInput>;
  deleteByCompanyGroupId?: InputMaybe<CompanyGroupCompanyGroupPkeyDelete>;
  deleteByName?: InputMaybe<CompanyGroupCompanyGroupNameKeyDelete>;
  deleteByNodeId?: InputMaybe<CompanyGroupNodeIdDelete>;
  updateByCompanyGroupId?: InputMaybe<CompanyGroupOnCompanyForCompanyCompanyGroupIdFkeyUsingCompanyGroupPkeyUpdate>;
  updateByName?: InputMaybe<CompanyGroupOnCompanyForCompanyCompanyGroupIdFkeyUsingCompanyGroupNameKeyUpdate>;
  updateByNodeId?: InputMaybe<CompanyOnCompanyForCompanyCompanyGroupIdFkeyNodeIdUpdate>;
}

export interface CompanyCompanyGroupIdFkeyInverseInput {
  connectByAbbreviation?: InputMaybe<Array<CompanyCompanyAbbreviationKeyConnect>>;
  connectByCompanyId?: InputMaybe<Array<CompanyCompanyPkeyConnect>>;
  connectByCountryCodeAlpha2AndFullName?: InputMaybe<Array<CompanyCompanyCountryCodeAlpha2FullNameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<CompanyNodeIdConnect>>;
  create?: InputMaybe<Array<CompanyCompanyGroupIdFkeyCompanyCreateInput>>;
  deleteByAbbreviation?: InputMaybe<Array<CompanyCompanyAbbreviationKeyDelete>>;
  deleteByCompanyId?: InputMaybe<Array<CompanyCompanyPkeyDelete>>;
  deleteByCountryCodeAlpha2AndFullName?: InputMaybe<Array<CompanyCompanyCountryCodeAlpha2FullNameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<CompanyNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByAbbreviation?: InputMaybe<Array<CompanyOnCompanyForCompanyCompanyGroupIdFkeyUsingCompanyAbbreviationKeyUpdate>>;
  updateByCompanyId?: InputMaybe<Array<CompanyOnCompanyForCompanyCompanyGroupIdFkeyUsingCompanyPkeyUpdate>>;
  updateByCountryCodeAlpha2AndFullName?: InputMaybe<Array<CompanyOnCompanyForCompanyCompanyGroupIdFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<CompanyGroupOnCompanyForCompanyCompanyGroupIdFkeyNodeIdUpdate>>;
}

export interface CompanyCompanyPkeyConnect {
  companyId: Scalars['BigInt']['input'];
}

export interface CompanyCompanyPkeyDelete {
  companyId: Scalars['BigInt']['input'];
}

export interface CompanyCondition {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyCountryCodeAlpha2FkeyCompanyCreateInput {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName: Scalars['String']['input'];
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyCountryCodeAlpha2FkeyCountryCreateInput {
  banks?: InputMaybe<BankCountryCodeAlpha3FkeyInverseInput>;
  cities?: InputMaybe<CityCountryCodeAlpha2FkeyInverseInput>;
  companies?: InputMaybe<CompanyCountryCodeAlpha2FkeyInverseInput>;
  countryCodeAlpha3: Scalars['String']['input'];
  countryCodeNumeric?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInverseInput>;
  employeeIdTypes?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInverseInput>;
  employeesToMobileNumberCountryIdUsingCountryCodeAlpha2?: InputMaybe<EmployeeMobileNumberCountryIdFkeyInverseInput>;
  employeesToNationalityUsingCountryCodeAlpha3?: InputMaybe<EmployeeNationalityFkeyInverseInput>;
  employeesToWorkingLocationCountryCodeAlpha2UsingCountryCodeAlpha2?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0InverseInput>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName: Scalars['String']['input'];
  telephoneCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyCountryCodeAlpha2FkeyInput {
  connectByCountryCodeAlpha2?: InputMaybe<CountryCountryPkeyConnect>;
  connectByCountryCodeAlpha3?: InputMaybe<CountryCountryCountryCodeAlpha3KeyConnect>;
  connectByCountryCodeNumeric?: InputMaybe<CountryCountryCountryCodeNumericKeyConnect>;
  connectByDisplayOrder?: InputMaybe<CountryCountryDisplayOrderKeyConnect>;
  connectByNodeId?: InputMaybe<CountryNodeIdConnect>;
  create?: InputMaybe<CompanyCountryCodeAlpha2FkeyCountryCreateInput>;
  deleteByCountryCodeAlpha2?: InputMaybe<CountryCountryPkeyDelete>;
  deleteByCountryCodeAlpha3?: InputMaybe<CountryCountryCountryCodeAlpha3KeyDelete>;
  deleteByCountryCodeNumeric?: InputMaybe<CountryCountryCountryCodeNumericKeyDelete>;
  deleteByDisplayOrder?: InputMaybe<CountryCountryDisplayOrderKeyDelete>;
  deleteByNodeId?: InputMaybe<CountryNodeIdDelete>;
  updateByCountryCodeAlpha2?: InputMaybe<CountryOnCompanyForCompanyCountryCodeAlpha2FkeyUsingCountryPkeyUpdate>;
  updateByCountryCodeAlpha3?: InputMaybe<CountryOnCompanyForCompanyCountryCodeAlpha2FkeyUsingCountryCountryCodeAlpha3KeyUpdate>;
  updateByCountryCodeNumeric?: InputMaybe<CountryOnCompanyForCompanyCountryCodeAlpha2FkeyUsingCountryCountryCodeNumericKeyUpdate>;
  updateByDisplayOrder?: InputMaybe<CountryOnCompanyForCompanyCountryCodeAlpha2FkeyUsingCountryDisplayOrderKeyUpdate>;
  updateByNodeId?: InputMaybe<CompanyOnCompanyForCompanyCountryCodeAlpha2FkeyNodeIdUpdate>;
}

export interface CompanyCountryCodeAlpha2FkeyInverseInput {
  connectByAbbreviation?: InputMaybe<Array<CompanyCompanyAbbreviationKeyConnect>>;
  connectByCompanyId?: InputMaybe<Array<CompanyCompanyPkeyConnect>>;
  connectByCountryCodeAlpha2AndFullName?: InputMaybe<Array<CompanyCompanyCountryCodeAlpha2FullNameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<CompanyNodeIdConnect>>;
  create?: InputMaybe<Array<CompanyCountryCodeAlpha2FkeyCompanyCreateInput>>;
  deleteByAbbreviation?: InputMaybe<Array<CompanyCompanyAbbreviationKeyDelete>>;
  deleteByCompanyId?: InputMaybe<Array<CompanyCompanyPkeyDelete>>;
  deleteByCountryCodeAlpha2AndFullName?: InputMaybe<Array<CompanyCompanyCountryCodeAlpha2FullNameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<CompanyNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByAbbreviation?: InputMaybe<Array<CompanyOnCompanyForCompanyCountryCodeAlpha2FkeyUsingCompanyAbbreviationKeyUpdate>>;
  updateByCompanyId?: InputMaybe<Array<CompanyOnCompanyForCompanyCountryCodeAlpha2FkeyUsingCompanyPkeyUpdate>>;
  updateByCountryCodeAlpha2AndFullName?: InputMaybe<Array<CompanyOnCompanyForCompanyCountryCodeAlpha2FkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<CountryOnCompanyForCompanyCountryCodeAlpha2FkeyNodeIdUpdate>>;
}

export interface CompanyDistinctCountAggregateFilter {
  abbreviation?: InputMaybe<BigIntFilter>;
  companyClosedDate?: InputMaybe<BigIntFilter>;
  companyEstablishedDate?: InputMaybe<BigIntFilter>;
  companyGroupId?: InputMaybe<BigIntFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  countryCodeAlpha2?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<BigIntFilter>;
  dateUpdated?: InputMaybe<BigIntFilter>;
  employeeCodePrefix?: InputMaybe<BigIntFilter>;
  fullName?: InputMaybe<BigIntFilter>;
  notifyPeriodContractEnd?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<BigIntFilter>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface CompanyDistinctCountAggregates {
  __typename?: 'CompanyDistinctCountAggregates';
  abbreviation?: Maybe<Scalars['BigInt']['output']>;
  companyClosedDate?: Maybe<Scalars['BigInt']['output']>;
  companyEstablishedDate?: Maybe<Scalars['BigInt']['output']>;
  companyGroupId?: Maybe<Scalars['BigInt']['output']>;
  companyId?: Maybe<Scalars['BigInt']['output']>;
  countryCodeAlpha2?: Maybe<Scalars['BigInt']['output']>;
  dateCreated?: Maybe<Scalars['BigInt']['output']>;
  dateUpdated?: Maybe<Scalars['BigInt']['output']>;
  employeeCodePrefix?: Maybe<Scalars['BigInt']['output']>;
  fullName?: Maybe<Scalars['BigInt']['output']>;
  notifyPeriodContractEnd?: Maybe<Scalars['BigInt']['output']>;
  remark?: Maybe<Scalars['BigInt']['output']>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface CompanyExternalAccountDisable extends Node {
  __typename?: 'CompanyExternalAccountDisable';
  company: Company;
  companyId: Scalars['BigInt']['output'];
  dateCreated: Scalars['Datetime']['output'];
  externalAccountTypeByExternalAccountTypeKey: ExternalAccountType;
  externalAccountTypeKey: ExternalAccountEmployeeType;
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
}

export interface CompanyExternalAccountDisableCompanyExternalAccountDisablePkeyConnect {
  companyId: Scalars['BigInt']['input'];
  externalAccountTypeKey: ExternalAccountEmployeeType;
}

export interface CompanyExternalAccountDisableCompanyExternalAccountDisablePkeyDelete {
  companyId: Scalars['BigInt']['input'];
  externalAccountTypeKey: ExternalAccountEmployeeType;
}

export interface CompanyExternalAccountDisableCompanyIdFkeyCompanyCreateInput {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName: Scalars['String']['input'];
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyExternalAccountDisableCompanyIdFkeyCompanyExternalAccountDisableCreateInput {
  company?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInput>;
  externalAccountType?: InputMaybe<CompanyExternalAccountDisableExternalAccountTypeKeyFkeyInput>;
  externalAccountTypeKey?: InputMaybe<ExternalAccountEmployeeType>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyExternalAccountDisableCompanyIdFkeyInput {
  connectByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyConnect>;
  connectByCompanyId?: InputMaybe<CompanyCompanyPkeyConnect>;
  connectByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyConnect>;
  connectByNodeId?: InputMaybe<CompanyNodeIdConnect>;
  create?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyCompanyCreateInput>;
  deleteByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyDelete>;
  deleteByCompanyId?: InputMaybe<CompanyCompanyPkeyDelete>;
  deleteByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyDelete>;
  deleteByNodeId?: InputMaybe<CompanyNodeIdDelete>;
  updateByAbbreviation?: InputMaybe<CompanyOnCompanyExternalAccountDisableForCompanyExternalAccountDisableCompanyIdFkeyUsingCompanyAbbreviationKeyUpdate>;
  updateByCompanyId?: InputMaybe<CompanyOnCompanyExternalAccountDisableForCompanyExternalAccountDisableCompanyIdFkeyUsingCompanyPkeyUpdate>;
  updateByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyOnCompanyExternalAccountDisableForCompanyExternalAccountDisableCompanyIdFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate>;
  updateByNodeId?: InputMaybe<CompanyExternalAccountDisableOnCompanyExternalAccountDisableForCompanyExternalAccountDisableCompanyIdFkeyNodeIdUpdate>;
}

export interface CompanyExternalAccountDisableCompanyIdFkeyInverseInput {
  connectByCompanyIdAndExternalAccountTypeKey?: InputMaybe<Array<CompanyExternalAccountDisableCompanyExternalAccountDisablePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<CompanyExternalAccountDisableNodeIdConnect>>;
  create?: InputMaybe<Array<CompanyExternalAccountDisableCompanyIdFkeyCompanyExternalAccountDisableCreateInput>>;
  deleteByCompanyIdAndExternalAccountTypeKey?: InputMaybe<Array<CompanyExternalAccountDisableCompanyExternalAccountDisablePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<CompanyExternalAccountDisableNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyIdAndExternalAccountTypeKey?: InputMaybe<
    Array<CompanyExternalAccountDisableOnCompanyExternalAccountDisableForCompanyExternalAccountDisableCompanyIdFkeyUsingCompanyExternalAccountDisablePkeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<CompanyOnCompanyExternalAccountDisableForCompanyExternalAccountDisableCompanyIdFkeyNodeIdUpdate>>;
}

export interface CompanyExternalAccountDisableCondition {
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  externalAccountTypeKey?: InputMaybe<ExternalAccountEmployeeType>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyExternalAccountDisableExternalAccountTypeKeyFkeyCompanyExternalAccountDisableCreateInput {
  company?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  externalAccountType?: InputMaybe<CompanyExternalAccountDisableExternalAccountTypeKeyFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyExternalAccountDisableExternalAccountTypeKeyFkeyExternalAccountTypeCreateInput {
  apiReferenceUrl?: InputMaybe<Scalars['String']['input']>;
  apiSettingUrl?: InputMaybe<Scalars['String']['input']>;
  authInfo?: InputMaybe<Scalars['JSON']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableExternalAccountTypeKeyFkeyInverseInput>;
  consoleReferenceUrl?: InputMaybe<Scalars['String']['input']>;
  consoleSettingUrl?: InputMaybe<Scalars['String']['input']>;
  displayOrder: Scalars['Int']['input'];
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeExternalAccountTypeKeyFkeyInverseInput>;
  iconBase64?: InputMaybe<Scalars['String']['input']>;
  key: ExternalAccountEmployeeType;
  name: Scalars['String']['input'];
  supportAutomaticallyCreated?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface CompanyExternalAccountDisableExternalAccountTypeKeyFkeyInput {
  connectByKey?: InputMaybe<ExternalAccountTypeExternalAccountTypePkeyConnect>;
  connectByName?: InputMaybe<ExternalAccountTypeExternalAccountTypeNameKeyConnect>;
  connectByNodeId?: InputMaybe<ExternalAccountTypeNodeIdConnect>;
  create?: InputMaybe<CompanyExternalAccountDisableExternalAccountTypeKeyFkeyExternalAccountTypeCreateInput>;
  deleteByKey?: InputMaybe<ExternalAccountTypeExternalAccountTypePkeyDelete>;
  deleteByName?: InputMaybe<ExternalAccountTypeExternalAccountTypeNameKeyDelete>;
  deleteByNodeId?: InputMaybe<ExternalAccountTypeNodeIdDelete>;
  updateByKey?: InputMaybe<ExternalAccountTypeOnCompanyExternalAccountDisableForCompanyExternalAccountDisableExternalAccountTypeKeyFkeyUsingExternalAccountTypePkeyUpdate>;
  updateByName?: InputMaybe<ExternalAccountTypeOnCompanyExternalAccountDisableForCompanyExternalAccountDisableExternalAccountTypeKeyFkeyUsingExternalAccountTypeNameKeyUpdate>;
  updateByNodeId?: InputMaybe<CompanyExternalAccountDisableOnCompanyExternalAccountDisableForCompanyExternalAccountDisableExternalAccountTypeKeyFkeyNodeIdUpdate>;
}

export interface CompanyExternalAccountDisableExternalAccountTypeKeyFkeyInverseInput {
  connectByCompanyIdAndExternalAccountTypeKey?: InputMaybe<Array<CompanyExternalAccountDisableCompanyExternalAccountDisablePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<CompanyExternalAccountDisableNodeIdConnect>>;
  create?: InputMaybe<Array<CompanyExternalAccountDisableExternalAccountTypeKeyFkeyCompanyExternalAccountDisableCreateInput>>;
  deleteByCompanyIdAndExternalAccountTypeKey?: InputMaybe<Array<CompanyExternalAccountDisableCompanyExternalAccountDisablePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<CompanyExternalAccountDisableNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyIdAndExternalAccountTypeKey?: InputMaybe<
    Array<CompanyExternalAccountDisableOnCompanyExternalAccountDisableForCompanyExternalAccountDisableExternalAccountTypeKeyFkeyUsingCompanyExternalAccountDisablePkeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<ExternalAccountTypeOnCompanyExternalAccountDisableForCompanyExternalAccountDisableExternalAccountTypeKeyFkeyNodeIdUpdate>>;
}

export interface CompanyExternalAccountDisableFilter {
  and?: InputMaybe<Array<CompanyExternalAccountDisableFilter>>;
  company?: InputMaybe<CompanyFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  externalAccountTypeByExternalAccountTypeKey?: InputMaybe<ExternalAccountTypeFilter>;
  externalAccountTypeKey?: InputMaybe<ExternalAccountEmployeeTypeFilter>;
  not?: InputMaybe<CompanyExternalAccountDisableFilter>;
  or?: InputMaybe<Array<CompanyExternalAccountDisableFilter>>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
}

export interface CompanyExternalAccountDisableInput {
  company?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  externalAccountType?: InputMaybe<CompanyExternalAccountDisableExternalAccountTypeKeyFkeyInput>;
  externalAccountTypeKey?: InputMaybe<ExternalAccountEmployeeType>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyExternalAccountDisableNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface CompanyExternalAccountDisableNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface CompanyExternalAccountDisableOnCompanyExternalAccountDisableForCompanyExternalAccountDisableCompanyIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyPatch;
}

export interface CompanyExternalAccountDisableOnCompanyExternalAccountDisableForCompanyExternalAccountDisableCompanyIdFkeyUsingCompanyExternalAccountDisablePkeyUpdate {
  companyId: Scalars['BigInt']['input'];
  externalAccountTypeKey: ExternalAccountEmployeeType;
  patch: UpdateCompanyExternalAccountDisableOnCompanyExternalAccountDisableForCompanyExternalAccountDisableCompanyIdFkeyPatch;
}

export interface CompanyExternalAccountDisableOnCompanyExternalAccountDisableForCompanyExternalAccountDisableExternalAccountTypeKeyFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ExternalAccountTypePatch;
}

export interface CompanyExternalAccountDisableOnCompanyExternalAccountDisableForCompanyExternalAccountDisableExternalAccountTypeKeyFkeyUsingCompanyExternalAccountDisablePkeyUpdate {
  companyId: Scalars['BigInt']['input'];
  externalAccountTypeKey: ExternalAccountEmployeeType;
  patch: UpdateCompanyExternalAccountDisableOnCompanyExternalAccountDisableForCompanyExternalAccountDisableExternalAccountTypeKeyFkeyPatch;
}

export interface CompanyExternalAccountDisableOnCompanyExternalAccountDisableForCompanyExternalAccountDisableUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface CompanyExternalAccountDisableOnCompanyExternalAccountDisableForCompanyExternalAccountDisableUserCreatedFkeyUsingCompanyExternalAccountDisablePkeyUpdate {
  companyId: Scalars['BigInt']['input'];
  externalAccountTypeKey: ExternalAccountEmployeeType;
  patch: UpdateCompanyExternalAccountDisableOnCompanyExternalAccountDisableForCompanyExternalAccountDisableUserCreatedFkeyPatch;
}

export interface CompanyExternalAccountDisablePatch {
  company?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  externalAccountType?: InputMaybe<CompanyExternalAccountDisableExternalAccountTypeKeyFkeyInput>;
  externalAccountTypeKey?: InputMaybe<ExternalAccountEmployeeType>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyExternalAccountDisableUserCreatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnCompanyExternalAccountDisableForCompanyExternalAccountDisableUserCreatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<CompanyExternalAccountDisableOnCompanyExternalAccountDisableForCompanyExternalAccountDisableUserCreatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnCompanyExternalAccountDisableForCompanyExternalAccountDisableUserCreatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface CompanyExternalAccountDisableUserCreatedFkeyInverseInput {
  connectByCompanyIdAndExternalAccountTypeKey?: InputMaybe<Array<CompanyExternalAccountDisableCompanyExternalAccountDisablePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<CompanyExternalAccountDisableNodeIdConnect>>;
  deleteByCompanyIdAndExternalAccountTypeKey?: InputMaybe<Array<CompanyExternalAccountDisableCompanyExternalAccountDisablePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<CompanyExternalAccountDisableNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyIdAndExternalAccountTypeKey?: InputMaybe<
    Array<CompanyExternalAccountDisableOnCompanyExternalAccountDisableForCompanyExternalAccountDisableUserCreatedFkeyUsingCompanyExternalAccountDisablePkeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<UserAccountOnCompanyExternalAccountDisableForCompanyExternalAccountDisableUserCreatedFkeyNodeIdUpdate>>;
}

export interface CompanyExternalAccountDisablesConnection {
  __typename?: 'CompanyExternalAccountDisablesConnection';
  edges: Array<CompanyExternalAccountDisablesEdge>;
  nodes: Array<CompanyExternalAccountDisable>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface CompanyExternalAccountDisablesEdge {
  __typename?: 'CompanyExternalAccountDisablesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: CompanyExternalAccountDisable;
}

export enum CompanyExternalAccountDisablesOrderBy {
  COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC',
  COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_ASC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_ASC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_DESC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_DESC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANY_BY_COMPANY_ID__REMARK_ASC = 'COMPANY_BY_COMPANY_ID__REMARK_ASC',
  COMPANY_BY_COMPANY_ID__REMARK_DESC = 'COMPANY_BY_COMPANY_ID__REMARK_DESC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC',
  COMPANY_ID_ASC = 'COMPANY_ID_ASC',
  COMPANY_ID_DESC = 'COMPANY_ID_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__API_REFERENCE_URL_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__API_REFERENCE_URL_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__API_REFERENCE_URL_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__API_REFERENCE_URL_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__API_SETTING_URL_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__API_SETTING_URL_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__API_SETTING_URL_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__API_SETTING_URL_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__AUTH_INFO_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__AUTH_INFO_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__AUTH_INFO_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__AUTH_INFO_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__CONSOLE_REFERENCE_URL_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__CONSOLE_REFERENCE_URL_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__CONSOLE_REFERENCE_URL_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__CONSOLE_REFERENCE_URL_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__CONSOLE_SETTING_URL_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__CONSOLE_SETTING_URL_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__CONSOLE_SETTING_URL_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__CONSOLE_SETTING_URL_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__DISPLAY_ORDER_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__DISPLAY_ORDER_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__DISPLAY_ORDER_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__DISPLAY_ORDER_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__ICON_BASE64_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__ICON_BASE64_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__ICON_BASE64_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__ICON_BASE64_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__KEY_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__KEY_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__KEY_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__KEY_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__NAME_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__NAME_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__NAME_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__NAME_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__SUPPORT_AUTOMATICALLY_CREATED_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__SUPPORT_AUTOMATICALLY_CREATED_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__SUPPORT_AUTOMATICALLY_CREATED_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__SUPPORT_AUTOMATICALLY_CREATED_DESC',
  EXTERNAL_ACCOUNT_TYPE_KEY_ASC = 'EXTERNAL_ACCOUNT_TYPE_KEY_ASC',
  EXTERNAL_ACCOUNT_TYPE_KEY_DESC = 'EXTERNAL_ACCOUNT_TYPE_KEY_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
}

export interface CompanyFilter {
  abbreviation?: InputMaybe<StringFilter>;
  and?: InputMaybe<Array<CompanyFilter>>;
  companyClosedDate?: InputMaybe<DateFilter>;
  companyEstablishedDate?: InputMaybe<DateFilter>;
  companyExternalAccountDisables?: InputMaybe<CompanyToManyCompanyExternalAccountDisableFilter>;
  companyExternalAccountDisablesExist?: InputMaybe<Scalars['Boolean']['input']>;
  companyGroup?: InputMaybe<CompanyGroupFilter>;
  companyGroupExists?: InputMaybe<Scalars['Boolean']['input']>;
  companyGroupId?: InputMaybe<BigIntFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  companyLocalHrs?: InputMaybe<CompanyToManyCompanyLocalHrFilter>;
  companyLocalHrsExist?: InputMaybe<Scalars['Boolean']['input']>;
  countryByCountryCodeAlpha2?: InputMaybe<CountryFilter>;
  countryByCountryCodeAlpha2Exists?: InputMaybe<Scalars['Boolean']['input']>;
  countryCodeAlpha2?: InputMaybe<StringFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  employeeCodePrefix?: InputMaybe<StringFilter>;
  employeeCurrentPositions?: InputMaybe<CompanyToManyEmployeeCurrentPositionFilter>;
  employeeCurrentPositionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeTpHistories?: InputMaybe<CompanyToManyEmployeeTpHistoryFilter>;
  employeeTpHistoriesExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<CompanyToManyEmployeeWorkingDayStatisticsAllFilter>;
  employeeWorkingDayStatisticsAllsExist?: InputMaybe<Scalars['Boolean']['input']>;
  fullName?: InputMaybe<StringFilter>;
  googleWorkspaceOrgUnits?: InputMaybe<CompanyToManyGoogleWorkspaceOrgUnitFilter>;
  googleWorkspaceOrgUnitsExist?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<CompanyFilter>;
  notifyPeriodContractEnd?: InputMaybe<IntFilter>;
  or?: InputMaybe<Array<CompanyFilter>>;
  performanceReviewEvaluationCounts?: InputMaybe<CompanyToManyPerformanceReviewEvaluationCountFilter>;
  performanceReviewEvaluationCountsExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterUsages?: InputMaybe<CompanyToManyPerformanceReviewMasterUsageFilter>;
  performanceReviewMasterUsagesExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterWeightings?: InputMaybe<CompanyToManyPerformanceReviewMasterWeightingFilter>;
  performanceReviewMasterWeightingsExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEnableViews?: InputMaybe<CompanyToManyProbationReviewEnableViewFilter>;
  probationReviewEnableViewsExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEnables?: InputMaybe<CompanyToManyProbationReviewEnableFilter>;
  probationReviewEnablesExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationCityCompanies?: InputMaybe<CompanyToManyRelationCityCompanyFilter>;
  relationCityCompaniesExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationCompanyBusinessUnits?: InputMaybe<CompanyToManyRelationCompanyBusinessUnitFilter>;
  relationCompanyBusinessUnitsExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationCompanyOffices?: InputMaybe<CompanyToManyRelationCompanyOfficeFilter>;
  relationCompanyOfficesExist?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userHrManagingCompanies?: InputMaybe<CompanyToManyUserHrManagingCompanyFilter>;
  userHrManagingCompaniesExist?: InputMaybe<Scalars['Boolean']['input']>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface CompanyGlobalHr extends Node {
  __typename?: 'CompanyGlobalHr';
  companyGlobalHrId: Scalars['BigInt']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  employee: Employee;
  employeeId: Scalars['BigInt']['output'];
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface CompanyGlobalHrCompanyGlobalHrEmployeeIdKeyConnect {
  employeeId: Scalars['BigInt']['input'];
}

export interface CompanyGlobalHrCompanyGlobalHrEmployeeIdKeyDelete {
  employeeId: Scalars['BigInt']['input'];
}

export interface CompanyGlobalHrCompanyGlobalHrPkeyConnect {
  companyGlobalHrId: Scalars['BigInt']['input'];
}

export interface CompanyGlobalHrCompanyGlobalHrPkeyDelete {
  companyGlobalHrId: Scalars['BigInt']['input'];
}

export interface CompanyGlobalHrCondition {
  companyGlobalHrId?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyGlobalHrEmployeeIdFkeyCompanyGlobalHrCreateInput {
  companyGlobalHrId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyGlobalHrEmployeeIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface CompanyGlobalHrEmployeeIdFkeyInput {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyEmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnCompanyGlobalHrForCompanyGlobalHrEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnCompanyGlobalHrForCompanyGlobalHrEmployeeIdFkeyUsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<CompanyGlobalHrOnCompanyGlobalHrForCompanyGlobalHrEmployeeIdFkeyNodeIdUpdate>;
}

export interface CompanyGlobalHrEmployeeIdFkeyInverseInput {
  connectByCompanyGlobalHrId?: InputMaybe<CompanyGlobalHrCompanyGlobalHrPkeyConnect>;
  connectByEmployeeId?: InputMaybe<CompanyGlobalHrCompanyGlobalHrEmployeeIdKeyConnect>;
  connectByNodeId?: InputMaybe<CompanyGlobalHrNodeIdConnect>;
  create?: InputMaybe<Array<CompanyGlobalHrEmployeeIdFkeyCompanyGlobalHrCreateInput>>;
  deleteByCompanyGlobalHrId?: InputMaybe<CompanyGlobalHrCompanyGlobalHrPkeyDelete>;
  deleteByEmployeeId?: InputMaybe<CompanyGlobalHrCompanyGlobalHrEmployeeIdKeyDelete>;
  deleteByNodeId?: InputMaybe<CompanyGlobalHrNodeIdDelete>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyGlobalHrId?: InputMaybe<CompanyGlobalHrOnCompanyGlobalHrForCompanyGlobalHrEmployeeIdFkeyUsingCompanyGlobalHrPkeyUpdate>;
  updateByEmployeeId?: InputMaybe<CompanyGlobalHrOnCompanyGlobalHrForCompanyGlobalHrEmployeeIdFkeyUsingCompanyGlobalHrEmployeeIdKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeOnCompanyGlobalHrForCompanyGlobalHrEmployeeIdFkeyNodeIdUpdate>;
}

export interface CompanyGlobalHrFilter {
  and?: InputMaybe<Array<CompanyGlobalHrFilter>>;
  companyGlobalHrId?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  employee?: InputMaybe<EmployeeFilter>;
  employeeId?: InputMaybe<BigIntFilter>;
  not?: InputMaybe<CompanyGlobalHrFilter>;
  or?: InputMaybe<Array<CompanyGlobalHrFilter>>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface CompanyGlobalHrInput {
  companyGlobalHrId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyGlobalHrNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface CompanyGlobalHrNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface CompanyGlobalHrOnCompanyGlobalHrForCompanyGlobalHrEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface CompanyGlobalHrOnCompanyGlobalHrForCompanyGlobalHrEmployeeIdFkeyUsingCompanyGlobalHrEmployeeIdKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateCompanyGlobalHrOnCompanyGlobalHrForCompanyGlobalHrEmployeeIdFkeyPatch;
}

export interface CompanyGlobalHrOnCompanyGlobalHrForCompanyGlobalHrEmployeeIdFkeyUsingCompanyGlobalHrPkeyUpdate {
  companyGlobalHrId: Scalars['BigInt']['input'];
  patch: UpdateCompanyGlobalHrOnCompanyGlobalHrForCompanyGlobalHrEmployeeIdFkeyPatch;
}

export interface CompanyGlobalHrOnCompanyGlobalHrForCompanyGlobalHrUserCreatedFkeyUsingCompanyGlobalHrEmployeeIdKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateCompanyGlobalHrOnCompanyGlobalHrForCompanyGlobalHrUserCreatedFkeyPatch;
}

export interface CompanyGlobalHrOnCompanyGlobalHrForCompanyGlobalHrUserCreatedFkeyUsingCompanyGlobalHrPkeyUpdate {
  companyGlobalHrId: Scalars['BigInt']['input'];
  patch: UpdateCompanyGlobalHrOnCompanyGlobalHrForCompanyGlobalHrUserCreatedFkeyPatch;
}

export interface CompanyGlobalHrOnCompanyGlobalHrForCompanyGlobalHrUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface CompanyGlobalHrOnCompanyGlobalHrForCompanyGlobalHrUserUpdatedFkeyUsingCompanyGlobalHrEmployeeIdKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateCompanyGlobalHrOnCompanyGlobalHrForCompanyGlobalHrUserUpdatedFkeyPatch;
}

export interface CompanyGlobalHrOnCompanyGlobalHrForCompanyGlobalHrUserUpdatedFkeyUsingCompanyGlobalHrPkeyUpdate {
  companyGlobalHrId: Scalars['BigInt']['input'];
  patch: UpdateCompanyGlobalHrOnCompanyGlobalHrForCompanyGlobalHrUserUpdatedFkeyPatch;
}

export interface CompanyGlobalHrPatch {
  companyGlobalHrId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyGlobalHrUserCreatedFkeyInverseInput {
  connectByCompanyGlobalHrId?: InputMaybe<Array<CompanyGlobalHrCompanyGlobalHrPkeyConnect>>;
  connectByEmployeeId?: InputMaybe<Array<CompanyGlobalHrCompanyGlobalHrEmployeeIdKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<CompanyGlobalHrNodeIdConnect>>;
  deleteByCompanyGlobalHrId?: InputMaybe<Array<CompanyGlobalHrCompanyGlobalHrPkeyDelete>>;
  deleteByEmployeeId?: InputMaybe<Array<CompanyGlobalHrCompanyGlobalHrEmployeeIdKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<CompanyGlobalHrNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyGlobalHrId?: InputMaybe<Array<CompanyGlobalHrOnCompanyGlobalHrForCompanyGlobalHrUserCreatedFkeyUsingCompanyGlobalHrPkeyUpdate>>;
  updateByEmployeeId?: InputMaybe<Array<CompanyGlobalHrOnCompanyGlobalHrForCompanyGlobalHrUserCreatedFkeyUsingCompanyGlobalHrEmployeeIdKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnCompanyGlobalHrForCompanyGlobalHrUserCreatedFkeyNodeIdUpdate>>;
}

export interface CompanyGlobalHrUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnCompanyGlobalHrForCompanyGlobalHrUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<CompanyGlobalHrOnCompanyGlobalHrForCompanyGlobalHrUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnCompanyGlobalHrForCompanyGlobalHrUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface CompanyGlobalHrUserUpdatedFkeyInverseInput {
  connectByCompanyGlobalHrId?: InputMaybe<Array<CompanyGlobalHrCompanyGlobalHrPkeyConnect>>;
  connectByEmployeeId?: InputMaybe<Array<CompanyGlobalHrCompanyGlobalHrEmployeeIdKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<CompanyGlobalHrNodeIdConnect>>;
  deleteByCompanyGlobalHrId?: InputMaybe<Array<CompanyGlobalHrCompanyGlobalHrPkeyDelete>>;
  deleteByEmployeeId?: InputMaybe<Array<CompanyGlobalHrCompanyGlobalHrEmployeeIdKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<CompanyGlobalHrNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyGlobalHrId?: InputMaybe<Array<CompanyGlobalHrOnCompanyGlobalHrForCompanyGlobalHrUserUpdatedFkeyUsingCompanyGlobalHrPkeyUpdate>>;
  updateByEmployeeId?: InputMaybe<Array<CompanyGlobalHrOnCompanyGlobalHrForCompanyGlobalHrUserUpdatedFkeyUsingCompanyGlobalHrEmployeeIdKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnCompanyGlobalHrForCompanyGlobalHrUserUpdatedFkeyNodeIdUpdate>>;
}

export interface CompanyGlobalHrsConnection {
  __typename?: 'CompanyGlobalHrsConnection';
  edges: Array<CompanyGlobalHrsEdge>;
  nodes: Array<CompanyGlobalHr>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface CompanyGlobalHrsEdge {
  __typename?: 'CompanyGlobalHrsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: CompanyGlobalHr;
}

export enum CompanyGlobalHrsOrderBy {
  COMPANY_GLOBAL_HR_ID_ASC = 'COMPANY_GLOBAL_HR_ID_ASC',
  COMPANY_GLOBAL_HR_ID_DESC = 'COMPANY_GLOBAL_HR_ID_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface CompanyGroup extends Node {
  __typename?: 'CompanyGroup';
  companies: CompaniesConnection;
  companyGroupId: Scalars['BigInt']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  name: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  relationCompanyGroupEmailDomains: RelationCompanyGroupEmailDomainsConnection;
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export type CompanyGroupCompaniesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CompanyCondition>;
  filter?: InputMaybe<CompanyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompaniesOrderBy>>;
};

export type CompanyGroupRelationCompanyGroupEmailDomainsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationCompanyGroupEmailDomainCondition>;
  filter?: InputMaybe<RelationCompanyGroupEmailDomainFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationCompanyGroupEmailDomainsOrderBy>>;
};

export enum CompanyGroupBy {
  COMPANY_CLOSED_DATE = 'COMPANY_CLOSED_DATE',
  COMPANY_ESTABLISHED_DATE = 'COMPANY_ESTABLISHED_DATE',
  COMPANY_GROUP_ID = 'COMPANY_GROUP_ID',
  COUNTRY_CODE_ALPHA2 = 'COUNTRY_CODE_ALPHA2',
  DATE_CREATED = 'DATE_CREATED',
  DATE_CREATED_TRUNCATED_TO_DAY = 'DATE_CREATED_TRUNCATED_TO_DAY',
  DATE_CREATED_TRUNCATED_TO_HOUR = 'DATE_CREATED_TRUNCATED_TO_HOUR',
  DATE_UPDATED = 'DATE_UPDATED',
  DATE_UPDATED_TRUNCATED_TO_DAY = 'DATE_UPDATED_TRUNCATED_TO_DAY',
  DATE_UPDATED_TRUNCATED_TO_HOUR = 'DATE_UPDATED_TRUNCATED_TO_HOUR',
  EMPLOYEE_CODE_PREFIX = 'EMPLOYEE_CODE_PREFIX',
  FULL_NAME = 'FULL_NAME',
  NOTIFY_PERIOD_CONTRACT_END = 'NOTIFY_PERIOD_CONTRACT_END',
  REMARK = 'REMARK',
  USER_CREATED = 'USER_CREATED',
  USER_UPDATED = 'USER_UPDATED',
}

export interface CompanyGroupCompanyGroupNameKeyConnect {
  name: Scalars['String']['input'];
}

export interface CompanyGroupCompanyGroupNameKeyDelete {
  name: Scalars['String']['input'];
}

export interface CompanyGroupCompanyGroupPkeyConnect {
  companyGroupId: Scalars['BigInt']['input'];
}

export interface CompanyGroupCompanyGroupPkeyDelete {
  companyGroupId: Scalars['BigInt']['input'];
}

export interface CompanyGroupCondition {
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyGroupFilter {
  and?: InputMaybe<Array<CompanyGroupFilter>>;
  companies?: InputMaybe<CompanyGroupToManyCompanyFilter>;
  companiesExist?: InputMaybe<Scalars['Boolean']['input']>;
  companyGroupId?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  name?: InputMaybe<StringFilter>;
  not?: InputMaybe<CompanyGroupFilter>;
  or?: InputMaybe<Array<CompanyGroupFilter>>;
  relationCompanyGroupEmailDomains?: InputMaybe<CompanyGroupToManyRelationCompanyGroupEmailDomainFilter>;
  relationCompanyGroupEmailDomainsExist?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface CompanyGroupInput {
  companies?: InputMaybe<CompanyCompanyGroupIdFkeyInverseInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  name: Scalars['String']['input'];
  relationCompanyGroupEmailDomains?: InputMaybe<RelationCompanyGroupEmailDomainCompanyGroupIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyGroupUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyGroupNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface CompanyGroupNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface CompanyGroupOnCompanyForCompanyCompanyGroupIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyPatch;
}

export interface CompanyGroupOnCompanyForCompanyCompanyGroupIdFkeyUsingCompanyGroupNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateCompanyGroupOnCompanyForCompanyCompanyGroupIdFkeyPatch;
}

export interface CompanyGroupOnCompanyForCompanyCompanyGroupIdFkeyUsingCompanyGroupPkeyUpdate {
  companyGroupId: Scalars['BigInt']['input'];
  patch: UpdateCompanyGroupOnCompanyForCompanyCompanyGroupIdFkeyPatch;
}

export interface CompanyGroupOnCompanyGroupForCompanyGroupUserCreatedFkeyUsingCompanyGroupNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateCompanyGroupOnCompanyGroupForCompanyGroupUserCreatedFkeyPatch;
}

export interface CompanyGroupOnCompanyGroupForCompanyGroupUserCreatedFkeyUsingCompanyGroupPkeyUpdate {
  companyGroupId: Scalars['BigInt']['input'];
  patch: UpdateCompanyGroupOnCompanyGroupForCompanyGroupUserCreatedFkeyPatch;
}

export interface CompanyGroupOnCompanyGroupForCompanyGroupUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface CompanyGroupOnCompanyGroupForCompanyGroupUserUpdatedFkeyUsingCompanyGroupNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateCompanyGroupOnCompanyGroupForCompanyGroupUserUpdatedFkeyPatch;
}

export interface CompanyGroupOnCompanyGroupForCompanyGroupUserUpdatedFkeyUsingCompanyGroupPkeyUpdate {
  companyGroupId: Scalars['BigInt']['input'];
  patch: UpdateCompanyGroupOnCompanyGroupForCompanyGroupUserUpdatedFkeyPatch;
}

export interface CompanyGroupOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainCompanyGroupIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationCompanyGroupEmailDomainPatch;
}

export interface CompanyGroupOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainCompanyGroupIdFkeyUsingCompanyGroupNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateCompanyGroupOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainCompanyGroupIdFkeyPatch;
}

export interface CompanyGroupOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainCompanyGroupIdFkeyUsingCompanyGroupPkeyUpdate {
  companyGroupId: Scalars['BigInt']['input'];
  patch: UpdateCompanyGroupOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainCompanyGroupIdFkeyPatch;
}

export interface CompanyGroupPatch {
  companies?: InputMaybe<CompanyCompanyGroupIdFkeyInverseInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  relationCompanyGroupEmailDomains?: InputMaybe<RelationCompanyGroupEmailDomainCompanyGroupIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyGroupUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyGroupToManyCompanyFilter {
  aggregates?: InputMaybe<CompanyAggregatesFilter>;
  every?: InputMaybe<CompanyFilter>;
  none?: InputMaybe<CompanyFilter>;
  some?: InputMaybe<CompanyFilter>;
}

export interface CompanyGroupToManyRelationCompanyGroupEmailDomainFilter {
  every?: InputMaybe<RelationCompanyGroupEmailDomainFilter>;
  none?: InputMaybe<RelationCompanyGroupEmailDomainFilter>;
  some?: InputMaybe<RelationCompanyGroupEmailDomainFilter>;
}

export interface CompanyGroupUserCreatedFkeyInverseInput {
  connectByCompanyGroupId?: InputMaybe<Array<CompanyGroupCompanyGroupPkeyConnect>>;
  connectByName?: InputMaybe<Array<CompanyGroupCompanyGroupNameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<CompanyGroupNodeIdConnect>>;
  deleteByCompanyGroupId?: InputMaybe<Array<CompanyGroupCompanyGroupPkeyDelete>>;
  deleteByName?: InputMaybe<Array<CompanyGroupCompanyGroupNameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<CompanyGroupNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyGroupId?: InputMaybe<Array<CompanyGroupOnCompanyGroupForCompanyGroupUserCreatedFkeyUsingCompanyGroupPkeyUpdate>>;
  updateByName?: InputMaybe<Array<CompanyGroupOnCompanyGroupForCompanyGroupUserCreatedFkeyUsingCompanyGroupNameKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnCompanyGroupForCompanyGroupUserCreatedFkeyNodeIdUpdate>>;
}

export interface CompanyGroupUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnCompanyGroupForCompanyGroupUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<CompanyGroupOnCompanyGroupForCompanyGroupUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnCompanyGroupForCompanyGroupUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface CompanyGroupUserUpdatedFkeyInverseInput {
  connectByCompanyGroupId?: InputMaybe<Array<CompanyGroupCompanyGroupPkeyConnect>>;
  connectByName?: InputMaybe<Array<CompanyGroupCompanyGroupNameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<CompanyGroupNodeIdConnect>>;
  deleteByCompanyGroupId?: InputMaybe<Array<CompanyGroupCompanyGroupPkeyDelete>>;
  deleteByName?: InputMaybe<Array<CompanyGroupCompanyGroupNameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<CompanyGroupNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyGroupId?: InputMaybe<Array<CompanyGroupOnCompanyGroupForCompanyGroupUserUpdatedFkeyUsingCompanyGroupPkeyUpdate>>;
  updateByName?: InputMaybe<Array<CompanyGroupOnCompanyGroupForCompanyGroupUserUpdatedFkeyUsingCompanyGroupNameKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnCompanyGroupForCompanyGroupUserUpdatedFkeyNodeIdUpdate>>;
}

export interface CompanyGroupsConnection {
  __typename?: 'CompanyGroupsConnection';
  edges: Array<CompanyGroupsEdge>;
  nodes: Array<CompanyGroup>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface CompanyGroupsEdge {
  __typename?: 'CompanyGroupsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: CompanyGroup;
}

export enum CompanyGroupsOrderBy {
  COMPANIES_AVERAGE_ABBREVIATION_ASC = 'COMPANIES_AVERAGE_ABBREVIATION_ASC',
  COMPANIES_AVERAGE_ABBREVIATION_DESC = 'COMPANIES_AVERAGE_ABBREVIATION_DESC',
  COMPANIES_AVERAGE_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_AVERAGE_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_AVERAGE_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_AVERAGE_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_AVERAGE_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_AVERAGE_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_AVERAGE_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_AVERAGE_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_AVERAGE_COMPANY_GROUP_ID_ASC = 'COMPANIES_AVERAGE_COMPANY_GROUP_ID_ASC',
  COMPANIES_AVERAGE_COMPANY_GROUP_ID_DESC = 'COMPANIES_AVERAGE_COMPANY_GROUP_ID_DESC',
  COMPANIES_AVERAGE_COMPANY_ID_ASC = 'COMPANIES_AVERAGE_COMPANY_ID_ASC',
  COMPANIES_AVERAGE_COMPANY_ID_DESC = 'COMPANIES_AVERAGE_COMPANY_ID_DESC',
  COMPANIES_AVERAGE_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_AVERAGE_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_AVERAGE_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_AVERAGE_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_AVERAGE_DATE_CREATED_ASC = 'COMPANIES_AVERAGE_DATE_CREATED_ASC',
  COMPANIES_AVERAGE_DATE_CREATED_DESC = 'COMPANIES_AVERAGE_DATE_CREATED_DESC',
  COMPANIES_AVERAGE_DATE_UPDATED_ASC = 'COMPANIES_AVERAGE_DATE_UPDATED_ASC',
  COMPANIES_AVERAGE_DATE_UPDATED_DESC = 'COMPANIES_AVERAGE_DATE_UPDATED_DESC',
  COMPANIES_AVERAGE_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_AVERAGE_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_AVERAGE_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_AVERAGE_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_AVERAGE_FULL_NAME_ASC = 'COMPANIES_AVERAGE_FULL_NAME_ASC',
  COMPANIES_AVERAGE_FULL_NAME_DESC = 'COMPANIES_AVERAGE_FULL_NAME_DESC',
  COMPANIES_AVERAGE_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_AVERAGE_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_AVERAGE_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_AVERAGE_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_AVERAGE_REMARK_ASC = 'COMPANIES_AVERAGE_REMARK_ASC',
  COMPANIES_AVERAGE_REMARK_DESC = 'COMPANIES_AVERAGE_REMARK_DESC',
  COMPANIES_AVERAGE_USER_CREATED_ASC = 'COMPANIES_AVERAGE_USER_CREATED_ASC',
  COMPANIES_AVERAGE_USER_CREATED_DESC = 'COMPANIES_AVERAGE_USER_CREATED_DESC',
  COMPANIES_AVERAGE_USER_UPDATED_ASC = 'COMPANIES_AVERAGE_USER_UPDATED_ASC',
  COMPANIES_AVERAGE_USER_UPDATED_DESC = 'COMPANIES_AVERAGE_USER_UPDATED_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__COUNT_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__COUNT_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__COUNT_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__COUNT_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_ABBREVIATION_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_ABBREVIATION_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_ABBREVIATION_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_ABBREVIATION_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_COMPANY_ID_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_COMPANY_ID_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_COMPANY_ID_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_COMPANY_ID_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_DATE_CREATED_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_DATE_CREATED_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_DATE_CREATED_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_DATE_CREATED_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_DATE_UPDATED_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_DATE_UPDATED_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_DATE_UPDATED_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_DATE_UPDATED_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_FULL_NAME_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_FULL_NAME_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_FULL_NAME_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_FULL_NAME_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_REMARK_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_REMARK_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_REMARK_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_REMARK_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_USER_CREATED_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_USER_CREATED_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_USER_CREATED_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_USER_CREATED_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_USER_UPDATED_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_USER_UPDATED_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MAX_USER_UPDATED_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MAX_USER_UPDATED_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_ABBREVIATION_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_ABBREVIATION_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_ABBREVIATION_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_ABBREVIATION_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_COMPANY_ID_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_COMPANY_ID_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_COMPANY_ID_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_COMPANY_ID_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_DATE_CREATED_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_DATE_CREATED_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_DATE_CREATED_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_DATE_CREATED_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_DATE_UPDATED_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_DATE_UPDATED_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_DATE_UPDATED_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_DATE_UPDATED_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_FULL_NAME_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_FULL_NAME_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_FULL_NAME_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_FULL_NAME_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_REMARK_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_REMARK_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_REMARK_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_REMARK_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_USER_CREATED_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_USER_CREATED_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_USER_CREATED_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_USER_CREATED_DESC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_USER_UPDATED_ASC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_USER_UPDATED_ASC',
  COMPANIES_BY_COMPANY_GROUP_ID__MIN_USER_UPDATED_DESC = 'COMPANIES_BY_COMPANY_GROUP_ID__MIN_USER_UPDATED_DESC',
  COMPANIES_COUNT_ASC = 'COMPANIES_COUNT_ASC',
  COMPANIES_COUNT_DESC = 'COMPANIES_COUNT_DESC',
  COMPANIES_DISTINCT_COUNT_ABBREVIATION_ASC = 'COMPANIES_DISTINCT_COUNT_ABBREVIATION_ASC',
  COMPANIES_DISTINCT_COUNT_ABBREVIATION_DESC = 'COMPANIES_DISTINCT_COUNT_ABBREVIATION_DESC',
  COMPANIES_DISTINCT_COUNT_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_DISTINCT_COUNT_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_DISTINCT_COUNT_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_DISTINCT_COUNT_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_DISTINCT_COUNT_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_DISTINCT_COUNT_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_DISTINCT_COUNT_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_DISTINCT_COUNT_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_DISTINCT_COUNT_COMPANY_GROUP_ID_ASC = 'COMPANIES_DISTINCT_COUNT_COMPANY_GROUP_ID_ASC',
  COMPANIES_DISTINCT_COUNT_COMPANY_GROUP_ID_DESC = 'COMPANIES_DISTINCT_COUNT_COMPANY_GROUP_ID_DESC',
  COMPANIES_DISTINCT_COUNT_COMPANY_ID_ASC = 'COMPANIES_DISTINCT_COUNT_COMPANY_ID_ASC',
  COMPANIES_DISTINCT_COUNT_COMPANY_ID_DESC = 'COMPANIES_DISTINCT_COUNT_COMPANY_ID_DESC',
  COMPANIES_DISTINCT_COUNT_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_DISTINCT_COUNT_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_DISTINCT_COUNT_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_DISTINCT_COUNT_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_DISTINCT_COUNT_DATE_CREATED_ASC = 'COMPANIES_DISTINCT_COUNT_DATE_CREATED_ASC',
  COMPANIES_DISTINCT_COUNT_DATE_CREATED_DESC = 'COMPANIES_DISTINCT_COUNT_DATE_CREATED_DESC',
  COMPANIES_DISTINCT_COUNT_DATE_UPDATED_ASC = 'COMPANIES_DISTINCT_COUNT_DATE_UPDATED_ASC',
  COMPANIES_DISTINCT_COUNT_DATE_UPDATED_DESC = 'COMPANIES_DISTINCT_COUNT_DATE_UPDATED_DESC',
  COMPANIES_DISTINCT_COUNT_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_DISTINCT_COUNT_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_DISTINCT_COUNT_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_DISTINCT_COUNT_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_DISTINCT_COUNT_FULL_NAME_ASC = 'COMPANIES_DISTINCT_COUNT_FULL_NAME_ASC',
  COMPANIES_DISTINCT_COUNT_FULL_NAME_DESC = 'COMPANIES_DISTINCT_COUNT_FULL_NAME_DESC',
  COMPANIES_DISTINCT_COUNT_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_DISTINCT_COUNT_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_DISTINCT_COUNT_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_DISTINCT_COUNT_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_DISTINCT_COUNT_REMARK_ASC = 'COMPANIES_DISTINCT_COUNT_REMARK_ASC',
  COMPANIES_DISTINCT_COUNT_REMARK_DESC = 'COMPANIES_DISTINCT_COUNT_REMARK_DESC',
  COMPANIES_DISTINCT_COUNT_USER_CREATED_ASC = 'COMPANIES_DISTINCT_COUNT_USER_CREATED_ASC',
  COMPANIES_DISTINCT_COUNT_USER_CREATED_DESC = 'COMPANIES_DISTINCT_COUNT_USER_CREATED_DESC',
  COMPANIES_DISTINCT_COUNT_USER_UPDATED_ASC = 'COMPANIES_DISTINCT_COUNT_USER_UPDATED_ASC',
  COMPANIES_DISTINCT_COUNT_USER_UPDATED_DESC = 'COMPANIES_DISTINCT_COUNT_USER_UPDATED_DESC',
  COMPANIES_MAX_ABBREVIATION_ASC = 'COMPANIES_MAX_ABBREVIATION_ASC',
  COMPANIES_MAX_ABBREVIATION_DESC = 'COMPANIES_MAX_ABBREVIATION_DESC',
  COMPANIES_MAX_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_MAX_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_MAX_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_MAX_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_MAX_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_MAX_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_MAX_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_MAX_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_MAX_COMPANY_GROUP_ID_ASC = 'COMPANIES_MAX_COMPANY_GROUP_ID_ASC',
  COMPANIES_MAX_COMPANY_GROUP_ID_DESC = 'COMPANIES_MAX_COMPANY_GROUP_ID_DESC',
  COMPANIES_MAX_COMPANY_ID_ASC = 'COMPANIES_MAX_COMPANY_ID_ASC',
  COMPANIES_MAX_COMPANY_ID_DESC = 'COMPANIES_MAX_COMPANY_ID_DESC',
  COMPANIES_MAX_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_MAX_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_MAX_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_MAX_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_MAX_DATE_CREATED_ASC = 'COMPANIES_MAX_DATE_CREATED_ASC',
  COMPANIES_MAX_DATE_CREATED_DESC = 'COMPANIES_MAX_DATE_CREATED_DESC',
  COMPANIES_MAX_DATE_UPDATED_ASC = 'COMPANIES_MAX_DATE_UPDATED_ASC',
  COMPANIES_MAX_DATE_UPDATED_DESC = 'COMPANIES_MAX_DATE_UPDATED_DESC',
  COMPANIES_MAX_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_MAX_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_MAX_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_MAX_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_MAX_FULL_NAME_ASC = 'COMPANIES_MAX_FULL_NAME_ASC',
  COMPANIES_MAX_FULL_NAME_DESC = 'COMPANIES_MAX_FULL_NAME_DESC',
  COMPANIES_MAX_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_MAX_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_MAX_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_MAX_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_MAX_REMARK_ASC = 'COMPANIES_MAX_REMARK_ASC',
  COMPANIES_MAX_REMARK_DESC = 'COMPANIES_MAX_REMARK_DESC',
  COMPANIES_MAX_USER_CREATED_ASC = 'COMPANIES_MAX_USER_CREATED_ASC',
  COMPANIES_MAX_USER_CREATED_DESC = 'COMPANIES_MAX_USER_CREATED_DESC',
  COMPANIES_MAX_USER_UPDATED_ASC = 'COMPANIES_MAX_USER_UPDATED_ASC',
  COMPANIES_MAX_USER_UPDATED_DESC = 'COMPANIES_MAX_USER_UPDATED_DESC',
  COMPANIES_MIN_ABBREVIATION_ASC = 'COMPANIES_MIN_ABBREVIATION_ASC',
  COMPANIES_MIN_ABBREVIATION_DESC = 'COMPANIES_MIN_ABBREVIATION_DESC',
  COMPANIES_MIN_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_MIN_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_MIN_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_MIN_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_MIN_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_MIN_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_MIN_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_MIN_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_MIN_COMPANY_GROUP_ID_ASC = 'COMPANIES_MIN_COMPANY_GROUP_ID_ASC',
  COMPANIES_MIN_COMPANY_GROUP_ID_DESC = 'COMPANIES_MIN_COMPANY_GROUP_ID_DESC',
  COMPANIES_MIN_COMPANY_ID_ASC = 'COMPANIES_MIN_COMPANY_ID_ASC',
  COMPANIES_MIN_COMPANY_ID_DESC = 'COMPANIES_MIN_COMPANY_ID_DESC',
  COMPANIES_MIN_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_MIN_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_MIN_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_MIN_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_MIN_DATE_CREATED_ASC = 'COMPANIES_MIN_DATE_CREATED_ASC',
  COMPANIES_MIN_DATE_CREATED_DESC = 'COMPANIES_MIN_DATE_CREATED_DESC',
  COMPANIES_MIN_DATE_UPDATED_ASC = 'COMPANIES_MIN_DATE_UPDATED_ASC',
  COMPANIES_MIN_DATE_UPDATED_DESC = 'COMPANIES_MIN_DATE_UPDATED_DESC',
  COMPANIES_MIN_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_MIN_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_MIN_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_MIN_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_MIN_FULL_NAME_ASC = 'COMPANIES_MIN_FULL_NAME_ASC',
  COMPANIES_MIN_FULL_NAME_DESC = 'COMPANIES_MIN_FULL_NAME_DESC',
  COMPANIES_MIN_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_MIN_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_MIN_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_MIN_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_MIN_REMARK_ASC = 'COMPANIES_MIN_REMARK_ASC',
  COMPANIES_MIN_REMARK_DESC = 'COMPANIES_MIN_REMARK_DESC',
  COMPANIES_MIN_USER_CREATED_ASC = 'COMPANIES_MIN_USER_CREATED_ASC',
  COMPANIES_MIN_USER_CREATED_DESC = 'COMPANIES_MIN_USER_CREATED_DESC',
  COMPANIES_MIN_USER_UPDATED_ASC = 'COMPANIES_MIN_USER_UPDATED_ASC',
  COMPANIES_MIN_USER_UPDATED_DESC = 'COMPANIES_MIN_USER_UPDATED_DESC',
  COMPANIES_STDDEV_POPULATION_ABBREVIATION_ASC = 'COMPANIES_STDDEV_POPULATION_ABBREVIATION_ASC',
  COMPANIES_STDDEV_POPULATION_ABBREVIATION_DESC = 'COMPANIES_STDDEV_POPULATION_ABBREVIATION_DESC',
  COMPANIES_STDDEV_POPULATION_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_STDDEV_POPULATION_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_STDDEV_POPULATION_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_STDDEV_POPULATION_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_STDDEV_POPULATION_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_STDDEV_POPULATION_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_STDDEV_POPULATION_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_STDDEV_POPULATION_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_STDDEV_POPULATION_COMPANY_GROUP_ID_ASC = 'COMPANIES_STDDEV_POPULATION_COMPANY_GROUP_ID_ASC',
  COMPANIES_STDDEV_POPULATION_COMPANY_GROUP_ID_DESC = 'COMPANIES_STDDEV_POPULATION_COMPANY_GROUP_ID_DESC',
  COMPANIES_STDDEV_POPULATION_COMPANY_ID_ASC = 'COMPANIES_STDDEV_POPULATION_COMPANY_ID_ASC',
  COMPANIES_STDDEV_POPULATION_COMPANY_ID_DESC = 'COMPANIES_STDDEV_POPULATION_COMPANY_ID_DESC',
  COMPANIES_STDDEV_POPULATION_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_STDDEV_POPULATION_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_STDDEV_POPULATION_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_STDDEV_POPULATION_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_STDDEV_POPULATION_DATE_CREATED_ASC = 'COMPANIES_STDDEV_POPULATION_DATE_CREATED_ASC',
  COMPANIES_STDDEV_POPULATION_DATE_CREATED_DESC = 'COMPANIES_STDDEV_POPULATION_DATE_CREATED_DESC',
  COMPANIES_STDDEV_POPULATION_DATE_UPDATED_ASC = 'COMPANIES_STDDEV_POPULATION_DATE_UPDATED_ASC',
  COMPANIES_STDDEV_POPULATION_DATE_UPDATED_DESC = 'COMPANIES_STDDEV_POPULATION_DATE_UPDATED_DESC',
  COMPANIES_STDDEV_POPULATION_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_STDDEV_POPULATION_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_STDDEV_POPULATION_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_STDDEV_POPULATION_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_STDDEV_POPULATION_FULL_NAME_ASC = 'COMPANIES_STDDEV_POPULATION_FULL_NAME_ASC',
  COMPANIES_STDDEV_POPULATION_FULL_NAME_DESC = 'COMPANIES_STDDEV_POPULATION_FULL_NAME_DESC',
  COMPANIES_STDDEV_POPULATION_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_STDDEV_POPULATION_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_STDDEV_POPULATION_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_STDDEV_POPULATION_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_STDDEV_POPULATION_REMARK_ASC = 'COMPANIES_STDDEV_POPULATION_REMARK_ASC',
  COMPANIES_STDDEV_POPULATION_REMARK_DESC = 'COMPANIES_STDDEV_POPULATION_REMARK_DESC',
  COMPANIES_STDDEV_POPULATION_USER_CREATED_ASC = 'COMPANIES_STDDEV_POPULATION_USER_CREATED_ASC',
  COMPANIES_STDDEV_POPULATION_USER_CREATED_DESC = 'COMPANIES_STDDEV_POPULATION_USER_CREATED_DESC',
  COMPANIES_STDDEV_POPULATION_USER_UPDATED_ASC = 'COMPANIES_STDDEV_POPULATION_USER_UPDATED_ASC',
  COMPANIES_STDDEV_POPULATION_USER_UPDATED_DESC = 'COMPANIES_STDDEV_POPULATION_USER_UPDATED_DESC',
  COMPANIES_STDDEV_SAMPLE_ABBREVIATION_ASC = 'COMPANIES_STDDEV_SAMPLE_ABBREVIATION_ASC',
  COMPANIES_STDDEV_SAMPLE_ABBREVIATION_DESC = 'COMPANIES_STDDEV_SAMPLE_ABBREVIATION_DESC',
  COMPANIES_STDDEV_SAMPLE_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_STDDEV_SAMPLE_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_STDDEV_SAMPLE_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_STDDEV_SAMPLE_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_STDDEV_SAMPLE_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_STDDEV_SAMPLE_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_STDDEV_SAMPLE_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_STDDEV_SAMPLE_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_STDDEV_SAMPLE_COMPANY_GROUP_ID_ASC = 'COMPANIES_STDDEV_SAMPLE_COMPANY_GROUP_ID_ASC',
  COMPANIES_STDDEV_SAMPLE_COMPANY_GROUP_ID_DESC = 'COMPANIES_STDDEV_SAMPLE_COMPANY_GROUP_ID_DESC',
  COMPANIES_STDDEV_SAMPLE_COMPANY_ID_ASC = 'COMPANIES_STDDEV_SAMPLE_COMPANY_ID_ASC',
  COMPANIES_STDDEV_SAMPLE_COMPANY_ID_DESC = 'COMPANIES_STDDEV_SAMPLE_COMPANY_ID_DESC',
  COMPANIES_STDDEV_SAMPLE_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_STDDEV_SAMPLE_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_STDDEV_SAMPLE_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_STDDEV_SAMPLE_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_STDDEV_SAMPLE_DATE_CREATED_ASC = 'COMPANIES_STDDEV_SAMPLE_DATE_CREATED_ASC',
  COMPANIES_STDDEV_SAMPLE_DATE_CREATED_DESC = 'COMPANIES_STDDEV_SAMPLE_DATE_CREATED_DESC',
  COMPANIES_STDDEV_SAMPLE_DATE_UPDATED_ASC = 'COMPANIES_STDDEV_SAMPLE_DATE_UPDATED_ASC',
  COMPANIES_STDDEV_SAMPLE_DATE_UPDATED_DESC = 'COMPANIES_STDDEV_SAMPLE_DATE_UPDATED_DESC',
  COMPANIES_STDDEV_SAMPLE_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_STDDEV_SAMPLE_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_STDDEV_SAMPLE_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_STDDEV_SAMPLE_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_STDDEV_SAMPLE_FULL_NAME_ASC = 'COMPANIES_STDDEV_SAMPLE_FULL_NAME_ASC',
  COMPANIES_STDDEV_SAMPLE_FULL_NAME_DESC = 'COMPANIES_STDDEV_SAMPLE_FULL_NAME_DESC',
  COMPANIES_STDDEV_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_STDDEV_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_STDDEV_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_STDDEV_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_STDDEV_SAMPLE_REMARK_ASC = 'COMPANIES_STDDEV_SAMPLE_REMARK_ASC',
  COMPANIES_STDDEV_SAMPLE_REMARK_DESC = 'COMPANIES_STDDEV_SAMPLE_REMARK_DESC',
  COMPANIES_STDDEV_SAMPLE_USER_CREATED_ASC = 'COMPANIES_STDDEV_SAMPLE_USER_CREATED_ASC',
  COMPANIES_STDDEV_SAMPLE_USER_CREATED_DESC = 'COMPANIES_STDDEV_SAMPLE_USER_CREATED_DESC',
  COMPANIES_STDDEV_SAMPLE_USER_UPDATED_ASC = 'COMPANIES_STDDEV_SAMPLE_USER_UPDATED_ASC',
  COMPANIES_STDDEV_SAMPLE_USER_UPDATED_DESC = 'COMPANIES_STDDEV_SAMPLE_USER_UPDATED_DESC',
  COMPANIES_SUM_ABBREVIATION_ASC = 'COMPANIES_SUM_ABBREVIATION_ASC',
  COMPANIES_SUM_ABBREVIATION_DESC = 'COMPANIES_SUM_ABBREVIATION_DESC',
  COMPANIES_SUM_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_SUM_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_SUM_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_SUM_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_SUM_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_SUM_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_SUM_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_SUM_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_SUM_COMPANY_GROUP_ID_ASC = 'COMPANIES_SUM_COMPANY_GROUP_ID_ASC',
  COMPANIES_SUM_COMPANY_GROUP_ID_DESC = 'COMPANIES_SUM_COMPANY_GROUP_ID_DESC',
  COMPANIES_SUM_COMPANY_ID_ASC = 'COMPANIES_SUM_COMPANY_ID_ASC',
  COMPANIES_SUM_COMPANY_ID_DESC = 'COMPANIES_SUM_COMPANY_ID_DESC',
  COMPANIES_SUM_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_SUM_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_SUM_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_SUM_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_SUM_DATE_CREATED_ASC = 'COMPANIES_SUM_DATE_CREATED_ASC',
  COMPANIES_SUM_DATE_CREATED_DESC = 'COMPANIES_SUM_DATE_CREATED_DESC',
  COMPANIES_SUM_DATE_UPDATED_ASC = 'COMPANIES_SUM_DATE_UPDATED_ASC',
  COMPANIES_SUM_DATE_UPDATED_DESC = 'COMPANIES_SUM_DATE_UPDATED_DESC',
  COMPANIES_SUM_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_SUM_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_SUM_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_SUM_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_SUM_FULL_NAME_ASC = 'COMPANIES_SUM_FULL_NAME_ASC',
  COMPANIES_SUM_FULL_NAME_DESC = 'COMPANIES_SUM_FULL_NAME_DESC',
  COMPANIES_SUM_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_SUM_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_SUM_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_SUM_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_SUM_REMARK_ASC = 'COMPANIES_SUM_REMARK_ASC',
  COMPANIES_SUM_REMARK_DESC = 'COMPANIES_SUM_REMARK_DESC',
  COMPANIES_SUM_USER_CREATED_ASC = 'COMPANIES_SUM_USER_CREATED_ASC',
  COMPANIES_SUM_USER_CREATED_DESC = 'COMPANIES_SUM_USER_CREATED_DESC',
  COMPANIES_SUM_USER_UPDATED_ASC = 'COMPANIES_SUM_USER_UPDATED_ASC',
  COMPANIES_SUM_USER_UPDATED_DESC = 'COMPANIES_SUM_USER_UPDATED_DESC',
  COMPANIES_VARIANCE_POPULATION_ABBREVIATION_ASC = 'COMPANIES_VARIANCE_POPULATION_ABBREVIATION_ASC',
  COMPANIES_VARIANCE_POPULATION_ABBREVIATION_DESC = 'COMPANIES_VARIANCE_POPULATION_ABBREVIATION_DESC',
  COMPANIES_VARIANCE_POPULATION_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_VARIANCE_POPULATION_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_VARIANCE_POPULATION_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_VARIANCE_POPULATION_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_VARIANCE_POPULATION_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_VARIANCE_POPULATION_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_VARIANCE_POPULATION_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_VARIANCE_POPULATION_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_VARIANCE_POPULATION_COMPANY_GROUP_ID_ASC = 'COMPANIES_VARIANCE_POPULATION_COMPANY_GROUP_ID_ASC',
  COMPANIES_VARIANCE_POPULATION_COMPANY_GROUP_ID_DESC = 'COMPANIES_VARIANCE_POPULATION_COMPANY_GROUP_ID_DESC',
  COMPANIES_VARIANCE_POPULATION_COMPANY_ID_ASC = 'COMPANIES_VARIANCE_POPULATION_COMPANY_ID_ASC',
  COMPANIES_VARIANCE_POPULATION_COMPANY_ID_DESC = 'COMPANIES_VARIANCE_POPULATION_COMPANY_ID_DESC',
  COMPANIES_VARIANCE_POPULATION_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_VARIANCE_POPULATION_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_VARIANCE_POPULATION_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_VARIANCE_POPULATION_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_VARIANCE_POPULATION_DATE_CREATED_ASC = 'COMPANIES_VARIANCE_POPULATION_DATE_CREATED_ASC',
  COMPANIES_VARIANCE_POPULATION_DATE_CREATED_DESC = 'COMPANIES_VARIANCE_POPULATION_DATE_CREATED_DESC',
  COMPANIES_VARIANCE_POPULATION_DATE_UPDATED_ASC = 'COMPANIES_VARIANCE_POPULATION_DATE_UPDATED_ASC',
  COMPANIES_VARIANCE_POPULATION_DATE_UPDATED_DESC = 'COMPANIES_VARIANCE_POPULATION_DATE_UPDATED_DESC',
  COMPANIES_VARIANCE_POPULATION_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_VARIANCE_POPULATION_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_VARIANCE_POPULATION_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_VARIANCE_POPULATION_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_VARIANCE_POPULATION_FULL_NAME_ASC = 'COMPANIES_VARIANCE_POPULATION_FULL_NAME_ASC',
  COMPANIES_VARIANCE_POPULATION_FULL_NAME_DESC = 'COMPANIES_VARIANCE_POPULATION_FULL_NAME_DESC',
  COMPANIES_VARIANCE_POPULATION_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_VARIANCE_POPULATION_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_VARIANCE_POPULATION_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_VARIANCE_POPULATION_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_VARIANCE_POPULATION_REMARK_ASC = 'COMPANIES_VARIANCE_POPULATION_REMARK_ASC',
  COMPANIES_VARIANCE_POPULATION_REMARK_DESC = 'COMPANIES_VARIANCE_POPULATION_REMARK_DESC',
  COMPANIES_VARIANCE_POPULATION_USER_CREATED_ASC = 'COMPANIES_VARIANCE_POPULATION_USER_CREATED_ASC',
  COMPANIES_VARIANCE_POPULATION_USER_CREATED_DESC = 'COMPANIES_VARIANCE_POPULATION_USER_CREATED_DESC',
  COMPANIES_VARIANCE_POPULATION_USER_UPDATED_ASC = 'COMPANIES_VARIANCE_POPULATION_USER_UPDATED_ASC',
  COMPANIES_VARIANCE_POPULATION_USER_UPDATED_DESC = 'COMPANIES_VARIANCE_POPULATION_USER_UPDATED_DESC',
  COMPANIES_VARIANCE_SAMPLE_ABBREVIATION_ASC = 'COMPANIES_VARIANCE_SAMPLE_ABBREVIATION_ASC',
  COMPANIES_VARIANCE_SAMPLE_ABBREVIATION_DESC = 'COMPANIES_VARIANCE_SAMPLE_ABBREVIATION_DESC',
  COMPANIES_VARIANCE_SAMPLE_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_VARIANCE_SAMPLE_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_VARIANCE_SAMPLE_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_VARIANCE_SAMPLE_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_VARIANCE_SAMPLE_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_VARIANCE_SAMPLE_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_VARIANCE_SAMPLE_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_VARIANCE_SAMPLE_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_VARIANCE_SAMPLE_COMPANY_GROUP_ID_ASC = 'COMPANIES_VARIANCE_SAMPLE_COMPANY_GROUP_ID_ASC',
  COMPANIES_VARIANCE_SAMPLE_COMPANY_GROUP_ID_DESC = 'COMPANIES_VARIANCE_SAMPLE_COMPANY_GROUP_ID_DESC',
  COMPANIES_VARIANCE_SAMPLE_COMPANY_ID_ASC = 'COMPANIES_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  COMPANIES_VARIANCE_SAMPLE_COMPANY_ID_DESC = 'COMPANIES_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  COMPANIES_VARIANCE_SAMPLE_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_VARIANCE_SAMPLE_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_VARIANCE_SAMPLE_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_VARIANCE_SAMPLE_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_VARIANCE_SAMPLE_DATE_CREATED_ASC = 'COMPANIES_VARIANCE_SAMPLE_DATE_CREATED_ASC',
  COMPANIES_VARIANCE_SAMPLE_DATE_CREATED_DESC = 'COMPANIES_VARIANCE_SAMPLE_DATE_CREATED_DESC',
  COMPANIES_VARIANCE_SAMPLE_DATE_UPDATED_ASC = 'COMPANIES_VARIANCE_SAMPLE_DATE_UPDATED_ASC',
  COMPANIES_VARIANCE_SAMPLE_DATE_UPDATED_DESC = 'COMPANIES_VARIANCE_SAMPLE_DATE_UPDATED_DESC',
  COMPANIES_VARIANCE_SAMPLE_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_VARIANCE_SAMPLE_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_VARIANCE_SAMPLE_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_VARIANCE_SAMPLE_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_VARIANCE_SAMPLE_FULL_NAME_ASC = 'COMPANIES_VARIANCE_SAMPLE_FULL_NAME_ASC',
  COMPANIES_VARIANCE_SAMPLE_FULL_NAME_DESC = 'COMPANIES_VARIANCE_SAMPLE_FULL_NAME_DESC',
  COMPANIES_VARIANCE_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_VARIANCE_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_VARIANCE_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_VARIANCE_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_VARIANCE_SAMPLE_REMARK_ASC = 'COMPANIES_VARIANCE_SAMPLE_REMARK_ASC',
  COMPANIES_VARIANCE_SAMPLE_REMARK_DESC = 'COMPANIES_VARIANCE_SAMPLE_REMARK_DESC',
  COMPANIES_VARIANCE_SAMPLE_USER_CREATED_ASC = 'COMPANIES_VARIANCE_SAMPLE_USER_CREATED_ASC',
  COMPANIES_VARIANCE_SAMPLE_USER_CREATED_DESC = 'COMPANIES_VARIANCE_SAMPLE_USER_CREATED_DESC',
  COMPANIES_VARIANCE_SAMPLE_USER_UPDATED_ASC = 'COMPANIES_VARIANCE_SAMPLE_USER_UPDATED_ASC',
  COMPANIES_VARIANCE_SAMPLE_USER_UPDATED_DESC = 'COMPANIES_VARIANCE_SAMPLE_USER_UPDATED_DESC',
  COMPANY_GROUP_ID_ASC = 'COMPANY_GROUP_ID_ASC',
  COMPANY_GROUP_ID_DESC = 'COMPANY_GROUP_ID_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__COUNT_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__COUNT_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__COUNT_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__COUNT_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_COMPANY_GROUP_ID_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_COMPANY_GROUP_ID_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_COMPANY_GROUP_ID_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_COMPANY_GROUP_ID_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_DATE_CREATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_DATE_CREATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_DATE_CREATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_DATE_CREATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_DATE_UPDATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_DATE_UPDATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_DATE_UPDATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_DATE_UPDATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_EMAIL_DOMAIN_ID_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_EMAIL_DOMAIN_ID_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_EMAIL_DOMAIN_ID_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_EMAIL_DOMAIN_ID_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_REMARK_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_REMARK_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_REMARK_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_REMARK_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_USER_CREATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_USER_CREATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_USER_CREATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_USER_CREATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_USER_UPDATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_USER_UPDATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_USER_UPDATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MAX_USER_UPDATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_COMPANY_GROUP_ID_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_COMPANY_GROUP_ID_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_COMPANY_GROUP_ID_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_COMPANY_GROUP_ID_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_DATE_CREATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_DATE_CREATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_DATE_CREATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_DATE_CREATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_DATE_UPDATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_DATE_UPDATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_DATE_UPDATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_DATE_UPDATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_EMAIL_DOMAIN_ID_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_EMAIL_DOMAIN_ID_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_EMAIL_DOMAIN_ID_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_EMAIL_DOMAIN_ID_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_REMARK_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_REMARK_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_REMARK_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_REMARK_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_USER_CREATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_USER_CREATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_USER_CREATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_USER_CREATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_USER_UPDATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_USER_UPDATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_USER_UPDATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_COMPANY_GROUP_ID__MIN_USER_UPDATED_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface CompanyHavingAverageInput {
  companyClosedDate?: InputMaybe<HavingDatetimeFilter>;
  companyEstablishedDate?: InputMaybe<HavingDatetimeFilter>;
  companyGroupId?: InputMaybe<HavingBigintFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  notifyPeriodContractEnd?: InputMaybe<HavingIntFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface CompanyHavingDistinctCountInput {
  companyClosedDate?: InputMaybe<HavingDatetimeFilter>;
  companyEstablishedDate?: InputMaybe<HavingDatetimeFilter>;
  companyGroupId?: InputMaybe<HavingBigintFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  notifyPeriodContractEnd?: InputMaybe<HavingIntFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface CompanyHavingInput {
  AND?: InputMaybe<Array<CompanyHavingInput>>;
  OR?: InputMaybe<Array<CompanyHavingInput>>;
  average?: InputMaybe<CompanyHavingAverageInput>;
  distinctCount?: InputMaybe<CompanyHavingDistinctCountInput>;
  max?: InputMaybe<CompanyHavingMaxInput>;
  min?: InputMaybe<CompanyHavingMinInput>;
  stddevPopulation?: InputMaybe<CompanyHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<CompanyHavingStddevSampleInput>;
  sum?: InputMaybe<CompanyHavingSumInput>;
  variancePopulation?: InputMaybe<CompanyHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<CompanyHavingVarianceSampleInput>;
}

export interface CompanyHavingMaxInput {
  companyClosedDate?: InputMaybe<HavingDatetimeFilter>;
  companyEstablishedDate?: InputMaybe<HavingDatetimeFilter>;
  companyGroupId?: InputMaybe<HavingBigintFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  notifyPeriodContractEnd?: InputMaybe<HavingIntFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface CompanyHavingMinInput {
  companyClosedDate?: InputMaybe<HavingDatetimeFilter>;
  companyEstablishedDate?: InputMaybe<HavingDatetimeFilter>;
  companyGroupId?: InputMaybe<HavingBigintFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  notifyPeriodContractEnd?: InputMaybe<HavingIntFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface CompanyHavingStddevPopulationInput {
  companyClosedDate?: InputMaybe<HavingDatetimeFilter>;
  companyEstablishedDate?: InputMaybe<HavingDatetimeFilter>;
  companyGroupId?: InputMaybe<HavingBigintFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  notifyPeriodContractEnd?: InputMaybe<HavingIntFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface CompanyHavingStddevSampleInput {
  companyClosedDate?: InputMaybe<HavingDatetimeFilter>;
  companyEstablishedDate?: InputMaybe<HavingDatetimeFilter>;
  companyGroupId?: InputMaybe<HavingBigintFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  notifyPeriodContractEnd?: InputMaybe<HavingIntFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface CompanyHavingSumInput {
  companyClosedDate?: InputMaybe<HavingDatetimeFilter>;
  companyEstablishedDate?: InputMaybe<HavingDatetimeFilter>;
  companyGroupId?: InputMaybe<HavingBigintFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  notifyPeriodContractEnd?: InputMaybe<HavingIntFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface CompanyHavingVariancePopulationInput {
  companyClosedDate?: InputMaybe<HavingDatetimeFilter>;
  companyEstablishedDate?: InputMaybe<HavingDatetimeFilter>;
  companyGroupId?: InputMaybe<HavingBigintFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  notifyPeriodContractEnd?: InputMaybe<HavingIntFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface CompanyHavingVarianceSampleInput {
  companyClosedDate?: InputMaybe<HavingDatetimeFilter>;
  companyEstablishedDate?: InputMaybe<HavingDatetimeFilter>;
  companyGroupId?: InputMaybe<HavingBigintFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  notifyPeriodContractEnd?: InputMaybe<HavingIntFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface CompanyInput {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName: Scalars['String']['input'];
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyLocalHr extends Node {
  __typename?: 'CompanyLocalHr';
  company: Company;
  companyId: Scalars['BigInt']['output'];
  companyLocalHrId: Scalars['BigInt']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  employee: Employee;
  employeeId: Scalars['BigInt']['output'];
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface CompanyLocalHrCompanyIdFkeyCompanyCreateInput {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName: Scalars['String']['input'];
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyLocalHrCompanyIdFkeyCompanyLocalHrCreateInput {
  company?: InputMaybe<CompanyLocalHrCompanyIdFkeyInput>;
  companyLocalHrId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyLocalHrCompanyIdFkeyInput {
  connectByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyConnect>;
  connectByCompanyId?: InputMaybe<CompanyCompanyPkeyConnect>;
  connectByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyConnect>;
  connectByNodeId?: InputMaybe<CompanyNodeIdConnect>;
  create?: InputMaybe<CompanyLocalHrCompanyIdFkeyCompanyCreateInput>;
  deleteByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyDelete>;
  deleteByCompanyId?: InputMaybe<CompanyCompanyPkeyDelete>;
  deleteByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyDelete>;
  deleteByNodeId?: InputMaybe<CompanyNodeIdDelete>;
  updateByAbbreviation?: InputMaybe<CompanyOnCompanyLocalHrForCompanyLocalHrCompanyIdFkeyUsingCompanyAbbreviationKeyUpdate>;
  updateByCompanyId?: InputMaybe<CompanyOnCompanyLocalHrForCompanyLocalHrCompanyIdFkeyUsingCompanyPkeyUpdate>;
  updateByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyOnCompanyLocalHrForCompanyLocalHrCompanyIdFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate>;
  updateByNodeId?: InputMaybe<CompanyLocalHrOnCompanyLocalHrForCompanyLocalHrCompanyIdFkeyNodeIdUpdate>;
}

export interface CompanyLocalHrCompanyIdFkeyInverseInput {
  connectByCompanyIdAndEmployeeId?: InputMaybe<Array<CompanyLocalHrCompanyLocalHrCompanyIdEmployeeIdKeyConnect>>;
  connectByCompanyLocalHrId?: InputMaybe<Array<CompanyLocalHrCompanyLocalHrPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<CompanyLocalHrNodeIdConnect>>;
  create?: InputMaybe<Array<CompanyLocalHrCompanyIdFkeyCompanyLocalHrCreateInput>>;
  deleteByCompanyIdAndEmployeeId?: InputMaybe<Array<CompanyLocalHrCompanyLocalHrCompanyIdEmployeeIdKeyDelete>>;
  deleteByCompanyLocalHrId?: InputMaybe<Array<CompanyLocalHrCompanyLocalHrPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<CompanyLocalHrNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyIdAndEmployeeId?: InputMaybe<Array<CompanyLocalHrOnCompanyLocalHrForCompanyLocalHrCompanyIdFkeyUsingCompanyLocalHrCompanyIdEmployeeIdKeyUpdate>>;
  updateByCompanyLocalHrId?: InputMaybe<Array<CompanyLocalHrOnCompanyLocalHrForCompanyLocalHrCompanyIdFkeyUsingCompanyLocalHrPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<CompanyOnCompanyLocalHrForCompanyLocalHrCompanyIdFkeyNodeIdUpdate>>;
}

export interface CompanyLocalHrCompanyLocalHrCompanyIdEmployeeIdKeyConnect {
  companyId: Scalars['BigInt']['input'];
  employeeId: Scalars['BigInt']['input'];
}

export interface CompanyLocalHrCompanyLocalHrCompanyIdEmployeeIdKeyDelete {
  companyId: Scalars['BigInt']['input'];
  employeeId: Scalars['BigInt']['input'];
}

export interface CompanyLocalHrCompanyLocalHrPkeyConnect {
  companyLocalHrId: Scalars['BigInt']['input'];
}

export interface CompanyLocalHrCompanyLocalHrPkeyDelete {
  companyLocalHrId: Scalars['BigInt']['input'];
}

export interface CompanyLocalHrCondition {
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrId?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyLocalHrEmployeeIdFkeyCompanyLocalHrCreateInput {
  company?: InputMaybe<CompanyLocalHrCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyLocalHrEmployeeIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface CompanyLocalHrEmployeeIdFkeyInput {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<CompanyLocalHrEmployeeIdFkeyEmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnCompanyLocalHrForCompanyLocalHrEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnCompanyLocalHrForCompanyLocalHrEmployeeIdFkeyUsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<CompanyLocalHrOnCompanyLocalHrForCompanyLocalHrEmployeeIdFkeyNodeIdUpdate>;
}

export interface CompanyLocalHrEmployeeIdFkeyInverseInput {
  connectByCompanyIdAndEmployeeId?: InputMaybe<Array<CompanyLocalHrCompanyLocalHrCompanyIdEmployeeIdKeyConnect>>;
  connectByCompanyLocalHrId?: InputMaybe<Array<CompanyLocalHrCompanyLocalHrPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<CompanyLocalHrNodeIdConnect>>;
  create?: InputMaybe<Array<CompanyLocalHrEmployeeIdFkeyCompanyLocalHrCreateInput>>;
  deleteByCompanyIdAndEmployeeId?: InputMaybe<Array<CompanyLocalHrCompanyLocalHrCompanyIdEmployeeIdKeyDelete>>;
  deleteByCompanyLocalHrId?: InputMaybe<Array<CompanyLocalHrCompanyLocalHrPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<CompanyLocalHrNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyIdAndEmployeeId?: InputMaybe<Array<CompanyLocalHrOnCompanyLocalHrForCompanyLocalHrEmployeeIdFkeyUsingCompanyLocalHrCompanyIdEmployeeIdKeyUpdate>>;
  updateByCompanyLocalHrId?: InputMaybe<Array<CompanyLocalHrOnCompanyLocalHrForCompanyLocalHrEmployeeIdFkeyUsingCompanyLocalHrPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<EmployeeOnCompanyLocalHrForCompanyLocalHrEmployeeIdFkeyNodeIdUpdate>>;
}

export interface CompanyLocalHrFilter {
  and?: InputMaybe<Array<CompanyLocalHrFilter>>;
  company?: InputMaybe<CompanyFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  companyLocalHrId?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  employee?: InputMaybe<EmployeeFilter>;
  employeeId?: InputMaybe<BigIntFilter>;
  not?: InputMaybe<CompanyLocalHrFilter>;
  or?: InputMaybe<Array<CompanyLocalHrFilter>>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface CompanyLocalHrInput {
  company?: InputMaybe<CompanyLocalHrCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyLocalHrNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface CompanyLocalHrNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface CompanyLocalHrOnCompanyLocalHrForCompanyLocalHrCompanyIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyPatch;
}

export interface CompanyLocalHrOnCompanyLocalHrForCompanyLocalHrCompanyIdFkeyUsingCompanyLocalHrCompanyIdEmployeeIdKeyUpdate {
  companyId: Scalars['BigInt']['input'];
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateCompanyLocalHrOnCompanyLocalHrForCompanyLocalHrCompanyIdFkeyPatch;
}

export interface CompanyLocalHrOnCompanyLocalHrForCompanyLocalHrCompanyIdFkeyUsingCompanyLocalHrPkeyUpdate {
  companyLocalHrId: Scalars['BigInt']['input'];
  patch: UpdateCompanyLocalHrOnCompanyLocalHrForCompanyLocalHrCompanyIdFkeyPatch;
}

export interface CompanyLocalHrOnCompanyLocalHrForCompanyLocalHrEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface CompanyLocalHrOnCompanyLocalHrForCompanyLocalHrEmployeeIdFkeyUsingCompanyLocalHrCompanyIdEmployeeIdKeyUpdate {
  companyId: Scalars['BigInt']['input'];
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateCompanyLocalHrOnCompanyLocalHrForCompanyLocalHrEmployeeIdFkeyPatch;
}

export interface CompanyLocalHrOnCompanyLocalHrForCompanyLocalHrEmployeeIdFkeyUsingCompanyLocalHrPkeyUpdate {
  companyLocalHrId: Scalars['BigInt']['input'];
  patch: UpdateCompanyLocalHrOnCompanyLocalHrForCompanyLocalHrEmployeeIdFkeyPatch;
}

export interface CompanyLocalHrOnCompanyLocalHrForCompanyLocalHrUserCreatedFkeyUsingCompanyLocalHrCompanyIdEmployeeIdKeyUpdate {
  companyId: Scalars['BigInt']['input'];
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateCompanyLocalHrOnCompanyLocalHrForCompanyLocalHrUserCreatedFkeyPatch;
}

export interface CompanyLocalHrOnCompanyLocalHrForCompanyLocalHrUserCreatedFkeyUsingCompanyLocalHrPkeyUpdate {
  companyLocalHrId: Scalars['BigInt']['input'];
  patch: UpdateCompanyLocalHrOnCompanyLocalHrForCompanyLocalHrUserCreatedFkeyPatch;
}

export interface CompanyLocalHrOnCompanyLocalHrForCompanyLocalHrUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface CompanyLocalHrOnCompanyLocalHrForCompanyLocalHrUserUpdatedFkeyUsingCompanyLocalHrCompanyIdEmployeeIdKeyUpdate {
  companyId: Scalars['BigInt']['input'];
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateCompanyLocalHrOnCompanyLocalHrForCompanyLocalHrUserUpdatedFkeyPatch;
}

export interface CompanyLocalHrOnCompanyLocalHrForCompanyLocalHrUserUpdatedFkeyUsingCompanyLocalHrPkeyUpdate {
  companyLocalHrId: Scalars['BigInt']['input'];
  patch: UpdateCompanyLocalHrOnCompanyLocalHrForCompanyLocalHrUserUpdatedFkeyPatch;
}

export interface CompanyLocalHrPatch {
  company?: InputMaybe<CompanyLocalHrCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyLocalHrUserCreatedFkeyInverseInput {
  connectByCompanyIdAndEmployeeId?: InputMaybe<Array<CompanyLocalHrCompanyLocalHrCompanyIdEmployeeIdKeyConnect>>;
  connectByCompanyLocalHrId?: InputMaybe<Array<CompanyLocalHrCompanyLocalHrPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<CompanyLocalHrNodeIdConnect>>;
  deleteByCompanyIdAndEmployeeId?: InputMaybe<Array<CompanyLocalHrCompanyLocalHrCompanyIdEmployeeIdKeyDelete>>;
  deleteByCompanyLocalHrId?: InputMaybe<Array<CompanyLocalHrCompanyLocalHrPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<CompanyLocalHrNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyIdAndEmployeeId?: InputMaybe<Array<CompanyLocalHrOnCompanyLocalHrForCompanyLocalHrUserCreatedFkeyUsingCompanyLocalHrCompanyIdEmployeeIdKeyUpdate>>;
  updateByCompanyLocalHrId?: InputMaybe<Array<CompanyLocalHrOnCompanyLocalHrForCompanyLocalHrUserCreatedFkeyUsingCompanyLocalHrPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnCompanyLocalHrForCompanyLocalHrUserCreatedFkeyNodeIdUpdate>>;
}

export interface CompanyLocalHrUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnCompanyLocalHrForCompanyLocalHrUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<CompanyLocalHrOnCompanyLocalHrForCompanyLocalHrUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnCompanyLocalHrForCompanyLocalHrUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface CompanyLocalHrUserUpdatedFkeyInverseInput {
  connectByCompanyIdAndEmployeeId?: InputMaybe<Array<CompanyLocalHrCompanyLocalHrCompanyIdEmployeeIdKeyConnect>>;
  connectByCompanyLocalHrId?: InputMaybe<Array<CompanyLocalHrCompanyLocalHrPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<CompanyLocalHrNodeIdConnect>>;
  deleteByCompanyIdAndEmployeeId?: InputMaybe<Array<CompanyLocalHrCompanyLocalHrCompanyIdEmployeeIdKeyDelete>>;
  deleteByCompanyLocalHrId?: InputMaybe<Array<CompanyLocalHrCompanyLocalHrPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<CompanyLocalHrNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyIdAndEmployeeId?: InputMaybe<Array<CompanyLocalHrOnCompanyLocalHrForCompanyLocalHrUserUpdatedFkeyUsingCompanyLocalHrCompanyIdEmployeeIdKeyUpdate>>;
  updateByCompanyLocalHrId?: InputMaybe<Array<CompanyLocalHrOnCompanyLocalHrForCompanyLocalHrUserUpdatedFkeyUsingCompanyLocalHrPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnCompanyLocalHrForCompanyLocalHrUserUpdatedFkeyNodeIdUpdate>>;
}

export interface CompanyLocalHrsConnection {
  __typename?: 'CompanyLocalHrsConnection';
  edges: Array<CompanyLocalHrsEdge>;
  nodes: Array<CompanyLocalHr>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface CompanyLocalHrsEdge {
  __typename?: 'CompanyLocalHrsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: CompanyLocalHr;
}

export enum CompanyLocalHrsOrderBy {
  COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC',
  COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_ASC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_ASC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_DESC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_DESC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANY_BY_COMPANY_ID__REMARK_ASC = 'COMPANY_BY_COMPANY_ID__REMARK_ASC',
  COMPANY_BY_COMPANY_ID__REMARK_DESC = 'COMPANY_BY_COMPANY_ID__REMARK_DESC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC',
  COMPANY_ID_ASC = 'COMPANY_ID_ASC',
  COMPANY_ID_DESC = 'COMPANY_ID_DESC',
  COMPANY_LOCAL_HR_ID_ASC = 'COMPANY_LOCAL_HR_ID_ASC',
  COMPANY_LOCAL_HR_ID_DESC = 'COMPANY_LOCAL_HR_ID_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface CompanyMaxAggregateFilter {
  companyGroupId?: InputMaybe<BigIntFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  notifyPeriodContractEnd?: InputMaybe<IntFilter>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface CompanyMaxAggregates {
  __typename?: 'CompanyMaxAggregates';
  companyGroupId?: Maybe<Scalars['BigInt']['output']>;
  companyId?: Maybe<Scalars['BigInt']['output']>;
  notifyPeriodContractEnd?: Maybe<Scalars['Int']['output']>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface CompanyMinAggregateFilter {
  companyGroupId?: InputMaybe<BigIntFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  notifyPeriodContractEnd?: InputMaybe<IntFilter>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface CompanyMinAggregates {
  __typename?: 'CompanyMinAggregates';
  companyGroupId?: Maybe<Scalars['BigInt']['output']>;
  companyId?: Maybe<Scalars['BigInt']['output']>;
  notifyPeriodContractEnd?: Maybe<Scalars['Int']['output']>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface CompanyNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface CompanyNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface CompanyOnCompanyExternalAccountDisableForCompanyExternalAccountDisableCompanyIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyExternalAccountDisablePatch;
}

export interface CompanyOnCompanyExternalAccountDisableForCompanyExternalAccountDisableCompanyIdFkeyUsingCompanyAbbreviationKeyUpdate {
  abbreviation: Scalars['String']['input'];
  patch: UpdateCompanyOnCompanyExternalAccountDisableForCompanyExternalAccountDisableCompanyIdFkeyPatch;
}

export interface CompanyOnCompanyExternalAccountDisableForCompanyExternalAccountDisableCompanyIdFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  patch: UpdateCompanyOnCompanyExternalAccountDisableForCompanyExternalAccountDisableCompanyIdFkeyPatch;
}

export interface CompanyOnCompanyExternalAccountDisableForCompanyExternalAccountDisableCompanyIdFkeyUsingCompanyPkeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdateCompanyOnCompanyExternalAccountDisableForCompanyExternalAccountDisableCompanyIdFkeyPatch;
}

export interface CompanyOnCompanyForCompanyCompanyGroupIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyGroupPatch;
}

export interface CompanyOnCompanyForCompanyCompanyGroupIdFkeyUsingCompanyAbbreviationKeyUpdate {
  abbreviation: Scalars['String']['input'];
  patch: UpdateCompanyOnCompanyForCompanyCompanyGroupIdFkeyPatch;
}

export interface CompanyOnCompanyForCompanyCompanyGroupIdFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  patch: UpdateCompanyOnCompanyForCompanyCompanyGroupIdFkeyPatch;
}

export interface CompanyOnCompanyForCompanyCompanyGroupIdFkeyUsingCompanyPkeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdateCompanyOnCompanyForCompanyCompanyGroupIdFkeyPatch;
}

export interface CompanyOnCompanyForCompanyCountryCodeAlpha2FkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CountryPatch;
}

export interface CompanyOnCompanyForCompanyCountryCodeAlpha2FkeyUsingCompanyAbbreviationKeyUpdate {
  abbreviation: Scalars['String']['input'];
  patch: UpdateCompanyOnCompanyForCompanyCountryCodeAlpha2FkeyPatch;
}

export interface CompanyOnCompanyForCompanyCountryCodeAlpha2FkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  patch: UpdateCompanyOnCompanyForCompanyCountryCodeAlpha2FkeyPatch;
}

export interface CompanyOnCompanyForCompanyCountryCodeAlpha2FkeyUsingCompanyPkeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdateCompanyOnCompanyForCompanyCountryCodeAlpha2FkeyPatch;
}

export interface CompanyOnCompanyForCompanyUserCreatedFkeyUsingCompanyAbbreviationKeyUpdate {
  abbreviation: Scalars['String']['input'];
  patch: UpdateCompanyOnCompanyForCompanyUserCreatedFkeyPatch;
}

export interface CompanyOnCompanyForCompanyUserCreatedFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  patch: UpdateCompanyOnCompanyForCompanyUserCreatedFkeyPatch;
}

export interface CompanyOnCompanyForCompanyUserCreatedFkeyUsingCompanyPkeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdateCompanyOnCompanyForCompanyUserCreatedFkeyPatch;
}

export interface CompanyOnCompanyForCompanyUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface CompanyOnCompanyForCompanyUserUpdatedFkeyUsingCompanyAbbreviationKeyUpdate {
  abbreviation: Scalars['String']['input'];
  patch: UpdateCompanyOnCompanyForCompanyUserUpdatedFkeyPatch;
}

export interface CompanyOnCompanyForCompanyUserUpdatedFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  patch: UpdateCompanyOnCompanyForCompanyUserUpdatedFkeyPatch;
}

export interface CompanyOnCompanyForCompanyUserUpdatedFkeyUsingCompanyPkeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdateCompanyOnCompanyForCompanyUserUpdatedFkeyPatch;
}

export interface CompanyOnCompanyLocalHrForCompanyLocalHrCompanyIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyLocalHrPatch;
}

export interface CompanyOnCompanyLocalHrForCompanyLocalHrCompanyIdFkeyUsingCompanyAbbreviationKeyUpdate {
  abbreviation: Scalars['String']['input'];
  patch: UpdateCompanyOnCompanyLocalHrForCompanyLocalHrCompanyIdFkeyPatch;
}

export interface CompanyOnCompanyLocalHrForCompanyLocalHrCompanyIdFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  patch: UpdateCompanyOnCompanyLocalHrForCompanyLocalHrCompanyIdFkeyPatch;
}

export interface CompanyOnCompanyLocalHrForCompanyLocalHrCompanyIdFkeyUsingCompanyPkeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdateCompanyOnCompanyLocalHrForCompanyLocalHrCompanyIdFkeyPatch;
}

export interface CompanyOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey5NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeCurrentPositionPatch;
}

export interface CompanyOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey5UsingCompanyAbbreviationKeyUpdate {
  abbreviation: Scalars['String']['input'];
  patch: UpdateCompanyOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey5Patch;
}

export interface CompanyOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey5UsingCompanyCountryCodeAlpha2FullNameKeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  patch: UpdateCompanyOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey5Patch;
}

export interface CompanyOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey5UsingCompanyPkeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdateCompanyOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey5Patch;
}

export interface CompanyOnEmployeeTpHistoryForEmployeeTpHistoryCompanyIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeTpHistoryPatch;
}

export interface CompanyOnEmployeeTpHistoryForEmployeeTpHistoryCompanyIdFkeyUsingCompanyAbbreviationKeyUpdate {
  abbreviation: Scalars['String']['input'];
  patch: UpdateCompanyOnEmployeeTpHistoryForEmployeeTpHistoryCompanyIdFkeyPatch;
}

export interface CompanyOnEmployeeTpHistoryForEmployeeTpHistoryCompanyIdFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  patch: UpdateCompanyOnEmployeeTpHistoryForEmployeeTpHistoryCompanyIdFkeyPatch;
}

export interface CompanyOnEmployeeTpHistoryForEmployeeTpHistoryCompanyIdFkeyUsingCompanyPkeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdateCompanyOnEmployeeTpHistoryForEmployeeTpHistoryCompanyIdFkeyPatch;
}

export interface CompanyOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey2NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeWorkingDayStatisticsAllPatch;
}

export interface CompanyOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey2UsingCompanyAbbreviationKeyUpdate {
  abbreviation: Scalars['String']['input'];
  patch: UpdateCompanyOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey2Patch;
}

export interface CompanyOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey2UsingCompanyCountryCodeAlpha2FullNameKeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  patch: UpdateCompanyOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey2Patch;
}

export interface CompanyOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey2UsingCompanyPkeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdateCompanyOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey2Patch;
}

export interface CompanyOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitCompanyIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: GoogleWorkspaceOrgUnitPatch;
}

export interface CompanyOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitCompanyIdFkeyUsingCompanyAbbreviationKeyUpdate {
  abbreviation: Scalars['String']['input'];
  patch: UpdateCompanyOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitCompanyIdFkeyPatch;
}

export interface CompanyOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitCompanyIdFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  patch: UpdateCompanyOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitCompanyIdFkeyPatch;
}

export interface CompanyOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitCompanyIdFkeyUsingCompanyPkeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdateCompanyOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitCompanyIdFkeyPatch;
}

export interface CompanyOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageCompanyIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterUsagePatch;
}

export interface CompanyOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageCompanyIdFkeyUsingCompanyAbbreviationKeyUpdate {
  abbreviation: Scalars['String']['input'];
  patch: UpdateCompanyOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageCompanyIdFkeyPatch;
}

export interface CompanyOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageCompanyIdFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  patch: UpdateCompanyOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageCompanyIdFkeyPatch;
}

export interface CompanyOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageCompanyIdFkeyUsingCompanyPkeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdateCompanyOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageCompanyIdFkeyPatch;
}

export interface CompanyOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingCompanyIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterWeightingPatch;
}

export interface CompanyOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingCompanyIdFkeyUsingCompanyAbbreviationKeyUpdate {
  abbreviation: Scalars['String']['input'];
  patch: UpdateCompanyOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingCompanyIdFkeyPatch;
}

export interface CompanyOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingCompanyIdFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  patch: UpdateCompanyOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingCompanyIdFkeyPatch;
}

export interface CompanyOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingCompanyIdFkeyUsingCompanyPkeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdateCompanyOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingCompanyIdFkeyPatch;
}

export interface CompanyOnProbationReviewEnableForProbationReviewEnableCompanyIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEnablePatch;
}

export interface CompanyOnProbationReviewEnableForProbationReviewEnableCompanyIdFkeyUsingCompanyAbbreviationKeyUpdate {
  abbreviation: Scalars['String']['input'];
  patch: UpdateCompanyOnProbationReviewEnableForProbationReviewEnableCompanyIdFkeyPatch;
}

export interface CompanyOnProbationReviewEnableForProbationReviewEnableCompanyIdFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  patch: UpdateCompanyOnProbationReviewEnableForProbationReviewEnableCompanyIdFkeyPatch;
}

export interface CompanyOnProbationReviewEnableForProbationReviewEnableCompanyIdFkeyUsingCompanyPkeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdateCompanyOnProbationReviewEnableForProbationReviewEnableCompanyIdFkeyPatch;
}

export interface CompanyOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey1NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEnableViewPatch;
}

export interface CompanyOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey1UsingCompanyAbbreviationKeyUpdate {
  abbreviation: Scalars['String']['input'];
  patch: UpdateCompanyOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey1Patch;
}

export interface CompanyOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey1UsingCompanyCountryCodeAlpha2FullNameKeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  patch: UpdateCompanyOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey1Patch;
}

export interface CompanyOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey1UsingCompanyPkeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdateCompanyOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey1Patch;
}

export interface CompanyOnRelationCityCompanyForRelationCityCompanyCompanyIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationCityCompanyPatch;
}

export interface CompanyOnRelationCityCompanyForRelationCityCompanyCompanyIdFkeyUsingCompanyAbbreviationKeyUpdate {
  abbreviation: Scalars['String']['input'];
  patch: UpdateCompanyOnRelationCityCompanyForRelationCityCompanyCompanyIdFkeyPatch;
}

export interface CompanyOnRelationCityCompanyForRelationCityCompanyCompanyIdFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  patch: UpdateCompanyOnRelationCityCompanyForRelationCityCompanyCompanyIdFkeyPatch;
}

export interface CompanyOnRelationCityCompanyForRelationCityCompanyCompanyIdFkeyUsingCompanyPkeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdateCompanyOnRelationCityCompanyForRelationCityCompanyCompanyIdFkeyPatch;
}

export interface CompanyOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitCompanyIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationCompanyBusinessUnitPatch;
}

export interface CompanyOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitCompanyIdFkeyUsingCompanyAbbreviationKeyUpdate {
  abbreviation: Scalars['String']['input'];
  patch: UpdateCompanyOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitCompanyIdFkeyPatch;
}

export interface CompanyOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitCompanyIdFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  patch: UpdateCompanyOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitCompanyIdFkeyPatch;
}

export interface CompanyOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitCompanyIdFkeyUsingCompanyPkeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdateCompanyOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitCompanyIdFkeyPatch;
}

export interface CompanyOnRelationCompanyOfficeForRelationCompanyOfficeCompanyIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationCompanyOfficePatch;
}

export interface CompanyOnRelationCompanyOfficeForRelationCompanyOfficeCompanyIdFkeyUsingCompanyAbbreviationKeyUpdate {
  abbreviation: Scalars['String']['input'];
  patch: UpdateCompanyOnRelationCompanyOfficeForRelationCompanyOfficeCompanyIdFkeyPatch;
}

export interface CompanyOnRelationCompanyOfficeForRelationCompanyOfficeCompanyIdFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  patch: UpdateCompanyOnRelationCompanyOfficeForRelationCompanyOfficeCompanyIdFkeyPatch;
}

export interface CompanyOnRelationCompanyOfficeForRelationCompanyOfficeCompanyIdFkeyUsingCompanyPkeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdateCompanyOnRelationCompanyOfficeForRelationCompanyOfficeCompanyIdFkeyPatch;
}

export interface CompanyOnUserHrManagingCompanyForUserHrManagingCompanyCompanyIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserHrManagingCompanyPatch;
}

export interface CompanyOnUserHrManagingCompanyForUserHrManagingCompanyCompanyIdFkeyUsingCompanyAbbreviationKeyUpdate {
  abbreviation: Scalars['String']['input'];
  patch: UpdateCompanyOnUserHrManagingCompanyForUserHrManagingCompanyCompanyIdFkeyPatch;
}

export interface CompanyOnUserHrManagingCompanyForUserHrManagingCompanyCompanyIdFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  patch: UpdateCompanyOnUserHrManagingCompanyForUserHrManagingCompanyCompanyIdFkeyPatch;
}

export interface CompanyOnUserHrManagingCompanyForUserHrManagingCompanyCompanyIdFkeyUsingCompanyPkeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdateCompanyOnUserHrManagingCompanyForUserHrManagingCompanyCompanyIdFkeyPatch;
}

export interface CompanyPatch {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CompanyStddevPopulationAggregateFilter {
  companyGroupId?: InputMaybe<BigFloatFilter>;
  companyId?: InputMaybe<BigFloatFilter>;
  notifyPeriodContractEnd?: InputMaybe<BigFloatFilter>;
  userCreated?: InputMaybe<BigFloatFilter>;
  userUpdated?: InputMaybe<BigFloatFilter>;
}

export interface CompanyStddevPopulationAggregates {
  __typename?: 'CompanyStddevPopulationAggregates';
  companyGroupId?: Maybe<Scalars['BigFloat']['output']>;
  companyId?: Maybe<Scalars['BigFloat']['output']>;
  notifyPeriodContractEnd?: Maybe<Scalars['BigFloat']['output']>;
  userCreated?: Maybe<Scalars['BigFloat']['output']>;
  userUpdated?: Maybe<Scalars['BigFloat']['output']>;
}

export interface CompanyStddevSampleAggregateFilter {
  companyGroupId?: InputMaybe<BigFloatFilter>;
  companyId?: InputMaybe<BigFloatFilter>;
  notifyPeriodContractEnd?: InputMaybe<BigFloatFilter>;
  userCreated?: InputMaybe<BigFloatFilter>;
  userUpdated?: InputMaybe<BigFloatFilter>;
}

export interface CompanyStddevSampleAggregates {
  __typename?: 'CompanyStddevSampleAggregates';
  companyGroupId?: Maybe<Scalars['BigFloat']['output']>;
  companyId?: Maybe<Scalars['BigFloat']['output']>;
  notifyPeriodContractEnd?: Maybe<Scalars['BigFloat']['output']>;
  userCreated?: Maybe<Scalars['BigFloat']['output']>;
  userUpdated?: Maybe<Scalars['BigFloat']['output']>;
}

export interface CompanySumAggregateFilter {
  companyGroupId?: InputMaybe<BigFloatFilter>;
  companyId?: InputMaybe<BigFloatFilter>;
  notifyPeriodContractEnd?: InputMaybe<BigIntFilter>;
  userCreated?: InputMaybe<BigFloatFilter>;
  userUpdated?: InputMaybe<BigFloatFilter>;
}

export interface CompanySumAggregates {
  __typename?: 'CompanySumAggregates';
  companyGroupId: Scalars['BigFloat']['output'];
  companyId: Scalars['BigFloat']['output'];
  notifyPeriodContractEnd: Scalars['BigInt']['output'];
  userCreated: Scalars['BigFloat']['output'];
  userUpdated: Scalars['BigFloat']['output'];
}

export interface CompanyToManyCompanyExternalAccountDisableFilter {
  every?: InputMaybe<CompanyExternalAccountDisableFilter>;
  none?: InputMaybe<CompanyExternalAccountDisableFilter>;
  some?: InputMaybe<CompanyExternalAccountDisableFilter>;
}

export interface CompanyToManyCompanyLocalHrFilter {
  every?: InputMaybe<CompanyLocalHrFilter>;
  none?: InputMaybe<CompanyLocalHrFilter>;
  some?: InputMaybe<CompanyLocalHrFilter>;
}

export interface CompanyToManyEmployeeCurrentPositionFilter {
  every?: InputMaybe<EmployeeCurrentPositionFilter>;
  none?: InputMaybe<EmployeeCurrentPositionFilter>;
  some?: InputMaybe<EmployeeCurrentPositionFilter>;
}

export interface CompanyToManyEmployeeTpHistoryFilter {
  every?: InputMaybe<EmployeeTpHistoryFilter>;
  none?: InputMaybe<EmployeeTpHistoryFilter>;
  some?: InputMaybe<EmployeeTpHistoryFilter>;
}

export interface CompanyToManyEmployeeWorkingDayStatisticsAllFilter {
  every?: InputMaybe<EmployeeWorkingDayStatisticsAllFilter>;
  none?: InputMaybe<EmployeeWorkingDayStatisticsAllFilter>;
  some?: InputMaybe<EmployeeWorkingDayStatisticsAllFilter>;
}

export interface CompanyToManyGoogleWorkspaceOrgUnitFilter {
  every?: InputMaybe<GoogleWorkspaceOrgUnitFilter>;
  none?: InputMaybe<GoogleWorkspaceOrgUnitFilter>;
  some?: InputMaybe<GoogleWorkspaceOrgUnitFilter>;
}

export interface CompanyToManyPerformanceReviewEvaluationCountFilter {
  every?: InputMaybe<PerformanceReviewEvaluationCountFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationCountFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationCountFilter>;
}

export interface CompanyToManyPerformanceReviewMasterUsageFilter {
  every?: InputMaybe<PerformanceReviewMasterUsageFilter>;
  none?: InputMaybe<PerformanceReviewMasterUsageFilter>;
  some?: InputMaybe<PerformanceReviewMasterUsageFilter>;
}

export interface CompanyToManyPerformanceReviewMasterWeightingFilter {
  every?: InputMaybe<PerformanceReviewMasterWeightingFilter>;
  none?: InputMaybe<PerformanceReviewMasterWeightingFilter>;
  some?: InputMaybe<PerformanceReviewMasterWeightingFilter>;
}

export interface CompanyToManyProbationReviewEnableFilter {
  every?: InputMaybe<ProbationReviewEnableFilter>;
  none?: InputMaybe<ProbationReviewEnableFilter>;
  some?: InputMaybe<ProbationReviewEnableFilter>;
}

export interface CompanyToManyProbationReviewEnableViewFilter {
  every?: InputMaybe<ProbationReviewEnableViewFilter>;
  none?: InputMaybe<ProbationReviewEnableViewFilter>;
  some?: InputMaybe<ProbationReviewEnableViewFilter>;
}

export interface CompanyToManyRelationCityCompanyFilter {
  every?: InputMaybe<RelationCityCompanyFilter>;
  none?: InputMaybe<RelationCityCompanyFilter>;
  some?: InputMaybe<RelationCityCompanyFilter>;
}

export interface CompanyToManyRelationCompanyBusinessUnitFilter {
  every?: InputMaybe<RelationCompanyBusinessUnitFilter>;
  none?: InputMaybe<RelationCompanyBusinessUnitFilter>;
  some?: InputMaybe<RelationCompanyBusinessUnitFilter>;
}

export interface CompanyToManyRelationCompanyOfficeFilter {
  every?: InputMaybe<RelationCompanyOfficeFilter>;
  none?: InputMaybe<RelationCompanyOfficeFilter>;
  some?: InputMaybe<RelationCompanyOfficeFilter>;
}

export interface CompanyToManyUserHrManagingCompanyFilter {
  every?: InputMaybe<UserHrManagingCompanyFilter>;
  none?: InputMaybe<UserHrManagingCompanyFilter>;
  some?: InputMaybe<UserHrManagingCompanyFilter>;
}

export interface CompanyUserCreatedFkeyInverseInput {
  connectByAbbreviation?: InputMaybe<Array<CompanyCompanyAbbreviationKeyConnect>>;
  connectByCompanyId?: InputMaybe<Array<CompanyCompanyPkeyConnect>>;
  connectByCountryCodeAlpha2AndFullName?: InputMaybe<Array<CompanyCompanyCountryCodeAlpha2FullNameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<CompanyNodeIdConnect>>;
  deleteByAbbreviation?: InputMaybe<Array<CompanyCompanyAbbreviationKeyDelete>>;
  deleteByCompanyId?: InputMaybe<Array<CompanyCompanyPkeyDelete>>;
  deleteByCountryCodeAlpha2AndFullName?: InputMaybe<Array<CompanyCompanyCountryCodeAlpha2FullNameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<CompanyNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByAbbreviation?: InputMaybe<Array<CompanyOnCompanyForCompanyUserCreatedFkeyUsingCompanyAbbreviationKeyUpdate>>;
  updateByCompanyId?: InputMaybe<Array<CompanyOnCompanyForCompanyUserCreatedFkeyUsingCompanyPkeyUpdate>>;
  updateByCountryCodeAlpha2AndFullName?: InputMaybe<Array<CompanyOnCompanyForCompanyUserCreatedFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnCompanyForCompanyUserCreatedFkeyNodeIdUpdate>>;
}

export interface CompanyUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnCompanyForCompanyUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<CompanyOnCompanyForCompanyUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnCompanyForCompanyUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface CompanyUserUpdatedFkeyInverseInput {
  connectByAbbreviation?: InputMaybe<Array<CompanyCompanyAbbreviationKeyConnect>>;
  connectByCompanyId?: InputMaybe<Array<CompanyCompanyPkeyConnect>>;
  connectByCountryCodeAlpha2AndFullName?: InputMaybe<Array<CompanyCompanyCountryCodeAlpha2FullNameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<CompanyNodeIdConnect>>;
  deleteByAbbreviation?: InputMaybe<Array<CompanyCompanyAbbreviationKeyDelete>>;
  deleteByCompanyId?: InputMaybe<Array<CompanyCompanyPkeyDelete>>;
  deleteByCountryCodeAlpha2AndFullName?: InputMaybe<Array<CompanyCompanyCountryCodeAlpha2FullNameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<CompanyNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByAbbreviation?: InputMaybe<Array<CompanyOnCompanyForCompanyUserUpdatedFkeyUsingCompanyAbbreviationKeyUpdate>>;
  updateByCompanyId?: InputMaybe<Array<CompanyOnCompanyForCompanyUserUpdatedFkeyUsingCompanyPkeyUpdate>>;
  updateByCountryCodeAlpha2AndFullName?: InputMaybe<Array<CompanyOnCompanyForCompanyUserUpdatedFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnCompanyForCompanyUserUpdatedFkeyNodeIdUpdate>>;
}

export interface CompanyVariancePopulationAggregateFilter {
  companyGroupId?: InputMaybe<BigFloatFilter>;
  companyId?: InputMaybe<BigFloatFilter>;
  notifyPeriodContractEnd?: InputMaybe<BigFloatFilter>;
  userCreated?: InputMaybe<BigFloatFilter>;
  userUpdated?: InputMaybe<BigFloatFilter>;
}

export interface CompanyVariancePopulationAggregates {
  __typename?: 'CompanyVariancePopulationAggregates';
  companyGroupId?: Maybe<Scalars['BigFloat']['output']>;
  companyId?: Maybe<Scalars['BigFloat']['output']>;
  notifyPeriodContractEnd?: Maybe<Scalars['BigFloat']['output']>;
  userCreated?: Maybe<Scalars['BigFloat']['output']>;
  userUpdated?: Maybe<Scalars['BigFloat']['output']>;
}

export interface CompanyVarianceSampleAggregateFilter {
  companyGroupId?: InputMaybe<BigFloatFilter>;
  companyId?: InputMaybe<BigFloatFilter>;
  notifyPeriodContractEnd?: InputMaybe<BigFloatFilter>;
  userCreated?: InputMaybe<BigFloatFilter>;
  userUpdated?: InputMaybe<BigFloatFilter>;
}

export interface CompanyVarianceSampleAggregates {
  __typename?: 'CompanyVarianceSampleAggregates';
  companyGroupId?: Maybe<Scalars['BigFloat']['output']>;
  companyId?: Maybe<Scalars['BigFloat']['output']>;
  notifyPeriodContractEnd?: Maybe<Scalars['BigFloat']['output']>;
  userCreated?: Maybe<Scalars['BigFloat']['output']>;
  userUpdated?: Maybe<Scalars['BigFloat']['output']>;
}

export interface ContractType extends Node {
  __typename?: 'ContractType';
  code: Scalars['String']['output'];
  contractTypeId: Scalars['BigInt']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  displayName: Scalars['String']['output'];
  employeeCurrentPositions: EmployeeCurrentPositionsConnection;
  employeeTpHistories: EmployeeTpHistoriesConnection;
  employeeWorkingDayStatisticsAlls: EmployeeWorkingDayStatisticsAllsConnection;
  nodeId: Scalars['ID']['output'];
  probationReviewEnableViews: ProbationReviewEnableViewsConnection;
  probationReviewEnables: ProbationReviewEnablesConnection;
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export type ContractTypeEmployeeCurrentPositionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCurrentPositionCondition>;
  filter?: InputMaybe<EmployeeCurrentPositionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeCurrentPositionsOrderBy>>;
};

export type ContractTypeEmployeeTpHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeTpHistoryCondition>;
  filter?: InputMaybe<EmployeeTpHistoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeTpHistoriesOrderBy>>;
};

export type ContractTypeEmployeeWorkingDayStatisticsAllsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeWorkingDayStatisticsAllCondition>;
  filter?: InputMaybe<EmployeeWorkingDayStatisticsAllFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeWorkingDayStatisticsAllsOrderBy>>;
};

export type ContractTypeProbationReviewEnableViewsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEnableViewCondition>;
  filter?: InputMaybe<ProbationReviewEnableViewFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEnableViewsOrderBy>>;
};

export type ContractTypeProbationReviewEnablesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEnableCondition>;
  filter?: InputMaybe<ProbationReviewEnableFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEnablesOrderBy>>;
};

export interface ContractTypeCondition {
  code?: InputMaybe<Scalars['String']['input']>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ContractTypeContractTypeCodeKeyConnect {
  code: Scalars['String']['input'];
}

export interface ContractTypeContractTypeCodeKeyDelete {
  code: Scalars['String']['input'];
}

export interface ContractTypeContractTypeDisplayNameKeyConnect {
  displayName: Scalars['String']['input'];
}

export interface ContractTypeContractTypeDisplayNameKeyDelete {
  displayName: Scalars['String']['input'];
}

export interface ContractTypeContractTypePkeyConnect {
  contractTypeId: Scalars['BigInt']['input'];
}

export interface ContractTypeContractTypePkeyDelete {
  contractTypeId: Scalars['BigInt']['input'];
}

export interface ContractTypeFilter {
  and?: InputMaybe<Array<ContractTypeFilter>>;
  code?: InputMaybe<StringFilter>;
  contractTypeId?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  displayName?: InputMaybe<StringFilter>;
  employeeCurrentPositions?: InputMaybe<ContractTypeToManyEmployeeCurrentPositionFilter>;
  employeeCurrentPositionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeTpHistories?: InputMaybe<ContractTypeToManyEmployeeTpHistoryFilter>;
  employeeTpHistoriesExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<ContractTypeToManyEmployeeWorkingDayStatisticsAllFilter>;
  employeeWorkingDayStatisticsAllsExist?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<ContractTypeFilter>;
  or?: InputMaybe<Array<ContractTypeFilter>>;
  probationReviewEnableViews?: InputMaybe<ContractTypeToManyProbationReviewEnableViewFilter>;
  probationReviewEnableViewsExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEnables?: InputMaybe<ContractTypeToManyProbationReviewEnableFilter>;
  probationReviewEnablesExist?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface ContractTypeInput {
  code: Scalars['String']['input'];
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  displayName: Scalars['String']['input'];
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey1InverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey2InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableContractTypeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ContractTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ContractTypeNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface ContractTypeNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface ContractTypeOnContractTypeForContractTypeUserCreatedFkeyUsingContractTypeCodeKeyUpdate {
  code: Scalars['String']['input'];
  patch: UpdateContractTypeOnContractTypeForContractTypeUserCreatedFkeyPatch;
}

export interface ContractTypeOnContractTypeForContractTypeUserCreatedFkeyUsingContractTypeDisplayNameKeyUpdate {
  displayName: Scalars['String']['input'];
  patch: UpdateContractTypeOnContractTypeForContractTypeUserCreatedFkeyPatch;
}

export interface ContractTypeOnContractTypeForContractTypeUserCreatedFkeyUsingContractTypePkeyUpdate {
  contractTypeId: Scalars['BigInt']['input'];
  patch: UpdateContractTypeOnContractTypeForContractTypeUserCreatedFkeyPatch;
}

export interface ContractTypeOnContractTypeForContractTypeUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface ContractTypeOnContractTypeForContractTypeUserUpdatedFkeyUsingContractTypeCodeKeyUpdate {
  code: Scalars['String']['input'];
  patch: UpdateContractTypeOnContractTypeForContractTypeUserUpdatedFkeyPatch;
}

export interface ContractTypeOnContractTypeForContractTypeUserUpdatedFkeyUsingContractTypeDisplayNameKeyUpdate {
  displayName: Scalars['String']['input'];
  patch: UpdateContractTypeOnContractTypeForContractTypeUserUpdatedFkeyPatch;
}

export interface ContractTypeOnContractTypeForContractTypeUserUpdatedFkeyUsingContractTypePkeyUpdate {
  contractTypeId: Scalars['BigInt']['input'];
  patch: UpdateContractTypeOnContractTypeForContractTypeUserUpdatedFkeyPatch;
}

export interface ContractTypeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey2NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeCurrentPositionPatch;
}

export interface ContractTypeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey2UsingContractTypeCodeKeyUpdate {
  code: Scalars['String']['input'];
  patch: UpdateContractTypeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey2Patch;
}

export interface ContractTypeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey2UsingContractTypeDisplayNameKeyUpdate {
  displayName: Scalars['String']['input'];
  patch: UpdateContractTypeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey2Patch;
}

export interface ContractTypeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey2UsingContractTypePkeyUpdate {
  contractTypeId: Scalars['BigInt']['input'];
  patch: UpdateContractTypeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey2Patch;
}

export interface ContractTypeOnEmployeeTpHistoryForEmployeeTpHistoryContractTypeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeTpHistoryPatch;
}

export interface ContractTypeOnEmployeeTpHistoryForEmployeeTpHistoryContractTypeIdFkeyUsingContractTypeCodeKeyUpdate {
  code: Scalars['String']['input'];
  patch: UpdateContractTypeOnEmployeeTpHistoryForEmployeeTpHistoryContractTypeIdFkeyPatch;
}

export interface ContractTypeOnEmployeeTpHistoryForEmployeeTpHistoryContractTypeIdFkeyUsingContractTypeDisplayNameKeyUpdate {
  displayName: Scalars['String']['input'];
  patch: UpdateContractTypeOnEmployeeTpHistoryForEmployeeTpHistoryContractTypeIdFkeyPatch;
}

export interface ContractTypeOnEmployeeTpHistoryForEmployeeTpHistoryContractTypeIdFkeyUsingContractTypePkeyUpdate {
  contractTypeId: Scalars['BigInt']['input'];
  patch: UpdateContractTypeOnEmployeeTpHistoryForEmployeeTpHistoryContractTypeIdFkeyPatch;
}

export interface ContractTypeOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey1NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeWorkingDayStatisticsAllPatch;
}

export interface ContractTypeOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey1UsingContractTypeCodeKeyUpdate {
  code: Scalars['String']['input'];
  patch: UpdateContractTypeOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey1Patch;
}

export interface ContractTypeOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey1UsingContractTypeDisplayNameKeyUpdate {
  displayName: Scalars['String']['input'];
  patch: UpdateContractTypeOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey1Patch;
}

export interface ContractTypeOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey1UsingContractTypePkeyUpdate {
  contractTypeId: Scalars['BigInt']['input'];
  patch: UpdateContractTypeOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey1Patch;
}

export interface ContractTypeOnProbationReviewEnableForProbationReviewEnableContractTypeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEnablePatch;
}

export interface ContractTypeOnProbationReviewEnableForProbationReviewEnableContractTypeIdFkeyUsingContractTypeCodeKeyUpdate {
  code: Scalars['String']['input'];
  patch: UpdateContractTypeOnProbationReviewEnableForProbationReviewEnableContractTypeIdFkeyPatch;
}

export interface ContractTypeOnProbationReviewEnableForProbationReviewEnableContractTypeIdFkeyUsingContractTypeDisplayNameKeyUpdate {
  displayName: Scalars['String']['input'];
  patch: UpdateContractTypeOnProbationReviewEnableForProbationReviewEnableContractTypeIdFkeyPatch;
}

export interface ContractTypeOnProbationReviewEnableForProbationReviewEnableContractTypeIdFkeyUsingContractTypePkeyUpdate {
  contractTypeId: Scalars['BigInt']['input'];
  patch: UpdateContractTypeOnProbationReviewEnableForProbationReviewEnableContractTypeIdFkeyPatch;
}

export interface ContractTypeOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey2NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEnableViewPatch;
}

export interface ContractTypeOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey2UsingContractTypeCodeKeyUpdate {
  code: Scalars['String']['input'];
  patch: UpdateContractTypeOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey2Patch;
}

export interface ContractTypeOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey2UsingContractTypeDisplayNameKeyUpdate {
  displayName: Scalars['String']['input'];
  patch: UpdateContractTypeOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey2Patch;
}

export interface ContractTypeOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey2UsingContractTypePkeyUpdate {
  contractTypeId: Scalars['BigInt']['input'];
  patch: UpdateContractTypeOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey2Patch;
}

export interface ContractTypePatch {
  code?: InputMaybe<Scalars['String']['input']>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey1InverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey2InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableContractTypeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ContractTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ContractTypeToManyEmployeeCurrentPositionFilter {
  every?: InputMaybe<EmployeeCurrentPositionFilter>;
  none?: InputMaybe<EmployeeCurrentPositionFilter>;
  some?: InputMaybe<EmployeeCurrentPositionFilter>;
}

export interface ContractTypeToManyEmployeeTpHistoryFilter {
  every?: InputMaybe<EmployeeTpHistoryFilter>;
  none?: InputMaybe<EmployeeTpHistoryFilter>;
  some?: InputMaybe<EmployeeTpHistoryFilter>;
}

export interface ContractTypeToManyEmployeeWorkingDayStatisticsAllFilter {
  every?: InputMaybe<EmployeeWorkingDayStatisticsAllFilter>;
  none?: InputMaybe<EmployeeWorkingDayStatisticsAllFilter>;
  some?: InputMaybe<EmployeeWorkingDayStatisticsAllFilter>;
}

export interface ContractTypeToManyProbationReviewEnableFilter {
  every?: InputMaybe<ProbationReviewEnableFilter>;
  none?: InputMaybe<ProbationReviewEnableFilter>;
  some?: InputMaybe<ProbationReviewEnableFilter>;
}

export interface ContractTypeToManyProbationReviewEnableViewFilter {
  every?: InputMaybe<ProbationReviewEnableViewFilter>;
  none?: InputMaybe<ProbationReviewEnableViewFilter>;
  some?: InputMaybe<ProbationReviewEnableViewFilter>;
}

export interface ContractTypeUserCreatedFkeyInverseInput {
  connectByCode?: InputMaybe<Array<ContractTypeContractTypeCodeKeyConnect>>;
  connectByContractTypeId?: InputMaybe<Array<ContractTypeContractTypePkeyConnect>>;
  connectByDisplayName?: InputMaybe<Array<ContractTypeContractTypeDisplayNameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<ContractTypeNodeIdConnect>>;
  deleteByCode?: InputMaybe<Array<ContractTypeContractTypeCodeKeyDelete>>;
  deleteByContractTypeId?: InputMaybe<Array<ContractTypeContractTypePkeyDelete>>;
  deleteByDisplayName?: InputMaybe<Array<ContractTypeContractTypeDisplayNameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<ContractTypeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCode?: InputMaybe<Array<ContractTypeOnContractTypeForContractTypeUserCreatedFkeyUsingContractTypeCodeKeyUpdate>>;
  updateByContractTypeId?: InputMaybe<Array<ContractTypeOnContractTypeForContractTypeUserCreatedFkeyUsingContractTypePkeyUpdate>>;
  updateByDisplayName?: InputMaybe<Array<ContractTypeOnContractTypeForContractTypeUserCreatedFkeyUsingContractTypeDisplayNameKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnContractTypeForContractTypeUserCreatedFkeyNodeIdUpdate>>;
}

export interface ContractTypeUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnContractTypeForContractTypeUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<ContractTypeOnContractTypeForContractTypeUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnContractTypeForContractTypeUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface ContractTypeUserUpdatedFkeyInverseInput {
  connectByCode?: InputMaybe<Array<ContractTypeContractTypeCodeKeyConnect>>;
  connectByContractTypeId?: InputMaybe<Array<ContractTypeContractTypePkeyConnect>>;
  connectByDisplayName?: InputMaybe<Array<ContractTypeContractTypeDisplayNameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<ContractTypeNodeIdConnect>>;
  deleteByCode?: InputMaybe<Array<ContractTypeContractTypeCodeKeyDelete>>;
  deleteByContractTypeId?: InputMaybe<Array<ContractTypeContractTypePkeyDelete>>;
  deleteByDisplayName?: InputMaybe<Array<ContractTypeContractTypeDisplayNameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<ContractTypeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCode?: InputMaybe<Array<ContractTypeOnContractTypeForContractTypeUserUpdatedFkeyUsingContractTypeCodeKeyUpdate>>;
  updateByContractTypeId?: InputMaybe<Array<ContractTypeOnContractTypeForContractTypeUserUpdatedFkeyUsingContractTypePkeyUpdate>>;
  updateByDisplayName?: InputMaybe<Array<ContractTypeOnContractTypeForContractTypeUserUpdatedFkeyUsingContractTypeDisplayNameKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnContractTypeForContractTypeUserUpdatedFkeyNodeIdUpdate>>;
}

export interface ContractTypesConnection {
  __typename?: 'ContractTypesConnection';
  edges: Array<ContractTypesEdge>;
  nodes: Array<ContractType>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ContractTypesEdge {
  __typename?: 'ContractTypesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ContractType;
}

export enum ContractTypesOrderBy {
  CODE_ASC = 'CODE_ASC',
  CODE_DESC = 'CODE_DESC',
  CONTRACT_TYPE_ID_ASC = 'CONTRACT_TYPE_ID_ASC',
  CONTRACT_TYPE_ID_DESC = 'CONTRACT_TYPE_ID_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DISPLAY_NAME_ASC = 'DISPLAY_NAME_ASC',
  DISPLAY_NAME_DESC = 'DISPLAY_NAME_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__COUNT_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__COUNT_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__COUNT_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__COUNT_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_CITY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_CITY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_CITY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_CITY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_COMPANY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_COMPANY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_COMPANY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_COMPANY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_DEPARTMENT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_DEPARTMENT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_DEPARTMENT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_DEPARTMENT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_DIVISION_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_DIVISION_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_DIVISION_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_DIVISION_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_EFFECTIVE_DATE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_EFFECTIVE_DATE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_EFFECTIVE_DATE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_EFFECTIVE_DATE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_CODE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_CODE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_CODE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_CODE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_JOB_TITLE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_JOB_TITLE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_JOB_TITLE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_JOB_TITLE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_OFFICE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_OFFICE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_OFFICE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_OFFICE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_TEAM_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_TEAM_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_TEAM_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MAX_TEAM_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_CITY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_CITY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_CITY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_CITY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_COMPANY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_COMPANY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_COMPANY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_COMPANY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_DEPARTMENT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_DEPARTMENT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_DEPARTMENT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_DEPARTMENT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_DIVISION_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_DIVISION_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_DIVISION_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_DIVISION_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_EFFECTIVE_DATE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_EFFECTIVE_DATE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_EFFECTIVE_DATE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_EFFECTIVE_DATE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_CODE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_CODE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_CODE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_CODE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_JOB_TITLE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_JOB_TITLE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_JOB_TITLE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_JOB_TITLE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_OFFICE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_OFFICE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_OFFICE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_OFFICE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_TEAM_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_TEAM_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_TEAM_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_CONTRACT_TYPE_ID__MIN_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__COUNT_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__COUNT_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__COUNT_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__COUNT_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_COMPANY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_COMPANY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_COMPANY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_COMPANY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_DATE_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_DATE_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_DATE_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_DATE_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_DEPARTMENT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_DEPARTMENT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_DEPARTMENT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_DEPARTMENT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_DIVISION_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_DIVISION_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_DIVISION_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_DIVISION_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_EFFECTIVE_DATE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_EFFECTIVE_DATE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_EFFECTIVE_DATE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_EFFECTIVE_DATE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_JOB_TITLE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_JOB_TITLE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_JOB_TITLE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_JOB_TITLE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_OFFICE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_OFFICE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_OFFICE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_OFFICE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_REMARK_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_REMARK_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_REMARK_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_REMARK_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_TEAM_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_TEAM_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_TEAM_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_USER_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_USER_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_USER_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_USER_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_USER_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_USER_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_USER_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MAX_USER_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_COMPANY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_COMPANY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_COMPANY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_COMPANY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_DATE_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_DATE_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_DATE_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_DATE_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_DEPARTMENT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_DEPARTMENT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_DEPARTMENT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_DEPARTMENT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_DIVISION_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_DIVISION_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_DIVISION_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_DIVISION_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_EFFECTIVE_DATE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_EFFECTIVE_DATE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_EFFECTIVE_DATE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_EFFECTIVE_DATE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_JOB_TITLE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_JOB_TITLE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_JOB_TITLE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_JOB_TITLE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_OFFICE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_OFFICE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_OFFICE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_OFFICE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_REMARK_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_REMARK_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_REMARK_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_REMARK_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_TEAM_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_TEAM_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_TEAM_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_USER_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_USER_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_USER_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_USER_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_USER_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_USER_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_USER_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_CONTRACT_TYPE_ID__MIN_USER_UPDATED_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__COUNT_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__COUNT_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__COUNT_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__COUNT_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_ALL_DATES_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_ALL_DATES_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_ALL_DATES_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_ALL_DATES_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_BASE_DATE_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_BASE_DATE_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_BASE_DATE_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_BASE_DATE_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_COMPANY_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_COMPANY_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_COMPANY_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_COMPANY_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_COMPANY_EMAIL_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_COMPANY_EMAIL_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_COMPANY_EMAIL_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_COMPANY_EMAIL_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_COMPANY_ID_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_COMPANY_ID_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_COMPANY_ID_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_COMPANY_ID_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_RANK_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_RANK_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_RANK_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_RANK_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_STATUS_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_STATUS_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_STATUS_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_STATUS_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_WORKING_DAYS_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_WORKING_DAYS_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_WORKING_DAYS_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MAX_WORKING_DAYS_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_ALL_DATES_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_ALL_DATES_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_ALL_DATES_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_ALL_DATES_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_BASE_DATE_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_BASE_DATE_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_BASE_DATE_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_BASE_DATE_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_COMPANY_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_COMPANY_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_COMPANY_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_COMPANY_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_COMPANY_EMAIL_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_COMPANY_EMAIL_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_COMPANY_EMAIL_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_COMPANY_EMAIL_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_COMPANY_ID_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_COMPANY_ID_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_COMPANY_ID_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_COMPANY_ID_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_RANK_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_RANK_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_RANK_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_RANK_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_STATUS_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_STATUS_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_STATUS_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_STATUS_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_WORKING_DAYS_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_WORKING_DAYS_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_WORKING_DAYS_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALLS_BY_CONTRACT_TYPE_ID__MIN_WORKING_DAYS_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__COUNT_ASC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__COUNT_ASC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__COUNT_DESC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__COUNT_DESC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_COMPANY_ID_ASC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_COMPANY_ID_ASC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_COMPANY_ID_DESC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_COMPANY_ID_DESC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_CONTRACT_TYPE_ID_ASC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_CONTRACT_TYPE_ID_ASC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_CONTRACT_TYPE_ID_DESC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_CONTRACT_TYPE_ID_DESC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_DEFAULT_PROBATION_PERIOD_ASC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_DEFAULT_PROBATION_PERIOD_ASC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_DEFAULT_PROBATION_PERIOD_DESC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_DEFAULT_PROBATION_PERIOD_DESC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_ENABLED_ASC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_ENABLED_ASC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_ENABLED_DESC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_ENABLED_DESC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_PROBATION_DATE_INTERVAL_ASC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_PROBATION_DATE_INTERVAL_ASC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_PROBATION_DATE_INTERVAL_DESC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_PROBATION_DATE_INTERVAL_DESC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_PROBATION_REVIEW_ENABLE_ID_ASC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_PROBATION_REVIEW_ENABLE_ID_ASC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_PROBATION_REVIEW_ENABLE_ID_DESC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_PROBATION_REVIEW_ENABLE_ID_DESC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_REMARK_ASC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_REMARK_ASC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_REMARK_DESC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_REMARK_DESC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_COMPANY_ID_ASC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_COMPANY_ID_ASC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_COMPANY_ID_DESC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_COMPANY_ID_DESC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_CONTRACT_TYPE_ID_ASC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_CONTRACT_TYPE_ID_ASC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_CONTRACT_TYPE_ID_DESC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_CONTRACT_TYPE_ID_DESC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_DEFAULT_PROBATION_PERIOD_ASC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_DEFAULT_PROBATION_PERIOD_ASC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_DEFAULT_PROBATION_PERIOD_DESC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_DEFAULT_PROBATION_PERIOD_DESC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_ENABLED_ASC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_ENABLED_ASC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_ENABLED_DESC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_ENABLED_DESC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_PROBATION_DATE_INTERVAL_ASC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_PROBATION_DATE_INTERVAL_ASC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_PROBATION_DATE_INTERVAL_DESC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_PROBATION_DATE_INTERVAL_DESC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_PROBATION_REVIEW_ENABLE_ID_ASC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_PROBATION_REVIEW_ENABLE_ID_ASC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_PROBATION_REVIEW_ENABLE_ID_DESC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_PROBATION_REVIEW_ENABLE_ID_DESC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_REMARK_ASC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_REMARK_ASC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_REMARK_DESC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_REMARK_DESC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_CONTRACT_TYPE_ID__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__COUNT_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__COUNT_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__COUNT_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__COUNT_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_COMPANY_ID_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_COMPANY_ID_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_COMPANY_ID_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_COMPANY_ID_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_CONTRACT_TYPE_ID_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_CONTRACT_TYPE_ID_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_CONTRACT_TYPE_ID_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_CONTRACT_TYPE_ID_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_COUNTRY_CODE_ALPHA2_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_COUNTRY_CODE_ALPHA2_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_COUNTRY_CODE_ALPHA2_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_COUNTRY_CODE_ALPHA2_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_DEFAULT_PROBATION_PERIOD_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_DEFAULT_PROBATION_PERIOD_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_DEFAULT_PROBATION_PERIOD_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_DEFAULT_PROBATION_PERIOD_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_ENABLED_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_ENABLED_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_ENABLED_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_ENABLED_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_PROBATION_DATE_INTERVAL_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_PROBATION_DATE_INTERVAL_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_PROBATION_DATE_INTERVAL_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_PROBATION_DATE_INTERVAL_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_PROBATION_REVIEW_ENABLE_ID_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_PROBATION_REVIEW_ENABLE_ID_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_PROBATION_REVIEW_ENABLE_ID_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MAX_PROBATION_REVIEW_ENABLE_ID_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_COMPANY_ID_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_COMPANY_ID_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_COMPANY_ID_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_COMPANY_ID_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_CONTRACT_TYPE_ID_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_CONTRACT_TYPE_ID_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_CONTRACT_TYPE_ID_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_CONTRACT_TYPE_ID_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_COUNTRY_CODE_ALPHA2_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_COUNTRY_CODE_ALPHA2_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_COUNTRY_CODE_ALPHA2_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_COUNTRY_CODE_ALPHA2_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_DEFAULT_PROBATION_PERIOD_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_DEFAULT_PROBATION_PERIOD_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_DEFAULT_PROBATION_PERIOD_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_DEFAULT_PROBATION_PERIOD_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_ENABLED_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_ENABLED_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_ENABLED_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_ENABLED_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_PROBATION_DATE_INTERVAL_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_PROBATION_DATE_INTERVAL_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_PROBATION_DATE_INTERVAL_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_PROBATION_DATE_INTERVAL_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_PROBATION_REVIEW_ENABLE_ID_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_PROBATION_REVIEW_ENABLE_ID_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_PROBATION_REVIEW_ENABLE_ID_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_CONTRACT_TYPE_ID__MIN_PROBATION_REVIEW_ENABLE_ID_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface CopyPerformanceReviewMasterCompetencyCommonInput {
  _input?: InputMaybe<InputTypeCopyPerformanceReviewMasterCompetencyCommonInput>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

export interface CopyPerformanceReviewMasterCompetencyCommonPayload {
  __typename?: 'CopyPerformanceReviewMasterCompetencyCommonPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
  returnTypeCopyPerformanceReviewMasterCompetencyCommon?: Maybe<ReturnTypeCopyPerformanceReviewMasterCompetencyCommon>;
}

export interface CopyPerformanceReviewMasterCompetencyDetailInput {
  _input?: InputMaybe<InputTypeCopyPerformanceReviewMasterCompetencyDetailInput>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

export interface CopyPerformanceReviewMasterCompetencyDetailPayload {
  __typename?: 'CopyPerformanceReviewMasterCompetencyDetailPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
  returnTypeCopyPerformanceReviewMasterCompetencyDetail?: Maybe<ReturnTypeCopyPerformanceReviewMasterCompetencyDetail>;
}

export interface CopyPerformanceReviewMasterRatingInput {
  _input?: InputMaybe<InputTypeCopyPerformanceReviewMasterRatingInput>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

export interface CopyPerformanceReviewMasterRatingPayload {
  __typename?: 'CopyPerformanceReviewMasterRatingPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
  returnTypeCopyPerformanceReviewMasterRating?: Maybe<ReturnTypeCopyPerformanceReviewMasterRating>;
}

export interface CopyPerformanceReviewMasterValueInput {
  _input?: InputMaybe<InputTypeCopyPerformanceReviewMasterValueInput>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

export interface CopyPerformanceReviewMasterValuePayload {
  __typename?: 'CopyPerformanceReviewMasterValuePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
  returnTypeCopyPerformanceReviewMasterValue?: Maybe<ReturnTypeCopyPerformanceReviewMasterValue>;
}

export interface CopyPerformanceReviewMasterWeightingInput {
  _input?: InputMaybe<InputTypeCopyPerformanceReviewMasterWeightingInput>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

export interface CopyPerformanceReviewMasterWeightingPayload {
  __typename?: 'CopyPerformanceReviewMasterWeightingPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
  returnTypeCopyPerformanceReviewMasterWeighting?: Maybe<ReturnTypeCopyPerformanceReviewMasterWeighting>;
}

export interface CountriesConnection {
  __typename?: 'CountriesConnection';
  edges: Array<CountriesEdge>;
  nodes: Array<Country>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface CountriesEdge {
  __typename?: 'CountriesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: Country;
}

export enum CountriesOrderBy {
  BANKS_BY_COUNTRY_CODE_ALPHA3__COUNT_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__COUNT_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__COUNT_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__COUNT_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_BANK_CODE_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_BANK_CODE_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_BANK_CODE_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_BANK_CODE_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_BANK_ID_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_BANK_ID_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_BANK_ID_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_BANK_ID_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_COUNTRY_CODE_ALPHA3_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_COUNTRY_CODE_ALPHA3_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_COUNTRY_CODE_ALPHA3_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_COUNTRY_CODE_ALPHA3_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_DATE_CREATED_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_DATE_CREATED_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_DATE_CREATED_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_DATE_CREATED_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_DATE_UPDATED_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_DATE_UPDATED_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_DATE_UPDATED_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_DATE_UPDATED_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_ENABLED_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_ENABLED_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_ENABLED_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_ENABLED_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_FULL_NAME_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_FULL_NAME_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_FULL_NAME_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_FULL_NAME_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_REMARK_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_REMARK_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_REMARK_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_REMARK_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_SHORT_NAME_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_SHORT_NAME_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_SHORT_NAME_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_SHORT_NAME_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_SWIFT_CODE_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_SWIFT_CODE_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_SWIFT_CODE_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_SWIFT_CODE_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_USER_CREATED_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_USER_CREATED_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_USER_CREATED_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_USER_CREATED_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_USER_UPDATED_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_USER_UPDATED_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_USER_UPDATED_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MAX_USER_UPDATED_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_BANK_CODE_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_BANK_CODE_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_BANK_CODE_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_BANK_CODE_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_BANK_ID_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_BANK_ID_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_BANK_ID_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_BANK_ID_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_COUNTRY_CODE_ALPHA3_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_COUNTRY_CODE_ALPHA3_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_COUNTRY_CODE_ALPHA3_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_COUNTRY_CODE_ALPHA3_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_DATE_CREATED_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_DATE_CREATED_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_DATE_CREATED_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_DATE_CREATED_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_DATE_UPDATED_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_DATE_UPDATED_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_DATE_UPDATED_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_DATE_UPDATED_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_ENABLED_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_ENABLED_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_ENABLED_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_ENABLED_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_FULL_NAME_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_FULL_NAME_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_FULL_NAME_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_FULL_NAME_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_REMARK_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_REMARK_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_REMARK_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_REMARK_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_SHORT_NAME_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_SHORT_NAME_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_SHORT_NAME_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_SHORT_NAME_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_SWIFT_CODE_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_SWIFT_CODE_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_SWIFT_CODE_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_SWIFT_CODE_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_USER_CREATED_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_USER_CREATED_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_USER_CREATED_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_USER_CREATED_DESC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_USER_UPDATED_ASC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_USER_UPDATED_ASC',
  BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_USER_UPDATED_DESC = 'BANKS_BY_COUNTRY_CODE_ALPHA3__MIN_USER_UPDATED_DESC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__COUNT_ASC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__COUNT_ASC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__COUNT_DESC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__COUNT_DESC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_CITY_ID_ASC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_CITY_ID_ASC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_CITY_ID_DESC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_CITY_ID_DESC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_COUNTRY_CODE_ALPHA2_ASC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_COUNTRY_CODE_ALPHA2_ASC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_COUNTRY_CODE_ALPHA2_DESC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_COUNTRY_CODE_ALPHA2_DESC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_CREATED_ASC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_CREATED_ASC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_CREATED_DESC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_CREATED_DESC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_UPDATED_ASC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_UPDATED_ASC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_UPDATED_DESC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_UPDATED_DESC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_NAME_ASC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_NAME_ASC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_NAME_DESC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_NAME_DESC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_REMARK_ASC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_REMARK_ASC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_REMARK_DESC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_REMARK_DESC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_CREATED_ASC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_CREATED_ASC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_CREATED_DESC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_CREATED_DESC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_UPDATED_ASC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_UPDATED_ASC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_UPDATED_DESC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_UPDATED_DESC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_UTC_OFFSET_ASC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_UTC_OFFSET_ASC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_UTC_OFFSET_DESC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MAX_UTC_OFFSET_DESC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_CITY_ID_ASC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_CITY_ID_ASC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_CITY_ID_DESC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_CITY_ID_DESC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_COUNTRY_CODE_ALPHA2_ASC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_COUNTRY_CODE_ALPHA2_ASC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_COUNTRY_CODE_ALPHA2_DESC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_COUNTRY_CODE_ALPHA2_DESC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_CREATED_ASC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_CREATED_ASC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_CREATED_DESC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_CREATED_DESC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_UPDATED_ASC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_UPDATED_ASC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_UPDATED_DESC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_UPDATED_DESC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_NAME_ASC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_NAME_ASC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_NAME_DESC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_NAME_DESC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_REMARK_ASC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_REMARK_ASC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_REMARK_DESC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_REMARK_DESC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_CREATED_ASC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_CREATED_ASC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_CREATED_DESC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_CREATED_DESC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_UPDATED_ASC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_UPDATED_ASC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_UPDATED_DESC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_UPDATED_DESC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_UTC_OFFSET_ASC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_UTC_OFFSET_ASC',
  CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_UTC_OFFSET_DESC = 'CITIES_BY_COUNTRY_CODE_ALPHA2__MIN_UTC_OFFSET_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_ABBREVIATION_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_ABBREVIATION_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_ABBREVIATION_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_ABBREVIATION_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_COMPANY_ID_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_COMPANY_ID_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_COMPANY_ID_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_COMPANY_ID_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_DATE_CREATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_DATE_CREATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_DATE_CREATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_DATE_CREATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_DATE_UPDATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_DATE_UPDATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_DATE_UPDATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_DATE_UPDATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_FULL_NAME_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_FULL_NAME_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_FULL_NAME_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_FULL_NAME_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_REMARK_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_REMARK_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_REMARK_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_REMARK_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_USER_CREATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_USER_CREATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_USER_CREATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_USER_CREATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_USER_UPDATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_USER_UPDATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_USER_UPDATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_AVERAGE_USER_UPDATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_COUNT_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_COUNT_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_COUNT_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_COUNT_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_ABBREVIATION_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_ABBREVIATION_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_ABBREVIATION_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_ABBREVIATION_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_COMPANY_ID_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_COMPANY_ID_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_COMPANY_ID_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_COMPANY_ID_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_DATE_CREATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_DATE_CREATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_DATE_CREATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_DATE_CREATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_DATE_UPDATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_DATE_UPDATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_DATE_UPDATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_DATE_UPDATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_FULL_NAME_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_FULL_NAME_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_FULL_NAME_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_FULL_NAME_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_REMARK_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_REMARK_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_REMARK_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_REMARK_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_USER_CREATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_USER_CREATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_USER_CREATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_USER_CREATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_USER_UPDATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_USER_UPDATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_USER_UPDATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_DISTINCT_COUNT_USER_UPDATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_ABBREVIATION_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_ABBREVIATION_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_ABBREVIATION_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_ABBREVIATION_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_COMPANY_ID_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_COMPANY_ID_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_COMPANY_ID_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_COMPANY_ID_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_DATE_CREATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_DATE_CREATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_DATE_CREATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_DATE_CREATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_DATE_UPDATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_DATE_UPDATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_DATE_UPDATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_DATE_UPDATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_FULL_NAME_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_FULL_NAME_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_FULL_NAME_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_FULL_NAME_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_REMARK_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_REMARK_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_REMARK_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_REMARK_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_USER_CREATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_USER_CREATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_USER_CREATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_USER_CREATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_USER_UPDATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_USER_UPDATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_USER_UPDATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MAX_USER_UPDATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_ABBREVIATION_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_ABBREVIATION_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_ABBREVIATION_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_ABBREVIATION_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_COMPANY_ID_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_COMPANY_ID_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_COMPANY_ID_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_COMPANY_ID_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_DATE_CREATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_DATE_CREATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_DATE_CREATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_DATE_CREATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_DATE_UPDATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_DATE_UPDATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_DATE_UPDATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_DATE_UPDATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_FULL_NAME_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_FULL_NAME_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_FULL_NAME_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_FULL_NAME_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_REMARK_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_REMARK_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_REMARK_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_REMARK_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_USER_CREATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_USER_CREATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_USER_CREATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_USER_CREATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_USER_UPDATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_USER_UPDATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_USER_UPDATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_MIN_USER_UPDATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_ABBREVIATION_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_ABBREVIATION_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_ABBREVIATION_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_ABBREVIATION_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_COMPANY_ID_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_COMPANY_ID_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_COMPANY_ID_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_COMPANY_ID_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_DATE_CREATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_DATE_CREATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_DATE_CREATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_DATE_CREATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_DATE_UPDATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_DATE_UPDATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_DATE_UPDATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_DATE_UPDATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_FULL_NAME_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_FULL_NAME_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_FULL_NAME_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_FULL_NAME_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_REMARK_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_REMARK_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_REMARK_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_REMARK_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_USER_CREATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_USER_CREATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_USER_CREATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_USER_CREATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_USER_UPDATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_USER_UPDATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_USER_UPDATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_POPULATION_USER_UPDATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_ABBREVIATION_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_ABBREVIATION_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_ABBREVIATION_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_ABBREVIATION_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_COMPANY_ID_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_COMPANY_ID_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_COMPANY_ID_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_COMPANY_ID_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_DATE_CREATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_DATE_CREATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_DATE_CREATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_DATE_CREATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_DATE_UPDATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_DATE_UPDATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_DATE_UPDATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_DATE_UPDATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_FULL_NAME_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_FULL_NAME_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_FULL_NAME_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_FULL_NAME_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_REMARK_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_REMARK_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_REMARK_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_REMARK_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_USER_CREATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_USER_CREATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_USER_CREATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_USER_CREATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_USER_UPDATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_USER_UPDATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_USER_UPDATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_STDDEV_SAMPLE_USER_UPDATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_ABBREVIATION_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_ABBREVIATION_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_ABBREVIATION_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_ABBREVIATION_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_COMPANY_ID_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_COMPANY_ID_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_COMPANY_ID_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_COMPANY_ID_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_DATE_CREATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_DATE_CREATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_DATE_CREATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_DATE_CREATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_DATE_UPDATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_DATE_UPDATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_DATE_UPDATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_DATE_UPDATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_FULL_NAME_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_FULL_NAME_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_FULL_NAME_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_FULL_NAME_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_REMARK_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_REMARK_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_REMARK_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_REMARK_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_USER_CREATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_USER_CREATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_USER_CREATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_USER_CREATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_USER_UPDATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_USER_UPDATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_USER_UPDATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_SUM_USER_UPDATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_ABBREVIATION_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_ABBREVIATION_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_ABBREVIATION_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_ABBREVIATION_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_COMPANY_ID_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_COMPANY_ID_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_COMPANY_ID_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_COMPANY_ID_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_DATE_CREATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_DATE_CREATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_DATE_CREATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_DATE_CREATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_DATE_UPDATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_DATE_UPDATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_DATE_UPDATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_DATE_UPDATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_FULL_NAME_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_FULL_NAME_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_FULL_NAME_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_FULL_NAME_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_REMARK_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_REMARK_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_REMARK_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_REMARK_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_USER_CREATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_USER_CREATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_USER_CREATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_USER_CREATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_USER_UPDATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_USER_UPDATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_USER_UPDATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_POPULATION_USER_UPDATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_ABBREVIATION_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_ABBREVIATION_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_ABBREVIATION_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_ABBREVIATION_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_COMPANY_ID_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_COMPANY_ID_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_DATE_CREATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_DATE_CREATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_DATE_CREATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_DATE_CREATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_DATE_UPDATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_DATE_UPDATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_DATE_UPDATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_DATE_UPDATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_FULL_NAME_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_FULL_NAME_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_FULL_NAME_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_FULL_NAME_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_REMARK_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_REMARK_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_REMARK_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_REMARK_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_USER_CREATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_USER_CREATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_USER_CREATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_USER_CREATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_USER_UPDATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_USER_UPDATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_USER_UPDATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2_VARIANCE_SAMPLE_USER_UPDATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__COUNT_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__COUNT_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__COUNT_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__COUNT_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_ABBREVIATION_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_ABBREVIATION_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_ABBREVIATION_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_ABBREVIATION_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_COMPANY_ID_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_COMPANY_ID_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_COMPANY_ID_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_COMPANY_ID_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_CREATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_CREATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_CREATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_CREATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_UPDATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_UPDATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_UPDATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_UPDATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_FULL_NAME_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_FULL_NAME_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_FULL_NAME_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_FULL_NAME_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_REMARK_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_REMARK_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_REMARK_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_REMARK_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_CREATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_CREATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_CREATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_CREATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_UPDATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_UPDATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_UPDATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_UPDATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_ABBREVIATION_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_ABBREVIATION_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_ABBREVIATION_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_ABBREVIATION_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_COMPANY_ID_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_COMPANY_ID_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_COMPANY_ID_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_COMPANY_ID_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_CREATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_CREATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_CREATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_CREATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_UPDATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_UPDATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_UPDATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_UPDATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_FULL_NAME_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_FULL_NAME_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_FULL_NAME_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_FULL_NAME_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_REMARK_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_REMARK_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_REMARK_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_REMARK_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_CREATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_CREATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_CREATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_CREATED_DESC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_UPDATED_ASC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_UPDATED_ASC',
  COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_UPDATED_DESC = 'COMPANIES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_UPDATED_DESC',
  COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_CODE_ALPHA2_DESC',
  COUNTRY_CODE_ALPHA3_ASC = 'COUNTRY_CODE_ALPHA3_ASC',
  COUNTRY_CODE_ALPHA3_DESC = 'COUNTRY_CODE_ALPHA3_DESC',
  COUNTRY_CODE_NUMERIC_ASC = 'COUNTRY_CODE_NUMERIC_ASC',
  COUNTRY_CODE_NUMERIC_DESC = 'COUNTRY_CODE_NUMERIC_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DISPLAY_ORDER_ASC = 'DISPLAY_ORDER_ASC',
  DISPLAY_ORDER_DESC = 'DISPLAY_ORDER_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__COUNT_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__COUNT_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__COUNT_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__COUNT_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_APPROVER_ID_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_APPROVER_ID_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_APPROVER_ID_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_APPROVER_ID_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_BANK_ID_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_BANK_ID_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_BANK_ID_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_BANK_ID_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_BANK_REMARKS_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_BANK_REMARKS_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_BANK_REMARKS_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_BANK_REMARKS_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_DATE_CREATED_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_DATE_CREATED_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_DATE_CREATED_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_DATE_CREATED_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_DATE_UPDATED_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_DATE_UPDATED_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_DATE_UPDATED_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_DATE_UPDATED_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_FULL_NAME_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_FULL_NAME_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_FULL_NAME_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_FULL_NAME_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_GENDER_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_GENDER_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_GENDER_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_GENDER_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_ID_NUMBER_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_ID_NUMBER_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_ID_NUMBER_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_ID_NUMBER_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_ID_TYPE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_ID_TYPE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_ID_TYPE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_ID_TYPE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_JOIN_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_JOIN_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_JOIN_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_JOIN_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_NATIONALITY_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_NATIONALITY_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_NATIONALITY_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_NATIONALITY_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_NICKNAME_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_NICKNAME_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_NICKNAME_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_NICKNAME_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PROBATION_END_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PROBATION_END_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PROBATION_END_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_PROBATION_END_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_REMARK_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_REMARK_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_REMARK_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_REMARK_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_STATUS_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_STATUS_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_STATUS_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_STATUS_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_TAX_CODE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_TAX_CODE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_TAX_CODE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_TAX_CODE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_USER_CREATED_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_USER_CREATED_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_USER_CREATED_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_USER_CREATED_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_USER_UPDATED_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_USER_UPDATED_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_USER_UPDATED_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_USER_UPDATED_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_VISA_NUMBER_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_VISA_NUMBER_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_VISA_NUMBER_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_VISA_NUMBER_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_VISA_TYPE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_VISA_TYPE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_VISA_TYPE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_VISA_TYPE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MAX_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_APPROVER_ID_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_APPROVER_ID_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_APPROVER_ID_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_APPROVER_ID_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_BANK_ID_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_BANK_ID_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_BANK_ID_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_BANK_ID_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_BANK_REMARKS_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_BANK_REMARKS_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_BANK_REMARKS_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_BANK_REMARKS_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_DATE_CREATED_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_DATE_CREATED_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_DATE_CREATED_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_DATE_CREATED_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_DATE_UPDATED_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_DATE_UPDATED_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_DATE_UPDATED_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_DATE_UPDATED_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_FULL_NAME_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_FULL_NAME_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_FULL_NAME_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_FULL_NAME_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_GENDER_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_GENDER_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_GENDER_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_GENDER_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_ID_NUMBER_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_ID_NUMBER_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_ID_NUMBER_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_ID_NUMBER_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_ID_TYPE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_ID_TYPE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_ID_TYPE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_ID_TYPE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_JOIN_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_JOIN_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_JOIN_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_JOIN_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_NATIONALITY_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_NATIONALITY_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_NATIONALITY_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_NATIONALITY_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_NICKNAME_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_NICKNAME_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_NICKNAME_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_NICKNAME_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PROBATION_END_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PROBATION_END_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PROBATION_END_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_PROBATION_END_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_REMARK_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_REMARK_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_REMARK_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_REMARK_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_STATUS_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_STATUS_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_STATUS_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_STATUS_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_TAX_CODE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_TAX_CODE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_TAX_CODE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_TAX_CODE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_USER_CREATED_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_USER_CREATED_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_USER_CREATED_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_USER_CREATED_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_USER_UPDATED_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_USER_UPDATED_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_USER_UPDATED_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_USER_UPDATED_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_VISA_NUMBER_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_VISA_NUMBER_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_VISA_NUMBER_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_VISA_NUMBER_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_VISA_TYPE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_VISA_TYPE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_VISA_TYPE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_VISA_TYPE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_MOBILE_NUMBER_COUNTRY_ID__MIN_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEES_BY_NATIONALITY__COUNT_ASC = 'EMPLOYEES_BY_NATIONALITY__COUNT_ASC',
  EMPLOYEES_BY_NATIONALITY__COUNT_DESC = 'EMPLOYEES_BY_NATIONALITY__COUNT_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_APPROVER_ID_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_APPROVER_ID_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_APPROVER_ID_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_APPROVER_ID_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_BANK_ID_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_BANK_ID_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_BANK_ID_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_BANK_ID_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_BANK_REMARKS_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_BANK_REMARKS_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_BANK_REMARKS_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_BANK_REMARKS_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_DATE_CREATED_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_DATE_CREATED_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_DATE_CREATED_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_DATE_CREATED_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_DATE_UPDATED_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_DATE_UPDATED_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_DATE_UPDATED_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_DATE_UPDATED_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_FULL_NAME_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_FULL_NAME_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_FULL_NAME_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_FULL_NAME_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_GENDER_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_GENDER_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_GENDER_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_GENDER_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_ID_NUMBER_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_ID_NUMBER_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_ID_NUMBER_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_ID_NUMBER_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_ID_TYPE_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_ID_TYPE_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_ID_TYPE_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_ID_TYPE_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_JOIN_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_JOIN_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_JOIN_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_JOIN_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_NATIONALITY_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_NATIONALITY_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_NATIONALITY_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_NATIONALITY_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_NICKNAME_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_NICKNAME_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_NICKNAME_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_NICKNAME_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_PROBATION_END_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_PROBATION_END_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_PROBATION_END_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_PROBATION_END_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_REMARK_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_REMARK_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_REMARK_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_REMARK_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_STATUS_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_STATUS_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_STATUS_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_STATUS_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_TAX_CODE_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_TAX_CODE_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_TAX_CODE_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_TAX_CODE_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_USER_CREATED_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_USER_CREATED_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_USER_CREATED_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_USER_CREATED_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_USER_UPDATED_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_USER_UPDATED_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_USER_UPDATED_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_USER_UPDATED_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_VISA_NUMBER_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_VISA_NUMBER_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_VISA_NUMBER_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_VISA_NUMBER_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_VISA_TYPE_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_VISA_TYPE_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_VISA_TYPE_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_VISA_TYPE_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MAX_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_NATIONALITY__MAX_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_NATIONALITY__MAX_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_NATIONALITY__MAX_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_APPROVER_ID_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_APPROVER_ID_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_APPROVER_ID_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_APPROVER_ID_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_BANK_ID_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_BANK_ID_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_BANK_ID_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_BANK_ID_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_BANK_REMARKS_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_BANK_REMARKS_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_BANK_REMARKS_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_BANK_REMARKS_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_DATE_CREATED_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_DATE_CREATED_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_DATE_CREATED_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_DATE_CREATED_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_DATE_UPDATED_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_DATE_UPDATED_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_DATE_UPDATED_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_DATE_UPDATED_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_FULL_NAME_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_FULL_NAME_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_FULL_NAME_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_FULL_NAME_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_GENDER_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_GENDER_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_GENDER_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_GENDER_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_ID_NUMBER_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_ID_NUMBER_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_ID_NUMBER_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_ID_NUMBER_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_ID_TYPE_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_ID_TYPE_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_ID_TYPE_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_ID_TYPE_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_JOIN_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_JOIN_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_JOIN_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_JOIN_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_NATIONALITY_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_NATIONALITY_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_NATIONALITY_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_NATIONALITY_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_NICKNAME_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_NICKNAME_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_NICKNAME_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_NICKNAME_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_PROBATION_END_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_PROBATION_END_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_PROBATION_END_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_PROBATION_END_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_REMARK_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_REMARK_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_REMARK_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_REMARK_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_STATUS_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_STATUS_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_STATUS_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_STATUS_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_TAX_CODE_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_TAX_CODE_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_TAX_CODE_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_TAX_CODE_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_USER_CREATED_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_USER_CREATED_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_USER_CREATED_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_USER_CREATED_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_USER_UPDATED_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_USER_UPDATED_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_USER_UPDATED_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_USER_UPDATED_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_VISA_NUMBER_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_VISA_NUMBER_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_VISA_NUMBER_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_VISA_NUMBER_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_VISA_TYPE_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_VISA_TYPE_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_VISA_TYPE_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_VISA_TYPE_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_NATIONALITY__MIN_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_NATIONALITY__MIN_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_NATIONALITY__MIN_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_NATIONALITY__MIN_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__COUNT_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__COUNT_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__COUNT_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__COUNT_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_APPROVER_ID_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_APPROVER_ID_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_APPROVER_ID_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_APPROVER_ID_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_BANK_ID_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_BANK_ID_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_BANK_ID_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_BANK_ID_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_BANK_REMARKS_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_BANK_REMARKS_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_BANK_REMARKS_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_BANK_REMARKS_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_DATE_CREATED_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_DATE_CREATED_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_DATE_CREATED_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_DATE_CREATED_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_DATE_UPDATED_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_DATE_UPDATED_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_DATE_UPDATED_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_DATE_UPDATED_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_FULL_NAME_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_FULL_NAME_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_FULL_NAME_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_FULL_NAME_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_GENDER_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_GENDER_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_GENDER_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_GENDER_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_ID_NUMBER_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_ID_NUMBER_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_ID_NUMBER_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_ID_NUMBER_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_ID_TYPE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_ID_TYPE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_ID_TYPE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_ID_TYPE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_JOIN_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_JOIN_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_JOIN_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_JOIN_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_NATIONALITY_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_NATIONALITY_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_NATIONALITY_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_NATIONALITY_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_NICKNAME_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_NICKNAME_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_NICKNAME_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_NICKNAME_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PROBATION_END_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PROBATION_END_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PROBATION_END_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_PROBATION_END_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_REMARK_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_REMARK_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_REMARK_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_REMARK_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_STATUS_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_STATUS_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_STATUS_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_STATUS_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_TAX_CODE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_TAX_CODE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_TAX_CODE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_TAX_CODE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_USER_CREATED_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_USER_CREATED_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_USER_CREATED_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_USER_CREATED_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_USER_UPDATED_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_USER_UPDATED_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_USER_UPDATED_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_USER_UPDATED_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_VISA_NUMBER_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_VISA_NUMBER_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_VISA_NUMBER_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_VISA_NUMBER_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_VISA_TYPE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_VISA_TYPE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_VISA_TYPE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_VISA_TYPE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MAX_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_APPROVER_ID_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_APPROVER_ID_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_APPROVER_ID_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_APPROVER_ID_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_BANK_ID_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_BANK_ID_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_BANK_ID_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_BANK_ID_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_BANK_REMARKS_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_BANK_REMARKS_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_BANK_REMARKS_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_BANK_REMARKS_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_DATE_CREATED_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_DATE_CREATED_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_DATE_CREATED_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_DATE_CREATED_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_DATE_UPDATED_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_DATE_UPDATED_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_DATE_UPDATED_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_DATE_UPDATED_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_FULL_NAME_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_FULL_NAME_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_FULL_NAME_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_FULL_NAME_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_GENDER_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_GENDER_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_GENDER_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_GENDER_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_ID_NUMBER_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_ID_NUMBER_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_ID_NUMBER_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_ID_NUMBER_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_ID_TYPE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_ID_TYPE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_ID_TYPE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_ID_TYPE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_JOIN_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_JOIN_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_JOIN_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_JOIN_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_NATIONALITY_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_NATIONALITY_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_NATIONALITY_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_NATIONALITY_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_NICKNAME_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_NICKNAME_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_NICKNAME_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_NICKNAME_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PROBATION_END_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PROBATION_END_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PROBATION_END_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_PROBATION_END_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_REMARK_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_REMARK_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_REMARK_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_REMARK_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_STATUS_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_STATUS_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_STATUS_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_STATUS_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_TAX_CODE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_TAX_CODE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_TAX_CODE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_TAX_CODE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_USER_CREATED_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_USER_CREATED_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_USER_CREATED_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_USER_CREATED_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_USER_UPDATED_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_USER_UPDATED_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_USER_UPDATED_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_USER_UPDATED_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_VISA_NUMBER_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_VISA_NUMBER_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_VISA_NUMBER_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_VISA_NUMBER_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_VISA_TYPE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_VISA_TYPE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_VISA_TYPE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_VISA_TYPE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__MIN_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__COUNT_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__COUNT_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__COUNT_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__COUNT_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_CITY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_CITY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_CITY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_CITY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_COMPANY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_COMPANY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_COMPANY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_COMPANY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_DEPARTMENT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_DEPARTMENT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_DEPARTMENT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_DEPARTMENT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_DIVISION_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_DIVISION_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_DIVISION_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_DIVISION_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_EFFECTIVE_DATE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_EFFECTIVE_DATE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_EFFECTIVE_DATE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_EFFECTIVE_DATE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_CODE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_CODE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_CODE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_CODE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_JOB_TITLE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_JOB_TITLE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_JOB_TITLE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_JOB_TITLE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_OFFICE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_OFFICE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_OFFICE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_OFFICE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_TEAM_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_TEAM_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_TEAM_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MAX_TEAM_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_CITY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_CITY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_CITY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_CITY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_COMPANY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_COMPANY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_COMPANY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_COMPANY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_DEPARTMENT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_DEPARTMENT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_DEPARTMENT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_DEPARTMENT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_DIVISION_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_DIVISION_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_DIVISION_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_DIVISION_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_EFFECTIVE_DATE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_EFFECTIVE_DATE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_EFFECTIVE_DATE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_EFFECTIVE_DATE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_CODE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_CODE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_CODE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_CODE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_JOB_TITLE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_JOB_TITLE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_JOB_TITLE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_JOB_TITLE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_OFFICE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_OFFICE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_OFFICE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_OFFICE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_TEAM_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_TEAM_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_TEAM_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_COUNTRY_CODE_ALPHA2__MIN_TEAM_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__COUNT_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__COUNT_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__COUNT_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__COUNT_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_DATE_CREATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_DATE_CREATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_DATE_CREATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_DATE_CREATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_EMPLOYEE_EMERGENCY_CONTACT_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_EMPLOYEE_EMERGENCY_CONTACT_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_EMPLOYEE_EMERGENCY_CONTACT_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_EMPLOYEE_EMERGENCY_CONTACT_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_NAME_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_NAME_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_NAME_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_NAME_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_RELATION_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_RELATION_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_RELATION_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_RELATION_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_REMARK_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_REMARK_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_REMARK_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_REMARK_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_TELEPHONE_NUMBER_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_TELEPHONE_NUMBER_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_TELEPHONE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_TELEPHONE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_TELEPHONE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_TELEPHONE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_TELEPHONE_NUMBER_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_TELEPHONE_NUMBER_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_USER_CREATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_USER_CREATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_USER_CREATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_USER_CREATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_USER_UPDATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_USER_UPDATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_USER_UPDATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MAX_USER_UPDATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_DATE_CREATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_DATE_CREATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_DATE_CREATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_DATE_CREATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_EMPLOYEE_EMERGENCY_CONTACT_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_EMPLOYEE_EMERGENCY_CONTACT_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_EMPLOYEE_EMERGENCY_CONTACT_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_EMPLOYEE_EMERGENCY_CONTACT_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_NAME_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_NAME_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_NAME_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_NAME_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_RELATION_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_RELATION_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_RELATION_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_RELATION_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_REMARK_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_REMARK_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_REMARK_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_REMARK_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_TELEPHONE_NUMBER_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_TELEPHONE_NUMBER_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_TELEPHONE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_TELEPHONE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_TELEPHONE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_TELEPHONE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_TELEPHONE_NUMBER_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_TELEPHONE_NUMBER_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_USER_CREATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_USER_CREATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_USER_CREATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_USER_CREATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_USER_UPDATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_USER_UPDATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_USER_UPDATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_TELEPHONE_NUMBER_COUNTRY_ID__MIN_USER_UPDATED_DESC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__COUNT_ASC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__COUNT_ASC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__COUNT_DESC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__COUNT_DESC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_CODE_ASC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_CODE_ASC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_CODE_DESC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_CODE_DESC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_CREATED_ASC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_CREATED_ASC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_CREATED_DESC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_CREATED_DESC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_DISPLAY_NAME_ASC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_DISPLAY_NAME_ASC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_DISPLAY_NAME_DESC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_DISPLAY_NAME_DESC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_DISPLAY_ORDER_ASC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_DISPLAY_ORDER_ASC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_DISPLAY_ORDER_DESC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_DISPLAY_ORDER_DESC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_ID_TYPE_ID_ASC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_ID_TYPE_ID_ASC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_ID_TYPE_ID_DESC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_EMPLOYEE_ID_TYPE_ID_DESC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_REMARK_ASC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_REMARK_ASC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_REMARK_DESC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_REMARK_DESC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_CREATED_ASC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_CREATED_ASC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_CREATED_DESC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_CREATED_DESC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_UPDATED_ASC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_UPDATED_ASC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_UPDATED_DESC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_UPDATED_DESC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_CODE_ASC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_CODE_ASC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_CODE_DESC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_CODE_DESC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_CREATED_ASC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_CREATED_ASC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_CREATED_DESC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_CREATED_DESC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_DISPLAY_NAME_ASC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_DISPLAY_NAME_ASC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_DISPLAY_NAME_DESC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_DISPLAY_NAME_DESC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_DISPLAY_ORDER_ASC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_DISPLAY_ORDER_ASC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_DISPLAY_ORDER_DESC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_DISPLAY_ORDER_DESC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_ID_TYPE_ID_ASC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_ID_TYPE_ID_ASC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_ID_TYPE_ID_DESC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_EMPLOYEE_ID_TYPE_ID_DESC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_REMARK_ASC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_REMARK_ASC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_REMARK_DESC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_REMARK_DESC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_CREATED_ASC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_CREATED_ASC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_CREATED_DESC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_CREATED_DESC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_UPDATED_ASC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_UPDATED_ASC',
  EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_UPDATED_DESC = 'EMPLOYEE_ID_TYPES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_UPDATED_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__COUNT_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__COUNT_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__COUNT_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__COUNT_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_COMPANY_ID_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_COMPANY_ID_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_COMPANY_ID_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_COMPANY_ID_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_CONTRACT_TYPE_ID_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_CONTRACT_TYPE_ID_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_CONTRACT_TYPE_ID_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_CONTRACT_TYPE_ID_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_COUNTRY_CODE_ALPHA2_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_COUNTRY_CODE_ALPHA2_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_COUNTRY_CODE_ALPHA2_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_COUNTRY_CODE_ALPHA2_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_DEFAULT_PROBATION_PERIOD_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_DEFAULT_PROBATION_PERIOD_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_DEFAULT_PROBATION_PERIOD_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_DEFAULT_PROBATION_PERIOD_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_ENABLED_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_ENABLED_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_ENABLED_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_ENABLED_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_PROBATION_DATE_INTERVAL_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_PROBATION_DATE_INTERVAL_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_PROBATION_DATE_INTERVAL_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_PROBATION_DATE_INTERVAL_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_PROBATION_REVIEW_ENABLE_ID_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_PROBATION_REVIEW_ENABLE_ID_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_PROBATION_REVIEW_ENABLE_ID_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MAX_PROBATION_REVIEW_ENABLE_ID_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_COMPANY_ID_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_COMPANY_ID_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_COMPANY_ID_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_COMPANY_ID_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_CONTRACT_TYPE_ID_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_CONTRACT_TYPE_ID_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_CONTRACT_TYPE_ID_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_CONTRACT_TYPE_ID_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_COUNTRY_CODE_ALPHA2_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_COUNTRY_CODE_ALPHA2_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_COUNTRY_CODE_ALPHA2_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_COUNTRY_CODE_ALPHA2_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_DEFAULT_PROBATION_PERIOD_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_DEFAULT_PROBATION_PERIOD_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_DEFAULT_PROBATION_PERIOD_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_DEFAULT_PROBATION_PERIOD_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_ENABLED_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_ENABLED_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_ENABLED_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_ENABLED_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_PROBATION_DATE_INTERVAL_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_PROBATION_DATE_INTERVAL_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_PROBATION_DATE_INTERVAL_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_PROBATION_DATE_INTERVAL_DESC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_PROBATION_REVIEW_ENABLE_ID_ASC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_PROBATION_REVIEW_ENABLE_ID_ASC',
  PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_PROBATION_REVIEW_ENABLE_ID_DESC = 'PROBATION_REVIEW_ENABLE_VIEWS_BY_COUNTRY_CODE_ALPHA2__MIN_PROBATION_REVIEW_ENABLE_ID_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__COUNT_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__COUNT_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__COUNT_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__COUNT_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_COUNTRY_CODE_ALPHA2_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_COUNTRY_CODE_ALPHA2_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_COUNTRY_CODE_ALPHA2_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_COUNTRY_CODE_ALPHA2_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_CURRENCY_CODE_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_CURRENCY_CODE_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_CURRENCY_CODE_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_CURRENCY_CODE_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_CREATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_CREATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_CREATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_CREATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_UPDATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_UPDATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_UPDATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_DATE_UPDATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_REMARK_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_REMARK_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_REMARK_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_REMARK_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_CREATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_CREATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_CREATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_CREATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_UPDATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_UPDATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_UPDATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MAX_USER_UPDATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_COUNTRY_CODE_ALPHA2_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_COUNTRY_CODE_ALPHA2_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_COUNTRY_CODE_ALPHA2_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_COUNTRY_CODE_ALPHA2_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_CURRENCY_CODE_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_CURRENCY_CODE_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_CURRENCY_CODE_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_CURRENCY_CODE_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_CREATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_CREATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_CREATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_CREATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_UPDATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_UPDATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_UPDATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_DATE_UPDATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_REMARK_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_REMARK_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_REMARK_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_REMARK_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_CREATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_CREATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_CREATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_CREATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_UPDATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_UPDATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_UPDATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_COUNTRY_CODE_ALPHA2__MIN_USER_UPDATED_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  SHORT_NAME_ASC = 'SHORT_NAME_ASC',
  SHORT_NAME_DESC = 'SHORT_NAME_DESC',
  TELEPHONE_CODE_ASC = 'TELEPHONE_CODE_ASC',
  TELEPHONE_CODE_DESC = 'TELEPHONE_CODE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface Country extends Node {
  __typename?: 'Country';
  banksByCountryCodeAlpha3: BanksConnection;
  citiesByCountryCodeAlpha2: CitiesConnection;
  companiesByCountryCodeAlpha2: CompaniesConnection;
  countryCodeAlpha2: Scalars['String']['output'];
  countryCodeAlpha3: Scalars['String']['output'];
  countryCodeNumeric?: Maybe<Scalars['String']['output']>;
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  displayOrder?: Maybe<Scalars['Int']['output']>;
  employeeCurrentPositionsByCountryCodeAlpha2: EmployeeCurrentPositionsConnection;
  employeeEmergencyContactsByTelephoneNumberCountryId: EmployeeEmergencyContactsConnection;
  employeeIdTypesByCountryCodeAlpha2: EmployeeIdTypesConnection;
  employeesByMobileNumberCountryId: EmployeesConnection;
  employeesByNationality: EmployeesConnection;
  employeesByWorkingLocationCountryCodeAlpha2: EmployeesConnection;
  nodeId: Scalars['ID']['output'];
  probationReviewEnableViewsByCountryCodeAlpha2: ProbationReviewEnableViewsConnection;
  relationCurrencyCountriesByCountryCodeAlpha2: RelationCurrencyCountriesConnection;
  remark?: Maybe<Scalars['String']['output']>;
  shortName: Scalars['String']['output'];
  telephoneCode?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export type CountryBanksByCountryCodeAlpha3Args = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<BankCondition>;
  filter?: InputMaybe<BankFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BanksOrderBy>>;
};

export type CountryCitiesByCountryCodeAlpha2Args = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CityCondition>;
  filter?: InputMaybe<CityFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CitiesOrderBy>>;
};

export type CountryCompaniesByCountryCodeAlpha2Args = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CompanyCondition>;
  filter?: InputMaybe<CompanyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompaniesOrderBy>>;
};

export type CountryEmployeeCurrentPositionsByCountryCodeAlpha2Args = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCurrentPositionCondition>;
  filter?: InputMaybe<EmployeeCurrentPositionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeCurrentPositionsOrderBy>>;
};

export type CountryEmployeeEmergencyContactsByTelephoneNumberCountryIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeEmergencyContactCondition>;
  filter?: InputMaybe<EmployeeEmergencyContactFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeEmergencyContactsOrderBy>>;
};

export type CountryEmployeeIdTypesByCountryCodeAlpha2Args = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeIdTypeCondition>;
  filter?: InputMaybe<EmployeeIdTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeIdTypesOrderBy>>;
};

export type CountryEmployeesByMobileNumberCountryIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCondition>;
  filter?: InputMaybe<EmployeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeesOrderBy>>;
};

export type CountryEmployeesByNationalityArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCondition>;
  filter?: InputMaybe<EmployeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeesOrderBy>>;
};

export type CountryEmployeesByWorkingLocationCountryCodeAlpha2Args = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCondition>;
  filter?: InputMaybe<EmployeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeesOrderBy>>;
};

export type CountryProbationReviewEnableViewsByCountryCodeAlpha2Args = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEnableViewCondition>;
  filter?: InputMaybe<ProbationReviewEnableViewFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEnableViewsOrderBy>>;
};

export type CountryRelationCurrencyCountriesByCountryCodeAlpha2Args = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationCurrencyCountryCondition>;
  filter?: InputMaybe<RelationCurrencyCountryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationCurrencyCountriesOrderBy>>;
};

export interface CountryCondition {
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha3?: InputMaybe<Scalars['String']['input']>;
  countryCodeNumeric?: InputMaybe<Scalars['String']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  telephoneCode?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CountryCountryCountryCodeAlpha3KeyConnect {
  countryCodeAlpha3: Scalars['String']['input'];
}

export interface CountryCountryCountryCodeAlpha3KeyDelete {
  countryCodeAlpha3: Scalars['String']['input'];
}

export interface CountryCountryCountryCodeNumericKeyConnect {
  countryCodeNumeric: Scalars['String']['input'];
}

export interface CountryCountryCountryCodeNumericKeyDelete {
  countryCodeNumeric: Scalars['String']['input'];
}

export interface CountryCountryDisplayOrderKeyConnect {
  displayOrder: Scalars['Int']['input'];
}

export interface CountryCountryDisplayOrderKeyDelete {
  displayOrder: Scalars['Int']['input'];
}

export interface CountryCountryPkeyConnect {
  countryCodeAlpha2: Scalars['String']['input'];
}

export interface CountryCountryPkeyDelete {
  countryCodeAlpha2: Scalars['String']['input'];
}

export interface CountryFilter {
  and?: InputMaybe<Array<CountryFilter>>;
  banksByCountryCodeAlpha3?: InputMaybe<CountryToManyBankFilter>;
  banksByCountryCodeAlpha3Exist?: InputMaybe<Scalars['Boolean']['input']>;
  citiesByCountryCodeAlpha2?: InputMaybe<CountryToManyCityFilter>;
  citiesByCountryCodeAlpha2Exist?: InputMaybe<Scalars['Boolean']['input']>;
  companiesByCountryCodeAlpha2?: InputMaybe<CountryToManyCompanyFilter>;
  companiesByCountryCodeAlpha2Exist?: InputMaybe<Scalars['Boolean']['input']>;
  countryCodeAlpha2?: InputMaybe<StringFilter>;
  countryCodeAlpha3?: InputMaybe<StringFilter>;
  countryCodeNumeric?: InputMaybe<StringFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  employeeCurrentPositionsByCountryCodeAlpha2?: InputMaybe<CountryToManyEmployeeCurrentPositionFilter>;
  employeeCurrentPositionsByCountryCodeAlpha2Exist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeEmergencyContactsByTelephoneNumberCountryId?: InputMaybe<CountryToManyEmployeeEmergencyContactFilter>;
  employeeEmergencyContactsByTelephoneNumberCountryIdExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeIdTypesByCountryCodeAlpha2?: InputMaybe<CountryToManyEmployeeIdTypeFilter>;
  employeeIdTypesByCountryCodeAlpha2Exist?: InputMaybe<Scalars['Boolean']['input']>;
  employeesByMobileNumberCountryId?: InputMaybe<CountryToManyEmployeeFilter>;
  employeesByMobileNumberCountryIdExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeesByNationality?: InputMaybe<CountryToManyEmployeeFilter>;
  employeesByNationalityExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeesByWorkingLocationCountryCodeAlpha2?: InputMaybe<CountryToManyEmployeeFilter>;
  employeesByWorkingLocationCountryCodeAlpha2Exist?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<CountryFilter>;
  or?: InputMaybe<Array<CountryFilter>>;
  probationReviewEnableViewsByCountryCodeAlpha2?: InputMaybe<CountryToManyProbationReviewEnableViewFilter>;
  probationReviewEnableViewsByCountryCodeAlpha2Exist?: InputMaybe<Scalars['Boolean']['input']>;
  relationCurrencyCountriesByCountryCodeAlpha2?: InputMaybe<CountryToManyRelationCurrencyCountryFilter>;
  relationCurrencyCountriesByCountryCodeAlpha2Exist?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<StringFilter>;
  shortName?: InputMaybe<StringFilter>;
  telephoneCode?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface CountryInput {
  banks?: InputMaybe<BankCountryCodeAlpha3FkeyInverseInput>;
  cities?: InputMaybe<CityCountryCodeAlpha2FkeyInverseInput>;
  companies?: InputMaybe<CompanyCountryCodeAlpha2FkeyInverseInput>;
  countryCodeAlpha2: Scalars['String']['input'];
  countryCodeAlpha3: Scalars['String']['input'];
  countryCodeNumeric?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInverseInput>;
  employeeIdTypes?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInverseInput>;
  employeesToMobileNumberCountryIdUsingCountryCodeAlpha2?: InputMaybe<EmployeeMobileNumberCountryIdFkeyInverseInput>;
  employeesToNationalityUsingCountryCodeAlpha3?: InputMaybe<EmployeeNationalityFkeyInverseInput>;
  employeesToWorkingLocationCountryCodeAlpha2UsingCountryCodeAlpha2?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0InverseInput>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName: Scalars['String']['input'];
  telephoneCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CountryNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface CountryNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface CountryOnBankForBankCountryCodeAlpha3FkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: BankPatch;
}

export interface CountryOnBankForBankCountryCodeAlpha3FkeyUsingCountryCountryCodeAlpha3KeyUpdate {
  countryCodeAlpha3: Scalars['String']['input'];
  patch: UpdateCountryOnBankForBankCountryCodeAlpha3FkeyPatch;
}

export interface CountryOnBankForBankCountryCodeAlpha3FkeyUsingCountryCountryCodeNumericKeyUpdate {
  countryCodeNumeric: Scalars['String']['input'];
  patch: UpdateCountryOnBankForBankCountryCodeAlpha3FkeyPatch;
}

export interface CountryOnBankForBankCountryCodeAlpha3FkeyUsingCountryDisplayOrderKeyUpdate {
  displayOrder: Scalars['Int']['input'];
  patch: UpdateCountryOnBankForBankCountryCodeAlpha3FkeyPatch;
}

export interface CountryOnBankForBankCountryCodeAlpha3FkeyUsingCountryPkeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  patch: UpdateCountryOnBankForBankCountryCodeAlpha3FkeyPatch;
}

export interface CountryOnCityForCityCountryCodeAlpha2FkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CityPatch;
}

export interface CountryOnCityForCityCountryCodeAlpha2FkeyUsingCountryCountryCodeAlpha3KeyUpdate {
  countryCodeAlpha3: Scalars['String']['input'];
  patch: UpdateCountryOnCityForCityCountryCodeAlpha2FkeyPatch;
}

export interface CountryOnCityForCityCountryCodeAlpha2FkeyUsingCountryCountryCodeNumericKeyUpdate {
  countryCodeNumeric: Scalars['String']['input'];
  patch: UpdateCountryOnCityForCityCountryCodeAlpha2FkeyPatch;
}

export interface CountryOnCityForCityCountryCodeAlpha2FkeyUsingCountryDisplayOrderKeyUpdate {
  displayOrder: Scalars['Int']['input'];
  patch: UpdateCountryOnCityForCityCountryCodeAlpha2FkeyPatch;
}

export interface CountryOnCityForCityCountryCodeAlpha2FkeyUsingCountryPkeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  patch: UpdateCountryOnCityForCityCountryCodeAlpha2FkeyPatch;
}

export interface CountryOnCompanyForCompanyCountryCodeAlpha2FkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyPatch;
}

export interface CountryOnCompanyForCompanyCountryCodeAlpha2FkeyUsingCountryCountryCodeAlpha3KeyUpdate {
  countryCodeAlpha3: Scalars['String']['input'];
  patch: UpdateCountryOnCompanyForCompanyCountryCodeAlpha2FkeyPatch;
}

export interface CountryOnCompanyForCompanyCountryCodeAlpha2FkeyUsingCountryCountryCodeNumericKeyUpdate {
  countryCodeNumeric: Scalars['String']['input'];
  patch: UpdateCountryOnCompanyForCompanyCountryCodeAlpha2FkeyPatch;
}

export interface CountryOnCompanyForCompanyCountryCodeAlpha2FkeyUsingCountryDisplayOrderKeyUpdate {
  displayOrder: Scalars['Int']['input'];
  patch: UpdateCountryOnCompanyForCompanyCountryCodeAlpha2FkeyPatch;
}

export interface CountryOnCompanyForCompanyCountryCodeAlpha2FkeyUsingCountryPkeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  patch: UpdateCountryOnCompanyForCompanyCountryCodeAlpha2FkeyPatch;
}

export interface CountryOnCountryForCountryUserCreatedFkeyUsingCountryCountryCodeAlpha3KeyUpdate {
  countryCodeAlpha3: Scalars['String']['input'];
  patch: UpdateCountryOnCountryForCountryUserCreatedFkeyPatch;
}

export interface CountryOnCountryForCountryUserCreatedFkeyUsingCountryCountryCodeNumericKeyUpdate {
  countryCodeNumeric: Scalars['String']['input'];
  patch: UpdateCountryOnCountryForCountryUserCreatedFkeyPatch;
}

export interface CountryOnCountryForCountryUserCreatedFkeyUsingCountryDisplayOrderKeyUpdate {
  displayOrder: Scalars['Int']['input'];
  patch: UpdateCountryOnCountryForCountryUserCreatedFkeyPatch;
}

export interface CountryOnCountryForCountryUserCreatedFkeyUsingCountryPkeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  patch: UpdateCountryOnCountryForCountryUserCreatedFkeyPatch;
}

export interface CountryOnCountryForCountryUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface CountryOnCountryForCountryUserUpdatedFkeyUsingCountryCountryCodeAlpha3KeyUpdate {
  countryCodeAlpha3: Scalars['String']['input'];
  patch: UpdateCountryOnCountryForCountryUserUpdatedFkeyPatch;
}

export interface CountryOnCountryForCountryUserUpdatedFkeyUsingCountryCountryCodeNumericKeyUpdate {
  countryCodeNumeric: Scalars['String']['input'];
  patch: UpdateCountryOnCountryForCountryUserUpdatedFkeyPatch;
}

export interface CountryOnCountryForCountryUserUpdatedFkeyUsingCountryDisplayOrderKeyUpdate {
  displayOrder: Scalars['Int']['input'];
  patch: UpdateCountryOnCountryForCountryUserUpdatedFkeyPatch;
}

export interface CountryOnCountryForCountryUserUpdatedFkeyUsingCountryPkeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  patch: UpdateCountryOnCountryForCountryUserUpdatedFkeyPatch;
}

export interface CountryOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey4NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeCurrentPositionPatch;
}

export interface CountryOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey4UsingCountryCountryCodeAlpha3KeyUpdate {
  countryCodeAlpha3: Scalars['String']['input'];
  patch: UpdateCountryOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey4Patch;
}

export interface CountryOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey4UsingCountryCountryCodeNumericKeyUpdate {
  countryCodeNumeric: Scalars['String']['input'];
  patch: UpdateCountryOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey4Patch;
}

export interface CountryOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey4UsingCountryDisplayOrderKeyUpdate {
  displayOrder: Scalars['Int']['input'];
  patch: UpdateCountryOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey4Patch;
}

export interface CountryOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey4UsingCountryPkeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  patch: UpdateCountryOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey4Patch;
}

export interface CountryOnEmployeeEmergencyContactForEmployeeEmergencyContactTelephoneNumberCountryIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeEmergencyContactPatch;
}

export interface CountryOnEmployeeEmergencyContactForEmployeeEmergencyContactTelephoneNumberCountryIdFkeyUsingCountryCountryCodeAlpha3KeyUpdate {
  countryCodeAlpha3: Scalars['String']['input'];
  patch: UpdateCountryOnEmployeeEmergencyContactForEmployeeEmergencyContactTelephoneNumberCountryIdFkeyPatch;
}

export interface CountryOnEmployeeEmergencyContactForEmployeeEmergencyContactTelephoneNumberCountryIdFkeyUsingCountryCountryCodeNumericKeyUpdate {
  countryCodeNumeric: Scalars['String']['input'];
  patch: UpdateCountryOnEmployeeEmergencyContactForEmployeeEmergencyContactTelephoneNumberCountryIdFkeyPatch;
}

export interface CountryOnEmployeeEmergencyContactForEmployeeEmergencyContactTelephoneNumberCountryIdFkeyUsingCountryDisplayOrderKeyUpdate {
  displayOrder: Scalars['Int']['input'];
  patch: UpdateCountryOnEmployeeEmergencyContactForEmployeeEmergencyContactTelephoneNumberCountryIdFkeyPatch;
}

export interface CountryOnEmployeeEmergencyContactForEmployeeEmergencyContactTelephoneNumberCountryIdFkeyUsingCountryPkeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  patch: UpdateCountryOnEmployeeEmergencyContactForEmployeeEmergencyContactTelephoneNumberCountryIdFkeyPatch;
}

export interface CountryOnEmployeeForEmployeeMobileNumberCountryIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface CountryOnEmployeeForEmployeeNationalityFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface CountryOnEmployeeForEmployeeWorkingLocationCountryCodeAlpha2FkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface CountryOnEmployeeForEmployeeWorkingLocationCountryCodeAlpha2FkeyUsingCountryCountryCodeAlpha3KeyUpdate {
  countryCodeAlpha3: Scalars['String']['input'];
  patch: UpdateCountryOnEmployeeForEmployeeWorkingLocationCountryCodeAlpha2FkeyPatch;
}

export interface CountryOnEmployeeForEmployeeWorkingLocationCountryCodeAlpha2FkeyUsingCountryCountryCodeNumericKeyUpdate {
  countryCodeNumeric: Scalars['String']['input'];
  patch: UpdateCountryOnEmployeeForEmployeeWorkingLocationCountryCodeAlpha2FkeyPatch;
}

export interface CountryOnEmployeeForEmployeeWorkingLocationCountryCodeAlpha2FkeyUsingCountryDisplayOrderKeyUpdate {
  displayOrder: Scalars['Int']['input'];
  patch: UpdateCountryOnEmployeeForEmployeeWorkingLocationCountryCodeAlpha2FkeyPatch;
}

export interface CountryOnEmployeeForEmployeeWorkingLocationCountryCodeAlpha2FkeyUsingCountryPkeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  patch: UpdateCountryOnEmployeeForEmployeeWorkingLocationCountryCodeAlpha2FkeyPatch;
}

export interface CountryOnEmployeeIdTypeForEmployeeIdTypeCountryCodeAlpha2FkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeIdTypePatch;
}

export interface CountryOnEmployeeIdTypeForEmployeeIdTypeCountryCodeAlpha2FkeyUsingCountryCountryCodeAlpha3KeyUpdate {
  countryCodeAlpha3: Scalars['String']['input'];
  patch: UpdateCountryOnEmployeeIdTypeForEmployeeIdTypeCountryCodeAlpha2FkeyPatch;
}

export interface CountryOnEmployeeIdTypeForEmployeeIdTypeCountryCodeAlpha2FkeyUsingCountryCountryCodeNumericKeyUpdate {
  countryCodeNumeric: Scalars['String']['input'];
  patch: UpdateCountryOnEmployeeIdTypeForEmployeeIdTypeCountryCodeAlpha2FkeyPatch;
}

export interface CountryOnEmployeeIdTypeForEmployeeIdTypeCountryCodeAlpha2FkeyUsingCountryDisplayOrderKeyUpdate {
  displayOrder: Scalars['Int']['input'];
  patch: UpdateCountryOnEmployeeIdTypeForEmployeeIdTypeCountryCodeAlpha2FkeyPatch;
}

export interface CountryOnEmployeeIdTypeForEmployeeIdTypeCountryCodeAlpha2FkeyUsingCountryPkeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  patch: UpdateCountryOnEmployeeIdTypeForEmployeeIdTypeCountryCodeAlpha2FkeyPatch;
}

export interface CountryOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey0NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEnableViewPatch;
}

export interface CountryOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey0UsingCountryCountryCodeAlpha3KeyUpdate {
  countryCodeAlpha3: Scalars['String']['input'];
  patch: UpdateCountryOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey0Patch;
}

export interface CountryOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey0UsingCountryCountryCodeNumericKeyUpdate {
  countryCodeNumeric: Scalars['String']['input'];
  patch: UpdateCountryOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey0Patch;
}

export interface CountryOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey0UsingCountryDisplayOrderKeyUpdate {
  displayOrder: Scalars['Int']['input'];
  patch: UpdateCountryOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey0Patch;
}

export interface CountryOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey0UsingCountryPkeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  patch: UpdateCountryOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey0Patch;
}

export interface CountryOnRelationCurrencyCountryForRelationCurrencyCountryCountryCodeAlpha2FkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationCurrencyCountryPatch;
}

export interface CountryOnRelationCurrencyCountryForRelationCurrencyCountryCountryCodeAlpha2FkeyUsingCountryCountryCodeAlpha3KeyUpdate {
  countryCodeAlpha3: Scalars['String']['input'];
  patch: UpdateCountryOnRelationCurrencyCountryForRelationCurrencyCountryCountryCodeAlpha2FkeyPatch;
}

export interface CountryOnRelationCurrencyCountryForRelationCurrencyCountryCountryCodeAlpha2FkeyUsingCountryCountryCodeNumericKeyUpdate {
  countryCodeNumeric: Scalars['String']['input'];
  patch: UpdateCountryOnRelationCurrencyCountryForRelationCurrencyCountryCountryCodeAlpha2FkeyPatch;
}

export interface CountryOnRelationCurrencyCountryForRelationCurrencyCountryCountryCodeAlpha2FkeyUsingCountryDisplayOrderKeyUpdate {
  displayOrder: Scalars['Int']['input'];
  patch: UpdateCountryOnRelationCurrencyCountryForRelationCurrencyCountryCountryCodeAlpha2FkeyPatch;
}

export interface CountryOnRelationCurrencyCountryForRelationCurrencyCountryCountryCodeAlpha2FkeyUsingCountryPkeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  patch: UpdateCountryOnRelationCurrencyCountryForRelationCurrencyCountryCountryCodeAlpha2FkeyPatch;
}

export interface CountryPatch {
  banks?: InputMaybe<BankCountryCodeAlpha3FkeyInverseInput>;
  cities?: InputMaybe<CityCountryCodeAlpha2FkeyInverseInput>;
  companies?: InputMaybe<CompanyCountryCodeAlpha2FkeyInverseInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha3?: InputMaybe<Scalars['String']['input']>;
  countryCodeNumeric?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInverseInput>;
  employeeIdTypes?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInverseInput>;
  employeesToMobileNumberCountryIdUsingCountryCodeAlpha2?: InputMaybe<EmployeeMobileNumberCountryIdFkeyInverseInput>;
  employeesToNationalityUsingCountryCodeAlpha3?: InputMaybe<EmployeeNationalityFkeyInverseInput>;
  employeesToWorkingLocationCountryCodeAlpha2UsingCountryCodeAlpha2?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0InverseInput>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  telephoneCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CountryToManyBankFilter {
  every?: InputMaybe<BankFilter>;
  none?: InputMaybe<BankFilter>;
  some?: InputMaybe<BankFilter>;
}

export interface CountryToManyCityFilter {
  every?: InputMaybe<CityFilter>;
  none?: InputMaybe<CityFilter>;
  some?: InputMaybe<CityFilter>;
}

export interface CountryToManyCompanyFilter {
  aggregates?: InputMaybe<CompanyAggregatesFilter>;
  every?: InputMaybe<CompanyFilter>;
  none?: InputMaybe<CompanyFilter>;
  some?: InputMaybe<CompanyFilter>;
}

export interface CountryToManyEmployeeCurrentPositionFilter {
  every?: InputMaybe<EmployeeCurrentPositionFilter>;
  none?: InputMaybe<EmployeeCurrentPositionFilter>;
  some?: InputMaybe<EmployeeCurrentPositionFilter>;
}

export interface CountryToManyEmployeeEmergencyContactFilter {
  every?: InputMaybe<EmployeeEmergencyContactFilter>;
  none?: InputMaybe<EmployeeEmergencyContactFilter>;
  some?: InputMaybe<EmployeeEmergencyContactFilter>;
}

export interface CountryToManyEmployeeFilter {
  every?: InputMaybe<EmployeeFilter>;
  none?: InputMaybe<EmployeeFilter>;
  some?: InputMaybe<EmployeeFilter>;
}

export interface CountryToManyEmployeeIdTypeFilter {
  every?: InputMaybe<EmployeeIdTypeFilter>;
  none?: InputMaybe<EmployeeIdTypeFilter>;
  some?: InputMaybe<EmployeeIdTypeFilter>;
}

export interface CountryToManyProbationReviewEnableViewFilter {
  every?: InputMaybe<ProbationReviewEnableViewFilter>;
  none?: InputMaybe<ProbationReviewEnableViewFilter>;
  some?: InputMaybe<ProbationReviewEnableViewFilter>;
}

export interface CountryToManyRelationCurrencyCountryFilter {
  every?: InputMaybe<RelationCurrencyCountryFilter>;
  none?: InputMaybe<RelationCurrencyCountryFilter>;
  some?: InputMaybe<RelationCurrencyCountryFilter>;
}

export interface CountryUserCreatedFkeyInverseInput {
  connectByCountryCodeAlpha2?: InputMaybe<Array<CountryCountryPkeyConnect>>;
  connectByCountryCodeAlpha3?: InputMaybe<Array<CountryCountryCountryCodeAlpha3KeyConnect>>;
  connectByCountryCodeNumeric?: InputMaybe<Array<CountryCountryCountryCodeNumericKeyConnect>>;
  connectByDisplayOrder?: InputMaybe<Array<CountryCountryDisplayOrderKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<CountryNodeIdConnect>>;
  deleteByCountryCodeAlpha2?: InputMaybe<Array<CountryCountryPkeyDelete>>;
  deleteByCountryCodeAlpha3?: InputMaybe<Array<CountryCountryCountryCodeAlpha3KeyDelete>>;
  deleteByCountryCodeNumeric?: InputMaybe<Array<CountryCountryCountryCodeNumericKeyDelete>>;
  deleteByDisplayOrder?: InputMaybe<Array<CountryCountryDisplayOrderKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<CountryNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCountryCodeAlpha2?: InputMaybe<Array<CountryOnCountryForCountryUserCreatedFkeyUsingCountryPkeyUpdate>>;
  updateByCountryCodeAlpha3?: InputMaybe<Array<CountryOnCountryForCountryUserCreatedFkeyUsingCountryCountryCodeAlpha3KeyUpdate>>;
  updateByCountryCodeNumeric?: InputMaybe<Array<CountryOnCountryForCountryUserCreatedFkeyUsingCountryCountryCodeNumericKeyUpdate>>;
  updateByDisplayOrder?: InputMaybe<Array<CountryOnCountryForCountryUserCreatedFkeyUsingCountryDisplayOrderKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnCountryForCountryUserCreatedFkeyNodeIdUpdate>>;
}

export interface CountryUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnCountryForCountryUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<CountryOnCountryForCountryUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnCountryForCountryUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface CountryUserUpdatedFkeyInverseInput {
  connectByCountryCodeAlpha2?: InputMaybe<Array<CountryCountryPkeyConnect>>;
  connectByCountryCodeAlpha3?: InputMaybe<Array<CountryCountryCountryCodeAlpha3KeyConnect>>;
  connectByCountryCodeNumeric?: InputMaybe<Array<CountryCountryCountryCodeNumericKeyConnect>>;
  connectByDisplayOrder?: InputMaybe<Array<CountryCountryDisplayOrderKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<CountryNodeIdConnect>>;
  deleteByCountryCodeAlpha2?: InputMaybe<Array<CountryCountryPkeyDelete>>;
  deleteByCountryCodeAlpha3?: InputMaybe<Array<CountryCountryCountryCodeAlpha3KeyDelete>>;
  deleteByCountryCodeNumeric?: InputMaybe<Array<CountryCountryCountryCodeNumericKeyDelete>>;
  deleteByDisplayOrder?: InputMaybe<Array<CountryCountryDisplayOrderKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<CountryNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCountryCodeAlpha2?: InputMaybe<Array<CountryOnCountryForCountryUserUpdatedFkeyUsingCountryPkeyUpdate>>;
  updateByCountryCodeAlpha3?: InputMaybe<Array<CountryOnCountryForCountryUserUpdatedFkeyUsingCountryCountryCodeAlpha3KeyUpdate>>;
  updateByCountryCodeNumeric?: InputMaybe<Array<CountryOnCountryForCountryUserUpdatedFkeyUsingCountryCountryCodeNumericKeyUpdate>>;
  updateByDisplayOrder?: InputMaybe<Array<CountryOnCountryForCountryUserUpdatedFkeyUsingCountryDisplayOrderKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnCountryForCountryUserUpdatedFkeyNodeIdUpdate>>;
}

export interface CreateBankInput {
  bank: BankInput;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateBankPayload {
  __typename?: 'CreateBankPayload';
  bank?: Maybe<Bank>;
  bankEdge?: Maybe<BanksEdge>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  countryByCountryCodeAlpha3: Country;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateBankPayloadBankEdgeArgs = {
  orderBy?: InputMaybe<Array<BanksOrderBy>>;
};

export interface CreateBusinessUnitInput {
  businessUnit: BusinessUnitInput;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateBusinessUnitPayload {
  __typename?: 'CreateBusinessUnitPayload';
  businessUnit?: Maybe<BusinessUnit>;
  businessUnitEdge?: Maybe<BusinessUnitsEdge>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateBusinessUnitPayloadBusinessUnitEdgeArgs = {
  orderBy?: InputMaybe<Array<BusinessUnitsOrderBy>>;
};

export interface CreateCityInput {
  city: CityInput;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateCityPayload {
  __typename?: 'CreateCityPayload';
  city?: Maybe<City>;
  cityEdge?: Maybe<CitiesEdge>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  countryByCountryCodeAlpha2?: Maybe<Country>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateCityPayloadCityEdgeArgs = {
  orderBy?: InputMaybe<Array<CitiesOrderBy>>;
};

export interface CreateCompanyExternalAccountDisableInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyExternalAccountDisable: CompanyExternalAccountDisableInput;
}

export interface CreateCompanyExternalAccountDisablePayload {
  __typename?: 'CreateCompanyExternalAccountDisablePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  companyExternalAccountDisable?: Maybe<CompanyExternalAccountDisable>;
  companyExternalAccountDisableEdge?: Maybe<CompanyExternalAccountDisablesEdge>;
  externalAccountTypeByExternalAccountTypeKey: ExternalAccountType;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
}

export type CreateCompanyExternalAccountDisablePayloadCompanyExternalAccountDisableEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyExternalAccountDisablesOrderBy>>;
};

export interface CreateCompanyGlobalHrInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr: CompanyGlobalHrInput;
}

export interface CreateCompanyGlobalHrPayload {
  __typename?: 'CreateCompanyGlobalHrPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  companyGlobalHr?: Maybe<CompanyGlobalHr>;
  companyGlobalHrEdge?: Maybe<CompanyGlobalHrsEdge>;
  employee: Employee;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateCompanyGlobalHrPayloadCompanyGlobalHrEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyGlobalHrsOrderBy>>;
};

export interface CreateCompanyGroupInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyGroup: CompanyGroupInput;
}

export interface CreateCompanyGroupPayload {
  __typename?: 'CreateCompanyGroupPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  companyGroup?: Maybe<CompanyGroup>;
  companyGroupEdge?: Maybe<CompanyGroupsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateCompanyGroupPayloadCompanyGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyGroupsOrderBy>>;
};

export interface CreateCompanyInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  company: CompanyInput;
}

export interface CreateCompanyLocalHrInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyLocalHr: CompanyLocalHrInput;
}

export interface CreateCompanyLocalHrPayload {
  __typename?: 'CreateCompanyLocalHrPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  companyLocalHr?: Maybe<CompanyLocalHr>;
  companyLocalHrEdge?: Maybe<CompanyLocalHrsEdge>;
  employee: Employee;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateCompanyLocalHrPayloadCompanyLocalHrEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyLocalHrsOrderBy>>;
};

export interface CreateCompanyPayload {
  __typename?: 'CreateCompanyPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Company>;
  companyEdge?: Maybe<CompaniesEdge>;
  companyGroup?: Maybe<CompanyGroup>;
  countryByCountryCodeAlpha2?: Maybe<Country>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateCompanyPayloadCompanyEdgeArgs = {
  orderBy?: InputMaybe<Array<CompaniesOrderBy>>;
};

export interface CreateContractTypeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contractType: ContractTypeInput;
}

export interface CreateContractTypePayload {
  __typename?: 'CreateContractTypePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contractType?: Maybe<ContractType>;
  contractTypeEdge?: Maybe<ContractTypesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateContractTypePayloadContractTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<ContractTypesOrderBy>>;
};

export interface CreateCountryInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  country: CountryInput;
}

export interface CreateCountryPayload {
  __typename?: 'CreateCountryPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  countryEdge?: Maybe<CountriesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateCountryPayloadCountryEdgeArgs = {
  orderBy?: InputMaybe<Array<CountriesOrderBy>>;
};

export interface CreateCurrencyInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currency: CurrencyInput;
}

export interface CreateCurrencyPayload {
  __typename?: 'CreateCurrencyPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Currency>;
  currencyEdge?: Maybe<CurrenciesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateCurrencyPayloadCurrencyEdgeArgs = {
  orderBy?: InputMaybe<Array<CurrenciesOrderBy>>;
};

export interface CreateDepartmentInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  department: DepartmentInput;
}

export interface CreateDepartmentPayload {
  __typename?: 'CreateDepartmentPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  department?: Maybe<Department>;
  departmentEdge?: Maybe<DepartmentsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateDepartmentPayloadDepartmentEdgeArgs = {
  orderBy?: InputMaybe<Array<DepartmentsOrderBy>>;
};

export interface CreateDivisionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  division: DivisionInput;
}

export interface CreateDivisionPayload {
  __typename?: 'CreateDivisionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  division?: Maybe<Division>;
  divisionEdge?: Maybe<DivisionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateDivisionPayloadDivisionEdgeArgs = {
  orderBy?: InputMaybe<Array<DivisionsOrderBy>>;
};

export interface CreateEmailDomainInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  emailDomain: EmailDomainInput;
}

export interface CreateEmailDomainPayload {
  __typename?: 'CreateEmailDomainPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  emailDomain?: Maybe<EmailDomain>;
  emailDomainEdge?: Maybe<EmailDomainsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateEmailDomainPayloadEmailDomainEdgeArgs = {
  orderBy?: InputMaybe<Array<EmailDomainsOrderBy>>;
};

export interface CreateEmployeeEmergencyContactInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeEmergencyContact: EmployeeEmergencyContactInput;
}

export interface CreateEmployeeEmergencyContactPayload {
  __typename?: 'CreateEmployeeEmergencyContactPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employee: Employee;
  employeeEmergencyContact?: Maybe<EmployeeEmergencyContact>;
  employeeEmergencyContactEdge?: Maybe<EmployeeEmergencyContactsEdge>;
  query?: Maybe<Query>;
  relation?: Maybe<RelationEmergencyContact>;
  telephoneNumberCountry?: Maybe<Country>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateEmployeeEmergencyContactPayloadEmployeeEmergencyContactEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeEmergencyContactsOrderBy>>;
};

export interface CreateEmployeeFileInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeFile: EmployeeFileInput;
}

export interface CreateEmployeeFilePayload {
  __typename?: 'CreateEmployeeFilePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employee?: Maybe<Employee>;
  employeeFile?: Maybe<EmployeeFile>;
  employeeFileEdge?: Maybe<EmployeeFilesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userRoleByVisibleRole?: Maybe<UserRole>;
}

export type CreateEmployeeFilePayloadEmployeeFileEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeFilesOrderBy>>;
};

export interface CreateEmployeeGroupInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeGroup: EmployeeGroupInput;
}

export interface CreateEmployeeGroupPayload {
  __typename?: 'CreateEmployeeGroupPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employee: Employee;
  employeeGroup?: Maybe<EmployeeGroup>;
  employeeGroupEdge?: Maybe<EmployeeGroupsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateEmployeeGroupPayloadEmployeeGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeGroupsOrderBy>>;
};

export interface CreateEmployeeIdTypeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeIdType: EmployeeIdTypeInput;
}

export interface CreateEmployeeIdTypePayload {
  __typename?: 'CreateEmployeeIdTypePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  countryByCountryCodeAlpha2?: Maybe<Country>;
  employeeIdType?: Maybe<EmployeeIdType>;
  employeeIdTypeEdge?: Maybe<EmployeeIdTypesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateEmployeeIdTypePayloadEmployeeIdTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeIdTypesOrderBy>>;
};

export interface CreateEmployeeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employee: EmployeeInput;
}

export interface CreateEmployeeLevelInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeLevel: EmployeeLevelInput;
}

export interface CreateEmployeeLevelPayload {
  __typename?: 'CreateEmployeeLevelPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employeeLevel?: Maybe<EmployeeLevel>;
  employeeLevelEdge?: Maybe<EmployeeLevelsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateEmployeeLevelPayloadEmployeeLevelEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeLevelsOrderBy>>;
};

export interface CreateEmployeeListCustomFilterInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeListCustomFilter: EmployeeListCustomFilterInput;
}

export interface CreateEmployeeListCustomFilterPayload {
  __typename?: 'CreateEmployeeListCustomFilterPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employeeListCustomFilter?: Maybe<EmployeeListCustomFilter>;
  employeeListCustomFilterEdge?: Maybe<EmployeeListCustomFiltersEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateEmployeeListCustomFilterPayloadEmployeeListCustomFilterEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeListCustomFiltersOrderBy>>;
};

export interface CreateEmployeeListCustomFilterRoleInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeListCustomFilterRole: EmployeeListCustomFilterRoleInput;
}

export interface CreateEmployeeListCustomFilterRolePayload {
  __typename?: 'CreateEmployeeListCustomFilterRolePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employeeListCustomFilter: EmployeeListCustomFilter;
  employeeListCustomFilterRole?: Maybe<EmployeeListCustomFilterRole>;
  employeeListCustomFilterRoleEdge?: Maybe<EmployeeListCustomFilterRolesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userRoleByRoleName: UserRole;
}

export type CreateEmployeeListCustomFilterRolePayloadEmployeeListCustomFilterRoleEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeListCustomFilterRolesOrderBy>>;
};

export interface CreateEmployeePayload {
  __typename?: 'CreateEmployeePayload';
  approver?: Maybe<Employee>;
  bank?: Maybe<Bank>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  consultingFeeBank?: Maybe<Bank>;
  consultingFeeCurrency?: Maybe<Currency>;
  countryByNationality?: Maybe<Country>;
  countryByWorkingLocationCountryCodeAlpha2: Country;
  dottedLineManager?: Maybe<Employee>;
  employee?: Maybe<Employee>;
  employeeEdge?: Maybe<EmployeesEdge>;
  employeeIdTypeByIdType?: Maybe<EmployeeIdType>;
  employeeStatusByStatus: EmployeeStatus;
  mobileNumberCountry?: Maybe<Country>;
  primaryManager?: Maybe<Employee>;
  query?: Maybe<Query>;
  resignationReason?: Maybe<EmployeeResignationReason>;
  resignationType?: Maybe<EmployeeResignationType>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateEmployeePayloadEmployeeEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeesOrderBy>>;
};

export interface CreateEmployeeResignationReasonInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeResignationReason: EmployeeResignationReasonInput;
}

export interface CreateEmployeeResignationReasonPayload {
  __typename?: 'CreateEmployeeResignationReasonPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employeeResignationReason?: Maybe<EmployeeResignationReason>;
  employeeResignationReasonEdge?: Maybe<EmployeeResignationReasonsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateEmployeeResignationReasonPayloadEmployeeResignationReasonEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeResignationReasonsOrderBy>>;
};

export interface CreateEmployeeResignationTypeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeResignationType: EmployeeResignationTypeInput;
}

export interface CreateEmployeeResignationTypePayload {
  __typename?: 'CreateEmployeeResignationTypePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employeeResignationType?: Maybe<EmployeeResignationType>;
  employeeResignationTypeEdge?: Maybe<EmployeeResignationTypesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateEmployeeResignationTypePayloadEmployeeResignationTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeResignationTypesOrderBy>>;
};

export interface CreateEmployeeTpHistoryInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeTpHistory: EmployeeTpHistoryInput;
}

export interface CreateEmployeeTpHistoryPayload {
  __typename?: 'CreateEmployeeTpHistoryPayload';
  businessUnit?: Maybe<BusinessUnit>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  contractType: ContractType;
  department?: Maybe<Department>;
  division?: Maybe<Division>;
  employee: Employee;
  employeeLevel: EmployeeLevel;
  employeeTpHistory?: Maybe<EmployeeTpHistory>;
  employeeTpHistoryEdge?: Maybe<EmployeeTpHistoriesEdge>;
  office?: Maybe<Office>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateEmployeeTpHistoryPayloadEmployeeTpHistoryEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeTpHistoriesOrderBy>>;
};

export interface CreateExportEmployeeStatusInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  exportEmployeeStatus: ExportEmployeeStatusInput;
}

export interface CreateExportEmployeeStatusPayload {
  __typename?: 'CreateExportEmployeeStatusPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exportEmployeeStatus?: Maybe<ExportEmployeeStatus>;
  exportEmployeeStatusEdge?: Maybe<ExportEmployeeStatusesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateExportEmployeeStatusPayloadExportEmployeeStatusEdgeArgs = {
  orderBy?: InputMaybe<Array<ExportEmployeeStatusesOrderBy>>;
};

export interface CreateExternalAccountEmployeeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  externalAccountEmployee: ExternalAccountEmployeeInput;
}

export interface CreateExternalAccountEmployeePayload {
  __typename?: 'CreateExternalAccountEmployeePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employee: Employee;
  externalAccountEmployee?: Maybe<ExternalAccountEmployee>;
  externalAccountEmployeeEdge?: Maybe<ExternalAccountEmployeesEdge>;
  externalAccountTypeByExternalAccountTypeKey: ExternalAccountType;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateExternalAccountEmployeePayloadExternalAccountEmployeeEdgeArgs = {
  orderBy?: InputMaybe<Array<ExternalAccountEmployeesOrderBy>>;
};

export interface CreateExternalAccountTypeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  externalAccountType: ExternalAccountTypeInput;
}

export interface CreateExternalAccountTypePayload {
  __typename?: 'CreateExternalAccountTypePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  externalAccountType?: Maybe<ExternalAccountType>;
  externalAccountTypeEdge?: Maybe<ExternalAccountTypesEdge>;
  query?: Maybe<Query>;
}

export type CreateExternalAccountTypePayloadExternalAccountTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<ExternalAccountTypesOrderBy>>;
};

export interface CreateGoogleWorkspaceOrgUnitInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  googleWorkspaceOrgUnit: GoogleWorkspaceOrgUnitInput;
}

export interface CreateGoogleWorkspaceOrgUnitPayload {
  __typename?: 'CreateGoogleWorkspaceOrgUnitPayload';
  businessUnit?: Maybe<BusinessUnit>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Company>;
  googleWorkspaceOrgUnit?: Maybe<GoogleWorkspaceOrgUnit>;
  googleWorkspaceOrgUnitEdge?: Maybe<GoogleWorkspaceOrgUnitsEdge>;
  query?: Maybe<Query>;
}

export type CreateGoogleWorkspaceOrgUnitPayloadGoogleWorkspaceOrgUnitEdgeArgs = {
  orderBy?: InputMaybe<Array<GoogleWorkspaceOrgUnitsOrderBy>>;
};

export interface CreateImportEmployeeStatusInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatus: ImportEmployeeStatusInput;
}

export interface CreateImportEmployeeStatusPayload {
  __typename?: 'CreateImportEmployeeStatusPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  importEmployeeStatus?: Maybe<ImportEmployeeStatus>;
  importEmployeeStatusEdge?: Maybe<ImportEmployeeStatusesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateImportEmployeeStatusPayloadImportEmployeeStatusEdgeArgs = {
  orderBy?: InputMaybe<Array<ImportEmployeeStatusesOrderBy>>;
};

export interface CreateOfficeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  office: OfficeInput;
}

export interface CreateOfficePayload {
  __typename?: 'CreateOfficePayload';
  city: City;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  office?: Maybe<Office>;
  officeEdge?: Maybe<OfficesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateOfficePayloadOfficeEdgeArgs = {
  orderBy?: InputMaybe<Array<OfficesOrderBy>>;
};

export interface CreatePerformanceReviewEvaluationCompetencyInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetency: PerformanceReviewEvaluationCompetencyInput;
}

export interface CreatePerformanceReviewEvaluationCompetencyPayload {
  __typename?: 'CreatePerformanceReviewEvaluationCompetencyPayload';
  assessmentEmployee: Employee;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationCompetency?: Maybe<PerformanceReviewEvaluationCompetency>;
  performanceReviewEvaluationCompetencyEdge?: Maybe<PerformanceReviewEvaluationCompetenciesEdge>;
  performanceReviewMasterCompetency: PerformanceReviewMasterCompetency;
  performanceReviewMasterRatingByAssessmentValue?: Maybe<PerformanceReviewMasterRating>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewEvaluationCompetencyPayloadPerformanceReviewEvaluationCompetencyEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationCompetenciesOrderBy>>;
};

export interface CreatePerformanceReviewEvaluationEvaluatorInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationEvaluator: PerformanceReviewEvaluationEvaluatorInput;
}

export interface CreatePerformanceReviewEvaluationEvaluatorPayload {
  __typename?: 'CreatePerformanceReviewEvaluationEvaluatorPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  evaluator: Employee;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationEvaluator?: Maybe<PerformanceReviewEvaluationEvaluator>;
  performanceReviewEvaluationEvaluatorEdge?: Maybe<PerformanceReviewEvaluationEvaluatorsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewEvaluationEvaluatorPayloadPerformanceReviewEvaluationEvaluatorEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorsOrderBy>>;
};

export interface CreatePerformanceReviewEvaluationFeedbackInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationFeedback: PerformanceReviewEvaluationFeedbackInput;
}

export interface CreatePerformanceReviewEvaluationFeedbackPayload {
  __typename?: 'CreatePerformanceReviewEvaluationFeedbackPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  feedbackEmployee?: Maybe<Employee>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationFeedback?: Maybe<PerformanceReviewEvaluationFeedback>;
  performanceReviewEvaluationFeedbackEdge?: Maybe<PerformanceReviewEvaluationFeedbacksEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewEvaluationFeedbackPayloadPerformanceReviewEvaluationFeedbackEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationFeedbacksOrderBy>>;
};

export interface CreatePerformanceReviewEvaluationInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation: PerformanceReviewEvaluationInput;
}

export interface CreatePerformanceReviewEvaluationKpiAssessmentInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationKpiAssessment: PerformanceReviewEvaluationKpiAssessmentInput;
}

export interface CreatePerformanceReviewEvaluationKpiAssessmentPayload {
  __typename?: 'CreatePerformanceReviewEvaluationKpiAssessmentPayload';
  assessmentEmployee: Employee;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationKpiAssessment?: Maybe<PerformanceReviewEvaluationKpiAssessment>;
  performanceReviewEvaluationKpiAssessmentEdge?: Maybe<PerformanceReviewEvaluationKpiAssessmentsEdge>;
  performanceReviewEvaluationKpiDescription: PerformanceReviewEvaluationKpiDescription;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewEvaluationKpiAssessmentPayloadPerformanceReviewEvaluationKpiAssessmentEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentsOrderBy>>;
};

export interface CreatePerformanceReviewEvaluationKpiDescriptionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationKpiDescription: PerformanceReviewEvaluationKpiDescriptionInput;
}

export interface CreatePerformanceReviewEvaluationKpiDescriptionPayload {
  __typename?: 'CreatePerformanceReviewEvaluationKpiDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationKpiDescription?: Maybe<PerformanceReviewEvaluationKpiDescription>;
  performanceReviewEvaluationKpiDescriptionEdge?: Maybe<PerformanceReviewEvaluationKpiDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewEvaluationKpiDescriptionPayloadPerformanceReviewEvaluationKpiDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionsOrderBy>>;
};

export interface CreatePerformanceReviewEvaluationKpiResultInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationKpiResult: PerformanceReviewEvaluationKpiResultInput;
}

export interface CreatePerformanceReviewEvaluationKpiResultPayload {
  __typename?: 'CreatePerformanceReviewEvaluationKpiResultPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationKpiResult?: Maybe<PerformanceReviewEvaluationKpiResult>;
  performanceReviewEvaluationKpiResultEdge?: Maybe<PerformanceReviewEvaluationKpiResultsEdge>;
  performanceReviewEvaluationKpiTarget: PerformanceReviewEvaluationKpiTarget;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewEvaluationKpiResultPayloadPerformanceReviewEvaluationKpiResultEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultsOrderBy>>;
};

export interface CreatePerformanceReviewEvaluationKpiTargetInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationKpiTarget: PerformanceReviewEvaluationKpiTargetInput;
}

export interface CreatePerformanceReviewEvaluationKpiTargetPayload {
  __typename?: 'CreatePerformanceReviewEvaluationKpiTargetPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationKpiDescription: PerformanceReviewEvaluationKpiDescription;
  performanceReviewEvaluationKpiTarget?: Maybe<PerformanceReviewEvaluationKpiTarget>;
  performanceReviewEvaluationKpiTargetEdge?: Maybe<PerformanceReviewEvaluationKpiTargetsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewEvaluationKpiTargetPayloadPerformanceReviewEvaluationKpiTargetEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetsOrderBy>>;
};

export interface CreatePerformanceReviewEvaluationOneOnOneInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationOneOnOne: PerformanceReviewEvaluationOneOnOneInput;
}

export interface CreatePerformanceReviewEvaluationOneOnOnePayload {
  __typename?: 'CreatePerformanceReviewEvaluationOneOnOnePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationOneOnOne?: Maybe<PerformanceReviewEvaluationOneOnOne>;
  performanceReviewEvaluationOneOnOneEdge?: Maybe<PerformanceReviewEvaluationOneOnOnesEdge>;
  performanceReviewMasterOneOnOne: PerformanceReviewMasterOneOnOne;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewEvaluationOneOnOnePayloadPerformanceReviewEvaluationOneOnOneEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOnesOrderBy>>;
};

export interface CreatePerformanceReviewEvaluationPayload {
  __typename?: 'CreatePerformanceReviewEvaluationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employee: Employee;
  employeeTpHistory?: Maybe<EmployeeTpHistory>;
  performanceReviewEvaluation?: Maybe<PerformanceReviewEvaluation>;
  performanceReviewEvaluationEdge?: Maybe<PerformanceReviewEvaluationsEdge>;
  performanceReviewMasterCompetencyRole?: Maybe<PerformanceReviewMasterCompetencyRole>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewEvaluationPayloadPerformanceReviewEvaluationEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationsOrderBy>>;
};

export interface CreatePerformanceReviewEvaluationQualitativeAssessmentInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationQualitativeAssessment: PerformanceReviewEvaluationQualitativeAssessmentInput;
}

export interface CreatePerformanceReviewEvaluationQualitativeAssessmentPayload {
  __typename?: 'CreatePerformanceReviewEvaluationQualitativeAssessmentPayload';
  assessmentEmployee: Employee;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationQualitativeAssessment?: Maybe<PerformanceReviewEvaluationQualitativeAssessment>;
  performanceReviewEvaluationQualitativeAssessmentEdge?: Maybe<PerformanceReviewEvaluationQualitativeAssessmentsEdge>;
  performanceReviewEvaluationQualitativeDescription: PerformanceReviewEvaluationQualitativeDescription;
  performanceReviewMasterRatingByAssessmentValue?: Maybe<PerformanceReviewMasterRating>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewEvaluationQualitativeAssessmentPayloadPerformanceReviewEvaluationQualitativeAssessmentEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentsOrderBy>>;
};

export interface CreatePerformanceReviewEvaluationQualitativeDescriptionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationQualitativeDescription: PerformanceReviewEvaluationQualitativeDescriptionInput;
}

export interface CreatePerformanceReviewEvaluationQualitativeDescriptionPayload {
  __typename?: 'CreatePerformanceReviewEvaluationQualitativeDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationQualitativeDescription?: Maybe<PerformanceReviewEvaluationQualitativeDescription>;
  performanceReviewEvaluationQualitativeDescriptionEdge?: Maybe<PerformanceReviewEvaluationQualitativeDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewEvaluationQualitativeDescriptionPayloadPerformanceReviewEvaluationQualitativeDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionsOrderBy>>;
};

export interface CreatePerformanceReviewEvaluationValueInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationValue: PerformanceReviewEvaluationValueInput;
}

export interface CreatePerformanceReviewEvaluationValuePayload {
  __typename?: 'CreatePerformanceReviewEvaluationValuePayload';
  assessmentEmployee: Employee;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationValue?: Maybe<PerformanceReviewEvaluationValue>;
  performanceReviewEvaluationValueEdge?: Maybe<PerformanceReviewEvaluationValuesEdge>;
  performanceReviewMasterRatingByAssessmentValue?: Maybe<PerformanceReviewMasterRating>;
  performanceReviewMasterValue: PerformanceReviewMasterValue;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewEvaluationValuePayloadPerformanceReviewEvaluationValueEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationValuesOrderBy>>;
};

export interface CreatePerformanceReviewMasterCompetencyCommonDescriptionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyCommonDescription: PerformanceReviewMasterCompetencyCommonDescriptionInput;
}

export interface CreatePerformanceReviewMasterCompetencyCommonDescriptionPayload {
  __typename?: 'CreatePerformanceReviewMasterCompetencyCommonDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterCompetencyCommon: PerformanceReviewMasterCompetencyCommon;
  performanceReviewMasterCompetencyCommonDescription?: Maybe<PerformanceReviewMasterCompetencyCommonDescription>;
  performanceReviewMasterCompetencyCommonDescriptionEdge?: Maybe<PerformanceReviewMasterCompetencyCommonDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewMasterCompetencyCommonDescriptionPayloadPerformanceReviewMasterCompetencyCommonDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionsOrderBy>>;
};

export interface CreatePerformanceReviewMasterCompetencyCommonInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyCommon: PerformanceReviewMasterCompetencyCommonInput;
}

export interface CreatePerformanceReviewMasterCompetencyCommonPayload {
  __typename?: 'CreatePerformanceReviewMasterCompetencyCommonPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterCompetencyCommon?: Maybe<PerformanceReviewMasterCompetencyCommon>;
  performanceReviewMasterCompetencyCommonEdge?: Maybe<PerformanceReviewMasterCompetencyCommonsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewMasterCompetencyCommonPayloadPerformanceReviewMasterCompetencyCommonEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonsOrderBy>>;
};

export interface CreatePerformanceReviewMasterCompetencyDetailDescriptionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyDetailDescription: PerformanceReviewMasterCompetencyDetailDescriptionInput;
}

export interface CreatePerformanceReviewMasterCompetencyDetailDescriptionPayload {
  __typename?: 'CreatePerformanceReviewMasterCompetencyDetailDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterCompetencyDetail: PerformanceReviewMasterCompetencyDetail;
  performanceReviewMasterCompetencyDetailDescription?: Maybe<PerformanceReviewMasterCompetencyDetailDescription>;
  performanceReviewMasterCompetencyDetailDescriptionEdge?: Maybe<PerformanceReviewMasterCompetencyDetailDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewMasterCompetencyDetailDescriptionPayloadPerformanceReviewMasterCompetencyDetailDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionsOrderBy>>;
};

export interface CreatePerformanceReviewMasterCompetencyDetailInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyDetail: PerformanceReviewMasterCompetencyDetailInput;
}

export interface CreatePerformanceReviewMasterCompetencyDetailPayload {
  __typename?: 'CreatePerformanceReviewMasterCompetencyDetailPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  overridingPerformanceReviewMasterCompetencyCommon?: Maybe<PerformanceReviewMasterCompetencyCommon>;
  performanceReviewMasterCompetencyDetail?: Maybe<PerformanceReviewMasterCompetencyDetail>;
  performanceReviewMasterCompetencyDetailEdge?: Maybe<PerformanceReviewMasterCompetencyDetailsEdge>;
  performanceReviewMasterCompetencyRole: PerformanceReviewMasterCompetencyRole;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewMasterCompetencyDetailPayloadPerformanceReviewMasterCompetencyDetailEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailsOrderBy>>;
};

export interface CreatePerformanceReviewMasterCompetencyInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetency: PerformanceReviewMasterCompetencyInput;
}

export interface CreatePerformanceReviewMasterCompetencyPayload {
  __typename?: 'CreatePerformanceReviewMasterCompetencyPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterCompetency?: Maybe<PerformanceReviewMasterCompetency>;
  performanceReviewMasterCompetencyCommon?: Maybe<PerformanceReviewMasterCompetencyCommon>;
  performanceReviewMasterCompetencyDetail?: Maybe<PerformanceReviewMasterCompetencyDetail>;
  performanceReviewMasterCompetencyEdge?: Maybe<PerformanceReviewMasterCompetenciesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewMasterCompetencyPayloadPerformanceReviewMasterCompetencyEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetenciesOrderBy>>;
};

export interface CreatePerformanceReviewMasterCompetencyRoleInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRole: PerformanceReviewMasterCompetencyRoleInput;
}

export interface CreatePerformanceReviewMasterCompetencyRolePayload {
  __typename?: 'CreatePerformanceReviewMasterCompetencyRolePayload';
  businessUnit: BusinessUnit;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  department: Department;
  division: Division;
  performanceReviewMasterCompetencyRole?: Maybe<PerformanceReviewMasterCompetencyRole>;
  performanceReviewMasterCompetencyRoleEdge?: Maybe<PerformanceReviewMasterCompetencyRolesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewMasterCompetencyRolePayloadPerformanceReviewMasterCompetencyRoleEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyRolesOrderBy>>;
};

export interface CreatePerformanceReviewMasterDefaultInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterDefault: PerformanceReviewMasterDefaultInput;
}

export interface CreatePerformanceReviewMasterDefaultPayload {
  __typename?: 'CreatePerformanceReviewMasterDefaultPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterDefault?: Maybe<PerformanceReviewMasterDefault>;
  performanceReviewMasterDefaultEdge?: Maybe<PerformanceReviewMasterDefaultsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewMasterDefaultPayloadPerformanceReviewMasterDefaultEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterDefaultsOrderBy>>;
};

export interface CreatePerformanceReviewMasterEvaluationViewerInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterEvaluationViewer: PerformanceReviewMasterEvaluationViewerInput;
}

export interface CreatePerformanceReviewMasterEvaluationViewerPayload {
  __typename?: 'CreatePerformanceReviewMasterEvaluationViewerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employee: Employee;
  performanceReviewMasterEvaluationViewer?: Maybe<PerformanceReviewMasterEvaluationViewer>;
  performanceReviewMasterEvaluationViewerEdge?: Maybe<PerformanceReviewMasterEvaluationViewersEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  viewerEmployee: Employee;
}

export type CreatePerformanceReviewMasterEvaluationViewerPayloadPerformanceReviewMasterEvaluationViewerEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewersOrderBy>>;
};

export interface CreatePerformanceReviewMasterExcludedEmployeeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterExcludedEmployee: PerformanceReviewMasterExcludedEmployeeInput;
}

export interface CreatePerformanceReviewMasterExcludedEmployeePayload {
  __typename?: 'CreatePerformanceReviewMasterExcludedEmployeePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employee: Employee;
  performanceReviewMasterExcludedEmployee?: Maybe<PerformanceReviewMasterExcludedEmployee>;
  performanceReviewMasterExcludedEmployeeEdge?: Maybe<PerformanceReviewMasterExcludedEmployeesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewMasterExcludedEmployeePayloadPerformanceReviewMasterExcludedEmployeeEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterExcludedEmployeesOrderBy>>;
};

export interface CreatePerformanceReviewMasterOneOnOneDetailInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterOneOnOneDetail: PerformanceReviewMasterOneOnOneDetailInput;
}

export interface CreatePerformanceReviewMasterOneOnOneDetailPayload {
  __typename?: 'CreatePerformanceReviewMasterOneOnOneDetailPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterOneOnOne: PerformanceReviewMasterOneOnOne;
  performanceReviewMasterOneOnOneDetail?: Maybe<PerformanceReviewMasterOneOnOneDetail>;
  performanceReviewMasterOneOnOneDetailEdge?: Maybe<PerformanceReviewMasterOneOnOneDetailsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewMasterOneOnOneDetailPayloadPerformanceReviewMasterOneOnOneDetailEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterOneOnOneDetailsOrderBy>>;
};

export interface CreatePerformanceReviewMasterOneOnOneInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterOneOnOne: PerformanceReviewMasterOneOnOneInput;
}

export interface CreatePerformanceReviewMasterOneOnOnePayload {
  __typename?: 'CreatePerformanceReviewMasterOneOnOnePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterOneOnOne?: Maybe<PerformanceReviewMasterOneOnOne>;
  performanceReviewMasterOneOnOneEdge?: Maybe<PerformanceReviewMasterOneOnOnesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewMasterOneOnOnePayloadPerformanceReviewMasterOneOnOneEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterOneOnOnesOrderBy>>;
};

export interface CreatePerformanceReviewMasterRatingDescriptionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterRatingDescription: PerformanceReviewMasterRatingDescriptionInput;
}

export interface CreatePerformanceReviewMasterRatingDescriptionPayload {
  __typename?: 'CreatePerformanceReviewMasterRatingDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterRating: PerformanceReviewMasterRating;
  performanceReviewMasterRatingDescription?: Maybe<PerformanceReviewMasterRatingDescription>;
  performanceReviewMasterRatingDescriptionEdge?: Maybe<PerformanceReviewMasterRatingDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewMasterRatingDescriptionPayloadPerformanceReviewMasterRatingDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionsOrderBy>>;
};

export interface CreatePerformanceReviewMasterRatingInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterRating: PerformanceReviewMasterRatingInput;
}

export interface CreatePerformanceReviewMasterRatingPayload {
  __typename?: 'CreatePerformanceReviewMasterRatingPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterRating?: Maybe<PerformanceReviewMasterRating>;
  performanceReviewMasterRatingEdge?: Maybe<PerformanceReviewMasterRatingsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewMasterRatingPayloadPerformanceReviewMasterRatingEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterRatingsOrderBy>>;
};

export interface CreatePerformanceReviewMasterUsageInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterUsage: PerformanceReviewMasterUsageInput;
}

export interface CreatePerformanceReviewMasterUsagePayload {
  __typename?: 'CreatePerformanceReviewMasterUsagePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  performanceReviewMasterUsage?: Maybe<PerformanceReviewMasterUsage>;
  performanceReviewMasterUsageEdge?: Maybe<PerformanceReviewMasterUsagesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewMasterUsagePayloadPerformanceReviewMasterUsageEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterUsagesOrderBy>>;
};

export interface CreatePerformanceReviewMasterValueDescriptionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterValueDescription: PerformanceReviewMasterValueDescriptionInput;
}

export interface CreatePerformanceReviewMasterValueDescriptionPayload {
  __typename?: 'CreatePerformanceReviewMasterValueDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterValue: PerformanceReviewMasterValue;
  performanceReviewMasterValueDescription?: Maybe<PerformanceReviewMasterValueDescription>;
  performanceReviewMasterValueDescriptionEdge?: Maybe<PerformanceReviewMasterValueDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewMasterValueDescriptionPayloadPerformanceReviewMasterValueDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionsOrderBy>>;
};

export interface CreatePerformanceReviewMasterValueInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterValue: PerformanceReviewMasterValueInput;
}

export interface CreatePerformanceReviewMasterValuePayload {
  __typename?: 'CreatePerformanceReviewMasterValuePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterValue?: Maybe<PerformanceReviewMasterValue>;
  performanceReviewMasterValueEdge?: Maybe<PerformanceReviewMasterValuesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewMasterValuePayloadPerformanceReviewMasterValueEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterValuesOrderBy>>;
};

export interface CreatePerformanceReviewMasterWeightingDetailInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterWeightingDetail: PerformanceReviewMasterWeightingDetailInput;
}

export interface CreatePerformanceReviewMasterWeightingDetailPayload {
  __typename?: 'CreatePerformanceReviewMasterWeightingDetailPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employeeLevel: EmployeeLevel;
  performanceReviewMasterWeighting: PerformanceReviewMasterWeighting;
  performanceReviewMasterWeightingDetail?: Maybe<PerformanceReviewMasterWeightingDetail>;
  performanceReviewMasterWeightingDetailEdge?: Maybe<PerformanceReviewMasterWeightingDetailsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewMasterWeightingDetailPayloadPerformanceReviewMasterWeightingDetailEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailsOrderBy>>;
};

export interface CreatePerformanceReviewMasterWeightingInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterWeighting: PerformanceReviewMasterWeightingInput;
}

export interface CreatePerformanceReviewMasterWeightingPayload {
  __typename?: 'CreatePerformanceReviewMasterWeightingPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Company>;
  performanceReviewMasterWeighting?: Maybe<PerformanceReviewMasterWeighting>;
  performanceReviewMasterWeightingEdge?: Maybe<PerformanceReviewMasterWeightingsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreatePerformanceReviewMasterWeightingPayloadPerformanceReviewMasterWeightingEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterWeightingsOrderBy>>;
};

export interface CreateProbationReviewEnableInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  probationReviewEnable: ProbationReviewEnableInput;
}

export interface CreateProbationReviewEnablePayload {
  __typename?: 'CreateProbationReviewEnablePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  contractType: ContractType;
  probationReviewEnable?: Maybe<ProbationReviewEnable>;
  probationReviewEnableEdge?: Maybe<ProbationReviewEnablesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateProbationReviewEnablePayloadProbationReviewEnableEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewEnablesOrderBy>>;
};

export interface CreateProbationReviewEvaluationInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  probationReviewEvaluation: ProbationReviewEvaluationInput;
}

export interface CreateProbationReviewEvaluationPayload {
  __typename?: 'CreateProbationReviewEvaluationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employee: Employee;
  probationReviewEvaluation?: Maybe<ProbationReviewEvaluation>;
  probationReviewEvaluationEdge?: Maybe<ProbationReviewEvaluationsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateProbationReviewEvaluationPayloadProbationReviewEvaluationEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationsOrderBy>>;
};

export interface CreateProbationReviewEvaluationResultItemInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  probationReviewEvaluationResultItem: ProbationReviewEvaluationResultItemInput;
}

export interface CreateProbationReviewEvaluationResultItemPayload {
  __typename?: 'CreateProbationReviewEvaluationResultItemPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  probationReviewEvaluation: ProbationReviewEvaluation;
  probationReviewEvaluationResultItem?: Maybe<ProbationReviewEvaluationResultItem>;
  probationReviewEvaluationResultItemEdge?: Maybe<ProbationReviewEvaluationResultItemsEdge>;
  probationReviewMasterItem: ProbationReviewMasterItem;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateProbationReviewEvaluationResultItemPayloadProbationReviewEvaluationResultItemEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultItemsOrderBy>>;
};

export interface CreateProbationReviewEvaluationResultScoreCompetencyInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  probationReviewEvaluationResultScoreCompetency: ProbationReviewEvaluationResultScoreCompetencyInput;
}

export interface CreateProbationReviewEvaluationResultScoreCompetencyPayload {
  __typename?: 'CreateProbationReviewEvaluationResultScoreCompetencyPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterCompetency: PerformanceReviewMasterCompetencyCommon;
  performanceReviewMasterRatingByAssessment?: Maybe<PerformanceReviewMasterRating>;
  probationReviewEvaluation: ProbationReviewEvaluation;
  probationReviewEvaluationResultScoreCompetency?: Maybe<ProbationReviewEvaluationResultScoreCompetency>;
  probationReviewEvaluationResultScoreCompetencyEdge?: Maybe<ProbationReviewEvaluationResultScoreCompetenciesEdge>;
  probationReviewMasterItem: ProbationReviewMasterItem;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateProbationReviewEvaluationResultScoreCompetencyPayloadProbationReviewEvaluationResultScoreCompetencyEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetenciesOrderBy>>;
};

export interface CreateProbationReviewEvaluationResultScoreValueInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  probationReviewEvaluationResultScoreValue: ProbationReviewEvaluationResultScoreValueInput;
}

export interface CreateProbationReviewEvaluationResultScoreValuePayload {
  __typename?: 'CreateProbationReviewEvaluationResultScoreValuePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterRatingByAssessment?: Maybe<PerformanceReviewMasterRating>;
  performanceReviewMasterValue: PerformanceReviewMasterValue;
  probationReviewEvaluation: ProbationReviewEvaluation;
  probationReviewEvaluationResultScoreValue?: Maybe<ProbationReviewEvaluationResultScoreValue>;
  probationReviewEvaluationResultScoreValueEdge?: Maybe<ProbationReviewEvaluationResultScoreValuesEdge>;
  probationReviewMasterItem: ProbationReviewMasterItem;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateProbationReviewEvaluationResultScoreValuePayloadProbationReviewEvaluationResultScoreValueEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValuesOrderBy>>;
};

export interface CreateProbationReviewEvaluationReviewerInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  probationReviewEvaluationReviewer: ProbationReviewEvaluationReviewerInput;
}

export interface CreateProbationReviewEvaluationReviewerPayload {
  __typename?: 'CreateProbationReviewEvaluationReviewerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  probationReviewEvaluation: ProbationReviewEvaluation;
  probationReviewEvaluationReviewer?: Maybe<ProbationReviewEvaluationReviewer>;
  probationReviewEvaluationReviewerEdge?: Maybe<ProbationReviewEvaluationReviewersEdge>;
  query?: Maybe<Query>;
  reviewerEmployee: Employee;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateProbationReviewEvaluationReviewerPayloadProbationReviewEvaluationReviewerEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationReviewersOrderBy>>;
};

export interface CreateProbationReviewMasterItemDescriptionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  probationReviewMasterItemDescription: ProbationReviewMasterItemDescriptionInput;
}

export interface CreateProbationReviewMasterItemDescriptionPayload {
  __typename?: 'CreateProbationReviewMasterItemDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  probationReviewMasterItem: ProbationReviewMasterItem;
  probationReviewMasterItemDescription?: Maybe<ProbationReviewMasterItemDescription>;
  probationReviewMasterItemDescriptionEdge?: Maybe<ProbationReviewMasterItemDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateProbationReviewMasterItemDescriptionPayloadProbationReviewMasterItemDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewMasterItemDescriptionsOrderBy>>;
};

export interface CreateProbationReviewMasterItemInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  probationReviewMasterItem: ProbationReviewMasterItemInput;
}

export interface CreateProbationReviewMasterItemPayload {
  __typename?: 'CreateProbationReviewMasterItemPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  probationReviewMasterItem?: Maybe<ProbationReviewMasterItem>;
  probationReviewMasterItemEdge?: Maybe<ProbationReviewMasterItemsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateProbationReviewMasterItemPayloadProbationReviewMasterItemEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewMasterItemsOrderBy>>;
};

export interface CreateRelationBusinessUnitDivisionDepartmentInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  relationBusinessUnitDivisionDepartment: RelationBusinessUnitDivisionDepartmentInput;
}

export interface CreateRelationBusinessUnitDivisionDepartmentPayload {
  __typename?: 'CreateRelationBusinessUnitDivisionDepartmentPayload';
  businessUnit: BusinessUnit;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  department: Department;
  division: Division;
  query?: Maybe<Query>;
  relationBusinessUnitDivisionDepartment?: Maybe<RelationBusinessUnitDivisionDepartment>;
  relationBusinessUnitDivisionDepartmentEdge?: Maybe<RelationBusinessUnitDivisionDepartmentsEdge>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateRelationBusinessUnitDivisionDepartmentPayloadRelationBusinessUnitDivisionDepartmentEdgeArgs = {
  orderBy?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentsOrderBy>>;
};

export interface CreateRelationBusinessUnitDivisionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  relationBusinessUnitDivision: RelationBusinessUnitDivisionInput;
}

export interface CreateRelationBusinessUnitDivisionPayload {
  __typename?: 'CreateRelationBusinessUnitDivisionPayload';
  businessUnit: BusinessUnit;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  division: Division;
  query?: Maybe<Query>;
  relationBusinessUnitDivision?: Maybe<RelationBusinessUnitDivision>;
  relationBusinessUnitDivisionEdge?: Maybe<RelationBusinessUnitDivisionsEdge>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateRelationBusinessUnitDivisionPayloadRelationBusinessUnitDivisionEdgeArgs = {
  orderBy?: InputMaybe<Array<RelationBusinessUnitDivisionsOrderBy>>;
};

export interface CreateRelationCityCompanyInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  relationCityCompany: RelationCityCompanyInput;
}

export interface CreateRelationCityCompanyPayload {
  __typename?: 'CreateRelationCityCompanyPayload';
  city: City;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  query?: Maybe<Query>;
  relationCityCompany?: Maybe<RelationCityCompany>;
  relationCityCompanyEdge?: Maybe<RelationCityCompaniesEdge>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateRelationCityCompanyPayloadRelationCityCompanyEdgeArgs = {
  orderBy?: InputMaybe<Array<RelationCityCompaniesOrderBy>>;
};

export interface CreateRelationCompanyBusinessUnitInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  relationCompanyBusinessUnit: RelationCompanyBusinessUnitInput;
}

export interface CreateRelationCompanyBusinessUnitPayload {
  __typename?: 'CreateRelationCompanyBusinessUnitPayload';
  businessUnit: BusinessUnit;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  query?: Maybe<Query>;
  relationCompanyBusinessUnit?: Maybe<RelationCompanyBusinessUnit>;
  relationCompanyBusinessUnitEdge?: Maybe<RelationCompanyBusinessUnitsEdge>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateRelationCompanyBusinessUnitPayloadRelationCompanyBusinessUnitEdgeArgs = {
  orderBy?: InputMaybe<Array<RelationCompanyBusinessUnitsOrderBy>>;
};

export interface CreateRelationCompanyGroupEmailDomainInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  relationCompanyGroupEmailDomain: RelationCompanyGroupEmailDomainInput;
}

export interface CreateRelationCompanyGroupEmailDomainPayload {
  __typename?: 'CreateRelationCompanyGroupEmailDomainPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  companyGroup: CompanyGroup;
  emailDomain: EmailDomain;
  query?: Maybe<Query>;
  relationCompanyGroupEmailDomain?: Maybe<RelationCompanyGroupEmailDomain>;
  relationCompanyGroupEmailDomainEdge?: Maybe<RelationCompanyGroupEmailDomainsEdge>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateRelationCompanyGroupEmailDomainPayloadRelationCompanyGroupEmailDomainEdgeArgs = {
  orderBy?: InputMaybe<Array<RelationCompanyGroupEmailDomainsOrderBy>>;
};

export interface CreateRelationCompanyOfficeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  relationCompanyOffice: RelationCompanyOfficeInput;
}

export interface CreateRelationCompanyOfficePayload {
  __typename?: 'CreateRelationCompanyOfficePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  office: Office;
  query?: Maybe<Query>;
  relationCompanyOffice?: Maybe<RelationCompanyOffice>;
  relationCompanyOfficeEdge?: Maybe<RelationCompanyOfficesEdge>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateRelationCompanyOfficePayloadRelationCompanyOfficeEdgeArgs = {
  orderBy?: InputMaybe<Array<RelationCompanyOfficesOrderBy>>;
};

export interface CreateRelationCurrencyCountryInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  relationCurrencyCountry: RelationCurrencyCountryInput;
}

export interface CreateRelationCurrencyCountryPayload {
  __typename?: 'CreateRelationCurrencyCountryPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  countryByCountryCodeAlpha2: Country;
  currencyByCurrencyCode: Currency;
  query?: Maybe<Query>;
  relationCurrencyCountry?: Maybe<RelationCurrencyCountry>;
  relationCurrencyCountryEdge?: Maybe<RelationCurrencyCountriesEdge>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateRelationCurrencyCountryPayloadRelationCurrencyCountryEdgeArgs = {
  orderBy?: InputMaybe<Array<RelationCurrencyCountriesOrderBy>>;
};

export interface CreateRelationEmployeeUserAccountInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  relationEmployeeUserAccount: RelationEmployeeUserAccountInput;
}

export interface CreateRelationEmployeeUserAccountPayload {
  __typename?: 'CreateRelationEmployeeUserAccountPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employee: Employee;
  query?: Maybe<Query>;
  relationEmployeeUserAccount?: Maybe<RelationEmployeeUserAccount>;
  relationEmployeeUserAccountEdge?: Maybe<RelationEmployeeUserAccountsEdge>;
  userAccount: UserAccount;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type CreateRelationEmployeeUserAccountPayloadRelationEmployeeUserAccountEdgeArgs = {
  orderBy?: InputMaybe<Array<RelationEmployeeUserAccountsOrderBy>>;
};

export interface CreateUserAccountAccessHistoryInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistory: UserAccountAccessHistoryInput;
}

export interface CreateUserAccountAccessHistoryPayload {
  __typename?: 'CreateUserAccountAccessHistoryPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
  userAccount: UserAccount;
  userAccountAccessHistory?: Maybe<UserAccountAccessHistory>;
  userAccountAccessHistoryEdge?: Maybe<UserAccountAccessHistoriesEdge>;
}

export type CreateUserAccountAccessHistoryPayloadUserAccountAccessHistoryEdgeArgs = {
  orderBy?: InputMaybe<Array<UserAccountAccessHistoriesOrderBy>>;
};

export interface CreateUserAccountInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userAccount: UserAccountInput;
}

export interface CreateUserAccountPayload {
  __typename?: 'CreateUserAccountPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
  userAccount?: Maybe<UserAccount>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userAccountEdge?: Maybe<UserAccountsEdge>;
  userRoleByRole: UserRole;
}

export type CreateUserAccountPayloadUserAccountEdgeArgs = {
  orderBy?: InputMaybe<Array<UserAccountsOrderBy>>;
};

export interface CreateUserAccountPinInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userAccountPin: UserAccountPinInput;
}

export interface CreateUserAccountPinPayload {
  __typename?: 'CreateUserAccountPinPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
  userAccount: UserAccount;
  userAccountPin?: Maybe<UserAccountPin>;
  userAccountPinEdge?: Maybe<UserAccountPinsEdge>;
}

export type CreateUserAccountPinPayloadUserAccountPinEdgeArgs = {
  orderBy?: InputMaybe<Array<UserAccountPinsOrderBy>>;
};

export interface CreateUserHrManagingCompanyInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userHrManagingCompany: UserHrManagingCompanyInput;
}

export interface CreateUserHrManagingCompanyPayload {
  __typename?: 'CreateUserHrManagingCompanyPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  query?: Maybe<Query>;
  userAccount: UserAccount;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userHrManagingCompany?: Maybe<UserHrManagingCompany>;
  userHrManagingCompanyEdge?: Maybe<UserHrManagingCompaniesEdge>;
}

export type CreateUserHrManagingCompanyPayloadUserHrManagingCompanyEdgeArgs = {
  orderBy?: InputMaybe<Array<UserHrManagingCompaniesOrderBy>>;
};

export interface CreateWorldPlaceInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  worldPlace: WorldPlaceInput;
}

export interface CreateWorldPlacePayload {
  __typename?: 'CreateWorldPlacePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  worldPlace?: Maybe<WorldPlace>;
  worldPlaceEdge?: Maybe<WorldPlacesEdge>;
}

export type CreateWorldPlacePayloadWorldPlaceEdgeArgs = {
  orderBy?: InputMaybe<Array<WorldPlacesOrderBy>>;
};

export interface CurrenciesConnection {
  __typename?: 'CurrenciesConnection';
  edges: Array<CurrenciesEdge>;
  nodes: Array<Currency>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface CurrenciesEdge {
  __typename?: 'CurrenciesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: Currency;
}

export enum CurrenciesOrderBy {
  ABBREVIATION_ASC = 'ABBREVIATION_ASC',
  ABBREVIATION_DESC = 'ABBREVIATION_DESC',
  CURRENCY_CODE_ASC = 'CURRENCY_CODE_ASC',
  CURRENCY_CODE_DESC = 'CURRENCY_CODE_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__COUNT_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__COUNT_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__COUNT_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__COUNT_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_APPROVER_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_APPROVER_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_APPROVER_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_APPROVER_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_BANK_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_BANK_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_BANK_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_BANK_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_BANK_REMARKS_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_BANK_REMARKS_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_BANK_REMARKS_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_BANK_REMARKS_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_DATE_CREATED_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_DATE_CREATED_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_DATE_CREATED_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_DATE_CREATED_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_DATE_UPDATED_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_DATE_UPDATED_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_DATE_UPDATED_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_DATE_UPDATED_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_FULL_NAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_FULL_NAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_FULL_NAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_FULL_NAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_GENDER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_GENDER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_GENDER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_GENDER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_ID_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_ID_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_ID_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_ID_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_ID_TYPE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_ID_TYPE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_ID_TYPE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_ID_TYPE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_JOIN_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_JOIN_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_JOIN_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_JOIN_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_NATIONALITY_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_NATIONALITY_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_NATIONALITY_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_NATIONALITY_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_NICKNAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_NICKNAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_NICKNAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_NICKNAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PROBATION_END_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PROBATION_END_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PROBATION_END_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_PROBATION_END_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_REMARK_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_REMARK_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_REMARK_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_REMARK_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_STATUS_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_STATUS_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_STATUS_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_STATUS_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_TAX_CODE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_TAX_CODE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_TAX_CODE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_TAX_CODE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_USER_CREATED_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_USER_CREATED_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_USER_CREATED_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_USER_CREATED_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_USER_UPDATED_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_USER_UPDATED_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_USER_UPDATED_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_USER_UPDATED_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_VISA_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_VISA_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_VISA_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_VISA_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_VISA_TYPE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_VISA_TYPE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_VISA_TYPE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_VISA_TYPE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MAX_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_APPROVER_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_APPROVER_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_APPROVER_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_APPROVER_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_BANK_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_BANK_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_BANK_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_BANK_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_BANK_REMARKS_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_BANK_REMARKS_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_BANK_REMARKS_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_BANK_REMARKS_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_DATE_CREATED_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_DATE_CREATED_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_DATE_CREATED_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_DATE_CREATED_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_DATE_UPDATED_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_DATE_UPDATED_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_DATE_UPDATED_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_DATE_UPDATED_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_FULL_NAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_FULL_NAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_FULL_NAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_FULL_NAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_GENDER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_GENDER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_GENDER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_GENDER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_ID_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_ID_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_ID_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_ID_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_ID_TYPE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_ID_TYPE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_ID_TYPE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_ID_TYPE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_JOIN_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_JOIN_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_JOIN_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_JOIN_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_NATIONALITY_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_NATIONALITY_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_NATIONALITY_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_NATIONALITY_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_NICKNAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_NICKNAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_NICKNAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_NICKNAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PROBATION_END_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PROBATION_END_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PROBATION_END_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_PROBATION_END_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_REMARK_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_REMARK_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_REMARK_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_REMARK_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_STATUS_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_STATUS_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_STATUS_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_STATUS_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_TAX_CODE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_TAX_CODE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_TAX_CODE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_TAX_CODE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_USER_CREATED_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_USER_CREATED_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_USER_CREATED_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_USER_CREATED_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_USER_UPDATED_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_USER_UPDATED_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_USER_UPDATED_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_USER_UPDATED_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_VISA_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_VISA_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_VISA_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_VISA_NUMBER_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_VISA_TYPE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_VISA_TYPE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_VISA_TYPE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_VISA_TYPE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_CONSULTING_FEE_CURRENCY_ID__MIN_WORK_PERMIT_NUMBER_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__COUNT_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__COUNT_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__COUNT_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__COUNT_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_COUNTRY_CODE_ALPHA2_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_COUNTRY_CODE_ALPHA2_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_COUNTRY_CODE_ALPHA2_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_COUNTRY_CODE_ALPHA2_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_CURRENCY_CODE_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_CURRENCY_CODE_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_CURRENCY_CODE_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_CURRENCY_CODE_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_DATE_CREATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_DATE_CREATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_DATE_CREATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_DATE_CREATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_DATE_UPDATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_DATE_UPDATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_DATE_UPDATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_DATE_UPDATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_REMARK_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_REMARK_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_REMARK_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_REMARK_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_USER_CREATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_USER_CREATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_USER_CREATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_USER_CREATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_USER_UPDATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_USER_UPDATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_USER_UPDATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MAX_USER_UPDATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_COUNTRY_CODE_ALPHA2_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_COUNTRY_CODE_ALPHA2_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_COUNTRY_CODE_ALPHA2_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_COUNTRY_CODE_ALPHA2_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_CURRENCY_CODE_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_CURRENCY_CODE_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_CURRENCY_CODE_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_CURRENCY_CODE_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_DATE_CREATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_DATE_CREATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_DATE_CREATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_DATE_CREATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_DATE_UPDATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_DATE_UPDATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_DATE_UPDATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_DATE_UPDATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_REMARK_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_REMARK_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_REMARK_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_REMARK_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_USER_CREATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_USER_CREATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_USER_CREATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_USER_CREATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_USER_UPDATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_USER_UPDATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_USER_UPDATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_CURRENCY_CODE__MIN_USER_UPDATED_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface Currency extends Node {
  __typename?: 'Currency';
  abbreviation: Scalars['String']['output'];
  currencyCode: Scalars['String']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  employeesByConsultingFeeCurrencyId: EmployeesConnection;
  name: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  relationCurrencyCountriesByCurrencyCode: RelationCurrencyCountriesConnection;
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export type CurrencyEmployeesByConsultingFeeCurrencyIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCondition>;
  filter?: InputMaybe<EmployeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeesOrderBy>>;
};

export type CurrencyRelationCurrencyCountriesByCurrencyCodeArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationCurrencyCountryCondition>;
  filter?: InputMaybe<RelationCurrencyCountryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationCurrencyCountriesOrderBy>>;
};

export interface CurrencyCondition {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CurrencyCurrencyPkeyConnect {
  currencyCode: Scalars['String']['input'];
}

export interface CurrencyCurrencyPkeyDelete {
  currencyCode: Scalars['String']['input'];
}

export interface CurrencyFilter {
  abbreviation?: InputMaybe<StringFilter>;
  and?: InputMaybe<Array<CurrencyFilter>>;
  currencyCode?: InputMaybe<StringFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  employeesByConsultingFeeCurrencyId?: InputMaybe<CurrencyToManyEmployeeFilter>;
  employeesByConsultingFeeCurrencyIdExist?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<StringFilter>;
  not?: InputMaybe<CurrencyFilter>;
  or?: InputMaybe<Array<CurrencyFilter>>;
  relationCurrencyCountriesByCurrencyCode?: InputMaybe<CurrencyToManyRelationCurrencyCountryFilter>;
  relationCurrencyCountriesByCurrencyCodeExist?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface CurrencyInput {
  abbreviation: Scalars['String']['input'];
  currencyCode: Scalars['String']['input'];
  employees?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInverseInput>;
  name: Scalars['String']['input'];
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCurrencyCodeFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CurrencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CurrencyNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface CurrencyNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface CurrencyOnCurrencyForCurrencyUserCreatedFkeyUsingCurrencyPkeyUpdate {
  currencyCode: Scalars['String']['input'];
  patch: UpdateCurrencyOnCurrencyForCurrencyUserCreatedFkeyPatch;
}

export interface CurrencyOnCurrencyForCurrencyUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface CurrencyOnCurrencyForCurrencyUserUpdatedFkeyUsingCurrencyPkeyUpdate {
  currencyCode: Scalars['String']['input'];
  patch: UpdateCurrencyOnCurrencyForCurrencyUserUpdatedFkeyPatch;
}

export interface CurrencyOnEmployeeForEmployeeConsultingFeeCurrencyIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface CurrencyOnEmployeeForEmployeeConsultingFeeCurrencyIdFkeyUsingCurrencyPkeyUpdate {
  currencyCode: Scalars['String']['input'];
  patch: UpdateCurrencyOnEmployeeForEmployeeConsultingFeeCurrencyIdFkeyPatch;
}

export interface CurrencyOnRelationCurrencyCountryForRelationCurrencyCountryCurrencyCodeFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationCurrencyCountryPatch;
}

export interface CurrencyOnRelationCurrencyCountryForRelationCurrencyCountryCurrencyCodeFkeyUsingCurrencyPkeyUpdate {
  currencyCode: Scalars['String']['input'];
  patch: UpdateCurrencyOnRelationCurrencyCountryForRelationCurrencyCountryCurrencyCodeFkeyPatch;
}

export interface CurrencyPatch {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  employees?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInverseInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCurrencyCodeFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CurrencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface CurrencyToManyEmployeeFilter {
  every?: InputMaybe<EmployeeFilter>;
  none?: InputMaybe<EmployeeFilter>;
  some?: InputMaybe<EmployeeFilter>;
}

export interface CurrencyToManyRelationCurrencyCountryFilter {
  every?: InputMaybe<RelationCurrencyCountryFilter>;
  none?: InputMaybe<RelationCurrencyCountryFilter>;
  some?: InputMaybe<RelationCurrencyCountryFilter>;
}

export interface CurrencyUserCreatedFkeyInverseInput {
  connectByCurrencyCode?: InputMaybe<Array<CurrencyCurrencyPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<CurrencyNodeIdConnect>>;
  deleteByCurrencyCode?: InputMaybe<Array<CurrencyCurrencyPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<CurrencyNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCurrencyCode?: InputMaybe<Array<CurrencyOnCurrencyForCurrencyUserCreatedFkeyUsingCurrencyPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnCurrencyForCurrencyUserCreatedFkeyNodeIdUpdate>>;
}

export interface CurrencyUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnCurrencyForCurrencyUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<CurrencyOnCurrencyForCurrencyUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnCurrencyForCurrencyUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface CurrencyUserUpdatedFkeyInverseInput {
  connectByCurrencyCode?: InputMaybe<Array<CurrencyCurrencyPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<CurrencyNodeIdConnect>>;
  deleteByCurrencyCode?: InputMaybe<Array<CurrencyCurrencyPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<CurrencyNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCurrencyCode?: InputMaybe<Array<CurrencyOnCurrencyForCurrencyUserUpdatedFkeyUsingCurrencyPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnCurrencyForCurrencyUserUpdatedFkeyNodeIdUpdate>>;
}

export interface DateFilter {
  distinctFrom?: InputMaybe<Scalars['Date']['input']>;
  equalTo?: InputMaybe<Scalars['Date']['input']>;
  greaterThan?: InputMaybe<Scalars['Date']['input']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['Date']['input']>;
  in?: InputMaybe<Array<Scalars['Date']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<Scalars['Date']['input']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['Date']['input']>;
  notDistinctFrom?: InputMaybe<Scalars['Date']['input']>;
  notEqualTo?: InputMaybe<Scalars['Date']['input']>;
  notIn?: InputMaybe<Array<Scalars['Date']['input']>>;
}

export interface DatetimeFilter {
  distinctFrom?: InputMaybe<Scalars['Datetime']['input']>;
  equalTo?: InputMaybe<Scalars['Datetime']['input']>;
  greaterThan?: InputMaybe<Scalars['Datetime']['input']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  in?: InputMaybe<Array<Scalars['Datetime']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<Scalars['Datetime']['input']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  notDistinctFrom?: InputMaybe<Scalars['Datetime']['input']>;
  notEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  notIn?: InputMaybe<Array<Scalars['Datetime']['input']>>;
}

export interface DeleteBankByCountryCodeAlpha3AndFullNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha3: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
}

export interface DeleteBankByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteBankBySwiftCodeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  swiftCode: Scalars['String']['input'];
}

export interface DeleteBankInput {
  bankId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

export interface DeleteBankPayload {
  __typename?: 'DeleteBankPayload';
  bank?: Maybe<Bank>;
  bankEdge?: Maybe<BanksEdge>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  countryByCountryCodeAlpha3: Country;
  deletedBankNodeId?: Maybe<Scalars['ID']['output']>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteBankPayloadBankEdgeArgs = {
  orderBy?: InputMaybe<Array<BanksOrderBy>>;
};

export interface DeleteBusinessUnitByNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
}

export interface DeleteBusinessUnitByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteBusinessUnitInput {
  businessUnitId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

export interface DeleteBusinessUnitPayload {
  __typename?: 'DeleteBusinessUnitPayload';
  businessUnit?: Maybe<BusinessUnit>;
  businessUnitEdge?: Maybe<BusinessUnitsEdge>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedBusinessUnitNodeId?: Maybe<Scalars['ID']['output']>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteBusinessUnitPayloadBusinessUnitEdgeArgs = {
  orderBy?: InputMaybe<Array<BusinessUnitsOrderBy>>;
};

export interface DeleteCityByCountryCodeAlpha2AndNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha2: Scalars['String']['input'];
  name: Scalars['String']['input'];
}

export interface DeleteCityByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteCityInput {
  cityId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

export interface DeleteCityPayload {
  __typename?: 'DeleteCityPayload';
  city?: Maybe<City>;
  cityEdge?: Maybe<CitiesEdge>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  countryByCountryCodeAlpha2?: Maybe<Country>;
  deletedCityNodeId?: Maybe<Scalars['ID']['output']>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteCityPayloadCityEdgeArgs = {
  orderBy?: InputMaybe<Array<CitiesOrderBy>>;
};

export interface DeleteCompanyByAbbreviationInput {
  abbreviation: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

export interface DeleteCompanyByCountryCodeAlpha2AndFullNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha2: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
}

export interface DeleteCompanyByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteCompanyExternalAccountDisableByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteCompanyExternalAccountDisableInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['BigInt']['input'];
  externalAccountTypeKey: ExternalAccountEmployeeType;
}

export interface DeleteCompanyExternalAccountDisablePayload {
  __typename?: 'DeleteCompanyExternalAccountDisablePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  companyExternalAccountDisable?: Maybe<CompanyExternalAccountDisable>;
  companyExternalAccountDisableEdge?: Maybe<CompanyExternalAccountDisablesEdge>;
  deletedCompanyExternalAccountDisableNodeId?: Maybe<Scalars['ID']['output']>;
  externalAccountTypeByExternalAccountTypeKey: ExternalAccountType;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
}

export type DeleteCompanyExternalAccountDisablePayloadCompanyExternalAccountDisableEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyExternalAccountDisablesOrderBy>>;
};

export interface DeleteCompanyGlobalHrByEmployeeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeId: Scalars['BigInt']['input'];
}

export interface DeleteCompanyGlobalHrByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteCompanyGlobalHrInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHrId: Scalars['BigInt']['input'];
}

export interface DeleteCompanyGlobalHrPayload {
  __typename?: 'DeleteCompanyGlobalHrPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  companyGlobalHr?: Maybe<CompanyGlobalHr>;
  companyGlobalHrEdge?: Maybe<CompanyGlobalHrsEdge>;
  deletedCompanyGlobalHrNodeId?: Maybe<Scalars['ID']['output']>;
  employee: Employee;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteCompanyGlobalHrPayloadCompanyGlobalHrEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyGlobalHrsOrderBy>>;
};

export interface DeleteCompanyGroupByNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
}

export interface DeleteCompanyGroupByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteCompanyGroupInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyGroupId: Scalars['BigInt']['input'];
}

export interface DeleteCompanyGroupPayload {
  __typename?: 'DeleteCompanyGroupPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  companyGroup?: Maybe<CompanyGroup>;
  companyGroupEdge?: Maybe<CompanyGroupsEdge>;
  deletedCompanyGroupNodeId?: Maybe<Scalars['ID']['output']>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteCompanyGroupPayloadCompanyGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyGroupsOrderBy>>;
};

export interface DeleteCompanyInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['BigInt']['input'];
}

export interface DeleteCompanyLocalHrByCompanyIdAndEmployeeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['BigInt']['input'];
  employeeId: Scalars['BigInt']['input'];
}

export interface DeleteCompanyLocalHrByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteCompanyLocalHrInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyLocalHrId: Scalars['BigInt']['input'];
}

export interface DeleteCompanyLocalHrPayload {
  __typename?: 'DeleteCompanyLocalHrPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  companyLocalHr?: Maybe<CompanyLocalHr>;
  companyLocalHrEdge?: Maybe<CompanyLocalHrsEdge>;
  deletedCompanyLocalHrNodeId?: Maybe<Scalars['ID']['output']>;
  employee: Employee;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteCompanyLocalHrPayloadCompanyLocalHrEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyLocalHrsOrderBy>>;
};

export interface DeleteCompanyPayload {
  __typename?: 'DeleteCompanyPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Company>;
  companyEdge?: Maybe<CompaniesEdge>;
  companyGroup?: Maybe<CompanyGroup>;
  countryByCountryCodeAlpha2?: Maybe<Country>;
  deletedCompanyNodeId?: Maybe<Scalars['ID']['output']>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteCompanyPayloadCompanyEdgeArgs = {
  orderBy?: InputMaybe<Array<CompaniesOrderBy>>;
};

export interface DeleteContractTypeByCodeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
}

export interface DeleteContractTypeByDisplayNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
}

export interface DeleteContractTypeByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteContractTypeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contractTypeId: Scalars['BigInt']['input'];
}

export interface DeleteContractTypePayload {
  __typename?: 'DeleteContractTypePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contractType?: Maybe<ContractType>;
  contractTypeEdge?: Maybe<ContractTypesEdge>;
  deletedContractTypeNodeId?: Maybe<Scalars['ID']['output']>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteContractTypePayloadContractTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<ContractTypesOrderBy>>;
};

export interface DeleteCountryByCountryCodeAlpha3Input {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha3: Scalars['String']['input'];
}

export interface DeleteCountryByCountryCodeNumericInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  countryCodeNumeric: Scalars['String']['input'];
}

export interface DeleteCountryByDisplayOrderInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  displayOrder: Scalars['Int']['input'];
}

export interface DeleteCountryByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteCountryInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha2: Scalars['String']['input'];
}

export interface DeleteCountryPayload {
  __typename?: 'DeleteCountryPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  countryEdge?: Maybe<CountriesEdge>;
  deletedCountryNodeId?: Maybe<Scalars['ID']['output']>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteCountryPayloadCountryEdgeArgs = {
  orderBy?: InputMaybe<Array<CountriesOrderBy>>;
};

export interface DeleteCurrencyByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteCurrencyInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currencyCode: Scalars['String']['input'];
}

export interface DeleteCurrencyPayload {
  __typename?: 'DeleteCurrencyPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Currency>;
  currencyEdge?: Maybe<CurrenciesEdge>;
  deletedCurrencyNodeId?: Maybe<Scalars['ID']['output']>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteCurrencyPayloadCurrencyEdgeArgs = {
  orderBy?: InputMaybe<Array<CurrenciesOrderBy>>;
};

export interface DeleteDepartmentByNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
}

export interface DeleteDepartmentByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteDepartmentInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  departmentId: Scalars['BigInt']['input'];
}

export interface DeleteDepartmentPayload {
  __typename?: 'DeleteDepartmentPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedDepartmentNodeId?: Maybe<Scalars['ID']['output']>;
  department?: Maybe<Department>;
  departmentEdge?: Maybe<DepartmentsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteDepartmentPayloadDepartmentEdgeArgs = {
  orderBy?: InputMaybe<Array<DepartmentsOrderBy>>;
};

export interface DeleteDivisionByNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
}

export interface DeleteDivisionByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteDivisionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  divisionId: Scalars['BigInt']['input'];
}

export interface DeleteDivisionPayload {
  __typename?: 'DeleteDivisionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedDivisionNodeId?: Maybe<Scalars['ID']['output']>;
  division?: Maybe<Division>;
  divisionEdge?: Maybe<DivisionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteDivisionPayloadDivisionEdgeArgs = {
  orderBy?: InputMaybe<Array<DivisionsOrderBy>>;
};

export interface DeleteEmailDomainByDomainNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  domainName: Scalars['String']['input'];
}

export interface DeleteEmailDomainByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteEmailDomainInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  emailDomainId: Scalars['BigInt']['input'];
}

export interface DeleteEmailDomainPayload {
  __typename?: 'DeleteEmailDomainPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedEmailDomainNodeId?: Maybe<Scalars['ID']['output']>;
  emailDomain?: Maybe<EmailDomain>;
  emailDomainEdge?: Maybe<EmailDomainsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteEmailDomainPayloadEmailDomainEdgeArgs = {
  orderBy?: InputMaybe<Array<EmailDomainsOrderBy>>;
};

export interface DeleteEmployeeByCompanyEmailInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyEmail: Scalars['String']['input'];
}

export interface DeleteEmployeeByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteEmployeeEmergencyContactByEmployeeIdAndRelationIdAndNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeId: Scalars['BigInt']['input'];
  name: Scalars['String']['input'];
  relationId: Scalars['BigInt']['input'];
}

export interface DeleteEmployeeEmergencyContactByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteEmployeeEmergencyContactInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeEmergencyContactId: Scalars['BigInt']['input'];
}

export interface DeleteEmployeeEmergencyContactPayload {
  __typename?: 'DeleteEmployeeEmergencyContactPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedEmployeeEmergencyContactNodeId?: Maybe<Scalars['ID']['output']>;
  employee: Employee;
  employeeEmergencyContact?: Maybe<EmployeeEmergencyContact>;
  employeeEmergencyContactEdge?: Maybe<EmployeeEmergencyContactsEdge>;
  query?: Maybe<Query>;
  relation?: Maybe<RelationEmergencyContact>;
  telephoneNumberCountry?: Maybe<Country>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteEmployeeEmergencyContactPayloadEmployeeEmergencyContactEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeEmergencyContactsOrderBy>>;
};

export interface DeleteEmployeeFileByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteEmployeeFileByS3PathInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  s3Path: Scalars['String']['input'];
}

export interface DeleteEmployeeFileInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeFileId: Scalars['BigInt']['input'];
}

export interface DeleteEmployeeFilePayload {
  __typename?: 'DeleteEmployeeFilePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedEmployeeFileNodeId?: Maybe<Scalars['ID']['output']>;
  employee?: Maybe<Employee>;
  employeeFile?: Maybe<EmployeeFile>;
  employeeFileEdge?: Maybe<EmployeeFilesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userRoleByVisibleRole?: Maybe<UserRole>;
}

export type DeleteEmployeeFilePayloadEmployeeFileEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeFilesOrderBy>>;
};

export interface DeleteEmployeeGroupByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteEmployeeGroupInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyEmail: Scalars['String']['input'];
  employeeId: Scalars['BigInt']['input'];
}

export interface DeleteEmployeeGroupPayload {
  __typename?: 'DeleteEmployeeGroupPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedEmployeeGroupNodeId?: Maybe<Scalars['ID']['output']>;
  employee: Employee;
  employeeGroup?: Maybe<EmployeeGroup>;
  employeeGroupEdge?: Maybe<EmployeeGroupsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteEmployeeGroupPayloadEmployeeGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeGroupsOrderBy>>;
};

export interface DeleteEmployeeIdTypeByCodeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
}

export interface DeleteEmployeeIdTypeByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteEmployeeIdTypeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeIdTypeId: Scalars['BigInt']['input'];
}

export interface DeleteEmployeeIdTypePayload {
  __typename?: 'DeleteEmployeeIdTypePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  countryByCountryCodeAlpha2?: Maybe<Country>;
  deletedEmployeeIdTypeNodeId?: Maybe<Scalars['ID']['output']>;
  employeeIdType?: Maybe<EmployeeIdType>;
  employeeIdTypeEdge?: Maybe<EmployeeIdTypesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteEmployeeIdTypePayloadEmployeeIdTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeIdTypesOrderBy>>;
};

export interface DeleteEmployeeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeId: Scalars['BigInt']['input'];
}

export interface DeleteEmployeeLevelByDisplayNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
}

export interface DeleteEmployeeLevelByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteEmployeeLevelInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeLevelId: Scalars['BigInt']['input'];
}

export interface DeleteEmployeeLevelPayload {
  __typename?: 'DeleteEmployeeLevelPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedEmployeeLevelNodeId?: Maybe<Scalars['ID']['output']>;
  employeeLevel?: Maybe<EmployeeLevel>;
  employeeLevelEdge?: Maybe<EmployeeLevelsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteEmployeeLevelPayloadEmployeeLevelEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeLevelsOrderBy>>;
};

export interface DeleteEmployeeListCustomFilterByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteEmployeeListCustomFilterInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeListCustomFilterId: Scalars['BigInt']['input'];
}

export interface DeleteEmployeeListCustomFilterPayload {
  __typename?: 'DeleteEmployeeListCustomFilterPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedEmployeeListCustomFilterNodeId?: Maybe<Scalars['ID']['output']>;
  employeeListCustomFilter?: Maybe<EmployeeListCustomFilter>;
  employeeListCustomFilterEdge?: Maybe<EmployeeListCustomFiltersEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteEmployeeListCustomFilterPayloadEmployeeListCustomFilterEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeListCustomFiltersOrderBy>>;
};

export interface DeleteEmployeeListCustomFilterRoleByEmployeeListCustomFilterIdAndRoleNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeListCustomFilterId: Scalars['BigInt']['input'];
  roleName: Scalars['String']['input'];
}

export interface DeleteEmployeeListCustomFilterRoleByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteEmployeeListCustomFilterRoleInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeListCustomFilterRoleId: Scalars['BigInt']['input'];
}

export interface DeleteEmployeeListCustomFilterRolePayload {
  __typename?: 'DeleteEmployeeListCustomFilterRolePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedEmployeeListCustomFilterRoleNodeId?: Maybe<Scalars['ID']['output']>;
  employeeListCustomFilter: EmployeeListCustomFilter;
  employeeListCustomFilterRole?: Maybe<EmployeeListCustomFilterRole>;
  employeeListCustomFilterRoleEdge?: Maybe<EmployeeListCustomFilterRolesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userRoleByRoleName: UserRole;
}

export type DeleteEmployeeListCustomFilterRolePayloadEmployeeListCustomFilterRoleEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeListCustomFilterRolesOrderBy>>;
};

export interface DeleteEmployeePayload {
  __typename?: 'DeleteEmployeePayload';
  approver?: Maybe<Employee>;
  bank?: Maybe<Bank>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  consultingFeeBank?: Maybe<Bank>;
  consultingFeeCurrency?: Maybe<Currency>;
  countryByNationality?: Maybe<Country>;
  countryByWorkingLocationCountryCodeAlpha2: Country;
  deletedEmployeeNodeId?: Maybe<Scalars['ID']['output']>;
  dottedLineManager?: Maybe<Employee>;
  employee?: Maybe<Employee>;
  employeeEdge?: Maybe<EmployeesEdge>;
  employeeIdTypeByIdType?: Maybe<EmployeeIdType>;
  employeeStatusByStatus: EmployeeStatus;
  mobileNumberCountry?: Maybe<Country>;
  primaryManager?: Maybe<Employee>;
  query?: Maybe<Query>;
  resignationReason?: Maybe<EmployeeResignationReason>;
  resignationType?: Maybe<EmployeeResignationType>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteEmployeePayloadEmployeeEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeesOrderBy>>;
};

export interface DeleteEmployeeResignationReasonByDisplayNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
}

export interface DeleteEmployeeResignationReasonByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteEmployeeResignationReasonInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeResignationReasonId: Scalars['BigInt']['input'];
}

export interface DeleteEmployeeResignationReasonPayload {
  __typename?: 'DeleteEmployeeResignationReasonPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedEmployeeResignationReasonNodeId?: Maybe<Scalars['ID']['output']>;
  employeeResignationReason?: Maybe<EmployeeResignationReason>;
  employeeResignationReasonEdge?: Maybe<EmployeeResignationReasonsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteEmployeeResignationReasonPayloadEmployeeResignationReasonEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeResignationReasonsOrderBy>>;
};

export interface DeleteEmployeeResignationTypeByDisplayNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
}

export interface DeleteEmployeeResignationTypeByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteEmployeeResignationTypeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeResignationTypeId: Scalars['BigInt']['input'];
}

export interface DeleteEmployeeResignationTypePayload {
  __typename?: 'DeleteEmployeeResignationTypePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedEmployeeResignationTypeNodeId?: Maybe<Scalars['ID']['output']>;
  employeeResignationType?: Maybe<EmployeeResignationType>;
  employeeResignationTypeEdge?: Maybe<EmployeeResignationTypesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteEmployeeResignationTypePayloadEmployeeResignationTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeResignationTypesOrderBy>>;
};

export interface DeleteEmployeeTpHistoryByEmployeeIdAndEffectiveDateInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  effectiveDate: Scalars['Date']['input'];
  employeeId: Scalars['BigInt']['input'];
}

export interface DeleteEmployeeTpHistoryByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteEmployeeTpHistoryInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeTpHistoryId: Scalars['BigInt']['input'];
}

export interface DeleteEmployeeTpHistoryPayload {
  __typename?: 'DeleteEmployeeTpHistoryPayload';
  businessUnit?: Maybe<BusinessUnit>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  contractType: ContractType;
  deletedEmployeeTpHistoryNodeId?: Maybe<Scalars['ID']['output']>;
  department?: Maybe<Department>;
  division?: Maybe<Division>;
  employee: Employee;
  employeeLevel: EmployeeLevel;
  employeeTpHistory?: Maybe<EmployeeTpHistory>;
  employeeTpHistoryEdge?: Maybe<EmployeeTpHistoriesEdge>;
  office?: Maybe<Office>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteEmployeeTpHistoryPayloadEmployeeTpHistoryEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeTpHistoriesOrderBy>>;
};

export interface DeleteExportEmployeeStatusByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteExportEmployeeStatusByUuid4Input {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  uuid4: Scalars['String']['input'];
}

export interface DeleteExportEmployeeStatusInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  exportEmployeeStatusId: Scalars['BigInt']['input'];
}

export interface DeleteExportEmployeeStatusPayload {
  __typename?: 'DeleteExportEmployeeStatusPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedExportEmployeeStatusNodeId?: Maybe<Scalars['ID']['output']>;
  exportEmployeeStatus?: Maybe<ExportEmployeeStatus>;
  exportEmployeeStatusEdge?: Maybe<ExportEmployeeStatusesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteExportEmployeeStatusPayloadExportEmployeeStatusEdgeArgs = {
  orderBy?: InputMaybe<Array<ExportEmployeeStatusesOrderBy>>;
};

export interface DeleteExternalAccountEmployeeByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKeyInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeId: Scalars['BigInt']['input'];
  externalAccountKey: Scalars['String']['input'];
  externalAccountTypeKey: ExternalAccountEmployeeType;
}

export interface DeleteExternalAccountEmployeeByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteExternalAccountEmployeeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  externalAccountEmployeeId: Scalars['BigInt']['input'];
}

export interface DeleteExternalAccountEmployeePayload {
  __typename?: 'DeleteExternalAccountEmployeePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedExternalAccountEmployeeNodeId?: Maybe<Scalars['ID']['output']>;
  employee: Employee;
  externalAccountEmployee?: Maybe<ExternalAccountEmployee>;
  externalAccountEmployeeEdge?: Maybe<ExternalAccountEmployeesEdge>;
  externalAccountTypeByExternalAccountTypeKey: ExternalAccountType;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteExternalAccountEmployeePayloadExternalAccountEmployeeEdgeArgs = {
  orderBy?: InputMaybe<Array<ExternalAccountEmployeesOrderBy>>;
};

export interface DeleteExternalAccountTypeByNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
}

export interface DeleteExternalAccountTypeByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteExternalAccountTypeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  key: ExternalAccountEmployeeType;
}

export interface DeleteExternalAccountTypePayload {
  __typename?: 'DeleteExternalAccountTypePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedExternalAccountTypeNodeId?: Maybe<Scalars['ID']['output']>;
  externalAccountType?: Maybe<ExternalAccountType>;
  externalAccountTypeEdge?: Maybe<ExternalAccountTypesEdge>;
  query?: Maybe<Query>;
}

export type DeleteExternalAccountTypePayloadExternalAccountTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<ExternalAccountTypesOrderBy>>;
};

export interface DeleteGoogleWorkspaceOrgUnitByCompanyIdAndBusinessUnitIdInput {
  businessUnitId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['BigInt']['input'];
}

export interface DeleteGoogleWorkspaceOrgUnitByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteGoogleWorkspaceOrgUnitInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  googleWorkspaceOrgUnitId: Scalars['BigInt']['input'];
}

export interface DeleteGoogleWorkspaceOrgUnitPayload {
  __typename?: 'DeleteGoogleWorkspaceOrgUnitPayload';
  businessUnit?: Maybe<BusinessUnit>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Company>;
  deletedGoogleWorkspaceOrgUnitNodeId?: Maybe<Scalars['ID']['output']>;
  googleWorkspaceOrgUnit?: Maybe<GoogleWorkspaceOrgUnit>;
  googleWorkspaceOrgUnitEdge?: Maybe<GoogleWorkspaceOrgUnitsEdge>;
  query?: Maybe<Query>;
}

export type DeleteGoogleWorkspaceOrgUnitPayloadGoogleWorkspaceOrgUnitEdgeArgs = {
  orderBy?: InputMaybe<Array<GoogleWorkspaceOrgUnitsOrderBy>>;
};

export interface DeleteImportEmployeeStatusByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteImportEmployeeStatusByUuid4Input {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  uuid4: Scalars['String']['input'];
}

export interface DeleteImportEmployeeStatusInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusId: Scalars['BigInt']['input'];
}

export interface DeleteImportEmployeeStatusPayload {
  __typename?: 'DeleteImportEmployeeStatusPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedImportEmployeeStatusNodeId?: Maybe<Scalars['ID']['output']>;
  importEmployeeStatus?: Maybe<ImportEmployeeStatus>;
  importEmployeeStatusEdge?: Maybe<ImportEmployeeStatusesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteImportEmployeeStatusPayloadImportEmployeeStatusEdgeArgs = {
  orderBy?: InputMaybe<Array<ImportEmployeeStatusesOrderBy>>;
};

export interface DeleteOfficeByCityIdAndNameInput {
  cityId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
}

export interface DeleteOfficeByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteOfficeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  officeId: Scalars['BigInt']['input'];
}

export interface DeleteOfficePayload {
  __typename?: 'DeleteOfficePayload';
  city: City;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedOfficeNodeId?: Maybe<Scalars['ID']['output']>;
  office?: Maybe<Office>;
  officeEdge?: Maybe<OfficesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteOfficePayloadOfficeEdgeArgs = {
  orderBy?: InputMaybe<Array<OfficesOrderBy>>;
};

export interface DeletePerformanceReviewEvaluationByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewEvaluationCompetencyByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewEvaluationCompetencyByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeIdInput {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewMasterCompetencyId: Scalars['String']['input'];
}

export interface DeletePerformanceReviewEvaluationCompetencyInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencyId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewEvaluationCompetencyPayload {
  __typename?: 'DeletePerformanceReviewEvaluationCompetencyPayload';
  assessmentEmployee: Employee;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewEvaluationCompetencyNodeId?: Maybe<Scalars['ID']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationCompetency?: Maybe<PerformanceReviewEvaluationCompetency>;
  performanceReviewEvaluationCompetencyEdge?: Maybe<PerformanceReviewEvaluationCompetenciesEdge>;
  performanceReviewMasterCompetency: PerformanceReviewMasterCompetency;
  performanceReviewMasterRatingByAssessmentValue?: Maybe<PerformanceReviewMasterRating>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewEvaluationCompetencyPayloadPerformanceReviewEvaluationCompetencyEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationCompetenciesOrderBy>>;
};

export interface DeletePerformanceReviewEvaluationEvaluatorByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewEvaluationEvaluatorByPerformanceReviewEvaluationIdAndEvaluatorIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  evaluatorId: Scalars['BigInt']['input'];
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewEvaluationEvaluatorInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationEvaluatorId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewEvaluationEvaluatorPayload {
  __typename?: 'DeletePerformanceReviewEvaluationEvaluatorPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewEvaluationEvaluatorNodeId?: Maybe<Scalars['ID']['output']>;
  evaluator: Employee;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationEvaluator?: Maybe<PerformanceReviewEvaluationEvaluator>;
  performanceReviewEvaluationEvaluatorEdge?: Maybe<PerformanceReviewEvaluationEvaluatorsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewEvaluationEvaluatorPayloadPerformanceReviewEvaluationEvaluatorEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorsOrderBy>>;
};

export interface DeletePerformanceReviewEvaluationFeedbackByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewEvaluationFeedbackByPerformanceReviewEvaluationIdAndFeedbackEmployeeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feedbackEmployeeId: Scalars['BigInt']['input'];
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewEvaluationFeedbackInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationFeedbackId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewEvaluationFeedbackPayload {
  __typename?: 'DeletePerformanceReviewEvaluationFeedbackPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewEvaluationFeedbackNodeId?: Maybe<Scalars['ID']['output']>;
  feedbackEmployee?: Maybe<Employee>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationFeedback?: Maybe<PerformanceReviewEvaluationFeedback>;
  performanceReviewEvaluationFeedbackEdge?: Maybe<PerformanceReviewEvaluationFeedbacksEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewEvaluationFeedbackPayloadPerformanceReviewEvaluationFeedbackEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationFeedbacksOrderBy>>;
};

export interface DeletePerformanceReviewEvaluationInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewEvaluationKpiAssessmentByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewEvaluationKpiAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeIdInput {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewEvaluationKpiAssessmentInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationKpiAssessmentId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewEvaluationKpiAssessmentPayload {
  __typename?: 'DeletePerformanceReviewEvaluationKpiAssessmentPayload';
  assessmentEmployee: Employee;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewEvaluationKpiAssessmentNodeId?: Maybe<Scalars['ID']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationKpiAssessment?: Maybe<PerformanceReviewEvaluationKpiAssessment>;
  performanceReviewEvaluationKpiAssessmentEdge?: Maybe<PerformanceReviewEvaluationKpiAssessmentsEdge>;
  performanceReviewEvaluationKpiDescription: PerformanceReviewEvaluationKpiDescription;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewEvaluationKpiAssessmentPayloadPerformanceReviewEvaluationKpiAssessmentEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentsOrderBy>>;
};

export interface DeletePerformanceReviewEvaluationKpiDescriptionByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewEvaluationKpiDescriptionByPerformanceReviewEvaluationIdAndTitleInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  title: Scalars['String']['input'];
}

export interface DeletePerformanceReviewEvaluationKpiDescriptionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewEvaluationKpiDescriptionPayload {
  __typename?: 'DeletePerformanceReviewEvaluationKpiDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewEvaluationKpiDescriptionNodeId?: Maybe<Scalars['ID']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationKpiDescription?: Maybe<PerformanceReviewEvaluationKpiDescription>;
  performanceReviewEvaluationKpiDescriptionEdge?: Maybe<PerformanceReviewEvaluationKpiDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewEvaluationKpiDescriptionPayloadPerformanceReviewEvaluationKpiDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionsOrderBy>>;
};

export interface DeletePerformanceReviewEvaluationKpiResultByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewEvaluationKpiResultByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiTargetId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewEvaluationKpiResultInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationKpiResultId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewEvaluationKpiResultPayload {
  __typename?: 'DeletePerformanceReviewEvaluationKpiResultPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewEvaluationKpiResultNodeId?: Maybe<Scalars['ID']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationKpiResult?: Maybe<PerformanceReviewEvaluationKpiResult>;
  performanceReviewEvaluationKpiResultEdge?: Maybe<PerformanceReviewEvaluationKpiResultsEdge>;
  performanceReviewEvaluationKpiTarget: PerformanceReviewEvaluationKpiTarget;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewEvaluationKpiResultPayloadPerformanceReviewEvaluationKpiResultEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultsOrderBy>>;
};

export interface DeletePerformanceReviewEvaluationKpiTargetByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewEvaluationKpiTargetByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewEvaluationKpiTargetInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationKpiTargetId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewEvaluationKpiTargetPayload {
  __typename?: 'DeletePerformanceReviewEvaluationKpiTargetPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewEvaluationKpiTargetNodeId?: Maybe<Scalars['ID']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationKpiDescription: PerformanceReviewEvaluationKpiDescription;
  performanceReviewEvaluationKpiTarget?: Maybe<PerformanceReviewEvaluationKpiTarget>;
  performanceReviewEvaluationKpiTargetEdge?: Maybe<PerformanceReviewEvaluationKpiTargetsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewEvaluationKpiTargetPayloadPerformanceReviewEvaluationKpiTargetEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetsOrderBy>>;
};

export interface DeletePerformanceReviewEvaluationOneOnOneByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewEvaluationOneOnOneInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationOneOnOneId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewEvaluationOneOnOnePayload {
  __typename?: 'DeletePerformanceReviewEvaluationOneOnOnePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewEvaluationOneOnOneNodeId?: Maybe<Scalars['ID']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationOneOnOne?: Maybe<PerformanceReviewEvaluationOneOnOne>;
  performanceReviewEvaluationOneOnOneEdge?: Maybe<PerformanceReviewEvaluationOneOnOnesEdge>;
  performanceReviewMasterOneOnOne: PerformanceReviewMasterOneOnOne;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewEvaluationOneOnOnePayloadPerformanceReviewEvaluationOneOnOneEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOnesOrderBy>>;
};

export interface DeletePerformanceReviewEvaluationPayload {
  __typename?: 'DeletePerformanceReviewEvaluationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewEvaluationNodeId?: Maybe<Scalars['ID']['output']>;
  employee: Employee;
  employeeTpHistory?: Maybe<EmployeeTpHistory>;
  performanceReviewEvaluation?: Maybe<PerformanceReviewEvaluation>;
  performanceReviewEvaluationEdge?: Maybe<PerformanceReviewEvaluationsEdge>;
  performanceReviewMasterCompetencyRole?: Maybe<PerformanceReviewMasterCompetencyRole>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewEvaluationPayloadPerformanceReviewEvaluationEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationsOrderBy>>;
};

export interface DeletePerformanceReviewEvaluationQualitativeAssessmentByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewEvaluationQualitativeAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeIdInput {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationQualitativeDescriptionId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewEvaluationQualitativeAssessmentInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationQualitativeAssessmentId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewEvaluationQualitativeAssessmentPayload {
  __typename?: 'DeletePerformanceReviewEvaluationQualitativeAssessmentPayload';
  assessmentEmployee: Employee;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewEvaluationQualitativeAssessmentNodeId?: Maybe<Scalars['ID']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationQualitativeAssessment?: Maybe<PerformanceReviewEvaluationQualitativeAssessment>;
  performanceReviewEvaluationQualitativeAssessmentEdge?: Maybe<PerformanceReviewEvaluationQualitativeAssessmentsEdge>;
  performanceReviewEvaluationQualitativeDescription: PerformanceReviewEvaluationQualitativeDescription;
  performanceReviewMasterRatingByAssessmentValue?: Maybe<PerformanceReviewMasterRating>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewEvaluationQualitativeAssessmentPayloadPerformanceReviewEvaluationQualitativeAssessmentEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentsOrderBy>>;
};

export interface DeletePerformanceReviewEvaluationQualitativeDescriptionByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewEvaluationQualitativeDescriptionByPerformanceReviewEvaluationIdAndTitleInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  title: Scalars['String']['input'];
}

export interface DeletePerformanceReviewEvaluationQualitativeDescriptionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationQualitativeDescriptionId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewEvaluationQualitativeDescriptionPayload {
  __typename?: 'DeletePerformanceReviewEvaluationQualitativeDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewEvaluationQualitativeDescriptionNodeId?: Maybe<Scalars['ID']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationQualitativeDescription?: Maybe<PerformanceReviewEvaluationQualitativeDescription>;
  performanceReviewEvaluationQualitativeDescriptionEdge?: Maybe<PerformanceReviewEvaluationQualitativeDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewEvaluationQualitativeDescriptionPayloadPerformanceReviewEvaluationQualitativeDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionsOrderBy>>;
};

export interface DeletePerformanceReviewEvaluationValueByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewEvaluationValueByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeIdInput {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewEvaluationValueInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationValueId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewEvaluationValuePayload {
  __typename?: 'DeletePerformanceReviewEvaluationValuePayload';
  assessmentEmployee: Employee;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewEvaluationValueNodeId?: Maybe<Scalars['ID']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationValue?: Maybe<PerformanceReviewEvaluationValue>;
  performanceReviewEvaluationValueEdge?: Maybe<PerformanceReviewEvaluationValuesEdge>;
  performanceReviewMasterRatingByAssessmentValue?: Maybe<PerformanceReviewMasterRating>;
  performanceReviewMasterValue: PerformanceReviewMasterValue;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewEvaluationValuePayloadPerformanceReviewEvaluationValueEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationValuesOrderBy>>;
};

export interface DeletePerformanceReviewMasterCompetencyByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewMasterCompetencyCommonByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewMasterCompetencyCommonDescriptionByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewMasterCompetencyCommonDescriptionByPerformanceReviewMasterCompetencyCommonIdAndLanguageInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  language: Scalars['String']['input'];
  performanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewMasterCompetencyCommonDescriptionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyCommonDescriptionId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewMasterCompetencyCommonDescriptionPayload {
  __typename?: 'DeletePerformanceReviewMasterCompetencyCommonDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewMasterCompetencyCommonDescriptionNodeId?: Maybe<Scalars['ID']['output']>;
  performanceReviewMasterCompetencyCommon: PerformanceReviewMasterCompetencyCommon;
  performanceReviewMasterCompetencyCommonDescription?: Maybe<PerformanceReviewMasterCompetencyCommonDescription>;
  performanceReviewMasterCompetencyCommonDescriptionEdge?: Maybe<PerformanceReviewMasterCompetencyCommonDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewMasterCompetencyCommonDescriptionPayloadPerformanceReviewMasterCompetencyCommonDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionsOrderBy>>;
};

export interface DeletePerformanceReviewMasterCompetencyCommonInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewMasterCompetencyCommonPayload {
  __typename?: 'DeletePerformanceReviewMasterCompetencyCommonPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewMasterCompetencyCommonNodeId?: Maybe<Scalars['ID']['output']>;
  performanceReviewMasterCompetencyCommon?: Maybe<PerformanceReviewMasterCompetencyCommon>;
  performanceReviewMasterCompetencyCommonEdge?: Maybe<PerformanceReviewMasterCompetencyCommonsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewMasterCompetencyCommonPayloadPerformanceReviewMasterCompetencyCommonEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonsOrderBy>>;
};

export interface DeletePerformanceReviewMasterCompetencyDetailByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewMasterCompetencyDetailByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  overridingPerformanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
  performanceReviewMasterCompetencyRoleId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewMasterCompetencyDetailDescriptionByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewMasterCompetencyDetailDescriptionByPerformanceReviewMasterCompetencyDetailIdAndLanguageInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  language: Scalars['String']['input'];
  performanceReviewMasterCompetencyDetailId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewMasterCompetencyDetailDescriptionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyDetailDescriptionId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewMasterCompetencyDetailDescriptionPayload {
  __typename?: 'DeletePerformanceReviewMasterCompetencyDetailDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewMasterCompetencyDetailDescriptionNodeId?: Maybe<Scalars['ID']['output']>;
  performanceReviewMasterCompetencyDetail: PerformanceReviewMasterCompetencyDetail;
  performanceReviewMasterCompetencyDetailDescription?: Maybe<PerformanceReviewMasterCompetencyDetailDescription>;
  performanceReviewMasterCompetencyDetailDescriptionEdge?: Maybe<PerformanceReviewMasterCompetencyDetailDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewMasterCompetencyDetailDescriptionPayloadPerformanceReviewMasterCompetencyDetailDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionsOrderBy>>;
};

export interface DeletePerformanceReviewMasterCompetencyDetailInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyDetailId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewMasterCompetencyDetailPayload {
  __typename?: 'DeletePerformanceReviewMasterCompetencyDetailPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewMasterCompetencyDetailNodeId?: Maybe<Scalars['ID']['output']>;
  overridingPerformanceReviewMasterCompetencyCommon?: Maybe<PerformanceReviewMasterCompetencyCommon>;
  performanceReviewMasterCompetencyDetail?: Maybe<PerformanceReviewMasterCompetencyDetail>;
  performanceReviewMasterCompetencyDetailEdge?: Maybe<PerformanceReviewMasterCompetencyDetailsEdge>;
  performanceReviewMasterCompetencyRole: PerformanceReviewMasterCompetencyRole;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewMasterCompetencyDetailPayloadPerformanceReviewMasterCompetencyDetailEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailsOrderBy>>;
};

export interface DeletePerformanceReviewMasterCompetencyInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyId: Scalars['String']['input'];
}

export interface DeletePerformanceReviewMasterCompetencyPayload {
  __typename?: 'DeletePerformanceReviewMasterCompetencyPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewMasterCompetencyNodeId?: Maybe<Scalars['ID']['output']>;
  performanceReviewMasterCompetency?: Maybe<PerformanceReviewMasterCompetency>;
  performanceReviewMasterCompetencyCommon?: Maybe<PerformanceReviewMasterCompetencyCommon>;
  performanceReviewMasterCompetencyDetail?: Maybe<PerformanceReviewMasterCompetencyDetail>;
  performanceReviewMasterCompetencyEdge?: Maybe<PerformanceReviewMasterCompetenciesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewMasterCompetencyPayloadPerformanceReviewMasterCompetencyEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetenciesOrderBy>>;
};

export interface DeletePerformanceReviewMasterCompetencyRoleByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewMasterCompetencyRoleByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleNameInput {
  businessUnitId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  departmentId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
  quarter: PerformanceReviewQuarter;
  roleName: Scalars['String']['input'];
  year: Scalars['Int']['input'];
}

export interface DeletePerformanceReviewMasterCompetencyRoleInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoleId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewMasterCompetencyRolePayload {
  __typename?: 'DeletePerformanceReviewMasterCompetencyRolePayload';
  businessUnit: BusinessUnit;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewMasterCompetencyRoleNodeId?: Maybe<Scalars['ID']['output']>;
  department: Department;
  division: Division;
  performanceReviewMasterCompetencyRole?: Maybe<PerformanceReviewMasterCompetencyRole>;
  performanceReviewMasterCompetencyRoleEdge?: Maybe<PerformanceReviewMasterCompetencyRolesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewMasterCompetencyRolePayloadPerformanceReviewMasterCompetencyRoleEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyRolesOrderBy>>;
};

export interface DeletePerformanceReviewMasterDefaultByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewMasterDefaultInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterDefaultId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewMasterDefaultPayload {
  __typename?: 'DeletePerformanceReviewMasterDefaultPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewMasterDefaultNodeId?: Maybe<Scalars['ID']['output']>;
  performanceReviewMasterDefault?: Maybe<PerformanceReviewMasterDefault>;
  performanceReviewMasterDefaultEdge?: Maybe<PerformanceReviewMasterDefaultsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewMasterDefaultPayloadPerformanceReviewMasterDefaultEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterDefaultsOrderBy>>;
};

export interface DeletePerformanceReviewMasterEvaluationViewerByEmployeeIdAndYearAndQuarterAndViewerEmployeeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeId: Scalars['BigInt']['input'];
  quarter: PerformanceReviewQuarter;
  viewerEmployeeId: Scalars['BigInt']['input'];
  year: Scalars['Int']['input'];
}

export interface DeletePerformanceReviewMasterEvaluationViewerByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewMasterEvaluationViewerInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterEvaluationViewerId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewMasterEvaluationViewerPayload {
  __typename?: 'DeletePerformanceReviewMasterEvaluationViewerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewMasterEvaluationViewerNodeId?: Maybe<Scalars['ID']['output']>;
  employee: Employee;
  performanceReviewMasterEvaluationViewer?: Maybe<PerformanceReviewMasterEvaluationViewer>;
  performanceReviewMasterEvaluationViewerEdge?: Maybe<PerformanceReviewMasterEvaluationViewersEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  viewerEmployee: Employee;
}

export type DeletePerformanceReviewMasterEvaluationViewerPayloadPerformanceReviewMasterEvaluationViewerEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewersOrderBy>>;
};

export interface DeletePerformanceReviewMasterExcludedEmployeeByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewMasterExcludedEmployeeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeId: Scalars['BigInt']['input'];
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface DeletePerformanceReviewMasterExcludedEmployeePayload {
  __typename?: 'DeletePerformanceReviewMasterExcludedEmployeePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewMasterExcludedEmployeeNodeId?: Maybe<Scalars['ID']['output']>;
  employee: Employee;
  performanceReviewMasterExcludedEmployee?: Maybe<PerformanceReviewMasterExcludedEmployee>;
  performanceReviewMasterExcludedEmployeeEdge?: Maybe<PerformanceReviewMasterExcludedEmployeesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewMasterExcludedEmployeePayloadPerformanceReviewMasterExcludedEmployeeEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterExcludedEmployeesOrderBy>>;
};

export interface DeletePerformanceReviewMasterOneOnOneByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewMasterOneOnOneDetailByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewMasterOneOnOneDetailInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterOneOnOneDetailId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewMasterOneOnOneDetailPayload {
  __typename?: 'DeletePerformanceReviewMasterOneOnOneDetailPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewMasterOneOnOneDetailNodeId?: Maybe<Scalars['ID']['output']>;
  performanceReviewMasterOneOnOne: PerformanceReviewMasterOneOnOne;
  performanceReviewMasterOneOnOneDetail?: Maybe<PerformanceReviewMasterOneOnOneDetail>;
  performanceReviewMasterOneOnOneDetailEdge?: Maybe<PerformanceReviewMasterOneOnOneDetailsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewMasterOneOnOneDetailPayloadPerformanceReviewMasterOneOnOneDetailEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterOneOnOneDetailsOrderBy>>;
};

export interface DeletePerformanceReviewMasterOneOnOneInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterOneOnOneId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewMasterOneOnOnePayload {
  __typename?: 'DeletePerformanceReviewMasterOneOnOnePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewMasterOneOnOneNodeId?: Maybe<Scalars['ID']['output']>;
  performanceReviewMasterOneOnOne?: Maybe<PerformanceReviewMasterOneOnOne>;
  performanceReviewMasterOneOnOneEdge?: Maybe<PerformanceReviewMasterOneOnOnesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewMasterOneOnOnePayloadPerformanceReviewMasterOneOnOneEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterOneOnOnesOrderBy>>;
};

export interface DeletePerformanceReviewMasterRatingByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewMasterRatingDescriptionByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewMasterRatingDescriptionByPerformanceReviewMasterRatingIdAndLanguageInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  language: Scalars['String']['input'];
  performanceReviewMasterRatingId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewMasterRatingDescriptionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterRatingDescriptionId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewMasterRatingDescriptionPayload {
  __typename?: 'DeletePerformanceReviewMasterRatingDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewMasterRatingDescriptionNodeId?: Maybe<Scalars['ID']['output']>;
  performanceReviewMasterRating: PerformanceReviewMasterRating;
  performanceReviewMasterRatingDescription?: Maybe<PerformanceReviewMasterRatingDescription>;
  performanceReviewMasterRatingDescriptionEdge?: Maybe<PerformanceReviewMasterRatingDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewMasterRatingDescriptionPayloadPerformanceReviewMasterRatingDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionsOrderBy>>;
};

export interface DeletePerformanceReviewMasterRatingInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterRatingId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewMasterRatingPayload {
  __typename?: 'DeletePerformanceReviewMasterRatingPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewMasterRatingNodeId?: Maybe<Scalars['ID']['output']>;
  performanceReviewMasterRating?: Maybe<PerformanceReviewMasterRating>;
  performanceReviewMasterRatingEdge?: Maybe<PerformanceReviewMasterRatingsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewMasterRatingPayloadPerformanceReviewMasterRatingEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterRatingsOrderBy>>;
};

export interface DeletePerformanceReviewMasterUsageByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewMasterUsageByYearAndQuarterAndCompanyIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['BigInt']['input'];
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface DeletePerformanceReviewMasterUsageInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterUsageId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewMasterUsagePayload {
  __typename?: 'DeletePerformanceReviewMasterUsagePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  deletedPerformanceReviewMasterUsageNodeId?: Maybe<Scalars['ID']['output']>;
  performanceReviewMasterUsage?: Maybe<PerformanceReviewMasterUsage>;
  performanceReviewMasterUsageEdge?: Maybe<PerformanceReviewMasterUsagesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewMasterUsagePayloadPerformanceReviewMasterUsageEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterUsagesOrderBy>>;
};

export interface DeletePerformanceReviewMasterValueByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewMasterValueDescriptionByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewMasterValueDescriptionByPerformanceReviewMasterValueIdAndLanguageInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  language: Scalars['String']['input'];
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewMasterValueDescriptionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterValueDescriptionId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewMasterValueDescriptionPayload {
  __typename?: 'DeletePerformanceReviewMasterValueDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewMasterValueDescriptionNodeId?: Maybe<Scalars['ID']['output']>;
  performanceReviewMasterValue: PerformanceReviewMasterValue;
  performanceReviewMasterValueDescription?: Maybe<PerformanceReviewMasterValueDescription>;
  performanceReviewMasterValueDescriptionEdge?: Maybe<PerformanceReviewMasterValueDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewMasterValueDescriptionPayloadPerformanceReviewMasterValueDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionsOrderBy>>;
};

export interface DeletePerformanceReviewMasterValueInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewMasterValuePayload {
  __typename?: 'DeletePerformanceReviewMasterValuePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewMasterValueNodeId?: Maybe<Scalars['ID']['output']>;
  performanceReviewMasterValue?: Maybe<PerformanceReviewMasterValue>;
  performanceReviewMasterValueEdge?: Maybe<PerformanceReviewMasterValuesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewMasterValuePayloadPerformanceReviewMasterValueEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterValuesOrderBy>>;
};

export interface DeletePerformanceReviewMasterWeightingByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewMasterWeightingByYearAndQuarterAndCompanyIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['BigInt']['input'];
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface DeletePerformanceReviewMasterWeightingByYearAndQuarterAndNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface DeletePerformanceReviewMasterWeightingDetailByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeletePerformanceReviewMasterWeightingDetailByPerformanceReviewMasterWeightingIdAndEmployeeLevelIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeLevelId: Scalars['BigInt']['input'];
  performanceReviewMasterWeightingId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewMasterWeightingDetailInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterWeightingDetailId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewMasterWeightingDetailPayload {
  __typename?: 'DeletePerformanceReviewMasterWeightingDetailPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPerformanceReviewMasterWeightingDetailNodeId?: Maybe<Scalars['ID']['output']>;
  employeeLevel: EmployeeLevel;
  performanceReviewMasterWeighting: PerformanceReviewMasterWeighting;
  performanceReviewMasterWeightingDetail?: Maybe<PerformanceReviewMasterWeightingDetail>;
  performanceReviewMasterWeightingDetailEdge?: Maybe<PerformanceReviewMasterWeightingDetailsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewMasterWeightingDetailPayloadPerformanceReviewMasterWeightingDetailEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailsOrderBy>>;
};

export interface DeletePerformanceReviewMasterWeightingInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterWeightingId: Scalars['BigInt']['input'];
}

export interface DeletePerformanceReviewMasterWeightingPayload {
  __typename?: 'DeletePerformanceReviewMasterWeightingPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Company>;
  deletedPerformanceReviewMasterWeightingNodeId?: Maybe<Scalars['ID']['output']>;
  performanceReviewMasterWeighting?: Maybe<PerformanceReviewMasterWeighting>;
  performanceReviewMasterWeightingEdge?: Maybe<PerformanceReviewMasterWeightingsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeletePerformanceReviewMasterWeightingPayloadPerformanceReviewMasterWeightingEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterWeightingsOrderBy>>;
};

export interface DeleteProbationReviewEnableByCompanyIdAndContractTypeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['BigInt']['input'];
  contractTypeId: Scalars['BigInt']['input'];
}

export interface DeleteProbationReviewEnableByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteProbationReviewEnableInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  probationReviewEnableId: Scalars['BigInt']['input'];
}

export interface DeleteProbationReviewEnablePayload {
  __typename?: 'DeleteProbationReviewEnablePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  contractType: ContractType;
  deletedProbationReviewEnableNodeId?: Maybe<Scalars['ID']['output']>;
  probationReviewEnable?: Maybe<ProbationReviewEnable>;
  probationReviewEnableEdge?: Maybe<ProbationReviewEnablesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteProbationReviewEnablePayloadProbationReviewEnableEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewEnablesOrderBy>>;
};

export interface DeleteProbationReviewEvaluationByEmployeeIdAndReviewDatePlannedInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeId: Scalars['BigInt']['input'];
  reviewDatePlanned: Scalars['Date']['input'];
}

export interface DeleteProbationReviewEvaluationByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteProbationReviewEvaluationInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  probationReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface DeleteProbationReviewEvaluationPayload {
  __typename?: 'DeleteProbationReviewEvaluationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedProbationReviewEvaluationNodeId?: Maybe<Scalars['ID']['output']>;
  employee: Employee;
  probationReviewEvaluation?: Maybe<ProbationReviewEvaluation>;
  probationReviewEvaluationEdge?: Maybe<ProbationReviewEvaluationsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteProbationReviewEvaluationPayloadProbationReviewEvaluationEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationsOrderBy>>;
};

export interface DeleteProbationReviewEvaluationResultItemByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteProbationReviewEvaluationResultItemByProbationReviewEvaluationIdAndProbationReviewMasterItemIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface DeleteProbationReviewEvaluationResultItemInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  probationReviewEvaluationResultItemId: Scalars['BigInt']['input'];
}

export interface DeleteProbationReviewEvaluationResultItemPayload {
  __typename?: 'DeleteProbationReviewEvaluationResultItemPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedProbationReviewEvaluationResultItemNodeId?: Maybe<Scalars['ID']['output']>;
  probationReviewEvaluation: ProbationReviewEvaluation;
  probationReviewEvaluationResultItem?: Maybe<ProbationReviewEvaluationResultItem>;
  probationReviewEvaluationResultItemEdge?: Maybe<ProbationReviewEvaluationResultItemsEdge>;
  probationReviewMasterItem: ProbationReviewMasterItem;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteProbationReviewEvaluationResultItemPayloadProbationReviewEvaluationResultItemEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultItemsOrderBy>>;
};

export interface DeleteProbationReviewEvaluationResultScoreCompetencyByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteProbationReviewEvaluationResultScoreCompetencyByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyId: Scalars['BigInt']['input'];
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface DeleteProbationReviewEvaluationResultScoreCompetencyInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  probationReviewEvaluationResultScoreCompetencyId: Scalars['BigInt']['input'];
}

export interface DeleteProbationReviewEvaluationResultScoreCompetencyPayload {
  __typename?: 'DeleteProbationReviewEvaluationResultScoreCompetencyPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedProbationReviewEvaluationResultScoreCompetencyNodeId?: Maybe<Scalars['ID']['output']>;
  performanceReviewMasterCompetency: PerformanceReviewMasterCompetencyCommon;
  performanceReviewMasterRatingByAssessment?: Maybe<PerformanceReviewMasterRating>;
  probationReviewEvaluation: ProbationReviewEvaluation;
  probationReviewEvaluationResultScoreCompetency?: Maybe<ProbationReviewEvaluationResultScoreCompetency>;
  probationReviewEvaluationResultScoreCompetencyEdge?: Maybe<ProbationReviewEvaluationResultScoreCompetenciesEdge>;
  probationReviewMasterItem: ProbationReviewMasterItem;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteProbationReviewEvaluationResultScoreCompetencyPayloadProbationReviewEvaluationResultScoreCompetencyEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetenciesOrderBy>>;
};

export interface DeleteProbationReviewEvaluationResultScoreValueByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteProbationReviewEvaluationResultScoreValueByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface DeleteProbationReviewEvaluationResultScoreValueInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  probationReviewEvaluationResultScoreValueId: Scalars['BigInt']['input'];
}

export interface DeleteProbationReviewEvaluationResultScoreValuePayload {
  __typename?: 'DeleteProbationReviewEvaluationResultScoreValuePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedProbationReviewEvaluationResultScoreValueNodeId?: Maybe<Scalars['ID']['output']>;
  performanceReviewMasterRatingByAssessment?: Maybe<PerformanceReviewMasterRating>;
  performanceReviewMasterValue: PerformanceReviewMasterValue;
  probationReviewEvaluation: ProbationReviewEvaluation;
  probationReviewEvaluationResultScoreValue?: Maybe<ProbationReviewEvaluationResultScoreValue>;
  probationReviewEvaluationResultScoreValueEdge?: Maybe<ProbationReviewEvaluationResultScoreValuesEdge>;
  probationReviewMasterItem: ProbationReviewMasterItem;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteProbationReviewEvaluationResultScoreValuePayloadProbationReviewEvaluationResultScoreValueEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValuesOrderBy>>;
};

export interface DeleteProbationReviewEvaluationReviewerByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteProbationReviewEvaluationReviewerInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  reviewerEmployeeId: Scalars['BigInt']['input'];
}

export interface DeleteProbationReviewEvaluationReviewerPayload {
  __typename?: 'DeleteProbationReviewEvaluationReviewerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedProbationReviewEvaluationReviewerNodeId?: Maybe<Scalars['ID']['output']>;
  probationReviewEvaluation: ProbationReviewEvaluation;
  probationReviewEvaluationReviewer?: Maybe<ProbationReviewEvaluationReviewer>;
  probationReviewEvaluationReviewerEdge?: Maybe<ProbationReviewEvaluationReviewersEdge>;
  query?: Maybe<Query>;
  reviewerEmployee: Employee;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteProbationReviewEvaluationReviewerPayloadProbationReviewEvaluationReviewerEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationReviewersOrderBy>>;
};

export interface DeleteProbationReviewMasterItemByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteProbationReviewMasterItemByYearAndQuarterAndTitleAndInputUserInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  inputUser: ProbationReviewInputUser;
  quarter: PerformanceReviewQuarter;
  title: Scalars['String']['input'];
  year: Scalars['Int']['input'];
}

export interface DeleteProbationReviewMasterItemDescriptionByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteProbationReviewMasterItemDescriptionByProbationReviewMasterItemIdAndLanguageInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  language: Scalars['String']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface DeleteProbationReviewMasterItemDescriptionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  probationReviewMasterItemDescriptionId: Scalars['BigInt']['input'];
}

export interface DeleteProbationReviewMasterItemDescriptionPayload {
  __typename?: 'DeleteProbationReviewMasterItemDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedProbationReviewMasterItemDescriptionNodeId?: Maybe<Scalars['ID']['output']>;
  probationReviewMasterItem: ProbationReviewMasterItem;
  probationReviewMasterItemDescription?: Maybe<ProbationReviewMasterItemDescription>;
  probationReviewMasterItemDescriptionEdge?: Maybe<ProbationReviewMasterItemDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteProbationReviewMasterItemDescriptionPayloadProbationReviewMasterItemDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewMasterItemDescriptionsOrderBy>>;
};

export interface DeleteProbationReviewMasterItemInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface DeleteProbationReviewMasterItemPayload {
  __typename?: 'DeleteProbationReviewMasterItemPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedProbationReviewMasterItemNodeId?: Maybe<Scalars['ID']['output']>;
  probationReviewMasterItem?: Maybe<ProbationReviewMasterItem>;
  probationReviewMasterItemEdge?: Maybe<ProbationReviewMasterItemsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteProbationReviewMasterItemPayloadProbationReviewMasterItemEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewMasterItemsOrderBy>>;
};

export interface DeleteRelationBusinessUnitDivisionByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteRelationBusinessUnitDivisionDepartmentByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteRelationBusinessUnitDivisionDepartmentInput {
  businessUnitId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  departmentId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
}

export interface DeleteRelationBusinessUnitDivisionDepartmentPayload {
  __typename?: 'DeleteRelationBusinessUnitDivisionDepartmentPayload';
  businessUnit: BusinessUnit;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedRelationBusinessUnitDivisionDepartmentNodeId?: Maybe<Scalars['ID']['output']>;
  department: Department;
  division: Division;
  query?: Maybe<Query>;
  relationBusinessUnitDivisionDepartment?: Maybe<RelationBusinessUnitDivisionDepartment>;
  relationBusinessUnitDivisionDepartmentEdge?: Maybe<RelationBusinessUnitDivisionDepartmentsEdge>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteRelationBusinessUnitDivisionDepartmentPayloadRelationBusinessUnitDivisionDepartmentEdgeArgs = {
  orderBy?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentsOrderBy>>;
};

export interface DeleteRelationBusinessUnitDivisionInput {
  businessUnitId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  divisionId: Scalars['BigInt']['input'];
}

export interface DeleteRelationBusinessUnitDivisionPayload {
  __typename?: 'DeleteRelationBusinessUnitDivisionPayload';
  businessUnit: BusinessUnit;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedRelationBusinessUnitDivisionNodeId?: Maybe<Scalars['ID']['output']>;
  division: Division;
  query?: Maybe<Query>;
  relationBusinessUnitDivision?: Maybe<RelationBusinessUnitDivision>;
  relationBusinessUnitDivisionEdge?: Maybe<RelationBusinessUnitDivisionsEdge>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteRelationBusinessUnitDivisionPayloadRelationBusinessUnitDivisionEdgeArgs = {
  orderBy?: InputMaybe<Array<RelationBusinessUnitDivisionsOrderBy>>;
};

export interface DeleteRelationCityCompanyByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteRelationCityCompanyInput {
  cityId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['BigInt']['input'];
}

export interface DeleteRelationCityCompanyPayload {
  __typename?: 'DeleteRelationCityCompanyPayload';
  city: City;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  deletedRelationCityCompanyNodeId?: Maybe<Scalars['ID']['output']>;
  query?: Maybe<Query>;
  relationCityCompany?: Maybe<RelationCityCompany>;
  relationCityCompanyEdge?: Maybe<RelationCityCompaniesEdge>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteRelationCityCompanyPayloadRelationCityCompanyEdgeArgs = {
  orderBy?: InputMaybe<Array<RelationCityCompaniesOrderBy>>;
};

export interface DeleteRelationCompanyBusinessUnitByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteRelationCompanyBusinessUnitInput {
  businessUnitId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['BigInt']['input'];
}

export interface DeleteRelationCompanyBusinessUnitPayload {
  __typename?: 'DeleteRelationCompanyBusinessUnitPayload';
  businessUnit: BusinessUnit;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  deletedRelationCompanyBusinessUnitNodeId?: Maybe<Scalars['ID']['output']>;
  query?: Maybe<Query>;
  relationCompanyBusinessUnit?: Maybe<RelationCompanyBusinessUnit>;
  relationCompanyBusinessUnitEdge?: Maybe<RelationCompanyBusinessUnitsEdge>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteRelationCompanyBusinessUnitPayloadRelationCompanyBusinessUnitEdgeArgs = {
  orderBy?: InputMaybe<Array<RelationCompanyBusinessUnitsOrderBy>>;
};

export interface DeleteRelationCompanyGroupEmailDomainByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteRelationCompanyGroupEmailDomainInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyGroupId: Scalars['BigInt']['input'];
  emailDomainId: Scalars['BigInt']['input'];
}

export interface DeleteRelationCompanyGroupEmailDomainPayload {
  __typename?: 'DeleteRelationCompanyGroupEmailDomainPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  companyGroup: CompanyGroup;
  deletedRelationCompanyGroupEmailDomainNodeId?: Maybe<Scalars['ID']['output']>;
  emailDomain: EmailDomain;
  query?: Maybe<Query>;
  relationCompanyGroupEmailDomain?: Maybe<RelationCompanyGroupEmailDomain>;
  relationCompanyGroupEmailDomainEdge?: Maybe<RelationCompanyGroupEmailDomainsEdge>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteRelationCompanyGroupEmailDomainPayloadRelationCompanyGroupEmailDomainEdgeArgs = {
  orderBy?: InputMaybe<Array<RelationCompanyGroupEmailDomainsOrderBy>>;
};

export interface DeleteRelationCompanyOfficeByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteRelationCompanyOfficeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['BigInt']['input'];
  officeId: Scalars['BigInt']['input'];
}

export interface DeleteRelationCompanyOfficePayload {
  __typename?: 'DeleteRelationCompanyOfficePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  deletedRelationCompanyOfficeNodeId?: Maybe<Scalars['ID']['output']>;
  office: Office;
  query?: Maybe<Query>;
  relationCompanyOffice?: Maybe<RelationCompanyOffice>;
  relationCompanyOfficeEdge?: Maybe<RelationCompanyOfficesEdge>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteRelationCompanyOfficePayloadRelationCompanyOfficeEdgeArgs = {
  orderBy?: InputMaybe<Array<RelationCompanyOfficesOrderBy>>;
};

export interface DeleteRelationCurrencyCountryByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteRelationCurrencyCountryInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha2: Scalars['String']['input'];
  currencyCode: Scalars['String']['input'];
}

export interface DeleteRelationCurrencyCountryPayload {
  __typename?: 'DeleteRelationCurrencyCountryPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  countryByCountryCodeAlpha2: Country;
  currencyByCurrencyCode: Currency;
  deletedRelationCurrencyCountryNodeId?: Maybe<Scalars['ID']['output']>;
  query?: Maybe<Query>;
  relationCurrencyCountry?: Maybe<RelationCurrencyCountry>;
  relationCurrencyCountryEdge?: Maybe<RelationCurrencyCountriesEdge>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteRelationCurrencyCountryPayloadRelationCurrencyCountryEdgeArgs = {
  orderBy?: InputMaybe<Array<RelationCurrencyCountriesOrderBy>>;
};

export interface DeleteRelationEmployeeUserAccountByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteRelationEmployeeUserAccountInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userAccountId: Scalars['BigInt']['input'];
}

export interface DeleteRelationEmployeeUserAccountPayload {
  __typename?: 'DeleteRelationEmployeeUserAccountPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedRelationEmployeeUserAccountNodeId?: Maybe<Scalars['ID']['output']>;
  employee: Employee;
  query?: Maybe<Query>;
  relationEmployeeUserAccount?: Maybe<RelationEmployeeUserAccount>;
  relationEmployeeUserAccountEdge?: Maybe<RelationEmployeeUserAccountsEdge>;
  userAccount: UserAccount;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type DeleteRelationEmployeeUserAccountPayloadRelationEmployeeUserAccountEdgeArgs = {
  orderBy?: InputMaybe<Array<RelationEmployeeUserAccountsOrderBy>>;
};

export interface DeleteUserAccountAccessHistoryByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteUserAccountAccessHistoryInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistoryId: Scalars['BigInt']['input'];
}

export interface DeleteUserAccountAccessHistoryPayload {
  __typename?: 'DeleteUserAccountAccessHistoryPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedUserAccountAccessHistoryNodeId?: Maybe<Scalars['ID']['output']>;
  query?: Maybe<Query>;
  userAccount: UserAccount;
  userAccountAccessHistory?: Maybe<UserAccountAccessHistory>;
  userAccountAccessHistoryEdge?: Maybe<UserAccountAccessHistoriesEdge>;
}

export type DeleteUserAccountAccessHistoryPayloadUserAccountAccessHistoryEdgeArgs = {
  orderBy?: InputMaybe<Array<UserAccountAccessHistoriesOrderBy>>;
};

export interface DeleteUserAccountByEmailAddressInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  emailAddress: Scalars['String']['input'];
}

export interface DeleteUserAccountByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteUserAccountInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userAccountId: Scalars['BigInt']['input'];
}

export interface DeleteUserAccountPayload {
  __typename?: 'DeleteUserAccountPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedUserAccountNodeId?: Maybe<Scalars['ID']['output']>;
  query?: Maybe<Query>;
  userAccount?: Maybe<UserAccount>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userAccountEdge?: Maybe<UserAccountsEdge>;
  userRoleByRole: UserRole;
}

export type DeleteUserAccountPayloadUserAccountEdgeArgs = {
  orderBy?: InputMaybe<Array<UserAccountsOrderBy>>;
};

export interface DeleteUserAccountPinByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteUserAccountPinInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ref: Scalars['String']['input'];
  userAccountId: Scalars['BigInt']['input'];
}

export interface DeleteUserAccountPinPayload {
  __typename?: 'DeleteUserAccountPinPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedUserAccountPinNodeId?: Maybe<Scalars['ID']['output']>;
  query?: Maybe<Query>;
  userAccount: UserAccount;
  userAccountPin?: Maybe<UserAccountPin>;
  userAccountPinEdge?: Maybe<UserAccountPinsEdge>;
}

export type DeleteUserAccountPinPayloadUserAccountPinEdgeArgs = {
  orderBy?: InputMaybe<Array<UserAccountPinsOrderBy>>;
};

export interface DeleteUserHrManagingCompanyByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteUserHrManagingCompanyByUserAccountIdAndCompanyIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['BigInt']['input'];
  userAccountId: Scalars['BigInt']['input'];
}

export interface DeleteUserHrManagingCompanyInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userHrManagingCompanyId: Scalars['BigInt']['input'];
}

export interface DeleteUserHrManagingCompanyPayload {
  __typename?: 'DeleteUserHrManagingCompanyPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  deletedUserHrManagingCompanyNodeId?: Maybe<Scalars['ID']['output']>;
  query?: Maybe<Query>;
  userAccount: UserAccount;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userHrManagingCompany?: Maybe<UserHrManagingCompany>;
  userHrManagingCompanyEdge?: Maybe<UserHrManagingCompaniesEdge>;
}

export type DeleteUserHrManagingCompanyPayloadUserHrManagingCompanyEdgeArgs = {
  orderBy?: InputMaybe<Array<UserHrManagingCompaniesOrderBy>>;
};

export interface DeleteWorldPlaceByDisplayOrderInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  displayOrder: Scalars['Int']['input'];
}

export interface DeleteWorldPlaceByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
}

export interface DeleteWorldPlaceInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  worldPlaceId: Scalars['BigInt']['input'];
}

export interface DeleteWorldPlacePayload {
  __typename?: 'DeleteWorldPlacePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedWorldPlaceNodeId?: Maybe<Scalars['ID']['output']>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  worldPlace?: Maybe<WorldPlace>;
  worldPlaceEdge?: Maybe<WorldPlacesEdge>;
}

export type DeleteWorldPlacePayloadWorldPlaceEdgeArgs = {
  orderBy?: InputMaybe<Array<WorldPlacesOrderBy>>;
};

export interface Department extends Node {
  __typename?: 'Department';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  departmentId: Scalars['BigInt']['output'];
  employeeCurrentPositions: EmployeeCurrentPositionsConnection;
  employeeTpHistories: EmployeeTpHistoriesConnection;
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  performanceReviewMasterCompetencyRoles: PerformanceReviewMasterCompetencyRolesConnection;
  relationBusinessUnitDivisionDepartments: RelationBusinessUnitDivisionDepartmentsConnection;
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export type DepartmentEmployeeCurrentPositionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCurrentPositionCondition>;
  filter?: InputMaybe<EmployeeCurrentPositionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeCurrentPositionsOrderBy>>;
};

export type DepartmentEmployeeTpHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeTpHistoryCondition>;
  filter?: InputMaybe<EmployeeTpHistoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeTpHistoriesOrderBy>>;
};

export type DepartmentPerformanceReviewMasterCompetencyRolesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyRoleCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyRoleFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyRolesOrderBy>>;
};

export type DepartmentRelationBusinessUnitDivisionDepartmentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationBusinessUnitDivisionDepartmentCondition>;
  filter?: InputMaybe<RelationBusinessUnitDivisionDepartmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentsOrderBy>>;
};

export interface DepartmentCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface DepartmentDepartmentNameKeyConnect {
  name: Scalars['String']['input'];
}

export interface DepartmentDepartmentNameKeyDelete {
  name: Scalars['String']['input'];
}

export interface DepartmentDepartmentPkeyConnect {
  departmentId: Scalars['BigInt']['input'];
}

export interface DepartmentDepartmentPkeyDelete {
  departmentId: Scalars['BigInt']['input'];
}

export interface DepartmentFilter {
  and?: InputMaybe<Array<DepartmentFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  departmentId?: InputMaybe<BigIntFilter>;
  employeeCurrentPositions?: InputMaybe<DepartmentToManyEmployeeCurrentPositionFilter>;
  employeeCurrentPositionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeTpHistories?: InputMaybe<DepartmentToManyEmployeeTpHistoryFilter>;
  employeeTpHistoriesExist?: InputMaybe<Scalars['Boolean']['input']>;
  isActive?: InputMaybe<BooleanFilter>;
  name?: InputMaybe<StringFilter>;
  not?: InputMaybe<DepartmentFilter>;
  or?: InputMaybe<Array<DepartmentFilter>>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<DepartmentToManyPerformanceReviewMasterCompetencyRoleFilter>;
  performanceReviewMasterCompetencyRolesExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<DepartmentToManyRelationBusinessUnitDivisionDepartmentFilter>;
  relationBusinessUnitDivisionDepartmentsExist?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface DepartmentInput {
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey10InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DepartmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface DepartmentNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface DepartmentNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface DepartmentOnDepartmentForDepartmentUserCreatedFkeyUsingDepartmentNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateDepartmentOnDepartmentForDepartmentUserCreatedFkeyPatch;
}

export interface DepartmentOnDepartmentForDepartmentUserCreatedFkeyUsingDepartmentPkeyUpdate {
  departmentId: Scalars['BigInt']['input'];
  patch: UpdateDepartmentOnDepartmentForDepartmentUserCreatedFkeyPatch;
}

export interface DepartmentOnDepartmentForDepartmentUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface DepartmentOnDepartmentForDepartmentUserUpdatedFkeyUsingDepartmentNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateDepartmentOnDepartmentForDepartmentUserUpdatedFkeyPatch;
}

export interface DepartmentOnDepartmentForDepartmentUserUpdatedFkeyUsingDepartmentPkeyUpdate {
  departmentId: Scalars['BigInt']['input'];
  patch: UpdateDepartmentOnDepartmentForDepartmentUserUpdatedFkeyPatch;
}

export interface DepartmentOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey10NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeCurrentPositionPatch;
}

export interface DepartmentOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey10UsingDepartmentNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateDepartmentOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey10Patch;
}

export interface DepartmentOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey10UsingDepartmentPkeyUpdate {
  departmentId: Scalars['BigInt']['input'];
  patch: UpdateDepartmentOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey10Patch;
}

export interface DepartmentOnEmployeeTpHistoryForEmployeeTpHistoryDepartmentIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeTpHistoryPatch;
}

export interface DepartmentOnEmployeeTpHistoryForEmployeeTpHistoryDepartmentIdFkeyUsingDepartmentNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateDepartmentOnEmployeeTpHistoryForEmployeeTpHistoryDepartmentIdFkeyPatch;
}

export interface DepartmentOnEmployeeTpHistoryForEmployeeTpHistoryDepartmentIdFkeyUsingDepartmentPkeyUpdate {
  departmentId: Scalars['BigInt']['input'];
  patch: UpdateDepartmentOnEmployeeTpHistoryForEmployeeTpHistoryDepartmentIdFkeyPatch;
}

export interface DepartmentOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDepartmentIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyRolePatch;
}

export interface DepartmentOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDepartmentIdFkeyUsingDepartmentNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateDepartmentOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDepartmentIdFkeyPatch;
}

export interface DepartmentOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDepartmentIdFkeyUsingDepartmentPkeyUpdate {
  departmentId: Scalars['BigInt']['input'];
  patch: UpdateDepartmentOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDepartmentIdFkeyPatch;
}

export interface DepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDepartmentIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationBusinessUnitDivisionDepartmentPatch;
}

export interface DepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDepartmentIdFkeyUsingDepartmentNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDepartmentIdFkeyPatch;
}

export interface DepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDepartmentIdFkeyUsingDepartmentPkeyUpdate {
  departmentId: Scalars['BigInt']['input'];
  patch: UpdateDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDepartmentIdFkeyPatch;
}

export interface DepartmentPatch {
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey10InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DepartmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface DepartmentToManyEmployeeCurrentPositionFilter {
  every?: InputMaybe<EmployeeCurrentPositionFilter>;
  none?: InputMaybe<EmployeeCurrentPositionFilter>;
  some?: InputMaybe<EmployeeCurrentPositionFilter>;
}

export interface DepartmentToManyEmployeeTpHistoryFilter {
  every?: InputMaybe<EmployeeTpHistoryFilter>;
  none?: InputMaybe<EmployeeTpHistoryFilter>;
  some?: InputMaybe<EmployeeTpHistoryFilter>;
}

export interface DepartmentToManyPerformanceReviewMasterCompetencyRoleFilter {
  every?: InputMaybe<PerformanceReviewMasterCompetencyRoleFilter>;
  none?: InputMaybe<PerformanceReviewMasterCompetencyRoleFilter>;
  some?: InputMaybe<PerformanceReviewMasterCompetencyRoleFilter>;
}

export interface DepartmentToManyRelationBusinessUnitDivisionDepartmentFilter {
  aggregates?: InputMaybe<RelationBusinessUnitDivisionDepartmentAggregatesFilter>;
  every?: InputMaybe<RelationBusinessUnitDivisionDepartmentFilter>;
  none?: InputMaybe<RelationBusinessUnitDivisionDepartmentFilter>;
  some?: InputMaybe<RelationBusinessUnitDivisionDepartmentFilter>;
}

export interface DepartmentUserCreatedFkeyInverseInput {
  connectByDepartmentId?: InputMaybe<Array<DepartmentDepartmentPkeyConnect>>;
  connectByName?: InputMaybe<Array<DepartmentDepartmentNameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<DepartmentNodeIdConnect>>;
  deleteByDepartmentId?: InputMaybe<Array<DepartmentDepartmentPkeyDelete>>;
  deleteByName?: InputMaybe<Array<DepartmentDepartmentNameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<DepartmentNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByDepartmentId?: InputMaybe<Array<DepartmentOnDepartmentForDepartmentUserCreatedFkeyUsingDepartmentPkeyUpdate>>;
  updateByName?: InputMaybe<Array<DepartmentOnDepartmentForDepartmentUserCreatedFkeyUsingDepartmentNameKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnDepartmentForDepartmentUserCreatedFkeyNodeIdUpdate>>;
}

export interface DepartmentUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnDepartmentForDepartmentUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<DepartmentOnDepartmentForDepartmentUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnDepartmentForDepartmentUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface DepartmentUserUpdatedFkeyInverseInput {
  connectByDepartmentId?: InputMaybe<Array<DepartmentDepartmentPkeyConnect>>;
  connectByName?: InputMaybe<Array<DepartmentDepartmentNameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<DepartmentNodeIdConnect>>;
  deleteByDepartmentId?: InputMaybe<Array<DepartmentDepartmentPkeyDelete>>;
  deleteByName?: InputMaybe<Array<DepartmentDepartmentNameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<DepartmentNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByDepartmentId?: InputMaybe<Array<DepartmentOnDepartmentForDepartmentUserUpdatedFkeyUsingDepartmentPkeyUpdate>>;
  updateByName?: InputMaybe<Array<DepartmentOnDepartmentForDepartmentUserUpdatedFkeyUsingDepartmentNameKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnDepartmentForDepartmentUserUpdatedFkeyNodeIdUpdate>>;
}

export interface DepartmentsConnection {
  __typename?: 'DepartmentsConnection';
  edges: Array<DepartmentsEdge>;
  nodes: Array<Department>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface DepartmentsEdge {
  __typename?: 'DepartmentsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: Department;
}

export enum DepartmentsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DEPARTMENT_ID_ASC = 'DEPARTMENT_ID_ASC',
  DEPARTMENT_ID_DESC = 'DEPARTMENT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__COUNT_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__COUNT_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__COUNT_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__COUNT_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_CITY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_CITY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_CITY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_CITY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_COMPANY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_COMPANY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_COMPANY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_COMPANY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_DEPARTMENT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_DEPARTMENT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_DEPARTMENT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_DEPARTMENT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_DIVISION_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_DIVISION_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_DIVISION_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_DIVISION_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_EFFECTIVE_DATE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_EFFECTIVE_DATE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_EFFECTIVE_DATE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_EFFECTIVE_DATE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_EMPLOYEE_CODE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_EMPLOYEE_CODE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_EMPLOYEE_CODE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_EMPLOYEE_CODE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_JOB_TITLE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_JOB_TITLE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_JOB_TITLE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_JOB_TITLE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_OFFICE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_OFFICE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_OFFICE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_OFFICE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_TEAM_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_TEAM_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_TEAM_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MAX_TEAM_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_CITY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_CITY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_CITY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_CITY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_COMPANY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_COMPANY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_COMPANY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_COMPANY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_DEPARTMENT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_DEPARTMENT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_DEPARTMENT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_DEPARTMENT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_DIVISION_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_DIVISION_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_DIVISION_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_DIVISION_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_EFFECTIVE_DATE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_EFFECTIVE_DATE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_EFFECTIVE_DATE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_EFFECTIVE_DATE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_EMPLOYEE_CODE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_EMPLOYEE_CODE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_EMPLOYEE_CODE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_EMPLOYEE_CODE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_JOB_TITLE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_JOB_TITLE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_JOB_TITLE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_JOB_TITLE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_OFFICE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_OFFICE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_OFFICE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_OFFICE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_TEAM_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_TEAM_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_TEAM_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DEPARTMENT_ID__MIN_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__COUNT_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__COUNT_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__COUNT_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__COUNT_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_COMPANY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_COMPANY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_COMPANY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_COMPANY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_DATE_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_DATE_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_DATE_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_DATE_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_DEPARTMENT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_DEPARTMENT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_DEPARTMENT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_DEPARTMENT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_DIVISION_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_DIVISION_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_DIVISION_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_DIVISION_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_EFFECTIVE_DATE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_EFFECTIVE_DATE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_EFFECTIVE_DATE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_EFFECTIVE_DATE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_JOB_TITLE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_JOB_TITLE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_JOB_TITLE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_JOB_TITLE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_OFFICE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_OFFICE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_OFFICE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_OFFICE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_REMARK_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_REMARK_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_REMARK_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_REMARK_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_TEAM_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_TEAM_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_TEAM_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_USER_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_USER_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_USER_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_USER_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_USER_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_USER_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_USER_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MAX_USER_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_COMPANY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_COMPANY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_COMPANY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_COMPANY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_DATE_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_DATE_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_DATE_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_DATE_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_DEPARTMENT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_DEPARTMENT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_DEPARTMENT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_DEPARTMENT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_DIVISION_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_DIVISION_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_DIVISION_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_DIVISION_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_EFFECTIVE_DATE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_EFFECTIVE_DATE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_EFFECTIVE_DATE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_EFFECTIVE_DATE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_JOB_TITLE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_JOB_TITLE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_JOB_TITLE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_JOB_TITLE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_OFFICE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_OFFICE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_OFFICE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_OFFICE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_REMARK_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_REMARK_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_REMARK_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_REMARK_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_TEAM_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_TEAM_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_TEAM_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_USER_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_USER_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_USER_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_USER_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_USER_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_USER_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_USER_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DEPARTMENT_ID__MIN_USER_UPDATED_DESC',
  IS_ACTIVE_ASC = 'IS_ACTIVE_ASC',
  IS_ACTIVE_DESC = 'IS_ACTIVE_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_BUSINESS_UNIT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_BUSINESS_UNIT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_BUSINESS_UNIT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_BUSINESS_UNIT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_DEPARTMENT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_DEPARTMENT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_DEPARTMENT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_DEPARTMENT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_DIVISION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_DIVISION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_DIVISION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_DIVISION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_ROLE_NAME_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_ROLE_NAME_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_ROLE_NAME_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_ROLE_NAME_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_BUSINESS_UNIT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_BUSINESS_UNIT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_BUSINESS_UNIT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_BUSINESS_UNIT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_DEPARTMENT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_DEPARTMENT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_DEPARTMENT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_DEPARTMENT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_DIVISION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_DIVISION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_DIVISION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_DIVISION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_ROLE_NAME_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_ROLE_NAME_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_ROLE_NAME_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_ROLE_NAME_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DEPARTMENT_ID__MIN_YEAR_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__COUNT_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__COUNT_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__COUNT_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__COUNT_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MAX_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DEPARTMENT_ID__MIN_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_COUNT_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_COUNT_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_COUNT_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_COUNT_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_USER_UPDATED_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface Division extends Node {
  __typename?: 'Division';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  divisionId: Scalars['BigInt']['output'];
  employeeCurrentPositions: EmployeeCurrentPositionsConnection;
  employeeTpHistories: EmployeeTpHistoriesConnection;
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  performanceReviewMasterCompetencyRoles: PerformanceReviewMasterCompetencyRolesConnection;
  relationBusinessUnitDivisionDepartments: RelationBusinessUnitDivisionDepartmentsConnection;
  relationBusinessUnitDivisions: RelationBusinessUnitDivisionsConnection;
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export type DivisionEmployeeCurrentPositionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCurrentPositionCondition>;
  filter?: InputMaybe<EmployeeCurrentPositionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeCurrentPositionsOrderBy>>;
};

export type DivisionEmployeeTpHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeTpHistoryCondition>;
  filter?: InputMaybe<EmployeeTpHistoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeTpHistoriesOrderBy>>;
};

export type DivisionPerformanceReviewMasterCompetencyRolesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyRoleCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyRoleFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyRolesOrderBy>>;
};

export type DivisionRelationBusinessUnitDivisionDepartmentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationBusinessUnitDivisionDepartmentCondition>;
  filter?: InputMaybe<RelationBusinessUnitDivisionDepartmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentsOrderBy>>;
};

export type DivisionRelationBusinessUnitDivisionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationBusinessUnitDivisionCondition>;
  filter?: InputMaybe<RelationBusinessUnitDivisionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationBusinessUnitDivisionsOrderBy>>;
};

export interface DivisionCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface DivisionDivisionNameKeyConnect {
  name: Scalars['String']['input'];
}

export interface DivisionDivisionNameKeyDelete {
  name: Scalars['String']['input'];
}

export interface DivisionDivisionPkeyConnect {
  divisionId: Scalars['BigInt']['input'];
}

export interface DivisionDivisionPkeyDelete {
  divisionId: Scalars['BigInt']['input'];
}

export interface DivisionFilter {
  and?: InputMaybe<Array<DivisionFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  divisionId?: InputMaybe<BigIntFilter>;
  employeeCurrentPositions?: InputMaybe<DivisionToManyEmployeeCurrentPositionFilter>;
  employeeCurrentPositionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeTpHistories?: InputMaybe<DivisionToManyEmployeeTpHistoryFilter>;
  employeeTpHistoriesExist?: InputMaybe<Scalars['Boolean']['input']>;
  isActive?: InputMaybe<BooleanFilter>;
  name?: InputMaybe<StringFilter>;
  not?: InputMaybe<DivisionFilter>;
  or?: InputMaybe<Array<DivisionFilter>>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<DivisionToManyPerformanceReviewMasterCompetencyRoleFilter>;
  performanceReviewMasterCompetencyRolesExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<DivisionToManyRelationBusinessUnitDivisionDepartmentFilter>;
  relationBusinessUnitDivisionDepartmentsExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationBusinessUnitDivisions?: InputMaybe<DivisionToManyRelationBusinessUnitDivisionFilter>;
  relationBusinessUnitDivisionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface DivisionInput {
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionDivisionIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DivisionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface DivisionNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface DivisionNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface DivisionOnDivisionForDivisionUserCreatedFkeyUsingDivisionNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateDivisionOnDivisionForDivisionUserCreatedFkeyPatch;
}

export interface DivisionOnDivisionForDivisionUserCreatedFkeyUsingDivisionPkeyUpdate {
  divisionId: Scalars['BigInt']['input'];
  patch: UpdateDivisionOnDivisionForDivisionUserCreatedFkeyPatch;
}

export interface DivisionOnDivisionForDivisionUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface DivisionOnDivisionForDivisionUserUpdatedFkeyUsingDivisionNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateDivisionOnDivisionForDivisionUserUpdatedFkeyPatch;
}

export interface DivisionOnDivisionForDivisionUserUpdatedFkeyUsingDivisionPkeyUpdate {
  divisionId: Scalars['BigInt']['input'];
  patch: UpdateDivisionOnDivisionForDivisionUserUpdatedFkeyPatch;
}

export interface DivisionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey9NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeCurrentPositionPatch;
}

export interface DivisionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey9UsingDivisionNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateDivisionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey9Patch;
}

export interface DivisionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey9UsingDivisionPkeyUpdate {
  divisionId: Scalars['BigInt']['input'];
  patch: UpdateDivisionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey9Patch;
}

export interface DivisionOnEmployeeTpHistoryForEmployeeTpHistoryDivisionIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeTpHistoryPatch;
}

export interface DivisionOnEmployeeTpHistoryForEmployeeTpHistoryDivisionIdFkeyUsingDivisionNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateDivisionOnEmployeeTpHistoryForEmployeeTpHistoryDivisionIdFkeyPatch;
}

export interface DivisionOnEmployeeTpHistoryForEmployeeTpHistoryDivisionIdFkeyUsingDivisionPkeyUpdate {
  divisionId: Scalars['BigInt']['input'];
  patch: UpdateDivisionOnEmployeeTpHistoryForEmployeeTpHistoryDivisionIdFkeyPatch;
}

export interface DivisionOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDivisionIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyRolePatch;
}

export interface DivisionOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDivisionIdFkeyUsingDivisionNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateDivisionOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDivisionIdFkeyPatch;
}

export interface DivisionOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDivisionIdFkeyUsingDivisionPkeyUpdate {
  divisionId: Scalars['BigInt']['input'];
  patch: UpdateDivisionOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDivisionIdFkeyPatch;
}

export interface DivisionOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDivisionIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationBusinessUnitDivisionDepartmentPatch;
}

export interface DivisionOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDivisionIdFkeyUsingDivisionNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateDivisionOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDivisionIdFkeyPatch;
}

export interface DivisionOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDivisionIdFkeyUsingDivisionPkeyUpdate {
  divisionId: Scalars['BigInt']['input'];
  patch: UpdateDivisionOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDivisionIdFkeyPatch;
}

export interface DivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionDivisionIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationBusinessUnitDivisionPatch;
}

export interface DivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionDivisionIdFkeyUsingDivisionNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateDivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionDivisionIdFkeyPatch;
}

export interface DivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionDivisionIdFkeyUsingDivisionPkeyUpdate {
  divisionId: Scalars['BigInt']['input'];
  patch: UpdateDivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionDivisionIdFkeyPatch;
}

export interface DivisionPatch {
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionDivisionIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DivisionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface DivisionToManyEmployeeCurrentPositionFilter {
  every?: InputMaybe<EmployeeCurrentPositionFilter>;
  none?: InputMaybe<EmployeeCurrentPositionFilter>;
  some?: InputMaybe<EmployeeCurrentPositionFilter>;
}

export interface DivisionToManyEmployeeTpHistoryFilter {
  every?: InputMaybe<EmployeeTpHistoryFilter>;
  none?: InputMaybe<EmployeeTpHistoryFilter>;
  some?: InputMaybe<EmployeeTpHistoryFilter>;
}

export interface DivisionToManyPerformanceReviewMasterCompetencyRoleFilter {
  every?: InputMaybe<PerformanceReviewMasterCompetencyRoleFilter>;
  none?: InputMaybe<PerformanceReviewMasterCompetencyRoleFilter>;
  some?: InputMaybe<PerformanceReviewMasterCompetencyRoleFilter>;
}

export interface DivisionToManyRelationBusinessUnitDivisionDepartmentFilter {
  aggregates?: InputMaybe<RelationBusinessUnitDivisionDepartmentAggregatesFilter>;
  every?: InputMaybe<RelationBusinessUnitDivisionDepartmentFilter>;
  none?: InputMaybe<RelationBusinessUnitDivisionDepartmentFilter>;
  some?: InputMaybe<RelationBusinessUnitDivisionDepartmentFilter>;
}

export interface DivisionToManyRelationBusinessUnitDivisionFilter {
  aggregates?: InputMaybe<RelationBusinessUnitDivisionAggregatesFilter>;
  every?: InputMaybe<RelationBusinessUnitDivisionFilter>;
  none?: InputMaybe<RelationBusinessUnitDivisionFilter>;
  some?: InputMaybe<RelationBusinessUnitDivisionFilter>;
}

export interface DivisionUserCreatedFkeyInverseInput {
  connectByDivisionId?: InputMaybe<Array<DivisionDivisionPkeyConnect>>;
  connectByName?: InputMaybe<Array<DivisionDivisionNameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<DivisionNodeIdConnect>>;
  deleteByDivisionId?: InputMaybe<Array<DivisionDivisionPkeyDelete>>;
  deleteByName?: InputMaybe<Array<DivisionDivisionNameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<DivisionNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByDivisionId?: InputMaybe<Array<DivisionOnDivisionForDivisionUserCreatedFkeyUsingDivisionPkeyUpdate>>;
  updateByName?: InputMaybe<Array<DivisionOnDivisionForDivisionUserCreatedFkeyUsingDivisionNameKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnDivisionForDivisionUserCreatedFkeyNodeIdUpdate>>;
}

export interface DivisionUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnDivisionForDivisionUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<DivisionOnDivisionForDivisionUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnDivisionForDivisionUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface DivisionUserUpdatedFkeyInverseInput {
  connectByDivisionId?: InputMaybe<Array<DivisionDivisionPkeyConnect>>;
  connectByName?: InputMaybe<Array<DivisionDivisionNameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<DivisionNodeIdConnect>>;
  deleteByDivisionId?: InputMaybe<Array<DivisionDivisionPkeyDelete>>;
  deleteByName?: InputMaybe<Array<DivisionDivisionNameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<DivisionNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByDivisionId?: InputMaybe<Array<DivisionOnDivisionForDivisionUserUpdatedFkeyUsingDivisionPkeyUpdate>>;
  updateByName?: InputMaybe<Array<DivisionOnDivisionForDivisionUserUpdatedFkeyUsingDivisionNameKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnDivisionForDivisionUserUpdatedFkeyNodeIdUpdate>>;
}

export interface DivisionsConnection {
  __typename?: 'DivisionsConnection';
  edges: Array<DivisionsEdge>;
  nodes: Array<Division>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface DivisionsEdge {
  __typename?: 'DivisionsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: Division;
}

export enum DivisionsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DIVISION_ID_ASC = 'DIVISION_ID_ASC',
  DIVISION_ID_DESC = 'DIVISION_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__COUNT_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__COUNT_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__COUNT_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__COUNT_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_CITY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_CITY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_CITY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_CITY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_COMPANY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_COMPANY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_COMPANY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_COMPANY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_DEPARTMENT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_DEPARTMENT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_DEPARTMENT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_DEPARTMENT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_DIVISION_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_DIVISION_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_DIVISION_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_DIVISION_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_EFFECTIVE_DATE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_EFFECTIVE_DATE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_EFFECTIVE_DATE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_EFFECTIVE_DATE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_EMPLOYEE_CODE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_EMPLOYEE_CODE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_EMPLOYEE_CODE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_EMPLOYEE_CODE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_JOB_TITLE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_JOB_TITLE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_JOB_TITLE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_JOB_TITLE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_OFFICE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_OFFICE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_OFFICE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_OFFICE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_TEAM_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_TEAM_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_TEAM_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MAX_TEAM_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_CITY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_CITY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_CITY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_CITY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_COMPANY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_COMPANY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_COMPANY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_COMPANY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_DEPARTMENT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_DEPARTMENT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_DEPARTMENT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_DEPARTMENT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_DIVISION_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_DIVISION_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_DIVISION_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_DIVISION_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_EFFECTIVE_DATE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_EFFECTIVE_DATE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_EFFECTIVE_DATE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_EFFECTIVE_DATE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_EMPLOYEE_CODE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_EMPLOYEE_CODE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_EMPLOYEE_CODE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_EMPLOYEE_CODE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_JOB_TITLE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_JOB_TITLE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_JOB_TITLE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_JOB_TITLE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_OFFICE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_OFFICE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_OFFICE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_OFFICE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_TEAM_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_TEAM_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_TEAM_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_DIVISION_ID__MIN_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__COUNT_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__COUNT_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__COUNT_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__COUNT_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_COMPANY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_COMPANY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_COMPANY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_COMPANY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_DATE_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_DATE_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_DATE_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_DATE_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_DEPARTMENT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_DEPARTMENT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_DEPARTMENT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_DEPARTMENT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_DIVISION_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_DIVISION_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_DIVISION_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_DIVISION_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_EFFECTIVE_DATE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_EFFECTIVE_DATE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_EFFECTIVE_DATE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_EFFECTIVE_DATE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_JOB_TITLE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_JOB_TITLE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_JOB_TITLE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_JOB_TITLE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_OFFICE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_OFFICE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_OFFICE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_OFFICE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_REMARK_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_REMARK_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_REMARK_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_REMARK_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_TEAM_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_TEAM_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_TEAM_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_USER_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_USER_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_USER_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_USER_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_USER_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_USER_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_USER_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MAX_USER_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_COMPANY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_COMPANY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_COMPANY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_COMPANY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_DATE_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_DATE_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_DATE_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_DATE_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_DEPARTMENT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_DEPARTMENT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_DEPARTMENT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_DEPARTMENT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_DIVISION_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_DIVISION_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_DIVISION_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_DIVISION_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_EFFECTIVE_DATE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_EFFECTIVE_DATE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_EFFECTIVE_DATE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_EFFECTIVE_DATE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_JOB_TITLE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_JOB_TITLE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_JOB_TITLE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_JOB_TITLE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_OFFICE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_OFFICE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_OFFICE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_OFFICE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_REMARK_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_REMARK_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_REMARK_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_REMARK_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_TEAM_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_TEAM_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_TEAM_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_USER_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_USER_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_USER_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_USER_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_USER_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_USER_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_USER_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_DIVISION_ID__MIN_USER_UPDATED_DESC',
  IS_ACTIVE_ASC = 'IS_ACTIVE_ASC',
  IS_ACTIVE_DESC = 'IS_ACTIVE_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_BUSINESS_UNIT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_BUSINESS_UNIT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_BUSINESS_UNIT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_BUSINESS_UNIT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_DEPARTMENT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_DEPARTMENT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_DEPARTMENT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_DEPARTMENT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_DIVISION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_DIVISION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_DIVISION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_DIVISION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_ROLE_NAME_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_ROLE_NAME_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_ROLE_NAME_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_ROLE_NAME_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_BUSINESS_UNIT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_BUSINESS_UNIT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_BUSINESS_UNIT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_BUSINESS_UNIT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_DEPARTMENT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_DEPARTMENT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_DEPARTMENT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_DEPARTMENT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_DIVISION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_DIVISION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_DIVISION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_DIVISION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_ROLE_NAME_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_ROLE_NAME_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_ROLE_NAME_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_ROLE_NAME_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_DIVISION_ID__MIN_YEAR_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_AVERAGE_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__COUNT_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__COUNT_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__COUNT_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__COUNT_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MAX_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_DIVISION_ID__MIN_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_COUNT_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_COUNT_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_COUNT_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_COUNT_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_DISTINCT_COUNT_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MAX_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MAX_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_MIN_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_MIN_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_POPULATION_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_STDDEV_SAMPLE_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_SUM_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_SUM_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_POPULATION_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_VARIANCE_SAMPLE_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_AVERAGE_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__COUNT_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__COUNT_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__COUNT_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__COUNT_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MAX_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_DIVISION_ID__MIN_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_COUNT_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_COUNT_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_COUNT_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_COUNT_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_DISTINCT_COUNT_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MAX_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_MIN_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_POPULATION_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_STDDEV_SAMPLE_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_SUM_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_POPULATION_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_VARIANCE_SAMPLE_USER_UPDATED_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface EmailDomain extends Node {
  __typename?: 'EmailDomain';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  domainName: Scalars['String']['output'];
  emailDomainId: Scalars['BigInt']['output'];
  isSsoLoginAvailable: Scalars['Boolean']['output'];
  nodeId: Scalars['ID']['output'];
  relationCompanyGroupEmailDomains: RelationCompanyGroupEmailDomainsConnection;
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export type EmailDomainRelationCompanyGroupEmailDomainsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationCompanyGroupEmailDomainCondition>;
  filter?: InputMaybe<RelationCompanyGroupEmailDomainFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationCompanyGroupEmailDomainsOrderBy>>;
};

export interface EmailDomainCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  domainName?: InputMaybe<Scalars['String']['input']>;
  emailDomainId?: InputMaybe<Scalars['BigInt']['input']>;
  isSsoLoginAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmailDomainEmailDomainDomainNameKeyConnect {
  domainName: Scalars['String']['input'];
}

export interface EmailDomainEmailDomainDomainNameKeyDelete {
  domainName: Scalars['String']['input'];
}

export interface EmailDomainEmailDomainPkeyConnect {
  emailDomainId: Scalars['BigInt']['input'];
}

export interface EmailDomainEmailDomainPkeyDelete {
  emailDomainId: Scalars['BigInt']['input'];
}

export interface EmailDomainFilter {
  and?: InputMaybe<Array<EmailDomainFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  domainName?: InputMaybe<StringFilter>;
  emailDomainId?: InputMaybe<BigIntFilter>;
  isSsoLoginAvailable?: InputMaybe<BooleanFilter>;
  not?: InputMaybe<EmailDomainFilter>;
  or?: InputMaybe<Array<EmailDomainFilter>>;
  relationCompanyGroupEmailDomains?: InputMaybe<EmailDomainToManyRelationCompanyGroupEmailDomainFilter>;
  relationCompanyGroupEmailDomainsExist?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface EmailDomainInput {
  domainName: Scalars['String']['input'];
  emailDomainId?: InputMaybe<Scalars['BigInt']['input']>;
  isSsoLoginAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  relationCompanyGroupEmailDomains?: InputMaybe<RelationCompanyGroupEmailDomainEmailDomainIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmailDomainUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmailDomainNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface EmailDomainNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface EmailDomainOnEmailDomainForEmailDomainUserCreatedFkeyUsingEmailDomainDomainNameKeyUpdate {
  domainName: Scalars['String']['input'];
  patch: UpdateEmailDomainOnEmailDomainForEmailDomainUserCreatedFkeyPatch;
}

export interface EmailDomainOnEmailDomainForEmailDomainUserCreatedFkeyUsingEmailDomainPkeyUpdate {
  emailDomainId: Scalars['BigInt']['input'];
  patch: UpdateEmailDomainOnEmailDomainForEmailDomainUserCreatedFkeyPatch;
}

export interface EmailDomainOnEmailDomainForEmailDomainUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface EmailDomainOnEmailDomainForEmailDomainUserUpdatedFkeyUsingEmailDomainDomainNameKeyUpdate {
  domainName: Scalars['String']['input'];
  patch: UpdateEmailDomainOnEmailDomainForEmailDomainUserUpdatedFkeyPatch;
}

export interface EmailDomainOnEmailDomainForEmailDomainUserUpdatedFkeyUsingEmailDomainPkeyUpdate {
  emailDomainId: Scalars['BigInt']['input'];
  patch: UpdateEmailDomainOnEmailDomainForEmailDomainUserUpdatedFkeyPatch;
}

export interface EmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainEmailDomainIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationCompanyGroupEmailDomainPatch;
}

export interface EmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainEmailDomainIdFkeyUsingEmailDomainDomainNameKeyUpdate {
  domainName: Scalars['String']['input'];
  patch: UpdateEmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainEmailDomainIdFkeyPatch;
}

export interface EmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainEmailDomainIdFkeyUsingEmailDomainPkeyUpdate {
  emailDomainId: Scalars['BigInt']['input'];
  patch: UpdateEmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainEmailDomainIdFkeyPatch;
}

export interface EmailDomainPatch {
  domainName?: InputMaybe<Scalars['String']['input']>;
  emailDomainId?: InputMaybe<Scalars['BigInt']['input']>;
  isSsoLoginAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  relationCompanyGroupEmailDomains?: InputMaybe<RelationCompanyGroupEmailDomainEmailDomainIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmailDomainUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmailDomainToManyRelationCompanyGroupEmailDomainFilter {
  every?: InputMaybe<RelationCompanyGroupEmailDomainFilter>;
  none?: InputMaybe<RelationCompanyGroupEmailDomainFilter>;
  some?: InputMaybe<RelationCompanyGroupEmailDomainFilter>;
}

export interface EmailDomainUserCreatedFkeyInverseInput {
  connectByDomainName?: InputMaybe<Array<EmailDomainEmailDomainDomainNameKeyConnect>>;
  connectByEmailDomainId?: InputMaybe<Array<EmailDomainEmailDomainPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmailDomainNodeIdConnect>>;
  deleteByDomainName?: InputMaybe<Array<EmailDomainEmailDomainDomainNameKeyDelete>>;
  deleteByEmailDomainId?: InputMaybe<Array<EmailDomainEmailDomainPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmailDomainNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByDomainName?: InputMaybe<Array<EmailDomainOnEmailDomainForEmailDomainUserCreatedFkeyUsingEmailDomainDomainNameKeyUpdate>>;
  updateByEmailDomainId?: InputMaybe<Array<EmailDomainOnEmailDomainForEmailDomainUserCreatedFkeyUsingEmailDomainPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmailDomainForEmailDomainUserCreatedFkeyNodeIdUpdate>>;
}

export interface EmailDomainUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnEmailDomainForEmailDomainUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<EmailDomainOnEmailDomainForEmailDomainUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnEmailDomainForEmailDomainUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface EmailDomainUserUpdatedFkeyInverseInput {
  connectByDomainName?: InputMaybe<Array<EmailDomainEmailDomainDomainNameKeyConnect>>;
  connectByEmailDomainId?: InputMaybe<Array<EmailDomainEmailDomainPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmailDomainNodeIdConnect>>;
  deleteByDomainName?: InputMaybe<Array<EmailDomainEmailDomainDomainNameKeyDelete>>;
  deleteByEmailDomainId?: InputMaybe<Array<EmailDomainEmailDomainPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmailDomainNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByDomainName?: InputMaybe<Array<EmailDomainOnEmailDomainForEmailDomainUserUpdatedFkeyUsingEmailDomainDomainNameKeyUpdate>>;
  updateByEmailDomainId?: InputMaybe<Array<EmailDomainOnEmailDomainForEmailDomainUserUpdatedFkeyUsingEmailDomainPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmailDomainForEmailDomainUserUpdatedFkeyNodeIdUpdate>>;
}

export interface EmailDomainsConnection {
  __typename?: 'EmailDomainsConnection';
  edges: Array<EmailDomainsEdge>;
  nodes: Array<EmailDomain>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmailDomainsEdge {
  __typename?: 'EmailDomainsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmailDomain;
}

export enum EmailDomainsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DOMAIN_NAME_ASC = 'DOMAIN_NAME_ASC',
  DOMAIN_NAME_DESC = 'DOMAIN_NAME_DESC',
  EMAIL_DOMAIN_ID_ASC = 'EMAIL_DOMAIN_ID_ASC',
  EMAIL_DOMAIN_ID_DESC = 'EMAIL_DOMAIN_ID_DESC',
  IS_SSO_LOGIN_AVAILABLE_ASC = 'IS_SSO_LOGIN_AVAILABLE_ASC',
  IS_SSO_LOGIN_AVAILABLE_DESC = 'IS_SSO_LOGIN_AVAILABLE_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__COUNT_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__COUNT_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__COUNT_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__COUNT_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_COMPANY_GROUP_ID_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_COMPANY_GROUP_ID_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_COMPANY_GROUP_ID_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_COMPANY_GROUP_ID_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_DATE_CREATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_DATE_CREATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_DATE_CREATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_DATE_CREATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_DATE_UPDATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_DATE_UPDATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_DATE_UPDATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_DATE_UPDATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_EMAIL_DOMAIN_ID_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_EMAIL_DOMAIN_ID_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_EMAIL_DOMAIN_ID_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_EMAIL_DOMAIN_ID_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_REMARK_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_REMARK_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_REMARK_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_REMARK_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_USER_CREATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_USER_CREATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_USER_CREATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_USER_CREATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_USER_UPDATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_USER_UPDATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_USER_UPDATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MAX_USER_UPDATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_COMPANY_GROUP_ID_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_COMPANY_GROUP_ID_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_COMPANY_GROUP_ID_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_COMPANY_GROUP_ID_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_DATE_CREATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_DATE_CREATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_DATE_CREATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_DATE_CREATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_DATE_UPDATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_DATE_UPDATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_DATE_UPDATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_DATE_UPDATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_EMAIL_DOMAIN_ID_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_EMAIL_DOMAIN_ID_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_EMAIL_DOMAIN_ID_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_EMAIL_DOMAIN_ID_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_REMARK_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_REMARK_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_REMARK_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_REMARK_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_USER_CREATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_USER_CREATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_USER_CREATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_USER_CREATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_USER_UPDATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_USER_UPDATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_USER_UPDATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_EMAIL_DOMAIN_ID__MIN_USER_UPDATED_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface Employee extends Node {
  __typename?: 'Employee';
  allEmployeeWithYearQuarters: AllEmployeeWithYearQuartersConnection;
  allSubordinateCount: Scalars['Int']['output'];
  allSubordinateCountNotResigned: Scalars['Int']['output'];
  approver?: Maybe<Employee>;
  approverId?: Maybe<Scalars['BigInt']['output']>;
  bank?: Maybe<Bank>;
  bankAccountHolderName?: Maybe<Scalars['String']['output']>;
  bankAccountNumber?: Maybe<Scalars['String']['output']>;
  bankBranchName?: Maybe<Scalars['String']['output']>;
  bankId?: Maybe<Scalars['BigInt']['output']>;
  bankRemarks?: Maybe<Scalars['String']['output']>;
  companyEmail?: Maybe<Scalars['String']['output']>;
  companyGlobalHr?: Maybe<CompanyGlobalHr>;
  companyLocalHrs: CompanyLocalHrsConnection;
  consultingFeeBank?: Maybe<Bank>;
  consultingFeeBankAccountHolderName?: Maybe<Scalars['String']['output']>;
  consultingFeeBankAccountNumber?: Maybe<Scalars['String']['output']>;
  consultingFeeBankBranchName?: Maybe<Scalars['String']['output']>;
  consultingFeeBankId?: Maybe<Scalars['BigInt']['output']>;
  consultingFeeBankRemarks?: Maybe<Scalars['String']['output']>;
  consultingFeeCurrency?: Maybe<Currency>;
  consultingFeeCurrencyId?: Maybe<Scalars['String']['output']>;
  consultingFeeType?: Maybe<Array<Maybe<EmployeeConsultingFeeType>>>;
  countryByNationality?: Maybe<Country>;
  countryByWorkingLocationCountryCodeAlpha2: Country;
  currentAddress?: Maybe<Scalars['String']['output']>;
  currentAddressZipcode?: Maybe<Scalars['String']['output']>;
  dateCreated: Scalars['Datetime']['output'];
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  dateUpdated: Scalars['Datetime']['output'];
  dottedLineManager?: Maybe<Employee>;
  dottedLineManagerId?: Maybe<Scalars['BigInt']['output']>;
  employeeCurrentPosition?: Maybe<EmployeeCurrentPosition>;
  employeeDaysByContractTypes: EmployeeDaysByContractTypesConnection;
  employeeEmergencyContacts: EmployeeEmergencyContactsConnection;
  employeeFiles: EmployeeFilesConnection;
  employeeGroupLatest?: Maybe<EmployeeGroupLatest>;
  employeeGroupLists: EmployeeGroupListsConnection;
  employeeGroups: EmployeeGroupsConnection;
  employeeId: Scalars['BigInt']['output'];
  employeeIdTypeByIdType?: Maybe<EmployeeIdType>;
  employeeStatusByStatus: EmployeeStatus;
  employeeTpHistories: EmployeeTpHistoriesConnection;
  employeeTransferHistories: EmployeeTransferHistoriesConnection;
  employeeWorkingDayStatisticsAll?: Maybe<EmployeeWorkingDayStatisticsAll>;
  employeesByApproverId: EmployeesConnection;
  employeesByDottedLineManagerId: EmployeesConnection;
  employeesByPrimaryManagerId: EmployeesConnection;
  externalAccountCreationFaileds: ExternalAccountCreationFailedsConnection;
  externalAccountEmployees: ExternalAccountEmployeesConnection;
  fullName: Scalars['String']['output'];
  fullNameInLocalLanguage?: Maybe<Scalars['String']['output']>;
  fullNameKana?: Maybe<Scalars['String']['output']>;
  gender: EmployeeGender;
  idExpiryDate?: Maybe<Scalars['Date']['output']>;
  idIssuedDate?: Maybe<Scalars['Date']['output']>;
  idIssuedPlace?: Maybe<Scalars['String']['output']>;
  idNumber?: Maybe<Scalars['String']['output']>;
  idType?: Maybe<Scalars['BigInt']['output']>;
  joinDate: Scalars['Date']['output'];
  lastWorkingDate?: Maybe<Scalars['Date']['output']>;
  maritalStatus?: Maybe<EmployeeMaritalStatus>;
  mobileNumber?: Maybe<Scalars['String']['output']>;
  mobileNumberCountry?: Maybe<Country>;
  mobileNumberCountryId?: Maybe<Scalars['String']['output']>;
  nationality?: Maybe<Scalars['String']['output']>;
  nhiDependencies?: Maybe<Scalars['Int']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  nodeId: Scalars['ID']['output'];
  numberOfDependencies?: Maybe<Scalars['Int']['output']>;
  passportExpiryDate?: Maybe<Scalars['Date']['output']>;
  passportIssuedDate?: Maybe<Scalars['Date']['output']>;
  passportIssuedPlace?: Maybe<Scalars['String']['output']>;
  passportName?: Maybe<Scalars['String']['output']>;
  passportNumber?: Maybe<Scalars['String']['output']>;
  pensionVoluntaryContributionRate?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluationCompetenciesByAssessmentEmployeeId: PerformanceReviewEvaluationCompetenciesConnection;
  performanceReviewEvaluationEvaluatorsByEvaluatorId: PerformanceReviewEvaluationEvaluatorsConnection;
  performanceReviewEvaluationFeedbacksByFeedbackEmployeeId: PerformanceReviewEvaluationFeedbacksConnection;
  performanceReviewEvaluationKpiAssessmentsByAssessmentEmployeeId: PerformanceReviewEvaluationKpiAssessmentsConnection;
  performanceReviewEvaluationOneOnOneSelfLists: PerformanceReviewEvaluationOneOnOneSelfListsConnection;
  performanceReviewEvaluationQualitativeAssessmentsByAssessmentEmployeeId: PerformanceReviewEvaluationQualitativeAssessmentsConnection;
  performanceReviewEvaluationValuesByAssessmentEmployeeId: PerformanceReviewEvaluationValuesConnection;
  performanceReviewEvaluations: PerformanceReviewEvaluationsConnection;
  performanceReviewMasterEvaluationViewers: PerformanceReviewMasterEvaluationViewersConnection;
  performanceReviewMasterEvaluationViewersByViewerEmployeeId: PerformanceReviewMasterEvaluationViewersConnection;
  performanceReviewMasterExcludedEmployees: PerformanceReviewMasterExcludedEmployeesConnection;
  permanentAddress?: Maybe<Scalars['String']['output']>;
  personalEmail?: Maybe<Scalars['String']['output']>;
  placeOfBirth?: Maybe<Scalars['String']['output']>;
  primaryManager?: Maybe<Employee>;
  primaryManagerId?: Maybe<Scalars['BigInt']['output']>;
  probationEnd?: Maybe<Scalars['Date']['output']>;
  probationReviewEvaluationReviewersByReviewerEmployeeId: ProbationReviewEvaluationReviewersConnection;
  probationReviewEvaluations: ProbationReviewEvaluationsConnection;
  relationEmployeeUserAccounts: RelationEmployeeUserAccountsConnection;
  remark?: Maybe<Scalars['String']['output']>;
  remoteWorking?: Maybe<Scalars['Boolean']['output']>;
  resignationDate?: Maybe<Scalars['Date']['output']>;
  resignationReason?: Maybe<EmployeeResignationReason>;
  resignationReasonDetail?: Maybe<Scalars['String']['output']>;
  resignationReasonId?: Maybe<Scalars['BigInt']['output']>;
  resignationType?: Maybe<EmployeeResignationType>;
  resignationTypeId?: Maybe<Scalars['BigInt']['output']>;
  sentAccountRequestEmailDate?: Maybe<Scalars['Datetime']['output']>;
  sentContractEndEmailDate?: Maybe<Scalars['Datetime']['output']>;
  socialInsuranceNumber?: Maybe<Scalars['String']['output']>;
  status: EmployeeStatusKey;
  taxCode?: Maybe<Scalars['String']['output']>;
  taxWithholdingRate?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
  visaExpiryDate?: Maybe<Scalars['Date']['output']>;
  visaIssuedDate?: Maybe<Scalars['Date']['output']>;
  visaNumber?: Maybe<Scalars['String']['output']>;
  visaType?: Maybe<Scalars['String']['output']>;
  workPermitExpiryDate?: Maybe<Scalars['Date']['output']>;
  workPermitIssuedDate?: Maybe<Scalars['Date']['output']>;
  workPermitNumber?: Maybe<Scalars['String']['output']>;
  workingLocationCountryCodeAlpha2: Scalars['String']['output'];
}

export type EmployeeAllEmployeeWithYearQuartersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AllEmployeeWithYearQuarterCondition>;
  filter?: InputMaybe<AllEmployeeWithYearQuarterFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AllEmployeeWithYearQuartersOrderBy>>;
};

export type EmployeeAllSubordinateCountArgs = {
  depth?: InputMaybe<Scalars['Int']['input']>;
};

export type EmployeeAllSubordinateCountNotResignedArgs = {
  depth?: InputMaybe<Scalars['Int']['input']>;
};

export type EmployeeCompanyLocalHrsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CompanyLocalHrCondition>;
  filter?: InputMaybe<CompanyLocalHrFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyLocalHrsOrderBy>>;
};

export type EmployeeEmployeeDaysByContractTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeDaysByContractTypeCondition>;
  filter?: InputMaybe<EmployeeDaysByContractTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeDaysByContractTypesOrderBy>>;
};

export type EmployeeEmployeeEmergencyContactsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeEmergencyContactCondition>;
  filter?: InputMaybe<EmployeeEmergencyContactFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeEmergencyContactsOrderBy>>;
};

export type EmployeeEmployeeFilesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeFileCondition>;
  filter?: InputMaybe<EmployeeFileFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeFilesOrderBy>>;
};

export type EmployeeEmployeeGroupListsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeGroupListCondition>;
  filter?: InputMaybe<EmployeeGroupListFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeGroupListsOrderBy>>;
};

export type EmployeeEmployeeGroupsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeGroupCondition>;
  filter?: InputMaybe<EmployeeGroupFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeGroupsOrderBy>>;
};

export type EmployeeEmployeeTpHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeTpHistoryCondition>;
  filter?: InputMaybe<EmployeeTpHistoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeTpHistoriesOrderBy>>;
};

export type EmployeeEmployeeTransferHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeTransferHistoryCondition>;
  filter?: InputMaybe<EmployeeTransferHistoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeTransferHistoriesOrderBy>>;
};

export type EmployeeEmployeesByApproverIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCondition>;
  filter?: InputMaybe<EmployeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeesOrderBy>>;
};

export type EmployeeEmployeesByDottedLineManagerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCondition>;
  filter?: InputMaybe<EmployeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeesOrderBy>>;
};

export type EmployeeEmployeesByPrimaryManagerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCondition>;
  filter?: InputMaybe<EmployeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeesOrderBy>>;
};

export type EmployeeExternalAccountCreationFailedsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ExternalAccountCreationFailedCondition>;
  filter?: InputMaybe<ExternalAccountCreationFailedFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ExternalAccountCreationFailedsOrderBy>>;
};

export type EmployeeExternalAccountEmployeesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ExternalAccountEmployeeCondition>;
  filter?: InputMaybe<ExternalAccountEmployeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ExternalAccountEmployeesOrderBy>>;
};

export type EmployeePerformanceReviewEvaluationCompetenciesByAssessmentEmployeeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationCompetencyCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationCompetencyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationCompetenciesOrderBy>>;
};

export type EmployeePerformanceReviewEvaluationEvaluatorsByEvaluatorIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationEvaluatorCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationEvaluatorFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorsOrderBy>>;
};

export type EmployeePerformanceReviewEvaluationFeedbacksByFeedbackEmployeeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationFeedbackCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationFeedbackFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationFeedbacksOrderBy>>;
};

export type EmployeePerformanceReviewEvaluationKpiAssessmentsByAssessmentEmployeeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentsOrderBy>>;
};

export type EmployeePerformanceReviewEvaluationOneOnOneSelfListsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationOneOnOneSelfListCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationOneOnOneSelfListFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOneSelfListsOrderBy>>;
};

export type EmployeePerformanceReviewEvaluationQualitativeAssessmentsByAssessmentEmployeeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentsOrderBy>>;
};

export type EmployeePerformanceReviewEvaluationValuesByAssessmentEmployeeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationValueCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationValueFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationValuesOrderBy>>;
};

export type EmployeePerformanceReviewEvaluationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationsOrderBy>>;
};

export type EmployeePerformanceReviewMasterEvaluationViewersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterEvaluationViewerCondition>;
  filter?: InputMaybe<PerformanceReviewMasterEvaluationViewerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewersOrderBy>>;
};

export type EmployeePerformanceReviewMasterEvaluationViewersByViewerEmployeeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterEvaluationViewerCondition>;
  filter?: InputMaybe<PerformanceReviewMasterEvaluationViewerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewersOrderBy>>;
};

export type EmployeePerformanceReviewMasterExcludedEmployeesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterExcludedEmployeeCondition>;
  filter?: InputMaybe<PerformanceReviewMasterExcludedEmployeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterExcludedEmployeesOrderBy>>;
};

export type EmployeeProbationReviewEvaluationReviewersByReviewerEmployeeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationReviewerCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationReviewerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationReviewersOrderBy>>;
};

export type EmployeeProbationReviewEvaluationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationsOrderBy>>;
};

export type EmployeeRelationEmployeeUserAccountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationEmployeeUserAccountCondition>;
  filter?: InputMaybe<RelationEmployeeUserAccountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationEmployeeUserAccountsOrderBy>>;
};

export interface EmployeeAgeStatistic {
  __typename?: 'EmployeeAgeStatistic';
  ageAverage: Scalars['BigFloat']['output'];
  ageMax: Scalars['Int']['output'];
  ageMedian: Scalars['Float']['output'];
  ageMin: Scalars['Int']['output'];
  categoryMain: Scalars['String']['output'];
  categorySub?: Maybe<Scalars['JSON']['output']>;
  count: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
}

export interface EmployeeAgeStatisticCondition {
  ageAverage?: InputMaybe<Scalars['BigFloat']['input']>;
  ageMax?: InputMaybe<Scalars['Int']['input']>;
  ageMedian?: InputMaybe<Scalars['Float']['input']>;
  ageMin?: InputMaybe<Scalars['Int']['input']>;
  categoryMain?: InputMaybe<Scalars['String']['input']>;
  categorySub?: InputMaybe<Scalars['JSON']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
}

export interface EmployeeAgeStatisticFilter {
  ageAverage?: InputMaybe<BigFloatFilter>;
  ageMax?: InputMaybe<IntFilter>;
  ageMedian?: InputMaybe<FloatFilter>;
  ageMin?: InputMaybe<IntFilter>;
  and?: InputMaybe<Array<EmployeeAgeStatisticFilter>>;
  categoryMain?: InputMaybe<StringFilter>;
  categorySub?: InputMaybe<JsonFilter>;
  count?: InputMaybe<IntFilter>;
  not?: InputMaybe<EmployeeAgeStatisticFilter>;
  or?: InputMaybe<Array<EmployeeAgeStatisticFilter>>;
  order?: InputMaybe<IntFilter>;
}

export interface EmployeeAgeStatisticsConnection {
  __typename?: 'EmployeeAgeStatisticsConnection';
  edges: Array<EmployeeAgeStatisticsEdge>;
  nodes: Array<EmployeeAgeStatistic>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeAgeStatisticsDetail {
  __typename?: 'EmployeeAgeStatisticsDetail';
  age: Scalars['Int']['output'];
  categoryMain: Scalars['String']['output'];
  categorySub?: Maybe<Scalars['JSON']['output']>;
  count: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  rank: Scalars['Int']['output'];
  rankSub: Scalars['Int']['output'];
}

export interface EmployeeAgeStatisticsDetailCondition {
  age?: InputMaybe<Scalars['Int']['input']>;
  categoryMain?: InputMaybe<Scalars['String']['input']>;
  categorySub?: InputMaybe<Scalars['JSON']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  rankSub?: InputMaybe<Scalars['Int']['input']>;
}

export interface EmployeeAgeStatisticsDetailFilter {
  age?: InputMaybe<IntFilter>;
  and?: InputMaybe<Array<EmployeeAgeStatisticsDetailFilter>>;
  categoryMain?: InputMaybe<StringFilter>;
  categorySub?: InputMaybe<JsonFilter>;
  count?: InputMaybe<IntFilter>;
  not?: InputMaybe<EmployeeAgeStatisticsDetailFilter>;
  or?: InputMaybe<Array<EmployeeAgeStatisticsDetailFilter>>;
  order?: InputMaybe<IntFilter>;
  rank?: InputMaybe<IntFilter>;
  rankSub?: InputMaybe<IntFilter>;
}

export interface EmployeeAgeStatisticsDetailsConnection {
  __typename?: 'EmployeeAgeStatisticsDetailsConnection';
  edges: Array<EmployeeAgeStatisticsDetailsEdge>;
  nodes: Array<EmployeeAgeStatisticsDetail>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeAgeStatisticsDetailsEdge {
  __typename?: 'EmployeeAgeStatisticsDetailsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeAgeStatisticsDetail;
}

export enum EmployeeAgeStatisticsDetailsOrderBy {
  AGE_ASC = 'AGE_ASC',
  AGE_DESC = 'AGE_DESC',
  CATEGORY_MAIN_ASC = 'CATEGORY_MAIN_ASC',
  CATEGORY_MAIN_DESC = 'CATEGORY_MAIN_DESC',
  CATEGORY_SUB_ASC = 'CATEGORY_SUB_ASC',
  CATEGORY_SUB_DESC = 'CATEGORY_SUB_DESC',
  COUNT_ASC = 'COUNT_ASC',
  COUNT_DESC = 'COUNT_DESC',
  NATURAL = 'NATURAL',
  ORDER_ASC = 'ORDER_ASC',
  ORDER_DESC = 'ORDER_DESC',
  RANK_ASC = 'RANK_ASC',
  RANK_DESC = 'RANK_DESC',
  RANK_SUB_ASC = 'RANK_SUB_ASC',
  RANK_SUB_DESC = 'RANK_SUB_DESC',
}

export interface EmployeeAgeStatisticsEdge {
  __typename?: 'EmployeeAgeStatisticsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeAgeStatistic;
}

export enum EmployeeAgeStatisticsOrderBy {
  AGE_AVERAGE_ASC = 'AGE_AVERAGE_ASC',
  AGE_AVERAGE_DESC = 'AGE_AVERAGE_DESC',
  AGE_MAX_ASC = 'AGE_MAX_ASC',
  AGE_MAX_DESC = 'AGE_MAX_DESC',
  AGE_MEDIAN_ASC = 'AGE_MEDIAN_ASC',
  AGE_MEDIAN_DESC = 'AGE_MEDIAN_DESC',
  AGE_MIN_ASC = 'AGE_MIN_ASC',
  AGE_MIN_DESC = 'AGE_MIN_DESC',
  CATEGORY_MAIN_ASC = 'CATEGORY_MAIN_ASC',
  CATEGORY_MAIN_DESC = 'CATEGORY_MAIN_DESC',
  CATEGORY_SUB_ASC = 'CATEGORY_SUB_ASC',
  CATEGORY_SUB_DESC = 'CATEGORY_SUB_DESC',
  COUNT_ASC = 'COUNT_ASC',
  COUNT_DESC = 'COUNT_DESC',
  NATURAL = 'NATURAL',
  ORDER_ASC = 'ORDER_ASC',
  ORDER_DESC = 'ORDER_DESC',
}

export interface EmployeeApproverIdFkeyEmployeeCreateInput {
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeApproverIdFkeyInput {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<EmployeeApproverIdFkeyEmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnEmployeeForEmployeeApproverIdFkeyUsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnEmployeeForEmployeeApproverIdFkeyUsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeOnEmployeeForEmployeeApproverIdFkeyNodeIdUpdate>;
}

export interface EmployeeApproverListConnection {
  __typename?: 'EmployeeApproverListConnection';
  edges: Array<EmployeeApproverListEdge>;
  nodes: Array<EmployeeApproverListRecord>;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeApproverListEdge {
  __typename?: 'EmployeeApproverListEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeApproverListRecord;
}

export interface EmployeeApproverListRecord {
  __typename?: 'EmployeeApproverListRecord';
  depth?: Maybe<Scalars['Int']['output']>;
  employeeId?: Maybe<Scalars['BigInt']['output']>;
  employeeLevel?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  fullNameInLocalLanguage?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
}

export interface EmployeeApproverListRecordFilter {
  and?: InputMaybe<Array<EmployeeApproverListRecordFilter>>;
  depth?: InputMaybe<IntFilter>;
  employeeId?: InputMaybe<BigIntFilter>;
  employeeLevel?: InputMaybe<StringFilter>;
  fullName?: InputMaybe<StringFilter>;
  fullNameInLocalLanguage?: InputMaybe<StringFilter>;
  nickname?: InputMaybe<StringFilter>;
  not?: InputMaybe<EmployeeApproverListRecordFilter>;
  or?: InputMaybe<Array<EmployeeApproverListRecordFilter>>;
}

export interface EmployeeBankIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeBankIdFkeyInverseInput {
  connectByCompanyEmail?: InputMaybe<Array<EmployeeEmployeeCompanyEmailKeyConnect>>;
  connectByEmployeeId?: InputMaybe<Array<EmployeeEmployeePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeNodeIdConnect>>;
  create?: InputMaybe<Array<EmployeeBankIdFkeyEmployeeCreateInput>>;
  deleteByCompanyEmail?: InputMaybe<Array<EmployeeEmployeeCompanyEmailKeyDelete>>;
  deleteByEmployeeId?: InputMaybe<Array<EmployeeEmployeePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyEmail?: InputMaybe<Array<EmployeeOnEmployeeForEmployeeBankIdFkeyUsingEmployeeCompanyEmailKeyUpdate>>;
  updateByEmployeeId?: InputMaybe<Array<EmployeeOnEmployeeForEmployeeBankIdFkeyUsingEmployeePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<BankOnEmployeeForEmployeeBankIdFkeyNodeIdUpdate>>;
}

export interface EmployeeCondition {
  allSubordinateCount?: InputMaybe<Scalars['Int']['input']>;
  allSubordinateCountNotResigned?: InputMaybe<Scalars['Int']['input']>;
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeConsultingFeeBankIdFkeyBankCreateInput {
  bankCode?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<BankCountryCodeAlpha3FkeyInput>;
  countryCodeAlpha3?: InputMaybe<Scalars['String']['input']>;
  employeesToBankIdUsingBankId?: InputMaybe<EmployeeBankIdFkeyInverseInput>;
  employeesToConsultingFeeBankIdUsingBankId?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInverseInput>;
  enabled: Scalars['Boolean']['input'];
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BankUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeConsultingFeeBankIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeConsultingFeeBankIdFkeyInput {
  connectByBankId?: InputMaybe<BankBankPkeyConnect>;
  connectByCountryCodeAlpha3AndFullName?: InputMaybe<BankBankCountryCodeAlpha3FullNameKeyConnect>;
  connectByNodeId?: InputMaybe<BankNodeIdConnect>;
  connectBySwiftCode?: InputMaybe<BankBankSwiftCodeKeyConnect>;
  create?: InputMaybe<EmployeeConsultingFeeBankIdFkeyBankCreateInput>;
  deleteByBankId?: InputMaybe<BankBankPkeyDelete>;
  deleteByCountryCodeAlpha3AndFullName?: InputMaybe<BankBankCountryCodeAlpha3FullNameKeyDelete>;
  deleteByNodeId?: InputMaybe<BankNodeIdDelete>;
  deleteBySwiftCode?: InputMaybe<BankBankSwiftCodeKeyDelete>;
  updateByBankId?: InputMaybe<BankOnEmployeeForEmployeeConsultingFeeBankIdFkeyUsingBankPkeyUpdate>;
  updateByCountryCodeAlpha3AndFullName?: InputMaybe<BankOnEmployeeForEmployeeConsultingFeeBankIdFkeyUsingBankCountryCodeAlpha3FullNameKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeOnEmployeeForEmployeeConsultingFeeBankIdFkeyNodeIdUpdate>;
  updateBySwiftCode?: InputMaybe<BankOnEmployeeForEmployeeConsultingFeeBankIdFkeyUsingBankSwiftCodeKeyUpdate>;
}

export interface EmployeeConsultingFeeBankIdFkeyInverseInput {
  connectByCompanyEmail?: InputMaybe<Array<EmployeeEmployeeCompanyEmailKeyConnect>>;
  connectByEmployeeId?: InputMaybe<Array<EmployeeEmployeePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeNodeIdConnect>>;
  create?: InputMaybe<Array<EmployeeConsultingFeeBankIdFkeyEmployeeCreateInput>>;
  deleteByCompanyEmail?: InputMaybe<Array<EmployeeEmployeeCompanyEmailKeyDelete>>;
  deleteByEmployeeId?: InputMaybe<Array<EmployeeEmployeePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyEmail?: InputMaybe<Array<EmployeeOnEmployeeForEmployeeConsultingFeeBankIdFkeyUsingEmployeeCompanyEmailKeyUpdate>>;
  updateByEmployeeId?: InputMaybe<Array<EmployeeOnEmployeeForEmployeeConsultingFeeBankIdFkeyUsingEmployeePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<BankOnEmployeeForEmployeeConsultingFeeBankIdFkeyNodeIdUpdate>>;
}

export interface EmployeeConsultingFeeCurrencyIdFkeyCurrencyCreateInput {
  abbreviation: Scalars['String']['input'];
  currencyCode: Scalars['String']['input'];
  employees?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInverseInput>;
  name: Scalars['String']['input'];
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCurrencyCodeFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CurrencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeConsultingFeeCurrencyIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeConsultingFeeCurrencyIdFkeyInput {
  connectByCurrencyCode?: InputMaybe<CurrencyCurrencyPkeyConnect>;
  connectByNodeId?: InputMaybe<CurrencyNodeIdConnect>;
  create?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyCurrencyCreateInput>;
  deleteByCurrencyCode?: InputMaybe<CurrencyCurrencyPkeyDelete>;
  deleteByNodeId?: InputMaybe<CurrencyNodeIdDelete>;
  updateByCurrencyCode?: InputMaybe<CurrencyOnEmployeeForEmployeeConsultingFeeCurrencyIdFkeyUsingCurrencyPkeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeOnEmployeeForEmployeeConsultingFeeCurrencyIdFkeyNodeIdUpdate>;
}

export interface EmployeeConsultingFeeCurrencyIdFkeyInverseInput {
  connectByCompanyEmail?: InputMaybe<Array<EmployeeEmployeeCompanyEmailKeyConnect>>;
  connectByEmployeeId?: InputMaybe<Array<EmployeeEmployeePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeNodeIdConnect>>;
  create?: InputMaybe<Array<EmployeeConsultingFeeCurrencyIdFkeyEmployeeCreateInput>>;
  deleteByCompanyEmail?: InputMaybe<Array<EmployeeEmployeeCompanyEmailKeyDelete>>;
  deleteByEmployeeId?: InputMaybe<Array<EmployeeEmployeePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyEmail?: InputMaybe<Array<EmployeeOnEmployeeForEmployeeConsultingFeeCurrencyIdFkeyUsingEmployeeCompanyEmailKeyUpdate>>;
  updateByEmployeeId?: InputMaybe<Array<EmployeeOnEmployeeForEmployeeConsultingFeeCurrencyIdFkeyUsingEmployeePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<CurrencyOnEmployeeForEmployeeConsultingFeeCurrencyIdFkeyNodeIdUpdate>>;
}

export enum EmployeeConsultingFeeType {
  ANYMIND_JAPAN_SALARY = 'ANYMIND_JAPAN_SALARY',
  CONSULTING_FEE = 'CONSULTING_FEE',
}

export interface EmployeeConsultingFeeTypeListFilter {
  anyEqualTo?: InputMaybe<EmployeeConsultingFeeType>;
  anyGreaterThan?: InputMaybe<EmployeeConsultingFeeType>;
  anyGreaterThanOrEqualTo?: InputMaybe<EmployeeConsultingFeeType>;
  anyLessThan?: InputMaybe<EmployeeConsultingFeeType>;
  anyLessThanOrEqualTo?: InputMaybe<EmployeeConsultingFeeType>;
  anyNotEqualTo?: InputMaybe<EmployeeConsultingFeeType>;
  containedBy?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  contains?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  distinctFrom?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  equalTo?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  greaterThan?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  greaterThanOrEqualTo?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  notDistinctFrom?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  notEqualTo?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  overlaps?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
}

export interface EmployeeCurrentPosition extends Node {
  __typename?: 'EmployeeCurrentPosition';
  businessUnit?: Maybe<BusinessUnit>;
  businessUnitId?: Maybe<Scalars['BigInt']['output']>;
  city?: Maybe<City>;
  cityId?: Maybe<Scalars['BigInt']['output']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['BigInt']['output']>;
  contractType?: Maybe<ContractType>;
  contractTypeId?: Maybe<Scalars['BigInt']['output']>;
  countryByCountryCodeAlpha2?: Maybe<Country>;
  countryCodeAlpha2?: Maybe<Scalars['String']['output']>;
  department?: Maybe<Department>;
  departmentId?: Maybe<Scalars['BigInt']['output']>;
  division?: Maybe<Division>;
  divisionId?: Maybe<Scalars['BigInt']['output']>;
  effectiveDate?: Maybe<Scalars['Date']['output']>;
  effectiveDateUntil?: Maybe<Scalars['Date']['output']>;
  employee: Employee;
  employeeCode?: Maybe<Scalars['String']['output']>;
  employeeId: Scalars['BigInt']['output'];
  employeeLevel?: Maybe<EmployeeLevel>;
  employeeLevelId?: Maybe<Scalars['BigInt']['output']>;
  employeeTpHistory?: Maybe<EmployeeTpHistory>;
  employeeTpHistoryId?: Maybe<Scalars['BigInt']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  nodeId: Scalars['ID']['output'];
  office?: Maybe<Office>;
  officeId?: Maybe<Scalars['BigInt']['output']>;
  team?: Maybe<Scalars['String']['output']>;
}

export interface EmployeeCurrentPositionCondition {
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeCurrentPositionFakePublicEmployeeCurrentPositionPrimaryKeyConnect {
  employeeId: Scalars['BigInt']['input'];
}

export interface EmployeeCurrentPositionFilter {
  and?: InputMaybe<Array<EmployeeCurrentPositionFilter>>;
  businessUnit?: InputMaybe<BusinessUnitFilter>;
  businessUnitExists?: InputMaybe<Scalars['Boolean']['input']>;
  businessUnitId?: InputMaybe<BigIntFilter>;
  city?: InputMaybe<CityFilter>;
  cityExists?: InputMaybe<Scalars['Boolean']['input']>;
  cityId?: InputMaybe<BigIntFilter>;
  company?: InputMaybe<CompanyFilter>;
  companyExists?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<BigIntFilter>;
  contractType?: InputMaybe<ContractTypeFilter>;
  contractTypeExists?: InputMaybe<Scalars['Boolean']['input']>;
  contractTypeId?: InputMaybe<BigIntFilter>;
  countryByCountryCodeAlpha2?: InputMaybe<CountryFilter>;
  countryByCountryCodeAlpha2Exists?: InputMaybe<Scalars['Boolean']['input']>;
  countryCodeAlpha2?: InputMaybe<StringFilter>;
  department?: InputMaybe<DepartmentFilter>;
  departmentExists?: InputMaybe<Scalars['Boolean']['input']>;
  departmentId?: InputMaybe<BigIntFilter>;
  division?: InputMaybe<DivisionFilter>;
  divisionExists?: InputMaybe<Scalars['Boolean']['input']>;
  divisionId?: InputMaybe<BigIntFilter>;
  effectiveDate?: InputMaybe<DateFilter>;
  effectiveDateUntil?: InputMaybe<DateFilter>;
  employee?: InputMaybe<EmployeeFilter>;
  employeeCode?: InputMaybe<StringFilter>;
  employeeExists?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<BigIntFilter>;
  employeeLevel?: InputMaybe<EmployeeLevelFilter>;
  employeeLevelExists?: InputMaybe<Scalars['Boolean']['input']>;
  employeeLevelId?: InputMaybe<BigIntFilter>;
  employeeTpHistory?: InputMaybe<EmployeeTpHistoryFilter>;
  employeeTpHistoryExists?: InputMaybe<Scalars['Boolean']['input']>;
  employeeTpHistoryId?: InputMaybe<BigIntFilter>;
  jobTitle?: InputMaybe<StringFilter>;
  not?: InputMaybe<EmployeeCurrentPositionFilter>;
  office?: InputMaybe<OfficeFilter>;
  officeExists?: InputMaybe<Scalars['Boolean']['input']>;
  officeId?: InputMaybe<BigIntFilter>;
  or?: InputMaybe<Array<EmployeeCurrentPositionFilter>>;
  team?: InputMaybe<StringFilter>;
}

export interface EmployeeCurrentPositionNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey0NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey0UsingFakePublicEmployeeCurrentPositionPrimaryKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey0Patch;
}

export interface EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey1NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeTpHistoryPatch;
}

export interface EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey1UsingFakePublicEmployeeCurrentPositionPrimaryKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey1Patch;
}

export interface EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey2NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ContractTypePatch;
}

export interface EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey2UsingFakePublicEmployeeCurrentPositionPrimaryKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey2Patch;
}

export interface EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey3NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeLevelPatch;
}

export interface EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey3UsingFakePublicEmployeeCurrentPositionPrimaryKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey3Patch;
}

export interface EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey4NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CountryPatch;
}

export interface EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey4UsingFakePublicEmployeeCurrentPositionPrimaryKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey4Patch;
}

export interface EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey5NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyPatch;
}

export interface EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey5UsingFakePublicEmployeeCurrentPositionPrimaryKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey5Patch;
}

export interface EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey6NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CityPatch;
}

export interface EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey6UsingFakePublicEmployeeCurrentPositionPrimaryKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey6Patch;
}

export interface EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey7NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: OfficePatch;
}

export interface EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey7UsingFakePublicEmployeeCurrentPositionPrimaryKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey7Patch;
}

export interface EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey8NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: BusinessUnitPatch;
}

export interface EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey8UsingFakePublicEmployeeCurrentPositionPrimaryKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey8Patch;
}

export interface EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey9NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: DivisionPatch;
}

export interface EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey9UsingFakePublicEmployeeCurrentPositionPrimaryKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey9Patch;
}

export interface EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey10NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: DepartmentPatch;
}

export interface EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey10UsingFakePublicEmployeeCurrentPositionPrimaryKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey10Patch;
}

export interface EmployeeCurrentPositionPatch {
  businessUnit?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8Input>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  city?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey6Input>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5Input>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2Input>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4Input>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey10Input>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9Input>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0Input>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey3Input>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1Input>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7Input>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeCurrentPositionsConnection {
  __typename?: 'EmployeeCurrentPositionsConnection';
  edges: Array<EmployeeCurrentPositionsEdge>;
  nodes: Array<EmployeeCurrentPosition>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeCurrentPositionsEdge {
  __typename?: 'EmployeeCurrentPositionsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeCurrentPosition;
}

export enum EmployeeCurrentPositionsOrderBy {
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_DESC',
  BUSINESS_UNIT_ID_ASC = 'BUSINESS_UNIT_ID_ASC',
  BUSINESS_UNIT_ID_DESC = 'BUSINESS_UNIT_ID_DESC',
  CITY_BY_CITY_ID__CITY_ID_ASC = 'CITY_BY_CITY_ID__CITY_ID_ASC',
  CITY_BY_CITY_ID__CITY_ID_DESC = 'CITY_BY_CITY_ID__CITY_ID_DESC',
  CITY_BY_CITY_ID__COUNTRY_CODE_ALPHA2_ASC = 'CITY_BY_CITY_ID__COUNTRY_CODE_ALPHA2_ASC',
  CITY_BY_CITY_ID__COUNTRY_CODE_ALPHA2_DESC = 'CITY_BY_CITY_ID__COUNTRY_CODE_ALPHA2_DESC',
  CITY_BY_CITY_ID__DATE_CREATED_ASC = 'CITY_BY_CITY_ID__DATE_CREATED_ASC',
  CITY_BY_CITY_ID__DATE_CREATED_DESC = 'CITY_BY_CITY_ID__DATE_CREATED_DESC',
  CITY_BY_CITY_ID__DATE_UPDATED_ASC = 'CITY_BY_CITY_ID__DATE_UPDATED_ASC',
  CITY_BY_CITY_ID__DATE_UPDATED_DESC = 'CITY_BY_CITY_ID__DATE_UPDATED_DESC',
  CITY_BY_CITY_ID__NAME_ASC = 'CITY_BY_CITY_ID__NAME_ASC',
  CITY_BY_CITY_ID__NAME_DESC = 'CITY_BY_CITY_ID__NAME_DESC',
  CITY_BY_CITY_ID__REMARK_ASC = 'CITY_BY_CITY_ID__REMARK_ASC',
  CITY_BY_CITY_ID__REMARK_DESC = 'CITY_BY_CITY_ID__REMARK_DESC',
  CITY_BY_CITY_ID__USER_CREATED_ASC = 'CITY_BY_CITY_ID__USER_CREATED_ASC',
  CITY_BY_CITY_ID__USER_CREATED_DESC = 'CITY_BY_CITY_ID__USER_CREATED_DESC',
  CITY_BY_CITY_ID__USER_UPDATED_ASC = 'CITY_BY_CITY_ID__USER_UPDATED_ASC',
  CITY_BY_CITY_ID__USER_UPDATED_DESC = 'CITY_BY_CITY_ID__USER_UPDATED_DESC',
  CITY_BY_CITY_ID__UTC_OFFSET_ASC = 'CITY_BY_CITY_ID__UTC_OFFSET_ASC',
  CITY_BY_CITY_ID__UTC_OFFSET_DESC = 'CITY_BY_CITY_ID__UTC_OFFSET_DESC',
  CITY_ID_ASC = 'CITY_ID_ASC',
  CITY_ID_DESC = 'CITY_ID_DESC',
  COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC',
  COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_ASC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_ASC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_DESC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_DESC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANY_BY_COMPANY_ID__REMARK_ASC = 'COMPANY_BY_COMPANY_ID__REMARK_ASC',
  COMPANY_BY_COMPANY_ID__REMARK_DESC = 'COMPANY_BY_COMPANY_ID__REMARK_DESC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC',
  COMPANY_ID_ASC = 'COMPANY_ID_ASC',
  COMPANY_ID_DESC = 'COMPANY_ID_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CODE_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CODE_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CODE_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CODE_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CONTRACT_TYPE_ID_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CONTRACT_TYPE_ID_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CONTRACT_TYPE_ID_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CONTRACT_TYPE_ID_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_CREATED_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_CREATED_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_CREATED_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_CREATED_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_UPDATED_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_UPDATED_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_UPDATED_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_UPDATED_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DISPLAY_NAME_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DISPLAY_NAME_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DISPLAY_NAME_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DISPLAY_NAME_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__REMARK_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__REMARK_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__REMARK_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__REMARK_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_CREATED_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_CREATED_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_CREATED_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_CREATED_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_UPDATED_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_UPDATED_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_UPDATED_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_UPDATED_DESC',
  CONTRACT_TYPE_ID_ASC = 'CONTRACT_TYPE_ID_ASC',
  CONTRACT_TYPE_ID_DESC = 'CONTRACT_TYPE_ID_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_CREATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_CREATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_CREATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_CREATED_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_UPDATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_UPDATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_UPDATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_UPDATED_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__REMARK_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__REMARK_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__REMARK_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__REMARK_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__SHORT_NAME_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__SHORT_NAME_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__SHORT_NAME_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__SHORT_NAME_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_CREATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_CREATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_CREATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_CREATED_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_UPDATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_UPDATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_UPDATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_UPDATED_DESC',
  COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_CODE_ALPHA2_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__DATE_CREATED_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__DATE_CREATED_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__DATE_CREATED_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__DATE_CREATED_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__DATE_UPDATED_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__DATE_UPDATED_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__DATE_UPDATED_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__DATE_UPDATED_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_ID_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_ID_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_ID_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_ID_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__IS_ACTIVE_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__IS_ACTIVE_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__IS_ACTIVE_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__IS_ACTIVE_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__REMARK_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__REMARK_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__REMARK_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__REMARK_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__USER_CREATED_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__USER_CREATED_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__USER_CREATED_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__USER_CREATED_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__USER_UPDATED_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__USER_UPDATED_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__USER_UPDATED_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__USER_UPDATED_DESC',
  DEPARTMENT_ID_ASC = 'DEPARTMENT_ID_ASC',
  DEPARTMENT_ID_DESC = 'DEPARTMENT_ID_DESC',
  DIVISION_BY_DIVISION_ID__DATE_CREATED_ASC = 'DIVISION_BY_DIVISION_ID__DATE_CREATED_ASC',
  DIVISION_BY_DIVISION_ID__DATE_CREATED_DESC = 'DIVISION_BY_DIVISION_ID__DATE_CREATED_DESC',
  DIVISION_BY_DIVISION_ID__DATE_UPDATED_ASC = 'DIVISION_BY_DIVISION_ID__DATE_UPDATED_ASC',
  DIVISION_BY_DIVISION_ID__DATE_UPDATED_DESC = 'DIVISION_BY_DIVISION_ID__DATE_UPDATED_DESC',
  DIVISION_BY_DIVISION_ID__DIVISION_ID_ASC = 'DIVISION_BY_DIVISION_ID__DIVISION_ID_ASC',
  DIVISION_BY_DIVISION_ID__DIVISION_ID_DESC = 'DIVISION_BY_DIVISION_ID__DIVISION_ID_DESC',
  DIVISION_BY_DIVISION_ID__IS_ACTIVE_ASC = 'DIVISION_BY_DIVISION_ID__IS_ACTIVE_ASC',
  DIVISION_BY_DIVISION_ID__IS_ACTIVE_DESC = 'DIVISION_BY_DIVISION_ID__IS_ACTIVE_DESC',
  DIVISION_BY_DIVISION_ID__NAME_ASC = 'DIVISION_BY_DIVISION_ID__NAME_ASC',
  DIVISION_BY_DIVISION_ID__NAME_DESC = 'DIVISION_BY_DIVISION_ID__NAME_DESC',
  DIVISION_BY_DIVISION_ID__REMARK_ASC = 'DIVISION_BY_DIVISION_ID__REMARK_ASC',
  DIVISION_BY_DIVISION_ID__REMARK_DESC = 'DIVISION_BY_DIVISION_ID__REMARK_DESC',
  DIVISION_BY_DIVISION_ID__USER_CREATED_ASC = 'DIVISION_BY_DIVISION_ID__USER_CREATED_ASC',
  DIVISION_BY_DIVISION_ID__USER_CREATED_DESC = 'DIVISION_BY_DIVISION_ID__USER_CREATED_DESC',
  DIVISION_BY_DIVISION_ID__USER_UPDATED_ASC = 'DIVISION_BY_DIVISION_ID__USER_UPDATED_ASC',
  DIVISION_BY_DIVISION_ID__USER_UPDATED_DESC = 'DIVISION_BY_DIVISION_ID__USER_UPDATED_DESC',
  DIVISION_ID_ASC = 'DIVISION_ID_ASC',
  DIVISION_ID_DESC = 'DIVISION_ID_DESC',
  EFFECTIVE_DATE_ASC = 'EFFECTIVE_DATE_ASC',
  EFFECTIVE_DATE_DESC = 'EFFECTIVE_DATE_DESC',
  EFFECTIVE_DATE_UNTIL_ASC = 'EFFECTIVE_DATE_UNTIL_ASC',
  EFFECTIVE_DATE_UNTIL_DESC = 'EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_CODE_ASC = 'EMPLOYEE_CODE_ASC',
  EMPLOYEE_CODE_DESC = 'EMPLOYEE_CODE_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_COUNTRY_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_COUNTRY_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_COUNTRY_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_COUNTRY_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_INDIVIDUAL_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_INDIVIDUAL_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_INDIVIDUAL_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_INDIVIDUAL_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_TEAM_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_TEAM_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_TEAM_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_TEAM_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PROBATION_REVIEW_OF_ALL_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PROBATION_REVIEW_OF_ALL_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PROBATION_REVIEW_OF_ALL_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PROBATION_REVIEW_OF_ALL_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DATE_CREATED_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DATE_CREATED_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DATE_CREATED_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DATE_CREATED_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DATE_UPDATED_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DATE_UPDATED_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DATE_UPDATED_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DATE_UPDATED_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DISPLAY_NAME_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DISPLAY_NAME_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DISPLAY_NAME_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DISPLAY_NAME_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__EMPLOYEE_LEVEL_ORDER_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__EMPLOYEE_LEVEL_ORDER_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__EMPLOYEE_LEVEL_ORDER_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__EMPLOYEE_LEVEL_ORDER_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__REMARK_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__REMARK_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__REMARK_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__REMARK_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__TITLE_CONTRIBUTOR_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__TITLE_CONTRIBUTOR_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__TITLE_CONTRIBUTOR_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__TITLE_CONTRIBUTOR_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__TITLE_MANAGER_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__TITLE_MANAGER_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__TITLE_MANAGER_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__TITLE_MANAGER_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__USER_CREATED_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__USER_CREATED_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__USER_CREATED_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__USER_CREATED_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__USER_UPDATED_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__USER_UPDATED_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__USER_UPDATED_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__USER_UPDATED_DESC',
  EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__COMPANY_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__COMPANY_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__COMPANY_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__COMPANY_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_CREATED_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_CREATED_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_CREATED_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_CREATED_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_UPDATED_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_UPDATED_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_UPDATED_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_UPDATED_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DEPARTMENT_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DEPARTMENT_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DEPARTMENT_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DEPARTMENT_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DIVISION_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DIVISION_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DIVISION_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DIVISION_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_CODE_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_CODE_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_CODE_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_CODE_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__JOB_TITLE_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__JOB_TITLE_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__JOB_TITLE_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__JOB_TITLE_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__OFFICE_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__OFFICE_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__OFFICE_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__OFFICE_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__REMARK_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__REMARK_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__REMARK_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__REMARK_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__TEAM_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__TEAM_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__TEAM_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__TEAM_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_CREATED_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_CREATED_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_CREATED_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_CREATED_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_UPDATED_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_UPDATED_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_UPDATED_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_UPDATED_DESC',
  EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORY_ID_DESC',
  JOB_TITLE_ASC = 'JOB_TITLE_ASC',
  JOB_TITLE_DESC = 'JOB_TITLE_DESC',
  NATURAL = 'NATURAL',
  OFFICE_BY_OFFICE_ID__CITY_ID_ASC = 'OFFICE_BY_OFFICE_ID__CITY_ID_ASC',
  OFFICE_BY_OFFICE_ID__CITY_ID_DESC = 'OFFICE_BY_OFFICE_ID__CITY_ID_DESC',
  OFFICE_BY_OFFICE_ID__DATE_CREATED_ASC = 'OFFICE_BY_OFFICE_ID__DATE_CREATED_ASC',
  OFFICE_BY_OFFICE_ID__DATE_CREATED_DESC = 'OFFICE_BY_OFFICE_ID__DATE_CREATED_DESC',
  OFFICE_BY_OFFICE_ID__DATE_UPDATED_ASC = 'OFFICE_BY_OFFICE_ID__DATE_UPDATED_ASC',
  OFFICE_BY_OFFICE_ID__DATE_UPDATED_DESC = 'OFFICE_BY_OFFICE_ID__DATE_UPDATED_DESC',
  OFFICE_BY_OFFICE_ID__LATITUDE_ASC = 'OFFICE_BY_OFFICE_ID__LATITUDE_ASC',
  OFFICE_BY_OFFICE_ID__LATITUDE_DESC = 'OFFICE_BY_OFFICE_ID__LATITUDE_DESC',
  OFFICE_BY_OFFICE_ID__LONGITUDE_ASC = 'OFFICE_BY_OFFICE_ID__LONGITUDE_ASC',
  OFFICE_BY_OFFICE_ID__LONGITUDE_DESC = 'OFFICE_BY_OFFICE_ID__LONGITUDE_DESC',
  OFFICE_BY_OFFICE_ID__NAME_ASC = 'OFFICE_BY_OFFICE_ID__NAME_ASC',
  OFFICE_BY_OFFICE_ID__NAME_DESC = 'OFFICE_BY_OFFICE_ID__NAME_DESC',
  OFFICE_BY_OFFICE_ID__OFFICE_ID_ASC = 'OFFICE_BY_OFFICE_ID__OFFICE_ID_ASC',
  OFFICE_BY_OFFICE_ID__OFFICE_ID_DESC = 'OFFICE_BY_OFFICE_ID__OFFICE_ID_DESC',
  OFFICE_BY_OFFICE_ID__REMARK_ASC = 'OFFICE_BY_OFFICE_ID__REMARK_ASC',
  OFFICE_BY_OFFICE_ID__REMARK_DESC = 'OFFICE_BY_OFFICE_ID__REMARK_DESC',
  OFFICE_BY_OFFICE_ID__USER_CREATED_ASC = 'OFFICE_BY_OFFICE_ID__USER_CREATED_ASC',
  OFFICE_BY_OFFICE_ID__USER_CREATED_DESC = 'OFFICE_BY_OFFICE_ID__USER_CREATED_DESC',
  OFFICE_BY_OFFICE_ID__USER_UPDATED_ASC = 'OFFICE_BY_OFFICE_ID__USER_UPDATED_ASC',
  OFFICE_BY_OFFICE_ID__USER_UPDATED_DESC = 'OFFICE_BY_OFFICE_ID__USER_UPDATED_DESC',
  OFFICE_ID_ASC = 'OFFICE_ID_ASC',
  OFFICE_ID_DESC = 'OFFICE_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  TEAM_ASC = 'TEAM_ASC',
  TEAM_DESC = 'TEAM_DESC',
}

export interface EmployeeDaysByContractType extends Node {
  __typename?: 'EmployeeDaysByContractType';
  contractTypeCode: Scalars['String']['output'];
  contractTypeName: Scalars['String']['output'];
  days?: Maybe<Scalars['Int']['output']>;
  effectiveDate: Scalars['Date']['output'];
  employee: Employee;
  employeeId: Scalars['BigInt']['output'];
  flag: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
}

export interface EmployeeDaysByContractTypeCondition {
  contractTypeCode?: InputMaybe<Scalars['String']['input']>;
  contractTypeName?: InputMaybe<Scalars['String']['input']>;
  days?: InputMaybe<Scalars['Int']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  flag?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeDaysByContractTypeFakePublicEmployeeDaysByContractTypePrimaryKeyConnect {
  effectiveDate: Scalars['Date']['input'];
  employeeId: Scalars['BigInt']['input'];
}

export interface EmployeeDaysByContractTypeFilter {
  and?: InputMaybe<Array<EmployeeDaysByContractTypeFilter>>;
  contractTypeCode?: InputMaybe<StringFilter>;
  contractTypeName?: InputMaybe<StringFilter>;
  days?: InputMaybe<IntFilter>;
  effectiveDate?: InputMaybe<DateFilter>;
  employee?: InputMaybe<EmployeeFilter>;
  employeeExists?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<BigIntFilter>;
  flag?: InputMaybe<StringFilter>;
  not?: InputMaybe<EmployeeDaysByContractTypeFilter>;
  or?: InputMaybe<Array<EmployeeDaysByContractTypeFilter>>;
}

export interface EmployeeDaysByContractTypeNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeDaysByContractTypeOnEmployeeDaysByContractTypeForFakePublicEmployeeDaysByContractTypeForeignKey0NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface EmployeeDaysByContractTypeOnEmployeeDaysByContractTypeForFakePublicEmployeeDaysByContractTypeForeignKey0UsingFakePublicEmployeeDaysByContractTypePrimaryKeyUpdate {
  effectiveDate: Scalars['Date']['input'];
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeDaysByContractTypeOnEmployeeDaysByContractTypeForFakePublicEmployeeDaysByContractTypeForeignKey0Patch;
}

export interface EmployeeDaysByContractTypePatch {
  contractTypeCode?: InputMaybe<Scalars['String']['input']>;
  contractTypeName?: InputMaybe<Scalars['String']['input']>;
  days?: InputMaybe<Scalars['Int']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0Input>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  flag?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeDaysByContractTypesConnection {
  __typename?: 'EmployeeDaysByContractTypesConnection';
  edges: Array<EmployeeDaysByContractTypesEdge>;
  nodes: Array<EmployeeDaysByContractType>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeDaysByContractTypesEdge {
  __typename?: 'EmployeeDaysByContractTypesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeDaysByContractType;
}

export enum EmployeeDaysByContractTypesOrderBy {
  CONTRACT_TYPE_CODE_ASC = 'CONTRACT_TYPE_CODE_ASC',
  CONTRACT_TYPE_CODE_DESC = 'CONTRACT_TYPE_CODE_DESC',
  CONTRACT_TYPE_NAME_ASC = 'CONTRACT_TYPE_NAME_ASC',
  CONTRACT_TYPE_NAME_DESC = 'CONTRACT_TYPE_NAME_DESC',
  DAYS_ASC = 'DAYS_ASC',
  DAYS_DESC = 'DAYS_DESC',
  EFFECTIVE_DATE_ASC = 'EFFECTIVE_DATE_ASC',
  EFFECTIVE_DATE_DESC = 'EFFECTIVE_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  FLAG_ASC = 'FLAG_ASC',
  FLAG_DESC = 'FLAG_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
}

export interface EmployeeDlmListConnection {
  __typename?: 'EmployeeDlmListConnection';
  edges: Array<EmployeeDlmListEdge>;
  nodes: Array<EmployeeDlmListRecord>;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeDlmListEdge {
  __typename?: 'EmployeeDlmListEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeDlmListRecord;
}

export interface EmployeeDlmListRecord {
  __typename?: 'EmployeeDlmListRecord';
  depth?: Maybe<Scalars['Int']['output']>;
  employeeId?: Maybe<Scalars['BigInt']['output']>;
  employeeLevel?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  fullNameInLocalLanguage?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
}

export interface EmployeeDlmListRecordFilter {
  and?: InputMaybe<Array<EmployeeDlmListRecordFilter>>;
  depth?: InputMaybe<IntFilter>;
  employeeId?: InputMaybe<BigIntFilter>;
  employeeLevel?: InputMaybe<StringFilter>;
  fullName?: InputMaybe<StringFilter>;
  fullNameInLocalLanguage?: InputMaybe<StringFilter>;
  nickname?: InputMaybe<StringFilter>;
  not?: InputMaybe<EmployeeDlmListRecordFilter>;
  or?: InputMaybe<Array<EmployeeDlmListRecordFilter>>;
}

export interface EmployeeDottedLineManagerIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeDottedLineManagerIdFkeyInput {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<EmployeeDottedLineManagerIdFkeyEmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnEmployeeForEmployeeDottedLineManagerIdFkeyUsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnEmployeeForEmployeeDottedLineManagerIdFkeyUsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeOnEmployeeForEmployeeDottedLineManagerIdFkeyNodeIdUpdate>;
}

export interface EmployeeEmergencyContact extends Node {
  __typename?: 'EmployeeEmergencyContact';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  employee: Employee;
  employeeEmergencyContactId: Scalars['BigInt']['output'];
  employeeId: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  relation?: Maybe<RelationEmergencyContact>;
  relationId?: Maybe<Scalars['BigInt']['output']>;
  remark?: Maybe<Scalars['String']['output']>;
  telephoneNumber?: Maybe<Scalars['String']['output']>;
  telephoneNumberCountry?: Maybe<Country>;
  telephoneNumberCountryId?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface EmployeeEmergencyContactCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  employeeEmergencyContactId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  relationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  telephoneNumber?: InputMaybe<Scalars['String']['input']>;
  telephoneNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeEmergencyContactEmployeeEmergencyContactEmployeeIdRelationIdNameKeyConnect {
  employeeId: Scalars['BigInt']['input'];
  name: Scalars['String']['input'];
  relationId: Scalars['BigInt']['input'];
}

export interface EmployeeEmergencyContactEmployeeEmergencyContactEmployeeIdRelationIdNameKeyDelete {
  employeeId: Scalars['BigInt']['input'];
  name: Scalars['String']['input'];
  relationId: Scalars['BigInt']['input'];
}

export interface EmployeeEmergencyContactEmployeeEmergencyContactPkeyConnect {
  employeeEmergencyContactId: Scalars['BigInt']['input'];
}

export interface EmployeeEmergencyContactEmployeeEmergencyContactPkeyDelete {
  employeeEmergencyContactId: Scalars['BigInt']['input'];
}

export interface EmployeeEmergencyContactEmployeeIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeEmergencyContactEmployeeIdFkeyEmployeeEmergencyContactCreateInput {
  country?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInput>;
  employee?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInput>;
  employeeEmergencyContactId?: InputMaybe<Scalars['BigInt']['input']>;
  name: Scalars['String']['input'];
  relationEmergencyContact?: InputMaybe<EmployeeEmergencyContactRelationIdFkeyInput>;
  relationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  telephoneNumber?: InputMaybe<Scalars['String']['input']>;
  telephoneNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeEmergencyContactEmployeeIdFkeyInput {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyEmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnEmployeeEmergencyContactForEmployeeEmergencyContactEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnEmployeeEmergencyContactForEmployeeEmergencyContactEmployeeIdFkeyUsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactEmployeeIdFkeyNodeIdUpdate>;
}

export interface EmployeeEmergencyContactEmployeeIdFkeyInverseInput {
  connectByEmployeeEmergencyContactId?: InputMaybe<Array<EmployeeEmergencyContactEmployeeEmergencyContactPkeyConnect>>;
  connectByEmployeeIdAndRelationIdAndName?: InputMaybe<Array<EmployeeEmergencyContactEmployeeEmergencyContactEmployeeIdRelationIdNameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeEmergencyContactNodeIdConnect>>;
  create?: InputMaybe<Array<EmployeeEmergencyContactEmployeeIdFkeyEmployeeEmergencyContactCreateInput>>;
  deleteByEmployeeEmergencyContactId?: InputMaybe<Array<EmployeeEmergencyContactEmployeeEmergencyContactPkeyDelete>>;
  deleteByEmployeeIdAndRelationIdAndName?: InputMaybe<Array<EmployeeEmergencyContactEmployeeEmergencyContactEmployeeIdRelationIdNameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeEmergencyContactNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeEmergencyContactId?: InputMaybe<Array<EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactEmployeeIdFkeyUsingEmployeeEmergencyContactPkeyUpdate>>;
  updateByEmployeeIdAndRelationIdAndName?: InputMaybe<
    Array<EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactEmployeeIdFkeyUsingEmployeeEmergencyContactEmployeeIdRelationIdNameKeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<EmployeeOnEmployeeEmergencyContactForEmployeeEmergencyContactEmployeeIdFkeyNodeIdUpdate>>;
}

export interface EmployeeEmergencyContactFilter {
  and?: InputMaybe<Array<EmployeeEmergencyContactFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  employee?: InputMaybe<EmployeeFilter>;
  employeeEmergencyContactId?: InputMaybe<BigIntFilter>;
  employeeId?: InputMaybe<BigIntFilter>;
  name?: InputMaybe<StringFilter>;
  not?: InputMaybe<EmployeeEmergencyContactFilter>;
  or?: InputMaybe<Array<EmployeeEmergencyContactFilter>>;
  relation?: InputMaybe<RelationEmergencyContactFilter>;
  relationExists?: InputMaybe<Scalars['Boolean']['input']>;
  relationId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<StringFilter>;
  telephoneNumber?: InputMaybe<StringFilter>;
  telephoneNumberCountry?: InputMaybe<CountryFilter>;
  telephoneNumberCountryExists?: InputMaybe<Scalars['Boolean']['input']>;
  telephoneNumberCountryId?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface EmployeeEmergencyContactInput {
  country?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInput>;
  employee?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInput>;
  employeeEmergencyContactId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  name: Scalars['String']['input'];
  relationEmergencyContact?: InputMaybe<EmployeeEmergencyContactRelationIdFkeyInput>;
  relationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  telephoneNumber?: InputMaybe<Scalars['String']['input']>;
  telephoneNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeEmergencyContactNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeEmergencyContactNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactEmployeeIdFkeyUsingEmployeeEmergencyContactEmployeeIdRelationIdNameKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  name: Scalars['String']['input'];
  patch: UpdateEmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactEmployeeIdFkeyPatch;
  relationId: Scalars['BigInt']['input'];
}

export interface EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactEmployeeIdFkeyUsingEmployeeEmergencyContactPkeyUpdate {
  employeeEmergencyContactId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactEmployeeIdFkeyPatch;
}

export interface EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactRelationIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationEmergencyContactPatch;
}

export interface EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactRelationIdFkeyUsingEmployeeEmergencyContactEmployeeIdRelationIdNameKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  name: Scalars['String']['input'];
  patch: UpdateEmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactRelationIdFkeyPatch;
  relationId: Scalars['BigInt']['input'];
}

export interface EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactRelationIdFkeyUsingEmployeeEmergencyContactPkeyUpdate {
  employeeEmergencyContactId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactRelationIdFkeyPatch;
}

export interface EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactTelephoneNumberCountryIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CountryPatch;
}

export interface EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactTelephoneNumberCountryIdFkeyUsingEmployeeEmergencyContactEmployeeIdRelationIdNameKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  name: Scalars['String']['input'];
  patch: UpdateEmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactTelephoneNumberCountryIdFkeyPatch;
  relationId: Scalars['BigInt']['input'];
}

export interface EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactTelephoneNumberCountryIdFkeyUsingEmployeeEmergencyContactPkeyUpdate {
  employeeEmergencyContactId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactTelephoneNumberCountryIdFkeyPatch;
}

export interface EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactUserCreatedFkeyUsingEmployeeEmergencyContactEmployeeIdRelationIdNameKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  name: Scalars['String']['input'];
  patch: UpdateEmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactUserCreatedFkeyPatch;
  relationId: Scalars['BigInt']['input'];
}

export interface EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactUserCreatedFkeyUsingEmployeeEmergencyContactPkeyUpdate {
  employeeEmergencyContactId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactUserCreatedFkeyPatch;
}

export interface EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactUserUpdatedFkeyUsingEmployeeEmergencyContactEmployeeIdRelationIdNameKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  name: Scalars['String']['input'];
  patch: UpdateEmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactUserUpdatedFkeyPatch;
  relationId: Scalars['BigInt']['input'];
}

export interface EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactUserUpdatedFkeyUsingEmployeeEmergencyContactPkeyUpdate {
  employeeEmergencyContactId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactUserUpdatedFkeyPatch;
}

export interface EmployeeEmergencyContactPatch {
  country?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInput>;
  employee?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInput>;
  employeeEmergencyContactId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  relationEmergencyContact?: InputMaybe<EmployeeEmergencyContactRelationIdFkeyInput>;
  relationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  telephoneNumber?: InputMaybe<Scalars['String']['input']>;
  telephoneNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeEmergencyContactRelationIdFkeyEmployeeEmergencyContactCreateInput {
  country?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInput>;
  employee?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInput>;
  employeeEmergencyContactId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  name: Scalars['String']['input'];
  relationEmergencyContact?: InputMaybe<EmployeeEmergencyContactRelationIdFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  telephoneNumber?: InputMaybe<Scalars['String']['input']>;
  telephoneNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeEmergencyContactRelationIdFkeyInput {
  connectByDisplayName?: InputMaybe<RelationEmergencyContactRelationEmergencyContactDisplayNameKeyConnect>;
  connectByEmergencyContactRelationId?: InputMaybe<RelationEmergencyContactRelationEmergencyContactPkeyConnect>;
  connectByNodeId?: InputMaybe<RelationEmergencyContactNodeIdConnect>;
  updateByDisplayName?: InputMaybe<RelationEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactRelationIdFkeyUsingRelationEmergencyContactDisplayNameKeyUpdate>;
  updateByEmergencyContactRelationId?: InputMaybe<RelationEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactRelationIdFkeyUsingRelationEmergencyContactPkeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactRelationIdFkeyNodeIdUpdate>;
}

export interface EmployeeEmergencyContactRelationIdFkeyInverseInput {
  connectByEmployeeEmergencyContactId?: InputMaybe<Array<EmployeeEmergencyContactEmployeeEmergencyContactPkeyConnect>>;
  connectByEmployeeIdAndRelationIdAndName?: InputMaybe<Array<EmployeeEmergencyContactEmployeeEmergencyContactEmployeeIdRelationIdNameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeEmergencyContactNodeIdConnect>>;
  create?: InputMaybe<Array<EmployeeEmergencyContactRelationIdFkeyEmployeeEmergencyContactCreateInput>>;
  deleteByEmployeeEmergencyContactId?: InputMaybe<Array<EmployeeEmergencyContactEmployeeEmergencyContactPkeyDelete>>;
  deleteByEmployeeIdAndRelationIdAndName?: InputMaybe<Array<EmployeeEmergencyContactEmployeeEmergencyContactEmployeeIdRelationIdNameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeEmergencyContactNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeEmergencyContactId?: InputMaybe<Array<EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactRelationIdFkeyUsingEmployeeEmergencyContactPkeyUpdate>>;
  updateByEmployeeIdAndRelationIdAndName?: InputMaybe<
    Array<EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactRelationIdFkeyUsingEmployeeEmergencyContactEmployeeIdRelationIdNameKeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<RelationEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactRelationIdFkeyNodeIdUpdate>>;
}

export interface EmployeeEmergencyContactTelephoneNumberCountryIdFkeyCountryCreateInput {
  banks?: InputMaybe<BankCountryCodeAlpha3FkeyInverseInput>;
  cities?: InputMaybe<CityCountryCodeAlpha2FkeyInverseInput>;
  companies?: InputMaybe<CompanyCountryCodeAlpha2FkeyInverseInput>;
  countryCodeAlpha2: Scalars['String']['input'];
  countryCodeAlpha3: Scalars['String']['input'];
  countryCodeNumeric?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInverseInput>;
  employeeIdTypes?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInverseInput>;
  employeesToMobileNumberCountryIdUsingCountryCodeAlpha2?: InputMaybe<EmployeeMobileNumberCountryIdFkeyInverseInput>;
  employeesToNationalityUsingCountryCodeAlpha3?: InputMaybe<EmployeeNationalityFkeyInverseInput>;
  employeesToWorkingLocationCountryCodeAlpha2UsingCountryCodeAlpha2?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0InverseInput>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName: Scalars['String']['input'];
  telephoneCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeEmergencyContactTelephoneNumberCountryIdFkeyEmployeeEmergencyContactCreateInput {
  country?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInput>;
  employee?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInput>;
  employeeEmergencyContactId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  name: Scalars['String']['input'];
  relationEmergencyContact?: InputMaybe<EmployeeEmergencyContactRelationIdFkeyInput>;
  relationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  telephoneNumber?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInput {
  connectByCountryCodeAlpha2?: InputMaybe<CountryCountryPkeyConnect>;
  connectByCountryCodeAlpha3?: InputMaybe<CountryCountryCountryCodeAlpha3KeyConnect>;
  connectByCountryCodeNumeric?: InputMaybe<CountryCountryCountryCodeNumericKeyConnect>;
  connectByDisplayOrder?: InputMaybe<CountryCountryDisplayOrderKeyConnect>;
  connectByNodeId?: InputMaybe<CountryNodeIdConnect>;
  create?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyCountryCreateInput>;
  deleteByCountryCodeAlpha2?: InputMaybe<CountryCountryPkeyDelete>;
  deleteByCountryCodeAlpha3?: InputMaybe<CountryCountryCountryCodeAlpha3KeyDelete>;
  deleteByCountryCodeNumeric?: InputMaybe<CountryCountryCountryCodeNumericKeyDelete>;
  deleteByDisplayOrder?: InputMaybe<CountryCountryDisplayOrderKeyDelete>;
  deleteByNodeId?: InputMaybe<CountryNodeIdDelete>;
  updateByCountryCodeAlpha2?: InputMaybe<CountryOnEmployeeEmergencyContactForEmployeeEmergencyContactTelephoneNumberCountryIdFkeyUsingCountryPkeyUpdate>;
  updateByCountryCodeAlpha3?: InputMaybe<CountryOnEmployeeEmergencyContactForEmployeeEmergencyContactTelephoneNumberCountryIdFkeyUsingCountryCountryCodeAlpha3KeyUpdate>;
  updateByCountryCodeNumeric?: InputMaybe<CountryOnEmployeeEmergencyContactForEmployeeEmergencyContactTelephoneNumberCountryIdFkeyUsingCountryCountryCodeNumericKeyUpdate>;
  updateByDisplayOrder?: InputMaybe<CountryOnEmployeeEmergencyContactForEmployeeEmergencyContactTelephoneNumberCountryIdFkeyUsingCountryDisplayOrderKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactTelephoneNumberCountryIdFkeyNodeIdUpdate>;
}

export interface EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInverseInput {
  connectByEmployeeEmergencyContactId?: InputMaybe<Array<EmployeeEmergencyContactEmployeeEmergencyContactPkeyConnect>>;
  connectByEmployeeIdAndRelationIdAndName?: InputMaybe<Array<EmployeeEmergencyContactEmployeeEmergencyContactEmployeeIdRelationIdNameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeEmergencyContactNodeIdConnect>>;
  create?: InputMaybe<Array<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyEmployeeEmergencyContactCreateInput>>;
  deleteByEmployeeEmergencyContactId?: InputMaybe<Array<EmployeeEmergencyContactEmployeeEmergencyContactPkeyDelete>>;
  deleteByEmployeeIdAndRelationIdAndName?: InputMaybe<Array<EmployeeEmergencyContactEmployeeEmergencyContactEmployeeIdRelationIdNameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeEmergencyContactNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeEmergencyContactId?: InputMaybe<
    Array<EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactTelephoneNumberCountryIdFkeyUsingEmployeeEmergencyContactPkeyUpdate>
  >;
  updateByEmployeeIdAndRelationIdAndName?: InputMaybe<
    Array<EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactTelephoneNumberCountryIdFkeyUsingEmployeeEmergencyContactEmployeeIdRelationIdNameKeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<CountryOnEmployeeEmergencyContactForEmployeeEmergencyContactTelephoneNumberCountryIdFkeyNodeIdUpdate>>;
}

export interface EmployeeEmergencyContactUserCreatedFkeyInverseInput {
  connectByEmployeeEmergencyContactId?: InputMaybe<Array<EmployeeEmergencyContactEmployeeEmergencyContactPkeyConnect>>;
  connectByEmployeeIdAndRelationIdAndName?: InputMaybe<Array<EmployeeEmergencyContactEmployeeEmergencyContactEmployeeIdRelationIdNameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeEmergencyContactNodeIdConnect>>;
  deleteByEmployeeEmergencyContactId?: InputMaybe<Array<EmployeeEmergencyContactEmployeeEmergencyContactPkeyDelete>>;
  deleteByEmployeeIdAndRelationIdAndName?: InputMaybe<Array<EmployeeEmergencyContactEmployeeEmergencyContactEmployeeIdRelationIdNameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeEmergencyContactNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeEmergencyContactId?: InputMaybe<Array<EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactUserCreatedFkeyUsingEmployeeEmergencyContactPkeyUpdate>>;
  updateByEmployeeIdAndRelationIdAndName?: InputMaybe<
    Array<EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactUserCreatedFkeyUsingEmployeeEmergencyContactEmployeeIdRelationIdNameKeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmployeeEmergencyContactForEmployeeEmergencyContactUserCreatedFkeyNodeIdUpdate>>;
}

export interface EmployeeEmergencyContactUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnEmployeeEmergencyContactForEmployeeEmergencyContactUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnEmployeeEmergencyContactForEmployeeEmergencyContactUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface EmployeeEmergencyContactUserUpdatedFkeyInverseInput {
  connectByEmployeeEmergencyContactId?: InputMaybe<Array<EmployeeEmergencyContactEmployeeEmergencyContactPkeyConnect>>;
  connectByEmployeeIdAndRelationIdAndName?: InputMaybe<Array<EmployeeEmergencyContactEmployeeEmergencyContactEmployeeIdRelationIdNameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeEmergencyContactNodeIdConnect>>;
  deleteByEmployeeEmergencyContactId?: InputMaybe<Array<EmployeeEmergencyContactEmployeeEmergencyContactPkeyDelete>>;
  deleteByEmployeeIdAndRelationIdAndName?: InputMaybe<Array<EmployeeEmergencyContactEmployeeEmergencyContactEmployeeIdRelationIdNameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeEmergencyContactNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeEmergencyContactId?: InputMaybe<Array<EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactUserUpdatedFkeyUsingEmployeeEmergencyContactPkeyUpdate>>;
  updateByEmployeeIdAndRelationIdAndName?: InputMaybe<
    Array<EmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactUserUpdatedFkeyUsingEmployeeEmergencyContactEmployeeIdRelationIdNameKeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmployeeEmergencyContactForEmployeeEmergencyContactUserUpdatedFkeyNodeIdUpdate>>;
}

export interface EmployeeEmergencyContactsConnection {
  __typename?: 'EmployeeEmergencyContactsConnection';
  edges: Array<EmployeeEmergencyContactsEdge>;
  nodes: Array<EmployeeEmergencyContact>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeEmergencyContactsEdge {
  __typename?: 'EmployeeEmergencyContactsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeEmergencyContact;
}

export enum EmployeeEmergencyContactsOrderBy {
  COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__COUNTRY_CODE_ALPHA2_DESC',
  COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__COUNTRY_CODE_ALPHA3_ASC = 'COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__COUNTRY_CODE_ALPHA3_ASC',
  COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__COUNTRY_CODE_ALPHA3_DESC = 'COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__COUNTRY_CODE_ALPHA3_DESC',
  COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__COUNTRY_CODE_NUMERIC_ASC = 'COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__COUNTRY_CODE_NUMERIC_ASC',
  COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__COUNTRY_CODE_NUMERIC_DESC = 'COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__COUNTRY_CODE_NUMERIC_DESC',
  COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__DATE_CREATED_ASC = 'COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__DATE_CREATED_ASC',
  COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__DATE_CREATED_DESC = 'COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__DATE_CREATED_DESC',
  COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__DATE_UPDATED_ASC = 'COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__DATE_UPDATED_ASC',
  COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__DATE_UPDATED_DESC = 'COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__DATE_UPDATED_DESC',
  COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__DISPLAY_ORDER_ASC = 'COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__DISPLAY_ORDER_ASC',
  COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__DISPLAY_ORDER_DESC = 'COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__DISPLAY_ORDER_DESC',
  COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__REMARK_ASC = 'COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__REMARK_ASC',
  COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__REMARK_DESC = 'COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__REMARK_DESC',
  COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__SHORT_NAME_ASC = 'COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__SHORT_NAME_ASC',
  COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__SHORT_NAME_DESC = 'COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__SHORT_NAME_DESC',
  COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__TELEPHONE_CODE_ASC = 'COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__TELEPHONE_CODE_ASC',
  COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__TELEPHONE_CODE_DESC = 'COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__TELEPHONE_CODE_DESC',
  COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__USER_CREATED_ASC = 'COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__USER_CREATED_ASC',
  COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__USER_CREATED_DESC = 'COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__USER_CREATED_DESC',
  COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__USER_UPDATED_ASC = 'COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__USER_UPDATED_ASC',
  COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__USER_UPDATED_DESC = 'COUNTRY_BY_TELEPHONE_NUMBER_COUNTRY_ID__USER_UPDATED_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_EMERGENCY_CONTACT_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACT_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACT_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACT_ID_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__DATE_CREATED_ASC = 'RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__DATE_CREATED_ASC',
  RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__DATE_CREATED_DESC = 'RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__DATE_CREATED_DESC',
  RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__DATE_UPDATED_ASC = 'RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__DATE_UPDATED_ASC',
  RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__DATE_UPDATED_DESC = 'RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__DATE_UPDATED_DESC',
  RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__DISPLAY_NAME_ASC = 'RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__DISPLAY_NAME_ASC',
  RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__DISPLAY_NAME_DESC = 'RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__DISPLAY_NAME_DESC',
  RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__DISPLAY_ORDER_ASC = 'RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__DISPLAY_ORDER_ASC',
  RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__DISPLAY_ORDER_DESC = 'RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__DISPLAY_ORDER_DESC',
  RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__EMERGENCY_CONTACT_RELATION_ID_ASC = 'RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__EMERGENCY_CONTACT_RELATION_ID_ASC',
  RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__EMERGENCY_CONTACT_RELATION_ID_DESC = 'RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__EMERGENCY_CONTACT_RELATION_ID_DESC',
  RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__REMARK_ASC = 'RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__REMARK_ASC',
  RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__REMARK_DESC = 'RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__REMARK_DESC',
  RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__USER_CREATED_ASC = 'RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__USER_CREATED_ASC',
  RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__USER_CREATED_DESC = 'RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__USER_CREATED_DESC',
  RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__USER_UPDATED_ASC = 'RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__USER_UPDATED_ASC',
  RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__USER_UPDATED_DESC = 'RELATION_EMERGENCY_CONTACT_BY_RELATION_ID__USER_UPDATED_DESC',
  RELATION_ID_ASC = 'RELATION_ID_ASC',
  RELATION_ID_DESC = 'RELATION_ID_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  TELEPHONE_NUMBER_ASC = 'TELEPHONE_NUMBER_ASC',
  TELEPHONE_NUMBER_COUNTRY_ID_ASC = 'TELEPHONE_NUMBER_COUNTRY_ID_ASC',
  TELEPHONE_NUMBER_COUNTRY_ID_DESC = 'TELEPHONE_NUMBER_COUNTRY_ID_DESC',
  TELEPHONE_NUMBER_DESC = 'TELEPHONE_NUMBER_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface EmployeeEmployeeCompanyEmailKeyConnect {
  companyEmail: Scalars['String']['input'];
}

export interface EmployeeEmployeeCompanyEmailKeyDelete {
  companyEmail: Scalars['String']['input'];
}

export interface EmployeeEmployeePkeyConnect {
  employeeId: Scalars['BigInt']['input'];
}

export interface EmployeeEmployeePkeyDelete {
  employeeId: Scalars['BigInt']['input'];
}

export interface EmployeeFile extends Node {
  __typename?: 'EmployeeFile';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  employee?: Maybe<Employee>;
  employeeFileId: Scalars['BigInt']['output'];
  employeeId?: Maybe<Scalars['BigInt']['output']>;
  fileName: Scalars['String']['output'];
  fileSize: Scalars['BigFloat']['output'];
  isHidden: Scalars['Boolean']['output'];
  isPrivate: Scalars['Boolean']['output'];
  mimeType: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  s3Path: Scalars['String']['output'];
  title: Scalars['String']['output'];
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userRoleByVisibleRole?: Maybe<UserRole>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
  visibleRole?: Maybe<Scalars['String']['output']>;
}

export interface EmployeeFileCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  employeeFileId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileSize?: InputMaybe<Scalars['BigFloat']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  s3Path?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visibleRole?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeFileEmployeeFilePkeyConnect {
  employeeFileId: Scalars['BigInt']['input'];
}

export interface EmployeeFileEmployeeFilePkeyDelete {
  employeeFileId: Scalars['BigInt']['input'];
}

export interface EmployeeFileEmployeeFileS3PathKeyConnect {
  s3Path: Scalars['String']['input'];
}

export interface EmployeeFileEmployeeFileS3PathKeyDelete {
  s3Path: Scalars['String']['input'];
}

export interface EmployeeFileEmployeeIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeFileEmployeeIdFkeyEmployeeFileCreateInput {
  employee?: InputMaybe<EmployeeFileEmployeeIdFkeyInput>;
  employeeFileId?: InputMaybe<Scalars['BigInt']['input']>;
  fileName: Scalars['String']['input'];
  fileSize?: InputMaybe<Scalars['BigFloat']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isPrivate: Scalars['Boolean']['input'];
  mimeType?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  s3Path: Scalars['String']['input'];
  title: Scalars['String']['input'];
  userAccount?: InputMaybe<EmployeeFileUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userRole?: InputMaybe<EmployeeFileVisibleRoleFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visibleRole?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeFileEmployeeIdFkeyInput {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<EmployeeFileEmployeeIdFkeyEmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnEmployeeFileForEmployeeFileEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnEmployeeFileForEmployeeFileEmployeeIdFkeyUsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeFileOnEmployeeFileForEmployeeFileEmployeeIdFkeyNodeIdUpdate>;
}

export interface EmployeeFileEmployeeIdFkeyInverseInput {
  connectByEmployeeFileId?: InputMaybe<Array<EmployeeFileEmployeeFilePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeFileNodeIdConnect>>;
  connectByS3Path?: InputMaybe<Array<EmployeeFileEmployeeFileS3PathKeyConnect>>;
  create?: InputMaybe<Array<EmployeeFileEmployeeIdFkeyEmployeeFileCreateInput>>;
  deleteByEmployeeFileId?: InputMaybe<Array<EmployeeFileEmployeeFilePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeFileNodeIdDelete>>;
  deleteByS3Path?: InputMaybe<Array<EmployeeFileEmployeeFileS3PathKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeFileId?: InputMaybe<Array<EmployeeFileOnEmployeeFileForEmployeeFileEmployeeIdFkeyUsingEmployeeFilePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<EmployeeOnEmployeeFileForEmployeeFileEmployeeIdFkeyNodeIdUpdate>>;
  updateByS3Path?: InputMaybe<Array<EmployeeFileOnEmployeeFileForEmployeeFileEmployeeIdFkeyUsingEmployeeFileS3PathKeyUpdate>>;
}

export interface EmployeeFileFilter {
  and?: InputMaybe<Array<EmployeeFileFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  employee?: InputMaybe<EmployeeFilter>;
  employeeExists?: InputMaybe<Scalars['Boolean']['input']>;
  employeeFileId?: InputMaybe<BigIntFilter>;
  employeeId?: InputMaybe<BigIntFilter>;
  fileName?: InputMaybe<StringFilter>;
  fileSize?: InputMaybe<BigFloatFilter>;
  isHidden?: InputMaybe<BooleanFilter>;
  isPrivate?: InputMaybe<BooleanFilter>;
  mimeType?: InputMaybe<StringFilter>;
  not?: InputMaybe<EmployeeFileFilter>;
  or?: InputMaybe<Array<EmployeeFileFilter>>;
  remark?: InputMaybe<StringFilter>;
  s3Path?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userRoleByVisibleRole?: InputMaybe<UserRoleFilter>;
  userRoleByVisibleRoleExists?: InputMaybe<Scalars['Boolean']['input']>;
  userUpdated?: InputMaybe<BigIntFilter>;
  visibleRole?: InputMaybe<StringFilter>;
}

export interface EmployeeFileInput {
  employee?: InputMaybe<EmployeeFileEmployeeIdFkeyInput>;
  employeeFileId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  fileName: Scalars['String']['input'];
  fileSize?: InputMaybe<Scalars['BigFloat']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isPrivate: Scalars['Boolean']['input'];
  mimeType?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  s3Path: Scalars['String']['input'];
  title: Scalars['String']['input'];
  userAccount?: InputMaybe<EmployeeFileUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userRole?: InputMaybe<EmployeeFileVisibleRoleFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visibleRole?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeFileNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeFileNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeFileOnEmployeeFileForEmployeeFileEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface EmployeeFileOnEmployeeFileForEmployeeFileEmployeeIdFkeyUsingEmployeeFilePkeyUpdate {
  employeeFileId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeFileOnEmployeeFileForEmployeeFileEmployeeIdFkeyPatch;
}

export interface EmployeeFileOnEmployeeFileForEmployeeFileEmployeeIdFkeyUsingEmployeeFileS3PathKeyUpdate {
  patch: UpdateEmployeeFileOnEmployeeFileForEmployeeFileEmployeeIdFkeyPatch;
  s3Path: Scalars['String']['input'];
}

export interface EmployeeFileOnEmployeeFileForEmployeeFileUserCreatedFkeyUsingEmployeeFilePkeyUpdate {
  employeeFileId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeFileOnEmployeeFileForEmployeeFileUserCreatedFkeyPatch;
}

export interface EmployeeFileOnEmployeeFileForEmployeeFileUserCreatedFkeyUsingEmployeeFileS3PathKeyUpdate {
  patch: UpdateEmployeeFileOnEmployeeFileForEmployeeFileUserCreatedFkeyPatch;
  s3Path: Scalars['String']['input'];
}

export interface EmployeeFileOnEmployeeFileForEmployeeFileUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface EmployeeFileOnEmployeeFileForEmployeeFileUserUpdatedFkeyUsingEmployeeFilePkeyUpdate {
  employeeFileId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeFileOnEmployeeFileForEmployeeFileUserUpdatedFkeyPatch;
}

export interface EmployeeFileOnEmployeeFileForEmployeeFileUserUpdatedFkeyUsingEmployeeFileS3PathKeyUpdate {
  patch: UpdateEmployeeFileOnEmployeeFileForEmployeeFileUserUpdatedFkeyPatch;
  s3Path: Scalars['String']['input'];
}

export interface EmployeeFileOnEmployeeFileForEmployeeFileVisibleRoleFkeyUsingEmployeeFilePkeyUpdate {
  employeeFileId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeFileOnEmployeeFileForEmployeeFileVisibleRoleFkeyPatch;
}

export interface EmployeeFileOnEmployeeFileForEmployeeFileVisibleRoleFkeyUsingEmployeeFileS3PathKeyUpdate {
  patch: UpdateEmployeeFileOnEmployeeFileForEmployeeFileVisibleRoleFkeyPatch;
  s3Path: Scalars['String']['input'];
}

export interface EmployeeFilePatch {
  employee?: InputMaybe<EmployeeFileEmployeeIdFkeyInput>;
  employeeFileId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileSize?: InputMaybe<Scalars['BigFloat']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  s3Path?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeFileUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userRole?: InputMaybe<EmployeeFileVisibleRoleFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visibleRole?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeFileUserCreatedFkeyInverseInput {
  connectByEmployeeFileId?: InputMaybe<Array<EmployeeFileEmployeeFilePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeFileNodeIdConnect>>;
  connectByS3Path?: InputMaybe<Array<EmployeeFileEmployeeFileS3PathKeyConnect>>;
  deleteByEmployeeFileId?: InputMaybe<Array<EmployeeFileEmployeeFilePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeFileNodeIdDelete>>;
  deleteByS3Path?: InputMaybe<Array<EmployeeFileEmployeeFileS3PathKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeFileId?: InputMaybe<Array<EmployeeFileOnEmployeeFileForEmployeeFileUserCreatedFkeyUsingEmployeeFilePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmployeeFileForEmployeeFileUserCreatedFkeyNodeIdUpdate>>;
  updateByS3Path?: InputMaybe<Array<EmployeeFileOnEmployeeFileForEmployeeFileUserCreatedFkeyUsingEmployeeFileS3PathKeyUpdate>>;
}

export interface EmployeeFileUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnEmployeeFileForEmployeeFileUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeFileOnEmployeeFileForEmployeeFileUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnEmployeeFileForEmployeeFileUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface EmployeeFileUserUpdatedFkeyInverseInput {
  connectByEmployeeFileId?: InputMaybe<Array<EmployeeFileEmployeeFilePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeFileNodeIdConnect>>;
  connectByS3Path?: InputMaybe<Array<EmployeeFileEmployeeFileS3PathKeyConnect>>;
  deleteByEmployeeFileId?: InputMaybe<Array<EmployeeFileEmployeeFilePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeFileNodeIdDelete>>;
  deleteByS3Path?: InputMaybe<Array<EmployeeFileEmployeeFileS3PathKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeFileId?: InputMaybe<Array<EmployeeFileOnEmployeeFileForEmployeeFileUserUpdatedFkeyUsingEmployeeFilePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmployeeFileForEmployeeFileUserUpdatedFkeyNodeIdUpdate>>;
  updateByS3Path?: InputMaybe<Array<EmployeeFileOnEmployeeFileForEmployeeFileUserUpdatedFkeyUsingEmployeeFileS3PathKeyUpdate>>;
}

export interface EmployeeFileVisibleRoleFkeyEmployeeFileCreateInput {
  employee?: InputMaybe<EmployeeFileEmployeeIdFkeyInput>;
  employeeFileId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  fileName: Scalars['String']['input'];
  fileSize?: InputMaybe<Scalars['BigFloat']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isPrivate: Scalars['Boolean']['input'];
  mimeType?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  s3Path: Scalars['String']['input'];
  title: Scalars['String']['input'];
  userAccount?: InputMaybe<EmployeeFileUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userRole?: InputMaybe<EmployeeFileVisibleRoleFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeFileVisibleRoleFkeyInput {
  connectByRoleName?: InputMaybe<UserRoleUserRoleRoleNameKeyConnect>;
  updateByRoleName?: InputMaybe<UserRoleOnEmployeeFileForEmployeeFileVisibleRoleFkeyUsingUserRoleRoleNameKeyUpdate>;
}

export interface EmployeeFileVisibleRoleFkeyInverseInput {
  connectByEmployeeFileId?: InputMaybe<Array<EmployeeFileEmployeeFilePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeFileNodeIdConnect>>;
  connectByS3Path?: InputMaybe<Array<EmployeeFileEmployeeFileS3PathKeyConnect>>;
  create?: InputMaybe<Array<EmployeeFileVisibleRoleFkeyEmployeeFileCreateInput>>;
  deleteByEmployeeFileId?: InputMaybe<Array<EmployeeFileEmployeeFilePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeFileNodeIdDelete>>;
  deleteByS3Path?: InputMaybe<Array<EmployeeFileEmployeeFileS3PathKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeFileId?: InputMaybe<Array<EmployeeFileOnEmployeeFileForEmployeeFileVisibleRoleFkeyUsingEmployeeFilePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserRoleOnEmployeeFileForEmployeeFileVisibleRoleFkeyNodeIdUpdate>>;
  updateByS3Path?: InputMaybe<Array<EmployeeFileOnEmployeeFileForEmployeeFileVisibleRoleFkeyUsingEmployeeFileS3PathKeyUpdate>>;
}

export interface EmployeeFilesConnection {
  __typename?: 'EmployeeFilesConnection';
  edges: Array<EmployeeFilesEdge>;
  nodes: Array<EmployeeFile>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeFilesEdge {
  __typename?: 'EmployeeFilesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeFile;
}

export enum EmployeeFilesOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_FILE_ID_ASC = 'EMPLOYEE_FILE_ID_ASC',
  EMPLOYEE_FILE_ID_DESC = 'EMPLOYEE_FILE_ID_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  FILE_NAME_ASC = 'FILE_NAME_ASC',
  FILE_NAME_DESC = 'FILE_NAME_DESC',
  FILE_SIZE_ASC = 'FILE_SIZE_ASC',
  FILE_SIZE_DESC = 'FILE_SIZE_DESC',
  IS_HIDDEN_ASC = 'IS_HIDDEN_ASC',
  IS_HIDDEN_DESC = 'IS_HIDDEN_DESC',
  IS_PRIVATE_ASC = 'IS_PRIVATE_ASC',
  IS_PRIVATE_DESC = 'IS_PRIVATE_DESC',
  MIME_TYPE_ASC = 'MIME_TYPE_ASC',
  MIME_TYPE_DESC = 'MIME_TYPE_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  S3_PATH_ASC = 'S3_PATH_ASC',
  S3_PATH_DESC = 'S3_PATH_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_ROLE_BY_VISIBLE_ROLE__DISPLAY_NAME_ASC = 'USER_ROLE_BY_VISIBLE_ROLE__DISPLAY_NAME_ASC',
  USER_ROLE_BY_VISIBLE_ROLE__DISPLAY_NAME_DESC = 'USER_ROLE_BY_VISIBLE_ROLE__DISPLAY_NAME_DESC',
  USER_ROLE_BY_VISIBLE_ROLE__IS_DEFAULT_ROLE_ASC = 'USER_ROLE_BY_VISIBLE_ROLE__IS_DEFAULT_ROLE_ASC',
  USER_ROLE_BY_VISIBLE_ROLE__IS_DEFAULT_ROLE_DESC = 'USER_ROLE_BY_VISIBLE_ROLE__IS_DEFAULT_ROLE_DESC',
  USER_ROLE_BY_VISIBLE_ROLE__PRIORITY_ASC = 'USER_ROLE_BY_VISIBLE_ROLE__PRIORITY_ASC',
  USER_ROLE_BY_VISIBLE_ROLE__PRIORITY_DESC = 'USER_ROLE_BY_VISIBLE_ROLE__PRIORITY_DESC',
  USER_ROLE_BY_VISIBLE_ROLE__ROLE_NAME_ASC = 'USER_ROLE_BY_VISIBLE_ROLE__ROLE_NAME_ASC',
  USER_ROLE_BY_VISIBLE_ROLE__ROLE_NAME_DESC = 'USER_ROLE_BY_VISIBLE_ROLE__ROLE_NAME_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
  VISIBLE_ROLE_ASC = 'VISIBLE_ROLE_ASC',
  VISIBLE_ROLE_DESC = 'VISIBLE_ROLE_DESC',
}

export interface EmployeeFilter {
  allEmployeeWithYearQuarters?: InputMaybe<EmployeeToManyAllEmployeeWithYearQuarterFilter>;
  allEmployeeWithYearQuartersExist?: InputMaybe<Scalars['Boolean']['input']>;
  allSubordinateCount?: InputMaybe<IntFilter>;
  allSubordinateCountNotResigned?: InputMaybe<IntFilter>;
  and?: InputMaybe<Array<EmployeeFilter>>;
  approver?: InputMaybe<EmployeeFilter>;
  approverExists?: InputMaybe<Scalars['Boolean']['input']>;
  approverId?: InputMaybe<BigIntFilter>;
  bank?: InputMaybe<BankFilter>;
  bankAccountHolderName?: InputMaybe<StringFilter>;
  bankAccountNumber?: InputMaybe<StringFilter>;
  bankBranchName?: InputMaybe<StringFilter>;
  bankExists?: InputMaybe<Scalars['Boolean']['input']>;
  bankId?: InputMaybe<BigIntFilter>;
  bankRemarks?: InputMaybe<StringFilter>;
  companyEmail?: InputMaybe<StringFilter>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrFilter>;
  companyGlobalHrExists?: InputMaybe<Scalars['Boolean']['input']>;
  companyLocalHrs?: InputMaybe<EmployeeToManyCompanyLocalHrFilter>;
  companyLocalHrsExist?: InputMaybe<Scalars['Boolean']['input']>;
  consultingFeeBank?: InputMaybe<BankFilter>;
  consultingFeeBankAccountHolderName?: InputMaybe<StringFilter>;
  consultingFeeBankAccountNumber?: InputMaybe<StringFilter>;
  consultingFeeBankBranchName?: InputMaybe<StringFilter>;
  consultingFeeBankExists?: InputMaybe<Scalars['Boolean']['input']>;
  consultingFeeBankId?: InputMaybe<BigIntFilter>;
  consultingFeeBankRemarks?: InputMaybe<StringFilter>;
  consultingFeeCurrency?: InputMaybe<CurrencyFilter>;
  consultingFeeCurrencyExists?: InputMaybe<Scalars['Boolean']['input']>;
  consultingFeeCurrencyId?: InputMaybe<StringFilter>;
  consultingFeeType?: InputMaybe<EmployeeConsultingFeeTypeListFilter>;
  countryByNationality?: InputMaybe<CountryFilter>;
  countryByNationalityExists?: InputMaybe<Scalars['Boolean']['input']>;
  countryByWorkingLocationCountryCodeAlpha2?: InputMaybe<CountryFilter>;
  currentAddress?: InputMaybe<StringFilter>;
  currentAddressZipcode?: InputMaybe<StringFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateOfBirth?: InputMaybe<DateFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  dottedLineManager?: InputMaybe<EmployeeFilter>;
  dottedLineManagerExists?: InputMaybe<Scalars['Boolean']['input']>;
  dottedLineManagerId?: InputMaybe<BigIntFilter>;
  employeeCurrentPosition?: InputMaybe<EmployeeCurrentPositionFilter>;
  employeeCurrentPositionExists?: InputMaybe<Scalars['Boolean']['input']>;
  employeeDaysByContractTypes?: InputMaybe<EmployeeToManyEmployeeDaysByContractTypeFilter>;
  employeeDaysByContractTypesExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeEmergencyContacts?: InputMaybe<EmployeeToManyEmployeeEmergencyContactFilter>;
  employeeEmergencyContactsExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeFiles?: InputMaybe<EmployeeToManyEmployeeFileFilter>;
  employeeFilesExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeGroupLatest?: InputMaybe<EmployeeGroupLatestFilter>;
  employeeGroupLatestExists?: InputMaybe<Scalars['Boolean']['input']>;
  employeeGroupLists?: InputMaybe<EmployeeToManyEmployeeGroupListFilter>;
  employeeGroupListsExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeGroups?: InputMaybe<EmployeeToManyEmployeeGroupFilter>;
  employeeGroupsExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<BigIntFilter>;
  employeeIdTypeByIdType?: InputMaybe<EmployeeIdTypeFilter>;
  employeeIdTypeByIdTypeExists?: InputMaybe<Scalars['Boolean']['input']>;
  employeeStatusByStatus?: InputMaybe<EmployeeStatusFilter>;
  employeeTpHistories?: InputMaybe<EmployeeToManyEmployeeTpHistoryFilter>;
  employeeTpHistoriesExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeTransferHistories?: InputMaybe<EmployeeToManyEmployeeTransferHistoryFilter>;
  employeeTransferHistoriesExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeWorkingDayStatisticsAll?: InputMaybe<EmployeeWorkingDayStatisticsAllFilter>;
  employeeWorkingDayStatisticsAllExists?: InputMaybe<Scalars['Boolean']['input']>;
  employeesByApproverId?: InputMaybe<EmployeeToManyEmployeeFilter>;
  employeesByApproverIdExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeesByDottedLineManagerId?: InputMaybe<EmployeeToManyEmployeeFilter>;
  employeesByDottedLineManagerIdExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeesByPrimaryManagerId?: InputMaybe<EmployeeToManyEmployeeFilter>;
  employeesByPrimaryManagerIdExist?: InputMaybe<Scalars['Boolean']['input']>;
  externalAccountCreationFaileds?: InputMaybe<EmployeeToManyExternalAccountCreationFailedFilter>;
  externalAccountCreationFailedsExist?: InputMaybe<Scalars['Boolean']['input']>;
  externalAccountEmployees?: InputMaybe<EmployeeToManyExternalAccountEmployeeFilter>;
  externalAccountEmployeesExist?: InputMaybe<Scalars['Boolean']['input']>;
  fullName?: InputMaybe<StringFilter>;
  fullNameInLocalLanguage?: InputMaybe<StringFilter>;
  fullNameKana?: InputMaybe<StringFilter>;
  gender?: InputMaybe<EmployeeGenderFilter>;
  idExpiryDate?: InputMaybe<DateFilter>;
  idIssuedDate?: InputMaybe<DateFilter>;
  idIssuedPlace?: InputMaybe<StringFilter>;
  idNumber?: InputMaybe<StringFilter>;
  idType?: InputMaybe<BigIntFilter>;
  joinDate?: InputMaybe<DateFilter>;
  lastWorkingDate?: InputMaybe<DateFilter>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatusFilter>;
  mobileNumber?: InputMaybe<StringFilter>;
  mobileNumberCountry?: InputMaybe<CountryFilter>;
  mobileNumberCountryExists?: InputMaybe<Scalars['Boolean']['input']>;
  mobileNumberCountryId?: InputMaybe<StringFilter>;
  nationality?: InputMaybe<StringFilter>;
  nhiDependencies?: InputMaybe<IntFilter>;
  nickname?: InputMaybe<StringFilter>;
  not?: InputMaybe<EmployeeFilter>;
  numberOfDependencies?: InputMaybe<IntFilter>;
  or?: InputMaybe<Array<EmployeeFilter>>;
  passportExpiryDate?: InputMaybe<DateFilter>;
  passportIssuedDate?: InputMaybe<DateFilter>;
  passportIssuedPlace?: InputMaybe<StringFilter>;
  passportName?: InputMaybe<StringFilter>;
  passportNumber?: InputMaybe<StringFilter>;
  pensionVoluntaryContributionRate?: InputMaybe<StringFilter>;
  performanceReviewEvaluationCompetenciesByAssessmentEmployeeId?: InputMaybe<EmployeeToManyPerformanceReviewEvaluationCompetencyFilter>;
  performanceReviewEvaluationCompetenciesByAssessmentEmployeeIdExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationEvaluatorsByEvaluatorId?: InputMaybe<EmployeeToManyPerformanceReviewEvaluationEvaluatorFilter>;
  performanceReviewEvaluationEvaluatorsByEvaluatorIdExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationFeedbacksByFeedbackEmployeeId?: InputMaybe<EmployeeToManyPerformanceReviewEvaluationFeedbackFilter>;
  performanceReviewEvaluationFeedbacksByFeedbackEmployeeIdExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationKpiAssessmentsByAssessmentEmployeeId?: InputMaybe<EmployeeToManyPerformanceReviewEvaluationKpiAssessmentFilter>;
  performanceReviewEvaluationKpiAssessmentsByAssessmentEmployeeIdExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationOneOnOneSelfLists?: InputMaybe<EmployeeToManyPerformanceReviewEvaluationOneOnOneSelfListFilter>;
  performanceReviewEvaluationOneOnOneSelfListsExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationQualitativeAssessmentsByAssessmentEmployeeId?: InputMaybe<EmployeeToManyPerformanceReviewEvaluationQualitativeAssessmentFilter>;
  performanceReviewEvaluationQualitativeAssessmentsByAssessmentEmployeeIdExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationValuesByAssessmentEmployeeId?: InputMaybe<EmployeeToManyPerformanceReviewEvaluationValueFilter>;
  performanceReviewEvaluationValuesByAssessmentEmployeeIdExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluations?: InputMaybe<EmployeeToManyPerformanceReviewEvaluationFilter>;
  performanceReviewEvaluationsExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterEvaluationViewers?: InputMaybe<EmployeeToManyPerformanceReviewMasterEvaluationViewerFilter>;
  performanceReviewMasterEvaluationViewersByViewerEmployeeId?: InputMaybe<EmployeeToManyPerformanceReviewMasterEvaluationViewerFilter>;
  performanceReviewMasterEvaluationViewersByViewerEmployeeIdExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterEvaluationViewersExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<EmployeeToManyPerformanceReviewMasterExcludedEmployeeFilter>;
  performanceReviewMasterExcludedEmployeesExist?: InputMaybe<Scalars['Boolean']['input']>;
  permanentAddress?: InputMaybe<StringFilter>;
  personalEmail?: InputMaybe<StringFilter>;
  placeOfBirth?: InputMaybe<StringFilter>;
  primaryManager?: InputMaybe<EmployeeFilter>;
  primaryManagerExists?: InputMaybe<Scalars['Boolean']['input']>;
  primaryManagerId?: InputMaybe<BigIntFilter>;
  probationEnd?: InputMaybe<DateFilter>;
  probationReviewEvaluationReviewersByReviewerEmployeeId?: InputMaybe<EmployeeToManyProbationReviewEvaluationReviewerFilter>;
  probationReviewEvaluationReviewersByReviewerEmployeeIdExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluations?: InputMaybe<EmployeeToManyProbationReviewEvaluationFilter>;
  probationReviewEvaluationsExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationEmployeeUserAccounts?: InputMaybe<EmployeeToManyRelationEmployeeUserAccountFilter>;
  relationEmployeeUserAccountsExist?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<StringFilter>;
  remoteWorking?: InputMaybe<BooleanFilter>;
  resignationDate?: InputMaybe<DateFilter>;
  resignationReason?: InputMaybe<EmployeeResignationReasonFilter>;
  resignationReasonDetail?: InputMaybe<StringFilter>;
  resignationReasonExists?: InputMaybe<Scalars['Boolean']['input']>;
  resignationReasonId?: InputMaybe<BigIntFilter>;
  resignationType?: InputMaybe<EmployeeResignationTypeFilter>;
  resignationTypeExists?: InputMaybe<Scalars['Boolean']['input']>;
  resignationTypeId?: InputMaybe<BigIntFilter>;
  sentAccountRequestEmailDate?: InputMaybe<DatetimeFilter>;
  sentContractEndEmailDate?: InputMaybe<DatetimeFilter>;
  socialInsuranceNumber?: InputMaybe<StringFilter>;
  status?: InputMaybe<EmployeeStatusKeyFilter>;
  taxCode?: InputMaybe<StringFilter>;
  taxWithholdingRate?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
  visaExpiryDate?: InputMaybe<DateFilter>;
  visaIssuedDate?: InputMaybe<DateFilter>;
  visaNumber?: InputMaybe<StringFilter>;
  visaType?: InputMaybe<StringFilter>;
  workPermitExpiryDate?: InputMaybe<DateFilter>;
  workPermitIssuedDate?: InputMaybe<DateFilter>;
  workPermitNumber?: InputMaybe<StringFilter>;
  workingLocationCountryCodeAlpha2?: InputMaybe<StringFilter>;
}

export enum EmployeeGender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

export interface EmployeeGenderFilter {
  distinctFrom?: InputMaybe<EmployeeGender>;
  equalTo?: InputMaybe<EmployeeGender>;
  greaterThan?: InputMaybe<EmployeeGender>;
  greaterThanOrEqualTo?: InputMaybe<EmployeeGender>;
  in?: InputMaybe<Array<EmployeeGender>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<EmployeeGender>;
  lessThanOrEqualTo?: InputMaybe<EmployeeGender>;
  notDistinctFrom?: InputMaybe<EmployeeGender>;
  notEqualTo?: InputMaybe<EmployeeGender>;
  notIn?: InputMaybe<Array<EmployeeGender>>;
}

export interface EmployeeGenderStatisticsRecord {
  __typename?: 'EmployeeGenderStatisticsRecord';
  _count: Scalars['Int']['output'];
  _order: Scalars['Int']['output'];
  categoryMain: Scalars['String']['output'];
  categorySub?: Maybe<Scalars['JSON']['output']>;
  countFemale: Scalars['Int']['output'];
  countMale: Scalars['Int']['output'];
}

export interface EmployeeGenderStatisticsRecordFilter {
  _count?: InputMaybe<IntFilter>;
  _order?: InputMaybe<IntFilter>;
  and?: InputMaybe<Array<EmployeeGenderStatisticsRecordFilter>>;
  categoryMain?: InputMaybe<StringFilter>;
  categorySub?: InputMaybe<JsonFilter>;
  countFemale?: InputMaybe<IntFilter>;
  countMale?: InputMaybe<IntFilter>;
  not?: InputMaybe<EmployeeGenderStatisticsRecordFilter>;
  or?: InputMaybe<Array<EmployeeGenderStatisticsRecordFilter>>;
}

export interface EmployeeGenderStatisticsRecordsConnection {
  __typename?: 'EmployeeGenderStatisticsRecordsConnection';
  edges: Array<EmployeeGenderStatisticsRecordsEdge>;
  nodes: Array<EmployeeGenderStatisticsRecord>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeGenderStatisticsRecordsEdge {
  __typename?: 'EmployeeGenderStatisticsRecordsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeGenderStatisticsRecord;
}

export interface EmployeeGroup extends Node {
  __typename?: 'EmployeeGroup';
  companyEmail: Scalars['String']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  employee: Employee;
  employeeId: Scalars['BigInt']['output'];
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface EmployeeGroupCondition {
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeGroupEmployeeGroupPkeyConnect {
  companyEmail: Scalars['String']['input'];
  employeeId: Scalars['BigInt']['input'];
}

export interface EmployeeGroupEmployeeGroupPkeyDelete {
  companyEmail: Scalars['String']['input'];
  employeeId: Scalars['BigInt']['input'];
}

export interface EmployeeGroupEmployeeIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeGroupEmployeeIdFkeyEmployeeGroupCreateInput {
  companyEmail: Scalars['String']['input'];
  employee?: InputMaybe<EmployeeGroupEmployeeIdFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeGroupUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeGroupEmployeeIdFkeyInput {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<EmployeeGroupEmployeeIdFkeyEmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnEmployeeGroupForEmployeeGroupEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnEmployeeGroupForEmployeeGroupEmployeeIdFkeyUsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeGroupOnEmployeeGroupForEmployeeGroupEmployeeIdFkeyNodeIdUpdate>;
}

export interface EmployeeGroupEmployeeIdFkeyInverseInput {
  connectByCompanyEmailAndEmployeeId?: InputMaybe<Array<EmployeeGroupEmployeeGroupPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeGroupNodeIdConnect>>;
  create?: InputMaybe<Array<EmployeeGroupEmployeeIdFkeyEmployeeGroupCreateInput>>;
  deleteByCompanyEmailAndEmployeeId?: InputMaybe<Array<EmployeeGroupEmployeeGroupPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeGroupNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyEmailAndEmployeeId?: InputMaybe<Array<EmployeeGroupOnEmployeeGroupForEmployeeGroupEmployeeIdFkeyUsingEmployeeGroupPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<EmployeeOnEmployeeGroupForEmployeeGroupEmployeeIdFkeyNodeIdUpdate>>;
}

export interface EmployeeGroupFilter {
  and?: InputMaybe<Array<EmployeeGroupFilter>>;
  companyEmail?: InputMaybe<StringFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  employee?: InputMaybe<EmployeeFilter>;
  employeeId?: InputMaybe<BigIntFilter>;
  not?: InputMaybe<EmployeeGroupFilter>;
  or?: InputMaybe<Array<EmployeeGroupFilter>>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface EmployeeGroupInput {
  companyEmail: Scalars['String']['input'];
  employee?: InputMaybe<EmployeeGroupEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeGroupUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeGroupLatest extends Node {
  __typename?: 'EmployeeGroupLatest';
  allEmployeeIdList?: Maybe<Array<Maybe<Scalars['BigInt']['output']>>>;
  companyEmail?: Maybe<Scalars['String']['output']>;
  employee: Employee;
  employeeId: Scalars['BigInt']['output'];
  employeeStatusByStatus?: Maybe<EmployeeStatus>;
  fullName?: Maybe<Scalars['String']['output']>;
  joinDate?: Maybe<Scalars['Date']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  nodeId: Scalars['ID']['output'];
  resignationDate?: Maybe<Scalars['Date']['output']>;
  status?: Maybe<EmployeeStatusKey>;
}

export interface EmployeeGroupLatestCondition {
  allEmployeeIdList?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
}

export interface EmployeeGroupLatestFakePublicEmployeeGroupLatestPrimaryKeyConnect {
  employeeId: Scalars['BigInt']['input'];
}

export interface EmployeeGroupLatestFilter {
  allEmployeeIdList?: InputMaybe<BigIntListFilter>;
  and?: InputMaybe<Array<EmployeeGroupLatestFilter>>;
  companyEmail?: InputMaybe<StringFilter>;
  employee?: InputMaybe<EmployeeFilter>;
  employeeExists?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<BigIntFilter>;
  employeeStatusByStatus?: InputMaybe<EmployeeStatusFilter>;
  employeeStatusByStatusExists?: InputMaybe<Scalars['Boolean']['input']>;
  fullName?: InputMaybe<StringFilter>;
  joinDate?: InputMaybe<DateFilter>;
  nickname?: InputMaybe<StringFilter>;
  not?: InputMaybe<EmployeeGroupLatestFilter>;
  or?: InputMaybe<Array<EmployeeGroupLatestFilter>>;
  resignationDate?: InputMaybe<DateFilter>;
  status?: InputMaybe<EmployeeStatusKeyFilter>;
}

export interface EmployeeGroupLatestNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeGroupLatestOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey0NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface EmployeeGroupLatestOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey0UsingFakePublicEmployeeGroupLatestPrimaryKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeGroupLatestOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey0Patch;
}

export interface EmployeeGroupLatestOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey1NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeStatusPatch;
}

export interface EmployeeGroupLatestOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey1UsingFakePublicEmployeeGroupLatestPrimaryKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeGroupLatestOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey1Patch;
}

export interface EmployeeGroupLatestPatch {
  allEmployeeIdList?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  employee?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0Input>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeStatus?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey1Input>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
}

export interface EmployeeGroupLatestsConnection {
  __typename?: 'EmployeeGroupLatestsConnection';
  edges: Array<EmployeeGroupLatestsEdge>;
  nodes: Array<EmployeeGroupLatest>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeGroupLatestsEdge {
  __typename?: 'EmployeeGroupLatestsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeGroupLatest;
}

export enum EmployeeGroupLatestsOrderBy {
  ALL_EMPLOYEE_ID_LIST_ASC = 'ALL_EMPLOYEE_ID_LIST_ASC',
  ALL_EMPLOYEE_ID_LIST_DESC = 'ALL_EMPLOYEE_ID_LIST_DESC',
  COMPANY_EMAIL_ASC = 'COMPANY_EMAIL_ASC',
  COMPANY_EMAIL_DESC = 'COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  EMPLOYEE_STATUS_BY_STATUS__DATE_CREATED_ASC = 'EMPLOYEE_STATUS_BY_STATUS__DATE_CREATED_ASC',
  EMPLOYEE_STATUS_BY_STATUS__DATE_CREATED_DESC = 'EMPLOYEE_STATUS_BY_STATUS__DATE_CREATED_DESC',
  EMPLOYEE_STATUS_BY_STATUS__DATE_UPDATED_ASC = 'EMPLOYEE_STATUS_BY_STATUS__DATE_UPDATED_ASC',
  EMPLOYEE_STATUS_BY_STATUS__DATE_UPDATED_DESC = 'EMPLOYEE_STATUS_BY_STATUS__DATE_UPDATED_DESC',
  EMPLOYEE_STATUS_BY_STATUS__DISPLAY_NAME_ASC = 'EMPLOYEE_STATUS_BY_STATUS__DISPLAY_NAME_ASC',
  EMPLOYEE_STATUS_BY_STATUS__DISPLAY_NAME_DESC = 'EMPLOYEE_STATUS_BY_STATUS__DISPLAY_NAME_DESC',
  EMPLOYEE_STATUS_BY_STATUS__EMPLOYEE_STATUS_KEY_ASC = 'EMPLOYEE_STATUS_BY_STATUS__EMPLOYEE_STATUS_KEY_ASC',
  EMPLOYEE_STATUS_BY_STATUS__EMPLOYEE_STATUS_KEY_DESC = 'EMPLOYEE_STATUS_BY_STATUS__EMPLOYEE_STATUS_KEY_DESC',
  EMPLOYEE_STATUS_BY_STATUS__EMPLOYEE_STATUS_ORDER_ASC = 'EMPLOYEE_STATUS_BY_STATUS__EMPLOYEE_STATUS_ORDER_ASC',
  EMPLOYEE_STATUS_BY_STATUS__EMPLOYEE_STATUS_ORDER_DESC = 'EMPLOYEE_STATUS_BY_STATUS__EMPLOYEE_STATUS_ORDER_DESC',
  EMPLOYEE_STATUS_BY_STATUS__REMARK_ASC = 'EMPLOYEE_STATUS_BY_STATUS__REMARK_ASC',
  EMPLOYEE_STATUS_BY_STATUS__REMARK_DESC = 'EMPLOYEE_STATUS_BY_STATUS__REMARK_DESC',
  EMPLOYEE_STATUS_BY_STATUS__SHORT_NAME_ASC = 'EMPLOYEE_STATUS_BY_STATUS__SHORT_NAME_ASC',
  EMPLOYEE_STATUS_BY_STATUS__SHORT_NAME_DESC = 'EMPLOYEE_STATUS_BY_STATUS__SHORT_NAME_DESC',
  EMPLOYEE_STATUS_BY_STATUS__USER_CREATED_ASC = 'EMPLOYEE_STATUS_BY_STATUS__USER_CREATED_ASC',
  EMPLOYEE_STATUS_BY_STATUS__USER_CREATED_DESC = 'EMPLOYEE_STATUS_BY_STATUS__USER_CREATED_DESC',
  EMPLOYEE_STATUS_BY_STATUS__USER_UPDATED_ASC = 'EMPLOYEE_STATUS_BY_STATUS__USER_UPDATED_ASC',
  EMPLOYEE_STATUS_BY_STATUS__USER_UPDATED_DESC = 'EMPLOYEE_STATUS_BY_STATUS__USER_UPDATED_DESC',
  FULL_NAME_ASC = 'FULL_NAME_ASC',
  FULL_NAME_DESC = 'FULL_NAME_DESC',
  JOIN_DATE_ASC = 'JOIN_DATE_ASC',
  JOIN_DATE_DESC = 'JOIN_DATE_DESC',
  NATURAL = 'NATURAL',
  NICKNAME_ASC = 'NICKNAME_ASC',
  NICKNAME_DESC = 'NICKNAME_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  RESIGNATION_DATE_ASC = 'RESIGNATION_DATE_ASC',
  RESIGNATION_DATE_DESC = 'RESIGNATION_DATE_DESC',
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC',
}

export interface EmployeeGroupList extends Node {
  __typename?: 'EmployeeGroupList';
  allEmployeeIdList: Array<Maybe<Scalars['BigInt']['output']>>;
  companyEmail: Scalars['String']['output'];
  dateCreated?: Maybe<Scalars['Datetime']['output']>;
  dateUpdated?: Maybe<Scalars['Datetime']['output']>;
  employee: Employee;
  employeeId: Scalars['BigInt']['output'];
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface EmployeeGroupListCondition {
  allEmployeeIdList?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeGroupListFakePublicEmployeeGroupListPrimaryKeyConnect {
  companyEmail: Scalars['String']['input'];
  employeeId: Scalars['BigInt']['input'];
}

export interface EmployeeGroupListFilter {
  allEmployeeIdList?: InputMaybe<BigIntListFilter>;
  and?: InputMaybe<Array<EmployeeGroupListFilter>>;
  companyEmail?: InputMaybe<StringFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  employee?: InputMaybe<EmployeeFilter>;
  employeeExists?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<BigIntFilter>;
  not?: InputMaybe<EmployeeGroupListFilter>;
  or?: InputMaybe<Array<EmployeeGroupListFilter>>;
  remark?: InputMaybe<StringFilter>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface EmployeeGroupListNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeGroupListOnEmployeeGroupListForFakePublicEmployeeGroupListForeignKey0NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface EmployeeGroupListOnEmployeeGroupListForFakePublicEmployeeGroupListForeignKey0UsingFakePublicEmployeeGroupListPrimaryKeyUpdate {
  companyEmail: Scalars['String']['input'];
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeGroupListOnEmployeeGroupListForFakePublicEmployeeGroupListForeignKey0Patch;
}

export interface EmployeeGroupListPatch {
  allEmployeeIdList?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  employee?: InputMaybe<FakePublicEmployeeGroupListForeignKey0Input>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeGroupListsConnection {
  __typename?: 'EmployeeGroupListsConnection';
  edges: Array<EmployeeGroupListsEdge>;
  nodes: Array<EmployeeGroupList>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeGroupListsEdge {
  __typename?: 'EmployeeGroupListsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeGroupList;
}

export enum EmployeeGroupListsOrderBy {
  ALL_EMPLOYEE_ID_LIST_ASC = 'ALL_EMPLOYEE_ID_LIST_ASC',
  ALL_EMPLOYEE_ID_LIST_DESC = 'ALL_EMPLOYEE_ID_LIST_DESC',
  COMPANY_EMAIL_ASC = 'COMPANY_EMAIL_ASC',
  COMPANY_EMAIL_DESC = 'COMPANY_EMAIL_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface EmployeeGroupNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeGroupNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeGroupOnEmployeeGroupForEmployeeGroupEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface EmployeeGroupOnEmployeeGroupForEmployeeGroupEmployeeIdFkeyUsingEmployeeGroupPkeyUpdate {
  companyEmail: Scalars['String']['input'];
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeGroupOnEmployeeGroupForEmployeeGroupEmployeeIdFkeyPatch;
}

export interface EmployeeGroupOnEmployeeGroupForEmployeeGroupUserCreatedFkeyUsingEmployeeGroupPkeyUpdate {
  companyEmail: Scalars['String']['input'];
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeGroupOnEmployeeGroupForEmployeeGroupUserCreatedFkeyPatch;
}

export interface EmployeeGroupOnEmployeeGroupForEmployeeGroupUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface EmployeeGroupOnEmployeeGroupForEmployeeGroupUserUpdatedFkeyUsingEmployeeGroupPkeyUpdate {
  companyEmail: Scalars['String']['input'];
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeGroupOnEmployeeGroupForEmployeeGroupUserUpdatedFkeyPatch;
}

export interface EmployeeGroupPatch {
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  employee?: InputMaybe<EmployeeGroupEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeGroupUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeGroupUserCreatedFkeyInverseInput {
  connectByCompanyEmailAndEmployeeId?: InputMaybe<Array<EmployeeGroupEmployeeGroupPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeGroupNodeIdConnect>>;
  deleteByCompanyEmailAndEmployeeId?: InputMaybe<Array<EmployeeGroupEmployeeGroupPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeGroupNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyEmailAndEmployeeId?: InputMaybe<Array<EmployeeGroupOnEmployeeGroupForEmployeeGroupUserCreatedFkeyUsingEmployeeGroupPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmployeeGroupForEmployeeGroupUserCreatedFkeyNodeIdUpdate>>;
}

export interface EmployeeGroupUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnEmployeeGroupForEmployeeGroupUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeGroupOnEmployeeGroupForEmployeeGroupUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnEmployeeGroupForEmployeeGroupUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface EmployeeGroupUserUpdatedFkeyInverseInput {
  connectByCompanyEmailAndEmployeeId?: InputMaybe<Array<EmployeeGroupEmployeeGroupPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeGroupNodeIdConnect>>;
  deleteByCompanyEmailAndEmployeeId?: InputMaybe<Array<EmployeeGroupEmployeeGroupPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeGroupNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyEmailAndEmployeeId?: InputMaybe<Array<EmployeeGroupOnEmployeeGroupForEmployeeGroupUserUpdatedFkeyUsingEmployeeGroupPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmployeeGroupForEmployeeGroupUserUpdatedFkeyNodeIdUpdate>>;
}

export interface EmployeeGroupsConnection {
  __typename?: 'EmployeeGroupsConnection';
  edges: Array<EmployeeGroupsEdge>;
  nodes: Array<EmployeeGroup>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeGroupsEdge {
  __typename?: 'EmployeeGroupsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeGroup;
}

export enum EmployeeGroupsOrderBy {
  COMPANY_EMAIL_ASC = 'COMPANY_EMAIL_ASC',
  COMPANY_EMAIL_DESC = 'COMPANY_EMAIL_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface EmployeeHeadcountByBusinessUnit {
  __typename?: 'EmployeeHeadcountByBusinessUnit';
  businessUnitId: Scalars['BigInt']['output'];
  businessUnitName: Scalars['String']['output'];
  contractTypeId: Scalars['BigInt']['output'];
  numberOfEmployees: Scalars['Int']['output'];
  pointOfDate: Scalars['Date']['output'];
  year: Scalars['Float']['output'];
}

export interface EmployeeHeadcountByBusinessUnitCondition {
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  businessUnitName?: InputMaybe<Scalars['String']['input']>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  numberOfEmployees?: InputMaybe<Scalars['Int']['input']>;
  pointOfDate?: InputMaybe<Scalars['Date']['input']>;
  year?: InputMaybe<Scalars['Float']['input']>;
}

export interface EmployeeHeadcountByBusinessUnitFilter {
  and?: InputMaybe<Array<EmployeeHeadcountByBusinessUnitFilter>>;
  businessUnitId?: InputMaybe<BigIntFilter>;
  businessUnitName?: InputMaybe<StringFilter>;
  contractTypeId?: InputMaybe<BigIntFilter>;
  not?: InputMaybe<EmployeeHeadcountByBusinessUnitFilter>;
  numberOfEmployees?: InputMaybe<IntFilter>;
  or?: InputMaybe<Array<EmployeeHeadcountByBusinessUnitFilter>>;
  pointOfDate?: InputMaybe<DateFilter>;
  year?: InputMaybe<FloatFilter>;
}

export interface EmployeeHeadcountByBusinessUnitsConnection {
  __typename?: 'EmployeeHeadcountByBusinessUnitsConnection';
  edges: Array<EmployeeHeadcountByBusinessUnitsEdge>;
  nodes: Array<EmployeeHeadcountByBusinessUnit>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeHeadcountByBusinessUnitsEdge {
  __typename?: 'EmployeeHeadcountByBusinessUnitsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeHeadcountByBusinessUnit;
}

export enum EmployeeHeadcountByBusinessUnitsOrderBy {
  BUSINESS_UNIT_ID_ASC = 'BUSINESS_UNIT_ID_ASC',
  BUSINESS_UNIT_ID_DESC = 'BUSINESS_UNIT_ID_DESC',
  BUSINESS_UNIT_NAME_ASC = 'BUSINESS_UNIT_NAME_ASC',
  BUSINESS_UNIT_NAME_DESC = 'BUSINESS_UNIT_NAME_DESC',
  CONTRACT_TYPE_ID_ASC = 'CONTRACT_TYPE_ID_ASC',
  CONTRACT_TYPE_ID_DESC = 'CONTRACT_TYPE_ID_DESC',
  NATURAL = 'NATURAL',
  NUMBER_OF_EMPLOYEES_ASC = 'NUMBER_OF_EMPLOYEES_ASC',
  NUMBER_OF_EMPLOYEES_DESC = 'NUMBER_OF_EMPLOYEES_DESC',
  POINT_OF_DATE_ASC = 'POINT_OF_DATE_ASC',
  POINT_OF_DATE_DESC = 'POINT_OF_DATE_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
}

export interface EmployeeHeadcountByCompaniesConnection {
  __typename?: 'EmployeeHeadcountByCompaniesConnection';
  edges: Array<EmployeeHeadcountByCompaniesEdge>;
  nodes: Array<EmployeeHeadcountByCompany>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeHeadcountByCompaniesEdge {
  __typename?: 'EmployeeHeadcountByCompaniesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeHeadcountByCompany;
}

export enum EmployeeHeadcountByCompaniesOrderBy {
  BUSINESS_UNIT_ID_ASC = 'BUSINESS_UNIT_ID_ASC',
  BUSINESS_UNIT_ID_DESC = 'BUSINESS_UNIT_ID_DESC',
  BUSINESS_UNIT_NAME_ASC = 'BUSINESS_UNIT_NAME_ASC',
  BUSINESS_UNIT_NAME_DESC = 'BUSINESS_UNIT_NAME_DESC',
  COMPANY_ID_ASC = 'COMPANY_ID_ASC',
  COMPANY_ID_DESC = 'COMPANY_ID_DESC',
  COMPANY_NAME_ASC = 'COMPANY_NAME_ASC',
  COMPANY_NAME_DESC = 'COMPANY_NAME_DESC',
  CONTRACT_TYPE_ID_ASC = 'CONTRACT_TYPE_ID_ASC',
  CONTRACT_TYPE_ID_DESC = 'CONTRACT_TYPE_ID_DESC',
  COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_CODE_ALPHA2_DESC',
  COUNTRY_NAME_ASC = 'COUNTRY_NAME_ASC',
  COUNTRY_NAME_DESC = 'COUNTRY_NAME_DESC',
  DEPARTMENT_ID_ASC = 'DEPARTMENT_ID_ASC',
  DEPARTMENT_ID_DESC = 'DEPARTMENT_ID_DESC',
  DIVISION_ID_ASC = 'DIVISION_ID_ASC',
  DIVISION_ID_DESC = 'DIVISION_ID_DESC',
  NATURAL = 'NATURAL',
  NUMBER_OF_EMPLOYEES_ASC = 'NUMBER_OF_EMPLOYEES_ASC',
  NUMBER_OF_EMPLOYEES_DESC = 'NUMBER_OF_EMPLOYEES_DESC',
  POINT_OF_DATE_ASC = 'POINT_OF_DATE_ASC',
  POINT_OF_DATE_DESC = 'POINT_OF_DATE_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
}

export interface EmployeeHeadcountByCompany {
  __typename?: 'EmployeeHeadcountByCompany';
  businessUnitId: Scalars['BigInt']['output'];
  businessUnitName: Scalars['String']['output'];
  companyId: Scalars['BigInt']['output'];
  companyName: Scalars['String']['output'];
  contractTypeId: Scalars['BigInt']['output'];
  countryCodeAlpha2: Scalars['String']['output'];
  countryName: Scalars['String']['output'];
  departmentId: Scalars['BigInt']['output'];
  divisionId: Scalars['BigInt']['output'];
  numberOfEmployees: Scalars['Int']['output'];
  pointOfDate: Scalars['Date']['output'];
  year: Scalars['Float']['output'];
}

export interface EmployeeHeadcountByCompanyCondition {
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  businessUnitName?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  countryName?: InputMaybe<Scalars['String']['input']>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  numberOfEmployees?: InputMaybe<Scalars['Int']['input']>;
  pointOfDate?: InputMaybe<Scalars['Date']['input']>;
  year?: InputMaybe<Scalars['Float']['input']>;
}

export interface EmployeeHeadcountByCompanyFilter {
  and?: InputMaybe<Array<EmployeeHeadcountByCompanyFilter>>;
  businessUnitId?: InputMaybe<BigIntFilter>;
  businessUnitName?: InputMaybe<StringFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  companyName?: InputMaybe<StringFilter>;
  contractTypeId?: InputMaybe<BigIntFilter>;
  countryCodeAlpha2?: InputMaybe<StringFilter>;
  countryName?: InputMaybe<StringFilter>;
  departmentId?: InputMaybe<BigIntFilter>;
  divisionId?: InputMaybe<BigIntFilter>;
  not?: InputMaybe<EmployeeHeadcountByCompanyFilter>;
  numberOfEmployees?: InputMaybe<IntFilter>;
  or?: InputMaybe<Array<EmployeeHeadcountByCompanyFilter>>;
  pointOfDate?: InputMaybe<DateFilter>;
  year?: InputMaybe<FloatFilter>;
}

export interface EmployeeHeadcountByCountriesConnection {
  __typename?: 'EmployeeHeadcountByCountriesConnection';
  edges: Array<EmployeeHeadcountByCountriesEdge>;
  nodes: Array<EmployeeHeadcountByCountry>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeHeadcountByCountriesEdge {
  __typename?: 'EmployeeHeadcountByCountriesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeHeadcountByCountry;
}

export enum EmployeeHeadcountByCountriesOrderBy {
  BUSINESS_UNIT_ID_ASC = 'BUSINESS_UNIT_ID_ASC',
  BUSINESS_UNIT_ID_DESC = 'BUSINESS_UNIT_ID_DESC',
  BUSINESS_UNIT_NAME_ASC = 'BUSINESS_UNIT_NAME_ASC',
  BUSINESS_UNIT_NAME_DESC = 'BUSINESS_UNIT_NAME_DESC',
  CONTRACT_TYPE_ID_ASC = 'CONTRACT_TYPE_ID_ASC',
  CONTRACT_TYPE_ID_DESC = 'CONTRACT_TYPE_ID_DESC',
  COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_CODE_ALPHA2_DESC',
  COUNTRY_NAME_ASC = 'COUNTRY_NAME_ASC',
  COUNTRY_NAME_DESC = 'COUNTRY_NAME_DESC',
  DEPARTMENT_ID_ASC = 'DEPARTMENT_ID_ASC',
  DEPARTMENT_ID_DESC = 'DEPARTMENT_ID_DESC',
  DIVISION_ID_ASC = 'DIVISION_ID_ASC',
  DIVISION_ID_DESC = 'DIVISION_ID_DESC',
  NATURAL = 'NATURAL',
  NUMBER_OF_EMPLOYEES_ASC = 'NUMBER_OF_EMPLOYEES_ASC',
  NUMBER_OF_EMPLOYEES_DESC = 'NUMBER_OF_EMPLOYEES_DESC',
  POINT_OF_DATE_ASC = 'POINT_OF_DATE_ASC',
  POINT_OF_DATE_DESC = 'POINT_OF_DATE_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
}

export interface EmployeeHeadcountByCountry {
  __typename?: 'EmployeeHeadcountByCountry';
  businessUnitId: Scalars['BigInt']['output'];
  businessUnitName: Scalars['String']['output'];
  contractTypeId: Scalars['BigInt']['output'];
  countryCodeAlpha2: Scalars['String']['output'];
  countryName: Scalars['String']['output'];
  departmentId: Scalars['BigInt']['output'];
  divisionId: Scalars['BigInt']['output'];
  numberOfEmployees: Scalars['Int']['output'];
  pointOfDate: Scalars['Date']['output'];
  year: Scalars['Float']['output'];
}

export interface EmployeeHeadcountByCountryCondition {
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  businessUnitName?: InputMaybe<Scalars['String']['input']>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  countryName?: InputMaybe<Scalars['String']['input']>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  numberOfEmployees?: InputMaybe<Scalars['Int']['input']>;
  pointOfDate?: InputMaybe<Scalars['Date']['input']>;
  year?: InputMaybe<Scalars['Float']['input']>;
}

export interface EmployeeHeadcountByCountryFilter {
  and?: InputMaybe<Array<EmployeeHeadcountByCountryFilter>>;
  businessUnitId?: InputMaybe<BigIntFilter>;
  businessUnitName?: InputMaybe<StringFilter>;
  contractTypeId?: InputMaybe<BigIntFilter>;
  countryCodeAlpha2?: InputMaybe<StringFilter>;
  countryName?: InputMaybe<StringFilter>;
  departmentId?: InputMaybe<BigIntFilter>;
  divisionId?: InputMaybe<BigIntFilter>;
  not?: InputMaybe<EmployeeHeadcountByCountryFilter>;
  numberOfEmployees?: InputMaybe<IntFilter>;
  or?: InputMaybe<Array<EmployeeHeadcountByCountryFilter>>;
  pointOfDate?: InputMaybe<DateFilter>;
  year?: InputMaybe<FloatFilter>;
}

export interface EmployeeHeadcountTransferred {
  __typename?: 'EmployeeHeadcountTransferred';
  cnt: Scalars['BigFloat']['output'];
  type: Scalars['String']['output'];
  yearMonthStart?: Maybe<Scalars['Date']['output']>;
}

export interface EmployeeHeadcountTransferredByContractType {
  __typename?: 'EmployeeHeadcountTransferredByContractType';
  cnt?: Maybe<Scalars['BigFloat']['output']>;
  contractTypeId?: Maybe<Scalars['BigInt']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  yearMonthStart?: Maybe<Scalars['Date']['output']>;
}

export interface EmployeeHeadcountTransferredByContractTypeCondition {
  cnt?: InputMaybe<Scalars['BigFloat']['input']>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  yearMonthStart?: InputMaybe<Scalars['Date']['input']>;
}

export interface EmployeeHeadcountTransferredByContractTypeFilter {
  and?: InputMaybe<Array<EmployeeHeadcountTransferredByContractTypeFilter>>;
  cnt?: InputMaybe<BigFloatFilter>;
  contractTypeId?: InputMaybe<BigIntFilter>;
  not?: InputMaybe<EmployeeHeadcountTransferredByContractTypeFilter>;
  or?: InputMaybe<Array<EmployeeHeadcountTransferredByContractTypeFilter>>;
  type?: InputMaybe<StringFilter>;
  yearMonthStart?: InputMaybe<DateFilter>;
}

export interface EmployeeHeadcountTransferredByContractTypesConnection {
  __typename?: 'EmployeeHeadcountTransferredByContractTypesConnection';
  edges: Array<EmployeeHeadcountTransferredByContractTypesEdge>;
  nodes: Array<EmployeeHeadcountTransferredByContractType>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeHeadcountTransferredByContractTypesEdge {
  __typename?: 'EmployeeHeadcountTransferredByContractTypesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeHeadcountTransferredByContractType;
}

export enum EmployeeHeadcountTransferredByContractTypesOrderBy {
  CNT_ASC = 'CNT_ASC',
  CNT_DESC = 'CNT_DESC',
  CONTRACT_TYPE_ID_ASC = 'CONTRACT_TYPE_ID_ASC',
  CONTRACT_TYPE_ID_DESC = 'CONTRACT_TYPE_ID_DESC',
  NATURAL = 'NATURAL',
  TYPE_ASC = 'TYPE_ASC',
  TYPE_DESC = 'TYPE_DESC',
  YEAR_MONTH_START_ASC = 'YEAR_MONTH_START_ASC',
  YEAR_MONTH_START_DESC = 'YEAR_MONTH_START_DESC',
}

export interface EmployeeHeadcountTransferredCondition {
  cnt?: InputMaybe<Scalars['BigFloat']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  yearMonthStart?: InputMaybe<Scalars['Date']['input']>;
}

export interface EmployeeHeadcountTransferredFilter {
  and?: InputMaybe<Array<EmployeeHeadcountTransferredFilter>>;
  cnt?: InputMaybe<BigFloatFilter>;
  not?: InputMaybe<EmployeeHeadcountTransferredFilter>;
  or?: InputMaybe<Array<EmployeeHeadcountTransferredFilter>>;
  type?: InputMaybe<StringFilter>;
  yearMonthStart?: InputMaybe<DateFilter>;
}

export interface EmployeeHeadcountTransferredsConnection {
  __typename?: 'EmployeeHeadcountTransferredsConnection';
  edges: Array<EmployeeHeadcountTransferredsEdge>;
  nodes: Array<EmployeeHeadcountTransferred>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeHeadcountTransferredsEdge {
  __typename?: 'EmployeeHeadcountTransferredsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeHeadcountTransferred;
}

export enum EmployeeHeadcountTransferredsOrderBy {
  CNT_ASC = 'CNT_ASC',
  CNT_DESC = 'CNT_DESC',
  NATURAL = 'NATURAL',
  TYPE_ASC = 'TYPE_ASC',
  TYPE_DESC = 'TYPE_DESC',
  YEAR_MONTH_START_ASC = 'YEAR_MONTH_START_ASC',
  YEAR_MONTH_START_DESC = 'YEAR_MONTH_START_DESC',
}

export interface EmployeeIdType extends Node {
  __typename?: 'EmployeeIdType';
  code: Scalars['String']['output'];
  countryByCountryCodeAlpha2?: Maybe<Country>;
  countryCodeAlpha2?: Maybe<Scalars['String']['output']>;
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  displayName: Scalars['String']['output'];
  displayOrder: Scalars['BigInt']['output'];
  employeeIdTypeId: Scalars['BigInt']['output'];
  employeesByIdType: EmployeesConnection;
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export type EmployeeIdTypeEmployeesByIdTypeArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCondition>;
  filter?: InputMaybe<EmployeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeesOrderBy>>;
};

export interface EmployeeIdTypeCondition {
  code?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeIdTypeCountryCodeAlpha2FkeyCountryCreateInput {
  banks?: InputMaybe<BankCountryCodeAlpha3FkeyInverseInput>;
  cities?: InputMaybe<CityCountryCodeAlpha2FkeyInverseInput>;
  companies?: InputMaybe<CompanyCountryCodeAlpha2FkeyInverseInput>;
  countryCodeAlpha3: Scalars['String']['input'];
  countryCodeNumeric?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInverseInput>;
  employeeIdTypes?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInverseInput>;
  employeesToMobileNumberCountryIdUsingCountryCodeAlpha2?: InputMaybe<EmployeeMobileNumberCountryIdFkeyInverseInput>;
  employeesToNationalityUsingCountryCodeAlpha3?: InputMaybe<EmployeeNationalityFkeyInverseInput>;
  employeesToWorkingLocationCountryCodeAlpha2UsingCountryCodeAlpha2?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0InverseInput>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName: Scalars['String']['input'];
  telephoneCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeIdTypeCountryCodeAlpha2FkeyEmployeeIdTypeCreateInput {
  code: Scalars['String']['input'];
  country?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInput>;
  displayName: Scalars['String']['input'];
  displayOrder: Scalars['BigInt']['input'];
  employeeIdTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  employees?: InputMaybe<EmployeeIdTypeFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeIdTypeCountryCodeAlpha2FkeyInput {
  connectByCountryCodeAlpha2?: InputMaybe<CountryCountryPkeyConnect>;
  connectByCountryCodeAlpha3?: InputMaybe<CountryCountryCountryCodeAlpha3KeyConnect>;
  connectByCountryCodeNumeric?: InputMaybe<CountryCountryCountryCodeNumericKeyConnect>;
  connectByDisplayOrder?: InputMaybe<CountryCountryDisplayOrderKeyConnect>;
  connectByNodeId?: InputMaybe<CountryNodeIdConnect>;
  create?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyCountryCreateInput>;
  deleteByCountryCodeAlpha2?: InputMaybe<CountryCountryPkeyDelete>;
  deleteByCountryCodeAlpha3?: InputMaybe<CountryCountryCountryCodeAlpha3KeyDelete>;
  deleteByCountryCodeNumeric?: InputMaybe<CountryCountryCountryCodeNumericKeyDelete>;
  deleteByDisplayOrder?: InputMaybe<CountryCountryDisplayOrderKeyDelete>;
  deleteByNodeId?: InputMaybe<CountryNodeIdDelete>;
  updateByCountryCodeAlpha2?: InputMaybe<CountryOnEmployeeIdTypeForEmployeeIdTypeCountryCodeAlpha2FkeyUsingCountryPkeyUpdate>;
  updateByCountryCodeAlpha3?: InputMaybe<CountryOnEmployeeIdTypeForEmployeeIdTypeCountryCodeAlpha2FkeyUsingCountryCountryCodeAlpha3KeyUpdate>;
  updateByCountryCodeNumeric?: InputMaybe<CountryOnEmployeeIdTypeForEmployeeIdTypeCountryCodeAlpha2FkeyUsingCountryCountryCodeNumericKeyUpdate>;
  updateByDisplayOrder?: InputMaybe<CountryOnEmployeeIdTypeForEmployeeIdTypeCountryCodeAlpha2FkeyUsingCountryDisplayOrderKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeIdTypeOnEmployeeIdTypeForEmployeeIdTypeCountryCodeAlpha2FkeyNodeIdUpdate>;
}

export interface EmployeeIdTypeCountryCodeAlpha2FkeyInverseInput {
  connectByCode?: InputMaybe<Array<EmployeeIdTypeEmployeeIdTypeCodeKeyConnect>>;
  connectByEmployeeIdTypeId?: InputMaybe<Array<EmployeeIdTypeEmployeeIdTypePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeIdTypeNodeIdConnect>>;
  create?: InputMaybe<Array<EmployeeIdTypeCountryCodeAlpha2FkeyEmployeeIdTypeCreateInput>>;
  deleteByCode?: InputMaybe<Array<EmployeeIdTypeEmployeeIdTypeCodeKeyDelete>>;
  deleteByEmployeeIdTypeId?: InputMaybe<Array<EmployeeIdTypeEmployeeIdTypePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeIdTypeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCode?: InputMaybe<Array<EmployeeIdTypeOnEmployeeIdTypeForEmployeeIdTypeCountryCodeAlpha2FkeyUsingEmployeeIdTypeCodeKeyUpdate>>;
  updateByEmployeeIdTypeId?: InputMaybe<Array<EmployeeIdTypeOnEmployeeIdTypeForEmployeeIdTypeCountryCodeAlpha2FkeyUsingEmployeeIdTypePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<CountryOnEmployeeIdTypeForEmployeeIdTypeCountryCodeAlpha2FkeyNodeIdUpdate>>;
}

export interface EmployeeIdTypeEmployeeIdTypeCodeKeyConnect {
  code: Scalars['String']['input'];
}

export interface EmployeeIdTypeEmployeeIdTypeCodeKeyDelete {
  code: Scalars['String']['input'];
}

export interface EmployeeIdTypeEmployeeIdTypePkeyConnect {
  employeeIdTypeId: Scalars['BigInt']['input'];
}

export interface EmployeeIdTypeEmployeeIdTypePkeyDelete {
  employeeIdTypeId: Scalars['BigInt']['input'];
}

export interface EmployeeIdTypeFilter {
  and?: InputMaybe<Array<EmployeeIdTypeFilter>>;
  code?: InputMaybe<StringFilter>;
  countryByCountryCodeAlpha2?: InputMaybe<CountryFilter>;
  countryByCountryCodeAlpha2Exists?: InputMaybe<Scalars['Boolean']['input']>;
  countryCodeAlpha2?: InputMaybe<StringFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  displayName?: InputMaybe<StringFilter>;
  displayOrder?: InputMaybe<BigIntFilter>;
  employeeIdTypeId?: InputMaybe<BigIntFilter>;
  employeesByIdType?: InputMaybe<EmployeeIdTypeToManyEmployeeFilter>;
  employeesByIdTypeExist?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<EmployeeIdTypeFilter>;
  or?: InputMaybe<Array<EmployeeIdTypeFilter>>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface EmployeeIdTypeFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeIdTypeFkeyEmployeeIdTypeCreateInput {
  code: Scalars['String']['input'];
  country?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  displayOrder: Scalars['BigInt']['input'];
  employeeIdTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  employees?: InputMaybe<EmployeeIdTypeFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeIdTypeFkeyInput {
  connectByCode?: InputMaybe<EmployeeIdTypeEmployeeIdTypeCodeKeyConnect>;
  connectByEmployeeIdTypeId?: InputMaybe<EmployeeIdTypeEmployeeIdTypePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeIdTypeNodeIdConnect>;
  create?: InputMaybe<EmployeeIdTypeFkeyEmployeeIdTypeCreateInput>;
  deleteByCode?: InputMaybe<EmployeeIdTypeEmployeeIdTypeCodeKeyDelete>;
  deleteByEmployeeIdTypeId?: InputMaybe<EmployeeIdTypeEmployeeIdTypePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeIdTypeNodeIdDelete>;
  updateByCode?: InputMaybe<EmployeeIdTypeOnEmployeeForEmployeeIdTypeFkeyUsingEmployeeIdTypeCodeKeyUpdate>;
  updateByEmployeeIdTypeId?: InputMaybe<EmployeeIdTypeOnEmployeeForEmployeeIdTypeFkeyUsingEmployeeIdTypePkeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeOnEmployeeForEmployeeIdTypeFkeyNodeIdUpdate>;
}

export interface EmployeeIdTypeFkeyInverseInput {
  connectByCompanyEmail?: InputMaybe<Array<EmployeeEmployeeCompanyEmailKeyConnect>>;
  connectByEmployeeId?: InputMaybe<Array<EmployeeEmployeePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeNodeIdConnect>>;
  create?: InputMaybe<Array<EmployeeIdTypeFkeyEmployeeCreateInput>>;
  deleteByCompanyEmail?: InputMaybe<Array<EmployeeEmployeeCompanyEmailKeyDelete>>;
  deleteByEmployeeId?: InputMaybe<Array<EmployeeEmployeePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyEmail?: InputMaybe<Array<EmployeeOnEmployeeForEmployeeIdTypeFkeyUsingEmployeeCompanyEmailKeyUpdate>>;
  updateByEmployeeId?: InputMaybe<Array<EmployeeOnEmployeeForEmployeeIdTypeFkeyUsingEmployeePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<EmployeeIdTypeOnEmployeeForEmployeeIdTypeFkeyNodeIdUpdate>>;
}

export interface EmployeeIdTypeInput {
  code: Scalars['String']['input'];
  country?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  displayOrder: Scalars['BigInt']['input'];
  employeeIdTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  employees?: InputMaybe<EmployeeIdTypeFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeIdTypeNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeIdTypeNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeIdTypeOnEmployeeForEmployeeIdTypeFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface EmployeeIdTypeOnEmployeeForEmployeeIdTypeFkeyUsingEmployeeIdTypeCodeKeyUpdate {
  code: Scalars['String']['input'];
  patch: UpdateEmployeeIdTypeOnEmployeeForEmployeeIdTypeFkeyPatch;
}

export interface EmployeeIdTypeOnEmployeeForEmployeeIdTypeFkeyUsingEmployeeIdTypePkeyUpdate {
  employeeIdTypeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeIdTypeOnEmployeeForEmployeeIdTypeFkeyPatch;
}

export interface EmployeeIdTypeOnEmployeeIdTypeForEmployeeIdTypeCountryCodeAlpha2FkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CountryPatch;
}

export interface EmployeeIdTypeOnEmployeeIdTypeForEmployeeIdTypeCountryCodeAlpha2FkeyUsingEmployeeIdTypeCodeKeyUpdate {
  code: Scalars['String']['input'];
  patch: UpdateEmployeeIdTypeOnEmployeeIdTypeForEmployeeIdTypeCountryCodeAlpha2FkeyPatch;
}

export interface EmployeeIdTypeOnEmployeeIdTypeForEmployeeIdTypeCountryCodeAlpha2FkeyUsingEmployeeIdTypePkeyUpdate {
  employeeIdTypeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeIdTypeOnEmployeeIdTypeForEmployeeIdTypeCountryCodeAlpha2FkeyPatch;
}

export interface EmployeeIdTypeOnEmployeeIdTypeForEmployeeIdTypeUserCreatedFkeyUsingEmployeeIdTypeCodeKeyUpdate {
  code: Scalars['String']['input'];
  patch: UpdateEmployeeIdTypeOnEmployeeIdTypeForEmployeeIdTypeUserCreatedFkeyPatch;
}

export interface EmployeeIdTypeOnEmployeeIdTypeForEmployeeIdTypeUserCreatedFkeyUsingEmployeeIdTypePkeyUpdate {
  employeeIdTypeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeIdTypeOnEmployeeIdTypeForEmployeeIdTypeUserCreatedFkeyPatch;
}

export interface EmployeeIdTypeOnEmployeeIdTypeForEmployeeIdTypeUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface EmployeeIdTypeOnEmployeeIdTypeForEmployeeIdTypeUserUpdatedFkeyUsingEmployeeIdTypeCodeKeyUpdate {
  code: Scalars['String']['input'];
  patch: UpdateEmployeeIdTypeOnEmployeeIdTypeForEmployeeIdTypeUserUpdatedFkeyPatch;
}

export interface EmployeeIdTypeOnEmployeeIdTypeForEmployeeIdTypeUserUpdatedFkeyUsingEmployeeIdTypePkeyUpdate {
  employeeIdTypeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeIdTypeOnEmployeeIdTypeForEmployeeIdTypeUserUpdatedFkeyPatch;
}

export interface EmployeeIdTypePatch {
  code?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  employees?: InputMaybe<EmployeeIdTypeFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeIdTypeToManyEmployeeFilter {
  every?: InputMaybe<EmployeeFilter>;
  none?: InputMaybe<EmployeeFilter>;
  some?: InputMaybe<EmployeeFilter>;
}

export interface EmployeeIdTypeUserCreatedFkeyInverseInput {
  connectByCode?: InputMaybe<Array<EmployeeIdTypeEmployeeIdTypeCodeKeyConnect>>;
  connectByEmployeeIdTypeId?: InputMaybe<Array<EmployeeIdTypeEmployeeIdTypePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeIdTypeNodeIdConnect>>;
  deleteByCode?: InputMaybe<Array<EmployeeIdTypeEmployeeIdTypeCodeKeyDelete>>;
  deleteByEmployeeIdTypeId?: InputMaybe<Array<EmployeeIdTypeEmployeeIdTypePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeIdTypeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCode?: InputMaybe<Array<EmployeeIdTypeOnEmployeeIdTypeForEmployeeIdTypeUserCreatedFkeyUsingEmployeeIdTypeCodeKeyUpdate>>;
  updateByEmployeeIdTypeId?: InputMaybe<Array<EmployeeIdTypeOnEmployeeIdTypeForEmployeeIdTypeUserCreatedFkeyUsingEmployeeIdTypePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmployeeIdTypeForEmployeeIdTypeUserCreatedFkeyNodeIdUpdate>>;
}

export interface EmployeeIdTypeUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnEmployeeIdTypeForEmployeeIdTypeUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeIdTypeOnEmployeeIdTypeForEmployeeIdTypeUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnEmployeeIdTypeForEmployeeIdTypeUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface EmployeeIdTypeUserUpdatedFkeyInverseInput {
  connectByCode?: InputMaybe<Array<EmployeeIdTypeEmployeeIdTypeCodeKeyConnect>>;
  connectByEmployeeIdTypeId?: InputMaybe<Array<EmployeeIdTypeEmployeeIdTypePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeIdTypeNodeIdConnect>>;
  deleteByCode?: InputMaybe<Array<EmployeeIdTypeEmployeeIdTypeCodeKeyDelete>>;
  deleteByEmployeeIdTypeId?: InputMaybe<Array<EmployeeIdTypeEmployeeIdTypePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeIdTypeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCode?: InputMaybe<Array<EmployeeIdTypeOnEmployeeIdTypeForEmployeeIdTypeUserUpdatedFkeyUsingEmployeeIdTypeCodeKeyUpdate>>;
  updateByEmployeeIdTypeId?: InputMaybe<Array<EmployeeIdTypeOnEmployeeIdTypeForEmployeeIdTypeUserUpdatedFkeyUsingEmployeeIdTypePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmployeeIdTypeForEmployeeIdTypeUserUpdatedFkeyNodeIdUpdate>>;
}

export interface EmployeeIdTypesConnection {
  __typename?: 'EmployeeIdTypesConnection';
  edges: Array<EmployeeIdTypesEdge>;
  nodes: Array<EmployeeIdType>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeIdTypesEdge {
  __typename?: 'EmployeeIdTypesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeIdType;
}

export enum EmployeeIdTypesOrderBy {
  CODE_ASC = 'CODE_ASC',
  CODE_DESC = 'CODE_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_CREATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_CREATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_CREATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_CREATED_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_UPDATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_UPDATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_UPDATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_UPDATED_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__REMARK_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__REMARK_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__REMARK_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__REMARK_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__SHORT_NAME_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__SHORT_NAME_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__SHORT_NAME_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__SHORT_NAME_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_CREATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_CREATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_CREATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_CREATED_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_UPDATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_UPDATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_UPDATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_UPDATED_DESC',
  COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_CODE_ALPHA2_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DISPLAY_NAME_ASC = 'DISPLAY_NAME_ASC',
  DISPLAY_NAME_DESC = 'DISPLAY_NAME_DESC',
  DISPLAY_ORDER_ASC = 'DISPLAY_ORDER_ASC',
  DISPLAY_ORDER_DESC = 'DISPLAY_ORDER_DESC',
  EMPLOYEES_BY_ID_TYPE__COUNT_ASC = 'EMPLOYEES_BY_ID_TYPE__COUNT_ASC',
  EMPLOYEES_BY_ID_TYPE__COUNT_DESC = 'EMPLOYEES_BY_ID_TYPE__COUNT_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_APPROVER_ID_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_APPROVER_ID_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_APPROVER_ID_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_APPROVER_ID_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_BANK_ID_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_BANK_ID_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_BANK_ID_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_BANK_ID_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_BANK_REMARKS_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_BANK_REMARKS_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_BANK_REMARKS_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_BANK_REMARKS_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_DATE_CREATED_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_DATE_CREATED_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_DATE_CREATED_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_DATE_CREATED_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_DATE_UPDATED_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_DATE_UPDATED_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_DATE_UPDATED_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_DATE_UPDATED_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_FULL_NAME_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_FULL_NAME_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_FULL_NAME_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_FULL_NAME_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_GENDER_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_GENDER_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_GENDER_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_GENDER_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_ID_NUMBER_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_ID_NUMBER_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_ID_NUMBER_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_ID_NUMBER_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_ID_TYPE_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_ID_TYPE_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_ID_TYPE_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_ID_TYPE_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_JOIN_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_JOIN_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_JOIN_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_JOIN_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_NATIONALITY_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_NATIONALITY_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_NATIONALITY_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_NATIONALITY_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_NICKNAME_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_NICKNAME_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_NICKNAME_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_NICKNAME_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_PROBATION_END_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_PROBATION_END_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_PROBATION_END_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_PROBATION_END_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_REMARK_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_REMARK_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_REMARK_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_REMARK_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_STATUS_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_STATUS_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_STATUS_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_STATUS_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_TAX_CODE_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_TAX_CODE_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_TAX_CODE_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_TAX_CODE_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_USER_CREATED_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_USER_CREATED_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_USER_CREATED_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_USER_CREATED_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_USER_UPDATED_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_USER_UPDATED_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_USER_UPDATED_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_USER_UPDATED_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_VISA_NUMBER_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_VISA_NUMBER_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_VISA_NUMBER_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_VISA_NUMBER_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_VISA_TYPE_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_VISA_TYPE_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_VISA_TYPE_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_VISA_TYPE_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MAX_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_ID_TYPE__MAX_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_ID_TYPE__MAX_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_ID_TYPE__MAX_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_APPROVER_ID_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_APPROVER_ID_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_APPROVER_ID_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_APPROVER_ID_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_BANK_ID_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_BANK_ID_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_BANK_ID_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_BANK_ID_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_BANK_REMARKS_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_BANK_REMARKS_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_BANK_REMARKS_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_BANK_REMARKS_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_DATE_CREATED_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_DATE_CREATED_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_DATE_CREATED_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_DATE_CREATED_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_DATE_UPDATED_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_DATE_UPDATED_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_DATE_UPDATED_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_DATE_UPDATED_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_FULL_NAME_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_FULL_NAME_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_FULL_NAME_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_FULL_NAME_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_GENDER_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_GENDER_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_GENDER_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_GENDER_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_ID_NUMBER_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_ID_NUMBER_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_ID_NUMBER_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_ID_NUMBER_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_ID_TYPE_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_ID_TYPE_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_ID_TYPE_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_ID_TYPE_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_JOIN_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_JOIN_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_JOIN_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_JOIN_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_NATIONALITY_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_NATIONALITY_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_NATIONALITY_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_NATIONALITY_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_NICKNAME_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_NICKNAME_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_NICKNAME_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_NICKNAME_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_PROBATION_END_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_PROBATION_END_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_PROBATION_END_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_PROBATION_END_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_REMARK_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_REMARK_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_REMARK_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_REMARK_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_STATUS_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_STATUS_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_STATUS_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_STATUS_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_TAX_CODE_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_TAX_CODE_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_TAX_CODE_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_TAX_CODE_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_USER_CREATED_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_USER_CREATED_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_USER_CREATED_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_USER_CREATED_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_USER_UPDATED_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_USER_UPDATED_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_USER_UPDATED_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_USER_UPDATED_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_VISA_NUMBER_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_VISA_NUMBER_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_VISA_NUMBER_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_VISA_NUMBER_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_VISA_TYPE_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_VISA_TYPE_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_VISA_TYPE_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_VISA_TYPE_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_ID_TYPE__MIN_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_ID_TYPE__MIN_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_ID_TYPE__MIN_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_ID_TYPE__MIN_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_ID_TYPE_ID_ASC = 'EMPLOYEE_ID_TYPE_ID_ASC',
  EMPLOYEE_ID_TYPE_ID_DESC = 'EMPLOYEE_ID_TYPE_ID_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface EmployeeInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeLatestTpHistoryByYearQuarter {
  __typename?: 'EmployeeLatestTpHistoryByYearQuarter';
  businessUnitId?: Maybe<Scalars['BigInt']['output']>;
  companyId: Scalars['BigInt']['output'];
  contractTypeId: Scalars['BigInt']['output'];
  departmentId?: Maybe<Scalars['BigInt']['output']>;
  divisionId?: Maybe<Scalars['BigInt']['output']>;
  effectiveDate: Scalars['Date']['output'];
  effectiveDateUntil?: Maybe<Scalars['Date']['output']>;
  employeeCode?: Maybe<Scalars['String']['output']>;
  employeeId: Scalars['BigInt']['output'];
  employeeLevelId: Scalars['BigInt']['output'];
  employeeTpHistoryId: Scalars['BigInt']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  officeId?: Maybe<Scalars['BigInt']['output']>;
  quarter: PerformanceReviewQuarter;
  team?: Maybe<Scalars['String']['output']>;
  year: Scalars['Int']['output'];
}

export interface EmployeeLatestTpHistoryByYearQuarterCondition {
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  team?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface EmployeeLatestTpHistoryByYearQuarterFilter {
  and?: InputMaybe<Array<EmployeeLatestTpHistoryByYearQuarterFilter>>;
  businessUnitId?: InputMaybe<BigIntFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  contractTypeId?: InputMaybe<BigIntFilter>;
  departmentId?: InputMaybe<BigIntFilter>;
  divisionId?: InputMaybe<BigIntFilter>;
  effectiveDate?: InputMaybe<DateFilter>;
  effectiveDateUntil?: InputMaybe<DateFilter>;
  employeeCode?: InputMaybe<StringFilter>;
  employeeId?: InputMaybe<BigIntFilter>;
  employeeLevelId?: InputMaybe<BigIntFilter>;
  employeeTpHistoryId?: InputMaybe<BigIntFilter>;
  jobTitle?: InputMaybe<StringFilter>;
  not?: InputMaybe<EmployeeLatestTpHistoryByYearQuarterFilter>;
  officeId?: InputMaybe<BigIntFilter>;
  or?: InputMaybe<Array<EmployeeLatestTpHistoryByYearQuarterFilter>>;
  quarter?: InputMaybe<PerformanceReviewQuarterFilter>;
  team?: InputMaybe<StringFilter>;
  year?: InputMaybe<IntFilter>;
}

export interface EmployeeLatestTpHistoryByYearQuartersConnection {
  __typename?: 'EmployeeLatestTpHistoryByYearQuartersConnection';
  edges: Array<EmployeeLatestTpHistoryByYearQuartersEdge>;
  nodes: Array<EmployeeLatestTpHistoryByYearQuarter>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeLatestTpHistoryByYearQuartersEdge {
  __typename?: 'EmployeeLatestTpHistoryByYearQuartersEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeLatestTpHistoryByYearQuarter;
}

export enum EmployeeLatestTpHistoryByYearQuartersOrderBy {
  BUSINESS_UNIT_ID_ASC = 'BUSINESS_UNIT_ID_ASC',
  BUSINESS_UNIT_ID_DESC = 'BUSINESS_UNIT_ID_DESC',
  COMPANY_ID_ASC = 'COMPANY_ID_ASC',
  COMPANY_ID_DESC = 'COMPANY_ID_DESC',
  CONTRACT_TYPE_ID_ASC = 'CONTRACT_TYPE_ID_ASC',
  CONTRACT_TYPE_ID_DESC = 'CONTRACT_TYPE_ID_DESC',
  DEPARTMENT_ID_ASC = 'DEPARTMENT_ID_ASC',
  DEPARTMENT_ID_DESC = 'DEPARTMENT_ID_DESC',
  DIVISION_ID_ASC = 'DIVISION_ID_ASC',
  DIVISION_ID_DESC = 'DIVISION_ID_DESC',
  EFFECTIVE_DATE_ASC = 'EFFECTIVE_DATE_ASC',
  EFFECTIVE_DATE_DESC = 'EFFECTIVE_DATE_DESC',
  EFFECTIVE_DATE_UNTIL_ASC = 'EFFECTIVE_DATE_UNTIL_ASC',
  EFFECTIVE_DATE_UNTIL_DESC = 'EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_CODE_ASC = 'EMPLOYEE_CODE_ASC',
  EMPLOYEE_CODE_DESC = 'EMPLOYEE_CODE_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORY_ID_DESC',
  JOB_TITLE_ASC = 'JOB_TITLE_ASC',
  JOB_TITLE_DESC = 'JOB_TITLE_DESC',
  NATURAL = 'NATURAL',
  OFFICE_ID_ASC = 'OFFICE_ID_ASC',
  OFFICE_ID_DESC = 'OFFICE_ID_DESC',
  QUARTER_ASC = 'QUARTER_ASC',
  QUARTER_DESC = 'QUARTER_DESC',
  TEAM_ASC = 'TEAM_ASC',
  TEAM_DESC = 'TEAM_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
}

export interface EmployeeLevel extends Node {
  __typename?: 'EmployeeLevel';
  canEditTargetCountry: Scalars['Boolean']['output'];
  canEditTargetIndividual: Scalars['Boolean']['output'];
  canEditTargetTeam: Scalars['Boolean']['output'];
  canSeePerformanceReviewOverallOfAll: Scalars['Boolean']['output'];
  canSeePerformanceReviewOverallOfCountry: Scalars['Boolean']['output'];
  canSeeProbationReviewOfAll: Scalars['Boolean']['output'];
  canSeeProbationReviewOfCountry: Scalars['Boolean']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  displayName: Scalars['String']['output'];
  employeeCurrentPositions: EmployeeCurrentPositionsConnection;
  employeeLevelId: Scalars['BigInt']['output'];
  employeeLevelOrder: Scalars['Int']['output'];
  employeeTpHistories: EmployeeTpHistoriesConnection;
  nodeId: Scalars['ID']['output'];
  performanceReviewMasterWeightingDetails: PerformanceReviewMasterWeightingDetailsConnection;
  remark?: Maybe<Scalars['String']['output']>;
  titleContributor?: Maybe<Scalars['String']['output']>;
  titleManager?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export type EmployeeLevelEmployeeCurrentPositionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCurrentPositionCondition>;
  filter?: InputMaybe<EmployeeCurrentPositionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeCurrentPositionsOrderBy>>;
};

export type EmployeeLevelEmployeeTpHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeTpHistoryCondition>;
  filter?: InputMaybe<EmployeeTpHistoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeTpHistoriesOrderBy>>;
};

export type EmployeeLevelPerformanceReviewMasterWeightingDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterWeightingDetailCondition>;
  filter?: InputMaybe<PerformanceReviewMasterWeightingDetailFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailsOrderBy>>;
};

export interface EmployeeLevelCondition {
  canEditTargetCountry?: InputMaybe<Scalars['Boolean']['input']>;
  canEditTargetIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  canEditTargetTeam?: InputMaybe<Scalars['Boolean']['input']>;
  canSeePerformanceReviewOverallOfAll?: InputMaybe<Scalars['Boolean']['input']>;
  canSeePerformanceReviewOverallOfCountry?: InputMaybe<Scalars['Boolean']['input']>;
  canSeeProbationReviewOfAll?: InputMaybe<Scalars['Boolean']['input']>;
  canSeeProbationReviewOfCountry?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevelOrder?: InputMaybe<Scalars['Int']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  titleContributor?: InputMaybe<Scalars['String']['input']>;
  titleManager?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeLevelEmployeeLevelDisplayNameKeyConnect {
  displayName: Scalars['String']['input'];
}

export interface EmployeeLevelEmployeeLevelDisplayNameKeyDelete {
  displayName: Scalars['String']['input'];
}

export interface EmployeeLevelEmployeeLevelPkeyConnect {
  employeeLevelId: Scalars['BigInt']['input'];
}

export interface EmployeeLevelEmployeeLevelPkeyDelete {
  employeeLevelId: Scalars['BigInt']['input'];
}

export interface EmployeeLevelFilter {
  and?: InputMaybe<Array<EmployeeLevelFilter>>;
  canEditTargetCountry?: InputMaybe<BooleanFilter>;
  canEditTargetIndividual?: InputMaybe<BooleanFilter>;
  canEditTargetTeam?: InputMaybe<BooleanFilter>;
  canSeePerformanceReviewOverallOfAll?: InputMaybe<BooleanFilter>;
  canSeePerformanceReviewOverallOfCountry?: InputMaybe<BooleanFilter>;
  canSeeProbationReviewOfAll?: InputMaybe<BooleanFilter>;
  canSeeProbationReviewOfCountry?: InputMaybe<BooleanFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  displayName?: InputMaybe<StringFilter>;
  employeeCurrentPositions?: InputMaybe<EmployeeLevelToManyEmployeeCurrentPositionFilter>;
  employeeCurrentPositionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeLevelId?: InputMaybe<BigIntFilter>;
  employeeLevelOrder?: InputMaybe<IntFilter>;
  employeeTpHistories?: InputMaybe<EmployeeLevelToManyEmployeeTpHistoryFilter>;
  employeeTpHistoriesExist?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<EmployeeLevelFilter>;
  or?: InputMaybe<Array<EmployeeLevelFilter>>;
  performanceReviewMasterWeightingDetails?: InputMaybe<EmployeeLevelToManyPerformanceReviewMasterWeightingDetailFilter>;
  performanceReviewMasterWeightingDetailsExist?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<StringFilter>;
  titleContributor?: InputMaybe<StringFilter>;
  titleManager?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface EmployeeLevelInput {
  canEditTargetCountry?: InputMaybe<Scalars['Boolean']['input']>;
  canEditTargetIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  canEditTargetTeam?: InputMaybe<Scalars['Boolean']['input']>;
  canSeePerformanceReviewOverallOfAll?: InputMaybe<Scalars['Boolean']['input']>;
  canSeePerformanceReviewOverallOfCountry?: InputMaybe<Scalars['Boolean']['input']>;
  canSeeProbationReviewOfAll?: InputMaybe<Scalars['Boolean']['input']>;
  canSeeProbationReviewOfCountry?: InputMaybe<Scalars['Boolean']['input']>;
  displayName: Scalars['String']['input'];
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey3InverseInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevelOrder: Scalars['Int']['input'];
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInverseInput>;
  performanceReviewMasterWeightingDetails?: InputMaybe<PerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  titleContributor?: InputMaybe<Scalars['String']['input']>;
  titleManager?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeLevelUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeLevelNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeLevelNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeLevelOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey3NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeCurrentPositionPatch;
}

export interface EmployeeLevelOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey3UsingEmployeeLevelDisplayNameKeyUpdate {
  displayName: Scalars['String']['input'];
  patch: UpdateEmployeeLevelOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey3Patch;
}

export interface EmployeeLevelOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey3UsingEmployeeLevelPkeyUpdate {
  employeeLevelId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeLevelOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey3Patch;
}

export interface EmployeeLevelOnEmployeeLevelForEmployeeLevelUserCreatedFkeyUsingEmployeeLevelDisplayNameKeyUpdate {
  displayName: Scalars['String']['input'];
  patch: UpdateEmployeeLevelOnEmployeeLevelForEmployeeLevelUserCreatedFkeyPatch;
}

export interface EmployeeLevelOnEmployeeLevelForEmployeeLevelUserCreatedFkeyUsingEmployeeLevelPkeyUpdate {
  employeeLevelId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeLevelOnEmployeeLevelForEmployeeLevelUserCreatedFkeyPatch;
}

export interface EmployeeLevelOnEmployeeLevelForEmployeeLevelUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface EmployeeLevelOnEmployeeLevelForEmployeeLevelUserUpdatedFkeyUsingEmployeeLevelDisplayNameKeyUpdate {
  displayName: Scalars['String']['input'];
  patch: UpdateEmployeeLevelOnEmployeeLevelForEmployeeLevelUserUpdatedFkeyPatch;
}

export interface EmployeeLevelOnEmployeeLevelForEmployeeLevelUserUpdatedFkeyUsingEmployeeLevelPkeyUpdate {
  employeeLevelId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeLevelOnEmployeeLevelForEmployeeLevelUserUpdatedFkeyPatch;
}

export interface EmployeeLevelOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeLevelIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeTpHistoryPatch;
}

export interface EmployeeLevelOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeLevelIdFkeyUsingEmployeeLevelDisplayNameKeyUpdate {
  displayName: Scalars['String']['input'];
  patch: UpdateEmployeeLevelOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeLevelIdFkeyPatch;
}

export interface EmployeeLevelOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeLevelIdFkeyUsingEmployeeLevelPkeyUpdate {
  employeeLevelId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeLevelOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeLevelIdFkeyPatch;
}

export interface EmployeeLevelOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterWeightingDetailPatch;
}

export interface EmployeeLevelOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyUsingEmployeeLevelDisplayNameKeyUpdate {
  displayName: Scalars['String']['input'];
  patch: UpdateEmployeeLevelOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyPatch;
}

export interface EmployeeLevelOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyUsingEmployeeLevelPkeyUpdate {
  employeeLevelId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeLevelOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyPatch;
}

export interface EmployeeLevelPatch {
  canEditTargetCountry?: InputMaybe<Scalars['Boolean']['input']>;
  canEditTargetIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  canEditTargetTeam?: InputMaybe<Scalars['Boolean']['input']>;
  canSeePerformanceReviewOverallOfAll?: InputMaybe<Scalars['Boolean']['input']>;
  canSeePerformanceReviewOverallOfCountry?: InputMaybe<Scalars['Boolean']['input']>;
  canSeeProbationReviewOfAll?: InputMaybe<Scalars['Boolean']['input']>;
  canSeeProbationReviewOfCountry?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey3InverseInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevelOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInverseInput>;
  performanceReviewMasterWeightingDetails?: InputMaybe<PerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  titleContributor?: InputMaybe<Scalars['String']['input']>;
  titleManager?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeLevelUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeLevelToManyEmployeeCurrentPositionFilter {
  every?: InputMaybe<EmployeeCurrentPositionFilter>;
  none?: InputMaybe<EmployeeCurrentPositionFilter>;
  some?: InputMaybe<EmployeeCurrentPositionFilter>;
}

export interface EmployeeLevelToManyEmployeeTpHistoryFilter {
  every?: InputMaybe<EmployeeTpHistoryFilter>;
  none?: InputMaybe<EmployeeTpHistoryFilter>;
  some?: InputMaybe<EmployeeTpHistoryFilter>;
}

export interface EmployeeLevelToManyPerformanceReviewMasterWeightingDetailFilter {
  every?: InputMaybe<PerformanceReviewMasterWeightingDetailFilter>;
  none?: InputMaybe<PerformanceReviewMasterWeightingDetailFilter>;
  some?: InputMaybe<PerformanceReviewMasterWeightingDetailFilter>;
}

export interface EmployeeLevelUserCreatedFkeyInverseInput {
  connectByDisplayName?: InputMaybe<Array<EmployeeLevelEmployeeLevelDisplayNameKeyConnect>>;
  connectByEmployeeLevelId?: InputMaybe<Array<EmployeeLevelEmployeeLevelPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeLevelNodeIdConnect>>;
  deleteByDisplayName?: InputMaybe<Array<EmployeeLevelEmployeeLevelDisplayNameKeyDelete>>;
  deleteByEmployeeLevelId?: InputMaybe<Array<EmployeeLevelEmployeeLevelPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeLevelNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByDisplayName?: InputMaybe<Array<EmployeeLevelOnEmployeeLevelForEmployeeLevelUserCreatedFkeyUsingEmployeeLevelDisplayNameKeyUpdate>>;
  updateByEmployeeLevelId?: InputMaybe<Array<EmployeeLevelOnEmployeeLevelForEmployeeLevelUserCreatedFkeyUsingEmployeeLevelPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmployeeLevelForEmployeeLevelUserCreatedFkeyNodeIdUpdate>>;
}

export interface EmployeeLevelUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnEmployeeLevelForEmployeeLevelUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeLevelOnEmployeeLevelForEmployeeLevelUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnEmployeeLevelForEmployeeLevelUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface EmployeeLevelUserUpdatedFkeyInverseInput {
  connectByDisplayName?: InputMaybe<Array<EmployeeLevelEmployeeLevelDisplayNameKeyConnect>>;
  connectByEmployeeLevelId?: InputMaybe<Array<EmployeeLevelEmployeeLevelPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeLevelNodeIdConnect>>;
  deleteByDisplayName?: InputMaybe<Array<EmployeeLevelEmployeeLevelDisplayNameKeyDelete>>;
  deleteByEmployeeLevelId?: InputMaybe<Array<EmployeeLevelEmployeeLevelPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeLevelNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByDisplayName?: InputMaybe<Array<EmployeeLevelOnEmployeeLevelForEmployeeLevelUserUpdatedFkeyUsingEmployeeLevelDisplayNameKeyUpdate>>;
  updateByEmployeeLevelId?: InputMaybe<Array<EmployeeLevelOnEmployeeLevelForEmployeeLevelUserUpdatedFkeyUsingEmployeeLevelPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmployeeLevelForEmployeeLevelUserUpdatedFkeyNodeIdUpdate>>;
}

export interface EmployeeLevelsConnection {
  __typename?: 'EmployeeLevelsConnection';
  edges: Array<EmployeeLevelsEdge>;
  nodes: Array<EmployeeLevel>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeLevelsEdge {
  __typename?: 'EmployeeLevelsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeLevel;
}

export enum EmployeeLevelsOrderBy {
  CAN_EDIT_TARGET_COUNTRY_ASC = 'CAN_EDIT_TARGET_COUNTRY_ASC',
  CAN_EDIT_TARGET_COUNTRY_DESC = 'CAN_EDIT_TARGET_COUNTRY_DESC',
  CAN_EDIT_TARGET_INDIVIDUAL_ASC = 'CAN_EDIT_TARGET_INDIVIDUAL_ASC',
  CAN_EDIT_TARGET_INDIVIDUAL_DESC = 'CAN_EDIT_TARGET_INDIVIDUAL_DESC',
  CAN_EDIT_TARGET_TEAM_ASC = 'CAN_EDIT_TARGET_TEAM_ASC',
  CAN_EDIT_TARGET_TEAM_DESC = 'CAN_EDIT_TARGET_TEAM_DESC',
  CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_ASC = 'CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_ASC',
  CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_DESC = 'CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_DESC',
  CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_ASC = 'CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_ASC',
  CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_DESC = 'CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_DESC',
  CAN_SEE_PROBATION_REVIEW_OF_ALL_ASC = 'CAN_SEE_PROBATION_REVIEW_OF_ALL_ASC',
  CAN_SEE_PROBATION_REVIEW_OF_ALL_DESC = 'CAN_SEE_PROBATION_REVIEW_OF_ALL_DESC',
  CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_ASC = 'CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_ASC',
  CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_DESC = 'CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DISPLAY_NAME_ASC = 'DISPLAY_NAME_ASC',
  DISPLAY_NAME_DESC = 'DISPLAY_NAME_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__COUNT_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__COUNT_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__COUNT_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__COUNT_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_CITY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_CITY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_CITY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_CITY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_COMPANY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_COMPANY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_COMPANY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_COMPANY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_DEPARTMENT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_DEPARTMENT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_DEPARTMENT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_DEPARTMENT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_DIVISION_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_DIVISION_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_DIVISION_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_DIVISION_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_EFFECTIVE_DATE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_EFFECTIVE_DATE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_EFFECTIVE_DATE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_EFFECTIVE_DATE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_CODE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_CODE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_CODE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_CODE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_JOB_TITLE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_JOB_TITLE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_JOB_TITLE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_JOB_TITLE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_OFFICE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_OFFICE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_OFFICE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_OFFICE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_TEAM_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_TEAM_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_TEAM_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MAX_TEAM_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_CITY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_CITY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_CITY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_CITY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_COMPANY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_COMPANY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_COMPANY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_COMPANY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_DEPARTMENT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_DEPARTMENT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_DEPARTMENT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_DEPARTMENT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_DIVISION_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_DIVISION_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_DIVISION_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_DIVISION_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_EFFECTIVE_DATE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_EFFECTIVE_DATE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_EFFECTIVE_DATE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_EFFECTIVE_DATE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_CODE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_CODE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_CODE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_CODE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_JOB_TITLE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_JOB_TITLE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_JOB_TITLE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_JOB_TITLE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_OFFICE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_OFFICE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_OFFICE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_OFFICE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_TEAM_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_TEAM_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_TEAM_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_LEVEL_ID__MIN_TEAM_DESC',
  EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_LEVEL_ORDER_ASC = 'EMPLOYEE_LEVEL_ORDER_ASC',
  EMPLOYEE_LEVEL_ORDER_DESC = 'EMPLOYEE_LEVEL_ORDER_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__COUNT_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__COUNT_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__COUNT_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__COUNT_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_COMPANY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_COMPANY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_COMPANY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_COMPANY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_DATE_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_DATE_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_DATE_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_DATE_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_DEPARTMENT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_DEPARTMENT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_DEPARTMENT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_DEPARTMENT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_DIVISION_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_DIVISION_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_DIVISION_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_DIVISION_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_EFFECTIVE_DATE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_EFFECTIVE_DATE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_EFFECTIVE_DATE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_EFFECTIVE_DATE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_JOB_TITLE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_JOB_TITLE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_JOB_TITLE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_JOB_TITLE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_OFFICE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_OFFICE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_OFFICE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_OFFICE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_REMARK_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_REMARK_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_REMARK_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_REMARK_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_TEAM_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_TEAM_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_TEAM_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_USER_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_USER_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_USER_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_USER_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_USER_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_USER_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_USER_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MAX_USER_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_COMPANY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_COMPANY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_COMPANY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_COMPANY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_DATE_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_DATE_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_DATE_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_DATE_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_DEPARTMENT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_DEPARTMENT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_DEPARTMENT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_DEPARTMENT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_DIVISION_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_DIVISION_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_DIVISION_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_DIVISION_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_EFFECTIVE_DATE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_EFFECTIVE_DATE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_EFFECTIVE_DATE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_EFFECTIVE_DATE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_JOB_TITLE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_JOB_TITLE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_JOB_TITLE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_JOB_TITLE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_OFFICE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_OFFICE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_OFFICE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_OFFICE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_REMARK_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_REMARK_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_REMARK_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_REMARK_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_TEAM_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_TEAM_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_TEAM_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_USER_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_USER_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_USER_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_USER_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_USER_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_USER_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_USER_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_LEVEL_ID__MIN_USER_UPDATED_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_LEVEL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_LEVEL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_LEVEL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_EMPLOYEE_LEVEL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_PERCENTAGE_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_PERCENTAGE_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_PERCENTAGE_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_PERCENTAGE_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_PERCENTAGE_MULTI_SOURCE_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_PERCENTAGE_MULTI_SOURCE_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_PERCENTAGE_MULTI_SOURCE_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_PERCENTAGE_MULTI_SOURCE_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_PERCENTAGE_TARGET_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_PERCENTAGE_TARGET_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_PERCENTAGE_TARGET_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_PERCENTAGE_TARGET_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_PERCENTAGE_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_PERCENTAGE_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_PERCENTAGE_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_PERCENTAGE_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_LEVEL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_LEVEL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_LEVEL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_EMPLOYEE_LEVEL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_PERCENTAGE_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_PERCENTAGE_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_PERCENTAGE_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_PERCENTAGE_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_PERCENTAGE_MULTI_SOURCE_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_PERCENTAGE_MULTI_SOURCE_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_PERCENTAGE_MULTI_SOURCE_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_PERCENTAGE_MULTI_SOURCE_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_PERCENTAGE_TARGET_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_PERCENTAGE_TARGET_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_PERCENTAGE_TARGET_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_PERCENTAGE_TARGET_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_PERCENTAGE_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_PERCENTAGE_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_PERCENTAGE_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_PERCENTAGE_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_EMPLOYEE_LEVEL_ID__MIN_USER_UPDATED_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  TITLE_CONTRIBUTOR_ASC = 'TITLE_CONTRIBUTOR_ASC',
  TITLE_CONTRIBUTOR_DESC = 'TITLE_CONTRIBUTOR_DESC',
  TITLE_MANAGER_ASC = 'TITLE_MANAGER_ASC',
  TITLE_MANAGER_DESC = 'TITLE_MANAGER_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface EmployeeListAtPointConnection {
  __typename?: 'EmployeeListAtPointConnection';
  edges: Array<EmployeeListAtPointEdge>;
  nodes: Array<EmployeeListAtPointRecord>;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeListAtPointEdge {
  __typename?: 'EmployeeListAtPointEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeListAtPointRecord;
}

export interface EmployeeListAtPointRecord {
  __typename?: 'EmployeeListAtPointRecord';
  businessUnitId?: Maybe<Scalars['BigInt']['output']>;
  businessUnitName?: Maybe<Scalars['String']['output']>;
  companyEmail?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['BigInt']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  contractTypeId?: Maybe<Scalars['BigInt']['output']>;
  contractTypeName?: Maybe<Scalars['String']['output']>;
  departmentId?: Maybe<Scalars['BigInt']['output']>;
  departmentName?: Maybe<Scalars['String']['output']>;
  divisionId?: Maybe<Scalars['BigInt']['output']>;
  divisionName?: Maybe<Scalars['String']['output']>;
  employeeCode?: Maybe<Scalars['String']['output']>;
  employeeId?: Maybe<Scalars['BigInt']['output']>;
  employeeLevelId?: Maybe<Scalars['BigInt']['output']>;
  employeeLevelName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  joinDate?: Maybe<Scalars['Date']['output']>;
  officeId?: Maybe<Scalars['BigInt']['output']>;
  officeName?: Maybe<Scalars['String']['output']>;
  primaryManager?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  workingLocationCountryCodeAlpha2?: Maybe<Scalars['String']['output']>;
}

export interface EmployeeListAtPointRecordFilter {
  and?: InputMaybe<Array<EmployeeListAtPointRecordFilter>>;
  businessUnitId?: InputMaybe<BigIntFilter>;
  businessUnitName?: InputMaybe<StringFilter>;
  companyEmail?: InputMaybe<StringFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  companyName?: InputMaybe<StringFilter>;
  contractTypeId?: InputMaybe<BigIntFilter>;
  contractTypeName?: InputMaybe<StringFilter>;
  departmentId?: InputMaybe<BigIntFilter>;
  departmentName?: InputMaybe<StringFilter>;
  divisionId?: InputMaybe<BigIntFilter>;
  divisionName?: InputMaybe<StringFilter>;
  employeeCode?: InputMaybe<StringFilter>;
  employeeId?: InputMaybe<BigIntFilter>;
  employeeLevelId?: InputMaybe<BigIntFilter>;
  employeeLevelName?: InputMaybe<StringFilter>;
  fullName?: InputMaybe<StringFilter>;
  jobTitle?: InputMaybe<StringFilter>;
  joinDate?: InputMaybe<DateFilter>;
  not?: InputMaybe<EmployeeListAtPointRecordFilter>;
  officeId?: InputMaybe<BigIntFilter>;
  officeName?: InputMaybe<StringFilter>;
  or?: InputMaybe<Array<EmployeeListAtPointRecordFilter>>;
  primaryManager?: InputMaybe<StringFilter>;
  team?: InputMaybe<StringFilter>;
  workingLocationCountryCodeAlpha2?: InputMaybe<StringFilter>;
}

export interface EmployeeListCustomFilter extends Node {
  __typename?: 'EmployeeListCustomFilter';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  employeeListCustomFilterId: Scalars['BigInt']['output'];
  employeeListCustomFilterRoles: EmployeeListCustomFilterRolesConnection;
  filter: Scalars['JSON']['output'];
  isPrivate: Scalars['Boolean']['output'];
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  sort: Scalars['JSON']['output'];
  title: Scalars['String']['output'];
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export type EmployeeListCustomFilterEmployeeListCustomFilterRolesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeListCustomFilterRoleCondition>;
  filter?: InputMaybe<EmployeeListCustomFilterRoleFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeListCustomFilterRolesOrderBy>>;
};

export interface EmployeeListCustomFilterCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  employeeListCustomFilterId?: InputMaybe<Scalars['BigInt']['input']>;
  filter?: InputMaybe<Scalars['JSON']['input']>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeListCustomFilterEmployeeListCustomFilterPkeyConnect {
  employeeListCustomFilterId: Scalars['BigInt']['input'];
}

export interface EmployeeListCustomFilterEmployeeListCustomFilterPkeyDelete {
  employeeListCustomFilterId: Scalars['BigInt']['input'];
}

export interface EmployeeListCustomFilterFilter {
  and?: InputMaybe<Array<EmployeeListCustomFilterFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  employeeListCustomFilterId?: InputMaybe<BigIntFilter>;
  employeeListCustomFilterRoles?: InputMaybe<EmployeeListCustomFilterToManyEmployeeListCustomFilterRoleFilter>;
  employeeListCustomFilterRolesExist?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<JsonFilter>;
  isPrivate?: InputMaybe<BooleanFilter>;
  not?: InputMaybe<EmployeeListCustomFilterFilter>;
  or?: InputMaybe<Array<EmployeeListCustomFilterFilter>>;
  remark?: InputMaybe<StringFilter>;
  sort?: InputMaybe<JsonFilter>;
  title?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface EmployeeListCustomFilterInput {
  employeeListCustomFilterId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeListCustomFilterRoles?: InputMaybe<EmployeeListCustomFilterREmployeeListCustomFilterFkeyInverseInput>;
  filter: Scalars['JSON']['input'];
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  sort: Scalars['JSON']['input'];
  title: Scalars['String']['input'];
  userAccount?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeListCustomFilterNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeListCustomFilterNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeListCustomFilterOnEmployeeListCustomFilterForEmployeeListCustomFilterUserCreatedFkeyUsingEmployeeListCustomFilterPkeyUpdate {
  employeeListCustomFilterId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeListCustomFilterOnEmployeeListCustomFilterForEmployeeListCustomFilterUserCreatedFkeyPatch;
}

export interface EmployeeListCustomFilterOnEmployeeListCustomFilterForEmployeeListCustomFilterUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface EmployeeListCustomFilterOnEmployeeListCustomFilterForEmployeeListCustomFilterUserUpdatedFkeyUsingEmployeeListCustomFilterPkeyUpdate {
  employeeListCustomFilterId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeListCustomFilterOnEmployeeListCustomFilterForEmployeeListCustomFilterUserUpdatedFkeyPatch;
}

export interface EmployeeListCustomFilterOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterREmployeeListCustomFilterFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeListCustomFilterRolePatch;
}

export interface EmployeeListCustomFilterOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterREmployeeListCustomFilterFkeyUsingEmployeeListCustomFilterPkeyUpdate {
  employeeListCustomFilterId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeListCustomFilterOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterREmployeeListCustomFilterFkeyPatch;
}

export interface EmployeeListCustomFilterPatch {
  employeeListCustomFilterId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeListCustomFilterRoles?: InputMaybe<EmployeeListCustomFilterREmployeeListCustomFilterFkeyInverseInput>;
  filter?: InputMaybe<Scalars['JSON']['input']>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeListCustomFilterREmployeeListCustomFilterFkeyEmployeeListCustomFilterCreateInput {
  employeeListCustomFilterRoles?: InputMaybe<EmployeeListCustomFilterREmployeeListCustomFilterFkeyInverseInput>;
  filter: Scalars['JSON']['input'];
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  sort: Scalars['JSON']['input'];
  title: Scalars['String']['input'];
  userAccount?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeListCustomFilterREmployeeListCustomFilterFkeyEmployeeListCustomFilterRoleCreateInput {
  employeeListCustomFilter?: InputMaybe<EmployeeListCustomFilterREmployeeListCustomFilterFkeyInput>;
  employeeListCustomFilterRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  roleName?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userRole?: InputMaybe<EmployeeListCustomFilterRoleRoleNameFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeListCustomFilterREmployeeListCustomFilterFkeyInput {
  connectByEmployeeListCustomFilterId?: InputMaybe<EmployeeListCustomFilterEmployeeListCustomFilterPkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeListCustomFilterNodeIdConnect>;
  create?: InputMaybe<EmployeeListCustomFilterREmployeeListCustomFilterFkeyEmployeeListCustomFilterCreateInput>;
  deleteByEmployeeListCustomFilterId?: InputMaybe<EmployeeListCustomFilterEmployeeListCustomFilterPkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeListCustomFilterNodeIdDelete>;
  updateByEmployeeListCustomFilterId?: InputMaybe<EmployeeListCustomFilterOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterREmployeeListCustomFilterFkeyUsingEmployeeListCustomFilterPkeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterREmployeeListCustomFilterFkeyNodeIdUpdate>;
}

export interface EmployeeListCustomFilterREmployeeListCustomFilterFkeyInverseInput {
  connectByEmployeeListCustomFilterIdAndRoleName?: InputMaybe<Array<EmployeeListCustomFilterRoleEmployeeListCustomFilterREmployeeListCustomFilterIKeyConnect>>;
  connectByEmployeeListCustomFilterRoleId?: InputMaybe<Array<EmployeeListCustomFilterRoleEmployeeListCustomFilterRolePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeListCustomFilterRoleNodeIdConnect>>;
  create?: InputMaybe<Array<EmployeeListCustomFilterREmployeeListCustomFilterFkeyEmployeeListCustomFilterRoleCreateInput>>;
  deleteByEmployeeListCustomFilterIdAndRoleName?: InputMaybe<Array<EmployeeListCustomFilterRoleEmployeeListCustomFilterREmployeeListCustomFilterIKeyDelete>>;
  deleteByEmployeeListCustomFilterRoleId?: InputMaybe<Array<EmployeeListCustomFilterRoleEmployeeListCustomFilterRolePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeListCustomFilterRoleNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeListCustomFilterIdAndRoleName?: InputMaybe<
    Array<EmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterREmployeeListCustomFilterFkeyUsingEmployeeListCustomFilterREmployeeListCustomFilterIKeyUpdate>
  >;
  updateByEmployeeListCustomFilterRoleId?: InputMaybe<
    Array<EmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterREmployeeListCustomFilterFkeyUsingEmployeeListCustomFilterRolePkeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<EmployeeListCustomFilterOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterREmployeeListCustomFilterFkeyNodeIdUpdate>>;
}

export interface EmployeeListCustomFilterRole extends Node {
  __typename?: 'EmployeeListCustomFilterRole';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  employeeListCustomFilter: EmployeeListCustomFilter;
  employeeListCustomFilterId: Scalars['BigInt']['output'];
  employeeListCustomFilterRoleId: Scalars['BigInt']['output'];
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  roleName: Scalars['String']['output'];
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userRoleByRoleName: UserRole;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface EmployeeListCustomFilterRoleCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  employeeListCustomFilterId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeListCustomFilterRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  roleName?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeListCustomFilterRoleEmployeeListCustomFilterREmployeeListCustomFilterIKeyConnect {
  employeeListCustomFilterId: Scalars['BigInt']['input'];
  roleName: Scalars['String']['input'];
}

export interface EmployeeListCustomFilterRoleEmployeeListCustomFilterREmployeeListCustomFilterIKeyDelete {
  employeeListCustomFilterId: Scalars['BigInt']['input'];
  roleName: Scalars['String']['input'];
}

export interface EmployeeListCustomFilterRoleEmployeeListCustomFilterRolePkeyConnect {
  employeeListCustomFilterRoleId: Scalars['BigInt']['input'];
}

export interface EmployeeListCustomFilterRoleEmployeeListCustomFilterRolePkeyDelete {
  employeeListCustomFilterRoleId: Scalars['BigInt']['input'];
}

export interface EmployeeListCustomFilterRoleFilter {
  and?: InputMaybe<Array<EmployeeListCustomFilterRoleFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  employeeListCustomFilter?: InputMaybe<EmployeeListCustomFilterFilter>;
  employeeListCustomFilterId?: InputMaybe<BigIntFilter>;
  employeeListCustomFilterRoleId?: InputMaybe<BigIntFilter>;
  not?: InputMaybe<EmployeeListCustomFilterRoleFilter>;
  or?: InputMaybe<Array<EmployeeListCustomFilterRoleFilter>>;
  remark?: InputMaybe<StringFilter>;
  roleName?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userRoleByRoleName?: InputMaybe<UserRoleFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface EmployeeListCustomFilterRoleInput {
  employeeListCustomFilter?: InputMaybe<EmployeeListCustomFilterREmployeeListCustomFilterFkeyInput>;
  employeeListCustomFilterId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeListCustomFilterRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  roleName?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userRole?: InputMaybe<EmployeeListCustomFilterRoleRoleNameFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeListCustomFilterRoleNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeListCustomFilterRoleNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterREmployeeListCustomFilterFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeListCustomFilterPatch;
}

export interface EmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterREmployeeListCustomFilterFkeyUsingEmployeeListCustomFilterREmployeeListCustomFilterIKeyUpdate {
  employeeListCustomFilterId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterREmployeeListCustomFilterFkeyPatch;
  roleName: Scalars['String']['input'];
}

export interface EmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterREmployeeListCustomFilterFkeyUsingEmployeeListCustomFilterRolePkeyUpdate {
  employeeListCustomFilterRoleId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterREmployeeListCustomFilterFkeyPatch;
}

export interface EmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleRoleNameFkeyUsingEmployeeListCustomFilterREmployeeListCustomFilterIKeyUpdate {
  employeeListCustomFilterId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleRoleNameFkeyPatch;
  roleName: Scalars['String']['input'];
}

export interface EmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleRoleNameFkeyUsingEmployeeListCustomFilterRolePkeyUpdate {
  employeeListCustomFilterRoleId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleRoleNameFkeyPatch;
}

export interface EmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserCreatedFkeyUsingEmployeeListCustomFilterREmployeeListCustomFilterIKeyUpdate {
  employeeListCustomFilterId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserCreatedFkeyPatch;
  roleName: Scalars['String']['input'];
}

export interface EmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserCreatedFkeyUsingEmployeeListCustomFilterRolePkeyUpdate {
  employeeListCustomFilterRoleId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserCreatedFkeyPatch;
}

export interface EmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface EmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserUpdatedFkeyUsingEmployeeListCustomFilterREmployeeListCustomFilterIKeyUpdate {
  employeeListCustomFilterId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserUpdatedFkeyPatch;
  roleName: Scalars['String']['input'];
}

export interface EmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserUpdatedFkeyUsingEmployeeListCustomFilterRolePkeyUpdate {
  employeeListCustomFilterRoleId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserUpdatedFkeyPatch;
}

export interface EmployeeListCustomFilterRolePatch {
  employeeListCustomFilter?: InputMaybe<EmployeeListCustomFilterREmployeeListCustomFilterFkeyInput>;
  employeeListCustomFilterId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeListCustomFilterRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  roleName?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userRole?: InputMaybe<EmployeeListCustomFilterRoleRoleNameFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeListCustomFilterRoleRoleNameFkeyEmployeeListCustomFilterRoleCreateInput {
  employeeListCustomFilter?: InputMaybe<EmployeeListCustomFilterREmployeeListCustomFilterFkeyInput>;
  employeeListCustomFilterId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeListCustomFilterRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userRole?: InputMaybe<EmployeeListCustomFilterRoleRoleNameFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeListCustomFilterRoleRoleNameFkeyInput {
  connectByRoleName?: InputMaybe<UserRoleUserRoleRoleNameKeyConnect>;
  updateByRoleName?: InputMaybe<UserRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleRoleNameFkeyUsingUserRoleRoleNameKeyUpdate>;
}

export interface EmployeeListCustomFilterRoleRoleNameFkeyInverseInput {
  connectByEmployeeListCustomFilterIdAndRoleName?: InputMaybe<Array<EmployeeListCustomFilterRoleEmployeeListCustomFilterREmployeeListCustomFilterIKeyConnect>>;
  connectByEmployeeListCustomFilterRoleId?: InputMaybe<Array<EmployeeListCustomFilterRoleEmployeeListCustomFilterRolePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeListCustomFilterRoleNodeIdConnect>>;
  create?: InputMaybe<Array<EmployeeListCustomFilterRoleRoleNameFkeyEmployeeListCustomFilterRoleCreateInput>>;
  deleteByEmployeeListCustomFilterIdAndRoleName?: InputMaybe<Array<EmployeeListCustomFilterRoleEmployeeListCustomFilterREmployeeListCustomFilterIKeyDelete>>;
  deleteByEmployeeListCustomFilterRoleId?: InputMaybe<Array<EmployeeListCustomFilterRoleEmployeeListCustomFilterRolePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeListCustomFilterRoleNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeListCustomFilterIdAndRoleName?: InputMaybe<
    Array<EmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleRoleNameFkeyUsingEmployeeListCustomFilterREmployeeListCustomFilterIKeyUpdate>
  >;
  updateByEmployeeListCustomFilterRoleId?: InputMaybe<
    Array<EmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleRoleNameFkeyUsingEmployeeListCustomFilterRolePkeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<UserRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleRoleNameFkeyNodeIdUpdate>>;
}

export interface EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput {
  connectByEmployeeListCustomFilterIdAndRoleName?: InputMaybe<Array<EmployeeListCustomFilterRoleEmployeeListCustomFilterREmployeeListCustomFilterIKeyConnect>>;
  connectByEmployeeListCustomFilterRoleId?: InputMaybe<Array<EmployeeListCustomFilterRoleEmployeeListCustomFilterRolePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeListCustomFilterRoleNodeIdConnect>>;
  deleteByEmployeeListCustomFilterIdAndRoleName?: InputMaybe<Array<EmployeeListCustomFilterRoleEmployeeListCustomFilterREmployeeListCustomFilterIKeyDelete>>;
  deleteByEmployeeListCustomFilterRoleId?: InputMaybe<Array<EmployeeListCustomFilterRoleEmployeeListCustomFilterRolePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeListCustomFilterRoleNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeListCustomFilterIdAndRoleName?: InputMaybe<
    Array<EmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserCreatedFkeyUsingEmployeeListCustomFilterREmployeeListCustomFilterIKeyUpdate>
  >;
  updateByEmployeeListCustomFilterRoleId?: InputMaybe<
    Array<EmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserCreatedFkeyUsingEmployeeListCustomFilterRolePkeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserCreatedFkeyNodeIdUpdate>>;
}

export interface EmployeeListCustomFilterRoleUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput {
  connectByEmployeeListCustomFilterIdAndRoleName?: InputMaybe<Array<EmployeeListCustomFilterRoleEmployeeListCustomFilterREmployeeListCustomFilterIKeyConnect>>;
  connectByEmployeeListCustomFilterRoleId?: InputMaybe<Array<EmployeeListCustomFilterRoleEmployeeListCustomFilterRolePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeListCustomFilterRoleNodeIdConnect>>;
  deleteByEmployeeListCustomFilterIdAndRoleName?: InputMaybe<Array<EmployeeListCustomFilterRoleEmployeeListCustomFilterREmployeeListCustomFilterIKeyDelete>>;
  deleteByEmployeeListCustomFilterRoleId?: InputMaybe<Array<EmployeeListCustomFilterRoleEmployeeListCustomFilterRolePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeListCustomFilterRoleNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeListCustomFilterIdAndRoleName?: InputMaybe<
    Array<EmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserUpdatedFkeyUsingEmployeeListCustomFilterREmployeeListCustomFilterIKeyUpdate>
  >;
  updateByEmployeeListCustomFilterRoleId?: InputMaybe<
    Array<EmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserUpdatedFkeyUsingEmployeeListCustomFilterRolePkeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserUpdatedFkeyNodeIdUpdate>>;
}

export interface EmployeeListCustomFilterRolesConnection {
  __typename?: 'EmployeeListCustomFilterRolesConnection';
  edges: Array<EmployeeListCustomFilterRolesEdge>;
  nodes: Array<EmployeeListCustomFilterRole>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeListCustomFilterRolesEdge {
  __typename?: 'EmployeeListCustomFilterRolesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeListCustomFilterRole;
}

export enum EmployeeListCustomFilterRolesOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__DATE_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__DATE_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__DATE_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__DATE_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__DATE_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__DATE_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__DATE_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__DATE_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__FILTER_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__FILTER_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__FILTER_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__FILTER_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__IS_PRIVATE_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__IS_PRIVATE_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__IS_PRIVATE_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__IS_PRIVATE_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__REMARK_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__REMARK_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__REMARK_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__REMARK_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__SORT_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__SORT_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__SORT_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__SORT_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__TITLE_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__TITLE_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__TITLE_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__TITLE_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__USER_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__USER_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__USER_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__USER_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__USER_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__USER_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__USER_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__USER_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  ROLE_NAME_ASC = 'ROLE_NAME_ASC',
  ROLE_NAME_DESC = 'ROLE_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_ROLE_BY_ROLE_NAME__DISPLAY_NAME_ASC = 'USER_ROLE_BY_ROLE_NAME__DISPLAY_NAME_ASC',
  USER_ROLE_BY_ROLE_NAME__DISPLAY_NAME_DESC = 'USER_ROLE_BY_ROLE_NAME__DISPLAY_NAME_DESC',
  USER_ROLE_BY_ROLE_NAME__IS_DEFAULT_ROLE_ASC = 'USER_ROLE_BY_ROLE_NAME__IS_DEFAULT_ROLE_ASC',
  USER_ROLE_BY_ROLE_NAME__IS_DEFAULT_ROLE_DESC = 'USER_ROLE_BY_ROLE_NAME__IS_DEFAULT_ROLE_DESC',
  USER_ROLE_BY_ROLE_NAME__PRIORITY_ASC = 'USER_ROLE_BY_ROLE_NAME__PRIORITY_ASC',
  USER_ROLE_BY_ROLE_NAME__PRIORITY_DESC = 'USER_ROLE_BY_ROLE_NAME__PRIORITY_DESC',
  USER_ROLE_BY_ROLE_NAME__ROLE_NAME_ASC = 'USER_ROLE_BY_ROLE_NAME__ROLE_NAME_ASC',
  USER_ROLE_BY_ROLE_NAME__ROLE_NAME_DESC = 'USER_ROLE_BY_ROLE_NAME__ROLE_NAME_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface EmployeeListCustomFilterToManyEmployeeListCustomFilterRoleFilter {
  every?: InputMaybe<EmployeeListCustomFilterRoleFilter>;
  none?: InputMaybe<EmployeeListCustomFilterRoleFilter>;
  some?: InputMaybe<EmployeeListCustomFilterRoleFilter>;
}

export interface EmployeeListCustomFilterUserCreatedFkeyInverseInput {
  connectByEmployeeListCustomFilterId?: InputMaybe<Array<EmployeeListCustomFilterEmployeeListCustomFilterPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeListCustomFilterNodeIdConnect>>;
  deleteByEmployeeListCustomFilterId?: InputMaybe<Array<EmployeeListCustomFilterEmployeeListCustomFilterPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeListCustomFilterNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeListCustomFilterId?: InputMaybe<Array<EmployeeListCustomFilterOnEmployeeListCustomFilterForEmployeeListCustomFilterUserCreatedFkeyUsingEmployeeListCustomFilterPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmployeeListCustomFilterForEmployeeListCustomFilterUserCreatedFkeyNodeIdUpdate>>;
}

export interface EmployeeListCustomFilterUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnEmployeeListCustomFilterForEmployeeListCustomFilterUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeListCustomFilterOnEmployeeListCustomFilterForEmployeeListCustomFilterUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnEmployeeListCustomFilterForEmployeeListCustomFilterUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface EmployeeListCustomFilterUserUpdatedFkeyInverseInput {
  connectByEmployeeListCustomFilterId?: InputMaybe<Array<EmployeeListCustomFilterEmployeeListCustomFilterPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeListCustomFilterNodeIdConnect>>;
  deleteByEmployeeListCustomFilterId?: InputMaybe<Array<EmployeeListCustomFilterEmployeeListCustomFilterPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeListCustomFilterNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeListCustomFilterId?: InputMaybe<Array<EmployeeListCustomFilterOnEmployeeListCustomFilterForEmployeeListCustomFilterUserUpdatedFkeyUsingEmployeeListCustomFilterPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmployeeListCustomFilterForEmployeeListCustomFilterUserUpdatedFkeyNodeIdUpdate>>;
}

export interface EmployeeListCustomFiltersConnection {
  __typename?: 'EmployeeListCustomFiltersConnection';
  edges: Array<EmployeeListCustomFiltersEdge>;
  nodes: Array<EmployeeListCustomFilter>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeListCustomFiltersEdge {
  __typename?: 'EmployeeListCustomFiltersEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeListCustomFilter;
}

export enum EmployeeListCustomFiltersOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__COUNT_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__COUNT_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__COUNT_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__COUNT_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_DATE_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_DATE_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_DATE_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_DATE_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_REMARK_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_REMARK_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_REMARK_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_REMARK_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_ROLE_NAME_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_ROLE_NAME_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_ROLE_NAME_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_ROLE_NAME_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_USER_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_USER_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_USER_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_USER_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_USER_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_USER_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_USER_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MAX_USER_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_DATE_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_DATE_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_DATE_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_DATE_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_REMARK_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_REMARK_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_REMARK_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_REMARK_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_ROLE_NAME_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_ROLE_NAME_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_ROLE_NAME_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_ROLE_NAME_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_USER_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_USER_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_USER_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_USER_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_USER_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_USER_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_USER_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_EMPLOYEE_LIST_CUSTOM_FILTER_ID__MIN_USER_UPDATED_DESC',
  FILTER_ASC = 'FILTER_ASC',
  FILTER_DESC = 'FILTER_DESC',
  IS_PRIVATE_ASC = 'IS_PRIVATE_ASC',
  IS_PRIVATE_DESC = 'IS_PRIVATE_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  SORT_ASC = 'SORT_ASC',
  SORT_DESC = 'SORT_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export enum EmployeeManagerType {
  APPROVER = 'APPROVER',
  DOTTED_LINE_MANAGER = 'DOTTED_LINE_MANAGER',
  PRIMARY_MANAGER = 'PRIMARY_MANAGER',
  SELF = 'SELF',
}

export interface EmployeeManagerTypeFilter {
  distinctFrom?: InputMaybe<EmployeeManagerType>;
  equalTo?: InputMaybe<EmployeeManagerType>;
  greaterThan?: InputMaybe<EmployeeManagerType>;
  greaterThanOrEqualTo?: InputMaybe<EmployeeManagerType>;
  in?: InputMaybe<Array<EmployeeManagerType>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<EmployeeManagerType>;
  lessThanOrEqualTo?: InputMaybe<EmployeeManagerType>;
  notDistinctFrom?: InputMaybe<EmployeeManagerType>;
  notEqualTo?: InputMaybe<EmployeeManagerType>;
  notIn?: InputMaybe<Array<EmployeeManagerType>>;
}

export enum EmployeeMaritalStatus {
  MARRIED = 'MARRIED',
  SINGLE = 'SINGLE',
}

export interface EmployeeMaritalStatusFilter {
  distinctFrom?: InputMaybe<EmployeeMaritalStatus>;
  equalTo?: InputMaybe<EmployeeMaritalStatus>;
  greaterThan?: InputMaybe<EmployeeMaritalStatus>;
  greaterThanOrEqualTo?: InputMaybe<EmployeeMaritalStatus>;
  in?: InputMaybe<Array<EmployeeMaritalStatus>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<EmployeeMaritalStatus>;
  lessThanOrEqualTo?: InputMaybe<EmployeeMaritalStatus>;
  notDistinctFrom?: InputMaybe<EmployeeMaritalStatus>;
  notEqualTo?: InputMaybe<EmployeeMaritalStatus>;
  notIn?: InputMaybe<Array<EmployeeMaritalStatus>>;
}

export interface EmployeeMobileNumberCountryIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeMobileNumberCountryIdFkeyInverseInput {
  connectByCompanyEmail?: InputMaybe<Array<EmployeeEmployeeCompanyEmailKeyConnect>>;
  connectByEmployeeId?: InputMaybe<Array<EmployeeEmployeePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeNodeIdConnect>>;
  create?: InputMaybe<Array<EmployeeMobileNumberCountryIdFkeyEmployeeCreateInput>>;
  deleteByCompanyEmail?: InputMaybe<Array<EmployeeEmployeeCompanyEmailKeyDelete>>;
  deleteByEmployeeId?: InputMaybe<Array<EmployeeEmployeePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyEmail?: InputMaybe<Array<EmployeeOnEmployeeForEmployeeMobileNumberCountryIdFkeyUsingEmployeeCompanyEmailKeyUpdate>>;
  updateByEmployeeId?: InputMaybe<Array<EmployeeOnEmployeeForEmployeeMobileNumberCountryIdFkeyUsingEmployeePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<CountryOnEmployeeForEmployeeMobileNumberCountryIdFkeyNodeIdUpdate>>;
}

export interface EmployeeNationalityFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeNationalityFkeyInverseInput {
  connectByCompanyEmail?: InputMaybe<Array<EmployeeEmployeeCompanyEmailKeyConnect>>;
  connectByEmployeeId?: InputMaybe<Array<EmployeeEmployeePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeNodeIdConnect>>;
  create?: InputMaybe<Array<EmployeeNationalityFkeyEmployeeCreateInput>>;
  deleteByCompanyEmail?: InputMaybe<Array<EmployeeEmployeeCompanyEmailKeyDelete>>;
  deleteByEmployeeId?: InputMaybe<Array<EmployeeEmployeePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyEmail?: InputMaybe<Array<EmployeeOnEmployeeForEmployeeNationalityFkeyUsingEmployeeCompanyEmailKeyUpdate>>;
  updateByEmployeeId?: InputMaybe<Array<EmployeeOnEmployeeForEmployeeNationalityFkeyUsingEmployeePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<CountryOnEmployeeForEmployeeNationalityFkeyNodeIdUpdate>>;
}

export interface EmployeeNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeOnCompanyGlobalHrForCompanyGlobalHrEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyGlobalHrPatch;
}

export interface EmployeeOnCompanyGlobalHrForCompanyGlobalHrEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnCompanyGlobalHrForCompanyGlobalHrEmployeeIdFkeyPatch;
}

export interface EmployeeOnCompanyGlobalHrForCompanyGlobalHrEmployeeIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnCompanyGlobalHrForCompanyGlobalHrEmployeeIdFkeyPatch;
}

export interface EmployeeOnCompanyLocalHrForCompanyLocalHrEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyLocalHrPatch;
}

export interface EmployeeOnCompanyLocalHrForCompanyLocalHrEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnCompanyLocalHrForCompanyLocalHrEmployeeIdFkeyPatch;
}

export interface EmployeeOnCompanyLocalHrForCompanyLocalHrEmployeeIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnCompanyLocalHrForCompanyLocalHrEmployeeIdFkeyPatch;
}

export interface EmployeeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey0NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeCurrentPositionPatch;
}

export interface EmployeeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey0UsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey0Patch;
}

export interface EmployeeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey0UsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey0Patch;
}

export interface EmployeeOnEmployeeDaysByContractTypeForFakePublicEmployeeDaysByContractTypeForeignKey0NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeDaysByContractTypePatch;
}

export interface EmployeeOnEmployeeDaysByContractTypeForFakePublicEmployeeDaysByContractTypeForeignKey0UsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnEmployeeDaysByContractTypeForFakePublicEmployeeDaysByContractTypeForeignKey0Patch;
}

export interface EmployeeOnEmployeeDaysByContractTypeForFakePublicEmployeeDaysByContractTypeForeignKey0UsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnEmployeeDaysByContractTypeForFakePublicEmployeeDaysByContractTypeForeignKey0Patch;
}

export interface EmployeeOnEmployeeEmergencyContactForEmployeeEmergencyContactEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeEmergencyContactPatch;
}

export interface EmployeeOnEmployeeEmergencyContactForEmployeeEmergencyContactEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnEmployeeEmergencyContactForEmployeeEmergencyContactEmployeeIdFkeyPatch;
}

export interface EmployeeOnEmployeeEmergencyContactForEmployeeEmergencyContactEmployeeIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnEmployeeEmergencyContactForEmployeeEmergencyContactEmployeeIdFkeyPatch;
}

export interface EmployeeOnEmployeeFileForEmployeeFileEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeFilePatch;
}

export interface EmployeeOnEmployeeFileForEmployeeFileEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnEmployeeFileForEmployeeFileEmployeeIdFkeyPatch;
}

export interface EmployeeOnEmployeeFileForEmployeeFileEmployeeIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnEmployeeFileForEmployeeFileEmployeeIdFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeApproverIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface EmployeeOnEmployeeForEmployeeApproverIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeApproverIdFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeApproverIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeApproverIdFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeBankIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeBankIdFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeBankIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeBankIdFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeConsultingFeeBankIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: BankPatch;
}

export interface EmployeeOnEmployeeForEmployeeConsultingFeeBankIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeConsultingFeeBankIdFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeConsultingFeeBankIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeConsultingFeeBankIdFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeConsultingFeeCurrencyIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CurrencyPatch;
}

export interface EmployeeOnEmployeeForEmployeeConsultingFeeCurrencyIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeConsultingFeeCurrencyIdFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeConsultingFeeCurrencyIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeConsultingFeeCurrencyIdFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeDottedLineManagerIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface EmployeeOnEmployeeForEmployeeDottedLineManagerIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeDottedLineManagerIdFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeDottedLineManagerIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeDottedLineManagerIdFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeIdTypeFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeIdTypePatch;
}

export interface EmployeeOnEmployeeForEmployeeIdTypeFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeIdTypeFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeIdTypeFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeIdTypeFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeMobileNumberCountryIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeMobileNumberCountryIdFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeMobileNumberCountryIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeMobileNumberCountryIdFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeNationalityFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeNationalityFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeNationalityFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeNationalityFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeePrimaryManagerIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface EmployeeOnEmployeeForEmployeePrimaryManagerIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeePrimaryManagerIdFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeePrimaryManagerIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeePrimaryManagerIdFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeResignationReasonIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeResignationReasonPatch;
}

export interface EmployeeOnEmployeeForEmployeeResignationReasonIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeResignationReasonIdFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeResignationReasonIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeResignationReasonIdFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeResignationTypeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeResignationTypePatch;
}

export interface EmployeeOnEmployeeForEmployeeResignationTypeIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeResignationTypeIdFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeResignationTypeIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeResignationTypeIdFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeStatusFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeStatusPatch;
}

export interface EmployeeOnEmployeeForEmployeeStatusFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeStatusFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeStatusFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeStatusFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeUserCreatedFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeUserCreatedFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeUserCreatedFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeUserCreatedFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface EmployeeOnEmployeeForEmployeeUserUpdatedFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeUserUpdatedFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeUserUpdatedFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeUserUpdatedFkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeWorkingLocationCountryCodeAlpha2FkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CountryPatch;
}

export interface EmployeeOnEmployeeForEmployeeWorkingLocationCountryCodeAlpha2FkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeWorkingLocationCountryCodeAlpha2FkeyPatch;
}

export interface EmployeeOnEmployeeForEmployeeWorkingLocationCountryCodeAlpha2FkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnEmployeeForEmployeeWorkingLocationCountryCodeAlpha2FkeyPatch;
}

export interface EmployeeOnEmployeeGroupForEmployeeGroupEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeGroupPatch;
}

export interface EmployeeOnEmployeeGroupForEmployeeGroupEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnEmployeeGroupForEmployeeGroupEmployeeIdFkeyPatch;
}

export interface EmployeeOnEmployeeGroupForEmployeeGroupEmployeeIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnEmployeeGroupForEmployeeGroupEmployeeIdFkeyPatch;
}

export interface EmployeeOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey0NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeGroupLatestPatch;
}

export interface EmployeeOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey0UsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey0Patch;
}

export interface EmployeeOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey0UsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey0Patch;
}

export interface EmployeeOnEmployeeGroupListForFakePublicEmployeeGroupListForeignKey0NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeGroupListPatch;
}

export interface EmployeeOnEmployeeGroupListForFakePublicEmployeeGroupListForeignKey0UsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnEmployeeGroupListForFakePublicEmployeeGroupListForeignKey0Patch;
}

export interface EmployeeOnEmployeeGroupListForFakePublicEmployeeGroupListForeignKey0UsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnEmployeeGroupListForFakePublicEmployeeGroupListForeignKey0Patch;
}

export interface EmployeeOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeTpHistoryPatch;
}

export interface EmployeeOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeIdFkeyPatch;
}

export interface EmployeeOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeIdFkeyPatch;
}

export interface EmployeeOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey0NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeTransferHistoryPatch;
}

export interface EmployeeOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey0UsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey0Patch;
}

export interface EmployeeOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey0UsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey0Patch;
}

export interface EmployeeOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey0NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeWorkingDayStatisticsAllPatch;
}

export interface EmployeeOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey0UsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey0Patch;
}

export interface EmployeeOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey0UsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey0Patch;
}

export interface EmployeeOnExternalAccountEmployeeForExternalAccountEmployeeEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ExternalAccountEmployeePatch;
}

export interface EmployeeOnExternalAccountEmployeeForExternalAccountEmployeeEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeEmployeeIdFkeyPatch;
}

export interface EmployeeOnExternalAccountEmployeeForExternalAccountEmployeeEmployeeIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeEmployeeIdFkeyPatch;
}

export interface EmployeeOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationCompetencyPatch;
}

export interface EmployeeOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyPatch;
}

export interface EmployeeOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyPatch;
}

export interface EmployeeOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationEvaluatorPatch;
}

export interface EmployeeOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyPatch;
}

export interface EmployeeOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyPatch;
}

export interface EmployeeOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationFeedbackPatch;
}

export interface EmployeeOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyPatch;
}

export interface EmployeeOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyPatch;
}

export interface EmployeeOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationPatch;
}

export interface EmployeeOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeIdFkeyPatch;
}

export interface EmployeeOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeIdFkeyPatch;
}

export interface EmployeeOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationKpiAssessmentPatch;
}

export interface EmployeeOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyPatch;
}

export interface EmployeeOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyPatch;
}

export interface EmployeeOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationQualitativeAssessmentPatch;
}

export interface EmployeeOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyPatch;
}

export interface EmployeeOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyPatch;
}

export interface EmployeeOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationValuePatch;
}

export interface EmployeeOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyPatch;
}

export interface EmployeeOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyPatch;
}

export interface EmployeeOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterEvaluationViewerPatch;
}

export interface EmployeeOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyPatch;
}

export interface EmployeeOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyPatch;
}

export interface EmployeeOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterEvaluationViewerPatch;
}

export interface EmployeeOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterExcludedEmployeePatch;
}

export interface EmployeeOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyPatch;
}

export interface EmployeeOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyPatch;
}

export interface EmployeeOnProbationReviewEvaluationForProbationReviewEvaluationEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationPatch;
}

export interface EmployeeOnProbationReviewEvaluationForProbationReviewEvaluationEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnProbationReviewEvaluationForProbationReviewEvaluationEmployeeIdFkeyPatch;
}

export interface EmployeeOnProbationReviewEvaluationForProbationReviewEvaluationEmployeeIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnProbationReviewEvaluationForProbationReviewEvaluationEmployeeIdFkeyPatch;
}

export interface EmployeeOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationReviewerPatch;
}

export interface EmployeeOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyPatch;
}

export interface EmployeeOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyPatch;
}

export interface EmployeeOnRelationEmployeeUserAccountForRelationEmployeeUserAccountEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationEmployeeUserAccountPatch;
}

export interface EmployeeOnRelationEmployeeUserAccountForRelationEmployeeUserAccountEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate {
  companyEmail: Scalars['String']['input'];
  patch: UpdateEmployeeOnRelationEmployeeUserAccountForRelationEmployeeUserAccountEmployeeIdFkeyPatch;
}

export interface EmployeeOnRelationEmployeeUserAccountForRelationEmployeeUserAccountEmployeeIdFkeyUsingEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeOnRelationEmployeeUserAccountForRelationEmployeeUserAccountEmployeeIdFkeyPatch;
}

export interface EmployeePatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeePrimaryManagerIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeePrimaryManagerIdFkeyInput {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<EmployeePrimaryManagerIdFkeyEmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnEmployeeForEmployeePrimaryManagerIdFkeyUsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnEmployeeForEmployeePrimaryManagerIdFkeyUsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeOnEmployeeForEmployeePrimaryManagerIdFkeyNodeIdUpdate>;
}

export interface EmployeePrimaryManagerListConnection {
  __typename?: 'EmployeePrimaryManagerListConnection';
  edges: Array<EmployeePrimaryManagerListEdge>;
  nodes: Array<EmployeePrimaryManagerListRecord>;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeePrimaryManagerListEdge {
  __typename?: 'EmployeePrimaryManagerListEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeePrimaryManagerListRecord;
}

export interface EmployeePrimaryManagerListRecord {
  __typename?: 'EmployeePrimaryManagerListRecord';
  depth?: Maybe<Scalars['Int']['output']>;
  employeeId?: Maybe<Scalars['BigInt']['output']>;
  employeeLevel?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  fullNameInLocalLanguage?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
}

export interface EmployeePrimaryManagerListRecordFilter {
  and?: InputMaybe<Array<EmployeePrimaryManagerListRecordFilter>>;
  depth?: InputMaybe<IntFilter>;
  employeeId?: InputMaybe<BigIntFilter>;
  employeeLevel?: InputMaybe<StringFilter>;
  fullName?: InputMaybe<StringFilter>;
  fullNameInLocalLanguage?: InputMaybe<StringFilter>;
  nickname?: InputMaybe<StringFilter>;
  not?: InputMaybe<EmployeePrimaryManagerListRecordFilter>;
  or?: InputMaybe<Array<EmployeePrimaryManagerListRecordFilter>>;
}

export interface EmployeeResignationReason extends Node {
  __typename?: 'EmployeeResignationReason';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  displayName: Scalars['String']['output'];
  employeeResignationReasonId: Scalars['BigInt']['output'];
  employeeResignationReasonOrder: Scalars['BigInt']['output'];
  employeesByResignationReasonId: EmployeesConnection;
  isForContract: Scalars['Boolean']['output'];
  isForTransfer: Scalars['Boolean']['output'];
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export type EmployeeResignationReasonEmployeesByResignationReasonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCondition>;
  filter?: InputMaybe<EmployeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeesOrderBy>>;
};

export interface EmployeeResignationReasonCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeResignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeResignationReasonOrder?: InputMaybe<Scalars['BigInt']['input']>;
  isForContract?: InputMaybe<Scalars['Boolean']['input']>;
  isForTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeResignationReasonEmployeeResignationReasonDisplayNameKeyConnect {
  displayName: Scalars['String']['input'];
}

export interface EmployeeResignationReasonEmployeeResignationReasonDisplayNameKeyDelete {
  displayName: Scalars['String']['input'];
}

export interface EmployeeResignationReasonEmployeeResignationReasonPkeyConnect {
  employeeResignationReasonId: Scalars['BigInt']['input'];
}

export interface EmployeeResignationReasonEmployeeResignationReasonPkeyDelete {
  employeeResignationReasonId: Scalars['BigInt']['input'];
}

export interface EmployeeResignationReasonFilter {
  and?: InputMaybe<Array<EmployeeResignationReasonFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  displayName?: InputMaybe<StringFilter>;
  employeeResignationReasonId?: InputMaybe<BigIntFilter>;
  employeeResignationReasonOrder?: InputMaybe<BigIntFilter>;
  employeesByResignationReasonId?: InputMaybe<EmployeeResignationReasonToManyEmployeeFilter>;
  employeesByResignationReasonIdExist?: InputMaybe<Scalars['Boolean']['input']>;
  isForContract?: InputMaybe<BooleanFilter>;
  isForTransfer?: InputMaybe<BooleanFilter>;
  not?: InputMaybe<EmployeeResignationReasonFilter>;
  or?: InputMaybe<Array<EmployeeResignationReasonFilter>>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface EmployeeResignationReasonIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeResignationReasonIdFkeyEmployeeResignationReasonCreateInput {
  displayName: Scalars['String']['input'];
  employeeResignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeResignationReasonOrder: Scalars['BigInt']['input'];
  employees?: InputMaybe<EmployeeResignationReasonIdFkeyInverseInput>;
  isForContract?: InputMaybe<Scalars['Boolean']['input']>;
  isForTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeResignationReasonIdFkeyInput {
  connectByDisplayName?: InputMaybe<EmployeeResignationReasonEmployeeResignationReasonDisplayNameKeyConnect>;
  connectByEmployeeResignationReasonId?: InputMaybe<EmployeeResignationReasonEmployeeResignationReasonPkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeResignationReasonNodeIdConnect>;
  create?: InputMaybe<EmployeeResignationReasonIdFkeyEmployeeResignationReasonCreateInput>;
  deleteByDisplayName?: InputMaybe<EmployeeResignationReasonEmployeeResignationReasonDisplayNameKeyDelete>;
  deleteByEmployeeResignationReasonId?: InputMaybe<EmployeeResignationReasonEmployeeResignationReasonPkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeResignationReasonNodeIdDelete>;
  updateByDisplayName?: InputMaybe<EmployeeResignationReasonOnEmployeeForEmployeeResignationReasonIdFkeyUsingEmployeeResignationReasonDisplayNameKeyUpdate>;
  updateByEmployeeResignationReasonId?: InputMaybe<EmployeeResignationReasonOnEmployeeForEmployeeResignationReasonIdFkeyUsingEmployeeResignationReasonPkeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeOnEmployeeForEmployeeResignationReasonIdFkeyNodeIdUpdate>;
}

export interface EmployeeResignationReasonIdFkeyInverseInput {
  connectByCompanyEmail?: InputMaybe<Array<EmployeeEmployeeCompanyEmailKeyConnect>>;
  connectByEmployeeId?: InputMaybe<Array<EmployeeEmployeePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeNodeIdConnect>>;
  create?: InputMaybe<Array<EmployeeResignationReasonIdFkeyEmployeeCreateInput>>;
  deleteByCompanyEmail?: InputMaybe<Array<EmployeeEmployeeCompanyEmailKeyDelete>>;
  deleteByEmployeeId?: InputMaybe<Array<EmployeeEmployeePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyEmail?: InputMaybe<Array<EmployeeOnEmployeeForEmployeeResignationReasonIdFkeyUsingEmployeeCompanyEmailKeyUpdate>>;
  updateByEmployeeId?: InputMaybe<Array<EmployeeOnEmployeeForEmployeeResignationReasonIdFkeyUsingEmployeePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<EmployeeResignationReasonOnEmployeeForEmployeeResignationReasonIdFkeyNodeIdUpdate>>;
}

export interface EmployeeResignationReasonInput {
  displayName: Scalars['String']['input'];
  employeeResignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeResignationReasonOrder: Scalars['BigInt']['input'];
  employees?: InputMaybe<EmployeeResignationReasonIdFkeyInverseInput>;
  isForContract?: InputMaybe<Scalars['Boolean']['input']>;
  isForTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeResignationReasonNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeResignationReasonNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeResignationReasonOnEmployeeForEmployeeResignationReasonIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface EmployeeResignationReasonOnEmployeeForEmployeeResignationReasonIdFkeyUsingEmployeeResignationReasonDisplayNameKeyUpdate {
  displayName: Scalars['String']['input'];
  patch: UpdateEmployeeResignationReasonOnEmployeeForEmployeeResignationReasonIdFkeyPatch;
}

export interface EmployeeResignationReasonOnEmployeeForEmployeeResignationReasonIdFkeyUsingEmployeeResignationReasonPkeyUpdate {
  employeeResignationReasonId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeResignationReasonOnEmployeeForEmployeeResignationReasonIdFkeyPatch;
}

export interface EmployeeResignationReasonOnEmployeeResignationReasonForEmployeeResignationReasonUserCreatedFkeyUsingEmployeeResignationReasonDisplayNameKeyUpdate {
  displayName: Scalars['String']['input'];
  patch: UpdateEmployeeResignationReasonOnEmployeeResignationReasonForEmployeeResignationReasonUserCreatedFkeyPatch;
}

export interface EmployeeResignationReasonOnEmployeeResignationReasonForEmployeeResignationReasonUserCreatedFkeyUsingEmployeeResignationReasonPkeyUpdate {
  employeeResignationReasonId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeResignationReasonOnEmployeeResignationReasonForEmployeeResignationReasonUserCreatedFkeyPatch;
}

export interface EmployeeResignationReasonOnEmployeeResignationReasonForEmployeeResignationReasonUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface EmployeeResignationReasonOnEmployeeResignationReasonForEmployeeResignationReasonUserUpdatedFkeyUsingEmployeeResignationReasonDisplayNameKeyUpdate {
  displayName: Scalars['String']['input'];
  patch: UpdateEmployeeResignationReasonOnEmployeeResignationReasonForEmployeeResignationReasonUserUpdatedFkeyPatch;
}

export interface EmployeeResignationReasonOnEmployeeResignationReasonForEmployeeResignationReasonUserUpdatedFkeyUsingEmployeeResignationReasonPkeyUpdate {
  employeeResignationReasonId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeResignationReasonOnEmployeeResignationReasonForEmployeeResignationReasonUserUpdatedFkeyPatch;
}

export interface EmployeeResignationReasonPatch {
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeResignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeResignationReasonOrder?: InputMaybe<Scalars['BigInt']['input']>;
  employees?: InputMaybe<EmployeeResignationReasonIdFkeyInverseInput>;
  isForContract?: InputMaybe<Scalars['Boolean']['input']>;
  isForTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeResignationReasonToManyEmployeeFilter {
  every?: InputMaybe<EmployeeFilter>;
  none?: InputMaybe<EmployeeFilter>;
  some?: InputMaybe<EmployeeFilter>;
}

export interface EmployeeResignationReasonUserCreatedFkeyInverseInput {
  connectByDisplayName?: InputMaybe<Array<EmployeeResignationReasonEmployeeResignationReasonDisplayNameKeyConnect>>;
  connectByEmployeeResignationReasonId?: InputMaybe<Array<EmployeeResignationReasonEmployeeResignationReasonPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeResignationReasonNodeIdConnect>>;
  deleteByDisplayName?: InputMaybe<Array<EmployeeResignationReasonEmployeeResignationReasonDisplayNameKeyDelete>>;
  deleteByEmployeeResignationReasonId?: InputMaybe<Array<EmployeeResignationReasonEmployeeResignationReasonPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeResignationReasonNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByDisplayName?: InputMaybe<Array<EmployeeResignationReasonOnEmployeeResignationReasonForEmployeeResignationReasonUserCreatedFkeyUsingEmployeeResignationReasonDisplayNameKeyUpdate>>;
  updateByEmployeeResignationReasonId?: InputMaybe<Array<EmployeeResignationReasonOnEmployeeResignationReasonForEmployeeResignationReasonUserCreatedFkeyUsingEmployeeResignationReasonPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmployeeResignationReasonForEmployeeResignationReasonUserCreatedFkeyNodeIdUpdate>>;
}

export interface EmployeeResignationReasonUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnEmployeeResignationReasonForEmployeeResignationReasonUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeResignationReasonOnEmployeeResignationReasonForEmployeeResignationReasonUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnEmployeeResignationReasonForEmployeeResignationReasonUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface EmployeeResignationReasonUserUpdatedFkeyInverseInput {
  connectByDisplayName?: InputMaybe<Array<EmployeeResignationReasonEmployeeResignationReasonDisplayNameKeyConnect>>;
  connectByEmployeeResignationReasonId?: InputMaybe<Array<EmployeeResignationReasonEmployeeResignationReasonPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeResignationReasonNodeIdConnect>>;
  deleteByDisplayName?: InputMaybe<Array<EmployeeResignationReasonEmployeeResignationReasonDisplayNameKeyDelete>>;
  deleteByEmployeeResignationReasonId?: InputMaybe<Array<EmployeeResignationReasonEmployeeResignationReasonPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeResignationReasonNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByDisplayName?: InputMaybe<Array<EmployeeResignationReasonOnEmployeeResignationReasonForEmployeeResignationReasonUserUpdatedFkeyUsingEmployeeResignationReasonDisplayNameKeyUpdate>>;
  updateByEmployeeResignationReasonId?: InputMaybe<Array<EmployeeResignationReasonOnEmployeeResignationReasonForEmployeeResignationReasonUserUpdatedFkeyUsingEmployeeResignationReasonPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmployeeResignationReasonForEmployeeResignationReasonUserUpdatedFkeyNodeIdUpdate>>;
}

export interface EmployeeResignationReasonsConnection {
  __typename?: 'EmployeeResignationReasonsConnection';
  edges: Array<EmployeeResignationReasonsEdge>;
  nodes: Array<EmployeeResignationReason>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeResignationReasonsEdge {
  __typename?: 'EmployeeResignationReasonsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeResignationReason;
}

export enum EmployeeResignationReasonsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DISPLAY_NAME_ASC = 'DISPLAY_NAME_ASC',
  DISPLAY_NAME_DESC = 'DISPLAY_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__COUNT_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__COUNT_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__COUNT_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__COUNT_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_APPROVER_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_APPROVER_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_APPROVER_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_APPROVER_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_BANK_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_BANK_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_BANK_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_BANK_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_BANK_REMARKS_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_BANK_REMARKS_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_BANK_REMARKS_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_BANK_REMARKS_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_DATE_CREATED_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_DATE_CREATED_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_DATE_CREATED_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_DATE_CREATED_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_DATE_UPDATED_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_DATE_UPDATED_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_DATE_UPDATED_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_DATE_UPDATED_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_FULL_NAME_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_FULL_NAME_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_FULL_NAME_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_FULL_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_GENDER_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_GENDER_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_GENDER_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_GENDER_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_ID_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_ID_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_ID_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_ID_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_ID_TYPE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_ID_TYPE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_ID_TYPE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_ID_TYPE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_JOIN_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_JOIN_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_JOIN_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_JOIN_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_NATIONALITY_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_NATIONALITY_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_NATIONALITY_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_NATIONALITY_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_NICKNAME_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_NICKNAME_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_NICKNAME_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_NICKNAME_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PROBATION_END_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PROBATION_END_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PROBATION_END_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_PROBATION_END_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_REMARK_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_REMARK_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_REMARK_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_REMARK_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_STATUS_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_STATUS_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_STATUS_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_STATUS_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_TAX_CODE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_TAX_CODE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_TAX_CODE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_TAX_CODE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_USER_CREATED_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_USER_CREATED_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_USER_CREATED_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_USER_CREATED_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_USER_UPDATED_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_USER_UPDATED_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_USER_UPDATED_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_USER_UPDATED_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_VISA_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_VISA_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_VISA_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_VISA_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_VISA_TYPE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_VISA_TYPE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_VISA_TYPE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_VISA_TYPE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MAX_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_APPROVER_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_APPROVER_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_APPROVER_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_APPROVER_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_BANK_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_BANK_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_BANK_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_BANK_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_BANK_REMARKS_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_BANK_REMARKS_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_BANK_REMARKS_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_BANK_REMARKS_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_DATE_CREATED_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_DATE_CREATED_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_DATE_CREATED_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_DATE_CREATED_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_DATE_UPDATED_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_DATE_UPDATED_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_DATE_UPDATED_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_DATE_UPDATED_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_FULL_NAME_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_FULL_NAME_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_FULL_NAME_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_FULL_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_GENDER_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_GENDER_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_GENDER_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_GENDER_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_ID_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_ID_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_ID_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_ID_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_ID_TYPE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_ID_TYPE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_ID_TYPE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_ID_TYPE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_JOIN_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_JOIN_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_JOIN_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_JOIN_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_NATIONALITY_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_NATIONALITY_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_NATIONALITY_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_NATIONALITY_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_NICKNAME_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_NICKNAME_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_NICKNAME_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_NICKNAME_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PROBATION_END_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PROBATION_END_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PROBATION_END_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_PROBATION_END_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_REMARK_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_REMARK_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_REMARK_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_REMARK_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_STATUS_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_STATUS_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_STATUS_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_STATUS_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_TAX_CODE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_TAX_CODE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_TAX_CODE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_TAX_CODE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_USER_CREATED_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_USER_CREATED_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_USER_CREATED_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_USER_CREATED_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_USER_UPDATED_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_USER_UPDATED_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_USER_UPDATED_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_USER_UPDATED_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_VISA_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_VISA_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_VISA_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_VISA_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_VISA_TYPE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_VISA_TYPE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_VISA_TYPE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_VISA_TYPE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_REASON_ID__MIN_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_RESIGNATION_REASON_ORDER_ASC = 'EMPLOYEE_RESIGNATION_REASON_ORDER_ASC',
  EMPLOYEE_RESIGNATION_REASON_ORDER_DESC = 'EMPLOYEE_RESIGNATION_REASON_ORDER_DESC',
  IS_FOR_CONTRACT_ASC = 'IS_FOR_CONTRACT_ASC',
  IS_FOR_CONTRACT_DESC = 'IS_FOR_CONTRACT_DESC',
  IS_FOR_TRANSFER_ASC = 'IS_FOR_TRANSFER_ASC',
  IS_FOR_TRANSFER_DESC = 'IS_FOR_TRANSFER_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface EmployeeResignationType extends Node {
  __typename?: 'EmployeeResignationType';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  displayName: Scalars['String']['output'];
  employeeResignationTypeId: Scalars['BigInt']['output'];
  employeeResignationTypeOrder: Scalars['BigInt']['output'];
  employeesByResignationTypeId: EmployeesConnection;
  isForContract: Scalars['Boolean']['output'];
  isForTransfer: Scalars['Boolean']['output'];
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export type EmployeeResignationTypeEmployeesByResignationTypeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCondition>;
  filter?: InputMaybe<EmployeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeesOrderBy>>;
};

export interface EmployeeResignationTypeCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeResignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeResignationTypeOrder?: InputMaybe<Scalars['BigInt']['input']>;
  isForContract?: InputMaybe<Scalars['Boolean']['input']>;
  isForTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeResignationTypeEmployeeResignationTypeDisplayNameKeyConnect {
  displayName: Scalars['String']['input'];
}

export interface EmployeeResignationTypeEmployeeResignationTypeDisplayNameKeyDelete {
  displayName: Scalars['String']['input'];
}

export interface EmployeeResignationTypeEmployeeResignationTypePkeyConnect {
  employeeResignationTypeId: Scalars['BigInt']['input'];
}

export interface EmployeeResignationTypeEmployeeResignationTypePkeyDelete {
  employeeResignationTypeId: Scalars['BigInt']['input'];
}

export interface EmployeeResignationTypeFilter {
  and?: InputMaybe<Array<EmployeeResignationTypeFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  displayName?: InputMaybe<StringFilter>;
  employeeResignationTypeId?: InputMaybe<BigIntFilter>;
  employeeResignationTypeOrder?: InputMaybe<BigIntFilter>;
  employeesByResignationTypeId?: InputMaybe<EmployeeResignationTypeToManyEmployeeFilter>;
  employeesByResignationTypeIdExist?: InputMaybe<Scalars['Boolean']['input']>;
  isForContract?: InputMaybe<BooleanFilter>;
  isForTransfer?: InputMaybe<BooleanFilter>;
  not?: InputMaybe<EmployeeResignationTypeFilter>;
  or?: InputMaybe<Array<EmployeeResignationTypeFilter>>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface EmployeeResignationTypeIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeResignationTypeIdFkeyEmployeeResignationTypeCreateInput {
  displayName: Scalars['String']['input'];
  employeeResignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeResignationTypeOrder: Scalars['BigInt']['input'];
  employees?: InputMaybe<EmployeeResignationTypeIdFkeyInverseInput>;
  isForContract?: InputMaybe<Scalars['Boolean']['input']>;
  isForTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeResignationTypeIdFkeyInput {
  connectByDisplayName?: InputMaybe<EmployeeResignationTypeEmployeeResignationTypeDisplayNameKeyConnect>;
  connectByEmployeeResignationTypeId?: InputMaybe<EmployeeResignationTypeEmployeeResignationTypePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeResignationTypeNodeIdConnect>;
  create?: InputMaybe<EmployeeResignationTypeIdFkeyEmployeeResignationTypeCreateInput>;
  deleteByDisplayName?: InputMaybe<EmployeeResignationTypeEmployeeResignationTypeDisplayNameKeyDelete>;
  deleteByEmployeeResignationTypeId?: InputMaybe<EmployeeResignationTypeEmployeeResignationTypePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeResignationTypeNodeIdDelete>;
  updateByDisplayName?: InputMaybe<EmployeeResignationTypeOnEmployeeForEmployeeResignationTypeIdFkeyUsingEmployeeResignationTypeDisplayNameKeyUpdate>;
  updateByEmployeeResignationTypeId?: InputMaybe<EmployeeResignationTypeOnEmployeeForEmployeeResignationTypeIdFkeyUsingEmployeeResignationTypePkeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeOnEmployeeForEmployeeResignationTypeIdFkeyNodeIdUpdate>;
}

export interface EmployeeResignationTypeIdFkeyInverseInput {
  connectByCompanyEmail?: InputMaybe<Array<EmployeeEmployeeCompanyEmailKeyConnect>>;
  connectByEmployeeId?: InputMaybe<Array<EmployeeEmployeePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeNodeIdConnect>>;
  create?: InputMaybe<Array<EmployeeResignationTypeIdFkeyEmployeeCreateInput>>;
  deleteByCompanyEmail?: InputMaybe<Array<EmployeeEmployeeCompanyEmailKeyDelete>>;
  deleteByEmployeeId?: InputMaybe<Array<EmployeeEmployeePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyEmail?: InputMaybe<Array<EmployeeOnEmployeeForEmployeeResignationTypeIdFkeyUsingEmployeeCompanyEmailKeyUpdate>>;
  updateByEmployeeId?: InputMaybe<Array<EmployeeOnEmployeeForEmployeeResignationTypeIdFkeyUsingEmployeePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<EmployeeResignationTypeOnEmployeeForEmployeeResignationTypeIdFkeyNodeIdUpdate>>;
}

export interface EmployeeResignationTypeInput {
  displayName: Scalars['String']['input'];
  employeeResignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeResignationTypeOrder: Scalars['BigInt']['input'];
  employees?: InputMaybe<EmployeeResignationTypeIdFkeyInverseInput>;
  isForContract?: InputMaybe<Scalars['Boolean']['input']>;
  isForTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeResignationTypeNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeResignationTypeNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeResignationTypeOnEmployeeForEmployeeResignationTypeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface EmployeeResignationTypeOnEmployeeForEmployeeResignationTypeIdFkeyUsingEmployeeResignationTypeDisplayNameKeyUpdate {
  displayName: Scalars['String']['input'];
  patch: UpdateEmployeeResignationTypeOnEmployeeForEmployeeResignationTypeIdFkeyPatch;
}

export interface EmployeeResignationTypeOnEmployeeForEmployeeResignationTypeIdFkeyUsingEmployeeResignationTypePkeyUpdate {
  employeeResignationTypeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeResignationTypeOnEmployeeForEmployeeResignationTypeIdFkeyPatch;
}

export interface EmployeeResignationTypeOnEmployeeResignationTypeForEmployeeResignationTypeUserCreatedFkeyUsingEmployeeResignationTypeDisplayNameKeyUpdate {
  displayName: Scalars['String']['input'];
  patch: UpdateEmployeeResignationTypeOnEmployeeResignationTypeForEmployeeResignationTypeUserCreatedFkeyPatch;
}

export interface EmployeeResignationTypeOnEmployeeResignationTypeForEmployeeResignationTypeUserCreatedFkeyUsingEmployeeResignationTypePkeyUpdate {
  employeeResignationTypeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeResignationTypeOnEmployeeResignationTypeForEmployeeResignationTypeUserCreatedFkeyPatch;
}

export interface EmployeeResignationTypeOnEmployeeResignationTypeForEmployeeResignationTypeUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface EmployeeResignationTypeOnEmployeeResignationTypeForEmployeeResignationTypeUserUpdatedFkeyUsingEmployeeResignationTypeDisplayNameKeyUpdate {
  displayName: Scalars['String']['input'];
  patch: UpdateEmployeeResignationTypeOnEmployeeResignationTypeForEmployeeResignationTypeUserUpdatedFkeyPatch;
}

export interface EmployeeResignationTypeOnEmployeeResignationTypeForEmployeeResignationTypeUserUpdatedFkeyUsingEmployeeResignationTypePkeyUpdate {
  employeeResignationTypeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeResignationTypeOnEmployeeResignationTypeForEmployeeResignationTypeUserUpdatedFkeyPatch;
}

export interface EmployeeResignationTypePatch {
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeResignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeResignationTypeOrder?: InputMaybe<Scalars['BigInt']['input']>;
  employees?: InputMaybe<EmployeeResignationTypeIdFkeyInverseInput>;
  isForContract?: InputMaybe<Scalars['Boolean']['input']>;
  isForTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeResignationTypeToManyEmployeeFilter {
  every?: InputMaybe<EmployeeFilter>;
  none?: InputMaybe<EmployeeFilter>;
  some?: InputMaybe<EmployeeFilter>;
}

export interface EmployeeResignationTypeUserCreatedFkeyInverseInput {
  connectByDisplayName?: InputMaybe<Array<EmployeeResignationTypeEmployeeResignationTypeDisplayNameKeyConnect>>;
  connectByEmployeeResignationTypeId?: InputMaybe<Array<EmployeeResignationTypeEmployeeResignationTypePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeResignationTypeNodeIdConnect>>;
  deleteByDisplayName?: InputMaybe<Array<EmployeeResignationTypeEmployeeResignationTypeDisplayNameKeyDelete>>;
  deleteByEmployeeResignationTypeId?: InputMaybe<Array<EmployeeResignationTypeEmployeeResignationTypePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeResignationTypeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByDisplayName?: InputMaybe<Array<EmployeeResignationTypeOnEmployeeResignationTypeForEmployeeResignationTypeUserCreatedFkeyUsingEmployeeResignationTypeDisplayNameKeyUpdate>>;
  updateByEmployeeResignationTypeId?: InputMaybe<Array<EmployeeResignationTypeOnEmployeeResignationTypeForEmployeeResignationTypeUserCreatedFkeyUsingEmployeeResignationTypePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmployeeResignationTypeForEmployeeResignationTypeUserCreatedFkeyNodeIdUpdate>>;
}

export interface EmployeeResignationTypeUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnEmployeeResignationTypeForEmployeeResignationTypeUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeResignationTypeOnEmployeeResignationTypeForEmployeeResignationTypeUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnEmployeeResignationTypeForEmployeeResignationTypeUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface EmployeeResignationTypeUserUpdatedFkeyInverseInput {
  connectByDisplayName?: InputMaybe<Array<EmployeeResignationTypeEmployeeResignationTypeDisplayNameKeyConnect>>;
  connectByEmployeeResignationTypeId?: InputMaybe<Array<EmployeeResignationTypeEmployeeResignationTypePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeResignationTypeNodeIdConnect>>;
  deleteByDisplayName?: InputMaybe<Array<EmployeeResignationTypeEmployeeResignationTypeDisplayNameKeyDelete>>;
  deleteByEmployeeResignationTypeId?: InputMaybe<Array<EmployeeResignationTypeEmployeeResignationTypePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeResignationTypeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByDisplayName?: InputMaybe<Array<EmployeeResignationTypeOnEmployeeResignationTypeForEmployeeResignationTypeUserUpdatedFkeyUsingEmployeeResignationTypeDisplayNameKeyUpdate>>;
  updateByEmployeeResignationTypeId?: InputMaybe<Array<EmployeeResignationTypeOnEmployeeResignationTypeForEmployeeResignationTypeUserUpdatedFkeyUsingEmployeeResignationTypePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmployeeResignationTypeForEmployeeResignationTypeUserUpdatedFkeyNodeIdUpdate>>;
}

export interface EmployeeResignationTypesConnection {
  __typename?: 'EmployeeResignationTypesConnection';
  edges: Array<EmployeeResignationTypesEdge>;
  nodes: Array<EmployeeResignationType>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeResignationTypesEdge {
  __typename?: 'EmployeeResignationTypesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeResignationType;
}

export enum EmployeeResignationTypesOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DISPLAY_NAME_ASC = 'DISPLAY_NAME_ASC',
  DISPLAY_NAME_DESC = 'DISPLAY_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__COUNT_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__COUNT_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__COUNT_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__COUNT_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_APPROVER_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_APPROVER_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_APPROVER_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_APPROVER_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_BANK_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_BANK_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_BANK_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_BANK_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_BANK_REMARKS_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_BANK_REMARKS_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_BANK_REMARKS_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_BANK_REMARKS_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_DATE_CREATED_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_DATE_CREATED_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_DATE_CREATED_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_DATE_CREATED_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_DATE_UPDATED_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_DATE_UPDATED_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_DATE_UPDATED_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_DATE_UPDATED_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_FULL_NAME_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_FULL_NAME_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_FULL_NAME_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_FULL_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_GENDER_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_GENDER_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_GENDER_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_GENDER_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_ID_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_ID_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_ID_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_ID_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_ID_TYPE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_ID_TYPE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_ID_TYPE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_ID_TYPE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_JOIN_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_JOIN_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_JOIN_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_JOIN_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_NATIONALITY_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_NATIONALITY_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_NATIONALITY_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_NATIONALITY_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_NICKNAME_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_NICKNAME_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_NICKNAME_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_NICKNAME_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PROBATION_END_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PROBATION_END_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PROBATION_END_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_PROBATION_END_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_REMARK_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_REMARK_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_REMARK_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_REMARK_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_STATUS_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_STATUS_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_STATUS_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_STATUS_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_TAX_CODE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_TAX_CODE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_TAX_CODE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_TAX_CODE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_USER_CREATED_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_USER_CREATED_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_USER_CREATED_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_USER_CREATED_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_USER_UPDATED_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_USER_UPDATED_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_USER_UPDATED_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_USER_UPDATED_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_VISA_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_VISA_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_VISA_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_VISA_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_VISA_TYPE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_VISA_TYPE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_VISA_TYPE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_VISA_TYPE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MAX_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_APPROVER_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_APPROVER_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_APPROVER_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_APPROVER_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_BANK_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_BANK_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_BANK_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_BANK_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_BANK_REMARKS_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_BANK_REMARKS_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_BANK_REMARKS_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_BANK_REMARKS_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_DATE_CREATED_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_DATE_CREATED_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_DATE_CREATED_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_DATE_CREATED_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_DATE_UPDATED_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_DATE_UPDATED_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_DATE_UPDATED_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_DATE_UPDATED_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_FULL_NAME_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_FULL_NAME_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_FULL_NAME_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_FULL_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_GENDER_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_GENDER_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_GENDER_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_GENDER_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_ID_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_ID_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_ID_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_ID_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_ID_TYPE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_ID_TYPE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_ID_TYPE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_ID_TYPE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_JOIN_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_JOIN_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_JOIN_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_JOIN_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_NATIONALITY_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_NATIONALITY_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_NATIONALITY_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_NATIONALITY_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_NICKNAME_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_NICKNAME_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_NICKNAME_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_NICKNAME_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PROBATION_END_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PROBATION_END_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PROBATION_END_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_PROBATION_END_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_REMARK_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_REMARK_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_REMARK_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_REMARK_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_STATUS_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_STATUS_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_STATUS_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_STATUS_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_TAX_CODE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_TAX_CODE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_TAX_CODE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_TAX_CODE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_USER_CREATED_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_USER_CREATED_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_USER_CREATED_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_USER_CREATED_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_USER_UPDATED_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_USER_UPDATED_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_USER_UPDATED_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_USER_UPDATED_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_VISA_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_VISA_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_VISA_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_VISA_NUMBER_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_VISA_TYPE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_VISA_TYPE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_VISA_TYPE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_VISA_TYPE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_RESIGNATION_TYPE_ID__MIN_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_RESIGNATION_TYPE_ORDER_ASC = 'EMPLOYEE_RESIGNATION_TYPE_ORDER_ASC',
  EMPLOYEE_RESIGNATION_TYPE_ORDER_DESC = 'EMPLOYEE_RESIGNATION_TYPE_ORDER_DESC',
  IS_FOR_CONTRACT_ASC = 'IS_FOR_CONTRACT_ASC',
  IS_FOR_CONTRACT_DESC = 'IS_FOR_CONTRACT_DESC',
  IS_FOR_TRANSFER_ASC = 'IS_FOR_TRANSFER_ASC',
  IS_FOR_TRANSFER_DESC = 'IS_FOR_TRANSFER_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface EmployeeStatus extends Node {
  __typename?: 'EmployeeStatus';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  displayName: Scalars['String']['output'];
  employeeGroupLatestsByStatus: EmployeeGroupLatestsConnection;
  employeeStatusKey: EmployeeStatusKey;
  employeeStatusOrder: Scalars['BigInt']['output'];
  employeesByStatus: EmployeesConnection;
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  shortName: Scalars['String']['output'];
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export type EmployeeStatusEmployeeGroupLatestsByStatusArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeGroupLatestCondition>;
  filter?: InputMaybe<EmployeeGroupLatestFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeGroupLatestsOrderBy>>;
};

export type EmployeeStatusEmployeesByStatusArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCondition>;
  filter?: InputMaybe<EmployeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeesOrderBy>>;
};

export interface EmployeeStatusCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeStatusKey?: InputMaybe<EmployeeStatusKey>;
  employeeStatusOrder?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeStatusEmployeeStatusEmployeeStatusOrderKeyConnect {
  employeeStatusOrder: Scalars['BigInt']['input'];
}

export interface EmployeeStatusEmployeeStatusPkeyConnect {
  employeeStatusKey: EmployeeStatusKey;
}

export interface EmployeeStatusFilter {
  and?: InputMaybe<Array<EmployeeStatusFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  displayName?: InputMaybe<StringFilter>;
  employeeGroupLatestsByStatus?: InputMaybe<EmployeeStatusToManyEmployeeGroupLatestFilter>;
  employeeGroupLatestsByStatusExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeStatusKey?: InputMaybe<EmployeeStatusKeyFilter>;
  employeeStatusOrder?: InputMaybe<BigIntFilter>;
  employeesByStatus?: InputMaybe<EmployeeStatusToManyEmployeeFilter>;
  employeesByStatusExist?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<EmployeeStatusFilter>;
  or?: InputMaybe<Array<EmployeeStatusFilter>>;
  remark?: InputMaybe<StringFilter>;
  shortName?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface EmployeeStatusFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeStatusFkeyInput {
  connectByEmployeeStatusKey?: InputMaybe<EmployeeStatusEmployeeStatusPkeyConnect>;
  connectByEmployeeStatusOrder?: InputMaybe<EmployeeStatusEmployeeStatusEmployeeStatusOrderKeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeStatusNodeIdConnect>;
  updateByEmployeeStatusKey?: InputMaybe<EmployeeStatusOnEmployeeForEmployeeStatusFkeyUsingEmployeeStatusPkeyUpdate>;
  updateByEmployeeStatusOrder?: InputMaybe<EmployeeStatusOnEmployeeForEmployeeStatusFkeyUsingEmployeeStatusEmployeeStatusOrderKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeOnEmployeeForEmployeeStatusFkeyNodeIdUpdate>;
}

export interface EmployeeStatusFkeyInverseInput {
  connectByCompanyEmail?: InputMaybe<Array<EmployeeEmployeeCompanyEmailKeyConnect>>;
  connectByEmployeeId?: InputMaybe<Array<EmployeeEmployeePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeNodeIdConnect>>;
  create?: InputMaybe<Array<EmployeeStatusFkeyEmployeeCreateInput>>;
  deleteByCompanyEmail?: InputMaybe<Array<EmployeeEmployeeCompanyEmailKeyDelete>>;
  deleteByEmployeeId?: InputMaybe<Array<EmployeeEmployeePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyEmail?: InputMaybe<Array<EmployeeOnEmployeeForEmployeeStatusFkeyUsingEmployeeCompanyEmailKeyUpdate>>;
  updateByEmployeeId?: InputMaybe<Array<EmployeeOnEmployeeForEmployeeStatusFkeyUsingEmployeePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<EmployeeStatusOnEmployeeForEmployeeStatusFkeyNodeIdUpdate>>;
}

export enum EmployeeStatusKey {
  ACTIVE = 'ACTIVE',
  PRE_ONBOARDING = 'PRE_ONBOARDING',
  RESIGNED = 'RESIGNED',
}

export interface EmployeeStatusKeyFilter {
  distinctFrom?: InputMaybe<EmployeeStatusKey>;
  equalTo?: InputMaybe<EmployeeStatusKey>;
  greaterThan?: InputMaybe<EmployeeStatusKey>;
  greaterThanOrEqualTo?: InputMaybe<EmployeeStatusKey>;
  in?: InputMaybe<Array<EmployeeStatusKey>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<EmployeeStatusKey>;
  lessThanOrEqualTo?: InputMaybe<EmployeeStatusKey>;
  notDistinctFrom?: InputMaybe<EmployeeStatusKey>;
  notEqualTo?: InputMaybe<EmployeeStatusKey>;
  notIn?: InputMaybe<Array<EmployeeStatusKey>>;
}

export interface EmployeeStatusNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeStatusOnEmployeeForEmployeeStatusFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface EmployeeStatusOnEmployeeForEmployeeStatusFkeyUsingEmployeeStatusEmployeeStatusOrderKeyUpdate {
  employeeStatusOrder: Scalars['BigInt']['input'];
  patch: UpdateEmployeeStatusOnEmployeeForEmployeeStatusFkeyPatch;
}

export interface EmployeeStatusOnEmployeeForEmployeeStatusFkeyUsingEmployeeStatusPkeyUpdate {
  employeeStatusKey: EmployeeStatusKey;
  patch: UpdateEmployeeStatusOnEmployeeForEmployeeStatusFkeyPatch;
}

export interface EmployeeStatusOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey1NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeGroupLatestPatch;
}

export interface EmployeeStatusOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey1UsingEmployeeStatusEmployeeStatusOrderKeyUpdate {
  employeeStatusOrder: Scalars['BigInt']['input'];
  patch: UpdateEmployeeStatusOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey1Patch;
}

export interface EmployeeStatusOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey1UsingEmployeeStatusPkeyUpdate {
  employeeStatusKey: EmployeeStatusKey;
  patch: UpdateEmployeeStatusOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey1Patch;
}

export interface EmployeeStatusOnEmployeeStatusForEmployeeStatusUserCreatedFkeyUsingEmployeeStatusEmployeeStatusOrderKeyUpdate {
  employeeStatusOrder: Scalars['BigInt']['input'];
  patch: UpdateEmployeeStatusOnEmployeeStatusForEmployeeStatusUserCreatedFkeyPatch;
}

export interface EmployeeStatusOnEmployeeStatusForEmployeeStatusUserCreatedFkeyUsingEmployeeStatusPkeyUpdate {
  employeeStatusKey: EmployeeStatusKey;
  patch: UpdateEmployeeStatusOnEmployeeStatusForEmployeeStatusUserCreatedFkeyPatch;
}

export interface EmployeeStatusOnEmployeeStatusForEmployeeStatusUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface EmployeeStatusOnEmployeeStatusForEmployeeStatusUserUpdatedFkeyUsingEmployeeStatusEmployeeStatusOrderKeyUpdate {
  employeeStatusOrder: Scalars['BigInt']['input'];
  patch: UpdateEmployeeStatusOnEmployeeStatusForEmployeeStatusUserUpdatedFkeyPatch;
}

export interface EmployeeStatusOnEmployeeStatusForEmployeeStatusUserUpdatedFkeyUsingEmployeeStatusPkeyUpdate {
  employeeStatusKey: EmployeeStatusKey;
  patch: UpdateEmployeeStatusOnEmployeeStatusForEmployeeStatusUserUpdatedFkeyPatch;
}

export interface EmployeeStatusPatch {
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeGroupLatests?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey1InverseInput>;
  employeeStatusKey?: InputMaybe<EmployeeStatusKey>;
  employeeStatusOrder?: InputMaybe<Scalars['BigInt']['input']>;
  employees?: InputMaybe<EmployeeStatusFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeStatusUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeStatusToManyEmployeeFilter {
  every?: InputMaybe<EmployeeFilter>;
  none?: InputMaybe<EmployeeFilter>;
  some?: InputMaybe<EmployeeFilter>;
}

export interface EmployeeStatusToManyEmployeeGroupLatestFilter {
  every?: InputMaybe<EmployeeGroupLatestFilter>;
  none?: InputMaybe<EmployeeGroupLatestFilter>;
  some?: InputMaybe<EmployeeGroupLatestFilter>;
}

export interface EmployeeStatusUserCreatedFkeyInverseInput {
  connectByEmployeeStatusKey?: InputMaybe<Array<EmployeeStatusEmployeeStatusPkeyConnect>>;
  connectByEmployeeStatusOrder?: InputMaybe<Array<EmployeeStatusEmployeeStatusEmployeeStatusOrderKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeStatusNodeIdConnect>>;
  updateByEmployeeStatusKey?: InputMaybe<Array<EmployeeStatusOnEmployeeStatusForEmployeeStatusUserCreatedFkeyUsingEmployeeStatusPkeyUpdate>>;
  updateByEmployeeStatusOrder?: InputMaybe<Array<EmployeeStatusOnEmployeeStatusForEmployeeStatusUserCreatedFkeyUsingEmployeeStatusEmployeeStatusOrderKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmployeeStatusForEmployeeStatusUserCreatedFkeyNodeIdUpdate>>;
}

export interface EmployeeStatusUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnEmployeeStatusForEmployeeStatusUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeStatusOnEmployeeStatusForEmployeeStatusUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnEmployeeStatusForEmployeeStatusUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface EmployeeStatusUserUpdatedFkeyInverseInput {
  connectByEmployeeStatusKey?: InputMaybe<Array<EmployeeStatusEmployeeStatusPkeyConnect>>;
  connectByEmployeeStatusOrder?: InputMaybe<Array<EmployeeStatusEmployeeStatusEmployeeStatusOrderKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeStatusNodeIdConnect>>;
  updateByEmployeeStatusKey?: InputMaybe<Array<EmployeeStatusOnEmployeeStatusForEmployeeStatusUserUpdatedFkeyUsingEmployeeStatusPkeyUpdate>>;
  updateByEmployeeStatusOrder?: InputMaybe<Array<EmployeeStatusOnEmployeeStatusForEmployeeStatusUserUpdatedFkeyUsingEmployeeStatusEmployeeStatusOrderKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmployeeStatusForEmployeeStatusUserUpdatedFkeyNodeIdUpdate>>;
}

export interface EmployeeStatusesConnection {
  __typename?: 'EmployeeStatusesConnection';
  edges: Array<EmployeeStatusesEdge>;
  nodes: Array<EmployeeStatus>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeStatusesEdge {
  __typename?: 'EmployeeStatusesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeStatus;
}

export enum EmployeeStatusesOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DISPLAY_NAME_ASC = 'DISPLAY_NAME_ASC',
  DISPLAY_NAME_DESC = 'DISPLAY_NAME_DESC',
  EMPLOYEES_BY_STATUS__COUNT_ASC = 'EMPLOYEES_BY_STATUS__COUNT_ASC',
  EMPLOYEES_BY_STATUS__COUNT_DESC = 'EMPLOYEES_BY_STATUS__COUNT_DESC',
  EMPLOYEES_BY_STATUS__MAX_APPROVER_ID_ASC = 'EMPLOYEES_BY_STATUS__MAX_APPROVER_ID_ASC',
  EMPLOYEES_BY_STATUS__MAX_APPROVER_ID_DESC = 'EMPLOYEES_BY_STATUS__MAX_APPROVER_ID_DESC',
  EMPLOYEES_BY_STATUS__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_STATUS__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_STATUS__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_STATUS__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_STATUS__MAX_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_STATUS__MAX_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_STATUS__MAX_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_STATUS__MAX_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_STATUS__MAX_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_STATUS__MAX_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_STATUS__MAX_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_STATUS__MAX_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_STATUS__MAX_BANK_ID_ASC = 'EMPLOYEES_BY_STATUS__MAX_BANK_ID_ASC',
  EMPLOYEES_BY_STATUS__MAX_BANK_ID_DESC = 'EMPLOYEES_BY_STATUS__MAX_BANK_ID_DESC',
  EMPLOYEES_BY_STATUS__MAX_BANK_REMARKS_ASC = 'EMPLOYEES_BY_STATUS__MAX_BANK_REMARKS_ASC',
  EMPLOYEES_BY_STATUS__MAX_BANK_REMARKS_DESC = 'EMPLOYEES_BY_STATUS__MAX_BANK_REMARKS_DESC',
  EMPLOYEES_BY_STATUS__MAX_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_STATUS__MAX_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_STATUS__MAX_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_STATUS__MAX_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_STATUS__MAX_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_STATUS__MAX_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_STATUS__MAX_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_STATUS__MAX_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_STATUS__MAX_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_STATUS__MAX_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_STATUS__MAX_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_STATUS__MAX_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_STATUS__MAX_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_STATUS__MAX_DATE_CREATED_ASC = 'EMPLOYEES_BY_STATUS__MAX_DATE_CREATED_ASC',
  EMPLOYEES_BY_STATUS__MAX_DATE_CREATED_DESC = 'EMPLOYEES_BY_STATUS__MAX_DATE_CREATED_DESC',
  EMPLOYEES_BY_STATUS__MAX_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_STATUS__MAX_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_STATUS__MAX_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_STATUS__MAX_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_STATUS__MAX_DATE_UPDATED_ASC = 'EMPLOYEES_BY_STATUS__MAX_DATE_UPDATED_ASC',
  EMPLOYEES_BY_STATUS__MAX_DATE_UPDATED_DESC = 'EMPLOYEES_BY_STATUS__MAX_DATE_UPDATED_DESC',
  EMPLOYEES_BY_STATUS__MAX_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_STATUS__MAX_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_STATUS__MAX_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_STATUS__MAX_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_STATUS__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_STATUS__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_STATUS__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_STATUS__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_STATUS__MAX_FULL_NAME_ASC = 'EMPLOYEES_BY_STATUS__MAX_FULL_NAME_ASC',
  EMPLOYEES_BY_STATUS__MAX_FULL_NAME_DESC = 'EMPLOYEES_BY_STATUS__MAX_FULL_NAME_DESC',
  EMPLOYEES_BY_STATUS__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_STATUS__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_STATUS__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_STATUS__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_STATUS__MAX_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_STATUS__MAX_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_STATUS__MAX_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_STATUS__MAX_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_STATUS__MAX_GENDER_ASC = 'EMPLOYEES_BY_STATUS__MAX_GENDER_ASC',
  EMPLOYEES_BY_STATUS__MAX_GENDER_DESC = 'EMPLOYEES_BY_STATUS__MAX_GENDER_DESC',
  EMPLOYEES_BY_STATUS__MAX_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_STATUS__MAX_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_STATUS__MAX_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_STATUS__MAX_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_STATUS__MAX_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_STATUS__MAX_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_STATUS__MAX_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_STATUS__MAX_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_STATUS__MAX_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_STATUS__MAX_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_STATUS__MAX_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_STATUS__MAX_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_STATUS__MAX_ID_NUMBER_ASC = 'EMPLOYEES_BY_STATUS__MAX_ID_NUMBER_ASC',
  EMPLOYEES_BY_STATUS__MAX_ID_NUMBER_DESC = 'EMPLOYEES_BY_STATUS__MAX_ID_NUMBER_DESC',
  EMPLOYEES_BY_STATUS__MAX_ID_TYPE_ASC = 'EMPLOYEES_BY_STATUS__MAX_ID_TYPE_ASC',
  EMPLOYEES_BY_STATUS__MAX_ID_TYPE_DESC = 'EMPLOYEES_BY_STATUS__MAX_ID_TYPE_DESC',
  EMPLOYEES_BY_STATUS__MAX_JOIN_DATE_ASC = 'EMPLOYEES_BY_STATUS__MAX_JOIN_DATE_ASC',
  EMPLOYEES_BY_STATUS__MAX_JOIN_DATE_DESC = 'EMPLOYEES_BY_STATUS__MAX_JOIN_DATE_DESC',
  EMPLOYEES_BY_STATUS__MAX_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_STATUS__MAX_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_STATUS__MAX_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_STATUS__MAX_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_STATUS__MAX_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_STATUS__MAX_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_STATUS__MAX_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_STATUS__MAX_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_STATUS__MAX_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_STATUS__MAX_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_STATUS__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_STATUS__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_STATUS__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_STATUS__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_STATUS__MAX_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_STATUS__MAX_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_STATUS__MAX_NATIONALITY_ASC = 'EMPLOYEES_BY_STATUS__MAX_NATIONALITY_ASC',
  EMPLOYEES_BY_STATUS__MAX_NATIONALITY_DESC = 'EMPLOYEES_BY_STATUS__MAX_NATIONALITY_DESC',
  EMPLOYEES_BY_STATUS__MAX_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_STATUS__MAX_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_STATUS__MAX_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_STATUS__MAX_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_STATUS__MAX_NICKNAME_ASC = 'EMPLOYEES_BY_STATUS__MAX_NICKNAME_ASC',
  EMPLOYEES_BY_STATUS__MAX_NICKNAME_DESC = 'EMPLOYEES_BY_STATUS__MAX_NICKNAME_DESC',
  EMPLOYEES_BY_STATUS__MAX_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_STATUS__MAX_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_STATUS__MAX_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_STATUS__MAX_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_STATUS__MAX_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_STATUS__MAX_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_STATUS__MAX_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_STATUS__MAX_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_STATUS__MAX_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_STATUS__MAX_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_STATUS__MAX_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_STATUS__MAX_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_STATUS__MAX_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_STATUS__MAX_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_STATUS__MAX_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_STATUS__MAX_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_STATUS__MAX_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_STATUS__MAX_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_STATUS__MAX_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_STATUS__MAX_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_STATUS__MAX_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_STATUS__MAX_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_STATUS__MAX_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_STATUS__MAX_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_STATUS__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_STATUS__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_STATUS__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_STATUS__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_STATUS__MAX_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_STATUS__MAX_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_STATUS__MAX_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_STATUS__MAX_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_STATUS__MAX_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_STATUS__MAX_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_STATUS__MAX_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_STATUS__MAX_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_STATUS__MAX_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_STATUS__MAX_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_STATUS__MAX_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_STATUS__MAX_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_STATUS__MAX_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_STATUS__MAX_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_STATUS__MAX_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_STATUS__MAX_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_STATUS__MAX_PROBATION_END_ASC = 'EMPLOYEES_BY_STATUS__MAX_PROBATION_END_ASC',
  EMPLOYEES_BY_STATUS__MAX_PROBATION_END_DESC = 'EMPLOYEES_BY_STATUS__MAX_PROBATION_END_DESC',
  EMPLOYEES_BY_STATUS__MAX_REMARK_ASC = 'EMPLOYEES_BY_STATUS__MAX_REMARK_ASC',
  EMPLOYEES_BY_STATUS__MAX_REMARK_DESC = 'EMPLOYEES_BY_STATUS__MAX_REMARK_DESC',
  EMPLOYEES_BY_STATUS__MAX_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_STATUS__MAX_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_STATUS__MAX_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_STATUS__MAX_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_STATUS__MAX_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_STATUS__MAX_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_STATUS__MAX_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_STATUS__MAX_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_STATUS__MAX_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_STATUS__MAX_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_STATUS__MAX_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_STATUS__MAX_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_STATUS__MAX_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_STATUS__MAX_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_STATUS__MAX_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_STATUS__MAX_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_STATUS__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_STATUS__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_STATUS__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_STATUS__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_STATUS__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_STATUS__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_STATUS__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_STATUS__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_STATUS__MAX_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_STATUS__MAX_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_STATUS__MAX_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_STATUS__MAX_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_STATUS__MAX_STATUS_ASC = 'EMPLOYEES_BY_STATUS__MAX_STATUS_ASC',
  EMPLOYEES_BY_STATUS__MAX_STATUS_DESC = 'EMPLOYEES_BY_STATUS__MAX_STATUS_DESC',
  EMPLOYEES_BY_STATUS__MAX_TAX_CODE_ASC = 'EMPLOYEES_BY_STATUS__MAX_TAX_CODE_ASC',
  EMPLOYEES_BY_STATUS__MAX_TAX_CODE_DESC = 'EMPLOYEES_BY_STATUS__MAX_TAX_CODE_DESC',
  EMPLOYEES_BY_STATUS__MAX_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_STATUS__MAX_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_STATUS__MAX_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_STATUS__MAX_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_STATUS__MAX_USER_CREATED_ASC = 'EMPLOYEES_BY_STATUS__MAX_USER_CREATED_ASC',
  EMPLOYEES_BY_STATUS__MAX_USER_CREATED_DESC = 'EMPLOYEES_BY_STATUS__MAX_USER_CREATED_DESC',
  EMPLOYEES_BY_STATUS__MAX_USER_UPDATED_ASC = 'EMPLOYEES_BY_STATUS__MAX_USER_UPDATED_ASC',
  EMPLOYEES_BY_STATUS__MAX_USER_UPDATED_DESC = 'EMPLOYEES_BY_STATUS__MAX_USER_UPDATED_DESC',
  EMPLOYEES_BY_STATUS__MAX_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_STATUS__MAX_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_STATUS__MAX_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_STATUS__MAX_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_STATUS__MAX_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_STATUS__MAX_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_STATUS__MAX_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_STATUS__MAX_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_STATUS__MAX_VISA_NUMBER_ASC = 'EMPLOYEES_BY_STATUS__MAX_VISA_NUMBER_ASC',
  EMPLOYEES_BY_STATUS__MAX_VISA_NUMBER_DESC = 'EMPLOYEES_BY_STATUS__MAX_VISA_NUMBER_DESC',
  EMPLOYEES_BY_STATUS__MAX_VISA_TYPE_ASC = 'EMPLOYEES_BY_STATUS__MAX_VISA_TYPE_ASC',
  EMPLOYEES_BY_STATUS__MAX_VISA_TYPE_DESC = 'EMPLOYEES_BY_STATUS__MAX_VISA_TYPE_DESC',
  EMPLOYEES_BY_STATUS__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_STATUS__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_STATUS__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_STATUS__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_STATUS__MAX_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_STATUS__MAX_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_STATUS__MAX_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_STATUS__MAX_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_STATUS__MAX_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_STATUS__MAX_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_STATUS__MAX_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_STATUS__MAX_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_STATUS__MAX_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_STATUS__MAX_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_STATUS__MAX_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_STATUS__MAX_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEES_BY_STATUS__MIN_APPROVER_ID_ASC = 'EMPLOYEES_BY_STATUS__MIN_APPROVER_ID_ASC',
  EMPLOYEES_BY_STATUS__MIN_APPROVER_ID_DESC = 'EMPLOYEES_BY_STATUS__MIN_APPROVER_ID_DESC',
  EMPLOYEES_BY_STATUS__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_STATUS__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_STATUS__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_STATUS__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_STATUS__MIN_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_STATUS__MIN_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_STATUS__MIN_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_STATUS__MIN_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_STATUS__MIN_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_STATUS__MIN_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_STATUS__MIN_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_STATUS__MIN_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_STATUS__MIN_BANK_ID_ASC = 'EMPLOYEES_BY_STATUS__MIN_BANK_ID_ASC',
  EMPLOYEES_BY_STATUS__MIN_BANK_ID_DESC = 'EMPLOYEES_BY_STATUS__MIN_BANK_ID_DESC',
  EMPLOYEES_BY_STATUS__MIN_BANK_REMARKS_ASC = 'EMPLOYEES_BY_STATUS__MIN_BANK_REMARKS_ASC',
  EMPLOYEES_BY_STATUS__MIN_BANK_REMARKS_DESC = 'EMPLOYEES_BY_STATUS__MIN_BANK_REMARKS_DESC',
  EMPLOYEES_BY_STATUS__MIN_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_STATUS__MIN_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_STATUS__MIN_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_STATUS__MIN_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_STATUS__MIN_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_STATUS__MIN_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_STATUS__MIN_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_STATUS__MIN_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_STATUS__MIN_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_STATUS__MIN_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_STATUS__MIN_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_STATUS__MIN_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_STATUS__MIN_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_STATUS__MIN_DATE_CREATED_ASC = 'EMPLOYEES_BY_STATUS__MIN_DATE_CREATED_ASC',
  EMPLOYEES_BY_STATUS__MIN_DATE_CREATED_DESC = 'EMPLOYEES_BY_STATUS__MIN_DATE_CREATED_DESC',
  EMPLOYEES_BY_STATUS__MIN_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_STATUS__MIN_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_STATUS__MIN_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_STATUS__MIN_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_STATUS__MIN_DATE_UPDATED_ASC = 'EMPLOYEES_BY_STATUS__MIN_DATE_UPDATED_ASC',
  EMPLOYEES_BY_STATUS__MIN_DATE_UPDATED_DESC = 'EMPLOYEES_BY_STATUS__MIN_DATE_UPDATED_DESC',
  EMPLOYEES_BY_STATUS__MIN_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_STATUS__MIN_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_STATUS__MIN_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_STATUS__MIN_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_STATUS__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_STATUS__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_STATUS__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_STATUS__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_STATUS__MIN_FULL_NAME_ASC = 'EMPLOYEES_BY_STATUS__MIN_FULL_NAME_ASC',
  EMPLOYEES_BY_STATUS__MIN_FULL_NAME_DESC = 'EMPLOYEES_BY_STATUS__MIN_FULL_NAME_DESC',
  EMPLOYEES_BY_STATUS__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_STATUS__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_STATUS__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_STATUS__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_STATUS__MIN_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_STATUS__MIN_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_STATUS__MIN_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_STATUS__MIN_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_STATUS__MIN_GENDER_ASC = 'EMPLOYEES_BY_STATUS__MIN_GENDER_ASC',
  EMPLOYEES_BY_STATUS__MIN_GENDER_DESC = 'EMPLOYEES_BY_STATUS__MIN_GENDER_DESC',
  EMPLOYEES_BY_STATUS__MIN_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_STATUS__MIN_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_STATUS__MIN_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_STATUS__MIN_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_STATUS__MIN_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_STATUS__MIN_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_STATUS__MIN_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_STATUS__MIN_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_STATUS__MIN_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_STATUS__MIN_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_STATUS__MIN_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_STATUS__MIN_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_STATUS__MIN_ID_NUMBER_ASC = 'EMPLOYEES_BY_STATUS__MIN_ID_NUMBER_ASC',
  EMPLOYEES_BY_STATUS__MIN_ID_NUMBER_DESC = 'EMPLOYEES_BY_STATUS__MIN_ID_NUMBER_DESC',
  EMPLOYEES_BY_STATUS__MIN_ID_TYPE_ASC = 'EMPLOYEES_BY_STATUS__MIN_ID_TYPE_ASC',
  EMPLOYEES_BY_STATUS__MIN_ID_TYPE_DESC = 'EMPLOYEES_BY_STATUS__MIN_ID_TYPE_DESC',
  EMPLOYEES_BY_STATUS__MIN_JOIN_DATE_ASC = 'EMPLOYEES_BY_STATUS__MIN_JOIN_DATE_ASC',
  EMPLOYEES_BY_STATUS__MIN_JOIN_DATE_DESC = 'EMPLOYEES_BY_STATUS__MIN_JOIN_DATE_DESC',
  EMPLOYEES_BY_STATUS__MIN_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_STATUS__MIN_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_STATUS__MIN_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_STATUS__MIN_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_STATUS__MIN_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_STATUS__MIN_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_STATUS__MIN_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_STATUS__MIN_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_STATUS__MIN_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_STATUS__MIN_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_STATUS__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_STATUS__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_STATUS__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_STATUS__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_STATUS__MIN_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_STATUS__MIN_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_STATUS__MIN_NATIONALITY_ASC = 'EMPLOYEES_BY_STATUS__MIN_NATIONALITY_ASC',
  EMPLOYEES_BY_STATUS__MIN_NATIONALITY_DESC = 'EMPLOYEES_BY_STATUS__MIN_NATIONALITY_DESC',
  EMPLOYEES_BY_STATUS__MIN_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_STATUS__MIN_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_STATUS__MIN_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_STATUS__MIN_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_STATUS__MIN_NICKNAME_ASC = 'EMPLOYEES_BY_STATUS__MIN_NICKNAME_ASC',
  EMPLOYEES_BY_STATUS__MIN_NICKNAME_DESC = 'EMPLOYEES_BY_STATUS__MIN_NICKNAME_DESC',
  EMPLOYEES_BY_STATUS__MIN_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_STATUS__MIN_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_STATUS__MIN_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_STATUS__MIN_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_STATUS__MIN_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_STATUS__MIN_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_STATUS__MIN_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_STATUS__MIN_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_STATUS__MIN_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_STATUS__MIN_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_STATUS__MIN_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_STATUS__MIN_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_STATUS__MIN_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_STATUS__MIN_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_STATUS__MIN_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_STATUS__MIN_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_STATUS__MIN_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_STATUS__MIN_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_STATUS__MIN_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_STATUS__MIN_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_STATUS__MIN_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_STATUS__MIN_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_STATUS__MIN_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_STATUS__MIN_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_STATUS__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_STATUS__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_STATUS__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_STATUS__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_STATUS__MIN_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_STATUS__MIN_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_STATUS__MIN_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_STATUS__MIN_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_STATUS__MIN_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_STATUS__MIN_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_STATUS__MIN_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_STATUS__MIN_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_STATUS__MIN_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_STATUS__MIN_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_STATUS__MIN_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_STATUS__MIN_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_STATUS__MIN_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_STATUS__MIN_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_STATUS__MIN_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_STATUS__MIN_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_STATUS__MIN_PROBATION_END_ASC = 'EMPLOYEES_BY_STATUS__MIN_PROBATION_END_ASC',
  EMPLOYEES_BY_STATUS__MIN_PROBATION_END_DESC = 'EMPLOYEES_BY_STATUS__MIN_PROBATION_END_DESC',
  EMPLOYEES_BY_STATUS__MIN_REMARK_ASC = 'EMPLOYEES_BY_STATUS__MIN_REMARK_ASC',
  EMPLOYEES_BY_STATUS__MIN_REMARK_DESC = 'EMPLOYEES_BY_STATUS__MIN_REMARK_DESC',
  EMPLOYEES_BY_STATUS__MIN_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_STATUS__MIN_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_STATUS__MIN_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_STATUS__MIN_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_STATUS__MIN_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_STATUS__MIN_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_STATUS__MIN_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_STATUS__MIN_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_STATUS__MIN_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_STATUS__MIN_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_STATUS__MIN_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_STATUS__MIN_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_STATUS__MIN_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_STATUS__MIN_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_STATUS__MIN_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_STATUS__MIN_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_STATUS__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_STATUS__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_STATUS__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_STATUS__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_STATUS__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_STATUS__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_STATUS__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_STATUS__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_STATUS__MIN_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_STATUS__MIN_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_STATUS__MIN_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_STATUS__MIN_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_STATUS__MIN_STATUS_ASC = 'EMPLOYEES_BY_STATUS__MIN_STATUS_ASC',
  EMPLOYEES_BY_STATUS__MIN_STATUS_DESC = 'EMPLOYEES_BY_STATUS__MIN_STATUS_DESC',
  EMPLOYEES_BY_STATUS__MIN_TAX_CODE_ASC = 'EMPLOYEES_BY_STATUS__MIN_TAX_CODE_ASC',
  EMPLOYEES_BY_STATUS__MIN_TAX_CODE_DESC = 'EMPLOYEES_BY_STATUS__MIN_TAX_CODE_DESC',
  EMPLOYEES_BY_STATUS__MIN_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_STATUS__MIN_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_STATUS__MIN_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_STATUS__MIN_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_STATUS__MIN_USER_CREATED_ASC = 'EMPLOYEES_BY_STATUS__MIN_USER_CREATED_ASC',
  EMPLOYEES_BY_STATUS__MIN_USER_CREATED_DESC = 'EMPLOYEES_BY_STATUS__MIN_USER_CREATED_DESC',
  EMPLOYEES_BY_STATUS__MIN_USER_UPDATED_ASC = 'EMPLOYEES_BY_STATUS__MIN_USER_UPDATED_ASC',
  EMPLOYEES_BY_STATUS__MIN_USER_UPDATED_DESC = 'EMPLOYEES_BY_STATUS__MIN_USER_UPDATED_DESC',
  EMPLOYEES_BY_STATUS__MIN_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_STATUS__MIN_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_STATUS__MIN_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_STATUS__MIN_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_STATUS__MIN_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_STATUS__MIN_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_STATUS__MIN_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_STATUS__MIN_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_STATUS__MIN_VISA_NUMBER_ASC = 'EMPLOYEES_BY_STATUS__MIN_VISA_NUMBER_ASC',
  EMPLOYEES_BY_STATUS__MIN_VISA_NUMBER_DESC = 'EMPLOYEES_BY_STATUS__MIN_VISA_NUMBER_DESC',
  EMPLOYEES_BY_STATUS__MIN_VISA_TYPE_ASC = 'EMPLOYEES_BY_STATUS__MIN_VISA_TYPE_ASC',
  EMPLOYEES_BY_STATUS__MIN_VISA_TYPE_DESC = 'EMPLOYEES_BY_STATUS__MIN_VISA_TYPE_DESC',
  EMPLOYEES_BY_STATUS__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_STATUS__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_STATUS__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_STATUS__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_STATUS__MIN_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_STATUS__MIN_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_STATUS__MIN_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_STATUS__MIN_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_STATUS__MIN_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_STATUS__MIN_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_STATUS__MIN_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_STATUS__MIN_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_STATUS__MIN_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_STATUS__MIN_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_STATUS__MIN_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_STATUS__MIN_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__COUNT_ASC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__COUNT_ASC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__COUNT_DESC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__COUNT_DESC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_ALL_EMPLOYEE_ID_LIST_ASC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_ALL_EMPLOYEE_ID_LIST_ASC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_ALL_EMPLOYEE_ID_LIST_DESC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_ALL_EMPLOYEE_ID_LIST_DESC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_COMPANY_EMAIL_ASC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_COMPANY_EMAIL_ASC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_COMPANY_EMAIL_DESC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_COMPANY_EMAIL_DESC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_FULL_NAME_ASC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_FULL_NAME_ASC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_FULL_NAME_DESC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_FULL_NAME_DESC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_JOIN_DATE_ASC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_JOIN_DATE_ASC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_JOIN_DATE_DESC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_JOIN_DATE_DESC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_NICKNAME_ASC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_NICKNAME_ASC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_NICKNAME_DESC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_NICKNAME_DESC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_RESIGNATION_DATE_ASC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_RESIGNATION_DATE_ASC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_RESIGNATION_DATE_DESC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_RESIGNATION_DATE_DESC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_STATUS_ASC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_STATUS_ASC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_STATUS_DESC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MAX_STATUS_DESC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_ALL_EMPLOYEE_ID_LIST_ASC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_ALL_EMPLOYEE_ID_LIST_ASC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_ALL_EMPLOYEE_ID_LIST_DESC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_ALL_EMPLOYEE_ID_LIST_DESC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_COMPANY_EMAIL_ASC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_COMPANY_EMAIL_ASC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_COMPANY_EMAIL_DESC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_COMPANY_EMAIL_DESC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_FULL_NAME_ASC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_FULL_NAME_ASC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_FULL_NAME_DESC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_FULL_NAME_DESC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_JOIN_DATE_ASC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_JOIN_DATE_ASC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_JOIN_DATE_DESC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_JOIN_DATE_DESC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_NICKNAME_ASC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_NICKNAME_ASC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_NICKNAME_DESC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_NICKNAME_DESC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_RESIGNATION_DATE_ASC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_RESIGNATION_DATE_ASC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_RESIGNATION_DATE_DESC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_RESIGNATION_DATE_DESC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_STATUS_ASC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_STATUS_ASC',
  EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_STATUS_DESC = 'EMPLOYEE_GROUP_LATESTS_BY_STATUS__MIN_STATUS_DESC',
  EMPLOYEE_STATUS_KEY_ASC = 'EMPLOYEE_STATUS_KEY_ASC',
  EMPLOYEE_STATUS_KEY_DESC = 'EMPLOYEE_STATUS_KEY_DESC',
  EMPLOYEE_STATUS_ORDER_ASC = 'EMPLOYEE_STATUS_ORDER_ASC',
  EMPLOYEE_STATUS_ORDER_DESC = 'EMPLOYEE_STATUS_ORDER_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  SHORT_NAME_ASC = 'SHORT_NAME_ASC',
  SHORT_NAME_DESC = 'SHORT_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface EmployeeToManyAllEmployeeWithYearQuarterFilter {
  every?: InputMaybe<AllEmployeeWithYearQuarterFilter>;
  none?: InputMaybe<AllEmployeeWithYearQuarterFilter>;
  some?: InputMaybe<AllEmployeeWithYearQuarterFilter>;
}

export interface EmployeeToManyCompanyLocalHrFilter {
  every?: InputMaybe<CompanyLocalHrFilter>;
  none?: InputMaybe<CompanyLocalHrFilter>;
  some?: InputMaybe<CompanyLocalHrFilter>;
}

export interface EmployeeToManyEmployeeDaysByContractTypeFilter {
  every?: InputMaybe<EmployeeDaysByContractTypeFilter>;
  none?: InputMaybe<EmployeeDaysByContractTypeFilter>;
  some?: InputMaybe<EmployeeDaysByContractTypeFilter>;
}

export interface EmployeeToManyEmployeeEmergencyContactFilter {
  every?: InputMaybe<EmployeeEmergencyContactFilter>;
  none?: InputMaybe<EmployeeEmergencyContactFilter>;
  some?: InputMaybe<EmployeeEmergencyContactFilter>;
}

export interface EmployeeToManyEmployeeFileFilter {
  every?: InputMaybe<EmployeeFileFilter>;
  none?: InputMaybe<EmployeeFileFilter>;
  some?: InputMaybe<EmployeeFileFilter>;
}

export interface EmployeeToManyEmployeeFilter {
  every?: InputMaybe<EmployeeFilter>;
  none?: InputMaybe<EmployeeFilter>;
  some?: InputMaybe<EmployeeFilter>;
}

export interface EmployeeToManyEmployeeGroupFilter {
  every?: InputMaybe<EmployeeGroupFilter>;
  none?: InputMaybe<EmployeeGroupFilter>;
  some?: InputMaybe<EmployeeGroupFilter>;
}

export interface EmployeeToManyEmployeeGroupListFilter {
  every?: InputMaybe<EmployeeGroupListFilter>;
  none?: InputMaybe<EmployeeGroupListFilter>;
  some?: InputMaybe<EmployeeGroupListFilter>;
}

export interface EmployeeToManyEmployeeTpHistoryFilter {
  every?: InputMaybe<EmployeeTpHistoryFilter>;
  none?: InputMaybe<EmployeeTpHistoryFilter>;
  some?: InputMaybe<EmployeeTpHistoryFilter>;
}

export interface EmployeeToManyEmployeeTransferHistoryFilter {
  every?: InputMaybe<EmployeeTransferHistoryFilter>;
  none?: InputMaybe<EmployeeTransferHistoryFilter>;
  some?: InputMaybe<EmployeeTransferHistoryFilter>;
}

export interface EmployeeToManyExternalAccountCreationFailedFilter {
  every?: InputMaybe<ExternalAccountCreationFailedFilter>;
  none?: InputMaybe<ExternalAccountCreationFailedFilter>;
  some?: InputMaybe<ExternalAccountCreationFailedFilter>;
}

export interface EmployeeToManyExternalAccountEmployeeFilter {
  every?: InputMaybe<ExternalAccountEmployeeFilter>;
  none?: InputMaybe<ExternalAccountEmployeeFilter>;
  some?: InputMaybe<ExternalAccountEmployeeFilter>;
}

export interface EmployeeToManyPerformanceReviewEvaluationCompetencyFilter {
  every?: InputMaybe<PerformanceReviewEvaluationCompetencyFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationCompetencyFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationCompetencyFilter>;
}

export interface EmployeeToManyPerformanceReviewEvaluationEvaluatorFilter {
  every?: InputMaybe<PerformanceReviewEvaluationEvaluatorFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationEvaluatorFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationEvaluatorFilter>;
}

export interface EmployeeToManyPerformanceReviewEvaluationFeedbackFilter {
  every?: InputMaybe<PerformanceReviewEvaluationFeedbackFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationFeedbackFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationFeedbackFilter>;
}

export interface EmployeeToManyPerformanceReviewEvaluationFilter {
  every?: InputMaybe<PerformanceReviewEvaluationFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationFilter>;
}

export interface EmployeeToManyPerformanceReviewEvaluationKpiAssessmentFilter {
  every?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentFilter>;
}

export interface EmployeeToManyPerformanceReviewEvaluationOneOnOneSelfListFilter {
  every?: InputMaybe<PerformanceReviewEvaluationOneOnOneSelfListFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationOneOnOneSelfListFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationOneOnOneSelfListFilter>;
}

export interface EmployeeToManyPerformanceReviewEvaluationQualitativeAssessmentFilter {
  every?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentFilter>;
}

export interface EmployeeToManyPerformanceReviewEvaluationValueFilter {
  every?: InputMaybe<PerformanceReviewEvaluationValueFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationValueFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationValueFilter>;
}

export interface EmployeeToManyPerformanceReviewMasterEvaluationViewerFilter {
  every?: InputMaybe<PerformanceReviewMasterEvaluationViewerFilter>;
  none?: InputMaybe<PerformanceReviewMasterEvaluationViewerFilter>;
  some?: InputMaybe<PerformanceReviewMasterEvaluationViewerFilter>;
}

export interface EmployeeToManyPerformanceReviewMasterExcludedEmployeeFilter {
  every?: InputMaybe<PerformanceReviewMasterExcludedEmployeeFilter>;
  none?: InputMaybe<PerformanceReviewMasterExcludedEmployeeFilter>;
  some?: InputMaybe<PerformanceReviewMasterExcludedEmployeeFilter>;
}

export interface EmployeeToManyProbationReviewEvaluationFilter {
  every?: InputMaybe<ProbationReviewEvaluationFilter>;
  none?: InputMaybe<ProbationReviewEvaluationFilter>;
  some?: InputMaybe<ProbationReviewEvaluationFilter>;
}

export interface EmployeeToManyProbationReviewEvaluationReviewerFilter {
  every?: InputMaybe<ProbationReviewEvaluationReviewerFilter>;
  none?: InputMaybe<ProbationReviewEvaluationReviewerFilter>;
  some?: InputMaybe<ProbationReviewEvaluationReviewerFilter>;
}

export interface EmployeeToManyRelationEmployeeUserAccountFilter {
  every?: InputMaybe<RelationEmployeeUserAccountFilter>;
  none?: InputMaybe<RelationEmployeeUserAccountFilter>;
  some?: InputMaybe<RelationEmployeeUserAccountFilter>;
}

export interface EmployeeTpHistoriesConnection {
  __typename?: 'EmployeeTpHistoriesConnection';
  edges: Array<EmployeeTpHistoriesEdge>;
  nodes: Array<EmployeeTpHistory>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeTpHistoriesEdge {
  __typename?: 'EmployeeTpHistoriesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeTpHistory;
}

export enum EmployeeTpHistoriesOrderBy {
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__COUNT_ASC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__COUNT_ASC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__COUNT_DESC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__COUNT_DESC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_ID_ASC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_ID_ASC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_ID_DESC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_ID_DESC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_QUARTER_ASC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_QUARTER_ASC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_QUARTER_DESC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_QUARTER_DESC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_RANK_LATEST_ASC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_RANK_LATEST_ASC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_RANK_LATEST_DESC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_RANK_LATEST_DESC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_YEAR_ASC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_YEAR_ASC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_YEAR_DESC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_YEAR_DESC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_ID_ASC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_ID_ASC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_ID_DESC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_ID_DESC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_QUARTER_ASC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_QUARTER_ASC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_QUARTER_DESC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_QUARTER_DESC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_RANK_LATEST_ASC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_RANK_LATEST_ASC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_RANK_LATEST_DESC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_RANK_LATEST_DESC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_YEAR_ASC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_YEAR_ASC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_YEAR_DESC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_YEAR_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_DESC',
  BUSINESS_UNIT_ID_ASC = 'BUSINESS_UNIT_ID_ASC',
  BUSINESS_UNIT_ID_DESC = 'BUSINESS_UNIT_ID_DESC',
  COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC',
  COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_ASC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_ASC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_DESC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_DESC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANY_BY_COMPANY_ID__REMARK_ASC = 'COMPANY_BY_COMPANY_ID__REMARK_ASC',
  COMPANY_BY_COMPANY_ID__REMARK_DESC = 'COMPANY_BY_COMPANY_ID__REMARK_DESC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC',
  COMPANY_ID_ASC = 'COMPANY_ID_ASC',
  COMPANY_ID_DESC = 'COMPANY_ID_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CODE_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CODE_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CODE_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CODE_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CONTRACT_TYPE_ID_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CONTRACT_TYPE_ID_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CONTRACT_TYPE_ID_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CONTRACT_TYPE_ID_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_CREATED_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_CREATED_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_CREATED_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_CREATED_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_UPDATED_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_UPDATED_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_UPDATED_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_UPDATED_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DISPLAY_NAME_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DISPLAY_NAME_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DISPLAY_NAME_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DISPLAY_NAME_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__REMARK_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__REMARK_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__REMARK_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__REMARK_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_CREATED_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_CREATED_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_CREATED_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_CREATED_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_UPDATED_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_UPDATED_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_UPDATED_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_UPDATED_DESC',
  CONTRACT_TYPE_ID_ASC = 'CONTRACT_TYPE_ID_ASC',
  CONTRACT_TYPE_ID_DESC = 'CONTRACT_TYPE_ID_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__DATE_CREATED_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__DATE_CREATED_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__DATE_CREATED_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__DATE_CREATED_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__DATE_UPDATED_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__DATE_UPDATED_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__DATE_UPDATED_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__DATE_UPDATED_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_ID_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_ID_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_ID_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_ID_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__IS_ACTIVE_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__IS_ACTIVE_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__IS_ACTIVE_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__IS_ACTIVE_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__REMARK_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__REMARK_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__REMARK_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__REMARK_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__USER_CREATED_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__USER_CREATED_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__USER_CREATED_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__USER_CREATED_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__USER_UPDATED_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__USER_UPDATED_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__USER_UPDATED_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__USER_UPDATED_DESC',
  DEPARTMENT_ID_ASC = 'DEPARTMENT_ID_ASC',
  DEPARTMENT_ID_DESC = 'DEPARTMENT_ID_DESC',
  DIVISION_BY_DIVISION_ID__DATE_CREATED_ASC = 'DIVISION_BY_DIVISION_ID__DATE_CREATED_ASC',
  DIVISION_BY_DIVISION_ID__DATE_CREATED_DESC = 'DIVISION_BY_DIVISION_ID__DATE_CREATED_DESC',
  DIVISION_BY_DIVISION_ID__DATE_UPDATED_ASC = 'DIVISION_BY_DIVISION_ID__DATE_UPDATED_ASC',
  DIVISION_BY_DIVISION_ID__DATE_UPDATED_DESC = 'DIVISION_BY_DIVISION_ID__DATE_UPDATED_DESC',
  DIVISION_BY_DIVISION_ID__DIVISION_ID_ASC = 'DIVISION_BY_DIVISION_ID__DIVISION_ID_ASC',
  DIVISION_BY_DIVISION_ID__DIVISION_ID_DESC = 'DIVISION_BY_DIVISION_ID__DIVISION_ID_DESC',
  DIVISION_BY_DIVISION_ID__IS_ACTIVE_ASC = 'DIVISION_BY_DIVISION_ID__IS_ACTIVE_ASC',
  DIVISION_BY_DIVISION_ID__IS_ACTIVE_DESC = 'DIVISION_BY_DIVISION_ID__IS_ACTIVE_DESC',
  DIVISION_BY_DIVISION_ID__NAME_ASC = 'DIVISION_BY_DIVISION_ID__NAME_ASC',
  DIVISION_BY_DIVISION_ID__NAME_DESC = 'DIVISION_BY_DIVISION_ID__NAME_DESC',
  DIVISION_BY_DIVISION_ID__REMARK_ASC = 'DIVISION_BY_DIVISION_ID__REMARK_ASC',
  DIVISION_BY_DIVISION_ID__REMARK_DESC = 'DIVISION_BY_DIVISION_ID__REMARK_DESC',
  DIVISION_BY_DIVISION_ID__USER_CREATED_ASC = 'DIVISION_BY_DIVISION_ID__USER_CREATED_ASC',
  DIVISION_BY_DIVISION_ID__USER_CREATED_DESC = 'DIVISION_BY_DIVISION_ID__USER_CREATED_DESC',
  DIVISION_BY_DIVISION_ID__USER_UPDATED_ASC = 'DIVISION_BY_DIVISION_ID__USER_UPDATED_ASC',
  DIVISION_BY_DIVISION_ID__USER_UPDATED_DESC = 'DIVISION_BY_DIVISION_ID__USER_UPDATED_DESC',
  DIVISION_ID_ASC = 'DIVISION_ID_ASC',
  DIVISION_ID_DESC = 'DIVISION_ID_DESC',
  EFFECTIVE_DATE_ASC = 'EFFECTIVE_DATE_ASC',
  EFFECTIVE_DATE_DESC = 'EFFECTIVE_DATE_DESC',
  EFFECTIVE_DATE_UNTIL_ASC = 'EFFECTIVE_DATE_UNTIL_ASC',
  EFFECTIVE_DATE_UNTIL_DESC = 'EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_CODE_ASC = 'EMPLOYEE_CODE_ASC',
  EMPLOYEE_CODE_DESC = 'EMPLOYEE_CODE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__COUNT_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__COUNT_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__COUNT_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__COUNT_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_CITY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_CITY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_CITY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_CITY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_COMPANY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_COMPANY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_COMPANY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_COMPANY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DEPARTMENT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DEPARTMENT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DEPARTMENT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DEPARTMENT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DIVISION_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DIVISION_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DIVISION_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DIVISION_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EFFECTIVE_DATE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EFFECTIVE_DATE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EFFECTIVE_DATE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EFFECTIVE_DATE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_CODE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_CODE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_CODE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_CODE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_JOB_TITLE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_JOB_TITLE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_JOB_TITLE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_JOB_TITLE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_OFFICE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_OFFICE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_OFFICE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_OFFICE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_TEAM_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_TEAM_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_TEAM_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_TEAM_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_CITY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_CITY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_CITY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_CITY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_COMPANY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_COMPANY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_COMPANY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_COMPANY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DEPARTMENT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DEPARTMENT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DEPARTMENT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DEPARTMENT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DIVISION_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DIVISION_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DIVISION_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DIVISION_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EFFECTIVE_DATE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EFFECTIVE_DATE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EFFECTIVE_DATE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EFFECTIVE_DATE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_CODE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_CODE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_CODE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_CODE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_JOB_TITLE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_JOB_TITLE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_JOB_TITLE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_JOB_TITLE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_OFFICE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_OFFICE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_OFFICE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_OFFICE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_TEAM_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_TEAM_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_TEAM_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_TEAM_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_COUNTRY_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_COUNTRY_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_COUNTRY_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_COUNTRY_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_INDIVIDUAL_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_INDIVIDUAL_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_INDIVIDUAL_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_INDIVIDUAL_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_TEAM_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_TEAM_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_TEAM_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_TEAM_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PROBATION_REVIEW_OF_ALL_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PROBATION_REVIEW_OF_ALL_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PROBATION_REVIEW_OF_ALL_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PROBATION_REVIEW_OF_ALL_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DATE_CREATED_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DATE_CREATED_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DATE_CREATED_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DATE_CREATED_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DATE_UPDATED_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DATE_UPDATED_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DATE_UPDATED_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DATE_UPDATED_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DISPLAY_NAME_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DISPLAY_NAME_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DISPLAY_NAME_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DISPLAY_NAME_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__EMPLOYEE_LEVEL_ORDER_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__EMPLOYEE_LEVEL_ORDER_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__EMPLOYEE_LEVEL_ORDER_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__EMPLOYEE_LEVEL_ORDER_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__REMARK_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__REMARK_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__REMARK_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__REMARK_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__TITLE_CONTRIBUTOR_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__TITLE_CONTRIBUTOR_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__TITLE_CONTRIBUTOR_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__TITLE_CONTRIBUTOR_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__TITLE_MANAGER_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__TITLE_MANAGER_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__TITLE_MANAGER_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__TITLE_MANAGER_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__USER_CREATED_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__USER_CREATED_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__USER_CREATED_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__USER_CREATED_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__USER_UPDATED_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__USER_UPDATED_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__USER_UPDATED_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__USER_UPDATED_DESC',
  EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__COUNT_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__COUNT_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__COUNT_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__COUNT_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_BUSINESS_UNIT_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_BUSINESS_UNIT_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_BUSINESS_UNIT_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_BUSINESS_UNIT_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_CITY_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_CITY_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_CITY_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_CITY_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_CITY_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_CITY_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_CITY_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_CITY_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_COMPANY_EMAIL_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_COMPANY_EMAIL_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_COMPANY_EMAIL_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_COMPANY_EMAIL_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_COMPANY_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_COMPANY_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_COMPANY_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_COMPANY_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_COMPANY_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_COMPANY_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_COMPANY_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_COMPANY_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_CONTRACT_TYPE_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_CONTRACT_TYPE_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_CONTRACT_TYPE_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_CONTRACT_TYPE_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DEPARTMENT_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DEPARTMENT_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DEPARTMENT_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DEPARTMENT_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DEPARTMENT_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DEPARTMENT_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DEPARTMENT_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DEPARTMENT_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DIVISION_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DIVISION_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DIVISION_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DIVISION_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DIVISION_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DIVISION_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DIVISION_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DIVISION_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EFFECTIVE_DATE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EFFECTIVE_DATE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EFFECTIVE_DATE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EFFECTIVE_DATE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_LEVEL_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_LEVEL_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_LEVEL_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_LEVEL_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_LEVEL_ORDER_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_LEVEL_ORDER_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_LEVEL_ORDER_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_LEVEL_ORDER_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_FLAG_DEMOTION_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_FLAG_DEMOTION_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_FLAG_DEMOTION_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_FLAG_DEMOTION_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_FLAG_PROMOTION_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_FLAG_PROMOTION_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_FLAG_PROMOTION_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_FLAG_PROMOTION_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_FLAG_TRANSFER_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_FLAG_TRANSFER_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_FLAG_TRANSFER_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_FLAG_TRANSFER_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_FULL_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_FULL_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_FULL_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_FULL_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_JOB_TITLE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_JOB_TITLE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_JOB_TITLE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_JOB_TITLE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_JOIN_DATE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_JOIN_DATE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_JOIN_DATE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_JOIN_DATE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_NICKNAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_NICKNAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_NICKNAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_NICKNAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_OFFICE_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_OFFICE_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_OFFICE_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_OFFICE_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_OFFICE_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_OFFICE_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_OFFICE_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_OFFICE_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_BUSINESS_UNIT_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_BUSINESS_UNIT_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_BUSINESS_UNIT_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_BUSINESS_UNIT_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_CITY_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_CITY_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_CITY_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_CITY_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_CITY_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_CITY_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_CITY_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_CITY_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_COMPANY_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_COMPANY_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_COMPANY_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_COMPANY_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_COMPANY_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_COMPANY_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_COMPANY_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_COMPANY_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_CONTRACT_TYPE_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_CONTRACT_TYPE_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_CONTRACT_TYPE_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_CONTRACT_TYPE_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_DEPARTMENT_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_DEPARTMENT_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_DEPARTMENT_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_DEPARTMENT_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_DEPARTMENT_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_DEPARTMENT_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_DEPARTMENT_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_DEPARTMENT_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_DIVISION_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_DIVISION_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_DIVISION_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_DIVISION_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_DIVISION_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_DIVISION_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_DIVISION_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_DIVISION_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_EMPLOYEE_LEVEL_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_EMPLOYEE_LEVEL_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_EMPLOYEE_LEVEL_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_EMPLOYEE_LEVEL_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_EMPLOYEE_LEVEL_ORDER_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_EMPLOYEE_LEVEL_ORDER_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_EMPLOYEE_LEVEL_ORDER_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_EMPLOYEE_LEVEL_ORDER_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_JOB_TITLE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_JOB_TITLE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_JOB_TITLE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_JOB_TITLE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_OFFICE_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_OFFICE_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_OFFICE_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_OFFICE_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_OFFICE_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_OFFICE_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_OFFICE_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_OFFICE_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_TEAM_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_TEAM_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_TEAM_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PREVIOUS_TEAM_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_TEAM_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_TEAM_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_TEAM_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MAX_TEAM_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_BUSINESS_UNIT_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_BUSINESS_UNIT_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_BUSINESS_UNIT_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_BUSINESS_UNIT_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_CITY_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_CITY_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_CITY_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_CITY_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_CITY_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_CITY_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_CITY_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_CITY_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_COMPANY_EMAIL_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_COMPANY_EMAIL_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_COMPANY_EMAIL_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_COMPANY_EMAIL_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_COMPANY_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_COMPANY_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_COMPANY_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_COMPANY_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_COMPANY_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_COMPANY_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_COMPANY_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_COMPANY_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_CONTRACT_TYPE_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_CONTRACT_TYPE_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_CONTRACT_TYPE_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_CONTRACT_TYPE_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DEPARTMENT_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DEPARTMENT_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DEPARTMENT_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DEPARTMENT_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DEPARTMENT_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DEPARTMENT_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DEPARTMENT_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DEPARTMENT_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DIVISION_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DIVISION_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DIVISION_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DIVISION_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DIVISION_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DIVISION_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DIVISION_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DIVISION_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EFFECTIVE_DATE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EFFECTIVE_DATE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EFFECTIVE_DATE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EFFECTIVE_DATE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_LEVEL_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_LEVEL_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_LEVEL_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_LEVEL_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_LEVEL_ORDER_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_LEVEL_ORDER_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_LEVEL_ORDER_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_LEVEL_ORDER_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_FLAG_DEMOTION_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_FLAG_DEMOTION_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_FLAG_DEMOTION_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_FLAG_DEMOTION_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_FLAG_PROMOTION_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_FLAG_PROMOTION_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_FLAG_PROMOTION_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_FLAG_PROMOTION_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_FLAG_TRANSFER_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_FLAG_TRANSFER_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_FLAG_TRANSFER_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_FLAG_TRANSFER_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_FULL_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_FULL_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_FULL_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_FULL_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_JOB_TITLE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_JOB_TITLE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_JOB_TITLE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_JOB_TITLE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_JOIN_DATE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_JOIN_DATE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_JOIN_DATE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_JOIN_DATE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_NICKNAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_NICKNAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_NICKNAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_NICKNAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_OFFICE_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_OFFICE_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_OFFICE_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_OFFICE_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_OFFICE_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_OFFICE_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_OFFICE_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_OFFICE_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_BUSINESS_UNIT_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_BUSINESS_UNIT_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_BUSINESS_UNIT_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_BUSINESS_UNIT_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_CITY_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_CITY_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_CITY_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_CITY_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_CITY_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_CITY_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_CITY_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_CITY_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_COMPANY_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_COMPANY_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_COMPANY_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_COMPANY_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_COMPANY_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_COMPANY_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_COMPANY_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_COMPANY_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_CONTRACT_TYPE_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_CONTRACT_TYPE_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_CONTRACT_TYPE_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_CONTRACT_TYPE_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_DEPARTMENT_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_DEPARTMENT_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_DEPARTMENT_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_DEPARTMENT_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_DEPARTMENT_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_DEPARTMENT_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_DEPARTMENT_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_DEPARTMENT_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_DIVISION_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_DIVISION_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_DIVISION_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_DIVISION_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_DIVISION_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_DIVISION_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_DIVISION_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_DIVISION_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_EMPLOYEE_LEVEL_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_EMPLOYEE_LEVEL_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_EMPLOYEE_LEVEL_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_EMPLOYEE_LEVEL_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_EMPLOYEE_LEVEL_ORDER_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_EMPLOYEE_LEVEL_ORDER_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_EMPLOYEE_LEVEL_ORDER_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_EMPLOYEE_LEVEL_ORDER_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_JOB_TITLE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_JOB_TITLE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_JOB_TITLE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_JOB_TITLE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_OFFICE_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_OFFICE_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_OFFICE_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_OFFICE_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_OFFICE_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_OFFICE_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_OFFICE_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_OFFICE_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_TEAM_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_TEAM_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_TEAM_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PREVIOUS_TEAM_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_TEAM_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_TEAM_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_TEAM_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_TP_HISTORY_ID__MIN_TEAM_DESC',
  JOB_TITLE_ASC = 'JOB_TITLE_ASC',
  JOB_TITLE_DESC = 'JOB_TITLE_DESC',
  NATURAL = 'NATURAL',
  OFFICE_BY_OFFICE_ID__CITY_ID_ASC = 'OFFICE_BY_OFFICE_ID__CITY_ID_ASC',
  OFFICE_BY_OFFICE_ID__CITY_ID_DESC = 'OFFICE_BY_OFFICE_ID__CITY_ID_DESC',
  OFFICE_BY_OFFICE_ID__DATE_CREATED_ASC = 'OFFICE_BY_OFFICE_ID__DATE_CREATED_ASC',
  OFFICE_BY_OFFICE_ID__DATE_CREATED_DESC = 'OFFICE_BY_OFFICE_ID__DATE_CREATED_DESC',
  OFFICE_BY_OFFICE_ID__DATE_UPDATED_ASC = 'OFFICE_BY_OFFICE_ID__DATE_UPDATED_ASC',
  OFFICE_BY_OFFICE_ID__DATE_UPDATED_DESC = 'OFFICE_BY_OFFICE_ID__DATE_UPDATED_DESC',
  OFFICE_BY_OFFICE_ID__LATITUDE_ASC = 'OFFICE_BY_OFFICE_ID__LATITUDE_ASC',
  OFFICE_BY_OFFICE_ID__LATITUDE_DESC = 'OFFICE_BY_OFFICE_ID__LATITUDE_DESC',
  OFFICE_BY_OFFICE_ID__LONGITUDE_ASC = 'OFFICE_BY_OFFICE_ID__LONGITUDE_ASC',
  OFFICE_BY_OFFICE_ID__LONGITUDE_DESC = 'OFFICE_BY_OFFICE_ID__LONGITUDE_DESC',
  OFFICE_BY_OFFICE_ID__NAME_ASC = 'OFFICE_BY_OFFICE_ID__NAME_ASC',
  OFFICE_BY_OFFICE_ID__NAME_DESC = 'OFFICE_BY_OFFICE_ID__NAME_DESC',
  OFFICE_BY_OFFICE_ID__OFFICE_ID_ASC = 'OFFICE_BY_OFFICE_ID__OFFICE_ID_ASC',
  OFFICE_BY_OFFICE_ID__OFFICE_ID_DESC = 'OFFICE_BY_OFFICE_ID__OFFICE_ID_DESC',
  OFFICE_BY_OFFICE_ID__REMARK_ASC = 'OFFICE_BY_OFFICE_ID__REMARK_ASC',
  OFFICE_BY_OFFICE_ID__REMARK_DESC = 'OFFICE_BY_OFFICE_ID__REMARK_DESC',
  OFFICE_BY_OFFICE_ID__USER_CREATED_ASC = 'OFFICE_BY_OFFICE_ID__USER_CREATED_ASC',
  OFFICE_BY_OFFICE_ID__USER_CREATED_DESC = 'OFFICE_BY_OFFICE_ID__USER_CREATED_DESC',
  OFFICE_BY_OFFICE_ID__USER_UPDATED_ASC = 'OFFICE_BY_OFFICE_ID__USER_UPDATED_ASC',
  OFFICE_BY_OFFICE_ID__USER_UPDATED_DESC = 'OFFICE_BY_OFFICE_ID__USER_UPDATED_DESC',
  OFFICE_ID_ASC = 'OFFICE_ID_ASC',
  OFFICE_ID_DESC = 'OFFICE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_IS_LOCKED_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_IS_LOCKED_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_IS_LOCKED_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_IS_LOCKED_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_IS_LOCKED_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_IS_LOCKED_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_IS_LOCKED_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_IS_LOCKED_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_MID_LONG_TERM_GOAL_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_MID_LONG_TERM_GOAL_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_MID_LONG_TERM_GOAL_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_MID_LONG_TERM_GOAL_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_NEED_INPUT_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_NEED_INPUT_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_NEED_INPUT_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_NEED_INPUT_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_NEED_INPUT_TARGET_KPI_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_NEED_INPUT_TARGET_KPI_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_NEED_INPUT_TARGET_KPI_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_NEED_INPUT_TARGET_KPI_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_NEED_INPUT_TARGET_QUALITATIVE_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_NEED_INPUT_TARGET_QUALITATIVE_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_NEED_INPUT_TARGET_QUALITATIVE_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_NEED_INPUT_TARGET_QUALITATIVE_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_IS_LOCKED_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_IS_LOCKED_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_IS_LOCKED_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_IS_LOCKED_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_IS_LOCKED_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_IS_LOCKED_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_IS_LOCKED_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_IS_LOCKED_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_MID_LONG_TERM_GOAL_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_MID_LONG_TERM_GOAL_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_MID_LONG_TERM_GOAL_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_MID_LONG_TERM_GOAL_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_NEED_INPUT_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_NEED_INPUT_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_NEED_INPUT_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_NEED_INPUT_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_NEED_INPUT_TARGET_KPI_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_NEED_INPUT_TARGET_KPI_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_NEED_INPUT_TARGET_KPI_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_NEED_INPUT_TARGET_KPI_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_NEED_INPUT_TARGET_QUALITATIVE_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_NEED_INPUT_TARGET_QUALITATIVE_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_NEED_INPUT_TARGET_QUALITATIVE_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_NEED_INPUT_TARGET_QUALITATIVE_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_TP_HISTORY_ID__MIN_YEAR_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  TEAM_ASC = 'TEAM_ASC',
  TEAM_DESC = 'TEAM_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface EmployeeTpHistory extends Node {
  __typename?: 'EmployeeTpHistory';
  allEmployeeWithYearQuarters: AllEmployeeWithYearQuartersConnection;
  businessUnit?: Maybe<BusinessUnit>;
  businessUnitId?: Maybe<Scalars['BigInt']['output']>;
  company: Company;
  companyId: Scalars['BigInt']['output'];
  contractType: ContractType;
  contractTypeId: Scalars['BigInt']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  department?: Maybe<Department>;
  departmentId?: Maybe<Scalars['BigInt']['output']>;
  division?: Maybe<Division>;
  divisionId?: Maybe<Scalars['BigInt']['output']>;
  effectiveDate: Scalars['Date']['output'];
  effectiveDateUntil?: Maybe<Scalars['Date']['output']>;
  employee: Employee;
  employeeCode?: Maybe<Scalars['String']['output']>;
  employeeCurrentPositions: EmployeeCurrentPositionsConnection;
  employeeId: Scalars['BigInt']['output'];
  employeeLevel: EmployeeLevel;
  employeeLevelId: Scalars['BigInt']['output'];
  employeeTpHistoryId: Scalars['BigInt']['output'];
  employeeTransferHistories: EmployeeTransferHistoriesConnection;
  jobTitle?: Maybe<Scalars['String']['output']>;
  nodeId: Scalars['ID']['output'];
  office?: Maybe<Office>;
  officeId?: Maybe<Scalars['BigInt']['output']>;
  performanceReviewEvaluations: PerformanceReviewEvaluationsConnection;
  remark?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export type EmployeeTpHistoryAllEmployeeWithYearQuartersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AllEmployeeWithYearQuarterCondition>;
  filter?: InputMaybe<AllEmployeeWithYearQuarterFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AllEmployeeWithYearQuartersOrderBy>>;
};

export type EmployeeTpHistoryEmployeeCurrentPositionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCurrentPositionCondition>;
  filter?: InputMaybe<EmployeeCurrentPositionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeCurrentPositionsOrderBy>>;
};

export type EmployeeTpHistoryEmployeeTransferHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeTransferHistoryCondition>;
  filter?: InputMaybe<EmployeeTransferHistoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeTransferHistoriesOrderBy>>;
};

export type EmployeeTpHistoryPerformanceReviewEvaluationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationsOrderBy>>;
};

export interface EmployeeTpHistoryBusinessUnitIdFkeyBusinessUnitCreateInput {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInverseInput>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BusinessUnitUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeTpHistoryBusinessUnitIdFkeyEmployeeTpHistoryCreateInput {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate: Scalars['Date']['input'];
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeTpHistoryBusinessUnitIdFkeyInput {
  connectByBusinessUnitId?: InputMaybe<BusinessUnitBusinessUnitPkeyConnect>;
  connectByName?: InputMaybe<BusinessUnitBusinessUnitNameKeyConnect>;
  connectByNodeId?: InputMaybe<BusinessUnitNodeIdConnect>;
  create?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyBusinessUnitCreateInput>;
  deleteByBusinessUnitId?: InputMaybe<BusinessUnitBusinessUnitPkeyDelete>;
  deleteByName?: InputMaybe<BusinessUnitBusinessUnitNameKeyDelete>;
  deleteByNodeId?: InputMaybe<BusinessUnitNodeIdDelete>;
  updateByBusinessUnitId?: InputMaybe<BusinessUnitOnEmployeeTpHistoryForEmployeeTpHistoryBusinessUnitIdFkeyUsingBusinessUnitPkeyUpdate>;
  updateByName?: InputMaybe<BusinessUnitOnEmployeeTpHistoryForEmployeeTpHistoryBusinessUnitIdFkeyUsingBusinessUnitNameKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryBusinessUnitIdFkeyNodeIdUpdate>;
}

export interface EmployeeTpHistoryBusinessUnitIdFkeyInverseInput {
  connectByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyConnect>>;
  connectByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeTpHistoryNodeIdConnect>>;
  create?: InputMaybe<Array<EmployeeTpHistoryBusinessUnitIdFkeyEmployeeTpHistoryCreateInput>>;
  deleteByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyDelete>>;
  deleteByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeTpHistoryNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryBusinessUnitIdFkeyUsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate>>;
  updateByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryBusinessUnitIdFkeyUsingEmployeeTpHistoryPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<BusinessUnitOnEmployeeTpHistoryForEmployeeTpHistoryBusinessUnitIdFkeyNodeIdUpdate>>;
}

export interface EmployeeTpHistoryCompanyIdFkeyCompanyCreateInput {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName: Scalars['String']['input'];
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeTpHistoryCompanyIdFkeyEmployeeTpHistoryCreateInput {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate: Scalars['Date']['input'];
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeTpHistoryCompanyIdFkeyInput {
  connectByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyConnect>;
  connectByCompanyId?: InputMaybe<CompanyCompanyPkeyConnect>;
  connectByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyConnect>;
  connectByNodeId?: InputMaybe<CompanyNodeIdConnect>;
  create?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyCompanyCreateInput>;
  deleteByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyDelete>;
  deleteByCompanyId?: InputMaybe<CompanyCompanyPkeyDelete>;
  deleteByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyDelete>;
  deleteByNodeId?: InputMaybe<CompanyNodeIdDelete>;
  updateByAbbreviation?: InputMaybe<CompanyOnEmployeeTpHistoryForEmployeeTpHistoryCompanyIdFkeyUsingCompanyAbbreviationKeyUpdate>;
  updateByCompanyId?: InputMaybe<CompanyOnEmployeeTpHistoryForEmployeeTpHistoryCompanyIdFkeyUsingCompanyPkeyUpdate>;
  updateByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyOnEmployeeTpHistoryForEmployeeTpHistoryCompanyIdFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryCompanyIdFkeyNodeIdUpdate>;
}

export interface EmployeeTpHistoryCompanyIdFkeyInverseInput {
  connectByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyConnect>>;
  connectByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeTpHistoryNodeIdConnect>>;
  create?: InputMaybe<Array<EmployeeTpHistoryCompanyIdFkeyEmployeeTpHistoryCreateInput>>;
  deleteByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyDelete>>;
  deleteByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeTpHistoryNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryCompanyIdFkeyUsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate>>;
  updateByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryCompanyIdFkeyUsingEmployeeTpHistoryPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<CompanyOnEmployeeTpHistoryForEmployeeTpHistoryCompanyIdFkeyNodeIdUpdate>>;
}

export interface EmployeeTpHistoryCondition {
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeTpHistoryContractTypeIdFkeyContractTypeCreateInput {
  code: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey1InverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey2InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableContractTypeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ContractTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeTpHistoryContractTypeIdFkeyEmployeeTpHistoryCreateInput {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate: Scalars['Date']['input'];
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeTpHistoryContractTypeIdFkeyInput {
  connectByCode?: InputMaybe<ContractTypeContractTypeCodeKeyConnect>;
  connectByContractTypeId?: InputMaybe<ContractTypeContractTypePkeyConnect>;
  connectByDisplayName?: InputMaybe<ContractTypeContractTypeDisplayNameKeyConnect>;
  connectByNodeId?: InputMaybe<ContractTypeNodeIdConnect>;
  create?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyContractTypeCreateInput>;
  deleteByCode?: InputMaybe<ContractTypeContractTypeCodeKeyDelete>;
  deleteByContractTypeId?: InputMaybe<ContractTypeContractTypePkeyDelete>;
  deleteByDisplayName?: InputMaybe<ContractTypeContractTypeDisplayNameKeyDelete>;
  deleteByNodeId?: InputMaybe<ContractTypeNodeIdDelete>;
  updateByCode?: InputMaybe<ContractTypeOnEmployeeTpHistoryForEmployeeTpHistoryContractTypeIdFkeyUsingContractTypeCodeKeyUpdate>;
  updateByContractTypeId?: InputMaybe<ContractTypeOnEmployeeTpHistoryForEmployeeTpHistoryContractTypeIdFkeyUsingContractTypePkeyUpdate>;
  updateByDisplayName?: InputMaybe<ContractTypeOnEmployeeTpHistoryForEmployeeTpHistoryContractTypeIdFkeyUsingContractTypeDisplayNameKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryContractTypeIdFkeyNodeIdUpdate>;
}

export interface EmployeeTpHistoryContractTypeIdFkeyInverseInput {
  connectByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyConnect>>;
  connectByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeTpHistoryNodeIdConnect>>;
  create?: InputMaybe<Array<EmployeeTpHistoryContractTypeIdFkeyEmployeeTpHistoryCreateInput>>;
  deleteByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyDelete>>;
  deleteByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeTpHistoryNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryContractTypeIdFkeyUsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate>>;
  updateByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryContractTypeIdFkeyUsingEmployeeTpHistoryPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<ContractTypeOnEmployeeTpHistoryForEmployeeTpHistoryContractTypeIdFkeyNodeIdUpdate>>;
}

export interface EmployeeTpHistoryDepartmentIdFkeyDepartmentCreateInput {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey10InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DepartmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeTpHistoryDepartmentIdFkeyEmployeeTpHistoryCreateInput {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate: Scalars['Date']['input'];
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeTpHistoryDepartmentIdFkeyInput {
  connectByDepartmentId?: InputMaybe<DepartmentDepartmentPkeyConnect>;
  connectByName?: InputMaybe<DepartmentDepartmentNameKeyConnect>;
  connectByNodeId?: InputMaybe<DepartmentNodeIdConnect>;
  create?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyDepartmentCreateInput>;
  deleteByDepartmentId?: InputMaybe<DepartmentDepartmentPkeyDelete>;
  deleteByName?: InputMaybe<DepartmentDepartmentNameKeyDelete>;
  deleteByNodeId?: InputMaybe<DepartmentNodeIdDelete>;
  updateByDepartmentId?: InputMaybe<DepartmentOnEmployeeTpHistoryForEmployeeTpHistoryDepartmentIdFkeyUsingDepartmentPkeyUpdate>;
  updateByName?: InputMaybe<DepartmentOnEmployeeTpHistoryForEmployeeTpHistoryDepartmentIdFkeyUsingDepartmentNameKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryDepartmentIdFkeyNodeIdUpdate>;
}

export interface EmployeeTpHistoryDepartmentIdFkeyInverseInput {
  connectByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyConnect>>;
  connectByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeTpHistoryNodeIdConnect>>;
  create?: InputMaybe<Array<EmployeeTpHistoryDepartmentIdFkeyEmployeeTpHistoryCreateInput>>;
  deleteByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyDelete>>;
  deleteByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeTpHistoryNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryDepartmentIdFkeyUsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate>>;
  updateByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryDepartmentIdFkeyUsingEmployeeTpHistoryPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<DepartmentOnEmployeeTpHistoryForEmployeeTpHistoryDepartmentIdFkeyNodeIdUpdate>>;
}

export interface EmployeeTpHistoryDivisionIdFkeyDivisionCreateInput {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionDivisionIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DivisionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeTpHistoryDivisionIdFkeyEmployeeTpHistoryCreateInput {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  effectiveDate: Scalars['Date']['input'];
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeTpHistoryDivisionIdFkeyInput {
  connectByDivisionId?: InputMaybe<DivisionDivisionPkeyConnect>;
  connectByName?: InputMaybe<DivisionDivisionNameKeyConnect>;
  connectByNodeId?: InputMaybe<DivisionNodeIdConnect>;
  create?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyDivisionCreateInput>;
  deleteByDivisionId?: InputMaybe<DivisionDivisionPkeyDelete>;
  deleteByName?: InputMaybe<DivisionDivisionNameKeyDelete>;
  deleteByNodeId?: InputMaybe<DivisionNodeIdDelete>;
  updateByDivisionId?: InputMaybe<DivisionOnEmployeeTpHistoryForEmployeeTpHistoryDivisionIdFkeyUsingDivisionPkeyUpdate>;
  updateByName?: InputMaybe<DivisionOnEmployeeTpHistoryForEmployeeTpHistoryDivisionIdFkeyUsingDivisionNameKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryDivisionIdFkeyNodeIdUpdate>;
}

export interface EmployeeTpHistoryDivisionIdFkeyInverseInput {
  connectByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyConnect>>;
  connectByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeTpHistoryNodeIdConnect>>;
  create?: InputMaybe<Array<EmployeeTpHistoryDivisionIdFkeyEmployeeTpHistoryCreateInput>>;
  deleteByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyDelete>>;
  deleteByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeTpHistoryNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryDivisionIdFkeyUsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate>>;
  updateByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryDivisionIdFkeyUsingEmployeeTpHistoryPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<DivisionOnEmployeeTpHistoryForEmployeeTpHistoryDivisionIdFkeyNodeIdUpdate>>;
}

export interface EmployeeTpHistoryEmployeeIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeTpHistoryEmployeeIdFkeyEmployeeTpHistoryCreateInput {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate: Scalars['Date']['input'];
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeTpHistoryEmployeeIdFkeyInput {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyEmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeIdFkeyUsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeIdFkeyNodeIdUpdate>;
}

export interface EmployeeTpHistoryEmployeeIdFkeyInverseInput {
  connectByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyConnect>>;
  connectByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeTpHistoryNodeIdConnect>>;
  create?: InputMaybe<Array<EmployeeTpHistoryEmployeeIdFkeyEmployeeTpHistoryCreateInput>>;
  deleteByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyDelete>>;
  deleteByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeTpHistoryNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeIdFkeyUsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate>>;
  updateByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeIdFkeyUsingEmployeeTpHistoryPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<EmployeeOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeIdFkeyNodeIdUpdate>>;
}

export interface EmployeeTpHistoryEmployeeLevelIdFkeyEmployeeLevelCreateInput {
  canEditTargetCountry?: InputMaybe<Scalars['Boolean']['input']>;
  canEditTargetIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  canEditTargetTeam?: InputMaybe<Scalars['Boolean']['input']>;
  canSeePerformanceReviewOverallOfAll?: InputMaybe<Scalars['Boolean']['input']>;
  canSeePerformanceReviewOverallOfCountry?: InputMaybe<Scalars['Boolean']['input']>;
  canSeeProbationReviewOfAll?: InputMaybe<Scalars['Boolean']['input']>;
  canSeeProbationReviewOfCountry?: InputMaybe<Scalars['Boolean']['input']>;
  displayName: Scalars['String']['input'];
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey3InverseInput>;
  employeeLevelOrder: Scalars['Int']['input'];
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInverseInput>;
  performanceReviewMasterWeightingDetails?: InputMaybe<PerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  titleContributor?: InputMaybe<Scalars['String']['input']>;
  titleManager?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeLevelUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeTpHistoryEmployeeLevelIdFkeyEmployeeTpHistoryCreateInput {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate: Scalars['Date']['input'];
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeTpHistoryEmployeeLevelIdFkeyInput {
  connectByDisplayName?: InputMaybe<EmployeeLevelEmployeeLevelDisplayNameKeyConnect>;
  connectByEmployeeLevelId?: InputMaybe<EmployeeLevelEmployeeLevelPkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeLevelNodeIdConnect>;
  create?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyEmployeeLevelCreateInput>;
  deleteByDisplayName?: InputMaybe<EmployeeLevelEmployeeLevelDisplayNameKeyDelete>;
  deleteByEmployeeLevelId?: InputMaybe<EmployeeLevelEmployeeLevelPkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeLevelNodeIdDelete>;
  updateByDisplayName?: InputMaybe<EmployeeLevelOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeLevelIdFkeyUsingEmployeeLevelDisplayNameKeyUpdate>;
  updateByEmployeeLevelId?: InputMaybe<EmployeeLevelOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeLevelIdFkeyUsingEmployeeLevelPkeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeLevelIdFkeyNodeIdUpdate>;
}

export interface EmployeeTpHistoryEmployeeLevelIdFkeyInverseInput {
  connectByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyConnect>>;
  connectByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeTpHistoryNodeIdConnect>>;
  create?: InputMaybe<Array<EmployeeTpHistoryEmployeeLevelIdFkeyEmployeeTpHistoryCreateInput>>;
  deleteByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyDelete>>;
  deleteByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeTpHistoryNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeLevelIdFkeyUsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate>>;
  updateByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeLevelIdFkeyUsingEmployeeTpHistoryPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<EmployeeLevelOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeLevelIdFkeyNodeIdUpdate>>;
}

export interface EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyConnect {
  effectiveDate: Scalars['Date']['input'];
  employeeId: Scalars['BigInt']['input'];
}

export interface EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyDelete {
  effectiveDate: Scalars['Date']['input'];
  employeeId: Scalars['BigInt']['input'];
}

export interface EmployeeTpHistoryEmployeeTpHistoryPkeyConnect {
  employeeTpHistoryId: Scalars['BigInt']['input'];
}

export interface EmployeeTpHistoryEmployeeTpHistoryPkeyDelete {
  employeeTpHistoryId: Scalars['BigInt']['input'];
}

export interface EmployeeTpHistoryFilter {
  allEmployeeWithYearQuarters?: InputMaybe<EmployeeTpHistoryToManyAllEmployeeWithYearQuarterFilter>;
  allEmployeeWithYearQuartersExist?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<EmployeeTpHistoryFilter>>;
  businessUnit?: InputMaybe<BusinessUnitFilter>;
  businessUnitExists?: InputMaybe<Scalars['Boolean']['input']>;
  businessUnitId?: InputMaybe<BigIntFilter>;
  company?: InputMaybe<CompanyFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  contractType?: InputMaybe<ContractTypeFilter>;
  contractTypeId?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  department?: InputMaybe<DepartmentFilter>;
  departmentExists?: InputMaybe<Scalars['Boolean']['input']>;
  departmentId?: InputMaybe<BigIntFilter>;
  division?: InputMaybe<DivisionFilter>;
  divisionExists?: InputMaybe<Scalars['Boolean']['input']>;
  divisionId?: InputMaybe<BigIntFilter>;
  effectiveDate?: InputMaybe<DateFilter>;
  effectiveDateUntil?: InputMaybe<DateFilter>;
  employee?: InputMaybe<EmployeeFilter>;
  employeeCode?: InputMaybe<StringFilter>;
  employeeCurrentPositions?: InputMaybe<EmployeeTpHistoryToManyEmployeeCurrentPositionFilter>;
  employeeCurrentPositionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<BigIntFilter>;
  employeeLevel?: InputMaybe<EmployeeLevelFilter>;
  employeeLevelId?: InputMaybe<BigIntFilter>;
  employeeTpHistoryId?: InputMaybe<BigIntFilter>;
  employeeTransferHistories?: InputMaybe<EmployeeTpHistoryToManyEmployeeTransferHistoryFilter>;
  employeeTransferHistoriesExist?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<StringFilter>;
  not?: InputMaybe<EmployeeTpHistoryFilter>;
  office?: InputMaybe<OfficeFilter>;
  officeExists?: InputMaybe<Scalars['Boolean']['input']>;
  officeId?: InputMaybe<BigIntFilter>;
  or?: InputMaybe<Array<EmployeeTpHistoryFilter>>;
  performanceReviewEvaluations?: InputMaybe<EmployeeTpHistoryToManyPerformanceReviewEvaluationFilter>;
  performanceReviewEvaluationsExist?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<StringFilter>;
  team?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface EmployeeTpHistoryInput {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate: Scalars['Date']['input'];
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeTpHistoryNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeTpHistoryNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeTpHistoryOfficeIdFkeyEmployeeTpHistoryCreateInput {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate: Scalars['Date']['input'];
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeTpHistoryOfficeIdFkeyInput {
  connectByCityIdAndName?: InputMaybe<OfficeOfficeCityIdNameKeyConnect>;
  connectByNodeId?: InputMaybe<OfficeNodeIdConnect>;
  connectByOfficeId?: InputMaybe<OfficeOfficePkeyConnect>;
  create?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyOfficeCreateInput>;
  deleteByCityIdAndName?: InputMaybe<OfficeOfficeCityIdNameKeyDelete>;
  deleteByNodeId?: InputMaybe<OfficeNodeIdDelete>;
  deleteByOfficeId?: InputMaybe<OfficeOfficePkeyDelete>;
  updateByCityIdAndName?: InputMaybe<OfficeOnEmployeeTpHistoryForEmployeeTpHistoryOfficeIdFkeyUsingOfficeCityIdNameKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryOfficeIdFkeyNodeIdUpdate>;
  updateByOfficeId?: InputMaybe<OfficeOnEmployeeTpHistoryForEmployeeTpHistoryOfficeIdFkeyUsingOfficePkeyUpdate>;
}

export interface EmployeeTpHistoryOfficeIdFkeyInverseInput {
  connectByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyConnect>>;
  connectByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeTpHistoryNodeIdConnect>>;
  create?: InputMaybe<Array<EmployeeTpHistoryOfficeIdFkeyEmployeeTpHistoryCreateInput>>;
  deleteByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyDelete>>;
  deleteByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeTpHistoryNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryOfficeIdFkeyUsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate>>;
  updateByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryOfficeIdFkeyUsingEmployeeTpHistoryPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<OfficeOnEmployeeTpHistoryForEmployeeTpHistoryOfficeIdFkeyNodeIdUpdate>>;
}

export interface EmployeeTpHistoryOfficeIdFkeyOfficeCreateInput {
  city?: InputMaybe<OfficeCityIdFkeyInput>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInverseInput>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  officeWeathers?: InputMaybe<OfficeWeatherOfficeIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeOfficeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<OfficeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeTpHistoryOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey1NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeCurrentPositionPatch;
}

export interface EmployeeTpHistoryOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey1UsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate {
  effectiveDate: Scalars['Date']['input'];
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey1Patch;
}

export interface EmployeeTpHistoryOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey1UsingEmployeeTpHistoryPkeyUpdate {
  employeeTpHistoryId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey1Patch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryBusinessUnitIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: BusinessUnitPatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryBusinessUnitIdFkeyUsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate {
  effectiveDate: Scalars['Date']['input'];
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryBusinessUnitIdFkeyPatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryBusinessUnitIdFkeyUsingEmployeeTpHistoryPkeyUpdate {
  employeeTpHistoryId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryBusinessUnitIdFkeyPatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryCompanyIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyPatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryCompanyIdFkeyUsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate {
  effectiveDate: Scalars['Date']['input'];
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryCompanyIdFkeyPatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryCompanyIdFkeyUsingEmployeeTpHistoryPkeyUpdate {
  employeeTpHistoryId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryCompanyIdFkeyPatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryContractTypeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ContractTypePatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryContractTypeIdFkeyUsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate {
  effectiveDate: Scalars['Date']['input'];
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryContractTypeIdFkeyPatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryContractTypeIdFkeyUsingEmployeeTpHistoryPkeyUpdate {
  employeeTpHistoryId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryContractTypeIdFkeyPatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryDepartmentIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: DepartmentPatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryDepartmentIdFkeyUsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate {
  effectiveDate: Scalars['Date']['input'];
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryDepartmentIdFkeyPatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryDepartmentIdFkeyUsingEmployeeTpHistoryPkeyUpdate {
  employeeTpHistoryId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryDepartmentIdFkeyPatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryDivisionIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: DivisionPatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryDivisionIdFkeyUsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate {
  effectiveDate: Scalars['Date']['input'];
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryDivisionIdFkeyPatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryDivisionIdFkeyUsingEmployeeTpHistoryPkeyUpdate {
  employeeTpHistoryId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryDivisionIdFkeyPatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeIdFkeyUsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate {
  effectiveDate: Scalars['Date']['input'];
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeIdFkeyPatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeIdFkeyUsingEmployeeTpHistoryPkeyUpdate {
  employeeTpHistoryId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeIdFkeyPatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeLevelIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeLevelPatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeLevelIdFkeyUsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate {
  effectiveDate: Scalars['Date']['input'];
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeLevelIdFkeyPatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeLevelIdFkeyUsingEmployeeTpHistoryPkeyUpdate {
  employeeTpHistoryId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeLevelIdFkeyPatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryOfficeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: OfficePatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryOfficeIdFkeyUsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate {
  effectiveDate: Scalars['Date']['input'];
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryOfficeIdFkeyPatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryOfficeIdFkeyUsingEmployeeTpHistoryPkeyUpdate {
  employeeTpHistoryId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryOfficeIdFkeyPatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryUserCreatedFkeyUsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate {
  effectiveDate: Scalars['Date']['input'];
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryUserCreatedFkeyPatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryUserCreatedFkeyUsingEmployeeTpHistoryPkeyUpdate {
  employeeTpHistoryId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryUserCreatedFkeyPatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryUserUpdatedFkeyUsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate {
  effectiveDate: Scalars['Date']['input'];
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryUserUpdatedFkeyPatch;
}

export interface EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryUserUpdatedFkeyUsingEmployeeTpHistoryPkeyUpdate {
  employeeTpHistoryId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryUserUpdatedFkeyPatch;
}

export interface EmployeeTpHistoryOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey1NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeTransferHistoryPatch;
}

export interface EmployeeTpHistoryOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey1UsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate {
  effectiveDate: Scalars['Date']['input'];
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey1Patch;
}

export interface EmployeeTpHistoryOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey1UsingEmployeeTpHistoryPkeyUpdate {
  employeeTpHistoryId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey1Patch;
}

export interface EmployeeTpHistoryOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeTpHistoryIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationPatch;
}

export interface EmployeeTpHistoryOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeTpHistoryIdFkeyUsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate {
  effectiveDate: Scalars['Date']['input'];
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeTpHistoryIdFkeyPatch;
}

export interface EmployeeTpHistoryOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeTpHistoryIdFkeyUsingEmployeeTpHistoryPkeyUpdate {
  employeeTpHistoryId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTpHistoryOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeTpHistoryIdFkeyPatch;
}

export interface EmployeeTpHistoryPatch {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeTpHistoryToManyAllEmployeeWithYearQuarterFilter {
  every?: InputMaybe<AllEmployeeWithYearQuarterFilter>;
  none?: InputMaybe<AllEmployeeWithYearQuarterFilter>;
  some?: InputMaybe<AllEmployeeWithYearQuarterFilter>;
}

export interface EmployeeTpHistoryToManyEmployeeCurrentPositionFilter {
  every?: InputMaybe<EmployeeCurrentPositionFilter>;
  none?: InputMaybe<EmployeeCurrentPositionFilter>;
  some?: InputMaybe<EmployeeCurrentPositionFilter>;
}

export interface EmployeeTpHistoryToManyEmployeeTransferHistoryFilter {
  every?: InputMaybe<EmployeeTransferHistoryFilter>;
  none?: InputMaybe<EmployeeTransferHistoryFilter>;
  some?: InputMaybe<EmployeeTransferHistoryFilter>;
}

export interface EmployeeTpHistoryToManyPerformanceReviewEvaluationFilter {
  every?: InputMaybe<PerformanceReviewEvaluationFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationFilter>;
}

export interface EmployeeTpHistoryUserCreatedFkeyInverseInput {
  connectByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyConnect>>;
  connectByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeTpHistoryNodeIdConnect>>;
  deleteByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyDelete>>;
  deleteByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeTpHistoryNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryUserCreatedFkeyUsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate>>;
  updateByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryUserCreatedFkeyUsingEmployeeTpHistoryPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmployeeTpHistoryForEmployeeTpHistoryUserCreatedFkeyNodeIdUpdate>>;
}

export interface EmployeeTpHistoryUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnEmployeeTpHistoryForEmployeeTpHistoryUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnEmployeeTpHistoryForEmployeeTpHistoryUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface EmployeeTpHistoryUserUpdatedFkeyInverseInput {
  connectByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyConnect>>;
  connectByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeTpHistoryNodeIdConnect>>;
  deleteByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyDelete>>;
  deleteByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryEmployeeTpHistoryPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeTpHistoryNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryUserUpdatedFkeyUsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate>>;
  updateByEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryUserUpdatedFkeyUsingEmployeeTpHistoryPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmployeeTpHistoryForEmployeeTpHistoryUserUpdatedFkeyNodeIdUpdate>>;
}

export interface EmployeeTransferHistoriesConnection {
  __typename?: 'EmployeeTransferHistoriesConnection';
  edges: Array<EmployeeTransferHistoriesEdge>;
  nodes: Array<EmployeeTransferHistory>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeTransferHistoriesEdge {
  __typename?: 'EmployeeTransferHistoriesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeTransferHistory;
}

export enum EmployeeTransferHistoriesOrderBy {
  BUSINESS_UNIT_ID_ASC = 'BUSINESS_UNIT_ID_ASC',
  BUSINESS_UNIT_ID_DESC = 'BUSINESS_UNIT_ID_DESC',
  BUSINESS_UNIT_NAME_ASC = 'BUSINESS_UNIT_NAME_ASC',
  BUSINESS_UNIT_NAME_DESC = 'BUSINESS_UNIT_NAME_DESC',
  CITY_ID_ASC = 'CITY_ID_ASC',
  CITY_ID_DESC = 'CITY_ID_DESC',
  CITY_NAME_ASC = 'CITY_NAME_ASC',
  CITY_NAME_DESC = 'CITY_NAME_DESC',
  COMPANY_EMAIL_ASC = 'COMPANY_EMAIL_ASC',
  COMPANY_EMAIL_DESC = 'COMPANY_EMAIL_DESC',
  COMPANY_ID_ASC = 'COMPANY_ID_ASC',
  COMPANY_ID_DESC = 'COMPANY_ID_DESC',
  COMPANY_NAME_ASC = 'COMPANY_NAME_ASC',
  COMPANY_NAME_DESC = 'COMPANY_NAME_DESC',
  CONTRACT_TYPE_ID_ASC = 'CONTRACT_TYPE_ID_ASC',
  CONTRACT_TYPE_ID_DESC = 'CONTRACT_TYPE_ID_DESC',
  CONTRACT_TYPE_NAME_ASC = 'CONTRACT_TYPE_NAME_ASC',
  CONTRACT_TYPE_NAME_DESC = 'CONTRACT_TYPE_NAME_DESC',
  COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_CODE_ALPHA2_DESC',
  DEPARTMENT_ID_ASC = 'DEPARTMENT_ID_ASC',
  DEPARTMENT_ID_DESC = 'DEPARTMENT_ID_DESC',
  DEPARTMENT_NAME_ASC = 'DEPARTMENT_NAME_ASC',
  DEPARTMENT_NAME_DESC = 'DEPARTMENT_NAME_DESC',
  DIVISION_ID_ASC = 'DIVISION_ID_ASC',
  DIVISION_ID_DESC = 'DIVISION_ID_DESC',
  DIVISION_NAME_ASC = 'DIVISION_NAME_ASC',
  DIVISION_NAME_DESC = 'DIVISION_NAME_DESC',
  EFFECTIVE_DATE_ASC = 'EFFECTIVE_DATE_ASC',
  EFFECTIVE_DATE_DESC = 'EFFECTIVE_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_CODE_ASC = 'EMPLOYEE_CODE_ASC',
  EMPLOYEE_CODE_DESC = 'EMPLOYEE_CODE_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_LEVEL_NAME_ASC = 'EMPLOYEE_LEVEL_NAME_ASC',
  EMPLOYEE_LEVEL_NAME_DESC = 'EMPLOYEE_LEVEL_NAME_DESC',
  EMPLOYEE_LEVEL_ORDER_ASC = 'EMPLOYEE_LEVEL_ORDER_ASC',
  EMPLOYEE_LEVEL_ORDER_DESC = 'EMPLOYEE_LEVEL_ORDER_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__COMPANY_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__COMPANY_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__COMPANY_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__COMPANY_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_CREATED_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_CREATED_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_CREATED_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_CREATED_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_UPDATED_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_UPDATED_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_UPDATED_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_UPDATED_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DEPARTMENT_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DEPARTMENT_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DEPARTMENT_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DEPARTMENT_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DIVISION_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DIVISION_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DIVISION_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DIVISION_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_CODE_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_CODE_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_CODE_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_CODE_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__JOB_TITLE_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__JOB_TITLE_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__JOB_TITLE_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__JOB_TITLE_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__OFFICE_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__OFFICE_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__OFFICE_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__OFFICE_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__REMARK_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__REMARK_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__REMARK_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__REMARK_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__TEAM_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__TEAM_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__TEAM_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__TEAM_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_CREATED_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_CREATED_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_CREATED_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_CREATED_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_UPDATED_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_UPDATED_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_UPDATED_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_UPDATED_DESC',
  EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORY_ID_DESC',
  FLAG_DEMOTION_ASC = 'FLAG_DEMOTION_ASC',
  FLAG_DEMOTION_DESC = 'FLAG_DEMOTION_DESC',
  FLAG_PROMOTION_ASC = 'FLAG_PROMOTION_ASC',
  FLAG_PROMOTION_DESC = 'FLAG_PROMOTION_DESC',
  FLAG_TRANSFER_ASC = 'FLAG_TRANSFER_ASC',
  FLAG_TRANSFER_DESC = 'FLAG_TRANSFER_DESC',
  FULL_NAME_ASC = 'FULL_NAME_ASC',
  FULL_NAME_DESC = 'FULL_NAME_DESC',
  FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  JOB_TITLE_ASC = 'JOB_TITLE_ASC',
  JOB_TITLE_DESC = 'JOB_TITLE_DESC',
  JOIN_DATE_ASC = 'JOIN_DATE_ASC',
  JOIN_DATE_DESC = 'JOIN_DATE_DESC',
  NATURAL = 'NATURAL',
  NICKNAME_ASC = 'NICKNAME_ASC',
  NICKNAME_DESC = 'NICKNAME_DESC',
  OFFICE_ID_ASC = 'OFFICE_ID_ASC',
  OFFICE_ID_DESC = 'OFFICE_ID_DESC',
  OFFICE_NAME_ASC = 'OFFICE_NAME_ASC',
  OFFICE_NAME_DESC = 'OFFICE_NAME_DESC',
  PREVIOUS_BUSINESS_UNIT_ID_ASC = 'PREVIOUS_BUSINESS_UNIT_ID_ASC',
  PREVIOUS_BUSINESS_UNIT_ID_DESC = 'PREVIOUS_BUSINESS_UNIT_ID_DESC',
  PREVIOUS_BUSINESS_UNIT_NAME_ASC = 'PREVIOUS_BUSINESS_UNIT_NAME_ASC',
  PREVIOUS_BUSINESS_UNIT_NAME_DESC = 'PREVIOUS_BUSINESS_UNIT_NAME_DESC',
  PREVIOUS_CITY_ID_ASC = 'PREVIOUS_CITY_ID_ASC',
  PREVIOUS_CITY_ID_DESC = 'PREVIOUS_CITY_ID_DESC',
  PREVIOUS_CITY_NAME_ASC = 'PREVIOUS_CITY_NAME_ASC',
  PREVIOUS_CITY_NAME_DESC = 'PREVIOUS_CITY_NAME_DESC',
  PREVIOUS_COMPANY_ID_ASC = 'PREVIOUS_COMPANY_ID_ASC',
  PREVIOUS_COMPANY_ID_DESC = 'PREVIOUS_COMPANY_ID_DESC',
  PREVIOUS_COMPANY_NAME_ASC = 'PREVIOUS_COMPANY_NAME_ASC',
  PREVIOUS_COMPANY_NAME_DESC = 'PREVIOUS_COMPANY_NAME_DESC',
  PREVIOUS_CONTRACT_TYPE_ID_ASC = 'PREVIOUS_CONTRACT_TYPE_ID_ASC',
  PREVIOUS_CONTRACT_TYPE_ID_DESC = 'PREVIOUS_CONTRACT_TYPE_ID_DESC',
  PREVIOUS_CONTRACT_TYPE_NAME_ASC = 'PREVIOUS_CONTRACT_TYPE_NAME_ASC',
  PREVIOUS_CONTRACT_TYPE_NAME_DESC = 'PREVIOUS_CONTRACT_TYPE_NAME_DESC',
  PREVIOUS_COUNTRY_CODE_ALPHA2_ASC = 'PREVIOUS_COUNTRY_CODE_ALPHA2_ASC',
  PREVIOUS_COUNTRY_CODE_ALPHA2_DESC = 'PREVIOUS_COUNTRY_CODE_ALPHA2_DESC',
  PREVIOUS_DEPARTMENT_ID_ASC = 'PREVIOUS_DEPARTMENT_ID_ASC',
  PREVIOUS_DEPARTMENT_ID_DESC = 'PREVIOUS_DEPARTMENT_ID_DESC',
  PREVIOUS_DEPARTMENT_NAME_ASC = 'PREVIOUS_DEPARTMENT_NAME_ASC',
  PREVIOUS_DEPARTMENT_NAME_DESC = 'PREVIOUS_DEPARTMENT_NAME_DESC',
  PREVIOUS_DIVISION_ID_ASC = 'PREVIOUS_DIVISION_ID_ASC',
  PREVIOUS_DIVISION_ID_DESC = 'PREVIOUS_DIVISION_ID_DESC',
  PREVIOUS_DIVISION_NAME_ASC = 'PREVIOUS_DIVISION_NAME_ASC',
  PREVIOUS_DIVISION_NAME_DESC = 'PREVIOUS_DIVISION_NAME_DESC',
  PREVIOUS_EMPLOYEE_CODE_ASC = 'PREVIOUS_EMPLOYEE_CODE_ASC',
  PREVIOUS_EMPLOYEE_CODE_DESC = 'PREVIOUS_EMPLOYEE_CODE_DESC',
  PREVIOUS_EMPLOYEE_LEVEL_ID_ASC = 'PREVIOUS_EMPLOYEE_LEVEL_ID_ASC',
  PREVIOUS_EMPLOYEE_LEVEL_ID_DESC = 'PREVIOUS_EMPLOYEE_LEVEL_ID_DESC',
  PREVIOUS_EMPLOYEE_LEVEL_NAME_ASC = 'PREVIOUS_EMPLOYEE_LEVEL_NAME_ASC',
  PREVIOUS_EMPLOYEE_LEVEL_NAME_DESC = 'PREVIOUS_EMPLOYEE_LEVEL_NAME_DESC',
  PREVIOUS_EMPLOYEE_LEVEL_ORDER_ASC = 'PREVIOUS_EMPLOYEE_LEVEL_ORDER_ASC',
  PREVIOUS_EMPLOYEE_LEVEL_ORDER_DESC = 'PREVIOUS_EMPLOYEE_LEVEL_ORDER_DESC',
  PREVIOUS_JOB_TITLE_ASC = 'PREVIOUS_JOB_TITLE_ASC',
  PREVIOUS_JOB_TITLE_DESC = 'PREVIOUS_JOB_TITLE_DESC',
  PREVIOUS_OFFICE_ID_ASC = 'PREVIOUS_OFFICE_ID_ASC',
  PREVIOUS_OFFICE_ID_DESC = 'PREVIOUS_OFFICE_ID_DESC',
  PREVIOUS_OFFICE_NAME_ASC = 'PREVIOUS_OFFICE_NAME_ASC',
  PREVIOUS_OFFICE_NAME_DESC = 'PREVIOUS_OFFICE_NAME_DESC',
  PREVIOUS_TEAM_ASC = 'PREVIOUS_TEAM_ASC',
  PREVIOUS_TEAM_DESC = 'PREVIOUS_TEAM_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  TEAM_ASC = 'TEAM_ASC',
  TEAM_DESC = 'TEAM_DESC',
}

export interface EmployeeTransferHistory extends Node {
  __typename?: 'EmployeeTransferHistory';
  businessUnitId?: Maybe<Scalars['BigInt']['output']>;
  businessUnitName?: Maybe<Scalars['String']['output']>;
  cityId?: Maybe<Scalars['BigInt']['output']>;
  cityName?: Maybe<Scalars['String']['output']>;
  companyEmail?: Maybe<Scalars['String']['output']>;
  companyId: Scalars['BigInt']['output'];
  companyName: Scalars['String']['output'];
  contractTypeId: Scalars['BigInt']['output'];
  contractTypeName: Scalars['String']['output'];
  countryCodeAlpha2: Scalars['String']['output'];
  departmentId?: Maybe<Scalars['BigInt']['output']>;
  departmentName?: Maybe<Scalars['String']['output']>;
  divisionId?: Maybe<Scalars['BigInt']['output']>;
  divisionName?: Maybe<Scalars['String']['output']>;
  effectiveDate: Scalars['Date']['output'];
  employee: Employee;
  employeeCode?: Maybe<Scalars['String']['output']>;
  employeeId: Scalars['BigInt']['output'];
  employeeLevelId: Scalars['BigInt']['output'];
  employeeLevelName: Scalars['String']['output'];
  employeeLevelOrder: Scalars['Int']['output'];
  employeeTpHistory: EmployeeTpHistory;
  employeeTpHistoryId: Scalars['BigInt']['output'];
  flagDemotion: Scalars['Boolean']['output'];
  flagPromotion: Scalars['Boolean']['output'];
  flagTransfer: Scalars['Boolean']['output'];
  fullName: Scalars['String']['output'];
  fullNameInLocalLanguage?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  joinDate: Scalars['Date']['output'];
  nickname?: Maybe<Scalars['String']['output']>;
  nodeId: Scalars['ID']['output'];
  officeId?: Maybe<Scalars['BigInt']['output']>;
  officeName?: Maybe<Scalars['String']['output']>;
  previousBusinessUnitId?: Maybe<Scalars['BigInt']['output']>;
  previousBusinessUnitName?: Maybe<Scalars['String']['output']>;
  previousCityId?: Maybe<Scalars['BigInt']['output']>;
  previousCityName?: Maybe<Scalars['String']['output']>;
  previousCompanyId: Scalars['BigInt']['output'];
  previousCompanyName: Scalars['String']['output'];
  previousContractTypeId: Scalars['BigInt']['output'];
  previousContractTypeName: Scalars['String']['output'];
  previousCountryCodeAlpha2: Scalars['String']['output'];
  previousDepartmentId?: Maybe<Scalars['BigInt']['output']>;
  previousDepartmentName?: Maybe<Scalars['String']['output']>;
  previousDivisionId?: Maybe<Scalars['BigInt']['output']>;
  previousDivisionName?: Maybe<Scalars['String']['output']>;
  previousEmployeeCode?: Maybe<Scalars['String']['output']>;
  previousEmployeeLevelId: Scalars['BigInt']['output'];
  previousEmployeeLevelName: Scalars['String']['output'];
  previousEmployeeLevelOrder: Scalars['Int']['output'];
  previousJobTitle?: Maybe<Scalars['String']['output']>;
  previousOfficeId?: Maybe<Scalars['BigInt']['output']>;
  previousOfficeName?: Maybe<Scalars['String']['output']>;
  previousTeam?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
}

export interface EmployeeTransferHistoryCondition {
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  businessUnitName?: InputMaybe<Scalars['String']['input']>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  cityName?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  contractTypeName?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  departmentName?: InputMaybe<Scalars['String']['input']>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  divisionName?: InputMaybe<Scalars['String']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevelName?: InputMaybe<Scalars['String']['input']>;
  employeeLevelOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  flagDemotion?: InputMaybe<Scalars['Boolean']['input']>;
  flagPromotion?: InputMaybe<Scalars['Boolean']['input']>;
  flagTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  officeName?: InputMaybe<Scalars['String']['input']>;
  previousBusinessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  previousBusinessUnitName?: InputMaybe<Scalars['String']['input']>;
  previousCityId?: InputMaybe<Scalars['BigInt']['input']>;
  previousCityName?: InputMaybe<Scalars['String']['input']>;
  previousCompanyId?: InputMaybe<Scalars['BigInt']['input']>;
  previousCompanyName?: InputMaybe<Scalars['String']['input']>;
  previousContractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  previousContractTypeName?: InputMaybe<Scalars['String']['input']>;
  previousCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  previousDepartmentId?: InputMaybe<Scalars['BigInt']['input']>;
  previousDepartmentName?: InputMaybe<Scalars['String']['input']>;
  previousDivisionId?: InputMaybe<Scalars['BigInt']['input']>;
  previousDivisionName?: InputMaybe<Scalars['String']['input']>;
  previousEmployeeCode?: InputMaybe<Scalars['String']['input']>;
  previousEmployeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  previousEmployeeLevelName?: InputMaybe<Scalars['String']['input']>;
  previousEmployeeLevelOrder?: InputMaybe<Scalars['Int']['input']>;
  previousJobTitle?: InputMaybe<Scalars['String']['input']>;
  previousOfficeId?: InputMaybe<Scalars['BigInt']['input']>;
  previousOfficeName?: InputMaybe<Scalars['String']['input']>;
  previousTeam?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeTransferHistoryFakePublicEmployeeTransferHistoryPrimaryKeyConnect {
  employeeId: Scalars['BigInt']['input'];
  employeeTpHistoryId: Scalars['BigInt']['input'];
}

export interface EmployeeTransferHistoryFilter {
  and?: InputMaybe<Array<EmployeeTransferHistoryFilter>>;
  businessUnitId?: InputMaybe<BigIntFilter>;
  businessUnitName?: InputMaybe<StringFilter>;
  cityId?: InputMaybe<BigIntFilter>;
  cityName?: InputMaybe<StringFilter>;
  companyEmail?: InputMaybe<StringFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  companyName?: InputMaybe<StringFilter>;
  contractTypeId?: InputMaybe<BigIntFilter>;
  contractTypeName?: InputMaybe<StringFilter>;
  countryCodeAlpha2?: InputMaybe<StringFilter>;
  departmentId?: InputMaybe<BigIntFilter>;
  departmentName?: InputMaybe<StringFilter>;
  divisionId?: InputMaybe<BigIntFilter>;
  divisionName?: InputMaybe<StringFilter>;
  effectiveDate?: InputMaybe<DateFilter>;
  employee?: InputMaybe<EmployeeFilter>;
  employeeCode?: InputMaybe<StringFilter>;
  employeeExists?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<BigIntFilter>;
  employeeLevelId?: InputMaybe<BigIntFilter>;
  employeeLevelName?: InputMaybe<StringFilter>;
  employeeLevelOrder?: InputMaybe<IntFilter>;
  employeeTpHistory?: InputMaybe<EmployeeTpHistoryFilter>;
  employeeTpHistoryExists?: InputMaybe<Scalars['Boolean']['input']>;
  employeeTpHistoryId?: InputMaybe<BigIntFilter>;
  flagDemotion?: InputMaybe<BooleanFilter>;
  flagPromotion?: InputMaybe<BooleanFilter>;
  flagTransfer?: InputMaybe<BooleanFilter>;
  fullName?: InputMaybe<StringFilter>;
  fullNameInLocalLanguage?: InputMaybe<StringFilter>;
  jobTitle?: InputMaybe<StringFilter>;
  joinDate?: InputMaybe<DateFilter>;
  nickname?: InputMaybe<StringFilter>;
  not?: InputMaybe<EmployeeTransferHistoryFilter>;
  officeId?: InputMaybe<BigIntFilter>;
  officeName?: InputMaybe<StringFilter>;
  or?: InputMaybe<Array<EmployeeTransferHistoryFilter>>;
  previousBusinessUnitId?: InputMaybe<BigIntFilter>;
  previousBusinessUnitName?: InputMaybe<StringFilter>;
  previousCityId?: InputMaybe<BigIntFilter>;
  previousCityName?: InputMaybe<StringFilter>;
  previousCompanyId?: InputMaybe<BigIntFilter>;
  previousCompanyName?: InputMaybe<StringFilter>;
  previousContractTypeId?: InputMaybe<BigIntFilter>;
  previousContractTypeName?: InputMaybe<StringFilter>;
  previousCountryCodeAlpha2?: InputMaybe<StringFilter>;
  previousDepartmentId?: InputMaybe<BigIntFilter>;
  previousDepartmentName?: InputMaybe<StringFilter>;
  previousDivisionId?: InputMaybe<BigIntFilter>;
  previousDivisionName?: InputMaybe<StringFilter>;
  previousEmployeeCode?: InputMaybe<StringFilter>;
  previousEmployeeLevelId?: InputMaybe<BigIntFilter>;
  previousEmployeeLevelName?: InputMaybe<StringFilter>;
  previousEmployeeLevelOrder?: InputMaybe<IntFilter>;
  previousJobTitle?: InputMaybe<StringFilter>;
  previousOfficeId?: InputMaybe<BigIntFilter>;
  previousOfficeName?: InputMaybe<StringFilter>;
  previousTeam?: InputMaybe<StringFilter>;
  team?: InputMaybe<StringFilter>;
}

export interface EmployeeTransferHistoryNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeTransferHistoryOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey0NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface EmployeeTransferHistoryOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey0UsingFakePublicEmployeeTransferHistoryPrimaryKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  employeeTpHistoryId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTransferHistoryOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey0Patch;
}

export interface EmployeeTransferHistoryOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey1NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeTpHistoryPatch;
}

export interface EmployeeTransferHistoryOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey1UsingFakePublicEmployeeTransferHistoryPrimaryKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  employeeTpHistoryId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeTransferHistoryOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey1Patch;
}

export interface EmployeeTransferHistoryPatch {
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  businessUnitName?: InputMaybe<Scalars['String']['input']>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  cityName?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  contractTypeName?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  departmentName?: InputMaybe<Scalars['String']['input']>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  divisionName?: InputMaybe<Scalars['String']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0Input>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevelName?: InputMaybe<Scalars['String']['input']>;
  employeeLevelOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeTpHistory?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1Input>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  flagDemotion?: InputMaybe<Scalars['Boolean']['input']>;
  flagPromotion?: InputMaybe<Scalars['Boolean']['input']>;
  flagTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  officeName?: InputMaybe<Scalars['String']['input']>;
  previousBusinessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  previousBusinessUnitName?: InputMaybe<Scalars['String']['input']>;
  previousCityId?: InputMaybe<Scalars['BigInt']['input']>;
  previousCityName?: InputMaybe<Scalars['String']['input']>;
  previousCompanyId?: InputMaybe<Scalars['BigInt']['input']>;
  previousCompanyName?: InputMaybe<Scalars['String']['input']>;
  previousContractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  previousContractTypeName?: InputMaybe<Scalars['String']['input']>;
  previousCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  previousDepartmentId?: InputMaybe<Scalars['BigInt']['input']>;
  previousDepartmentName?: InputMaybe<Scalars['String']['input']>;
  previousDivisionId?: InputMaybe<Scalars['BigInt']['input']>;
  previousDivisionName?: InputMaybe<Scalars['String']['input']>;
  previousEmployeeCode?: InputMaybe<Scalars['String']['input']>;
  previousEmployeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  previousEmployeeLevelName?: InputMaybe<Scalars['String']['input']>;
  previousEmployeeLevelOrder?: InputMaybe<Scalars['Int']['input']>;
  previousJobTitle?: InputMaybe<Scalars['String']['input']>;
  previousOfficeId?: InputMaybe<Scalars['BigInt']['input']>;
  previousOfficeName?: InputMaybe<Scalars['String']['input']>;
  previousTeam?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeUserCreatedFkeyInverseInput {
  connectByCompanyEmail?: InputMaybe<Array<EmployeeEmployeeCompanyEmailKeyConnect>>;
  connectByEmployeeId?: InputMaybe<Array<EmployeeEmployeePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeNodeIdConnect>>;
  deleteByCompanyEmail?: InputMaybe<Array<EmployeeEmployeeCompanyEmailKeyDelete>>;
  deleteByEmployeeId?: InputMaybe<Array<EmployeeEmployeePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyEmail?: InputMaybe<Array<EmployeeOnEmployeeForEmployeeUserCreatedFkeyUsingEmployeeCompanyEmailKeyUpdate>>;
  updateByEmployeeId?: InputMaybe<Array<EmployeeOnEmployeeForEmployeeUserCreatedFkeyUsingEmployeePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmployeeForEmployeeUserCreatedFkeyNodeIdUpdate>>;
}

export interface EmployeeUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnEmployeeForEmployeeUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeOnEmployeeForEmployeeUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnEmployeeForEmployeeUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface EmployeeUserUpdatedFkeyInverseInput {
  connectByCompanyEmail?: InputMaybe<Array<EmployeeEmployeeCompanyEmailKeyConnect>>;
  connectByEmployeeId?: InputMaybe<Array<EmployeeEmployeePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeNodeIdConnect>>;
  deleteByCompanyEmail?: InputMaybe<Array<EmployeeEmployeeCompanyEmailKeyDelete>>;
  deleteByEmployeeId?: InputMaybe<Array<EmployeeEmployeePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyEmail?: InputMaybe<Array<EmployeeOnEmployeeForEmployeeUserUpdatedFkeyUsingEmployeeCompanyEmailKeyUpdate>>;
  updateByEmployeeId?: InputMaybe<Array<EmployeeOnEmployeeForEmployeeUserUpdatedFkeyUsingEmployeePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnEmployeeForEmployeeUserUpdatedFkeyNodeIdUpdate>>;
}

export interface EmployeeWorkingDayStatisticsAll extends Node {
  __typename?: 'EmployeeWorkingDayStatisticsAll';
  allDates: Array<Maybe<EmployeeWorkingDayStatisticsRecordAllDate>>;
  baseDate?: Maybe<Scalars['Date']['output']>;
  company?: Maybe<Company>;
  companyCountryCodeAlpha2: Scalars['String']['output'];
  companyEmail: Scalars['String']['output'];
  companyId: Scalars['BigInt']['output'];
  contractType?: Maybe<ContractType>;
  contractTypeId: Scalars['BigInt']['output'];
  employee: Employee;
  employeeId: Scalars['BigInt']['output'];
  nodeId: Scalars['ID']['output'];
  rank: Scalars['Int']['output'];
  status: EmployeeStatusKey;
  workingDays: Scalars['Int']['output'];
}

export interface EmployeeWorkingDayStatisticsAllCondition {
  allDates?: InputMaybe<Array<InputMaybe<EmployeeWorkingDayStatisticsRecordAllDateInput>>>;
  baseDate?: InputMaybe<Scalars['Date']['input']>;
  companyCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  workingDays?: InputMaybe<Scalars['Int']['input']>;
}

export interface EmployeeWorkingDayStatisticsAllFakePublicEmployeeWorkingDayStatisticsAllPrimaryKeyConnect {
  employeeId: Scalars['BigInt']['input'];
}

export interface EmployeeWorkingDayStatisticsAllFilter {
  and?: InputMaybe<Array<EmployeeWorkingDayStatisticsAllFilter>>;
  baseDate?: InputMaybe<DateFilter>;
  company?: InputMaybe<CompanyFilter>;
  companyCountryCodeAlpha2?: InputMaybe<StringFilter>;
  companyEmail?: InputMaybe<StringFilter>;
  companyExists?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<BigIntFilter>;
  contractType?: InputMaybe<ContractTypeFilter>;
  contractTypeExists?: InputMaybe<Scalars['Boolean']['input']>;
  contractTypeId?: InputMaybe<BigIntFilter>;
  employee?: InputMaybe<EmployeeFilter>;
  employeeExists?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<BigIntFilter>;
  not?: InputMaybe<EmployeeWorkingDayStatisticsAllFilter>;
  or?: InputMaybe<Array<EmployeeWorkingDayStatisticsAllFilter>>;
  rank?: InputMaybe<IntFilter>;
  status?: InputMaybe<EmployeeStatusKeyFilter>;
  workingDays?: InputMaybe<IntFilter>;
}

export interface EmployeeWorkingDayStatisticsAllNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface EmployeeWorkingDayStatisticsAllOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey0NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface EmployeeWorkingDayStatisticsAllOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey0UsingFakePublicEmployeeWorkingDayStatisticsAllPrimaryKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeWorkingDayStatisticsAllOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey0Patch;
}

export interface EmployeeWorkingDayStatisticsAllOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey1NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ContractTypePatch;
}

export interface EmployeeWorkingDayStatisticsAllOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey1UsingFakePublicEmployeeWorkingDayStatisticsAllPrimaryKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeWorkingDayStatisticsAllOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey1Patch;
}

export interface EmployeeWorkingDayStatisticsAllOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey2NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyPatch;
}

export interface EmployeeWorkingDayStatisticsAllOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey2UsingFakePublicEmployeeWorkingDayStatisticsAllPrimaryKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateEmployeeWorkingDayStatisticsAllOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey2Patch;
}

export interface EmployeeWorkingDayStatisticsAllPatch {
  allDates?: InputMaybe<Array<InputMaybe<EmployeeWorkingDayStatisticsRecordAllDateInput>>>;
  baseDate?: InputMaybe<Scalars['Date']['input']>;
  company?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2Input>;
  companyCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey1Input>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0Input>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  workingDays?: InputMaybe<Scalars['Int']['input']>;
}

export interface EmployeeWorkingDayStatisticsAllsConnection {
  __typename?: 'EmployeeWorkingDayStatisticsAllsConnection';
  edges: Array<EmployeeWorkingDayStatisticsAllsEdge>;
  nodes: Array<EmployeeWorkingDayStatisticsAll>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeWorkingDayStatisticsAllsEdge {
  __typename?: 'EmployeeWorkingDayStatisticsAllsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeWorkingDayStatisticsAll;
}

export enum EmployeeWorkingDayStatisticsAllsOrderBy {
  ALL_DATES_ASC = 'ALL_DATES_ASC',
  ALL_DATES_DESC = 'ALL_DATES_DESC',
  BASE_DATE_ASC = 'BASE_DATE_ASC',
  BASE_DATE_DESC = 'BASE_DATE_DESC',
  COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC',
  COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_ASC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_ASC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_DESC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_DESC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANY_BY_COMPANY_ID__REMARK_ASC = 'COMPANY_BY_COMPANY_ID__REMARK_ASC',
  COMPANY_BY_COMPANY_ID__REMARK_DESC = 'COMPANY_BY_COMPANY_ID__REMARK_DESC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC',
  COMPANY_COUNTRY_CODE_ALPHA2_ASC = 'COMPANY_COUNTRY_CODE_ALPHA2_ASC',
  COMPANY_COUNTRY_CODE_ALPHA2_DESC = 'COMPANY_COUNTRY_CODE_ALPHA2_DESC',
  COMPANY_EMAIL_ASC = 'COMPANY_EMAIL_ASC',
  COMPANY_EMAIL_DESC = 'COMPANY_EMAIL_DESC',
  COMPANY_ID_ASC = 'COMPANY_ID_ASC',
  COMPANY_ID_DESC = 'COMPANY_ID_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CODE_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CODE_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CODE_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CODE_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CONTRACT_TYPE_ID_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CONTRACT_TYPE_ID_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CONTRACT_TYPE_ID_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CONTRACT_TYPE_ID_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_CREATED_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_CREATED_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_CREATED_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_CREATED_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_UPDATED_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_UPDATED_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_UPDATED_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_UPDATED_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DISPLAY_NAME_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DISPLAY_NAME_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DISPLAY_NAME_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DISPLAY_NAME_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__REMARK_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__REMARK_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__REMARK_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__REMARK_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_CREATED_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_CREATED_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_CREATED_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_CREATED_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_UPDATED_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_UPDATED_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_UPDATED_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_UPDATED_DESC',
  CONTRACT_TYPE_ID_ASC = 'CONTRACT_TYPE_ID_ASC',
  CONTRACT_TYPE_ID_DESC = 'CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  RANK_ASC = 'RANK_ASC',
  RANK_DESC = 'RANK_DESC',
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC',
  WORKING_DAYS_ASC = 'WORKING_DAYS_ASC',
  WORKING_DAYS_DESC = 'WORKING_DAYS_DESC',
}

export interface EmployeeWorkingDayStatisticsRecord {
  __typename?: 'EmployeeWorkingDayStatisticsRecord';
  allDates: Array<Maybe<EmployeeWorkingDayStatisticsRecordAllDate>>;
  companyCountryCodeAlpha2: Scalars['String']['output'];
  companyEmail: Scalars['String']['output'];
  companyId: Scalars['BigInt']['output'];
  contractTypeId: Scalars['BigInt']['output'];
  employeeId: Scalars['BigInt']['output'];
  rank: Scalars['Int']['output'];
  status: EmployeeStatusKey;
  workingDays: Scalars['Int']['output'];
}

export interface EmployeeWorkingDayStatisticsRecordAllDate {
  __typename?: 'EmployeeWorkingDayStatisticsRecordAllDate';
  employeeId: Scalars['BigInt']['output'];
  employeeName: Scalars['String']['output'];
  employeeNickname?: Maybe<Scalars['String']['output']>;
  joinDate: Scalars['Date']['output'];
  resignationDate?: Maybe<Scalars['Date']['output']>;
  workingDays: Scalars['Int']['output'];
}

export interface EmployeeWorkingDayStatisticsRecordAllDateInput {
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeName?: InputMaybe<Scalars['String']['input']>;
  employeeNickname?: InputMaybe<Scalars['String']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  workingDays?: InputMaybe<Scalars['Int']['input']>;
}

export interface EmployeeWorkingDayStatisticsRecordFilter {
  and?: InputMaybe<Array<EmployeeWorkingDayStatisticsRecordFilter>>;
  companyCountryCodeAlpha2?: InputMaybe<StringFilter>;
  companyEmail?: InputMaybe<StringFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  contractTypeId?: InputMaybe<BigIntFilter>;
  employeeId?: InputMaybe<BigIntFilter>;
  not?: InputMaybe<EmployeeWorkingDayStatisticsRecordFilter>;
  or?: InputMaybe<Array<EmployeeWorkingDayStatisticsRecordFilter>>;
  rank?: InputMaybe<IntFilter>;
  status?: InputMaybe<EmployeeStatusKeyFilter>;
  workingDays?: InputMaybe<IntFilter>;
}

export interface EmployeeWorkingDayStatisticsRecordsConnection {
  __typename?: 'EmployeeWorkingDayStatisticsRecordsConnection';
  edges: Array<EmployeeWorkingDayStatisticsRecordsEdge>;
  nodes: Array<EmployeeWorkingDayStatisticsRecord>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeeWorkingDayStatisticsRecordsEdge {
  __typename?: 'EmployeeWorkingDayStatisticsRecordsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: EmployeeWorkingDayStatisticsRecord;
}

export interface EmployeeWorkingLocationCountryCodeAlpha2FkeyCountryCreateInput {
  banks?: InputMaybe<BankCountryCodeAlpha3FkeyInverseInput>;
  cities?: InputMaybe<CityCountryCodeAlpha2FkeyInverseInput>;
  companies?: InputMaybe<CompanyCountryCodeAlpha2FkeyInverseInput>;
  countryCodeAlpha2: Scalars['String']['input'];
  countryCodeAlpha3: Scalars['String']['input'];
  countryCodeNumeric?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInverseInput>;
  employeeIdTypes?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInverseInput>;
  employeesToMobileNumberCountryIdUsingCountryCodeAlpha2?: InputMaybe<EmployeeMobileNumberCountryIdFkeyInverseInput>;
  employeesToNationalityUsingCountryCodeAlpha3?: InputMaybe<EmployeeNationalityFkeyInverseInput>;
  employeesToWorkingLocationCountryCodeAlpha2UsingCountryCodeAlpha2?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0InverseInput>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName: Scalars['String']['input'];
  telephoneCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface EmployeeWorkingLocationCountryCodeAlpha2FkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface EmployeeWorkingLocationCountryCodeAlpha2FkeyInput {
  connectByCountryCodeAlpha2?: InputMaybe<CountryCountryPkeyConnect>;
  connectByCountryCodeAlpha3?: InputMaybe<CountryCountryCountryCodeAlpha3KeyConnect>;
  connectByCountryCodeNumeric?: InputMaybe<CountryCountryCountryCodeNumericKeyConnect>;
  connectByDisplayOrder?: InputMaybe<CountryCountryDisplayOrderKeyConnect>;
  connectByNodeId?: InputMaybe<CountryNodeIdConnect>;
  create?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyCountryCreateInput>;
  deleteByCountryCodeAlpha2?: InputMaybe<CountryCountryPkeyDelete>;
  deleteByCountryCodeAlpha3?: InputMaybe<CountryCountryCountryCodeAlpha3KeyDelete>;
  deleteByCountryCodeNumeric?: InputMaybe<CountryCountryCountryCodeNumericKeyDelete>;
  deleteByDisplayOrder?: InputMaybe<CountryCountryDisplayOrderKeyDelete>;
  deleteByNodeId?: InputMaybe<CountryNodeIdDelete>;
  updateByCountryCodeAlpha2?: InputMaybe<CountryOnEmployeeForEmployeeWorkingLocationCountryCodeAlpha2FkeyUsingCountryPkeyUpdate>;
  updateByCountryCodeAlpha3?: InputMaybe<CountryOnEmployeeForEmployeeWorkingLocationCountryCodeAlpha2FkeyUsingCountryCountryCodeAlpha3KeyUpdate>;
  updateByCountryCodeNumeric?: InputMaybe<CountryOnEmployeeForEmployeeWorkingLocationCountryCodeAlpha2FkeyUsingCountryCountryCodeNumericKeyUpdate>;
  updateByDisplayOrder?: InputMaybe<CountryOnEmployeeForEmployeeWorkingLocationCountryCodeAlpha2FkeyUsingCountryDisplayOrderKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeOnEmployeeForEmployeeWorkingLocationCountryCodeAlpha2FkeyNodeIdUpdate>;
}

export interface EmployeeWorkingLocationCountryCodeAlpha2FkeyInverseInput {
  connectByCompanyEmail?: InputMaybe<Array<EmployeeEmployeeCompanyEmailKeyConnect>>;
  connectByEmployeeId?: InputMaybe<Array<EmployeeEmployeePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeNodeIdConnect>>;
  create?: InputMaybe<Array<EmployeeWorkingLocationCountryCodeAlpha2FkeyEmployeeCreateInput>>;
  deleteByCompanyEmail?: InputMaybe<Array<EmployeeEmployeeCompanyEmailKeyDelete>>;
  deleteByEmployeeId?: InputMaybe<Array<EmployeeEmployeePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<EmployeeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyEmail?: InputMaybe<Array<EmployeeOnEmployeeForEmployeeWorkingLocationCountryCodeAlpha2FkeyUsingEmployeeCompanyEmailKeyUpdate>>;
  updateByEmployeeId?: InputMaybe<Array<EmployeeOnEmployeeForEmployeeWorkingLocationCountryCodeAlpha2FkeyUsingEmployeePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<CountryOnEmployeeForEmployeeWorkingLocationCountryCodeAlpha2FkeyNodeIdUpdate>>;
}

export interface EmployeesConnection {
  __typename?: 'EmployeesConnection';
  edges: Array<EmployeesEdge>;
  nodes: Array<Employee>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface EmployeesEdge {
  __typename?: 'EmployeesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: Employee;
}

export enum EmployeesOrderBy {
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__COUNT_ASC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__COUNT_ASC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__COUNT_DESC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__COUNT_DESC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MAX_QUARTER_ASC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MAX_QUARTER_ASC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MAX_QUARTER_DESC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MAX_QUARTER_DESC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MAX_RANK_LATEST_ASC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MAX_RANK_LATEST_ASC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MAX_RANK_LATEST_DESC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MAX_RANK_LATEST_DESC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MAX_YEAR_ASC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MAX_YEAR_ASC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MAX_YEAR_DESC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MAX_YEAR_DESC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MIN_QUARTER_ASC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MIN_QUARTER_ASC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MIN_QUARTER_DESC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MIN_QUARTER_DESC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MIN_RANK_LATEST_ASC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MIN_RANK_LATEST_ASC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MIN_RANK_LATEST_DESC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MIN_RANK_LATEST_DESC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MIN_YEAR_ASC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MIN_YEAR_ASC',
  ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MIN_YEAR_DESC = 'ALL_EMPLOYEE_WITH_YEAR_QUARTERS_BY_EMPLOYEE_ID__MIN_YEAR_DESC',
  ALL_SUBORDINATE_COUNT_ASC = 'ALL_SUBORDINATE_COUNT_ASC',
  ALL_SUBORDINATE_COUNT_DESC = 'ALL_SUBORDINATE_COUNT_DESC',
  ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  APPROVER_ID_ASC = 'APPROVER_ID_ASC',
  APPROVER_ID_DESC = 'APPROVER_ID_DESC',
  BANK_ACCOUNT_HOLDER_NAME_ASC = 'BANK_ACCOUNT_HOLDER_NAME_ASC',
  BANK_ACCOUNT_HOLDER_NAME_DESC = 'BANK_ACCOUNT_HOLDER_NAME_DESC',
  BANK_ACCOUNT_NUMBER_ASC = 'BANK_ACCOUNT_NUMBER_ASC',
  BANK_ACCOUNT_NUMBER_DESC = 'BANK_ACCOUNT_NUMBER_DESC',
  BANK_BRANCH_NAME_ASC = 'BANK_BRANCH_NAME_ASC',
  BANK_BRANCH_NAME_DESC = 'BANK_BRANCH_NAME_DESC',
  BANK_BY_BANK_ID__BANK_CODE_ASC = 'BANK_BY_BANK_ID__BANK_CODE_ASC',
  BANK_BY_BANK_ID__BANK_CODE_DESC = 'BANK_BY_BANK_ID__BANK_CODE_DESC',
  BANK_BY_BANK_ID__BANK_ID_ASC = 'BANK_BY_BANK_ID__BANK_ID_ASC',
  BANK_BY_BANK_ID__BANK_ID_DESC = 'BANK_BY_BANK_ID__BANK_ID_DESC',
  BANK_BY_BANK_ID__COUNTRY_CODE_ALPHA3_ASC = 'BANK_BY_BANK_ID__COUNTRY_CODE_ALPHA3_ASC',
  BANK_BY_BANK_ID__COUNTRY_CODE_ALPHA3_DESC = 'BANK_BY_BANK_ID__COUNTRY_CODE_ALPHA3_DESC',
  BANK_BY_BANK_ID__DATE_CREATED_ASC = 'BANK_BY_BANK_ID__DATE_CREATED_ASC',
  BANK_BY_BANK_ID__DATE_CREATED_DESC = 'BANK_BY_BANK_ID__DATE_CREATED_DESC',
  BANK_BY_BANK_ID__DATE_UPDATED_ASC = 'BANK_BY_BANK_ID__DATE_UPDATED_ASC',
  BANK_BY_BANK_ID__DATE_UPDATED_DESC = 'BANK_BY_BANK_ID__DATE_UPDATED_DESC',
  BANK_BY_BANK_ID__ENABLED_ASC = 'BANK_BY_BANK_ID__ENABLED_ASC',
  BANK_BY_BANK_ID__ENABLED_DESC = 'BANK_BY_BANK_ID__ENABLED_DESC',
  BANK_BY_BANK_ID__FULL_NAME_ASC = 'BANK_BY_BANK_ID__FULL_NAME_ASC',
  BANK_BY_BANK_ID__FULL_NAME_DESC = 'BANK_BY_BANK_ID__FULL_NAME_DESC',
  BANK_BY_BANK_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'BANK_BY_BANK_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  BANK_BY_BANK_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'BANK_BY_BANK_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  BANK_BY_BANK_ID__REMARK_ASC = 'BANK_BY_BANK_ID__REMARK_ASC',
  BANK_BY_BANK_ID__REMARK_DESC = 'BANK_BY_BANK_ID__REMARK_DESC',
  BANK_BY_BANK_ID__SHORT_NAME_ASC = 'BANK_BY_BANK_ID__SHORT_NAME_ASC',
  BANK_BY_BANK_ID__SHORT_NAME_DESC = 'BANK_BY_BANK_ID__SHORT_NAME_DESC',
  BANK_BY_BANK_ID__SWIFT_CODE_ASC = 'BANK_BY_BANK_ID__SWIFT_CODE_ASC',
  BANK_BY_BANK_ID__SWIFT_CODE_DESC = 'BANK_BY_BANK_ID__SWIFT_CODE_DESC',
  BANK_BY_BANK_ID__USER_CREATED_ASC = 'BANK_BY_BANK_ID__USER_CREATED_ASC',
  BANK_BY_BANK_ID__USER_CREATED_DESC = 'BANK_BY_BANK_ID__USER_CREATED_DESC',
  BANK_BY_BANK_ID__USER_UPDATED_ASC = 'BANK_BY_BANK_ID__USER_UPDATED_ASC',
  BANK_BY_BANK_ID__USER_UPDATED_DESC = 'BANK_BY_BANK_ID__USER_UPDATED_DESC',
  BANK_BY_CONSULTING_FEE_BANK_ID__BANK_CODE_ASC = 'BANK_BY_CONSULTING_FEE_BANK_ID__BANK_CODE_ASC',
  BANK_BY_CONSULTING_FEE_BANK_ID__BANK_CODE_DESC = 'BANK_BY_CONSULTING_FEE_BANK_ID__BANK_CODE_DESC',
  BANK_BY_CONSULTING_FEE_BANK_ID__BANK_ID_ASC = 'BANK_BY_CONSULTING_FEE_BANK_ID__BANK_ID_ASC',
  BANK_BY_CONSULTING_FEE_BANK_ID__BANK_ID_DESC = 'BANK_BY_CONSULTING_FEE_BANK_ID__BANK_ID_DESC',
  BANK_BY_CONSULTING_FEE_BANK_ID__COUNTRY_CODE_ALPHA3_ASC = 'BANK_BY_CONSULTING_FEE_BANK_ID__COUNTRY_CODE_ALPHA3_ASC',
  BANK_BY_CONSULTING_FEE_BANK_ID__COUNTRY_CODE_ALPHA3_DESC = 'BANK_BY_CONSULTING_FEE_BANK_ID__COUNTRY_CODE_ALPHA3_DESC',
  BANK_BY_CONSULTING_FEE_BANK_ID__DATE_CREATED_ASC = 'BANK_BY_CONSULTING_FEE_BANK_ID__DATE_CREATED_ASC',
  BANK_BY_CONSULTING_FEE_BANK_ID__DATE_CREATED_DESC = 'BANK_BY_CONSULTING_FEE_BANK_ID__DATE_CREATED_DESC',
  BANK_BY_CONSULTING_FEE_BANK_ID__DATE_UPDATED_ASC = 'BANK_BY_CONSULTING_FEE_BANK_ID__DATE_UPDATED_ASC',
  BANK_BY_CONSULTING_FEE_BANK_ID__DATE_UPDATED_DESC = 'BANK_BY_CONSULTING_FEE_BANK_ID__DATE_UPDATED_DESC',
  BANK_BY_CONSULTING_FEE_BANK_ID__ENABLED_ASC = 'BANK_BY_CONSULTING_FEE_BANK_ID__ENABLED_ASC',
  BANK_BY_CONSULTING_FEE_BANK_ID__ENABLED_DESC = 'BANK_BY_CONSULTING_FEE_BANK_ID__ENABLED_DESC',
  BANK_BY_CONSULTING_FEE_BANK_ID__FULL_NAME_ASC = 'BANK_BY_CONSULTING_FEE_BANK_ID__FULL_NAME_ASC',
  BANK_BY_CONSULTING_FEE_BANK_ID__FULL_NAME_DESC = 'BANK_BY_CONSULTING_FEE_BANK_ID__FULL_NAME_DESC',
  BANK_BY_CONSULTING_FEE_BANK_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'BANK_BY_CONSULTING_FEE_BANK_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  BANK_BY_CONSULTING_FEE_BANK_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'BANK_BY_CONSULTING_FEE_BANK_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  BANK_BY_CONSULTING_FEE_BANK_ID__REMARK_ASC = 'BANK_BY_CONSULTING_FEE_BANK_ID__REMARK_ASC',
  BANK_BY_CONSULTING_FEE_BANK_ID__REMARK_DESC = 'BANK_BY_CONSULTING_FEE_BANK_ID__REMARK_DESC',
  BANK_BY_CONSULTING_FEE_BANK_ID__SHORT_NAME_ASC = 'BANK_BY_CONSULTING_FEE_BANK_ID__SHORT_NAME_ASC',
  BANK_BY_CONSULTING_FEE_BANK_ID__SHORT_NAME_DESC = 'BANK_BY_CONSULTING_FEE_BANK_ID__SHORT_NAME_DESC',
  BANK_BY_CONSULTING_FEE_BANK_ID__SWIFT_CODE_ASC = 'BANK_BY_CONSULTING_FEE_BANK_ID__SWIFT_CODE_ASC',
  BANK_BY_CONSULTING_FEE_BANK_ID__SWIFT_CODE_DESC = 'BANK_BY_CONSULTING_FEE_BANK_ID__SWIFT_CODE_DESC',
  BANK_BY_CONSULTING_FEE_BANK_ID__USER_CREATED_ASC = 'BANK_BY_CONSULTING_FEE_BANK_ID__USER_CREATED_ASC',
  BANK_BY_CONSULTING_FEE_BANK_ID__USER_CREATED_DESC = 'BANK_BY_CONSULTING_FEE_BANK_ID__USER_CREATED_DESC',
  BANK_BY_CONSULTING_FEE_BANK_ID__USER_UPDATED_ASC = 'BANK_BY_CONSULTING_FEE_BANK_ID__USER_UPDATED_ASC',
  BANK_BY_CONSULTING_FEE_BANK_ID__USER_UPDATED_DESC = 'BANK_BY_CONSULTING_FEE_BANK_ID__USER_UPDATED_DESC',
  BANK_ID_ASC = 'BANK_ID_ASC',
  BANK_ID_DESC = 'BANK_ID_DESC',
  BANK_REMARKS_ASC = 'BANK_REMARKS_ASC',
  BANK_REMARKS_DESC = 'BANK_REMARKS_DESC',
  COMPANY_EMAIL_ASC = 'COMPANY_EMAIL_ASC',
  COMPANY_EMAIL_DESC = 'COMPANY_EMAIL_DESC',
  COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__COMPANY_GLOBAL_HR_ID_ASC = 'COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__COMPANY_GLOBAL_HR_ID_ASC',
  COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__COMPANY_GLOBAL_HR_ID_DESC = 'COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__COMPANY_GLOBAL_HR_ID_DESC',
  COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__DATE_CREATED_ASC = 'COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__DATE_CREATED_ASC',
  COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__DATE_CREATED_DESC = 'COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__DATE_CREATED_DESC',
  COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__DATE_UPDATED_ASC = 'COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__DATE_UPDATED_ASC',
  COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__DATE_UPDATED_DESC = 'COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__DATE_UPDATED_DESC',
  COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__REMARK_ASC = 'COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__REMARK_ASC',
  COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__REMARK_DESC = 'COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__REMARK_DESC',
  COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__USER_CREATED_ASC = 'COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__USER_CREATED_ASC',
  COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__USER_CREATED_DESC = 'COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__USER_CREATED_DESC',
  COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__USER_UPDATED_ASC = 'COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__USER_UPDATED_ASC',
  COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__USER_UPDATED_DESC = 'COMPANY_GLOBAL_HR_BY_EMPLOYEE_ID__USER_UPDATED_DESC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__COUNT_ASC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__COUNT_ASC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__COUNT_DESC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__COUNT_DESC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_COMPANY_ID_ASC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_COMPANY_ID_ASC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_COMPANY_ID_DESC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_COMPANY_ID_DESC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_COMPANY_LOCAL_HR_ID_ASC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_COMPANY_LOCAL_HR_ID_ASC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_COMPANY_LOCAL_HR_ID_DESC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_COMPANY_LOCAL_HR_ID_DESC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_REMARK_ASC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_REMARK_ASC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_REMARK_DESC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_REMARK_DESC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_USER_CREATED_ASC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_USER_CREATED_ASC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_USER_CREATED_DESC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_USER_CREATED_DESC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_ASC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_ASC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_DESC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_DESC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_COMPANY_ID_ASC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_COMPANY_ID_ASC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_COMPANY_ID_DESC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_COMPANY_ID_DESC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_COMPANY_LOCAL_HR_ID_ASC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_COMPANY_LOCAL_HR_ID_ASC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_COMPANY_LOCAL_HR_ID_DESC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_COMPANY_LOCAL_HR_ID_DESC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_REMARK_ASC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_REMARK_ASC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_REMARK_DESC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_REMARK_DESC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_USER_CREATED_ASC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_USER_CREATED_ASC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_USER_CREATED_DESC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_USER_CREATED_DESC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_ASC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_ASC',
  COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_DESC = 'COMPANY_LOCAL_HRS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_DESC',
  CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  CONSULTING_FEE_BANK_ID_ASC = 'CONSULTING_FEE_BANK_ID_ASC',
  CONSULTING_FEE_BANK_ID_DESC = 'CONSULTING_FEE_BANK_ID_DESC',
  CONSULTING_FEE_BANK_REMARKS_ASC = 'CONSULTING_FEE_BANK_REMARKS_ASC',
  CONSULTING_FEE_BANK_REMARKS_DESC = 'CONSULTING_FEE_BANK_REMARKS_DESC',
  CONSULTING_FEE_CURRENCY_ID_ASC = 'CONSULTING_FEE_CURRENCY_ID_ASC',
  CONSULTING_FEE_CURRENCY_ID_DESC = 'CONSULTING_FEE_CURRENCY_ID_DESC',
  CONSULTING_FEE_TYPE_ASC = 'CONSULTING_FEE_TYPE_ASC',
  CONSULTING_FEE_TYPE_DESC = 'CONSULTING_FEE_TYPE_DESC',
  COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__COUNTRY_CODE_ALPHA2_DESC',
  COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__COUNTRY_CODE_ALPHA3_ASC = 'COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__COUNTRY_CODE_ALPHA3_ASC',
  COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__COUNTRY_CODE_ALPHA3_DESC = 'COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__COUNTRY_CODE_ALPHA3_DESC',
  COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__COUNTRY_CODE_NUMERIC_ASC = 'COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__COUNTRY_CODE_NUMERIC_ASC',
  COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__COUNTRY_CODE_NUMERIC_DESC = 'COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__COUNTRY_CODE_NUMERIC_DESC',
  COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__DATE_CREATED_ASC = 'COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__DATE_CREATED_ASC',
  COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__DATE_CREATED_DESC = 'COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__DATE_CREATED_DESC',
  COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__DATE_UPDATED_ASC = 'COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__DATE_UPDATED_ASC',
  COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__DATE_UPDATED_DESC = 'COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__DATE_UPDATED_DESC',
  COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__DISPLAY_ORDER_ASC = 'COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__DISPLAY_ORDER_ASC',
  COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__DISPLAY_ORDER_DESC = 'COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__DISPLAY_ORDER_DESC',
  COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__REMARK_ASC = 'COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__REMARK_ASC',
  COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__REMARK_DESC = 'COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__REMARK_DESC',
  COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__SHORT_NAME_ASC = 'COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__SHORT_NAME_ASC',
  COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__SHORT_NAME_DESC = 'COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__SHORT_NAME_DESC',
  COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__TELEPHONE_CODE_ASC = 'COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__TELEPHONE_CODE_ASC',
  COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__TELEPHONE_CODE_DESC = 'COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__TELEPHONE_CODE_DESC',
  COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__USER_CREATED_ASC = 'COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__USER_CREATED_ASC',
  COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__USER_CREATED_DESC = 'COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__USER_CREATED_DESC',
  COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__USER_UPDATED_ASC = 'COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__USER_UPDATED_ASC',
  COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__USER_UPDATED_DESC = 'COUNTRY_BY_MOBILE_NUMBER_COUNTRY_ID__USER_UPDATED_DESC',
  COUNTRY_BY_NATIONALITY__COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_BY_NATIONALITY__COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_BY_NATIONALITY__COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_BY_NATIONALITY__COUNTRY_CODE_ALPHA2_DESC',
  COUNTRY_BY_NATIONALITY__COUNTRY_CODE_ALPHA3_ASC = 'COUNTRY_BY_NATIONALITY__COUNTRY_CODE_ALPHA3_ASC',
  COUNTRY_BY_NATIONALITY__COUNTRY_CODE_ALPHA3_DESC = 'COUNTRY_BY_NATIONALITY__COUNTRY_CODE_ALPHA3_DESC',
  COUNTRY_BY_NATIONALITY__COUNTRY_CODE_NUMERIC_ASC = 'COUNTRY_BY_NATIONALITY__COUNTRY_CODE_NUMERIC_ASC',
  COUNTRY_BY_NATIONALITY__COUNTRY_CODE_NUMERIC_DESC = 'COUNTRY_BY_NATIONALITY__COUNTRY_CODE_NUMERIC_DESC',
  COUNTRY_BY_NATIONALITY__DATE_CREATED_ASC = 'COUNTRY_BY_NATIONALITY__DATE_CREATED_ASC',
  COUNTRY_BY_NATIONALITY__DATE_CREATED_DESC = 'COUNTRY_BY_NATIONALITY__DATE_CREATED_DESC',
  COUNTRY_BY_NATIONALITY__DATE_UPDATED_ASC = 'COUNTRY_BY_NATIONALITY__DATE_UPDATED_ASC',
  COUNTRY_BY_NATIONALITY__DATE_UPDATED_DESC = 'COUNTRY_BY_NATIONALITY__DATE_UPDATED_DESC',
  COUNTRY_BY_NATIONALITY__DISPLAY_ORDER_ASC = 'COUNTRY_BY_NATIONALITY__DISPLAY_ORDER_ASC',
  COUNTRY_BY_NATIONALITY__DISPLAY_ORDER_DESC = 'COUNTRY_BY_NATIONALITY__DISPLAY_ORDER_DESC',
  COUNTRY_BY_NATIONALITY__REMARK_ASC = 'COUNTRY_BY_NATIONALITY__REMARK_ASC',
  COUNTRY_BY_NATIONALITY__REMARK_DESC = 'COUNTRY_BY_NATIONALITY__REMARK_DESC',
  COUNTRY_BY_NATIONALITY__SHORT_NAME_ASC = 'COUNTRY_BY_NATIONALITY__SHORT_NAME_ASC',
  COUNTRY_BY_NATIONALITY__SHORT_NAME_DESC = 'COUNTRY_BY_NATIONALITY__SHORT_NAME_DESC',
  COUNTRY_BY_NATIONALITY__TELEPHONE_CODE_ASC = 'COUNTRY_BY_NATIONALITY__TELEPHONE_CODE_ASC',
  COUNTRY_BY_NATIONALITY__TELEPHONE_CODE_DESC = 'COUNTRY_BY_NATIONALITY__TELEPHONE_CODE_DESC',
  COUNTRY_BY_NATIONALITY__USER_CREATED_ASC = 'COUNTRY_BY_NATIONALITY__USER_CREATED_ASC',
  COUNTRY_BY_NATIONALITY__USER_CREATED_DESC = 'COUNTRY_BY_NATIONALITY__USER_CREATED_DESC',
  COUNTRY_BY_NATIONALITY__USER_UPDATED_ASC = 'COUNTRY_BY_NATIONALITY__USER_UPDATED_ASC',
  COUNTRY_BY_NATIONALITY__USER_UPDATED_DESC = 'COUNTRY_BY_NATIONALITY__USER_UPDATED_DESC',
  COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_DESC',
  COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_ASC = 'COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_ASC',
  COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_DESC = 'COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_DESC',
  COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_ASC = 'COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_ASC',
  COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_DESC = 'COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_DESC',
  COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__DATE_CREATED_ASC = 'COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__DATE_CREATED_ASC',
  COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__DATE_CREATED_DESC = 'COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__DATE_CREATED_DESC',
  COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__DATE_UPDATED_ASC = 'COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__DATE_UPDATED_ASC',
  COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__DATE_UPDATED_DESC = 'COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__DATE_UPDATED_DESC',
  COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_ASC = 'COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_ASC',
  COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_DESC = 'COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_DESC',
  COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__REMARK_ASC = 'COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__REMARK_ASC',
  COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__REMARK_DESC = 'COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__REMARK_DESC',
  COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__SHORT_NAME_ASC = 'COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__SHORT_NAME_ASC',
  COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__SHORT_NAME_DESC = 'COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__SHORT_NAME_DESC',
  COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_ASC = 'COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_ASC',
  COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_DESC = 'COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_DESC',
  COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__USER_CREATED_ASC = 'COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__USER_CREATED_ASC',
  COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__USER_CREATED_DESC = 'COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__USER_CREATED_DESC',
  COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__USER_UPDATED_ASC = 'COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__USER_UPDATED_ASC',
  COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__USER_UPDATED_DESC = 'COUNTRY_BY_WORKING_LOCATION_COUNTRY_CODE_ALPHA2__USER_UPDATED_DESC',
  CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__ABBREVIATION_ASC = 'CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__ABBREVIATION_ASC',
  CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__ABBREVIATION_DESC = 'CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__ABBREVIATION_DESC',
  CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__CURRENCY_CODE_ASC = 'CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__CURRENCY_CODE_ASC',
  CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__CURRENCY_CODE_DESC = 'CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__CURRENCY_CODE_DESC',
  CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__DATE_CREATED_ASC = 'CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__DATE_CREATED_ASC',
  CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__DATE_CREATED_DESC = 'CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__DATE_CREATED_DESC',
  CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__DATE_UPDATED_ASC = 'CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__DATE_UPDATED_ASC',
  CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__DATE_UPDATED_DESC = 'CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__DATE_UPDATED_DESC',
  CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__NAME_ASC = 'CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__NAME_ASC',
  CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__NAME_DESC = 'CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__NAME_DESC',
  CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__REMARK_ASC = 'CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__REMARK_ASC',
  CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__REMARK_DESC = 'CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__REMARK_DESC',
  CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__USER_CREATED_ASC = 'CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__USER_CREATED_ASC',
  CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__USER_CREATED_DESC = 'CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__USER_CREATED_DESC',
  CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__USER_UPDATED_ASC = 'CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__USER_UPDATED_ASC',
  CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__USER_UPDATED_DESC = 'CURRENCY_BY_CONSULTING_FEE_CURRENCY_ID__USER_UPDATED_DESC',
  CURRENT_ADDRESS_ASC = 'CURRENT_ADDRESS_ASC',
  CURRENT_ADDRESS_DESC = 'CURRENT_ADDRESS_DESC',
  CURRENT_ADDRESS_ZIPCODE_ASC = 'CURRENT_ADDRESS_ZIPCODE_ASC',
  CURRENT_ADDRESS_ZIPCODE_DESC = 'CURRENT_ADDRESS_ZIPCODE_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_OF_BIRTH_ASC = 'DATE_OF_BIRTH_ASC',
  DATE_OF_BIRTH_DESC = 'DATE_OF_BIRTH_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DOTTED_LINE_MANAGER_ID_ASC = 'DOTTED_LINE_MANAGER_ID_ASC',
  DOTTED_LINE_MANAGER_ID_DESC = 'DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_APPROVER_ID__COUNT_ASC = 'EMPLOYEES_BY_APPROVER_ID__COUNT_ASC',
  EMPLOYEES_BY_APPROVER_ID__COUNT_DESC = 'EMPLOYEES_BY_APPROVER_ID__COUNT_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_APPROVER_ID_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_APPROVER_ID_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_APPROVER_ID_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_APPROVER_ID_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_BANK_ID_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_BANK_ID_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_BANK_ID_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_BANK_ID_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_BANK_REMARKS_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_BANK_REMARKS_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_BANK_REMARKS_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_BANK_REMARKS_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_DATE_CREATED_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_DATE_CREATED_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_DATE_CREATED_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_DATE_CREATED_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_DATE_UPDATED_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_DATE_UPDATED_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_DATE_UPDATED_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_DATE_UPDATED_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_FULL_NAME_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_FULL_NAME_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_FULL_NAME_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_FULL_NAME_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_GENDER_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_GENDER_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_GENDER_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_GENDER_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_ID_NUMBER_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_ID_NUMBER_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_ID_NUMBER_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_ID_NUMBER_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_ID_TYPE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_ID_TYPE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_ID_TYPE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_ID_TYPE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_JOIN_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_JOIN_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_JOIN_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_JOIN_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_NATIONALITY_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_NATIONALITY_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_NATIONALITY_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_NATIONALITY_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_NICKNAME_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_NICKNAME_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_NICKNAME_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_NICKNAME_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_PROBATION_END_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_PROBATION_END_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_PROBATION_END_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_PROBATION_END_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_REMARK_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_REMARK_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_REMARK_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_REMARK_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_STATUS_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_STATUS_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_STATUS_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_STATUS_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_TAX_CODE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_TAX_CODE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_TAX_CODE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_TAX_CODE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_USER_CREATED_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_USER_CREATED_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_USER_CREATED_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_USER_CREATED_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_USER_UPDATED_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_USER_UPDATED_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_USER_UPDATED_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_USER_UPDATED_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_VISA_NUMBER_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_VISA_NUMBER_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_VISA_NUMBER_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_VISA_NUMBER_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_VISA_TYPE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_VISA_TYPE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_VISA_TYPE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_VISA_TYPE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MAX_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_APPROVER_ID__MAX_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_APPROVER_ID__MAX_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_APPROVER_ID__MAX_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_APPROVER_ID_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_APPROVER_ID_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_APPROVER_ID_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_APPROVER_ID_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_BANK_ID_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_BANK_ID_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_BANK_ID_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_BANK_ID_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_BANK_REMARKS_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_BANK_REMARKS_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_BANK_REMARKS_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_BANK_REMARKS_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_DATE_CREATED_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_DATE_CREATED_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_DATE_CREATED_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_DATE_CREATED_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_DATE_UPDATED_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_DATE_UPDATED_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_DATE_UPDATED_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_DATE_UPDATED_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_FULL_NAME_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_FULL_NAME_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_FULL_NAME_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_FULL_NAME_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_GENDER_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_GENDER_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_GENDER_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_GENDER_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_ID_NUMBER_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_ID_NUMBER_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_ID_NUMBER_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_ID_NUMBER_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_ID_TYPE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_ID_TYPE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_ID_TYPE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_ID_TYPE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_JOIN_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_JOIN_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_JOIN_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_JOIN_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_NATIONALITY_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_NATIONALITY_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_NATIONALITY_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_NATIONALITY_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_NICKNAME_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_NICKNAME_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_NICKNAME_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_NICKNAME_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_PROBATION_END_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_PROBATION_END_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_PROBATION_END_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_PROBATION_END_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_REMARK_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_REMARK_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_REMARK_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_REMARK_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_STATUS_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_STATUS_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_STATUS_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_STATUS_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_TAX_CODE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_TAX_CODE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_TAX_CODE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_TAX_CODE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_USER_CREATED_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_USER_CREATED_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_USER_CREATED_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_USER_CREATED_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_USER_UPDATED_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_USER_UPDATED_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_USER_UPDATED_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_USER_UPDATED_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_VISA_NUMBER_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_VISA_NUMBER_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_VISA_NUMBER_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_VISA_NUMBER_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_VISA_TYPE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_VISA_TYPE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_VISA_TYPE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_VISA_TYPE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_APPROVER_ID__MIN_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_APPROVER_ID__MIN_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_APPROVER_ID__MIN_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_APPROVER_ID__MIN_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__COUNT_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__COUNT_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__COUNT_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__COUNT_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_APPROVER_ID_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_APPROVER_ID_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_APPROVER_ID_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_APPROVER_ID_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_BANK_ID_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_BANK_ID_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_BANK_ID_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_BANK_ID_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_BANK_REMARKS_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_BANK_REMARKS_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_BANK_REMARKS_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_BANK_REMARKS_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_DATE_CREATED_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_DATE_CREATED_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_DATE_CREATED_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_DATE_CREATED_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_DATE_UPDATED_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_DATE_UPDATED_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_DATE_UPDATED_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_DATE_UPDATED_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_FULL_NAME_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_FULL_NAME_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_FULL_NAME_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_FULL_NAME_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_GENDER_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_GENDER_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_GENDER_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_GENDER_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_ID_NUMBER_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_ID_NUMBER_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_ID_NUMBER_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_ID_NUMBER_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_ID_TYPE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_ID_TYPE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_ID_TYPE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_ID_TYPE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_JOIN_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_JOIN_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_JOIN_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_JOIN_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_NATIONALITY_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_NATIONALITY_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_NATIONALITY_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_NATIONALITY_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_NICKNAME_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_NICKNAME_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_NICKNAME_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_NICKNAME_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PROBATION_END_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PROBATION_END_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PROBATION_END_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_PROBATION_END_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_REMARK_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_REMARK_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_REMARK_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_REMARK_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_STATUS_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_STATUS_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_STATUS_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_STATUS_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_TAX_CODE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_TAX_CODE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_TAX_CODE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_TAX_CODE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_USER_CREATED_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_USER_CREATED_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_USER_CREATED_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_USER_CREATED_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_USER_UPDATED_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_USER_UPDATED_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_USER_UPDATED_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_USER_UPDATED_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_VISA_NUMBER_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_VISA_NUMBER_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_VISA_NUMBER_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_VISA_NUMBER_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_VISA_TYPE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_VISA_TYPE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_VISA_TYPE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_VISA_TYPE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MAX_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_APPROVER_ID_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_APPROVER_ID_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_APPROVER_ID_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_APPROVER_ID_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_BANK_ID_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_BANK_ID_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_BANK_ID_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_BANK_ID_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_BANK_REMARKS_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_BANK_REMARKS_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_BANK_REMARKS_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_BANK_REMARKS_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_DATE_CREATED_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_DATE_CREATED_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_DATE_CREATED_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_DATE_CREATED_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_DATE_UPDATED_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_DATE_UPDATED_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_DATE_UPDATED_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_DATE_UPDATED_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_FULL_NAME_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_FULL_NAME_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_FULL_NAME_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_FULL_NAME_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_GENDER_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_GENDER_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_GENDER_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_GENDER_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_ID_NUMBER_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_ID_NUMBER_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_ID_NUMBER_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_ID_NUMBER_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_ID_TYPE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_ID_TYPE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_ID_TYPE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_ID_TYPE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_JOIN_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_JOIN_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_JOIN_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_JOIN_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_NATIONALITY_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_NATIONALITY_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_NATIONALITY_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_NATIONALITY_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_NICKNAME_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_NICKNAME_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_NICKNAME_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_NICKNAME_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PROBATION_END_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PROBATION_END_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PROBATION_END_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_PROBATION_END_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_REMARK_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_REMARK_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_REMARK_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_REMARK_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_STATUS_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_STATUS_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_STATUS_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_STATUS_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_TAX_CODE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_TAX_CODE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_TAX_CODE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_TAX_CODE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_USER_CREATED_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_USER_CREATED_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_USER_CREATED_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_USER_CREATED_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_USER_UPDATED_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_USER_UPDATED_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_USER_UPDATED_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_USER_UPDATED_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_VISA_NUMBER_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_VISA_NUMBER_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_VISA_NUMBER_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_VISA_NUMBER_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_VISA_TYPE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_VISA_TYPE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_VISA_TYPE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_VISA_TYPE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_DOTTED_LINE_MANAGER_ID__MIN_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__COUNT_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__COUNT_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__COUNT_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__COUNT_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_APPROVER_ID_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_APPROVER_ID_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_APPROVER_ID_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_APPROVER_ID_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_BANK_ID_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_BANK_ID_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_BANK_ID_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_BANK_ID_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_BANK_REMARKS_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_BANK_REMARKS_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_BANK_REMARKS_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_BANK_REMARKS_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_DATE_CREATED_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_DATE_CREATED_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_DATE_CREATED_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_DATE_CREATED_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_DATE_UPDATED_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_DATE_UPDATED_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_DATE_UPDATED_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_DATE_UPDATED_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_FULL_NAME_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_FULL_NAME_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_FULL_NAME_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_FULL_NAME_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_GENDER_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_GENDER_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_GENDER_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_GENDER_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_ID_NUMBER_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_ID_NUMBER_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_ID_NUMBER_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_ID_NUMBER_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_ID_TYPE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_ID_TYPE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_ID_TYPE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_ID_TYPE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_JOIN_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_JOIN_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_JOIN_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_JOIN_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_NATIONALITY_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_NATIONALITY_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_NATIONALITY_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_NATIONALITY_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_NICKNAME_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_NICKNAME_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_NICKNAME_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_NICKNAME_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PROBATION_END_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PROBATION_END_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PROBATION_END_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_PROBATION_END_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_REMARK_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_REMARK_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_REMARK_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_REMARK_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_STATUS_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_STATUS_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_STATUS_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_STATUS_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_TAX_CODE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_TAX_CODE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_TAX_CODE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_TAX_CODE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_USER_CREATED_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_USER_CREATED_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_USER_CREATED_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_USER_CREATED_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_USER_UPDATED_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_USER_UPDATED_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_USER_UPDATED_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_USER_UPDATED_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_VISA_NUMBER_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_VISA_NUMBER_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_VISA_NUMBER_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_VISA_NUMBER_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_VISA_TYPE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_VISA_TYPE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_VISA_TYPE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_VISA_TYPE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MAX_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_APPROVER_ID_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_APPROVER_ID_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_APPROVER_ID_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_APPROVER_ID_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_BANK_ID_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_BANK_ID_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_BANK_ID_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_BANK_ID_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_BANK_REMARKS_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_BANK_REMARKS_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_BANK_REMARKS_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_BANK_REMARKS_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_DATE_CREATED_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_DATE_CREATED_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_DATE_CREATED_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_DATE_CREATED_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_DATE_UPDATED_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_DATE_UPDATED_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_DATE_UPDATED_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_DATE_UPDATED_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_FULL_NAME_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_FULL_NAME_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_FULL_NAME_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_FULL_NAME_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_GENDER_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_GENDER_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_GENDER_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_GENDER_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_ID_NUMBER_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_ID_NUMBER_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_ID_NUMBER_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_ID_NUMBER_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_ID_TYPE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_ID_TYPE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_ID_TYPE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_ID_TYPE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_JOIN_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_JOIN_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_JOIN_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_JOIN_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_NATIONALITY_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_NATIONALITY_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_NATIONALITY_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_NATIONALITY_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_NICKNAME_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_NICKNAME_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_NICKNAME_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_NICKNAME_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PROBATION_END_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PROBATION_END_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PROBATION_END_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_PROBATION_END_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_REMARK_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_REMARK_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_REMARK_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_REMARK_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_STATUS_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_STATUS_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_STATUS_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_STATUS_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_TAX_CODE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_TAX_CODE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_TAX_CODE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_TAX_CODE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_USER_CREATED_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_USER_CREATED_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_USER_CREATED_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_USER_CREATED_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_USER_UPDATED_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_USER_UPDATED_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_USER_UPDATED_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_USER_UPDATED_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_VISA_NUMBER_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_VISA_NUMBER_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_VISA_NUMBER_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_VISA_NUMBER_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_VISA_TYPE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_VISA_TYPE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_VISA_TYPE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_VISA_TYPE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_PRIMARY_MANAGER_ID__MIN_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_BY_APPROVER_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_APPROVER_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_APPROVER_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_APPROVER_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_APPROVER_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_APPROVER_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_APPROVER_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_APPROVER_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_APPROVER_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_APPROVER_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_APPROVER_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_APPROVER_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_APPROVER_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_APPROVER_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_APPROVER_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_APPROVER_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_APPROVER_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_APPROVER_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_APPROVER_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_APPROVER_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_APPROVER_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_APPROVER_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_APPROVER_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_APPROVER_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_APPROVER_ID__BANK_ID_ASC = 'EMPLOYEE_BY_APPROVER_ID__BANK_ID_ASC',
  EMPLOYEE_BY_APPROVER_ID__BANK_ID_DESC = 'EMPLOYEE_BY_APPROVER_ID__BANK_ID_DESC',
  EMPLOYEE_BY_APPROVER_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_APPROVER_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_APPROVER_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_APPROVER_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_APPROVER_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_APPROVER_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_APPROVER_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_APPROVER_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_APPROVER_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_APPROVER_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_APPROVER_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_APPROVER_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_APPROVER_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_APPROVER_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_APPROVER_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_APPROVER_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_APPROVER_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_APPROVER_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_APPROVER_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_APPROVER_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_APPROVER_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_APPROVER_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_APPROVER_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_APPROVER_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_APPROVER_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_APPROVER_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_APPROVER_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_APPROVER_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_APPROVER_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_APPROVER_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_APPROVER_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_APPROVER_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_APPROVER_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_APPROVER_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_APPROVER_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_APPROVER_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_APPROVER_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_APPROVER_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_APPROVER_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_APPROVER_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_APPROVER_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_APPROVER_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_APPROVER_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_APPROVER_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_APPROVER_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_APPROVER_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_APPROVER_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_APPROVER_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_APPROVER_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_APPROVER_ID__GENDER_ASC = 'EMPLOYEE_BY_APPROVER_ID__GENDER_ASC',
  EMPLOYEE_BY_APPROVER_ID__GENDER_DESC = 'EMPLOYEE_BY_APPROVER_ID__GENDER_DESC',
  EMPLOYEE_BY_APPROVER_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_APPROVER_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_APPROVER_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_APPROVER_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_APPROVER_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_APPROVER_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_APPROVER_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_APPROVER_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_APPROVER_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_APPROVER_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_APPROVER_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_APPROVER_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_APPROVER_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_APPROVER_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_APPROVER_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_APPROVER_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_APPROVER_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_APPROVER_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_APPROVER_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_APPROVER_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_APPROVER_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_APPROVER_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_APPROVER_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_APPROVER_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_APPROVER_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_APPROVER_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_APPROVER_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_APPROVER_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_APPROVER_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_APPROVER_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_APPROVER_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_APPROVER_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_APPROVER_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_APPROVER_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_APPROVER_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_APPROVER_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_APPROVER_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_APPROVER_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_APPROVER_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_APPROVER_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_APPROVER_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_APPROVER_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_APPROVER_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_APPROVER_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_APPROVER_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_APPROVER_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_APPROVER_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_APPROVER_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_APPROVER_ID__NICKNAME_ASC = 'EMPLOYEE_BY_APPROVER_ID__NICKNAME_ASC',
  EMPLOYEE_BY_APPROVER_ID__NICKNAME_DESC = 'EMPLOYEE_BY_APPROVER_ID__NICKNAME_DESC',
  EMPLOYEE_BY_APPROVER_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_APPROVER_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_APPROVER_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_APPROVER_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_APPROVER_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_APPROVER_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_APPROVER_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_APPROVER_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_APPROVER_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_APPROVER_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_APPROVER_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_APPROVER_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_APPROVER_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_APPROVER_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_APPROVER_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_APPROVER_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_APPROVER_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_APPROVER_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_APPROVER_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_APPROVER_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_APPROVER_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_APPROVER_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_APPROVER_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_APPROVER_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_APPROVER_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_APPROVER_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_APPROVER_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_APPROVER_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_APPROVER_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_APPROVER_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_APPROVER_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_APPROVER_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_APPROVER_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_APPROVER_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_APPROVER_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_APPROVER_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_APPROVER_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_APPROVER_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_APPROVER_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_APPROVER_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_APPROVER_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_APPROVER_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_APPROVER_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_APPROVER_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_APPROVER_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_APPROVER_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_APPROVER_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_APPROVER_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_APPROVER_ID__REMARK_ASC = 'EMPLOYEE_BY_APPROVER_ID__REMARK_ASC',
  EMPLOYEE_BY_APPROVER_ID__REMARK_DESC = 'EMPLOYEE_BY_APPROVER_ID__REMARK_DESC',
  EMPLOYEE_BY_APPROVER_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_APPROVER_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_APPROVER_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_APPROVER_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_APPROVER_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_APPROVER_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_APPROVER_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_APPROVER_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_APPROVER_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_APPROVER_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_APPROVER_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_APPROVER_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_APPROVER_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_APPROVER_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_APPROVER_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_APPROVER_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_APPROVER_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_APPROVER_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_APPROVER_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_APPROVER_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_APPROVER_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_APPROVER_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_APPROVER_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_APPROVER_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_APPROVER_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_APPROVER_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_APPROVER_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_APPROVER_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_APPROVER_ID__STATUS_ASC = 'EMPLOYEE_BY_APPROVER_ID__STATUS_ASC',
  EMPLOYEE_BY_APPROVER_ID__STATUS_DESC = 'EMPLOYEE_BY_APPROVER_ID__STATUS_DESC',
  EMPLOYEE_BY_APPROVER_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_APPROVER_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_APPROVER_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_APPROVER_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_APPROVER_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_APPROVER_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_APPROVER_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_APPROVER_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_APPROVER_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_APPROVER_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_APPROVER_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_APPROVER_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_APPROVER_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_APPROVER_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_APPROVER_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_APPROVER_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_APPROVER_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_APPROVER_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_APPROVER_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_APPROVER_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_APPROVER_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_APPROVER_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_APPROVER_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_APPROVER_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_APPROVER_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_APPROVER_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_APPROVER_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_APPROVER_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_APPROVER_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_APPROVER_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_APPROVER_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_APPROVER_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_APPROVER_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_APPROVER_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_APPROVER_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_APPROVER_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_APPROVER_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_APPROVER_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_APPROVER_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_APPROVER_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_APPROVER_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_APPROVER_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_APPROVER_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_APPROVER_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_APPROVER_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_APPROVER_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_APPROVER_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_APPROVER_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__BANK_ID_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__BANK_ID_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__BANK_ID_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__BANK_ID_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__GENDER_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__GENDER_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__GENDER_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__GENDER_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__NICKNAME_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__NICKNAME_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__NICKNAME_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__NICKNAME_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__REMARK_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__REMARK_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__REMARK_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__REMARK_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__STATUS_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__STATUS_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__STATUS_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__STATUS_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_DOTTED_LINE_MANAGER_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__BANK_ID_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__BANK_ID_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__BANK_ID_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__BANK_ID_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__GENDER_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__GENDER_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__GENDER_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__GENDER_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__NICKNAME_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__NICKNAME_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__NICKNAME_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__NICKNAME_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__REMARK_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__REMARK_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__REMARK_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__REMARK_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__STATUS_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__STATUS_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__STATUS_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__STATUS_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_PRIMARY_MANAGER_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_PRIMARY_MANAGER_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__CITY_ID_ASC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__CITY_ID_ASC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__CITY_ID_DESC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__CITY_ID_DESC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__COMPANY_ID_ASC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__COMPANY_ID_ASC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__COMPANY_ID_DESC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__COMPANY_ID_DESC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__DEPARTMENT_ID_ASC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__DEPARTMENT_ID_ASC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__DEPARTMENT_ID_DESC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__DEPARTMENT_ID_DESC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__DIVISION_ID_ASC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__DIVISION_ID_ASC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__DIVISION_ID_DESC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__DIVISION_ID_DESC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__EFFECTIVE_DATE_ASC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__EFFECTIVE_DATE_ASC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__EFFECTIVE_DATE_DESC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__EFFECTIVE_DATE_DESC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__EMPLOYEE_CODE_ASC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__EMPLOYEE_CODE_ASC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__EMPLOYEE_CODE_DESC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__EMPLOYEE_CODE_DESC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__JOB_TITLE_ASC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__JOB_TITLE_ASC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__JOB_TITLE_DESC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__JOB_TITLE_DESC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__OFFICE_ID_ASC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__OFFICE_ID_ASC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__OFFICE_ID_DESC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__OFFICE_ID_DESC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__TEAM_ASC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__TEAM_ASC',
  EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__TEAM_DESC = 'EMPLOYEE_CURRENT_POSITION_BY_EMPLOYEE_ID__TEAM_DESC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__COUNT_ASC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__COUNT_ASC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__COUNT_DESC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__COUNT_DESC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MAX_CONTRACT_TYPE_CODE_ASC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MAX_CONTRACT_TYPE_CODE_ASC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MAX_CONTRACT_TYPE_CODE_DESC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MAX_CONTRACT_TYPE_CODE_DESC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MAX_CONTRACT_TYPE_NAME_ASC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MAX_CONTRACT_TYPE_NAME_ASC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MAX_CONTRACT_TYPE_NAME_DESC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MAX_CONTRACT_TYPE_NAME_DESC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MAX_DAYS_ASC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MAX_DAYS_ASC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MAX_DAYS_DESC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MAX_DAYS_DESC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MAX_EFFECTIVE_DATE_ASC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MAX_EFFECTIVE_DATE_ASC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MAX_EFFECTIVE_DATE_DESC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MAX_EFFECTIVE_DATE_DESC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MAX_FLAG_ASC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MAX_FLAG_ASC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MAX_FLAG_DESC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MAX_FLAG_DESC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MIN_CONTRACT_TYPE_CODE_ASC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MIN_CONTRACT_TYPE_CODE_ASC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MIN_CONTRACT_TYPE_CODE_DESC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MIN_CONTRACT_TYPE_CODE_DESC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MIN_CONTRACT_TYPE_NAME_ASC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MIN_CONTRACT_TYPE_NAME_ASC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MIN_CONTRACT_TYPE_NAME_DESC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MIN_CONTRACT_TYPE_NAME_DESC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MIN_DAYS_ASC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MIN_DAYS_ASC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MIN_DAYS_DESC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MIN_DAYS_DESC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MIN_EFFECTIVE_DATE_ASC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MIN_EFFECTIVE_DATE_ASC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MIN_EFFECTIVE_DATE_DESC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MIN_EFFECTIVE_DATE_DESC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MIN_FLAG_ASC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MIN_FLAG_ASC',
  EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MIN_FLAG_DESC = 'EMPLOYEE_DAYS_BY_CONTRACT_TYPES_BY_EMPLOYEE_ID__MIN_FLAG_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__COUNT_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__COUNT_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__COUNT_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__COUNT_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_EMERGENCY_CONTACT_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_EMERGENCY_CONTACT_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_EMERGENCY_CONTACT_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_EMERGENCY_CONTACT_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_NAME_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_NAME_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_NAME_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_NAME_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_RELATION_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_RELATION_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_RELATION_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_RELATION_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_REMARK_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_REMARK_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_REMARK_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_REMARK_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_TELEPHONE_NUMBER_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_TELEPHONE_NUMBER_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_TELEPHONE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_TELEPHONE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_TELEPHONE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_TELEPHONE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_TELEPHONE_NUMBER_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_TELEPHONE_NUMBER_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_USER_CREATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_USER_CREATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_USER_CREATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_USER_CREATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_EMERGENCY_CONTACT_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_EMERGENCY_CONTACT_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_EMERGENCY_CONTACT_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_EMERGENCY_CONTACT_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_NAME_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_NAME_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_NAME_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_NAME_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_RELATION_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_RELATION_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_RELATION_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_RELATION_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_REMARK_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_REMARK_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_REMARK_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_REMARK_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_TELEPHONE_NUMBER_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_TELEPHONE_NUMBER_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_TELEPHONE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_TELEPHONE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_TELEPHONE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_TELEPHONE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_TELEPHONE_NUMBER_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_TELEPHONE_NUMBER_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_USER_CREATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_USER_CREATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_USER_CREATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_USER_CREATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__COUNT_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__COUNT_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__COUNT_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__COUNT_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_FILE_ID_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_FILE_ID_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_FILE_ID_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_FILE_ID_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_FILE_NAME_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_FILE_NAME_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_FILE_NAME_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_FILE_NAME_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_FILE_SIZE_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_FILE_SIZE_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_FILE_SIZE_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_FILE_SIZE_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_IS_HIDDEN_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_IS_HIDDEN_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_IS_HIDDEN_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_IS_HIDDEN_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_IS_PRIVATE_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_IS_PRIVATE_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_IS_PRIVATE_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_IS_PRIVATE_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_MIME_TYPE_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_MIME_TYPE_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_MIME_TYPE_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_MIME_TYPE_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_REMARK_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_REMARK_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_REMARK_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_REMARK_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_S3_PATH_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_S3_PATH_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_S3_PATH_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_S3_PATH_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_TITLE_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_TITLE_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_TITLE_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_TITLE_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_USER_CREATED_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_USER_CREATED_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_USER_CREATED_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_USER_CREATED_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_USER_UPDATED_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_USER_UPDATED_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_USER_UPDATED_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_USER_UPDATED_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_VISIBLE_ROLE_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_VISIBLE_ROLE_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_VISIBLE_ROLE_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MAX_VISIBLE_ROLE_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_FILE_ID_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_FILE_ID_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_FILE_ID_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_FILE_ID_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_FILE_NAME_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_FILE_NAME_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_FILE_NAME_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_FILE_NAME_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_FILE_SIZE_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_FILE_SIZE_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_FILE_SIZE_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_FILE_SIZE_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_IS_HIDDEN_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_IS_HIDDEN_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_IS_HIDDEN_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_IS_HIDDEN_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_IS_PRIVATE_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_IS_PRIVATE_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_IS_PRIVATE_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_IS_PRIVATE_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_MIME_TYPE_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_MIME_TYPE_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_MIME_TYPE_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_MIME_TYPE_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_REMARK_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_REMARK_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_REMARK_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_REMARK_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_S3_PATH_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_S3_PATH_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_S3_PATH_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_S3_PATH_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_TITLE_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_TITLE_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_TITLE_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_TITLE_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_USER_CREATED_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_USER_CREATED_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_USER_CREATED_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_USER_CREATED_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_USER_UPDATED_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_USER_UPDATED_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_USER_UPDATED_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_USER_UPDATED_DESC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_VISIBLE_ROLE_ASC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_VISIBLE_ROLE_ASC',
  EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_VISIBLE_ROLE_DESC = 'EMPLOYEE_FILES_BY_EMPLOYEE_ID__MIN_VISIBLE_ROLE_DESC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__COUNT_ASC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__COUNT_ASC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__COUNT_DESC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__COUNT_DESC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_COMPANY_EMAIL_ASC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_COMPANY_EMAIL_ASC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_COMPANY_EMAIL_DESC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_COMPANY_EMAIL_DESC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_REMARK_ASC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_REMARK_ASC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_REMARK_DESC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_REMARK_DESC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_USER_CREATED_ASC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_USER_CREATED_ASC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_USER_CREATED_DESC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_USER_CREATED_DESC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_ASC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_ASC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_DESC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_DESC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_COMPANY_EMAIL_ASC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_COMPANY_EMAIL_ASC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_COMPANY_EMAIL_DESC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_COMPANY_EMAIL_DESC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_REMARK_ASC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_REMARK_ASC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_REMARK_DESC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_REMARK_DESC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_USER_CREATED_ASC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_USER_CREATED_ASC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_USER_CREATED_DESC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_USER_CREATED_DESC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_ASC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_ASC',
  EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_DESC = 'EMPLOYEE_GROUPS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_DESC',
  EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__ALL_EMPLOYEE_ID_LIST_ASC = 'EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__ALL_EMPLOYEE_ID_LIST_ASC',
  EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__ALL_EMPLOYEE_ID_LIST_DESC = 'EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__ALL_EMPLOYEE_ID_LIST_DESC',
  EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_GROUP_LATEST_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__COUNT_ASC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__COUNT_ASC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__COUNT_DESC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__COUNT_DESC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_ALL_EMPLOYEE_ID_LIST_ASC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_ALL_EMPLOYEE_ID_LIST_ASC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_ALL_EMPLOYEE_ID_LIST_DESC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_ALL_EMPLOYEE_ID_LIST_DESC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_COMPANY_EMAIL_ASC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_COMPANY_EMAIL_ASC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_COMPANY_EMAIL_DESC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_COMPANY_EMAIL_DESC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_REMARK_ASC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_REMARK_ASC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_REMARK_DESC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_REMARK_DESC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_USER_CREATED_ASC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_USER_CREATED_ASC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_USER_CREATED_DESC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_USER_CREATED_DESC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_ASC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_ASC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_DESC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_DESC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_ALL_EMPLOYEE_ID_LIST_ASC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_ALL_EMPLOYEE_ID_LIST_ASC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_ALL_EMPLOYEE_ID_LIST_DESC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_ALL_EMPLOYEE_ID_LIST_DESC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_COMPANY_EMAIL_ASC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_COMPANY_EMAIL_ASC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_COMPANY_EMAIL_DESC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_COMPANY_EMAIL_DESC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_REMARK_ASC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_REMARK_ASC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_REMARK_DESC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_REMARK_DESC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_USER_CREATED_ASC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_USER_CREATED_ASC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_USER_CREATED_DESC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_USER_CREATED_DESC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_ASC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_ASC',
  EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_DESC = 'EMPLOYEE_GROUP_LISTS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  EMPLOYEE_ID_TYPE_BY_ID_TYPE__CODE_ASC = 'EMPLOYEE_ID_TYPE_BY_ID_TYPE__CODE_ASC',
  EMPLOYEE_ID_TYPE_BY_ID_TYPE__CODE_DESC = 'EMPLOYEE_ID_TYPE_BY_ID_TYPE__CODE_DESC',
  EMPLOYEE_ID_TYPE_BY_ID_TYPE__COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_ID_TYPE_BY_ID_TYPE__COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_ID_TYPE_BY_ID_TYPE__COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_ID_TYPE_BY_ID_TYPE__COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_ID_TYPE_BY_ID_TYPE__DATE_CREATED_ASC = 'EMPLOYEE_ID_TYPE_BY_ID_TYPE__DATE_CREATED_ASC',
  EMPLOYEE_ID_TYPE_BY_ID_TYPE__DATE_CREATED_DESC = 'EMPLOYEE_ID_TYPE_BY_ID_TYPE__DATE_CREATED_DESC',
  EMPLOYEE_ID_TYPE_BY_ID_TYPE__DATE_UPDATED_ASC = 'EMPLOYEE_ID_TYPE_BY_ID_TYPE__DATE_UPDATED_ASC',
  EMPLOYEE_ID_TYPE_BY_ID_TYPE__DATE_UPDATED_DESC = 'EMPLOYEE_ID_TYPE_BY_ID_TYPE__DATE_UPDATED_DESC',
  EMPLOYEE_ID_TYPE_BY_ID_TYPE__DISPLAY_NAME_ASC = 'EMPLOYEE_ID_TYPE_BY_ID_TYPE__DISPLAY_NAME_ASC',
  EMPLOYEE_ID_TYPE_BY_ID_TYPE__DISPLAY_NAME_DESC = 'EMPLOYEE_ID_TYPE_BY_ID_TYPE__DISPLAY_NAME_DESC',
  EMPLOYEE_ID_TYPE_BY_ID_TYPE__DISPLAY_ORDER_ASC = 'EMPLOYEE_ID_TYPE_BY_ID_TYPE__DISPLAY_ORDER_ASC',
  EMPLOYEE_ID_TYPE_BY_ID_TYPE__DISPLAY_ORDER_DESC = 'EMPLOYEE_ID_TYPE_BY_ID_TYPE__DISPLAY_ORDER_DESC',
  EMPLOYEE_ID_TYPE_BY_ID_TYPE__EMPLOYEE_ID_TYPE_ID_ASC = 'EMPLOYEE_ID_TYPE_BY_ID_TYPE__EMPLOYEE_ID_TYPE_ID_ASC',
  EMPLOYEE_ID_TYPE_BY_ID_TYPE__EMPLOYEE_ID_TYPE_ID_DESC = 'EMPLOYEE_ID_TYPE_BY_ID_TYPE__EMPLOYEE_ID_TYPE_ID_DESC',
  EMPLOYEE_ID_TYPE_BY_ID_TYPE__REMARK_ASC = 'EMPLOYEE_ID_TYPE_BY_ID_TYPE__REMARK_ASC',
  EMPLOYEE_ID_TYPE_BY_ID_TYPE__REMARK_DESC = 'EMPLOYEE_ID_TYPE_BY_ID_TYPE__REMARK_DESC',
  EMPLOYEE_ID_TYPE_BY_ID_TYPE__USER_CREATED_ASC = 'EMPLOYEE_ID_TYPE_BY_ID_TYPE__USER_CREATED_ASC',
  EMPLOYEE_ID_TYPE_BY_ID_TYPE__USER_CREATED_DESC = 'EMPLOYEE_ID_TYPE_BY_ID_TYPE__USER_CREATED_DESC',
  EMPLOYEE_ID_TYPE_BY_ID_TYPE__USER_UPDATED_ASC = 'EMPLOYEE_ID_TYPE_BY_ID_TYPE__USER_UPDATED_ASC',
  EMPLOYEE_ID_TYPE_BY_ID_TYPE__USER_UPDATED_DESC = 'EMPLOYEE_ID_TYPE_BY_ID_TYPE__USER_UPDATED_DESC',
  EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__DATE_CREATED_ASC = 'EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__DATE_CREATED_ASC',
  EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__DATE_CREATED_DESC = 'EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__DATE_CREATED_DESC',
  EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__DATE_UPDATED_ASC = 'EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__DATE_UPDATED_ASC',
  EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__DATE_UPDATED_DESC = 'EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__DATE_UPDATED_DESC',
  EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__DISPLAY_NAME_ASC = 'EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__DISPLAY_NAME_ASC',
  EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__DISPLAY_NAME_DESC = 'EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__DISPLAY_NAME_DESC',
  EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__EMPLOYEE_RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__EMPLOYEE_RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__EMPLOYEE_RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__EMPLOYEE_RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__EMPLOYEE_RESIGNATION_REASON_ORDER_ASC = 'EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__EMPLOYEE_RESIGNATION_REASON_ORDER_ASC',
  EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__EMPLOYEE_RESIGNATION_REASON_ORDER_DESC = 'EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__EMPLOYEE_RESIGNATION_REASON_ORDER_DESC',
  EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__IS_FOR_CONTRACT_ASC = 'EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__IS_FOR_CONTRACT_ASC',
  EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__IS_FOR_CONTRACT_DESC = 'EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__IS_FOR_CONTRACT_DESC',
  EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__IS_FOR_TRANSFER_ASC = 'EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__IS_FOR_TRANSFER_ASC',
  EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__IS_FOR_TRANSFER_DESC = 'EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__IS_FOR_TRANSFER_DESC',
  EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__REMARK_ASC = 'EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__REMARK_ASC',
  EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__REMARK_DESC = 'EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__REMARK_DESC',
  EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__USER_CREATED_ASC = 'EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__USER_CREATED_ASC',
  EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__USER_CREATED_DESC = 'EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__USER_CREATED_DESC',
  EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__USER_UPDATED_ASC = 'EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__USER_UPDATED_ASC',
  EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__USER_UPDATED_DESC = 'EMPLOYEE_RESIGNATION_REASON_BY_RESIGNATION_REASON_ID__USER_UPDATED_DESC',
  EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__DATE_CREATED_ASC = 'EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__DATE_CREATED_ASC',
  EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__DATE_CREATED_DESC = 'EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__DATE_CREATED_DESC',
  EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__DISPLAY_NAME_ASC = 'EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__DISPLAY_NAME_ASC',
  EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__DISPLAY_NAME_DESC = 'EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__DISPLAY_NAME_DESC',
  EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__EMPLOYEE_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__EMPLOYEE_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__EMPLOYEE_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__EMPLOYEE_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__EMPLOYEE_RESIGNATION_TYPE_ORDER_ASC = 'EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__EMPLOYEE_RESIGNATION_TYPE_ORDER_ASC',
  EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__EMPLOYEE_RESIGNATION_TYPE_ORDER_DESC = 'EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__EMPLOYEE_RESIGNATION_TYPE_ORDER_DESC',
  EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__IS_FOR_CONTRACT_ASC = 'EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__IS_FOR_CONTRACT_ASC',
  EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__IS_FOR_CONTRACT_DESC = 'EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__IS_FOR_CONTRACT_DESC',
  EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__IS_FOR_TRANSFER_ASC = 'EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__IS_FOR_TRANSFER_ASC',
  EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__IS_FOR_TRANSFER_DESC = 'EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__IS_FOR_TRANSFER_DESC',
  EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__REMARK_ASC = 'EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__REMARK_ASC',
  EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__REMARK_DESC = 'EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__REMARK_DESC',
  EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__USER_CREATED_ASC = 'EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__USER_CREATED_ASC',
  EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__USER_CREATED_DESC = 'EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__USER_CREATED_DESC',
  EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__USER_UPDATED_ASC = 'EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__USER_UPDATED_ASC',
  EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__USER_UPDATED_DESC = 'EMPLOYEE_RESIGNATION_TYPE_BY_RESIGNATION_TYPE_ID__USER_UPDATED_DESC',
  EMPLOYEE_STATUS_BY_STATUS__DATE_CREATED_ASC = 'EMPLOYEE_STATUS_BY_STATUS__DATE_CREATED_ASC',
  EMPLOYEE_STATUS_BY_STATUS__DATE_CREATED_DESC = 'EMPLOYEE_STATUS_BY_STATUS__DATE_CREATED_DESC',
  EMPLOYEE_STATUS_BY_STATUS__DATE_UPDATED_ASC = 'EMPLOYEE_STATUS_BY_STATUS__DATE_UPDATED_ASC',
  EMPLOYEE_STATUS_BY_STATUS__DATE_UPDATED_DESC = 'EMPLOYEE_STATUS_BY_STATUS__DATE_UPDATED_DESC',
  EMPLOYEE_STATUS_BY_STATUS__DISPLAY_NAME_ASC = 'EMPLOYEE_STATUS_BY_STATUS__DISPLAY_NAME_ASC',
  EMPLOYEE_STATUS_BY_STATUS__DISPLAY_NAME_DESC = 'EMPLOYEE_STATUS_BY_STATUS__DISPLAY_NAME_DESC',
  EMPLOYEE_STATUS_BY_STATUS__EMPLOYEE_STATUS_KEY_ASC = 'EMPLOYEE_STATUS_BY_STATUS__EMPLOYEE_STATUS_KEY_ASC',
  EMPLOYEE_STATUS_BY_STATUS__EMPLOYEE_STATUS_KEY_DESC = 'EMPLOYEE_STATUS_BY_STATUS__EMPLOYEE_STATUS_KEY_DESC',
  EMPLOYEE_STATUS_BY_STATUS__EMPLOYEE_STATUS_ORDER_ASC = 'EMPLOYEE_STATUS_BY_STATUS__EMPLOYEE_STATUS_ORDER_ASC',
  EMPLOYEE_STATUS_BY_STATUS__EMPLOYEE_STATUS_ORDER_DESC = 'EMPLOYEE_STATUS_BY_STATUS__EMPLOYEE_STATUS_ORDER_DESC',
  EMPLOYEE_STATUS_BY_STATUS__REMARK_ASC = 'EMPLOYEE_STATUS_BY_STATUS__REMARK_ASC',
  EMPLOYEE_STATUS_BY_STATUS__REMARK_DESC = 'EMPLOYEE_STATUS_BY_STATUS__REMARK_DESC',
  EMPLOYEE_STATUS_BY_STATUS__SHORT_NAME_ASC = 'EMPLOYEE_STATUS_BY_STATUS__SHORT_NAME_ASC',
  EMPLOYEE_STATUS_BY_STATUS__SHORT_NAME_DESC = 'EMPLOYEE_STATUS_BY_STATUS__SHORT_NAME_DESC',
  EMPLOYEE_STATUS_BY_STATUS__USER_CREATED_ASC = 'EMPLOYEE_STATUS_BY_STATUS__USER_CREATED_ASC',
  EMPLOYEE_STATUS_BY_STATUS__USER_CREATED_DESC = 'EMPLOYEE_STATUS_BY_STATUS__USER_CREATED_DESC',
  EMPLOYEE_STATUS_BY_STATUS__USER_UPDATED_ASC = 'EMPLOYEE_STATUS_BY_STATUS__USER_UPDATED_ASC',
  EMPLOYEE_STATUS_BY_STATUS__USER_UPDATED_DESC = 'EMPLOYEE_STATUS_BY_STATUS__USER_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__COUNT_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__COUNT_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__COUNT_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__COUNT_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_COMPANY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_COMPANY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_COMPANY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_COMPANY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_DEPARTMENT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_DEPARTMENT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_DEPARTMENT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_DEPARTMENT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_DIVISION_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_DIVISION_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_DIVISION_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_DIVISION_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_EFFECTIVE_DATE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_EFFECTIVE_DATE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_EFFECTIVE_DATE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_EFFECTIVE_DATE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_JOB_TITLE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_JOB_TITLE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_JOB_TITLE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_JOB_TITLE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_OFFICE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_OFFICE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_OFFICE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_OFFICE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_REMARK_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_REMARK_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_REMARK_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_REMARK_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_TEAM_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_TEAM_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_TEAM_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_USER_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_USER_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_USER_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_USER_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_USER_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_USER_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_USER_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MAX_USER_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_COMPANY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_COMPANY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_COMPANY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_COMPANY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_DEPARTMENT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_DEPARTMENT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_DEPARTMENT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_DEPARTMENT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_DIVISION_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_DIVISION_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_DIVISION_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_DIVISION_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_EFFECTIVE_DATE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_EFFECTIVE_DATE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_EFFECTIVE_DATE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_EFFECTIVE_DATE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_JOB_TITLE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_JOB_TITLE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_JOB_TITLE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_JOB_TITLE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_OFFICE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_OFFICE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_OFFICE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_OFFICE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_REMARK_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_REMARK_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_REMARK_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_REMARK_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_TEAM_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_TEAM_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_TEAM_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_USER_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_USER_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_USER_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_USER_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_USER_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_USER_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_USER_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_EMPLOYEE_ID__MIN_USER_UPDATED_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__COUNT_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__COUNT_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__COUNT_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__COUNT_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_BUSINESS_UNIT_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_BUSINESS_UNIT_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_BUSINESS_UNIT_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_BUSINESS_UNIT_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_CITY_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_CITY_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_CITY_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_CITY_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_CITY_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_CITY_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_CITY_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_CITY_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_COMPANY_EMAIL_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_COMPANY_EMAIL_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_COMPANY_EMAIL_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_COMPANY_EMAIL_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_COMPANY_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_COMPANY_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_COMPANY_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_COMPANY_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_COMPANY_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_COMPANY_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_COMPANY_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_COMPANY_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_CONTRACT_TYPE_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_CONTRACT_TYPE_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_CONTRACT_TYPE_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_CONTRACT_TYPE_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_DEPARTMENT_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_DEPARTMENT_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_DEPARTMENT_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_DEPARTMENT_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_DEPARTMENT_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_DEPARTMENT_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_DEPARTMENT_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_DEPARTMENT_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_DIVISION_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_DIVISION_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_DIVISION_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_DIVISION_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_DIVISION_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_DIVISION_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_DIVISION_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_DIVISION_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EFFECTIVE_DATE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EFFECTIVE_DATE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EFFECTIVE_DATE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EFFECTIVE_DATE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_LEVEL_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_LEVEL_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_LEVEL_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_LEVEL_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_LEVEL_ORDER_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_LEVEL_ORDER_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_LEVEL_ORDER_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_LEVEL_ORDER_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_FLAG_DEMOTION_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_FLAG_DEMOTION_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_FLAG_DEMOTION_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_FLAG_DEMOTION_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_FLAG_PROMOTION_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_FLAG_PROMOTION_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_FLAG_PROMOTION_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_FLAG_PROMOTION_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_FLAG_TRANSFER_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_FLAG_TRANSFER_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_FLAG_TRANSFER_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_FLAG_TRANSFER_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_FULL_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_FULL_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_FULL_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_FULL_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_JOB_TITLE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_JOB_TITLE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_JOB_TITLE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_JOB_TITLE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_JOIN_DATE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_JOIN_DATE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_JOIN_DATE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_JOIN_DATE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_NICKNAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_NICKNAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_NICKNAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_NICKNAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_OFFICE_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_OFFICE_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_OFFICE_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_OFFICE_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_OFFICE_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_OFFICE_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_OFFICE_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_OFFICE_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_BUSINESS_UNIT_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_BUSINESS_UNIT_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_BUSINESS_UNIT_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_BUSINESS_UNIT_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_CITY_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_CITY_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_CITY_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_CITY_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_CITY_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_CITY_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_CITY_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_CITY_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_COMPANY_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_COMPANY_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_COMPANY_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_COMPANY_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_COMPANY_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_COMPANY_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_COMPANY_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_COMPANY_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_CONTRACT_TYPE_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_CONTRACT_TYPE_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_CONTRACT_TYPE_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_CONTRACT_TYPE_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_DEPARTMENT_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_DEPARTMENT_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_DEPARTMENT_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_DEPARTMENT_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_DEPARTMENT_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_DEPARTMENT_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_DEPARTMENT_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_DEPARTMENT_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_DIVISION_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_DIVISION_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_DIVISION_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_DIVISION_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_DIVISION_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_DIVISION_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_DIVISION_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_DIVISION_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_EMPLOYEE_LEVEL_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_EMPLOYEE_LEVEL_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_EMPLOYEE_LEVEL_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_EMPLOYEE_LEVEL_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_EMPLOYEE_LEVEL_ORDER_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_EMPLOYEE_LEVEL_ORDER_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_EMPLOYEE_LEVEL_ORDER_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_EMPLOYEE_LEVEL_ORDER_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_JOB_TITLE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_JOB_TITLE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_JOB_TITLE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_JOB_TITLE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_OFFICE_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_OFFICE_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_OFFICE_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_OFFICE_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_OFFICE_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_OFFICE_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_OFFICE_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_OFFICE_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_TEAM_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_TEAM_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_TEAM_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_PREVIOUS_TEAM_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_TEAM_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_TEAM_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_TEAM_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MAX_TEAM_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_BUSINESS_UNIT_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_BUSINESS_UNIT_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_BUSINESS_UNIT_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_BUSINESS_UNIT_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_CITY_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_CITY_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_CITY_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_CITY_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_CITY_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_CITY_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_CITY_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_CITY_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_COMPANY_EMAIL_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_COMPANY_EMAIL_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_COMPANY_EMAIL_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_COMPANY_EMAIL_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_COMPANY_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_COMPANY_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_COMPANY_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_COMPANY_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_COMPANY_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_COMPANY_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_COMPANY_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_COMPANY_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_CONTRACT_TYPE_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_CONTRACT_TYPE_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_CONTRACT_TYPE_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_CONTRACT_TYPE_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_DEPARTMENT_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_DEPARTMENT_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_DEPARTMENT_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_DEPARTMENT_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_DEPARTMENT_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_DEPARTMENT_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_DEPARTMENT_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_DEPARTMENT_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_DIVISION_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_DIVISION_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_DIVISION_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_DIVISION_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_DIVISION_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_DIVISION_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_DIVISION_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_DIVISION_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EFFECTIVE_DATE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EFFECTIVE_DATE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EFFECTIVE_DATE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EFFECTIVE_DATE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_LEVEL_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_LEVEL_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_LEVEL_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_LEVEL_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_LEVEL_ORDER_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_LEVEL_ORDER_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_LEVEL_ORDER_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_LEVEL_ORDER_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_FLAG_DEMOTION_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_FLAG_DEMOTION_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_FLAG_DEMOTION_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_FLAG_DEMOTION_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_FLAG_PROMOTION_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_FLAG_PROMOTION_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_FLAG_PROMOTION_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_FLAG_PROMOTION_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_FLAG_TRANSFER_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_FLAG_TRANSFER_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_FLAG_TRANSFER_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_FLAG_TRANSFER_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_FULL_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_FULL_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_FULL_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_FULL_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_JOB_TITLE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_JOB_TITLE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_JOB_TITLE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_JOB_TITLE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_JOIN_DATE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_JOIN_DATE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_JOIN_DATE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_JOIN_DATE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_NICKNAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_NICKNAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_NICKNAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_NICKNAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_OFFICE_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_OFFICE_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_OFFICE_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_OFFICE_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_OFFICE_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_OFFICE_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_OFFICE_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_OFFICE_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_BUSINESS_UNIT_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_BUSINESS_UNIT_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_BUSINESS_UNIT_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_BUSINESS_UNIT_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_CITY_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_CITY_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_CITY_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_CITY_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_CITY_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_CITY_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_CITY_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_CITY_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_COMPANY_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_COMPANY_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_COMPANY_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_COMPANY_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_COMPANY_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_COMPANY_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_COMPANY_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_COMPANY_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_CONTRACT_TYPE_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_CONTRACT_TYPE_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_CONTRACT_TYPE_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_CONTRACT_TYPE_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_DEPARTMENT_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_DEPARTMENT_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_DEPARTMENT_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_DEPARTMENT_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_DEPARTMENT_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_DEPARTMENT_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_DEPARTMENT_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_DEPARTMENT_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_DIVISION_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_DIVISION_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_DIVISION_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_DIVISION_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_DIVISION_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_DIVISION_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_DIVISION_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_DIVISION_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_EMPLOYEE_LEVEL_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_EMPLOYEE_LEVEL_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_EMPLOYEE_LEVEL_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_EMPLOYEE_LEVEL_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_EMPLOYEE_LEVEL_ORDER_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_EMPLOYEE_LEVEL_ORDER_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_EMPLOYEE_LEVEL_ORDER_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_EMPLOYEE_LEVEL_ORDER_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_JOB_TITLE_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_JOB_TITLE_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_JOB_TITLE_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_JOB_TITLE_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_OFFICE_ID_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_OFFICE_ID_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_OFFICE_ID_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_OFFICE_ID_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_OFFICE_NAME_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_OFFICE_NAME_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_OFFICE_NAME_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_OFFICE_NAME_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_TEAM_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_TEAM_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_TEAM_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_PREVIOUS_TEAM_DESC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_TEAM_ASC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_TEAM_ASC',
  EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_TEAM_DESC = 'EMPLOYEE_TRANSFER_HISTORIES_BY_EMPLOYEE_ID__MIN_TEAM_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__ALL_DATES_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__ALL_DATES_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__ALL_DATES_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__ALL_DATES_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__BASE_DATE_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__BASE_DATE_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__BASE_DATE_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__BASE_DATE_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__COMPANY_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__COMPANY_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__COMPANY_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__COMPANY_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__COMPANY_ID_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__COMPANY_ID_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__COMPANY_ID_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__COMPANY_ID_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__RANK_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__RANK_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__RANK_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__RANK_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__WORKING_DAYS_ASC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__WORKING_DAYS_ASC',
  EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__WORKING_DAYS_DESC = 'EMPLOYEE_WORKING_DAY_STATISTICS_ALL_BY_EMPLOYEE_ID__WORKING_DAYS_DESC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__COUNT_ASC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__COUNT_ASC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__COUNT_DESC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__COUNT_DESC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MAX_ERROR_MESSAGE_ASC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MAX_ERROR_MESSAGE_ASC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MAX_ERROR_MESSAGE_DESC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MAX_ERROR_MESSAGE_DESC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_ASC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_ASC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_DESC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_DESC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MAX_REMARK_ASC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MAX_REMARK_ASC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MAX_REMARK_DESC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MAX_REMARK_DESC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MIN_ERROR_MESSAGE_ASC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MIN_ERROR_MESSAGE_ASC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MIN_ERROR_MESSAGE_DESC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MIN_ERROR_MESSAGE_DESC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_ASC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_ASC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_DESC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_DESC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MIN_REMARK_ASC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MIN_REMARK_ASC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MIN_REMARK_DESC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EMPLOYEE_ID__MIN_REMARK_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__COUNT_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__COUNT_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__COUNT_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__COUNT_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_EXTERNAL_ACCOUNT_EMPLOYEE_ID_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_EXTERNAL_ACCOUNT_EMPLOYEE_ID_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_EXTERNAL_ACCOUNT_EMPLOYEE_ID_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_EXTERNAL_ACCOUNT_EMPLOYEE_ID_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_EXTERNAL_ACCOUNT_KEY_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_EXTERNAL_ACCOUNT_KEY_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_EXTERNAL_ACCOUNT_KEY_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_EXTERNAL_ACCOUNT_KEY_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_EXTERNAL_ACCOUNT_STATUS_CACHED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_EXTERNAL_ACCOUNT_STATUS_CACHED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_EXTERNAL_ACCOUNT_STATUS_CACHED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_EXTERNAL_ACCOUNT_STATUS_CACHED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_REMARK_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_REMARK_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_REMARK_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_REMARK_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_USER_CREATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_USER_CREATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_USER_CREATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_USER_CREATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_USER_UPDATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_USER_UPDATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_USER_UPDATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MAX_USER_UPDATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_EXTERNAL_ACCOUNT_EMPLOYEE_ID_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_EXTERNAL_ACCOUNT_EMPLOYEE_ID_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_EXTERNAL_ACCOUNT_EMPLOYEE_ID_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_EXTERNAL_ACCOUNT_EMPLOYEE_ID_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_EXTERNAL_ACCOUNT_KEY_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_EXTERNAL_ACCOUNT_KEY_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_EXTERNAL_ACCOUNT_KEY_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_EXTERNAL_ACCOUNT_KEY_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_EXTERNAL_ACCOUNT_STATUS_CACHED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_EXTERNAL_ACCOUNT_STATUS_CACHED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_EXTERNAL_ACCOUNT_STATUS_CACHED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_EXTERNAL_ACCOUNT_STATUS_CACHED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_REMARK_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_REMARK_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_REMARK_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_REMARK_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_USER_CREATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_USER_CREATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_USER_CREATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_USER_CREATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_USER_UPDATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_USER_UPDATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_USER_UPDATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EMPLOYEE_ID__MIN_USER_UPDATED_DESC',
  FULL_NAME_ASC = 'FULL_NAME_ASC',
  FULL_NAME_DESC = 'FULL_NAME_DESC',
  FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  FULL_NAME_KANA_ASC = 'FULL_NAME_KANA_ASC',
  FULL_NAME_KANA_DESC = 'FULL_NAME_KANA_DESC',
  GENDER_ASC = 'GENDER_ASC',
  GENDER_DESC = 'GENDER_DESC',
  ID_EXPIRY_DATE_ASC = 'ID_EXPIRY_DATE_ASC',
  ID_EXPIRY_DATE_DESC = 'ID_EXPIRY_DATE_DESC',
  ID_ISSUED_DATE_ASC = 'ID_ISSUED_DATE_ASC',
  ID_ISSUED_DATE_DESC = 'ID_ISSUED_DATE_DESC',
  ID_ISSUED_PLACE_ASC = 'ID_ISSUED_PLACE_ASC',
  ID_ISSUED_PLACE_DESC = 'ID_ISSUED_PLACE_DESC',
  ID_NUMBER_ASC = 'ID_NUMBER_ASC',
  ID_NUMBER_DESC = 'ID_NUMBER_DESC',
  ID_TYPE_ASC = 'ID_TYPE_ASC',
  ID_TYPE_DESC = 'ID_TYPE_DESC',
  JOIN_DATE_ASC = 'JOIN_DATE_ASC',
  JOIN_DATE_DESC = 'JOIN_DATE_DESC',
  LAST_WORKING_DATE_ASC = 'LAST_WORKING_DATE_ASC',
  LAST_WORKING_DATE_DESC = 'LAST_WORKING_DATE_DESC',
  MARITAL_STATUS_ASC = 'MARITAL_STATUS_ASC',
  MARITAL_STATUS_DESC = 'MARITAL_STATUS_DESC',
  MOBILE_NUMBER_ASC = 'MOBILE_NUMBER_ASC',
  MOBILE_NUMBER_COUNTRY_ID_ASC = 'MOBILE_NUMBER_COUNTRY_ID_ASC',
  MOBILE_NUMBER_COUNTRY_ID_DESC = 'MOBILE_NUMBER_COUNTRY_ID_DESC',
  MOBILE_NUMBER_DESC = 'MOBILE_NUMBER_DESC',
  NATIONALITY_ASC = 'NATIONALITY_ASC',
  NATIONALITY_DESC = 'NATIONALITY_DESC',
  NATURAL = 'NATURAL',
  NHI_DEPENDENCIES_ASC = 'NHI_DEPENDENCIES_ASC',
  NHI_DEPENDENCIES_DESC = 'NHI_DEPENDENCIES_DESC',
  NICKNAME_ASC = 'NICKNAME_ASC',
  NICKNAME_DESC = 'NICKNAME_DESC',
  NUMBER_OF_DEPENDENCIES_ASC = 'NUMBER_OF_DEPENDENCIES_ASC',
  NUMBER_OF_DEPENDENCIES_DESC = 'NUMBER_OF_DEPENDENCIES_DESC',
  PASSPORT_EXPIRY_DATE_ASC = 'PASSPORT_EXPIRY_DATE_ASC',
  PASSPORT_EXPIRY_DATE_DESC = 'PASSPORT_EXPIRY_DATE_DESC',
  PASSPORT_ISSUED_DATE_ASC = 'PASSPORT_ISSUED_DATE_ASC',
  PASSPORT_ISSUED_DATE_DESC = 'PASSPORT_ISSUED_DATE_DESC',
  PASSPORT_ISSUED_PLACE_ASC = 'PASSPORT_ISSUED_PLACE_ASC',
  PASSPORT_ISSUED_PLACE_DESC = 'PASSPORT_ISSUED_PLACE_DESC',
  PASSPORT_NAME_ASC = 'PASSPORT_NAME_ASC',
  PASSPORT_NAME_DESC = 'PASSPORT_NAME_DESC',
  PASSPORT_NUMBER_ASC = 'PASSPORT_NUMBER_ASC',
  PASSPORT_NUMBER_DESC = 'PASSPORT_NUMBER_DESC',
  PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_IS_LOCKED_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_IS_LOCKED_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_IS_LOCKED_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_IS_LOCKED_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_IS_LOCKED_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_IS_LOCKED_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_IS_LOCKED_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_IS_LOCKED_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_MID_LONG_TERM_GOAL_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_MID_LONG_TERM_GOAL_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_MID_LONG_TERM_GOAL_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_MID_LONG_TERM_GOAL_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_NEED_INPUT_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_NEED_INPUT_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_NEED_INPUT_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_NEED_INPUT_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_NEED_INPUT_TARGET_KPI_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_NEED_INPUT_TARGET_KPI_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_NEED_INPUT_TARGET_KPI_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_NEED_INPUT_TARGET_KPI_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_NEED_INPUT_TARGET_QUALITATIVE_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_NEED_INPUT_TARGET_QUALITATIVE_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_NEED_INPUT_TARGET_QUALITATIVE_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_NEED_INPUT_TARGET_QUALITATIVE_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_IS_LOCKED_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_IS_LOCKED_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_IS_LOCKED_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_IS_LOCKED_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_IS_LOCKED_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_IS_LOCKED_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_IS_LOCKED_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_IS_LOCKED_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_MID_LONG_TERM_GOAL_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_MID_LONG_TERM_GOAL_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_MID_LONG_TERM_GOAL_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_MID_LONG_TERM_GOAL_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_NEED_INPUT_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_NEED_INPUT_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_NEED_INPUT_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_NEED_INPUT_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_NEED_INPUT_TARGET_KPI_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_NEED_INPUT_TARGET_KPI_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_NEED_INPUT_TARGET_KPI_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_NEED_INPUT_TARGET_KPI_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_NEED_INPUT_TARGET_QUALITATIVE_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_NEED_INPUT_TARGET_QUALITATIVE_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_NEED_INPUT_TARGET_QUALITATIVE_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_NEED_INPUT_TARGET_QUALITATIVE_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_EVALUATOR_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_EVALUATOR_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_EVALUATOR_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_EVALUATOR_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_EVALUATOR_TYPE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_EVALUATOR_TYPE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_EVALUATOR_TYPE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_EVALUATOR_TYPE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_EVALUATOR_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_EVALUATOR_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_EVALUATOR_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_EVALUATOR_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_EVALUATOR_TYPE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_EVALUATOR_TYPE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_EVALUATOR_TYPE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_EVALUATOR_TYPE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_EVALUATOR_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_FEEDBACK_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_FEEDBACK_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_FEEDBACK_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_FEEDBACK_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_FEEDBACK_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_FEEDBACK_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_FEEDBACK_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_FEEDBACK_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_FEEDBACK_EMPLOYEE_NAME_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_FEEDBACK_EMPLOYEE_NAME_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_FEEDBACK_EMPLOYEE_NAME_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_FEEDBACK_EMPLOYEE_NAME_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_FEEDBACK_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_FEEDBACK_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_FEEDBACK_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_FEEDBACK_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_FEEDBACK_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_FEEDBACK_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_FEEDBACK_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_FEEDBACK_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_FEEDBACK_EMPLOYEE_NAME_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_FEEDBACK_EMPLOYEE_NAME_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_FEEDBACK_EMPLOYEE_NAME_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_FEEDBACK_EMPLOYEE_NAME_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_FEEDBACK_EMPLOYEE_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MAX_IS_FILLED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MAX_IS_FILLED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MAX_IS_FILLED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MAX_IS_FILLED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MAX_MONTH_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MAX_MONTH_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MAX_MONTH_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MAX_MONTH_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MIN_IS_FILLED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MIN_IS_FILLED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MIN_IS_FILLED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MIN_IS_FILLED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MIN_MONTH_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MIN_MONTH_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MIN_MONTH_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MIN_MONTH_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_EMPLOYEE_ID__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_EMPLOYEE_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_CAN_MODIFY_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_CAN_MODIFY_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_CAN_MODIFY_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_CAN_MODIFY_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_VIEWER_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_VIEWER_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_VIEWER_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_VIEWER_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_CAN_MODIFY_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_CAN_MODIFY_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_CAN_MODIFY_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_CAN_MODIFY_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_VIEWER_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_VIEWER_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_VIEWER_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_VIEWER_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_EMPLOYEE_ID__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_CAN_MODIFY_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_CAN_MODIFY_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_CAN_MODIFY_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_CAN_MODIFY_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_VIEWER_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_VIEWER_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_VIEWER_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_VIEWER_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_CAN_MODIFY_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_CAN_MODIFY_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_CAN_MODIFY_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_CAN_MODIFY_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_VIEWER_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_VIEWER_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_VIEWER_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_VIEWER_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_VIEWER_EMPLOYEE_ID__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_EMPLOYEE_ID__MIN_YEAR_DESC',
  PERMANENT_ADDRESS_ASC = 'PERMANENT_ADDRESS_ASC',
  PERMANENT_ADDRESS_DESC = 'PERMANENT_ADDRESS_DESC',
  PERSONAL_EMAIL_ASC = 'PERSONAL_EMAIL_ASC',
  PERSONAL_EMAIL_DESC = 'PERSONAL_EMAIL_DESC',
  PLACE_OF_BIRTH_ASC = 'PLACE_OF_BIRTH_ASC',
  PLACE_OF_BIRTH_DESC = 'PLACE_OF_BIRTH_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PRIMARY_MANAGER_ID_ASC = 'PRIMARY_MANAGER_ID_ASC',
  PRIMARY_MANAGER_ID_DESC = 'PRIMARY_MANAGER_ID_DESC',
  PROBATION_END_ASC = 'PROBATION_END_ASC',
  PROBATION_END_DESC = 'PROBATION_END_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__COUNT_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__COUNT_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__COUNT_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__COUNT_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_DATE_REMINDER_SENT_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_DATE_REMINDER_SENT_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_DATE_REMINDER_SENT_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_DATE_REMINDER_SENT_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_GOOGLE_CALENDAR_INFO_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_GOOGLE_CALENDAR_INFO_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_GOOGLE_CALENDAR_INFO_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_GOOGLE_CALENDAR_INFO_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_IS_FINAL_OR_EXTENDED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_IS_FINAL_OR_EXTENDED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_IS_FINAL_OR_EXTENDED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_IS_FINAL_OR_EXTENDED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_REMARK_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_REMARK_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_REMARK_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_REMARK_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_RESULT_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_RESULT_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_RESULT_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_RESULT_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_REVIEW_DATE_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_REVIEW_DATE_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_REVIEW_DATE_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_REVIEW_DATE_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_REVIEW_DATE_PLANNED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_REVIEW_DATE_PLANNED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_REVIEW_DATE_PLANNED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_REVIEW_DATE_PLANNED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_SUBMIT_DATE_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_SUBMIT_DATE_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_SUBMIT_DATE_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_SUBMIT_DATE_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_DATE_REMINDER_SENT_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_DATE_REMINDER_SENT_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_DATE_REMINDER_SENT_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_DATE_REMINDER_SENT_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_GOOGLE_CALENDAR_INFO_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_GOOGLE_CALENDAR_INFO_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_GOOGLE_CALENDAR_INFO_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_GOOGLE_CALENDAR_INFO_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_IS_FINAL_OR_EXTENDED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_IS_FINAL_OR_EXTENDED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_IS_FINAL_OR_EXTENDED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_IS_FINAL_OR_EXTENDED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_REMARK_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_REMARK_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_REMARK_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_REMARK_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_RESULT_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_RESULT_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_RESULT_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_RESULT_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_REVIEW_DATE_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_REVIEW_DATE_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_REVIEW_DATE_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_REVIEW_DATE_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_REVIEW_DATE_PLANNED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_REVIEW_DATE_PLANNED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_REVIEW_DATE_PLANNED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_REVIEW_DATE_PLANNED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_SUBMIT_DATE_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_SUBMIT_DATE_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_SUBMIT_DATE_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_SUBMIT_DATE_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__COUNT_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__COUNT_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__COUNT_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__COUNT_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_REVIEWER_EMPLOYEE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_REVIEWER_EMPLOYEE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_REVIEWER_EMPLOYEE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_REVIEWER_EMPLOYEE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_REVIEWER_EMPLOYEE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_REVIEWER_EMPLOYEE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_REVIEWER_EMPLOYEE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_REVIEWER_EMPLOYEE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_REVIEWER_EMPLOYEE_ID__MIN_USER_UPDATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__COUNT_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__COUNT_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__COUNT_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__COUNT_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_DATE_CREATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_DATE_UPDATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_EMPLOYEE_ID_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_REMARK_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_REMARK_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_REMARK_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_REMARK_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_USER_ACCOUNT_ID_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_USER_ACCOUNT_ID_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_USER_ACCOUNT_ID_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_USER_ACCOUNT_ID_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_USER_CREATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_USER_CREATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_USER_CREATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_USER_CREATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MAX_USER_UPDATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_DATE_CREATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_DATE_UPDATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_EMPLOYEE_ID_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_REMARK_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_REMARK_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_REMARK_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_REMARK_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_USER_ACCOUNT_ID_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_USER_ACCOUNT_ID_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_USER_ACCOUNT_ID_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_USER_ACCOUNT_ID_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_USER_CREATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_USER_CREATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_USER_CREATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_USER_CREATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_EMPLOYEE_ID__MIN_USER_UPDATED_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  RESIGNATION_DATE_ASC = 'RESIGNATION_DATE_ASC',
  RESIGNATION_DATE_DESC = 'RESIGNATION_DATE_DESC',
  RESIGNATION_REASON_DETAIL_ASC = 'RESIGNATION_REASON_DETAIL_ASC',
  RESIGNATION_REASON_DETAIL_DESC = 'RESIGNATION_REASON_DETAIL_DESC',
  RESIGNATION_REASON_ID_ASC = 'RESIGNATION_REASON_ID_ASC',
  RESIGNATION_REASON_ID_DESC = 'RESIGNATION_REASON_ID_DESC',
  RESIGNATION_TYPE_ID_ASC = 'RESIGNATION_TYPE_ID_ASC',
  RESIGNATION_TYPE_ID_DESC = 'RESIGNATION_TYPE_ID_DESC',
  SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  SENT_CONTRACT_END_EMAIL_DATE_ASC = 'SENT_CONTRACT_END_EMAIL_DATE_ASC',
  SENT_CONTRACT_END_EMAIL_DATE_DESC = 'SENT_CONTRACT_END_EMAIL_DATE_DESC',
  SOCIAL_INSURANCE_NUMBER_ASC = 'SOCIAL_INSURANCE_NUMBER_ASC',
  SOCIAL_INSURANCE_NUMBER_DESC = 'SOCIAL_INSURANCE_NUMBER_DESC',
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC',
  TAX_CODE_ASC = 'TAX_CODE_ASC',
  TAX_CODE_DESC = 'TAX_CODE_DESC',
  TAX_WITHHOLDING_RATE_ASC = 'TAX_WITHHOLDING_RATE_ASC',
  TAX_WITHHOLDING_RATE_DESC = 'TAX_WITHHOLDING_RATE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
  VISA_EXPIRY_DATE_ASC = 'VISA_EXPIRY_DATE_ASC',
  VISA_EXPIRY_DATE_DESC = 'VISA_EXPIRY_DATE_DESC',
  VISA_ISSUED_DATE_ASC = 'VISA_ISSUED_DATE_ASC',
  VISA_ISSUED_DATE_DESC = 'VISA_ISSUED_DATE_DESC',
  VISA_NUMBER_ASC = 'VISA_NUMBER_ASC',
  VISA_NUMBER_DESC = 'VISA_NUMBER_DESC',
  VISA_TYPE_ASC = 'VISA_TYPE_ASC',
  VISA_TYPE_DESC = 'VISA_TYPE_DESC',
  WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  WORK_PERMIT_EXPIRY_DATE_ASC = 'WORK_PERMIT_EXPIRY_DATE_ASC',
  WORK_PERMIT_EXPIRY_DATE_DESC = 'WORK_PERMIT_EXPIRY_DATE_DESC',
  WORK_PERMIT_ISSUED_DATE_ASC = 'WORK_PERMIT_ISSUED_DATE_ASC',
  WORK_PERMIT_ISSUED_DATE_DESC = 'WORK_PERMIT_ISSUED_DATE_DESC',
  WORK_PERMIT_NUMBER_ASC = 'WORK_PERMIT_NUMBER_ASC',
  WORK_PERMIT_NUMBER_DESC = 'WORK_PERMIT_NUMBER_DESC',
}

export interface ExportEmployeeStatus extends Node {
  __typename?: 'ExportEmployeeStatus';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  exportEmployeeStatusId: Scalars['BigInt']['output'];
  fileMode: Scalars['String']['output'];
  inProgress: Scalars['Boolean']['output'];
  nodeId: Scalars['ID']['output'];
  requestBody?: Maybe<Scalars['JSON']['output']>;
  responseDownloadUrl?: Maybe<Scalars['String']['output']>;
  responseDownloadUrlUntil?: Maybe<Scalars['Datetime']['output']>;
  responseError?: Maybe<Scalars['JSON']['output']>;
  responseFilePassword?: Maybe<Scalars['String']['output']>;
  responseSuccess?: Maybe<Scalars['Boolean']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
  uuid4: Scalars['String']['output'];
}

export interface ExportEmployeeStatusCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  exportEmployeeStatusId?: InputMaybe<Scalars['BigInt']['input']>;
  fileMode?: InputMaybe<Scalars['String']['input']>;
  inProgress?: InputMaybe<Scalars['Boolean']['input']>;
  requestBody?: InputMaybe<Scalars['JSON']['input']>;
  responseDownloadUrl?: InputMaybe<Scalars['String']['input']>;
  responseDownloadUrlUntil?: InputMaybe<Scalars['Datetime']['input']>;
  responseError?: InputMaybe<Scalars['JSON']['input']>;
  responseFilePassword?: InputMaybe<Scalars['String']['input']>;
  responseSuccess?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  uuid4?: InputMaybe<Scalars['String']['input']>;
}

export interface ExportEmployeeStatusExportEmployeeStatusPkeyConnect {
  exportEmployeeStatusId: Scalars['BigInt']['input'];
}

export interface ExportEmployeeStatusExportEmployeeStatusPkeyDelete {
  exportEmployeeStatusId: Scalars['BigInt']['input'];
}

export interface ExportEmployeeStatusExportEmployeeStatusUuid4KeyConnect {
  uuid4: Scalars['String']['input'];
}

export interface ExportEmployeeStatusExportEmployeeStatusUuid4KeyDelete {
  uuid4: Scalars['String']['input'];
}

export interface ExportEmployeeStatusFilter {
  and?: InputMaybe<Array<ExportEmployeeStatusFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  exportEmployeeStatusId?: InputMaybe<BigIntFilter>;
  fileMode?: InputMaybe<StringFilter>;
  inProgress?: InputMaybe<BooleanFilter>;
  not?: InputMaybe<ExportEmployeeStatusFilter>;
  or?: InputMaybe<Array<ExportEmployeeStatusFilter>>;
  requestBody?: InputMaybe<JsonFilter>;
  responseDownloadUrl?: InputMaybe<StringFilter>;
  responseDownloadUrlUntil?: InputMaybe<DatetimeFilter>;
  responseError?: InputMaybe<JsonFilter>;
  responseFilePassword?: InputMaybe<StringFilter>;
  responseSuccess?: InputMaybe<BooleanFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
  uuid4?: InputMaybe<StringFilter>;
}

export interface ExportEmployeeStatusInput {
  exportEmployeeStatusId?: InputMaybe<Scalars['BigInt']['input']>;
  fileMode?: InputMaybe<Scalars['String']['input']>;
  inProgress: Scalars['Boolean']['input'];
  requestBody?: InputMaybe<Scalars['JSON']['input']>;
  responseDownloadUrl?: InputMaybe<Scalars['String']['input']>;
  responseDownloadUrlUntil?: InputMaybe<Scalars['Datetime']['input']>;
  responseError?: InputMaybe<Scalars['JSON']['input']>;
  responseFilePassword?: InputMaybe<Scalars['String']['input']>;
  responseSuccess?: InputMaybe<Scalars['Boolean']['input']>;
  userAccount?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  uuid4: Scalars['String']['input'];
}

export interface ExportEmployeeStatusNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface ExportEmployeeStatusNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface ExportEmployeeStatusOnExportEmployeeStatusForExportEmployeeStatusUserCreatedFkeyUsingExportEmployeeStatusPkeyUpdate {
  exportEmployeeStatusId: Scalars['BigInt']['input'];
  patch: UpdateExportEmployeeStatusOnExportEmployeeStatusForExportEmployeeStatusUserCreatedFkeyPatch;
}

export interface ExportEmployeeStatusOnExportEmployeeStatusForExportEmployeeStatusUserCreatedFkeyUsingExportEmployeeStatusUuid4KeyUpdate {
  patch: UpdateExportEmployeeStatusOnExportEmployeeStatusForExportEmployeeStatusUserCreatedFkeyPatch;
  uuid4: Scalars['String']['input'];
}

export interface ExportEmployeeStatusOnExportEmployeeStatusForExportEmployeeStatusUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface ExportEmployeeStatusOnExportEmployeeStatusForExportEmployeeStatusUserUpdatedFkeyUsingExportEmployeeStatusPkeyUpdate {
  exportEmployeeStatusId: Scalars['BigInt']['input'];
  patch: UpdateExportEmployeeStatusOnExportEmployeeStatusForExportEmployeeStatusUserUpdatedFkeyPatch;
}

export interface ExportEmployeeStatusOnExportEmployeeStatusForExportEmployeeStatusUserUpdatedFkeyUsingExportEmployeeStatusUuid4KeyUpdate {
  patch: UpdateExportEmployeeStatusOnExportEmployeeStatusForExportEmployeeStatusUserUpdatedFkeyPatch;
  uuid4: Scalars['String']['input'];
}

export interface ExportEmployeeStatusPatch {
  exportEmployeeStatusId?: InputMaybe<Scalars['BigInt']['input']>;
  fileMode?: InputMaybe<Scalars['String']['input']>;
  inProgress?: InputMaybe<Scalars['Boolean']['input']>;
  requestBody?: InputMaybe<Scalars['JSON']['input']>;
  responseDownloadUrl?: InputMaybe<Scalars['String']['input']>;
  responseDownloadUrlUntil?: InputMaybe<Scalars['Datetime']['input']>;
  responseError?: InputMaybe<Scalars['JSON']['input']>;
  responseFilePassword?: InputMaybe<Scalars['String']['input']>;
  responseSuccess?: InputMaybe<Scalars['Boolean']['input']>;
  userAccount?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  uuid4?: InputMaybe<Scalars['String']['input']>;
}

export interface ExportEmployeeStatusUserCreatedFkeyInverseInput {
  connectByExportEmployeeStatusId?: InputMaybe<Array<ExportEmployeeStatusExportEmployeeStatusPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<ExportEmployeeStatusNodeIdConnect>>;
  connectByUuid4?: InputMaybe<Array<ExportEmployeeStatusExportEmployeeStatusUuid4KeyConnect>>;
  deleteByExportEmployeeStatusId?: InputMaybe<Array<ExportEmployeeStatusExportEmployeeStatusPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<ExportEmployeeStatusNodeIdDelete>>;
  deleteByUuid4?: InputMaybe<Array<ExportEmployeeStatusExportEmployeeStatusUuid4KeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByExportEmployeeStatusId?: InputMaybe<Array<ExportEmployeeStatusOnExportEmployeeStatusForExportEmployeeStatusUserCreatedFkeyUsingExportEmployeeStatusPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnExportEmployeeStatusForExportEmployeeStatusUserCreatedFkeyNodeIdUpdate>>;
  updateByUuid4?: InputMaybe<Array<ExportEmployeeStatusOnExportEmployeeStatusForExportEmployeeStatusUserCreatedFkeyUsingExportEmployeeStatusUuid4KeyUpdate>>;
}

export interface ExportEmployeeStatusUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnExportEmployeeStatusForExportEmployeeStatusUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<ExportEmployeeStatusOnExportEmployeeStatusForExportEmployeeStatusUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnExportEmployeeStatusForExportEmployeeStatusUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface ExportEmployeeStatusUserUpdatedFkeyInverseInput {
  connectByExportEmployeeStatusId?: InputMaybe<Array<ExportEmployeeStatusExportEmployeeStatusPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<ExportEmployeeStatusNodeIdConnect>>;
  connectByUuid4?: InputMaybe<Array<ExportEmployeeStatusExportEmployeeStatusUuid4KeyConnect>>;
  deleteByExportEmployeeStatusId?: InputMaybe<Array<ExportEmployeeStatusExportEmployeeStatusPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<ExportEmployeeStatusNodeIdDelete>>;
  deleteByUuid4?: InputMaybe<Array<ExportEmployeeStatusExportEmployeeStatusUuid4KeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByExportEmployeeStatusId?: InputMaybe<Array<ExportEmployeeStatusOnExportEmployeeStatusForExportEmployeeStatusUserUpdatedFkeyUsingExportEmployeeStatusPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnExportEmployeeStatusForExportEmployeeStatusUserUpdatedFkeyNodeIdUpdate>>;
  updateByUuid4?: InputMaybe<Array<ExportEmployeeStatusOnExportEmployeeStatusForExportEmployeeStatusUserUpdatedFkeyUsingExportEmployeeStatusUuid4KeyUpdate>>;
}

export interface ExportEmployeeStatusesConnection {
  __typename?: 'ExportEmployeeStatusesConnection';
  edges: Array<ExportEmployeeStatusesEdge>;
  nodes: Array<ExportEmployeeStatus>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ExportEmployeeStatusesEdge {
  __typename?: 'ExportEmployeeStatusesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ExportEmployeeStatus;
}

export enum ExportEmployeeStatusesOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EXPORT_EMPLOYEE_STATUS_ID_ASC = 'EXPORT_EMPLOYEE_STATUS_ID_ASC',
  EXPORT_EMPLOYEE_STATUS_ID_DESC = 'EXPORT_EMPLOYEE_STATUS_ID_DESC',
  FILE_MODE_ASC = 'FILE_MODE_ASC',
  FILE_MODE_DESC = 'FILE_MODE_DESC',
  IN_PROGRESS_ASC = 'IN_PROGRESS_ASC',
  IN_PROGRESS_DESC = 'IN_PROGRESS_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REQUEST_BODY_ASC = 'REQUEST_BODY_ASC',
  REQUEST_BODY_DESC = 'REQUEST_BODY_DESC',
  RESPONSE_DOWNLOAD_URL_ASC = 'RESPONSE_DOWNLOAD_URL_ASC',
  RESPONSE_DOWNLOAD_URL_DESC = 'RESPONSE_DOWNLOAD_URL_DESC',
  RESPONSE_DOWNLOAD_URL_UNTIL_ASC = 'RESPONSE_DOWNLOAD_URL_UNTIL_ASC',
  RESPONSE_DOWNLOAD_URL_UNTIL_DESC = 'RESPONSE_DOWNLOAD_URL_UNTIL_DESC',
  RESPONSE_ERROR_ASC = 'RESPONSE_ERROR_ASC',
  RESPONSE_ERROR_DESC = 'RESPONSE_ERROR_DESC',
  RESPONSE_FILE_PASSWORD_ASC = 'RESPONSE_FILE_PASSWORD_ASC',
  RESPONSE_FILE_PASSWORD_DESC = 'RESPONSE_FILE_PASSWORD_DESC',
  RESPONSE_SUCCESS_ASC = 'RESPONSE_SUCCESS_ASC',
  RESPONSE_SUCCESS_DESC = 'RESPONSE_SUCCESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
  UUID4_ASC = 'UUID4_ASC',
  UUID4_DESC = 'UUID4_DESC',
}

export interface ExternalAccountCreationFailed {
  __typename?: 'ExternalAccountCreationFailed';
  dateCreated: Scalars['Datetime']['output'];
  employee: Employee;
  employeeId: Scalars['BigInt']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  externalAccountTypeByExternalAccountTypeKey: ExternalAccountType;
  externalAccountTypeKey: ExternalAccountEmployeeType;
  remark?: Maybe<Scalars['String']['output']>;
}

export interface ExternalAccountCreationFailedCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  externalAccountTypeKey?: InputMaybe<ExternalAccountEmployeeType>;
  remark?: InputMaybe<Scalars['String']['input']>;
}

export interface ExternalAccountCreationFailedFilter {
  and?: InputMaybe<Array<ExternalAccountCreationFailedFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  employee?: InputMaybe<EmployeeFilter>;
  employeeId?: InputMaybe<BigIntFilter>;
  errorMessage?: InputMaybe<StringFilter>;
  externalAccountTypeByExternalAccountTypeKey?: InputMaybe<ExternalAccountTypeFilter>;
  externalAccountTypeKey?: InputMaybe<ExternalAccountEmployeeTypeFilter>;
  not?: InputMaybe<ExternalAccountCreationFailedFilter>;
  or?: InputMaybe<Array<ExternalAccountCreationFailedFilter>>;
  remark?: InputMaybe<StringFilter>;
}

export interface ExternalAccountCreationFailedsConnection {
  __typename?: 'ExternalAccountCreationFailedsConnection';
  edges: Array<ExternalAccountCreationFailedsEdge>;
  nodes: Array<ExternalAccountCreationFailed>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ExternalAccountCreationFailedsEdge {
  __typename?: 'ExternalAccountCreationFailedsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ExternalAccountCreationFailed;
}

export enum ExternalAccountCreationFailedsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  ERROR_MESSAGE_ASC = 'ERROR_MESSAGE_ASC',
  ERROR_MESSAGE_DESC = 'ERROR_MESSAGE_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__API_REFERENCE_URL_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__API_REFERENCE_URL_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__API_REFERENCE_URL_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__API_REFERENCE_URL_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__API_SETTING_URL_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__API_SETTING_URL_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__API_SETTING_URL_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__API_SETTING_URL_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__AUTH_INFO_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__AUTH_INFO_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__AUTH_INFO_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__AUTH_INFO_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__CONSOLE_REFERENCE_URL_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__CONSOLE_REFERENCE_URL_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__CONSOLE_REFERENCE_URL_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__CONSOLE_REFERENCE_URL_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__CONSOLE_SETTING_URL_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__CONSOLE_SETTING_URL_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__CONSOLE_SETTING_URL_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__CONSOLE_SETTING_URL_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__DISPLAY_ORDER_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__DISPLAY_ORDER_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__DISPLAY_ORDER_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__DISPLAY_ORDER_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__ICON_BASE64_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__ICON_BASE64_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__ICON_BASE64_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__ICON_BASE64_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__KEY_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__KEY_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__KEY_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__KEY_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__NAME_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__NAME_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__NAME_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__NAME_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__SUPPORT_AUTOMATICALLY_CREATED_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__SUPPORT_AUTOMATICALLY_CREATED_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__SUPPORT_AUTOMATICALLY_CREATED_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__SUPPORT_AUTOMATICALLY_CREATED_DESC',
  EXTERNAL_ACCOUNT_TYPE_KEY_ASC = 'EXTERNAL_ACCOUNT_TYPE_KEY_ASC',
  EXTERNAL_ACCOUNT_TYPE_KEY_DESC = 'EXTERNAL_ACCOUNT_TYPE_KEY_DESC',
  NATURAL = 'NATURAL',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
}

export interface ExternalAccountEmployee extends Node {
  __typename?: 'ExternalAccountEmployee';
  active?: Maybe<Scalars['Boolean']['output']>;
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  employee: Employee;
  employeeId: Scalars['BigInt']['output'];
  externalAccountEmployeeId: Scalars['BigInt']['output'];
  externalAccountKey: Scalars['String']['output'];
  externalAccountStatusCached: Scalars['JSON']['output'];
  externalAccountTypeByExternalAccountTypeKey: ExternalAccountType;
  externalAccountTypeKey: ExternalAccountEmployeeType;
  google2SvNotEnrolled?: Maybe<Scalars['Boolean']['output']>;
  googleTransferred?: Maybe<Scalars['Boolean']['output']>;
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface ExternalAccountEmployeeCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  externalAccountEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  externalAccountKey?: InputMaybe<Scalars['String']['input']>;
  externalAccountStatusCached?: InputMaybe<Scalars['JSON']['input']>;
  externalAccountTypeKey?: InputMaybe<ExternalAccountEmployeeType>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ExternalAccountEmployeeEmployeeIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface ExternalAccountEmployeeEmployeeIdFkeyExternalAccountEmployeeCreateInput {
  employee?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInput>;
  externalAccountEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  externalAccountKey: Scalars['String']['input'];
  externalAccountStatusCached: Scalars['JSON']['input'];
  externalAccountType?: InputMaybe<ExternalAccountEmployeeExternalAccountTypeKeyFkeyInput>;
  externalAccountTypeKey?: InputMaybe<ExternalAccountEmployeeType>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ExternalAccountEmployeeEmployeeIdFkeyInput {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyEmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnExternalAccountEmployeeForExternalAccountEmployeeEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnExternalAccountEmployeeForExternalAccountEmployeeEmployeeIdFkeyUsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<ExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeEmployeeIdFkeyNodeIdUpdate>;
}

export interface ExternalAccountEmployeeEmployeeIdFkeyInverseInput {
  connectByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKey?: InputMaybe<Array<ExternalAccountEmployeeExternalAccountEmployeeEmployeeIdExternalAccountTypeKeyConnect>>;
  connectByExternalAccountEmployeeId?: InputMaybe<Array<ExternalAccountEmployeeExternalAccountEmployeePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<ExternalAccountEmployeeNodeIdConnect>>;
  create?: InputMaybe<Array<ExternalAccountEmployeeEmployeeIdFkeyExternalAccountEmployeeCreateInput>>;
  deleteByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKey?: InputMaybe<Array<ExternalAccountEmployeeExternalAccountEmployeeEmployeeIdExternalAccountTypeKeyDelete>>;
  deleteByExternalAccountEmployeeId?: InputMaybe<Array<ExternalAccountEmployeeExternalAccountEmployeePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<ExternalAccountEmployeeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKey?: InputMaybe<
    Array<ExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeEmployeeIdFkeyUsingExternalAccountEmployeeEmployeeIdExternalAccountTypeKeyUpdate>
  >;
  updateByExternalAccountEmployeeId?: InputMaybe<Array<ExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeEmployeeIdFkeyUsingExternalAccountEmployeePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<EmployeeOnExternalAccountEmployeeForExternalAccountEmployeeEmployeeIdFkeyNodeIdUpdate>>;
}

export interface ExternalAccountEmployeeExternalAccountEmployeeEmployeeIdExternalAccountTypeKeyConnect {
  employeeId: Scalars['BigInt']['input'];
  externalAccountKey: Scalars['String']['input'];
  externalAccountTypeKey: ExternalAccountEmployeeType;
}

export interface ExternalAccountEmployeeExternalAccountEmployeeEmployeeIdExternalAccountTypeKeyDelete {
  employeeId: Scalars['BigInt']['input'];
  externalAccountKey: Scalars['String']['input'];
  externalAccountTypeKey: ExternalAccountEmployeeType;
}

export interface ExternalAccountEmployeeExternalAccountEmployeePkeyConnect {
  externalAccountEmployeeId: Scalars['BigInt']['input'];
}

export interface ExternalAccountEmployeeExternalAccountEmployeePkeyDelete {
  externalAccountEmployeeId: Scalars['BigInt']['input'];
}

export interface ExternalAccountEmployeeExternalAccountTypeKeyFkeyExternalAccountEmployeeCreateInput {
  employee?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  externalAccountEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  externalAccountKey: Scalars['String']['input'];
  externalAccountStatusCached: Scalars['JSON']['input'];
  externalAccountType?: InputMaybe<ExternalAccountEmployeeExternalAccountTypeKeyFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ExternalAccountEmployeeExternalAccountTypeKeyFkeyExternalAccountTypeCreateInput {
  apiReferenceUrl?: InputMaybe<Scalars['String']['input']>;
  apiSettingUrl?: InputMaybe<Scalars['String']['input']>;
  authInfo?: InputMaybe<Scalars['JSON']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableExternalAccountTypeKeyFkeyInverseInput>;
  consoleReferenceUrl?: InputMaybe<Scalars['String']['input']>;
  consoleSettingUrl?: InputMaybe<Scalars['String']['input']>;
  displayOrder: Scalars['Int']['input'];
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeExternalAccountTypeKeyFkeyInverseInput>;
  iconBase64?: InputMaybe<Scalars['String']['input']>;
  key: ExternalAccountEmployeeType;
  name: Scalars['String']['input'];
  supportAutomaticallyCreated?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ExternalAccountEmployeeExternalAccountTypeKeyFkeyInput {
  connectByKey?: InputMaybe<ExternalAccountTypeExternalAccountTypePkeyConnect>;
  connectByName?: InputMaybe<ExternalAccountTypeExternalAccountTypeNameKeyConnect>;
  connectByNodeId?: InputMaybe<ExternalAccountTypeNodeIdConnect>;
  create?: InputMaybe<ExternalAccountEmployeeExternalAccountTypeKeyFkeyExternalAccountTypeCreateInput>;
  deleteByKey?: InputMaybe<ExternalAccountTypeExternalAccountTypePkeyDelete>;
  deleteByName?: InputMaybe<ExternalAccountTypeExternalAccountTypeNameKeyDelete>;
  deleteByNodeId?: InputMaybe<ExternalAccountTypeNodeIdDelete>;
  updateByKey?: InputMaybe<ExternalAccountTypeOnExternalAccountEmployeeForExternalAccountEmployeeExternalAccountTypeKeyFkeyUsingExternalAccountTypePkeyUpdate>;
  updateByName?: InputMaybe<ExternalAccountTypeOnExternalAccountEmployeeForExternalAccountEmployeeExternalAccountTypeKeyFkeyUsingExternalAccountTypeNameKeyUpdate>;
  updateByNodeId?: InputMaybe<ExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeExternalAccountTypeKeyFkeyNodeIdUpdate>;
}

export interface ExternalAccountEmployeeExternalAccountTypeKeyFkeyInverseInput {
  connectByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKey?: InputMaybe<Array<ExternalAccountEmployeeExternalAccountEmployeeEmployeeIdExternalAccountTypeKeyConnect>>;
  connectByExternalAccountEmployeeId?: InputMaybe<Array<ExternalAccountEmployeeExternalAccountEmployeePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<ExternalAccountEmployeeNodeIdConnect>>;
  create?: InputMaybe<Array<ExternalAccountEmployeeExternalAccountTypeKeyFkeyExternalAccountEmployeeCreateInput>>;
  deleteByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKey?: InputMaybe<Array<ExternalAccountEmployeeExternalAccountEmployeeEmployeeIdExternalAccountTypeKeyDelete>>;
  deleteByExternalAccountEmployeeId?: InputMaybe<Array<ExternalAccountEmployeeExternalAccountEmployeePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<ExternalAccountEmployeeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKey?: InputMaybe<
    Array<ExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeExternalAccountTypeKeyFkeyUsingExternalAccountEmployeeEmployeeIdExternalAccountTypeKeyUpdate>
  >;
  updateByExternalAccountEmployeeId?: InputMaybe<Array<ExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeExternalAccountTypeKeyFkeyUsingExternalAccountEmployeePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<ExternalAccountTypeOnExternalAccountEmployeeForExternalAccountEmployeeExternalAccountTypeKeyFkeyNodeIdUpdate>>;
}

export interface ExternalAccountEmployeeFilter {
  active?: InputMaybe<BooleanFilter>;
  and?: InputMaybe<Array<ExternalAccountEmployeeFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  employee?: InputMaybe<EmployeeFilter>;
  employeeId?: InputMaybe<BigIntFilter>;
  externalAccountEmployeeId?: InputMaybe<BigIntFilter>;
  externalAccountKey?: InputMaybe<StringFilter>;
  externalAccountStatusCached?: InputMaybe<JsonFilter>;
  externalAccountTypeByExternalAccountTypeKey?: InputMaybe<ExternalAccountTypeFilter>;
  externalAccountTypeKey?: InputMaybe<ExternalAccountEmployeeTypeFilter>;
  google2SvNotEnrolled?: InputMaybe<BooleanFilter>;
  googleTransferred?: InputMaybe<BooleanFilter>;
  not?: InputMaybe<ExternalAccountEmployeeFilter>;
  or?: InputMaybe<Array<ExternalAccountEmployeeFilter>>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface ExternalAccountEmployeeInput {
  employee?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  externalAccountEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  externalAccountKey: Scalars['String']['input'];
  externalAccountStatusCached: Scalars['JSON']['input'];
  externalAccountType?: InputMaybe<ExternalAccountEmployeeExternalAccountTypeKeyFkeyInput>;
  externalAccountTypeKey?: InputMaybe<ExternalAccountEmployeeType>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ExternalAccountEmployeeNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface ExternalAccountEmployeeNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface ExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface ExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeEmployeeIdFkeyUsingExternalAccountEmployeeEmployeeIdExternalAccountTypeKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  externalAccountKey: Scalars['String']['input'];
  externalAccountTypeKey: ExternalAccountEmployeeType;
  patch: UpdateExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeEmployeeIdFkeyPatch;
}

export interface ExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeEmployeeIdFkeyUsingExternalAccountEmployeePkeyUpdate {
  externalAccountEmployeeId: Scalars['BigInt']['input'];
  patch: UpdateExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeEmployeeIdFkeyPatch;
}

export interface ExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeExternalAccountTypeKeyFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ExternalAccountTypePatch;
}

export interface ExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeExternalAccountTypeKeyFkeyUsingExternalAccountEmployeeEmployeeIdExternalAccountTypeKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  externalAccountKey: Scalars['String']['input'];
  externalAccountTypeKey: ExternalAccountEmployeeType;
  patch: UpdateExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeExternalAccountTypeKeyFkeyPatch;
}

export interface ExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeExternalAccountTypeKeyFkeyUsingExternalAccountEmployeePkeyUpdate {
  externalAccountEmployeeId: Scalars['BigInt']['input'];
  patch: UpdateExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeExternalAccountTypeKeyFkeyPatch;
}

export interface ExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeUserCreatedFkeyUsingExternalAccountEmployeeEmployeeIdExternalAccountTypeKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  externalAccountKey: Scalars['String']['input'];
  externalAccountTypeKey: ExternalAccountEmployeeType;
  patch: UpdateExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeUserCreatedFkeyPatch;
}

export interface ExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeUserCreatedFkeyUsingExternalAccountEmployeePkeyUpdate {
  externalAccountEmployeeId: Scalars['BigInt']['input'];
  patch: UpdateExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeUserCreatedFkeyPatch;
}

export interface ExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface ExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeUserUpdatedFkeyUsingExternalAccountEmployeeEmployeeIdExternalAccountTypeKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  externalAccountKey: Scalars['String']['input'];
  externalAccountTypeKey: ExternalAccountEmployeeType;
  patch: UpdateExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeUserUpdatedFkeyPatch;
}

export interface ExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeUserUpdatedFkeyUsingExternalAccountEmployeePkeyUpdate {
  externalAccountEmployeeId: Scalars['BigInt']['input'];
  patch: UpdateExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeUserUpdatedFkeyPatch;
}

export interface ExternalAccountEmployeePatch {
  employee?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  externalAccountEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  externalAccountKey?: InputMaybe<Scalars['String']['input']>;
  externalAccountStatusCached?: InputMaybe<Scalars['JSON']['input']>;
  externalAccountType?: InputMaybe<ExternalAccountEmployeeExternalAccountTypeKeyFkeyInput>;
  externalAccountTypeKey?: InputMaybe<ExternalAccountEmployeeType>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export enum ExternalAccountEmployeeType {
  GOOGLE_WORKSPACE = 'GOOGLE_WORKSPACE',
  HUBSPOT = 'HUBSPOT',
  NETSUITE_EMPLOYEE = 'NETSUITE_EMPLOYEE',
  SLACK = 'SLACK',
}

export interface ExternalAccountEmployeeTypeFilter {
  distinctFrom?: InputMaybe<ExternalAccountEmployeeType>;
  equalTo?: InputMaybe<ExternalAccountEmployeeType>;
  greaterThan?: InputMaybe<ExternalAccountEmployeeType>;
  greaterThanOrEqualTo?: InputMaybe<ExternalAccountEmployeeType>;
  in?: InputMaybe<Array<ExternalAccountEmployeeType>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<ExternalAccountEmployeeType>;
  lessThanOrEqualTo?: InputMaybe<ExternalAccountEmployeeType>;
  notDistinctFrom?: InputMaybe<ExternalAccountEmployeeType>;
  notEqualTo?: InputMaybe<ExternalAccountEmployeeType>;
  notIn?: InputMaybe<Array<ExternalAccountEmployeeType>>;
}

export interface ExternalAccountEmployeeUserCreatedFkeyInverseInput {
  connectByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKey?: InputMaybe<Array<ExternalAccountEmployeeExternalAccountEmployeeEmployeeIdExternalAccountTypeKeyConnect>>;
  connectByExternalAccountEmployeeId?: InputMaybe<Array<ExternalAccountEmployeeExternalAccountEmployeePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<ExternalAccountEmployeeNodeIdConnect>>;
  deleteByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKey?: InputMaybe<Array<ExternalAccountEmployeeExternalAccountEmployeeEmployeeIdExternalAccountTypeKeyDelete>>;
  deleteByExternalAccountEmployeeId?: InputMaybe<Array<ExternalAccountEmployeeExternalAccountEmployeePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<ExternalAccountEmployeeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKey?: InputMaybe<
    Array<ExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeUserCreatedFkeyUsingExternalAccountEmployeeEmployeeIdExternalAccountTypeKeyUpdate>
  >;
  updateByExternalAccountEmployeeId?: InputMaybe<Array<ExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeUserCreatedFkeyUsingExternalAccountEmployeePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnExternalAccountEmployeeForExternalAccountEmployeeUserCreatedFkeyNodeIdUpdate>>;
}

export interface ExternalAccountEmployeeUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnExternalAccountEmployeeForExternalAccountEmployeeUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<ExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnExternalAccountEmployeeForExternalAccountEmployeeUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface ExternalAccountEmployeeUserUpdatedFkeyInverseInput {
  connectByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKey?: InputMaybe<Array<ExternalAccountEmployeeExternalAccountEmployeeEmployeeIdExternalAccountTypeKeyConnect>>;
  connectByExternalAccountEmployeeId?: InputMaybe<Array<ExternalAccountEmployeeExternalAccountEmployeePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<ExternalAccountEmployeeNodeIdConnect>>;
  deleteByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKey?: InputMaybe<Array<ExternalAccountEmployeeExternalAccountEmployeeEmployeeIdExternalAccountTypeKeyDelete>>;
  deleteByExternalAccountEmployeeId?: InputMaybe<Array<ExternalAccountEmployeeExternalAccountEmployeePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<ExternalAccountEmployeeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKey?: InputMaybe<
    Array<ExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeUserUpdatedFkeyUsingExternalAccountEmployeeEmployeeIdExternalAccountTypeKeyUpdate>
  >;
  updateByExternalAccountEmployeeId?: InputMaybe<Array<ExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeUserUpdatedFkeyUsingExternalAccountEmployeePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnExternalAccountEmployeeForExternalAccountEmployeeUserUpdatedFkeyNodeIdUpdate>>;
}

export interface ExternalAccountEmployeesConnection {
  __typename?: 'ExternalAccountEmployeesConnection';
  edges: Array<ExternalAccountEmployeesEdge>;
  nodes: Array<ExternalAccountEmployee>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ExternalAccountEmployeesEdge {
  __typename?: 'ExternalAccountEmployeesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ExternalAccountEmployee;
}

export enum ExternalAccountEmployeesOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEE_ID_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEE_ID_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEE_ID_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEE_ID_DESC',
  EXTERNAL_ACCOUNT_KEY_ASC = 'EXTERNAL_ACCOUNT_KEY_ASC',
  EXTERNAL_ACCOUNT_KEY_DESC = 'EXTERNAL_ACCOUNT_KEY_DESC',
  EXTERNAL_ACCOUNT_STATUS_CACHED_ASC = 'EXTERNAL_ACCOUNT_STATUS_CACHED_ASC',
  EXTERNAL_ACCOUNT_STATUS_CACHED_DESC = 'EXTERNAL_ACCOUNT_STATUS_CACHED_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__API_REFERENCE_URL_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__API_REFERENCE_URL_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__API_REFERENCE_URL_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__API_REFERENCE_URL_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__API_SETTING_URL_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__API_SETTING_URL_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__API_SETTING_URL_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__API_SETTING_URL_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__AUTH_INFO_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__AUTH_INFO_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__AUTH_INFO_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__AUTH_INFO_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__CONSOLE_REFERENCE_URL_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__CONSOLE_REFERENCE_URL_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__CONSOLE_REFERENCE_URL_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__CONSOLE_REFERENCE_URL_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__CONSOLE_SETTING_URL_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__CONSOLE_SETTING_URL_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__CONSOLE_SETTING_URL_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__CONSOLE_SETTING_URL_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__DISPLAY_ORDER_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__DISPLAY_ORDER_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__DISPLAY_ORDER_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__DISPLAY_ORDER_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__ICON_BASE64_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__ICON_BASE64_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__ICON_BASE64_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__ICON_BASE64_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__KEY_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__KEY_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__KEY_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__KEY_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__NAME_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__NAME_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__NAME_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__NAME_DESC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__SUPPORT_AUTOMATICALLY_CREATED_ASC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__SUPPORT_AUTOMATICALLY_CREATED_ASC',
  EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__SUPPORT_AUTOMATICALLY_CREATED_DESC = 'EXTERNAL_ACCOUNT_TYPE_BY_EXTERNAL_ACCOUNT_TYPE_KEY__SUPPORT_AUTOMATICALLY_CREATED_DESC',
  EXTERNAL_ACCOUNT_TYPE_KEY_ASC = 'EXTERNAL_ACCOUNT_TYPE_KEY_ASC',
  EXTERNAL_ACCOUNT_TYPE_KEY_DESC = 'EXTERNAL_ACCOUNT_TYPE_KEY_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface ExternalAccountType extends Node {
  __typename?: 'ExternalAccountType';
  apiReferenceUrl?: Maybe<Scalars['String']['output']>;
  apiSettingUrl?: Maybe<Scalars['String']['output']>;
  authInfo?: Maybe<Scalars['JSON']['output']>;
  companyExternalAccountDisablesByExternalAccountTypeKey: CompanyExternalAccountDisablesConnection;
  consoleReferenceUrl?: Maybe<Scalars['String']['output']>;
  consoleSettingUrl?: Maybe<Scalars['String']['output']>;
  displayOrder: Scalars['Int']['output'];
  externalAccountCreationFailedsByExternalAccountTypeKey: ExternalAccountCreationFailedsConnection;
  externalAccountEmployeesByExternalAccountTypeKey: ExternalAccountEmployeesConnection;
  iconBase64?: Maybe<Scalars['String']['output']>;
  key: ExternalAccountEmployeeType;
  name: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  supportAutomaticallyCreated: Scalars['Boolean']['output'];
}

export type ExternalAccountTypeCompanyExternalAccountDisablesByExternalAccountTypeKeyArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CompanyExternalAccountDisableCondition>;
  filter?: InputMaybe<CompanyExternalAccountDisableFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyExternalAccountDisablesOrderBy>>;
};

export type ExternalAccountTypeExternalAccountCreationFailedsByExternalAccountTypeKeyArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ExternalAccountCreationFailedCondition>;
  filter?: InputMaybe<ExternalAccountCreationFailedFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ExternalAccountCreationFailedsOrderBy>>;
};

export type ExternalAccountTypeExternalAccountEmployeesByExternalAccountTypeKeyArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ExternalAccountEmployeeCondition>;
  filter?: InputMaybe<ExternalAccountEmployeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ExternalAccountEmployeesOrderBy>>;
};

export interface ExternalAccountTypeCondition {
  apiReferenceUrl?: InputMaybe<Scalars['String']['input']>;
  apiSettingUrl?: InputMaybe<Scalars['String']['input']>;
  authInfo?: InputMaybe<Scalars['JSON']['input']>;
  consoleReferenceUrl?: InputMaybe<Scalars['String']['input']>;
  consoleSettingUrl?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  iconBase64?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<ExternalAccountEmployeeType>;
  name?: InputMaybe<Scalars['String']['input']>;
  supportAutomaticallyCreated?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ExternalAccountTypeExternalAccountTypeNameKeyConnect {
  name: Scalars['String']['input'];
}

export interface ExternalAccountTypeExternalAccountTypeNameKeyDelete {
  name: Scalars['String']['input'];
}

export interface ExternalAccountTypeExternalAccountTypePkeyConnect {
  key: ExternalAccountEmployeeType;
}

export interface ExternalAccountTypeExternalAccountTypePkeyDelete {
  key: ExternalAccountEmployeeType;
}

export interface ExternalAccountTypeFilter {
  and?: InputMaybe<Array<ExternalAccountTypeFilter>>;
  apiReferenceUrl?: InputMaybe<StringFilter>;
  apiSettingUrl?: InputMaybe<StringFilter>;
  authInfo?: InputMaybe<JsonFilter>;
  companyExternalAccountDisablesByExternalAccountTypeKey?: InputMaybe<ExternalAccountTypeToManyCompanyExternalAccountDisableFilter>;
  companyExternalAccountDisablesByExternalAccountTypeKeyExist?: InputMaybe<Scalars['Boolean']['input']>;
  consoleReferenceUrl?: InputMaybe<StringFilter>;
  consoleSettingUrl?: InputMaybe<StringFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  externalAccountCreationFailedsByExternalAccountTypeKey?: InputMaybe<ExternalAccountTypeToManyExternalAccountCreationFailedFilter>;
  externalAccountCreationFailedsByExternalAccountTypeKeyExist?: InputMaybe<Scalars['Boolean']['input']>;
  externalAccountEmployeesByExternalAccountTypeKey?: InputMaybe<ExternalAccountTypeToManyExternalAccountEmployeeFilter>;
  externalAccountEmployeesByExternalAccountTypeKeyExist?: InputMaybe<Scalars['Boolean']['input']>;
  iconBase64?: InputMaybe<StringFilter>;
  key?: InputMaybe<ExternalAccountEmployeeTypeFilter>;
  name?: InputMaybe<StringFilter>;
  not?: InputMaybe<ExternalAccountTypeFilter>;
  or?: InputMaybe<Array<ExternalAccountTypeFilter>>;
  supportAutomaticallyCreated?: InputMaybe<BooleanFilter>;
}

export interface ExternalAccountTypeInput {
  apiReferenceUrl?: InputMaybe<Scalars['String']['input']>;
  apiSettingUrl?: InputMaybe<Scalars['String']['input']>;
  authInfo?: InputMaybe<Scalars['JSON']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableExternalAccountTypeKeyFkeyInverseInput>;
  consoleReferenceUrl?: InputMaybe<Scalars['String']['input']>;
  consoleSettingUrl?: InputMaybe<Scalars['String']['input']>;
  displayOrder: Scalars['Int']['input'];
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeExternalAccountTypeKeyFkeyInverseInput>;
  iconBase64?: InputMaybe<Scalars['String']['input']>;
  key: ExternalAccountEmployeeType;
  name: Scalars['String']['input'];
  supportAutomaticallyCreated?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ExternalAccountTypeNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface ExternalAccountTypeNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface ExternalAccountTypeOnCompanyExternalAccountDisableForCompanyExternalAccountDisableExternalAccountTypeKeyFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyExternalAccountDisablePatch;
}

export interface ExternalAccountTypeOnCompanyExternalAccountDisableForCompanyExternalAccountDisableExternalAccountTypeKeyFkeyUsingExternalAccountTypeNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateExternalAccountTypeOnCompanyExternalAccountDisableForCompanyExternalAccountDisableExternalAccountTypeKeyFkeyPatch;
}

export interface ExternalAccountTypeOnCompanyExternalAccountDisableForCompanyExternalAccountDisableExternalAccountTypeKeyFkeyUsingExternalAccountTypePkeyUpdate {
  key: ExternalAccountEmployeeType;
  patch: UpdateExternalAccountTypeOnCompanyExternalAccountDisableForCompanyExternalAccountDisableExternalAccountTypeKeyFkeyPatch;
}

export interface ExternalAccountTypeOnExternalAccountEmployeeForExternalAccountEmployeeExternalAccountTypeKeyFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ExternalAccountEmployeePatch;
}

export interface ExternalAccountTypeOnExternalAccountEmployeeForExternalAccountEmployeeExternalAccountTypeKeyFkeyUsingExternalAccountTypeNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdateExternalAccountTypeOnExternalAccountEmployeeForExternalAccountEmployeeExternalAccountTypeKeyFkeyPatch;
}

export interface ExternalAccountTypeOnExternalAccountEmployeeForExternalAccountEmployeeExternalAccountTypeKeyFkeyUsingExternalAccountTypePkeyUpdate {
  key: ExternalAccountEmployeeType;
  patch: UpdateExternalAccountTypeOnExternalAccountEmployeeForExternalAccountEmployeeExternalAccountTypeKeyFkeyPatch;
}

export interface ExternalAccountTypePatch {
  apiReferenceUrl?: InputMaybe<Scalars['String']['input']>;
  apiSettingUrl?: InputMaybe<Scalars['String']['input']>;
  authInfo?: InputMaybe<Scalars['JSON']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableExternalAccountTypeKeyFkeyInverseInput>;
  consoleReferenceUrl?: InputMaybe<Scalars['String']['input']>;
  consoleSettingUrl?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeExternalAccountTypeKeyFkeyInverseInput>;
  iconBase64?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<ExternalAccountEmployeeType>;
  name?: InputMaybe<Scalars['String']['input']>;
  supportAutomaticallyCreated?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ExternalAccountTypeToManyCompanyExternalAccountDisableFilter {
  every?: InputMaybe<CompanyExternalAccountDisableFilter>;
  none?: InputMaybe<CompanyExternalAccountDisableFilter>;
  some?: InputMaybe<CompanyExternalAccountDisableFilter>;
}

export interface ExternalAccountTypeToManyExternalAccountCreationFailedFilter {
  every?: InputMaybe<ExternalAccountCreationFailedFilter>;
  none?: InputMaybe<ExternalAccountCreationFailedFilter>;
  some?: InputMaybe<ExternalAccountCreationFailedFilter>;
}

export interface ExternalAccountTypeToManyExternalAccountEmployeeFilter {
  every?: InputMaybe<ExternalAccountEmployeeFilter>;
  none?: InputMaybe<ExternalAccountEmployeeFilter>;
  some?: InputMaybe<ExternalAccountEmployeeFilter>;
}

export interface ExternalAccountTypesConnection {
  __typename?: 'ExternalAccountTypesConnection';
  edges: Array<ExternalAccountTypesEdge>;
  nodes: Array<ExternalAccountType>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ExternalAccountTypesEdge {
  __typename?: 'ExternalAccountTypesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ExternalAccountType;
}

export enum ExternalAccountTypesOrderBy {
  API_REFERENCE_URL_ASC = 'API_REFERENCE_URL_ASC',
  API_REFERENCE_URL_DESC = 'API_REFERENCE_URL_DESC',
  API_SETTING_URL_ASC = 'API_SETTING_URL_ASC',
  API_SETTING_URL_DESC = 'API_SETTING_URL_DESC',
  AUTH_INFO_ASC = 'AUTH_INFO_ASC',
  AUTH_INFO_DESC = 'AUTH_INFO_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__COUNT_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__COUNT_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__COUNT_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__COUNT_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_COMPANY_ID_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_COMPANY_ID_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_COMPANY_ID_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_COMPANY_ID_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_DATE_CREATED_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_DATE_CREATED_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_DATE_CREATED_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_DATE_CREATED_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_REMARK_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_REMARK_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_REMARK_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_REMARK_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_USER_CREATED_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_USER_CREATED_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_USER_CREATED_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_USER_CREATED_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_COMPANY_ID_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_COMPANY_ID_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_COMPANY_ID_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_COMPANY_ID_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_DATE_CREATED_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_DATE_CREATED_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_DATE_CREATED_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_DATE_CREATED_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_REMARK_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_REMARK_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_REMARK_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_REMARK_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_USER_CREATED_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_USER_CREATED_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_USER_CREATED_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_USER_CREATED_DESC',
  CONSOLE_REFERENCE_URL_ASC = 'CONSOLE_REFERENCE_URL_ASC',
  CONSOLE_REFERENCE_URL_DESC = 'CONSOLE_REFERENCE_URL_DESC',
  CONSOLE_SETTING_URL_ASC = 'CONSOLE_SETTING_URL_ASC',
  CONSOLE_SETTING_URL_DESC = 'CONSOLE_SETTING_URL_DESC',
  DISPLAY_ORDER_ASC = 'DISPLAY_ORDER_ASC',
  DISPLAY_ORDER_DESC = 'DISPLAY_ORDER_DESC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__COUNT_ASC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__COUNT_ASC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__COUNT_DESC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__COUNT_DESC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_DATE_CREATED_ASC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_DATE_CREATED_ASC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_DATE_CREATED_DESC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_DATE_CREATED_DESC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EMPLOYEE_ID_ASC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EMPLOYEE_ID_ASC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EMPLOYEE_ID_DESC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EMPLOYEE_ID_DESC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_ERROR_MESSAGE_ASC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_ERROR_MESSAGE_ASC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_ERROR_MESSAGE_DESC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_ERROR_MESSAGE_DESC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_ASC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_ASC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_DESC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_DESC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_REMARK_ASC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_REMARK_ASC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_REMARK_DESC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_REMARK_DESC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_DATE_CREATED_ASC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_DATE_CREATED_ASC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_DATE_CREATED_DESC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_DATE_CREATED_DESC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EMPLOYEE_ID_ASC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EMPLOYEE_ID_ASC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EMPLOYEE_ID_DESC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EMPLOYEE_ID_DESC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_ERROR_MESSAGE_ASC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_ERROR_MESSAGE_ASC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_ERROR_MESSAGE_DESC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_ERROR_MESSAGE_DESC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_ASC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_ASC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_DESC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_DESC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_REMARK_ASC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_REMARK_ASC',
  EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_REMARK_DESC = 'EXTERNAL_ACCOUNT_CREATION_FAILEDS_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_REMARK_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__COUNT_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__COUNT_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__COUNT_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__COUNT_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_DATE_CREATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_DATE_CREATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_DATE_CREATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_DATE_CREATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_DATE_UPDATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_DATE_UPDATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_DATE_UPDATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_DATE_UPDATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EMPLOYEE_ID_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EMPLOYEE_ID_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EMPLOYEE_ID_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EMPLOYEE_ID_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EXTERNAL_ACCOUNT_EMPLOYEE_ID_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EXTERNAL_ACCOUNT_EMPLOYEE_ID_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EXTERNAL_ACCOUNT_EMPLOYEE_ID_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EXTERNAL_ACCOUNT_EMPLOYEE_ID_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EXTERNAL_ACCOUNT_KEY_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EXTERNAL_ACCOUNT_KEY_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EXTERNAL_ACCOUNT_KEY_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EXTERNAL_ACCOUNT_KEY_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EXTERNAL_ACCOUNT_STATUS_CACHED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EXTERNAL_ACCOUNT_STATUS_CACHED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EXTERNAL_ACCOUNT_STATUS_CACHED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EXTERNAL_ACCOUNT_STATUS_CACHED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_REMARK_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_REMARK_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_REMARK_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_REMARK_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_USER_CREATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_USER_CREATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_USER_CREATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_USER_CREATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_USER_UPDATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_USER_UPDATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_USER_UPDATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MAX_USER_UPDATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_DATE_CREATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_DATE_CREATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_DATE_CREATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_DATE_CREATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_DATE_UPDATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_DATE_UPDATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_DATE_UPDATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_DATE_UPDATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EMPLOYEE_ID_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EMPLOYEE_ID_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EMPLOYEE_ID_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EMPLOYEE_ID_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EXTERNAL_ACCOUNT_EMPLOYEE_ID_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EXTERNAL_ACCOUNT_EMPLOYEE_ID_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EXTERNAL_ACCOUNT_EMPLOYEE_ID_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EXTERNAL_ACCOUNT_EMPLOYEE_ID_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EXTERNAL_ACCOUNT_KEY_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EXTERNAL_ACCOUNT_KEY_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EXTERNAL_ACCOUNT_KEY_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EXTERNAL_ACCOUNT_KEY_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EXTERNAL_ACCOUNT_STATUS_CACHED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EXTERNAL_ACCOUNT_STATUS_CACHED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EXTERNAL_ACCOUNT_STATUS_CACHED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EXTERNAL_ACCOUNT_STATUS_CACHED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_REMARK_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_REMARK_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_REMARK_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_REMARK_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_USER_CREATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_USER_CREATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_USER_CREATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_USER_CREATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_USER_UPDATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_USER_UPDATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_USER_UPDATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_EXTERNAL_ACCOUNT_TYPE_KEY__MIN_USER_UPDATED_DESC',
  ICON_BASE64_ASC = 'ICON_BASE64_ASC',
  ICON_BASE64_DESC = 'ICON_BASE64_DESC',
  KEY_ASC = 'KEY_ASC',
  KEY_DESC = 'KEY_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  SUPPORT_AUTOMATICALLY_CREATED_ASC = 'SUPPORT_AUTOMATICALLY_CREATED_ASC',
  SUPPORT_AUTOMATICALLY_CREATED_DESC = 'SUPPORT_AUTOMATICALLY_CREATED_DESC',
}

export interface FakePublicEmployeeCurrentPositionForeignKey0EmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey0Input {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0EmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey0UsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey0UsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey0NodeIdUpdate>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey0InverseInput {
  connectByEmployeeId?: InputMaybe<EmployeeCurrentPositionFakePublicEmployeeCurrentPositionPrimaryKeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeCurrentPositionNodeIdConnect>;
  updateByEmployeeId?: InputMaybe<EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey0UsingFakePublicEmployeeCurrentPositionPrimaryKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey0NodeIdUpdate>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey1EmployeeTpHistoryCreateInput {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate: Scalars['Date']['input'];
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey1Input {
  connectByEmployeeIdAndEffectiveDate?: InputMaybe<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyConnect>;
  connectByEmployeeTpHistoryId?: InputMaybe<EmployeeTpHistoryEmployeeTpHistoryPkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeTpHistoryNodeIdConnect>;
  create?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1EmployeeTpHistoryCreateInput>;
  deleteByEmployeeIdAndEffectiveDate?: InputMaybe<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyDelete>;
  deleteByEmployeeTpHistoryId?: InputMaybe<EmployeeTpHistoryEmployeeTpHistoryPkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeTpHistoryNodeIdDelete>;
  updateByEmployeeIdAndEffectiveDate?: InputMaybe<EmployeeTpHistoryOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey1UsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate>;
  updateByEmployeeTpHistoryId?: InputMaybe<EmployeeTpHistoryOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey1UsingEmployeeTpHistoryPkeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey1NodeIdUpdate>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey1InverseInput {
  connectByEmployeeId?: InputMaybe<Array<EmployeeCurrentPositionFakePublicEmployeeCurrentPositionPrimaryKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeCurrentPositionNodeIdConnect>>;
  updateByEmployeeId?: InputMaybe<Array<EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey1UsingFakePublicEmployeeCurrentPositionPrimaryKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<EmployeeTpHistoryOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey1NodeIdUpdate>>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey2ContractTypeCreateInput {
  code: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey1InverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey2InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableContractTypeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ContractTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey2Input {
  connectByCode?: InputMaybe<ContractTypeContractTypeCodeKeyConnect>;
  connectByContractTypeId?: InputMaybe<ContractTypeContractTypePkeyConnect>;
  connectByDisplayName?: InputMaybe<ContractTypeContractTypeDisplayNameKeyConnect>;
  connectByNodeId?: InputMaybe<ContractTypeNodeIdConnect>;
  create?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2ContractTypeCreateInput>;
  deleteByCode?: InputMaybe<ContractTypeContractTypeCodeKeyDelete>;
  deleteByContractTypeId?: InputMaybe<ContractTypeContractTypePkeyDelete>;
  deleteByDisplayName?: InputMaybe<ContractTypeContractTypeDisplayNameKeyDelete>;
  deleteByNodeId?: InputMaybe<ContractTypeNodeIdDelete>;
  updateByCode?: InputMaybe<ContractTypeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey2UsingContractTypeCodeKeyUpdate>;
  updateByContractTypeId?: InputMaybe<ContractTypeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey2UsingContractTypePkeyUpdate>;
  updateByDisplayName?: InputMaybe<ContractTypeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey2UsingContractTypeDisplayNameKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey2NodeIdUpdate>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey2InverseInput {
  connectByEmployeeId?: InputMaybe<Array<EmployeeCurrentPositionFakePublicEmployeeCurrentPositionPrimaryKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeCurrentPositionNodeIdConnect>>;
  updateByEmployeeId?: InputMaybe<Array<EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey2UsingFakePublicEmployeeCurrentPositionPrimaryKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<ContractTypeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey2NodeIdUpdate>>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey3EmployeeLevelCreateInput {
  canEditTargetCountry?: InputMaybe<Scalars['Boolean']['input']>;
  canEditTargetIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  canEditTargetTeam?: InputMaybe<Scalars['Boolean']['input']>;
  canSeePerformanceReviewOverallOfAll?: InputMaybe<Scalars['Boolean']['input']>;
  canSeePerformanceReviewOverallOfCountry?: InputMaybe<Scalars['Boolean']['input']>;
  canSeeProbationReviewOfAll?: InputMaybe<Scalars['Boolean']['input']>;
  canSeeProbationReviewOfCountry?: InputMaybe<Scalars['Boolean']['input']>;
  displayName: Scalars['String']['input'];
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey3InverseInput>;
  employeeLevelOrder: Scalars['Int']['input'];
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInverseInput>;
  performanceReviewMasterWeightingDetails?: InputMaybe<PerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  titleContributor?: InputMaybe<Scalars['String']['input']>;
  titleManager?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeLevelUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey3Input {
  connectByDisplayName?: InputMaybe<EmployeeLevelEmployeeLevelDisplayNameKeyConnect>;
  connectByEmployeeLevelId?: InputMaybe<EmployeeLevelEmployeeLevelPkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeLevelNodeIdConnect>;
  create?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey3EmployeeLevelCreateInput>;
  deleteByDisplayName?: InputMaybe<EmployeeLevelEmployeeLevelDisplayNameKeyDelete>;
  deleteByEmployeeLevelId?: InputMaybe<EmployeeLevelEmployeeLevelPkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeLevelNodeIdDelete>;
  updateByDisplayName?: InputMaybe<EmployeeLevelOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey3UsingEmployeeLevelDisplayNameKeyUpdate>;
  updateByEmployeeLevelId?: InputMaybe<EmployeeLevelOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey3UsingEmployeeLevelPkeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey3NodeIdUpdate>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey3InverseInput {
  connectByEmployeeId?: InputMaybe<Array<EmployeeCurrentPositionFakePublicEmployeeCurrentPositionPrimaryKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeCurrentPositionNodeIdConnect>>;
  updateByEmployeeId?: InputMaybe<Array<EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey3UsingFakePublicEmployeeCurrentPositionPrimaryKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<EmployeeLevelOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey3NodeIdUpdate>>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey4CountryCreateInput {
  banks?: InputMaybe<BankCountryCodeAlpha3FkeyInverseInput>;
  cities?: InputMaybe<CityCountryCodeAlpha2FkeyInverseInput>;
  companies?: InputMaybe<CompanyCountryCodeAlpha2FkeyInverseInput>;
  countryCodeAlpha3: Scalars['String']['input'];
  countryCodeNumeric?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInverseInput>;
  employeeIdTypes?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInverseInput>;
  employeesToMobileNumberCountryIdUsingCountryCodeAlpha2?: InputMaybe<EmployeeMobileNumberCountryIdFkeyInverseInput>;
  employeesToNationalityUsingCountryCodeAlpha3?: InputMaybe<EmployeeNationalityFkeyInverseInput>;
  employeesToWorkingLocationCountryCodeAlpha2UsingCountryCodeAlpha2?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0InverseInput>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName: Scalars['String']['input'];
  telephoneCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey4Input {
  connectByCountryCodeAlpha2?: InputMaybe<CountryCountryPkeyConnect>;
  connectByCountryCodeAlpha3?: InputMaybe<CountryCountryCountryCodeAlpha3KeyConnect>;
  connectByCountryCodeNumeric?: InputMaybe<CountryCountryCountryCodeNumericKeyConnect>;
  connectByDisplayOrder?: InputMaybe<CountryCountryDisplayOrderKeyConnect>;
  connectByNodeId?: InputMaybe<CountryNodeIdConnect>;
  create?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4CountryCreateInput>;
  deleteByCountryCodeAlpha2?: InputMaybe<CountryCountryPkeyDelete>;
  deleteByCountryCodeAlpha3?: InputMaybe<CountryCountryCountryCodeAlpha3KeyDelete>;
  deleteByCountryCodeNumeric?: InputMaybe<CountryCountryCountryCodeNumericKeyDelete>;
  deleteByDisplayOrder?: InputMaybe<CountryCountryDisplayOrderKeyDelete>;
  deleteByNodeId?: InputMaybe<CountryNodeIdDelete>;
  updateByCountryCodeAlpha2?: InputMaybe<CountryOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey4UsingCountryPkeyUpdate>;
  updateByCountryCodeAlpha3?: InputMaybe<CountryOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey4UsingCountryCountryCodeAlpha3KeyUpdate>;
  updateByCountryCodeNumeric?: InputMaybe<CountryOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey4UsingCountryCountryCodeNumericKeyUpdate>;
  updateByDisplayOrder?: InputMaybe<CountryOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey4UsingCountryDisplayOrderKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey4NodeIdUpdate>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey4InverseInput {
  connectByEmployeeId?: InputMaybe<Array<EmployeeCurrentPositionFakePublicEmployeeCurrentPositionPrimaryKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeCurrentPositionNodeIdConnect>>;
  updateByEmployeeId?: InputMaybe<Array<EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey4UsingFakePublicEmployeeCurrentPositionPrimaryKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<CountryOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey4NodeIdUpdate>>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey5CompanyCreateInput {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName: Scalars['String']['input'];
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey5Input {
  connectByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyConnect>;
  connectByCompanyId?: InputMaybe<CompanyCompanyPkeyConnect>;
  connectByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyConnect>;
  connectByNodeId?: InputMaybe<CompanyNodeIdConnect>;
  create?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5CompanyCreateInput>;
  deleteByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyDelete>;
  deleteByCompanyId?: InputMaybe<CompanyCompanyPkeyDelete>;
  deleteByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyDelete>;
  deleteByNodeId?: InputMaybe<CompanyNodeIdDelete>;
  updateByAbbreviation?: InputMaybe<CompanyOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey5UsingCompanyAbbreviationKeyUpdate>;
  updateByCompanyId?: InputMaybe<CompanyOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey5UsingCompanyPkeyUpdate>;
  updateByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey5UsingCompanyCountryCodeAlpha2FullNameKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey5NodeIdUpdate>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey5InverseInput {
  connectByEmployeeId?: InputMaybe<Array<EmployeeCurrentPositionFakePublicEmployeeCurrentPositionPrimaryKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeCurrentPositionNodeIdConnect>>;
  updateByEmployeeId?: InputMaybe<Array<EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey5UsingFakePublicEmployeeCurrentPositionPrimaryKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<CompanyOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey5NodeIdUpdate>>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey6CityCreateInput {
  country?: InputMaybe<CityCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey6InverseInput>;
  name: Scalars['String']['input'];
  offices?: InputMaybe<OfficeCityIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCityIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CityUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  utcOffset?: InputMaybe<IntervalInput>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey6Input {
  connectByCityId?: InputMaybe<CityCityPkeyConnect>;
  connectByCountryCodeAlpha2AndName?: InputMaybe<CityCityCountryCodeAlpha2NameKeyConnect>;
  connectByNodeId?: InputMaybe<CityNodeIdConnect>;
  create?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey6CityCreateInput>;
  deleteByCityId?: InputMaybe<CityCityPkeyDelete>;
  deleteByCountryCodeAlpha2AndName?: InputMaybe<CityCityCountryCodeAlpha2NameKeyDelete>;
  deleteByNodeId?: InputMaybe<CityNodeIdDelete>;
  updateByCityId?: InputMaybe<CityOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey6UsingCityPkeyUpdate>;
  updateByCountryCodeAlpha2AndName?: InputMaybe<CityOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey6UsingCityCountryCodeAlpha2NameKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey6NodeIdUpdate>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey6InverseInput {
  connectByEmployeeId?: InputMaybe<Array<EmployeeCurrentPositionFakePublicEmployeeCurrentPositionPrimaryKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeCurrentPositionNodeIdConnect>>;
  updateByEmployeeId?: InputMaybe<Array<EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey6UsingFakePublicEmployeeCurrentPositionPrimaryKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<CityOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey6NodeIdUpdate>>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey7Input {
  connectByCityIdAndName?: InputMaybe<OfficeOfficeCityIdNameKeyConnect>;
  connectByNodeId?: InputMaybe<OfficeNodeIdConnect>;
  connectByOfficeId?: InputMaybe<OfficeOfficePkeyConnect>;
  create?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7OfficeCreateInput>;
  deleteByCityIdAndName?: InputMaybe<OfficeOfficeCityIdNameKeyDelete>;
  deleteByNodeId?: InputMaybe<OfficeNodeIdDelete>;
  deleteByOfficeId?: InputMaybe<OfficeOfficePkeyDelete>;
  updateByCityIdAndName?: InputMaybe<OfficeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey7UsingOfficeCityIdNameKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey7NodeIdUpdate>;
  updateByOfficeId?: InputMaybe<OfficeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey7UsingOfficePkeyUpdate>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey7InverseInput {
  connectByEmployeeId?: InputMaybe<Array<EmployeeCurrentPositionFakePublicEmployeeCurrentPositionPrimaryKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeCurrentPositionNodeIdConnect>>;
  updateByEmployeeId?: InputMaybe<Array<EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey7UsingFakePublicEmployeeCurrentPositionPrimaryKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<OfficeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey7NodeIdUpdate>>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey7OfficeCreateInput {
  city?: InputMaybe<OfficeCityIdFkeyInput>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInverseInput>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  officeWeathers?: InputMaybe<OfficeWeatherOfficeIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeOfficeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<OfficeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey8BusinessUnitCreateInput {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInverseInput>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BusinessUnitUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey8Input {
  connectByBusinessUnitId?: InputMaybe<BusinessUnitBusinessUnitPkeyConnect>;
  connectByName?: InputMaybe<BusinessUnitBusinessUnitNameKeyConnect>;
  connectByNodeId?: InputMaybe<BusinessUnitNodeIdConnect>;
  create?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8BusinessUnitCreateInput>;
  deleteByBusinessUnitId?: InputMaybe<BusinessUnitBusinessUnitPkeyDelete>;
  deleteByName?: InputMaybe<BusinessUnitBusinessUnitNameKeyDelete>;
  deleteByNodeId?: InputMaybe<BusinessUnitNodeIdDelete>;
  updateByBusinessUnitId?: InputMaybe<BusinessUnitOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey8UsingBusinessUnitPkeyUpdate>;
  updateByName?: InputMaybe<BusinessUnitOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey8UsingBusinessUnitNameKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey8NodeIdUpdate>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey8InverseInput {
  connectByEmployeeId?: InputMaybe<Array<EmployeeCurrentPositionFakePublicEmployeeCurrentPositionPrimaryKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeCurrentPositionNodeIdConnect>>;
  updateByEmployeeId?: InputMaybe<Array<EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey8UsingFakePublicEmployeeCurrentPositionPrimaryKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<BusinessUnitOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey8NodeIdUpdate>>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey9DivisionCreateInput {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionDivisionIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DivisionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey9Input {
  connectByDivisionId?: InputMaybe<DivisionDivisionPkeyConnect>;
  connectByName?: InputMaybe<DivisionDivisionNameKeyConnect>;
  connectByNodeId?: InputMaybe<DivisionNodeIdConnect>;
  create?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9DivisionCreateInput>;
  deleteByDivisionId?: InputMaybe<DivisionDivisionPkeyDelete>;
  deleteByName?: InputMaybe<DivisionDivisionNameKeyDelete>;
  deleteByNodeId?: InputMaybe<DivisionNodeIdDelete>;
  updateByDivisionId?: InputMaybe<DivisionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey9UsingDivisionPkeyUpdate>;
  updateByName?: InputMaybe<DivisionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey9UsingDivisionNameKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey9NodeIdUpdate>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey9InverseInput {
  connectByEmployeeId?: InputMaybe<Array<EmployeeCurrentPositionFakePublicEmployeeCurrentPositionPrimaryKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeCurrentPositionNodeIdConnect>>;
  updateByEmployeeId?: InputMaybe<Array<EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey9UsingFakePublicEmployeeCurrentPositionPrimaryKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<DivisionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey9NodeIdUpdate>>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey10DepartmentCreateInput {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey10InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DepartmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey10Input {
  connectByDepartmentId?: InputMaybe<DepartmentDepartmentPkeyConnect>;
  connectByName?: InputMaybe<DepartmentDepartmentNameKeyConnect>;
  connectByNodeId?: InputMaybe<DepartmentNodeIdConnect>;
  create?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey10DepartmentCreateInput>;
  deleteByDepartmentId?: InputMaybe<DepartmentDepartmentPkeyDelete>;
  deleteByName?: InputMaybe<DepartmentDepartmentNameKeyDelete>;
  deleteByNodeId?: InputMaybe<DepartmentNodeIdDelete>;
  updateByDepartmentId?: InputMaybe<DepartmentOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey10UsingDepartmentPkeyUpdate>;
  updateByName?: InputMaybe<DepartmentOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey10UsingDepartmentNameKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey10NodeIdUpdate>;
}

export interface FakePublicEmployeeCurrentPositionForeignKey10InverseInput {
  connectByEmployeeId?: InputMaybe<Array<EmployeeCurrentPositionFakePublicEmployeeCurrentPositionPrimaryKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeCurrentPositionNodeIdConnect>>;
  updateByEmployeeId?: InputMaybe<Array<EmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey10UsingFakePublicEmployeeCurrentPositionPrimaryKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<DepartmentOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey10NodeIdUpdate>>;
}

export interface FakePublicEmployeeDaysByContractTypeForeignKey0EmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface FakePublicEmployeeDaysByContractTypeForeignKey0Input {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0EmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnEmployeeDaysByContractTypeForFakePublicEmployeeDaysByContractTypeForeignKey0UsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnEmployeeDaysByContractTypeForFakePublicEmployeeDaysByContractTypeForeignKey0UsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeDaysByContractTypeOnEmployeeDaysByContractTypeForFakePublicEmployeeDaysByContractTypeForeignKey0NodeIdUpdate>;
}

export interface FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput {
  connectByEmployeeIdAndEffectiveDate?: InputMaybe<Array<EmployeeDaysByContractTypeFakePublicEmployeeDaysByContractTypePrimaryKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeDaysByContractTypeNodeIdConnect>>;
  updateByEmployeeIdAndEffectiveDate?: InputMaybe<
    Array<EmployeeDaysByContractTypeOnEmployeeDaysByContractTypeForFakePublicEmployeeDaysByContractTypeForeignKey0UsingFakePublicEmployeeDaysByContractTypePrimaryKeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<EmployeeOnEmployeeDaysByContractTypeForFakePublicEmployeeDaysByContractTypeForeignKey0NodeIdUpdate>>;
}

export interface FakePublicEmployeeGroupLatestForeignKey0EmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface FakePublicEmployeeGroupLatestForeignKey0Input {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0EmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey0UsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey0UsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeGroupLatestOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey0NodeIdUpdate>;
}

export interface FakePublicEmployeeGroupLatestForeignKey0InverseInput {
  connectByEmployeeId?: InputMaybe<EmployeeGroupLatestFakePublicEmployeeGroupLatestPrimaryKeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeGroupLatestNodeIdConnect>;
  updateByEmployeeId?: InputMaybe<EmployeeGroupLatestOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey0UsingFakePublicEmployeeGroupLatestPrimaryKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey0NodeIdUpdate>;
}

export interface FakePublicEmployeeGroupLatestForeignKey1Input {
  connectByEmployeeStatusKey?: InputMaybe<EmployeeStatusEmployeeStatusPkeyConnect>;
  connectByEmployeeStatusOrder?: InputMaybe<EmployeeStatusEmployeeStatusEmployeeStatusOrderKeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeStatusNodeIdConnect>;
  updateByEmployeeStatusKey?: InputMaybe<EmployeeStatusOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey1UsingEmployeeStatusPkeyUpdate>;
  updateByEmployeeStatusOrder?: InputMaybe<EmployeeStatusOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey1UsingEmployeeStatusEmployeeStatusOrderKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeGroupLatestOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey1NodeIdUpdate>;
}

export interface FakePublicEmployeeGroupLatestForeignKey1InverseInput {
  connectByEmployeeId?: InputMaybe<Array<EmployeeGroupLatestFakePublicEmployeeGroupLatestPrimaryKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeGroupLatestNodeIdConnect>>;
  updateByEmployeeId?: InputMaybe<Array<EmployeeGroupLatestOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey1UsingFakePublicEmployeeGroupLatestPrimaryKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<EmployeeStatusOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey1NodeIdUpdate>>;
}

export interface FakePublicEmployeeGroupListForeignKey0EmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface FakePublicEmployeeGroupListForeignKey0Input {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<FakePublicEmployeeGroupListForeignKey0EmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnEmployeeGroupListForFakePublicEmployeeGroupListForeignKey0UsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnEmployeeGroupListForFakePublicEmployeeGroupListForeignKey0UsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeGroupListOnEmployeeGroupListForFakePublicEmployeeGroupListForeignKey0NodeIdUpdate>;
}

export interface FakePublicEmployeeGroupListForeignKey0InverseInput {
  connectByCompanyEmailAndEmployeeId?: InputMaybe<Array<EmployeeGroupListFakePublicEmployeeGroupListPrimaryKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeGroupListNodeIdConnect>>;
  updateByCompanyEmailAndEmployeeId?: InputMaybe<Array<EmployeeGroupListOnEmployeeGroupListForFakePublicEmployeeGroupListForeignKey0UsingFakePublicEmployeeGroupListPrimaryKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<EmployeeOnEmployeeGroupListForFakePublicEmployeeGroupListForeignKey0NodeIdUpdate>>;
}

export interface FakePublicEmployeeTransferHistoryForeignKey0EmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface FakePublicEmployeeTransferHistoryForeignKey0Input {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0EmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey0UsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey0UsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeTransferHistoryOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey0NodeIdUpdate>;
}

export interface FakePublicEmployeeTransferHistoryForeignKey0InverseInput {
  connectByEmployeeIdAndEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTransferHistoryFakePublicEmployeeTransferHistoryPrimaryKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeTransferHistoryNodeIdConnect>>;
  updateByEmployeeIdAndEmployeeTpHistoryId?: InputMaybe<
    Array<EmployeeTransferHistoryOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey0UsingFakePublicEmployeeTransferHistoryPrimaryKeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<EmployeeOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey0NodeIdUpdate>>;
}

export interface FakePublicEmployeeTransferHistoryForeignKey1EmployeeTpHistoryCreateInput {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate: Scalars['Date']['input'];
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface FakePublicEmployeeTransferHistoryForeignKey1Input {
  connectByEmployeeIdAndEffectiveDate?: InputMaybe<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyConnect>;
  connectByEmployeeTpHistoryId?: InputMaybe<EmployeeTpHistoryEmployeeTpHistoryPkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeTpHistoryNodeIdConnect>;
  create?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1EmployeeTpHistoryCreateInput>;
  deleteByEmployeeIdAndEffectiveDate?: InputMaybe<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyDelete>;
  deleteByEmployeeTpHistoryId?: InputMaybe<EmployeeTpHistoryEmployeeTpHistoryPkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeTpHistoryNodeIdDelete>;
  updateByEmployeeIdAndEffectiveDate?: InputMaybe<EmployeeTpHistoryOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey1UsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate>;
  updateByEmployeeTpHistoryId?: InputMaybe<EmployeeTpHistoryOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey1UsingEmployeeTpHistoryPkeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeTransferHistoryOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey1NodeIdUpdate>;
}

export interface FakePublicEmployeeTransferHistoryForeignKey1InverseInput {
  connectByEmployeeIdAndEmployeeTpHistoryId?: InputMaybe<Array<EmployeeTransferHistoryFakePublicEmployeeTransferHistoryPrimaryKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeTransferHistoryNodeIdConnect>>;
  updateByEmployeeIdAndEmployeeTpHistoryId?: InputMaybe<
    Array<EmployeeTransferHistoryOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey1UsingFakePublicEmployeeTransferHistoryPrimaryKeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<EmployeeTpHistoryOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey1NodeIdUpdate>>;
}

export interface FakePublicEmployeeWorkingDayStatisticsAllForeignKey0EmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface FakePublicEmployeeWorkingDayStatisticsAllForeignKey0Input {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0EmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey0UsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey0UsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeWorkingDayStatisticsAllOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey0NodeIdUpdate>;
}

export interface FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput {
  connectByEmployeeId?: InputMaybe<EmployeeWorkingDayStatisticsAllFakePublicEmployeeWorkingDayStatisticsAllPrimaryKeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeWorkingDayStatisticsAllNodeIdConnect>;
  updateByEmployeeId?: InputMaybe<EmployeeWorkingDayStatisticsAllOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey0UsingFakePublicEmployeeWorkingDayStatisticsAllPrimaryKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey0NodeIdUpdate>;
}

export interface FakePublicEmployeeWorkingDayStatisticsAllForeignKey1ContractTypeCreateInput {
  code: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey1InverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey2InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableContractTypeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ContractTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface FakePublicEmployeeWorkingDayStatisticsAllForeignKey1Input {
  connectByCode?: InputMaybe<ContractTypeContractTypeCodeKeyConnect>;
  connectByContractTypeId?: InputMaybe<ContractTypeContractTypePkeyConnect>;
  connectByDisplayName?: InputMaybe<ContractTypeContractTypeDisplayNameKeyConnect>;
  connectByNodeId?: InputMaybe<ContractTypeNodeIdConnect>;
  create?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey1ContractTypeCreateInput>;
  deleteByCode?: InputMaybe<ContractTypeContractTypeCodeKeyDelete>;
  deleteByContractTypeId?: InputMaybe<ContractTypeContractTypePkeyDelete>;
  deleteByDisplayName?: InputMaybe<ContractTypeContractTypeDisplayNameKeyDelete>;
  deleteByNodeId?: InputMaybe<ContractTypeNodeIdDelete>;
  updateByCode?: InputMaybe<ContractTypeOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey1UsingContractTypeCodeKeyUpdate>;
  updateByContractTypeId?: InputMaybe<ContractTypeOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey1UsingContractTypePkeyUpdate>;
  updateByDisplayName?: InputMaybe<ContractTypeOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey1UsingContractTypeDisplayNameKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeWorkingDayStatisticsAllOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey1NodeIdUpdate>;
}

export interface FakePublicEmployeeWorkingDayStatisticsAllForeignKey1InverseInput {
  connectByEmployeeId?: InputMaybe<Array<EmployeeWorkingDayStatisticsAllFakePublicEmployeeWorkingDayStatisticsAllPrimaryKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeWorkingDayStatisticsAllNodeIdConnect>>;
  updateByEmployeeId?: InputMaybe<
    Array<EmployeeWorkingDayStatisticsAllOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey1UsingFakePublicEmployeeWorkingDayStatisticsAllPrimaryKeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<ContractTypeOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey1NodeIdUpdate>>;
}

export interface FakePublicEmployeeWorkingDayStatisticsAllForeignKey2CompanyCreateInput {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName: Scalars['String']['input'];
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface FakePublicEmployeeWorkingDayStatisticsAllForeignKey2Input {
  connectByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyConnect>;
  connectByCompanyId?: InputMaybe<CompanyCompanyPkeyConnect>;
  connectByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyConnect>;
  connectByNodeId?: InputMaybe<CompanyNodeIdConnect>;
  create?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2CompanyCreateInput>;
  deleteByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyDelete>;
  deleteByCompanyId?: InputMaybe<CompanyCompanyPkeyDelete>;
  deleteByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyDelete>;
  deleteByNodeId?: InputMaybe<CompanyNodeIdDelete>;
  updateByAbbreviation?: InputMaybe<CompanyOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey2UsingCompanyAbbreviationKeyUpdate>;
  updateByCompanyId?: InputMaybe<CompanyOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey2UsingCompanyPkeyUpdate>;
  updateByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey2UsingCompanyCountryCodeAlpha2FullNameKeyUpdate>;
  updateByNodeId?: InputMaybe<EmployeeWorkingDayStatisticsAllOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey2NodeIdUpdate>;
}

export interface FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput {
  connectByEmployeeId?: InputMaybe<Array<EmployeeWorkingDayStatisticsAllFakePublicEmployeeWorkingDayStatisticsAllPrimaryKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<EmployeeWorkingDayStatisticsAllNodeIdConnect>>;
  updateByEmployeeId?: InputMaybe<
    Array<EmployeeWorkingDayStatisticsAllOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey2UsingFakePublicEmployeeWorkingDayStatisticsAllPrimaryKeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<CompanyOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey2NodeIdUpdate>>;
}

export interface FakePublicProbationReviewEnableViewForeignKey0CountryCreateInput {
  banks?: InputMaybe<BankCountryCodeAlpha3FkeyInverseInput>;
  cities?: InputMaybe<CityCountryCodeAlpha2FkeyInverseInput>;
  companies?: InputMaybe<CompanyCountryCodeAlpha2FkeyInverseInput>;
  countryCodeAlpha3: Scalars['String']['input'];
  countryCodeNumeric?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInverseInput>;
  employeeIdTypes?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInverseInput>;
  employeesToMobileNumberCountryIdUsingCountryCodeAlpha2?: InputMaybe<EmployeeMobileNumberCountryIdFkeyInverseInput>;
  employeesToNationalityUsingCountryCodeAlpha3?: InputMaybe<EmployeeNationalityFkeyInverseInput>;
  employeesToWorkingLocationCountryCodeAlpha2UsingCountryCodeAlpha2?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0InverseInput>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName: Scalars['String']['input'];
  telephoneCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface FakePublicProbationReviewEnableViewForeignKey0Input {
  connectByCountryCodeAlpha2?: InputMaybe<CountryCountryPkeyConnect>;
  connectByCountryCodeAlpha3?: InputMaybe<CountryCountryCountryCodeAlpha3KeyConnect>;
  connectByCountryCodeNumeric?: InputMaybe<CountryCountryCountryCodeNumericKeyConnect>;
  connectByDisplayOrder?: InputMaybe<CountryCountryDisplayOrderKeyConnect>;
  connectByNodeId?: InputMaybe<CountryNodeIdConnect>;
  create?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0CountryCreateInput>;
  deleteByCountryCodeAlpha2?: InputMaybe<CountryCountryPkeyDelete>;
  deleteByCountryCodeAlpha3?: InputMaybe<CountryCountryCountryCodeAlpha3KeyDelete>;
  deleteByCountryCodeNumeric?: InputMaybe<CountryCountryCountryCodeNumericKeyDelete>;
  deleteByDisplayOrder?: InputMaybe<CountryCountryDisplayOrderKeyDelete>;
  deleteByNodeId?: InputMaybe<CountryNodeIdDelete>;
  updateByCountryCodeAlpha2?: InputMaybe<CountryOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey0UsingCountryPkeyUpdate>;
  updateByCountryCodeAlpha3?: InputMaybe<CountryOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey0UsingCountryCountryCodeAlpha3KeyUpdate>;
  updateByCountryCodeNumeric?: InputMaybe<CountryOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey0UsingCountryCountryCodeNumericKeyUpdate>;
  updateByDisplayOrder?: InputMaybe<CountryOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey0UsingCountryDisplayOrderKeyUpdate>;
  updateByNodeId?: InputMaybe<ProbationReviewEnableViewOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey0NodeIdUpdate>;
}

export interface FakePublicProbationReviewEnableViewForeignKey0InverseInput {
  connectByCompanyIdAndContractTypeId?: InputMaybe<Array<ProbationReviewEnableViewFakePublicProbationReviewEnableViewPrimaryKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<ProbationReviewEnableViewNodeIdConnect>>;
  create?: InputMaybe<Array<FakePublicProbationReviewEnableViewForeignKey0ProbationReviewEnableViewCreateInput>>;
  deleteByCompanyIdAndContractTypeId?: InputMaybe<Array<ProbationReviewEnableViewFakePublicProbationReviewEnableViewPrimaryKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEnableViewNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyIdAndContractTypeId?: InputMaybe<
    Array<ProbationReviewEnableViewOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey0UsingFakePublicProbationReviewEnableViewPrimaryKeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<CountryOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey0NodeIdUpdate>>;
}

export interface FakePublicProbationReviewEnableViewForeignKey0ProbationReviewEnableViewCreateInput {
  company?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1Input>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey2Input>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0Input>;
  defaultProbationPeriod?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  probationDateInterval?: InputMaybe<Scalars['Int']['input']>;
  probationReviewEnableId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface FakePublicProbationReviewEnableViewForeignKey1CompanyCreateInput {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName: Scalars['String']['input'];
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface FakePublicProbationReviewEnableViewForeignKey1Input {
  connectByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyConnect>;
  connectByCompanyId?: InputMaybe<CompanyCompanyPkeyConnect>;
  connectByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyConnect>;
  connectByNodeId?: InputMaybe<CompanyNodeIdConnect>;
  create?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1CompanyCreateInput>;
  deleteByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyDelete>;
  deleteByCompanyId?: InputMaybe<CompanyCompanyPkeyDelete>;
  deleteByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyDelete>;
  deleteByNodeId?: InputMaybe<CompanyNodeIdDelete>;
  updateByAbbreviation?: InputMaybe<CompanyOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey1UsingCompanyAbbreviationKeyUpdate>;
  updateByCompanyId?: InputMaybe<CompanyOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey1UsingCompanyPkeyUpdate>;
  updateByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey1UsingCompanyCountryCodeAlpha2FullNameKeyUpdate>;
  updateByNodeId?: InputMaybe<ProbationReviewEnableViewOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey1NodeIdUpdate>;
}

export interface FakePublicProbationReviewEnableViewForeignKey1InverseInput {
  connectByCompanyIdAndContractTypeId?: InputMaybe<Array<ProbationReviewEnableViewFakePublicProbationReviewEnableViewPrimaryKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<ProbationReviewEnableViewNodeIdConnect>>;
  create?: InputMaybe<Array<FakePublicProbationReviewEnableViewForeignKey1ProbationReviewEnableViewCreateInput>>;
  deleteByCompanyIdAndContractTypeId?: InputMaybe<Array<ProbationReviewEnableViewFakePublicProbationReviewEnableViewPrimaryKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEnableViewNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyIdAndContractTypeId?: InputMaybe<
    Array<ProbationReviewEnableViewOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey1UsingFakePublicProbationReviewEnableViewPrimaryKeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<CompanyOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey1NodeIdUpdate>>;
}

export interface FakePublicProbationReviewEnableViewForeignKey1ProbationReviewEnableViewCreateInput {
  company?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1Input>;
  contractType?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey2Input>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0Input>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  defaultProbationPeriod?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  probationDateInterval?: InputMaybe<Scalars['Int']['input']>;
  probationReviewEnableId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface FakePublicProbationReviewEnableViewForeignKey2ContractTypeCreateInput {
  code: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey1InverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey2InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableContractTypeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ContractTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface FakePublicProbationReviewEnableViewForeignKey2Input {
  connectByCode?: InputMaybe<ContractTypeContractTypeCodeKeyConnect>;
  connectByContractTypeId?: InputMaybe<ContractTypeContractTypePkeyConnect>;
  connectByDisplayName?: InputMaybe<ContractTypeContractTypeDisplayNameKeyConnect>;
  connectByNodeId?: InputMaybe<ContractTypeNodeIdConnect>;
  create?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey2ContractTypeCreateInput>;
  deleteByCode?: InputMaybe<ContractTypeContractTypeCodeKeyDelete>;
  deleteByContractTypeId?: InputMaybe<ContractTypeContractTypePkeyDelete>;
  deleteByDisplayName?: InputMaybe<ContractTypeContractTypeDisplayNameKeyDelete>;
  deleteByNodeId?: InputMaybe<ContractTypeNodeIdDelete>;
  updateByCode?: InputMaybe<ContractTypeOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey2UsingContractTypeCodeKeyUpdate>;
  updateByContractTypeId?: InputMaybe<ContractTypeOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey2UsingContractTypePkeyUpdate>;
  updateByDisplayName?: InputMaybe<ContractTypeOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey2UsingContractTypeDisplayNameKeyUpdate>;
  updateByNodeId?: InputMaybe<ProbationReviewEnableViewOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey2NodeIdUpdate>;
}

export interface FakePublicProbationReviewEnableViewForeignKey2InverseInput {
  connectByCompanyIdAndContractTypeId?: InputMaybe<Array<ProbationReviewEnableViewFakePublicProbationReviewEnableViewPrimaryKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<ProbationReviewEnableViewNodeIdConnect>>;
  create?: InputMaybe<Array<FakePublicProbationReviewEnableViewForeignKey2ProbationReviewEnableViewCreateInput>>;
  deleteByCompanyIdAndContractTypeId?: InputMaybe<Array<ProbationReviewEnableViewFakePublicProbationReviewEnableViewPrimaryKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEnableViewNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyIdAndContractTypeId?: InputMaybe<
    Array<ProbationReviewEnableViewOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey2UsingFakePublicProbationReviewEnableViewPrimaryKeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<ContractTypeOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey2NodeIdUpdate>>;
}

export interface FakePublicProbationReviewEnableViewForeignKey2ProbationReviewEnableViewCreateInput {
  company?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1Input>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey2Input>;
  country?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0Input>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  defaultProbationPeriod?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  probationDateInterval?: InputMaybe<Scalars['Int']['input']>;
  probationReviewEnableId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface FloatFilter {
  distinctFrom?: InputMaybe<Scalars['Float']['input']>;
  equalTo?: InputMaybe<Scalars['Float']['input']>;
  greaterThan?: InputMaybe<Scalars['Float']['input']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<Scalars['Float']['input']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['Float']['input']>;
  notDistinctFrom?: InputMaybe<Scalars['Float']['input']>;
  notEqualTo?: InputMaybe<Scalars['Float']['input']>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
}

export interface FlywaySchemaHistoriesConnection {
  __typename?: 'FlywaySchemaHistoriesConnection';
  edges: Array<FlywaySchemaHistoriesEdge>;
  nodes: Array<FlywaySchemaHistory>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface FlywaySchemaHistoriesEdge {
  __typename?: 'FlywaySchemaHistoriesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: FlywaySchemaHistory;
}

export enum FlywaySchemaHistoriesOrderBy {
  CHECKSUM_ASC = 'CHECKSUM_ASC',
  CHECKSUM_DESC = 'CHECKSUM_DESC',
  DESCRIPTION_ASC = 'DESCRIPTION_ASC',
  DESCRIPTION_DESC = 'DESCRIPTION_DESC',
  EXECUTION_TIME_ASC = 'EXECUTION_TIME_ASC',
  EXECUTION_TIME_DESC = 'EXECUTION_TIME_DESC',
  INSTALLED_BY_ASC = 'INSTALLED_BY_ASC',
  INSTALLED_BY_DESC = 'INSTALLED_BY_DESC',
  INSTALLED_ON_ASC = 'INSTALLED_ON_ASC',
  INSTALLED_ON_DESC = 'INSTALLED_ON_DESC',
  INSTALLED_RANK_ASC = 'INSTALLED_RANK_ASC',
  INSTALLED_RANK_DESC = 'INSTALLED_RANK_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  SCRIPT_ASC = 'SCRIPT_ASC',
  SCRIPT_DESC = 'SCRIPT_DESC',
  SUCCESS_ASC = 'SUCCESS_ASC',
  SUCCESS_DESC = 'SUCCESS_DESC',
  TYPE_ASC = 'TYPE_ASC',
  TYPE_DESC = 'TYPE_DESC',
  VERSION_ASC = 'VERSION_ASC',
  VERSION_DESC = 'VERSION_DESC',
}

export interface FlywaySchemaHistory extends Node {
  __typename?: 'FlywaySchemaHistory';
  checksum?: Maybe<Scalars['Int']['output']>;
  description: Scalars['String']['output'];
  executionTime: Scalars['Int']['output'];
  installedBy: Scalars['String']['output'];
  installedOn: Scalars['Datetime']['output'];
  installedRank: Scalars['Int']['output'];
  nodeId: Scalars['ID']['output'];
  script: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
  version?: Maybe<Scalars['String']['output']>;
}

export interface FlywaySchemaHistoryCondition {
  checksum?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  executionTime?: InputMaybe<Scalars['Int']['input']>;
  installedBy?: InputMaybe<Scalars['String']['input']>;
  installedOn?: InputMaybe<Scalars['Datetime']['input']>;
  installedRank?: InputMaybe<Scalars['Int']['input']>;
  script?: InputMaybe<Scalars['String']['input']>;
  success?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
}

export interface FlywaySchemaHistoryFilter {
  and?: InputMaybe<Array<FlywaySchemaHistoryFilter>>;
  checksum?: InputMaybe<IntFilter>;
  description?: InputMaybe<StringFilter>;
  executionTime?: InputMaybe<IntFilter>;
  installedBy?: InputMaybe<StringFilter>;
  installedOn?: InputMaybe<DatetimeFilter>;
  installedRank?: InputMaybe<IntFilter>;
  not?: InputMaybe<FlywaySchemaHistoryFilter>;
  or?: InputMaybe<Array<FlywaySchemaHistoryFilter>>;
  script?: InputMaybe<StringFilter>;
  success?: InputMaybe<BooleanFilter>;
  type?: InputMaybe<StringFilter>;
  version?: InputMaybe<StringFilter>;
}

export interface GetCurrentYearQuarterInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

export interface GetCurrentYearQuarterPayload {
  __typename?: 'GetCurrentYearQuarterPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
  yearQuarter?: Maybe<YearQuarter>;
}

export interface GetProbationReviewEvaluationReviewerUsageInput {
  _probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

export interface GetProbationReviewEvaluationReviewerUsagePayload {
  __typename?: 'GetProbationReviewEvaluationReviewerUsagePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  integer?: Maybe<Scalars['Int']['output']>;
  query?: Maybe<Query>;
}

export interface GoogleJwk extends Node {
  __typename?: 'GoogleJwk';
  body: Scalars['JSON']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  googleJwkId: Scalars['BigInt']['output'];
  keyId: Scalars['String']['output'];
  keyType: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
}

export interface GoogleJwkCondition {
  body?: InputMaybe<Scalars['JSON']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  googleJwkId?: InputMaybe<Scalars['BigInt']['input']>;
  keyId?: InputMaybe<Scalars['String']['input']>;
  keyType?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
}

export interface GoogleJwkFilter {
  and?: InputMaybe<Array<GoogleJwkFilter>>;
  body?: InputMaybe<JsonFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  googleJwkId?: InputMaybe<BigIntFilter>;
  keyId?: InputMaybe<StringFilter>;
  keyType?: InputMaybe<StringFilter>;
  not?: InputMaybe<GoogleJwkFilter>;
  or?: InputMaybe<Array<GoogleJwkFilter>>;
  remark?: InputMaybe<StringFilter>;
}

export interface GoogleJwksConnection {
  __typename?: 'GoogleJwksConnection';
  edges: Array<GoogleJwksEdge>;
  nodes: Array<GoogleJwk>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface GoogleJwksEdge {
  __typename?: 'GoogleJwksEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: GoogleJwk;
}

export enum GoogleJwksOrderBy {
  BODY_ASC = 'BODY_ASC',
  BODY_DESC = 'BODY_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  GOOGLE_JWK_ID_ASC = 'GOOGLE_JWK_ID_ASC',
  GOOGLE_JWK_ID_DESC = 'GOOGLE_JWK_ID_DESC',
  KEY_ID_ASC = 'KEY_ID_ASC',
  KEY_ID_DESC = 'KEY_ID_DESC',
  KEY_TYPE_ASC = 'KEY_TYPE_ASC',
  KEY_TYPE_DESC = 'KEY_TYPE_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
}

export interface GoogleWorkspaceOrgUnit extends Node {
  __typename?: 'GoogleWorkspaceOrgUnit';
  businessUnit?: Maybe<BusinessUnit>;
  businessUnitId?: Maybe<Scalars['BigInt']['output']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['BigInt']['output']>;
  googleWorkspaceOrgUnitId: Scalars['BigInt']['output'];
  nodeId: Scalars['ID']['output'];
  orgUnitPath: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
}

export interface GoogleWorkspaceOrgUnitBusinessUnitIdFkeyBusinessUnitCreateInput {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInverseInput>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BusinessUnitUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface GoogleWorkspaceOrgUnitBusinessUnitIdFkeyGoogleWorkspaceOrgUnitCreateInput {
  businessUnit?: InputMaybe<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInput>;
  company?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  googleWorkspaceOrgUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  orgUnitPath: Scalars['String']['input'];
  priority: Scalars['Int']['input'];
}

export interface GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInput {
  connectByBusinessUnitId?: InputMaybe<BusinessUnitBusinessUnitPkeyConnect>;
  connectByName?: InputMaybe<BusinessUnitBusinessUnitNameKeyConnect>;
  connectByNodeId?: InputMaybe<BusinessUnitNodeIdConnect>;
  create?: InputMaybe<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyBusinessUnitCreateInput>;
  deleteByBusinessUnitId?: InputMaybe<BusinessUnitBusinessUnitPkeyDelete>;
  deleteByName?: InputMaybe<BusinessUnitBusinessUnitNameKeyDelete>;
  deleteByNodeId?: InputMaybe<BusinessUnitNodeIdDelete>;
  updateByBusinessUnitId?: InputMaybe<BusinessUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitBusinessUnitIdFkeyUsingBusinessUnitPkeyUpdate>;
  updateByName?: InputMaybe<BusinessUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitBusinessUnitIdFkeyUsingBusinessUnitNameKeyUpdate>;
  updateByNodeId?: InputMaybe<GoogleWorkspaceOrgUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitBusinessUnitIdFkeyNodeIdUpdate>;
}

export interface GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInverseInput {
  connectByCompanyIdAndBusinessUnitId?: InputMaybe<Array<GoogleWorkspaceOrgUnitGoogleWorkspaceOrgUnitCompanyIdBusinessUnitIdKeyConnect>>;
  connectByGoogleWorkspaceOrgUnitId?: InputMaybe<Array<GoogleWorkspaceOrgUnitGoogleWorkspaceOrgUnitPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<GoogleWorkspaceOrgUnitNodeIdConnect>>;
  create?: InputMaybe<Array<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyGoogleWorkspaceOrgUnitCreateInput>>;
  deleteByCompanyIdAndBusinessUnitId?: InputMaybe<Array<GoogleWorkspaceOrgUnitGoogleWorkspaceOrgUnitCompanyIdBusinessUnitIdKeyDelete>>;
  deleteByGoogleWorkspaceOrgUnitId?: InputMaybe<Array<GoogleWorkspaceOrgUnitGoogleWorkspaceOrgUnitPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<GoogleWorkspaceOrgUnitNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyIdAndBusinessUnitId?: InputMaybe<
    Array<GoogleWorkspaceOrgUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitBusinessUnitIdFkeyUsingGoogleWorkspaceOrgUnitCompanyIdBusinessUnitIdKeyUpdate>
  >;
  updateByGoogleWorkspaceOrgUnitId?: InputMaybe<Array<GoogleWorkspaceOrgUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitBusinessUnitIdFkeyUsingGoogleWorkspaceOrgUnitPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<BusinessUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitBusinessUnitIdFkeyNodeIdUpdate>>;
}

export interface GoogleWorkspaceOrgUnitCompanyIdFkeyCompanyCreateInput {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName: Scalars['String']['input'];
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface GoogleWorkspaceOrgUnitCompanyIdFkeyGoogleWorkspaceOrgUnitCreateInput {
  businessUnit?: InputMaybe<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInput>;
  googleWorkspaceOrgUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  orgUnitPath: Scalars['String']['input'];
  priority: Scalars['Int']['input'];
}

export interface GoogleWorkspaceOrgUnitCompanyIdFkeyInput {
  connectByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyConnect>;
  connectByCompanyId?: InputMaybe<CompanyCompanyPkeyConnect>;
  connectByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyConnect>;
  connectByNodeId?: InputMaybe<CompanyNodeIdConnect>;
  create?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyCompanyCreateInput>;
  deleteByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyDelete>;
  deleteByCompanyId?: InputMaybe<CompanyCompanyPkeyDelete>;
  deleteByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyDelete>;
  deleteByNodeId?: InputMaybe<CompanyNodeIdDelete>;
  updateByAbbreviation?: InputMaybe<CompanyOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitCompanyIdFkeyUsingCompanyAbbreviationKeyUpdate>;
  updateByCompanyId?: InputMaybe<CompanyOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitCompanyIdFkeyUsingCompanyPkeyUpdate>;
  updateByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitCompanyIdFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate>;
  updateByNodeId?: InputMaybe<GoogleWorkspaceOrgUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitCompanyIdFkeyNodeIdUpdate>;
}

export interface GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput {
  connectByCompanyIdAndBusinessUnitId?: InputMaybe<Array<GoogleWorkspaceOrgUnitGoogleWorkspaceOrgUnitCompanyIdBusinessUnitIdKeyConnect>>;
  connectByGoogleWorkspaceOrgUnitId?: InputMaybe<Array<GoogleWorkspaceOrgUnitGoogleWorkspaceOrgUnitPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<GoogleWorkspaceOrgUnitNodeIdConnect>>;
  create?: InputMaybe<Array<GoogleWorkspaceOrgUnitCompanyIdFkeyGoogleWorkspaceOrgUnitCreateInput>>;
  deleteByCompanyIdAndBusinessUnitId?: InputMaybe<Array<GoogleWorkspaceOrgUnitGoogleWorkspaceOrgUnitCompanyIdBusinessUnitIdKeyDelete>>;
  deleteByGoogleWorkspaceOrgUnitId?: InputMaybe<Array<GoogleWorkspaceOrgUnitGoogleWorkspaceOrgUnitPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<GoogleWorkspaceOrgUnitNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyIdAndBusinessUnitId?: InputMaybe<
    Array<GoogleWorkspaceOrgUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitCompanyIdFkeyUsingGoogleWorkspaceOrgUnitCompanyIdBusinessUnitIdKeyUpdate>
  >;
  updateByGoogleWorkspaceOrgUnitId?: InputMaybe<Array<GoogleWorkspaceOrgUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitCompanyIdFkeyUsingGoogleWorkspaceOrgUnitPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<CompanyOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitCompanyIdFkeyNodeIdUpdate>>;
}

export interface GoogleWorkspaceOrgUnitCondition {
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  googleWorkspaceOrgUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  orgUnitPath?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
}

export interface GoogleWorkspaceOrgUnitFilter {
  and?: InputMaybe<Array<GoogleWorkspaceOrgUnitFilter>>;
  businessUnit?: InputMaybe<BusinessUnitFilter>;
  businessUnitExists?: InputMaybe<Scalars['Boolean']['input']>;
  businessUnitId?: InputMaybe<BigIntFilter>;
  company?: InputMaybe<CompanyFilter>;
  companyExists?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<BigIntFilter>;
  googleWorkspaceOrgUnitId?: InputMaybe<BigIntFilter>;
  not?: InputMaybe<GoogleWorkspaceOrgUnitFilter>;
  or?: InputMaybe<Array<GoogleWorkspaceOrgUnitFilter>>;
  orgUnitPath?: InputMaybe<StringFilter>;
  priority?: InputMaybe<IntFilter>;
}

export interface GoogleWorkspaceOrgUnitGoogleWorkspaceOrgUnitCompanyIdBusinessUnitIdKeyConnect {
  businessUnitId: Scalars['BigInt']['input'];
  companyId: Scalars['BigInt']['input'];
}

export interface GoogleWorkspaceOrgUnitGoogleWorkspaceOrgUnitCompanyIdBusinessUnitIdKeyDelete {
  businessUnitId: Scalars['BigInt']['input'];
  companyId: Scalars['BigInt']['input'];
}

export interface GoogleWorkspaceOrgUnitGoogleWorkspaceOrgUnitPkeyConnect {
  googleWorkspaceOrgUnitId: Scalars['BigInt']['input'];
}

export interface GoogleWorkspaceOrgUnitGoogleWorkspaceOrgUnitPkeyDelete {
  googleWorkspaceOrgUnitId: Scalars['BigInt']['input'];
}

export interface GoogleWorkspaceOrgUnitInput {
  businessUnit?: InputMaybe<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  googleWorkspaceOrgUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  orgUnitPath: Scalars['String']['input'];
  priority: Scalars['Int']['input'];
}

export interface GoogleWorkspaceOrgUnitNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface GoogleWorkspaceOrgUnitNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface GoogleWorkspaceOrgUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitBusinessUnitIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: BusinessUnitPatch;
}

export interface GoogleWorkspaceOrgUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitBusinessUnitIdFkeyUsingGoogleWorkspaceOrgUnitCompanyIdBusinessUnitIdKeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  companyId: Scalars['BigInt']['input'];
  patch: UpdateGoogleWorkspaceOrgUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitBusinessUnitIdFkeyPatch;
}

export interface GoogleWorkspaceOrgUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitBusinessUnitIdFkeyUsingGoogleWorkspaceOrgUnitPkeyUpdate {
  googleWorkspaceOrgUnitId: Scalars['BigInt']['input'];
  patch: UpdateGoogleWorkspaceOrgUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitBusinessUnitIdFkeyPatch;
}

export interface GoogleWorkspaceOrgUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitCompanyIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyPatch;
}

export interface GoogleWorkspaceOrgUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitCompanyIdFkeyUsingGoogleWorkspaceOrgUnitCompanyIdBusinessUnitIdKeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  companyId: Scalars['BigInt']['input'];
  patch: UpdateGoogleWorkspaceOrgUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitCompanyIdFkeyPatch;
}

export interface GoogleWorkspaceOrgUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitCompanyIdFkeyUsingGoogleWorkspaceOrgUnitPkeyUpdate {
  googleWorkspaceOrgUnitId: Scalars['BigInt']['input'];
  patch: UpdateGoogleWorkspaceOrgUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitCompanyIdFkeyPatch;
}

export interface GoogleWorkspaceOrgUnitPatch {
  businessUnit?: InputMaybe<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  googleWorkspaceOrgUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  orgUnitPath?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
}

export interface GoogleWorkspaceOrgUnitsConnection {
  __typename?: 'GoogleWorkspaceOrgUnitsConnection';
  edges: Array<GoogleWorkspaceOrgUnitsEdge>;
  nodes: Array<GoogleWorkspaceOrgUnit>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface GoogleWorkspaceOrgUnitsEdge {
  __typename?: 'GoogleWorkspaceOrgUnitsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: GoogleWorkspaceOrgUnit;
}

export enum GoogleWorkspaceOrgUnitsOrderBy {
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_DESC',
  BUSINESS_UNIT_ID_ASC = 'BUSINESS_UNIT_ID_ASC',
  BUSINESS_UNIT_ID_DESC = 'BUSINESS_UNIT_ID_DESC',
  COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC',
  COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_ASC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_ASC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_DESC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_DESC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANY_BY_COMPANY_ID__REMARK_ASC = 'COMPANY_BY_COMPANY_ID__REMARK_ASC',
  COMPANY_BY_COMPANY_ID__REMARK_DESC = 'COMPANY_BY_COMPANY_ID__REMARK_DESC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC',
  COMPANY_ID_ASC = 'COMPANY_ID_ASC',
  COMPANY_ID_DESC = 'COMPANY_ID_DESC',
  GOOGLE_WORKSPACE_ORG_UNIT_ID_ASC = 'GOOGLE_WORKSPACE_ORG_UNIT_ID_ASC',
  GOOGLE_WORKSPACE_ORG_UNIT_ID_DESC = 'GOOGLE_WORKSPACE_ORG_UNIT_ID_DESC',
  NATURAL = 'NATURAL',
  ORG_UNIT_PATH_ASC = 'ORG_UNIT_PATH_ASC',
  ORG_UNIT_PATH_DESC = 'ORG_UNIT_PATH_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PRIORITY_ASC = 'PRIORITY_ASC',
  PRIORITY_DESC = 'PRIORITY_DESC',
}

export interface HavingBigintFilter {
  equalTo?: InputMaybe<Scalars['BigInt']['input']>;
  greaterThan?: InputMaybe<Scalars['BigInt']['input']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['BigInt']['input']>;
  lessThan?: InputMaybe<Scalars['BigInt']['input']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['BigInt']['input']>;
  notEqualTo?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface HavingDatetimeFilter {
  equalTo?: InputMaybe<Scalars['Datetime']['input']>;
  greaterThan?: InputMaybe<Scalars['Datetime']['input']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  lessThan?: InputMaybe<Scalars['Datetime']['input']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  notEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
}

export interface HavingIntFilter {
  equalTo?: InputMaybe<Scalars['Int']['input']>;
  greaterThan?: InputMaybe<Scalars['Int']['input']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']['input']>;
  lessThan?: InputMaybe<Scalars['Int']['input']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']['input']>;
  notEqualTo?: InputMaybe<Scalars['Int']['input']>;
}

export interface ImportEmployeeStatus extends Node {
  __typename?: 'ImportEmployeeStatus';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  fileMode: Scalars['String']['output'];
  googleAuth?: Maybe<Scalars['JSON']['output']>;
  importEmployeeStatusId: Scalars['BigInt']['output'];
  inProgress: Scalars['Boolean']['output'];
  nodeId: Scalars['ID']['output'];
  progressLoopCount?: Maybe<Scalars['Int']['output']>;
  progressProcessedCount?: Maybe<Scalars['Int']['output']>;
  progressProcessedCountOfLoop?: Maybe<Scalars['Int']['output']>;
  progressShouldStop: Scalars['Boolean']['output'];
  progressTotalCount?: Maybe<Scalars['Int']['output']>;
  progressTotalCountOfLoop?: Maybe<Scalars['Int']['output']>;
  requestCreateUnsatisfiedMasters: Scalars['Boolean']['output'];
  requestOverwriteExisting: Scalars['Boolean']['output'];
  requestUrl: Scalars['String']['output'];
  responseError?: Maybe<Scalars['JSON']['output']>;
  responseSuccess?: Maybe<Scalars['Boolean']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
  uuid4: Scalars['String']['output'];
}

export interface ImportEmployeeStatusCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  fileMode?: InputMaybe<Scalars['String']['input']>;
  googleAuth?: InputMaybe<Scalars['JSON']['input']>;
  importEmployeeStatusId?: InputMaybe<Scalars['BigInt']['input']>;
  inProgress?: InputMaybe<Scalars['Boolean']['input']>;
  progressLoopCount?: InputMaybe<Scalars['Int']['input']>;
  progressProcessedCount?: InputMaybe<Scalars['Int']['input']>;
  progressProcessedCountOfLoop?: InputMaybe<Scalars['Int']['input']>;
  progressShouldStop?: InputMaybe<Scalars['Boolean']['input']>;
  progressTotalCount?: InputMaybe<Scalars['Int']['input']>;
  progressTotalCountOfLoop?: InputMaybe<Scalars['Int']['input']>;
  requestCreateUnsatisfiedMasters?: InputMaybe<Scalars['Boolean']['input']>;
  requestOverwriteExisting?: InputMaybe<Scalars['Boolean']['input']>;
  requestUrl?: InputMaybe<Scalars['String']['input']>;
  responseError?: InputMaybe<Scalars['JSON']['input']>;
  responseSuccess?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  uuid4?: InputMaybe<Scalars['String']['input']>;
}

export interface ImportEmployeeStatusFilter {
  and?: InputMaybe<Array<ImportEmployeeStatusFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  fileMode?: InputMaybe<StringFilter>;
  googleAuth?: InputMaybe<JsonFilter>;
  importEmployeeStatusId?: InputMaybe<BigIntFilter>;
  inProgress?: InputMaybe<BooleanFilter>;
  not?: InputMaybe<ImportEmployeeStatusFilter>;
  or?: InputMaybe<Array<ImportEmployeeStatusFilter>>;
  progressLoopCount?: InputMaybe<IntFilter>;
  progressProcessedCount?: InputMaybe<IntFilter>;
  progressProcessedCountOfLoop?: InputMaybe<IntFilter>;
  progressShouldStop?: InputMaybe<BooleanFilter>;
  progressTotalCount?: InputMaybe<IntFilter>;
  progressTotalCountOfLoop?: InputMaybe<IntFilter>;
  requestCreateUnsatisfiedMasters?: InputMaybe<BooleanFilter>;
  requestOverwriteExisting?: InputMaybe<BooleanFilter>;
  requestUrl?: InputMaybe<StringFilter>;
  responseError?: InputMaybe<JsonFilter>;
  responseSuccess?: InputMaybe<BooleanFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
  uuid4?: InputMaybe<StringFilter>;
}

export interface ImportEmployeeStatusImportEmployeeStatusPkeyConnect {
  importEmployeeStatusId: Scalars['BigInt']['input'];
}

export interface ImportEmployeeStatusImportEmployeeStatusPkeyDelete {
  importEmployeeStatusId: Scalars['BigInt']['input'];
}

export interface ImportEmployeeStatusImportEmployeeStatusUuid4KeyConnect {
  uuid4: Scalars['String']['input'];
}

export interface ImportEmployeeStatusImportEmployeeStatusUuid4KeyDelete {
  uuid4: Scalars['String']['input'];
}

export interface ImportEmployeeStatusInput {
  fileMode?: InputMaybe<Scalars['String']['input']>;
  googleAuth?: InputMaybe<Scalars['JSON']['input']>;
  importEmployeeStatusId?: InputMaybe<Scalars['BigInt']['input']>;
  inProgress: Scalars['Boolean']['input'];
  progressLoopCount?: InputMaybe<Scalars['Int']['input']>;
  progressProcessedCount?: InputMaybe<Scalars['Int']['input']>;
  progressProcessedCountOfLoop?: InputMaybe<Scalars['Int']['input']>;
  progressShouldStop?: InputMaybe<Scalars['Boolean']['input']>;
  progressTotalCount?: InputMaybe<Scalars['Int']['input']>;
  progressTotalCountOfLoop?: InputMaybe<Scalars['Int']['input']>;
  requestCreateUnsatisfiedMasters: Scalars['Boolean']['input'];
  requestOverwriteExisting: Scalars['Boolean']['input'];
  requestUrl: Scalars['String']['input'];
  responseError?: InputMaybe<Scalars['JSON']['input']>;
  responseSuccess?: InputMaybe<Scalars['Boolean']['input']>;
  userAccount?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  uuid4: Scalars['String']['input'];
}

export interface ImportEmployeeStatusNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface ImportEmployeeStatusNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface ImportEmployeeStatusOnImportEmployeeStatusForImportEmployeeStatusUserCreatedFkeyUsingImportEmployeeStatusPkeyUpdate {
  importEmployeeStatusId: Scalars['BigInt']['input'];
  patch: UpdateImportEmployeeStatusOnImportEmployeeStatusForImportEmployeeStatusUserCreatedFkeyPatch;
}

export interface ImportEmployeeStatusOnImportEmployeeStatusForImportEmployeeStatusUserCreatedFkeyUsingImportEmployeeStatusUuid4KeyUpdate {
  patch: UpdateImportEmployeeStatusOnImportEmployeeStatusForImportEmployeeStatusUserCreatedFkeyPatch;
  uuid4: Scalars['String']['input'];
}

export interface ImportEmployeeStatusOnImportEmployeeStatusForImportEmployeeStatusUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface ImportEmployeeStatusOnImportEmployeeStatusForImportEmployeeStatusUserUpdatedFkeyUsingImportEmployeeStatusPkeyUpdate {
  importEmployeeStatusId: Scalars['BigInt']['input'];
  patch: UpdateImportEmployeeStatusOnImportEmployeeStatusForImportEmployeeStatusUserUpdatedFkeyPatch;
}

export interface ImportEmployeeStatusOnImportEmployeeStatusForImportEmployeeStatusUserUpdatedFkeyUsingImportEmployeeStatusUuid4KeyUpdate {
  patch: UpdateImportEmployeeStatusOnImportEmployeeStatusForImportEmployeeStatusUserUpdatedFkeyPatch;
  uuid4: Scalars['String']['input'];
}

export interface ImportEmployeeStatusPatch {
  fileMode?: InputMaybe<Scalars['String']['input']>;
  googleAuth?: InputMaybe<Scalars['JSON']['input']>;
  importEmployeeStatusId?: InputMaybe<Scalars['BigInt']['input']>;
  inProgress?: InputMaybe<Scalars['Boolean']['input']>;
  progressLoopCount?: InputMaybe<Scalars['Int']['input']>;
  progressProcessedCount?: InputMaybe<Scalars['Int']['input']>;
  progressProcessedCountOfLoop?: InputMaybe<Scalars['Int']['input']>;
  progressShouldStop?: InputMaybe<Scalars['Boolean']['input']>;
  progressTotalCount?: InputMaybe<Scalars['Int']['input']>;
  progressTotalCountOfLoop?: InputMaybe<Scalars['Int']['input']>;
  requestCreateUnsatisfiedMasters?: InputMaybe<Scalars['Boolean']['input']>;
  requestOverwriteExisting?: InputMaybe<Scalars['Boolean']['input']>;
  requestUrl?: InputMaybe<Scalars['String']['input']>;
  responseError?: InputMaybe<Scalars['JSON']['input']>;
  responseSuccess?: InputMaybe<Scalars['Boolean']['input']>;
  userAccount?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  uuid4?: InputMaybe<Scalars['String']['input']>;
}

export interface ImportEmployeeStatusUserCreatedFkeyInverseInput {
  connectByImportEmployeeStatusId?: InputMaybe<Array<ImportEmployeeStatusImportEmployeeStatusPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<ImportEmployeeStatusNodeIdConnect>>;
  connectByUuid4?: InputMaybe<Array<ImportEmployeeStatusImportEmployeeStatusUuid4KeyConnect>>;
  deleteByImportEmployeeStatusId?: InputMaybe<Array<ImportEmployeeStatusImportEmployeeStatusPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<ImportEmployeeStatusNodeIdDelete>>;
  deleteByUuid4?: InputMaybe<Array<ImportEmployeeStatusImportEmployeeStatusUuid4KeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByImportEmployeeStatusId?: InputMaybe<Array<ImportEmployeeStatusOnImportEmployeeStatusForImportEmployeeStatusUserCreatedFkeyUsingImportEmployeeStatusPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnImportEmployeeStatusForImportEmployeeStatusUserCreatedFkeyNodeIdUpdate>>;
  updateByUuid4?: InputMaybe<Array<ImportEmployeeStatusOnImportEmployeeStatusForImportEmployeeStatusUserCreatedFkeyUsingImportEmployeeStatusUuid4KeyUpdate>>;
}

export interface ImportEmployeeStatusUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnImportEmployeeStatusForImportEmployeeStatusUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<ImportEmployeeStatusOnImportEmployeeStatusForImportEmployeeStatusUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnImportEmployeeStatusForImportEmployeeStatusUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface ImportEmployeeStatusUserUpdatedFkeyInverseInput {
  connectByImportEmployeeStatusId?: InputMaybe<Array<ImportEmployeeStatusImportEmployeeStatusPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<ImportEmployeeStatusNodeIdConnect>>;
  connectByUuid4?: InputMaybe<Array<ImportEmployeeStatusImportEmployeeStatusUuid4KeyConnect>>;
  deleteByImportEmployeeStatusId?: InputMaybe<Array<ImportEmployeeStatusImportEmployeeStatusPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<ImportEmployeeStatusNodeIdDelete>>;
  deleteByUuid4?: InputMaybe<Array<ImportEmployeeStatusImportEmployeeStatusUuid4KeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByImportEmployeeStatusId?: InputMaybe<Array<ImportEmployeeStatusOnImportEmployeeStatusForImportEmployeeStatusUserUpdatedFkeyUsingImportEmployeeStatusPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnImportEmployeeStatusForImportEmployeeStatusUserUpdatedFkeyNodeIdUpdate>>;
  updateByUuid4?: InputMaybe<Array<ImportEmployeeStatusOnImportEmployeeStatusForImportEmployeeStatusUserUpdatedFkeyUsingImportEmployeeStatusUuid4KeyUpdate>>;
}

export interface ImportEmployeeStatusesConnection {
  __typename?: 'ImportEmployeeStatusesConnection';
  edges: Array<ImportEmployeeStatusesEdge>;
  nodes: Array<ImportEmployeeStatus>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ImportEmployeeStatusesEdge {
  __typename?: 'ImportEmployeeStatusesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ImportEmployeeStatus;
}

export enum ImportEmployeeStatusesOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  FILE_MODE_ASC = 'FILE_MODE_ASC',
  FILE_MODE_DESC = 'FILE_MODE_DESC',
  GOOGLE_AUTH_ASC = 'GOOGLE_AUTH_ASC',
  GOOGLE_AUTH_DESC = 'GOOGLE_AUTH_DESC',
  IMPORT_EMPLOYEE_STATUS_ID_ASC = 'IMPORT_EMPLOYEE_STATUS_ID_ASC',
  IMPORT_EMPLOYEE_STATUS_ID_DESC = 'IMPORT_EMPLOYEE_STATUS_ID_DESC',
  IN_PROGRESS_ASC = 'IN_PROGRESS_ASC',
  IN_PROGRESS_DESC = 'IN_PROGRESS_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROGRESS_LOOP_COUNT_ASC = 'PROGRESS_LOOP_COUNT_ASC',
  PROGRESS_LOOP_COUNT_DESC = 'PROGRESS_LOOP_COUNT_DESC',
  PROGRESS_PROCESSED_COUNT_ASC = 'PROGRESS_PROCESSED_COUNT_ASC',
  PROGRESS_PROCESSED_COUNT_DESC = 'PROGRESS_PROCESSED_COUNT_DESC',
  PROGRESS_PROCESSED_COUNT_OF_LOOP_ASC = 'PROGRESS_PROCESSED_COUNT_OF_LOOP_ASC',
  PROGRESS_PROCESSED_COUNT_OF_LOOP_DESC = 'PROGRESS_PROCESSED_COUNT_OF_LOOP_DESC',
  PROGRESS_SHOULD_STOP_ASC = 'PROGRESS_SHOULD_STOP_ASC',
  PROGRESS_SHOULD_STOP_DESC = 'PROGRESS_SHOULD_STOP_DESC',
  PROGRESS_TOTAL_COUNT_ASC = 'PROGRESS_TOTAL_COUNT_ASC',
  PROGRESS_TOTAL_COUNT_DESC = 'PROGRESS_TOTAL_COUNT_DESC',
  PROGRESS_TOTAL_COUNT_OF_LOOP_ASC = 'PROGRESS_TOTAL_COUNT_OF_LOOP_ASC',
  PROGRESS_TOTAL_COUNT_OF_LOOP_DESC = 'PROGRESS_TOTAL_COUNT_OF_LOOP_DESC',
  REQUEST_CREATE_UNSATISFIED_MASTERS_ASC = 'REQUEST_CREATE_UNSATISFIED_MASTERS_ASC',
  REQUEST_CREATE_UNSATISFIED_MASTERS_DESC = 'REQUEST_CREATE_UNSATISFIED_MASTERS_DESC',
  REQUEST_OVERWRITE_EXISTING_ASC = 'REQUEST_OVERWRITE_EXISTING_ASC',
  REQUEST_OVERWRITE_EXISTING_DESC = 'REQUEST_OVERWRITE_EXISTING_DESC',
  REQUEST_URL_ASC = 'REQUEST_URL_ASC',
  REQUEST_URL_DESC = 'REQUEST_URL_DESC',
  RESPONSE_ERROR_ASC = 'RESPONSE_ERROR_ASC',
  RESPONSE_ERROR_DESC = 'RESPONSE_ERROR_DESC',
  RESPONSE_SUCCESS_ASC = 'RESPONSE_SUCCESS_ASC',
  RESPONSE_SUCCESS_DESC = 'RESPONSE_SUCCESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
  UUID4_ASC = 'UUID4_ASC',
  UUID4_DESC = 'UUID4_DESC',
}

export interface InitializeAllRolesInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

export interface InitializeAllRolesPayload {
  __typename?: 'InitializeAllRolesPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
}

export interface InputTypeCopyPerformanceReviewMasterCompetencyCommon {
  __typename?: 'InputTypeCopyPerformanceReviewMasterCompetencyCommon';
  sourceQuarter?: Maybe<PerformanceReviewQuarter>;
  sourceYear?: Maybe<Scalars['Int']['output']>;
  targetQuarter?: Maybe<PerformanceReviewQuarter>;
  targetYear?: Maybe<Scalars['Int']['output']>;
}

export interface InputTypeCopyPerformanceReviewMasterCompetencyCommonInput {
  sourceQuarter?: InputMaybe<PerformanceReviewQuarter>;
  sourceYear?: InputMaybe<Scalars['Int']['input']>;
  targetQuarter?: InputMaybe<PerformanceReviewQuarter>;
  targetYear?: InputMaybe<Scalars['Int']['input']>;
}

export interface InputTypeCopyPerformanceReviewMasterCompetencyDetail {
  __typename?: 'InputTypeCopyPerformanceReviewMasterCompetencyDetail';
  sourceQuarter?: Maybe<PerformanceReviewQuarter>;
  sourceYear?: Maybe<Scalars['Int']['output']>;
  targetQuarter?: Maybe<PerformanceReviewQuarter>;
  targetYear?: Maybe<Scalars['Int']['output']>;
}

export interface InputTypeCopyPerformanceReviewMasterCompetencyDetailInput {
  sourceQuarter?: InputMaybe<PerformanceReviewQuarter>;
  sourceYear?: InputMaybe<Scalars['Int']['input']>;
  targetQuarter?: InputMaybe<PerformanceReviewQuarter>;
  targetYear?: InputMaybe<Scalars['Int']['input']>;
}

export interface InputTypeCopyPerformanceReviewMasterRating {
  __typename?: 'InputTypeCopyPerformanceReviewMasterRating';
  sourceQuarter?: Maybe<PerformanceReviewQuarter>;
  sourceYear?: Maybe<Scalars['Int']['output']>;
  targetQuarter?: Maybe<PerformanceReviewQuarter>;
  targetYear?: Maybe<Scalars['Int']['output']>;
}

export interface InputTypeCopyPerformanceReviewMasterRatingInput {
  sourceQuarter?: InputMaybe<PerformanceReviewQuarter>;
  sourceYear?: InputMaybe<Scalars['Int']['input']>;
  targetQuarter?: InputMaybe<PerformanceReviewQuarter>;
  targetYear?: InputMaybe<Scalars['Int']['input']>;
}

export interface InputTypeCopyPerformanceReviewMasterValue {
  __typename?: 'InputTypeCopyPerformanceReviewMasterValue';
  sourceQuarter?: Maybe<PerformanceReviewQuarter>;
  sourceYear?: Maybe<Scalars['Int']['output']>;
  targetQuarter?: Maybe<PerformanceReviewQuarter>;
  targetYear?: Maybe<Scalars['Int']['output']>;
}

export interface InputTypeCopyPerformanceReviewMasterValueInput {
  sourceQuarter?: InputMaybe<PerformanceReviewQuarter>;
  sourceYear?: InputMaybe<Scalars['Int']['input']>;
  targetQuarter?: InputMaybe<PerformanceReviewQuarter>;
  targetYear?: InputMaybe<Scalars['Int']['input']>;
}

export interface InputTypeCopyPerformanceReviewMasterWeighting {
  __typename?: 'InputTypeCopyPerformanceReviewMasterWeighting';
  sourceQuarter?: Maybe<PerformanceReviewQuarter>;
  sourceYear?: Maybe<Scalars['Int']['output']>;
  targetQuarter?: Maybe<PerformanceReviewQuarter>;
  targetYear?: Maybe<Scalars['Int']['output']>;
}

export interface InputTypeCopyPerformanceReviewMasterWeightingInput {
  sourceQuarter?: InputMaybe<PerformanceReviewQuarter>;
  sourceYear?: InputMaybe<Scalars['Int']['input']>;
  targetQuarter?: InputMaybe<PerformanceReviewQuarter>;
  targetYear?: InputMaybe<Scalars['Int']['input']>;
}

export interface IntFilter {
  distinctFrom?: InputMaybe<Scalars['Int']['input']>;
  equalTo?: InputMaybe<Scalars['Int']['input']>;
  greaterThan?: InputMaybe<Scalars['Int']['input']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<Scalars['Int']['input']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']['input']>;
  notDistinctFrom?: InputMaybe<Scalars['Int']['input']>;
  notEqualTo?: InputMaybe<Scalars['Int']['input']>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface InternetAddressFilter {
  containedBy?: InputMaybe<Scalars['InternetAddress']['input']>;
  containedByOrEqualTo?: InputMaybe<Scalars['InternetAddress']['input']>;
  contains?: InputMaybe<Scalars['InternetAddress']['input']>;
  containsOrContainedBy?: InputMaybe<Scalars['InternetAddress']['input']>;
  containsOrEqualTo?: InputMaybe<Scalars['InternetAddress']['input']>;
  distinctFrom?: InputMaybe<Scalars['InternetAddress']['input']>;
  equalTo?: InputMaybe<Scalars['InternetAddress']['input']>;
  greaterThan?: InputMaybe<Scalars['InternetAddress']['input']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['InternetAddress']['input']>;
  in?: InputMaybe<Array<Scalars['InternetAddress']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<Scalars['InternetAddress']['input']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['InternetAddress']['input']>;
  notDistinctFrom?: InputMaybe<Scalars['InternetAddress']['input']>;
  notEqualTo?: InputMaybe<Scalars['InternetAddress']['input']>;
  notIn?: InputMaybe<Array<Scalars['InternetAddress']['input']>>;
}

export interface Interval {
  __typename?: 'Interval';
  days?: Maybe<Scalars['Int']['output']>;
  hours?: Maybe<Scalars['Int']['output']>;
  minutes?: Maybe<Scalars['Int']['output']>;
  months?: Maybe<Scalars['Int']['output']>;
  seconds?: Maybe<Scalars['Float']['output']>;
  years?: Maybe<Scalars['Int']['output']>;
}

export interface IntervalFilter {
  distinctFrom?: InputMaybe<IntervalInput>;
  equalTo?: InputMaybe<IntervalInput>;
  greaterThan?: InputMaybe<IntervalInput>;
  greaterThanOrEqualTo?: InputMaybe<IntervalInput>;
  in?: InputMaybe<Array<IntervalInput>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<IntervalInput>;
  lessThanOrEqualTo?: InputMaybe<IntervalInput>;
  notDistinctFrom?: InputMaybe<IntervalInput>;
  notEqualTo?: InputMaybe<IntervalInput>;
  notIn?: InputMaybe<Array<IntervalInput>>;
}

export interface IntervalInput {
  days?: InputMaybe<Scalars['Int']['input']>;
  hours?: InputMaybe<Scalars['Int']['input']>;
  minutes?: InputMaybe<Scalars['Int']['input']>;
  months?: InputMaybe<Scalars['Int']['input']>;
  seconds?: InputMaybe<Scalars['Float']['input']>;
  years?: InputMaybe<Scalars['Int']['input']>;
}

export interface JsonFilter {
  containedBy?: InputMaybe<Scalars['JSON']['input']>;
  contains?: InputMaybe<Scalars['JSON']['input']>;
  containsAllKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  containsAnyKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  containsKey?: InputMaybe<Scalars['String']['input']>;
  distinctFrom?: InputMaybe<Scalars['JSON']['input']>;
  equalTo?: InputMaybe<Scalars['JSON']['input']>;
  greaterThan?: InputMaybe<Scalars['JSON']['input']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<Scalars['JSON']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<Scalars['JSON']['input']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['JSON']['input']>;
  notDistinctFrom?: InputMaybe<Scalars['JSON']['input']>;
  notEqualTo?: InputMaybe<Scalars['JSON']['input']>;
  notIn?: InputMaybe<Array<Scalars['JSON']['input']>>;
}

export interface ListenPayload {
  __typename?: 'ListenPayload';
  query?: Maybe<Query>;
  relatedNode?: Maybe<Node>;
  relatedNodeId?: Maybe<Scalars['ID']['output']>;
}

export interface MergeBankInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['BigInt']['input']>;
  targetId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface MergeBankPayload {
  __typename?: 'MergeBankPayload';
  bigInt?: Maybe<Scalars['BigInt']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
}

export interface MergeBusinessUnitInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['BigInt']['input']>;
  targetId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface MergeBusinessUnitPayload {
  __typename?: 'MergeBusinessUnitPayload';
  bigInt?: Maybe<Scalars['BigInt']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
}

export interface MergeCityInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['BigInt']['input']>;
  targetId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface MergeCityPayload {
  __typename?: 'MergeCityPayload';
  bigInt?: Maybe<Scalars['BigInt']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
}

export interface MergeCompanyGroupInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['BigInt']['input']>;
  targetId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface MergeCompanyGroupPayload {
  __typename?: 'MergeCompanyGroupPayload';
  bigInt?: Maybe<Scalars['BigInt']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
}

export interface MergeCompanyInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['BigInt']['input']>;
  targetId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface MergeCompanyPayload {
  __typename?: 'MergeCompanyPayload';
  bigInt?: Maybe<Scalars['BigInt']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
}

export interface MergeContractTypeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['BigInt']['input']>;
  targetId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface MergeContractTypePayload {
  __typename?: 'MergeContractTypePayload';
  bigInt?: Maybe<Scalars['BigInt']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
}

export interface MergeCountryInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sourceCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  targetCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface MergeCountryPayload {
  __typename?: 'MergeCountryPayload';
  bigInt?: Maybe<Scalars['BigInt']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
}

export interface MergeCurrencyInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['String']['input']>;
  targetId?: InputMaybe<Scalars['String']['input']>;
}

export interface MergeCurrencyPayload {
  __typename?: 'MergeCurrencyPayload';
  bigInt?: Maybe<Scalars['BigInt']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
}

export interface MergeDepartmentInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['BigInt']['input']>;
  targetId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface MergeDepartmentPayload {
  __typename?: 'MergeDepartmentPayload';
  bigInt?: Maybe<Scalars['BigInt']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
}

export interface MergeDivisionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['BigInt']['input']>;
  targetId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface MergeDivisionPayload {
  __typename?: 'MergeDivisionPayload';
  bigInt?: Maybe<Scalars['BigInt']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
}

export interface MergeEmployeeLevelInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['BigInt']['input']>;
  targetId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface MergeEmployeeLevelPayload {
  __typename?: 'MergeEmployeeLevelPayload';
  bigInt?: Maybe<Scalars['BigInt']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
}

export interface MergeEmployeeResignationReasonInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['BigInt']['input']>;
  targetId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface MergeEmployeeResignationReasonPayload {
  __typename?: 'MergeEmployeeResignationReasonPayload';
  bigInt?: Maybe<Scalars['BigInt']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
}

export interface MergeEmployeeResignationTypeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['BigInt']['input']>;
  targetId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface MergeEmployeeResignationTypePayload {
  __typename?: 'MergeEmployeeResignationTypePayload';
  bigInt?: Maybe<Scalars['BigInt']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
}

export interface MergeOfficeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['BigInt']['input']>;
  targetId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface MergeOfficePayload {
  __typename?: 'MergeOfficePayload';
  bigInt?: Maybe<Scalars['BigInt']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
}

export interface Mutation {
  __typename?: 'Mutation';
  copyPerformanceReviewMasterCompetencyCommon?: Maybe<CopyPerformanceReviewMasterCompetencyCommonPayload>;
  copyPerformanceReviewMasterCompetencyDetail?: Maybe<CopyPerformanceReviewMasterCompetencyDetailPayload>;
  copyPerformanceReviewMasterRating?: Maybe<CopyPerformanceReviewMasterRatingPayload>;
  copyPerformanceReviewMasterValue?: Maybe<CopyPerformanceReviewMasterValuePayload>;
  copyPerformanceReviewMasterWeighting?: Maybe<CopyPerformanceReviewMasterWeightingPayload>;
  createBank?: Maybe<CreateBankPayload>;
  createBusinessUnit?: Maybe<CreateBusinessUnitPayload>;
  createCity?: Maybe<CreateCityPayload>;
  createCompany?: Maybe<CreateCompanyPayload>;
  createCompanyExternalAccountDisable?: Maybe<CreateCompanyExternalAccountDisablePayload>;
  createCompanyGlobalHr?: Maybe<CreateCompanyGlobalHrPayload>;
  createCompanyGroup?: Maybe<CreateCompanyGroupPayload>;
  createCompanyLocalHr?: Maybe<CreateCompanyLocalHrPayload>;
  createContractType?: Maybe<CreateContractTypePayload>;
  createCountry?: Maybe<CreateCountryPayload>;
  createCurrency?: Maybe<CreateCurrencyPayload>;
  createDepartment?: Maybe<CreateDepartmentPayload>;
  createDivision?: Maybe<CreateDivisionPayload>;
  createEmailDomain?: Maybe<CreateEmailDomainPayload>;
  createEmployee?: Maybe<CreateEmployeePayload>;
  createEmployeeEmergencyContact?: Maybe<CreateEmployeeEmergencyContactPayload>;
  createEmployeeFile?: Maybe<CreateEmployeeFilePayload>;
  createEmployeeGroup?: Maybe<CreateEmployeeGroupPayload>;
  createEmployeeIdType?: Maybe<CreateEmployeeIdTypePayload>;
  createEmployeeLevel?: Maybe<CreateEmployeeLevelPayload>;
  createEmployeeListCustomFilter?: Maybe<CreateEmployeeListCustomFilterPayload>;
  createEmployeeListCustomFilterRole?: Maybe<CreateEmployeeListCustomFilterRolePayload>;
  createEmployeeResignationReason?: Maybe<CreateEmployeeResignationReasonPayload>;
  createEmployeeResignationType?: Maybe<CreateEmployeeResignationTypePayload>;
  createEmployeeTpHistory?: Maybe<CreateEmployeeTpHistoryPayload>;
  createExportEmployeeStatus?: Maybe<CreateExportEmployeeStatusPayload>;
  createExternalAccountEmployee?: Maybe<CreateExternalAccountEmployeePayload>;
  createExternalAccountType?: Maybe<CreateExternalAccountTypePayload>;
  createGoogleWorkspaceOrgUnit?: Maybe<CreateGoogleWorkspaceOrgUnitPayload>;
  createImportEmployeeStatus?: Maybe<CreateImportEmployeeStatusPayload>;
  createOffice?: Maybe<CreateOfficePayload>;
  createPerformanceReviewEvaluation?: Maybe<CreatePerformanceReviewEvaluationPayload>;
  createPerformanceReviewEvaluationCompetency?: Maybe<CreatePerformanceReviewEvaluationCompetencyPayload>;
  createPerformanceReviewEvaluationEvaluator?: Maybe<CreatePerformanceReviewEvaluationEvaluatorPayload>;
  createPerformanceReviewEvaluationFeedback?: Maybe<CreatePerformanceReviewEvaluationFeedbackPayload>;
  createPerformanceReviewEvaluationKpiAssessment?: Maybe<CreatePerformanceReviewEvaluationKpiAssessmentPayload>;
  createPerformanceReviewEvaluationKpiDescription?: Maybe<CreatePerformanceReviewEvaluationKpiDescriptionPayload>;
  createPerformanceReviewEvaluationKpiResult?: Maybe<CreatePerformanceReviewEvaluationKpiResultPayload>;
  createPerformanceReviewEvaluationKpiTarget?: Maybe<CreatePerformanceReviewEvaluationKpiTargetPayload>;
  createPerformanceReviewEvaluationOneOnOne?: Maybe<CreatePerformanceReviewEvaluationOneOnOnePayload>;
  createPerformanceReviewEvaluationQualitativeAssessment?: Maybe<CreatePerformanceReviewEvaluationQualitativeAssessmentPayload>;
  createPerformanceReviewEvaluationQualitativeDescription?: Maybe<CreatePerformanceReviewEvaluationQualitativeDescriptionPayload>;
  createPerformanceReviewEvaluationValue?: Maybe<CreatePerformanceReviewEvaluationValuePayload>;
  createPerformanceReviewMasterCompetency?: Maybe<CreatePerformanceReviewMasterCompetencyPayload>;
  createPerformanceReviewMasterCompetencyCommon?: Maybe<CreatePerformanceReviewMasterCompetencyCommonPayload>;
  createPerformanceReviewMasterCompetencyCommonDescription?: Maybe<CreatePerformanceReviewMasterCompetencyCommonDescriptionPayload>;
  createPerformanceReviewMasterCompetencyDetail?: Maybe<CreatePerformanceReviewMasterCompetencyDetailPayload>;
  createPerformanceReviewMasterCompetencyDetailDescription?: Maybe<CreatePerformanceReviewMasterCompetencyDetailDescriptionPayload>;
  createPerformanceReviewMasterCompetencyRole?: Maybe<CreatePerformanceReviewMasterCompetencyRolePayload>;
  createPerformanceReviewMasterDefault?: Maybe<CreatePerformanceReviewMasterDefaultPayload>;
  createPerformanceReviewMasterEvaluationViewer?: Maybe<CreatePerformanceReviewMasterEvaluationViewerPayload>;
  createPerformanceReviewMasterExcludedEmployee?: Maybe<CreatePerformanceReviewMasterExcludedEmployeePayload>;
  createPerformanceReviewMasterOneOnOne?: Maybe<CreatePerformanceReviewMasterOneOnOnePayload>;
  createPerformanceReviewMasterOneOnOneDetail?: Maybe<CreatePerformanceReviewMasterOneOnOneDetailPayload>;
  createPerformanceReviewMasterRating?: Maybe<CreatePerformanceReviewMasterRatingPayload>;
  createPerformanceReviewMasterRatingDescription?: Maybe<CreatePerformanceReviewMasterRatingDescriptionPayload>;
  createPerformanceReviewMasterUsage?: Maybe<CreatePerformanceReviewMasterUsagePayload>;
  createPerformanceReviewMasterValue?: Maybe<CreatePerformanceReviewMasterValuePayload>;
  createPerformanceReviewMasterValueDescription?: Maybe<CreatePerformanceReviewMasterValueDescriptionPayload>;
  createPerformanceReviewMasterWeighting?: Maybe<CreatePerformanceReviewMasterWeightingPayload>;
  createPerformanceReviewMasterWeightingDetail?: Maybe<CreatePerformanceReviewMasterWeightingDetailPayload>;
  createProbationReviewEnable?: Maybe<CreateProbationReviewEnablePayload>;
  createProbationReviewEvaluation?: Maybe<CreateProbationReviewEvaluationPayload>;
  createProbationReviewEvaluationResultItem?: Maybe<CreateProbationReviewEvaluationResultItemPayload>;
  createProbationReviewEvaluationResultScoreCompetency?: Maybe<CreateProbationReviewEvaluationResultScoreCompetencyPayload>;
  createProbationReviewEvaluationResultScoreValue?: Maybe<CreateProbationReviewEvaluationResultScoreValuePayload>;
  createProbationReviewEvaluationReviewer?: Maybe<CreateProbationReviewEvaluationReviewerPayload>;
  createProbationReviewMasterItem?: Maybe<CreateProbationReviewMasterItemPayload>;
  createProbationReviewMasterItemDescription?: Maybe<CreateProbationReviewMasterItemDescriptionPayload>;
  createRelationBusinessUnitDivision?: Maybe<CreateRelationBusinessUnitDivisionPayload>;
  createRelationBusinessUnitDivisionDepartment?: Maybe<CreateRelationBusinessUnitDivisionDepartmentPayload>;
  createRelationCityCompany?: Maybe<CreateRelationCityCompanyPayload>;
  createRelationCompanyBusinessUnit?: Maybe<CreateRelationCompanyBusinessUnitPayload>;
  createRelationCompanyGroupEmailDomain?: Maybe<CreateRelationCompanyGroupEmailDomainPayload>;
  createRelationCompanyOffice?: Maybe<CreateRelationCompanyOfficePayload>;
  createRelationCurrencyCountry?: Maybe<CreateRelationCurrencyCountryPayload>;
  createRelationEmployeeUserAccount?: Maybe<CreateRelationEmployeeUserAccountPayload>;
  createUserAccount?: Maybe<CreateUserAccountPayload>;
  createUserAccountAccessHistory?: Maybe<CreateUserAccountAccessHistoryPayload>;
  createUserAccountPin?: Maybe<CreateUserAccountPinPayload>;
  createUserHrManagingCompany?: Maybe<CreateUserHrManagingCompanyPayload>;
  createWorldPlace?: Maybe<CreateWorldPlacePayload>;
  deleteBank?: Maybe<DeleteBankPayload>;
  deleteBankByCountryCodeAlpha3AndFullName?: Maybe<DeleteBankPayload>;
  deleteBankByNodeId?: Maybe<DeleteBankPayload>;
  deleteBankBySwiftCode?: Maybe<DeleteBankPayload>;
  deleteBusinessUnit?: Maybe<DeleteBusinessUnitPayload>;
  deleteBusinessUnitByName?: Maybe<DeleteBusinessUnitPayload>;
  deleteBusinessUnitByNodeId?: Maybe<DeleteBusinessUnitPayload>;
  deleteCity?: Maybe<DeleteCityPayload>;
  deleteCityByCountryCodeAlpha2AndName?: Maybe<DeleteCityPayload>;
  deleteCityByNodeId?: Maybe<DeleteCityPayload>;
  deleteCompany?: Maybe<DeleteCompanyPayload>;
  deleteCompanyByAbbreviation?: Maybe<DeleteCompanyPayload>;
  deleteCompanyByCountryCodeAlpha2AndFullName?: Maybe<DeleteCompanyPayload>;
  deleteCompanyByNodeId?: Maybe<DeleteCompanyPayload>;
  deleteCompanyExternalAccountDisable?: Maybe<DeleteCompanyExternalAccountDisablePayload>;
  deleteCompanyExternalAccountDisableByNodeId?: Maybe<DeleteCompanyExternalAccountDisablePayload>;
  deleteCompanyGlobalHr?: Maybe<DeleteCompanyGlobalHrPayload>;
  deleteCompanyGlobalHrByEmployeeId?: Maybe<DeleteCompanyGlobalHrPayload>;
  deleteCompanyGlobalHrByNodeId?: Maybe<DeleteCompanyGlobalHrPayload>;
  deleteCompanyGroup?: Maybe<DeleteCompanyGroupPayload>;
  deleteCompanyGroupByName?: Maybe<DeleteCompanyGroupPayload>;
  deleteCompanyGroupByNodeId?: Maybe<DeleteCompanyGroupPayload>;
  deleteCompanyLocalHr?: Maybe<DeleteCompanyLocalHrPayload>;
  deleteCompanyLocalHrByCompanyIdAndEmployeeId?: Maybe<DeleteCompanyLocalHrPayload>;
  deleteCompanyLocalHrByNodeId?: Maybe<DeleteCompanyLocalHrPayload>;
  deleteContractType?: Maybe<DeleteContractTypePayload>;
  deleteContractTypeByCode?: Maybe<DeleteContractTypePayload>;
  deleteContractTypeByDisplayName?: Maybe<DeleteContractTypePayload>;
  deleteContractTypeByNodeId?: Maybe<DeleteContractTypePayload>;
  deleteCountry?: Maybe<DeleteCountryPayload>;
  deleteCountryByCountryCodeAlpha3?: Maybe<DeleteCountryPayload>;
  deleteCountryByCountryCodeNumeric?: Maybe<DeleteCountryPayload>;
  deleteCountryByDisplayOrder?: Maybe<DeleteCountryPayload>;
  deleteCountryByNodeId?: Maybe<DeleteCountryPayload>;
  deleteCurrency?: Maybe<DeleteCurrencyPayload>;
  deleteCurrencyByNodeId?: Maybe<DeleteCurrencyPayload>;
  deleteDepartment?: Maybe<DeleteDepartmentPayload>;
  deleteDepartmentByName?: Maybe<DeleteDepartmentPayload>;
  deleteDepartmentByNodeId?: Maybe<DeleteDepartmentPayload>;
  deleteDivision?: Maybe<DeleteDivisionPayload>;
  deleteDivisionByName?: Maybe<DeleteDivisionPayload>;
  deleteDivisionByNodeId?: Maybe<DeleteDivisionPayload>;
  deleteEmailDomain?: Maybe<DeleteEmailDomainPayload>;
  deleteEmailDomainByDomainName?: Maybe<DeleteEmailDomainPayload>;
  deleteEmailDomainByNodeId?: Maybe<DeleteEmailDomainPayload>;
  deleteEmployee?: Maybe<DeleteEmployeePayload>;
  deleteEmployeeByCompanyEmail?: Maybe<DeleteEmployeePayload>;
  deleteEmployeeByNodeId?: Maybe<DeleteEmployeePayload>;
  deleteEmployeeEmergencyContact?: Maybe<DeleteEmployeeEmergencyContactPayload>;
  deleteEmployeeEmergencyContactByEmployeeIdAndRelationIdAndName?: Maybe<DeleteEmployeeEmergencyContactPayload>;
  deleteEmployeeEmergencyContactByNodeId?: Maybe<DeleteEmployeeEmergencyContactPayload>;
  deleteEmployeeFile?: Maybe<DeleteEmployeeFilePayload>;
  deleteEmployeeFileByNodeId?: Maybe<DeleteEmployeeFilePayload>;
  deleteEmployeeFileByS3Path?: Maybe<DeleteEmployeeFilePayload>;
  deleteEmployeeGroup?: Maybe<DeleteEmployeeGroupPayload>;
  deleteEmployeeGroupByNodeId?: Maybe<DeleteEmployeeGroupPayload>;
  deleteEmployeeIdType?: Maybe<DeleteEmployeeIdTypePayload>;
  deleteEmployeeIdTypeByCode?: Maybe<DeleteEmployeeIdTypePayload>;
  deleteEmployeeIdTypeByNodeId?: Maybe<DeleteEmployeeIdTypePayload>;
  deleteEmployeeLevel?: Maybe<DeleteEmployeeLevelPayload>;
  deleteEmployeeLevelByDisplayName?: Maybe<DeleteEmployeeLevelPayload>;
  deleteEmployeeLevelByNodeId?: Maybe<DeleteEmployeeLevelPayload>;
  deleteEmployeeListCustomFilter?: Maybe<DeleteEmployeeListCustomFilterPayload>;
  deleteEmployeeListCustomFilterByNodeId?: Maybe<DeleteEmployeeListCustomFilterPayload>;
  deleteEmployeeListCustomFilterRole?: Maybe<DeleteEmployeeListCustomFilterRolePayload>;
  deleteEmployeeListCustomFilterRoleByEmployeeListCustomFilterIdAndRoleName?: Maybe<DeleteEmployeeListCustomFilterRolePayload>;
  deleteEmployeeListCustomFilterRoleByNodeId?: Maybe<DeleteEmployeeListCustomFilterRolePayload>;
  deleteEmployeeResignationReason?: Maybe<DeleteEmployeeResignationReasonPayload>;
  deleteEmployeeResignationReasonByDisplayName?: Maybe<DeleteEmployeeResignationReasonPayload>;
  deleteEmployeeResignationReasonByNodeId?: Maybe<DeleteEmployeeResignationReasonPayload>;
  deleteEmployeeResignationType?: Maybe<DeleteEmployeeResignationTypePayload>;
  deleteEmployeeResignationTypeByDisplayName?: Maybe<DeleteEmployeeResignationTypePayload>;
  deleteEmployeeResignationTypeByNodeId?: Maybe<DeleteEmployeeResignationTypePayload>;
  deleteEmployeeTpHistory?: Maybe<DeleteEmployeeTpHistoryPayload>;
  deleteEmployeeTpHistoryByEmployeeIdAndEffectiveDate?: Maybe<DeleteEmployeeTpHistoryPayload>;
  deleteEmployeeTpHistoryByNodeId?: Maybe<DeleteEmployeeTpHistoryPayload>;
  deleteExportEmployeeStatus?: Maybe<DeleteExportEmployeeStatusPayload>;
  deleteExportEmployeeStatusByNodeId?: Maybe<DeleteExportEmployeeStatusPayload>;
  deleteExportEmployeeStatusByUuid4?: Maybe<DeleteExportEmployeeStatusPayload>;
  deleteExternalAccountEmployee?: Maybe<DeleteExternalAccountEmployeePayload>;
  deleteExternalAccountEmployeeByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKey?: Maybe<DeleteExternalAccountEmployeePayload>;
  deleteExternalAccountEmployeeByNodeId?: Maybe<DeleteExternalAccountEmployeePayload>;
  deleteExternalAccountType?: Maybe<DeleteExternalAccountTypePayload>;
  deleteExternalAccountTypeByName?: Maybe<DeleteExternalAccountTypePayload>;
  deleteExternalAccountTypeByNodeId?: Maybe<DeleteExternalAccountTypePayload>;
  deleteGoogleWorkspaceOrgUnit?: Maybe<DeleteGoogleWorkspaceOrgUnitPayload>;
  deleteGoogleWorkspaceOrgUnitByCompanyIdAndBusinessUnitId?: Maybe<DeleteGoogleWorkspaceOrgUnitPayload>;
  deleteGoogleWorkspaceOrgUnitByNodeId?: Maybe<DeleteGoogleWorkspaceOrgUnitPayload>;
  deleteImportEmployeeStatus?: Maybe<DeleteImportEmployeeStatusPayload>;
  deleteImportEmployeeStatusByNodeId?: Maybe<DeleteImportEmployeeStatusPayload>;
  deleteImportEmployeeStatusByUuid4?: Maybe<DeleteImportEmployeeStatusPayload>;
  deleteOffice?: Maybe<DeleteOfficePayload>;
  deleteOfficeByCityIdAndName?: Maybe<DeleteOfficePayload>;
  deleteOfficeByNodeId?: Maybe<DeleteOfficePayload>;
  deletePerformanceReviewEvaluation?: Maybe<DeletePerformanceReviewEvaluationPayload>;
  deletePerformanceReviewEvaluationByNodeId?: Maybe<DeletePerformanceReviewEvaluationPayload>;
  deletePerformanceReviewEvaluationCompetency?: Maybe<DeletePerformanceReviewEvaluationCompetencyPayload>;
  deletePerformanceReviewEvaluationCompetencyByNodeId?: Maybe<DeletePerformanceReviewEvaluationCompetencyPayload>;
  deletePerformanceReviewEvaluationCompetencyByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeId?: Maybe<DeletePerformanceReviewEvaluationCompetencyPayload>;
  deletePerformanceReviewEvaluationEvaluator?: Maybe<DeletePerformanceReviewEvaluationEvaluatorPayload>;
  deletePerformanceReviewEvaluationEvaluatorByNodeId?: Maybe<DeletePerformanceReviewEvaluationEvaluatorPayload>;
  deletePerformanceReviewEvaluationEvaluatorByPerformanceReviewEvaluationIdAndEvaluatorId?: Maybe<DeletePerformanceReviewEvaluationEvaluatorPayload>;
  deletePerformanceReviewEvaluationFeedback?: Maybe<DeletePerformanceReviewEvaluationFeedbackPayload>;
  deletePerformanceReviewEvaluationFeedbackByNodeId?: Maybe<DeletePerformanceReviewEvaluationFeedbackPayload>;
  deletePerformanceReviewEvaluationFeedbackByPerformanceReviewEvaluationIdAndFeedbackEmployeeId?: Maybe<DeletePerformanceReviewEvaluationFeedbackPayload>;
  deletePerformanceReviewEvaluationKpiAssessment?: Maybe<DeletePerformanceReviewEvaluationKpiAssessmentPayload>;
  deletePerformanceReviewEvaluationKpiAssessmentByNodeId?: Maybe<DeletePerformanceReviewEvaluationKpiAssessmentPayload>;
  deletePerformanceReviewEvaluationKpiAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeId?: Maybe<DeletePerformanceReviewEvaluationKpiAssessmentPayload>;
  deletePerformanceReviewEvaluationKpiDescription?: Maybe<DeletePerformanceReviewEvaluationKpiDescriptionPayload>;
  deletePerformanceReviewEvaluationKpiDescriptionByNodeId?: Maybe<DeletePerformanceReviewEvaluationKpiDescriptionPayload>;
  deletePerformanceReviewEvaluationKpiDescriptionByPerformanceReviewEvaluationIdAndTitle?: Maybe<DeletePerformanceReviewEvaluationKpiDescriptionPayload>;
  deletePerformanceReviewEvaluationKpiResult?: Maybe<DeletePerformanceReviewEvaluationKpiResultPayload>;
  deletePerformanceReviewEvaluationKpiResultByNodeId?: Maybe<DeletePerformanceReviewEvaluationKpiResultPayload>;
  deletePerformanceReviewEvaluationKpiResultByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetId?: Maybe<DeletePerformanceReviewEvaluationKpiResultPayload>;
  deletePerformanceReviewEvaluationKpiTarget?: Maybe<DeletePerformanceReviewEvaluationKpiTargetPayload>;
  deletePerformanceReviewEvaluationKpiTargetByNodeId?: Maybe<DeletePerformanceReviewEvaluationKpiTargetPayload>;
  deletePerformanceReviewEvaluationKpiTargetByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndName?: Maybe<DeletePerformanceReviewEvaluationKpiTargetPayload>;
  deletePerformanceReviewEvaluationOneOnOne?: Maybe<DeletePerformanceReviewEvaluationOneOnOnePayload>;
  deletePerformanceReviewEvaluationOneOnOneByNodeId?: Maybe<DeletePerformanceReviewEvaluationOneOnOnePayload>;
  deletePerformanceReviewEvaluationQualitativeAssessment?: Maybe<DeletePerformanceReviewEvaluationQualitativeAssessmentPayload>;
  deletePerformanceReviewEvaluationQualitativeAssessmentByNodeId?: Maybe<DeletePerformanceReviewEvaluationQualitativeAssessmentPayload>;
  deletePerformanceReviewEvaluationQualitativeAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeId?: Maybe<DeletePerformanceReviewEvaluationQualitativeAssessmentPayload>;
  deletePerformanceReviewEvaluationQualitativeDescription?: Maybe<DeletePerformanceReviewEvaluationQualitativeDescriptionPayload>;
  deletePerformanceReviewEvaluationQualitativeDescriptionByNodeId?: Maybe<DeletePerformanceReviewEvaluationQualitativeDescriptionPayload>;
  deletePerformanceReviewEvaluationQualitativeDescriptionByPerformanceReviewEvaluationIdAndTitle?: Maybe<DeletePerformanceReviewEvaluationQualitativeDescriptionPayload>;
  deletePerformanceReviewEvaluationValue?: Maybe<DeletePerformanceReviewEvaluationValuePayload>;
  deletePerformanceReviewEvaluationValueByNodeId?: Maybe<DeletePerformanceReviewEvaluationValuePayload>;
  deletePerformanceReviewEvaluationValueByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeId?: Maybe<DeletePerformanceReviewEvaluationValuePayload>;
  deletePerformanceReviewMasterCompetency?: Maybe<DeletePerformanceReviewMasterCompetencyPayload>;
  deletePerformanceReviewMasterCompetencyByNodeId?: Maybe<DeletePerformanceReviewMasterCompetencyPayload>;
  deletePerformanceReviewMasterCompetencyCommon?: Maybe<DeletePerformanceReviewMasterCompetencyCommonPayload>;
  deletePerformanceReviewMasterCompetencyCommonByNodeId?: Maybe<DeletePerformanceReviewMasterCompetencyCommonPayload>;
  deletePerformanceReviewMasterCompetencyCommonDescription?: Maybe<DeletePerformanceReviewMasterCompetencyCommonDescriptionPayload>;
  deletePerformanceReviewMasterCompetencyCommonDescriptionByNodeId?: Maybe<DeletePerformanceReviewMasterCompetencyCommonDescriptionPayload>;
  deletePerformanceReviewMasterCompetencyCommonDescriptionByPerformanceReviewMasterCompetencyCommonIdAndLanguage?: Maybe<DeletePerformanceReviewMasterCompetencyCommonDescriptionPayload>;
  deletePerformanceReviewMasterCompetencyDetail?: Maybe<DeletePerformanceReviewMasterCompetencyDetailPayload>;
  deletePerformanceReviewMasterCompetencyDetailByNodeId?: Maybe<DeletePerformanceReviewMasterCompetencyDetailPayload>;
  deletePerformanceReviewMasterCompetencyDetailByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonId?: Maybe<DeletePerformanceReviewMasterCompetencyDetailPayload>;
  deletePerformanceReviewMasterCompetencyDetailDescription?: Maybe<DeletePerformanceReviewMasterCompetencyDetailDescriptionPayload>;
  deletePerformanceReviewMasterCompetencyDetailDescriptionByNodeId?: Maybe<DeletePerformanceReviewMasterCompetencyDetailDescriptionPayload>;
  deletePerformanceReviewMasterCompetencyDetailDescriptionByPerformanceReviewMasterCompetencyDetailIdAndLanguage?: Maybe<DeletePerformanceReviewMasterCompetencyDetailDescriptionPayload>;
  deletePerformanceReviewMasterCompetencyRole?: Maybe<DeletePerformanceReviewMasterCompetencyRolePayload>;
  deletePerformanceReviewMasterCompetencyRoleByNodeId?: Maybe<DeletePerformanceReviewMasterCompetencyRolePayload>;
  deletePerformanceReviewMasterCompetencyRoleByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleName?: Maybe<DeletePerformanceReviewMasterCompetencyRolePayload>;
  deletePerformanceReviewMasterDefault?: Maybe<DeletePerformanceReviewMasterDefaultPayload>;
  deletePerformanceReviewMasterDefaultByNodeId?: Maybe<DeletePerformanceReviewMasterDefaultPayload>;
  deletePerformanceReviewMasterEvaluationViewer?: Maybe<DeletePerformanceReviewMasterEvaluationViewerPayload>;
  deletePerformanceReviewMasterEvaluationViewerByEmployeeIdAndYearAndQuarterAndViewerEmployeeId?: Maybe<DeletePerformanceReviewMasterEvaluationViewerPayload>;
  deletePerformanceReviewMasterEvaluationViewerByNodeId?: Maybe<DeletePerformanceReviewMasterEvaluationViewerPayload>;
  deletePerformanceReviewMasterExcludedEmployee?: Maybe<DeletePerformanceReviewMasterExcludedEmployeePayload>;
  deletePerformanceReviewMasterExcludedEmployeeByNodeId?: Maybe<DeletePerformanceReviewMasterExcludedEmployeePayload>;
  deletePerformanceReviewMasterOneOnOne?: Maybe<DeletePerformanceReviewMasterOneOnOnePayload>;
  deletePerformanceReviewMasterOneOnOneByNodeId?: Maybe<DeletePerformanceReviewMasterOneOnOnePayload>;
  deletePerformanceReviewMasterOneOnOneDetail?: Maybe<DeletePerformanceReviewMasterOneOnOneDetailPayload>;
  deletePerformanceReviewMasterOneOnOneDetailByNodeId?: Maybe<DeletePerformanceReviewMasterOneOnOneDetailPayload>;
  deletePerformanceReviewMasterRating?: Maybe<DeletePerformanceReviewMasterRatingPayload>;
  deletePerformanceReviewMasterRatingByNodeId?: Maybe<DeletePerformanceReviewMasterRatingPayload>;
  deletePerformanceReviewMasterRatingDescription?: Maybe<DeletePerformanceReviewMasterRatingDescriptionPayload>;
  deletePerformanceReviewMasterRatingDescriptionByNodeId?: Maybe<DeletePerformanceReviewMasterRatingDescriptionPayload>;
  deletePerformanceReviewMasterRatingDescriptionByPerformanceReviewMasterRatingIdAndLanguage?: Maybe<DeletePerformanceReviewMasterRatingDescriptionPayload>;
  deletePerformanceReviewMasterUsage?: Maybe<DeletePerformanceReviewMasterUsagePayload>;
  deletePerformanceReviewMasterUsageByNodeId?: Maybe<DeletePerformanceReviewMasterUsagePayload>;
  deletePerformanceReviewMasterUsageByYearAndQuarterAndCompanyId?: Maybe<DeletePerformanceReviewMasterUsagePayload>;
  deletePerformanceReviewMasterValue?: Maybe<DeletePerformanceReviewMasterValuePayload>;
  deletePerformanceReviewMasterValueByNodeId?: Maybe<DeletePerformanceReviewMasterValuePayload>;
  deletePerformanceReviewMasterValueDescription?: Maybe<DeletePerformanceReviewMasterValueDescriptionPayload>;
  deletePerformanceReviewMasterValueDescriptionByNodeId?: Maybe<DeletePerformanceReviewMasterValueDescriptionPayload>;
  deletePerformanceReviewMasterValueDescriptionByPerformanceReviewMasterValueIdAndLanguage?: Maybe<DeletePerformanceReviewMasterValueDescriptionPayload>;
  deletePerformanceReviewMasterWeighting?: Maybe<DeletePerformanceReviewMasterWeightingPayload>;
  deletePerformanceReviewMasterWeightingByNodeId?: Maybe<DeletePerformanceReviewMasterWeightingPayload>;
  deletePerformanceReviewMasterWeightingByYearAndQuarterAndCompanyId?: Maybe<DeletePerformanceReviewMasterWeightingPayload>;
  deletePerformanceReviewMasterWeightingByYearAndQuarterAndName?: Maybe<DeletePerformanceReviewMasterWeightingPayload>;
  deletePerformanceReviewMasterWeightingDetail?: Maybe<DeletePerformanceReviewMasterWeightingDetailPayload>;
  deletePerformanceReviewMasterWeightingDetailByNodeId?: Maybe<DeletePerformanceReviewMasterWeightingDetailPayload>;
  deletePerformanceReviewMasterWeightingDetailByPerformanceReviewMasterWeightingIdAndEmployeeLevelId?: Maybe<DeletePerformanceReviewMasterWeightingDetailPayload>;
  deleteProbationReviewEnable?: Maybe<DeleteProbationReviewEnablePayload>;
  deleteProbationReviewEnableByCompanyIdAndContractTypeId?: Maybe<DeleteProbationReviewEnablePayload>;
  deleteProbationReviewEnableByNodeId?: Maybe<DeleteProbationReviewEnablePayload>;
  deleteProbationReviewEvaluation?: Maybe<DeleteProbationReviewEvaluationPayload>;
  deleteProbationReviewEvaluationByEmployeeIdAndReviewDatePlanned?: Maybe<DeleteProbationReviewEvaluationPayload>;
  deleteProbationReviewEvaluationByNodeId?: Maybe<DeleteProbationReviewEvaluationPayload>;
  deleteProbationReviewEvaluationResultItem?: Maybe<DeleteProbationReviewEvaluationResultItemPayload>;
  deleteProbationReviewEvaluationResultItemByNodeId?: Maybe<DeleteProbationReviewEvaluationResultItemPayload>;
  deleteProbationReviewEvaluationResultItemByProbationReviewEvaluationIdAndProbationReviewMasterItemId?: Maybe<DeleteProbationReviewEvaluationResultItemPayload>;
  deleteProbationReviewEvaluationResultScoreCompetency?: Maybe<DeleteProbationReviewEvaluationResultScoreCompetencyPayload>;
  deleteProbationReviewEvaluationResultScoreCompetencyByNodeId?: Maybe<DeleteProbationReviewEvaluationResultScoreCompetencyPayload>;
  deleteProbationReviewEvaluationResultScoreCompetencyByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyId?: Maybe<DeleteProbationReviewEvaluationResultScoreCompetencyPayload>;
  deleteProbationReviewEvaluationResultScoreValue?: Maybe<DeleteProbationReviewEvaluationResultScoreValuePayload>;
  deleteProbationReviewEvaluationResultScoreValueByNodeId?: Maybe<DeleteProbationReviewEvaluationResultScoreValuePayload>;
  deleteProbationReviewEvaluationResultScoreValueByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueId?: Maybe<DeleteProbationReviewEvaluationResultScoreValuePayload>;
  deleteProbationReviewEvaluationReviewer?: Maybe<DeleteProbationReviewEvaluationReviewerPayload>;
  deleteProbationReviewEvaluationReviewerByNodeId?: Maybe<DeleteProbationReviewEvaluationReviewerPayload>;
  deleteProbationReviewMasterItem?: Maybe<DeleteProbationReviewMasterItemPayload>;
  deleteProbationReviewMasterItemByNodeId?: Maybe<DeleteProbationReviewMasterItemPayload>;
  deleteProbationReviewMasterItemByYearAndQuarterAndTitleAndInputUser?: Maybe<DeleteProbationReviewMasterItemPayload>;
  deleteProbationReviewMasterItemDescription?: Maybe<DeleteProbationReviewMasterItemDescriptionPayload>;
  deleteProbationReviewMasterItemDescriptionByNodeId?: Maybe<DeleteProbationReviewMasterItemDescriptionPayload>;
  deleteProbationReviewMasterItemDescriptionByProbationReviewMasterItemIdAndLanguage?: Maybe<DeleteProbationReviewMasterItemDescriptionPayload>;
  deleteRelationBusinessUnitDivision?: Maybe<DeleteRelationBusinessUnitDivisionPayload>;
  deleteRelationBusinessUnitDivisionByNodeId?: Maybe<DeleteRelationBusinessUnitDivisionPayload>;
  deleteRelationBusinessUnitDivisionDepartment?: Maybe<DeleteRelationBusinessUnitDivisionDepartmentPayload>;
  deleteRelationBusinessUnitDivisionDepartmentByNodeId?: Maybe<DeleteRelationBusinessUnitDivisionDepartmentPayload>;
  deleteRelationCityCompany?: Maybe<DeleteRelationCityCompanyPayload>;
  deleteRelationCityCompanyByNodeId?: Maybe<DeleteRelationCityCompanyPayload>;
  deleteRelationCompanyBusinessUnit?: Maybe<DeleteRelationCompanyBusinessUnitPayload>;
  deleteRelationCompanyBusinessUnitByNodeId?: Maybe<DeleteRelationCompanyBusinessUnitPayload>;
  deleteRelationCompanyGroupEmailDomain?: Maybe<DeleteRelationCompanyGroupEmailDomainPayload>;
  deleteRelationCompanyGroupEmailDomainByNodeId?: Maybe<DeleteRelationCompanyGroupEmailDomainPayload>;
  deleteRelationCompanyOffice?: Maybe<DeleteRelationCompanyOfficePayload>;
  deleteRelationCompanyOfficeByNodeId?: Maybe<DeleteRelationCompanyOfficePayload>;
  deleteRelationCurrencyCountry?: Maybe<DeleteRelationCurrencyCountryPayload>;
  deleteRelationCurrencyCountryByNodeId?: Maybe<DeleteRelationCurrencyCountryPayload>;
  deleteRelationEmployeeUserAccount?: Maybe<DeleteRelationEmployeeUserAccountPayload>;
  deleteRelationEmployeeUserAccountByNodeId?: Maybe<DeleteRelationEmployeeUserAccountPayload>;
  deleteUserAccount?: Maybe<DeleteUserAccountPayload>;
  deleteUserAccountAccessHistory?: Maybe<DeleteUserAccountAccessHistoryPayload>;
  deleteUserAccountAccessHistoryByNodeId?: Maybe<DeleteUserAccountAccessHistoryPayload>;
  deleteUserAccountByEmailAddress?: Maybe<DeleteUserAccountPayload>;
  deleteUserAccountByNodeId?: Maybe<DeleteUserAccountPayload>;
  deleteUserAccountPin?: Maybe<DeleteUserAccountPinPayload>;
  deleteUserAccountPinByNodeId?: Maybe<DeleteUserAccountPinPayload>;
  deleteUserHrManagingCompany?: Maybe<DeleteUserHrManagingCompanyPayload>;
  deleteUserHrManagingCompanyByNodeId?: Maybe<DeleteUserHrManagingCompanyPayload>;
  deleteUserHrManagingCompanyByUserAccountIdAndCompanyId?: Maybe<DeleteUserHrManagingCompanyPayload>;
  deleteWorldPlace?: Maybe<DeleteWorldPlacePayload>;
  deleteWorldPlaceByDisplayOrder?: Maybe<DeleteWorldPlacePayload>;
  deleteWorldPlaceByNodeId?: Maybe<DeleteWorldPlacePayload>;
  getCurrentYearQuarter?: Maybe<GetCurrentYearQuarterPayload>;
  getProbationReviewEvaluationReviewerUsage?: Maybe<GetProbationReviewEvaluationReviewerUsagePayload>;
  initializeAllRoles?: Maybe<InitializeAllRolesPayload>;
  mergeBank?: Maybe<MergeBankPayload>;
  mergeBusinessUnit?: Maybe<MergeBusinessUnitPayload>;
  mergeCity?: Maybe<MergeCityPayload>;
  mergeCompany?: Maybe<MergeCompanyPayload>;
  mergeCompanyGroup?: Maybe<MergeCompanyGroupPayload>;
  mergeContractType?: Maybe<MergeContractTypePayload>;
  mergeCountry?: Maybe<MergeCountryPayload>;
  mergeCurrency?: Maybe<MergeCurrencyPayload>;
  mergeDepartment?: Maybe<MergeDepartmentPayload>;
  mergeDivision?: Maybe<MergeDivisionPayload>;
  mergeEmployeeLevel?: Maybe<MergeEmployeeLevelPayload>;
  mergeEmployeeResignationReason?: Maybe<MergeEmployeeResignationReasonPayload>;
  mergeEmployeeResignationType?: Maybe<MergeEmployeeResignationTypePayload>;
  mergeOffice?: Maybe<MergeOfficePayload>;
  perfReviewEvaluationKpiDescFixDisplayOrder?: Maybe<PerfReviewEvaluationKpiDescFixDisplayOrderPayload>;
  perfReviewEvaluationQualitativeDescFixDisplayOrder?: Maybe<PerfReviewEvaluationQualitativeDescFixDisplayOrderPayload>;
  perfReviewMasterCompetencyCommonFixDisplayOrder?: Maybe<PerfReviewMasterCompetencyCommonFixDisplayOrderPayload>;
  performanceReviewEvaluationLockAssessment?: Maybe<PerformanceReviewEvaluationLockAssessmentPayload>;
  performanceReviewEvaluationLockDescription?: Maybe<PerformanceReviewEvaluationLockDescriptionPayload>;
  performanceReviewMasterCompetencyDetailFixDisplayOrder?: Maybe<PerformanceReviewMasterCompetencyDetailFixDisplayOrderPayload>;
  performanceReviewMasterOneOnOneFixDisplayOrder?: Maybe<PerformanceReviewMasterOneOnOneFixDisplayOrderPayload>;
  performanceReviewMasterValueFixDisplayOrder?: Maybe<PerformanceReviewMasterValueFixDisplayOrderPayload>;
  switchTrigger?: Maybe<SwitchTriggerPayload>;
  updateBank?: Maybe<UpdateBankPayload>;
  updateBankByCountryCodeAlpha3AndFullName?: Maybe<UpdateBankPayload>;
  updateBankByNodeId?: Maybe<UpdateBankPayload>;
  updateBankBySwiftCode?: Maybe<UpdateBankPayload>;
  updateBusinessUnit?: Maybe<UpdateBusinessUnitPayload>;
  updateBusinessUnitByName?: Maybe<UpdateBusinessUnitPayload>;
  updateBusinessUnitByNodeId?: Maybe<UpdateBusinessUnitPayload>;
  updateCity?: Maybe<UpdateCityPayload>;
  updateCityByCountryCodeAlpha2AndName?: Maybe<UpdateCityPayload>;
  updateCityByNodeId?: Maybe<UpdateCityPayload>;
  updateCompany?: Maybe<UpdateCompanyPayload>;
  updateCompanyByAbbreviation?: Maybe<UpdateCompanyPayload>;
  updateCompanyByCountryCodeAlpha2AndFullName?: Maybe<UpdateCompanyPayload>;
  updateCompanyByNodeId?: Maybe<UpdateCompanyPayload>;
  updateCompanyExternalAccountDisable?: Maybe<UpdateCompanyExternalAccountDisablePayload>;
  updateCompanyExternalAccountDisableByNodeId?: Maybe<UpdateCompanyExternalAccountDisablePayload>;
  updateCompanyGlobalHr?: Maybe<UpdateCompanyGlobalHrPayload>;
  updateCompanyGlobalHrByEmployeeId?: Maybe<UpdateCompanyGlobalHrPayload>;
  updateCompanyGlobalHrByNodeId?: Maybe<UpdateCompanyGlobalHrPayload>;
  updateCompanyGroup?: Maybe<UpdateCompanyGroupPayload>;
  updateCompanyGroupByName?: Maybe<UpdateCompanyGroupPayload>;
  updateCompanyGroupByNodeId?: Maybe<UpdateCompanyGroupPayload>;
  updateCompanyLocalHr?: Maybe<UpdateCompanyLocalHrPayload>;
  updateCompanyLocalHrByCompanyIdAndEmployeeId?: Maybe<UpdateCompanyLocalHrPayload>;
  updateCompanyLocalHrByNodeId?: Maybe<UpdateCompanyLocalHrPayload>;
  updateContractType?: Maybe<UpdateContractTypePayload>;
  updateContractTypeByCode?: Maybe<UpdateContractTypePayload>;
  updateContractTypeByDisplayName?: Maybe<UpdateContractTypePayload>;
  updateContractTypeByNodeId?: Maybe<UpdateContractTypePayload>;
  updateCountry?: Maybe<UpdateCountryPayload>;
  updateCountryByCountryCodeAlpha3?: Maybe<UpdateCountryPayload>;
  updateCountryByCountryCodeNumeric?: Maybe<UpdateCountryPayload>;
  updateCountryByDisplayOrder?: Maybe<UpdateCountryPayload>;
  updateCountryByNodeId?: Maybe<UpdateCountryPayload>;
  updateCurrency?: Maybe<UpdateCurrencyPayload>;
  updateCurrencyByNodeId?: Maybe<UpdateCurrencyPayload>;
  updateDepartment?: Maybe<UpdateDepartmentPayload>;
  updateDepartmentByName?: Maybe<UpdateDepartmentPayload>;
  updateDepartmentByNodeId?: Maybe<UpdateDepartmentPayload>;
  updateDivision?: Maybe<UpdateDivisionPayload>;
  updateDivisionByName?: Maybe<UpdateDivisionPayload>;
  updateDivisionByNodeId?: Maybe<UpdateDivisionPayload>;
  updateEmailDomain?: Maybe<UpdateEmailDomainPayload>;
  updateEmailDomainByDomainName?: Maybe<UpdateEmailDomainPayload>;
  updateEmailDomainByNodeId?: Maybe<UpdateEmailDomainPayload>;
  updateEmployee?: Maybe<UpdateEmployeePayload>;
  updateEmployeeByCompanyEmail?: Maybe<UpdateEmployeePayload>;
  updateEmployeeByNodeId?: Maybe<UpdateEmployeePayload>;
  updateEmployeeEmergencyContact?: Maybe<UpdateEmployeeEmergencyContactPayload>;
  updateEmployeeEmergencyContactByEmployeeIdAndRelationIdAndName?: Maybe<UpdateEmployeeEmergencyContactPayload>;
  updateEmployeeEmergencyContactByNodeId?: Maybe<UpdateEmployeeEmergencyContactPayload>;
  updateEmployeeFile?: Maybe<UpdateEmployeeFilePayload>;
  updateEmployeeFileByNodeId?: Maybe<UpdateEmployeeFilePayload>;
  updateEmployeeFileByS3Path?: Maybe<UpdateEmployeeFilePayload>;
  updateEmployeeIdType?: Maybe<UpdateEmployeeIdTypePayload>;
  updateEmployeeIdTypeByCode?: Maybe<UpdateEmployeeIdTypePayload>;
  updateEmployeeIdTypeByNodeId?: Maybe<UpdateEmployeeIdTypePayload>;
  updateEmployeeLevel?: Maybe<UpdateEmployeeLevelPayload>;
  updateEmployeeLevelByDisplayName?: Maybe<UpdateEmployeeLevelPayload>;
  updateEmployeeLevelByNodeId?: Maybe<UpdateEmployeeLevelPayload>;
  updateEmployeeListCustomFilter?: Maybe<UpdateEmployeeListCustomFilterPayload>;
  updateEmployeeListCustomFilterByNodeId?: Maybe<UpdateEmployeeListCustomFilterPayload>;
  updateEmployeeListCustomFilterRole?: Maybe<UpdateEmployeeListCustomFilterRolePayload>;
  updateEmployeeListCustomFilterRoleByEmployeeListCustomFilterIdAndRoleName?: Maybe<UpdateEmployeeListCustomFilterRolePayload>;
  updateEmployeeListCustomFilterRoleByNodeId?: Maybe<UpdateEmployeeListCustomFilterRolePayload>;
  updateEmployeeResignationReason?: Maybe<UpdateEmployeeResignationReasonPayload>;
  updateEmployeeResignationReasonByDisplayName?: Maybe<UpdateEmployeeResignationReasonPayload>;
  updateEmployeeResignationReasonByNodeId?: Maybe<UpdateEmployeeResignationReasonPayload>;
  updateEmployeeResignationType?: Maybe<UpdateEmployeeResignationTypePayload>;
  updateEmployeeResignationTypeByDisplayName?: Maybe<UpdateEmployeeResignationTypePayload>;
  updateEmployeeResignationTypeByNodeId?: Maybe<UpdateEmployeeResignationTypePayload>;
  updateEmployeeTpHistory?: Maybe<UpdateEmployeeTpHistoryPayload>;
  updateEmployeeTpHistoryByEmployeeIdAndEffectiveDate?: Maybe<UpdateEmployeeTpHistoryPayload>;
  updateEmployeeTpHistoryByNodeId?: Maybe<UpdateEmployeeTpHistoryPayload>;
  updateExportEmployeeStatus?: Maybe<UpdateExportEmployeeStatusPayload>;
  updateExportEmployeeStatusByNodeId?: Maybe<UpdateExportEmployeeStatusPayload>;
  updateExportEmployeeStatusByUuid4?: Maybe<UpdateExportEmployeeStatusPayload>;
  updateExternalAccountEmployee?: Maybe<UpdateExternalAccountEmployeePayload>;
  updateExternalAccountEmployeeByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKey?: Maybe<UpdateExternalAccountEmployeePayload>;
  updateExternalAccountEmployeeByNodeId?: Maybe<UpdateExternalAccountEmployeePayload>;
  updateExternalAccountType?: Maybe<UpdateExternalAccountTypePayload>;
  updateExternalAccountTypeByName?: Maybe<UpdateExternalAccountTypePayload>;
  updateExternalAccountTypeByNodeId?: Maybe<UpdateExternalAccountTypePayload>;
  updateGoogleWorkspaceOrgUnit?: Maybe<UpdateGoogleWorkspaceOrgUnitPayload>;
  updateGoogleWorkspaceOrgUnitByCompanyIdAndBusinessUnitId?: Maybe<UpdateGoogleWorkspaceOrgUnitPayload>;
  updateGoogleWorkspaceOrgUnitByNodeId?: Maybe<UpdateGoogleWorkspaceOrgUnitPayload>;
  updateImportEmployeeStatus?: Maybe<UpdateImportEmployeeStatusPayload>;
  updateImportEmployeeStatusByNodeId?: Maybe<UpdateImportEmployeeStatusPayload>;
  updateImportEmployeeStatusByUuid4?: Maybe<UpdateImportEmployeeStatusPayload>;
  updateLatestEmployeeTransferPromotion?: Maybe<UpdateLatestEmployeeTransferPromotionPayload>;
  updateOffice?: Maybe<UpdateOfficePayload>;
  updateOfficeByCityIdAndName?: Maybe<UpdateOfficePayload>;
  updateOfficeByNodeId?: Maybe<UpdateOfficePayload>;
  updatePerformanceReviewEvaluation?: Maybe<UpdatePerformanceReviewEvaluationPayload>;
  updatePerformanceReviewEvaluationByNodeId?: Maybe<UpdatePerformanceReviewEvaluationPayload>;
  updatePerformanceReviewEvaluationCompetency?: Maybe<UpdatePerformanceReviewEvaluationCompetencyPayload>;
  updatePerformanceReviewEvaluationCompetencyByNodeId?: Maybe<UpdatePerformanceReviewEvaluationCompetencyPayload>;
  updatePerformanceReviewEvaluationCompetencyByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeId?: Maybe<UpdatePerformanceReviewEvaluationCompetencyPayload>;
  updatePerformanceReviewEvaluationEvaluator?: Maybe<UpdatePerformanceReviewEvaluationEvaluatorPayload>;
  updatePerformanceReviewEvaluationEvaluatorByNodeId?: Maybe<UpdatePerformanceReviewEvaluationEvaluatorPayload>;
  updatePerformanceReviewEvaluationEvaluatorByPerformanceReviewEvaluationIdAndEvaluatorId?: Maybe<UpdatePerformanceReviewEvaluationEvaluatorPayload>;
  updatePerformanceReviewEvaluationFeedback?: Maybe<UpdatePerformanceReviewEvaluationFeedbackPayload>;
  updatePerformanceReviewEvaluationFeedbackByNodeId?: Maybe<UpdatePerformanceReviewEvaluationFeedbackPayload>;
  updatePerformanceReviewEvaluationFeedbackByPerformanceReviewEvaluationIdAndFeedbackEmployeeId?: Maybe<UpdatePerformanceReviewEvaluationFeedbackPayload>;
  updatePerformanceReviewEvaluationKpiAssessment?: Maybe<UpdatePerformanceReviewEvaluationKpiAssessmentPayload>;
  updatePerformanceReviewEvaluationKpiAssessmentByNodeId?: Maybe<UpdatePerformanceReviewEvaluationKpiAssessmentPayload>;
  updatePerformanceReviewEvaluationKpiAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeId?: Maybe<UpdatePerformanceReviewEvaluationKpiAssessmentPayload>;
  updatePerformanceReviewEvaluationKpiDescription?: Maybe<UpdatePerformanceReviewEvaluationKpiDescriptionPayload>;
  updatePerformanceReviewEvaluationKpiDescriptionByNodeId?: Maybe<UpdatePerformanceReviewEvaluationKpiDescriptionPayload>;
  updatePerformanceReviewEvaluationKpiDescriptionByPerformanceReviewEvaluationIdAndTitle?: Maybe<UpdatePerformanceReviewEvaluationKpiDescriptionPayload>;
  updatePerformanceReviewEvaluationKpiResult?: Maybe<UpdatePerformanceReviewEvaluationKpiResultPayload>;
  updatePerformanceReviewEvaluationKpiResultByNodeId?: Maybe<UpdatePerformanceReviewEvaluationKpiResultPayload>;
  updatePerformanceReviewEvaluationKpiResultByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetId?: Maybe<UpdatePerformanceReviewEvaluationKpiResultPayload>;
  updatePerformanceReviewEvaluationKpiTarget?: Maybe<UpdatePerformanceReviewEvaluationKpiTargetPayload>;
  updatePerformanceReviewEvaluationKpiTargetByNodeId?: Maybe<UpdatePerformanceReviewEvaluationKpiTargetPayload>;
  updatePerformanceReviewEvaluationKpiTargetByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndName?: Maybe<UpdatePerformanceReviewEvaluationKpiTargetPayload>;
  updatePerformanceReviewEvaluationOneOnOne?: Maybe<UpdatePerformanceReviewEvaluationOneOnOnePayload>;
  updatePerformanceReviewEvaluationOneOnOneByNodeId?: Maybe<UpdatePerformanceReviewEvaluationOneOnOnePayload>;
  updatePerformanceReviewEvaluationQualitativeAssessment?: Maybe<UpdatePerformanceReviewEvaluationQualitativeAssessmentPayload>;
  updatePerformanceReviewEvaluationQualitativeAssessmentByNodeId?: Maybe<UpdatePerformanceReviewEvaluationQualitativeAssessmentPayload>;
  updatePerformanceReviewEvaluationQualitativeAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeId?: Maybe<UpdatePerformanceReviewEvaluationQualitativeAssessmentPayload>;
  updatePerformanceReviewEvaluationQualitativeDescription?: Maybe<UpdatePerformanceReviewEvaluationQualitativeDescriptionPayload>;
  updatePerformanceReviewEvaluationQualitativeDescriptionByNodeId?: Maybe<UpdatePerformanceReviewEvaluationQualitativeDescriptionPayload>;
  updatePerformanceReviewEvaluationQualitativeDescriptionByPerformanceReviewEvaluationIdAndTitle?: Maybe<UpdatePerformanceReviewEvaluationQualitativeDescriptionPayload>;
  updatePerformanceReviewEvaluationValue?: Maybe<UpdatePerformanceReviewEvaluationValuePayload>;
  updatePerformanceReviewEvaluationValueByNodeId?: Maybe<UpdatePerformanceReviewEvaluationValuePayload>;
  updatePerformanceReviewEvaluationValueByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeId?: Maybe<UpdatePerformanceReviewEvaluationValuePayload>;
  updatePerformanceReviewMasterCompetency?: Maybe<UpdatePerformanceReviewMasterCompetencyPayload>;
  updatePerformanceReviewMasterCompetencyByNodeId?: Maybe<UpdatePerformanceReviewMasterCompetencyPayload>;
  updatePerformanceReviewMasterCompetencyCommon?: Maybe<UpdatePerformanceReviewMasterCompetencyCommonPayload>;
  updatePerformanceReviewMasterCompetencyCommonByNodeId?: Maybe<UpdatePerformanceReviewMasterCompetencyCommonPayload>;
  updatePerformanceReviewMasterCompetencyCommonDescription?: Maybe<UpdatePerformanceReviewMasterCompetencyCommonDescriptionPayload>;
  updatePerformanceReviewMasterCompetencyCommonDescriptionByNodeId?: Maybe<UpdatePerformanceReviewMasterCompetencyCommonDescriptionPayload>;
  updatePerformanceReviewMasterCompetencyCommonDescriptionByPerformanceReviewMasterCompetencyCommonIdAndLanguage?: Maybe<UpdatePerformanceReviewMasterCompetencyCommonDescriptionPayload>;
  updatePerformanceReviewMasterCompetencyDetail?: Maybe<UpdatePerformanceReviewMasterCompetencyDetailPayload>;
  updatePerformanceReviewMasterCompetencyDetailByNodeId?: Maybe<UpdatePerformanceReviewMasterCompetencyDetailPayload>;
  updatePerformanceReviewMasterCompetencyDetailByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonId?: Maybe<UpdatePerformanceReviewMasterCompetencyDetailPayload>;
  updatePerformanceReviewMasterCompetencyDetailDescription?: Maybe<UpdatePerformanceReviewMasterCompetencyDetailDescriptionPayload>;
  updatePerformanceReviewMasterCompetencyDetailDescriptionByNodeId?: Maybe<UpdatePerformanceReviewMasterCompetencyDetailDescriptionPayload>;
  updatePerformanceReviewMasterCompetencyDetailDescriptionByPerformanceReviewMasterCompetencyDetailIdAndLanguage?: Maybe<UpdatePerformanceReviewMasterCompetencyDetailDescriptionPayload>;
  updatePerformanceReviewMasterCompetencyRole?: Maybe<UpdatePerformanceReviewMasterCompetencyRolePayload>;
  updatePerformanceReviewMasterCompetencyRoleByNodeId?: Maybe<UpdatePerformanceReviewMasterCompetencyRolePayload>;
  updatePerformanceReviewMasterCompetencyRoleByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleName?: Maybe<UpdatePerformanceReviewMasterCompetencyRolePayload>;
  updatePerformanceReviewMasterDefault?: Maybe<UpdatePerformanceReviewMasterDefaultPayload>;
  updatePerformanceReviewMasterDefaultByNodeId?: Maybe<UpdatePerformanceReviewMasterDefaultPayload>;
  updatePerformanceReviewMasterEvaluationViewer?: Maybe<UpdatePerformanceReviewMasterEvaluationViewerPayload>;
  updatePerformanceReviewMasterEvaluationViewerByEmployeeIdAndYearAndQuarterAndViewerEmployeeId?: Maybe<UpdatePerformanceReviewMasterEvaluationViewerPayload>;
  updatePerformanceReviewMasterEvaluationViewerByNodeId?: Maybe<UpdatePerformanceReviewMasterEvaluationViewerPayload>;
  updatePerformanceReviewMasterExcludedEmployee?: Maybe<UpdatePerformanceReviewMasterExcludedEmployeePayload>;
  updatePerformanceReviewMasterExcludedEmployeeByNodeId?: Maybe<UpdatePerformanceReviewMasterExcludedEmployeePayload>;
  updatePerformanceReviewMasterOneOnOne?: Maybe<UpdatePerformanceReviewMasterOneOnOnePayload>;
  updatePerformanceReviewMasterOneOnOneByNodeId?: Maybe<UpdatePerformanceReviewMasterOneOnOnePayload>;
  updatePerformanceReviewMasterOneOnOneDetail?: Maybe<UpdatePerformanceReviewMasterOneOnOneDetailPayload>;
  updatePerformanceReviewMasterOneOnOneDetailByNodeId?: Maybe<UpdatePerformanceReviewMasterOneOnOneDetailPayload>;
  updatePerformanceReviewMasterRating?: Maybe<UpdatePerformanceReviewMasterRatingPayload>;
  updatePerformanceReviewMasterRatingByNodeId?: Maybe<UpdatePerformanceReviewMasterRatingPayload>;
  updatePerformanceReviewMasterRatingDescription?: Maybe<UpdatePerformanceReviewMasterRatingDescriptionPayload>;
  updatePerformanceReviewMasterRatingDescriptionByNodeId?: Maybe<UpdatePerformanceReviewMasterRatingDescriptionPayload>;
  updatePerformanceReviewMasterRatingDescriptionByPerformanceReviewMasterRatingIdAndLanguage?: Maybe<UpdatePerformanceReviewMasterRatingDescriptionPayload>;
  updatePerformanceReviewMasterUsage?: Maybe<UpdatePerformanceReviewMasterUsagePayload>;
  updatePerformanceReviewMasterUsageByNodeId?: Maybe<UpdatePerformanceReviewMasterUsagePayload>;
  updatePerformanceReviewMasterUsageByYearAndQuarterAndCompanyId?: Maybe<UpdatePerformanceReviewMasterUsagePayload>;
  updatePerformanceReviewMasterValue?: Maybe<UpdatePerformanceReviewMasterValuePayload>;
  updatePerformanceReviewMasterValueByNodeId?: Maybe<UpdatePerformanceReviewMasterValuePayload>;
  updatePerformanceReviewMasterValueDescription?: Maybe<UpdatePerformanceReviewMasterValueDescriptionPayload>;
  updatePerformanceReviewMasterValueDescriptionByNodeId?: Maybe<UpdatePerformanceReviewMasterValueDescriptionPayload>;
  updatePerformanceReviewMasterValueDescriptionByPerformanceReviewMasterValueIdAndLanguage?: Maybe<UpdatePerformanceReviewMasterValueDescriptionPayload>;
  updatePerformanceReviewMasterWeighting?: Maybe<UpdatePerformanceReviewMasterWeightingPayload>;
  updatePerformanceReviewMasterWeightingByNodeId?: Maybe<UpdatePerformanceReviewMasterWeightingPayload>;
  updatePerformanceReviewMasterWeightingByYearAndQuarterAndCompanyId?: Maybe<UpdatePerformanceReviewMasterWeightingPayload>;
  updatePerformanceReviewMasterWeightingByYearAndQuarterAndName?: Maybe<UpdatePerformanceReviewMasterWeightingPayload>;
  updatePerformanceReviewMasterWeightingDetail?: Maybe<UpdatePerformanceReviewMasterWeightingDetailPayload>;
  updatePerformanceReviewMasterWeightingDetailByNodeId?: Maybe<UpdatePerformanceReviewMasterWeightingDetailPayload>;
  updatePerformanceReviewMasterWeightingDetailByPerformanceReviewMasterWeightingIdAndEmployeeLevelId?: Maybe<UpdatePerformanceReviewMasterWeightingDetailPayload>;
  updateProbationReviewEnable?: Maybe<UpdateProbationReviewEnablePayload>;
  updateProbationReviewEnableByCompanyIdAndContractTypeId?: Maybe<UpdateProbationReviewEnablePayload>;
  updateProbationReviewEnableByNodeId?: Maybe<UpdateProbationReviewEnablePayload>;
  updateProbationReviewEvaluation?: Maybe<UpdateProbationReviewEvaluationPayload>;
  updateProbationReviewEvaluationByEmployeeIdAndReviewDatePlanned?: Maybe<UpdateProbationReviewEvaluationPayload>;
  updateProbationReviewEvaluationByNodeId?: Maybe<UpdateProbationReviewEvaluationPayload>;
  updateProbationReviewEvaluationResultItem?: Maybe<UpdateProbationReviewEvaluationResultItemPayload>;
  updateProbationReviewEvaluationResultItemByNodeId?: Maybe<UpdateProbationReviewEvaluationResultItemPayload>;
  updateProbationReviewEvaluationResultItemByProbationReviewEvaluationIdAndProbationReviewMasterItemId?: Maybe<UpdateProbationReviewEvaluationResultItemPayload>;
  updateProbationReviewEvaluationResultScoreCompetency?: Maybe<UpdateProbationReviewEvaluationResultScoreCompetencyPayload>;
  updateProbationReviewEvaluationResultScoreCompetencyByNodeId?: Maybe<UpdateProbationReviewEvaluationResultScoreCompetencyPayload>;
  updateProbationReviewEvaluationResultScoreCompetencyByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyId?: Maybe<UpdateProbationReviewEvaluationResultScoreCompetencyPayload>;
  updateProbationReviewEvaluationResultScoreValue?: Maybe<UpdateProbationReviewEvaluationResultScoreValuePayload>;
  updateProbationReviewEvaluationResultScoreValueByNodeId?: Maybe<UpdateProbationReviewEvaluationResultScoreValuePayload>;
  updateProbationReviewEvaluationResultScoreValueByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueId?: Maybe<UpdateProbationReviewEvaluationResultScoreValuePayload>;
  updateProbationReviewMasterItem?: Maybe<UpdateProbationReviewMasterItemPayload>;
  updateProbationReviewMasterItemByNodeId?: Maybe<UpdateProbationReviewMasterItemPayload>;
  updateProbationReviewMasterItemByYearAndQuarterAndTitleAndInputUser?: Maybe<UpdateProbationReviewMasterItemPayload>;
  updateProbationReviewMasterItemDescription?: Maybe<UpdateProbationReviewMasterItemDescriptionPayload>;
  updateProbationReviewMasterItemDescriptionByNodeId?: Maybe<UpdateProbationReviewMasterItemDescriptionPayload>;
  updateProbationReviewMasterItemDescriptionByProbationReviewMasterItemIdAndLanguage?: Maybe<UpdateProbationReviewMasterItemDescriptionPayload>;
  updateRelationBusinessUnitDivision?: Maybe<UpdateRelationBusinessUnitDivisionPayload>;
  updateRelationBusinessUnitDivisionByNodeId?: Maybe<UpdateRelationBusinessUnitDivisionPayload>;
  updateRelationBusinessUnitDivisionDepartment?: Maybe<UpdateRelationBusinessUnitDivisionDepartmentPayload>;
  updateRelationBusinessUnitDivisionDepartmentByNodeId?: Maybe<UpdateRelationBusinessUnitDivisionDepartmentPayload>;
  updateRelationCityCompany?: Maybe<UpdateRelationCityCompanyPayload>;
  updateRelationCityCompanyByNodeId?: Maybe<UpdateRelationCityCompanyPayload>;
  updateRelationCompanyBusinessUnit?: Maybe<UpdateRelationCompanyBusinessUnitPayload>;
  updateRelationCompanyBusinessUnitByNodeId?: Maybe<UpdateRelationCompanyBusinessUnitPayload>;
  updateRelationCompanyGroupEmailDomain?: Maybe<UpdateRelationCompanyGroupEmailDomainPayload>;
  updateRelationCompanyGroupEmailDomainByNodeId?: Maybe<UpdateRelationCompanyGroupEmailDomainPayload>;
  updateRelationCompanyOffice?: Maybe<UpdateRelationCompanyOfficePayload>;
  updateRelationCompanyOfficeByNodeId?: Maybe<UpdateRelationCompanyOfficePayload>;
  updateRelationCurrencyCountry?: Maybe<UpdateRelationCurrencyCountryPayload>;
  updateRelationCurrencyCountryByNodeId?: Maybe<UpdateRelationCurrencyCountryPayload>;
  updateRelationEmployeeUserAccount?: Maybe<UpdateRelationEmployeeUserAccountPayload>;
  updateRelationEmployeeUserAccountByNodeId?: Maybe<UpdateRelationEmployeeUserAccountPayload>;
  updateUserAccount?: Maybe<UpdateUserAccountPayload>;
  updateUserAccountAccessHistory?: Maybe<UpdateUserAccountAccessHistoryPayload>;
  updateUserAccountAccessHistoryByNodeId?: Maybe<UpdateUserAccountAccessHistoryPayload>;
  updateUserAccountByEmailAddress?: Maybe<UpdateUserAccountPayload>;
  updateUserAccountByNodeId?: Maybe<UpdateUserAccountPayload>;
  updateUserAccountPin?: Maybe<UpdateUserAccountPinPayload>;
  updateUserAccountPinByNodeId?: Maybe<UpdateUserAccountPinPayload>;
  updateUserHrManagingCompany?: Maybe<UpdateUserHrManagingCompanyPayload>;
  updateUserHrManagingCompanyByNodeId?: Maybe<UpdateUserHrManagingCompanyPayload>;
  updateUserHrManagingCompanyByUserAccountIdAndCompanyId?: Maybe<UpdateUserHrManagingCompanyPayload>;
  updateWorldPlace?: Maybe<UpdateWorldPlacePayload>;
  updateWorldPlaceByDisplayOrder?: Maybe<UpdateWorldPlacePayload>;
  updateWorldPlaceByNodeId?: Maybe<UpdateWorldPlacePayload>;
  upsertBankByBankId?: Maybe<UpsertBankPayload>;
  upsertBankByCountryCodeAlpha3AndFullName?: Maybe<UpsertBankPayload>;
  upsertBankBySwiftCode?: Maybe<UpsertBankPayload>;
  upsertBusinessUnitByBusinessUnitId?: Maybe<UpsertBusinessUnitPayload>;
  upsertBusinessUnitByName?: Maybe<UpsertBusinessUnitPayload>;
  upsertCityByCityId?: Maybe<UpsertCityPayload>;
  upsertCityByCountryCodeAlpha2AndName?: Maybe<UpsertCityPayload>;
  upsertCompanyByAbbreviation?: Maybe<UpsertCompanyPayload>;
  upsertCompanyByCompanyId?: Maybe<UpsertCompanyPayload>;
  upsertCompanyByCountryCodeAlpha2AndFullName?: Maybe<UpsertCompanyPayload>;
  upsertCompanyGlobalHrByCompanyGlobalHrId?: Maybe<UpsertCompanyGlobalHrPayload>;
  upsertCompanyGlobalHrByEmployeeId?: Maybe<UpsertCompanyGlobalHrPayload>;
  upsertCompanyGroupByCompanyGroupId?: Maybe<UpsertCompanyGroupPayload>;
  upsertCompanyGroupByName?: Maybe<UpsertCompanyGroupPayload>;
  upsertCompanyLocalHrByCompanyIdAndEmployeeId?: Maybe<UpsertCompanyLocalHrPayload>;
  upsertCompanyLocalHrByCompanyLocalHrId?: Maybe<UpsertCompanyLocalHrPayload>;
  upsertContractTypeByCode?: Maybe<UpsertContractTypePayload>;
  upsertContractTypeByContractTypeId?: Maybe<UpsertContractTypePayload>;
  upsertContractTypeByDisplayName?: Maybe<UpsertContractTypePayload>;
  upsertCountryByCountryCodeAlpha2?: Maybe<UpsertCountryPayload>;
  upsertCountryByCountryCodeAlpha3?: Maybe<UpsertCountryPayload>;
  upsertCountryByCountryCodeNumeric?: Maybe<UpsertCountryPayload>;
  upsertCountryByDisplayOrder?: Maybe<UpsertCountryPayload>;
  upsertCurrencyByCurrencyCode?: Maybe<UpsertCurrencyPayload>;
  upsertDepartmentByDepartmentId?: Maybe<UpsertDepartmentPayload>;
  upsertDepartmentByName?: Maybe<UpsertDepartmentPayload>;
  upsertDivisionByDivisionId?: Maybe<UpsertDivisionPayload>;
  upsertDivisionByName?: Maybe<UpsertDivisionPayload>;
  upsertEmailDomainByDomainName?: Maybe<UpsertEmailDomainPayload>;
  upsertEmailDomainByEmailDomainId?: Maybe<UpsertEmailDomainPayload>;
  upsertEmployeeByCompanyEmail?: Maybe<UpsertEmployeePayload>;
  upsertEmployeeByEmployeeId?: Maybe<UpsertEmployeePayload>;
  upsertEmployeeEmergencyContactByEmployeeEmergencyContactId?: Maybe<UpsertEmployeeEmergencyContactPayload>;
  upsertEmployeeEmergencyContactByEmployeeIdAndRelationIdAndName?: Maybe<UpsertEmployeeEmergencyContactPayload>;
  upsertEmployeeIdTypeByCode?: Maybe<UpsertEmployeeIdTypePayload>;
  upsertEmployeeIdTypeByEmployeeIdTypeId?: Maybe<UpsertEmployeeIdTypePayload>;
  upsertEmployeeLevelByDisplayName?: Maybe<UpsertEmployeeLevelPayload>;
  upsertEmployeeLevelByEmployeeLevelId?: Maybe<UpsertEmployeeLevelPayload>;
  upsertEmployeeResignationReasonByDisplayName?: Maybe<UpsertEmployeeResignationReasonPayload>;
  upsertEmployeeResignationReasonByEmployeeResignationReasonId?: Maybe<UpsertEmployeeResignationReasonPayload>;
  upsertEmployeeResignationTypeByDisplayName?: Maybe<UpsertEmployeeResignationTypePayload>;
  upsertEmployeeResignationTypeByEmployeeResignationTypeId?: Maybe<UpsertEmployeeResignationTypePayload>;
  upsertEmployeeTpHistoryByEmployeeIdAndEffectiveDate?: Maybe<UpsertEmployeeTpHistoryPayload>;
  upsertEmployeeTpHistoryByEmployeeTpHistoryId?: Maybe<UpsertEmployeeTpHistoryPayload>;
  upsertExternalAccountEmployeeByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKey?: Maybe<UpsertExternalAccountEmployeePayload>;
  upsertExternalAccountEmployeeByExternalAccountEmployeeId?: Maybe<UpsertExternalAccountEmployeePayload>;
  upsertGoogleWorkspaceOrgUnitByCompanyIdAndBusinessUnitId?: Maybe<UpsertGoogleWorkspaceOrgUnitPayload>;
  upsertGoogleWorkspaceOrgUnitByGoogleWorkspaceOrgUnitId?: Maybe<UpsertGoogleWorkspaceOrgUnitPayload>;
  upsertOfficeByCityIdAndName?: Maybe<UpsertOfficePayload>;
  upsertOfficeByOfficeId?: Maybe<UpsertOfficePayload>;
  upsertPerformanceReviewEvaluationByPerformanceReviewEvaluationId?: Maybe<UpsertPerformanceReviewEvaluationPayload>;
  upsertPerformanceReviewEvaluationCompetencyByPerformanceReviewEvaluationCompetencyId?: Maybe<UpsertPerformanceReviewEvaluationCompetencyPayload>;
  upsertPerformanceReviewEvaluationCompetencyByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeId?: Maybe<UpsertPerformanceReviewEvaluationCompetencyPayload>;
  upsertPerformanceReviewEvaluationFeedbackByPerformanceReviewEvaluationFeedbackId?: Maybe<UpsertPerformanceReviewEvaluationFeedbackPayload>;
  upsertPerformanceReviewEvaluationFeedbackByPerformanceReviewEvaluationIdAndFeedbackEmployeeId?: Maybe<UpsertPerformanceReviewEvaluationFeedbackPayload>;
  upsertPerformanceReviewEvaluationKpiAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeId?: Maybe<UpsertPerformanceReviewEvaluationKpiAssessmentPayload>;
  upsertPerformanceReviewEvaluationKpiAssessmentByPerformanceReviewEvaluationKpiAssessmentId?: Maybe<UpsertPerformanceReviewEvaluationKpiAssessmentPayload>;
  upsertPerformanceReviewEvaluationKpiDescriptionByPerformanceReviewEvaluationIdAndTitle?: Maybe<UpsertPerformanceReviewEvaluationKpiDescriptionPayload>;
  upsertPerformanceReviewEvaluationKpiDescriptionByPerformanceReviewEvaluationKpiDescriptionId?: Maybe<UpsertPerformanceReviewEvaluationKpiDescriptionPayload>;
  upsertPerformanceReviewEvaluationKpiResultByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetId?: Maybe<UpsertPerformanceReviewEvaluationKpiResultPayload>;
  upsertPerformanceReviewEvaluationKpiResultByPerformanceReviewEvaluationKpiResultId?: Maybe<UpsertPerformanceReviewEvaluationKpiResultPayload>;
  upsertPerformanceReviewEvaluationKpiTargetByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndName?: Maybe<UpsertPerformanceReviewEvaluationKpiTargetPayload>;
  upsertPerformanceReviewEvaluationKpiTargetByPerformanceReviewEvaluationKpiTargetId?: Maybe<UpsertPerformanceReviewEvaluationKpiTargetPayload>;
  upsertPerformanceReviewEvaluationQualitativeAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeId?: Maybe<UpsertPerformanceReviewEvaluationQualitativeAssessmentPayload>;
  upsertPerformanceReviewEvaluationQualitativeAssessmentByPerformanceReviewEvaluationQualitativeAssessmentId?: Maybe<UpsertPerformanceReviewEvaluationQualitativeAssessmentPayload>;
  upsertPerformanceReviewEvaluationQualitativeDescriptionByPerformanceReviewEvaluationIdAndTitle?: Maybe<UpsertPerformanceReviewEvaluationQualitativeDescriptionPayload>;
  upsertPerformanceReviewEvaluationQualitativeDescriptionByPerformanceReviewEvaluationQualitativeDescriptionId?: Maybe<UpsertPerformanceReviewEvaluationQualitativeDescriptionPayload>;
  upsertPerformanceReviewEvaluationValueByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeId?: Maybe<UpsertPerformanceReviewEvaluationValuePayload>;
  upsertPerformanceReviewEvaluationValueByPerformanceReviewEvaluationValueId?: Maybe<UpsertPerformanceReviewEvaluationValuePayload>;
  upsertPerformanceReviewMasterCompetencyCommonDescriptionByPerformanceReviewMasterCompetencyCommonDescriptionId?: Maybe<UpsertPerformanceReviewMasterCompetencyCommonDescriptionPayload>;
  upsertPerformanceReviewMasterCompetencyCommonDescriptionByPerformanceReviewMasterCompetencyCommonIdAndLanguage?: Maybe<UpsertPerformanceReviewMasterCompetencyCommonDescriptionPayload>;
  upsertPerformanceReviewMasterCompetencyDetailDescriptionByPerformanceReviewMasterCompetencyDetailDescriptionId?: Maybe<UpsertPerformanceReviewMasterCompetencyDetailDescriptionPayload>;
  upsertPerformanceReviewMasterCompetencyDetailDescriptionByPerformanceReviewMasterCompetencyDetailIdAndLanguage?: Maybe<UpsertPerformanceReviewMasterCompetencyDetailDescriptionPayload>;
  upsertPerformanceReviewMasterCompetencyRoleByPerformanceReviewMasterCompetencyRoleId?: Maybe<UpsertPerformanceReviewMasterCompetencyRolePayload>;
  upsertPerformanceReviewMasterCompetencyRoleByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleName?: Maybe<UpsertPerformanceReviewMasterCompetencyRolePayload>;
  upsertPerformanceReviewMasterExcludedEmployeeByYearAndQuarterAndEmployeeId?: Maybe<UpsertPerformanceReviewMasterExcludedEmployeePayload>;
  upsertPerformanceReviewMasterRatingDescriptionByPerformanceReviewMasterRatingDescriptionId?: Maybe<UpsertPerformanceReviewMasterRatingDescriptionPayload>;
  upsertPerformanceReviewMasterRatingDescriptionByPerformanceReviewMasterRatingIdAndLanguage?: Maybe<UpsertPerformanceReviewMasterRatingDescriptionPayload>;
  upsertPerformanceReviewMasterUsageByPerformanceReviewMasterUsageId?: Maybe<UpsertPerformanceReviewMasterUsagePayload>;
  upsertPerformanceReviewMasterUsageByYearAndQuarterAndCompanyId?: Maybe<UpsertPerformanceReviewMasterUsagePayload>;
  upsertPerformanceReviewMasterValueDescriptionByPerformanceReviewMasterValueDescriptionId?: Maybe<UpsertPerformanceReviewMasterValueDescriptionPayload>;
  upsertPerformanceReviewMasterValueDescriptionByPerformanceReviewMasterValueIdAndLanguage?: Maybe<UpsertPerformanceReviewMasterValueDescriptionPayload>;
  upsertPerformanceReviewMasterWeightingByPerformanceReviewMasterWeightingId?: Maybe<UpsertPerformanceReviewMasterWeightingPayload>;
  upsertPerformanceReviewMasterWeightingByYearAndQuarterAndCompanyId?: Maybe<UpsertPerformanceReviewMasterWeightingPayload>;
  upsertPerformanceReviewMasterWeightingByYearAndQuarterAndName?: Maybe<UpsertPerformanceReviewMasterWeightingPayload>;
  upsertPerformanceReviewMasterWeightingDetailByPerformanceReviewMasterWeightingDetailId?: Maybe<UpsertPerformanceReviewMasterWeightingDetailPayload>;
  upsertPerformanceReviewMasterWeightingDetailByPerformanceReviewMasterWeightingIdAndEmployeeLevelId?: Maybe<UpsertPerformanceReviewMasterWeightingDetailPayload>;
  upsertProbationReviewEvaluationByEmployeeIdAndReviewDatePlanned?: Maybe<UpsertProbationReviewEvaluationPayload>;
  upsertProbationReviewEvaluationByProbationReviewEvaluationId?: Maybe<UpsertProbationReviewEvaluationPayload>;
  upsertProbationReviewEvaluationResultItemByProbationReviewEvaluationIdAndProbationReviewMasterItemId?: Maybe<UpsertProbationReviewEvaluationResultItemPayload>;
  upsertProbationReviewEvaluationResultItemByProbationReviewEvaluationResultItemId?: Maybe<UpsertProbationReviewEvaluationResultItemPayload>;
  upsertProbationReviewEvaluationResultScoreCompetencyByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyId?: Maybe<UpsertProbationReviewEvaluationResultScoreCompetencyPayload>;
  upsertProbationReviewEvaluationResultScoreCompetencyByProbationReviewEvaluationResultScoreCompetencyId?: Maybe<UpsertProbationReviewEvaluationResultScoreCompetencyPayload>;
  upsertProbationReviewEvaluationResultScoreValueByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueId?: Maybe<UpsertProbationReviewEvaluationResultScoreValuePayload>;
  upsertProbationReviewEvaluationResultScoreValueByProbationReviewEvaluationResultScoreValueId?: Maybe<UpsertProbationReviewEvaluationResultScoreValuePayload>;
  upsertProbationReviewEvaluationReviewerByProbationReviewEvaluationIdAndReviewerEmployeeId?: Maybe<UpsertProbationReviewEvaluationReviewerPayload>;
  upsertProbationReviewMasterItemByProbationReviewMasterItemId?: Maybe<UpsertProbationReviewMasterItemPayload>;
  upsertProbationReviewMasterItemByYearAndQuarterAndTitleAndInputUser?: Maybe<UpsertProbationReviewMasterItemPayload>;
  upsertProbationReviewMasterItemDescriptionByProbationReviewMasterItemDescriptionId?: Maybe<UpsertProbationReviewMasterItemDescriptionPayload>;
  upsertProbationReviewMasterItemDescriptionByProbationReviewMasterItemIdAndLanguage?: Maybe<UpsertProbationReviewMasterItemDescriptionPayload>;
  upsertRelationEmployeeUserAccountByUserAccountId?: Maybe<UpsertRelationEmployeeUserAccountPayload>;
  validateSubscription?: Maybe<ValidateSubscriptionPayload>;
}

export type MutationCopyPerformanceReviewMasterCompetencyCommonArgs = {
  input: CopyPerformanceReviewMasterCompetencyCommonInput;
};

export type MutationCopyPerformanceReviewMasterCompetencyDetailArgs = {
  input: CopyPerformanceReviewMasterCompetencyDetailInput;
};

export type MutationCopyPerformanceReviewMasterRatingArgs = {
  input: CopyPerformanceReviewMasterRatingInput;
};

export type MutationCopyPerformanceReviewMasterValueArgs = {
  input: CopyPerformanceReviewMasterValueInput;
};

export type MutationCopyPerformanceReviewMasterWeightingArgs = {
  input: CopyPerformanceReviewMasterWeightingInput;
};

export type MutationCreateBankArgs = {
  input: CreateBankInput;
};

export type MutationCreateBusinessUnitArgs = {
  input: CreateBusinessUnitInput;
};

export type MutationCreateCityArgs = {
  input: CreateCityInput;
};

export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};

export type MutationCreateCompanyExternalAccountDisableArgs = {
  input: CreateCompanyExternalAccountDisableInput;
};

export type MutationCreateCompanyGlobalHrArgs = {
  input: CreateCompanyGlobalHrInput;
};

export type MutationCreateCompanyGroupArgs = {
  input: CreateCompanyGroupInput;
};

export type MutationCreateCompanyLocalHrArgs = {
  input: CreateCompanyLocalHrInput;
};

export type MutationCreateContractTypeArgs = {
  input: CreateContractTypeInput;
};

export type MutationCreateCountryArgs = {
  input: CreateCountryInput;
};

export type MutationCreateCurrencyArgs = {
  input: CreateCurrencyInput;
};

export type MutationCreateDepartmentArgs = {
  input: CreateDepartmentInput;
};

export type MutationCreateDivisionArgs = {
  input: CreateDivisionInput;
};

export type MutationCreateEmailDomainArgs = {
  input: CreateEmailDomainInput;
};

export type MutationCreateEmployeeArgs = {
  input: CreateEmployeeInput;
};

export type MutationCreateEmployeeEmergencyContactArgs = {
  input: CreateEmployeeEmergencyContactInput;
};

export type MutationCreateEmployeeFileArgs = {
  input: CreateEmployeeFileInput;
};

export type MutationCreateEmployeeGroupArgs = {
  input: CreateEmployeeGroupInput;
};

export type MutationCreateEmployeeIdTypeArgs = {
  input: CreateEmployeeIdTypeInput;
};

export type MutationCreateEmployeeLevelArgs = {
  input: CreateEmployeeLevelInput;
};

export type MutationCreateEmployeeListCustomFilterArgs = {
  input: CreateEmployeeListCustomFilterInput;
};

export type MutationCreateEmployeeListCustomFilterRoleArgs = {
  input: CreateEmployeeListCustomFilterRoleInput;
};

export type MutationCreateEmployeeResignationReasonArgs = {
  input: CreateEmployeeResignationReasonInput;
};

export type MutationCreateEmployeeResignationTypeArgs = {
  input: CreateEmployeeResignationTypeInput;
};

export type MutationCreateEmployeeTpHistoryArgs = {
  input: CreateEmployeeTpHistoryInput;
};

export type MutationCreateExportEmployeeStatusArgs = {
  input: CreateExportEmployeeStatusInput;
};

export type MutationCreateExternalAccountEmployeeArgs = {
  input: CreateExternalAccountEmployeeInput;
};

export type MutationCreateExternalAccountTypeArgs = {
  input: CreateExternalAccountTypeInput;
};

export type MutationCreateGoogleWorkspaceOrgUnitArgs = {
  input: CreateGoogleWorkspaceOrgUnitInput;
};

export type MutationCreateImportEmployeeStatusArgs = {
  input: CreateImportEmployeeStatusInput;
};

export type MutationCreateOfficeArgs = {
  input: CreateOfficeInput;
};

export type MutationCreatePerformanceReviewEvaluationArgs = {
  input: CreatePerformanceReviewEvaluationInput;
};

export type MutationCreatePerformanceReviewEvaluationCompetencyArgs = {
  input: CreatePerformanceReviewEvaluationCompetencyInput;
};

export type MutationCreatePerformanceReviewEvaluationEvaluatorArgs = {
  input: CreatePerformanceReviewEvaluationEvaluatorInput;
};

export type MutationCreatePerformanceReviewEvaluationFeedbackArgs = {
  input: CreatePerformanceReviewEvaluationFeedbackInput;
};

export type MutationCreatePerformanceReviewEvaluationKpiAssessmentArgs = {
  input: CreatePerformanceReviewEvaluationKpiAssessmentInput;
};

export type MutationCreatePerformanceReviewEvaluationKpiDescriptionArgs = {
  input: CreatePerformanceReviewEvaluationKpiDescriptionInput;
};

export type MutationCreatePerformanceReviewEvaluationKpiResultArgs = {
  input: CreatePerformanceReviewEvaluationKpiResultInput;
};

export type MutationCreatePerformanceReviewEvaluationKpiTargetArgs = {
  input: CreatePerformanceReviewEvaluationKpiTargetInput;
};

export type MutationCreatePerformanceReviewEvaluationOneOnOneArgs = {
  input: CreatePerformanceReviewEvaluationOneOnOneInput;
};

export type MutationCreatePerformanceReviewEvaluationQualitativeAssessmentArgs = {
  input: CreatePerformanceReviewEvaluationQualitativeAssessmentInput;
};

export type MutationCreatePerformanceReviewEvaluationQualitativeDescriptionArgs = {
  input: CreatePerformanceReviewEvaluationQualitativeDescriptionInput;
};

export type MutationCreatePerformanceReviewEvaluationValueArgs = {
  input: CreatePerformanceReviewEvaluationValueInput;
};

export type MutationCreatePerformanceReviewMasterCompetencyArgs = {
  input: CreatePerformanceReviewMasterCompetencyInput;
};

export type MutationCreatePerformanceReviewMasterCompetencyCommonArgs = {
  input: CreatePerformanceReviewMasterCompetencyCommonInput;
};

export type MutationCreatePerformanceReviewMasterCompetencyCommonDescriptionArgs = {
  input: CreatePerformanceReviewMasterCompetencyCommonDescriptionInput;
};

export type MutationCreatePerformanceReviewMasterCompetencyDetailArgs = {
  input: CreatePerformanceReviewMasterCompetencyDetailInput;
};

export type MutationCreatePerformanceReviewMasterCompetencyDetailDescriptionArgs = {
  input: CreatePerformanceReviewMasterCompetencyDetailDescriptionInput;
};

export type MutationCreatePerformanceReviewMasterCompetencyRoleArgs = {
  input: CreatePerformanceReviewMasterCompetencyRoleInput;
};

export type MutationCreatePerformanceReviewMasterDefaultArgs = {
  input: CreatePerformanceReviewMasterDefaultInput;
};

export type MutationCreatePerformanceReviewMasterEvaluationViewerArgs = {
  input: CreatePerformanceReviewMasterEvaluationViewerInput;
};

export type MutationCreatePerformanceReviewMasterExcludedEmployeeArgs = {
  input: CreatePerformanceReviewMasterExcludedEmployeeInput;
};

export type MutationCreatePerformanceReviewMasterOneOnOneArgs = {
  input: CreatePerformanceReviewMasterOneOnOneInput;
};

export type MutationCreatePerformanceReviewMasterOneOnOneDetailArgs = {
  input: CreatePerformanceReviewMasterOneOnOneDetailInput;
};

export type MutationCreatePerformanceReviewMasterRatingArgs = {
  input: CreatePerformanceReviewMasterRatingInput;
};

export type MutationCreatePerformanceReviewMasterRatingDescriptionArgs = {
  input: CreatePerformanceReviewMasterRatingDescriptionInput;
};

export type MutationCreatePerformanceReviewMasterUsageArgs = {
  input: CreatePerformanceReviewMasterUsageInput;
};

export type MutationCreatePerformanceReviewMasterValueArgs = {
  input: CreatePerformanceReviewMasterValueInput;
};

export type MutationCreatePerformanceReviewMasterValueDescriptionArgs = {
  input: CreatePerformanceReviewMasterValueDescriptionInput;
};

export type MutationCreatePerformanceReviewMasterWeightingArgs = {
  input: CreatePerformanceReviewMasterWeightingInput;
};

export type MutationCreatePerformanceReviewMasterWeightingDetailArgs = {
  input: CreatePerformanceReviewMasterWeightingDetailInput;
};

export type MutationCreateProbationReviewEnableArgs = {
  input: CreateProbationReviewEnableInput;
};

export type MutationCreateProbationReviewEvaluationArgs = {
  input: CreateProbationReviewEvaluationInput;
};

export type MutationCreateProbationReviewEvaluationResultItemArgs = {
  input: CreateProbationReviewEvaluationResultItemInput;
};

export type MutationCreateProbationReviewEvaluationResultScoreCompetencyArgs = {
  input: CreateProbationReviewEvaluationResultScoreCompetencyInput;
};

export type MutationCreateProbationReviewEvaluationResultScoreValueArgs = {
  input: CreateProbationReviewEvaluationResultScoreValueInput;
};

export type MutationCreateProbationReviewEvaluationReviewerArgs = {
  input: CreateProbationReviewEvaluationReviewerInput;
};

export type MutationCreateProbationReviewMasterItemArgs = {
  input: CreateProbationReviewMasterItemInput;
};

export type MutationCreateProbationReviewMasterItemDescriptionArgs = {
  input: CreateProbationReviewMasterItemDescriptionInput;
};

export type MutationCreateRelationBusinessUnitDivisionArgs = {
  input: CreateRelationBusinessUnitDivisionInput;
};

export type MutationCreateRelationBusinessUnitDivisionDepartmentArgs = {
  input: CreateRelationBusinessUnitDivisionDepartmentInput;
};

export type MutationCreateRelationCityCompanyArgs = {
  input: CreateRelationCityCompanyInput;
};

export type MutationCreateRelationCompanyBusinessUnitArgs = {
  input: CreateRelationCompanyBusinessUnitInput;
};

export type MutationCreateRelationCompanyGroupEmailDomainArgs = {
  input: CreateRelationCompanyGroupEmailDomainInput;
};

export type MutationCreateRelationCompanyOfficeArgs = {
  input: CreateRelationCompanyOfficeInput;
};

export type MutationCreateRelationCurrencyCountryArgs = {
  input: CreateRelationCurrencyCountryInput;
};

export type MutationCreateRelationEmployeeUserAccountArgs = {
  input: CreateRelationEmployeeUserAccountInput;
};

export type MutationCreateUserAccountArgs = {
  input: CreateUserAccountInput;
};

export type MutationCreateUserAccountAccessHistoryArgs = {
  input: CreateUserAccountAccessHistoryInput;
};

export type MutationCreateUserAccountPinArgs = {
  input: CreateUserAccountPinInput;
};

export type MutationCreateUserHrManagingCompanyArgs = {
  input: CreateUserHrManagingCompanyInput;
};

export type MutationCreateWorldPlaceArgs = {
  input: CreateWorldPlaceInput;
};

export type MutationDeleteBankArgs = {
  input: DeleteBankInput;
};

export type MutationDeleteBankByCountryCodeAlpha3AndFullNameArgs = {
  input: DeleteBankByCountryCodeAlpha3AndFullNameInput;
};

export type MutationDeleteBankByNodeIdArgs = {
  input: DeleteBankByNodeIdInput;
};

export type MutationDeleteBankBySwiftCodeArgs = {
  input: DeleteBankBySwiftCodeInput;
};

export type MutationDeleteBusinessUnitArgs = {
  input: DeleteBusinessUnitInput;
};

export type MutationDeleteBusinessUnitByNameArgs = {
  input: DeleteBusinessUnitByNameInput;
};

export type MutationDeleteBusinessUnitByNodeIdArgs = {
  input: DeleteBusinessUnitByNodeIdInput;
};

export type MutationDeleteCityArgs = {
  input: DeleteCityInput;
};

export type MutationDeleteCityByCountryCodeAlpha2AndNameArgs = {
  input: DeleteCityByCountryCodeAlpha2AndNameInput;
};

export type MutationDeleteCityByNodeIdArgs = {
  input: DeleteCityByNodeIdInput;
};

export type MutationDeleteCompanyArgs = {
  input: DeleteCompanyInput;
};

export type MutationDeleteCompanyByAbbreviationArgs = {
  input: DeleteCompanyByAbbreviationInput;
};

export type MutationDeleteCompanyByCountryCodeAlpha2AndFullNameArgs = {
  input: DeleteCompanyByCountryCodeAlpha2AndFullNameInput;
};

export type MutationDeleteCompanyByNodeIdArgs = {
  input: DeleteCompanyByNodeIdInput;
};

export type MutationDeleteCompanyExternalAccountDisableArgs = {
  input: DeleteCompanyExternalAccountDisableInput;
};

export type MutationDeleteCompanyExternalAccountDisableByNodeIdArgs = {
  input: DeleteCompanyExternalAccountDisableByNodeIdInput;
};

export type MutationDeleteCompanyGlobalHrArgs = {
  input: DeleteCompanyGlobalHrInput;
};

export type MutationDeleteCompanyGlobalHrByEmployeeIdArgs = {
  input: DeleteCompanyGlobalHrByEmployeeIdInput;
};

export type MutationDeleteCompanyGlobalHrByNodeIdArgs = {
  input: DeleteCompanyGlobalHrByNodeIdInput;
};

export type MutationDeleteCompanyGroupArgs = {
  input: DeleteCompanyGroupInput;
};

export type MutationDeleteCompanyGroupByNameArgs = {
  input: DeleteCompanyGroupByNameInput;
};

export type MutationDeleteCompanyGroupByNodeIdArgs = {
  input: DeleteCompanyGroupByNodeIdInput;
};

export type MutationDeleteCompanyLocalHrArgs = {
  input: DeleteCompanyLocalHrInput;
};

export type MutationDeleteCompanyLocalHrByCompanyIdAndEmployeeIdArgs = {
  input: DeleteCompanyLocalHrByCompanyIdAndEmployeeIdInput;
};

export type MutationDeleteCompanyLocalHrByNodeIdArgs = {
  input: DeleteCompanyLocalHrByNodeIdInput;
};

export type MutationDeleteContractTypeArgs = {
  input: DeleteContractTypeInput;
};

export type MutationDeleteContractTypeByCodeArgs = {
  input: DeleteContractTypeByCodeInput;
};

export type MutationDeleteContractTypeByDisplayNameArgs = {
  input: DeleteContractTypeByDisplayNameInput;
};

export type MutationDeleteContractTypeByNodeIdArgs = {
  input: DeleteContractTypeByNodeIdInput;
};

export type MutationDeleteCountryArgs = {
  input: DeleteCountryInput;
};

export type MutationDeleteCountryByCountryCodeAlpha3Args = {
  input: DeleteCountryByCountryCodeAlpha3Input;
};

export type MutationDeleteCountryByCountryCodeNumericArgs = {
  input: DeleteCountryByCountryCodeNumericInput;
};

export type MutationDeleteCountryByDisplayOrderArgs = {
  input: DeleteCountryByDisplayOrderInput;
};

export type MutationDeleteCountryByNodeIdArgs = {
  input: DeleteCountryByNodeIdInput;
};

export type MutationDeleteCurrencyArgs = {
  input: DeleteCurrencyInput;
};

export type MutationDeleteCurrencyByNodeIdArgs = {
  input: DeleteCurrencyByNodeIdInput;
};

export type MutationDeleteDepartmentArgs = {
  input: DeleteDepartmentInput;
};

export type MutationDeleteDepartmentByNameArgs = {
  input: DeleteDepartmentByNameInput;
};

export type MutationDeleteDepartmentByNodeIdArgs = {
  input: DeleteDepartmentByNodeIdInput;
};

export type MutationDeleteDivisionArgs = {
  input: DeleteDivisionInput;
};

export type MutationDeleteDivisionByNameArgs = {
  input: DeleteDivisionByNameInput;
};

export type MutationDeleteDivisionByNodeIdArgs = {
  input: DeleteDivisionByNodeIdInput;
};

export type MutationDeleteEmailDomainArgs = {
  input: DeleteEmailDomainInput;
};

export type MutationDeleteEmailDomainByDomainNameArgs = {
  input: DeleteEmailDomainByDomainNameInput;
};

export type MutationDeleteEmailDomainByNodeIdArgs = {
  input: DeleteEmailDomainByNodeIdInput;
};

export type MutationDeleteEmployeeArgs = {
  input: DeleteEmployeeInput;
};

export type MutationDeleteEmployeeByCompanyEmailArgs = {
  input: DeleteEmployeeByCompanyEmailInput;
};

export type MutationDeleteEmployeeByNodeIdArgs = {
  input: DeleteEmployeeByNodeIdInput;
};

export type MutationDeleteEmployeeEmergencyContactArgs = {
  input: DeleteEmployeeEmergencyContactInput;
};

export type MutationDeleteEmployeeEmergencyContactByEmployeeIdAndRelationIdAndNameArgs = {
  input: DeleteEmployeeEmergencyContactByEmployeeIdAndRelationIdAndNameInput;
};

export type MutationDeleteEmployeeEmergencyContactByNodeIdArgs = {
  input: DeleteEmployeeEmergencyContactByNodeIdInput;
};

export type MutationDeleteEmployeeFileArgs = {
  input: DeleteEmployeeFileInput;
};

export type MutationDeleteEmployeeFileByNodeIdArgs = {
  input: DeleteEmployeeFileByNodeIdInput;
};

export type MutationDeleteEmployeeFileByS3PathArgs = {
  input: DeleteEmployeeFileByS3PathInput;
};

export type MutationDeleteEmployeeGroupArgs = {
  input: DeleteEmployeeGroupInput;
};

export type MutationDeleteEmployeeGroupByNodeIdArgs = {
  input: DeleteEmployeeGroupByNodeIdInput;
};

export type MutationDeleteEmployeeIdTypeArgs = {
  input: DeleteEmployeeIdTypeInput;
};

export type MutationDeleteEmployeeIdTypeByCodeArgs = {
  input: DeleteEmployeeIdTypeByCodeInput;
};

export type MutationDeleteEmployeeIdTypeByNodeIdArgs = {
  input: DeleteEmployeeIdTypeByNodeIdInput;
};

export type MutationDeleteEmployeeLevelArgs = {
  input: DeleteEmployeeLevelInput;
};

export type MutationDeleteEmployeeLevelByDisplayNameArgs = {
  input: DeleteEmployeeLevelByDisplayNameInput;
};

export type MutationDeleteEmployeeLevelByNodeIdArgs = {
  input: DeleteEmployeeLevelByNodeIdInput;
};

export type MutationDeleteEmployeeListCustomFilterArgs = {
  input: DeleteEmployeeListCustomFilterInput;
};

export type MutationDeleteEmployeeListCustomFilterByNodeIdArgs = {
  input: DeleteEmployeeListCustomFilterByNodeIdInput;
};

export type MutationDeleteEmployeeListCustomFilterRoleArgs = {
  input: DeleteEmployeeListCustomFilterRoleInput;
};

export type MutationDeleteEmployeeListCustomFilterRoleByEmployeeListCustomFilterIdAndRoleNameArgs = {
  input: DeleteEmployeeListCustomFilterRoleByEmployeeListCustomFilterIdAndRoleNameInput;
};

export type MutationDeleteEmployeeListCustomFilterRoleByNodeIdArgs = {
  input: DeleteEmployeeListCustomFilterRoleByNodeIdInput;
};

export type MutationDeleteEmployeeResignationReasonArgs = {
  input: DeleteEmployeeResignationReasonInput;
};

export type MutationDeleteEmployeeResignationReasonByDisplayNameArgs = {
  input: DeleteEmployeeResignationReasonByDisplayNameInput;
};

export type MutationDeleteEmployeeResignationReasonByNodeIdArgs = {
  input: DeleteEmployeeResignationReasonByNodeIdInput;
};

export type MutationDeleteEmployeeResignationTypeArgs = {
  input: DeleteEmployeeResignationTypeInput;
};

export type MutationDeleteEmployeeResignationTypeByDisplayNameArgs = {
  input: DeleteEmployeeResignationTypeByDisplayNameInput;
};

export type MutationDeleteEmployeeResignationTypeByNodeIdArgs = {
  input: DeleteEmployeeResignationTypeByNodeIdInput;
};

export type MutationDeleteEmployeeTpHistoryArgs = {
  input: DeleteEmployeeTpHistoryInput;
};

export type MutationDeleteEmployeeTpHistoryByEmployeeIdAndEffectiveDateArgs = {
  input: DeleteEmployeeTpHistoryByEmployeeIdAndEffectiveDateInput;
};

export type MutationDeleteEmployeeTpHistoryByNodeIdArgs = {
  input: DeleteEmployeeTpHistoryByNodeIdInput;
};

export type MutationDeleteExportEmployeeStatusArgs = {
  input: DeleteExportEmployeeStatusInput;
};

export type MutationDeleteExportEmployeeStatusByNodeIdArgs = {
  input: DeleteExportEmployeeStatusByNodeIdInput;
};

export type MutationDeleteExportEmployeeStatusByUuid4Args = {
  input: DeleteExportEmployeeStatusByUuid4Input;
};

export type MutationDeleteExternalAccountEmployeeArgs = {
  input: DeleteExternalAccountEmployeeInput;
};

export type MutationDeleteExternalAccountEmployeeByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKeyArgs = {
  input: DeleteExternalAccountEmployeeByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKeyInput;
};

export type MutationDeleteExternalAccountEmployeeByNodeIdArgs = {
  input: DeleteExternalAccountEmployeeByNodeIdInput;
};

export type MutationDeleteExternalAccountTypeArgs = {
  input: DeleteExternalAccountTypeInput;
};

export type MutationDeleteExternalAccountTypeByNameArgs = {
  input: DeleteExternalAccountTypeByNameInput;
};

export type MutationDeleteExternalAccountTypeByNodeIdArgs = {
  input: DeleteExternalAccountTypeByNodeIdInput;
};

export type MutationDeleteGoogleWorkspaceOrgUnitArgs = {
  input: DeleteGoogleWorkspaceOrgUnitInput;
};

export type MutationDeleteGoogleWorkspaceOrgUnitByCompanyIdAndBusinessUnitIdArgs = {
  input: DeleteGoogleWorkspaceOrgUnitByCompanyIdAndBusinessUnitIdInput;
};

export type MutationDeleteGoogleWorkspaceOrgUnitByNodeIdArgs = {
  input: DeleteGoogleWorkspaceOrgUnitByNodeIdInput;
};

export type MutationDeleteImportEmployeeStatusArgs = {
  input: DeleteImportEmployeeStatusInput;
};

export type MutationDeleteImportEmployeeStatusByNodeIdArgs = {
  input: DeleteImportEmployeeStatusByNodeIdInput;
};

export type MutationDeleteImportEmployeeStatusByUuid4Args = {
  input: DeleteImportEmployeeStatusByUuid4Input;
};

export type MutationDeleteOfficeArgs = {
  input: DeleteOfficeInput;
};

export type MutationDeleteOfficeByCityIdAndNameArgs = {
  input: DeleteOfficeByCityIdAndNameInput;
};

export type MutationDeleteOfficeByNodeIdArgs = {
  input: DeleteOfficeByNodeIdInput;
};

export type MutationDeletePerformanceReviewEvaluationArgs = {
  input: DeletePerformanceReviewEvaluationInput;
};

export type MutationDeletePerformanceReviewEvaluationByNodeIdArgs = {
  input: DeletePerformanceReviewEvaluationByNodeIdInput;
};

export type MutationDeletePerformanceReviewEvaluationCompetencyArgs = {
  input: DeletePerformanceReviewEvaluationCompetencyInput;
};

export type MutationDeletePerformanceReviewEvaluationCompetencyByNodeIdArgs = {
  input: DeletePerformanceReviewEvaluationCompetencyByNodeIdInput;
};

export type MutationDeletePerformanceReviewEvaluationCompetencyByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeIdArgs = {
  input: DeletePerformanceReviewEvaluationCompetencyByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeIdInput;
};

export type MutationDeletePerformanceReviewEvaluationEvaluatorArgs = {
  input: DeletePerformanceReviewEvaluationEvaluatorInput;
};

export type MutationDeletePerformanceReviewEvaluationEvaluatorByNodeIdArgs = {
  input: DeletePerformanceReviewEvaluationEvaluatorByNodeIdInput;
};

export type MutationDeletePerformanceReviewEvaluationEvaluatorByPerformanceReviewEvaluationIdAndEvaluatorIdArgs = {
  input: DeletePerformanceReviewEvaluationEvaluatorByPerformanceReviewEvaluationIdAndEvaluatorIdInput;
};

export type MutationDeletePerformanceReviewEvaluationFeedbackArgs = {
  input: DeletePerformanceReviewEvaluationFeedbackInput;
};

export type MutationDeletePerformanceReviewEvaluationFeedbackByNodeIdArgs = {
  input: DeletePerformanceReviewEvaluationFeedbackByNodeIdInput;
};

export type MutationDeletePerformanceReviewEvaluationFeedbackByPerformanceReviewEvaluationIdAndFeedbackEmployeeIdArgs = {
  input: DeletePerformanceReviewEvaluationFeedbackByPerformanceReviewEvaluationIdAndFeedbackEmployeeIdInput;
};

export type MutationDeletePerformanceReviewEvaluationKpiAssessmentArgs = {
  input: DeletePerformanceReviewEvaluationKpiAssessmentInput;
};

export type MutationDeletePerformanceReviewEvaluationKpiAssessmentByNodeIdArgs = {
  input: DeletePerformanceReviewEvaluationKpiAssessmentByNodeIdInput;
};

export type MutationDeletePerformanceReviewEvaluationKpiAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeIdArgs = {
  input: DeletePerformanceReviewEvaluationKpiAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeIdInput;
};

export type MutationDeletePerformanceReviewEvaluationKpiDescriptionArgs = {
  input: DeletePerformanceReviewEvaluationKpiDescriptionInput;
};

export type MutationDeletePerformanceReviewEvaluationKpiDescriptionByNodeIdArgs = {
  input: DeletePerformanceReviewEvaluationKpiDescriptionByNodeIdInput;
};

export type MutationDeletePerformanceReviewEvaluationKpiDescriptionByPerformanceReviewEvaluationIdAndTitleArgs = {
  input: DeletePerformanceReviewEvaluationKpiDescriptionByPerformanceReviewEvaluationIdAndTitleInput;
};

export type MutationDeletePerformanceReviewEvaluationKpiResultArgs = {
  input: DeletePerformanceReviewEvaluationKpiResultInput;
};

export type MutationDeletePerformanceReviewEvaluationKpiResultByNodeIdArgs = {
  input: DeletePerformanceReviewEvaluationKpiResultByNodeIdInput;
};

export type MutationDeletePerformanceReviewEvaluationKpiResultByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetIdArgs = {
  input: DeletePerformanceReviewEvaluationKpiResultByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetIdInput;
};

export type MutationDeletePerformanceReviewEvaluationKpiTargetArgs = {
  input: DeletePerformanceReviewEvaluationKpiTargetInput;
};

export type MutationDeletePerformanceReviewEvaluationKpiTargetByNodeIdArgs = {
  input: DeletePerformanceReviewEvaluationKpiTargetByNodeIdInput;
};

export type MutationDeletePerformanceReviewEvaluationKpiTargetByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndNameArgs = {
  input: DeletePerformanceReviewEvaluationKpiTargetByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndNameInput;
};

export type MutationDeletePerformanceReviewEvaluationOneOnOneArgs = {
  input: DeletePerformanceReviewEvaluationOneOnOneInput;
};

export type MutationDeletePerformanceReviewEvaluationOneOnOneByNodeIdArgs = {
  input: DeletePerformanceReviewEvaluationOneOnOneByNodeIdInput;
};

export type MutationDeletePerformanceReviewEvaluationQualitativeAssessmentArgs = {
  input: DeletePerformanceReviewEvaluationQualitativeAssessmentInput;
};

export type MutationDeletePerformanceReviewEvaluationQualitativeAssessmentByNodeIdArgs = {
  input: DeletePerformanceReviewEvaluationQualitativeAssessmentByNodeIdInput;
};

export type MutationDeletePerformanceReviewEvaluationQualitativeAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeIdArgs = {
  input: DeletePerformanceReviewEvaluationQualitativeAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeIdInput;
};

export type MutationDeletePerformanceReviewEvaluationQualitativeDescriptionArgs = {
  input: DeletePerformanceReviewEvaluationQualitativeDescriptionInput;
};

export type MutationDeletePerformanceReviewEvaluationQualitativeDescriptionByNodeIdArgs = {
  input: DeletePerformanceReviewEvaluationQualitativeDescriptionByNodeIdInput;
};

export type MutationDeletePerformanceReviewEvaluationQualitativeDescriptionByPerformanceReviewEvaluationIdAndTitleArgs = {
  input: DeletePerformanceReviewEvaluationQualitativeDescriptionByPerformanceReviewEvaluationIdAndTitleInput;
};

export type MutationDeletePerformanceReviewEvaluationValueArgs = {
  input: DeletePerformanceReviewEvaluationValueInput;
};

export type MutationDeletePerformanceReviewEvaluationValueByNodeIdArgs = {
  input: DeletePerformanceReviewEvaluationValueByNodeIdInput;
};

export type MutationDeletePerformanceReviewEvaluationValueByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeIdArgs = {
  input: DeletePerformanceReviewEvaluationValueByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeIdInput;
};

export type MutationDeletePerformanceReviewMasterCompetencyArgs = {
  input: DeletePerformanceReviewMasterCompetencyInput;
};

export type MutationDeletePerformanceReviewMasterCompetencyByNodeIdArgs = {
  input: DeletePerformanceReviewMasterCompetencyByNodeIdInput;
};

export type MutationDeletePerformanceReviewMasterCompetencyCommonArgs = {
  input: DeletePerformanceReviewMasterCompetencyCommonInput;
};

export type MutationDeletePerformanceReviewMasterCompetencyCommonByNodeIdArgs = {
  input: DeletePerformanceReviewMasterCompetencyCommonByNodeIdInput;
};

export type MutationDeletePerformanceReviewMasterCompetencyCommonDescriptionArgs = {
  input: DeletePerformanceReviewMasterCompetencyCommonDescriptionInput;
};

export type MutationDeletePerformanceReviewMasterCompetencyCommonDescriptionByNodeIdArgs = {
  input: DeletePerformanceReviewMasterCompetencyCommonDescriptionByNodeIdInput;
};

export type MutationDeletePerformanceReviewMasterCompetencyCommonDescriptionByPerformanceReviewMasterCompetencyCommonIdAndLanguageArgs = {
  input: DeletePerformanceReviewMasterCompetencyCommonDescriptionByPerformanceReviewMasterCompetencyCommonIdAndLanguageInput;
};

export type MutationDeletePerformanceReviewMasterCompetencyDetailArgs = {
  input: DeletePerformanceReviewMasterCompetencyDetailInput;
};

export type MutationDeletePerformanceReviewMasterCompetencyDetailByNodeIdArgs = {
  input: DeletePerformanceReviewMasterCompetencyDetailByNodeIdInput;
};

export type MutationDeletePerformanceReviewMasterCompetencyDetailByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonIdArgs = {
  input: DeletePerformanceReviewMasterCompetencyDetailByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonIdInput;
};

export type MutationDeletePerformanceReviewMasterCompetencyDetailDescriptionArgs = {
  input: DeletePerformanceReviewMasterCompetencyDetailDescriptionInput;
};

export type MutationDeletePerformanceReviewMasterCompetencyDetailDescriptionByNodeIdArgs = {
  input: DeletePerformanceReviewMasterCompetencyDetailDescriptionByNodeIdInput;
};

export type MutationDeletePerformanceReviewMasterCompetencyDetailDescriptionByPerformanceReviewMasterCompetencyDetailIdAndLanguageArgs = {
  input: DeletePerformanceReviewMasterCompetencyDetailDescriptionByPerformanceReviewMasterCompetencyDetailIdAndLanguageInput;
};

export type MutationDeletePerformanceReviewMasterCompetencyRoleArgs = {
  input: DeletePerformanceReviewMasterCompetencyRoleInput;
};

export type MutationDeletePerformanceReviewMasterCompetencyRoleByNodeIdArgs = {
  input: DeletePerformanceReviewMasterCompetencyRoleByNodeIdInput;
};

export type MutationDeletePerformanceReviewMasterCompetencyRoleByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleNameArgs = {
  input: DeletePerformanceReviewMasterCompetencyRoleByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleNameInput;
};

export type MutationDeletePerformanceReviewMasterDefaultArgs = {
  input: DeletePerformanceReviewMasterDefaultInput;
};

export type MutationDeletePerformanceReviewMasterDefaultByNodeIdArgs = {
  input: DeletePerformanceReviewMasterDefaultByNodeIdInput;
};

export type MutationDeletePerformanceReviewMasterEvaluationViewerArgs = {
  input: DeletePerformanceReviewMasterEvaluationViewerInput;
};

export type MutationDeletePerformanceReviewMasterEvaluationViewerByEmployeeIdAndYearAndQuarterAndViewerEmployeeIdArgs = {
  input: DeletePerformanceReviewMasterEvaluationViewerByEmployeeIdAndYearAndQuarterAndViewerEmployeeIdInput;
};

export type MutationDeletePerformanceReviewMasterEvaluationViewerByNodeIdArgs = {
  input: DeletePerformanceReviewMasterEvaluationViewerByNodeIdInput;
};

export type MutationDeletePerformanceReviewMasterExcludedEmployeeArgs = {
  input: DeletePerformanceReviewMasterExcludedEmployeeInput;
};

export type MutationDeletePerformanceReviewMasterExcludedEmployeeByNodeIdArgs = {
  input: DeletePerformanceReviewMasterExcludedEmployeeByNodeIdInput;
};

export type MutationDeletePerformanceReviewMasterOneOnOneArgs = {
  input: DeletePerformanceReviewMasterOneOnOneInput;
};

export type MutationDeletePerformanceReviewMasterOneOnOneByNodeIdArgs = {
  input: DeletePerformanceReviewMasterOneOnOneByNodeIdInput;
};

export type MutationDeletePerformanceReviewMasterOneOnOneDetailArgs = {
  input: DeletePerformanceReviewMasterOneOnOneDetailInput;
};

export type MutationDeletePerformanceReviewMasterOneOnOneDetailByNodeIdArgs = {
  input: DeletePerformanceReviewMasterOneOnOneDetailByNodeIdInput;
};

export type MutationDeletePerformanceReviewMasterRatingArgs = {
  input: DeletePerformanceReviewMasterRatingInput;
};

export type MutationDeletePerformanceReviewMasterRatingByNodeIdArgs = {
  input: DeletePerformanceReviewMasterRatingByNodeIdInput;
};

export type MutationDeletePerformanceReviewMasterRatingDescriptionArgs = {
  input: DeletePerformanceReviewMasterRatingDescriptionInput;
};

export type MutationDeletePerformanceReviewMasterRatingDescriptionByNodeIdArgs = {
  input: DeletePerformanceReviewMasterRatingDescriptionByNodeIdInput;
};

export type MutationDeletePerformanceReviewMasterRatingDescriptionByPerformanceReviewMasterRatingIdAndLanguageArgs = {
  input: DeletePerformanceReviewMasterRatingDescriptionByPerformanceReviewMasterRatingIdAndLanguageInput;
};

export type MutationDeletePerformanceReviewMasterUsageArgs = {
  input: DeletePerformanceReviewMasterUsageInput;
};

export type MutationDeletePerformanceReviewMasterUsageByNodeIdArgs = {
  input: DeletePerformanceReviewMasterUsageByNodeIdInput;
};

export type MutationDeletePerformanceReviewMasterUsageByYearAndQuarterAndCompanyIdArgs = {
  input: DeletePerformanceReviewMasterUsageByYearAndQuarterAndCompanyIdInput;
};

export type MutationDeletePerformanceReviewMasterValueArgs = {
  input: DeletePerformanceReviewMasterValueInput;
};

export type MutationDeletePerformanceReviewMasterValueByNodeIdArgs = {
  input: DeletePerformanceReviewMasterValueByNodeIdInput;
};

export type MutationDeletePerformanceReviewMasterValueDescriptionArgs = {
  input: DeletePerformanceReviewMasterValueDescriptionInput;
};

export type MutationDeletePerformanceReviewMasterValueDescriptionByNodeIdArgs = {
  input: DeletePerformanceReviewMasterValueDescriptionByNodeIdInput;
};

export type MutationDeletePerformanceReviewMasterValueDescriptionByPerformanceReviewMasterValueIdAndLanguageArgs = {
  input: DeletePerformanceReviewMasterValueDescriptionByPerformanceReviewMasterValueIdAndLanguageInput;
};

export type MutationDeletePerformanceReviewMasterWeightingArgs = {
  input: DeletePerformanceReviewMasterWeightingInput;
};

export type MutationDeletePerformanceReviewMasterWeightingByNodeIdArgs = {
  input: DeletePerformanceReviewMasterWeightingByNodeIdInput;
};

export type MutationDeletePerformanceReviewMasterWeightingByYearAndQuarterAndCompanyIdArgs = {
  input: DeletePerformanceReviewMasterWeightingByYearAndQuarterAndCompanyIdInput;
};

export type MutationDeletePerformanceReviewMasterWeightingByYearAndQuarterAndNameArgs = {
  input: DeletePerformanceReviewMasterWeightingByYearAndQuarterAndNameInput;
};

export type MutationDeletePerformanceReviewMasterWeightingDetailArgs = {
  input: DeletePerformanceReviewMasterWeightingDetailInput;
};

export type MutationDeletePerformanceReviewMasterWeightingDetailByNodeIdArgs = {
  input: DeletePerformanceReviewMasterWeightingDetailByNodeIdInput;
};

export type MutationDeletePerformanceReviewMasterWeightingDetailByPerformanceReviewMasterWeightingIdAndEmployeeLevelIdArgs = {
  input: DeletePerformanceReviewMasterWeightingDetailByPerformanceReviewMasterWeightingIdAndEmployeeLevelIdInput;
};

export type MutationDeleteProbationReviewEnableArgs = {
  input: DeleteProbationReviewEnableInput;
};

export type MutationDeleteProbationReviewEnableByCompanyIdAndContractTypeIdArgs = {
  input: DeleteProbationReviewEnableByCompanyIdAndContractTypeIdInput;
};

export type MutationDeleteProbationReviewEnableByNodeIdArgs = {
  input: DeleteProbationReviewEnableByNodeIdInput;
};

export type MutationDeleteProbationReviewEvaluationArgs = {
  input: DeleteProbationReviewEvaluationInput;
};

export type MutationDeleteProbationReviewEvaluationByEmployeeIdAndReviewDatePlannedArgs = {
  input: DeleteProbationReviewEvaluationByEmployeeIdAndReviewDatePlannedInput;
};

export type MutationDeleteProbationReviewEvaluationByNodeIdArgs = {
  input: DeleteProbationReviewEvaluationByNodeIdInput;
};

export type MutationDeleteProbationReviewEvaluationResultItemArgs = {
  input: DeleteProbationReviewEvaluationResultItemInput;
};

export type MutationDeleteProbationReviewEvaluationResultItemByNodeIdArgs = {
  input: DeleteProbationReviewEvaluationResultItemByNodeIdInput;
};

export type MutationDeleteProbationReviewEvaluationResultItemByProbationReviewEvaluationIdAndProbationReviewMasterItemIdArgs = {
  input: DeleteProbationReviewEvaluationResultItemByProbationReviewEvaluationIdAndProbationReviewMasterItemIdInput;
};

export type MutationDeleteProbationReviewEvaluationResultScoreCompetencyArgs = {
  input: DeleteProbationReviewEvaluationResultScoreCompetencyInput;
};

export type MutationDeleteProbationReviewEvaluationResultScoreCompetencyByNodeIdArgs = {
  input: DeleteProbationReviewEvaluationResultScoreCompetencyByNodeIdInput;
};

export type MutationDeleteProbationReviewEvaluationResultScoreCompetencyByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyIdArgs = {
  input: DeleteProbationReviewEvaluationResultScoreCompetencyByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyIdInput;
};

export type MutationDeleteProbationReviewEvaluationResultScoreValueArgs = {
  input: DeleteProbationReviewEvaluationResultScoreValueInput;
};

export type MutationDeleteProbationReviewEvaluationResultScoreValueByNodeIdArgs = {
  input: DeleteProbationReviewEvaluationResultScoreValueByNodeIdInput;
};

export type MutationDeleteProbationReviewEvaluationResultScoreValueByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueIdArgs = {
  input: DeleteProbationReviewEvaluationResultScoreValueByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueIdInput;
};

export type MutationDeleteProbationReviewEvaluationReviewerArgs = {
  input: DeleteProbationReviewEvaluationReviewerInput;
};

export type MutationDeleteProbationReviewEvaluationReviewerByNodeIdArgs = {
  input: DeleteProbationReviewEvaluationReviewerByNodeIdInput;
};

export type MutationDeleteProbationReviewMasterItemArgs = {
  input: DeleteProbationReviewMasterItemInput;
};

export type MutationDeleteProbationReviewMasterItemByNodeIdArgs = {
  input: DeleteProbationReviewMasterItemByNodeIdInput;
};

export type MutationDeleteProbationReviewMasterItemByYearAndQuarterAndTitleAndInputUserArgs = {
  input: DeleteProbationReviewMasterItemByYearAndQuarterAndTitleAndInputUserInput;
};

export type MutationDeleteProbationReviewMasterItemDescriptionArgs = {
  input: DeleteProbationReviewMasterItemDescriptionInput;
};

export type MutationDeleteProbationReviewMasterItemDescriptionByNodeIdArgs = {
  input: DeleteProbationReviewMasterItemDescriptionByNodeIdInput;
};

export type MutationDeleteProbationReviewMasterItemDescriptionByProbationReviewMasterItemIdAndLanguageArgs = {
  input: DeleteProbationReviewMasterItemDescriptionByProbationReviewMasterItemIdAndLanguageInput;
};

export type MutationDeleteRelationBusinessUnitDivisionArgs = {
  input: DeleteRelationBusinessUnitDivisionInput;
};

export type MutationDeleteRelationBusinessUnitDivisionByNodeIdArgs = {
  input: DeleteRelationBusinessUnitDivisionByNodeIdInput;
};

export type MutationDeleteRelationBusinessUnitDivisionDepartmentArgs = {
  input: DeleteRelationBusinessUnitDivisionDepartmentInput;
};

export type MutationDeleteRelationBusinessUnitDivisionDepartmentByNodeIdArgs = {
  input: DeleteRelationBusinessUnitDivisionDepartmentByNodeIdInput;
};

export type MutationDeleteRelationCityCompanyArgs = {
  input: DeleteRelationCityCompanyInput;
};

export type MutationDeleteRelationCityCompanyByNodeIdArgs = {
  input: DeleteRelationCityCompanyByNodeIdInput;
};

export type MutationDeleteRelationCompanyBusinessUnitArgs = {
  input: DeleteRelationCompanyBusinessUnitInput;
};

export type MutationDeleteRelationCompanyBusinessUnitByNodeIdArgs = {
  input: DeleteRelationCompanyBusinessUnitByNodeIdInput;
};

export type MutationDeleteRelationCompanyGroupEmailDomainArgs = {
  input: DeleteRelationCompanyGroupEmailDomainInput;
};

export type MutationDeleteRelationCompanyGroupEmailDomainByNodeIdArgs = {
  input: DeleteRelationCompanyGroupEmailDomainByNodeIdInput;
};

export type MutationDeleteRelationCompanyOfficeArgs = {
  input: DeleteRelationCompanyOfficeInput;
};

export type MutationDeleteRelationCompanyOfficeByNodeIdArgs = {
  input: DeleteRelationCompanyOfficeByNodeIdInput;
};

export type MutationDeleteRelationCurrencyCountryArgs = {
  input: DeleteRelationCurrencyCountryInput;
};

export type MutationDeleteRelationCurrencyCountryByNodeIdArgs = {
  input: DeleteRelationCurrencyCountryByNodeIdInput;
};

export type MutationDeleteRelationEmployeeUserAccountArgs = {
  input: DeleteRelationEmployeeUserAccountInput;
};

export type MutationDeleteRelationEmployeeUserAccountByNodeIdArgs = {
  input: DeleteRelationEmployeeUserAccountByNodeIdInput;
};

export type MutationDeleteUserAccountArgs = {
  input: DeleteUserAccountInput;
};

export type MutationDeleteUserAccountAccessHistoryArgs = {
  input: DeleteUserAccountAccessHistoryInput;
};

export type MutationDeleteUserAccountAccessHistoryByNodeIdArgs = {
  input: DeleteUserAccountAccessHistoryByNodeIdInput;
};

export type MutationDeleteUserAccountByEmailAddressArgs = {
  input: DeleteUserAccountByEmailAddressInput;
};

export type MutationDeleteUserAccountByNodeIdArgs = {
  input: DeleteUserAccountByNodeIdInput;
};

export type MutationDeleteUserAccountPinArgs = {
  input: DeleteUserAccountPinInput;
};

export type MutationDeleteUserAccountPinByNodeIdArgs = {
  input: DeleteUserAccountPinByNodeIdInput;
};

export type MutationDeleteUserHrManagingCompanyArgs = {
  input: DeleteUserHrManagingCompanyInput;
};

export type MutationDeleteUserHrManagingCompanyByNodeIdArgs = {
  input: DeleteUserHrManagingCompanyByNodeIdInput;
};

export type MutationDeleteUserHrManagingCompanyByUserAccountIdAndCompanyIdArgs = {
  input: DeleteUserHrManagingCompanyByUserAccountIdAndCompanyIdInput;
};

export type MutationDeleteWorldPlaceArgs = {
  input: DeleteWorldPlaceInput;
};

export type MutationDeleteWorldPlaceByDisplayOrderArgs = {
  input: DeleteWorldPlaceByDisplayOrderInput;
};

export type MutationDeleteWorldPlaceByNodeIdArgs = {
  input: DeleteWorldPlaceByNodeIdInput;
};

export type MutationGetCurrentYearQuarterArgs = {
  input: GetCurrentYearQuarterInput;
};

export type MutationGetProbationReviewEvaluationReviewerUsageArgs = {
  input: GetProbationReviewEvaluationReviewerUsageInput;
};

export type MutationInitializeAllRolesArgs = {
  input: InitializeAllRolesInput;
};

export type MutationMergeBankArgs = {
  input: MergeBankInput;
};

export type MutationMergeBusinessUnitArgs = {
  input: MergeBusinessUnitInput;
};

export type MutationMergeCityArgs = {
  input: MergeCityInput;
};

export type MutationMergeCompanyArgs = {
  input: MergeCompanyInput;
};

export type MutationMergeCompanyGroupArgs = {
  input: MergeCompanyGroupInput;
};

export type MutationMergeContractTypeArgs = {
  input: MergeContractTypeInput;
};

export type MutationMergeCountryArgs = {
  input: MergeCountryInput;
};

export type MutationMergeCurrencyArgs = {
  input: MergeCurrencyInput;
};

export type MutationMergeDepartmentArgs = {
  input: MergeDepartmentInput;
};

export type MutationMergeDivisionArgs = {
  input: MergeDivisionInput;
};

export type MutationMergeEmployeeLevelArgs = {
  input: MergeEmployeeLevelInput;
};

export type MutationMergeEmployeeResignationReasonArgs = {
  input: MergeEmployeeResignationReasonInput;
};

export type MutationMergeEmployeeResignationTypeArgs = {
  input: MergeEmployeeResignationTypeInput;
};

export type MutationMergeOfficeArgs = {
  input: MergeOfficeInput;
};

export type MutationPerfReviewEvaluationKpiDescFixDisplayOrderArgs = {
  input: PerfReviewEvaluationKpiDescFixDisplayOrderInput;
};

export type MutationPerfReviewEvaluationQualitativeDescFixDisplayOrderArgs = {
  input: PerfReviewEvaluationQualitativeDescFixDisplayOrderInput;
};

export type MutationPerfReviewMasterCompetencyCommonFixDisplayOrderArgs = {
  input: PerfReviewMasterCompetencyCommonFixDisplayOrderInput;
};

export type MutationPerformanceReviewEvaluationLockAssessmentArgs = {
  input: PerformanceReviewEvaluationLockAssessmentInput;
};

export type MutationPerformanceReviewEvaluationLockDescriptionArgs = {
  input: PerformanceReviewEvaluationLockDescriptionInput;
};

export type MutationPerformanceReviewMasterCompetencyDetailFixDisplayOrderArgs = {
  input: PerformanceReviewMasterCompetencyDetailFixDisplayOrderInput;
};

export type MutationPerformanceReviewMasterOneOnOneFixDisplayOrderArgs = {
  input: PerformanceReviewMasterOneOnOneFixDisplayOrderInput;
};

export type MutationPerformanceReviewMasterValueFixDisplayOrderArgs = {
  input: PerformanceReviewMasterValueFixDisplayOrderInput;
};

export type MutationSwitchTriggerArgs = {
  input: SwitchTriggerInput;
};

export type MutationUpdateBankArgs = {
  input: UpdateBankInput;
};

export type MutationUpdateBankByCountryCodeAlpha3AndFullNameArgs = {
  input: UpdateBankByCountryCodeAlpha3AndFullNameInput;
};

export type MutationUpdateBankByNodeIdArgs = {
  input: UpdateBankByNodeIdInput;
};

export type MutationUpdateBankBySwiftCodeArgs = {
  input: UpdateBankBySwiftCodeInput;
};

export type MutationUpdateBusinessUnitArgs = {
  input: UpdateBusinessUnitInput;
};

export type MutationUpdateBusinessUnitByNameArgs = {
  input: UpdateBusinessUnitByNameInput;
};

export type MutationUpdateBusinessUnitByNodeIdArgs = {
  input: UpdateBusinessUnitByNodeIdInput;
};

export type MutationUpdateCityArgs = {
  input: UpdateCityInput;
};

export type MutationUpdateCityByCountryCodeAlpha2AndNameArgs = {
  input: UpdateCityByCountryCodeAlpha2AndNameInput;
};

export type MutationUpdateCityByNodeIdArgs = {
  input: UpdateCityByNodeIdInput;
};

export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};

export type MutationUpdateCompanyByAbbreviationArgs = {
  input: UpdateCompanyByAbbreviationInput;
};

export type MutationUpdateCompanyByCountryCodeAlpha2AndFullNameArgs = {
  input: UpdateCompanyByCountryCodeAlpha2AndFullNameInput;
};

export type MutationUpdateCompanyByNodeIdArgs = {
  input: UpdateCompanyByNodeIdInput;
};

export type MutationUpdateCompanyExternalAccountDisableArgs = {
  input: UpdateCompanyExternalAccountDisableInput;
};

export type MutationUpdateCompanyExternalAccountDisableByNodeIdArgs = {
  input: UpdateCompanyExternalAccountDisableByNodeIdInput;
};

export type MutationUpdateCompanyGlobalHrArgs = {
  input: UpdateCompanyGlobalHrInput;
};

export type MutationUpdateCompanyGlobalHrByEmployeeIdArgs = {
  input: UpdateCompanyGlobalHrByEmployeeIdInput;
};

export type MutationUpdateCompanyGlobalHrByNodeIdArgs = {
  input: UpdateCompanyGlobalHrByNodeIdInput;
};

export type MutationUpdateCompanyGroupArgs = {
  input: UpdateCompanyGroupInput;
};

export type MutationUpdateCompanyGroupByNameArgs = {
  input: UpdateCompanyGroupByNameInput;
};

export type MutationUpdateCompanyGroupByNodeIdArgs = {
  input: UpdateCompanyGroupByNodeIdInput;
};

export type MutationUpdateCompanyLocalHrArgs = {
  input: UpdateCompanyLocalHrInput;
};

export type MutationUpdateCompanyLocalHrByCompanyIdAndEmployeeIdArgs = {
  input: UpdateCompanyLocalHrByCompanyIdAndEmployeeIdInput;
};

export type MutationUpdateCompanyLocalHrByNodeIdArgs = {
  input: UpdateCompanyLocalHrByNodeIdInput;
};

export type MutationUpdateContractTypeArgs = {
  input: UpdateContractTypeInput;
};

export type MutationUpdateContractTypeByCodeArgs = {
  input: UpdateContractTypeByCodeInput;
};

export type MutationUpdateContractTypeByDisplayNameArgs = {
  input: UpdateContractTypeByDisplayNameInput;
};

export type MutationUpdateContractTypeByNodeIdArgs = {
  input: UpdateContractTypeByNodeIdInput;
};

export type MutationUpdateCountryArgs = {
  input: UpdateCountryInput;
};

export type MutationUpdateCountryByCountryCodeAlpha3Args = {
  input: UpdateCountryByCountryCodeAlpha3Input;
};

export type MutationUpdateCountryByCountryCodeNumericArgs = {
  input: UpdateCountryByCountryCodeNumericInput;
};

export type MutationUpdateCountryByDisplayOrderArgs = {
  input: UpdateCountryByDisplayOrderInput;
};

export type MutationUpdateCountryByNodeIdArgs = {
  input: UpdateCountryByNodeIdInput;
};

export type MutationUpdateCurrencyArgs = {
  input: UpdateCurrencyInput;
};

export type MutationUpdateCurrencyByNodeIdArgs = {
  input: UpdateCurrencyByNodeIdInput;
};

export type MutationUpdateDepartmentArgs = {
  input: UpdateDepartmentInput;
};

export type MutationUpdateDepartmentByNameArgs = {
  input: UpdateDepartmentByNameInput;
};

export type MutationUpdateDepartmentByNodeIdArgs = {
  input: UpdateDepartmentByNodeIdInput;
};

export type MutationUpdateDivisionArgs = {
  input: UpdateDivisionInput;
};

export type MutationUpdateDivisionByNameArgs = {
  input: UpdateDivisionByNameInput;
};

export type MutationUpdateDivisionByNodeIdArgs = {
  input: UpdateDivisionByNodeIdInput;
};

export type MutationUpdateEmailDomainArgs = {
  input: UpdateEmailDomainInput;
};

export type MutationUpdateEmailDomainByDomainNameArgs = {
  input: UpdateEmailDomainByDomainNameInput;
};

export type MutationUpdateEmailDomainByNodeIdArgs = {
  input: UpdateEmailDomainByNodeIdInput;
};

export type MutationUpdateEmployeeArgs = {
  input: UpdateEmployeeInput;
};

export type MutationUpdateEmployeeByCompanyEmailArgs = {
  input: UpdateEmployeeByCompanyEmailInput;
};

export type MutationUpdateEmployeeByNodeIdArgs = {
  input: UpdateEmployeeByNodeIdInput;
};

export type MutationUpdateEmployeeEmergencyContactArgs = {
  input: UpdateEmployeeEmergencyContactInput;
};

export type MutationUpdateEmployeeEmergencyContactByEmployeeIdAndRelationIdAndNameArgs = {
  input: UpdateEmployeeEmergencyContactByEmployeeIdAndRelationIdAndNameInput;
};

export type MutationUpdateEmployeeEmergencyContactByNodeIdArgs = {
  input: UpdateEmployeeEmergencyContactByNodeIdInput;
};

export type MutationUpdateEmployeeFileArgs = {
  input: UpdateEmployeeFileInput;
};

export type MutationUpdateEmployeeFileByNodeIdArgs = {
  input: UpdateEmployeeFileByNodeIdInput;
};

export type MutationUpdateEmployeeFileByS3PathArgs = {
  input: UpdateEmployeeFileByS3PathInput;
};

export type MutationUpdateEmployeeIdTypeArgs = {
  input: UpdateEmployeeIdTypeInput;
};

export type MutationUpdateEmployeeIdTypeByCodeArgs = {
  input: UpdateEmployeeIdTypeByCodeInput;
};

export type MutationUpdateEmployeeIdTypeByNodeIdArgs = {
  input: UpdateEmployeeIdTypeByNodeIdInput;
};

export type MutationUpdateEmployeeLevelArgs = {
  input: UpdateEmployeeLevelInput;
};

export type MutationUpdateEmployeeLevelByDisplayNameArgs = {
  input: UpdateEmployeeLevelByDisplayNameInput;
};

export type MutationUpdateEmployeeLevelByNodeIdArgs = {
  input: UpdateEmployeeLevelByNodeIdInput;
};

export type MutationUpdateEmployeeListCustomFilterArgs = {
  input: UpdateEmployeeListCustomFilterInput;
};

export type MutationUpdateEmployeeListCustomFilterByNodeIdArgs = {
  input: UpdateEmployeeListCustomFilterByNodeIdInput;
};

export type MutationUpdateEmployeeListCustomFilterRoleArgs = {
  input: UpdateEmployeeListCustomFilterRoleInput;
};

export type MutationUpdateEmployeeListCustomFilterRoleByEmployeeListCustomFilterIdAndRoleNameArgs = {
  input: UpdateEmployeeListCustomFilterRoleByEmployeeListCustomFilterIdAndRoleNameInput;
};

export type MutationUpdateEmployeeListCustomFilterRoleByNodeIdArgs = {
  input: UpdateEmployeeListCustomFilterRoleByNodeIdInput;
};

export type MutationUpdateEmployeeResignationReasonArgs = {
  input: UpdateEmployeeResignationReasonInput;
};

export type MutationUpdateEmployeeResignationReasonByDisplayNameArgs = {
  input: UpdateEmployeeResignationReasonByDisplayNameInput;
};

export type MutationUpdateEmployeeResignationReasonByNodeIdArgs = {
  input: UpdateEmployeeResignationReasonByNodeIdInput;
};

export type MutationUpdateEmployeeResignationTypeArgs = {
  input: UpdateEmployeeResignationTypeInput;
};

export type MutationUpdateEmployeeResignationTypeByDisplayNameArgs = {
  input: UpdateEmployeeResignationTypeByDisplayNameInput;
};

export type MutationUpdateEmployeeResignationTypeByNodeIdArgs = {
  input: UpdateEmployeeResignationTypeByNodeIdInput;
};

export type MutationUpdateEmployeeTpHistoryArgs = {
  input: UpdateEmployeeTpHistoryInput;
};

export type MutationUpdateEmployeeTpHistoryByEmployeeIdAndEffectiveDateArgs = {
  input: UpdateEmployeeTpHistoryByEmployeeIdAndEffectiveDateInput;
};

export type MutationUpdateEmployeeTpHistoryByNodeIdArgs = {
  input: UpdateEmployeeTpHistoryByNodeIdInput;
};

export type MutationUpdateExportEmployeeStatusArgs = {
  input: UpdateExportEmployeeStatusInput;
};

export type MutationUpdateExportEmployeeStatusByNodeIdArgs = {
  input: UpdateExportEmployeeStatusByNodeIdInput;
};

export type MutationUpdateExportEmployeeStatusByUuid4Args = {
  input: UpdateExportEmployeeStatusByUuid4Input;
};

export type MutationUpdateExternalAccountEmployeeArgs = {
  input: UpdateExternalAccountEmployeeInput;
};

export type MutationUpdateExternalAccountEmployeeByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKeyArgs = {
  input: UpdateExternalAccountEmployeeByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKeyInput;
};

export type MutationUpdateExternalAccountEmployeeByNodeIdArgs = {
  input: UpdateExternalAccountEmployeeByNodeIdInput;
};

export type MutationUpdateExternalAccountTypeArgs = {
  input: UpdateExternalAccountTypeInput;
};

export type MutationUpdateExternalAccountTypeByNameArgs = {
  input: UpdateExternalAccountTypeByNameInput;
};

export type MutationUpdateExternalAccountTypeByNodeIdArgs = {
  input: UpdateExternalAccountTypeByNodeIdInput;
};

export type MutationUpdateGoogleWorkspaceOrgUnitArgs = {
  input: UpdateGoogleWorkspaceOrgUnitInput;
};

export type MutationUpdateGoogleWorkspaceOrgUnitByCompanyIdAndBusinessUnitIdArgs = {
  input: UpdateGoogleWorkspaceOrgUnitByCompanyIdAndBusinessUnitIdInput;
};

export type MutationUpdateGoogleWorkspaceOrgUnitByNodeIdArgs = {
  input: UpdateGoogleWorkspaceOrgUnitByNodeIdInput;
};

export type MutationUpdateImportEmployeeStatusArgs = {
  input: UpdateImportEmployeeStatusInput;
};

export type MutationUpdateImportEmployeeStatusByNodeIdArgs = {
  input: UpdateImportEmployeeStatusByNodeIdInput;
};

export type MutationUpdateImportEmployeeStatusByUuid4Args = {
  input: UpdateImportEmployeeStatusByUuid4Input;
};

export type MutationUpdateLatestEmployeeTransferPromotionArgs = {
  input: UpdateLatestEmployeeTransferPromotionInput;
};

export type MutationUpdateOfficeArgs = {
  input: UpdateOfficeInput;
};

export type MutationUpdateOfficeByCityIdAndNameArgs = {
  input: UpdateOfficeByCityIdAndNameInput;
};

export type MutationUpdateOfficeByNodeIdArgs = {
  input: UpdateOfficeByNodeIdInput;
};

export type MutationUpdatePerformanceReviewEvaluationArgs = {
  input: UpdatePerformanceReviewEvaluationInput;
};

export type MutationUpdatePerformanceReviewEvaluationByNodeIdArgs = {
  input: UpdatePerformanceReviewEvaluationByNodeIdInput;
};

export type MutationUpdatePerformanceReviewEvaluationCompetencyArgs = {
  input: UpdatePerformanceReviewEvaluationCompetencyInput;
};

export type MutationUpdatePerformanceReviewEvaluationCompetencyByNodeIdArgs = {
  input: UpdatePerformanceReviewEvaluationCompetencyByNodeIdInput;
};

export type MutationUpdatePerformanceReviewEvaluationCompetencyByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeIdArgs = {
  input: UpdatePerformanceReviewEvaluationCompetencyByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeIdInput;
};

export type MutationUpdatePerformanceReviewEvaluationEvaluatorArgs = {
  input: UpdatePerformanceReviewEvaluationEvaluatorInput;
};

export type MutationUpdatePerformanceReviewEvaluationEvaluatorByNodeIdArgs = {
  input: UpdatePerformanceReviewEvaluationEvaluatorByNodeIdInput;
};

export type MutationUpdatePerformanceReviewEvaluationEvaluatorByPerformanceReviewEvaluationIdAndEvaluatorIdArgs = {
  input: UpdatePerformanceReviewEvaluationEvaluatorByPerformanceReviewEvaluationIdAndEvaluatorIdInput;
};

export type MutationUpdatePerformanceReviewEvaluationFeedbackArgs = {
  input: UpdatePerformanceReviewEvaluationFeedbackInput;
};

export type MutationUpdatePerformanceReviewEvaluationFeedbackByNodeIdArgs = {
  input: UpdatePerformanceReviewEvaluationFeedbackByNodeIdInput;
};

export type MutationUpdatePerformanceReviewEvaluationFeedbackByPerformanceReviewEvaluationIdAndFeedbackEmployeeIdArgs = {
  input: UpdatePerformanceReviewEvaluationFeedbackByPerformanceReviewEvaluationIdAndFeedbackEmployeeIdInput;
};

export type MutationUpdatePerformanceReviewEvaluationKpiAssessmentArgs = {
  input: UpdatePerformanceReviewEvaluationKpiAssessmentInput;
};

export type MutationUpdatePerformanceReviewEvaluationKpiAssessmentByNodeIdArgs = {
  input: UpdatePerformanceReviewEvaluationKpiAssessmentByNodeIdInput;
};

export type MutationUpdatePerformanceReviewEvaluationKpiAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeIdArgs = {
  input: UpdatePerformanceReviewEvaluationKpiAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeIdInput;
};

export type MutationUpdatePerformanceReviewEvaluationKpiDescriptionArgs = {
  input: UpdatePerformanceReviewEvaluationKpiDescriptionInput;
};

export type MutationUpdatePerformanceReviewEvaluationKpiDescriptionByNodeIdArgs = {
  input: UpdatePerformanceReviewEvaluationKpiDescriptionByNodeIdInput;
};

export type MutationUpdatePerformanceReviewEvaluationKpiDescriptionByPerformanceReviewEvaluationIdAndTitleArgs = {
  input: UpdatePerformanceReviewEvaluationKpiDescriptionByPerformanceReviewEvaluationIdAndTitleInput;
};

export type MutationUpdatePerformanceReviewEvaluationKpiResultArgs = {
  input: UpdatePerformanceReviewEvaluationKpiResultInput;
};

export type MutationUpdatePerformanceReviewEvaluationKpiResultByNodeIdArgs = {
  input: UpdatePerformanceReviewEvaluationKpiResultByNodeIdInput;
};

export type MutationUpdatePerformanceReviewEvaluationKpiResultByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetIdArgs = {
  input: UpdatePerformanceReviewEvaluationKpiResultByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetIdInput;
};

export type MutationUpdatePerformanceReviewEvaluationKpiTargetArgs = {
  input: UpdatePerformanceReviewEvaluationKpiTargetInput;
};

export type MutationUpdatePerformanceReviewEvaluationKpiTargetByNodeIdArgs = {
  input: UpdatePerformanceReviewEvaluationKpiTargetByNodeIdInput;
};

export type MutationUpdatePerformanceReviewEvaluationKpiTargetByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndNameArgs = {
  input: UpdatePerformanceReviewEvaluationKpiTargetByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndNameInput;
};

export type MutationUpdatePerformanceReviewEvaluationOneOnOneArgs = {
  input: UpdatePerformanceReviewEvaluationOneOnOneInput;
};

export type MutationUpdatePerformanceReviewEvaluationOneOnOneByNodeIdArgs = {
  input: UpdatePerformanceReviewEvaluationOneOnOneByNodeIdInput;
};

export type MutationUpdatePerformanceReviewEvaluationQualitativeAssessmentArgs = {
  input: UpdatePerformanceReviewEvaluationQualitativeAssessmentInput;
};

export type MutationUpdatePerformanceReviewEvaluationQualitativeAssessmentByNodeIdArgs = {
  input: UpdatePerformanceReviewEvaluationQualitativeAssessmentByNodeIdInput;
};

export type MutationUpdatePerformanceReviewEvaluationQualitativeAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeIdArgs = {
  input: UpdatePerformanceReviewEvaluationQualitativeAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeIdInput;
};

export type MutationUpdatePerformanceReviewEvaluationQualitativeDescriptionArgs = {
  input: UpdatePerformanceReviewEvaluationQualitativeDescriptionInput;
};

export type MutationUpdatePerformanceReviewEvaluationQualitativeDescriptionByNodeIdArgs = {
  input: UpdatePerformanceReviewEvaluationQualitativeDescriptionByNodeIdInput;
};

export type MutationUpdatePerformanceReviewEvaluationQualitativeDescriptionByPerformanceReviewEvaluationIdAndTitleArgs = {
  input: UpdatePerformanceReviewEvaluationQualitativeDescriptionByPerformanceReviewEvaluationIdAndTitleInput;
};

export type MutationUpdatePerformanceReviewEvaluationValueArgs = {
  input: UpdatePerformanceReviewEvaluationValueInput;
};

export type MutationUpdatePerformanceReviewEvaluationValueByNodeIdArgs = {
  input: UpdatePerformanceReviewEvaluationValueByNodeIdInput;
};

export type MutationUpdatePerformanceReviewEvaluationValueByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeIdArgs = {
  input: UpdatePerformanceReviewEvaluationValueByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeIdInput;
};

export type MutationUpdatePerformanceReviewMasterCompetencyArgs = {
  input: UpdatePerformanceReviewMasterCompetencyInput;
};

export type MutationUpdatePerformanceReviewMasterCompetencyByNodeIdArgs = {
  input: UpdatePerformanceReviewMasterCompetencyByNodeIdInput;
};

export type MutationUpdatePerformanceReviewMasterCompetencyCommonArgs = {
  input: UpdatePerformanceReviewMasterCompetencyCommonInput;
};

export type MutationUpdatePerformanceReviewMasterCompetencyCommonByNodeIdArgs = {
  input: UpdatePerformanceReviewMasterCompetencyCommonByNodeIdInput;
};

export type MutationUpdatePerformanceReviewMasterCompetencyCommonDescriptionArgs = {
  input: UpdatePerformanceReviewMasterCompetencyCommonDescriptionInput;
};

export type MutationUpdatePerformanceReviewMasterCompetencyCommonDescriptionByNodeIdArgs = {
  input: UpdatePerformanceReviewMasterCompetencyCommonDescriptionByNodeIdInput;
};

export type MutationUpdatePerformanceReviewMasterCompetencyCommonDescriptionByPerformanceReviewMasterCompetencyCommonIdAndLanguageArgs = {
  input: UpdatePerformanceReviewMasterCompetencyCommonDescriptionByPerformanceReviewMasterCompetencyCommonIdAndLanguageInput;
};

export type MutationUpdatePerformanceReviewMasterCompetencyDetailArgs = {
  input: UpdatePerformanceReviewMasterCompetencyDetailInput;
};

export type MutationUpdatePerformanceReviewMasterCompetencyDetailByNodeIdArgs = {
  input: UpdatePerformanceReviewMasterCompetencyDetailByNodeIdInput;
};

export type MutationUpdatePerformanceReviewMasterCompetencyDetailByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonIdArgs = {
  input: UpdatePerformanceReviewMasterCompetencyDetailByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonIdInput;
};

export type MutationUpdatePerformanceReviewMasterCompetencyDetailDescriptionArgs = {
  input: UpdatePerformanceReviewMasterCompetencyDetailDescriptionInput;
};

export type MutationUpdatePerformanceReviewMasterCompetencyDetailDescriptionByNodeIdArgs = {
  input: UpdatePerformanceReviewMasterCompetencyDetailDescriptionByNodeIdInput;
};

export type MutationUpdatePerformanceReviewMasterCompetencyDetailDescriptionByPerformanceReviewMasterCompetencyDetailIdAndLanguageArgs = {
  input: UpdatePerformanceReviewMasterCompetencyDetailDescriptionByPerformanceReviewMasterCompetencyDetailIdAndLanguageInput;
};

export type MutationUpdatePerformanceReviewMasterCompetencyRoleArgs = {
  input: UpdatePerformanceReviewMasterCompetencyRoleInput;
};

export type MutationUpdatePerformanceReviewMasterCompetencyRoleByNodeIdArgs = {
  input: UpdatePerformanceReviewMasterCompetencyRoleByNodeIdInput;
};

export type MutationUpdatePerformanceReviewMasterCompetencyRoleByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleNameArgs = {
  input: UpdatePerformanceReviewMasterCompetencyRoleByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleNameInput;
};

export type MutationUpdatePerformanceReviewMasterDefaultArgs = {
  input: UpdatePerformanceReviewMasterDefaultInput;
};

export type MutationUpdatePerformanceReviewMasterDefaultByNodeIdArgs = {
  input: UpdatePerformanceReviewMasterDefaultByNodeIdInput;
};

export type MutationUpdatePerformanceReviewMasterEvaluationViewerArgs = {
  input: UpdatePerformanceReviewMasterEvaluationViewerInput;
};

export type MutationUpdatePerformanceReviewMasterEvaluationViewerByEmployeeIdAndYearAndQuarterAndViewerEmployeeIdArgs = {
  input: UpdatePerformanceReviewMasterEvaluationViewerByEmployeeIdAndYearAndQuarterAndViewerEmployeeIdInput;
};

export type MutationUpdatePerformanceReviewMasterEvaluationViewerByNodeIdArgs = {
  input: UpdatePerformanceReviewMasterEvaluationViewerByNodeIdInput;
};

export type MutationUpdatePerformanceReviewMasterExcludedEmployeeArgs = {
  input: UpdatePerformanceReviewMasterExcludedEmployeeInput;
};

export type MutationUpdatePerformanceReviewMasterExcludedEmployeeByNodeIdArgs = {
  input: UpdatePerformanceReviewMasterExcludedEmployeeByNodeIdInput;
};

export type MutationUpdatePerformanceReviewMasterOneOnOneArgs = {
  input: UpdatePerformanceReviewMasterOneOnOneInput;
};

export type MutationUpdatePerformanceReviewMasterOneOnOneByNodeIdArgs = {
  input: UpdatePerformanceReviewMasterOneOnOneByNodeIdInput;
};

export type MutationUpdatePerformanceReviewMasterOneOnOneDetailArgs = {
  input: UpdatePerformanceReviewMasterOneOnOneDetailInput;
};

export type MutationUpdatePerformanceReviewMasterOneOnOneDetailByNodeIdArgs = {
  input: UpdatePerformanceReviewMasterOneOnOneDetailByNodeIdInput;
};

export type MutationUpdatePerformanceReviewMasterRatingArgs = {
  input: UpdatePerformanceReviewMasterRatingInput;
};

export type MutationUpdatePerformanceReviewMasterRatingByNodeIdArgs = {
  input: UpdatePerformanceReviewMasterRatingByNodeIdInput;
};

export type MutationUpdatePerformanceReviewMasterRatingDescriptionArgs = {
  input: UpdatePerformanceReviewMasterRatingDescriptionInput;
};

export type MutationUpdatePerformanceReviewMasterRatingDescriptionByNodeIdArgs = {
  input: UpdatePerformanceReviewMasterRatingDescriptionByNodeIdInput;
};

export type MutationUpdatePerformanceReviewMasterRatingDescriptionByPerformanceReviewMasterRatingIdAndLanguageArgs = {
  input: UpdatePerformanceReviewMasterRatingDescriptionByPerformanceReviewMasterRatingIdAndLanguageInput;
};

export type MutationUpdatePerformanceReviewMasterUsageArgs = {
  input: UpdatePerformanceReviewMasterUsageInput;
};

export type MutationUpdatePerformanceReviewMasterUsageByNodeIdArgs = {
  input: UpdatePerformanceReviewMasterUsageByNodeIdInput;
};

export type MutationUpdatePerformanceReviewMasterUsageByYearAndQuarterAndCompanyIdArgs = {
  input: UpdatePerformanceReviewMasterUsageByYearAndQuarterAndCompanyIdInput;
};

export type MutationUpdatePerformanceReviewMasterValueArgs = {
  input: UpdatePerformanceReviewMasterValueInput;
};

export type MutationUpdatePerformanceReviewMasterValueByNodeIdArgs = {
  input: UpdatePerformanceReviewMasterValueByNodeIdInput;
};

export type MutationUpdatePerformanceReviewMasterValueDescriptionArgs = {
  input: UpdatePerformanceReviewMasterValueDescriptionInput;
};

export type MutationUpdatePerformanceReviewMasterValueDescriptionByNodeIdArgs = {
  input: UpdatePerformanceReviewMasterValueDescriptionByNodeIdInput;
};

export type MutationUpdatePerformanceReviewMasterValueDescriptionByPerformanceReviewMasterValueIdAndLanguageArgs = {
  input: UpdatePerformanceReviewMasterValueDescriptionByPerformanceReviewMasterValueIdAndLanguageInput;
};

export type MutationUpdatePerformanceReviewMasterWeightingArgs = {
  input: UpdatePerformanceReviewMasterWeightingInput;
};

export type MutationUpdatePerformanceReviewMasterWeightingByNodeIdArgs = {
  input: UpdatePerformanceReviewMasterWeightingByNodeIdInput;
};

export type MutationUpdatePerformanceReviewMasterWeightingByYearAndQuarterAndCompanyIdArgs = {
  input: UpdatePerformanceReviewMasterWeightingByYearAndQuarterAndCompanyIdInput;
};

export type MutationUpdatePerformanceReviewMasterWeightingByYearAndQuarterAndNameArgs = {
  input: UpdatePerformanceReviewMasterWeightingByYearAndQuarterAndNameInput;
};

export type MutationUpdatePerformanceReviewMasterWeightingDetailArgs = {
  input: UpdatePerformanceReviewMasterWeightingDetailInput;
};

export type MutationUpdatePerformanceReviewMasterWeightingDetailByNodeIdArgs = {
  input: UpdatePerformanceReviewMasterWeightingDetailByNodeIdInput;
};

export type MutationUpdatePerformanceReviewMasterWeightingDetailByPerformanceReviewMasterWeightingIdAndEmployeeLevelIdArgs = {
  input: UpdatePerformanceReviewMasterWeightingDetailByPerformanceReviewMasterWeightingIdAndEmployeeLevelIdInput;
};

export type MutationUpdateProbationReviewEnableArgs = {
  input: UpdateProbationReviewEnableInput;
};

export type MutationUpdateProbationReviewEnableByCompanyIdAndContractTypeIdArgs = {
  input: UpdateProbationReviewEnableByCompanyIdAndContractTypeIdInput;
};

export type MutationUpdateProbationReviewEnableByNodeIdArgs = {
  input: UpdateProbationReviewEnableByNodeIdInput;
};

export type MutationUpdateProbationReviewEvaluationArgs = {
  input: UpdateProbationReviewEvaluationInput;
};

export type MutationUpdateProbationReviewEvaluationByEmployeeIdAndReviewDatePlannedArgs = {
  input: UpdateProbationReviewEvaluationByEmployeeIdAndReviewDatePlannedInput;
};

export type MutationUpdateProbationReviewEvaluationByNodeIdArgs = {
  input: UpdateProbationReviewEvaluationByNodeIdInput;
};

export type MutationUpdateProbationReviewEvaluationResultItemArgs = {
  input: UpdateProbationReviewEvaluationResultItemInput;
};

export type MutationUpdateProbationReviewEvaluationResultItemByNodeIdArgs = {
  input: UpdateProbationReviewEvaluationResultItemByNodeIdInput;
};

export type MutationUpdateProbationReviewEvaluationResultItemByProbationReviewEvaluationIdAndProbationReviewMasterItemIdArgs = {
  input: UpdateProbationReviewEvaluationResultItemByProbationReviewEvaluationIdAndProbationReviewMasterItemIdInput;
};

export type MutationUpdateProbationReviewEvaluationResultScoreCompetencyArgs = {
  input: UpdateProbationReviewEvaluationResultScoreCompetencyInput;
};

export type MutationUpdateProbationReviewEvaluationResultScoreCompetencyByNodeIdArgs = {
  input: UpdateProbationReviewEvaluationResultScoreCompetencyByNodeIdInput;
};

export type MutationUpdateProbationReviewEvaluationResultScoreCompetencyByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyIdArgs = {
  input: UpdateProbationReviewEvaluationResultScoreCompetencyByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyIdInput;
};

export type MutationUpdateProbationReviewEvaluationResultScoreValueArgs = {
  input: UpdateProbationReviewEvaluationResultScoreValueInput;
};

export type MutationUpdateProbationReviewEvaluationResultScoreValueByNodeIdArgs = {
  input: UpdateProbationReviewEvaluationResultScoreValueByNodeIdInput;
};

export type MutationUpdateProbationReviewEvaluationResultScoreValueByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueIdArgs = {
  input: UpdateProbationReviewEvaluationResultScoreValueByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueIdInput;
};

export type MutationUpdateProbationReviewMasterItemArgs = {
  input: UpdateProbationReviewMasterItemInput;
};

export type MutationUpdateProbationReviewMasterItemByNodeIdArgs = {
  input: UpdateProbationReviewMasterItemByNodeIdInput;
};

export type MutationUpdateProbationReviewMasterItemByYearAndQuarterAndTitleAndInputUserArgs = {
  input: UpdateProbationReviewMasterItemByYearAndQuarterAndTitleAndInputUserInput;
};

export type MutationUpdateProbationReviewMasterItemDescriptionArgs = {
  input: UpdateProbationReviewMasterItemDescriptionInput;
};

export type MutationUpdateProbationReviewMasterItemDescriptionByNodeIdArgs = {
  input: UpdateProbationReviewMasterItemDescriptionByNodeIdInput;
};

export type MutationUpdateProbationReviewMasterItemDescriptionByProbationReviewMasterItemIdAndLanguageArgs = {
  input: UpdateProbationReviewMasterItemDescriptionByProbationReviewMasterItemIdAndLanguageInput;
};

export type MutationUpdateRelationBusinessUnitDivisionArgs = {
  input: UpdateRelationBusinessUnitDivisionInput;
};

export type MutationUpdateRelationBusinessUnitDivisionByNodeIdArgs = {
  input: UpdateRelationBusinessUnitDivisionByNodeIdInput;
};

export type MutationUpdateRelationBusinessUnitDivisionDepartmentArgs = {
  input: UpdateRelationBusinessUnitDivisionDepartmentInput;
};

export type MutationUpdateRelationBusinessUnitDivisionDepartmentByNodeIdArgs = {
  input: UpdateRelationBusinessUnitDivisionDepartmentByNodeIdInput;
};

export type MutationUpdateRelationCityCompanyArgs = {
  input: UpdateRelationCityCompanyInput;
};

export type MutationUpdateRelationCityCompanyByNodeIdArgs = {
  input: UpdateRelationCityCompanyByNodeIdInput;
};

export type MutationUpdateRelationCompanyBusinessUnitArgs = {
  input: UpdateRelationCompanyBusinessUnitInput;
};

export type MutationUpdateRelationCompanyBusinessUnitByNodeIdArgs = {
  input: UpdateRelationCompanyBusinessUnitByNodeIdInput;
};

export type MutationUpdateRelationCompanyGroupEmailDomainArgs = {
  input: UpdateRelationCompanyGroupEmailDomainInput;
};

export type MutationUpdateRelationCompanyGroupEmailDomainByNodeIdArgs = {
  input: UpdateRelationCompanyGroupEmailDomainByNodeIdInput;
};

export type MutationUpdateRelationCompanyOfficeArgs = {
  input: UpdateRelationCompanyOfficeInput;
};

export type MutationUpdateRelationCompanyOfficeByNodeIdArgs = {
  input: UpdateRelationCompanyOfficeByNodeIdInput;
};

export type MutationUpdateRelationCurrencyCountryArgs = {
  input: UpdateRelationCurrencyCountryInput;
};

export type MutationUpdateRelationCurrencyCountryByNodeIdArgs = {
  input: UpdateRelationCurrencyCountryByNodeIdInput;
};

export type MutationUpdateRelationEmployeeUserAccountArgs = {
  input: UpdateRelationEmployeeUserAccountInput;
};

export type MutationUpdateRelationEmployeeUserAccountByNodeIdArgs = {
  input: UpdateRelationEmployeeUserAccountByNodeIdInput;
};

export type MutationUpdateUserAccountArgs = {
  input: UpdateUserAccountInput;
};

export type MutationUpdateUserAccountAccessHistoryArgs = {
  input: UpdateUserAccountAccessHistoryInput;
};

export type MutationUpdateUserAccountAccessHistoryByNodeIdArgs = {
  input: UpdateUserAccountAccessHistoryByNodeIdInput;
};

export type MutationUpdateUserAccountByEmailAddressArgs = {
  input: UpdateUserAccountByEmailAddressInput;
};

export type MutationUpdateUserAccountByNodeIdArgs = {
  input: UpdateUserAccountByNodeIdInput;
};

export type MutationUpdateUserAccountPinArgs = {
  input: UpdateUserAccountPinInput;
};

export type MutationUpdateUserAccountPinByNodeIdArgs = {
  input: UpdateUserAccountPinByNodeIdInput;
};

export type MutationUpdateUserHrManagingCompanyArgs = {
  input: UpdateUserHrManagingCompanyInput;
};

export type MutationUpdateUserHrManagingCompanyByNodeIdArgs = {
  input: UpdateUserHrManagingCompanyByNodeIdInput;
};

export type MutationUpdateUserHrManagingCompanyByUserAccountIdAndCompanyIdArgs = {
  input: UpdateUserHrManagingCompanyByUserAccountIdAndCompanyIdInput;
};

export type MutationUpdateWorldPlaceArgs = {
  input: UpdateWorldPlaceInput;
};

export type MutationUpdateWorldPlaceByDisplayOrderArgs = {
  input: UpdateWorldPlaceByDisplayOrderInput;
};

export type MutationUpdateWorldPlaceByNodeIdArgs = {
  input: UpdateWorldPlaceByNodeIdInput;
};

export type MutationUpsertBankByBankIdArgs = {
  input: UpsertBankByBankIdInput;
};

export type MutationUpsertBankByCountryCodeAlpha3AndFullNameArgs = {
  input: UpsertBankByCountryCodeAlpha3AndFullNameInput;
};

export type MutationUpsertBankBySwiftCodeArgs = {
  input: UpsertBankBySwiftCodeInput;
};

export type MutationUpsertBusinessUnitByBusinessUnitIdArgs = {
  input: UpsertBusinessUnitByBusinessUnitIdInput;
};

export type MutationUpsertBusinessUnitByNameArgs = {
  input: UpsertBusinessUnitByNameInput;
};

export type MutationUpsertCityByCityIdArgs = {
  input: UpsertCityByCityIdInput;
};

export type MutationUpsertCityByCountryCodeAlpha2AndNameArgs = {
  input: UpsertCityByCountryCodeAlpha2AndNameInput;
};

export type MutationUpsertCompanyByAbbreviationArgs = {
  input: UpsertCompanyByAbbreviationInput;
};

export type MutationUpsertCompanyByCompanyIdArgs = {
  input: UpsertCompanyByCompanyIdInput;
};

export type MutationUpsertCompanyByCountryCodeAlpha2AndFullNameArgs = {
  input: UpsertCompanyByCountryCodeAlpha2AndFullNameInput;
};

export type MutationUpsertCompanyGlobalHrByCompanyGlobalHrIdArgs = {
  input: UpsertCompanyGlobalHrByCompanyGlobalHrIdInput;
};

export type MutationUpsertCompanyGlobalHrByEmployeeIdArgs = {
  input: UpsertCompanyGlobalHrByEmployeeIdInput;
};

export type MutationUpsertCompanyGroupByCompanyGroupIdArgs = {
  input: UpsertCompanyGroupByCompanyGroupIdInput;
};

export type MutationUpsertCompanyGroupByNameArgs = {
  input: UpsertCompanyGroupByNameInput;
};

export type MutationUpsertCompanyLocalHrByCompanyIdAndEmployeeIdArgs = {
  input: UpsertCompanyLocalHrByCompanyIdAndEmployeeIdInput;
};

export type MutationUpsertCompanyLocalHrByCompanyLocalHrIdArgs = {
  input: UpsertCompanyLocalHrByCompanyLocalHrIdInput;
};

export type MutationUpsertContractTypeByCodeArgs = {
  input: UpsertContractTypeByCodeInput;
};

export type MutationUpsertContractTypeByContractTypeIdArgs = {
  input: UpsertContractTypeByContractTypeIdInput;
};

export type MutationUpsertContractTypeByDisplayNameArgs = {
  input: UpsertContractTypeByDisplayNameInput;
};

export type MutationUpsertCountryByCountryCodeAlpha2Args = {
  input: UpsertCountryByCountryCodeAlpha2Input;
};

export type MutationUpsertCountryByCountryCodeAlpha3Args = {
  input: UpsertCountryByCountryCodeAlpha3Input;
};

export type MutationUpsertCountryByCountryCodeNumericArgs = {
  input: UpsertCountryByCountryCodeNumericInput;
};

export type MutationUpsertCountryByDisplayOrderArgs = {
  input: UpsertCountryByDisplayOrderInput;
};

export type MutationUpsertCurrencyByCurrencyCodeArgs = {
  input: UpsertCurrencyByCurrencyCodeInput;
};

export type MutationUpsertDepartmentByDepartmentIdArgs = {
  input: UpsertDepartmentByDepartmentIdInput;
};

export type MutationUpsertDepartmentByNameArgs = {
  input: UpsertDepartmentByNameInput;
};

export type MutationUpsertDivisionByDivisionIdArgs = {
  input: UpsertDivisionByDivisionIdInput;
};

export type MutationUpsertDivisionByNameArgs = {
  input: UpsertDivisionByNameInput;
};

export type MutationUpsertEmailDomainByDomainNameArgs = {
  input: UpsertEmailDomainByDomainNameInput;
};

export type MutationUpsertEmailDomainByEmailDomainIdArgs = {
  input: UpsertEmailDomainByEmailDomainIdInput;
};

export type MutationUpsertEmployeeByCompanyEmailArgs = {
  input: UpsertEmployeeByCompanyEmailInput;
};

export type MutationUpsertEmployeeByEmployeeIdArgs = {
  input: UpsertEmployeeByEmployeeIdInput;
};

export type MutationUpsertEmployeeEmergencyContactByEmployeeEmergencyContactIdArgs = {
  input: UpsertEmployeeEmergencyContactByEmployeeEmergencyContactIdInput;
};

export type MutationUpsertEmployeeEmergencyContactByEmployeeIdAndRelationIdAndNameArgs = {
  input: UpsertEmployeeEmergencyContactByEmployeeIdAndRelationIdAndNameInput;
};

export type MutationUpsertEmployeeIdTypeByCodeArgs = {
  input: UpsertEmployeeIdTypeByCodeInput;
};

export type MutationUpsertEmployeeIdTypeByEmployeeIdTypeIdArgs = {
  input: UpsertEmployeeIdTypeByEmployeeIdTypeIdInput;
};

export type MutationUpsertEmployeeLevelByDisplayNameArgs = {
  input: UpsertEmployeeLevelByDisplayNameInput;
};

export type MutationUpsertEmployeeLevelByEmployeeLevelIdArgs = {
  input: UpsertEmployeeLevelByEmployeeLevelIdInput;
};

export type MutationUpsertEmployeeResignationReasonByDisplayNameArgs = {
  input: UpsertEmployeeResignationReasonByDisplayNameInput;
};

export type MutationUpsertEmployeeResignationReasonByEmployeeResignationReasonIdArgs = {
  input: UpsertEmployeeResignationReasonByEmployeeResignationReasonIdInput;
};

export type MutationUpsertEmployeeResignationTypeByDisplayNameArgs = {
  input: UpsertEmployeeResignationTypeByDisplayNameInput;
};

export type MutationUpsertEmployeeResignationTypeByEmployeeResignationTypeIdArgs = {
  input: UpsertEmployeeResignationTypeByEmployeeResignationTypeIdInput;
};

export type MutationUpsertEmployeeTpHistoryByEmployeeIdAndEffectiveDateArgs = {
  input: UpsertEmployeeTpHistoryByEmployeeIdAndEffectiveDateInput;
};

export type MutationUpsertEmployeeTpHistoryByEmployeeTpHistoryIdArgs = {
  input: UpsertEmployeeTpHistoryByEmployeeTpHistoryIdInput;
};

export type MutationUpsertExternalAccountEmployeeByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKeyArgs = {
  input: UpsertExternalAccountEmployeeByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKeyInput;
};

export type MutationUpsertExternalAccountEmployeeByExternalAccountEmployeeIdArgs = {
  input: UpsertExternalAccountEmployeeByExternalAccountEmployeeIdInput;
};

export type MutationUpsertGoogleWorkspaceOrgUnitByCompanyIdAndBusinessUnitIdArgs = {
  input: UpsertGoogleWorkspaceOrgUnitByCompanyIdAndBusinessUnitIdInput;
};

export type MutationUpsertGoogleWorkspaceOrgUnitByGoogleWorkspaceOrgUnitIdArgs = {
  input: UpsertGoogleWorkspaceOrgUnitByGoogleWorkspaceOrgUnitIdInput;
};

export type MutationUpsertOfficeByCityIdAndNameArgs = {
  input: UpsertOfficeByCityIdAndNameInput;
};

export type MutationUpsertOfficeByOfficeIdArgs = {
  input: UpsertOfficeByOfficeIdInput;
};

export type MutationUpsertPerformanceReviewEvaluationByPerformanceReviewEvaluationIdArgs = {
  input: UpsertPerformanceReviewEvaluationByPerformanceReviewEvaluationIdInput;
};

export type MutationUpsertPerformanceReviewEvaluationCompetencyByPerformanceReviewEvaluationCompetencyIdArgs = {
  input: UpsertPerformanceReviewEvaluationCompetencyByPerformanceReviewEvaluationCompetencyIdInput;
};

export type MutationUpsertPerformanceReviewEvaluationCompetencyByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeIdArgs = {
  input: UpsertPerformanceReviewEvaluationCompetencyByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeIdInput;
};

export type MutationUpsertPerformanceReviewEvaluationFeedbackByPerformanceReviewEvaluationFeedbackIdArgs = {
  input: UpsertPerformanceReviewEvaluationFeedbackByPerformanceReviewEvaluationFeedbackIdInput;
};

export type MutationUpsertPerformanceReviewEvaluationFeedbackByPerformanceReviewEvaluationIdAndFeedbackEmployeeIdArgs = {
  input: UpsertPerformanceReviewEvaluationFeedbackByPerformanceReviewEvaluationIdAndFeedbackEmployeeIdInput;
};

export type MutationUpsertPerformanceReviewEvaluationKpiAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeIdArgs = {
  input: UpsertPerformanceReviewEvaluationKpiAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeIdInput;
};

export type MutationUpsertPerformanceReviewEvaluationKpiAssessmentByPerformanceReviewEvaluationKpiAssessmentIdArgs = {
  input: UpsertPerformanceReviewEvaluationKpiAssessmentByPerformanceReviewEvaluationKpiAssessmentIdInput;
};

export type MutationUpsertPerformanceReviewEvaluationKpiDescriptionByPerformanceReviewEvaluationIdAndTitleArgs = {
  input: UpsertPerformanceReviewEvaluationKpiDescriptionByPerformanceReviewEvaluationIdAndTitleInput;
};

export type MutationUpsertPerformanceReviewEvaluationKpiDescriptionByPerformanceReviewEvaluationKpiDescriptionIdArgs = {
  input: UpsertPerformanceReviewEvaluationKpiDescriptionByPerformanceReviewEvaluationKpiDescriptionIdInput;
};

export type MutationUpsertPerformanceReviewEvaluationKpiResultByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetIdArgs = {
  input: UpsertPerformanceReviewEvaluationKpiResultByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetIdInput;
};

export type MutationUpsertPerformanceReviewEvaluationKpiResultByPerformanceReviewEvaluationKpiResultIdArgs = {
  input: UpsertPerformanceReviewEvaluationKpiResultByPerformanceReviewEvaluationKpiResultIdInput;
};

export type MutationUpsertPerformanceReviewEvaluationKpiTargetByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndNameArgs = {
  input: UpsertPerformanceReviewEvaluationKpiTargetByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndNameInput;
};

export type MutationUpsertPerformanceReviewEvaluationKpiTargetByPerformanceReviewEvaluationKpiTargetIdArgs = {
  input: UpsertPerformanceReviewEvaluationKpiTargetByPerformanceReviewEvaluationKpiTargetIdInput;
};

export type MutationUpsertPerformanceReviewEvaluationQualitativeAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeIdArgs = {
  input: UpsertPerformanceReviewEvaluationQualitativeAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeIdInput;
};

export type MutationUpsertPerformanceReviewEvaluationQualitativeAssessmentByPerformanceReviewEvaluationQualitativeAssessmentIdArgs = {
  input: UpsertPerformanceReviewEvaluationQualitativeAssessmentByPerformanceReviewEvaluationQualitativeAssessmentIdInput;
};

export type MutationUpsertPerformanceReviewEvaluationQualitativeDescriptionByPerformanceReviewEvaluationIdAndTitleArgs = {
  input: UpsertPerformanceReviewEvaluationQualitativeDescriptionByPerformanceReviewEvaluationIdAndTitleInput;
};

export type MutationUpsertPerformanceReviewEvaluationQualitativeDescriptionByPerformanceReviewEvaluationQualitativeDescriptionIdArgs = {
  input: UpsertPerformanceReviewEvaluationQualitativeDescriptionByPerformanceReviewEvaluationQualitativeDescriptionIdInput;
};

export type MutationUpsertPerformanceReviewEvaluationValueByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeIdArgs = {
  input: UpsertPerformanceReviewEvaluationValueByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeIdInput;
};

export type MutationUpsertPerformanceReviewEvaluationValueByPerformanceReviewEvaluationValueIdArgs = {
  input: UpsertPerformanceReviewEvaluationValueByPerformanceReviewEvaluationValueIdInput;
};

export type MutationUpsertPerformanceReviewMasterCompetencyCommonDescriptionByPerformanceReviewMasterCompetencyCommonDescriptionIdArgs = {
  input: UpsertPerformanceReviewMasterCompetencyCommonDescriptionByPerformanceReviewMasterCompetencyCommonDescriptionIdInput;
};

export type MutationUpsertPerformanceReviewMasterCompetencyCommonDescriptionByPerformanceReviewMasterCompetencyCommonIdAndLanguageArgs = {
  input: UpsertPerformanceReviewMasterCompetencyCommonDescriptionByPerformanceReviewMasterCompetencyCommonIdAndLanguageInput;
};

export type MutationUpsertPerformanceReviewMasterCompetencyDetailDescriptionByPerformanceReviewMasterCompetencyDetailDescriptionIdArgs = {
  input: UpsertPerformanceReviewMasterCompetencyDetailDescriptionByPerformanceReviewMasterCompetencyDetailDescriptionIdInput;
};

export type MutationUpsertPerformanceReviewMasterCompetencyDetailDescriptionByPerformanceReviewMasterCompetencyDetailIdAndLanguageArgs = {
  input: UpsertPerformanceReviewMasterCompetencyDetailDescriptionByPerformanceReviewMasterCompetencyDetailIdAndLanguageInput;
};

export type MutationUpsertPerformanceReviewMasterCompetencyRoleByPerformanceReviewMasterCompetencyRoleIdArgs = {
  input: UpsertPerformanceReviewMasterCompetencyRoleByPerformanceReviewMasterCompetencyRoleIdInput;
};

export type MutationUpsertPerformanceReviewMasterCompetencyRoleByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleNameArgs = {
  input: UpsertPerformanceReviewMasterCompetencyRoleByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleNameInput;
};

export type MutationUpsertPerformanceReviewMasterExcludedEmployeeByYearAndQuarterAndEmployeeIdArgs = {
  input: UpsertPerformanceReviewMasterExcludedEmployeeByYearAndQuarterAndEmployeeIdInput;
};

export type MutationUpsertPerformanceReviewMasterRatingDescriptionByPerformanceReviewMasterRatingDescriptionIdArgs = {
  input: UpsertPerformanceReviewMasterRatingDescriptionByPerformanceReviewMasterRatingDescriptionIdInput;
};

export type MutationUpsertPerformanceReviewMasterRatingDescriptionByPerformanceReviewMasterRatingIdAndLanguageArgs = {
  input: UpsertPerformanceReviewMasterRatingDescriptionByPerformanceReviewMasterRatingIdAndLanguageInput;
};

export type MutationUpsertPerformanceReviewMasterUsageByPerformanceReviewMasterUsageIdArgs = {
  input: UpsertPerformanceReviewMasterUsageByPerformanceReviewMasterUsageIdInput;
};

export type MutationUpsertPerformanceReviewMasterUsageByYearAndQuarterAndCompanyIdArgs = {
  input: UpsertPerformanceReviewMasterUsageByYearAndQuarterAndCompanyIdInput;
};

export type MutationUpsertPerformanceReviewMasterValueDescriptionByPerformanceReviewMasterValueDescriptionIdArgs = {
  input: UpsertPerformanceReviewMasterValueDescriptionByPerformanceReviewMasterValueDescriptionIdInput;
};

export type MutationUpsertPerformanceReviewMasterValueDescriptionByPerformanceReviewMasterValueIdAndLanguageArgs = {
  input: UpsertPerformanceReviewMasterValueDescriptionByPerformanceReviewMasterValueIdAndLanguageInput;
};

export type MutationUpsertPerformanceReviewMasterWeightingByPerformanceReviewMasterWeightingIdArgs = {
  input: UpsertPerformanceReviewMasterWeightingByPerformanceReviewMasterWeightingIdInput;
};

export type MutationUpsertPerformanceReviewMasterWeightingByYearAndQuarterAndCompanyIdArgs = {
  input: UpsertPerformanceReviewMasterWeightingByYearAndQuarterAndCompanyIdInput;
};

export type MutationUpsertPerformanceReviewMasterWeightingByYearAndQuarterAndNameArgs = {
  input: UpsertPerformanceReviewMasterWeightingByYearAndQuarterAndNameInput;
};

export type MutationUpsertPerformanceReviewMasterWeightingDetailByPerformanceReviewMasterWeightingDetailIdArgs = {
  input: UpsertPerformanceReviewMasterWeightingDetailByPerformanceReviewMasterWeightingDetailIdInput;
};

export type MutationUpsertPerformanceReviewMasterWeightingDetailByPerformanceReviewMasterWeightingIdAndEmployeeLevelIdArgs = {
  input: UpsertPerformanceReviewMasterWeightingDetailByPerformanceReviewMasterWeightingIdAndEmployeeLevelIdInput;
};

export type MutationUpsertProbationReviewEvaluationByEmployeeIdAndReviewDatePlannedArgs = {
  input: UpsertProbationReviewEvaluationByEmployeeIdAndReviewDatePlannedInput;
};

export type MutationUpsertProbationReviewEvaluationByProbationReviewEvaluationIdArgs = {
  input: UpsertProbationReviewEvaluationByProbationReviewEvaluationIdInput;
};

export type MutationUpsertProbationReviewEvaluationResultItemByProbationReviewEvaluationIdAndProbationReviewMasterItemIdArgs = {
  input: UpsertProbationReviewEvaluationResultItemByProbationReviewEvaluationIdAndProbationReviewMasterItemIdInput;
};

export type MutationUpsertProbationReviewEvaluationResultItemByProbationReviewEvaluationResultItemIdArgs = {
  input: UpsertProbationReviewEvaluationResultItemByProbationReviewEvaluationResultItemIdInput;
};

export type MutationUpsertProbationReviewEvaluationResultScoreCompetencyByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyIdArgs = {
  input: UpsertProbationReviewEvaluationResultScoreCompetencyByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyIdInput;
};

export type MutationUpsertProbationReviewEvaluationResultScoreCompetencyByProbationReviewEvaluationResultScoreCompetencyIdArgs = {
  input: UpsertProbationReviewEvaluationResultScoreCompetencyByProbationReviewEvaluationResultScoreCompetencyIdInput;
};

export type MutationUpsertProbationReviewEvaluationResultScoreValueByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueIdArgs = {
  input: UpsertProbationReviewEvaluationResultScoreValueByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueIdInput;
};

export type MutationUpsertProbationReviewEvaluationResultScoreValueByProbationReviewEvaluationResultScoreValueIdArgs = {
  input: UpsertProbationReviewEvaluationResultScoreValueByProbationReviewEvaluationResultScoreValueIdInput;
};

export type MutationUpsertProbationReviewEvaluationReviewerByProbationReviewEvaluationIdAndReviewerEmployeeIdArgs = {
  input: UpsertProbationReviewEvaluationReviewerByProbationReviewEvaluationIdAndReviewerEmployeeIdInput;
};

export type MutationUpsertProbationReviewMasterItemByProbationReviewMasterItemIdArgs = {
  input: UpsertProbationReviewMasterItemByProbationReviewMasterItemIdInput;
};

export type MutationUpsertProbationReviewMasterItemByYearAndQuarterAndTitleAndInputUserArgs = {
  input: UpsertProbationReviewMasterItemByYearAndQuarterAndTitleAndInputUserInput;
};

export type MutationUpsertProbationReviewMasterItemDescriptionByProbationReviewMasterItemDescriptionIdArgs = {
  input: UpsertProbationReviewMasterItemDescriptionByProbationReviewMasterItemDescriptionIdInput;
};

export type MutationUpsertProbationReviewMasterItemDescriptionByProbationReviewMasterItemIdAndLanguageArgs = {
  input: UpsertProbationReviewMasterItemDescriptionByProbationReviewMasterItemIdAndLanguageInput;
};

export type MutationUpsertRelationEmployeeUserAccountByUserAccountIdArgs = {
  input: UpsertRelationEmployeeUserAccountByUserAccountIdInput;
};

export type MutationValidateSubscriptionArgs = {
  input: ValidateSubscriptionInput;
};

export type Node = {
  nodeId: Scalars['ID']['output'];
};

export interface Office extends Node {
  __typename?: 'Office';
  city: City;
  cityId: Scalars['BigInt']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  employeeCurrentPositions: EmployeeCurrentPositionsConnection;
  employeeTpHistories: EmployeeTpHistoriesConnection;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  officeId: Scalars['BigInt']['output'];
  officeWeathers: OfficeWeathersConnection;
  relationCompanyOffices: RelationCompanyOfficesConnection;
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export type OfficeEmployeeCurrentPositionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCurrentPositionCondition>;
  filter?: InputMaybe<EmployeeCurrentPositionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeCurrentPositionsOrderBy>>;
};

export type OfficeEmployeeTpHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeTpHistoryCondition>;
  filter?: InputMaybe<EmployeeTpHistoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeTpHistoriesOrderBy>>;
};

export type OfficeOfficeWeathersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<OfficeWeatherCondition>;
  filter?: InputMaybe<OfficeWeatherFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OfficeWeathersOrderBy>>;
};

export type OfficeRelationCompanyOfficesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationCompanyOfficeCondition>;
  filter?: InputMaybe<RelationCompanyOfficeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationCompanyOfficesOrderBy>>;
};

export interface OfficeCityIdFkeyCityCreateInput {
  country?: InputMaybe<CityCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey6InverseInput>;
  name: Scalars['String']['input'];
  offices?: InputMaybe<OfficeCityIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCityIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CityUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  utcOffset?: InputMaybe<IntervalInput>;
}

export interface OfficeCityIdFkeyInput {
  connectByCityId?: InputMaybe<CityCityPkeyConnect>;
  connectByCountryCodeAlpha2AndName?: InputMaybe<CityCityCountryCodeAlpha2NameKeyConnect>;
  connectByNodeId?: InputMaybe<CityNodeIdConnect>;
  create?: InputMaybe<OfficeCityIdFkeyCityCreateInput>;
  deleteByCityId?: InputMaybe<CityCityPkeyDelete>;
  deleteByCountryCodeAlpha2AndName?: InputMaybe<CityCityCountryCodeAlpha2NameKeyDelete>;
  deleteByNodeId?: InputMaybe<CityNodeIdDelete>;
  updateByCityId?: InputMaybe<CityOnOfficeForOfficeCityIdFkeyUsingCityPkeyUpdate>;
  updateByCountryCodeAlpha2AndName?: InputMaybe<CityOnOfficeForOfficeCityIdFkeyUsingCityCountryCodeAlpha2NameKeyUpdate>;
  updateByNodeId?: InputMaybe<OfficeOnOfficeForOfficeCityIdFkeyNodeIdUpdate>;
}

export interface OfficeCityIdFkeyInverseInput {
  connectByCityIdAndName?: InputMaybe<Array<OfficeOfficeCityIdNameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<OfficeNodeIdConnect>>;
  connectByOfficeId?: InputMaybe<Array<OfficeOfficePkeyConnect>>;
  create?: InputMaybe<Array<OfficeCityIdFkeyOfficeCreateInput>>;
  deleteByCityIdAndName?: InputMaybe<Array<OfficeOfficeCityIdNameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<OfficeNodeIdDelete>>;
  deleteByOfficeId?: InputMaybe<Array<OfficeOfficePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCityIdAndName?: InputMaybe<Array<OfficeOnOfficeForOfficeCityIdFkeyUsingOfficeCityIdNameKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<CityOnOfficeForOfficeCityIdFkeyNodeIdUpdate>>;
  updateByOfficeId?: InputMaybe<Array<OfficeOnOfficeForOfficeCityIdFkeyUsingOfficePkeyUpdate>>;
}

export interface OfficeCityIdFkeyOfficeCreateInput {
  city?: InputMaybe<OfficeCityIdFkeyInput>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInverseInput>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  officeWeathers?: InputMaybe<OfficeWeatherOfficeIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeOfficeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<OfficeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface OfficeCondition {
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface OfficeFilter {
  and?: InputMaybe<Array<OfficeFilter>>;
  city?: InputMaybe<CityFilter>;
  cityId?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  employeeCurrentPositions?: InputMaybe<OfficeToManyEmployeeCurrentPositionFilter>;
  employeeCurrentPositionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeTpHistories?: InputMaybe<OfficeToManyEmployeeTpHistoryFilter>;
  employeeTpHistoriesExist?: InputMaybe<Scalars['Boolean']['input']>;
  latitude?: InputMaybe<FloatFilter>;
  longitude?: InputMaybe<FloatFilter>;
  name?: InputMaybe<StringFilter>;
  not?: InputMaybe<OfficeFilter>;
  officeId?: InputMaybe<BigIntFilter>;
  officeWeathers?: InputMaybe<OfficeToManyOfficeWeatherFilter>;
  officeWeathersExist?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<OfficeFilter>>;
  relationCompanyOffices?: InputMaybe<OfficeToManyRelationCompanyOfficeFilter>;
  relationCompanyOfficesExist?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface OfficeInput {
  city?: InputMaybe<OfficeCityIdFkeyInput>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInverseInput>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  officeWeathers?: InputMaybe<OfficeWeatherOfficeIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeOfficeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<OfficeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface OfficeNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface OfficeNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface OfficeOfficeCityIdNameKeyConnect {
  cityId: Scalars['BigInt']['input'];
  name: Scalars['String']['input'];
}

export interface OfficeOfficeCityIdNameKeyDelete {
  cityId: Scalars['BigInt']['input'];
  name: Scalars['String']['input'];
}

export interface OfficeOfficePkeyConnect {
  officeId: Scalars['BigInt']['input'];
}

export interface OfficeOfficePkeyDelete {
  officeId: Scalars['BigInt']['input'];
}

export interface OfficeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey7NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeCurrentPositionPatch;
}

export interface OfficeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey7UsingOfficeCityIdNameKeyUpdate {
  cityId: Scalars['BigInt']['input'];
  name: Scalars['String']['input'];
  patch: UpdateOfficeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey7Patch;
}

export interface OfficeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey7UsingOfficePkeyUpdate {
  officeId: Scalars['BigInt']['input'];
  patch: UpdateOfficeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey7Patch;
}

export interface OfficeOnEmployeeTpHistoryForEmployeeTpHistoryOfficeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeTpHistoryPatch;
}

export interface OfficeOnEmployeeTpHistoryForEmployeeTpHistoryOfficeIdFkeyUsingOfficeCityIdNameKeyUpdate {
  cityId: Scalars['BigInt']['input'];
  name: Scalars['String']['input'];
  patch: UpdateOfficeOnEmployeeTpHistoryForEmployeeTpHistoryOfficeIdFkeyPatch;
}

export interface OfficeOnEmployeeTpHistoryForEmployeeTpHistoryOfficeIdFkeyUsingOfficePkeyUpdate {
  officeId: Scalars['BigInt']['input'];
  patch: UpdateOfficeOnEmployeeTpHistoryForEmployeeTpHistoryOfficeIdFkeyPatch;
}

export interface OfficeOnOfficeForOfficeCityIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CityPatch;
}

export interface OfficeOnOfficeForOfficeCityIdFkeyUsingOfficeCityIdNameKeyUpdate {
  cityId: Scalars['BigInt']['input'];
  name: Scalars['String']['input'];
  patch: UpdateOfficeOnOfficeForOfficeCityIdFkeyPatch;
}

export interface OfficeOnOfficeForOfficeCityIdFkeyUsingOfficePkeyUpdate {
  officeId: Scalars['BigInt']['input'];
  patch: UpdateOfficeOnOfficeForOfficeCityIdFkeyPatch;
}

export interface OfficeOnOfficeForOfficeUserCreatedFkeyUsingOfficeCityIdNameKeyUpdate {
  cityId: Scalars['BigInt']['input'];
  name: Scalars['String']['input'];
  patch: UpdateOfficeOnOfficeForOfficeUserCreatedFkeyPatch;
}

export interface OfficeOnOfficeForOfficeUserCreatedFkeyUsingOfficePkeyUpdate {
  officeId: Scalars['BigInt']['input'];
  patch: UpdateOfficeOnOfficeForOfficeUserCreatedFkeyPatch;
}

export interface OfficeOnOfficeForOfficeUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface OfficeOnOfficeForOfficeUserUpdatedFkeyUsingOfficeCityIdNameKeyUpdate {
  cityId: Scalars['BigInt']['input'];
  name: Scalars['String']['input'];
  patch: UpdateOfficeOnOfficeForOfficeUserUpdatedFkeyPatch;
}

export interface OfficeOnOfficeForOfficeUserUpdatedFkeyUsingOfficePkeyUpdate {
  officeId: Scalars['BigInt']['input'];
  patch: UpdateOfficeOnOfficeForOfficeUserUpdatedFkeyPatch;
}

export interface OfficeOnOfficeWeatherForOfficeWeatherOfficeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: OfficeWeatherPatch;
}

export interface OfficeOnOfficeWeatherForOfficeWeatherOfficeIdFkeyUsingOfficeCityIdNameKeyUpdate {
  cityId: Scalars['BigInt']['input'];
  name: Scalars['String']['input'];
  patch: UpdateOfficeOnOfficeWeatherForOfficeWeatherOfficeIdFkeyPatch;
}

export interface OfficeOnOfficeWeatherForOfficeWeatherOfficeIdFkeyUsingOfficePkeyUpdate {
  officeId: Scalars['BigInt']['input'];
  patch: UpdateOfficeOnOfficeWeatherForOfficeWeatherOfficeIdFkeyPatch;
}

export interface OfficeOnRelationCompanyOfficeForRelationCompanyOfficeOfficeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationCompanyOfficePatch;
}

export interface OfficeOnRelationCompanyOfficeForRelationCompanyOfficeOfficeIdFkeyUsingOfficeCityIdNameKeyUpdate {
  cityId: Scalars['BigInt']['input'];
  name: Scalars['String']['input'];
  patch: UpdateOfficeOnRelationCompanyOfficeForRelationCompanyOfficeOfficeIdFkeyPatch;
}

export interface OfficeOnRelationCompanyOfficeForRelationCompanyOfficeOfficeIdFkeyUsingOfficePkeyUpdate {
  officeId: Scalars['BigInt']['input'];
  patch: UpdateOfficeOnRelationCompanyOfficeForRelationCompanyOfficeOfficeIdFkeyPatch;
}

export interface OfficePatch {
  city?: InputMaybe<OfficeCityIdFkeyInput>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInverseInput>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  officeWeathers?: InputMaybe<OfficeWeatherOfficeIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeOfficeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<OfficeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface OfficeToManyEmployeeCurrentPositionFilter {
  every?: InputMaybe<EmployeeCurrentPositionFilter>;
  none?: InputMaybe<EmployeeCurrentPositionFilter>;
  some?: InputMaybe<EmployeeCurrentPositionFilter>;
}

export interface OfficeToManyEmployeeTpHistoryFilter {
  every?: InputMaybe<EmployeeTpHistoryFilter>;
  none?: InputMaybe<EmployeeTpHistoryFilter>;
  some?: InputMaybe<EmployeeTpHistoryFilter>;
}

export interface OfficeToManyOfficeWeatherFilter {
  every?: InputMaybe<OfficeWeatherFilter>;
  none?: InputMaybe<OfficeWeatherFilter>;
  some?: InputMaybe<OfficeWeatherFilter>;
}

export interface OfficeToManyRelationCompanyOfficeFilter {
  every?: InputMaybe<RelationCompanyOfficeFilter>;
  none?: InputMaybe<RelationCompanyOfficeFilter>;
  some?: InputMaybe<RelationCompanyOfficeFilter>;
}

export interface OfficeUserCreatedFkeyInverseInput {
  connectByCityIdAndName?: InputMaybe<Array<OfficeOfficeCityIdNameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<OfficeNodeIdConnect>>;
  connectByOfficeId?: InputMaybe<Array<OfficeOfficePkeyConnect>>;
  deleteByCityIdAndName?: InputMaybe<Array<OfficeOfficeCityIdNameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<OfficeNodeIdDelete>>;
  deleteByOfficeId?: InputMaybe<Array<OfficeOfficePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCityIdAndName?: InputMaybe<Array<OfficeOnOfficeForOfficeUserCreatedFkeyUsingOfficeCityIdNameKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnOfficeForOfficeUserCreatedFkeyNodeIdUpdate>>;
  updateByOfficeId?: InputMaybe<Array<OfficeOnOfficeForOfficeUserCreatedFkeyUsingOfficePkeyUpdate>>;
}

export interface OfficeUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnOfficeForOfficeUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<OfficeOnOfficeForOfficeUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnOfficeForOfficeUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface OfficeUserUpdatedFkeyInverseInput {
  connectByCityIdAndName?: InputMaybe<Array<OfficeOfficeCityIdNameKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<OfficeNodeIdConnect>>;
  connectByOfficeId?: InputMaybe<Array<OfficeOfficePkeyConnect>>;
  deleteByCityIdAndName?: InputMaybe<Array<OfficeOfficeCityIdNameKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<OfficeNodeIdDelete>>;
  deleteByOfficeId?: InputMaybe<Array<OfficeOfficePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCityIdAndName?: InputMaybe<Array<OfficeOnOfficeForOfficeUserUpdatedFkeyUsingOfficeCityIdNameKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnOfficeForOfficeUserUpdatedFkeyNodeIdUpdate>>;
  updateByOfficeId?: InputMaybe<Array<OfficeOnOfficeForOfficeUserUpdatedFkeyUsingOfficePkeyUpdate>>;
}

export interface OfficeWeather extends Node {
  __typename?: 'OfficeWeather';
  condition?: Maybe<Scalars['String']['output']>;
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  day: Scalars['Date']['output'];
  humidity?: Maybe<Scalars['Float']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  nodeId: Scalars['ID']['output'];
  office: Office;
  officeId: Scalars['BigInt']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  tempMax?: Maybe<Scalars['Float']['output']>;
  tempMin?: Maybe<Scalars['Float']['output']>;
  timeSunrise?: Maybe<Scalars['Time']['output']>;
  timeSunset?: Maybe<Scalars['Time']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface OfficeWeatherCondition {
  condition?: InputMaybe<Scalars['String']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  day?: InputMaybe<Scalars['Date']['input']>;
  humidity?: InputMaybe<Scalars['Float']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  tempMax?: InputMaybe<Scalars['Float']['input']>;
  tempMin?: InputMaybe<Scalars['Float']['input']>;
  timeSunrise?: InputMaybe<Scalars['Time']['input']>;
  timeSunset?: InputMaybe<Scalars['Time']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface OfficeWeatherFilter {
  and?: InputMaybe<Array<OfficeWeatherFilter>>;
  condition?: InputMaybe<StringFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  day?: InputMaybe<DateFilter>;
  humidity?: InputMaybe<FloatFilter>;
  icon?: InputMaybe<StringFilter>;
  not?: InputMaybe<OfficeWeatherFilter>;
  office?: InputMaybe<OfficeFilter>;
  officeId?: InputMaybe<BigIntFilter>;
  or?: InputMaybe<Array<OfficeWeatherFilter>>;
  remark?: InputMaybe<StringFilter>;
  tempMax?: InputMaybe<FloatFilter>;
  tempMin?: InputMaybe<FloatFilter>;
  timeSunrise?: InputMaybe<TimeFilter>;
  timeSunset?: InputMaybe<TimeFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface OfficeWeatherNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface OfficeWeatherOfficeIdFkeyInput {
  connectByCityIdAndName?: InputMaybe<OfficeOfficeCityIdNameKeyConnect>;
  connectByNodeId?: InputMaybe<OfficeNodeIdConnect>;
  connectByOfficeId?: InputMaybe<OfficeOfficePkeyConnect>;
  create?: InputMaybe<OfficeWeatherOfficeIdFkeyOfficeCreateInput>;
  deleteByCityIdAndName?: InputMaybe<OfficeOfficeCityIdNameKeyDelete>;
  deleteByNodeId?: InputMaybe<OfficeNodeIdDelete>;
  deleteByOfficeId?: InputMaybe<OfficeOfficePkeyDelete>;
  updateByCityIdAndName?: InputMaybe<OfficeOnOfficeWeatherForOfficeWeatherOfficeIdFkeyUsingOfficeCityIdNameKeyUpdate>;
  updateByNodeId?: InputMaybe<OfficeWeatherOnOfficeWeatherForOfficeWeatherOfficeIdFkeyNodeIdUpdate>;
  updateByOfficeId?: InputMaybe<OfficeOnOfficeWeatherForOfficeWeatherOfficeIdFkeyUsingOfficePkeyUpdate>;
}

export interface OfficeWeatherOfficeIdFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<OfficeWeatherNodeIdConnect>>;
  connectByOfficeIdAndDay?: InputMaybe<Array<OfficeWeatherOfficeWeatherPkeyConnect>>;
  updateByNodeId?: InputMaybe<Array<OfficeOnOfficeWeatherForOfficeWeatherOfficeIdFkeyNodeIdUpdate>>;
  updateByOfficeIdAndDay?: InputMaybe<Array<OfficeWeatherOnOfficeWeatherForOfficeWeatherOfficeIdFkeyUsingOfficeWeatherPkeyUpdate>>;
}

export interface OfficeWeatherOfficeIdFkeyOfficeCreateInput {
  city?: InputMaybe<OfficeCityIdFkeyInput>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInverseInput>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  officeWeathers?: InputMaybe<OfficeWeatherOfficeIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeOfficeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<OfficeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface OfficeWeatherOfficeWeatherPkeyConnect {
  day: Scalars['Date']['input'];
  officeId: Scalars['BigInt']['input'];
}

export interface OfficeWeatherOnOfficeWeatherForOfficeWeatherOfficeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: OfficePatch;
}

export interface OfficeWeatherOnOfficeWeatherForOfficeWeatherOfficeIdFkeyUsingOfficeWeatherPkeyUpdate {
  day: Scalars['Date']['input'];
  officeId: Scalars['BigInt']['input'];
  patch: UpdateOfficeWeatherOnOfficeWeatherForOfficeWeatherOfficeIdFkeyPatch;
}

export interface OfficeWeatherOnOfficeWeatherForOfficeWeatherUserCreatedFkeyUsingOfficeWeatherPkeyUpdate {
  day: Scalars['Date']['input'];
  officeId: Scalars['BigInt']['input'];
  patch: UpdateOfficeWeatherOnOfficeWeatherForOfficeWeatherUserCreatedFkeyPatch;
}

export interface OfficeWeatherOnOfficeWeatherForOfficeWeatherUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface OfficeWeatherOnOfficeWeatherForOfficeWeatherUserUpdatedFkeyUsingOfficeWeatherPkeyUpdate {
  day: Scalars['Date']['input'];
  officeId: Scalars['BigInt']['input'];
  patch: UpdateOfficeWeatherOnOfficeWeatherForOfficeWeatherUserUpdatedFkeyPatch;
}

export interface OfficeWeatherPatch {
  condition?: InputMaybe<Scalars['String']['input']>;
  day?: InputMaybe<Scalars['Date']['input']>;
  humidity?: InputMaybe<Scalars['Float']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<OfficeWeatherOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  tempMax?: InputMaybe<Scalars['Float']['input']>;
  tempMin?: InputMaybe<Scalars['Float']['input']>;
  timeSunrise?: InputMaybe<Scalars['Time']['input']>;
  timeSunset?: InputMaybe<Scalars['Time']['input']>;
  userAccount?: InputMaybe<OfficeWeatherUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface OfficeWeatherUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<OfficeWeatherNodeIdConnect>>;
  connectByOfficeIdAndDay?: InputMaybe<Array<OfficeWeatherOfficeWeatherPkeyConnect>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnOfficeWeatherForOfficeWeatherUserCreatedFkeyNodeIdUpdate>>;
  updateByOfficeIdAndDay?: InputMaybe<Array<OfficeWeatherOnOfficeWeatherForOfficeWeatherUserCreatedFkeyUsingOfficeWeatherPkeyUpdate>>;
}

export interface OfficeWeatherUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnOfficeWeatherForOfficeWeatherUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<OfficeWeatherOnOfficeWeatherForOfficeWeatherUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnOfficeWeatherForOfficeWeatherUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface OfficeWeatherUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<OfficeWeatherNodeIdConnect>>;
  connectByOfficeIdAndDay?: InputMaybe<Array<OfficeWeatherOfficeWeatherPkeyConnect>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnOfficeWeatherForOfficeWeatherUserUpdatedFkeyNodeIdUpdate>>;
  updateByOfficeIdAndDay?: InputMaybe<Array<OfficeWeatherOnOfficeWeatherForOfficeWeatherUserUpdatedFkeyUsingOfficeWeatherPkeyUpdate>>;
}

export interface OfficeWeathersConnection {
  __typename?: 'OfficeWeathersConnection';
  edges: Array<OfficeWeathersEdge>;
  nodes: Array<OfficeWeather>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface OfficeWeathersEdge {
  __typename?: 'OfficeWeathersEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: OfficeWeather;
}

export enum OfficeWeathersOrderBy {
  CONDITION_ASC = 'CONDITION_ASC',
  CONDITION_DESC = 'CONDITION_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DAY_ASC = 'DAY_ASC',
  DAY_DESC = 'DAY_DESC',
  HUMIDITY_ASC = 'HUMIDITY_ASC',
  HUMIDITY_DESC = 'HUMIDITY_DESC',
  ICON_ASC = 'ICON_ASC',
  ICON_DESC = 'ICON_DESC',
  NATURAL = 'NATURAL',
  OFFICE_BY_OFFICE_ID__CITY_ID_ASC = 'OFFICE_BY_OFFICE_ID__CITY_ID_ASC',
  OFFICE_BY_OFFICE_ID__CITY_ID_DESC = 'OFFICE_BY_OFFICE_ID__CITY_ID_DESC',
  OFFICE_BY_OFFICE_ID__DATE_CREATED_ASC = 'OFFICE_BY_OFFICE_ID__DATE_CREATED_ASC',
  OFFICE_BY_OFFICE_ID__DATE_CREATED_DESC = 'OFFICE_BY_OFFICE_ID__DATE_CREATED_DESC',
  OFFICE_BY_OFFICE_ID__DATE_UPDATED_ASC = 'OFFICE_BY_OFFICE_ID__DATE_UPDATED_ASC',
  OFFICE_BY_OFFICE_ID__DATE_UPDATED_DESC = 'OFFICE_BY_OFFICE_ID__DATE_UPDATED_DESC',
  OFFICE_BY_OFFICE_ID__LATITUDE_ASC = 'OFFICE_BY_OFFICE_ID__LATITUDE_ASC',
  OFFICE_BY_OFFICE_ID__LATITUDE_DESC = 'OFFICE_BY_OFFICE_ID__LATITUDE_DESC',
  OFFICE_BY_OFFICE_ID__LONGITUDE_ASC = 'OFFICE_BY_OFFICE_ID__LONGITUDE_ASC',
  OFFICE_BY_OFFICE_ID__LONGITUDE_DESC = 'OFFICE_BY_OFFICE_ID__LONGITUDE_DESC',
  OFFICE_BY_OFFICE_ID__NAME_ASC = 'OFFICE_BY_OFFICE_ID__NAME_ASC',
  OFFICE_BY_OFFICE_ID__NAME_DESC = 'OFFICE_BY_OFFICE_ID__NAME_DESC',
  OFFICE_BY_OFFICE_ID__OFFICE_ID_ASC = 'OFFICE_BY_OFFICE_ID__OFFICE_ID_ASC',
  OFFICE_BY_OFFICE_ID__OFFICE_ID_DESC = 'OFFICE_BY_OFFICE_ID__OFFICE_ID_DESC',
  OFFICE_BY_OFFICE_ID__REMARK_ASC = 'OFFICE_BY_OFFICE_ID__REMARK_ASC',
  OFFICE_BY_OFFICE_ID__REMARK_DESC = 'OFFICE_BY_OFFICE_ID__REMARK_DESC',
  OFFICE_BY_OFFICE_ID__USER_CREATED_ASC = 'OFFICE_BY_OFFICE_ID__USER_CREATED_ASC',
  OFFICE_BY_OFFICE_ID__USER_CREATED_DESC = 'OFFICE_BY_OFFICE_ID__USER_CREATED_DESC',
  OFFICE_BY_OFFICE_ID__USER_UPDATED_ASC = 'OFFICE_BY_OFFICE_ID__USER_UPDATED_ASC',
  OFFICE_BY_OFFICE_ID__USER_UPDATED_DESC = 'OFFICE_BY_OFFICE_ID__USER_UPDATED_DESC',
  OFFICE_ID_ASC = 'OFFICE_ID_ASC',
  OFFICE_ID_DESC = 'OFFICE_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  TEMP_MAX_ASC = 'TEMP_MAX_ASC',
  TEMP_MAX_DESC = 'TEMP_MAX_DESC',
  TEMP_MIN_ASC = 'TEMP_MIN_ASC',
  TEMP_MIN_DESC = 'TEMP_MIN_DESC',
  TIME_SUNRISE_ASC = 'TIME_SUNRISE_ASC',
  TIME_SUNRISE_DESC = 'TIME_SUNRISE_DESC',
  TIME_SUNSET_ASC = 'TIME_SUNSET_ASC',
  TIME_SUNSET_DESC = 'TIME_SUNSET_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface OfficesConnection {
  __typename?: 'OfficesConnection';
  edges: Array<OfficesEdge>;
  nodes: Array<Office>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface OfficesEdge {
  __typename?: 'OfficesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: Office;
}

export enum OfficesOrderBy {
  CITY_BY_CITY_ID__CITY_ID_ASC = 'CITY_BY_CITY_ID__CITY_ID_ASC',
  CITY_BY_CITY_ID__CITY_ID_DESC = 'CITY_BY_CITY_ID__CITY_ID_DESC',
  CITY_BY_CITY_ID__COUNTRY_CODE_ALPHA2_ASC = 'CITY_BY_CITY_ID__COUNTRY_CODE_ALPHA2_ASC',
  CITY_BY_CITY_ID__COUNTRY_CODE_ALPHA2_DESC = 'CITY_BY_CITY_ID__COUNTRY_CODE_ALPHA2_DESC',
  CITY_BY_CITY_ID__DATE_CREATED_ASC = 'CITY_BY_CITY_ID__DATE_CREATED_ASC',
  CITY_BY_CITY_ID__DATE_CREATED_DESC = 'CITY_BY_CITY_ID__DATE_CREATED_DESC',
  CITY_BY_CITY_ID__DATE_UPDATED_ASC = 'CITY_BY_CITY_ID__DATE_UPDATED_ASC',
  CITY_BY_CITY_ID__DATE_UPDATED_DESC = 'CITY_BY_CITY_ID__DATE_UPDATED_DESC',
  CITY_BY_CITY_ID__NAME_ASC = 'CITY_BY_CITY_ID__NAME_ASC',
  CITY_BY_CITY_ID__NAME_DESC = 'CITY_BY_CITY_ID__NAME_DESC',
  CITY_BY_CITY_ID__REMARK_ASC = 'CITY_BY_CITY_ID__REMARK_ASC',
  CITY_BY_CITY_ID__REMARK_DESC = 'CITY_BY_CITY_ID__REMARK_DESC',
  CITY_BY_CITY_ID__USER_CREATED_ASC = 'CITY_BY_CITY_ID__USER_CREATED_ASC',
  CITY_BY_CITY_ID__USER_CREATED_DESC = 'CITY_BY_CITY_ID__USER_CREATED_DESC',
  CITY_BY_CITY_ID__USER_UPDATED_ASC = 'CITY_BY_CITY_ID__USER_UPDATED_ASC',
  CITY_BY_CITY_ID__USER_UPDATED_DESC = 'CITY_BY_CITY_ID__USER_UPDATED_DESC',
  CITY_BY_CITY_ID__UTC_OFFSET_ASC = 'CITY_BY_CITY_ID__UTC_OFFSET_ASC',
  CITY_BY_CITY_ID__UTC_OFFSET_DESC = 'CITY_BY_CITY_ID__UTC_OFFSET_DESC',
  CITY_ID_ASC = 'CITY_ID_ASC',
  CITY_ID_DESC = 'CITY_ID_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__COUNT_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__COUNT_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__COUNT_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__COUNT_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_CITY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_CITY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_CITY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_CITY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_COMPANY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_COMPANY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_COMPANY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_COMPANY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_DEPARTMENT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_DEPARTMENT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_DEPARTMENT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_DEPARTMENT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_DIVISION_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_DIVISION_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_DIVISION_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_DIVISION_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_EFFECTIVE_DATE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_EFFECTIVE_DATE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_EFFECTIVE_DATE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_EFFECTIVE_DATE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_EMPLOYEE_CODE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_EMPLOYEE_CODE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_EMPLOYEE_CODE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_EMPLOYEE_CODE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_JOB_TITLE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_JOB_TITLE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_JOB_TITLE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_JOB_TITLE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_OFFICE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_OFFICE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_OFFICE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_OFFICE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_TEAM_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_TEAM_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_TEAM_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MAX_TEAM_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_CITY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_CITY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_CITY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_CITY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_COMPANY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_COMPANY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_COMPANY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_COMPANY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_DEPARTMENT_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_DEPARTMENT_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_DEPARTMENT_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_DEPARTMENT_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_DIVISION_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_DIVISION_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_DIVISION_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_DIVISION_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_EFFECTIVE_DATE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_EFFECTIVE_DATE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_EFFECTIVE_DATE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_EFFECTIVE_DATE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_EMPLOYEE_CODE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_EMPLOYEE_CODE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_EMPLOYEE_CODE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_EMPLOYEE_CODE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_JOB_TITLE_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_JOB_TITLE_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_JOB_TITLE_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_JOB_TITLE_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_OFFICE_ID_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_OFFICE_ID_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_OFFICE_ID_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_OFFICE_ID_DESC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_TEAM_ASC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_TEAM_ASC',
  EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_TEAM_DESC = 'EMPLOYEE_CURRENT_POSITIONS_BY_OFFICE_ID__MIN_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__COUNT_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__COUNT_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__COUNT_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__COUNT_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_COMPANY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_COMPANY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_COMPANY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_COMPANY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_DATE_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_DATE_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_DATE_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_DATE_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_DEPARTMENT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_DEPARTMENT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_DEPARTMENT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_DEPARTMENT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_DIVISION_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_DIVISION_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_DIVISION_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_DIVISION_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_EFFECTIVE_DATE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_EFFECTIVE_DATE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_EFFECTIVE_DATE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_EFFECTIVE_DATE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_JOB_TITLE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_JOB_TITLE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_JOB_TITLE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_JOB_TITLE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_OFFICE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_OFFICE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_OFFICE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_OFFICE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_REMARK_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_REMARK_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_REMARK_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_REMARK_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_TEAM_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_TEAM_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_TEAM_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_USER_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_USER_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_USER_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_USER_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_USER_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_USER_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_USER_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MAX_USER_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_COMPANY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_COMPANY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_COMPANY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_COMPANY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_DATE_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_DATE_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_DATE_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_DATE_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_DEPARTMENT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_DEPARTMENT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_DEPARTMENT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_DEPARTMENT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_DIVISION_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_DIVISION_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_DIVISION_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_DIVISION_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_EFFECTIVE_DATE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_EFFECTIVE_DATE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_EFFECTIVE_DATE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_EFFECTIVE_DATE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_JOB_TITLE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_JOB_TITLE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_JOB_TITLE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_JOB_TITLE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_OFFICE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_OFFICE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_OFFICE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_OFFICE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_REMARK_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_REMARK_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_REMARK_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_REMARK_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_TEAM_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_TEAM_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_TEAM_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_USER_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_USER_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_USER_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_USER_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_USER_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_USER_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_USER_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_OFFICE_ID__MIN_USER_UPDATED_DESC',
  LATITUDE_ASC = 'LATITUDE_ASC',
  LATITUDE_DESC = 'LATITUDE_DESC',
  LONGITUDE_ASC = 'LONGITUDE_ASC',
  LONGITUDE_DESC = 'LONGITUDE_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  OFFICE_ID_ASC = 'OFFICE_ID_ASC',
  OFFICE_ID_DESC = 'OFFICE_ID_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__COUNT_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__COUNT_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__COUNT_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__COUNT_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_CONDITION_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_CONDITION_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_CONDITION_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_CONDITION_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_DATE_CREATED_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_DATE_CREATED_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_DATE_CREATED_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_DATE_CREATED_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_DATE_UPDATED_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_DATE_UPDATED_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_DATE_UPDATED_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_DATE_UPDATED_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_DAY_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_DAY_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_DAY_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_DAY_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_HUMIDITY_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_HUMIDITY_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_HUMIDITY_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_HUMIDITY_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_ICON_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_ICON_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_ICON_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_ICON_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_OFFICE_ID_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_OFFICE_ID_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_OFFICE_ID_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_OFFICE_ID_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_REMARK_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_REMARK_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_REMARK_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_REMARK_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_TEMP_MAX_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_TEMP_MAX_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_TEMP_MAX_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_TEMP_MAX_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_TEMP_MIN_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_TEMP_MIN_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_TEMP_MIN_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_TEMP_MIN_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_TIME_SUNRISE_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_TIME_SUNRISE_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_TIME_SUNRISE_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_TIME_SUNRISE_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_TIME_SUNSET_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_TIME_SUNSET_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_TIME_SUNSET_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_TIME_SUNSET_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_USER_CREATED_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_USER_CREATED_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_USER_CREATED_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_USER_CREATED_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_USER_UPDATED_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_USER_UPDATED_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MAX_USER_UPDATED_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MAX_USER_UPDATED_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_CONDITION_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_CONDITION_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_CONDITION_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_CONDITION_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_DATE_CREATED_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_DATE_CREATED_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_DATE_CREATED_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_DATE_CREATED_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_DATE_UPDATED_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_DATE_UPDATED_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_DATE_UPDATED_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_DATE_UPDATED_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_DAY_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_DAY_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_DAY_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_DAY_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_HUMIDITY_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_HUMIDITY_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_HUMIDITY_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_HUMIDITY_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_ICON_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_ICON_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_ICON_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_ICON_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_OFFICE_ID_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_OFFICE_ID_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_OFFICE_ID_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_OFFICE_ID_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_REMARK_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_REMARK_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_REMARK_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_REMARK_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_TEMP_MAX_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_TEMP_MAX_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_TEMP_MAX_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_TEMP_MAX_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_TEMP_MIN_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_TEMP_MIN_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_TEMP_MIN_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_TEMP_MIN_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_TIME_SUNRISE_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_TIME_SUNRISE_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_TIME_SUNRISE_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_TIME_SUNRISE_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_TIME_SUNSET_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_TIME_SUNSET_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_TIME_SUNSET_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_TIME_SUNSET_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_USER_CREATED_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_USER_CREATED_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_USER_CREATED_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_USER_CREATED_DESC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_USER_UPDATED_ASC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_USER_UPDATED_ASC',
  OFFICE_WEATHERS_BY_OFFICE_ID__MIN_USER_UPDATED_DESC = 'OFFICE_WEATHERS_BY_OFFICE_ID__MIN_USER_UPDATED_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__COUNT_ASC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__COUNT_ASC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__COUNT_DESC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__COUNT_DESC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_COMPANY_ID_ASC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_COMPANY_ID_ASC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_COMPANY_ID_DESC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_COMPANY_ID_DESC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_DATE_CREATED_ASC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_DATE_CREATED_ASC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_DATE_CREATED_DESC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_DATE_CREATED_DESC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_DATE_UPDATED_ASC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_DATE_UPDATED_ASC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_DATE_UPDATED_DESC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_DATE_UPDATED_DESC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_OFFICE_ID_ASC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_OFFICE_ID_ASC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_OFFICE_ID_DESC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_OFFICE_ID_DESC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_REMARK_ASC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_REMARK_ASC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_REMARK_DESC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_REMARK_DESC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_USER_CREATED_ASC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_USER_CREATED_ASC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_USER_CREATED_DESC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_USER_CREATED_DESC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_USER_UPDATED_ASC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_USER_UPDATED_ASC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_USER_UPDATED_DESC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MAX_USER_UPDATED_DESC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_COMPANY_ID_ASC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_COMPANY_ID_ASC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_COMPANY_ID_DESC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_COMPANY_ID_DESC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_DATE_CREATED_ASC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_DATE_CREATED_ASC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_DATE_CREATED_DESC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_DATE_CREATED_DESC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_DATE_UPDATED_ASC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_DATE_UPDATED_ASC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_DATE_UPDATED_DESC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_DATE_UPDATED_DESC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_OFFICE_ID_ASC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_OFFICE_ID_ASC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_OFFICE_ID_DESC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_OFFICE_ID_DESC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_REMARK_ASC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_REMARK_ASC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_REMARK_DESC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_REMARK_DESC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_USER_CREATED_ASC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_USER_CREATED_ASC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_USER_CREATED_DESC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_USER_CREATED_DESC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_USER_UPDATED_ASC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_USER_UPDATED_ASC',
  RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_USER_UPDATED_DESC = 'RELATION_COMPANY_OFFICES_BY_OFFICE_ID__MIN_USER_UPDATED_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface OutputTypeCopyPerformanceReviewMasterCompetencyCommon {
  __typename?: 'OutputTypeCopyPerformanceReviewMasterCompetencyCommon';
  copiedRows?: Maybe<Scalars['Int']['output']>;
}

export interface OutputTypeCopyPerformanceReviewMasterCompetencyDetail {
  __typename?: 'OutputTypeCopyPerformanceReviewMasterCompetencyDetail';
  copiedRows?: Maybe<Scalars['Int']['output']>;
}

export interface OutputTypeCopyPerformanceReviewMasterRating {
  __typename?: 'OutputTypeCopyPerformanceReviewMasterRating';
  copiedRows?: Maybe<Scalars['Int']['output']>;
}

export interface OutputTypeCopyPerformanceReviewMasterValue {
  __typename?: 'OutputTypeCopyPerformanceReviewMasterValue';
  copiedRows?: Maybe<Scalars['Int']['output']>;
}

export interface OutputTypeCopyPerformanceReviewMasterWeighting {
  __typename?: 'OutputTypeCopyPerformanceReviewMasterWeighting';
  copiedRows?: Maybe<Scalars['Int']['output']>;
}

export interface PageInfo {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['Cursor']['output']>;
}

export interface PerfReviewEvaluationKpiDescFixDisplayOrderInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  targetPerformanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerfReviewEvaluationKpiDescFixDisplayOrderPayload {
  __typename?: 'PerfReviewEvaluationKpiDescFixDisplayOrderPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
}

export interface PerfReviewEvaluationQualitativeDescFixDisplayOrderInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  targetPerformanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerfReviewEvaluationQualitativeDescFixDisplayOrderPayload {
  __typename?: 'PerfReviewEvaluationQualitativeDescFixDisplayOrderPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
}

export interface PerfReviewMasterCompetencyCommonFixDisplayOrderInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  targetQuarter?: InputMaybe<PerformanceReviewQuarter>;
  targetYear?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerfReviewMasterCompetencyCommonFixDisplayOrderPayload {
  __typename?: 'PerfReviewMasterCompetencyCommonFixDisplayOrderPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
}

export interface PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10Input {
  connectByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyNodeIdConnect>;
  connectByPerformanceReviewMasterCompetencyId?: InputMaybe<PerformanceReviewMasterCompetencyPerformanceReviewMasterCompetencyPkeyConnect>;
  create?: InputMaybe<PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10PerformanceReviewMasterCompetencyCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyNodeIdDelete>;
  deleteByPerformanceReviewMasterCompetencyId?: InputMaybe<PerformanceReviewMasterCompetencyPerformanceReviewMasterCompetencyPkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10NodeIdUpdate>;
  updateByPerformanceReviewMasterCompetencyId?: InputMaybe<PerformanceReviewMasterCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10UsingPerformanceReviewMasterCompetencyPkeyUpdate>;
}

export interface PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10InverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyNodeIdConnect>>;
  connectByPerformanceReviewEvaluationCompetencyId?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationCompetencyPkeyConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Connect>
  >;
  create?: InputMaybe<Array<PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10PerformanceReviewEvaluationCompetencyCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationCompetencyId?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationCompetencyPkeyDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Delete>
  >;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10NodeIdUpdate>>;
  updateByPerformanceReviewEvaluationCompetencyId?: InputMaybe<
    Array<PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10UsingPerformanceReviewEvaluationCompetencyPkeyUpdate>
  >;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Update>
  >;
}

export interface PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10PerformanceReviewEvaluationCompetencyCreateInput {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1Input>;
  performanceReviewEvaluationCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetency?: InputMaybe<PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10Input>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10PerformanceReviewMasterCompetencyCreateInput {
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10InverseInput>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4Input>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyDetail?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3Input>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10Input {
  connectByNodeId?: InputMaybe<PerformanceReviewEvaluationNodeIdConnect>;
  connectByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyConnect>;
  create?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10PerformanceReviewEvaluationCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewEvaluationNodeIdDelete>;
  deleteByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10NodeIdUpdate>;
  updateByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10UsingPerformanceReviewEvaluationPkeyUpdate>;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiResultPerformanceReviewEvaluationPerformanceReviewEvaluatioKey7Connect>
  >;
  connectByPerformanceReviewEvaluationKpiResultId?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultPerformanceReviewEvaluationKpiResultPkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10PerformanceReviewEvaluationKpiResultCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiResultPerformanceReviewEvaluationPerformanceReviewEvaluatioKey7Delete>
  >;
  deleteByPerformanceReviewEvaluationKpiResultId?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultPerformanceReviewEvaluationKpiResultPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10NodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey7Update>
  >;
  updateByPerformanceReviewEvaluationKpiResultId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10UsingPerformanceReviewEvaluationKpiResultPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10PerformanceReviewEvaluationCreateInput {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10PerformanceReviewEvaluationKpiResultCreateInput {
  description?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10Input>;
  performanceReviewEvaluationKpiResultId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiTarget?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11Input>;
  performanceReviewEvaluationKpiTargetId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  value: Scalars['BigFloat']['input'];
  valueUnit?: InputMaybe<Scalars['String']['input']>;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11Input {
  connectByNodeId?: InputMaybe<PerformanceReviewEvaluationKpiTargetNodeIdConnect>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndName?: InputMaybe<PerformanceReviewEvaluationKpiTargetPerformanceReviewEvaluationPerformanceReviewEvaluatioKey5Connect>;
  connectByPerformanceReviewEvaluationKpiTargetId?: InputMaybe<PerformanceReviewEvaluationKpiTargetPerformanceReviewEvaluationKpiTargetPkeyConnect>;
  create?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11PerformanceReviewEvaluationKpiTargetCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewEvaluationKpiTargetNodeIdDelete>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndName?: InputMaybe<PerformanceReviewEvaluationKpiTargetPerformanceReviewEvaluationPerformanceReviewEvaluatioKey5Delete>;
  deleteByPerformanceReviewEvaluationKpiTargetId?: InputMaybe<PerformanceReviewEvaluationKpiTargetPerformanceReviewEvaluationKpiTargetPkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11NodeIdUpdate>;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndName?: InputMaybe<PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey5Update>;
  updateByPerformanceReviewEvaluationKpiTargetId?: InputMaybe<PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11UsingPerformanceReviewEvaluationKpiTargetPkeyUpdate>;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11InverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiResultPerformanceReviewEvaluationPerformanceReviewEvaluatioKey7Connect>
  >;
  connectByPerformanceReviewEvaluationKpiResultId?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultPerformanceReviewEvaluationKpiResultPkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11PerformanceReviewEvaluationKpiResultCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiResultPerformanceReviewEvaluationPerformanceReviewEvaluatioKey7Delete>
  >;
  deleteByPerformanceReviewEvaluationKpiResultId?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultPerformanceReviewEvaluationKpiResultPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11NodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey7Update>
  >;
  updateByPerformanceReviewEvaluationKpiResultId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11UsingPerformanceReviewEvaluationKpiResultPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11PerformanceReviewEvaluationKpiResultCreateInput {
  description?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiResultId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiTarget?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11Input>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  value: Scalars['BigFloat']['input'];
  valueUnit?: InputMaybe<Scalars['String']['input']>;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11PerformanceReviewEvaluationKpiTargetCreateInput {
  description: Scalars['String']['input'];
  invertedAchievementRate?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7Input>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  value: Scalars['BigFloat']['input'];
  valueUnit: Scalars['String']['input'];
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12Input {
  connectByNodeId?: InputMaybe<PerformanceReviewEvaluationNodeIdConnect>;
  connectByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyConnect>;
  create?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12PerformanceReviewEvaluationCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewEvaluationNodeIdDelete>;
  deleteByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12NodeIdUpdate>;
  updateByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12UsingPerformanceReviewEvaluationPkeyUpdate>;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackNodeIdConnect>>;
  connectByPerformanceReviewEvaluationFeedbackId?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackPerformanceReviewEvaluationFeedbackPkeyConnect>>;
  connectByPerformanceReviewEvaluationIdAndFeedbackEmployeeId?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackPerformanceReviewEvaluationPerformanceReviewEvaluatioKey8Connect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12PerformanceReviewEvaluationFeedbackCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationFeedbackId?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackPerformanceReviewEvaluationFeedbackPkeyDelete>>;
  deleteByPerformanceReviewEvaluationIdAndFeedbackEmployeeId?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackPerformanceReviewEvaluationPerformanceReviewEvaluatioKey8Delete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12NodeIdUpdate>>;
  updateByPerformanceReviewEvaluationFeedbackId?: InputMaybe<
    Array<PerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12UsingPerformanceReviewEvaluationFeedbackPkeyUpdate>
  >;
  updateByPerformanceReviewEvaluationIdAndFeedbackEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey8Update>
  >;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12PerformanceReviewEvaluationCreateInput {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12PerformanceReviewEvaluationFeedbackCreateInput {
  employee?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInput>;
  feedbackComment: Scalars['String']['input'];
  feedbackEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  feedbackEmployeeName?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12Input>;
  performanceReviewEvaluationFeedbackId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13Input {
  connectByNodeId?: InputMaybe<PerformanceReviewEvaluationNodeIdConnect>;
  connectByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyConnect>;
  create?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13PerformanceReviewEvaluationCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewEvaluationNodeIdDelete>;
  deleteByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13NodeIdUpdate>;
  updateByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13UsingPerformanceReviewEvaluationPkeyUpdate>;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorNodeIdConnect>>;
  connectByPerformanceReviewEvaluationEvaluatorId?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorPerformanceReviewEvaluationEvaluatorPkeyConnect>>;
  connectByPerformanceReviewEvaluationIdAndEvaluatorId?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorPerformanceReviewEvaluationPerformanceReviewEvaluatioKey9Connect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13PerformanceReviewEvaluationEvaluatorCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationEvaluatorId?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorPerformanceReviewEvaluationEvaluatorPkeyDelete>>;
  deleteByPerformanceReviewEvaluationIdAndEvaluatorId?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorPerformanceReviewEvaluationPerformanceReviewEvaluatioKey9Delete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13NodeIdUpdate>>;
  updateByPerformanceReviewEvaluationEvaluatorId?: InputMaybe<
    Array<PerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13UsingPerformanceReviewEvaluationEvaluatorPkeyUpdate>
  >;
  updateByPerformanceReviewEvaluationIdAndEvaluatorId?: InputMaybe<
    Array<PerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey9Update>
  >;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13PerformanceReviewEvaluationCreateInput {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13PerformanceReviewEvaluationEvaluatorCreateInput {
  employee?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInput>;
  evaluatorId?: InputMaybe<Scalars['BigInt']['input']>;
  evaluatorType?: InputMaybe<PerformanceReviewEvaluationEvaluatorType>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13Input>;
  performanceReviewEvaluationEvaluatorId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14Input {
  connectByNodeId?: InputMaybe<PerformanceReviewEvaluationNodeIdConnect>;
  connectByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyConnect>;
  create?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14PerformanceReviewEvaluationCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewEvaluationNodeIdDelete>;
  deleteByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationOneOnOneOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14NodeIdUpdate>;
  updateByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14UsingPerformanceReviewEvaluationPkeyUpdate>;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOneNodeIdConnect>>;
  connectByPerformanceReviewEvaluationOneOnOneId?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOnePerformanceReviewEvaluationOneOnOnePkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14PerformanceReviewEvaluationOneOnOneCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOneNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationOneOnOneId?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOnePerformanceReviewEvaluationOneOnOnePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14NodeIdUpdate>>;
  updateByPerformanceReviewEvaluationOneOnOneId?: InputMaybe<
    Array<PerformanceReviewEvaluationOneOnOneOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14UsingPerformanceReviewEvaluationOneOnOnePkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14PerformanceReviewEvaluationCreateInput {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14PerformanceReviewEvaluationOneOnOneCreateInput {
  month: Scalars['Int']['input'];
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14Input>;
  performanceReviewEvaluationOneOnOneId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterOneOnOne?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterOnFkeyInput>;
  performanceReviewMasterOneOnOneId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1Input {
  connectByNodeId?: InputMaybe<PerformanceReviewEvaluationNodeIdConnect>;
  connectByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyConnect>;
  create?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1PerformanceReviewEvaluationCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewEvaluationNodeIdDelete>;
  deleteByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1NodeIdUpdate>;
  updateByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1UsingPerformanceReviewEvaluationPkeyUpdate>;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyNodeIdConnect>>;
  connectByPerformanceReviewEvaluationCompetencyId?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationCompetencyPkeyConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Connect>
  >;
  create?: InputMaybe<Array<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1PerformanceReviewEvaluationCompetencyCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationCompetencyId?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationCompetencyPkeyDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Delete>
  >;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1NodeIdUpdate>>;
  updateByPerformanceReviewEvaluationCompetencyId?: InputMaybe<
    Array<PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1UsingPerformanceReviewEvaluationCompetencyPkeyUpdate>
  >;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Update>
  >;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1PerformanceReviewEvaluationCompetencyCreateInput {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1Input>;
  performanceReviewEvaluationCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetency?: InputMaybe<PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10Input>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1PerformanceReviewEvaluationCreateInput {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2Input {
  connectByNodeId?: InputMaybe<PerformanceReviewEvaluationNodeIdConnect>;
  connectByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyConnect>;
  create?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2PerformanceReviewEvaluationCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewEvaluationNodeIdDelete>;
  deleteByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2NodeIdUpdate>;
  updateByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2UsingPerformanceReviewEvaluationPkeyUpdate>;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionPerformanceReviewEvaluationPerformanceReviewEvaluatioKey2Connect>>;
  connectByPerformanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionPerformanceReviewEvaluationQualitativeDescriptionPkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2PerformanceReviewEvaluationQualitativeDescriptionCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionPerformanceReviewEvaluationPerformanceReviewEvaluatioKey2Delete>>;
  deleteByPerformanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionPerformanceReviewEvaluationQualitativeDescriptionPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2NodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey2Update>
  >;
  updateByPerformanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2UsingPerformanceReviewEvaluationQualitativeDescriptionPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2PerformanceReviewEvaluationCreateInput {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2PerformanceReviewEvaluationQualitativeDescriptionCreateInput {
  definitionForScore100Percent?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  evaluatorExpectation?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2Input>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4InverseInput>;
  performanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  userAccount?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3Input {
  connectByNodeId?: InputMaybe<PerformanceReviewEvaluationNodeIdConnect>;
  connectByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyConnect>;
  create?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3PerformanceReviewEvaluationCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewEvaluationNodeIdDelete>;
  deleteByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3NodeIdUpdate>;
  updateByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3UsingPerformanceReviewEvaluationPkeyUpdate>;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Connect>
  >;
  connectByPerformanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationQualitativeAssessmentPkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3PerformanceReviewEvaluationQualitativeAssessmentCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Delete>
  >;
  deleteByPerformanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationQualitativeAssessmentPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3NodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Update>
  >;
  updateByPerformanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3UsingPerformanceReviewEvaluationQualitativeAssessmentPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3PerformanceReviewEvaluationCreateInput {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3PerformanceReviewEvaluationQualitativeAssessmentCreateInput {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3Input>;
  performanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4Input>;
  performanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4Input {
  connectByNodeId?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionNodeIdConnect>;
  connectByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionPerformanceReviewEvaluationPerformanceReviewEvaluatioKey2Connect>;
  connectByPerformanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionPerformanceReviewEvaluationQualitativeDescriptionPkeyConnect>;
  create?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4PerformanceReviewEvaluationQualitativeDescriptionCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionNodeIdDelete>;
  deleteByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionPerformanceReviewEvaluationPerformanceReviewEvaluatioKey2Delete>;
  deleteByPerformanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionPerformanceReviewEvaluationQualitativeDescriptionPkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4NodeIdUpdate>;
  updateByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey2Update>;
  updateByPerformanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4UsingPerformanceReviewEvaluationQualitativeDescriptionPkeyUpdate>;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4InverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Connect>
  >;
  connectByPerformanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationQualitativeAssessmentPkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4PerformanceReviewEvaluationQualitativeAssessmentCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Delete>
  >;
  deleteByPerformanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationQualitativeAssessmentPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4NodeIdUpdate>
  >;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Update>
  >;
  updateByPerformanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4UsingPerformanceReviewEvaluationQualitativeAssessmentPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4PerformanceReviewEvaluationQualitativeAssessmentCreateInput {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4Input>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4PerformanceReviewEvaluationQualitativeDescriptionCreateInput {
  definitionForScore100Percent?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  evaluatorExpectation?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  userAccount?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5Input {
  connectByNodeId?: InputMaybe<PerformanceReviewEvaluationNodeIdConnect>;
  connectByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyConnect>;
  create?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5PerformanceReviewEvaluationCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewEvaluationNodeIdDelete>;
  deleteByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5NodeIdUpdate>;
  updateByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5UsingPerformanceReviewEvaluationPkeyUpdate>;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationPerformanceReviewEvaluatioKey4Connect>>;
  connectByPerformanceReviewEvaluationKpiDescriptionId?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationKpiDescriptionPkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5PerformanceReviewEvaluationKpiDescriptionCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationPerformanceReviewEvaluatioKey4Delete>>;
  deleteByPerformanceReviewEvaluationKpiDescriptionId?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationKpiDescriptionPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5NodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey4Update>
  >;
  updateByPerformanceReviewEvaluationKpiDescriptionId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5UsingPerformanceReviewEvaluationKpiDescriptionPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5PerformanceReviewEvaluationCreateInput {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5PerformanceReviewEvaluationKpiDescriptionCreateInput {
  description: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  invertedAchievementRate?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5Input>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9InverseInput>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6Input {
  connectByNodeId?: InputMaybe<PerformanceReviewEvaluationNodeIdConnect>;
  connectByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyConnect>;
  create?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6PerformanceReviewEvaluationCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewEvaluationNodeIdDelete>;
  deleteByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6NodeIdUpdate>;
  updateByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6UsingPerformanceReviewEvaluationPkeyUpdate>;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndName?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiTargetPerformanceReviewEvaluationPerformanceReviewEvaluatioKey5Connect>
  >;
  connectByPerformanceReviewEvaluationKpiTargetId?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetPerformanceReviewEvaluationKpiTargetPkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6PerformanceReviewEvaluationKpiTargetCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndName?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiTargetPerformanceReviewEvaluationPerformanceReviewEvaluatioKey5Delete>
  >;
  deleteByPerformanceReviewEvaluationKpiTargetId?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetPerformanceReviewEvaluationKpiTargetPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6NodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndName?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey5Update>
  >;
  updateByPerformanceReviewEvaluationKpiTargetId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6UsingPerformanceReviewEvaluationKpiTargetPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6PerformanceReviewEvaluationCreateInput {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6PerformanceReviewEvaluationKpiTargetCreateInput {
  description: Scalars['String']['input'];
  invertedAchievementRate?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6Input>;
  performanceReviewEvaluationKpiDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7Input>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11InverseInput>;
  performanceReviewEvaluationKpiTargetId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  value: Scalars['BigFloat']['input'];
  valueUnit: Scalars['String']['input'];
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7Input {
  connectByNodeId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionNodeIdConnect>;
  connectByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationPerformanceReviewEvaluatioKey4Connect>;
  connectByPerformanceReviewEvaluationKpiDescriptionId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationKpiDescriptionPkeyConnect>;
  create?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7PerformanceReviewEvaluationKpiDescriptionCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionNodeIdDelete>;
  deleteByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationPerformanceReviewEvaluatioKey4Delete>;
  deleteByPerformanceReviewEvaluationKpiDescriptionId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationKpiDescriptionPkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7NodeIdUpdate>;
  updateByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey4Update>;
  updateByPerformanceReviewEvaluationKpiDescriptionId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7UsingPerformanceReviewEvaluationKpiDescriptionPkeyUpdate>;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7InverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndName?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiTargetPerformanceReviewEvaluationPerformanceReviewEvaluatioKey5Connect>
  >;
  connectByPerformanceReviewEvaluationKpiTargetId?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetPerformanceReviewEvaluationKpiTargetPkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7PerformanceReviewEvaluationKpiTargetCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndName?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiTargetPerformanceReviewEvaluationPerformanceReviewEvaluatioKey5Delete>
  >;
  deleteByPerformanceReviewEvaluationKpiTargetId?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetPerformanceReviewEvaluationKpiTargetPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7NodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndName?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey5Update>
  >;
  updateByPerformanceReviewEvaluationKpiTargetId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7UsingPerformanceReviewEvaluationKpiTargetPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7PerformanceReviewEvaluationKpiDescriptionCreateInput {
  description: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  invertedAchievementRate?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7PerformanceReviewEvaluationKpiTargetCreateInput {
  description: Scalars['String']['input'];
  invertedAchievementRate?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7Input>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11InverseInput>;
  performanceReviewEvaluationKpiTargetId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  value: Scalars['BigFloat']['input'];
  valueUnit: Scalars['String']['input'];
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8Input {
  connectByNodeId?: InputMaybe<PerformanceReviewEvaluationNodeIdConnect>;
  connectByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyConnect>;
  create?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8PerformanceReviewEvaluationCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewEvaluationNodeIdDelete>;
  deleteByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8NodeIdUpdate>;
  updateByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8UsingPerformanceReviewEvaluationPkeyUpdate>;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey6Connect>
  >;
  connectByPerformanceReviewEvaluationKpiAssessmentId?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentPerformanceReviewEvaluationKpiAssessmentPkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8PerformanceReviewEvaluationKpiAssessmentCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey6Delete>
  >;
  deleteByPerformanceReviewEvaluationKpiAssessmentId?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentPerformanceReviewEvaluationKpiAssessmentPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8NodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey6Update>
  >;
  updateByPerformanceReviewEvaluationKpiAssessmentId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8UsingPerformanceReviewEvaluationKpiAssessmentPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8PerformanceReviewEvaluationCreateInput {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8PerformanceReviewEvaluationKpiAssessmentCreateInput {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8Input>;
  performanceReviewEvaluationKpiAssessmentId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9Input>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9Input {
  connectByNodeId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionNodeIdConnect>;
  connectByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationPerformanceReviewEvaluatioKey4Connect>;
  connectByPerformanceReviewEvaluationKpiDescriptionId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationKpiDescriptionPkeyConnect>;
  create?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9PerformanceReviewEvaluationKpiDescriptionCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionNodeIdDelete>;
  deleteByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationPerformanceReviewEvaluatioKey4Delete>;
  deleteByPerformanceReviewEvaluationKpiDescriptionId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationKpiDescriptionPkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9NodeIdUpdate>;
  updateByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey4Update>;
  updateByPerformanceReviewEvaluationKpiDescriptionId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9UsingPerformanceReviewEvaluationKpiDescriptionPkeyUpdate>;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9InverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey6Connect>
  >;
  connectByPerformanceReviewEvaluationKpiAssessmentId?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentPerformanceReviewEvaluationKpiAssessmentPkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9PerformanceReviewEvaluationKpiAssessmentCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey6Delete>
  >;
  deleteByPerformanceReviewEvaluationKpiAssessmentId?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentPerformanceReviewEvaluationKpiAssessmentPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9NodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey6Update>
  >;
  updateByPerformanceReviewEvaluationKpiAssessmentId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9UsingPerformanceReviewEvaluationKpiAssessmentPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9PerformanceReviewEvaluationKpiAssessmentCreateInput {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessmentId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9Input>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9PerformanceReviewEvaluationKpiDescriptionCreateInput {
  description: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  invertedAchievementRate?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluation extends Node {
  __typename?: 'PerformanceReviewEvaluation';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  employee: Employee;
  employeeId: Scalars['BigInt']['output'];
  employeeTpHistory?: Maybe<EmployeeTpHistory>;
  employeeTpHistoryId?: Maybe<Scalars['BigInt']['output']>;
  isLockedAssessment: Scalars['Boolean']['output'];
  isLockedDescription: Scalars['Boolean']['output'];
  kpiWeightTotal?: Maybe<Scalars['Int']['output']>;
  masterWeightingDetail?: Maybe<PerformanceReviewMasterWeightingDetail>;
  midLongTermGoal?: Maybe<Scalars['String']['output']>;
  needInputCompetency?: Maybe<Scalars['Boolean']['output']>;
  needInputTargetKpi?: Maybe<Scalars['Boolean']['output']>;
  needInputTargetQualitative?: Maybe<Scalars['Boolean']['output']>;
  nodeId: Scalars['ID']['output'];
  performanceReviewEvaluationCompetencies: PerformanceReviewEvaluationCompetenciesConnection;
  performanceReviewEvaluationEvaluators: PerformanceReviewEvaluationEvaluatorsConnection;
  performanceReviewEvaluationFeedbacks: PerformanceReviewEvaluationFeedbacksConnection;
  performanceReviewEvaluationId: Scalars['BigInt']['output'];
  performanceReviewEvaluationKpiAssessments: PerformanceReviewEvaluationKpiAssessmentsConnection;
  performanceReviewEvaluationKpiDescriptions: PerformanceReviewEvaluationKpiDescriptionsConnection;
  performanceReviewEvaluationKpiResults: PerformanceReviewEvaluationKpiResultsConnection;
  performanceReviewEvaluationKpiTargets: PerformanceReviewEvaluationKpiTargetsConnection;
  performanceReviewEvaluationOneOnOneSelfLists: PerformanceReviewEvaluationOneOnOneSelfListsConnection;
  performanceReviewEvaluationOneOnOnes: PerformanceReviewEvaluationOneOnOnesConnection;
  performanceReviewEvaluationQualitativeAssessments: PerformanceReviewEvaluationQualitativeAssessmentsConnection;
  performanceReviewEvaluationQualitativeDescriptions: PerformanceReviewEvaluationQualitativeDescriptionsConnection;
  performanceReviewEvaluationValues: PerformanceReviewEvaluationValuesConnection;
  performanceReviewMasterCompetencyRole?: Maybe<PerformanceReviewMasterCompetencyRole>;
  performanceReviewMasterCompetencyRoleId?: Maybe<Scalars['BigInt']['output']>;
  qualitativeWeightTotal?: Maybe<Scalars['Int']['output']>;
  quarter: PerformanceReviewQuarter;
  remark?: Maybe<Scalars['String']['output']>;
  scoreCompetency: PerformanceReviewEvaluationScoreCompetencyConnection;
  scoreKpi: PerformanceReviewEvaluationScoreKpiConnection;
  scoreQualitative: PerformanceReviewEvaluationScoreQualitativeConnection;
  scoreValue: PerformanceReviewEvaluationScoreValueConnection;
  scores: PerformanceReviewEvaluationScoresConnection;
  status?: Maybe<PerformanceReviewEvaluationStatus>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
  year: Scalars['Int']['output'];
}

export type PerformanceReviewEvaluationPerformanceReviewEvaluationCompetenciesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationCompetencyCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationCompetencyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationCompetenciesOrderBy>>;
};

export type PerformanceReviewEvaluationPerformanceReviewEvaluationEvaluatorsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationEvaluatorCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationEvaluatorFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorsOrderBy>>;
};

export type PerformanceReviewEvaluationPerformanceReviewEvaluationFeedbacksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationFeedbackCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationFeedbackFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationFeedbacksOrderBy>>;
};

export type PerformanceReviewEvaluationPerformanceReviewEvaluationKpiAssessmentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentsOrderBy>>;
};

export type PerformanceReviewEvaluationPerformanceReviewEvaluationKpiDescriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionsOrderBy>>;
};

export type PerformanceReviewEvaluationPerformanceReviewEvaluationKpiResultsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationKpiResultCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationKpiResultFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultsOrderBy>>;
};

export type PerformanceReviewEvaluationPerformanceReviewEvaluationKpiTargetsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationKpiTargetCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationKpiTargetFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetsOrderBy>>;
};

export type PerformanceReviewEvaluationPerformanceReviewEvaluationOneOnOneSelfListsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationOneOnOneSelfListCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationOneOnOneSelfListFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOneSelfListsOrderBy>>;
};

export type PerformanceReviewEvaluationPerformanceReviewEvaluationOneOnOnesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationOneOnOneCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationOneOnOneFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOnesOrderBy>>;
};

export type PerformanceReviewEvaluationPerformanceReviewEvaluationQualitativeAssessmentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentsOrderBy>>;
};

export type PerformanceReviewEvaluationPerformanceReviewEvaluationQualitativeDescriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionsOrderBy>>;
};

export type PerformanceReviewEvaluationPerformanceReviewEvaluationValuesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationValueCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationValueFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationValuesOrderBy>>;
};

export type PerformanceReviewEvaluationScoreCompetencyArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<PerformanceReviewEvaluationScoreCompetencyRecordFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSelfAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PerformanceReviewEvaluationScoreKpiArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<PerformanceReviewEvaluationScoreKpiRecordFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PerformanceReviewEvaluationScoreQualitativeArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<PerformanceReviewEvaluationScoreQualitativeRecordFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSelfAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PerformanceReviewEvaluationScoreValueArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<PerformanceReviewEvaluationScoreValueRecordFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSelfAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PerformanceReviewEvaluationScoresArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<PerformanceReviewEvaluationScoresRecordFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSelfAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export interface PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInput {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyEmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyUsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyNodeIdUpdate>;
}

export interface PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyNodeIdConnect>>;
  connectByPerformanceReviewEvaluationCompetencyId?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationCompetencyPkeyConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Connect>
  >;
  create?: InputMaybe<Array<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyPerformanceReviewEvaluationCompetencyCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationCompetencyId?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationCompetencyPkeyDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Delete>
  >;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<EmployeeOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationCompetencyId?: InputMaybe<
    Array<PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyUsingPerformanceReviewEvaluationCompetencyPkeyUpdate>
  >;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Update>
  >;
}

export interface PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyPerformanceReviewEvaluationCompetencyCreateInput {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1Input>;
  performanceReviewEvaluationCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetency?: InputMaybe<PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10Input>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationCompetenciesConnection {
  __typename?: 'PerformanceReviewEvaluationCompetenciesConnection';
  edges: Array<PerformanceReviewEvaluationCompetenciesEdge>;
  nodes: Array<PerformanceReviewEvaluationCompetency>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewEvaluationCompetenciesEdge {
  __typename?: 'PerformanceReviewEvaluationCompetenciesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewEvaluationCompetency;
}

export enum PerformanceReviewEvaluationCompetenciesOrderBy {
  ASSESSMENT_COMMENT_ASC = 'ASSESSMENT_COMMENT_ASC',
  ASSESSMENT_COMMENT_DESC = 'ASSESSMENT_COMMENT_DESC',
  ASSESSMENT_EMPLOYEE_ID_ASC = 'ASSESSMENT_EMPLOYEE_ID_ASC',
  ASSESSMENT_EMPLOYEE_ID_DESC = 'ASSESSMENT_EMPLOYEE_ID_DESC',
  ASSESSMENT_VALUE_ASC = 'ASSESSMENT_VALUE_ASC',
  ASSESSMENT_VALUE_DESC = 'ASSESSMENT_VALUE_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__CALCULATION_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__CALCULATION_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__CALCULATION_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__CALCULATION_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DISPLAY_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DISPLAY_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DISPLAY_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DISPLAY_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERCENTAGE_LOWER_BOUND_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERCENTAGE_LOWER_BOUND_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERCENTAGE_LOWER_BOUND_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERCENTAGE_LOWER_BOUND_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERCENTAGE_UPPER_BOUND_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERCENTAGE_UPPER_BOUND_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERCENTAGE_UPPER_BOUND_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERCENTAGE_UPPER_BOUND_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__YEAR_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface PerformanceReviewEvaluationCompetency extends Node {
  __typename?: 'PerformanceReviewEvaluationCompetency';
  assessmentComment?: Maybe<Scalars['String']['output']>;
  assessmentEmployee: Employee;
  assessmentEmployeeId: Scalars['BigInt']['output'];
  assessmentValue?: Maybe<Scalars['BigInt']['output']>;
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  nodeId: Scalars['ID']['output'];
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationCompetencyId: Scalars['BigInt']['output'];
  performanceReviewEvaluationId: Scalars['BigInt']['output'];
  performanceReviewMasterCompetency: PerformanceReviewMasterCompetency;
  performanceReviewMasterCompetencyId: Scalars['String']['output'];
  performanceReviewMasterRatingByAssessmentValue?: Maybe<PerformanceReviewMasterRating>;
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInput {
  connectByNodeId?: InputMaybe<PerformanceReviewMasterRatingNodeIdConnect>;
  connectByPerformanceReviewMasterRatingId?: InputMaybe<PerformanceReviewMasterRatingPerformanceReviewMasterRatingPkeyConnect>;
  create?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyPerformanceReviewMasterRatingCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewMasterRatingNodeIdDelete>;
  deleteByPerformanceReviewMasterRatingId?: InputMaybe<PerformanceReviewMasterRatingPerformanceReviewMasterRatingPkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyAssessmentValueFkeyNodeIdUpdate>;
  updateByPerformanceReviewMasterRatingId?: InputMaybe<PerformanceReviewMasterRatingOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyAssessmentValueFkeyUsingPerformanceReviewMasterRatingPkeyUpdate>;
}

export interface PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyNodeIdConnect>>;
  connectByPerformanceReviewEvaluationCompetencyId?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationCompetencyPkeyConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Connect>
  >;
  create?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyPerformanceReviewEvaluationCompetencyCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationCompetencyId?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationCompetencyPkeyDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Delete>
  >;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewMasterRatingOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyAssessmentValueFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationCompetencyId?: InputMaybe<
    Array<PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyAssessmentValueFkeyUsingPerformanceReviewEvaluationCompetencyPkeyUpdate>
  >;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyAssessmentValueFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Update>
  >;
}

export interface PerformanceReviewEvaluationCompetencyAssessmentValueFkeyPerformanceReviewEvaluationCompetencyCreateInput {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1Input>;
  performanceReviewEvaluationCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetency?: InputMaybe<PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10Input>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationCompetencyAssessmentValueFkeyPerformanceReviewMasterRatingCreateInput {
  calculationValue: Scalars['BigFloat']['input'];
  displayValue: Scalars['Int']['input'];
  percentageLowerBound?: InputMaybe<Scalars['Int']['input']>;
  percentageUpperBound?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInverseInput>;
  performanceReviewMasterRatingDescriptions?: InputMaybe<PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyInverseInput>;
  performanceReviewMasterRatingId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInverseInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluationCompetencyCondition {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  performanceReviewEvaluationCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationCompetencyFilter {
  and?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyFilter>>;
  assessmentComment?: InputMaybe<StringFilter>;
  assessmentEmployee?: InputMaybe<EmployeeFilter>;
  assessmentEmployeeId?: InputMaybe<BigIntFilter>;
  assessmentValue?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  not?: InputMaybe<PerformanceReviewEvaluationCompetencyFilter>;
  or?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyFilter>>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluationFilter>;
  performanceReviewEvaluationCompetencyId?: InputMaybe<BigIntFilter>;
  performanceReviewEvaluationId?: InputMaybe<BigIntFilter>;
  performanceReviewMasterCompetency?: InputMaybe<PerformanceReviewMasterCompetencyFilter>;
  performanceReviewMasterCompetencyId?: InputMaybe<StringFilter>;
  performanceReviewMasterRatingByAssessmentValue?: InputMaybe<PerformanceReviewMasterRatingFilter>;
  performanceReviewMasterRatingByAssessmentValueExists?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface PerformanceReviewEvaluationCompetencyInput {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1Input>;
  performanceReviewEvaluationCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetency?: InputMaybe<PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10Input>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationCompetencyNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewEvaluationCompetencyNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyPatch;
}

export interface PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10UsingPerformanceReviewEvaluationCompetencyPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10Patch;
  performanceReviewEvaluationCompetencyId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Update {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewMasterCompetencyId: Scalars['String']['input'];
}

export interface PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationPatch;
}

export interface PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1UsingPerformanceReviewEvaluationCompetencyPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1Patch;
  performanceReviewEvaluationCompetencyId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Update {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewMasterCompetencyId: Scalars['String']['input'];
}

export interface PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyUsingPerformanceReviewEvaluationCompetencyPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyPatch;
  performanceReviewEvaluationCompetencyId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Update {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewMasterCompetencyId: Scalars['String']['input'];
}

export interface PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyAssessmentValueFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterRatingPatch;
}

export interface PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyAssessmentValueFkeyUsingPerformanceReviewEvaluationCompetencyPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyAssessmentValueFkeyPatch;
  performanceReviewEvaluationCompetencyId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyAssessmentValueFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Update {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyAssessmentValueFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewMasterCompetencyId: Scalars['String']['input'];
}

export interface PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserCreatedFkeyUsingPerformanceReviewEvaluationCompetencyPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserCreatedFkeyPatch;
  performanceReviewEvaluationCompetencyId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserCreatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Update {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserCreatedFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewMasterCompetencyId: Scalars['String']['input'];
}

export interface PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserUpdatedFkeyUsingPerformanceReviewEvaluationCompetencyPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserUpdatedFkeyPatch;
  performanceReviewEvaluationCompetencyId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserUpdatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Update {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserUpdatedFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewMasterCompetencyId: Scalars['String']['input'];
}

export interface PerformanceReviewEvaluationCompetencyPatch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1Input>;
  performanceReviewEvaluationCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetency?: InputMaybe<PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10Input>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationCompetencyPkeyConnect {
  performanceReviewEvaluationCompetencyId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationCompetencyPkeyDelete {
  performanceReviewEvaluationCompetencyId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Connect {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewMasterCompetencyId: Scalars['String']['input'];
}

export interface PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Delete {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewMasterCompetencyId: Scalars['String']['input'];
}

export interface PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyNodeIdConnect>>;
  connectByPerformanceReviewEvaluationCompetencyId?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationCompetencyPkeyConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Connect>
  >;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationCompetencyId?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationCompetencyPkeyDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Delete>
  >;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationCompetencyId?: InputMaybe<
    Array<PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserCreatedFkeyUsingPerformanceReviewEvaluationCompetencyPkeyUpdate>
  >;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserCreatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Update>
  >;
}

export interface PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyNodeIdConnect>>;
  connectByPerformanceReviewEvaluationCompetencyId?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationCompetencyPkeyConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Connect>
  >;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationCompetencyId?: InputMaybe<Array<PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationCompetencyPkeyDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationCompetencyPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Delete>
  >;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationCompetencyId?: InputMaybe<
    Array<PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserUpdatedFkeyUsingPerformanceReviewEvaluationCompetencyPkeyUpdate>
  >;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserUpdatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey1Update>
  >;
}

export interface PerformanceReviewEvaluationCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewEvaluationCount {
  __typename?: 'PerformanceReviewEvaluationCount';
  assessmentCompleted: Scalars['Int']['output'];
  company: Company;
  companyId: Scalars['BigInt']['output'];
  needToBeAssessed: Scalars['Int']['output'];
  needToSetTarget: Scalars['Int']['output'];
  possibleToLockAssessment: Scalars['Int']['output'];
  possibleToLockDescription: Scalars['Int']['output'];
  possibleToUnlockAssessment: Scalars['Int']['output'];
  possibleToUnlockDescription: Scalars['Int']['output'];
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['output'];
}

export interface PerformanceReviewEvaluationCountCondition {
  assessmentCompleted?: InputMaybe<Scalars['Int']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  needToBeAssessed?: InputMaybe<Scalars['Int']['input']>;
  needToSetTarget?: InputMaybe<Scalars['Int']['input']>;
  possibleToLockAssessment?: InputMaybe<Scalars['Int']['input']>;
  possibleToLockDescription?: InputMaybe<Scalars['Int']['input']>;
  possibleToUnlockAssessment?: InputMaybe<Scalars['Int']['input']>;
  possibleToUnlockDescription?: InputMaybe<Scalars['Int']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewEvaluationCountFilter {
  and?: InputMaybe<Array<PerformanceReviewEvaluationCountFilter>>;
  assessmentCompleted?: InputMaybe<IntFilter>;
  company?: InputMaybe<CompanyFilter>;
  companyExists?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<BigIntFilter>;
  needToBeAssessed?: InputMaybe<IntFilter>;
  needToSetTarget?: InputMaybe<IntFilter>;
  not?: InputMaybe<PerformanceReviewEvaluationCountFilter>;
  or?: InputMaybe<Array<PerformanceReviewEvaluationCountFilter>>;
  possibleToLockAssessment?: InputMaybe<IntFilter>;
  possibleToLockDescription?: InputMaybe<IntFilter>;
  possibleToUnlockAssessment?: InputMaybe<IntFilter>;
  possibleToUnlockDescription?: InputMaybe<IntFilter>;
  quarter?: InputMaybe<PerformanceReviewQuarterFilter>;
  year?: InputMaybe<IntFilter>;
}

export interface PerformanceReviewEvaluationCountsConnection {
  __typename?: 'PerformanceReviewEvaluationCountsConnection';
  edges: Array<PerformanceReviewEvaluationCountsEdge>;
  nodes: Array<PerformanceReviewEvaluationCount>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewEvaluationCountsEdge {
  __typename?: 'PerformanceReviewEvaluationCountsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewEvaluationCount;
}

export enum PerformanceReviewEvaluationCountsOrderBy {
  ASSESSMENT_COMPLETED_ASC = 'ASSESSMENT_COMPLETED_ASC',
  ASSESSMENT_COMPLETED_DESC = 'ASSESSMENT_COMPLETED_DESC',
  COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC',
  COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_ASC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_ASC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_DESC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_DESC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANY_BY_COMPANY_ID__REMARK_ASC = 'COMPANY_BY_COMPANY_ID__REMARK_ASC',
  COMPANY_BY_COMPANY_ID__REMARK_DESC = 'COMPANY_BY_COMPANY_ID__REMARK_DESC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC',
  COMPANY_ID_ASC = 'COMPANY_ID_ASC',
  COMPANY_ID_DESC = 'COMPANY_ID_DESC',
  NATURAL = 'NATURAL',
  NEED_TO_BE_ASSESSED_ASC = 'NEED_TO_BE_ASSESSED_ASC',
  NEED_TO_BE_ASSESSED_DESC = 'NEED_TO_BE_ASSESSED_DESC',
  NEED_TO_SET_TARGET_ASC = 'NEED_TO_SET_TARGET_ASC',
  NEED_TO_SET_TARGET_DESC = 'NEED_TO_SET_TARGET_DESC',
  POSSIBLE_TO_LOCK_ASSESSMENT_ASC = 'POSSIBLE_TO_LOCK_ASSESSMENT_ASC',
  POSSIBLE_TO_LOCK_ASSESSMENT_DESC = 'POSSIBLE_TO_LOCK_ASSESSMENT_DESC',
  POSSIBLE_TO_LOCK_DESCRIPTION_ASC = 'POSSIBLE_TO_LOCK_DESCRIPTION_ASC',
  POSSIBLE_TO_LOCK_DESCRIPTION_DESC = 'POSSIBLE_TO_LOCK_DESCRIPTION_DESC',
  POSSIBLE_TO_UNLOCK_ASSESSMENT_ASC = 'POSSIBLE_TO_UNLOCK_ASSESSMENT_ASC',
  POSSIBLE_TO_UNLOCK_ASSESSMENT_DESC = 'POSSIBLE_TO_UNLOCK_ASSESSMENT_DESC',
  POSSIBLE_TO_UNLOCK_DESCRIPTION_ASC = 'POSSIBLE_TO_UNLOCK_DESCRIPTION_ASC',
  POSSIBLE_TO_UNLOCK_DESCRIPTION_DESC = 'POSSIBLE_TO_UNLOCK_DESCRIPTION_DESC',
  QUARTER_ASC = 'QUARTER_ASC',
  QUARTER_DESC = 'QUARTER_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
}

export interface PerformanceReviewEvaluationEmployeeIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface PerformanceReviewEvaluationEmployeeIdFkeyInput {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyEmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeIdFkeyUsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeIdFkeyNodeIdUpdate>;
}

export interface PerformanceReviewEvaluationEmployeeIdFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationNodeIdConnect>>;
  connectByPerformanceReviewEvaluationId?: InputMaybe<Array<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluationEmployeeIdFkeyPerformanceReviewEvaluationCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationId?: InputMaybe<Array<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<EmployeeOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeIdFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationId?: InputMaybe<
    Array<PerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeIdFkeyUsingPerformanceReviewEvaluationPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationEmployeeIdFkeyPerformanceReviewEvaluationCreateInput {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyEmployeeTpHistoryCreateInput {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate: Scalars['Date']['input'];
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput {
  connectByEmployeeIdAndEffectiveDate?: InputMaybe<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyConnect>;
  connectByEmployeeTpHistoryId?: InputMaybe<EmployeeTpHistoryEmployeeTpHistoryPkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeTpHistoryNodeIdConnect>;
  create?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyEmployeeTpHistoryCreateInput>;
  deleteByEmployeeIdAndEffectiveDate?: InputMaybe<EmployeeTpHistoryEmployeeTpHistoryEmployeeIdEffectiveDateKeyDelete>;
  deleteByEmployeeTpHistoryId?: InputMaybe<EmployeeTpHistoryEmployeeTpHistoryPkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeTpHistoryNodeIdDelete>;
  updateByEmployeeIdAndEffectiveDate?: InputMaybe<EmployeeTpHistoryOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeTpHistoryIdFkeyUsingEmployeeTpHistoryEmployeeIdEffectiveDateKeyUpdate>;
  updateByEmployeeTpHistoryId?: InputMaybe<EmployeeTpHistoryOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeTpHistoryIdFkeyUsingEmployeeTpHistoryPkeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeTpHistoryIdFkeyNodeIdUpdate>;
}

export interface PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationNodeIdConnect>>;
  connectByPerformanceReviewEvaluationId?: InputMaybe<Array<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyPerformanceReviewEvaluationCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationId?: InputMaybe<Array<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<EmployeeTpHistoryOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeTpHistoryIdFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationId?: InputMaybe<
    Array<PerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeTpHistoryIdFkeyUsingPerformanceReviewEvaluationPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyPerformanceReviewEvaluationCreateInput {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluationEvaluator extends Node {
  __typename?: 'PerformanceReviewEvaluationEvaluator';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  evaluator: Employee;
  evaluatorId: Scalars['BigInt']['output'];
  evaluatorType: PerformanceReviewEvaluationEvaluatorType;
  nodeId: Scalars['ID']['output'];
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationEvaluatorId: Scalars['BigInt']['output'];
  performanceReviewEvaluationId: Scalars['BigInt']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface PerformanceReviewEvaluationEvaluatorCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  evaluatorId?: InputMaybe<Scalars['BigInt']['input']>;
  evaluatorType?: InputMaybe<PerformanceReviewEvaluationEvaluatorType>;
  performanceReviewEvaluationEvaluatorId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInput {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyEmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyUsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyUsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyNodeIdUpdate>;
}

export interface PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorNodeIdConnect>>;
  connectByPerformanceReviewEvaluationEvaluatorId?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorPerformanceReviewEvaluationEvaluatorPkeyConnect>>;
  connectByPerformanceReviewEvaluationIdAndEvaluatorId?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorPerformanceReviewEvaluationPerformanceReviewEvaluatioKey9Connect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyPerformanceReviewEvaluationEvaluatorCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationEvaluatorId?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorPerformanceReviewEvaluationEvaluatorPkeyDelete>>;
  deleteByPerformanceReviewEvaluationIdAndEvaluatorId?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorPerformanceReviewEvaluationPerformanceReviewEvaluatioKey9Delete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<EmployeeOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationEvaluatorId?: InputMaybe<
    Array<PerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyUsingPerformanceReviewEvaluationEvaluatorPkeyUpdate>
  >;
  updateByPerformanceReviewEvaluationIdAndEvaluatorId?: InputMaybe<
    Array<PerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey9Update>
  >;
}

export interface PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyPerformanceReviewEvaluationEvaluatorCreateInput {
  employee?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInput>;
  evaluatorType?: InputMaybe<PerformanceReviewEvaluationEvaluatorType>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13Input>;
  performanceReviewEvaluationEvaluatorId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationEvaluatorFilter {
  and?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  evaluator?: InputMaybe<EmployeeFilter>;
  evaluatorId?: InputMaybe<BigIntFilter>;
  evaluatorType?: InputMaybe<PerformanceReviewEvaluationEvaluatorTypeFilter>;
  not?: InputMaybe<PerformanceReviewEvaluationEvaluatorFilter>;
  or?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorFilter>>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluationFilter>;
  performanceReviewEvaluationEvaluatorId?: InputMaybe<BigIntFilter>;
  performanceReviewEvaluationId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface PerformanceReviewEvaluationEvaluatorInput {
  employee?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInput>;
  evaluatorId?: InputMaybe<Scalars['BigInt']['input']>;
  evaluatorType?: InputMaybe<PerformanceReviewEvaluationEvaluatorType>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13Input>;
  performanceReviewEvaluationEvaluatorId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationEvaluatorNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewEvaluationEvaluatorNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationPatch;
}

export interface PerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13UsingPerformanceReviewEvaluationEvaluatorPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13Patch;
  performanceReviewEvaluationEvaluatorId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey9Update {
  evaluatorId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface PerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyUsingPerformanceReviewEvaluationEvaluatorPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyPatch;
  performanceReviewEvaluationEvaluatorId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey9Update {
  evaluatorId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserCreatedFkeyUsingPerformanceReviewEvaluationEvaluatorPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserCreatedFkeyPatch;
  performanceReviewEvaluationEvaluatorId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserCreatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey9Update {
  evaluatorId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserCreatedFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserUpdatedFkeyUsingPerformanceReviewEvaluationEvaluatorPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserUpdatedFkeyPatch;
  performanceReviewEvaluationEvaluatorId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserUpdatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey9Update {
  evaluatorId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserUpdatedFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationEvaluatorPatch {
  employee?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInput>;
  evaluatorId?: InputMaybe<Scalars['BigInt']['input']>;
  evaluatorType?: InputMaybe<PerformanceReviewEvaluationEvaluatorType>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13Input>;
  performanceReviewEvaluationEvaluatorId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationEvaluatorPerformanceReviewEvaluationEvaluatorPkeyConnect {
  performanceReviewEvaluationEvaluatorId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationEvaluatorPerformanceReviewEvaluationEvaluatorPkeyDelete {
  performanceReviewEvaluationEvaluatorId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationEvaluatorPerformanceReviewEvaluationPerformanceReviewEvaluatioKey9Connect {
  evaluatorId: Scalars['BigInt']['input'];
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationEvaluatorPerformanceReviewEvaluationPerformanceReviewEvaluatioKey9Delete {
  evaluatorId: Scalars['BigInt']['input'];
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export enum PerformanceReviewEvaluationEvaluatorType {
  DOTTED_LINE_MANAGER = 'DOTTED_LINE_MANAGER',
  OTHER = 'OTHER',
  PRIMARY_MANAGER = 'PRIMARY_MANAGER',
}

export interface PerformanceReviewEvaluationEvaluatorTypeFilter {
  distinctFrom?: InputMaybe<PerformanceReviewEvaluationEvaluatorType>;
  equalTo?: InputMaybe<PerformanceReviewEvaluationEvaluatorType>;
  greaterThan?: InputMaybe<PerformanceReviewEvaluationEvaluatorType>;
  greaterThanOrEqualTo?: InputMaybe<PerformanceReviewEvaluationEvaluatorType>;
  in?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorType>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<PerformanceReviewEvaluationEvaluatorType>;
  lessThanOrEqualTo?: InputMaybe<PerformanceReviewEvaluationEvaluatorType>;
  notDistinctFrom?: InputMaybe<PerformanceReviewEvaluationEvaluatorType>;
  notEqualTo?: InputMaybe<PerformanceReviewEvaluationEvaluatorType>;
  notIn?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorType>>;
}

export interface PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorNodeIdConnect>>;
  connectByPerformanceReviewEvaluationEvaluatorId?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorPerformanceReviewEvaluationEvaluatorPkeyConnect>>;
  connectByPerformanceReviewEvaluationIdAndEvaluatorId?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorPerformanceReviewEvaluationPerformanceReviewEvaluatioKey9Connect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationEvaluatorId?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorPerformanceReviewEvaluationEvaluatorPkeyDelete>>;
  deleteByPerformanceReviewEvaluationIdAndEvaluatorId?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorPerformanceReviewEvaluationPerformanceReviewEvaluatioKey9Delete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationEvaluatorId?: InputMaybe<
    Array<PerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserCreatedFkeyUsingPerformanceReviewEvaluationEvaluatorPkeyUpdate>
  >;
  updateByPerformanceReviewEvaluationIdAndEvaluatorId?: InputMaybe<
    Array<PerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserCreatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey9Update>
  >;
}

export interface PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorNodeIdConnect>>;
  connectByPerformanceReviewEvaluationEvaluatorId?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorPerformanceReviewEvaluationEvaluatorPkeyConnect>>;
  connectByPerformanceReviewEvaluationIdAndEvaluatorId?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorPerformanceReviewEvaluationPerformanceReviewEvaluatioKey9Connect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationEvaluatorId?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorPerformanceReviewEvaluationEvaluatorPkeyDelete>>;
  deleteByPerformanceReviewEvaluationIdAndEvaluatorId?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorPerformanceReviewEvaluationPerformanceReviewEvaluatioKey9Delete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationEvaluatorId?: InputMaybe<
    Array<PerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserUpdatedFkeyUsingPerformanceReviewEvaluationEvaluatorPkeyUpdate>
  >;
  updateByPerformanceReviewEvaluationIdAndEvaluatorId?: InputMaybe<
    Array<PerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserUpdatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey9Update>
  >;
}

export interface PerformanceReviewEvaluationEvaluatorsConnection {
  __typename?: 'PerformanceReviewEvaluationEvaluatorsConnection';
  edges: Array<PerformanceReviewEvaluationEvaluatorsEdge>;
  nodes: Array<PerformanceReviewEvaluationEvaluator>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewEvaluationEvaluatorsEdge {
  __typename?: 'PerformanceReviewEvaluationEvaluatorsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewEvaluationEvaluator;
}

export enum PerformanceReviewEvaluationEvaluatorsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__BANK_ID_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__BANK_ID_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__BANK_ID_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__BANK_ID_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__GENDER_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__GENDER_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__GENDER_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__GENDER_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__NICKNAME_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__NICKNAME_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__NICKNAME_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__NICKNAME_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__REMARK_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__REMARK_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__REMARK_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__REMARK_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__STATUS_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__STATUS_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__STATUS_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__STATUS_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EVALUATOR_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_EVALUATOR_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_EVALUATOR_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_EVALUATOR_ID__WORK_PERMIT_NUMBER_DESC',
  EVALUATOR_ID_ASC = 'EVALUATOR_ID_ASC',
  EVALUATOR_ID_DESC = 'EVALUATOR_ID_DESC',
  EVALUATOR_TYPE_ASC = 'EVALUATOR_TYPE_ASC',
  EVALUATOR_TYPE_DESC = 'EVALUATOR_TYPE_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInput {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyEmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyUsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyNodeIdUpdate>;
}

export interface PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackNodeIdConnect>>;
  connectByPerformanceReviewEvaluationFeedbackId?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackPerformanceReviewEvaluationFeedbackPkeyConnect>>;
  connectByPerformanceReviewEvaluationIdAndFeedbackEmployeeId?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackPerformanceReviewEvaluationPerformanceReviewEvaluatioKey8Connect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyPerformanceReviewEvaluationFeedbackCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationFeedbackId?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackPerformanceReviewEvaluationFeedbackPkeyDelete>>;
  deleteByPerformanceReviewEvaluationIdAndFeedbackEmployeeId?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackPerformanceReviewEvaluationPerformanceReviewEvaluatioKey8Delete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<EmployeeOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationFeedbackId?: InputMaybe<
    Array<PerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyUsingPerformanceReviewEvaluationFeedbackPkeyUpdate>
  >;
  updateByPerformanceReviewEvaluationIdAndFeedbackEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey8Update>
  >;
}

export interface PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyPerformanceReviewEvaluationFeedbackCreateInput {
  employee?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInput>;
  feedbackComment: Scalars['String']['input'];
  feedbackEmployeeName?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12Input>;
  performanceReviewEvaluationFeedbackId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationFeedback extends Node {
  __typename?: 'PerformanceReviewEvaluationFeedback';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  feedbackComment: Scalars['String']['output'];
  feedbackEmployee?: Maybe<Employee>;
  feedbackEmployeeId?: Maybe<Scalars['BigInt']['output']>;
  feedbackEmployeeName?: Maybe<Scalars['String']['output']>;
  nodeId: Scalars['ID']['output'];
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationFeedbackId: Scalars['BigInt']['output'];
  performanceReviewEvaluationId: Scalars['BigInt']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface PerformanceReviewEvaluationFeedbackCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  feedbackComment?: InputMaybe<Scalars['String']['input']>;
  feedbackEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  feedbackEmployeeName?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationFeedbackId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationFeedbackFilter {
  and?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  feedbackComment?: InputMaybe<StringFilter>;
  feedbackEmployee?: InputMaybe<EmployeeFilter>;
  feedbackEmployeeExists?: InputMaybe<Scalars['Boolean']['input']>;
  feedbackEmployeeId?: InputMaybe<BigIntFilter>;
  feedbackEmployeeName?: InputMaybe<StringFilter>;
  not?: InputMaybe<PerformanceReviewEvaluationFeedbackFilter>;
  or?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackFilter>>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluationFilter>;
  performanceReviewEvaluationFeedbackId?: InputMaybe<BigIntFilter>;
  performanceReviewEvaluationId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface PerformanceReviewEvaluationFeedbackInput {
  employee?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInput>;
  feedbackComment: Scalars['String']['input'];
  feedbackEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  feedbackEmployeeName?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12Input>;
  performanceReviewEvaluationFeedbackId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationFeedbackNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewEvaluationFeedbackNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationPatch;
}

export interface PerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12UsingPerformanceReviewEvaluationFeedbackPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12Patch;
  performanceReviewEvaluationFeedbackId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey8Update {
  feedbackEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface PerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyUsingPerformanceReviewEvaluationFeedbackPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyPatch;
  performanceReviewEvaluationFeedbackId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey8Update {
  feedbackEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserCreatedFkeyUsingPerformanceReviewEvaluationFeedbackPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserCreatedFkeyPatch;
  performanceReviewEvaluationFeedbackId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserCreatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey8Update {
  feedbackEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserCreatedFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserUpdatedFkeyUsingPerformanceReviewEvaluationFeedbackPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserUpdatedFkeyPatch;
  performanceReviewEvaluationFeedbackId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserUpdatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey8Update {
  feedbackEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserUpdatedFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationFeedbackPatch {
  employee?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInput>;
  feedbackComment?: InputMaybe<Scalars['String']['input']>;
  feedbackEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  feedbackEmployeeName?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12Input>;
  performanceReviewEvaluationFeedbackId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationFeedbackPerformanceReviewEvaluationFeedbackPkeyConnect {
  performanceReviewEvaluationFeedbackId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationFeedbackPerformanceReviewEvaluationFeedbackPkeyDelete {
  performanceReviewEvaluationFeedbackId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationFeedbackPerformanceReviewEvaluationPerformanceReviewEvaluatioKey8Connect {
  feedbackEmployeeId: Scalars['BigInt']['input'];
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationFeedbackPerformanceReviewEvaluationPerformanceReviewEvaluatioKey8Delete {
  feedbackEmployeeId: Scalars['BigInt']['input'];
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackNodeIdConnect>>;
  connectByPerformanceReviewEvaluationFeedbackId?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackPerformanceReviewEvaluationFeedbackPkeyConnect>>;
  connectByPerformanceReviewEvaluationIdAndFeedbackEmployeeId?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackPerformanceReviewEvaluationPerformanceReviewEvaluatioKey8Connect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationFeedbackId?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackPerformanceReviewEvaluationFeedbackPkeyDelete>>;
  deleteByPerformanceReviewEvaluationIdAndFeedbackEmployeeId?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackPerformanceReviewEvaluationPerformanceReviewEvaluatioKey8Delete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationFeedbackId?: InputMaybe<
    Array<PerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserCreatedFkeyUsingPerformanceReviewEvaluationFeedbackPkeyUpdate>
  >;
  updateByPerformanceReviewEvaluationIdAndFeedbackEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserCreatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey8Update>
  >;
}

export interface PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackNodeIdConnect>>;
  connectByPerformanceReviewEvaluationFeedbackId?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackPerformanceReviewEvaluationFeedbackPkeyConnect>>;
  connectByPerformanceReviewEvaluationIdAndFeedbackEmployeeId?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackPerformanceReviewEvaluationPerformanceReviewEvaluatioKey8Connect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationFeedbackId?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackPerformanceReviewEvaluationFeedbackPkeyDelete>>;
  deleteByPerformanceReviewEvaluationIdAndFeedbackEmployeeId?: InputMaybe<Array<PerformanceReviewEvaluationFeedbackPerformanceReviewEvaluationPerformanceReviewEvaluatioKey8Delete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationFeedbackId?: InputMaybe<
    Array<PerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserUpdatedFkeyUsingPerformanceReviewEvaluationFeedbackPkeyUpdate>
  >;
  updateByPerformanceReviewEvaluationIdAndFeedbackEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserUpdatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey8Update>
  >;
}

export interface PerformanceReviewEvaluationFeedbacksConnection {
  __typename?: 'PerformanceReviewEvaluationFeedbacksConnection';
  edges: Array<PerformanceReviewEvaluationFeedbacksEdge>;
  nodes: Array<PerformanceReviewEvaluationFeedback>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewEvaluationFeedbacksEdge {
  __typename?: 'PerformanceReviewEvaluationFeedbacksEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewEvaluationFeedback;
}

export enum PerformanceReviewEvaluationFeedbacksOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_FEEDBACK_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  FEEDBACK_COMMENT_ASC = 'FEEDBACK_COMMENT_ASC',
  FEEDBACK_COMMENT_DESC = 'FEEDBACK_COMMENT_DESC',
  FEEDBACK_EMPLOYEE_ID_ASC = 'FEEDBACK_EMPLOYEE_ID_ASC',
  FEEDBACK_EMPLOYEE_ID_DESC = 'FEEDBACK_EMPLOYEE_ID_DESC',
  FEEDBACK_EMPLOYEE_NAME_ASC = 'FEEDBACK_EMPLOYEE_NAME_ASC',
  FEEDBACK_EMPLOYEE_NAME_DESC = 'FEEDBACK_EMPLOYEE_NAME_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface PerformanceReviewEvaluationFilter {
  and?: InputMaybe<Array<PerformanceReviewEvaluationFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  employee?: InputMaybe<EmployeeFilter>;
  employeeId?: InputMaybe<BigIntFilter>;
  employeeTpHistory?: InputMaybe<EmployeeTpHistoryFilter>;
  employeeTpHistoryExists?: InputMaybe<Scalars['Boolean']['input']>;
  employeeTpHistoryId?: InputMaybe<BigIntFilter>;
  isLockedAssessment?: InputMaybe<BooleanFilter>;
  isLockedDescription?: InputMaybe<BooleanFilter>;
  kpiWeightTotal?: InputMaybe<IntFilter>;
  midLongTermGoal?: InputMaybe<StringFilter>;
  needInputCompetency?: InputMaybe<BooleanFilter>;
  needInputTargetKpi?: InputMaybe<BooleanFilter>;
  needInputTargetQualitative?: InputMaybe<BooleanFilter>;
  not?: InputMaybe<PerformanceReviewEvaluationFilter>;
  or?: InputMaybe<Array<PerformanceReviewEvaluationFilter>>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationToManyPerformanceReviewEvaluationCompetencyFilter>;
  performanceReviewEvaluationCompetenciesExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationToManyPerformanceReviewEvaluationEvaluatorFilter>;
  performanceReviewEvaluationEvaluatorsExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationToManyPerformanceReviewEvaluationFeedbackFilter>;
  performanceReviewEvaluationFeedbacksExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationId?: InputMaybe<BigIntFilter>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationToManyPerformanceReviewEvaluationKpiAssessmentFilter>;
  performanceReviewEvaluationKpiAssessmentsExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluationToManyPerformanceReviewEvaluationKpiDescriptionFilter>;
  performanceReviewEvaluationKpiDescriptionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluationToManyPerformanceReviewEvaluationKpiResultFilter>;
  performanceReviewEvaluationKpiResultsExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluationToManyPerformanceReviewEvaluationKpiTargetFilter>;
  performanceReviewEvaluationKpiTargetsExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationOneOnOneSelfLists?: InputMaybe<PerformanceReviewEvaluationToManyPerformanceReviewEvaluationOneOnOneSelfListFilter>;
  performanceReviewEvaluationOneOnOneSelfListsExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationToManyPerformanceReviewEvaluationOneOnOneFilter>;
  performanceReviewEvaluationOneOnOnesExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationToManyPerformanceReviewEvaluationQualitativeAssessmentFilter>;
  performanceReviewEvaluationQualitativeAssessmentsExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluationToManyPerformanceReviewEvaluationQualitativeDescriptionFilter>;
  performanceReviewEvaluationQualitativeDescriptionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationToManyPerformanceReviewEvaluationValueFilter>;
  performanceReviewEvaluationValuesExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewMasterCompetencyRoleFilter>;
  performanceReviewMasterCompetencyRoleExists?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<BigIntFilter>;
  qualitativeWeightTotal?: InputMaybe<IntFilter>;
  quarter?: InputMaybe<PerformanceReviewQuarterFilter>;
  remark?: InputMaybe<StringFilter>;
  status?: InputMaybe<PerformanceReviewEvaluationStatusFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
  year?: InputMaybe<IntFilter>;
}

export interface PerformanceReviewEvaluationInput {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInput {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyEmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyUsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyNodeIdUpdate>;
}

export interface PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey6Connect>
  >;
  connectByPerformanceReviewEvaluationKpiAssessmentId?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentPerformanceReviewEvaluationKpiAssessmentPkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyPerformanceReviewEvaluationKpiAssessmentCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey6Delete>
  >;
  deleteByPerformanceReviewEvaluationKpiAssessmentId?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentPerformanceReviewEvaluationKpiAssessmentPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<EmployeeOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey6Update>
  >;
  updateByPerformanceReviewEvaluationKpiAssessmentId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyUsingPerformanceReviewEvaluationKpiAssessmentPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyPerformanceReviewEvaluationKpiAssessmentCreateInput {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessmentId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9Input>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationKpiAssessment extends Node {
  __typename?: 'PerformanceReviewEvaluationKpiAssessment';
  assessmentComment?: Maybe<Scalars['String']['output']>;
  assessmentEmployee: Employee;
  assessmentEmployeeId: Scalars['BigInt']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  nodeId: Scalars['ID']['output'];
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationId: Scalars['BigInt']['output'];
  performanceReviewEvaluationKpiAssessmentId: Scalars['BigInt']['output'];
  performanceReviewEvaluationKpiDescription: PerformanceReviewEvaluationKpiDescription;
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface PerformanceReviewEvaluationKpiAssessmentCondition {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessmentId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationKpiAssessmentFilter {
  and?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentFilter>>;
  assessmentComment?: InputMaybe<StringFilter>;
  assessmentEmployee?: InputMaybe<EmployeeFilter>;
  assessmentEmployeeId?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  not?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentFilter>;
  or?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentFilter>>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluationFilter>;
  performanceReviewEvaluationId?: InputMaybe<BigIntFilter>;
  performanceReviewEvaluationKpiAssessmentId?: InputMaybe<BigIntFilter>;
  performanceReviewEvaluationKpiDescription?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionFilter>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface PerformanceReviewEvaluationKpiAssessmentInput {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessmentId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9Input>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationKpiAssessmentNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewEvaluationKpiAssessmentNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationPatch;
}

export interface PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8UsingPerformanceReviewEvaluationKpiAssessmentPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8Patch;
  performanceReviewEvaluationKpiAssessmentId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey6Update {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationKpiDescriptionPatch;
}

export interface PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9UsingPerformanceReviewEvaluationKpiAssessmentPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9Patch;
  performanceReviewEvaluationKpiAssessmentId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey6Update {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyUsingPerformanceReviewEvaluationKpiAssessmentPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyPatch;
  performanceReviewEvaluationKpiAssessmentId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey6Update {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyUsingPerformanceReviewEvaluationKpiAssessmentPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyPatch;
  performanceReviewEvaluationKpiAssessmentId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey6Update {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyUsingPerformanceReviewEvaluationKpiAssessmentPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyPatch;
  performanceReviewEvaluationKpiAssessmentId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey6Update {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiAssessmentPatch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessmentId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9Input>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationKpiAssessmentPerformanceReviewEvaluationKpiAssessmentPkeyConnect {
  performanceReviewEvaluationKpiAssessmentId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiAssessmentPerformanceReviewEvaluationKpiAssessmentPkeyDelete {
  performanceReviewEvaluationKpiAssessmentId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey6Connect {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey6Delete {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey6Connect>
  >;
  connectByPerformanceReviewEvaluationKpiAssessmentId?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentPerformanceReviewEvaluationKpiAssessmentPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey6Delete>
  >;
  deleteByPerformanceReviewEvaluationKpiAssessmentId?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentPerformanceReviewEvaluationKpiAssessmentPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey6Update>
  >;
  updateByPerformanceReviewEvaluationKpiAssessmentId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyUsingPerformanceReviewEvaluationKpiAssessmentPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey6Connect>
  >;
  connectByPerformanceReviewEvaluationKpiAssessmentId?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentPerformanceReviewEvaluationKpiAssessmentPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey6Delete>
  >;
  deleteByPerformanceReviewEvaluationKpiAssessmentId?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentPerformanceReviewEvaluationKpiAssessmentPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey6Update>
  >;
  updateByPerformanceReviewEvaluationKpiAssessmentId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyUsingPerformanceReviewEvaluationKpiAssessmentPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationKpiAssessmentsConnection {
  __typename?: 'PerformanceReviewEvaluationKpiAssessmentsConnection';
  edges: Array<PerformanceReviewEvaluationKpiAssessmentsEdge>;
  nodes: Array<PerformanceReviewEvaluationKpiAssessment>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewEvaluationKpiAssessmentsEdge {
  __typename?: 'PerformanceReviewEvaluationKpiAssessmentsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewEvaluationKpiAssessment;
}

export enum PerformanceReviewEvaluationKpiAssessmentsOrderBy {
  ASSESSMENT_COMMENT_ASC = 'ASSESSMENT_COMMENT_ASC',
  ASSESSMENT_COMMENT_DESC = 'ASSESSMENT_COMMENT_DESC',
  ASSESSMENT_EMPLOYEE_ID_ASC = 'ASSESSMENT_EMPLOYEE_ID_ASC',
  ASSESSMENT_EMPLOYEE_ID_DESC = 'ASSESSMENT_EMPLOYEE_ID_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__INVERTED_ACHIEVEMENT_RATE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__INVERTED_ACHIEVEMENT_RATE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__INVERTED_ACHIEVEMENT_RATE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__INVERTED_ACHIEVEMENT_RATE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__TITLE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__TITLE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__TITLE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__TITLE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__WEIGHT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__WEIGHT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__WEIGHT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__WEIGHT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface PerformanceReviewEvaluationKpiDescription extends Node {
  __typename?: 'PerformanceReviewEvaluationKpiDescription';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  description: Scalars['String']['output'];
  displayOrder?: Maybe<Scalars['Int']['output']>;
  invertedAchievementRate: Scalars['Boolean']['output'];
  nodeId: Scalars['ID']['output'];
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationId: Scalars['BigInt']['output'];
  performanceReviewEvaluationKpiAssessments: PerformanceReviewEvaluationKpiAssessmentsConnection;
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['output'];
  performanceReviewEvaluationKpiTargets: PerformanceReviewEvaluationKpiTargetsConnection;
  remark?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
  weight: Scalars['Int']['output'];
}

export type PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationKpiAssessmentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentsOrderBy>>;
};

export type PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationKpiTargetsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationKpiTargetCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationKpiTargetFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetsOrderBy>>;
};

export interface PerformanceReviewEvaluationKpiDescriptionCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  invertedAchievementRate?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewEvaluationKpiDescriptionFilter {
  and?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  description?: InputMaybe<StringFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  invertedAchievementRate?: InputMaybe<BooleanFilter>;
  not?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionFilter>;
  or?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionFilter>>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluationFilter>;
  performanceReviewEvaluationId?: InputMaybe<BigIntFilter>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionToManyPerformanceReviewEvaluationKpiAssessmentFilter>;
  performanceReviewEvaluationKpiAssessmentsExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<BigIntFilter>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionToManyPerformanceReviewEvaluationKpiTargetFilter>;
  performanceReviewEvaluationKpiTargetsExist?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
  weight?: InputMaybe<IntFilter>;
}

export interface PerformanceReviewEvaluationKpiDescriptionInput {
  description: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  invertedAchievementRate?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9InverseInput>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluationKpiDescriptionNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewEvaluationKpiDescriptionNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationKpiAssessmentPatch;
}

export interface PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9UsingPerformanceReviewEvaluationKpiDescriptionPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9Patch;
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey4Update {
  patch: UpdatePerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  title: Scalars['String']['input'];
}

export interface PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationPatch;
}

export interface PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5UsingPerformanceReviewEvaluationKpiDescriptionPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5Patch;
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey4Update {
  patch: UpdatePerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  title: Scalars['String']['input'];
}

export interface PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyUsingPerformanceReviewEvaluationKpiDescriptionPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyPatch;
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey4Update {
  patch: UpdatePerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  title: Scalars['String']['input'];
}

export interface PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyUsingPerformanceReviewEvaluationKpiDescriptionPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyPatch;
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey4Update {
  patch: UpdatePerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  title: Scalars['String']['input'];
}

export interface PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationKpiTargetPatch;
}

export interface PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7UsingPerformanceReviewEvaluationKpiDescriptionPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7Patch;
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey4Update {
  patch: UpdatePerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  title: Scalars['String']['input'];
}

export interface PerformanceReviewEvaluationKpiDescriptionPatch {
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  invertedAchievementRate?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9InverseInput>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationKpiDescriptionPkeyConnect {
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationKpiDescriptionPkeyDelete {
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationPerformanceReviewEvaluatioKey4Connect {
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  title: Scalars['String']['input'];
}

export interface PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationPerformanceReviewEvaluatioKey4Delete {
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  title: Scalars['String']['input'];
}

export interface PerformanceReviewEvaluationKpiDescriptionToManyPerformanceReviewEvaluationKpiAssessmentFilter {
  every?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentFilter>;
}

export interface PerformanceReviewEvaluationKpiDescriptionToManyPerformanceReviewEvaluationKpiTargetFilter {
  every?: InputMaybe<PerformanceReviewEvaluationKpiTargetFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationKpiTargetFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationKpiTargetFilter>;
}

export interface PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationPerformanceReviewEvaluatioKey4Connect>>;
  connectByPerformanceReviewEvaluationKpiDescriptionId?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationKpiDescriptionPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationPerformanceReviewEvaluatioKey4Delete>>;
  deleteByPerformanceReviewEvaluationKpiDescriptionId?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationKpiDescriptionPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey4Update>
  >;
  updateByPerformanceReviewEvaluationKpiDescriptionId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyUsingPerformanceReviewEvaluationKpiDescriptionPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationPerformanceReviewEvaluatioKey4Connect>>;
  connectByPerformanceReviewEvaluationKpiDescriptionId?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationKpiDescriptionPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationPerformanceReviewEvaluatioKey4Delete>>;
  deleteByPerformanceReviewEvaluationKpiDescriptionId?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionPerformanceReviewEvaluationKpiDescriptionPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey4Update>
  >;
  updateByPerformanceReviewEvaluationKpiDescriptionId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyUsingPerformanceReviewEvaluationKpiDescriptionPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationKpiDescriptionsConnection {
  __typename?: 'PerformanceReviewEvaluationKpiDescriptionsConnection';
  edges: Array<PerformanceReviewEvaluationKpiDescriptionsEdge>;
  nodes: Array<PerformanceReviewEvaluationKpiDescription>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewEvaluationKpiDescriptionsEdge {
  __typename?: 'PerformanceReviewEvaluationKpiDescriptionsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewEvaluationKpiDescription;
}

export enum PerformanceReviewEvaluationKpiDescriptionsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DESCRIPTION_ASC = 'DESCRIPTION_ASC',
  DESCRIPTION_DESC = 'DESCRIPTION_DESC',
  DISPLAY_ORDER_ASC = 'DISPLAY_ORDER_ASC',
  DISPLAY_ORDER_DESC = 'DISPLAY_ORDER_DESC',
  INVERTED_ACHIEVEMENT_RATE_ASC = 'INVERTED_ACHIEVEMENT_RATE_ASC',
  INVERTED_ACHIEVEMENT_RATE_DESC = 'INVERTED_ACHIEVEMENT_RATE_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_INVERTED_ACHIEVEMENT_RATE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_INVERTED_ACHIEVEMENT_RATE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_INVERTED_ACHIEVEMENT_RATE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_INVERTED_ACHIEVEMENT_RATE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_NAME_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_NAME_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_NAME_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_NAME_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_VALUE_UNIT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_VALUE_UNIT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_VALUE_UNIT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MAX_VALUE_UNIT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_INVERTED_ACHIEVEMENT_RATE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_INVERTED_ACHIEVEMENT_RATE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_INVERTED_ACHIEVEMENT_RATE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_INVERTED_ACHIEVEMENT_RATE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_NAME_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_NAME_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_NAME_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_NAME_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_VALUE_UNIT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_VALUE_UNIT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_VALUE_UNIT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__MIN_VALUE_UNIT_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
  WEIGHT_ASC = 'WEIGHT_ASC',
  WEIGHT_DESC = 'WEIGHT_DESC',
}

export interface PerformanceReviewEvaluationKpiResult extends Node {
  __typename?: 'PerformanceReviewEvaluationKpiResult';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  nodeId: Scalars['ID']['output'];
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationId: Scalars['BigInt']['output'];
  performanceReviewEvaluationKpiResultId: Scalars['BigInt']['output'];
  performanceReviewEvaluationKpiTarget: PerformanceReviewEvaluationKpiTarget;
  performanceReviewEvaluationKpiTargetId: Scalars['BigInt']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
  value: Scalars['BigFloat']['output'];
  valueUnit?: Maybe<Scalars['String']['output']>;
}

export interface PerformanceReviewEvaluationKpiResultCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiResultId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiTargetId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  value?: InputMaybe<Scalars['BigFloat']['input']>;
  valueUnit?: InputMaybe<Scalars['String']['input']>;
}

export interface PerformanceReviewEvaluationKpiResultFilter {
  and?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  description?: InputMaybe<StringFilter>;
  not?: InputMaybe<PerformanceReviewEvaluationKpiResultFilter>;
  or?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultFilter>>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluationFilter>;
  performanceReviewEvaluationId?: InputMaybe<BigIntFilter>;
  performanceReviewEvaluationKpiResultId?: InputMaybe<BigIntFilter>;
  performanceReviewEvaluationKpiTarget?: InputMaybe<PerformanceReviewEvaluationKpiTargetFilter>;
  performanceReviewEvaluationKpiTargetId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
  value?: InputMaybe<BigFloatFilter>;
  valueUnit?: InputMaybe<StringFilter>;
}

export interface PerformanceReviewEvaluationKpiResultInput {
  description?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiResultId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiTarget?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11Input>;
  performanceReviewEvaluationKpiTargetId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  value: Scalars['BigFloat']['input'];
  valueUnit?: InputMaybe<Scalars['String']['input']>;
}

export interface PerformanceReviewEvaluationKpiResultNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewEvaluationKpiResultNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationPatch;
}

export interface PerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10UsingPerformanceReviewEvaluationKpiResultPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10Patch;
  performanceReviewEvaluationKpiResultId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey7Update {
  patch: UpdatePerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiTargetId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationKpiTargetPatch;
}

export interface PerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11UsingPerformanceReviewEvaluationKpiResultPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11Patch;
  performanceReviewEvaluationKpiResultId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey7Update {
  patch: UpdatePerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiTargetId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserCreatedFkeyUsingPerformanceReviewEvaluationKpiResultPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserCreatedFkeyPatch;
  performanceReviewEvaluationKpiResultId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserCreatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey7Update {
  patch: UpdatePerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserCreatedFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiTargetId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserUpdatedFkeyUsingPerformanceReviewEvaluationKpiResultPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserUpdatedFkeyPatch;
  performanceReviewEvaluationKpiResultId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserUpdatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey7Update {
  patch: UpdatePerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserUpdatedFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiTargetId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiResultPatch {
  description?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiResultId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiTarget?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11Input>;
  performanceReviewEvaluationKpiTargetId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  value?: InputMaybe<Scalars['BigFloat']['input']>;
  valueUnit?: InputMaybe<Scalars['String']['input']>;
}

export interface PerformanceReviewEvaluationKpiResultPerformanceReviewEvaluationKpiResultPkeyConnect {
  performanceReviewEvaluationKpiResultId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiResultPerformanceReviewEvaluationKpiResultPkeyDelete {
  performanceReviewEvaluationKpiResultId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiResultPerformanceReviewEvaluationPerformanceReviewEvaluatioKey7Connect {
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiTargetId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiResultPerformanceReviewEvaluationPerformanceReviewEvaluatioKey7Delete {
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiTargetId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiResultPerformanceReviewEvaluationPerformanceReviewEvaluatioKey7Connect>
  >;
  connectByPerformanceReviewEvaluationKpiResultId?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultPerformanceReviewEvaluationKpiResultPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiResultPerformanceReviewEvaluationPerformanceReviewEvaluatioKey7Delete>
  >;
  deleteByPerformanceReviewEvaluationKpiResultId?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultPerformanceReviewEvaluationKpiResultPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserCreatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey7Update>
  >;
  updateByPerformanceReviewEvaluationKpiResultId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserCreatedFkeyUsingPerformanceReviewEvaluationKpiResultPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiResultPerformanceReviewEvaluationPerformanceReviewEvaluatioKey7Connect>
  >;
  connectByPerformanceReviewEvaluationKpiResultId?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultPerformanceReviewEvaluationKpiResultPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiResultPerformanceReviewEvaluationPerformanceReviewEvaluatioKey7Delete>
  >;
  deleteByPerformanceReviewEvaluationKpiResultId?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultPerformanceReviewEvaluationKpiResultPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserUpdatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey7Update>
  >;
  updateByPerformanceReviewEvaluationKpiResultId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserUpdatedFkeyUsingPerformanceReviewEvaluationKpiResultPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationKpiResultsConnection {
  __typename?: 'PerformanceReviewEvaluationKpiResultsConnection';
  edges: Array<PerformanceReviewEvaluationKpiResultsEdge>;
  nodes: Array<PerformanceReviewEvaluationKpiResult>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewEvaluationKpiResultsEdge {
  __typename?: 'PerformanceReviewEvaluationKpiResultsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewEvaluationKpiResult;
}

export enum PerformanceReviewEvaluationKpiResultsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DESCRIPTION_ASC = 'DESCRIPTION_ASC',
  DESCRIPTION_DESC = 'DESCRIPTION_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__INVERTED_ACHIEVEMENT_RATE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__INVERTED_ACHIEVEMENT_RATE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__INVERTED_ACHIEVEMENT_RATE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__INVERTED_ACHIEVEMENT_RATE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__NAME_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__NAME_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__NAME_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__NAME_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__VALUE_UNIT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__VALUE_UNIT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__VALUE_UNIT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__VALUE_UNIT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
  VALUE_ASC = 'VALUE_ASC',
  VALUE_DESC = 'VALUE_DESC',
  VALUE_UNIT_ASC = 'VALUE_UNIT_ASC',
  VALUE_UNIT_DESC = 'VALUE_UNIT_DESC',
}

export interface PerformanceReviewEvaluationKpiTarget extends Node {
  __typename?: 'PerformanceReviewEvaluationKpiTarget';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  description: Scalars['String']['output'];
  invertedAchievementRate: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationId: Scalars['BigInt']['output'];
  performanceReviewEvaluationKpiDescription: PerformanceReviewEvaluationKpiDescription;
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['output'];
  performanceReviewEvaluationKpiResults: PerformanceReviewEvaluationKpiResultsConnection;
  performanceReviewEvaluationKpiTargetId: Scalars['BigInt']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
  value: Scalars['BigFloat']['output'];
  valueUnit: Scalars['String']['output'];
}

export type PerformanceReviewEvaluationKpiTargetPerformanceReviewEvaluationKpiResultsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationKpiResultCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationKpiResultFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultsOrderBy>>;
};

export interface PerformanceReviewEvaluationKpiTargetCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  invertedAchievementRate?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiTargetId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  value?: InputMaybe<Scalars['BigFloat']['input']>;
  valueUnit?: InputMaybe<Scalars['String']['input']>;
}

export interface PerformanceReviewEvaluationKpiTargetFilter {
  and?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  description?: InputMaybe<StringFilter>;
  invertedAchievementRate?: InputMaybe<BooleanFilter>;
  name?: InputMaybe<StringFilter>;
  not?: InputMaybe<PerformanceReviewEvaluationKpiTargetFilter>;
  or?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetFilter>>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluationFilter>;
  performanceReviewEvaluationId?: InputMaybe<BigIntFilter>;
  performanceReviewEvaluationKpiDescription?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionFilter>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<BigIntFilter>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluationKpiTargetToManyPerformanceReviewEvaluationKpiResultFilter>;
  performanceReviewEvaluationKpiResultsExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationKpiTargetId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
  value?: InputMaybe<BigFloatFilter>;
  valueUnit?: InputMaybe<StringFilter>;
}

export interface PerformanceReviewEvaluationKpiTargetInput {
  description: Scalars['String']['input'];
  invertedAchievementRate?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7Input>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11InverseInput>;
  performanceReviewEvaluationKpiTargetId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  value: Scalars['BigFloat']['input'];
  valueUnit: Scalars['String']['input'];
}

export interface PerformanceReviewEvaluationKpiTargetNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewEvaluationKpiTargetNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationKpiResultPatch;
}

export interface PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11UsingPerformanceReviewEvaluationKpiTargetPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11Patch;
  performanceReviewEvaluationKpiTargetId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey5Update {
  name: Scalars['String']['input'];
  patch: UpdatePerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationPatch;
}

export interface PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6UsingPerformanceReviewEvaluationKpiTargetPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6Patch;
  performanceReviewEvaluationKpiTargetId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey5Update {
  name: Scalars['String']['input'];
  patch: UpdatePerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationKpiDescriptionPatch;
}

export interface PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7UsingPerformanceReviewEvaluationKpiTargetPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7Patch;
  performanceReviewEvaluationKpiTargetId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey5Update {
  name: Scalars['String']['input'];
  patch: UpdatePerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserCreatedFkeyUsingPerformanceReviewEvaluationKpiTargetPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserCreatedFkeyPatch;
  performanceReviewEvaluationKpiTargetId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserCreatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey5Update {
  name: Scalars['String']['input'];
  patch: UpdatePerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserCreatedFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserUpdatedFkeyUsingPerformanceReviewEvaluationKpiTargetPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserUpdatedFkeyPatch;
  performanceReviewEvaluationKpiTargetId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserUpdatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey5Update {
  name: Scalars['String']['input'];
  patch: UpdatePerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserUpdatedFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiTargetPatch {
  description?: InputMaybe<Scalars['String']['input']>;
  invertedAchievementRate?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7Input>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11InverseInput>;
  performanceReviewEvaluationKpiTargetId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  value?: InputMaybe<Scalars['BigFloat']['input']>;
  valueUnit?: InputMaybe<Scalars['String']['input']>;
}

export interface PerformanceReviewEvaluationKpiTargetPerformanceReviewEvaluationKpiTargetPkeyConnect {
  performanceReviewEvaluationKpiTargetId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiTargetPerformanceReviewEvaluationKpiTargetPkeyDelete {
  performanceReviewEvaluationKpiTargetId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiTargetPerformanceReviewEvaluationPerformanceReviewEvaluatioKey5Connect {
  name: Scalars['String']['input'];
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiTargetPerformanceReviewEvaluationPerformanceReviewEvaluatioKey5Delete {
  name: Scalars['String']['input'];
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationKpiTargetToManyPerformanceReviewEvaluationKpiResultFilter {
  every?: InputMaybe<PerformanceReviewEvaluationKpiResultFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationKpiResultFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationKpiResultFilter>;
}

export interface PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndName?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiTargetPerformanceReviewEvaluationPerformanceReviewEvaluatioKey5Connect>
  >;
  connectByPerformanceReviewEvaluationKpiTargetId?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetPerformanceReviewEvaluationKpiTargetPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndName?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiTargetPerformanceReviewEvaluationPerformanceReviewEvaluatioKey5Delete>
  >;
  deleteByPerformanceReviewEvaluationKpiTargetId?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetPerformanceReviewEvaluationKpiTargetPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndName?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserCreatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey5Update>
  >;
  updateByPerformanceReviewEvaluationKpiTargetId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserCreatedFkeyUsingPerformanceReviewEvaluationKpiTargetPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndName?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiTargetPerformanceReviewEvaluationPerformanceReviewEvaluatioKey5Connect>
  >;
  connectByPerformanceReviewEvaluationKpiTargetId?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetPerformanceReviewEvaluationKpiTargetPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndName?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiTargetPerformanceReviewEvaluationPerformanceReviewEvaluatioKey5Delete>
  >;
  deleteByPerformanceReviewEvaluationKpiTargetId?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetPerformanceReviewEvaluationKpiTargetPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndName?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserUpdatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey5Update>
  >;
  updateByPerformanceReviewEvaluationKpiTargetId?: InputMaybe<
    Array<PerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserUpdatedFkeyUsingPerformanceReviewEvaluationKpiTargetPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationKpiTargetsConnection {
  __typename?: 'PerformanceReviewEvaluationKpiTargetsConnection';
  edges: Array<PerformanceReviewEvaluationKpiTargetsEdge>;
  nodes: Array<PerformanceReviewEvaluationKpiTarget>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewEvaluationKpiTargetsEdge {
  __typename?: 'PerformanceReviewEvaluationKpiTargetsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewEvaluationKpiTarget;
}

export enum PerformanceReviewEvaluationKpiTargetsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DESCRIPTION_ASC = 'DESCRIPTION_ASC',
  DESCRIPTION_DESC = 'DESCRIPTION_DESC',
  INVERTED_ACHIEVEMENT_RATE_ASC = 'INVERTED_ACHIEVEMENT_RATE_ASC',
  INVERTED_ACHIEVEMENT_RATE_DESC = 'INVERTED_ACHIEVEMENT_RATE_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__INVERTED_ACHIEVEMENT_RATE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__INVERTED_ACHIEVEMENT_RATE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__INVERTED_ACHIEVEMENT_RATE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__INVERTED_ACHIEVEMENT_RATE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__TITLE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__TITLE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__TITLE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__TITLE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__WEIGHT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__WEIGHT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__WEIGHT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID__WEIGHT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_VALUE_UNIT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_VALUE_UNIT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_VALUE_UNIT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MAX_VALUE_UNIT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_VALUE_UNIT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_VALUE_UNIT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_VALUE_UNIT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID__MIN_VALUE_UNIT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
  VALUE_ASC = 'VALUE_ASC',
  VALUE_DESC = 'VALUE_DESC',
  VALUE_UNIT_ASC = 'VALUE_UNIT_ASC',
  VALUE_UNIT_DESC = 'VALUE_UNIT_DESC',
}

export interface PerformanceReviewEvaluationLockAssessmentInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewEvaluationLockAssessmentPayload {
  __typename?: 'PerformanceReviewEvaluationLockAssessmentPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  integer?: Maybe<Scalars['Int']['output']>;
  query?: Maybe<Query>;
}

export interface PerformanceReviewEvaluationLockDescriptionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewEvaluationLockDescriptionPayload {
  __typename?: 'PerformanceReviewEvaluationLockDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  integer?: Maybe<Scalars['Int']['output']>;
  query?: Maybe<Query>;
}

export interface PerformanceReviewEvaluationNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewEvaluationNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationCompetencyPatch;
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1UsingPerformanceReviewEvaluationPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationEvaluatorPatch;
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13UsingPerformanceReviewEvaluationPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationFeedbackPatch;
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12UsingPerformanceReviewEvaluationPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeIdFkeyUsingPerformanceReviewEvaluationPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeIdFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeTpHistoryIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeTpHistoryPatch;
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeTpHistoryIdFkeyUsingPerformanceReviewEvaluationPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeTpHistoryIdFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationPerformanceReviewMasterCoFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyRolePatch;
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationPerformanceReviewMasterCoFkeyUsingPerformanceReviewEvaluationPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationPerformanceReviewMasterCoFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationUserCreatedFkeyUsingPerformanceReviewEvaluationPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationUserCreatedFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationUserUpdatedFkeyUsingPerformanceReviewEvaluationPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationUserUpdatedFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationKpiAssessmentPatch;
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8UsingPerformanceReviewEvaluationPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationKpiDescriptionPatch;
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5UsingPerformanceReviewEvaluationPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationKpiResultPatch;
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10UsingPerformanceReviewEvaluationPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationKpiTargetPatch;
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6UsingPerformanceReviewEvaluationPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationOneOnOnePatch;
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14UsingPerformanceReviewEvaluationPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationQualitativeAssessmentPatch;
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3UsingPerformanceReviewEvaluationPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationQualitativeDescriptionPatch;
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2UsingPerformanceReviewEvaluationPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationValuePatch;
}

export interface PerformanceReviewEvaluationOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyUsingPerformanceReviewEvaluationPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationOneOnOne extends Node {
  __typename?: 'PerformanceReviewEvaluationOneOnOne';
  dateCreated: Scalars['Datetime']['output'];
  month: Scalars['Int']['output'];
  nodeId: Scalars['ID']['output'];
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationId: Scalars['BigInt']['output'];
  performanceReviewEvaluationOneOnOneId: Scalars['BigInt']['output'];
  performanceReviewMasterOneOnOne: PerformanceReviewMasterOneOnOne;
  performanceReviewMasterOneOnOneId: Scalars['BigInt']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  year: Scalars['Int']['output'];
}

export interface PerformanceReviewEvaluationOneOnOneCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  month?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationOneOnOneId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterOneOnOneId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewEvaluationOneOnOneFilter {
  and?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOneFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  month?: InputMaybe<IntFilter>;
  not?: InputMaybe<PerformanceReviewEvaluationOneOnOneFilter>;
  or?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOneFilter>>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluationFilter>;
  performanceReviewEvaluationId?: InputMaybe<BigIntFilter>;
  performanceReviewEvaluationOneOnOneId?: InputMaybe<BigIntFilter>;
  performanceReviewMasterOneOnOne?: InputMaybe<PerformanceReviewMasterOneOnOneFilter>;
  performanceReviewMasterOneOnOneId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  value?: InputMaybe<StringFilter>;
  year?: InputMaybe<IntFilter>;
}

export interface PerformanceReviewEvaluationOneOnOneInput {
  month: Scalars['Int']['input'];
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationOneOnOneId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterOneOnOne?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterOnFkeyInput>;
  performanceReviewMasterOneOnOneId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluationOneOnOneNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewEvaluationOneOnOneNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewEvaluationOneOnOneOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationPatch;
}

export interface PerformanceReviewEvaluationOneOnOneOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14UsingPerformanceReviewEvaluationOneOnOnePkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationOneOnOneOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14Patch;
  performanceReviewEvaluationOneOnOneId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationOneOnOneOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationOneOnOneUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewEvaluationOneOnOneOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationOneOnOneUserCreatedFkeyUsingPerformanceReviewEvaluationOneOnOnePkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationOneOnOneOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationOneOnOneUserCreatedFkeyPatch;
  performanceReviewEvaluationOneOnOneId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationOneOnOneOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationPerformanceReviewMasterOnFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterOneOnOnePatch;
}

export interface PerformanceReviewEvaluationOneOnOneOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationPerformanceReviewMasterOnFkeyUsingPerformanceReviewEvaluationOneOnOnePkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationOneOnOneOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationPerformanceReviewMasterOnFkeyPatch;
  performanceReviewEvaluationOneOnOneId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationOneOnOnePatch {
  month?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationOneOnOneId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterOneOnOne?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterOnFkeyInput>;
  performanceReviewMasterOneOnOneId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewEvaluationOneOnOnePerformanceReviewEvaluationOneOnOnePkeyConnect {
  performanceReviewEvaluationOneOnOneId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationOneOnOnePerformanceReviewEvaluationOneOnOnePkeyDelete {
  performanceReviewEvaluationOneOnOneId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationOneOnOneSelfList {
  __typename?: 'PerformanceReviewEvaluationOneOnOneSelfList';
  employee: Employee;
  employeeId: Scalars['BigInt']['output'];
  isFilled: Scalars['Boolean']['output'];
  month: Scalars['Int']['output'];
  performanceReviewEvaluation?: Maybe<PerformanceReviewEvaluation>;
  performanceReviewEvaluationId?: Maybe<Scalars['BigInt']['output']>;
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['output'];
}

export interface PerformanceReviewEvaluationOneOnOneSelfListCondition {
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  isFilled?: InputMaybe<Scalars['Boolean']['input']>;
  month?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewEvaluationOneOnOneSelfListFilter {
  and?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOneSelfListFilter>>;
  employee?: InputMaybe<EmployeeFilter>;
  employeeExists?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<BigIntFilter>;
  isFilled?: InputMaybe<BooleanFilter>;
  month?: InputMaybe<IntFilter>;
  not?: InputMaybe<PerformanceReviewEvaluationOneOnOneSelfListFilter>;
  or?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOneSelfListFilter>>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluationFilter>;
  performanceReviewEvaluationExists?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationId?: InputMaybe<BigIntFilter>;
  quarter?: InputMaybe<PerformanceReviewQuarterFilter>;
  year?: InputMaybe<IntFilter>;
}

export interface PerformanceReviewEvaluationOneOnOneSelfListsConnection {
  __typename?: 'PerformanceReviewEvaluationOneOnOneSelfListsConnection';
  edges: Array<PerformanceReviewEvaluationOneOnOneSelfListsEdge>;
  nodes: Array<PerformanceReviewEvaluationOneOnOneSelfList>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewEvaluationOneOnOneSelfListsEdge {
  __typename?: 'PerformanceReviewEvaluationOneOnOneSelfListsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewEvaluationOneOnOneSelfList;
}

export enum PerformanceReviewEvaluationOneOnOneSelfListsOrderBy {
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  IS_FILLED_ASC = 'IS_FILLED_ASC',
  IS_FILLED_DESC = 'IS_FILLED_DESC',
  MONTH_ASC = 'MONTH_ASC',
  MONTH_DESC = 'MONTH_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  QUARTER_ASC = 'QUARTER_ASC',
  QUARTER_DESC = 'QUARTER_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
}

export interface PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationOneOnOneUserCreatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationOneOnOneOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationOneOnOneUserCreatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationOneOnOneUserCreatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOneNodeIdConnect>>;
  connectByPerformanceReviewEvaluationOneOnOneId?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOnePerformanceReviewEvaluationOneOnOnePkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOneNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationOneOnOneId?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOnePerformanceReviewEvaluationOneOnOnePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationOneOnOneUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationOneOnOneId?: InputMaybe<
    Array<PerformanceReviewEvaluationOneOnOneOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationOneOnOneUserCreatedFkeyUsingPerformanceReviewEvaluationOneOnOnePkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationOneOnOnesConnection {
  __typename?: 'PerformanceReviewEvaluationOneOnOnesConnection';
  edges: Array<PerformanceReviewEvaluationOneOnOnesEdge>;
  nodes: Array<PerformanceReviewEvaluationOneOnOne>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewEvaluationOneOnOnesEdge {
  __typename?: 'PerformanceReviewEvaluationOneOnOnesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewEvaluationOneOnOne;
}

export enum PerformanceReviewEvaluationOneOnOnesOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  MONTH_ASC = 'MONTH_ASC',
  MONTH_DESC = 'MONTH_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__HIDDEN_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__HIDDEN_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__HIDDEN_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__HIDDEN_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MODE_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MODE_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MODE_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MODE_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__REQUIRED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__REQUIRED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__REQUIRED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__REQUIRED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  VALUE_ASC = 'VALUE_ASC',
  VALUE_DESC = 'VALUE_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
}

export interface PerformanceReviewEvaluationPatch {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInput {
  connectByNodeId?: InputMaybe<PerformanceReviewEvaluationNodeIdConnect>;
  connectByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyConnect>;
  create?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyPerformanceReviewEvaluationCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewEvaluationNodeIdDelete>;
  deleteByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyNodeIdUpdate>;
  updateByPerformanceReviewEvaluationId?: InputMaybe<PerformanceReviewEvaluationOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyUsingPerformanceReviewEvaluationPkeyUpdate>;
}

export interface PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationValueNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationValuePerformanceReviewEvaluationPerformanceReviewEvaluationKeyConnect>
  >;
  connectByPerformanceReviewEvaluationValueId?: InputMaybe<Array<PerformanceReviewEvaluationValuePerformanceReviewEvaluationValuePkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyPerformanceReviewEvaluationValueCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationValueNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationValuePerformanceReviewEvaluationPerformanceReviewEvaluationKeyDelete>
  >;
  deleteByPerformanceReviewEvaluationValueId?: InputMaybe<Array<PerformanceReviewEvaluationValuePerformanceReviewEvaluationValuePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluationKeyUpdate>
  >;
  updateByPerformanceReviewEvaluationValueId?: InputMaybe<
    Array<PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyUsingPerformanceReviewEvaluationValuePkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyPerformanceReviewEvaluationCreateInput {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyPerformanceReviewEvaluationValueCreateInput {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInput>;
  performanceReviewEvaluationValueId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInput>;
  performanceReviewMasterValue?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyConnect {
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyDelete {
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput {
  connectByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyRoleNodeIdConnect>;
  connectByPerformanceReviewMasterCompetencyRoleId?: InputMaybe<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterCompetencyRolePkeyConnect>;
  connectByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleName?: InputMaybe<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterComYearQuarterBusinessUnitIdKeyConnect>;
  create?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyPerformanceReviewMasterCompetencyRoleCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyRoleNodeIdDelete>;
  deleteByPerformanceReviewMasterCompetencyRoleId?: InputMaybe<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterCompetencyRolePkeyDelete>;
  deleteByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleName?: InputMaybe<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterComYearQuarterBusinessUnitIdKeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationPerformanceReviewMasterCoFkeyNodeIdUpdate>;
  updateByPerformanceReviewMasterCompetencyRoleId?: InputMaybe<PerformanceReviewMasterCompetencyRoleOnPerformanceReviewEvaluationForPerformanceReviewEvaluationPerformanceReviewMasterCoFkeyUsingPerformanceReviewMasterCompetencyRolePkeyUpdate>;
  updateByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleName?: InputMaybe<PerformanceReviewMasterCompetencyRoleOnPerformanceReviewEvaluationForPerformanceReviewEvaluationPerformanceReviewMasterCoFkeyUsingPerformanceReviewMasterComYearQuarterBusinessUnitIdKeyUpdate>;
}

export interface PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationNodeIdConnect>>;
  connectByPerformanceReviewEvaluationId?: InputMaybe<Array<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyPerformanceReviewEvaluationCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationId?: InputMaybe<Array<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyRoleOnPerformanceReviewEvaluationForPerformanceReviewEvaluationPerformanceReviewMasterCoFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationId?: InputMaybe<
    Array<PerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationPerformanceReviewMasterCoFkeyUsingPerformanceReviewEvaluationPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyPerformanceReviewEvaluationCreateInput {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyPerformanceReviewMasterCompetencyRoleCreateInput {
  businessUnit?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1InverseInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  roleName: Scalars['String']['input'];
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluationPerformanceReviewMasterOnFkeyInput {
  connectByNodeId?: InputMaybe<PerformanceReviewMasterOneOnOneNodeIdConnect>;
  connectByPerformanceReviewMasterOneOnOneId?: InputMaybe<PerformanceReviewMasterOneOnOnePerformanceReviewMasterOneOnOnePkeyConnect>;
  create?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterOnFkeyPerformanceReviewMasterOneOnOneCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewMasterOneOnOneNodeIdDelete>;
  deleteByPerformanceReviewMasterOneOnOneId?: InputMaybe<PerformanceReviewMasterOneOnOnePerformanceReviewMasterOneOnOnePkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationOneOnOneOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationPerformanceReviewMasterOnFkeyNodeIdUpdate>;
  updateByPerformanceReviewMasterOneOnOneId?: InputMaybe<PerformanceReviewMasterOneOnOneOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationPerformanceReviewMasterOnFkeyUsingPerformanceReviewMasterOneOnOnePkeyUpdate>;
}

export interface PerformanceReviewEvaluationPerformanceReviewMasterOnFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOneNodeIdConnect>>;
  connectByPerformanceReviewEvaluationOneOnOneId?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOnePerformanceReviewEvaluationOneOnOnePkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluationPerformanceReviewMasterOnFkeyPerformanceReviewEvaluationOneOnOneCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOneNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationOneOnOneId?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOnePerformanceReviewEvaluationOneOnOnePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewMasterOneOnOneOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationPerformanceReviewMasterOnFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationOneOnOneId?: InputMaybe<
    Array<PerformanceReviewEvaluationOneOnOneOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationPerformanceReviewMasterOnFkeyUsingPerformanceReviewEvaluationOneOnOnePkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationPerformanceReviewMasterOnFkeyPerformanceReviewEvaluationOneOnOneCreateInput {
  month: Scalars['Int']['input'];
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationOneOnOneId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterOneOnOne?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterOnFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluationPerformanceReviewMasterOnFkeyPerformanceReviewMasterOneOnOneCreateInput {
  displayOrder: Scalars['Int']['input'];
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  mode: Scalars['String']['input'];
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterOnFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetails?: InputMaybe<PerformanceReviewMasterOnePerformanceReviewMasterOnFkeyInverseInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyInput {
  connectByNodeId?: InputMaybe<PerformanceReviewMasterValueNodeIdConnect>;
  connectByPerformanceReviewMasterValueId?: InputMaybe<PerformanceReviewMasterValuePerformanceReviewMasterValuePkeyConnect>;
  create?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyPerformanceReviewMasterValueCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewMasterValueNodeIdDelete>;
  deleteByPerformanceReviewMasterValueId?: InputMaybe<PerformanceReviewMasterValuePerformanceReviewMasterValuePkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewMasterVaFkeyNodeIdUpdate>;
  updateByPerformanceReviewMasterValueId?: InputMaybe<PerformanceReviewMasterValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewMasterVaFkeyUsingPerformanceReviewMasterValuePkeyUpdate>;
}

export interface PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationValueNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationValuePerformanceReviewEvaluationPerformanceReviewEvaluationKeyConnect>
  >;
  connectByPerformanceReviewEvaluationValueId?: InputMaybe<Array<PerformanceReviewEvaluationValuePerformanceReviewEvaluationValuePkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyPerformanceReviewEvaluationValueCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationValueNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationValuePerformanceReviewEvaluationPerformanceReviewEvaluationKeyDelete>
  >;
  deleteByPerformanceReviewEvaluationValueId?: InputMaybe<Array<PerformanceReviewEvaluationValuePerformanceReviewEvaluationValuePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewMasterValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewMasterVaFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewMasterVaFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluationKeyUpdate>
  >;
  updateByPerformanceReviewEvaluationValueId?: InputMaybe<
    Array<PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewMasterVaFkeyUsingPerformanceReviewEvaluationValuePkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyPerformanceReviewEvaluationValueCreateInput {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInput>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationValueId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInput>;
  performanceReviewMasterValue?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyPerformanceReviewMasterValueCreateInput {
  displayOrder: Scalars['Int']['input'];
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyInverseInput>;
  performanceReviewMasterValueDescriptions?: InputMaybe<PerformanceReviewMasterValPerformanceReviewMasterVaFkeyInverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyInverseInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInput {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyEmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyUsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyNodeIdUpdate>;
}

export interface PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Connect>
  >;
  connectByPerformanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationQualitativeAssessmentPkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyPerformanceReviewEvaluationQualitativeAssessmentCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Delete>
  >;
  deleteByPerformanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationQualitativeAssessmentPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<EmployeeOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Update>
  >;
  updateByPerformanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyUsingPerformanceReviewEvaluationQualitativeAssessmentPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyPerformanceReviewEvaluationQualitativeAssessmentCreateInput {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4Input>;
  performanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Connect>
  >;
  connectByPerformanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationQualitativeAssessmentPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Delete>
  >;
  deleteByPerformanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationQualitativeAssessmentPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserCreatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Update>
  >;
  updateByPerformanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserCreatedFkeyUsingPerformanceReviewEvaluationQualitativeAssessmentPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Connect>
  >;
  connectByPerformanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationQualitativeAssessmentPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Delete>
  >;
  deleteByPerformanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationQualitativeAssessmentPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Update>
  >;
  updateByPerformanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyUsingPerformanceReviewEvaluationQualitativeAssessmentPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationQualitativeAssessment extends Node {
  __typename?: 'PerformanceReviewEvaluationQualitativeAssessment';
  assessmentComment?: Maybe<Scalars['String']['output']>;
  assessmentEmployee: Employee;
  assessmentEmployeeId: Scalars['BigInt']['output'];
  assessmentValue?: Maybe<Scalars['BigInt']['output']>;
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  nodeId: Scalars['ID']['output'];
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationId: Scalars['BigInt']['output'];
  performanceReviewEvaluationQualitativeAssessmentId: Scalars['BigInt']['output'];
  performanceReviewEvaluationQualitativeDescription: PerformanceReviewEvaluationQualitativeDescription;
  performanceReviewEvaluationQualitativeDescriptionId: Scalars['BigInt']['output'];
  performanceReviewMasterRatingByAssessmentValue?: Maybe<PerformanceReviewMasterRating>;
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface PerformanceReviewEvaluationQualitativeAssessmentCondition {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationQualitativeAssessmentFilter {
  and?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentFilter>>;
  assessmentComment?: InputMaybe<StringFilter>;
  assessmentEmployee?: InputMaybe<EmployeeFilter>;
  assessmentEmployeeId?: InputMaybe<BigIntFilter>;
  assessmentValue?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  not?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentFilter>;
  or?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentFilter>>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluationFilter>;
  performanceReviewEvaluationId?: InputMaybe<BigIntFilter>;
  performanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<BigIntFilter>;
  performanceReviewEvaluationQualitativeDescription?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionFilter>;
  performanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<BigIntFilter>;
  performanceReviewMasterRatingByAssessmentValue?: InputMaybe<PerformanceReviewMasterRatingFilter>;
  performanceReviewMasterRatingByAssessmentValueExists?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface PerformanceReviewEvaluationQualitativeAssessmentInput {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4Input>;
  performanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationQualitativeAssessmentNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewEvaluationQualitativeAssessmentNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationPatch;
}

export interface PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Update {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationQualitativeDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3UsingPerformanceReviewEvaluationQualitativeAssessmentPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3Patch;
  performanceReviewEvaluationQualitativeAssessmentId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationQualitativeDescriptionPatch;
}

export interface PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Update {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationQualitativeDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4UsingPerformanceReviewEvaluationQualitativeAssessmentPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4Patch;
  performanceReviewEvaluationQualitativeAssessmentId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Update {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationQualitativeDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyUsingPerformanceReviewEvaluationQualitativeAssessmentPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyPatch;
  performanceReviewEvaluationQualitativeAssessmentId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserCreatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Update {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserCreatedFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationQualitativeDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserCreatedFkeyUsingPerformanceReviewEvaluationQualitativeAssessmentPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserCreatedFkeyPatch;
  performanceReviewEvaluationQualitativeAssessmentId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Update {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationQualitativeDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyUsingPerformanceReviewEvaluationQualitativeAssessmentPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyPatch;
  performanceReviewEvaluationQualitativeAssessmentId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssessmentValueFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterRatingPatch;
}

export interface PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssessmentValueFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Update {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssessmentValueFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationQualitativeDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssessmentValueFkeyUsingPerformanceReviewEvaluationQualitativeAssessmentPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssessmentValueFkeyPatch;
  performanceReviewEvaluationQualitativeAssessmentId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationQualitativeAssessmentPatch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4Input>;
  performanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Connect {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationQualitativeDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Delete {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationQualitativeDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationQualitativeAssessmentPkeyConnect {
  performanceReviewEvaluationQualitativeAssessmentId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationQualitativeAssessmentPkeyDelete {
  performanceReviewEvaluationQualitativeAssessmentId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInput {
  connectByNodeId?: InputMaybe<PerformanceReviewMasterRatingNodeIdConnect>;
  connectByPerformanceReviewMasterRatingId?: InputMaybe<PerformanceReviewMasterRatingPerformanceReviewMasterRatingPkeyConnect>;
  create?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyPerformanceReviewMasterRatingCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewMasterRatingNodeIdDelete>;
  deleteByPerformanceReviewMasterRatingId?: InputMaybe<PerformanceReviewMasterRatingPerformanceReviewMasterRatingPkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssessmentValueFkeyNodeIdUpdate>;
  updateByPerformanceReviewMasterRatingId?: InputMaybe<PerformanceReviewMasterRatingOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssessmentValueFkeyUsingPerformanceReviewMasterRatingPkeyUpdate>;
}

export interface PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Connect>
  >;
  connectByPerformanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationQualitativeAssessmentPkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyPerformanceReviewEvaluationQualitativeAssessmentCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Delete>
  >;
  deleteByPerformanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentPerformanceReviewEvaluationQualitativeAssessmentPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewMasterRatingOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssessmentValueFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssessmentValueFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey3Update>
  >;
  updateByPerformanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssessmentValueFkeyUsingPerformanceReviewEvaluationQualitativeAssessmentPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationQualitativeAssessmentValueFkeyPerformanceReviewEvaluationQualitativeAssessmentCreateInput {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4Input>;
  performanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationQualitativeAssessmentValueFkeyPerformanceReviewMasterRatingCreateInput {
  calculationValue: Scalars['BigFloat']['input'];
  displayValue: Scalars['Int']['input'];
  percentageLowerBound?: InputMaybe<Scalars['Int']['input']>;
  percentageUpperBound?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInverseInput>;
  performanceReviewMasterRatingDescriptions?: InputMaybe<PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyInverseInput>;
  performanceReviewMasterRatingId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInverseInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluationQualitativeAssessmentsConnection {
  __typename?: 'PerformanceReviewEvaluationQualitativeAssessmentsConnection';
  edges: Array<PerformanceReviewEvaluationQualitativeAssessmentsEdge>;
  nodes: Array<PerformanceReviewEvaluationQualitativeAssessment>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewEvaluationQualitativeAssessmentsEdge {
  __typename?: 'PerformanceReviewEvaluationQualitativeAssessmentsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewEvaluationQualitativeAssessment;
}

export enum PerformanceReviewEvaluationQualitativeAssessmentsOrderBy {
  ASSESSMENT_COMMENT_ASC = 'ASSESSMENT_COMMENT_ASC',
  ASSESSMENT_COMMENT_DESC = 'ASSESSMENT_COMMENT_DESC',
  ASSESSMENT_EMPLOYEE_ID_ASC = 'ASSESSMENT_EMPLOYEE_ID_ASC',
  ASSESSMENT_EMPLOYEE_ID_DESC = 'ASSESSMENT_EMPLOYEE_ID_DESC',
  ASSESSMENT_VALUE_ASC = 'ASSESSMENT_VALUE_ASC',
  ASSESSMENT_VALUE_DESC = 'ASSESSMENT_VALUE_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__DEFINITION_FOR_SCORE_100_PERCENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__DEFINITION_FOR_SCORE_100_PERCENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__DEFINITION_FOR_SCORE_100_PERCENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__DEFINITION_FOR_SCORE_100_PERCENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__EVALUATOR_EXPECTATION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__EVALUATOR_EXPECTATION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__EVALUATOR_EXPECTATION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__EVALUATOR_EXPECTATION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__TITLE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__TITLE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__TITLE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__TITLE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__WEIGHT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__WEIGHT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__WEIGHT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__WEIGHT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__CALCULATION_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__CALCULATION_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__CALCULATION_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__CALCULATION_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DISPLAY_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DISPLAY_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DISPLAY_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DISPLAY_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERCENTAGE_LOWER_BOUND_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERCENTAGE_LOWER_BOUND_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERCENTAGE_LOWER_BOUND_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERCENTAGE_LOWER_BOUND_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERCENTAGE_UPPER_BOUND_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERCENTAGE_UPPER_BOUND_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERCENTAGE_UPPER_BOUND_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERCENTAGE_UPPER_BOUND_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__YEAR_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionPerformanceReviewEvaluationPerformanceReviewEvaluatioKey2Connect>>;
  connectByPerformanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionPerformanceReviewEvaluationQualitativeDescriptionPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionPerformanceReviewEvaluationPerformanceReviewEvaluatioKey2Delete>>;
  deleteByPerformanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionPerformanceReviewEvaluationQualitativeDescriptionPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserCreatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey2Update>
  >;
  updateByPerformanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserCreatedFkeyUsingPerformanceReviewEvaluationQualitativeDescriptionPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionPerformanceReviewEvaluationPerformanceReviewEvaluatioKey2Connect>>;
  connectByPerformanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionPerformanceReviewEvaluationQualitativeDescriptionPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionPerformanceReviewEvaluationPerformanceReviewEvaluatioKey2Delete>>;
  deleteByPerformanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionPerformanceReviewEvaluationQualitativeDescriptionPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndTitle?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey2Update>
  >;
  updateByPerformanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<
    Array<PerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyUsingPerformanceReviewEvaluationQualitativeDescriptionPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationQualitativeDescription extends Node {
  __typename?: 'PerformanceReviewEvaluationQualitativeDescription';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  definitionForScore100Percent?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  displayOrder?: Maybe<Scalars['Int']['output']>;
  evaluatorExpectation?: Maybe<Scalars['String']['output']>;
  nodeId: Scalars['ID']['output'];
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationId: Scalars['BigInt']['output'];
  performanceReviewEvaluationQualitativeAssessments: PerformanceReviewEvaluationQualitativeAssessmentsConnection;
  performanceReviewEvaluationQualitativeDescriptionId: Scalars['BigInt']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
  weight: Scalars['Int']['output'];
}

export type PerformanceReviewEvaluationQualitativeDescriptionPerformanceReviewEvaluationQualitativeAssessmentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentsOrderBy>>;
};

export interface PerformanceReviewEvaluationQualitativeDescriptionCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  definitionForScore100Percent?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  evaluatorExpectation?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewEvaluationQualitativeDescriptionFilter {
  and?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  definitionForScore100Percent?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  evaluatorExpectation?: InputMaybe<StringFilter>;
  not?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionFilter>;
  or?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionFilter>>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluationFilter>;
  performanceReviewEvaluationId?: InputMaybe<BigIntFilter>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionToManyPerformanceReviewEvaluationQualitativeAssessmentFilter>;
  performanceReviewEvaluationQualitativeAssessmentsExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
  weight?: InputMaybe<IntFilter>;
}

export interface PerformanceReviewEvaluationQualitativeDescriptionInput {
  definitionForScore100Percent?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  evaluatorExpectation?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4InverseInput>;
  performanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  userAccount?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluationQualitativeDescriptionNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewEvaluationQualitativeDescriptionNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationQualitativeAssessmentPatch;
}

export interface PerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey2Update {
  patch: UpdatePerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  title: Scalars['String']['input'];
}

export interface PerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4UsingPerformanceReviewEvaluationQualitativeDescriptionPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4Patch;
  performanceReviewEvaluationQualitativeDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationPatch;
}

export interface PerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2UsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey2Update {
  patch: UpdatePerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2Patch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  title: Scalars['String']['input'];
}

export interface PerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2UsingPerformanceReviewEvaluationQualitativeDescriptionPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2Patch;
  performanceReviewEvaluationQualitativeDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserCreatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey2Update {
  patch: UpdatePerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserCreatedFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  title: Scalars['String']['input'];
}

export interface PerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserCreatedFkeyUsingPerformanceReviewEvaluationQualitativeDescriptionPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserCreatedFkeyPatch;
  performanceReviewEvaluationQualitativeDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluatioKey2Update {
  patch: UpdatePerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  title: Scalars['String']['input'];
}

export interface PerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyUsingPerformanceReviewEvaluationQualitativeDescriptionPkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyPatch;
  performanceReviewEvaluationQualitativeDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationQualitativeDescriptionPatch {
  definitionForScore100Percent?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  evaluatorExpectation?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4InverseInput>;
  performanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewEvaluationQualitativeDescriptionPerformanceReviewEvaluationPerformanceReviewEvaluatioKey2Connect {
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  title: Scalars['String']['input'];
}

export interface PerformanceReviewEvaluationQualitativeDescriptionPerformanceReviewEvaluationPerformanceReviewEvaluatioKey2Delete {
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  title: Scalars['String']['input'];
}

export interface PerformanceReviewEvaluationQualitativeDescriptionPerformanceReviewEvaluationQualitativeDescriptionPkeyConnect {
  performanceReviewEvaluationQualitativeDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationQualitativeDescriptionPerformanceReviewEvaluationQualitativeDescriptionPkeyDelete {
  performanceReviewEvaluationQualitativeDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationQualitativeDescriptionToManyPerformanceReviewEvaluationQualitativeAssessmentFilter {
  every?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentFilter>;
}

export interface PerformanceReviewEvaluationQualitativeDescriptionsConnection {
  __typename?: 'PerformanceReviewEvaluationQualitativeDescriptionsConnection';
  edges: Array<PerformanceReviewEvaluationQualitativeDescriptionsEdge>;
  nodes: Array<PerformanceReviewEvaluationQualitativeDescription>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewEvaluationQualitativeDescriptionsEdge {
  __typename?: 'PerformanceReviewEvaluationQualitativeDescriptionsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewEvaluationQualitativeDescription;
}

export enum PerformanceReviewEvaluationQualitativeDescriptionsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DEFINITION_FOR_SCORE_100_PERCENT_ASC = 'DEFINITION_FOR_SCORE_100_PERCENT_ASC',
  DEFINITION_FOR_SCORE_100_PERCENT_DESC = 'DEFINITION_FOR_SCORE_100_PERCENT_DESC',
  DESCRIPTION_ASC = 'DESCRIPTION_ASC',
  DESCRIPTION_DESC = 'DESCRIPTION_DESC',
  DISPLAY_ORDER_ASC = 'DISPLAY_ORDER_ASC',
  DISPLAY_ORDER_DESC = 'DISPLAY_ORDER_DESC',
  EVALUATOR_EXPECTATION_ASC = 'EVALUATOR_EXPECTATION_ASC',
  EVALUATOR_EXPECTATION_DESC = 'EVALUATOR_EXPECTATION_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
  WEIGHT_ASC = 'WEIGHT_ASC',
  WEIGHT_DESC = 'WEIGHT_DESC',
}

export interface PerformanceReviewEvaluationScoreCompetencyConnection {
  __typename?: 'PerformanceReviewEvaluationScoreCompetencyConnection';
  edges: Array<PerformanceReviewEvaluationScoreCompetencyEdge>;
  nodes: Array<PerformanceReviewEvaluationScoreCompetencyRecord>;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewEvaluationScoreCompetencyEdge {
  __typename?: 'PerformanceReviewEvaluationScoreCompetencyEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewEvaluationScoreCompetencyRecord;
}

export interface PerformanceReviewEvaluationScoreCompetencyRecord {
  __typename?: 'PerformanceReviewEvaluationScoreCompetencyRecord';
  externalValueWeighted?: Maybe<Scalars['BigFloat']['output']>;
  internalValueWeighted?: Maybe<Scalars['BigFloat']['output']>;
  totalWeight?: Maybe<Scalars['Int']['output']>;
}

export interface PerformanceReviewEvaluationScoreCompetencyRecordFilter {
  and?: InputMaybe<Array<PerformanceReviewEvaluationScoreCompetencyRecordFilter>>;
  externalValueWeighted?: InputMaybe<BigFloatFilter>;
  internalValueWeighted?: InputMaybe<BigFloatFilter>;
  not?: InputMaybe<PerformanceReviewEvaluationScoreCompetencyRecordFilter>;
  or?: InputMaybe<Array<PerformanceReviewEvaluationScoreCompetencyRecordFilter>>;
  totalWeight?: InputMaybe<IntFilter>;
}

export interface PerformanceReviewEvaluationScoreEdge {
  __typename?: 'PerformanceReviewEvaluationScoreEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewEvaluationScoresRecord;
}

export interface PerformanceReviewEvaluationScoreKpiConnection {
  __typename?: 'PerformanceReviewEvaluationScoreKpiConnection';
  edges: Array<PerformanceReviewEvaluationScoreKpiEdge>;
  nodes: Array<PerformanceReviewEvaluationScoreKpiRecord>;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewEvaluationScoreKpiEdge {
  __typename?: 'PerformanceReviewEvaluationScoreKpiEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewEvaluationScoreKpiRecord;
}

export interface PerformanceReviewEvaluationScoreKpiRecord {
  __typename?: 'PerformanceReviewEvaluationScoreKpiRecord';
  externalValueDisplay?: Maybe<Scalars['BigFloat']['output']>;
  externalValueWeighted?: Maybe<Scalars['BigFloat']['output']>;
  internalValueDisplay?: Maybe<Scalars['BigFloat']['output']>;
  internalValueWeighted?: Maybe<Scalars['BigFloat']['output']>;
  totalWeight?: Maybe<Scalars['Int']['output']>;
}

export interface PerformanceReviewEvaluationScoreKpiRecordFilter {
  and?: InputMaybe<Array<PerformanceReviewEvaluationScoreKpiRecordFilter>>;
  externalValueDisplay?: InputMaybe<BigFloatFilter>;
  externalValueWeighted?: InputMaybe<BigFloatFilter>;
  internalValueDisplay?: InputMaybe<BigFloatFilter>;
  internalValueWeighted?: InputMaybe<BigFloatFilter>;
  not?: InputMaybe<PerformanceReviewEvaluationScoreKpiRecordFilter>;
  or?: InputMaybe<Array<PerformanceReviewEvaluationScoreKpiRecordFilter>>;
  totalWeight?: InputMaybe<IntFilter>;
}

export interface PerformanceReviewEvaluationScoreQualitativeConnection {
  __typename?: 'PerformanceReviewEvaluationScoreQualitativeConnection';
  edges: Array<PerformanceReviewEvaluationScoreQualitativeEdge>;
  nodes: Array<PerformanceReviewEvaluationScoreQualitativeRecord>;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewEvaluationScoreQualitativeEdge {
  __typename?: 'PerformanceReviewEvaluationScoreQualitativeEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewEvaluationScoreQualitativeRecord;
}

export interface PerformanceReviewEvaluationScoreQualitativeRecord {
  __typename?: 'PerformanceReviewEvaluationScoreQualitativeRecord';
  externalValueDisplay?: Maybe<Scalars['BigFloat']['output']>;
  externalValueWeighted?: Maybe<Scalars['BigFloat']['output']>;
  internalValueDisplay?: Maybe<Scalars['BigFloat']['output']>;
  internalValueWeighted?: Maybe<Scalars['BigFloat']['output']>;
  totalWeight?: Maybe<Scalars['Int']['output']>;
}

export interface PerformanceReviewEvaluationScoreQualitativeRecordFilter {
  and?: InputMaybe<Array<PerformanceReviewEvaluationScoreQualitativeRecordFilter>>;
  externalValueDisplay?: InputMaybe<BigFloatFilter>;
  externalValueWeighted?: InputMaybe<BigFloatFilter>;
  internalValueDisplay?: InputMaybe<BigFloatFilter>;
  internalValueWeighted?: InputMaybe<BigFloatFilter>;
  not?: InputMaybe<PerformanceReviewEvaluationScoreQualitativeRecordFilter>;
  or?: InputMaybe<Array<PerformanceReviewEvaluationScoreQualitativeRecordFilter>>;
  totalWeight?: InputMaybe<IntFilter>;
}

export interface PerformanceReviewEvaluationScoreValueConnection {
  __typename?: 'PerformanceReviewEvaluationScoreValueConnection';
  edges: Array<PerformanceReviewEvaluationScoreValueEdge>;
  nodes: Array<PerformanceReviewEvaluationScoreValueRecord>;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewEvaluationScoreValueEdge {
  __typename?: 'PerformanceReviewEvaluationScoreValueEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewEvaluationScoreValueRecord;
}

export interface PerformanceReviewEvaluationScoreValueRecord {
  __typename?: 'PerformanceReviewEvaluationScoreValueRecord';
  externalValueWeighted?: Maybe<Scalars['BigFloat']['output']>;
  internalValueWeighted?: Maybe<Scalars['BigFloat']['output']>;
  totalWeight?: Maybe<Scalars['Int']['output']>;
}

export interface PerformanceReviewEvaluationScoreValueRecordFilter {
  and?: InputMaybe<Array<PerformanceReviewEvaluationScoreValueRecordFilter>>;
  externalValueWeighted?: InputMaybe<BigFloatFilter>;
  internalValueWeighted?: InputMaybe<BigFloatFilter>;
  not?: InputMaybe<PerformanceReviewEvaluationScoreValueRecordFilter>;
  or?: InputMaybe<Array<PerformanceReviewEvaluationScoreValueRecordFilter>>;
  totalWeight?: InputMaybe<IntFilter>;
}

export interface PerformanceReviewEvaluationScoresConnection {
  __typename?: 'PerformanceReviewEvaluationScoresConnection';
  edges: Array<PerformanceReviewEvaluationScoreEdge>;
  nodes: Array<PerformanceReviewEvaluationScoresRecord>;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewEvaluationScoresRecord {
  __typename?: 'PerformanceReviewEvaluationScoresRecord';
  category?: Maybe<Scalars['String']['output']>;
  externalValueDisplay?: Maybe<Scalars['BigFloat']['output']>;
  externalValueWeighted?: Maybe<Scalars['BigFloat']['output']>;
  internalValueDisplay?: Maybe<Scalars['BigFloat']['output']>;
  internalValueWeighted?: Maybe<Scalars['BigFloat']['output']>;
  totalWeight?: Maybe<Scalars['BigFloat']['output']>;
}

export interface PerformanceReviewEvaluationScoresRecordFilter {
  and?: InputMaybe<Array<PerformanceReviewEvaluationScoresRecordFilter>>;
  category?: InputMaybe<StringFilter>;
  externalValueDisplay?: InputMaybe<BigFloatFilter>;
  externalValueWeighted?: InputMaybe<BigFloatFilter>;
  internalValueDisplay?: InputMaybe<BigFloatFilter>;
  internalValueWeighted?: InputMaybe<BigFloatFilter>;
  not?: InputMaybe<PerformanceReviewEvaluationScoresRecordFilter>;
  or?: InputMaybe<Array<PerformanceReviewEvaluationScoresRecordFilter>>;
  totalWeight?: InputMaybe<BigFloatFilter>;
}

export enum PerformanceReviewEvaluationStatus {
  ASSESSMENT_WAITING = 'ASSESSMENT_WAITING',
  DESCRIPTION_WAITING = 'DESCRIPTION_WAITING',
  LOCKED = 'LOCKED',
  UNFILLED = 'UNFILLED',
}

export interface PerformanceReviewEvaluationStatusFilter {
  distinctFrom?: InputMaybe<PerformanceReviewEvaluationStatus>;
  equalTo?: InputMaybe<PerformanceReviewEvaluationStatus>;
  greaterThan?: InputMaybe<PerformanceReviewEvaluationStatus>;
  greaterThanOrEqualTo?: InputMaybe<PerformanceReviewEvaluationStatus>;
  in?: InputMaybe<Array<PerformanceReviewEvaluationStatus>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<PerformanceReviewEvaluationStatus>;
  lessThanOrEqualTo?: InputMaybe<PerformanceReviewEvaluationStatus>;
  notDistinctFrom?: InputMaybe<PerformanceReviewEvaluationStatus>;
  notEqualTo?: InputMaybe<PerformanceReviewEvaluationStatus>;
  notIn?: InputMaybe<Array<PerformanceReviewEvaluationStatus>>;
}

export interface PerformanceReviewEvaluationToManyPerformanceReviewEvaluationCompetencyFilter {
  every?: InputMaybe<PerformanceReviewEvaluationCompetencyFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationCompetencyFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationCompetencyFilter>;
}

export interface PerformanceReviewEvaluationToManyPerformanceReviewEvaluationEvaluatorFilter {
  every?: InputMaybe<PerformanceReviewEvaluationEvaluatorFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationEvaluatorFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationEvaluatorFilter>;
}

export interface PerformanceReviewEvaluationToManyPerformanceReviewEvaluationFeedbackFilter {
  every?: InputMaybe<PerformanceReviewEvaluationFeedbackFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationFeedbackFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationFeedbackFilter>;
}

export interface PerformanceReviewEvaluationToManyPerformanceReviewEvaluationKpiAssessmentFilter {
  every?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentFilter>;
}

export interface PerformanceReviewEvaluationToManyPerformanceReviewEvaluationKpiDescriptionFilter {
  every?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionFilter>;
}

export interface PerformanceReviewEvaluationToManyPerformanceReviewEvaluationKpiResultFilter {
  every?: InputMaybe<PerformanceReviewEvaluationKpiResultFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationKpiResultFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationKpiResultFilter>;
}

export interface PerformanceReviewEvaluationToManyPerformanceReviewEvaluationKpiTargetFilter {
  every?: InputMaybe<PerformanceReviewEvaluationKpiTargetFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationKpiTargetFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationKpiTargetFilter>;
}

export interface PerformanceReviewEvaluationToManyPerformanceReviewEvaluationOneOnOneFilter {
  every?: InputMaybe<PerformanceReviewEvaluationOneOnOneFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationOneOnOneFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationOneOnOneFilter>;
}

export interface PerformanceReviewEvaluationToManyPerformanceReviewEvaluationOneOnOneSelfListFilter {
  every?: InputMaybe<PerformanceReviewEvaluationOneOnOneSelfListFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationOneOnOneSelfListFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationOneOnOneSelfListFilter>;
}

export interface PerformanceReviewEvaluationToManyPerformanceReviewEvaluationQualitativeAssessmentFilter {
  every?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentFilter>;
}

export interface PerformanceReviewEvaluationToManyPerformanceReviewEvaluationQualitativeDescriptionFilter {
  every?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionFilter>;
}

export interface PerformanceReviewEvaluationToManyPerformanceReviewEvaluationValueFilter {
  every?: InputMaybe<PerformanceReviewEvaluationValueFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationValueFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationValueFilter>;
}

export interface PerformanceReviewEvaluationUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationNodeIdConnect>>;
  connectByPerformanceReviewEvaluationId?: InputMaybe<Array<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationId?: InputMaybe<Array<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewEvaluationForPerformanceReviewEvaluationUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationId?: InputMaybe<
    Array<PerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationUserCreatedFkeyUsingPerformanceReviewEvaluationPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewEvaluationForPerformanceReviewEvaluationUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewEvaluationForPerformanceReviewEvaluationUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewEvaluationUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationNodeIdConnect>>;
  connectByPerformanceReviewEvaluationId?: InputMaybe<Array<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationId?: InputMaybe<Array<PerformanceReviewEvaluationPerformanceReviewEvaluationPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewEvaluationForPerformanceReviewEvaluationUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationId?: InputMaybe<
    Array<PerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationUserUpdatedFkeyUsingPerformanceReviewEvaluationPkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationValue extends Node {
  __typename?: 'PerformanceReviewEvaluationValue';
  assessmentComment?: Maybe<Scalars['String']['output']>;
  assessmentEmployee: Employee;
  assessmentEmployeeId: Scalars['BigInt']['output'];
  assessmentValue?: Maybe<Scalars['BigInt']['output']>;
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  nodeId: Scalars['ID']['output'];
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationId: Scalars['BigInt']['output'];
  performanceReviewEvaluationValueId: Scalars['BigInt']['output'];
  performanceReviewMasterRatingByAssessmentValue?: Maybe<PerformanceReviewMasterRating>;
  performanceReviewMasterValue: PerformanceReviewMasterValue;
  performanceReviewMasterValueId: Scalars['BigInt']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInput {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyEmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyUsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyNodeIdUpdate>;
}

export interface PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationValueNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationValuePerformanceReviewEvaluationPerformanceReviewEvaluationKeyConnect>
  >;
  connectByPerformanceReviewEvaluationValueId?: InputMaybe<Array<PerformanceReviewEvaluationValuePerformanceReviewEvaluationValuePkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyPerformanceReviewEvaluationValueCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationValueNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationValuePerformanceReviewEvaluationPerformanceReviewEvaluationKeyDelete>
  >;
  deleteByPerformanceReviewEvaluationValueId?: InputMaybe<Array<PerformanceReviewEvaluationValuePerformanceReviewEvaluationValuePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<EmployeeOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluationKeyUpdate>
  >;
  updateByPerformanceReviewEvaluationValueId?: InputMaybe<
    Array<PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyUsingPerformanceReviewEvaluationValuePkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyPerformanceReviewEvaluationValueCreateInput {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInput>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationValueId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInput>;
  performanceReviewMasterValue?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationValueAssessmentValueFkeyInput {
  connectByNodeId?: InputMaybe<PerformanceReviewMasterRatingNodeIdConnect>;
  connectByPerformanceReviewMasterRatingId?: InputMaybe<PerformanceReviewMasterRatingPerformanceReviewMasterRatingPkeyConnect>;
  create?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyPerformanceReviewMasterRatingCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewMasterRatingNodeIdDelete>;
  deleteByPerformanceReviewMasterRatingId?: InputMaybe<PerformanceReviewMasterRatingPerformanceReviewMasterRatingPkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentValueFkeyNodeIdUpdate>;
  updateByPerformanceReviewMasterRatingId?: InputMaybe<PerformanceReviewMasterRatingOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentValueFkeyUsingPerformanceReviewMasterRatingPkeyUpdate>;
}

export interface PerformanceReviewEvaluationValueAssessmentValueFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationValueNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationValuePerformanceReviewEvaluationPerformanceReviewEvaluationKeyConnect>
  >;
  connectByPerformanceReviewEvaluationValueId?: InputMaybe<Array<PerformanceReviewEvaluationValuePerformanceReviewEvaluationValuePkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewEvaluationValueAssessmentValueFkeyPerformanceReviewEvaluationValueCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationValueNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationValuePerformanceReviewEvaluationPerformanceReviewEvaluationKeyDelete>
  >;
  deleteByPerformanceReviewEvaluationValueId?: InputMaybe<Array<PerformanceReviewEvaluationValuePerformanceReviewEvaluationValuePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewMasterRatingOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentValueFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentValueFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluationKeyUpdate>
  >;
  updateByPerformanceReviewEvaluationValueId?: InputMaybe<
    Array<PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentValueFkeyUsingPerformanceReviewEvaluationValuePkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationValueAssessmentValueFkeyPerformanceReviewEvaluationValueCreateInput {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInput>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationValueId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInput>;
  performanceReviewMasterValue?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationValueAssessmentValueFkeyPerformanceReviewMasterRatingCreateInput {
  calculationValue: Scalars['BigFloat']['input'];
  displayValue: Scalars['Int']['input'];
  percentageLowerBound?: InputMaybe<Scalars['Int']['input']>;
  percentageUpperBound?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInverseInput>;
  performanceReviewMasterRatingDescriptions?: InputMaybe<PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyInverseInput>;
  performanceReviewMasterRatingId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInverseInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewEvaluationValueCondition {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationValueId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationValueFilter {
  and?: InputMaybe<Array<PerformanceReviewEvaluationValueFilter>>;
  assessmentComment?: InputMaybe<StringFilter>;
  assessmentEmployee?: InputMaybe<EmployeeFilter>;
  assessmentEmployeeId?: InputMaybe<BigIntFilter>;
  assessmentValue?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  not?: InputMaybe<PerformanceReviewEvaluationValueFilter>;
  or?: InputMaybe<Array<PerformanceReviewEvaluationValueFilter>>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluationFilter>;
  performanceReviewEvaluationId?: InputMaybe<BigIntFilter>;
  performanceReviewEvaluationValueId?: InputMaybe<BigIntFilter>;
  performanceReviewMasterRatingByAssessmentValue?: InputMaybe<PerformanceReviewMasterRatingFilter>;
  performanceReviewMasterRatingByAssessmentValueExists?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterValue?: InputMaybe<PerformanceReviewMasterValueFilter>;
  performanceReviewMasterValueId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface PerformanceReviewEvaluationValueInput {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInput>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationValueId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInput>;
  performanceReviewMasterValue?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationValueNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewEvaluationValueNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationPatch;
}

export interface PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluationKeyUpdate {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyUsingPerformanceReviewEvaluationValuePkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyPatch;
  performanceReviewEvaluationValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewMasterVaFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterValuePatch;
}

export interface PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewMasterVaFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluationKeyUpdate {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewMasterVaFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewMasterVaFkeyUsingPerformanceReviewEvaluationValuePkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewMasterVaFkeyPatch;
  performanceReviewEvaluationValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluationKeyUpdate {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyUsingPerformanceReviewEvaluationValuePkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyPatch;
  performanceReviewEvaluationValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentValueFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterRatingPatch;
}

export interface PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentValueFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluationKeyUpdate {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentValueFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentValueFkeyUsingPerformanceReviewEvaluationValuePkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentValueFkeyPatch;
  performanceReviewEvaluationValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserCreatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluationKeyUpdate {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserCreatedFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserCreatedFkeyUsingPerformanceReviewEvaluationValuePkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserCreatedFkeyPatch;
  performanceReviewEvaluationValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserUpdatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluationKeyUpdate {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserUpdatedFkeyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserUpdatedFkeyUsingPerformanceReviewEvaluationValuePkeyUpdate {
  patch: UpdatePerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserUpdatedFkeyPatch;
  performanceReviewEvaluationValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationValuePatch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInput>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationValueId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInput>;
  performanceReviewMasterValue?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewEvaluationValuePerformanceReviewEvaluationPerformanceReviewEvaluationKeyConnect {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationValuePerformanceReviewEvaluationPerformanceReviewEvaluationKeyDelete {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationValuePerformanceReviewEvaluationValuePkeyConnect {
  performanceReviewEvaluationValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationValuePerformanceReviewEvaluationValuePkeyDelete {
  performanceReviewEvaluationValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationValueNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationValuePerformanceReviewEvaluationPerformanceReviewEvaluationKeyConnect>
  >;
  connectByPerformanceReviewEvaluationValueId?: InputMaybe<Array<PerformanceReviewEvaluationValuePerformanceReviewEvaluationValuePkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationValueNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationValuePerformanceReviewEvaluationPerformanceReviewEvaluationKeyDelete>
  >;
  deleteByPerformanceReviewEvaluationValueId?: InputMaybe<Array<PerformanceReviewEvaluationValuePerformanceReviewEvaluationValuePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserCreatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluationKeyUpdate>
  >;
  updateByPerformanceReviewEvaluationValueId?: InputMaybe<
    Array<PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserCreatedFkeyUsingPerformanceReviewEvaluationValuePkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationValueUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationValueNodeIdConnect>>;
  connectByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationValuePerformanceReviewEvaluationPerformanceReviewEvaluationKeyConnect>
  >;
  connectByPerformanceReviewEvaluationValueId?: InputMaybe<Array<PerformanceReviewEvaluationValuePerformanceReviewEvaluationValuePkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewEvaluationValueNodeIdDelete>>;
  deleteByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationValuePerformanceReviewEvaluationPerformanceReviewEvaluationKeyDelete>
  >;
  deleteByPerformanceReviewEvaluationValueId?: InputMaybe<Array<PerformanceReviewEvaluationValuePerformanceReviewEvaluationValuePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeId?: InputMaybe<
    Array<PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserUpdatedFkeyUsingPerformanceReviewEvaluationPerformanceReviewEvaluationKeyUpdate>
  >;
  updateByPerformanceReviewEvaluationValueId?: InputMaybe<
    Array<PerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserUpdatedFkeyUsingPerformanceReviewEvaluationValuePkeyUpdate>
  >;
}

export interface PerformanceReviewEvaluationValuesConnection {
  __typename?: 'PerformanceReviewEvaluationValuesConnection';
  edges: Array<PerformanceReviewEvaluationValuesEdge>;
  nodes: Array<PerformanceReviewEvaluationValue>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewEvaluationValuesEdge {
  __typename?: 'PerformanceReviewEvaluationValuesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewEvaluationValue;
}

export enum PerformanceReviewEvaluationValuesOrderBy {
  ASSESSMENT_COMMENT_ASC = 'ASSESSMENT_COMMENT_ASC',
  ASSESSMENT_COMMENT_DESC = 'ASSESSMENT_COMMENT_DESC',
  ASSESSMENT_EMPLOYEE_ID_ASC = 'ASSESSMENT_EMPLOYEE_ID_ASC',
  ASSESSMENT_EMPLOYEE_ID_DESC = 'ASSESSMENT_EMPLOYEE_ID_DESC',
  ASSESSMENT_VALUE_ASC = 'ASSESSMENT_VALUE_ASC',
  ASSESSMENT_VALUE_DESC = 'ASSESSMENT_VALUE_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_ASSESSMENT_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__CALCULATION_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__CALCULATION_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__CALCULATION_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__CALCULATION_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DISPLAY_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DISPLAY_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DISPLAY_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__DISPLAY_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERCENTAGE_LOWER_BOUND_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERCENTAGE_LOWER_BOUND_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERCENTAGE_LOWER_BOUND_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERCENTAGE_LOWER_BOUND_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERCENTAGE_UPPER_BOUND_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERCENTAGE_UPPER_BOUND_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERCENTAGE_UPPER_BOUND_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERCENTAGE_UPPER_BOUND_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT_VALUE__YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__WEIGHT_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__WEIGHT_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__WEIGHT_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__WEIGHT_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface PerformanceReviewEvaluationsConnection {
  __typename?: 'PerformanceReviewEvaluationsConnection';
  edges: Array<PerformanceReviewEvaluationsEdge>;
  nodes: Array<PerformanceReviewEvaluation>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewEvaluationsEdge {
  __typename?: 'PerformanceReviewEvaluationsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewEvaluation;
}

export enum PerformanceReviewEvaluationsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__COMPANY_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__COMPANY_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__COMPANY_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__COMPANY_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_CREATED_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_CREATED_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_CREATED_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_CREATED_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_UPDATED_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_UPDATED_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_UPDATED_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_UPDATED_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DEPARTMENT_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DEPARTMENT_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DEPARTMENT_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DEPARTMENT_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DIVISION_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DIVISION_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DIVISION_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DIVISION_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_CODE_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_CODE_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_CODE_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_CODE_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__JOB_TITLE_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__JOB_TITLE_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__JOB_TITLE_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__JOB_TITLE_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__OFFICE_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__OFFICE_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__OFFICE_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__OFFICE_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__REMARK_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__REMARK_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__REMARK_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__REMARK_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__TEAM_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__TEAM_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__TEAM_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__TEAM_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_CREATED_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_CREATED_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_CREATED_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_CREATED_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_UPDATED_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_UPDATED_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_UPDATED_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_UPDATED_DESC',
  EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORY_ID_DESC',
  IS_LOCKED_ASSESSMENT_ASC = 'IS_LOCKED_ASSESSMENT_ASC',
  IS_LOCKED_ASSESSMENT_DESC = 'IS_LOCKED_ASSESSMENT_DESC',
  IS_LOCKED_DESCRIPTION_ASC = 'IS_LOCKED_DESCRIPTION_ASC',
  IS_LOCKED_DESCRIPTION_DESC = 'IS_LOCKED_DESCRIPTION_DESC',
  MID_LONG_TERM_GOAL_ASC = 'MID_LONG_TERM_GOAL_ASC',
  MID_LONG_TERM_GOAL_DESC = 'MID_LONG_TERM_GOAL_DESC',
  NATURAL = 'NATURAL',
  NEED_INPUT_COMPETENCY_ASC = 'NEED_INPUT_COMPETENCY_ASC',
  NEED_INPUT_COMPETENCY_DESC = 'NEED_INPUT_COMPETENCY_DESC',
  NEED_INPUT_TARGET_KPI_ASC = 'NEED_INPUT_TARGET_KPI_ASC',
  NEED_INPUT_TARGET_KPI_DESC = 'NEED_INPUT_TARGET_KPI_DESC',
  NEED_INPUT_TARGET_QUALITATIVE_ASC = 'NEED_INPUT_TARGET_QUALITATIVE_ASC',
  NEED_INPUT_TARGET_QUALITATIVE_DESC = 'NEED_INPUT_TARGET_QUALITATIVE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_EVALUATOR_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_EVALUATOR_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_EVALUATOR_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_EVALUATOR_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_EVALUATOR_TYPE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_EVALUATOR_TYPE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_EVALUATOR_TYPE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_EVALUATOR_TYPE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_EVALUATOR_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_EVALUATOR_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_EVALUATOR_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_EVALUATOR_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_EVALUATOR_TYPE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_EVALUATOR_TYPE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_EVALUATOR_TYPE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_EVALUATOR_TYPE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_FEEDBACK_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_FEEDBACK_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_FEEDBACK_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_FEEDBACK_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_FEEDBACK_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_FEEDBACK_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_FEEDBACK_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_FEEDBACK_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_FEEDBACK_EMPLOYEE_NAME_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_FEEDBACK_EMPLOYEE_NAME_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_FEEDBACK_EMPLOYEE_NAME_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_FEEDBACK_EMPLOYEE_NAME_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_FEEDBACK_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_FEEDBACK_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_FEEDBACK_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_FEEDBACK_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_FEEDBACK_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_FEEDBACK_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_FEEDBACK_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_FEEDBACK_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_FEEDBACK_EMPLOYEE_NAME_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_FEEDBACK_EMPLOYEE_NAME_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_FEEDBACK_EMPLOYEE_NAME_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_FEEDBACK_EMPLOYEE_NAME_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_INVERTED_ACHIEVEMENT_RATE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_INVERTED_ACHIEVEMENT_RATE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_INVERTED_ACHIEVEMENT_RATE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_INVERTED_ACHIEVEMENT_RATE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_TITLE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_TITLE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_TITLE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_TITLE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_WEIGHT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_WEIGHT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_WEIGHT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_WEIGHT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_INVERTED_ACHIEVEMENT_RATE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_INVERTED_ACHIEVEMENT_RATE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_INVERTED_ACHIEVEMENT_RATE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_INVERTED_ACHIEVEMENT_RATE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_TITLE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_TITLE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_TITLE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_TITLE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_WEIGHT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_WEIGHT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_WEIGHT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_WEIGHT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_VALUE_UNIT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_VALUE_UNIT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_VALUE_UNIT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_VALUE_UNIT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_VALUE_UNIT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_VALUE_UNIT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_VALUE_UNIT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_VALUE_UNIT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_INVERTED_ACHIEVEMENT_RATE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_INVERTED_ACHIEVEMENT_RATE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_INVERTED_ACHIEVEMENT_RATE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_INVERTED_ACHIEVEMENT_RATE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_NAME_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_NAME_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_NAME_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_NAME_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_VALUE_UNIT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_VALUE_UNIT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_VALUE_UNIT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_VALUE_UNIT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_INVERTED_ACHIEVEMENT_RATE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_INVERTED_ACHIEVEMENT_RATE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_INVERTED_ACHIEVEMENT_RATE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_INVERTED_ACHIEVEMENT_RATE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_NAME_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_NAME_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_NAME_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_NAME_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_VALUE_UNIT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_VALUE_UNIT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_VALUE_UNIT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_VALUE_UNIT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_MONTH_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_MONTH_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_MONTH_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_MONTH_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_MONTH_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_MONTH_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_MONTH_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_MONTH_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_IS_FILLED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_IS_FILLED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_IS_FILLED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_IS_FILLED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_MONTH_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_MONTH_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_MONTH_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_MONTH_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_IS_FILLED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_IS_FILLED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_IS_FILLED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_IS_FILLED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_MONTH_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_MONTH_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_MONTH_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_MONTH_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_SELF_LISTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DEFINITION_FOR_SCORE_100_PERCENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DEFINITION_FOR_SCORE_100_PERCENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DEFINITION_FOR_SCORE_100_PERCENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DEFINITION_FOR_SCORE_100_PERCENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_EVALUATOR_EXPECTATION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_EVALUATOR_EXPECTATION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_EVALUATOR_EXPECTATION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_EVALUATOR_EXPECTATION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_TITLE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_TITLE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_TITLE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_TITLE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_WEIGHT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_WEIGHT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_WEIGHT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_WEIGHT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DEFINITION_FOR_SCORE_100_PERCENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DEFINITION_FOR_SCORE_100_PERCENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DEFINITION_FOR_SCORE_100_PERCENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DEFINITION_FOR_SCORE_100_PERCENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_EVALUATOR_EXPECTATION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_EVALUATOR_EXPECTATION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_EVALUATOR_EXPECTATION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_EVALUATOR_EXPECTATION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_TITLE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_TITLE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_TITLE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_TITLE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_WEIGHT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_WEIGHT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_WEIGHT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_WEIGHT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__BUSINESS_UNIT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__BUSINESS_UNIT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__BUSINESS_UNIT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__BUSINESS_UNIT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DEPARTMENT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DEPARTMENT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DEPARTMENT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DEPARTMENT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DIVISION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DIVISION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DIVISION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DIVISION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__ROLE_NAME_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__ROLE_NAME_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__ROLE_NAME_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__ROLE_NAME_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  QUARTER_ASC = 'QUARTER_ASC',
  QUARTER_DESC = 'QUARTER_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
}

export interface PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1Input {
  connectByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyRoleNodeIdConnect>;
  connectByPerformanceReviewMasterCompetencyRoleId?: InputMaybe<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterCompetencyRolePkeyConnect>;
  connectByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleName?: InputMaybe<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterComYearQuarterBusinessUnitIdKeyConnect>;
  create?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1PerformanceReviewMasterCompetencyRoleCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyRoleNodeIdDelete>;
  deleteByPerformanceReviewMasterCompetencyRoleId?: InputMaybe<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterCompetencyRolePkeyDelete>;
  deleteByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleName?: InputMaybe<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterComYearQuarterBusinessUnitIdKeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey1NodeIdUpdate>;
  updateByPerformanceReviewMasterCompetencyRoleId?: InputMaybe<PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey1UsingPerformanceReviewMasterCompetencyRolePkeyUpdate>;
  updateByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleName?: InputMaybe<PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey1UsingPerformanceReviewMasterComYearQuarterBusinessUnitIdKeyUpdate>;
}

export interface PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1InverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailNodeIdConnect>>;
  connectByPerformanceReviewMasterCompetencyDetailId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterCompetencyDetailPkeyConnect>>;
  connectByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterComPerformanceReviewMasterCoKey1Connect>
  >;
  create?: InputMaybe<Array<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1PerformanceReviewMasterCompetencyDetailCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailNodeIdDelete>>;
  deleteByPerformanceReviewMasterCompetencyDetailId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterCompetencyDetailPkeyDelete>>;
  deleteByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterComPerformanceReviewMasterCoKey1Delete>
  >;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey1NodeIdUpdate>>;
  updateByPerformanceReviewMasterCompetencyDetailId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey1UsingPerformanceReviewMasterCompetencyDetailPkeyUpdate>
  >;
  updateByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey1UsingPerformanceReviewMasterComPerformanceReviewMasterCoKey1Update>
  >;
}

export interface PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1PerformanceReviewMasterCompetencyDetailCreateInput {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  overridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3InverseInput>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterComOverridingPerformanceRevieFkeyInput>;
  performanceReviewMasterCompetencyDetailDescriptions?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey2InverseInput>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1Input>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1PerformanceReviewMasterCompetencyRoleCreateInput {
  businessUnit?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1InverseInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  roleName: Scalars['String']['input'];
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterCoPerformanceReviewMasterCoFkey2Input {
  connectByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyDetailNodeIdConnect>;
  connectByPerformanceReviewMasterCompetencyDetailId?: InputMaybe<PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterCompetencyDetailPkeyConnect>;
  connectByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterComPerformanceReviewMasterCoKey1Connect>;
  create?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey2PerformanceReviewMasterCompetencyDetailCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyDetailNodeIdDelete>;
  deleteByPerformanceReviewMasterCompetencyDetailId?: InputMaybe<PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterCompetencyDetailPkeyDelete>;
  deleteByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterComPerformanceReviewMasterCoKey1Delete>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDescriptionOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey2NodeIdUpdate>;
  updateByPerformanceReviewMasterCompetencyDetailId?: InputMaybe<PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey2UsingPerformanceReviewMasterCompetencyDetailPkeyUpdate>;
  updateByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey2UsingPerformanceReviewMasterComPerformanceReviewMasterCoKey1Update>;
}

export interface PerformanceReviewMasterCoPerformanceReviewMasterCoFkey2InverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionNodeIdConnect>>;
  connectByPerformanceReviewMasterCompetencyDetailDescriptionId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionPerformanceReviewMasterCompetencyDetailDescriptionPkeyConnect>>;
  connectByPerformanceReviewMasterCompetencyDetailIdAndLanguage?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionPerformanceReviewMasterComPerformanceReviewMasterCoKey2Connect>>;
  create?: InputMaybe<Array<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey2PerformanceReviewMasterCompetencyDetailDescriptionCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionNodeIdDelete>>;
  deleteByPerformanceReviewMasterCompetencyDetailDescriptionId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionPerformanceReviewMasterCompetencyDetailDescriptionPkeyDelete>>;
  deleteByPerformanceReviewMasterCompetencyDetailIdAndLanguage?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionPerformanceReviewMasterComPerformanceReviewMasterCoKey2Delete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey2NodeIdUpdate>>;
  updateByPerformanceReviewMasterCompetencyDetailDescriptionId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyDetailDescriptionOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey2UsingPerformanceReviewMasterCompetencyDetailDescriptionPkeyUpdate>
  >;
  updateByPerformanceReviewMasterCompetencyDetailIdAndLanguage?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyDetailDescriptionOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey2UsingPerformanceReviewMasterComPerformanceReviewMasterCoKey2Update>
  >;
}

export interface PerformanceReviewMasterCoPerformanceReviewMasterCoFkey2PerformanceReviewMasterCompetencyDetailCreateInput {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  overridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3InverseInput>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterComOverridingPerformanceRevieFkeyInput>;
  performanceReviewMasterCompetencyDetailDescriptions?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey2InverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1Input>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterCoPerformanceReviewMasterCoFkey2PerformanceReviewMasterCompetencyDetailDescriptionCreateInput {
  description: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyDetail?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey2Input>;
  performanceReviewMasterCompetencyDetailDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3Input {
  connectByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyDetailNodeIdConnect>;
  connectByPerformanceReviewMasterCompetencyDetailId?: InputMaybe<PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterCompetencyDetailPkeyConnect>;
  connectByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterComPerformanceReviewMasterCoKey1Connect>;
  create?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3PerformanceReviewMasterCompetencyDetailCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyDetailNodeIdDelete>;
  deleteByPerformanceReviewMasterCompetencyDetailId?: InputMaybe<PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterCompetencyDetailPkeyDelete>;
  deleteByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterComPerformanceReviewMasterCoKey1Delete>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey3NodeIdUpdate>;
  updateByPerformanceReviewMasterCompetencyDetailId?: InputMaybe<PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey3UsingPerformanceReviewMasterCompetencyDetailPkeyUpdate>;
  updateByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey3UsingPerformanceReviewMasterComPerformanceReviewMasterCoKey1Update>;
}

export interface PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3InverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyNodeIdConnect>>;
  connectByPerformanceReviewMasterCompetencyId?: InputMaybe<Array<PerformanceReviewMasterCompetencyPerformanceReviewMasterCompetencyPkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3PerformanceReviewMasterCompetencyCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyNodeIdDelete>>;
  deleteByPerformanceReviewMasterCompetencyId?: InputMaybe<Array<PerformanceReviewMasterCompetencyPerformanceReviewMasterCompetencyPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey3NodeIdUpdate>>;
  updateByPerformanceReviewMasterCompetencyId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey3UsingPerformanceReviewMasterCompetencyPkeyUpdate>
  >;
}

export interface PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3PerformanceReviewMasterCompetencyCreateInput {
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10InverseInput>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4Input>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyDetail?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3Input>;
  performanceReviewMasterCompetencyId: Scalars['String']['input'];
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3PerformanceReviewMasterCompetencyDetailCreateInput {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  overridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3InverseInput>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterComOverridingPerformanceRevieFkeyInput>;
  performanceReviewMasterCompetencyDetailDescriptions?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey2InverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1Input>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4Input {
  connectByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyCommonNodeIdConnect>;
  connectByPerformanceReviewMasterCompetencyCommonId?: InputMaybe<PerformanceReviewMasterCompetencyCommonPerformanceReviewMasterCompetencyCommonPkeyConnect>;
  create?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4PerformanceReviewMasterCompetencyCommonCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyCommonNodeIdDelete>;
  deleteByPerformanceReviewMasterCompetencyCommonId?: InputMaybe<PerformanceReviewMasterCompetencyCommonPerformanceReviewMasterCompetencyCommonPkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey4NodeIdUpdate>;
  updateByPerformanceReviewMasterCompetencyCommonId?: InputMaybe<PerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey4UsingPerformanceReviewMasterCompetencyCommonPkeyUpdate>;
}

export interface PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4InverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyNodeIdConnect>>;
  connectByPerformanceReviewMasterCompetencyId?: InputMaybe<Array<PerformanceReviewMasterCompetencyPerformanceReviewMasterCompetencyPkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4PerformanceReviewMasterCompetencyCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyNodeIdDelete>>;
  deleteByPerformanceReviewMasterCompetencyId?: InputMaybe<Array<PerformanceReviewMasterCompetencyPerformanceReviewMasterCompetencyPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey4NodeIdUpdate>>;
  updateByPerformanceReviewMasterCompetencyId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey4UsingPerformanceReviewMasterCompetencyPkeyUpdate>
  >;
}

export interface PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4PerformanceReviewMasterCompetencyCommonCreateInput {
  displayOrder: Scalars['Int']['input'];
  overridable?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4InverseInput>;
  performanceReviewMasterCompetencyCommonDescriptions?: InputMaybe<PerformanceReviewMasterComPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterComOverridingPerformanceRevieFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInverseInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4PerformanceReviewMasterCompetencyCreateInput {
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10InverseInput>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4Input>;
  performanceReviewMasterCompetencyDetail?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3Input>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyId: Scalars['String']['input'];
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterComOverridingPerformanceRevieFkeyInput {
  connectByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyCommonNodeIdConnect>;
  connectByPerformanceReviewMasterCompetencyCommonId?: InputMaybe<PerformanceReviewMasterCompetencyCommonPerformanceReviewMasterCompetencyCommonPkeyConnect>;
  create?: InputMaybe<PerformanceReviewMasterComOverridingPerformanceRevieFkeyPerformanceReviewMasterCompetencyCommonCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyCommonNodeIdDelete>;
  deleteByPerformanceReviewMasterCompetencyCommonId?: InputMaybe<PerformanceReviewMasterCompetencyCommonPerformanceReviewMasterCompetencyCommonPkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterComOverridingPerformanceRevieFkeyNodeIdUpdate>;
  updateByPerformanceReviewMasterCompetencyCommonId?: InputMaybe<PerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterComOverridingPerformanceRevieFkeyUsingPerformanceReviewMasterCompetencyCommonPkeyUpdate>;
}

export interface PerformanceReviewMasterComOverridingPerformanceRevieFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailNodeIdConnect>>;
  connectByPerformanceReviewMasterCompetencyDetailId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterCompetencyDetailPkeyConnect>>;
  connectByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterComPerformanceReviewMasterCoKey1Connect>
  >;
  create?: InputMaybe<Array<PerformanceReviewMasterComOverridingPerformanceRevieFkeyPerformanceReviewMasterCompetencyDetailCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailNodeIdDelete>>;
  deleteByPerformanceReviewMasterCompetencyDetailId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterCompetencyDetailPkeyDelete>>;
  deleteByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterComPerformanceReviewMasterCoKey1Delete>
  >;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterComOverridingPerformanceRevieFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterCompetencyDetailId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterComOverridingPerformanceRevieFkeyUsingPerformanceReviewMasterCompetencyDetailPkeyUpdate>
  >;
  updateByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterComOverridingPerformanceRevieFkeyUsingPerformanceReviewMasterComPerformanceReviewMasterCoKey1Update>
  >;
}

export interface PerformanceReviewMasterComOverridingPerformanceRevieFkeyPerformanceReviewMasterCompetencyCommonCreateInput {
  displayOrder: Scalars['Int']['input'];
  overridable?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4InverseInput>;
  performanceReviewMasterCompetencyCommonDescriptions?: InputMaybe<PerformanceReviewMasterComPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterComOverridingPerformanceRevieFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInverseInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterComOverridingPerformanceRevieFkeyPerformanceReviewMasterCompetencyDetailCreateInput {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3InverseInput>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterComOverridingPerformanceRevieFkeyInput>;
  performanceReviewMasterCompetencyDetailDescriptions?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey2InverseInput>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1Input>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterComPerformanceReviewMasterCoFkeyInput {
  connectByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyCommonNodeIdConnect>;
  connectByPerformanceReviewMasterCompetencyCommonId?: InputMaybe<PerformanceReviewMasterCompetencyCommonPerformanceReviewMasterCompetencyCommonPkeyConnect>;
  create?: InputMaybe<PerformanceReviewMasterComPerformanceReviewMasterCoFkeyPerformanceReviewMasterCompetencyCommonCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyCommonNodeIdDelete>;
  deleteByPerformanceReviewMasterCompetencyCommonId?: InputMaybe<PerformanceReviewMasterCompetencyCommonPerformanceReviewMasterCompetencyCommonPkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDescriptionOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterComPerformanceReviewMasterCoFkeyNodeIdUpdate>;
  updateByPerformanceReviewMasterCompetencyCommonId?: InputMaybe<PerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterComPerformanceReviewMasterCoFkeyUsingPerformanceReviewMasterCompetencyCommonPkeyUpdate>;
}

export interface PerformanceReviewMasterComPerformanceReviewMasterCoFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionNodeIdConnect>>;
  connectByPerformanceReviewMasterCompetencyCommonDescriptionId?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionPerformanceReviewMasterCompetencyCommonDescriptionPkeyConnect>>;
  connectByPerformanceReviewMasterCompetencyCommonIdAndLanguage?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionPerformanceReviewMasterComPerformanceReviewMasterComKeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewMasterComPerformanceReviewMasterCoFkeyPerformanceReviewMasterCompetencyCommonDescriptionCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionNodeIdDelete>>;
  deleteByPerformanceReviewMasterCompetencyCommonDescriptionId?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionPerformanceReviewMasterCompetencyCommonDescriptionPkeyDelete>>;
  deleteByPerformanceReviewMasterCompetencyCommonIdAndLanguage?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionPerformanceReviewMasterComPerformanceReviewMasterComKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterComPerformanceReviewMasterCoFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterCompetencyCommonDescriptionId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyCommonDescriptionOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterComPerformanceReviewMasterCoFkeyUsingPerformanceReviewMasterCompetencyCommonDescriptionPkeyUpdate>
  >;
  updateByPerformanceReviewMasterCompetencyCommonIdAndLanguage?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyCommonDescriptionOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterComPerformanceReviewMasterCoFkeyUsingPerformanceReviewMasterComPerformanceReviewMasterComKeyUpdate>
  >;
}

export interface PerformanceReviewMasterComPerformanceReviewMasterCoFkeyPerformanceReviewMasterCompetencyCommonCreateInput {
  displayOrder: Scalars['Int']['input'];
  overridable?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4InverseInput>;
  performanceReviewMasterCompetencyCommonDescriptions?: InputMaybe<PerformanceReviewMasterComPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterComOverridingPerformanceRevieFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInverseInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterComPerformanceReviewMasterCoFkeyPerformanceReviewMasterCompetencyCommonDescriptionCreateInput {
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterComPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyCommonDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterCompetenciesConnection {
  __typename?: 'PerformanceReviewMasterCompetenciesConnection';
  edges: Array<PerformanceReviewMasterCompetenciesEdge>;
  nodes: Array<PerformanceReviewMasterCompetency>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterCompetenciesEdge {
  __typename?: 'PerformanceReviewMasterCompetenciesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewMasterCompetency;
}

export enum PerformanceReviewMasterCompetenciesOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__OVERRIDABLE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__OVERRIDABLE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__OVERRIDABLE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__OVERRIDABLE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__WEIGHT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__WEIGHT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__WEIGHT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__WEIGHT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__WEIGHT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__WEIGHT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__WEIGHT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__WEIGHT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface PerformanceReviewMasterCompetency extends Node {
  __typename?: 'PerformanceReviewMasterCompetency';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  nodeId: Scalars['ID']['output'];
  performanceReviewEvaluationCompetencies: PerformanceReviewEvaluationCompetenciesConnection;
  performanceReviewMasterCompetencyCommon?: Maybe<PerformanceReviewMasterCompetencyCommon>;
  performanceReviewMasterCompetencyCommonId?: Maybe<Scalars['BigInt']['output']>;
  performanceReviewMasterCompetencyDetail?: Maybe<PerformanceReviewMasterCompetencyDetail>;
  performanceReviewMasterCompetencyDetailId?: Maybe<Scalars['BigInt']['output']>;
  performanceReviewMasterCompetencyId: Scalars['String']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export type PerformanceReviewMasterCompetencyPerformanceReviewEvaluationCompetenciesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationCompetencyCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationCompetencyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationCompetenciesOrderBy>>;
};

export interface PerformanceReviewMasterCompetencyCommon extends Node {
  __typename?: 'PerformanceReviewMasterCompetencyCommon';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  displayOrder: Scalars['Int']['output'];
  nodeId: Scalars['ID']['output'];
  overridable: Scalars['Boolean']['output'];
  performanceReviewMasterCompetencies: PerformanceReviewMasterCompetenciesConnection;
  performanceReviewMasterCompetencyCommonDescriptions: PerformanceReviewMasterCompetencyCommonDescriptionsConnection;
  performanceReviewMasterCompetencyCommonId: Scalars['BigInt']['output'];
  performanceReviewMasterCompetencyDetailsByOverridingPerformanceReviewMasterCompetencyCommonId: PerformanceReviewMasterCompetencyDetailsConnection;
  probationReviewEvaluationResultScoreCompetenciesByPerformanceReviewMasterCompetencyId: ProbationReviewEvaluationResultScoreCompetenciesConnection;
  quarter: PerformanceReviewQuarter;
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
  weight: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
}

export type PerformanceReviewMasterCompetencyCommonPerformanceReviewMasterCompetenciesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetenciesOrderBy>>;
};

export type PerformanceReviewMasterCompetencyCommonPerformanceReviewMasterCompetencyCommonDescriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyCommonDescriptionCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyCommonDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionsOrderBy>>;
};

export type PerformanceReviewMasterCompetencyCommonPerformanceReviewMasterCompetencyDetailsByOverridingPerformanceReviewMasterCompetencyCommonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyDetailCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyDetailFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailsOrderBy>>;
};

export type PerformanceReviewMasterCompetencyCommonProbationReviewEvaluationResultScoreCompetenciesByPerformanceReviewMasterCompetencyIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetenciesOrderBy>>;
};

export interface PerformanceReviewMasterCompetencyCommonCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  overridable?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionNodeIdConnect>>;
  connectByPerformanceReviewMasterCompetencyCommonDescriptionId?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionPerformanceReviewMasterCompetencyCommonDescriptionPkeyConnect>>;
  connectByPerformanceReviewMasterCompetencyCommonIdAndLanguage?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionPerformanceReviewMasterComPerformanceReviewMasterComKeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionNodeIdDelete>>;
  deleteByPerformanceReviewMasterCompetencyCommonDescriptionId?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionPerformanceReviewMasterCompetencyCommonDescriptionPkeyDelete>>;
  deleteByPerformanceReviewMasterCompetencyCommonIdAndLanguage?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionPerformanceReviewMasterComPerformanceReviewMasterComKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterCompetencyCommonDescriptionId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyCommonDescriptionOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserCreatedFkeyUsingPerformanceReviewMasterCompetencyCommonDescriptionPkeyUpdate>
  >;
  updateByPerformanceReviewMasterCompetencyCommonIdAndLanguage?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyCommonDescriptionOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserCreatedFkeyUsingPerformanceReviewMasterComPerformanceReviewMasterComKeyUpdate>
  >;
}

export interface PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDescriptionOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionNodeIdConnect>>;
  connectByPerformanceReviewMasterCompetencyCommonDescriptionId?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionPerformanceReviewMasterCompetencyCommonDescriptionPkeyConnect>>;
  connectByPerformanceReviewMasterCompetencyCommonIdAndLanguage?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionPerformanceReviewMasterComPerformanceReviewMasterComKeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionNodeIdDelete>>;
  deleteByPerformanceReviewMasterCompetencyCommonDescriptionId?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionPerformanceReviewMasterCompetencyCommonDescriptionPkeyDelete>>;
  deleteByPerformanceReviewMasterCompetencyCommonIdAndLanguage?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionPerformanceReviewMasterComPerformanceReviewMasterComKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterCompetencyCommonDescriptionId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyCommonDescriptionOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyUsingPerformanceReviewMasterCompetencyCommonDescriptionPkeyUpdate>
  >;
  updateByPerformanceReviewMasterCompetencyCommonIdAndLanguage?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyCommonDescriptionOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyUsingPerformanceReviewMasterComPerformanceReviewMasterComKeyUpdate>
  >;
}

export interface PerformanceReviewMasterCompetencyCommonDescription extends Node {
  __typename?: 'PerformanceReviewMasterCompetencyCommonDescription';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  language: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  performanceReviewMasterCompetencyCommon: PerformanceReviewMasterCompetencyCommon;
  performanceReviewMasterCompetencyCommonDescriptionId: Scalars['BigInt']['output'];
  performanceReviewMasterCompetencyCommonId: Scalars['BigInt']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface PerformanceReviewMasterCompetencyCommonDescriptionCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyCommonDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterCompetencyCommonDescriptionFilter {
  and?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  description?: InputMaybe<StringFilter>;
  language?: InputMaybe<StringFilter>;
  not?: InputMaybe<PerformanceReviewMasterCompetencyCommonDescriptionFilter>;
  or?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionFilter>>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterCompetencyCommonFilter>;
  performanceReviewMasterCompetencyCommonDescriptionId?: InputMaybe<BigIntFilter>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface PerformanceReviewMasterCompetencyCommonDescriptionInput {
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterComPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyCommonDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterCompetencyCommonDescriptionNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterCompetencyCommonDescriptionNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterCompetencyCommonDescriptionOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterComPerformanceReviewMasterCoFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyCommonPatch;
}

export interface PerformanceReviewMasterCompetencyCommonDescriptionOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterComPerformanceReviewMasterCoFkeyUsingPerformanceReviewMasterComPerformanceReviewMasterComKeyUpdate {
  language: Scalars['String']['input'];
  patch: UpdatePerformanceReviewMasterCompetencyCommonDescriptionOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterComPerformanceReviewMasterCoFkeyPatch;
  performanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyCommonDescriptionOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterComPerformanceReviewMasterCoFkeyUsingPerformanceReviewMasterCompetencyCommonDescriptionPkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyCommonDescriptionOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterComPerformanceReviewMasterCoFkeyPatch;
  performanceReviewMasterCompetencyCommonDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyCommonDescriptionOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserCreatedFkeyUsingPerformanceReviewMasterComPerformanceReviewMasterComKeyUpdate {
  language: Scalars['String']['input'];
  patch: UpdatePerformanceReviewMasterCompetencyCommonDescriptionOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserCreatedFkeyPatch;
  performanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyCommonDescriptionOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserCreatedFkeyUsingPerformanceReviewMasterCompetencyCommonDescriptionPkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyCommonDescriptionOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserCreatedFkeyPatch;
  performanceReviewMasterCompetencyCommonDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyCommonDescriptionOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewMasterCompetencyCommonDescriptionOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyUsingPerformanceReviewMasterComPerformanceReviewMasterComKeyUpdate {
  language: Scalars['String']['input'];
  patch: UpdatePerformanceReviewMasterCompetencyCommonDescriptionOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyPatch;
  performanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyCommonDescriptionOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyUsingPerformanceReviewMasterCompetencyCommonDescriptionPkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyCommonDescriptionOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyPatch;
  performanceReviewMasterCompetencyCommonDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyCommonDescriptionPatch {
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterComPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyCommonDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterCompetencyCommonDescriptionPerformanceReviewMasterComPerformanceReviewMasterComKeyConnect {
  language: Scalars['String']['input'];
  performanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyCommonDescriptionPerformanceReviewMasterComPerformanceReviewMasterComKeyDelete {
  language: Scalars['String']['input'];
  performanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyCommonDescriptionPerformanceReviewMasterCompetencyCommonDescriptionPkeyConnect {
  performanceReviewMasterCompetencyCommonDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyCommonDescriptionPerformanceReviewMasterCompetencyCommonDescriptionPkeyDelete {
  performanceReviewMasterCompetencyCommonDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyCommonDescriptionsConnection {
  __typename?: 'PerformanceReviewMasterCompetencyCommonDescriptionsConnection';
  edges: Array<PerformanceReviewMasterCompetencyCommonDescriptionsEdge>;
  nodes: Array<PerformanceReviewMasterCompetencyCommonDescription>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterCompetencyCommonDescriptionsEdge {
  __typename?: 'PerformanceReviewMasterCompetencyCommonDescriptionsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewMasterCompetencyCommonDescription;
}

export enum PerformanceReviewMasterCompetencyCommonDescriptionsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DESCRIPTION_ASC = 'DESCRIPTION_ASC',
  DESCRIPTION_DESC = 'DESCRIPTION_DESC',
  LANGUAGE_ASC = 'LANGUAGE_ASC',
  LANGUAGE_DESC = 'LANGUAGE_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__OVERRIDABLE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__OVERRIDABLE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__OVERRIDABLE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__OVERRIDABLE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__WEIGHT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__WEIGHT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__WEIGHT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__WEIGHT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface PerformanceReviewMasterCompetencyCommonFilter {
  and?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  not?: InputMaybe<PerformanceReviewMasterCompetencyCommonFilter>;
  or?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonFilter>>;
  overridable?: InputMaybe<BooleanFilter>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCompetencyCommonToManyPerformanceReviewMasterCompetencyFilter>;
  performanceReviewMasterCompetenciesExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencyCommonDescriptions?: InputMaybe<PerformanceReviewMasterCompetencyCommonToManyPerformanceReviewMasterCompetencyCommonDescriptionFilter>;
  performanceReviewMasterCompetencyCommonDescriptionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<BigIntFilter>;
  performanceReviewMasterCompetencyDetailsByOverridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<PerformanceReviewMasterCompetencyCommonToManyPerformanceReviewMasterCompetencyDetailFilter>;
  performanceReviewMasterCompetencyDetailsByOverridingPerformanceReviewMasterCompetencyCommonIdExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationResultScoreCompetenciesByPerformanceReviewMasterCompetencyId?: InputMaybe<PerformanceReviewMasterCompetencyCommonToManyProbationReviewEvaluationResultScoreCompetencyFilter>;
  probationReviewEvaluationResultScoreCompetenciesByPerformanceReviewMasterCompetencyIdExist?: InputMaybe<Scalars['Boolean']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarterFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
  weight?: InputMaybe<IntFilter>;
  year?: InputMaybe<IntFilter>;
}

export interface PerformanceReviewMasterCompetencyCommonInput {
  displayOrder: Scalars['Int']['input'];
  overridable?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4InverseInput>;
  performanceReviewMasterCompetencyCommonDescriptions?: InputMaybe<PerformanceReviewMasterComPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterComOverridingPerformanceRevieFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInverseInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterCompetencyCommonNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterCompetencyCommonNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterComPerformanceReviewMasterCoFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyCommonDescriptionPatch;
}

export interface PerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterComPerformanceReviewMasterCoFkeyUsingPerformanceReviewMasterCompetencyCommonPkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterComPerformanceReviewMasterCoFkeyPatch;
  performanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyCommonForPerformanceReviewMasterCompetencyCommonUserCreatedFkeyUsingPerformanceReviewMasterCompetencyCommonPkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyCommonForPerformanceReviewMasterCompetencyCommonUserCreatedFkeyPatch;
  performanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyCommonForPerformanceReviewMasterCompetencyCommonUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyCommonForPerformanceReviewMasterCompetencyCommonUserUpdatedFkeyUsingPerformanceReviewMasterCompetencyCommonPkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyCommonForPerformanceReviewMasterCompetencyCommonUserUpdatedFkeyPatch;
  performanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterComOverridingPerformanceRevieFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyDetailPatch;
}

export interface PerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterComOverridingPerformanceRevieFkeyUsingPerformanceReviewMasterCompetencyCommonPkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterComOverridingPerformanceRevieFkeyPatch;
  performanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey4NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyPatch;
}

export interface PerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey4UsingPerformanceReviewMasterCompetencyCommonPkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey4Patch;
  performanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyCommonOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationRPerformanceReviewMasterCoFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationResultScoreCompetencyPatch;
}

export interface PerformanceReviewMasterCompetencyCommonOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationRPerformanceReviewMasterCoFkeyUsingPerformanceReviewMasterCompetencyCommonPkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyCommonOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationRPerformanceReviewMasterCoFkeyPatch;
  performanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyCommonPatch {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  overridable?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4InverseInput>;
  performanceReviewMasterCompetencyCommonDescriptions?: InputMaybe<PerformanceReviewMasterComPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterComOverridingPerformanceRevieFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewMasterCompetencyCommonPerformanceReviewMasterCompetencyCommonPkeyConnect {
  performanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyCommonPerformanceReviewMasterCompetencyCommonPkeyDelete {
  performanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyCommonToManyPerformanceReviewMasterCompetencyCommonDescriptionFilter {
  every?: InputMaybe<PerformanceReviewMasterCompetencyCommonDescriptionFilter>;
  none?: InputMaybe<PerformanceReviewMasterCompetencyCommonDescriptionFilter>;
  some?: InputMaybe<PerformanceReviewMasterCompetencyCommonDescriptionFilter>;
}

export interface PerformanceReviewMasterCompetencyCommonToManyPerformanceReviewMasterCompetencyDetailFilter {
  every?: InputMaybe<PerformanceReviewMasterCompetencyDetailFilter>;
  none?: InputMaybe<PerformanceReviewMasterCompetencyDetailFilter>;
  some?: InputMaybe<PerformanceReviewMasterCompetencyDetailFilter>;
}

export interface PerformanceReviewMasterCompetencyCommonToManyPerformanceReviewMasterCompetencyFilter {
  every?: InputMaybe<PerformanceReviewMasterCompetencyFilter>;
  none?: InputMaybe<PerformanceReviewMasterCompetencyFilter>;
  some?: InputMaybe<PerformanceReviewMasterCompetencyFilter>;
}

export interface PerformanceReviewMasterCompetencyCommonToManyProbationReviewEvaluationResultScoreCompetencyFilter {
  every?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyFilter>;
  none?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyFilter>;
  some?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyFilter>;
}

export interface PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonNodeIdConnect>>;
  connectByPerformanceReviewMasterCompetencyCommonId?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonPerformanceReviewMasterCompetencyCommonPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonNodeIdDelete>>;
  deleteByPerformanceReviewMasterCompetencyCommonId?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonPerformanceReviewMasterCompetencyCommonPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterCompetencyCommonForPerformanceReviewMasterCompetencyCommonUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterCompetencyCommonId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyCommonForPerformanceReviewMasterCompetencyCommonUserCreatedFkeyUsingPerformanceReviewMasterCompetencyCommonPkeyUpdate>
  >;
}

export interface PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewMasterCompetencyCommonForPerformanceReviewMasterCompetencyCommonUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyCommonForPerformanceReviewMasterCompetencyCommonUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewMasterCompetencyCommonForPerformanceReviewMasterCompetencyCommonUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonNodeIdConnect>>;
  connectByPerformanceReviewMasterCompetencyCommonId?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonPerformanceReviewMasterCompetencyCommonPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonNodeIdDelete>>;
  deleteByPerformanceReviewMasterCompetencyCommonId?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonPerformanceReviewMasterCompetencyCommonPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterCompetencyCommonForPerformanceReviewMasterCompetencyCommonUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterCompetencyCommonId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyCommonForPerformanceReviewMasterCompetencyCommonUserUpdatedFkeyUsingPerformanceReviewMasterCompetencyCommonPkeyUpdate>
  >;
}

export interface PerformanceReviewMasterCompetencyCommonsConnection {
  __typename?: 'PerformanceReviewMasterCompetencyCommonsConnection';
  edges: Array<PerformanceReviewMasterCompetencyCommonsEdge>;
  nodes: Array<PerformanceReviewMasterCompetencyCommon>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterCompetencyCommonsEdge {
  __typename?: 'PerformanceReviewMasterCompetencyCommonsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewMasterCompetencyCommon;
}

export enum PerformanceReviewMasterCompetencyCommonsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DISPLAY_ORDER_ASC = 'DISPLAY_ORDER_ASC',
  DISPLAY_ORDER_DESC = 'DISPLAY_ORDER_DESC',
  NATURAL = 'NATURAL',
  OVERRIDABLE_ASC = 'OVERRIDABLE_ASC',
  OVERRIDABLE_DESC = 'OVERRIDABLE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_TITLE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_TITLE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_TITLE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_TITLE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_TITLE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_TITLE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_TITLE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_TITLE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_WEIGHT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_WEIGHT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_WEIGHT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MAX_WEIGHT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_WEIGHT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_WEIGHT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_WEIGHT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__MIN_WEIGHT_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__COUNT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__COUNT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__COUNT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__COUNT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_ASSESSMENT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_ASSESSMENT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_ASSESSMENT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_ASSESSMENT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_ASSESSMENT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_ASSESSMENT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_ASSESSMENT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_ASSESSMENT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__MIN_USER_UPDATED_DESC',
  QUARTER_ASC = 'QUARTER_ASC',
  QUARTER_DESC = 'QUARTER_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
  WEIGHT_ASC = 'WEIGHT_ASC',
  WEIGHT_DESC = 'WEIGHT_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
}

export interface PerformanceReviewMasterCompetencyCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterCompetencyCount extends Node {
  __typename?: 'PerformanceReviewMasterCompetencyCount';
  count?: Maybe<Scalars['BigFloat']['output']>;
  language: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterCompetencyCountByRoleId {
  __typename?: 'PerformanceReviewMasterCompetencyCountByRoleId';
  count?: Maybe<Scalars['BigFloat']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterCompetencyRoleId?: Maybe<Scalars['BigInt']['output']>;
}

export interface PerformanceReviewMasterCompetencyCountByRoleIdCondition {
  count?: InputMaybe<Scalars['BigFloat']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterCompetencyCountByRoleIdFilter {
  and?: InputMaybe<Array<PerformanceReviewMasterCompetencyCountByRoleIdFilter>>;
  count?: InputMaybe<BigFloatFilter>;
  language?: InputMaybe<StringFilter>;
  not?: InputMaybe<PerformanceReviewMasterCompetencyCountByRoleIdFilter>;
  or?: InputMaybe<Array<PerformanceReviewMasterCompetencyCountByRoleIdFilter>>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<BigIntFilter>;
}

export interface PerformanceReviewMasterCompetencyCountByRoleIdsConnection {
  __typename?: 'PerformanceReviewMasterCompetencyCountByRoleIdsConnection';
  edges: Array<PerformanceReviewMasterCompetencyCountByRoleIdsEdge>;
  nodes: Array<PerformanceReviewMasterCompetencyCountByRoleId>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterCompetencyCountByRoleIdsEdge {
  __typename?: 'PerformanceReviewMasterCompetencyCountByRoleIdsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewMasterCompetencyCountByRoleId;
}

export enum PerformanceReviewMasterCompetencyCountByRoleIdsOrderBy {
  COUNT_ASC = 'COUNT_ASC',
  COUNT_DESC = 'COUNT_DESC',
  LANGUAGE_ASC = 'LANGUAGE_ASC',
  LANGUAGE_DESC = 'LANGUAGE_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
}

export interface PerformanceReviewMasterCompetencyCountCondition {
  count?: InputMaybe<Scalars['BigFloat']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewMasterCompetencyCountFilter {
  and?: InputMaybe<Array<PerformanceReviewMasterCompetencyCountFilter>>;
  count?: InputMaybe<BigFloatFilter>;
  language?: InputMaybe<StringFilter>;
  not?: InputMaybe<PerformanceReviewMasterCompetencyCountFilter>;
  or?: InputMaybe<Array<PerformanceReviewMasterCompetencyCountFilter>>;
  quarter?: InputMaybe<PerformanceReviewQuarterFilter>;
  year?: InputMaybe<IntFilter>;
}

export interface PerformanceReviewMasterCompetencyCountsConnection {
  __typename?: 'PerformanceReviewMasterCompetencyCountsConnection';
  edges: Array<PerformanceReviewMasterCompetencyCountsEdge>;
  nodes: Array<PerformanceReviewMasterCompetencyCount>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterCompetencyCountsEdge {
  __typename?: 'PerformanceReviewMasterCompetencyCountsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewMasterCompetencyCount;
}

export enum PerformanceReviewMasterCompetencyCountsOrderBy {
  COUNT_ASC = 'COUNT_ASC',
  COUNT_DESC = 'COUNT_DESC',
  LANGUAGE_ASC = 'LANGUAGE_ASC',
  LANGUAGE_DESC = 'LANGUAGE_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  QUARTER_ASC = 'QUARTER_ASC',
  QUARTER_DESC = 'QUARTER_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
}

export interface PerformanceReviewMasterCompetencyDetail extends Node {
  __typename?: 'PerformanceReviewMasterCompetencyDetail';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  displayOrder?: Maybe<Scalars['Int']['output']>;
  nodeId: Scalars['ID']['output'];
  overridingPerformanceReviewMasterCompetencyCommon?: Maybe<PerformanceReviewMasterCompetencyCommon>;
  overridingPerformanceReviewMasterCompetencyCommonId?: Maybe<Scalars['BigInt']['output']>;
  performanceReviewMasterCompetencies: PerformanceReviewMasterCompetenciesConnection;
  performanceReviewMasterCompetencyDetailDescriptions: PerformanceReviewMasterCompetencyDetailDescriptionsConnection;
  performanceReviewMasterCompetencyDetailId: Scalars['BigInt']['output'];
  performanceReviewMasterCompetencyRole: PerformanceReviewMasterCompetencyRole;
  performanceReviewMasterCompetencyRoleId: Scalars['BigInt']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
  weight: Scalars['Int']['output'];
}

export type PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterCompetenciesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetenciesOrderBy>>;
};

export type PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterCompetencyDetailDescriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyDetailDescriptionCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyDetailDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionsOrderBy>>;
};

export interface PerformanceReviewMasterCompetencyDetailCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  overridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionNodeIdConnect>>;
  connectByPerformanceReviewMasterCompetencyDetailDescriptionId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionPerformanceReviewMasterCompetencyDetailDescriptionPkeyConnect>>;
  connectByPerformanceReviewMasterCompetencyDetailIdAndLanguage?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionPerformanceReviewMasterComPerformanceReviewMasterCoKey2Connect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionNodeIdDelete>>;
  deleteByPerformanceReviewMasterCompetencyDetailDescriptionId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionPerformanceReviewMasterCompetencyDetailDescriptionPkeyDelete>>;
  deleteByPerformanceReviewMasterCompetencyDetailIdAndLanguage?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionPerformanceReviewMasterComPerformanceReviewMasterCoKey2Delete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterCompetencyDetailDescriptionId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyDetailDescriptionOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserCreatedFkeyUsingPerformanceReviewMasterCompetencyDetailDescriptionPkeyUpdate>
  >;
  updateByPerformanceReviewMasterCompetencyDetailIdAndLanguage?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyDetailDescriptionOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserCreatedFkeyUsingPerformanceReviewMasterComPerformanceReviewMasterCoKey2Update>
  >;
}

export interface PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDescriptionOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionNodeIdConnect>>;
  connectByPerformanceReviewMasterCompetencyDetailDescriptionId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionPerformanceReviewMasterCompetencyDetailDescriptionPkeyConnect>>;
  connectByPerformanceReviewMasterCompetencyDetailIdAndLanguage?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionPerformanceReviewMasterComPerformanceReviewMasterCoKey2Connect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionNodeIdDelete>>;
  deleteByPerformanceReviewMasterCompetencyDetailDescriptionId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionPerformanceReviewMasterCompetencyDetailDescriptionPkeyDelete>>;
  deleteByPerformanceReviewMasterCompetencyDetailIdAndLanguage?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionPerformanceReviewMasterComPerformanceReviewMasterCoKey2Delete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterCompetencyDetailDescriptionId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyDetailDescriptionOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyUsingPerformanceReviewMasterCompetencyDetailDescriptionPkeyUpdate>
  >;
  updateByPerformanceReviewMasterCompetencyDetailIdAndLanguage?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyDetailDescriptionOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyUsingPerformanceReviewMasterComPerformanceReviewMasterCoKey2Update>
  >;
}

export interface PerformanceReviewMasterCompetencyDetailDescription extends Node {
  __typename?: 'PerformanceReviewMasterCompetencyDetailDescription';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  description: Scalars['String']['output'];
  language: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  performanceReviewMasterCompetencyDetail: PerformanceReviewMasterCompetencyDetail;
  performanceReviewMasterCompetencyDetailDescriptionId: Scalars['BigInt']['output'];
  performanceReviewMasterCompetencyDetailId: Scalars['BigInt']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface PerformanceReviewMasterCompetencyDetailDescriptionCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyDetailDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterCompetencyDetailDescriptionFilter {
  and?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  description?: InputMaybe<StringFilter>;
  language?: InputMaybe<StringFilter>;
  not?: InputMaybe<PerformanceReviewMasterCompetencyDetailDescriptionFilter>;
  or?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionFilter>>;
  performanceReviewMasterCompetencyDetail?: InputMaybe<PerformanceReviewMasterCompetencyDetailFilter>;
  performanceReviewMasterCompetencyDetailDescriptionId?: InputMaybe<BigIntFilter>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface PerformanceReviewMasterCompetencyDetailDescriptionInput {
  description: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyDetail?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey2Input>;
  performanceReviewMasterCompetencyDetailDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterCompetencyDetailDescriptionNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailDescriptionNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailDescriptionOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey2NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyDetailPatch;
}

export interface PerformanceReviewMasterCompetencyDetailDescriptionOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey2UsingPerformanceReviewMasterComPerformanceReviewMasterCoKey2Update {
  language: Scalars['String']['input'];
  patch: UpdatePerformanceReviewMasterCompetencyDetailDescriptionOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey2Patch;
  performanceReviewMasterCompetencyDetailId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailDescriptionOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey2UsingPerformanceReviewMasterCompetencyDetailDescriptionPkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyDetailDescriptionOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey2Patch;
  performanceReviewMasterCompetencyDetailDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailDescriptionOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserCreatedFkeyUsingPerformanceReviewMasterComPerformanceReviewMasterCoKey2Update {
  language: Scalars['String']['input'];
  patch: UpdatePerformanceReviewMasterCompetencyDetailDescriptionOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserCreatedFkeyPatch;
  performanceReviewMasterCompetencyDetailId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailDescriptionOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserCreatedFkeyUsingPerformanceReviewMasterCompetencyDetailDescriptionPkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyDetailDescriptionOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserCreatedFkeyPatch;
  performanceReviewMasterCompetencyDetailDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailDescriptionOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewMasterCompetencyDetailDescriptionOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyUsingPerformanceReviewMasterComPerformanceReviewMasterCoKey2Update {
  language: Scalars['String']['input'];
  patch: UpdatePerformanceReviewMasterCompetencyDetailDescriptionOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyPatch;
  performanceReviewMasterCompetencyDetailId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailDescriptionOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyUsingPerformanceReviewMasterCompetencyDetailDescriptionPkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyDetailDescriptionOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyPatch;
  performanceReviewMasterCompetencyDetailDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailDescriptionPatch {
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyDetail?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey2Input>;
  performanceReviewMasterCompetencyDetailDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterCompetencyDetailDescriptionPerformanceReviewMasterComPerformanceReviewMasterCoKey2Connect {
  language: Scalars['String']['input'];
  performanceReviewMasterCompetencyDetailId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailDescriptionPerformanceReviewMasterComPerformanceReviewMasterCoKey2Delete {
  language: Scalars['String']['input'];
  performanceReviewMasterCompetencyDetailId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailDescriptionPerformanceReviewMasterCompetencyDetailDescriptionPkeyConnect {
  performanceReviewMasterCompetencyDetailDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailDescriptionPerformanceReviewMasterCompetencyDetailDescriptionPkeyDelete {
  performanceReviewMasterCompetencyDetailDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailDescriptionsConnection {
  __typename?: 'PerformanceReviewMasterCompetencyDetailDescriptionsConnection';
  edges: Array<PerformanceReviewMasterCompetencyDetailDescriptionsEdge>;
  nodes: Array<PerformanceReviewMasterCompetencyDetailDescription>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterCompetencyDetailDescriptionsEdge {
  __typename?: 'PerformanceReviewMasterCompetencyDetailDescriptionsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewMasterCompetencyDetailDescription;
}

export enum PerformanceReviewMasterCompetencyDetailDescriptionsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DESCRIPTION_ASC = 'DESCRIPTION_ASC',
  DESCRIPTION_DESC = 'DESCRIPTION_DESC',
  LANGUAGE_ASC = 'LANGUAGE_ASC',
  LANGUAGE_DESC = 'LANGUAGE_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__WEIGHT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__WEIGHT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__WEIGHT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__WEIGHT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface PerformanceReviewMasterCompetencyDetailFilter {
  and?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  not?: InputMaybe<PerformanceReviewMasterCompetencyDetailFilter>;
  or?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailFilter>>;
  overridingPerformanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterCompetencyCommonFilter>;
  overridingPerformanceReviewMasterCompetencyCommonExists?: InputMaybe<Scalars['Boolean']['input']>;
  overridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<BigIntFilter>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCompetencyDetailToManyPerformanceReviewMasterCompetencyFilter>;
  performanceReviewMasterCompetenciesExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencyDetailDescriptions?: InputMaybe<PerformanceReviewMasterCompetencyDetailToManyPerformanceReviewMasterCompetencyDetailDescriptionFilter>;
  performanceReviewMasterCompetencyDetailDescriptionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<BigIntFilter>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewMasterCompetencyRoleFilter>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
  weight?: InputMaybe<IntFilter>;
}

export interface PerformanceReviewMasterCompetencyDetailFixDisplayOrderInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  targetPerformanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterCompetencyDetailFixDisplayOrderPayload {
  __typename?: 'PerformanceReviewMasterCompetencyDetailFixDisplayOrderPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
}

export interface PerformanceReviewMasterCompetencyDetailInput {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  overridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3InverseInput>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterComOverridingPerformanceRevieFkeyInput>;
  performanceReviewMasterCompetencyDetailDescriptions?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey2InverseInput>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1Input>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey2NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyDetailDescriptionPatch;
}

export interface PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey2UsingPerformanceReviewMasterComPerformanceReviewMasterCoKey1Update {
  overridingPerformanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey2Patch;
  performanceReviewMasterCompetencyRoleId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey2UsingPerformanceReviewMasterCompetencyDetailPkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey2Patch;
  performanceReviewMasterCompetencyDetailId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey1NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyRolePatch;
}

export interface PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey1UsingPerformanceReviewMasterComPerformanceReviewMasterCoKey1Update {
  overridingPerformanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey1Patch;
  performanceReviewMasterCompetencyRoleId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey1UsingPerformanceReviewMasterCompetencyDetailPkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey1Patch;
  performanceReviewMasterCompetencyDetailId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterComOverridingPerformanceRevieFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyCommonPatch;
}

export interface PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterComOverridingPerformanceRevieFkeyUsingPerformanceReviewMasterComPerformanceReviewMasterCoKey1Update {
  overridingPerformanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterComOverridingPerformanceRevieFkeyPatch;
  performanceReviewMasterCompetencyRoleId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterComOverridingPerformanceRevieFkeyUsingPerformanceReviewMasterCompetencyDetailPkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterComOverridingPerformanceRevieFkeyPatch;
  performanceReviewMasterCompetencyDetailId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserCreatedFkeyUsingPerformanceReviewMasterComPerformanceReviewMasterCoKey1Update {
  overridingPerformanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserCreatedFkeyPatch;
  performanceReviewMasterCompetencyRoleId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserCreatedFkeyUsingPerformanceReviewMasterCompetencyDetailPkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserCreatedFkeyPatch;
  performanceReviewMasterCompetencyDetailId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserUpdatedFkeyUsingPerformanceReviewMasterComPerformanceReviewMasterCoKey1Update {
  overridingPerformanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserUpdatedFkeyPatch;
  performanceReviewMasterCompetencyRoleId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserUpdatedFkeyUsingPerformanceReviewMasterCompetencyDetailPkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserUpdatedFkeyPatch;
  performanceReviewMasterCompetencyDetailId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey3NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyPatch;
}

export interface PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey3UsingPerformanceReviewMasterComPerformanceReviewMasterCoKey1Update {
  overridingPerformanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey3Patch;
  performanceReviewMasterCompetencyRoleId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey3UsingPerformanceReviewMasterCompetencyDetailPkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey3Patch;
  performanceReviewMasterCompetencyDetailId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailPatch {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  overridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3InverseInput>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterComOverridingPerformanceRevieFkeyInput>;
  performanceReviewMasterCompetencyDetailDescriptions?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey2InverseInput>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1Input>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterComPerformanceReviewMasterCoKey1Connect {
  overridingPerformanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
  performanceReviewMasterCompetencyRoleId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterComPerformanceReviewMasterCoKey1Delete {
  overridingPerformanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
  performanceReviewMasterCompetencyRoleId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterCompetencyDetailPkeyConnect {
  performanceReviewMasterCompetencyDetailId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterCompetencyDetailPkeyDelete {
  performanceReviewMasterCompetencyDetailId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyDetailToManyPerformanceReviewMasterCompetencyDetailDescriptionFilter {
  every?: InputMaybe<PerformanceReviewMasterCompetencyDetailDescriptionFilter>;
  none?: InputMaybe<PerformanceReviewMasterCompetencyDetailDescriptionFilter>;
  some?: InputMaybe<PerformanceReviewMasterCompetencyDetailDescriptionFilter>;
}

export interface PerformanceReviewMasterCompetencyDetailToManyPerformanceReviewMasterCompetencyFilter {
  every?: InputMaybe<PerformanceReviewMasterCompetencyFilter>;
  none?: InputMaybe<PerformanceReviewMasterCompetencyFilter>;
  some?: InputMaybe<PerformanceReviewMasterCompetencyFilter>;
}

export interface PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailNodeIdConnect>>;
  connectByPerformanceReviewMasterCompetencyDetailId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterCompetencyDetailPkeyConnect>>;
  connectByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterComPerformanceReviewMasterCoKey1Connect>
  >;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailNodeIdDelete>>;
  deleteByPerformanceReviewMasterCompetencyDetailId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterCompetencyDetailPkeyDelete>>;
  deleteByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterComPerformanceReviewMasterCoKey1Delete>
  >;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterCompetencyDetailId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserCreatedFkeyUsingPerformanceReviewMasterCompetencyDetailPkeyUpdate>
  >;
  updateByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserCreatedFkeyUsingPerformanceReviewMasterComPerformanceReviewMasterCoKey1Update>
  >;
}

export interface PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailNodeIdConnect>>;
  connectByPerformanceReviewMasterCompetencyDetailId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterCompetencyDetailPkeyConnect>>;
  connectByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterComPerformanceReviewMasterCoKey1Connect>
  >;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailNodeIdDelete>>;
  deleteByPerformanceReviewMasterCompetencyDetailId?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterCompetencyDetailPkeyDelete>>;
  deleteByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyDetailPerformanceReviewMasterComPerformanceReviewMasterCoKey1Delete>
  >;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterCompetencyDetailId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserUpdatedFkeyUsingPerformanceReviewMasterCompetencyDetailPkeyUpdate>
  >;
  updateByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserUpdatedFkeyUsingPerformanceReviewMasterComPerformanceReviewMasterCoKey1Update>
  >;
}

export interface PerformanceReviewMasterCompetencyDetailsConnection {
  __typename?: 'PerformanceReviewMasterCompetencyDetailsConnection';
  edges: Array<PerformanceReviewMasterCompetencyDetailsEdge>;
  nodes: Array<PerformanceReviewMasterCompetencyDetail>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterCompetencyDetailsEdge {
  __typename?: 'PerformanceReviewMasterCompetencyDetailsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewMasterCompetencyDetail;
}

export enum PerformanceReviewMasterCompetencyDetailsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DISPLAY_ORDER_ASC = 'DISPLAY_ORDER_ASC',
  DISPLAY_ORDER_DESC = 'DISPLAY_ORDER_DESC',
  NATURAL = 'NATURAL',
  OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__OVERRIDABLE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__OVERRIDABLE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__OVERRIDABLE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__OVERRIDABLE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__WEIGHT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__WEIGHT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__WEIGHT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__WEIGHT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_TITLE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_TITLE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_TITLE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_TITLE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_TITLE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_TITLE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_TITLE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_TITLE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__BUSINESS_UNIT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__BUSINESS_UNIT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__BUSINESS_UNIT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__BUSINESS_UNIT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DEPARTMENT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DEPARTMENT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DEPARTMENT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DEPARTMENT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DIVISION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DIVISION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DIVISION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__DIVISION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__ROLE_NAME_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__ROLE_NAME_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__ROLE_NAME_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__ROLE_NAME_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
  WEIGHT_ASC = 'WEIGHT_ASC',
  WEIGHT_DESC = 'WEIGHT_DESC',
}

export interface PerformanceReviewMasterCompetencyFilter {
  and?: InputMaybe<Array<PerformanceReviewMasterCompetencyFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  not?: InputMaybe<PerformanceReviewMasterCompetencyFilter>;
  or?: InputMaybe<Array<PerformanceReviewMasterCompetencyFilter>>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewMasterCompetencyToManyPerformanceReviewEvaluationCompetencyFilter>;
  performanceReviewEvaluationCompetenciesExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterCompetencyCommonFilter>;
  performanceReviewMasterCompetencyCommonExists?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<BigIntFilter>;
  performanceReviewMasterCompetencyDetail?: InputMaybe<PerformanceReviewMasterCompetencyDetailFilter>;
  performanceReviewMasterCompetencyDetailExists?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<BigIntFilter>;
  performanceReviewMasterCompetencyId?: InputMaybe<StringFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface PerformanceReviewMasterCompetencyInput {
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10InverseInput>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4Input>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyDetail?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3Input>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyId: Scalars['String']['input'];
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterCompetencyNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterCompetencyNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationCompetencyPatch;
}

export interface PerformanceReviewMasterCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10UsingPerformanceReviewMasterCompetencyPkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10Patch;
  performanceReviewMasterCompetencyId: Scalars['String']['input'];
}

export interface PerformanceReviewMasterCompetencyOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey3NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyDetailPatch;
}

export interface PerformanceReviewMasterCompetencyOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey3UsingPerformanceReviewMasterCompetencyPkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey3Patch;
  performanceReviewMasterCompetencyId: Scalars['String']['input'];
}

export interface PerformanceReviewMasterCompetencyOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey4NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyCommonPatch;
}

export interface PerformanceReviewMasterCompetencyOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey4UsingPerformanceReviewMasterCompetencyPkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey4Patch;
  performanceReviewMasterCompetencyId: Scalars['String']['input'];
}

export interface PerformanceReviewMasterCompetencyOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCompetencyUserCreatedFkeyUsingPerformanceReviewMasterCompetencyPkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCompetencyUserCreatedFkeyPatch;
  performanceReviewMasterCompetencyId: Scalars['String']['input'];
}

export interface PerformanceReviewMasterCompetencyOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCompetencyUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewMasterCompetencyOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCompetencyUserUpdatedFkeyUsingPerformanceReviewMasterCompetencyPkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCompetencyUserUpdatedFkeyPatch;
  performanceReviewMasterCompetencyId: Scalars['String']['input'];
}

export interface PerformanceReviewMasterCompetencyPatch {
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10InverseInput>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4Input>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyDetail?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3Input>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterCompetencyPerformanceReviewMasterCompetencyPkeyConnect {
  performanceReviewMasterCompetencyId: Scalars['String']['input'];
}

export interface PerformanceReviewMasterCompetencyPerformanceReviewMasterCompetencyPkeyDelete {
  performanceReviewMasterCompetencyId: Scalars['String']['input'];
}

export interface PerformanceReviewMasterCompetencyRole extends Node {
  __typename?: 'PerformanceReviewMasterCompetencyRole';
  businessUnit: BusinessUnit;
  businessUnitId: Scalars['BigInt']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  department: Department;
  departmentId: Scalars['BigInt']['output'];
  division: Division;
  divisionId: Scalars['BigInt']['output'];
  nodeId: Scalars['ID']['output'];
  performanceReviewEvaluations: PerformanceReviewEvaluationsConnection;
  performanceReviewMasterCompetencyDetails: PerformanceReviewMasterCompetencyDetailsConnection;
  performanceReviewMasterCompetencyRoleId: Scalars['BigInt']['output'];
  quarter: PerformanceReviewQuarter;
  remark?: Maybe<Scalars['String']['output']>;
  roleName: Scalars['String']['output'];
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
  year: Scalars['Int']['output'];
}

export type PerformanceReviewMasterCompetencyRolePerformanceReviewEvaluationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationsOrderBy>>;
};

export type PerformanceReviewMasterCompetencyRolePerformanceReviewMasterCompetencyDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyDetailCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyDetailFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailsOrderBy>>;
};

export interface PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyBusinessUnitCreateInput {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInverseInput>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BusinessUnitUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInput {
  connectByBusinessUnitId?: InputMaybe<BusinessUnitBusinessUnitPkeyConnect>;
  connectByName?: InputMaybe<BusinessUnitBusinessUnitNameKeyConnect>;
  connectByNodeId?: InputMaybe<BusinessUnitNodeIdConnect>;
  create?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyBusinessUnitCreateInput>;
  deleteByBusinessUnitId?: InputMaybe<BusinessUnitBusinessUnitPkeyDelete>;
  deleteByName?: InputMaybe<BusinessUnitBusinessUnitNameKeyDelete>;
  deleteByNodeId?: InputMaybe<BusinessUnitNodeIdDelete>;
  updateByBusinessUnitId?: InputMaybe<BusinessUnitOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyUsingBusinessUnitPkeyUpdate>;
  updateByName?: InputMaybe<BusinessUnitOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyUsingBusinessUnitNameKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyNodeIdUpdate>;
}

export interface PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyRoleNodeIdConnect>>;
  connectByPerformanceReviewMasterCompetencyRoleId?: InputMaybe<Array<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterCompetencyRolePkeyConnect>>;
  connectByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleName?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterComYearQuarterBusinessUnitIdKeyConnect>
  >;
  create?: InputMaybe<Array<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyPerformanceReviewMasterCompetencyRoleCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyRoleNodeIdDelete>>;
  deleteByPerformanceReviewMasterCompetencyRoleId?: InputMaybe<Array<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterCompetencyRolePkeyDelete>>;
  deleteByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleName?: InputMaybe<Array<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterComYearQuarterBusinessUnitIdKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<BusinessUnitOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterCompetencyRoleId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyUsingPerformanceReviewMasterCompetencyRolePkeyUpdate>
  >;
  updateByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleName?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyUsingPerformanceReviewMasterComYearQuarterBusinessUnitIdKeyUpdate>
  >;
}

export interface PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyPerformanceReviewMasterCompetencyRoleCreateInput {
  businessUnit?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInput>;
  department?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1InverseInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  roleName: Scalars['String']['input'];
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterCompetencyRoleCondition {
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  roleName?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyDepartmentCreateInput {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey10InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DepartmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInput {
  connectByDepartmentId?: InputMaybe<DepartmentDepartmentPkeyConnect>;
  connectByName?: InputMaybe<DepartmentDepartmentNameKeyConnect>;
  connectByNodeId?: InputMaybe<DepartmentNodeIdConnect>;
  create?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyDepartmentCreateInput>;
  deleteByDepartmentId?: InputMaybe<DepartmentDepartmentPkeyDelete>;
  deleteByName?: InputMaybe<DepartmentDepartmentNameKeyDelete>;
  deleteByNodeId?: InputMaybe<DepartmentNodeIdDelete>;
  updateByDepartmentId?: InputMaybe<DepartmentOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDepartmentIdFkeyUsingDepartmentPkeyUpdate>;
  updateByName?: InputMaybe<DepartmentOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDepartmentIdFkeyUsingDepartmentNameKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDepartmentIdFkeyNodeIdUpdate>;
}

export interface PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyRoleNodeIdConnect>>;
  connectByPerformanceReviewMasterCompetencyRoleId?: InputMaybe<Array<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterCompetencyRolePkeyConnect>>;
  connectByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleName?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterComYearQuarterBusinessUnitIdKeyConnect>
  >;
  create?: InputMaybe<Array<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyPerformanceReviewMasterCompetencyRoleCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyRoleNodeIdDelete>>;
  deleteByPerformanceReviewMasterCompetencyRoleId?: InputMaybe<Array<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterCompetencyRolePkeyDelete>>;
  deleteByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleName?: InputMaybe<Array<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterComYearQuarterBusinessUnitIdKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<DepartmentOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDepartmentIdFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterCompetencyRoleId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDepartmentIdFkeyUsingPerformanceReviewMasterCompetencyRolePkeyUpdate>
  >;
  updateByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleName?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDepartmentIdFkeyUsingPerformanceReviewMasterComYearQuarterBusinessUnitIdKeyUpdate>
  >;
}

export interface PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyPerformanceReviewMasterCompetencyRoleCreateInput {
  businessUnit?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInput>;
  division?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1InverseInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  roleName: Scalars['String']['input'];
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterCompetencyRoleDivisionIdFkeyDivisionCreateInput {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionDivisionIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DivisionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInput {
  connectByDivisionId?: InputMaybe<DivisionDivisionPkeyConnect>;
  connectByName?: InputMaybe<DivisionDivisionNameKeyConnect>;
  connectByNodeId?: InputMaybe<DivisionNodeIdConnect>;
  create?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyDivisionCreateInput>;
  deleteByDivisionId?: InputMaybe<DivisionDivisionPkeyDelete>;
  deleteByName?: InputMaybe<DivisionDivisionNameKeyDelete>;
  deleteByNodeId?: InputMaybe<DivisionNodeIdDelete>;
  updateByDivisionId?: InputMaybe<DivisionOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDivisionIdFkeyUsingDivisionPkeyUpdate>;
  updateByName?: InputMaybe<DivisionOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDivisionIdFkeyUsingDivisionNameKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDivisionIdFkeyNodeIdUpdate>;
}

export interface PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyRoleNodeIdConnect>>;
  connectByPerformanceReviewMasterCompetencyRoleId?: InputMaybe<Array<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterCompetencyRolePkeyConnect>>;
  connectByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleName?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterComYearQuarterBusinessUnitIdKeyConnect>
  >;
  create?: InputMaybe<Array<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyPerformanceReviewMasterCompetencyRoleCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyRoleNodeIdDelete>>;
  deleteByPerformanceReviewMasterCompetencyRoleId?: InputMaybe<Array<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterCompetencyRolePkeyDelete>>;
  deleteByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleName?: InputMaybe<Array<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterComYearQuarterBusinessUnitIdKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<DivisionOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDivisionIdFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterCompetencyRoleId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDivisionIdFkeyUsingPerformanceReviewMasterCompetencyRolePkeyUpdate>
  >;
  updateByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleName?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDivisionIdFkeyUsingPerformanceReviewMasterComYearQuarterBusinessUnitIdKeyUpdate>
  >;
}

export interface PerformanceReviewMasterCompetencyRoleDivisionIdFkeyPerformanceReviewMasterCompetencyRoleCreateInput {
  businessUnit?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1InverseInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  roleName: Scalars['String']['input'];
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterCompetencyRoleFilter {
  and?: InputMaybe<Array<PerformanceReviewMasterCompetencyRoleFilter>>;
  businessUnit?: InputMaybe<BusinessUnitFilter>;
  businessUnitId?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  department?: InputMaybe<DepartmentFilter>;
  departmentId?: InputMaybe<BigIntFilter>;
  division?: InputMaybe<DivisionFilter>;
  divisionId?: InputMaybe<BigIntFilter>;
  not?: InputMaybe<PerformanceReviewMasterCompetencyRoleFilter>;
  or?: InputMaybe<Array<PerformanceReviewMasterCompetencyRoleFilter>>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewMasterCompetencyRoleToManyPerformanceReviewEvaluationFilter>;
  performanceReviewEvaluationsExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterCompetencyRoleToManyPerformanceReviewMasterCompetencyDetailFilter>;
  performanceReviewMasterCompetencyDetailsExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<BigIntFilter>;
  quarter?: InputMaybe<PerformanceReviewQuarterFilter>;
  remark?: InputMaybe<StringFilter>;
  roleName?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
  year?: InputMaybe<IntFilter>;
}

export interface PerformanceReviewMasterCompetencyRoleInput {
  businessUnit?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1InverseInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  roleName: Scalars['String']['input'];
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterCompetencyRoleNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterCompetencyRoleNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterCompetencyRoleOnPerformanceReviewEvaluationForPerformanceReviewEvaluationPerformanceReviewMasterCoFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationPatch;
}

export interface PerformanceReviewMasterCompetencyRoleOnPerformanceReviewEvaluationForPerformanceReviewEvaluationPerformanceReviewMasterCoFkeyUsingPerformanceReviewMasterComYearQuarterBusinessUnitIdKeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  departmentId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterCompetencyRoleOnPerformanceReviewEvaluationForPerformanceReviewEvaluationPerformanceReviewMasterCoFkeyPatch;
  quarter: PerformanceReviewQuarter;
  roleName: Scalars['String']['input'];
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterCompetencyRoleOnPerformanceReviewEvaluationForPerformanceReviewEvaluationPerformanceReviewMasterCoFkeyUsingPerformanceReviewMasterCompetencyRolePkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyRoleOnPerformanceReviewEvaluationForPerformanceReviewEvaluationPerformanceReviewMasterCoFkeyPatch;
  performanceReviewMasterCompetencyRoleId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey1NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyDetailPatch;
}

export interface PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey1UsingPerformanceReviewMasterComYearQuarterBusinessUnitIdKeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  departmentId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey1Patch;
  quarter: PerformanceReviewQuarter;
  roleName: Scalars['String']['input'];
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey1UsingPerformanceReviewMasterCompetencyRolePkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey1Patch;
  performanceReviewMasterCompetencyRoleId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: BusinessUnitPatch;
}

export interface PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyUsingPerformanceReviewMasterComYearQuarterBusinessUnitIdKeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  departmentId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyPatch;
  quarter: PerformanceReviewQuarter;
  roleName: Scalars['String']['input'];
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyUsingPerformanceReviewMasterCompetencyRolePkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyPatch;
  performanceReviewMasterCompetencyRoleId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDepartmentIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: DepartmentPatch;
}

export interface PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDepartmentIdFkeyUsingPerformanceReviewMasterComYearQuarterBusinessUnitIdKeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  departmentId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDepartmentIdFkeyPatch;
  quarter: PerformanceReviewQuarter;
  roleName: Scalars['String']['input'];
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDepartmentIdFkeyUsingPerformanceReviewMasterCompetencyRolePkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDepartmentIdFkeyPatch;
  performanceReviewMasterCompetencyRoleId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDivisionIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: DivisionPatch;
}

export interface PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDivisionIdFkeyUsingPerformanceReviewMasterComYearQuarterBusinessUnitIdKeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  departmentId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDivisionIdFkeyPatch;
  quarter: PerformanceReviewQuarter;
  roleName: Scalars['String']['input'];
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDivisionIdFkeyUsingPerformanceReviewMasterCompetencyRolePkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDivisionIdFkeyPatch;
  performanceReviewMasterCompetencyRoleId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserCreatedFkeyUsingPerformanceReviewMasterComYearQuarterBusinessUnitIdKeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  departmentId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserCreatedFkeyPatch;
  quarter: PerformanceReviewQuarter;
  roleName: Scalars['String']['input'];
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserCreatedFkeyUsingPerformanceReviewMasterCompetencyRolePkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserCreatedFkeyPatch;
  performanceReviewMasterCompetencyRoleId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserUpdatedFkeyUsingPerformanceReviewMasterComYearQuarterBusinessUnitIdKeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  departmentId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserUpdatedFkeyPatch;
  quarter: PerformanceReviewQuarter;
  roleName: Scalars['String']['input'];
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserUpdatedFkeyUsingPerformanceReviewMasterCompetencyRolePkeyUpdate {
  patch: UpdatePerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserUpdatedFkeyPatch;
  performanceReviewMasterCompetencyRoleId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyRolePatch {
  businessUnit?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1InverseInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  roleName?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewMasterCompetencyRolePerformanceReviewMasterComYearQuarterBusinessUnitIdKeyConnect {
  businessUnitId: Scalars['BigInt']['input'];
  departmentId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
  quarter: PerformanceReviewQuarter;
  roleName: Scalars['String']['input'];
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterCompetencyRolePerformanceReviewMasterComYearQuarterBusinessUnitIdKeyDelete {
  businessUnitId: Scalars['BigInt']['input'];
  departmentId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
  quarter: PerformanceReviewQuarter;
  roleName: Scalars['String']['input'];
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterCompetencyRolePerformanceReviewMasterCompetencyRolePkeyConnect {
  performanceReviewMasterCompetencyRoleId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyRolePerformanceReviewMasterCompetencyRolePkeyDelete {
  performanceReviewMasterCompetencyRoleId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterCompetencyRoleToManyPerformanceReviewEvaluationFilter {
  every?: InputMaybe<PerformanceReviewEvaluationFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationFilter>;
}

export interface PerformanceReviewMasterCompetencyRoleToManyPerformanceReviewMasterCompetencyDetailFilter {
  every?: InputMaybe<PerformanceReviewMasterCompetencyDetailFilter>;
  none?: InputMaybe<PerformanceReviewMasterCompetencyDetailFilter>;
  some?: InputMaybe<PerformanceReviewMasterCompetencyDetailFilter>;
}

export interface PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyRoleNodeIdConnect>>;
  connectByPerformanceReviewMasterCompetencyRoleId?: InputMaybe<Array<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterCompetencyRolePkeyConnect>>;
  connectByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleName?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterComYearQuarterBusinessUnitIdKeyConnect>
  >;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyRoleNodeIdDelete>>;
  deleteByPerformanceReviewMasterCompetencyRoleId?: InputMaybe<Array<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterCompetencyRolePkeyDelete>>;
  deleteByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleName?: InputMaybe<Array<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterComYearQuarterBusinessUnitIdKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterCompetencyRoleId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserCreatedFkeyUsingPerformanceReviewMasterCompetencyRolePkeyUpdate>
  >;
  updateByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleName?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserCreatedFkeyUsingPerformanceReviewMasterComYearQuarterBusinessUnitIdKeyUpdate>
  >;
}

export interface PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyRoleNodeIdConnect>>;
  connectByPerformanceReviewMasterCompetencyRoleId?: InputMaybe<Array<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterCompetencyRolePkeyConnect>>;
  connectByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleName?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterComYearQuarterBusinessUnitIdKeyConnect>
  >;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyRoleNodeIdDelete>>;
  deleteByPerformanceReviewMasterCompetencyRoleId?: InputMaybe<Array<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterCompetencyRolePkeyDelete>>;
  deleteByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleName?: InputMaybe<Array<PerformanceReviewMasterCompetencyRolePerformanceReviewMasterComYearQuarterBusinessUnitIdKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterCompetencyRoleId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserUpdatedFkeyUsingPerformanceReviewMasterCompetencyRolePkeyUpdate>
  >;
  updateByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleName?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserUpdatedFkeyUsingPerformanceReviewMasterComYearQuarterBusinessUnitIdKeyUpdate>
  >;
}

export interface PerformanceReviewMasterCompetencyRolesConnection {
  __typename?: 'PerformanceReviewMasterCompetencyRolesConnection';
  edges: Array<PerformanceReviewMasterCompetencyRolesEdge>;
  nodes: Array<PerformanceReviewMasterCompetencyRole>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterCompetencyRolesEdge {
  __typename?: 'PerformanceReviewMasterCompetencyRolesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewMasterCompetencyRole;
}

export enum PerformanceReviewMasterCompetencyRolesOrderBy {
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_DESC',
  BUSINESS_UNIT_ID_ASC = 'BUSINESS_UNIT_ID_ASC',
  BUSINESS_UNIT_ID_DESC = 'BUSINESS_UNIT_ID_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__DATE_CREATED_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__DATE_CREATED_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__DATE_CREATED_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__DATE_CREATED_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__DATE_UPDATED_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__DATE_UPDATED_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__DATE_UPDATED_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__DATE_UPDATED_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_ID_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_ID_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_ID_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_ID_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__IS_ACTIVE_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__IS_ACTIVE_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__IS_ACTIVE_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__IS_ACTIVE_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__REMARK_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__REMARK_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__REMARK_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__REMARK_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__USER_CREATED_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__USER_CREATED_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__USER_CREATED_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__USER_CREATED_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__USER_UPDATED_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__USER_UPDATED_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__USER_UPDATED_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__USER_UPDATED_DESC',
  DEPARTMENT_ID_ASC = 'DEPARTMENT_ID_ASC',
  DEPARTMENT_ID_DESC = 'DEPARTMENT_ID_DESC',
  DIVISION_BY_DIVISION_ID__DATE_CREATED_ASC = 'DIVISION_BY_DIVISION_ID__DATE_CREATED_ASC',
  DIVISION_BY_DIVISION_ID__DATE_CREATED_DESC = 'DIVISION_BY_DIVISION_ID__DATE_CREATED_DESC',
  DIVISION_BY_DIVISION_ID__DATE_UPDATED_ASC = 'DIVISION_BY_DIVISION_ID__DATE_UPDATED_ASC',
  DIVISION_BY_DIVISION_ID__DATE_UPDATED_DESC = 'DIVISION_BY_DIVISION_ID__DATE_UPDATED_DESC',
  DIVISION_BY_DIVISION_ID__DIVISION_ID_ASC = 'DIVISION_BY_DIVISION_ID__DIVISION_ID_ASC',
  DIVISION_BY_DIVISION_ID__DIVISION_ID_DESC = 'DIVISION_BY_DIVISION_ID__DIVISION_ID_DESC',
  DIVISION_BY_DIVISION_ID__IS_ACTIVE_ASC = 'DIVISION_BY_DIVISION_ID__IS_ACTIVE_ASC',
  DIVISION_BY_DIVISION_ID__IS_ACTIVE_DESC = 'DIVISION_BY_DIVISION_ID__IS_ACTIVE_DESC',
  DIVISION_BY_DIVISION_ID__NAME_ASC = 'DIVISION_BY_DIVISION_ID__NAME_ASC',
  DIVISION_BY_DIVISION_ID__NAME_DESC = 'DIVISION_BY_DIVISION_ID__NAME_DESC',
  DIVISION_BY_DIVISION_ID__REMARK_ASC = 'DIVISION_BY_DIVISION_ID__REMARK_ASC',
  DIVISION_BY_DIVISION_ID__REMARK_DESC = 'DIVISION_BY_DIVISION_ID__REMARK_DESC',
  DIVISION_BY_DIVISION_ID__USER_CREATED_ASC = 'DIVISION_BY_DIVISION_ID__USER_CREATED_ASC',
  DIVISION_BY_DIVISION_ID__USER_CREATED_DESC = 'DIVISION_BY_DIVISION_ID__USER_CREATED_DESC',
  DIVISION_BY_DIVISION_ID__USER_UPDATED_ASC = 'DIVISION_BY_DIVISION_ID__USER_UPDATED_ASC',
  DIVISION_BY_DIVISION_ID__USER_UPDATED_DESC = 'DIVISION_BY_DIVISION_ID__USER_UPDATED_DESC',
  DIVISION_ID_ASC = 'DIVISION_ID_ASC',
  DIVISION_ID_DESC = 'DIVISION_ID_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_IS_LOCKED_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_IS_LOCKED_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_IS_LOCKED_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_IS_LOCKED_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_IS_LOCKED_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_IS_LOCKED_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_IS_LOCKED_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_IS_LOCKED_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_MID_LONG_TERM_GOAL_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_MID_LONG_TERM_GOAL_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_MID_LONG_TERM_GOAL_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_MID_LONG_TERM_GOAL_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_NEED_INPUT_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_NEED_INPUT_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_NEED_INPUT_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_NEED_INPUT_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_NEED_INPUT_TARGET_KPI_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_NEED_INPUT_TARGET_KPI_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_NEED_INPUT_TARGET_KPI_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_NEED_INPUT_TARGET_KPI_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_NEED_INPUT_TARGET_QUALITATIVE_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_NEED_INPUT_TARGET_QUALITATIVE_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_NEED_INPUT_TARGET_QUALITATIVE_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_NEED_INPUT_TARGET_QUALITATIVE_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_IS_LOCKED_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_IS_LOCKED_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_IS_LOCKED_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_IS_LOCKED_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_IS_LOCKED_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_IS_LOCKED_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_IS_LOCKED_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_IS_LOCKED_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_MID_LONG_TERM_GOAL_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_MID_LONG_TERM_GOAL_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_MID_LONG_TERM_GOAL_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_MID_LONG_TERM_GOAL_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_NEED_INPUT_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_NEED_INPUT_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_NEED_INPUT_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_NEED_INPUT_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_NEED_INPUT_TARGET_KPI_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_NEED_INPUT_TARGET_KPI_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_NEED_INPUT_TARGET_KPI_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_NEED_INPUT_TARGET_KPI_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_NEED_INPUT_TARGET_QUALITATIVE_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_NEED_INPUT_TARGET_QUALITATIVE_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_NEED_INPUT_TARGET_QUALITATIVE_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_NEED_INPUT_TARGET_QUALITATIVE_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_WEIGHT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_WEIGHT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_WEIGHT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MAX_WEIGHT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_WEIGHT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_WEIGHT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_WEIGHT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID__MIN_WEIGHT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  QUARTER_ASC = 'QUARTER_ASC',
  QUARTER_DESC = 'QUARTER_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  ROLE_NAME_ASC = 'ROLE_NAME_ASC',
  ROLE_NAME_DESC = 'ROLE_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
}

export interface PerformanceReviewMasterCompetencyToManyPerformanceReviewEvaluationCompetencyFilter {
  every?: InputMaybe<PerformanceReviewEvaluationCompetencyFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationCompetencyFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationCompetencyFilter>;
}

export interface PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyNodeIdConnect>>;
  connectByPerformanceReviewMasterCompetencyId?: InputMaybe<Array<PerformanceReviewMasterCompetencyPerformanceReviewMasterCompetencyPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyNodeIdDelete>>;
  deleteByPerformanceReviewMasterCompetencyId?: InputMaybe<Array<PerformanceReviewMasterCompetencyPerformanceReviewMasterCompetencyPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCompetencyUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterCompetencyId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCompetencyUserCreatedFkeyUsingPerformanceReviewMasterCompetencyPkeyUpdate>
  >;
}

export interface PerformanceReviewMasterCompetencyUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCompetencyUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCompetencyUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCompetencyUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyNodeIdConnect>>;
  connectByPerformanceReviewMasterCompetencyId?: InputMaybe<Array<PerformanceReviewMasterCompetencyPerformanceReviewMasterCompetencyPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyNodeIdDelete>>;
  deleteByPerformanceReviewMasterCompetencyId?: InputMaybe<Array<PerformanceReviewMasterCompetencyPerformanceReviewMasterCompetencyPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCompetencyUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterCompetencyId?: InputMaybe<
    Array<PerformanceReviewMasterCompetencyOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCompetencyUserUpdatedFkeyUsingPerformanceReviewMasterCompetencyPkeyUpdate>
  >;
}

export interface PerformanceReviewMasterDefault extends Node {
  __typename?: 'PerformanceReviewMasterDefault';
  dateCreated: Scalars['Datetime']['output'];
  defaultQuarter: PerformanceReviewQuarter;
  defaultYear: Scalars['Int']['output'];
  lockYearQuarterForEveryone: Scalars['Boolean']['output'];
  nodeId: Scalars['ID']['output'];
  performanceReviewMasterDefaultId: Scalars['BigInt']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
}

export interface PerformanceReviewMasterDefaultCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  defaultQuarter?: InputMaybe<PerformanceReviewQuarter>;
  defaultYear?: InputMaybe<Scalars['Int']['input']>;
  lockYearQuarterForEveryone?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterDefaultId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterDefaultFilter {
  and?: InputMaybe<Array<PerformanceReviewMasterDefaultFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  defaultQuarter?: InputMaybe<PerformanceReviewQuarterFilter>;
  defaultYear?: InputMaybe<IntFilter>;
  lockYearQuarterForEveryone?: InputMaybe<BooleanFilter>;
  not?: InputMaybe<PerformanceReviewMasterDefaultFilter>;
  or?: InputMaybe<Array<PerformanceReviewMasterDefaultFilter>>;
  performanceReviewMasterDefaultId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
}

export interface PerformanceReviewMasterDefaultInput {
  defaultQuarter: PerformanceReviewQuarter;
  defaultYear: Scalars['Int']['input'];
  lockYearQuarterForEveryone?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterDefaultId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterDefaultNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterDefaultNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterDefaultOnPerformanceReviewMasterDefaultForPerformanceReviewMasterDefaultUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewMasterDefaultOnPerformanceReviewMasterDefaultForPerformanceReviewMasterDefaultUserCreatedFkeyUsingPerformanceReviewMasterDefaultPkeyUpdate {
  patch: UpdatePerformanceReviewMasterDefaultOnPerformanceReviewMasterDefaultForPerformanceReviewMasterDefaultUserCreatedFkeyPatch;
  performanceReviewMasterDefaultId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterDefaultPatch {
  defaultQuarter?: InputMaybe<PerformanceReviewQuarter>;
  defaultYear?: InputMaybe<Scalars['Int']['input']>;
  lockYearQuarterForEveryone?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterDefaultId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterDefaultPerformanceReviewMasterDefaultPkeyConnect {
  performanceReviewMasterDefaultId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterDefaultPerformanceReviewMasterDefaultPkeyDelete {
  performanceReviewMasterDefaultId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterDefaultUserCreatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewMasterDefaultForPerformanceReviewMasterDefaultUserCreatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterDefaultOnPerformanceReviewMasterDefaultForPerformanceReviewMasterDefaultUserCreatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewMasterDefaultForPerformanceReviewMasterDefaultUserCreatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterDefaultNodeIdConnect>>;
  connectByPerformanceReviewMasterDefaultId?: InputMaybe<Array<PerformanceReviewMasterDefaultPerformanceReviewMasterDefaultPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterDefaultNodeIdDelete>>;
  deleteByPerformanceReviewMasterDefaultId?: InputMaybe<Array<PerformanceReviewMasterDefaultPerformanceReviewMasterDefaultPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterDefaultForPerformanceReviewMasterDefaultUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterDefaultId?: InputMaybe<
    Array<PerformanceReviewMasterDefaultOnPerformanceReviewMasterDefaultForPerformanceReviewMasterDefaultUserCreatedFkeyUsingPerformanceReviewMasterDefaultPkeyUpdate>
  >;
}

export interface PerformanceReviewMasterDefaultsConnection {
  __typename?: 'PerformanceReviewMasterDefaultsConnection';
  edges: Array<PerformanceReviewMasterDefaultsEdge>;
  nodes: Array<PerformanceReviewMasterDefault>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterDefaultsEdge {
  __typename?: 'PerformanceReviewMasterDefaultsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewMasterDefault;
}

export enum PerformanceReviewMasterDefaultsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DEFAULT_QUARTER_ASC = 'DEFAULT_QUARTER_ASC',
  DEFAULT_QUARTER_DESC = 'DEFAULT_QUARTER_DESC',
  DEFAULT_YEAR_ASC = 'DEFAULT_YEAR_ASC',
  DEFAULT_YEAR_DESC = 'DEFAULT_YEAR_DESC',
  LOCK_YEAR_QUARTER_FOR_EVERYONE_ASC = 'LOCK_YEAR_QUARTER_FOR_EVERYONE_ASC',
  LOCK_YEAR_QUARTER_FOR_EVERYONE_DESC = 'LOCK_YEAR_QUARTER_FOR_EVERYONE_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_MASTER_DEFAULT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_DEFAULT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_DEFAULT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_DEFAULT_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
}

export interface PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInput {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyEmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyUsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyNodeIdUpdate>;
}

export interface PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput {
  connectByEmployeeIdAndYearAndQuarterAndViewerEmployeeId?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerPerformanceReviewMasterEvaEmployeeIdYearQuarterViewKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerNodeIdConnect>>;
  connectByPerformanceReviewMasterEvaluationViewerId?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerPerformanceReviewMasterEvaluationViewerPkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyPerformanceReviewMasterEvaluationViewerCreateInput>>;
  deleteByEmployeeIdAndYearAndQuarterAndViewerEmployeeId?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerPerformanceReviewMasterEvaEmployeeIdYearQuarterViewKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerNodeIdDelete>>;
  deleteByPerformanceReviewMasterEvaluationViewerId?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerPerformanceReviewMasterEvaluationViewerPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeIdAndYearAndQuarterAndViewerEmployeeId?: InputMaybe<
    Array<PerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyUsingPerformanceReviewMasterEvaEmployeeIdYearQuarterViewKeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<EmployeeOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterEvaluationViewerId?: InputMaybe<
    Array<PerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyUsingPerformanceReviewMasterEvaluationViewerPkeyUpdate>
  >;
}

export interface PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyPerformanceReviewMasterEvaluationViewerCreateInput {
  canModify: Scalars['Boolean']['input'];
  employee?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterEvaluationViewerId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterEvaluationViewer extends Node {
  __typename?: 'PerformanceReviewMasterEvaluationViewer';
  canModify: Scalars['Boolean']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  employee: Employee;
  employeeId: Scalars['BigInt']['output'];
  nodeId: Scalars['ID']['output'];
  performanceReviewMasterEvaluationViewerId: Scalars['BigInt']['output'];
  quarter: PerformanceReviewQuarter;
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
  viewerEmployee: Employee;
  viewerEmployeeId: Scalars['BigInt']['output'];
  year: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterEvaluationViewerCondition {
  canModify?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterEvaluationViewerId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  viewerEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput {
  connectByEmployeeIdAndYearAndQuarterAndViewerEmployeeId?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerPerformanceReviewMasterEvaEmployeeIdYearQuarterViewKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerNodeIdConnect>>;
  connectByPerformanceReviewMasterEvaluationViewerId?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerPerformanceReviewMasterEvaluationViewerPkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyPerformanceReviewMasterEvaluationViewerCreateInput>>;
  deleteByEmployeeIdAndYearAndQuarterAndViewerEmployeeId?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerPerformanceReviewMasterEvaEmployeeIdYearQuarterViewKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerNodeIdDelete>>;
  deleteByPerformanceReviewMasterEvaluationViewerId?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerPerformanceReviewMasterEvaluationViewerPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeIdAndYearAndQuarterAndViewerEmployeeId?: InputMaybe<
    Array<PerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerEmployeeIdFkeyUsingPerformanceReviewMasterEvaEmployeeIdYearQuarterViewKeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<EmployeeOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerEmployeeIdFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterEvaluationViewerId?: InputMaybe<
    Array<PerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerEmployeeIdFkeyUsingPerformanceReviewMasterEvaluationViewerPkeyUpdate>
  >;
}

export interface PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyPerformanceReviewMasterEvaluationViewerCreateInput {
  canModify: Scalars['Boolean']['input'];
  employee?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInput>;
  performanceReviewMasterEvaluationViewerId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  viewerEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterEvaluationViewerFilter {
  and?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerFilter>>;
  canModify?: InputMaybe<BooleanFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  employee?: InputMaybe<EmployeeFilter>;
  employeeId?: InputMaybe<BigIntFilter>;
  not?: InputMaybe<PerformanceReviewMasterEvaluationViewerFilter>;
  or?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerFilter>>;
  performanceReviewMasterEvaluationViewerId?: InputMaybe<BigIntFilter>;
  quarter?: InputMaybe<PerformanceReviewQuarterFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
  viewerEmployee?: InputMaybe<EmployeeFilter>;
  viewerEmployeeId?: InputMaybe<BigIntFilter>;
  year?: InputMaybe<IntFilter>;
}

export interface PerformanceReviewMasterEvaluationViewerInput {
  canModify: Scalars['Boolean']['input'];
  employee?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterEvaluationViewerId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  viewerEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterEvaluationViewerNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterEvaluationViewerNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface PerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyUsingPerformanceReviewMasterEvaEmployeeIdYearQuarterViewKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyPatch;
  quarter: PerformanceReviewQuarter;
  viewerEmployeeId: Scalars['BigInt']['input'];
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyUsingPerformanceReviewMasterEvaluationViewerPkeyUpdate {
  patch: UpdatePerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyPatch;
  performanceReviewMasterEvaluationViewerId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerEmployeeIdFkeyUsingPerformanceReviewMasterEvaEmployeeIdYearQuarterViewKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerEmployeeIdFkeyPatch;
  quarter: PerformanceReviewQuarter;
  viewerEmployeeId: Scalars['BigInt']['input'];
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerEmployeeIdFkeyUsingPerformanceReviewMasterEvaluationViewerPkeyUpdate {
  patch: UpdatePerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerEmployeeIdFkeyPatch;
  performanceReviewMasterEvaluationViewerId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserCreatedFkeyUsingPerformanceReviewMasterEvaEmployeeIdYearQuarterViewKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserCreatedFkeyPatch;
  quarter: PerformanceReviewQuarter;
  viewerEmployeeId: Scalars['BigInt']['input'];
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserCreatedFkeyUsingPerformanceReviewMasterEvaluationViewerPkeyUpdate {
  patch: UpdatePerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserCreatedFkeyPatch;
  performanceReviewMasterEvaluationViewerId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserUpdatedFkeyUsingPerformanceReviewMasterEvaEmployeeIdYearQuarterViewKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserUpdatedFkeyPatch;
  quarter: PerformanceReviewQuarter;
  viewerEmployeeId: Scalars['BigInt']['input'];
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserUpdatedFkeyUsingPerformanceReviewMasterEvaluationViewerPkeyUpdate {
  patch: UpdatePerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserUpdatedFkeyPatch;
  performanceReviewMasterEvaluationViewerId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterEvaluationViewerPatch {
  canModify?: InputMaybe<Scalars['Boolean']['input']>;
  employee?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterEvaluationViewerId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  viewerEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewMasterEvaluationViewerPerformanceReviewMasterEvaEmployeeIdYearQuarterViewKeyConnect {
  employeeId: Scalars['BigInt']['input'];
  quarter: PerformanceReviewQuarter;
  viewerEmployeeId: Scalars['BigInt']['input'];
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterEvaluationViewerPerformanceReviewMasterEvaEmployeeIdYearQuarterViewKeyDelete {
  employeeId: Scalars['BigInt']['input'];
  quarter: PerformanceReviewQuarter;
  viewerEmployeeId: Scalars['BigInt']['input'];
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterEvaluationViewerPerformanceReviewMasterEvaluationViewerPkeyConnect {
  performanceReviewMasterEvaluationViewerId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterEvaluationViewerPerformanceReviewMasterEvaluationViewerPkeyDelete {
  performanceReviewMasterEvaluationViewerId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput {
  connectByEmployeeIdAndYearAndQuarterAndViewerEmployeeId?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerPerformanceReviewMasterEvaEmployeeIdYearQuarterViewKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerNodeIdConnect>>;
  connectByPerformanceReviewMasterEvaluationViewerId?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerPerformanceReviewMasterEvaluationViewerPkeyConnect>>;
  deleteByEmployeeIdAndYearAndQuarterAndViewerEmployeeId?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerPerformanceReviewMasterEvaEmployeeIdYearQuarterViewKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerNodeIdDelete>>;
  deleteByPerformanceReviewMasterEvaluationViewerId?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerPerformanceReviewMasterEvaluationViewerPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeIdAndYearAndQuarterAndViewerEmployeeId?: InputMaybe<
    Array<PerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserCreatedFkeyUsingPerformanceReviewMasterEvaEmployeeIdYearQuarterViewKeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterEvaluationViewerId?: InputMaybe<
    Array<PerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserCreatedFkeyUsingPerformanceReviewMasterEvaluationViewerPkeyUpdate>
  >;
}

export interface PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput {
  connectByEmployeeIdAndYearAndQuarterAndViewerEmployeeId?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerPerformanceReviewMasterEvaEmployeeIdYearQuarterViewKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerNodeIdConnect>>;
  connectByPerformanceReviewMasterEvaluationViewerId?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerPerformanceReviewMasterEvaluationViewerPkeyConnect>>;
  deleteByEmployeeIdAndYearAndQuarterAndViewerEmployeeId?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerPerformanceReviewMasterEvaEmployeeIdYearQuarterViewKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerNodeIdDelete>>;
  deleteByPerformanceReviewMasterEvaluationViewerId?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewerPerformanceReviewMasterEvaluationViewerPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeIdAndYearAndQuarterAndViewerEmployeeId?: InputMaybe<
    Array<PerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserUpdatedFkeyUsingPerformanceReviewMasterEvaEmployeeIdYearQuarterViewKeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterEvaluationViewerId?: InputMaybe<
    Array<PerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserUpdatedFkeyUsingPerformanceReviewMasterEvaluationViewerPkeyUpdate>
  >;
}

export interface PerformanceReviewMasterEvaluationViewersConnection {
  __typename?: 'PerformanceReviewMasterEvaluationViewersConnection';
  edges: Array<PerformanceReviewMasterEvaluationViewersEdge>;
  nodes: Array<PerformanceReviewMasterEvaluationViewer>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterEvaluationViewersEdge {
  __typename?: 'PerformanceReviewMasterEvaluationViewersEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewMasterEvaluationViewer;
}

export enum PerformanceReviewMasterEvaluationViewersOrderBy {
  CAN_MODIFY_ASC = 'CAN_MODIFY_ASC',
  CAN_MODIFY_DESC = 'CAN_MODIFY_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_VIEWER_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  QUARTER_ASC = 'QUARTER_ASC',
  QUARTER_DESC = 'QUARTER_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
  VIEWER_EMPLOYEE_ID_ASC = 'VIEWER_EMPLOYEE_ID_ASC',
  VIEWER_EMPLOYEE_ID_DESC = 'VIEWER_EMPLOYEE_ID_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
}

export interface PerformanceReviewMasterExcludedEmployee extends Node {
  __typename?: 'PerformanceReviewMasterExcludedEmployee';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  employee: Employee;
  employeeId: Scalars['BigInt']['output'];
  nodeId: Scalars['ID']['output'];
  quarter: PerformanceReviewQuarter;
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
  year: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterExcludedEmployeeCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInput {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyEmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyUsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyNodeIdUpdate>;
}

export interface PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterExcludedEmployeeNodeIdConnect>>;
  connectByYearAndQuarterAndEmployeeId?: InputMaybe<Array<PerformanceReviewMasterExcludedEmployeePerformanceReviewMasterExcludedEmployeePkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyPerformanceReviewMasterExcludedEmployeeCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterExcludedEmployeeNodeIdDelete>>;
  deleteByYearAndQuarterAndEmployeeId?: InputMaybe<Array<PerformanceReviewMasterExcludedEmployeePerformanceReviewMasterExcludedEmployeePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<EmployeeOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyNodeIdUpdate>>;
  updateByYearAndQuarterAndEmployeeId?: InputMaybe<
    Array<PerformanceReviewMasterExcludedEmployeeOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyUsingPerformanceReviewMasterExcludedEmployeePkeyUpdate>
  >;
}

export interface PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyPerformanceReviewMasterExcludedEmployeeCreateInput {
  employee?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterExcludedEmployeeFilter {
  and?: InputMaybe<Array<PerformanceReviewMasterExcludedEmployeeFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  employee?: InputMaybe<EmployeeFilter>;
  employeeId?: InputMaybe<BigIntFilter>;
  not?: InputMaybe<PerformanceReviewMasterExcludedEmployeeFilter>;
  or?: InputMaybe<Array<PerformanceReviewMasterExcludedEmployeeFilter>>;
  quarter?: InputMaybe<PerformanceReviewQuarterFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
  year?: InputMaybe<IntFilter>;
}

export interface PerformanceReviewMasterExcludedEmployeeInput {
  employee?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterExcludedEmployeeNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterExcludedEmployeeNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterExcludedEmployeeOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface PerformanceReviewMasterExcludedEmployeeOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyUsingPerformanceReviewMasterExcludedEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterExcludedEmployeeOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyPatch;
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterExcludedEmployeeOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeUserCreatedFkeyUsingPerformanceReviewMasterExcludedEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterExcludedEmployeeOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeUserCreatedFkeyPatch;
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterExcludedEmployeeOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewMasterExcludedEmployeeOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyUsingPerformanceReviewMasterExcludedEmployeePkeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterExcludedEmployeeOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyPatch;
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterExcludedEmployeePatch {
  employee?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewMasterExcludedEmployeePerformanceReviewMasterExcludedEmployeePkeyConnect {
  employeeId: Scalars['BigInt']['input'];
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterExcludedEmployeePerformanceReviewMasterExcludedEmployeePkeyDelete {
  employeeId: Scalars['BigInt']['input'];
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterExcludedEmployeeNodeIdConnect>>;
  connectByYearAndQuarterAndEmployeeId?: InputMaybe<Array<PerformanceReviewMasterExcludedEmployeePerformanceReviewMasterExcludedEmployeePkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterExcludedEmployeeNodeIdDelete>>;
  deleteByYearAndQuarterAndEmployeeId?: InputMaybe<Array<PerformanceReviewMasterExcludedEmployeePerformanceReviewMasterExcludedEmployeePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeUserCreatedFkeyNodeIdUpdate>>;
  updateByYearAndQuarterAndEmployeeId?: InputMaybe<
    Array<PerformanceReviewMasterExcludedEmployeeOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeUserCreatedFkeyUsingPerformanceReviewMasterExcludedEmployeePkeyUpdate>
  >;
}

export interface PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterExcludedEmployeeNodeIdConnect>>;
  connectByYearAndQuarterAndEmployeeId?: InputMaybe<Array<PerformanceReviewMasterExcludedEmployeePerformanceReviewMasterExcludedEmployeePkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterExcludedEmployeeNodeIdDelete>>;
  deleteByYearAndQuarterAndEmployeeId?: InputMaybe<Array<PerformanceReviewMasterExcludedEmployeePerformanceReviewMasterExcludedEmployeePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyNodeIdUpdate>>;
  updateByYearAndQuarterAndEmployeeId?: InputMaybe<
    Array<PerformanceReviewMasterExcludedEmployeeOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyUsingPerformanceReviewMasterExcludedEmployeePkeyUpdate>
  >;
}

export interface PerformanceReviewMasterExcludedEmployeesConnection {
  __typename?: 'PerformanceReviewMasterExcludedEmployeesConnection';
  edges: Array<PerformanceReviewMasterExcludedEmployeesEdge>;
  nodes: Array<PerformanceReviewMasterExcludedEmployee>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterExcludedEmployeesEdge {
  __typename?: 'PerformanceReviewMasterExcludedEmployeesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewMasterExcludedEmployee;
}

export enum PerformanceReviewMasterExcludedEmployeesOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  QUARTER_ASC = 'QUARTER_ASC',
  QUARTER_DESC = 'QUARTER_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
}

export interface PerformanceReviewMasterOneOnOne extends Node {
  __typename?: 'PerformanceReviewMasterOneOnOne';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  displayOrder: Scalars['Int']['output'];
  hidden: Scalars['Boolean']['output'];
  mode: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  performanceReviewEvaluationOneOnOnes: PerformanceReviewEvaluationOneOnOnesConnection;
  performanceReviewMasterOneOnOneDetails: PerformanceReviewMasterOneOnOneDetailsConnection;
  performanceReviewMasterOneOnOneId: Scalars['BigInt']['output'];
  quarter: PerformanceReviewQuarter;
  remark?: Maybe<Scalars['String']['output']>;
  required: Scalars['Boolean']['output'];
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
  year: Scalars['Int']['output'];
}

export type PerformanceReviewMasterOneOnOnePerformanceReviewEvaluationOneOnOnesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationOneOnOneCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationOneOnOneFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOnesOrderBy>>;
};

export type PerformanceReviewMasterOneOnOnePerformanceReviewMasterOneOnOneDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterOneOnOneDetailCondition>;
  filter?: InputMaybe<PerformanceReviewMasterOneOnOneDetailFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterOneOnOneDetailsOrderBy>>;
};

export interface PerformanceReviewMasterOneOnOneCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterOneOnOneId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewMasterOneOnOneDetail extends Node {
  __typename?: 'PerformanceReviewMasterOneOnOneDetail';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  itemName: Scalars['String']['output'];
  language: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  performanceReviewMasterOneOnOne: PerformanceReviewMasterOneOnOne;
  performanceReviewMasterOneOnOneDetailId: Scalars['BigInt']['output'];
  performanceReviewMasterOneOnOneId: Scalars['BigInt']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface PerformanceReviewMasterOneOnOneDetailCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  itemName?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterOneOnOneDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterOneOnOneId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterOneOnOneDetailFilter {
  and?: InputMaybe<Array<PerformanceReviewMasterOneOnOneDetailFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  itemName?: InputMaybe<StringFilter>;
  language?: InputMaybe<StringFilter>;
  not?: InputMaybe<PerformanceReviewMasterOneOnOneDetailFilter>;
  or?: InputMaybe<Array<PerformanceReviewMasterOneOnOneDetailFilter>>;
  performanceReviewMasterOneOnOne?: InputMaybe<PerformanceReviewMasterOneOnOneFilter>;
  performanceReviewMasterOneOnOneDetailId?: InputMaybe<BigIntFilter>;
  performanceReviewMasterOneOnOneId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface PerformanceReviewMasterOneOnOneDetailInput {
  itemName: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterOneOnOne?: InputMaybe<PerformanceReviewMasterOnePerformanceReviewMasterOnFkeyInput>;
  performanceReviewMasterOneOnOneDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterOneOnOneId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterOneOnOneDetailNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterOneOnOneDetailNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterOneOnOneDetailOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOneOnOneDetailUserCreatedFkeyUsingPerformanceReviewMasterOneOnOneDetailPkeyUpdate {
  patch: UpdatePerformanceReviewMasterOneOnOneDetailOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOneOnOneDetailUserCreatedFkeyPatch;
  performanceReviewMasterOneOnOneDetailId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterOneOnOneDetailOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewMasterOneOnOneDetailOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyUsingPerformanceReviewMasterOneOnOneDetailPkeyUpdate {
  patch: UpdatePerformanceReviewMasterOneOnOneDetailOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyPatch;
  performanceReviewMasterOneOnOneDetailId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterOneOnOneDetailOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOnePerformanceReviewMasterOnFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterOneOnOnePatch;
}

export interface PerformanceReviewMasterOneOnOneDetailOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOnePerformanceReviewMasterOnFkeyUsingPerformanceReviewMasterOneOnOneDetailPkeyUpdate {
  patch: UpdatePerformanceReviewMasterOneOnOneDetailOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOnePerformanceReviewMasterOnFkeyPatch;
  performanceReviewMasterOneOnOneDetailId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterOneOnOneDetailPatch {
  itemName?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterOneOnOne?: InputMaybe<PerformanceReviewMasterOnePerformanceReviewMasterOnFkeyInput>;
  performanceReviewMasterOneOnOneDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterOneOnOneId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterOneOnOneDetailPerformanceReviewMasterOneOnOneDetailPkeyConnect {
  performanceReviewMasterOneOnOneDetailId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterOneOnOneDetailPerformanceReviewMasterOneOnOneDetailPkeyDelete {
  performanceReviewMasterOneOnOneDetailId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterOneOnOneDetailNodeIdConnect>>;
  connectByPerformanceReviewMasterOneOnOneDetailId?: InputMaybe<Array<PerformanceReviewMasterOneOnOneDetailPerformanceReviewMasterOneOnOneDetailPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterOneOnOneDetailNodeIdDelete>>;
  deleteByPerformanceReviewMasterOneOnOneDetailId?: InputMaybe<Array<PerformanceReviewMasterOneOnOneDetailPerformanceReviewMasterOneOnOneDetailPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOneOnOneDetailUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterOneOnOneDetailId?: InputMaybe<
    Array<PerformanceReviewMasterOneOnOneDetailOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOneOnOneDetailUserCreatedFkeyUsingPerformanceReviewMasterOneOnOneDetailPkeyUpdate>
  >;
}

export interface PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterOneOnOneDetailNodeIdConnect>>;
  connectByPerformanceReviewMasterOneOnOneDetailId?: InputMaybe<Array<PerformanceReviewMasterOneOnOneDetailPerformanceReviewMasterOneOnOneDetailPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterOneOnOneDetailNodeIdDelete>>;
  deleteByPerformanceReviewMasterOneOnOneDetailId?: InputMaybe<Array<PerformanceReviewMasterOneOnOneDetailPerformanceReviewMasterOneOnOneDetailPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterOneOnOneDetailId?: InputMaybe<
    Array<PerformanceReviewMasterOneOnOneDetailOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyUsingPerformanceReviewMasterOneOnOneDetailPkeyUpdate>
  >;
}

export interface PerformanceReviewMasterOneOnOneDetailsConnection {
  __typename?: 'PerformanceReviewMasterOneOnOneDetailsConnection';
  edges: Array<PerformanceReviewMasterOneOnOneDetailsEdge>;
  nodes: Array<PerformanceReviewMasterOneOnOneDetail>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterOneOnOneDetailsEdge {
  __typename?: 'PerformanceReviewMasterOneOnOneDetailsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewMasterOneOnOneDetail;
}

export enum PerformanceReviewMasterOneOnOneDetailsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  ITEM_NAME_ASC = 'ITEM_NAME_ASC',
  ITEM_NAME_DESC = 'ITEM_NAME_DESC',
  LANGUAGE_ASC = 'LANGUAGE_ASC',
  LANGUAGE_DESC = 'LANGUAGE_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__HIDDEN_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__HIDDEN_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__HIDDEN_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__HIDDEN_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MODE_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MODE_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MODE_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MODE_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__REQUIRED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__REQUIRED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__REQUIRED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__REQUIRED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface PerformanceReviewMasterOneOnOneFilter {
  and?: InputMaybe<Array<PerformanceReviewMasterOneOnOneFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  hidden?: InputMaybe<BooleanFilter>;
  mode?: InputMaybe<StringFilter>;
  not?: InputMaybe<PerformanceReviewMasterOneOnOneFilter>;
  or?: InputMaybe<Array<PerformanceReviewMasterOneOnOneFilter>>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewMasterOneOnOneToManyPerformanceReviewEvaluationOneOnOneFilter>;
  performanceReviewEvaluationOneOnOnesExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterOneOnOneDetails?: InputMaybe<PerformanceReviewMasterOneOnOneToManyPerformanceReviewMasterOneOnOneDetailFilter>;
  performanceReviewMasterOneOnOneDetailsExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterOneOnOneId?: InputMaybe<BigIntFilter>;
  quarter?: InputMaybe<PerformanceReviewQuarterFilter>;
  remark?: InputMaybe<StringFilter>;
  required?: InputMaybe<BooleanFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
  year?: InputMaybe<IntFilter>;
}

export interface PerformanceReviewMasterOneOnOneFixDisplayOrderInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  targetQuarter?: InputMaybe<PerformanceReviewQuarter>;
  targetYear?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewMasterOneOnOneFixDisplayOrderPayload {
  __typename?: 'PerformanceReviewMasterOneOnOneFixDisplayOrderPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
}

export interface PerformanceReviewMasterOneOnOneInput {
  displayOrder: Scalars['Int']['input'];
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  mode: Scalars['String']['input'];
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterOnFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetails?: InputMaybe<PerformanceReviewMasterOnePerformanceReviewMasterOnFkeyInverseInput>;
  performanceReviewMasterOneOnOneId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterOneOnOneNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterOneOnOneNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterOneOnOneOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationPerformanceReviewMasterOnFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationOneOnOnePatch;
}

export interface PerformanceReviewMasterOneOnOneOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationPerformanceReviewMasterOnFkeyUsingPerformanceReviewMasterOneOnOnePkeyUpdate {
  patch: UpdatePerformanceReviewMasterOneOnOneOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationPerformanceReviewMasterOnFkeyPatch;
  performanceReviewMasterOneOnOneId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterOneOnOneOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOnePerformanceReviewMasterOnFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterOneOnOneDetailPatch;
}

export interface PerformanceReviewMasterOneOnOneOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOnePerformanceReviewMasterOnFkeyUsingPerformanceReviewMasterOneOnOnePkeyUpdate {
  patch: UpdatePerformanceReviewMasterOneOnOneOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOnePerformanceReviewMasterOnFkeyPatch;
  performanceReviewMasterOneOnOneId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterOneOnOneOnPerformanceReviewMasterOneOnOneForPerformanceReviewMasterOneOnOneUserCreatedFkeyUsingPerformanceReviewMasterOneOnOnePkeyUpdate {
  patch: UpdatePerformanceReviewMasterOneOnOneOnPerformanceReviewMasterOneOnOneForPerformanceReviewMasterOneOnOneUserCreatedFkeyPatch;
  performanceReviewMasterOneOnOneId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterOneOnOneOnPerformanceReviewMasterOneOnOneForPerformanceReviewMasterOneOnOneUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewMasterOneOnOneOnPerformanceReviewMasterOneOnOneForPerformanceReviewMasterOneOnOneUserUpdatedFkeyUsingPerformanceReviewMasterOneOnOnePkeyUpdate {
  patch: UpdatePerformanceReviewMasterOneOnOneOnPerformanceReviewMasterOneOnOneForPerformanceReviewMasterOneOnOneUserUpdatedFkeyPatch;
  performanceReviewMasterOneOnOneId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterOneOnOnePatch {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterOnFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetails?: InputMaybe<PerformanceReviewMasterOnePerformanceReviewMasterOnFkeyInverseInput>;
  performanceReviewMasterOneOnOneId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewMasterOneOnOnePerformanceReviewMasterOneOnOnePkeyConnect {
  performanceReviewMasterOneOnOneId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterOneOnOnePerformanceReviewMasterOneOnOnePkeyDelete {
  performanceReviewMasterOneOnOneId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterOneOnOneToManyPerformanceReviewEvaluationOneOnOneFilter {
  every?: InputMaybe<PerformanceReviewEvaluationOneOnOneFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationOneOnOneFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationOneOnOneFilter>;
}

export interface PerformanceReviewMasterOneOnOneToManyPerformanceReviewMasterOneOnOneDetailFilter {
  every?: InputMaybe<PerformanceReviewMasterOneOnOneDetailFilter>;
  none?: InputMaybe<PerformanceReviewMasterOneOnOneDetailFilter>;
  some?: InputMaybe<PerformanceReviewMasterOneOnOneDetailFilter>;
}

export interface PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterOneOnOneNodeIdConnect>>;
  connectByPerformanceReviewMasterOneOnOneId?: InputMaybe<Array<PerformanceReviewMasterOneOnOnePerformanceReviewMasterOneOnOnePkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterOneOnOneNodeIdDelete>>;
  deleteByPerformanceReviewMasterOneOnOneId?: InputMaybe<Array<PerformanceReviewMasterOneOnOnePerformanceReviewMasterOneOnOnePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterOneOnOneForPerformanceReviewMasterOneOnOneUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterOneOnOneId?: InputMaybe<
    Array<PerformanceReviewMasterOneOnOneOnPerformanceReviewMasterOneOnOneForPerformanceReviewMasterOneOnOneUserCreatedFkeyUsingPerformanceReviewMasterOneOnOnePkeyUpdate>
  >;
}

export interface PerformanceReviewMasterOneOnOneUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewMasterOneOnOneForPerformanceReviewMasterOneOnOneUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterOneOnOneOnPerformanceReviewMasterOneOnOneForPerformanceReviewMasterOneOnOneUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewMasterOneOnOneForPerformanceReviewMasterOneOnOneUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterOneOnOneNodeIdConnect>>;
  connectByPerformanceReviewMasterOneOnOneId?: InputMaybe<Array<PerformanceReviewMasterOneOnOnePerformanceReviewMasterOneOnOnePkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterOneOnOneNodeIdDelete>>;
  deleteByPerformanceReviewMasterOneOnOneId?: InputMaybe<Array<PerformanceReviewMasterOneOnOnePerformanceReviewMasterOneOnOnePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterOneOnOneForPerformanceReviewMasterOneOnOneUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterOneOnOneId?: InputMaybe<
    Array<PerformanceReviewMasterOneOnOneOnPerformanceReviewMasterOneOnOneForPerformanceReviewMasterOneOnOneUserUpdatedFkeyUsingPerformanceReviewMasterOneOnOnePkeyUpdate>
  >;
}

export interface PerformanceReviewMasterOneOnOnesConnection {
  __typename?: 'PerformanceReviewMasterOneOnOnesConnection';
  edges: Array<PerformanceReviewMasterOneOnOnesEdge>;
  nodes: Array<PerformanceReviewMasterOneOnOne>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterOneOnOnesEdge {
  __typename?: 'PerformanceReviewMasterOneOnOnesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewMasterOneOnOne;
}

export enum PerformanceReviewMasterOneOnOnesOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DISPLAY_ORDER_ASC = 'DISPLAY_ORDER_ASC',
  DISPLAY_ORDER_DESC = 'DISPLAY_ORDER_DESC',
  HIDDEN_ASC = 'HIDDEN_ASC',
  HIDDEN_DESC = 'HIDDEN_DESC',
  MODE_ASC = 'MODE_ASC',
  MODE_DESC = 'MODE_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_MONTH_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_MONTH_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_MONTH_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_MONTH_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_MONTH_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_MONTH_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_MONTH_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_MONTH_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_ITEM_NAME_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_ITEM_NAME_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_ITEM_NAME_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_ITEM_NAME_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_ITEM_NAME_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_ITEM_NAME_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_ITEM_NAME_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_ITEM_NAME_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  QUARTER_ASC = 'QUARTER_ASC',
  QUARTER_DESC = 'QUARTER_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  REQUIRED_ASC = 'REQUIRED_ASC',
  REQUIRED_DESC = 'REQUIRED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
}

export interface PerformanceReviewMasterOnePerformanceReviewMasterOnFkeyInput {
  connectByNodeId?: InputMaybe<PerformanceReviewMasterOneOnOneNodeIdConnect>;
  connectByPerformanceReviewMasterOneOnOneId?: InputMaybe<PerformanceReviewMasterOneOnOnePerformanceReviewMasterOneOnOnePkeyConnect>;
  create?: InputMaybe<PerformanceReviewMasterOnePerformanceReviewMasterOnFkeyPerformanceReviewMasterOneOnOneCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewMasterOneOnOneNodeIdDelete>;
  deleteByPerformanceReviewMasterOneOnOneId?: InputMaybe<PerformanceReviewMasterOneOnOnePerformanceReviewMasterOneOnOnePkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOnePerformanceReviewMasterOnFkeyNodeIdUpdate>;
  updateByPerformanceReviewMasterOneOnOneId?: InputMaybe<PerformanceReviewMasterOneOnOneOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOnePerformanceReviewMasterOnFkeyUsingPerformanceReviewMasterOneOnOnePkeyUpdate>;
}

export interface PerformanceReviewMasterOnePerformanceReviewMasterOnFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterOneOnOneDetailNodeIdConnect>>;
  connectByPerformanceReviewMasterOneOnOneDetailId?: InputMaybe<Array<PerformanceReviewMasterOneOnOneDetailPerformanceReviewMasterOneOnOneDetailPkeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewMasterOnePerformanceReviewMasterOnFkeyPerformanceReviewMasterOneOnOneDetailCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterOneOnOneDetailNodeIdDelete>>;
  deleteByPerformanceReviewMasterOneOnOneDetailId?: InputMaybe<Array<PerformanceReviewMasterOneOnOneDetailPerformanceReviewMasterOneOnOneDetailPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewMasterOneOnOneOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOnePerformanceReviewMasterOnFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterOneOnOneDetailId?: InputMaybe<
    Array<PerformanceReviewMasterOneOnOneDetailOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOnePerformanceReviewMasterOnFkeyUsingPerformanceReviewMasterOneOnOneDetailPkeyUpdate>
  >;
}

export interface PerformanceReviewMasterOnePerformanceReviewMasterOnFkeyPerformanceReviewMasterOneOnOneCreateInput {
  displayOrder: Scalars['Int']['input'];
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  mode: Scalars['String']['input'];
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterOnFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetails?: InputMaybe<PerformanceReviewMasterOnePerformanceReviewMasterOnFkeyInverseInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterOnePerformanceReviewMasterOnFkeyPerformanceReviewMasterOneOnOneDetailCreateInput {
  itemName: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterOneOnOne?: InputMaybe<PerformanceReviewMasterOnePerformanceReviewMasterOnFkeyInput>;
  performanceReviewMasterOneOnOneDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyInput {
  connectByNodeId?: InputMaybe<PerformanceReviewMasterRatingNodeIdConnect>;
  connectByPerformanceReviewMasterRatingId?: InputMaybe<PerformanceReviewMasterRatingPerformanceReviewMasterRatingPkeyConnect>;
  create?: InputMaybe<PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyPerformanceReviewMasterRatingCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewMasterRatingNodeIdDelete>;
  deleteByPerformanceReviewMasterRatingId?: InputMaybe<PerformanceReviewMasterRatingPerformanceReviewMasterRatingPkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterRatingDescriptionOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatPerformanceReviewMasterRaFkeyNodeIdUpdate>;
  updateByPerformanceReviewMasterRatingId?: InputMaybe<PerformanceReviewMasterRatingOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatPerformanceReviewMasterRaFkeyUsingPerformanceReviewMasterRatingPkeyUpdate>;
}

export interface PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionNodeIdConnect>>;
  connectByPerformanceReviewMasterRatingDescriptionId?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionPerformanceReviewMasterRatingDescriptionPkeyConnect>>;
  connectByPerformanceReviewMasterRatingIdAndLanguage?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionPerformanceReviewMasterRatPerformanceReviewMasterRatKeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyPerformanceReviewMasterRatingDescriptionCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionNodeIdDelete>>;
  deleteByPerformanceReviewMasterRatingDescriptionId?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionPerformanceReviewMasterRatingDescriptionPkeyDelete>>;
  deleteByPerformanceReviewMasterRatingIdAndLanguage?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionPerformanceReviewMasterRatPerformanceReviewMasterRatKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewMasterRatingOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatPerformanceReviewMasterRaFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterRatingDescriptionId?: InputMaybe<
    Array<PerformanceReviewMasterRatingDescriptionOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatPerformanceReviewMasterRaFkeyUsingPerformanceReviewMasterRatingDescriptionPkeyUpdate>
  >;
  updateByPerformanceReviewMasterRatingIdAndLanguage?: InputMaybe<
    Array<PerformanceReviewMasterRatingDescriptionOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatPerformanceReviewMasterRaFkeyUsingPerformanceReviewMasterRatPerformanceReviewMasterRatKeyUpdate>
  >;
}

export interface PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyPerformanceReviewMasterRatingCreateInput {
  calculationValue: Scalars['BigFloat']['input'];
  displayValue: Scalars['Int']['input'];
  percentageLowerBound?: InputMaybe<Scalars['Int']['input']>;
  percentageUpperBound?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInverseInput>;
  performanceReviewMasterRatingDescriptions?: InputMaybe<PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInverseInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyPerformanceReviewMasterRatingDescriptionCreateInput {
  descriptionExpectation: Scalars['String']['input'];
  descriptionRating: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyInput>;
  performanceReviewMasterRatingDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterRating extends Node {
  __typename?: 'PerformanceReviewMasterRating';
  calculationValue: Scalars['BigFloat']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  displayValue: Scalars['Int']['output'];
  nodeId: Scalars['ID']['output'];
  percentageLowerBound?: Maybe<Scalars['Int']['output']>;
  percentageUpperBound?: Maybe<Scalars['Int']['output']>;
  performanceReviewEvaluationCompetenciesByAssessmentValue: PerformanceReviewEvaluationCompetenciesConnection;
  performanceReviewEvaluationQualitativeAssessmentsByAssessmentValue: PerformanceReviewEvaluationQualitativeAssessmentsConnection;
  performanceReviewEvaluationValuesByAssessmentValue: PerformanceReviewEvaluationValuesConnection;
  performanceReviewMasterRatingDescriptions: PerformanceReviewMasterRatingDescriptionsConnection;
  performanceReviewMasterRatingId: Scalars['BigInt']['output'];
  probationReviewEvaluationResultScoreCompetenciesByAssessment: ProbationReviewEvaluationResultScoreCompetenciesConnection;
  probationReviewEvaluationResultScoreValuesByAssessment: ProbationReviewEvaluationResultScoreValuesConnection;
  quarter: PerformanceReviewQuarter;
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
  year: Scalars['Int']['output'];
}

export type PerformanceReviewMasterRatingPerformanceReviewEvaluationCompetenciesByAssessmentValueArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationCompetencyCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationCompetencyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationCompetenciesOrderBy>>;
};

export type PerformanceReviewMasterRatingPerformanceReviewEvaluationQualitativeAssessmentsByAssessmentValueArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentsOrderBy>>;
};

export type PerformanceReviewMasterRatingPerformanceReviewEvaluationValuesByAssessmentValueArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationValueCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationValueFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationValuesOrderBy>>;
};

export type PerformanceReviewMasterRatingPerformanceReviewMasterRatingDescriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterRatingDescriptionCondition>;
  filter?: InputMaybe<PerformanceReviewMasterRatingDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionsOrderBy>>;
};

export type PerformanceReviewMasterRatingProbationReviewEvaluationResultScoreCompetenciesByAssessmentArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetenciesOrderBy>>;
};

export type PerformanceReviewMasterRatingProbationReviewEvaluationResultScoreValuesByAssessmentArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationResultScoreValueCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationResultScoreValueFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValuesOrderBy>>;
};

export interface PerformanceReviewMasterRatingCondition {
  calculationValue?: InputMaybe<Scalars['BigFloat']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  displayValue?: InputMaybe<Scalars['Int']['input']>;
  percentageLowerBound?: InputMaybe<Scalars['Int']['input']>;
  percentageUpperBound?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterRatingId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewMasterRatingDescription extends Node {
  __typename?: 'PerformanceReviewMasterRatingDescription';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  descriptionExpectation: Scalars['String']['output'];
  descriptionRating: Scalars['String']['output'];
  language: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  performanceReviewMasterRating: PerformanceReviewMasterRating;
  performanceReviewMasterRatingDescriptionId: Scalars['BigInt']['output'];
  performanceReviewMasterRatingId: Scalars['BigInt']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface PerformanceReviewMasterRatingDescriptionCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  descriptionExpectation?: InputMaybe<Scalars['String']['input']>;
  descriptionRating?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterRatingDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRatingId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterRatingDescriptionFilter {
  and?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  descriptionExpectation?: InputMaybe<StringFilter>;
  descriptionRating?: InputMaybe<StringFilter>;
  language?: InputMaybe<StringFilter>;
  not?: InputMaybe<PerformanceReviewMasterRatingDescriptionFilter>;
  or?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionFilter>>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewMasterRatingFilter>;
  performanceReviewMasterRatingDescriptionId?: InputMaybe<BigIntFilter>;
  performanceReviewMasterRatingId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface PerformanceReviewMasterRatingDescriptionInput {
  descriptionExpectation: Scalars['String']['input'];
  descriptionRating: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyInput>;
  performanceReviewMasterRatingDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRatingId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterRatingDescriptionNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterRatingDescriptionNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterRatingDescriptionOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatPerformanceReviewMasterRaFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterRatingPatch;
}

export interface PerformanceReviewMasterRatingDescriptionOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatPerformanceReviewMasterRaFkeyUsingPerformanceReviewMasterRatPerformanceReviewMasterRatKeyUpdate {
  language: Scalars['String']['input'];
  patch: UpdatePerformanceReviewMasterRatingDescriptionOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatPerformanceReviewMasterRaFkeyPatch;
  performanceReviewMasterRatingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterRatingDescriptionOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatPerformanceReviewMasterRaFkeyUsingPerformanceReviewMasterRatingDescriptionPkeyUpdate {
  patch: UpdatePerformanceReviewMasterRatingDescriptionOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatPerformanceReviewMasterRaFkeyPatch;
  performanceReviewMasterRatingDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterRatingDescriptionOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserCreatedFkeyUsingPerformanceReviewMasterRatPerformanceReviewMasterRatKeyUpdate {
  language: Scalars['String']['input'];
  patch: UpdatePerformanceReviewMasterRatingDescriptionOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserCreatedFkeyPatch;
  performanceReviewMasterRatingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterRatingDescriptionOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserCreatedFkeyUsingPerformanceReviewMasterRatingDescriptionPkeyUpdate {
  patch: UpdatePerformanceReviewMasterRatingDescriptionOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserCreatedFkeyPatch;
  performanceReviewMasterRatingDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterRatingDescriptionOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewMasterRatingDescriptionOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserUpdatedFkeyUsingPerformanceReviewMasterRatPerformanceReviewMasterRatKeyUpdate {
  language: Scalars['String']['input'];
  patch: UpdatePerformanceReviewMasterRatingDescriptionOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserUpdatedFkeyPatch;
  performanceReviewMasterRatingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterRatingDescriptionOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserUpdatedFkeyUsingPerformanceReviewMasterRatingDescriptionPkeyUpdate {
  patch: UpdatePerformanceReviewMasterRatingDescriptionOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserUpdatedFkeyPatch;
  performanceReviewMasterRatingDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterRatingDescriptionPatch {
  descriptionExpectation?: InputMaybe<Scalars['String']['input']>;
  descriptionRating?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyInput>;
  performanceReviewMasterRatingDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRatingId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterRatingDescriptionPerformanceReviewMasterRatPerformanceReviewMasterRatKeyConnect {
  language: Scalars['String']['input'];
  performanceReviewMasterRatingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterRatingDescriptionPerformanceReviewMasterRatPerformanceReviewMasterRatKeyDelete {
  language: Scalars['String']['input'];
  performanceReviewMasterRatingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterRatingDescriptionPerformanceReviewMasterRatingDescriptionPkeyConnect {
  performanceReviewMasterRatingDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterRatingDescriptionPerformanceReviewMasterRatingDescriptionPkeyDelete {
  performanceReviewMasterRatingDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionNodeIdConnect>>;
  connectByPerformanceReviewMasterRatingDescriptionId?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionPerformanceReviewMasterRatingDescriptionPkeyConnect>>;
  connectByPerformanceReviewMasterRatingIdAndLanguage?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionPerformanceReviewMasterRatPerformanceReviewMasterRatKeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionNodeIdDelete>>;
  deleteByPerformanceReviewMasterRatingDescriptionId?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionPerformanceReviewMasterRatingDescriptionPkeyDelete>>;
  deleteByPerformanceReviewMasterRatingIdAndLanguage?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionPerformanceReviewMasterRatPerformanceReviewMasterRatKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterRatingDescriptionId?: InputMaybe<
    Array<PerformanceReviewMasterRatingDescriptionOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserCreatedFkeyUsingPerformanceReviewMasterRatingDescriptionPkeyUpdate>
  >;
  updateByPerformanceReviewMasterRatingIdAndLanguage?: InputMaybe<
    Array<PerformanceReviewMasterRatingDescriptionOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserCreatedFkeyUsingPerformanceReviewMasterRatPerformanceReviewMasterRatKeyUpdate>
  >;
}

export interface PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterRatingDescriptionOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionNodeIdConnect>>;
  connectByPerformanceReviewMasterRatingDescriptionId?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionPerformanceReviewMasterRatingDescriptionPkeyConnect>>;
  connectByPerformanceReviewMasterRatingIdAndLanguage?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionPerformanceReviewMasterRatPerformanceReviewMasterRatKeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionNodeIdDelete>>;
  deleteByPerformanceReviewMasterRatingDescriptionId?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionPerformanceReviewMasterRatingDescriptionPkeyDelete>>;
  deleteByPerformanceReviewMasterRatingIdAndLanguage?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionPerformanceReviewMasterRatPerformanceReviewMasterRatKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterRatingDescriptionId?: InputMaybe<
    Array<PerformanceReviewMasterRatingDescriptionOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserUpdatedFkeyUsingPerformanceReviewMasterRatingDescriptionPkeyUpdate>
  >;
  updateByPerformanceReviewMasterRatingIdAndLanguage?: InputMaybe<
    Array<PerformanceReviewMasterRatingDescriptionOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserUpdatedFkeyUsingPerformanceReviewMasterRatPerformanceReviewMasterRatKeyUpdate>
  >;
}

export interface PerformanceReviewMasterRatingDescriptionsConnection {
  __typename?: 'PerformanceReviewMasterRatingDescriptionsConnection';
  edges: Array<PerformanceReviewMasterRatingDescriptionsEdge>;
  nodes: Array<PerformanceReviewMasterRatingDescription>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterRatingDescriptionsEdge {
  __typename?: 'PerformanceReviewMasterRatingDescriptionsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewMasterRatingDescription;
}

export enum PerformanceReviewMasterRatingDescriptionsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DESCRIPTION_EXPECTATION_ASC = 'DESCRIPTION_EXPECTATION_ASC',
  DESCRIPTION_EXPECTATION_DESC = 'DESCRIPTION_EXPECTATION_DESC',
  DESCRIPTION_RATING_ASC = 'DESCRIPTION_RATING_ASC',
  DESCRIPTION_RATING_DESC = 'DESCRIPTION_RATING_DESC',
  LANGUAGE_ASC = 'LANGUAGE_ASC',
  LANGUAGE_DESC = 'LANGUAGE_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__CALCULATION_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__CALCULATION_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__CALCULATION_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__CALCULATION_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__DISPLAY_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__DISPLAY_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__DISPLAY_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__DISPLAY_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__PERCENTAGE_LOWER_BOUND_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__PERCENTAGE_LOWER_BOUND_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__PERCENTAGE_LOWER_BOUND_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__PERCENTAGE_LOWER_BOUND_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__PERCENTAGE_UPPER_BOUND_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__PERCENTAGE_UPPER_BOUND_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__PERCENTAGE_UPPER_BOUND_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__PERCENTAGE_UPPER_BOUND_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface PerformanceReviewMasterRatingFilter {
  and?: InputMaybe<Array<PerformanceReviewMasterRatingFilter>>;
  calculationValue?: InputMaybe<BigFloatFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  displayValue?: InputMaybe<IntFilter>;
  not?: InputMaybe<PerformanceReviewMasterRatingFilter>;
  or?: InputMaybe<Array<PerformanceReviewMasterRatingFilter>>;
  percentageLowerBound?: InputMaybe<IntFilter>;
  percentageUpperBound?: InputMaybe<IntFilter>;
  performanceReviewEvaluationCompetenciesByAssessmentValue?: InputMaybe<PerformanceReviewMasterRatingToManyPerformanceReviewEvaluationCompetencyFilter>;
  performanceReviewEvaluationCompetenciesByAssessmentValueExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationQualitativeAssessmentsByAssessmentValue?: InputMaybe<PerformanceReviewMasterRatingToManyPerformanceReviewEvaluationQualitativeAssessmentFilter>;
  performanceReviewEvaluationQualitativeAssessmentsByAssessmentValueExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationValuesByAssessmentValue?: InputMaybe<PerformanceReviewMasterRatingToManyPerformanceReviewEvaluationValueFilter>;
  performanceReviewEvaluationValuesByAssessmentValueExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterRatingDescriptions?: InputMaybe<PerformanceReviewMasterRatingToManyPerformanceReviewMasterRatingDescriptionFilter>;
  performanceReviewMasterRatingDescriptionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterRatingId?: InputMaybe<BigIntFilter>;
  probationReviewEvaluationResultScoreCompetenciesByAssessment?: InputMaybe<PerformanceReviewMasterRatingToManyProbationReviewEvaluationResultScoreCompetencyFilter>;
  probationReviewEvaluationResultScoreCompetenciesByAssessmentExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationResultScoreValuesByAssessment?: InputMaybe<PerformanceReviewMasterRatingToManyProbationReviewEvaluationResultScoreValueFilter>;
  probationReviewEvaluationResultScoreValuesByAssessmentExist?: InputMaybe<Scalars['Boolean']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarterFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
  year?: InputMaybe<IntFilter>;
}

export interface PerformanceReviewMasterRatingInput {
  calculationValue: Scalars['BigFloat']['input'];
  displayValue: Scalars['Int']['input'];
  percentageLowerBound?: InputMaybe<Scalars['Int']['input']>;
  percentageUpperBound?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInverseInput>;
  performanceReviewMasterRatingDescriptions?: InputMaybe<PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyInverseInput>;
  performanceReviewMasterRatingId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInverseInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterRatingNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterRatingNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterRatingOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyAssessmentValueFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationCompetencyPatch;
}

export interface PerformanceReviewMasterRatingOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyAssessmentValueFkeyUsingPerformanceReviewMasterRatingPkeyUpdate {
  patch: UpdatePerformanceReviewMasterRatingOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyAssessmentValueFkeyPatch;
  performanceReviewMasterRatingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterRatingOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssessmentValueFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationQualitativeAssessmentPatch;
}

export interface PerformanceReviewMasterRatingOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssessmentValueFkeyUsingPerformanceReviewMasterRatingPkeyUpdate {
  patch: UpdatePerformanceReviewMasterRatingOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssessmentValueFkeyPatch;
  performanceReviewMasterRatingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterRatingOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentValueFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationValuePatch;
}

export interface PerformanceReviewMasterRatingOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentValueFkeyUsingPerformanceReviewMasterRatingPkeyUpdate {
  patch: UpdatePerformanceReviewMasterRatingOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentValueFkeyPatch;
  performanceReviewMasterRatingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterRatingOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatPerformanceReviewMasterRaFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterRatingDescriptionPatch;
}

export interface PerformanceReviewMasterRatingOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatPerformanceReviewMasterRaFkeyUsingPerformanceReviewMasterRatingPkeyUpdate {
  patch: UpdatePerformanceReviewMasterRatingOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatPerformanceReviewMasterRaFkeyPatch;
  performanceReviewMasterRatingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterRatingOnPerformanceReviewMasterRatingForPerformanceReviewMasterRatingUserCreatedFkeyUsingPerformanceReviewMasterRatingPkeyUpdate {
  patch: UpdatePerformanceReviewMasterRatingOnPerformanceReviewMasterRatingForPerformanceReviewMasterRatingUserCreatedFkeyPatch;
  performanceReviewMasterRatingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterRatingOnPerformanceReviewMasterRatingForPerformanceReviewMasterRatingUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewMasterRatingOnPerformanceReviewMasterRatingForPerformanceReviewMasterRatingUserUpdatedFkeyUsingPerformanceReviewMasterRatingPkeyUpdate {
  patch: UpdatePerformanceReviewMasterRatingOnPerformanceReviewMasterRatingForPerformanceReviewMasterRatingUserUpdatedFkeyPatch;
  performanceReviewMasterRatingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterRatingOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompetAssessmentFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationResultScoreCompetencyPatch;
}

export interface PerformanceReviewMasterRatingOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompetAssessmentFkeyUsingPerformanceReviewMasterRatingPkeyUpdate {
  patch: UpdatePerformanceReviewMasterRatingOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompetAssessmentFkeyPatch;
  performanceReviewMasterRatingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterRatingOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValueAssessmentFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationResultScoreValuePatch;
}

export interface PerformanceReviewMasterRatingOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValueAssessmentFkeyUsingPerformanceReviewMasterRatingPkeyUpdate {
  patch: UpdatePerformanceReviewMasterRatingOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValueAssessmentFkeyPatch;
  performanceReviewMasterRatingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterRatingPatch {
  calculationValue?: InputMaybe<Scalars['BigFloat']['input']>;
  displayValue?: InputMaybe<Scalars['Int']['input']>;
  percentageLowerBound?: InputMaybe<Scalars['Int']['input']>;
  percentageUpperBound?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInverseInput>;
  performanceReviewMasterRatingDescriptions?: InputMaybe<PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyInverseInput>;
  performanceReviewMasterRatingId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewMasterRatingPerformanceReviewMasterRatingPkeyConnect {
  performanceReviewMasterRatingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterRatingPerformanceReviewMasterRatingPkeyDelete {
  performanceReviewMasterRatingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterRatingToManyPerformanceReviewEvaluationCompetencyFilter {
  every?: InputMaybe<PerformanceReviewEvaluationCompetencyFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationCompetencyFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationCompetencyFilter>;
}

export interface PerformanceReviewMasterRatingToManyPerformanceReviewEvaluationQualitativeAssessmentFilter {
  every?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentFilter>;
}

export interface PerformanceReviewMasterRatingToManyPerformanceReviewEvaluationValueFilter {
  every?: InputMaybe<PerformanceReviewEvaluationValueFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationValueFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationValueFilter>;
}

export interface PerformanceReviewMasterRatingToManyPerformanceReviewMasterRatingDescriptionFilter {
  every?: InputMaybe<PerformanceReviewMasterRatingDescriptionFilter>;
  none?: InputMaybe<PerformanceReviewMasterRatingDescriptionFilter>;
  some?: InputMaybe<PerformanceReviewMasterRatingDescriptionFilter>;
}

export interface PerformanceReviewMasterRatingToManyProbationReviewEvaluationResultScoreCompetencyFilter {
  every?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyFilter>;
  none?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyFilter>;
  some?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyFilter>;
}

export interface PerformanceReviewMasterRatingToManyProbationReviewEvaluationResultScoreValueFilter {
  every?: InputMaybe<ProbationReviewEvaluationResultScoreValueFilter>;
  none?: InputMaybe<ProbationReviewEvaluationResultScoreValueFilter>;
  some?: InputMaybe<ProbationReviewEvaluationResultScoreValueFilter>;
}

export interface PerformanceReviewMasterRatingUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterRatingNodeIdConnect>>;
  connectByPerformanceReviewMasterRatingId?: InputMaybe<Array<PerformanceReviewMasterRatingPerformanceReviewMasterRatingPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterRatingNodeIdDelete>>;
  deleteByPerformanceReviewMasterRatingId?: InputMaybe<Array<PerformanceReviewMasterRatingPerformanceReviewMasterRatingPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterRatingForPerformanceReviewMasterRatingUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterRatingId?: InputMaybe<
    Array<PerformanceReviewMasterRatingOnPerformanceReviewMasterRatingForPerformanceReviewMasterRatingUserCreatedFkeyUsingPerformanceReviewMasterRatingPkeyUpdate>
  >;
}

export interface PerformanceReviewMasterRatingUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewMasterRatingForPerformanceReviewMasterRatingUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterRatingOnPerformanceReviewMasterRatingForPerformanceReviewMasterRatingUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewMasterRatingForPerformanceReviewMasterRatingUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterRatingNodeIdConnect>>;
  connectByPerformanceReviewMasterRatingId?: InputMaybe<Array<PerformanceReviewMasterRatingPerformanceReviewMasterRatingPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterRatingNodeIdDelete>>;
  deleteByPerformanceReviewMasterRatingId?: InputMaybe<Array<PerformanceReviewMasterRatingPerformanceReviewMasterRatingPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterRatingForPerformanceReviewMasterRatingUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterRatingId?: InputMaybe<
    Array<PerformanceReviewMasterRatingOnPerformanceReviewMasterRatingForPerformanceReviewMasterRatingUserUpdatedFkeyUsingPerformanceReviewMasterRatingPkeyUpdate>
  >;
}

export interface PerformanceReviewMasterRatingsConnection {
  __typename?: 'PerformanceReviewMasterRatingsConnection';
  edges: Array<PerformanceReviewMasterRatingsEdge>;
  nodes: Array<PerformanceReviewMasterRating>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterRatingsEdge {
  __typename?: 'PerformanceReviewMasterRatingsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewMasterRating;
}

export enum PerformanceReviewMasterRatingsOrderBy {
  CALCULATION_VALUE_ASC = 'CALCULATION_VALUE_ASC',
  CALCULATION_VALUE_DESC = 'CALCULATION_VALUE_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DISPLAY_VALUE_ASC = 'DISPLAY_VALUE_ASC',
  DISPLAY_VALUE_DESC = 'DISPLAY_VALUE_DESC',
  NATURAL = 'NATURAL',
  PERCENTAGE_LOWER_BOUND_ASC = 'PERCENTAGE_LOWER_BOUND_ASC',
  PERCENTAGE_LOWER_BOUND_DESC = 'PERCENTAGE_LOWER_BOUND_DESC',
  PERCENTAGE_UPPER_BOUND_ASC = 'PERCENTAGE_UPPER_BOUND_ASC',
  PERCENTAGE_UPPER_BOUND_DESC = 'PERCENTAGE_UPPER_BOUND_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_ASSESSMENT_VALUE__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_ASSESSMENT_VALUE__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_ASSESSMENT_VALUE__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_DESCRIPTION_EXPECTATION_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_DESCRIPTION_EXPECTATION_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_DESCRIPTION_EXPECTATION_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_DESCRIPTION_EXPECTATION_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_DESCRIPTION_RATING_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_DESCRIPTION_RATING_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_DESCRIPTION_RATING_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_DESCRIPTION_RATING_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_DESCRIPTION_EXPECTATION_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_DESCRIPTION_EXPECTATION_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_DESCRIPTION_EXPECTATION_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_DESCRIPTION_EXPECTATION_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_DESCRIPTION_RATING_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_DESCRIPTION_RATING_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_DESCRIPTION_RATING_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_DESCRIPTION_RATING_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_RATING_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__COUNT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__COUNT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__COUNT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__COUNT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_ASSESSMENT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_ASSESSMENT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_ASSESSMENT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_ASSESSMENT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_ASSESSMENT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_ASSESSMENT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_ASSESSMENT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_ASSESSMENT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_ASSESSMENT__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__COUNT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__COUNT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__COUNT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__COUNT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_ASSESSMENT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_ASSESSMENT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_ASSESSMENT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_ASSESSMENT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_ASSESSMENT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_ASSESSMENT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_ASSESSMENT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_ASSESSMENT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_ASSESSMENT__MIN_USER_UPDATED_DESC',
  QUARTER_ASC = 'QUARTER_ASC',
  QUARTER_DESC = 'QUARTER_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
}

export interface PerformanceReviewMasterUsage extends Node {
  __typename?: 'PerformanceReviewMasterUsage';
  company: Company;
  companyId: Scalars['BigInt']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  nodeId: Scalars['ID']['output'];
  performanceReviewMasterUsageId: Scalars['BigInt']['output'];
  quarter: PerformanceReviewQuarter;
  remark?: Maybe<Scalars['String']['output']>;
  usePerformanceReview: Scalars['Boolean']['output'];
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
  year: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterUsageCompanyIdFkeyCompanyCreateInput {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName: Scalars['String']['input'];
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterUsageCompanyIdFkeyInput {
  connectByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyConnect>;
  connectByCompanyId?: InputMaybe<CompanyCompanyPkeyConnect>;
  connectByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyConnect>;
  connectByNodeId?: InputMaybe<CompanyNodeIdConnect>;
  create?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyCompanyCreateInput>;
  deleteByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyDelete>;
  deleteByCompanyId?: InputMaybe<CompanyCompanyPkeyDelete>;
  deleteByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyDelete>;
  deleteByNodeId?: InputMaybe<CompanyNodeIdDelete>;
  updateByAbbreviation?: InputMaybe<CompanyOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageCompanyIdFkeyUsingCompanyAbbreviationKeyUpdate>;
  updateByCompanyId?: InputMaybe<CompanyOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageCompanyIdFkeyUsingCompanyPkeyUpdate>;
  updateByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageCompanyIdFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterUsageOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageCompanyIdFkeyNodeIdUpdate>;
}

export interface PerformanceReviewMasterUsageCompanyIdFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterUsageNodeIdConnect>>;
  connectByPerformanceReviewMasterUsageId?: InputMaybe<Array<PerformanceReviewMasterUsagePerformanceReviewMasterUsagePkeyConnect>>;
  connectByYearAndQuarterAndCompanyId?: InputMaybe<Array<PerformanceReviewMasterUsagePerformanceReviewMasterUsageYearQuarterCompanyIdKeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewMasterUsageCompanyIdFkeyPerformanceReviewMasterUsageCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterUsageNodeIdDelete>>;
  deleteByPerformanceReviewMasterUsageId?: InputMaybe<Array<PerformanceReviewMasterUsagePerformanceReviewMasterUsagePkeyDelete>>;
  deleteByYearAndQuarterAndCompanyId?: InputMaybe<Array<PerformanceReviewMasterUsagePerformanceReviewMasterUsageYearQuarterCompanyIdKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<CompanyOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageCompanyIdFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterUsageId?: InputMaybe<
    Array<PerformanceReviewMasterUsageOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageCompanyIdFkeyUsingPerformanceReviewMasterUsagePkeyUpdate>
  >;
  updateByYearAndQuarterAndCompanyId?: InputMaybe<
    Array<PerformanceReviewMasterUsageOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageCompanyIdFkeyUsingPerformanceReviewMasterUsageYearQuarterCompanyIdKeyUpdate>
  >;
}

export interface PerformanceReviewMasterUsageCompanyIdFkeyPerformanceReviewMasterUsageCreateInput {
  company?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInput>;
  performanceReviewMasterUsageId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  usePerformanceReview?: InputMaybe<Scalars['Boolean']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterUsageCondition {
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  performanceReviewMasterUsageId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  usePerformanceReview?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewMasterUsageFilter {
  and?: InputMaybe<Array<PerformanceReviewMasterUsageFilter>>;
  company?: InputMaybe<CompanyFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  not?: InputMaybe<PerformanceReviewMasterUsageFilter>;
  or?: InputMaybe<Array<PerformanceReviewMasterUsageFilter>>;
  performanceReviewMasterUsageId?: InputMaybe<BigIntFilter>;
  quarter?: InputMaybe<PerformanceReviewQuarterFilter>;
  remark?: InputMaybe<StringFilter>;
  usePerformanceReview?: InputMaybe<BooleanFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
  year?: InputMaybe<IntFilter>;
}

export interface PerformanceReviewMasterUsageInput {
  company?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterUsageId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  usePerformanceReview?: InputMaybe<Scalars['Boolean']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterUsageNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterUsageNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterUsageOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageCompanyIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyPatch;
}

export interface PerformanceReviewMasterUsageOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageCompanyIdFkeyUsingPerformanceReviewMasterUsagePkeyUpdate {
  patch: UpdatePerformanceReviewMasterUsageOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageCompanyIdFkeyPatch;
  performanceReviewMasterUsageId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterUsageOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageCompanyIdFkeyUsingPerformanceReviewMasterUsageYearQuarterCompanyIdKeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterUsageOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageCompanyIdFkeyPatch;
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterUsageOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserCreatedFkeyUsingPerformanceReviewMasterUsagePkeyUpdate {
  patch: UpdatePerformanceReviewMasterUsageOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserCreatedFkeyPatch;
  performanceReviewMasterUsageId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterUsageOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserCreatedFkeyUsingPerformanceReviewMasterUsageYearQuarterCompanyIdKeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterUsageOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserCreatedFkeyPatch;
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterUsageOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewMasterUsageOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserUpdatedFkeyUsingPerformanceReviewMasterUsagePkeyUpdate {
  patch: UpdatePerformanceReviewMasterUsageOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserUpdatedFkeyPatch;
  performanceReviewMasterUsageId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterUsageOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserUpdatedFkeyUsingPerformanceReviewMasterUsageYearQuarterCompanyIdKeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterUsageOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserUpdatedFkeyPatch;
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterUsagePatch {
  company?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterUsageId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  usePerformanceReview?: InputMaybe<Scalars['Boolean']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewMasterUsagePerformanceReviewMasterUsagePkeyConnect {
  performanceReviewMasterUsageId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterUsagePerformanceReviewMasterUsagePkeyDelete {
  performanceReviewMasterUsageId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterUsagePerformanceReviewMasterUsageYearQuarterCompanyIdKeyConnect {
  companyId: Scalars['BigInt']['input'];
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterUsagePerformanceReviewMasterUsageYearQuarterCompanyIdKeyDelete {
  companyId: Scalars['BigInt']['input'];
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterUsageUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterUsageNodeIdConnect>>;
  connectByPerformanceReviewMasterUsageId?: InputMaybe<Array<PerformanceReviewMasterUsagePerformanceReviewMasterUsagePkeyConnect>>;
  connectByYearAndQuarterAndCompanyId?: InputMaybe<Array<PerformanceReviewMasterUsagePerformanceReviewMasterUsageYearQuarterCompanyIdKeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterUsageNodeIdDelete>>;
  deleteByPerformanceReviewMasterUsageId?: InputMaybe<Array<PerformanceReviewMasterUsagePerformanceReviewMasterUsagePkeyDelete>>;
  deleteByYearAndQuarterAndCompanyId?: InputMaybe<Array<PerformanceReviewMasterUsagePerformanceReviewMasterUsageYearQuarterCompanyIdKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterUsageId?: InputMaybe<
    Array<PerformanceReviewMasterUsageOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserCreatedFkeyUsingPerformanceReviewMasterUsagePkeyUpdate>
  >;
  updateByYearAndQuarterAndCompanyId?: InputMaybe<
    Array<PerformanceReviewMasterUsageOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserCreatedFkeyUsingPerformanceReviewMasterUsageYearQuarterCompanyIdKeyUpdate>
  >;
}

export interface PerformanceReviewMasterUsageUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterUsageOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterUsageNodeIdConnect>>;
  connectByPerformanceReviewMasterUsageId?: InputMaybe<Array<PerformanceReviewMasterUsagePerformanceReviewMasterUsagePkeyConnect>>;
  connectByYearAndQuarterAndCompanyId?: InputMaybe<Array<PerformanceReviewMasterUsagePerformanceReviewMasterUsageYearQuarterCompanyIdKeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterUsageNodeIdDelete>>;
  deleteByPerformanceReviewMasterUsageId?: InputMaybe<Array<PerformanceReviewMasterUsagePerformanceReviewMasterUsagePkeyDelete>>;
  deleteByYearAndQuarterAndCompanyId?: InputMaybe<Array<PerformanceReviewMasterUsagePerformanceReviewMasterUsageYearQuarterCompanyIdKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterUsageId?: InputMaybe<
    Array<PerformanceReviewMasterUsageOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserUpdatedFkeyUsingPerformanceReviewMasterUsagePkeyUpdate>
  >;
  updateByYearAndQuarterAndCompanyId?: InputMaybe<
    Array<PerformanceReviewMasterUsageOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserUpdatedFkeyUsingPerformanceReviewMasterUsageYearQuarterCompanyIdKeyUpdate>
  >;
}

export interface PerformanceReviewMasterUsagesConnection {
  __typename?: 'PerformanceReviewMasterUsagesConnection';
  edges: Array<PerformanceReviewMasterUsagesEdge>;
  nodes: Array<PerformanceReviewMasterUsage>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterUsagesEdge {
  __typename?: 'PerformanceReviewMasterUsagesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewMasterUsage;
}

export enum PerformanceReviewMasterUsagesOrderBy {
  COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC',
  COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_ASC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_ASC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_DESC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_DESC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANY_BY_COMPANY_ID__REMARK_ASC = 'COMPANY_BY_COMPANY_ID__REMARK_ASC',
  COMPANY_BY_COMPANY_ID__REMARK_DESC = 'COMPANY_BY_COMPANY_ID__REMARK_DESC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC',
  COMPANY_ID_ASC = 'COMPANY_ID_ASC',
  COMPANY_ID_DESC = 'COMPANY_ID_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_MASTER_USAGE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGE_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  QUARTER_ASC = 'QUARTER_ASC',
  QUARTER_DESC = 'QUARTER_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
  USE_PERFORMANCE_REVIEW_ASC = 'USE_PERFORMANCE_REVIEW_ASC',
  USE_PERFORMANCE_REVIEW_DESC = 'USE_PERFORMANCE_REVIEW_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
}

export interface PerformanceReviewMasterValPerformanceReviewMasterVaFkeyInput {
  connectByNodeId?: InputMaybe<PerformanceReviewMasterValueNodeIdConnect>;
  connectByPerformanceReviewMasterValueId?: InputMaybe<PerformanceReviewMasterValuePerformanceReviewMasterValuePkeyConnect>;
  create?: InputMaybe<PerformanceReviewMasterValPerformanceReviewMasterVaFkeyPerformanceReviewMasterValueCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewMasterValueNodeIdDelete>;
  deleteByPerformanceReviewMasterValueId?: InputMaybe<PerformanceReviewMasterValuePerformanceReviewMasterValuePkeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterValueDescriptionOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValPerformanceReviewMasterVaFkeyNodeIdUpdate>;
  updateByPerformanceReviewMasterValueId?: InputMaybe<PerformanceReviewMasterValueOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValPerformanceReviewMasterVaFkeyUsingPerformanceReviewMasterValuePkeyUpdate>;
}

export interface PerformanceReviewMasterValPerformanceReviewMasterVaFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionNodeIdConnect>>;
  connectByPerformanceReviewMasterValueDescriptionId?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionPerformanceReviewMasterValueDescriptionPkeyConnect>>;
  connectByPerformanceReviewMasterValueIdAndLanguage?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionPerformanceReviewMasterValPerformanceReviewMasterValKeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewMasterValPerformanceReviewMasterVaFkeyPerformanceReviewMasterValueDescriptionCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionNodeIdDelete>>;
  deleteByPerformanceReviewMasterValueDescriptionId?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionPerformanceReviewMasterValueDescriptionPkeyDelete>>;
  deleteByPerformanceReviewMasterValueIdAndLanguage?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionPerformanceReviewMasterValPerformanceReviewMasterValKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewMasterValueOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValPerformanceReviewMasterVaFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterValueDescriptionId?: InputMaybe<
    Array<PerformanceReviewMasterValueDescriptionOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValPerformanceReviewMasterVaFkeyUsingPerformanceReviewMasterValueDescriptionPkeyUpdate>
  >;
  updateByPerformanceReviewMasterValueIdAndLanguage?: InputMaybe<
    Array<PerformanceReviewMasterValueDescriptionOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValPerformanceReviewMasterVaFkeyUsingPerformanceReviewMasterValPerformanceReviewMasterValKeyUpdate>
  >;
}

export interface PerformanceReviewMasterValPerformanceReviewMasterVaFkeyPerformanceReviewMasterValueCreateInput {
  displayOrder: Scalars['Int']['input'];
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyInverseInput>;
  performanceReviewMasterValueDescriptions?: InputMaybe<PerformanceReviewMasterValPerformanceReviewMasterVaFkeyInverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyInverseInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterValPerformanceReviewMasterVaFkeyPerformanceReviewMasterValueDescriptionCreateInput {
  description: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterValue?: InputMaybe<PerformanceReviewMasterValPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  userAccount?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterValue extends Node {
  __typename?: 'PerformanceReviewMasterValue';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  displayOrder: Scalars['Int']['output'];
  nodeId: Scalars['ID']['output'];
  performanceReviewEvaluationValues: PerformanceReviewEvaluationValuesConnection;
  performanceReviewMasterValueDescriptions: PerformanceReviewMasterValueDescriptionsConnection;
  performanceReviewMasterValueId: Scalars['BigInt']['output'];
  probationReviewEvaluationResultScoreValues: ProbationReviewEvaluationResultScoreValuesConnection;
  quarter: PerformanceReviewQuarter;
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
  weight: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
}

export type PerformanceReviewMasterValuePerformanceReviewEvaluationValuesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationValueCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationValueFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationValuesOrderBy>>;
};

export type PerformanceReviewMasterValuePerformanceReviewMasterValueDescriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterValueDescriptionCondition>;
  filter?: InputMaybe<PerformanceReviewMasterValueDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionsOrderBy>>;
};

export type PerformanceReviewMasterValueProbationReviewEvaluationResultScoreValuesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationResultScoreValueCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationResultScoreValueFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValuesOrderBy>>;
};

export interface PerformanceReviewMasterValueCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewMasterValueCount extends Node {
  __typename?: 'PerformanceReviewMasterValueCount';
  count?: Maybe<Scalars['BigInt']['output']>;
  language: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterValueCountCondition {
  count?: InputMaybe<Scalars['BigInt']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewMasterValueCountFilter {
  and?: InputMaybe<Array<PerformanceReviewMasterValueCountFilter>>;
  count?: InputMaybe<BigIntFilter>;
  language?: InputMaybe<StringFilter>;
  not?: InputMaybe<PerformanceReviewMasterValueCountFilter>;
  or?: InputMaybe<Array<PerformanceReviewMasterValueCountFilter>>;
  quarter?: InputMaybe<PerformanceReviewQuarterFilter>;
  year?: InputMaybe<IntFilter>;
}

export interface PerformanceReviewMasterValueCountsConnection {
  __typename?: 'PerformanceReviewMasterValueCountsConnection';
  edges: Array<PerformanceReviewMasterValueCountsEdge>;
  nodes: Array<PerformanceReviewMasterValueCount>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterValueCountsEdge {
  __typename?: 'PerformanceReviewMasterValueCountsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewMasterValueCount;
}

export enum PerformanceReviewMasterValueCountsOrderBy {
  COUNT_ASC = 'COUNT_ASC',
  COUNT_DESC = 'COUNT_DESC',
  LANGUAGE_ASC = 'LANGUAGE_ASC',
  LANGUAGE_DESC = 'LANGUAGE_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  QUARTER_ASC = 'QUARTER_ASC',
  QUARTER_DESC = 'QUARTER_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
}

export interface PerformanceReviewMasterValueDescription extends Node {
  __typename?: 'PerformanceReviewMasterValueDescription';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  description: Scalars['String']['output'];
  language: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  performanceReviewMasterValue: PerformanceReviewMasterValue;
  performanceReviewMasterValueDescriptionId: Scalars['BigInt']['output'];
  performanceReviewMasterValueId: Scalars['BigInt']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface PerformanceReviewMasterValueDescriptionCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterValueDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterValueDescriptionFilter {
  and?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  description?: InputMaybe<StringFilter>;
  language?: InputMaybe<StringFilter>;
  not?: InputMaybe<PerformanceReviewMasterValueDescriptionFilter>;
  or?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionFilter>>;
  performanceReviewMasterValue?: InputMaybe<PerformanceReviewMasterValueFilter>;
  performanceReviewMasterValueDescriptionId?: InputMaybe<BigIntFilter>;
  performanceReviewMasterValueId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface PerformanceReviewMasterValueDescriptionInput {
  description: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterValue?: InputMaybe<PerformanceReviewMasterValPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  userAccount?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterValueDescriptionNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterValueDescriptionNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterValueDescriptionOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValPerformanceReviewMasterVaFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterValuePatch;
}

export interface PerformanceReviewMasterValueDescriptionOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValPerformanceReviewMasterVaFkeyUsingPerformanceReviewMasterValPerformanceReviewMasterValKeyUpdate {
  language: Scalars['String']['input'];
  patch: UpdatePerformanceReviewMasterValueDescriptionOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValPerformanceReviewMasterVaFkeyPatch;
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterValueDescriptionOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValPerformanceReviewMasterVaFkeyUsingPerformanceReviewMasterValueDescriptionPkeyUpdate {
  patch: UpdatePerformanceReviewMasterValueDescriptionOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValPerformanceReviewMasterVaFkeyPatch;
  performanceReviewMasterValueDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterValueDescriptionOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserCreatedFkeyUsingPerformanceReviewMasterValPerformanceReviewMasterValKeyUpdate {
  language: Scalars['String']['input'];
  patch: UpdatePerformanceReviewMasterValueDescriptionOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserCreatedFkeyPatch;
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterValueDescriptionOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserCreatedFkeyUsingPerformanceReviewMasterValueDescriptionPkeyUpdate {
  patch: UpdatePerformanceReviewMasterValueDescriptionOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserCreatedFkeyPatch;
  performanceReviewMasterValueDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterValueDescriptionOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewMasterValueDescriptionOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserUpdatedFkeyUsingPerformanceReviewMasterValPerformanceReviewMasterValKeyUpdate {
  language: Scalars['String']['input'];
  patch: UpdatePerformanceReviewMasterValueDescriptionOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserUpdatedFkeyPatch;
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterValueDescriptionOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserUpdatedFkeyUsingPerformanceReviewMasterValueDescriptionPkeyUpdate {
  patch: UpdatePerformanceReviewMasterValueDescriptionOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserUpdatedFkeyPatch;
  performanceReviewMasterValueDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterValueDescriptionPatch {
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterValue?: InputMaybe<PerformanceReviewMasterValPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterValueDescriptionPerformanceReviewMasterValPerformanceReviewMasterValKeyConnect {
  language: Scalars['String']['input'];
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterValueDescriptionPerformanceReviewMasterValPerformanceReviewMasterValKeyDelete {
  language: Scalars['String']['input'];
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterValueDescriptionPerformanceReviewMasterValueDescriptionPkeyConnect {
  performanceReviewMasterValueDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterValueDescriptionPerformanceReviewMasterValueDescriptionPkeyDelete {
  performanceReviewMasterValueDescriptionId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionNodeIdConnect>>;
  connectByPerformanceReviewMasterValueDescriptionId?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionPerformanceReviewMasterValueDescriptionPkeyConnect>>;
  connectByPerformanceReviewMasterValueIdAndLanguage?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionPerformanceReviewMasterValPerformanceReviewMasterValKeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionNodeIdDelete>>;
  deleteByPerformanceReviewMasterValueDescriptionId?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionPerformanceReviewMasterValueDescriptionPkeyDelete>>;
  deleteByPerformanceReviewMasterValueIdAndLanguage?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionPerformanceReviewMasterValPerformanceReviewMasterValKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterValueDescriptionId?: InputMaybe<
    Array<PerformanceReviewMasterValueDescriptionOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserCreatedFkeyUsingPerformanceReviewMasterValueDescriptionPkeyUpdate>
  >;
  updateByPerformanceReviewMasterValueIdAndLanguage?: InputMaybe<
    Array<PerformanceReviewMasterValueDescriptionOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserCreatedFkeyUsingPerformanceReviewMasterValPerformanceReviewMasterValKeyUpdate>
  >;
}

export interface PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterValueDescriptionOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionNodeIdConnect>>;
  connectByPerformanceReviewMasterValueDescriptionId?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionPerformanceReviewMasterValueDescriptionPkeyConnect>>;
  connectByPerformanceReviewMasterValueIdAndLanguage?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionPerformanceReviewMasterValPerformanceReviewMasterValKeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionNodeIdDelete>>;
  deleteByPerformanceReviewMasterValueDescriptionId?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionPerformanceReviewMasterValueDescriptionPkeyDelete>>;
  deleteByPerformanceReviewMasterValueIdAndLanguage?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionPerformanceReviewMasterValPerformanceReviewMasterValKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterValueDescriptionId?: InputMaybe<
    Array<PerformanceReviewMasterValueDescriptionOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserUpdatedFkeyUsingPerformanceReviewMasterValueDescriptionPkeyUpdate>
  >;
  updateByPerformanceReviewMasterValueIdAndLanguage?: InputMaybe<
    Array<PerformanceReviewMasterValueDescriptionOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserUpdatedFkeyUsingPerformanceReviewMasterValPerformanceReviewMasterValKeyUpdate>
  >;
}

export interface PerformanceReviewMasterValueDescriptionsConnection {
  __typename?: 'PerformanceReviewMasterValueDescriptionsConnection';
  edges: Array<PerformanceReviewMasterValueDescriptionsEdge>;
  nodes: Array<PerformanceReviewMasterValueDescription>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterValueDescriptionsEdge {
  __typename?: 'PerformanceReviewMasterValueDescriptionsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewMasterValueDescription;
}

export enum PerformanceReviewMasterValueDescriptionsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DESCRIPTION_ASC = 'DESCRIPTION_ASC',
  DESCRIPTION_DESC = 'DESCRIPTION_DESC',
  LANGUAGE_ASC = 'LANGUAGE_ASC',
  LANGUAGE_DESC = 'LANGUAGE_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__WEIGHT_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__WEIGHT_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__WEIGHT_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__WEIGHT_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface PerformanceReviewMasterValueFilter {
  and?: InputMaybe<Array<PerformanceReviewMasterValueFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  not?: InputMaybe<PerformanceReviewMasterValueFilter>;
  or?: InputMaybe<Array<PerformanceReviewMasterValueFilter>>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewMasterValueToManyPerformanceReviewEvaluationValueFilter>;
  performanceReviewEvaluationValuesExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterValueDescriptions?: InputMaybe<PerformanceReviewMasterValueToManyPerformanceReviewMasterValueDescriptionFilter>;
  performanceReviewMasterValueDescriptionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterValueId?: InputMaybe<BigIntFilter>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<PerformanceReviewMasterValueToManyProbationReviewEvaluationResultScoreValueFilter>;
  probationReviewEvaluationResultScoreValuesExist?: InputMaybe<Scalars['Boolean']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarterFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
  weight?: InputMaybe<IntFilter>;
  year?: InputMaybe<IntFilter>;
}

export interface PerformanceReviewMasterValueFixDisplayOrderInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  targetQuarter?: InputMaybe<PerformanceReviewQuarter>;
  targetYear?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewMasterValueFixDisplayOrderPayload {
  __typename?: 'PerformanceReviewMasterValueFixDisplayOrderPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
}

export interface PerformanceReviewMasterValueInput {
  displayOrder: Scalars['Int']['input'];
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyInverseInput>;
  performanceReviewMasterValueDescriptions?: InputMaybe<PerformanceReviewMasterValPerformanceReviewMasterVaFkeyInverseInput>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyInverseInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterValueNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterValueNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewMasterVaFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationValuePatch;
}

export interface PerformanceReviewMasterValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewMasterVaFkeyUsingPerformanceReviewMasterValuePkeyUpdate {
  patch: UpdatePerformanceReviewMasterValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewMasterVaFkeyPatch;
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterValueOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValPerformanceReviewMasterVaFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterValueDescriptionPatch;
}

export interface PerformanceReviewMasterValueOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValPerformanceReviewMasterVaFkeyUsingPerformanceReviewMasterValuePkeyUpdate {
  patch: UpdatePerformanceReviewMasterValueOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValPerformanceReviewMasterVaFkeyPatch;
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterValueOnPerformanceReviewMasterValueForPerformanceReviewMasterValueUserCreatedFkeyUsingPerformanceReviewMasterValuePkeyUpdate {
  patch: UpdatePerformanceReviewMasterValueOnPerformanceReviewMasterValueForPerformanceReviewMasterValueUserCreatedFkeyPatch;
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterValueOnPerformanceReviewMasterValueForPerformanceReviewMasterValueUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewMasterValueOnPerformanceReviewMasterValueForPerformanceReviewMasterValueUserUpdatedFkeyUsingPerformanceReviewMasterValuePkeyUpdate {
  patch: UpdatePerformanceReviewMasterValueOnPerformanceReviewMasterValueForPerformanceReviewMasterValueUserUpdatedFkeyPatch;
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationRPerformanceReviewMasterVaFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationResultScoreValuePatch;
}

export interface PerformanceReviewMasterValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationRPerformanceReviewMasterVaFkeyUsingPerformanceReviewMasterValuePkeyUpdate {
  patch: UpdatePerformanceReviewMasterValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationRPerformanceReviewMasterVaFkeyPatch;
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterValuePatch {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyInverseInput>;
  performanceReviewMasterValueDescriptions?: InputMaybe<PerformanceReviewMasterValPerformanceReviewMasterVaFkeyInverseInput>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewMasterValuePerformanceReviewMasterValuePkeyConnect {
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterValuePerformanceReviewMasterValuePkeyDelete {
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterValueToManyPerformanceReviewEvaluationValueFilter {
  every?: InputMaybe<PerformanceReviewEvaluationValueFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationValueFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationValueFilter>;
}

export interface PerformanceReviewMasterValueToManyPerformanceReviewMasterValueDescriptionFilter {
  every?: InputMaybe<PerformanceReviewMasterValueDescriptionFilter>;
  none?: InputMaybe<PerformanceReviewMasterValueDescriptionFilter>;
  some?: InputMaybe<PerformanceReviewMasterValueDescriptionFilter>;
}

export interface PerformanceReviewMasterValueToManyProbationReviewEvaluationResultScoreValueFilter {
  every?: InputMaybe<ProbationReviewEvaluationResultScoreValueFilter>;
  none?: InputMaybe<ProbationReviewEvaluationResultScoreValueFilter>;
  some?: InputMaybe<ProbationReviewEvaluationResultScoreValueFilter>;
}

export interface PerformanceReviewMasterValueUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterValueNodeIdConnect>>;
  connectByPerformanceReviewMasterValueId?: InputMaybe<Array<PerformanceReviewMasterValuePerformanceReviewMasterValuePkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterValueNodeIdDelete>>;
  deleteByPerformanceReviewMasterValueId?: InputMaybe<Array<PerformanceReviewMasterValuePerformanceReviewMasterValuePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterValueForPerformanceReviewMasterValueUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterValueId?: InputMaybe<
    Array<PerformanceReviewMasterValueOnPerformanceReviewMasterValueForPerformanceReviewMasterValueUserCreatedFkeyUsingPerformanceReviewMasterValuePkeyUpdate>
  >;
}

export interface PerformanceReviewMasterValueUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewMasterValueForPerformanceReviewMasterValueUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterValueOnPerformanceReviewMasterValueForPerformanceReviewMasterValueUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewMasterValueForPerformanceReviewMasterValueUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewMasterValueUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterValueNodeIdConnect>>;
  connectByPerformanceReviewMasterValueId?: InputMaybe<Array<PerformanceReviewMasterValuePerformanceReviewMasterValuePkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterValueNodeIdDelete>>;
  deleteByPerformanceReviewMasterValueId?: InputMaybe<Array<PerformanceReviewMasterValuePerformanceReviewMasterValuePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterValueForPerformanceReviewMasterValueUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterValueId?: InputMaybe<
    Array<PerformanceReviewMasterValueOnPerformanceReviewMasterValueForPerformanceReviewMasterValueUserUpdatedFkeyUsingPerformanceReviewMasterValuePkeyUpdate>
  >;
}

export interface PerformanceReviewMasterValuesConnection {
  __typename?: 'PerformanceReviewMasterValuesConnection';
  edges: Array<PerformanceReviewMasterValuesEdge>;
  nodes: Array<PerformanceReviewMasterValue>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterValuesEdge {
  __typename?: 'PerformanceReviewMasterValuesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewMasterValue;
}

export enum PerformanceReviewMasterValuesOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DISPLAY_ORDER_ASC = 'DISPLAY_ORDER_ASC',
  DISPLAY_ORDER_DESC = 'DISPLAY_ORDER_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_TITLE_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_TITLE_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_TITLE_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_TITLE_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_TITLE_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_TITLE_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_TITLE_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_TITLE_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__COUNT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__COUNT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__COUNT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__COUNT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_ASSESSMENT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_ASSESSMENT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_ASSESSMENT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_ASSESSMENT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_ASSESSMENT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_ASSESSMENT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_ASSESSMENT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_ASSESSMENT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__MIN_USER_UPDATED_DESC',
  QUARTER_ASC = 'QUARTER_ASC',
  QUARTER_DESC = 'QUARTER_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
  WEIGHT_ASC = 'WEIGHT_ASC',
  WEIGHT_DESC = 'WEIGHT_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
}

export interface PerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyInput {
  connectByNodeId?: InputMaybe<PerformanceReviewMasterWeightingNodeIdConnect>;
  connectByPerformanceReviewMasterWeightingId?: InputMaybe<PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingPkeyConnect>;
  connectByYearAndQuarterAndCompanyId?: InputMaybe<PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingYearQuarterCompanyIdKeyConnect>;
  connectByYearAndQuarterAndName?: InputMaybe<PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingYearQuarterNameKeyConnect>;
  create?: InputMaybe<PerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyPerformanceReviewMasterWeightingCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewMasterWeightingNodeIdDelete>;
  deleteByPerformanceReviewMasterWeightingId?: InputMaybe<PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingPkeyDelete>;
  deleteByYearAndQuarterAndCompanyId?: InputMaybe<PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingYearQuarterCompanyIdKeyDelete>;
  deleteByYearAndQuarterAndName?: InputMaybe<PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingYearQuarterNameKeyDelete>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyNodeIdUpdate>;
  updateByPerformanceReviewMasterWeightingId?: InputMaybe<PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyUsingPerformanceReviewMasterWeightingPkeyUpdate>;
  updateByYearAndQuarterAndCompanyId?: InputMaybe<PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyUsingPerformanceReviewMasterWeightingYearQuarterCompanyIdKeyUpdate>;
  updateByYearAndQuarterAndName?: InputMaybe<PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyUsingPerformanceReviewMasterWeightingYearQuarterNameKeyUpdate>;
}

export interface PerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailNodeIdConnect>>;
  connectByPerformanceReviewMasterWeightingDetailId?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailPerformanceReviewMasterWeightingDetailPkeyConnect>>;
  connectByPerformanceReviewMasterWeightingIdAndEmployeeLevelId?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailPerformanceReviewMasterWeiPerformanceReviewMasterWeiKeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyPerformanceReviewMasterWeightingDetailCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailNodeIdDelete>>;
  deleteByPerformanceReviewMasterWeightingDetailId?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailPerformanceReviewMasterWeightingDetailPkeyDelete>>;
  deleteByPerformanceReviewMasterWeightingIdAndEmployeeLevelId?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailPerformanceReviewMasterWeiPerformanceReviewMasterWeiKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterWeightingDetailId?: InputMaybe<
    Array<PerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyUsingPerformanceReviewMasterWeightingDetailPkeyUpdate>
  >;
  updateByPerformanceReviewMasterWeightingIdAndEmployeeLevelId?: InputMaybe<
    Array<PerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyUsingPerformanceReviewMasterWeiPerformanceReviewMasterWeiKeyUpdate>
  >;
}

export interface PerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyPerformanceReviewMasterWeightingCreateInput {
  company?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  name: Scalars['String']['input'];
  performanceReviewMasterWeightingDetails?: InputMaybe<PerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyInverseInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyPerformanceReviewMasterWeightingDetailCreateInput {
  employeeLevel?: InputMaybe<PerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  percentageCompetency: Scalars['Int']['input'];
  percentageMultiSource: Scalars['Int']['input'];
  percentageTarget: Scalars['Int']['input'];
  percentageValue: Scalars['Int']['input'];
  performanceReviewMasterWeighting?: InputMaybe<PerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyInput>;
  performanceReviewMasterWeightingDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterWeighting extends Node {
  __typename?: 'PerformanceReviewMasterWeighting';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['BigInt']['output']>;
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  name: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  performanceReviewMasterWeightingDetails: PerformanceReviewMasterWeightingDetailsConnection;
  performanceReviewMasterWeightingId: Scalars['BigInt']['output'];
  quarter: PerformanceReviewQuarter;
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
  year: Scalars['Int']['output'];
}

export type PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterWeightingDetailCondition>;
  filter?: InputMaybe<PerformanceReviewMasterWeightingDetailFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailsOrderBy>>;
};

export interface PerformanceReviewMasterWeightingCompanyIdFkeyCompanyCreateInput {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName: Scalars['String']['input'];
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterWeightingCompanyIdFkeyInput {
  connectByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyConnect>;
  connectByCompanyId?: InputMaybe<CompanyCompanyPkeyConnect>;
  connectByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyConnect>;
  connectByNodeId?: InputMaybe<CompanyNodeIdConnect>;
  create?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyCompanyCreateInput>;
  deleteByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyDelete>;
  deleteByCompanyId?: InputMaybe<CompanyCompanyPkeyDelete>;
  deleteByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyDelete>;
  deleteByNodeId?: InputMaybe<CompanyNodeIdDelete>;
  updateByAbbreviation?: InputMaybe<CompanyOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingCompanyIdFkeyUsingCompanyAbbreviationKeyUpdate>;
  updateByCompanyId?: InputMaybe<CompanyOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingCompanyIdFkeyUsingCompanyPkeyUpdate>;
  updateByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingCompanyIdFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingCompanyIdFkeyNodeIdUpdate>;
}

export interface PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterWeightingNodeIdConnect>>;
  connectByPerformanceReviewMasterWeightingId?: InputMaybe<Array<PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingPkeyConnect>>;
  connectByYearAndQuarterAndCompanyId?: InputMaybe<Array<PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingYearQuarterCompanyIdKeyConnect>>;
  connectByYearAndQuarterAndName?: InputMaybe<Array<PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingYearQuarterNameKeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewMasterWeightingCompanyIdFkeyPerformanceReviewMasterWeightingCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterWeightingNodeIdDelete>>;
  deleteByPerformanceReviewMasterWeightingId?: InputMaybe<Array<PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingPkeyDelete>>;
  deleteByYearAndQuarterAndCompanyId?: InputMaybe<Array<PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingYearQuarterCompanyIdKeyDelete>>;
  deleteByYearAndQuarterAndName?: InputMaybe<Array<PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingYearQuarterNameKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<CompanyOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingCompanyIdFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterWeightingId?: InputMaybe<
    Array<PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingCompanyIdFkeyUsingPerformanceReviewMasterWeightingPkeyUpdate>
  >;
  updateByYearAndQuarterAndCompanyId?: InputMaybe<
    Array<PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingCompanyIdFkeyUsingPerformanceReviewMasterWeightingYearQuarterCompanyIdKeyUpdate>
  >;
  updateByYearAndQuarterAndName?: InputMaybe<
    Array<PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingCompanyIdFkeyUsingPerformanceReviewMasterWeightingYearQuarterNameKeyUpdate>
  >;
}

export interface PerformanceReviewMasterWeightingCompanyIdFkeyPerformanceReviewMasterWeightingCreateInput {
  company?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInput>;
  name: Scalars['String']['input'];
  performanceReviewMasterWeightingDetails?: InputMaybe<PerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyInverseInput>;
  performanceReviewMasterWeightingId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterWeightingCondition {
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterWeightingId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyEmployeeLevelCreateInput {
  canEditTargetCountry?: InputMaybe<Scalars['Boolean']['input']>;
  canEditTargetIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  canEditTargetTeam?: InputMaybe<Scalars['Boolean']['input']>;
  canSeePerformanceReviewOverallOfAll?: InputMaybe<Scalars['Boolean']['input']>;
  canSeePerformanceReviewOverallOfCountry?: InputMaybe<Scalars['Boolean']['input']>;
  canSeeProbationReviewOfAll?: InputMaybe<Scalars['Boolean']['input']>;
  canSeeProbationReviewOfCountry?: InputMaybe<Scalars['Boolean']['input']>;
  displayName: Scalars['String']['input'];
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey3InverseInput>;
  employeeLevelOrder: Scalars['Int']['input'];
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInverseInput>;
  performanceReviewMasterWeightingDetails?: InputMaybe<PerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  titleContributor?: InputMaybe<Scalars['String']['input']>;
  titleManager?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeLevelUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyInput {
  connectByDisplayName?: InputMaybe<EmployeeLevelEmployeeLevelDisplayNameKeyConnect>;
  connectByEmployeeLevelId?: InputMaybe<EmployeeLevelEmployeeLevelPkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeLevelNodeIdConnect>;
  create?: InputMaybe<PerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyEmployeeLevelCreateInput>;
  deleteByDisplayName?: InputMaybe<EmployeeLevelEmployeeLevelDisplayNameKeyDelete>;
  deleteByEmployeeLevelId?: InputMaybe<EmployeeLevelEmployeeLevelPkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeLevelNodeIdDelete>;
  updateByDisplayName?: InputMaybe<EmployeeLevelOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyUsingEmployeeLevelDisplayNameKeyUpdate>;
  updateByEmployeeLevelId?: InputMaybe<EmployeeLevelOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyUsingEmployeeLevelPkeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyNodeIdUpdate>;
}

export interface PerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailNodeIdConnect>>;
  connectByPerformanceReviewMasterWeightingDetailId?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailPerformanceReviewMasterWeightingDetailPkeyConnect>>;
  connectByPerformanceReviewMasterWeightingIdAndEmployeeLevelId?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailPerformanceReviewMasterWeiPerformanceReviewMasterWeiKeyConnect>>;
  create?: InputMaybe<Array<PerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyPerformanceReviewMasterWeightingDetailCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailNodeIdDelete>>;
  deleteByPerformanceReviewMasterWeightingDetailId?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailPerformanceReviewMasterWeightingDetailPkeyDelete>>;
  deleteByPerformanceReviewMasterWeightingIdAndEmployeeLevelId?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailPerformanceReviewMasterWeiPerformanceReviewMasterWeiKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<EmployeeLevelOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterWeightingDetailId?: InputMaybe<
    Array<PerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyUsingPerformanceReviewMasterWeightingDetailPkeyUpdate>
  >;
  updateByPerformanceReviewMasterWeightingIdAndEmployeeLevelId?: InputMaybe<
    Array<PerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyUsingPerformanceReviewMasterWeiPerformanceReviewMasterWeiKeyUpdate>
  >;
}

export interface PerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyPerformanceReviewMasterWeightingDetailCreateInput {
  employeeLevel?: InputMaybe<PerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyInput>;
  percentageCompetency: Scalars['Int']['input'];
  percentageMultiSource: Scalars['Int']['input'];
  percentageTarget: Scalars['Int']['input'];
  percentageValue: Scalars['Int']['input'];
  performanceReviewMasterWeighting?: InputMaybe<PerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyInput>;
  performanceReviewMasterWeightingDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterWeightingId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterWeightingDetail extends Node {
  __typename?: 'PerformanceReviewMasterWeightingDetail';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  employeeLevel: EmployeeLevel;
  employeeLevelId: Scalars['BigInt']['output'];
  nodeId: Scalars['ID']['output'];
  percentageCompetency: Scalars['Int']['output'];
  percentageMultiSource: Scalars['Int']['output'];
  percentageTarget: Scalars['Int']['output'];
  percentageValue: Scalars['Int']['output'];
  performanceReviewMasterWeighting: PerformanceReviewMasterWeighting;
  performanceReviewMasterWeightingDetailId: Scalars['BigInt']['output'];
  performanceReviewMasterWeightingId: Scalars['BigInt']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface PerformanceReviewMasterWeightingDetailCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  percentageCompetency?: InputMaybe<Scalars['Int']['input']>;
  percentageMultiSource?: InputMaybe<Scalars['Int']['input']>;
  percentageTarget?: InputMaybe<Scalars['Int']['input']>;
  percentageValue?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterWeightingDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterWeightingId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterWeightingDetailFilter {
  and?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  employeeLevel?: InputMaybe<EmployeeLevelFilter>;
  employeeLevelId?: InputMaybe<BigIntFilter>;
  not?: InputMaybe<PerformanceReviewMasterWeightingDetailFilter>;
  or?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailFilter>>;
  percentageCompetency?: InputMaybe<IntFilter>;
  percentageMultiSource?: InputMaybe<IntFilter>;
  percentageTarget?: InputMaybe<IntFilter>;
  percentageValue?: InputMaybe<IntFilter>;
  performanceReviewMasterWeighting?: InputMaybe<PerformanceReviewMasterWeightingFilter>;
  performanceReviewMasterWeightingDetailId?: InputMaybe<BigIntFilter>;
  performanceReviewMasterWeightingId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface PerformanceReviewMasterWeightingDetailInput {
  employeeLevel?: InputMaybe<PerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  percentageCompetency: Scalars['Int']['input'];
  percentageMultiSource: Scalars['Int']['input'];
  percentageTarget: Scalars['Int']['input'];
  percentageValue: Scalars['Int']['input'];
  performanceReviewMasterWeighting?: InputMaybe<PerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyInput>;
  performanceReviewMasterWeightingDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterWeightingId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterWeightingDetailNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterWeightingDetailNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterWeightingPatch;
}

export interface PerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyUsingPerformanceReviewMasterWeiPerformanceReviewMasterWeiKeyUpdate {
  employeeLevelId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyPatch;
  performanceReviewMasterWeightingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyUsingPerformanceReviewMasterWeightingDetailPkeyUpdate {
  patch: UpdatePerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyPatch;
  performanceReviewMasterWeightingDetailId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeLevelPatch;
}

export interface PerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyUsingPerformanceReviewMasterWeiPerformanceReviewMasterWeiKeyUpdate {
  employeeLevelId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyPatch;
  performanceReviewMasterWeightingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyUsingPerformanceReviewMasterWeightingDetailPkeyUpdate {
  patch: UpdatePerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyPatch;
  performanceReviewMasterWeightingDetailId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserCreatedFkeyUsingPerformanceReviewMasterWeiPerformanceReviewMasterWeiKeyUpdate {
  employeeLevelId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserCreatedFkeyPatch;
  performanceReviewMasterWeightingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserCreatedFkeyUsingPerformanceReviewMasterWeightingDetailPkeyUpdate {
  patch: UpdatePerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserCreatedFkeyPatch;
  performanceReviewMasterWeightingDetailId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserUpdatedFkeyUsingPerformanceReviewMasterWeiPerformanceReviewMasterWeiKeyUpdate {
  employeeLevelId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserUpdatedFkeyPatch;
  performanceReviewMasterWeightingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserUpdatedFkeyUsingPerformanceReviewMasterWeightingDetailPkeyUpdate {
  patch: UpdatePerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserUpdatedFkeyPatch;
  performanceReviewMasterWeightingDetailId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterWeightingDetailPatch {
  employeeLevel?: InputMaybe<PerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  percentageCompetency?: InputMaybe<Scalars['Int']['input']>;
  percentageMultiSource?: InputMaybe<Scalars['Int']['input']>;
  percentageTarget?: InputMaybe<Scalars['Int']['input']>;
  percentageValue?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterWeighting?: InputMaybe<PerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyInput>;
  performanceReviewMasterWeightingDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterWeightingId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PerformanceReviewMasterWeightingDetailPerformanceReviewMasterWeiPerformanceReviewMasterWeiKeyConnect {
  employeeLevelId: Scalars['BigInt']['input'];
  performanceReviewMasterWeightingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterWeightingDetailPerformanceReviewMasterWeiPerformanceReviewMasterWeiKeyDelete {
  employeeLevelId: Scalars['BigInt']['input'];
  performanceReviewMasterWeightingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterWeightingDetailPerformanceReviewMasterWeightingDetailPkeyConnect {
  performanceReviewMasterWeightingDetailId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterWeightingDetailPerformanceReviewMasterWeightingDetailPkeyDelete {
  performanceReviewMasterWeightingDetailId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailNodeIdConnect>>;
  connectByPerformanceReviewMasterWeightingDetailId?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailPerformanceReviewMasterWeightingDetailPkeyConnect>>;
  connectByPerformanceReviewMasterWeightingIdAndEmployeeLevelId?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailPerformanceReviewMasterWeiPerformanceReviewMasterWeiKeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailNodeIdDelete>>;
  deleteByPerformanceReviewMasterWeightingDetailId?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailPerformanceReviewMasterWeightingDetailPkeyDelete>>;
  deleteByPerformanceReviewMasterWeightingIdAndEmployeeLevelId?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailPerformanceReviewMasterWeiPerformanceReviewMasterWeiKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterWeightingDetailId?: InputMaybe<
    Array<PerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserCreatedFkeyUsingPerformanceReviewMasterWeightingDetailPkeyUpdate>
  >;
  updateByPerformanceReviewMasterWeightingIdAndEmployeeLevelId?: InputMaybe<
    Array<PerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserCreatedFkeyUsingPerformanceReviewMasterWeiPerformanceReviewMasterWeiKeyUpdate>
  >;
}

export interface PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailNodeIdConnect>>;
  connectByPerformanceReviewMasterWeightingDetailId?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailPerformanceReviewMasterWeightingDetailPkeyConnect>>;
  connectByPerformanceReviewMasterWeightingIdAndEmployeeLevelId?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailPerformanceReviewMasterWeiPerformanceReviewMasterWeiKeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailNodeIdDelete>>;
  deleteByPerformanceReviewMasterWeightingDetailId?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailPerformanceReviewMasterWeightingDetailPkeyDelete>>;
  deleteByPerformanceReviewMasterWeightingIdAndEmployeeLevelId?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailPerformanceReviewMasterWeiPerformanceReviewMasterWeiKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterWeightingDetailId?: InputMaybe<
    Array<PerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserUpdatedFkeyUsingPerformanceReviewMasterWeightingDetailPkeyUpdate>
  >;
  updateByPerformanceReviewMasterWeightingIdAndEmployeeLevelId?: InputMaybe<
    Array<PerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserUpdatedFkeyUsingPerformanceReviewMasterWeiPerformanceReviewMasterWeiKeyUpdate>
  >;
}

export interface PerformanceReviewMasterWeightingDetailsConnection {
  __typename?: 'PerformanceReviewMasterWeightingDetailsConnection';
  edges: Array<PerformanceReviewMasterWeightingDetailsEdge>;
  nodes: Array<PerformanceReviewMasterWeightingDetail>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterWeightingDetailsEdge {
  __typename?: 'PerformanceReviewMasterWeightingDetailsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewMasterWeightingDetail;
}

export enum PerformanceReviewMasterWeightingDetailsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_COUNTRY_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_COUNTRY_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_COUNTRY_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_COUNTRY_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_INDIVIDUAL_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_INDIVIDUAL_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_INDIVIDUAL_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_INDIVIDUAL_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_TEAM_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_TEAM_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_TEAM_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_EDIT_TARGET_TEAM_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PROBATION_REVIEW_OF_ALL_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PROBATION_REVIEW_OF_ALL_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PROBATION_REVIEW_OF_ALL_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PROBATION_REVIEW_OF_ALL_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DATE_CREATED_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DATE_CREATED_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DATE_CREATED_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DATE_CREATED_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DATE_UPDATED_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DATE_UPDATED_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DATE_UPDATED_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DATE_UPDATED_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DISPLAY_NAME_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DISPLAY_NAME_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DISPLAY_NAME_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__DISPLAY_NAME_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__EMPLOYEE_LEVEL_ORDER_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__EMPLOYEE_LEVEL_ORDER_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__EMPLOYEE_LEVEL_ORDER_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__EMPLOYEE_LEVEL_ORDER_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__REMARK_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__REMARK_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__REMARK_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__REMARK_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__TITLE_CONTRIBUTOR_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__TITLE_CONTRIBUTOR_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__TITLE_CONTRIBUTOR_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__TITLE_CONTRIBUTOR_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__TITLE_MANAGER_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__TITLE_MANAGER_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__TITLE_MANAGER_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__TITLE_MANAGER_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__USER_CREATED_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__USER_CREATED_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__USER_CREATED_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__USER_CREATED_DESC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__USER_UPDATED_ASC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__USER_UPDATED_ASC',
  EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__USER_UPDATED_DESC = 'EMPLOYEE_LEVEL_BY_EMPLOYEE_LEVEL_ID__USER_UPDATED_DESC',
  EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_LEVEL_ID_DESC',
  NATURAL = 'NATURAL',
  PERCENTAGE_COMPETENCY_ASC = 'PERCENTAGE_COMPETENCY_ASC',
  PERCENTAGE_COMPETENCY_DESC = 'PERCENTAGE_COMPETENCY_DESC',
  PERCENTAGE_MULTI_SOURCE_ASC = 'PERCENTAGE_MULTI_SOURCE_ASC',
  PERCENTAGE_MULTI_SOURCE_DESC = 'PERCENTAGE_MULTI_SOURCE_DESC',
  PERCENTAGE_TARGET_ASC = 'PERCENTAGE_TARGET_ASC',
  PERCENTAGE_TARGET_DESC = 'PERCENTAGE_TARGET_DESC',
  PERCENTAGE_VALUE_ASC = 'PERCENTAGE_VALUE_ASC',
  PERCENTAGE_VALUE_DESC = 'PERCENTAGE_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__COMPANY_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__COMPANY_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__COMPANY_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__COMPANY_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__NAME_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__NAME_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__NAME_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__NAME_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface PerformanceReviewMasterWeightingFilter {
  and?: InputMaybe<Array<PerformanceReviewMasterWeightingFilter>>;
  company?: InputMaybe<CompanyFilter>;
  companyExists?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  name?: InputMaybe<StringFilter>;
  not?: InputMaybe<PerformanceReviewMasterWeightingFilter>;
  or?: InputMaybe<Array<PerformanceReviewMasterWeightingFilter>>;
  performanceReviewMasterWeightingDetails?: InputMaybe<PerformanceReviewMasterWeightingToManyPerformanceReviewMasterWeightingDetailFilter>;
  performanceReviewMasterWeightingDetailsExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterWeightingId?: InputMaybe<BigIntFilter>;
  quarter?: InputMaybe<PerformanceReviewQuarterFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
  year?: InputMaybe<IntFilter>;
}

export interface PerformanceReviewMasterWeightingInput {
  company?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  name: Scalars['String']['input'];
  performanceReviewMasterWeightingDetails?: InputMaybe<PerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyInverseInput>;
  performanceReviewMasterWeightingId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterWeightingNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterWeightingNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterWeightingDetailPatch;
}

export interface PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyUsingPerformanceReviewMasterWeightingPkeyUpdate {
  patch: UpdatePerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyPatch;
  performanceReviewMasterWeightingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyUsingPerformanceReviewMasterWeightingYearQuarterCompanyIdKeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyPatch;
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyUsingPerformanceReviewMasterWeightingYearQuarterNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdatePerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyPatch;
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingCompanyIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyPatch;
}

export interface PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingCompanyIdFkeyUsingPerformanceReviewMasterWeightingPkeyUpdate {
  patch: UpdatePerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingCompanyIdFkeyPatch;
  performanceReviewMasterWeightingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingCompanyIdFkeyUsingPerformanceReviewMasterWeightingYearQuarterCompanyIdKeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingCompanyIdFkeyPatch;
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingCompanyIdFkeyUsingPerformanceReviewMasterWeightingYearQuarterNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdatePerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingCompanyIdFkeyPatch;
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserCreatedFkeyUsingPerformanceReviewMasterWeightingPkeyUpdate {
  patch: UpdatePerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserCreatedFkeyPatch;
  performanceReviewMasterWeightingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserCreatedFkeyUsingPerformanceReviewMasterWeightingYearQuarterCompanyIdKeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserCreatedFkeyPatch;
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserCreatedFkeyUsingPerformanceReviewMasterWeightingYearQuarterNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdatePerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserCreatedFkeyPatch;
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserUpdatedFkeyUsingPerformanceReviewMasterWeightingPkeyUpdate {
  patch: UpdatePerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserUpdatedFkeyPatch;
  performanceReviewMasterWeightingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserUpdatedFkeyUsingPerformanceReviewMasterWeightingYearQuarterCompanyIdKeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdatePerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserUpdatedFkeyPatch;
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserUpdatedFkeyUsingPerformanceReviewMasterWeightingYearQuarterNameKeyUpdate {
  name: Scalars['String']['input'];
  patch: UpdatePerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserUpdatedFkeyPatch;
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterWeightingPatch {
  company?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterWeightingDetails?: InputMaybe<PerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyInverseInput>;
  performanceReviewMasterWeightingId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingPkeyConnect {
  performanceReviewMasterWeightingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingPkeyDelete {
  performanceReviewMasterWeightingId: Scalars['BigInt']['input'];
}

export interface PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingYearQuarterCompanyIdKeyConnect {
  companyId: Scalars['BigInt']['input'];
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingYearQuarterCompanyIdKeyDelete {
  companyId: Scalars['BigInt']['input'];
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingYearQuarterNameKeyConnect {
  name: Scalars['String']['input'];
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingYearQuarterNameKeyDelete {
  name: Scalars['String']['input'];
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface PerformanceReviewMasterWeightingToManyPerformanceReviewMasterWeightingDetailFilter {
  every?: InputMaybe<PerformanceReviewMasterWeightingDetailFilter>;
  none?: InputMaybe<PerformanceReviewMasterWeightingDetailFilter>;
  some?: InputMaybe<PerformanceReviewMasterWeightingDetailFilter>;
}

export interface PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterWeightingNodeIdConnect>>;
  connectByPerformanceReviewMasterWeightingId?: InputMaybe<Array<PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingPkeyConnect>>;
  connectByYearAndQuarterAndCompanyId?: InputMaybe<Array<PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingYearQuarterCompanyIdKeyConnect>>;
  connectByYearAndQuarterAndName?: InputMaybe<Array<PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingYearQuarterNameKeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterWeightingNodeIdDelete>>;
  deleteByPerformanceReviewMasterWeightingId?: InputMaybe<Array<PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingPkeyDelete>>;
  deleteByYearAndQuarterAndCompanyId?: InputMaybe<Array<PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingYearQuarterCompanyIdKeyDelete>>;
  deleteByYearAndQuarterAndName?: InputMaybe<Array<PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingYearQuarterNameKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserCreatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterWeightingId?: InputMaybe<
    Array<PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserCreatedFkeyUsingPerformanceReviewMasterWeightingPkeyUpdate>
  >;
  updateByYearAndQuarterAndCompanyId?: InputMaybe<
    Array<PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserCreatedFkeyUsingPerformanceReviewMasterWeightingYearQuarterCompanyIdKeyUpdate>
  >;
  updateByYearAndQuarterAndName?: InputMaybe<
    Array<PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserCreatedFkeyUsingPerformanceReviewMasterWeightingYearQuarterNameKeyUpdate>
  >;
}

export interface PerformanceReviewMasterWeightingUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<PerformanceReviewMasterWeightingNodeIdConnect>>;
  connectByPerformanceReviewMasterWeightingId?: InputMaybe<Array<PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingPkeyConnect>>;
  connectByYearAndQuarterAndCompanyId?: InputMaybe<Array<PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingYearQuarterCompanyIdKeyConnect>>;
  connectByYearAndQuarterAndName?: InputMaybe<Array<PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingYearQuarterNameKeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<PerformanceReviewMasterWeightingNodeIdDelete>>;
  deleteByPerformanceReviewMasterWeightingId?: InputMaybe<Array<PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingPkeyDelete>>;
  deleteByYearAndQuarterAndCompanyId?: InputMaybe<Array<PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingYearQuarterCompanyIdKeyDelete>>;
  deleteByYearAndQuarterAndName?: InputMaybe<Array<PerformanceReviewMasterWeightingPerformanceReviewMasterWeightingYearQuarterNameKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserUpdatedFkeyNodeIdUpdate>>;
  updateByPerformanceReviewMasterWeightingId?: InputMaybe<
    Array<PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserUpdatedFkeyUsingPerformanceReviewMasterWeightingPkeyUpdate>
  >;
  updateByYearAndQuarterAndCompanyId?: InputMaybe<
    Array<PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserUpdatedFkeyUsingPerformanceReviewMasterWeightingYearQuarterCompanyIdKeyUpdate>
  >;
  updateByYearAndQuarterAndName?: InputMaybe<
    Array<PerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserUpdatedFkeyUsingPerformanceReviewMasterWeightingYearQuarterNameKeyUpdate>
  >;
}

export interface PerformanceReviewMasterWeightingsConnection {
  __typename?: 'PerformanceReviewMasterWeightingsConnection';
  edges: Array<PerformanceReviewMasterWeightingsEdge>;
  nodes: Array<PerformanceReviewMasterWeighting>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewMasterWeightingsEdge {
  __typename?: 'PerformanceReviewMasterWeightingsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewMasterWeighting;
}

export enum PerformanceReviewMasterWeightingsOrderBy {
  COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC',
  COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_ASC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_ASC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_DESC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_DESC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANY_BY_COMPANY_ID__REMARK_ASC = 'COMPANY_BY_COMPANY_ID__REMARK_ASC',
  COMPANY_BY_COMPANY_ID__REMARK_DESC = 'COMPANY_BY_COMPANY_ID__REMARK_DESC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC',
  COMPANY_ID_ASC = 'COMPANY_ID_ASC',
  COMPANY_ID_DESC = 'COMPANY_ID_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_EMPLOYEE_LEVEL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_EMPLOYEE_LEVEL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_EMPLOYEE_LEVEL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_EMPLOYEE_LEVEL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_PERCENTAGE_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_PERCENTAGE_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_PERCENTAGE_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_PERCENTAGE_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_PERCENTAGE_MULTI_SOURCE_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_PERCENTAGE_MULTI_SOURCE_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_PERCENTAGE_MULTI_SOURCE_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_PERCENTAGE_MULTI_SOURCE_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_PERCENTAGE_TARGET_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_PERCENTAGE_TARGET_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_PERCENTAGE_TARGET_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_PERCENTAGE_TARGET_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_PERCENTAGE_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_PERCENTAGE_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_PERCENTAGE_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_PERCENTAGE_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_EMPLOYEE_LEVEL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_EMPLOYEE_LEVEL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_EMPLOYEE_LEVEL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_EMPLOYEE_LEVEL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_PERCENTAGE_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_PERCENTAGE_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_PERCENTAGE_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_PERCENTAGE_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_PERCENTAGE_MULTI_SOURCE_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_PERCENTAGE_MULTI_SOURCE_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_PERCENTAGE_MULTI_SOURCE_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_PERCENTAGE_MULTI_SOURCE_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_PERCENTAGE_TARGET_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_PERCENTAGE_TARGET_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_PERCENTAGE_TARGET_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_PERCENTAGE_TARGET_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_PERCENTAGE_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_PERCENTAGE_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_PERCENTAGE_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_PERCENTAGE_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  QUARTER_ASC = 'QUARTER_ASC',
  QUARTER_DESC = 'QUARTER_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
}

export enum PerformanceReviewQuarter {
  Q1_Q2 = 'Q1_Q2',
  Q3_Q4 = 'Q3_Q4',
}

export interface PerformanceReviewQuarterFilter {
  distinctFrom?: InputMaybe<PerformanceReviewQuarter>;
  equalTo?: InputMaybe<PerformanceReviewQuarter>;
  greaterThan?: InputMaybe<PerformanceReviewQuarter>;
  greaterThanOrEqualTo?: InputMaybe<PerformanceReviewQuarter>;
  in?: InputMaybe<Array<PerformanceReviewQuarter>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<PerformanceReviewQuarter>;
  lessThanOrEqualTo?: InputMaybe<PerformanceReviewQuarter>;
  notDistinctFrom?: InputMaybe<PerformanceReviewQuarter>;
  notEqualTo?: InputMaybe<PerformanceReviewQuarter>;
  notIn?: InputMaybe<Array<PerformanceReviewQuarter>>;
}

export interface PerformanceReviewResult {
  __typename?: 'PerformanceReviewResult';
  approverFullName?: Maybe<Scalars['String']['output']>;
  approverNickname?: Maybe<Scalars['String']['output']>;
  businessUnitId?: Maybe<Scalars['BigInt']['output']>;
  businessUnitName?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['BigInt']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  contractType?: Maybe<Scalars['String']['output']>;
  countryCodeAlpha2?: Maybe<Scalars['String']['output']>;
  departmentId?: Maybe<Scalars['BigInt']['output']>;
  departmentName?: Maybe<Scalars['String']['output']>;
  divisionId?: Maybe<Scalars['BigInt']['output']>;
  divisionName?: Maybe<Scalars['String']['output']>;
  dottedLineManagerFullName?: Maybe<Scalars['String']['output']>;
  dottedLineManagerNickname?: Maybe<Scalars['String']['output']>;
  effectiveDate?: Maybe<Scalars['Date']['output']>;
  effectiveDateUntil?: Maybe<Scalars['Date']['output']>;
  employedDays?: Maybe<Scalars['Int']['output']>;
  employeeId?: Maybe<Scalars['BigInt']['output']>;
  employeeLevel?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  joinDate?: Maybe<Scalars['Date']['output']>;
  lastWorkingDate?: Maybe<Scalars['Date']['output']>;
  needInputCompetency?: Maybe<Scalars['Boolean']['output']>;
  needInputTargetKpi?: Maybe<Scalars['Boolean']['output']>;
  needInputTargetQualitative?: Maybe<Scalars['Boolean']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  officeName?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluationId?: Maybe<Scalars['BigInt']['output']>;
  primaryManagerFullName?: Maybe<Scalars['String']['output']>;
  primaryManagerNickname?: Maybe<Scalars['String']['output']>;
  probationEndDate?: Maybe<Scalars['Date']['output']>;
  quarter?: Maybe<PerformanceReviewQuarter>;
  resignationDate?: Maybe<Scalars['Date']['output']>;
  scoreCompetency?: Maybe<Scalars['BigFloat']['output']>;
  scoreCompetencyWeight?: Maybe<Scalars['BigFloat']['output']>;
  scoreKpi?: Maybe<Scalars['BigFloat']['output']>;
  scoreKpiWeight?: Maybe<Scalars['BigFloat']['output']>;
  scoreOverall?: Maybe<Scalars['BigFloat']['output']>;
  scoreOverallWeight?: Maybe<Scalars['BigFloat']['output']>;
  scoreQualitative?: Maybe<Scalars['BigFloat']['output']>;
  scoreQualitativeWeight?: Maybe<Scalars['BigFloat']['output']>;
  scoreTargetTotal?: Maybe<Scalars['BigFloat']['output']>;
  scoreTargetTotalWeight?: Maybe<Scalars['BigFloat']['output']>;
  scoreValue?: Maybe<Scalars['BigFloat']['output']>;
  scoreValueWeight?: Maybe<Scalars['BigFloat']['output']>;
  templateId?: Maybe<Scalars['BigInt']['output']>;
  templateName?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
}

export interface PerformanceReviewResultCondition {
  approverFullName?: InputMaybe<Scalars['String']['input']>;
  approverNickname?: InputMaybe<Scalars['String']['input']>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  businessUnitName?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  contractType?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  departmentName?: InputMaybe<Scalars['String']['input']>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  divisionName?: InputMaybe<Scalars['String']['input']>;
  dottedLineManagerFullName?: InputMaybe<Scalars['String']['input']>;
  dottedLineManagerNickname?: InputMaybe<Scalars['String']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employedDays?: InputMaybe<Scalars['Int']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  officeName?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  primaryManagerFullName?: InputMaybe<Scalars['String']['input']>;
  primaryManagerNickname?: InputMaybe<Scalars['String']['input']>;
  probationEndDate?: InputMaybe<Scalars['Date']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  scoreCompetency?: InputMaybe<Scalars['BigFloat']['input']>;
  scoreCompetencyWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  scoreKpi?: InputMaybe<Scalars['BigFloat']['input']>;
  scoreKpiWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  scoreOverall?: InputMaybe<Scalars['BigFloat']['input']>;
  scoreOverallWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  scoreQualitative?: InputMaybe<Scalars['BigFloat']['input']>;
  scoreQualitativeWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  scoreTargetTotal?: InputMaybe<Scalars['BigFloat']['input']>;
  scoreTargetTotalWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  scoreValue?: InputMaybe<Scalars['BigFloat']['input']>;
  scoreValueWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  templateId?: InputMaybe<Scalars['BigInt']['input']>;
  templateName?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface PerformanceReviewResultFilter {
  and?: InputMaybe<Array<PerformanceReviewResultFilter>>;
  approverFullName?: InputMaybe<StringFilter>;
  approverNickname?: InputMaybe<StringFilter>;
  businessUnitId?: InputMaybe<BigIntFilter>;
  businessUnitName?: InputMaybe<StringFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  companyName?: InputMaybe<StringFilter>;
  contractType?: InputMaybe<StringFilter>;
  countryCodeAlpha2?: InputMaybe<StringFilter>;
  departmentId?: InputMaybe<BigIntFilter>;
  departmentName?: InputMaybe<StringFilter>;
  divisionId?: InputMaybe<BigIntFilter>;
  divisionName?: InputMaybe<StringFilter>;
  dottedLineManagerFullName?: InputMaybe<StringFilter>;
  dottedLineManagerNickname?: InputMaybe<StringFilter>;
  effectiveDate?: InputMaybe<DateFilter>;
  effectiveDateUntil?: InputMaybe<DateFilter>;
  employedDays?: InputMaybe<IntFilter>;
  employeeId?: InputMaybe<BigIntFilter>;
  employeeLevel?: InputMaybe<StringFilter>;
  fullName?: InputMaybe<StringFilter>;
  joinDate?: InputMaybe<DateFilter>;
  lastWorkingDate?: InputMaybe<DateFilter>;
  needInputCompetency?: InputMaybe<BooleanFilter>;
  needInputTargetKpi?: InputMaybe<BooleanFilter>;
  needInputTargetQualitative?: InputMaybe<BooleanFilter>;
  nickname?: InputMaybe<StringFilter>;
  not?: InputMaybe<PerformanceReviewResultFilter>;
  officeName?: InputMaybe<StringFilter>;
  or?: InputMaybe<Array<PerformanceReviewResultFilter>>;
  performanceReviewEvaluationId?: InputMaybe<BigIntFilter>;
  primaryManagerFullName?: InputMaybe<StringFilter>;
  primaryManagerNickname?: InputMaybe<StringFilter>;
  probationEndDate?: InputMaybe<DateFilter>;
  quarter?: InputMaybe<PerformanceReviewQuarterFilter>;
  resignationDate?: InputMaybe<DateFilter>;
  scoreCompetency?: InputMaybe<BigFloatFilter>;
  scoreCompetencyWeight?: InputMaybe<BigFloatFilter>;
  scoreKpi?: InputMaybe<BigFloatFilter>;
  scoreKpiWeight?: InputMaybe<BigFloatFilter>;
  scoreOverall?: InputMaybe<BigFloatFilter>;
  scoreOverallWeight?: InputMaybe<BigFloatFilter>;
  scoreQualitative?: InputMaybe<BigFloatFilter>;
  scoreQualitativeWeight?: InputMaybe<BigFloatFilter>;
  scoreTargetTotal?: InputMaybe<BigFloatFilter>;
  scoreTargetTotalWeight?: InputMaybe<BigFloatFilter>;
  scoreValue?: InputMaybe<BigFloatFilter>;
  scoreValueWeight?: InputMaybe<BigFloatFilter>;
  templateId?: InputMaybe<BigIntFilter>;
  templateName?: InputMaybe<StringFilter>;
  year?: InputMaybe<IntFilter>;
}

export interface PerformanceReviewResultsConnection {
  __typename?: 'PerformanceReviewResultsConnection';
  edges: Array<PerformanceReviewResultsEdge>;
  nodes: Array<PerformanceReviewResult>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PerformanceReviewResultsEdge {
  __typename?: 'PerformanceReviewResultsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PerformanceReviewResult;
}

export enum PerformanceReviewResultsOrderBy {
  APPROVER_FULL_NAME_ASC = 'APPROVER_FULL_NAME_ASC',
  APPROVER_FULL_NAME_DESC = 'APPROVER_FULL_NAME_DESC',
  APPROVER_NICKNAME_ASC = 'APPROVER_NICKNAME_ASC',
  APPROVER_NICKNAME_DESC = 'APPROVER_NICKNAME_DESC',
  BUSINESS_UNIT_ID_ASC = 'BUSINESS_UNIT_ID_ASC',
  BUSINESS_UNIT_ID_DESC = 'BUSINESS_UNIT_ID_DESC',
  BUSINESS_UNIT_NAME_ASC = 'BUSINESS_UNIT_NAME_ASC',
  BUSINESS_UNIT_NAME_DESC = 'BUSINESS_UNIT_NAME_DESC',
  COMPANY_ID_ASC = 'COMPANY_ID_ASC',
  COMPANY_ID_DESC = 'COMPANY_ID_DESC',
  COMPANY_NAME_ASC = 'COMPANY_NAME_ASC',
  COMPANY_NAME_DESC = 'COMPANY_NAME_DESC',
  CONTRACT_TYPE_ASC = 'CONTRACT_TYPE_ASC',
  CONTRACT_TYPE_DESC = 'CONTRACT_TYPE_DESC',
  COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_CODE_ALPHA2_DESC',
  DEPARTMENT_ID_ASC = 'DEPARTMENT_ID_ASC',
  DEPARTMENT_ID_DESC = 'DEPARTMENT_ID_DESC',
  DEPARTMENT_NAME_ASC = 'DEPARTMENT_NAME_ASC',
  DEPARTMENT_NAME_DESC = 'DEPARTMENT_NAME_DESC',
  DIVISION_ID_ASC = 'DIVISION_ID_ASC',
  DIVISION_ID_DESC = 'DIVISION_ID_DESC',
  DIVISION_NAME_ASC = 'DIVISION_NAME_ASC',
  DIVISION_NAME_DESC = 'DIVISION_NAME_DESC',
  DOTTED_LINE_MANAGER_FULL_NAME_ASC = 'DOTTED_LINE_MANAGER_FULL_NAME_ASC',
  DOTTED_LINE_MANAGER_FULL_NAME_DESC = 'DOTTED_LINE_MANAGER_FULL_NAME_DESC',
  DOTTED_LINE_MANAGER_NICKNAME_ASC = 'DOTTED_LINE_MANAGER_NICKNAME_ASC',
  DOTTED_LINE_MANAGER_NICKNAME_DESC = 'DOTTED_LINE_MANAGER_NICKNAME_DESC',
  EFFECTIVE_DATE_ASC = 'EFFECTIVE_DATE_ASC',
  EFFECTIVE_DATE_DESC = 'EFFECTIVE_DATE_DESC',
  EFFECTIVE_DATE_UNTIL_ASC = 'EFFECTIVE_DATE_UNTIL_ASC',
  EFFECTIVE_DATE_UNTIL_DESC = 'EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYED_DAYS_ASC = 'EMPLOYED_DAYS_ASC',
  EMPLOYED_DAYS_DESC = 'EMPLOYED_DAYS_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  EMPLOYEE_LEVEL_ASC = 'EMPLOYEE_LEVEL_ASC',
  EMPLOYEE_LEVEL_DESC = 'EMPLOYEE_LEVEL_DESC',
  FULL_NAME_ASC = 'FULL_NAME_ASC',
  FULL_NAME_DESC = 'FULL_NAME_DESC',
  JOIN_DATE_ASC = 'JOIN_DATE_ASC',
  JOIN_DATE_DESC = 'JOIN_DATE_DESC',
  LAST_WORKING_DATE_ASC = 'LAST_WORKING_DATE_ASC',
  LAST_WORKING_DATE_DESC = 'LAST_WORKING_DATE_DESC',
  NATURAL = 'NATURAL',
  NEED_INPUT_COMPETENCY_ASC = 'NEED_INPUT_COMPETENCY_ASC',
  NEED_INPUT_COMPETENCY_DESC = 'NEED_INPUT_COMPETENCY_DESC',
  NEED_INPUT_TARGET_KPI_ASC = 'NEED_INPUT_TARGET_KPI_ASC',
  NEED_INPUT_TARGET_KPI_DESC = 'NEED_INPUT_TARGET_KPI_DESC',
  NEED_INPUT_TARGET_QUALITATIVE_ASC = 'NEED_INPUT_TARGET_QUALITATIVE_ASC',
  NEED_INPUT_TARGET_QUALITATIVE_DESC = 'NEED_INPUT_TARGET_QUALITATIVE_DESC',
  NICKNAME_ASC = 'NICKNAME_ASC',
  NICKNAME_DESC = 'NICKNAME_DESC',
  OFFICE_NAME_ASC = 'OFFICE_NAME_ASC',
  OFFICE_NAME_DESC = 'OFFICE_NAME_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PRIMARY_MANAGER_FULL_NAME_ASC = 'PRIMARY_MANAGER_FULL_NAME_ASC',
  PRIMARY_MANAGER_FULL_NAME_DESC = 'PRIMARY_MANAGER_FULL_NAME_DESC',
  PRIMARY_MANAGER_NICKNAME_ASC = 'PRIMARY_MANAGER_NICKNAME_ASC',
  PRIMARY_MANAGER_NICKNAME_DESC = 'PRIMARY_MANAGER_NICKNAME_DESC',
  PROBATION_END_DATE_ASC = 'PROBATION_END_DATE_ASC',
  PROBATION_END_DATE_DESC = 'PROBATION_END_DATE_DESC',
  QUARTER_ASC = 'QUARTER_ASC',
  QUARTER_DESC = 'QUARTER_DESC',
  RESIGNATION_DATE_ASC = 'RESIGNATION_DATE_ASC',
  RESIGNATION_DATE_DESC = 'RESIGNATION_DATE_DESC',
  SCORE_COMPETENCY_ASC = 'SCORE_COMPETENCY_ASC',
  SCORE_COMPETENCY_DESC = 'SCORE_COMPETENCY_DESC',
  SCORE_COMPETENCY_WEIGHT_ASC = 'SCORE_COMPETENCY_WEIGHT_ASC',
  SCORE_COMPETENCY_WEIGHT_DESC = 'SCORE_COMPETENCY_WEIGHT_DESC',
  SCORE_KPI_ASC = 'SCORE_KPI_ASC',
  SCORE_KPI_DESC = 'SCORE_KPI_DESC',
  SCORE_KPI_WEIGHT_ASC = 'SCORE_KPI_WEIGHT_ASC',
  SCORE_KPI_WEIGHT_DESC = 'SCORE_KPI_WEIGHT_DESC',
  SCORE_OVERALL_ASC = 'SCORE_OVERALL_ASC',
  SCORE_OVERALL_DESC = 'SCORE_OVERALL_DESC',
  SCORE_OVERALL_WEIGHT_ASC = 'SCORE_OVERALL_WEIGHT_ASC',
  SCORE_OVERALL_WEIGHT_DESC = 'SCORE_OVERALL_WEIGHT_DESC',
  SCORE_QUALITATIVE_ASC = 'SCORE_QUALITATIVE_ASC',
  SCORE_QUALITATIVE_DESC = 'SCORE_QUALITATIVE_DESC',
  SCORE_QUALITATIVE_WEIGHT_ASC = 'SCORE_QUALITATIVE_WEIGHT_ASC',
  SCORE_QUALITATIVE_WEIGHT_DESC = 'SCORE_QUALITATIVE_WEIGHT_DESC',
  SCORE_TARGET_TOTAL_ASC = 'SCORE_TARGET_TOTAL_ASC',
  SCORE_TARGET_TOTAL_DESC = 'SCORE_TARGET_TOTAL_DESC',
  SCORE_TARGET_TOTAL_WEIGHT_ASC = 'SCORE_TARGET_TOTAL_WEIGHT_ASC',
  SCORE_TARGET_TOTAL_WEIGHT_DESC = 'SCORE_TARGET_TOTAL_WEIGHT_DESC',
  SCORE_VALUE_ASC = 'SCORE_VALUE_ASC',
  SCORE_VALUE_DESC = 'SCORE_VALUE_DESC',
  SCORE_VALUE_WEIGHT_ASC = 'SCORE_VALUE_WEIGHT_ASC',
  SCORE_VALUE_WEIGHT_DESC = 'SCORE_VALUE_WEIGHT_DESC',
  TEMPLATE_ID_ASC = 'TEMPLATE_ID_ASC',
  TEMPLATE_ID_DESC = 'TEMPLATE_ID_DESC',
  TEMPLATE_NAME_ASC = 'TEMPLATE_NAME_ASC',
  TEMPLATE_NAME_DESC = 'TEMPLATE_NAME_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
}

export interface PersonalIdentityType extends Node {
  __typename?: 'PersonalIdentityType';
  code: Scalars['String']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  nodeId: Scalars['ID']['output'];
  personalIdentityTypeId: Scalars['BigInt']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface PersonalIdentityTypeCondition {
  code?: InputMaybe<Scalars['String']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  personalIdentityTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PersonalIdentityTypeFilter {
  and?: InputMaybe<Array<PersonalIdentityTypeFilter>>;
  code?: InputMaybe<StringFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  not?: InputMaybe<PersonalIdentityTypeFilter>;
  or?: InputMaybe<Array<PersonalIdentityTypeFilter>>;
  personalIdentityTypeId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface PersonalIdentityTypeNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface PersonalIdentityTypeOnPersonalIdentityTypeForPersonalIdentityTypeUserCreatedFkeyUsingPersonalIdentityTypeCodeKeyUpdate {
  code: Scalars['String']['input'];
  patch: UpdatePersonalIdentityTypeOnPersonalIdentityTypeForPersonalIdentityTypeUserCreatedFkeyPatch;
}

export interface PersonalIdentityTypeOnPersonalIdentityTypeForPersonalIdentityTypeUserCreatedFkeyUsingPersonalIdentityTypePkeyUpdate {
  patch: UpdatePersonalIdentityTypeOnPersonalIdentityTypeForPersonalIdentityTypeUserCreatedFkeyPatch;
  personalIdentityTypeId: Scalars['BigInt']['input'];
}

export interface PersonalIdentityTypeOnPersonalIdentityTypeForPersonalIdentityTypeUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface PersonalIdentityTypeOnPersonalIdentityTypeForPersonalIdentityTypeUserUpdatedFkeyUsingPersonalIdentityTypeCodeKeyUpdate {
  code: Scalars['String']['input'];
  patch: UpdatePersonalIdentityTypeOnPersonalIdentityTypeForPersonalIdentityTypeUserUpdatedFkeyPatch;
}

export interface PersonalIdentityTypeOnPersonalIdentityTypeForPersonalIdentityTypeUserUpdatedFkeyUsingPersonalIdentityTypePkeyUpdate {
  patch: UpdatePersonalIdentityTypeOnPersonalIdentityTypeForPersonalIdentityTypeUserUpdatedFkeyPatch;
  personalIdentityTypeId: Scalars['BigInt']['input'];
}

export interface PersonalIdentityTypePatch {
  code?: InputMaybe<Scalars['String']['input']>;
  personalIdentityTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface PersonalIdentityTypePersonalIdentityTypeCodeKeyConnect {
  code: Scalars['String']['input'];
}

export interface PersonalIdentityTypePersonalIdentityTypePkeyConnect {
  personalIdentityTypeId: Scalars['BigInt']['input'];
}

export interface PersonalIdentityTypeUserCreatedFkeyInverseInput {
  connectByCode?: InputMaybe<Array<PersonalIdentityTypePersonalIdentityTypeCodeKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<PersonalIdentityTypeNodeIdConnect>>;
  connectByPersonalIdentityTypeId?: InputMaybe<Array<PersonalIdentityTypePersonalIdentityTypePkeyConnect>>;
  updateByCode?: InputMaybe<Array<PersonalIdentityTypeOnPersonalIdentityTypeForPersonalIdentityTypeUserCreatedFkeyUsingPersonalIdentityTypeCodeKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPersonalIdentityTypeForPersonalIdentityTypeUserCreatedFkeyNodeIdUpdate>>;
  updateByPersonalIdentityTypeId?: InputMaybe<Array<PersonalIdentityTypeOnPersonalIdentityTypeForPersonalIdentityTypeUserCreatedFkeyUsingPersonalIdentityTypePkeyUpdate>>;
}

export interface PersonalIdentityTypeUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnPersonalIdentityTypeForPersonalIdentityTypeUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<PersonalIdentityTypeOnPersonalIdentityTypeForPersonalIdentityTypeUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnPersonalIdentityTypeForPersonalIdentityTypeUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface PersonalIdentityTypeUserUpdatedFkeyInverseInput {
  connectByCode?: InputMaybe<Array<PersonalIdentityTypePersonalIdentityTypeCodeKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<PersonalIdentityTypeNodeIdConnect>>;
  connectByPersonalIdentityTypeId?: InputMaybe<Array<PersonalIdentityTypePersonalIdentityTypePkeyConnect>>;
  updateByCode?: InputMaybe<Array<PersonalIdentityTypeOnPersonalIdentityTypeForPersonalIdentityTypeUserUpdatedFkeyUsingPersonalIdentityTypeCodeKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnPersonalIdentityTypeForPersonalIdentityTypeUserUpdatedFkeyNodeIdUpdate>>;
  updateByPersonalIdentityTypeId?: InputMaybe<Array<PersonalIdentityTypeOnPersonalIdentityTypeForPersonalIdentityTypeUserUpdatedFkeyUsingPersonalIdentityTypePkeyUpdate>>;
}

export interface PersonalIdentityTypesConnection {
  __typename?: 'PersonalIdentityTypesConnection';
  edges: Array<PersonalIdentityTypesEdge>;
  nodes: Array<PersonalIdentityType>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface PersonalIdentityTypesEdge {
  __typename?: 'PersonalIdentityTypesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: PersonalIdentityType;
}

export enum PersonalIdentityTypesOrderBy {
  CODE_ASC = 'CODE_ASC',
  CODE_DESC = 'CODE_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  NATURAL = 'NATURAL',
  PERSONAL_IDENTITY_TYPE_ID_ASC = 'PERSONAL_IDENTITY_TYPE_ID_ASC',
  PERSONAL_IDENTITY_TYPE_ID_DESC = 'PERSONAL_IDENTITY_TYPE_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface Point {
  __typename?: 'Point';
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
}

export interface PointInput {
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
}

export interface ProbationReviewEnable extends Node {
  __typename?: 'ProbationReviewEnable';
  company: Company;
  companyId: Scalars['BigInt']['output'];
  contractType: ContractType;
  contractTypeId: Scalars['BigInt']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  defaultProbationPeriod?: Maybe<Scalars['Int']['output']>;
  enabled: Scalars['Boolean']['output'];
  nodeId: Scalars['ID']['output'];
  probationDateInterval: Scalars['Int']['output'];
  probationReviewEnableId: Scalars['BigInt']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface ProbationReviewEnableCompanyIdFkeyCompanyCreateInput {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName: Scalars['String']['input'];
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEnableCompanyIdFkeyInput {
  connectByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyConnect>;
  connectByCompanyId?: InputMaybe<CompanyCompanyPkeyConnect>;
  connectByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyConnect>;
  connectByNodeId?: InputMaybe<CompanyNodeIdConnect>;
  create?: InputMaybe<ProbationReviewEnableCompanyIdFkeyCompanyCreateInput>;
  deleteByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyDelete>;
  deleteByCompanyId?: InputMaybe<CompanyCompanyPkeyDelete>;
  deleteByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyDelete>;
  deleteByNodeId?: InputMaybe<CompanyNodeIdDelete>;
  updateByAbbreviation?: InputMaybe<CompanyOnProbationReviewEnableForProbationReviewEnableCompanyIdFkeyUsingCompanyAbbreviationKeyUpdate>;
  updateByCompanyId?: InputMaybe<CompanyOnProbationReviewEnableForProbationReviewEnableCompanyIdFkeyUsingCompanyPkeyUpdate>;
  updateByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyOnProbationReviewEnableForProbationReviewEnableCompanyIdFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate>;
  updateByNodeId?: InputMaybe<ProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableCompanyIdFkeyNodeIdUpdate>;
}

export interface ProbationReviewEnableCompanyIdFkeyInverseInput {
  connectByCompanyIdAndContractTypeId?: InputMaybe<Array<ProbationReviewEnableProbationReviewEnableCompanyIdContractTypeIdKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<ProbationReviewEnableNodeIdConnect>>;
  connectByProbationReviewEnableId?: InputMaybe<Array<ProbationReviewEnableProbationReviewEnablePkeyConnect>>;
  create?: InputMaybe<Array<ProbationReviewEnableCompanyIdFkeyProbationReviewEnableCreateInput>>;
  deleteByCompanyIdAndContractTypeId?: InputMaybe<Array<ProbationReviewEnableProbationReviewEnableCompanyIdContractTypeIdKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEnableNodeIdDelete>>;
  deleteByProbationReviewEnableId?: InputMaybe<Array<ProbationReviewEnableProbationReviewEnablePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyIdAndContractTypeId?: InputMaybe<Array<ProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableCompanyIdFkeyUsingProbationReviewEnableCompanyIdContractTypeIdKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<CompanyOnProbationReviewEnableForProbationReviewEnableCompanyIdFkeyNodeIdUpdate>>;
  updateByProbationReviewEnableId?: InputMaybe<Array<ProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableCompanyIdFkeyUsingProbationReviewEnablePkeyUpdate>>;
}

export interface ProbationReviewEnableCompanyIdFkeyProbationReviewEnableCreateInput {
  company?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInput>;
  contractType?: InputMaybe<ProbationReviewEnableContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  defaultProbationPeriod?: InputMaybe<Scalars['Int']['input']>;
  enabled: Scalars['Boolean']['input'];
  probationDateInterval?: InputMaybe<Scalars['Int']['input']>;
  probationReviewEnableId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEnableCondition {
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  defaultProbationPeriod?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  probationDateInterval?: InputMaybe<Scalars['Int']['input']>;
  probationReviewEnableId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEnableContractTypeIdFkeyContractTypeCreateInput {
  code: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey1InverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey2InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableContractTypeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ContractTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEnableContractTypeIdFkeyInput {
  connectByCode?: InputMaybe<ContractTypeContractTypeCodeKeyConnect>;
  connectByContractTypeId?: InputMaybe<ContractTypeContractTypePkeyConnect>;
  connectByDisplayName?: InputMaybe<ContractTypeContractTypeDisplayNameKeyConnect>;
  connectByNodeId?: InputMaybe<ContractTypeNodeIdConnect>;
  create?: InputMaybe<ProbationReviewEnableContractTypeIdFkeyContractTypeCreateInput>;
  deleteByCode?: InputMaybe<ContractTypeContractTypeCodeKeyDelete>;
  deleteByContractTypeId?: InputMaybe<ContractTypeContractTypePkeyDelete>;
  deleteByDisplayName?: InputMaybe<ContractTypeContractTypeDisplayNameKeyDelete>;
  deleteByNodeId?: InputMaybe<ContractTypeNodeIdDelete>;
  updateByCode?: InputMaybe<ContractTypeOnProbationReviewEnableForProbationReviewEnableContractTypeIdFkeyUsingContractTypeCodeKeyUpdate>;
  updateByContractTypeId?: InputMaybe<ContractTypeOnProbationReviewEnableForProbationReviewEnableContractTypeIdFkeyUsingContractTypePkeyUpdate>;
  updateByDisplayName?: InputMaybe<ContractTypeOnProbationReviewEnableForProbationReviewEnableContractTypeIdFkeyUsingContractTypeDisplayNameKeyUpdate>;
  updateByNodeId?: InputMaybe<ProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableContractTypeIdFkeyNodeIdUpdate>;
}

export interface ProbationReviewEnableContractTypeIdFkeyInverseInput {
  connectByCompanyIdAndContractTypeId?: InputMaybe<Array<ProbationReviewEnableProbationReviewEnableCompanyIdContractTypeIdKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<ProbationReviewEnableNodeIdConnect>>;
  connectByProbationReviewEnableId?: InputMaybe<Array<ProbationReviewEnableProbationReviewEnablePkeyConnect>>;
  create?: InputMaybe<Array<ProbationReviewEnableContractTypeIdFkeyProbationReviewEnableCreateInput>>;
  deleteByCompanyIdAndContractTypeId?: InputMaybe<Array<ProbationReviewEnableProbationReviewEnableCompanyIdContractTypeIdKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEnableNodeIdDelete>>;
  deleteByProbationReviewEnableId?: InputMaybe<Array<ProbationReviewEnableProbationReviewEnablePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyIdAndContractTypeId?: InputMaybe<
    Array<ProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableContractTypeIdFkeyUsingProbationReviewEnableCompanyIdContractTypeIdKeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<ContractTypeOnProbationReviewEnableForProbationReviewEnableContractTypeIdFkeyNodeIdUpdate>>;
  updateByProbationReviewEnableId?: InputMaybe<Array<ProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableContractTypeIdFkeyUsingProbationReviewEnablePkeyUpdate>>;
}

export interface ProbationReviewEnableContractTypeIdFkeyProbationReviewEnableCreateInput {
  company?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<ProbationReviewEnableContractTypeIdFkeyInput>;
  defaultProbationPeriod?: InputMaybe<Scalars['Int']['input']>;
  enabled: Scalars['Boolean']['input'];
  probationDateInterval?: InputMaybe<Scalars['Int']['input']>;
  probationReviewEnableId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEnableFilter {
  and?: InputMaybe<Array<ProbationReviewEnableFilter>>;
  company?: InputMaybe<CompanyFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  contractType?: InputMaybe<ContractTypeFilter>;
  contractTypeId?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  defaultProbationPeriod?: InputMaybe<IntFilter>;
  enabled?: InputMaybe<BooleanFilter>;
  not?: InputMaybe<ProbationReviewEnableFilter>;
  or?: InputMaybe<Array<ProbationReviewEnableFilter>>;
  probationDateInterval?: InputMaybe<IntFilter>;
  probationReviewEnableId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface ProbationReviewEnableInput {
  company?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<ProbationReviewEnableContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  defaultProbationPeriod?: InputMaybe<Scalars['Int']['input']>;
  enabled: Scalars['Boolean']['input'];
  probationDateInterval?: InputMaybe<Scalars['Int']['input']>;
  probationReviewEnableId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEnableNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface ProbationReviewEnableNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface ProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableCompanyIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyPatch;
}

export interface ProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableCompanyIdFkeyUsingProbationReviewEnableCompanyIdContractTypeIdKeyUpdate {
  companyId: Scalars['BigInt']['input'];
  contractTypeId: Scalars['BigInt']['input'];
  patch: UpdateProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableCompanyIdFkeyPatch;
}

export interface ProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableCompanyIdFkeyUsingProbationReviewEnablePkeyUpdate {
  patch: UpdateProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableCompanyIdFkeyPatch;
  probationReviewEnableId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableContractTypeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ContractTypePatch;
}

export interface ProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableContractTypeIdFkeyUsingProbationReviewEnableCompanyIdContractTypeIdKeyUpdate {
  companyId: Scalars['BigInt']['input'];
  contractTypeId: Scalars['BigInt']['input'];
  patch: UpdateProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableContractTypeIdFkeyPatch;
}

export interface ProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableContractTypeIdFkeyUsingProbationReviewEnablePkeyUpdate {
  patch: UpdateProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableContractTypeIdFkeyPatch;
  probationReviewEnableId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableUserCreatedFkeyUsingProbationReviewEnableCompanyIdContractTypeIdKeyUpdate {
  companyId: Scalars['BigInt']['input'];
  contractTypeId: Scalars['BigInt']['input'];
  patch: UpdateProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableUserCreatedFkeyPatch;
}

export interface ProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableUserCreatedFkeyUsingProbationReviewEnablePkeyUpdate {
  patch: UpdateProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableUserCreatedFkeyPatch;
  probationReviewEnableId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface ProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableUserUpdatedFkeyUsingProbationReviewEnableCompanyIdContractTypeIdKeyUpdate {
  companyId: Scalars['BigInt']['input'];
  contractTypeId: Scalars['BigInt']['input'];
  patch: UpdateProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableUserUpdatedFkeyPatch;
}

export interface ProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableUserUpdatedFkeyUsingProbationReviewEnablePkeyUpdate {
  patch: UpdateProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableUserUpdatedFkeyPatch;
  probationReviewEnableId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEnablePatch {
  company?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<ProbationReviewEnableContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  defaultProbationPeriod?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  probationDateInterval?: InputMaybe<Scalars['Int']['input']>;
  probationReviewEnableId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEnableProbationReviewEnableCompanyIdContractTypeIdKeyConnect {
  companyId: Scalars['BigInt']['input'];
  contractTypeId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEnableProbationReviewEnableCompanyIdContractTypeIdKeyDelete {
  companyId: Scalars['BigInt']['input'];
  contractTypeId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEnableProbationReviewEnablePkeyConnect {
  probationReviewEnableId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEnableProbationReviewEnablePkeyDelete {
  probationReviewEnableId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEnableUserCreatedFkeyInverseInput {
  connectByCompanyIdAndContractTypeId?: InputMaybe<Array<ProbationReviewEnableProbationReviewEnableCompanyIdContractTypeIdKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<ProbationReviewEnableNodeIdConnect>>;
  connectByProbationReviewEnableId?: InputMaybe<Array<ProbationReviewEnableProbationReviewEnablePkeyConnect>>;
  deleteByCompanyIdAndContractTypeId?: InputMaybe<Array<ProbationReviewEnableProbationReviewEnableCompanyIdContractTypeIdKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEnableNodeIdDelete>>;
  deleteByProbationReviewEnableId?: InputMaybe<Array<ProbationReviewEnableProbationReviewEnablePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyIdAndContractTypeId?: InputMaybe<Array<ProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableUserCreatedFkeyUsingProbationReviewEnableCompanyIdContractTypeIdKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnProbationReviewEnableForProbationReviewEnableUserCreatedFkeyNodeIdUpdate>>;
  updateByProbationReviewEnableId?: InputMaybe<Array<ProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableUserCreatedFkeyUsingProbationReviewEnablePkeyUpdate>>;
}

export interface ProbationReviewEnableUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnProbationReviewEnableForProbationReviewEnableUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<ProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnProbationReviewEnableForProbationReviewEnableUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface ProbationReviewEnableUserUpdatedFkeyInverseInput {
  connectByCompanyIdAndContractTypeId?: InputMaybe<Array<ProbationReviewEnableProbationReviewEnableCompanyIdContractTypeIdKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<ProbationReviewEnableNodeIdConnect>>;
  connectByProbationReviewEnableId?: InputMaybe<Array<ProbationReviewEnableProbationReviewEnablePkeyConnect>>;
  deleteByCompanyIdAndContractTypeId?: InputMaybe<Array<ProbationReviewEnableProbationReviewEnableCompanyIdContractTypeIdKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEnableNodeIdDelete>>;
  deleteByProbationReviewEnableId?: InputMaybe<Array<ProbationReviewEnableProbationReviewEnablePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyIdAndContractTypeId?: InputMaybe<Array<ProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableUserUpdatedFkeyUsingProbationReviewEnableCompanyIdContractTypeIdKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnProbationReviewEnableForProbationReviewEnableUserUpdatedFkeyNodeIdUpdate>>;
  updateByProbationReviewEnableId?: InputMaybe<Array<ProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableUserUpdatedFkeyUsingProbationReviewEnablePkeyUpdate>>;
}

export interface ProbationReviewEnableView extends Node {
  __typename?: 'ProbationReviewEnableView';
  company: Company;
  companyId: Scalars['BigInt']['output'];
  contractType: ContractType;
  contractTypeId: Scalars['BigInt']['output'];
  countryByCountryCodeAlpha2?: Maybe<Country>;
  countryCodeAlpha2?: Maybe<Scalars['String']['output']>;
  defaultProbationPeriod?: Maybe<Scalars['Int']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  nodeId: Scalars['ID']['output'];
  probationDateInterval?: Maybe<Scalars['Int']['output']>;
  probationReviewEnableId?: Maybe<Scalars['BigInt']['output']>;
}

export interface ProbationReviewEnableViewCondition {
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  defaultProbationPeriod?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  probationDateInterval?: InputMaybe<Scalars['Int']['input']>;
  probationReviewEnableId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEnableViewFakePublicProbationReviewEnableViewPrimaryKeyConnect {
  companyId: Scalars['BigInt']['input'];
  contractTypeId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEnableViewFakePublicProbationReviewEnableViewPrimaryKeyDelete {
  companyId: Scalars['BigInt']['input'];
  contractTypeId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEnableViewFilter {
  and?: InputMaybe<Array<ProbationReviewEnableViewFilter>>;
  company?: InputMaybe<CompanyFilter>;
  companyExists?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<BigIntFilter>;
  contractType?: InputMaybe<ContractTypeFilter>;
  contractTypeExists?: InputMaybe<Scalars['Boolean']['input']>;
  contractTypeId?: InputMaybe<BigIntFilter>;
  countryByCountryCodeAlpha2?: InputMaybe<CountryFilter>;
  countryByCountryCodeAlpha2Exists?: InputMaybe<Scalars['Boolean']['input']>;
  countryCodeAlpha2?: InputMaybe<StringFilter>;
  defaultProbationPeriod?: InputMaybe<IntFilter>;
  enabled?: InputMaybe<BooleanFilter>;
  not?: InputMaybe<ProbationReviewEnableViewFilter>;
  or?: InputMaybe<Array<ProbationReviewEnableViewFilter>>;
  probationDateInterval?: InputMaybe<IntFilter>;
  probationReviewEnableId?: InputMaybe<BigIntFilter>;
}

export interface ProbationReviewEnableViewNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface ProbationReviewEnableViewNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface ProbationReviewEnableViewOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey0NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CountryPatch;
}

export interface ProbationReviewEnableViewOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey0UsingFakePublicProbationReviewEnableViewPrimaryKeyUpdate {
  companyId: Scalars['BigInt']['input'];
  contractTypeId: Scalars['BigInt']['input'];
  patch: UpdateProbationReviewEnableViewOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey0Patch;
}

export interface ProbationReviewEnableViewOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey1NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyPatch;
}

export interface ProbationReviewEnableViewOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey1UsingFakePublicProbationReviewEnableViewPrimaryKeyUpdate {
  companyId: Scalars['BigInt']['input'];
  contractTypeId: Scalars['BigInt']['input'];
  patch: UpdateProbationReviewEnableViewOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey1Patch;
}

export interface ProbationReviewEnableViewOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey2NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ContractTypePatch;
}

export interface ProbationReviewEnableViewOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey2UsingFakePublicProbationReviewEnableViewPrimaryKeyUpdate {
  companyId: Scalars['BigInt']['input'];
  contractTypeId: Scalars['BigInt']['input'];
  patch: UpdateProbationReviewEnableViewOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey2Patch;
}

export interface ProbationReviewEnableViewPatch {
  company?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1Input>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey2Input>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0Input>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  defaultProbationPeriod?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  probationDateInterval?: InputMaybe<Scalars['Int']['input']>;
  probationReviewEnableId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEnableViewsConnection {
  __typename?: 'ProbationReviewEnableViewsConnection';
  edges: Array<ProbationReviewEnableViewsEdge>;
  nodes: Array<ProbationReviewEnableView>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ProbationReviewEnableViewsEdge {
  __typename?: 'ProbationReviewEnableViewsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ProbationReviewEnableView;
}

export enum ProbationReviewEnableViewsOrderBy {
  COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC',
  COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_ASC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_ASC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_DESC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_DESC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANY_BY_COMPANY_ID__REMARK_ASC = 'COMPANY_BY_COMPANY_ID__REMARK_ASC',
  COMPANY_BY_COMPANY_ID__REMARK_DESC = 'COMPANY_BY_COMPANY_ID__REMARK_DESC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC',
  COMPANY_ID_ASC = 'COMPANY_ID_ASC',
  COMPANY_ID_DESC = 'COMPANY_ID_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CODE_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CODE_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CODE_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CODE_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CONTRACT_TYPE_ID_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CONTRACT_TYPE_ID_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CONTRACT_TYPE_ID_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CONTRACT_TYPE_ID_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_CREATED_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_CREATED_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_CREATED_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_CREATED_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_UPDATED_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_UPDATED_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_UPDATED_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_UPDATED_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DISPLAY_NAME_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DISPLAY_NAME_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DISPLAY_NAME_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DISPLAY_NAME_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__REMARK_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__REMARK_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__REMARK_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__REMARK_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_CREATED_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_CREATED_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_CREATED_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_CREATED_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_UPDATED_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_UPDATED_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_UPDATED_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_UPDATED_DESC',
  CONTRACT_TYPE_ID_ASC = 'CONTRACT_TYPE_ID_ASC',
  CONTRACT_TYPE_ID_DESC = 'CONTRACT_TYPE_ID_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_CREATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_CREATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_CREATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_CREATED_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_UPDATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_UPDATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_UPDATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_UPDATED_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__REMARK_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__REMARK_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__REMARK_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__REMARK_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__SHORT_NAME_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__SHORT_NAME_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__SHORT_NAME_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__SHORT_NAME_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_CREATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_CREATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_CREATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_CREATED_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_UPDATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_UPDATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_UPDATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_UPDATED_DESC',
  COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_CODE_ALPHA2_DESC',
  DEFAULT_PROBATION_PERIOD_ASC = 'DEFAULT_PROBATION_PERIOD_ASC',
  DEFAULT_PROBATION_PERIOD_DESC = 'DEFAULT_PROBATION_PERIOD_DESC',
  ENABLED_ASC = 'ENABLED_ASC',
  ENABLED_DESC = 'ENABLED_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROBATION_DATE_INTERVAL_ASC = 'PROBATION_DATE_INTERVAL_ASC',
  PROBATION_DATE_INTERVAL_DESC = 'PROBATION_DATE_INTERVAL_DESC',
  PROBATION_REVIEW_ENABLE_ID_ASC = 'PROBATION_REVIEW_ENABLE_ID_ASC',
  PROBATION_REVIEW_ENABLE_ID_DESC = 'PROBATION_REVIEW_ENABLE_ID_DESC',
}

export interface ProbationReviewEnablesConnection {
  __typename?: 'ProbationReviewEnablesConnection';
  edges: Array<ProbationReviewEnablesEdge>;
  nodes: Array<ProbationReviewEnable>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ProbationReviewEnablesEdge {
  __typename?: 'ProbationReviewEnablesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ProbationReviewEnable;
}

export enum ProbationReviewEnablesOrderBy {
  COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC',
  COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_ASC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_ASC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_DESC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_DESC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANY_BY_COMPANY_ID__REMARK_ASC = 'COMPANY_BY_COMPANY_ID__REMARK_ASC',
  COMPANY_BY_COMPANY_ID__REMARK_DESC = 'COMPANY_BY_COMPANY_ID__REMARK_DESC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC',
  COMPANY_ID_ASC = 'COMPANY_ID_ASC',
  COMPANY_ID_DESC = 'COMPANY_ID_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CODE_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CODE_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CODE_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CODE_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CONTRACT_TYPE_ID_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CONTRACT_TYPE_ID_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CONTRACT_TYPE_ID_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__CONTRACT_TYPE_ID_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_CREATED_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_CREATED_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_CREATED_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_CREATED_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_UPDATED_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_UPDATED_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_UPDATED_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DATE_UPDATED_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DISPLAY_NAME_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DISPLAY_NAME_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DISPLAY_NAME_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__DISPLAY_NAME_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__REMARK_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__REMARK_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__REMARK_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__REMARK_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_CREATED_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_CREATED_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_CREATED_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_CREATED_DESC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_UPDATED_ASC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_UPDATED_ASC',
  CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_UPDATED_DESC = 'CONTRACT_TYPE_BY_CONTRACT_TYPE_ID__USER_UPDATED_DESC',
  CONTRACT_TYPE_ID_ASC = 'CONTRACT_TYPE_ID_ASC',
  CONTRACT_TYPE_ID_DESC = 'CONTRACT_TYPE_ID_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DEFAULT_PROBATION_PERIOD_ASC = 'DEFAULT_PROBATION_PERIOD_ASC',
  DEFAULT_PROBATION_PERIOD_DESC = 'DEFAULT_PROBATION_PERIOD_DESC',
  ENABLED_ASC = 'ENABLED_ASC',
  ENABLED_DESC = 'ENABLED_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROBATION_DATE_INTERVAL_ASC = 'PROBATION_DATE_INTERVAL_ASC',
  PROBATION_DATE_INTERVAL_DESC = 'PROBATION_DATE_INTERVAL_DESC',
  PROBATION_REVIEW_ENABLE_ID_ASC = 'PROBATION_REVIEW_ENABLE_ID_ASC',
  PROBATION_REVIEW_ENABLE_ID_DESC = 'PROBATION_REVIEW_ENABLE_ID_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface ProbationReviewEvaluation extends Node {
  __typename?: 'ProbationReviewEvaluation';
  dateCreated: Scalars['Datetime']['output'];
  dateReminderSent?: Maybe<Scalars['Datetime']['output']>;
  dateUpdated: Scalars['Datetime']['output'];
  employee: Employee;
  employeeId: Scalars['BigInt']['output'];
  googleCalendarInfo?: Maybe<Scalars['JSON']['output']>;
  isFinalOrExtended: Scalars['Boolean']['output'];
  nodeId: Scalars['ID']['output'];
  probationReviewEvaluationId: Scalars['BigInt']['output'];
  probationReviewEvaluationResultItems: ProbationReviewEvaluationResultItemsConnection;
  probationReviewEvaluationResultScoreCompetencies: ProbationReviewEvaluationResultScoreCompetenciesConnection;
  probationReviewEvaluationResultScoreValues: ProbationReviewEvaluationResultScoreValuesConnection;
  probationReviewEvaluationReviewers: ProbationReviewEvaluationReviewersConnection;
  remark?: Maybe<Scalars['String']['output']>;
  result?: Maybe<ProbationReviewEvaluationResult>;
  reviewDate?: Maybe<Scalars['Date']['output']>;
  reviewDatePlanned: Scalars['Date']['output'];
  submitDate?: Maybe<Scalars['Datetime']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export type ProbationReviewEvaluationProbationReviewEvaluationResultItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationResultItemCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationResultItemFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultItemsOrderBy>>;
};

export type ProbationReviewEvaluationProbationReviewEvaluationResultScoreCompetenciesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetenciesOrderBy>>;
};

export type ProbationReviewEvaluationProbationReviewEvaluationResultScoreValuesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationResultScoreValueCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationResultScoreValueFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValuesOrderBy>>;
};

export type ProbationReviewEvaluationProbationReviewEvaluationReviewersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationReviewerCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationReviewerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationReviewersOrderBy>>;
};

export interface ProbationReviewEvaluationCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateReminderSent?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  googleCalendarInfo?: InputMaybe<Scalars['JSON']['input']>;
  isFinalOrExtended?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<ProbationReviewEvaluationResult>;
  reviewDate?: InputMaybe<Scalars['Date']['input']>;
  reviewDatePlanned?: InputMaybe<Scalars['Date']['input']>;
  submitDate?: InputMaybe<Scalars['Datetime']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationEmployeeIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface ProbationReviewEvaluationEmployeeIdFkeyInput {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyEmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnProbationReviewEvaluationForProbationReviewEvaluationEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnProbationReviewEvaluationForProbationReviewEvaluationEmployeeIdFkeyUsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<ProbationReviewEvaluationOnProbationReviewEvaluationForProbationReviewEvaluationEmployeeIdFkeyNodeIdUpdate>;
}

export interface ProbationReviewEvaluationEmployeeIdFkeyInverseInput {
  connectByEmployeeIdAndReviewDatePlanned?: InputMaybe<Array<ProbationReviewEvaluationProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<ProbationReviewEvaluationNodeIdConnect>>;
  connectByProbationReviewEvaluationId?: InputMaybe<Array<ProbationReviewEvaluationProbationReviewEvaluationPkeyConnect>>;
  create?: InputMaybe<Array<ProbationReviewEvaluationEmployeeIdFkeyProbationReviewEvaluationCreateInput>>;
  deleteByEmployeeIdAndReviewDatePlanned?: InputMaybe<Array<ProbationReviewEvaluationProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEvaluationNodeIdDelete>>;
  deleteByProbationReviewEvaluationId?: InputMaybe<Array<ProbationReviewEvaluationProbationReviewEvaluationPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeIdAndReviewDatePlanned?: InputMaybe<
    Array<ProbationReviewEvaluationOnProbationReviewEvaluationForProbationReviewEvaluationEmployeeIdFkeyUsingProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<EmployeeOnProbationReviewEvaluationForProbationReviewEvaluationEmployeeIdFkeyNodeIdUpdate>>;
  updateByProbationReviewEvaluationId?: InputMaybe<Array<ProbationReviewEvaluationOnProbationReviewEvaluationForProbationReviewEvaluationEmployeeIdFkeyUsingProbationReviewEvaluationPkeyUpdate>>;
}

export interface ProbationReviewEvaluationEmployeeIdFkeyProbationReviewEvaluationCreateInput {
  dateReminderSent?: InputMaybe<Scalars['Datetime']['input']>;
  employee?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInput>;
  googleCalendarInfo?: InputMaybe<Scalars['JSON']['input']>;
  isFinalOrExtended: Scalars['Boolean']['input'];
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewEvaluationFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1InverseInput>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey3InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<ProbationReviewEvaluationResult>;
  reviewDate?: InputMaybe<Scalars['Date']['input']>;
  reviewDatePlanned: Scalars['Date']['input'];
  submitDate?: InputMaybe<Scalars['Datetime']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationFilter {
  and?: InputMaybe<Array<ProbationReviewEvaluationFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateReminderSent?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  employee?: InputMaybe<EmployeeFilter>;
  employeeId?: InputMaybe<BigIntFilter>;
  googleCalendarInfo?: InputMaybe<JsonFilter>;
  isFinalOrExtended?: InputMaybe<BooleanFilter>;
  not?: InputMaybe<ProbationReviewEvaluationFilter>;
  or?: InputMaybe<Array<ProbationReviewEvaluationFilter>>;
  probationReviewEvaluationId?: InputMaybe<BigIntFilter>;
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationToManyProbationReviewEvaluationResultItemFilter>;
  probationReviewEvaluationResultItemsExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationToManyProbationReviewEvaluationResultScoreCompetencyFilter>;
  probationReviewEvaluationResultScoreCompetenciesExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationToManyProbationReviewEvaluationResultScoreValueFilter>;
  probationReviewEvaluationResultScoreValuesExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationToManyProbationReviewEvaluationReviewerFilter>;
  probationReviewEvaluationReviewersExist?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<StringFilter>;
  result?: InputMaybe<ProbationReviewEvaluationResultFilter>;
  reviewDate?: InputMaybe<DateFilter>;
  reviewDatePlanned?: InputMaybe<DateFilter>;
  submitDate?: InputMaybe<DatetimeFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface ProbationReviewEvaluationInput {
  dateReminderSent?: InputMaybe<Scalars['Datetime']['input']>;
  employee?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  googleCalendarInfo?: InputMaybe<Scalars['JSON']['input']>;
  isFinalOrExtended: Scalars['Boolean']['input'];
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewEvaluationFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1InverseInput>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey3InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<ProbationReviewEvaluationResult>;
  reviewDate?: InputMaybe<Scalars['Date']['input']>;
  reviewDatePlanned: Scalars['Date']['input'];
  submitDate?: InputMaybe<Scalars['Datetime']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationListReview {
  __typename?: 'ProbationReviewEvaluationListReview';
  overdueDays?: Maybe<Scalars['Int']['output']>;
  probationReviewEvaluationId?: Maybe<Scalars['BigInt']['output']>;
  result?: Maybe<ProbationReviewEvaluationResult>;
  reviewDate?: Maybe<Scalars['Date']['output']>;
  reviewDatePlanned?: Maybe<Scalars['Date']['output']>;
  submitDate?: Maybe<Scalars['Datetime']['output']>;
}

export interface ProbationReviewEvaluationNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface ProbationReviewEvaluationNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface ProbationReviewEvaluationOnProbationReviewEvaluationForProbationReviewEvaluationEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface ProbationReviewEvaluationOnProbationReviewEvaluationForProbationReviewEvaluationEmployeeIdFkeyUsingProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateProbationReviewEvaluationOnProbationReviewEvaluationForProbationReviewEvaluationEmployeeIdFkeyPatch;
  reviewDatePlanned: Scalars['Date']['input'];
}

export interface ProbationReviewEvaluationOnProbationReviewEvaluationForProbationReviewEvaluationEmployeeIdFkeyUsingProbationReviewEvaluationPkeyUpdate {
  patch: UpdateProbationReviewEvaluationOnProbationReviewEvaluationForProbationReviewEvaluationEmployeeIdFkeyPatch;
  probationReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationOnProbationReviewEvaluationForProbationReviewEvaluationUserCreatedFkeyUsingProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateProbationReviewEvaluationOnProbationReviewEvaluationForProbationReviewEvaluationUserCreatedFkeyPatch;
  reviewDatePlanned: Scalars['Date']['input'];
}

export interface ProbationReviewEvaluationOnProbationReviewEvaluationForProbationReviewEvaluationUserCreatedFkeyUsingProbationReviewEvaluationPkeyUpdate {
  patch: UpdateProbationReviewEvaluationOnProbationReviewEvaluationForProbationReviewEvaluationUserCreatedFkeyPatch;
  probationReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationOnProbationReviewEvaluationForProbationReviewEvaluationUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface ProbationReviewEvaluationOnProbationReviewEvaluationForProbationReviewEvaluationUserUpdatedFkeyUsingProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateProbationReviewEvaluationOnProbationReviewEvaluationForProbationReviewEvaluationUserUpdatedFkeyPatch;
  reviewDatePlanned: Scalars['Date']['input'];
}

export interface ProbationReviewEvaluationOnProbationReviewEvaluationForProbationReviewEvaluationUserUpdatedFkeyUsingProbationReviewEvaluationPkeyUpdate {
  patch: UpdateProbationReviewEvaluationOnProbationReviewEvaluationForProbationReviewEvaluationUserUpdatedFkeyPatch;
  probationReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewEvaluationFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationResultItemPatch;
}

export interface ProbationReviewEvaluationOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewEvaluationFkeyUsingProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateProbationReviewEvaluationOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewEvaluationFkeyPatch;
  reviewDatePlanned: Scalars['Date']['input'];
}

export interface ProbationReviewEvaluationOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewEvaluationFkeyUsingProbationReviewEvaluationPkeyUpdate {
  patch: UpdateProbationReviewEvaluationOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewEvaluationFkeyPatch;
  probationReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewEvaluationFkey2NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationResultScoreCompetencyPatch;
}

export interface ProbationReviewEvaluationOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewEvaluationFkey2UsingProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateProbationReviewEvaluationOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewEvaluationFkey2Patch;
  reviewDatePlanned: Scalars['Date']['input'];
}

export interface ProbationReviewEvaluationOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewEvaluationFkey2UsingProbationReviewEvaluationPkeyUpdate {
  patch: UpdateProbationReviewEvaluationOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewEvaluationFkey2Patch;
  probationReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewEvaluationFkey1NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationResultScoreValuePatch;
}

export interface ProbationReviewEvaluationOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewEvaluationFkey1UsingProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateProbationReviewEvaluationOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewEvaluationFkey1Patch;
  reviewDatePlanned: Scalars['Date']['input'];
}

export interface ProbationReviewEvaluationOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewEvaluationFkey1UsingProbationReviewEvaluationPkeyUpdate {
  patch: UpdateProbationReviewEvaluationOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewEvaluationFkey1Patch;
  probationReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationOnProbationReviewEvaluationReviewerForProbationReviewEvaluationProbationReviewEvaluationFkey3NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationReviewerPatch;
}

export interface ProbationReviewEvaluationOnProbationReviewEvaluationReviewerForProbationReviewEvaluationProbationReviewEvaluationFkey3UsingProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyUpdate {
  employeeId: Scalars['BigInt']['input'];
  patch: UpdateProbationReviewEvaluationOnProbationReviewEvaluationReviewerForProbationReviewEvaluationProbationReviewEvaluationFkey3Patch;
  reviewDatePlanned: Scalars['Date']['input'];
}

export interface ProbationReviewEvaluationOnProbationReviewEvaluationReviewerForProbationReviewEvaluationProbationReviewEvaluationFkey3UsingProbationReviewEvaluationPkeyUpdate {
  patch: UpdateProbationReviewEvaluationOnProbationReviewEvaluationReviewerForProbationReviewEvaluationProbationReviewEvaluationFkey3Patch;
  probationReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationPatch {
  dateReminderSent?: InputMaybe<Scalars['Datetime']['input']>;
  employee?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  googleCalendarInfo?: InputMaybe<Scalars['JSON']['input']>;
  isFinalOrExtended?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewEvaluationFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1InverseInput>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey3InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<ProbationReviewEvaluationResult>;
  reviewDate?: InputMaybe<Scalars['Date']['input']>;
  reviewDatePlanned?: InputMaybe<Scalars['Date']['input']>;
  submitDate?: InputMaybe<Scalars['Datetime']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyConnect {
  employeeId: Scalars['BigInt']['input'];
  reviewDatePlanned: Scalars['Date']['input'];
}

export interface ProbationReviewEvaluationProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyDelete {
  employeeId: Scalars['BigInt']['input'];
  reviewDatePlanned: Scalars['Date']['input'];
}

export interface ProbationReviewEvaluationProbationReviewEvaluationFkey1Input {
  connectByEmployeeIdAndReviewDatePlanned?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyConnect>;
  connectByNodeId?: InputMaybe<ProbationReviewEvaluationNodeIdConnect>;
  connectByProbationReviewEvaluationId?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationPkeyConnect>;
  create?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1ProbationReviewEvaluationCreateInput>;
  deleteByEmployeeIdAndReviewDatePlanned?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyDelete>;
  deleteByNodeId?: InputMaybe<ProbationReviewEvaluationNodeIdDelete>;
  deleteByProbationReviewEvaluationId?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationPkeyDelete>;
  updateByEmployeeIdAndReviewDatePlanned?: InputMaybe<ProbationReviewEvaluationOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewEvaluationFkey1UsingProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyUpdate>;
  updateByNodeId?: InputMaybe<ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewEvaluationFkey1NodeIdUpdate>;
  updateByProbationReviewEvaluationId?: InputMaybe<ProbationReviewEvaluationOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewEvaluationFkey1UsingProbationReviewEvaluationPkeyUpdate>;
}

export interface ProbationReviewEvaluationProbationReviewEvaluationFkey1InverseInput {
  connectByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueNodeIdConnect>>;
  connectByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationRProbationReviewEvaluationKey1Connect>
  >;
  connectByProbationReviewEvaluationResultScoreValueId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationResultScoreValuePkeyConnect>>;
  create?: InputMaybe<Array<ProbationReviewEvaluationProbationReviewEvaluationFkey1ProbationReviewEvaluationResultScoreValueCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueNodeIdDelete>>;
  deleteByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationRProbationReviewEvaluationKey1Delete>
  >;
  deleteByProbationReviewEvaluationResultScoreValueId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationResultScoreValuePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<ProbationReviewEvaluationOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewEvaluationFkey1NodeIdUpdate>>;
  updateByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewEvaluationFkey1UsingProbationReviewEvaluationRProbationReviewEvaluationKey1Update>
  >;
  updateByProbationReviewEvaluationResultScoreValueId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewEvaluationFkey1UsingProbationReviewEvaluationResultScoreValuePkeyUpdate>
  >;
}

export interface ProbationReviewEvaluationProbationReviewEvaluationFkey1ProbationReviewEvaluationCreateInput {
  dateReminderSent?: InputMaybe<Scalars['Datetime']['input']>;
  employee?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  googleCalendarInfo?: InputMaybe<Scalars['JSON']['input']>;
  isFinalOrExtended: Scalars['Boolean']['input'];
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewEvaluationFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1InverseInput>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey3InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<ProbationReviewEvaluationResult>;
  reviewDate?: InputMaybe<Scalars['Date']['input']>;
  reviewDatePlanned: Scalars['Date']['input'];
  submitDate?: InputMaybe<Scalars['Datetime']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationProbationReviewEvaluationFkey1ProbationReviewEvaluationResultScoreValueCreateInput {
  assessment?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInput>;
  performanceReviewMasterValue?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1Input>;
  probationReviewEvaluationResultScoreValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey1Input>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationProbationReviewEvaluationFkey2Input {
  connectByEmployeeIdAndReviewDatePlanned?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyConnect>;
  connectByNodeId?: InputMaybe<ProbationReviewEvaluationNodeIdConnect>;
  connectByProbationReviewEvaluationId?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationPkeyConnect>;
  create?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2ProbationReviewEvaluationCreateInput>;
  deleteByEmployeeIdAndReviewDatePlanned?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyDelete>;
  deleteByNodeId?: InputMaybe<ProbationReviewEvaluationNodeIdDelete>;
  deleteByProbationReviewEvaluationId?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationPkeyDelete>;
  updateByEmployeeIdAndReviewDatePlanned?: InputMaybe<ProbationReviewEvaluationOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewEvaluationFkey2UsingProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyUpdate>;
  updateByNodeId?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewEvaluationFkey2NodeIdUpdate>;
  updateByProbationReviewEvaluationId?: InputMaybe<ProbationReviewEvaluationOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewEvaluationFkey2UsingProbationReviewEvaluationPkeyUpdate>;
}

export interface ProbationReviewEvaluationProbationReviewEvaluationFkey2InverseInput {
  connectByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyNodeIdConnect>>;
  connectByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationRProbationReviewEvaluationKey2Connect>
  >;
  connectByProbationReviewEvaluationResultScoreCompetencyId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationResultScoreCompetencyPkeyConnect>>;
  create?: InputMaybe<Array<ProbationReviewEvaluationProbationReviewEvaluationFkey2ProbationReviewEvaluationResultScoreCompetencyCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyNodeIdDelete>>;
  deleteByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationRProbationReviewEvaluationKey2Delete>
  >;
  deleteByProbationReviewEvaluationResultScoreCompetencyId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationResultScoreCompetencyPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<ProbationReviewEvaluationOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewEvaluationFkey2NodeIdUpdate>>;
  updateByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewEvaluationFkey2UsingProbationReviewEvaluationRProbationReviewEvaluationKey2Update>
  >;
  updateByProbationReviewEvaluationResultScoreCompetencyId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewEvaluationFkey2UsingProbationReviewEvaluationResultScoreCompetencyPkeyUpdate>
  >;
}

export interface ProbationReviewEvaluationProbationReviewEvaluationFkey2ProbationReviewEvaluationCreateInput {
  dateReminderSent?: InputMaybe<Scalars['Datetime']['input']>;
  employee?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  googleCalendarInfo?: InputMaybe<Scalars['JSON']['input']>;
  isFinalOrExtended: Scalars['Boolean']['input'];
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewEvaluationFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1InverseInput>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey3InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<ProbationReviewEvaluationResult>;
  reviewDate?: InputMaybe<Scalars['Date']['input']>;
  reviewDatePlanned: Scalars['Date']['input'];
  submitDate?: InputMaybe<Scalars['Datetime']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationProbationReviewEvaluationFkey2ProbationReviewEvaluationResultScoreCompetencyCreateInput {
  assessment?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInput>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2Input>;
  probationReviewEvaluationResultScoreCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey2Input>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationProbationReviewEvaluationFkey3Input {
  connectByEmployeeIdAndReviewDatePlanned?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyConnect>;
  connectByNodeId?: InputMaybe<ProbationReviewEvaluationNodeIdConnect>;
  connectByProbationReviewEvaluationId?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationPkeyConnect>;
  create?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey3ProbationReviewEvaluationCreateInput>;
  deleteByEmployeeIdAndReviewDatePlanned?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyDelete>;
  deleteByNodeId?: InputMaybe<ProbationReviewEvaluationNodeIdDelete>;
  deleteByProbationReviewEvaluationId?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationPkeyDelete>;
  updateByEmployeeIdAndReviewDatePlanned?: InputMaybe<ProbationReviewEvaluationOnProbationReviewEvaluationReviewerForProbationReviewEvaluationProbationReviewEvaluationFkey3UsingProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyUpdate>;
  updateByNodeId?: InputMaybe<ProbationReviewEvaluationReviewerOnProbationReviewEvaluationReviewerForProbationReviewEvaluationProbationReviewEvaluationFkey3NodeIdUpdate>;
  updateByProbationReviewEvaluationId?: InputMaybe<ProbationReviewEvaluationOnProbationReviewEvaluationReviewerForProbationReviewEvaluationProbationReviewEvaluationFkey3UsingProbationReviewEvaluationPkeyUpdate>;
}

export interface ProbationReviewEvaluationProbationReviewEvaluationFkey3InverseInput {
  connectByNodeId?: InputMaybe<Array<ProbationReviewEvaluationReviewerNodeIdConnect>>;
  connectByProbationReviewEvaluationIdAndReviewerEmployeeId?: InputMaybe<Array<ProbationReviewEvaluationReviewerProbationReviewEvaluationReviewerPkeyConnect>>;
  create?: InputMaybe<Array<ProbationReviewEvaluationProbationReviewEvaluationFkey3ProbationReviewEvaluationReviewerCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEvaluationReviewerNodeIdDelete>>;
  deleteByProbationReviewEvaluationIdAndReviewerEmployeeId?: InputMaybe<Array<ProbationReviewEvaluationReviewerProbationReviewEvaluationReviewerPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<ProbationReviewEvaluationOnProbationReviewEvaluationReviewerForProbationReviewEvaluationProbationReviewEvaluationFkey3NodeIdUpdate>>;
  updateByProbationReviewEvaluationIdAndReviewerEmployeeId?: InputMaybe<
    Array<ProbationReviewEvaluationReviewerOnProbationReviewEvaluationReviewerForProbationReviewEvaluationProbationReviewEvaluationFkey3UsingProbationReviewEvaluationReviewerPkeyUpdate>
  >;
}

export interface ProbationReviewEvaluationProbationReviewEvaluationFkey3ProbationReviewEvaluationCreateInput {
  dateReminderSent?: InputMaybe<Scalars['Datetime']['input']>;
  employee?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  googleCalendarInfo?: InputMaybe<Scalars['JSON']['input']>;
  isFinalOrExtended: Scalars['Boolean']['input'];
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewEvaluationFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1InverseInput>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey3InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<ProbationReviewEvaluationResult>;
  reviewDate?: InputMaybe<Scalars['Date']['input']>;
  reviewDatePlanned: Scalars['Date']['input'];
  submitDate?: InputMaybe<Scalars['Datetime']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationProbationReviewEvaluationFkey3ProbationReviewEvaluationReviewerCreateInput {
  employee?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInput>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey3Input>;
  remark?: InputMaybe<Scalars['String']['input']>;
  reviewerEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationProbationReviewEvaluationPkeyConnect {
  probationReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationProbationReviewEvaluationPkeyDelete {
  probationReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationProbationReviewMasterItemFkey1Input {
  connectByNodeId?: InputMaybe<ProbationReviewMasterItemNodeIdConnect>;
  connectByProbationReviewMasterItemId?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemPkeyConnect>;
  connectByYearAndQuarterAndTitleAndInputUser?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemYearQuarterTitleInputUserKeyConnect>;
  create?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey1ProbationReviewMasterItemCreateInput>;
  deleteByNodeId?: InputMaybe<ProbationReviewMasterItemNodeIdDelete>;
  deleteByProbationReviewMasterItemId?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemPkeyDelete>;
  deleteByYearAndQuarterAndTitleAndInputUser?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemYearQuarterTitleInputUserKeyDelete>;
  updateByNodeId?: InputMaybe<ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewMasterItemFkey1NodeIdUpdate>;
  updateByProbationReviewMasterItemId?: InputMaybe<ProbationReviewMasterItemOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewMasterItemFkey1UsingProbationReviewMasterItemPkeyUpdate>;
  updateByYearAndQuarterAndTitleAndInputUser?: InputMaybe<ProbationReviewMasterItemOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewMasterItemFkey1UsingProbationReviewMasterItemYearQuarterTitleInputUserKeyUpdate>;
}

export interface ProbationReviewEvaluationProbationReviewMasterItemFkey1InverseInput {
  connectByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueNodeIdConnect>>;
  connectByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationRProbationReviewEvaluationKey1Connect>
  >;
  connectByProbationReviewEvaluationResultScoreValueId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationResultScoreValuePkeyConnect>>;
  create?: InputMaybe<Array<ProbationReviewEvaluationProbationReviewMasterItemFkey1ProbationReviewEvaluationResultScoreValueCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueNodeIdDelete>>;
  deleteByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationRProbationReviewEvaluationKey1Delete>
  >;
  deleteByProbationReviewEvaluationResultScoreValueId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationResultScoreValuePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<ProbationReviewMasterItemOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewMasterItemFkey1NodeIdUpdate>>;
  updateByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewMasterItemFkey1UsingProbationReviewEvaluationRProbationReviewEvaluationKey1Update>
  >;
  updateByProbationReviewEvaluationResultScoreValueId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewMasterItemFkey1UsingProbationReviewEvaluationResultScoreValuePkeyUpdate>
  >;
}

export interface ProbationReviewEvaluationProbationReviewMasterItemFkey1ProbationReviewEvaluationResultScoreValueCreateInput {
  assessment?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInput>;
  performanceReviewMasterValue?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey1Input>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationProbationReviewMasterItemFkey1ProbationReviewMasterItemCreateInput {
  dateDisabled?: InputMaybe<Scalars['Date']['input']>;
  dateEnabled?: InputMaybe<Scalars['Date']['input']>;
  displayOrder: Scalars['Int']['input'];
  inputMode: ProbationReviewInputMode;
  inputUser: ProbationReviewInputUser;
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewMasterItemFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey1InverseInput>;
  probationReviewMasterItemDescriptions?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemFkeyInverseInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  showOnSummary: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
  userAccount?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface ProbationReviewEvaluationProbationReviewMasterItemFkey2Input {
  connectByNodeId?: InputMaybe<ProbationReviewMasterItemNodeIdConnect>;
  connectByProbationReviewMasterItemId?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemPkeyConnect>;
  connectByYearAndQuarterAndTitleAndInputUser?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemYearQuarterTitleInputUserKeyConnect>;
  create?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey2ProbationReviewMasterItemCreateInput>;
  deleteByNodeId?: InputMaybe<ProbationReviewMasterItemNodeIdDelete>;
  deleteByProbationReviewMasterItemId?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemPkeyDelete>;
  deleteByYearAndQuarterAndTitleAndInputUser?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemYearQuarterTitleInputUserKeyDelete>;
  updateByNodeId?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewMasterItemFkey2NodeIdUpdate>;
  updateByProbationReviewMasterItemId?: InputMaybe<ProbationReviewMasterItemOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewMasterItemFkey2UsingProbationReviewMasterItemPkeyUpdate>;
  updateByYearAndQuarterAndTitleAndInputUser?: InputMaybe<ProbationReviewMasterItemOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewMasterItemFkey2UsingProbationReviewMasterItemYearQuarterTitleInputUserKeyUpdate>;
}

export interface ProbationReviewEvaluationProbationReviewMasterItemFkey2InverseInput {
  connectByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyNodeIdConnect>>;
  connectByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationRProbationReviewEvaluationKey2Connect>
  >;
  connectByProbationReviewEvaluationResultScoreCompetencyId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationResultScoreCompetencyPkeyConnect>>;
  create?: InputMaybe<Array<ProbationReviewEvaluationProbationReviewMasterItemFkey2ProbationReviewEvaluationResultScoreCompetencyCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyNodeIdDelete>>;
  deleteByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationRProbationReviewEvaluationKey2Delete>
  >;
  deleteByProbationReviewEvaluationResultScoreCompetencyId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationResultScoreCompetencyPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<ProbationReviewMasterItemOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewMasterItemFkey2NodeIdUpdate>>;
  updateByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewMasterItemFkey2UsingProbationReviewEvaluationRProbationReviewEvaluationKey2Update>
  >;
  updateByProbationReviewEvaluationResultScoreCompetencyId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewMasterItemFkey2UsingProbationReviewEvaluationResultScoreCompetencyPkeyUpdate>
  >;
}

export interface ProbationReviewEvaluationProbationReviewMasterItemFkey2ProbationReviewEvaluationResultScoreCompetencyCreateInput {
  assessment?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInput>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey2Input>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationProbationReviewMasterItemFkey2ProbationReviewMasterItemCreateInput {
  dateDisabled?: InputMaybe<Scalars['Date']['input']>;
  dateEnabled?: InputMaybe<Scalars['Date']['input']>;
  displayOrder: Scalars['Int']['input'];
  inputMode: ProbationReviewInputMode;
  inputUser: ProbationReviewInputUser;
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewMasterItemFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey1InverseInput>;
  probationReviewMasterItemDescriptions?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemFkeyInverseInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  showOnSummary: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
  userAccount?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInput {
  connectByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyCommonNodeIdConnect>;
  connectByPerformanceReviewMasterCompetencyCommonId?: InputMaybe<PerformanceReviewMasterCompetencyCommonPerformanceReviewMasterCompetencyCommonPkeyConnect>;
  create?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyPerformanceReviewMasterCompetencyCommonCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewMasterCompetencyCommonNodeIdDelete>;
  deleteByPerformanceReviewMasterCompetencyCommonId?: InputMaybe<PerformanceReviewMasterCompetencyCommonPerformanceReviewMasterCompetencyCommonPkeyDelete>;
  updateByNodeId?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationRPerformanceReviewMasterCoFkeyNodeIdUpdate>;
  updateByPerformanceReviewMasterCompetencyCommonId?: InputMaybe<PerformanceReviewMasterCompetencyCommonOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationRPerformanceReviewMasterCoFkeyUsingPerformanceReviewMasterCompetencyCommonPkeyUpdate>;
}

export interface ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyNodeIdConnect>>;
  connectByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationRProbationReviewEvaluationKey2Connect>
  >;
  connectByProbationReviewEvaluationResultScoreCompetencyId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationResultScoreCompetencyPkeyConnect>>;
  create?: InputMaybe<Array<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyProbationReviewEvaluationResultScoreCompetencyCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyNodeIdDelete>>;
  deleteByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationRProbationReviewEvaluationKey2Delete>
  >;
  deleteByProbationReviewEvaluationResultScoreCompetencyId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationResultScoreCompetencyPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationRPerformanceReviewMasterCoFkeyNodeIdUpdate>>;
  updateByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationRPerformanceReviewMasterCoFkeyUsingProbationReviewEvaluationRProbationReviewEvaluationKey2Update>
  >;
  updateByProbationReviewEvaluationResultScoreCompetencyId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationRPerformanceReviewMasterCoFkeyUsingProbationReviewEvaluationResultScoreCompetencyPkeyUpdate>
  >;
}

export interface ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyPerformanceReviewMasterCompetencyCommonCreateInput {
  displayOrder: Scalars['Int']['input'];
  overridable?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4InverseInput>;
  performanceReviewMasterCompetencyCommonDescriptions?: InputMaybe<PerformanceReviewMasterComPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterComOverridingPerformanceRevieFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInverseInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
}

export interface ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyProbationReviewEvaluationResultScoreCompetencyCreateInput {
  assessment?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterRating?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInput>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey2Input>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyInput {
  connectByNodeId?: InputMaybe<PerformanceReviewMasterValueNodeIdConnect>;
  connectByPerformanceReviewMasterValueId?: InputMaybe<PerformanceReviewMasterValuePerformanceReviewMasterValuePkeyConnect>;
  create?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyPerformanceReviewMasterValueCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewMasterValueNodeIdDelete>;
  deleteByPerformanceReviewMasterValueId?: InputMaybe<PerformanceReviewMasterValuePerformanceReviewMasterValuePkeyDelete>;
  updateByNodeId?: InputMaybe<ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationRPerformanceReviewMasterVaFkeyNodeIdUpdate>;
  updateByPerformanceReviewMasterValueId?: InputMaybe<PerformanceReviewMasterValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationRPerformanceReviewMasterVaFkeyUsingPerformanceReviewMasterValuePkeyUpdate>;
}

export interface ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueNodeIdConnect>>;
  connectByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationRProbationReviewEvaluationKey1Connect>
  >;
  connectByProbationReviewEvaluationResultScoreValueId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationResultScoreValuePkeyConnect>>;
  create?: InputMaybe<Array<ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyProbationReviewEvaluationResultScoreValueCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueNodeIdDelete>>;
  deleteByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationRProbationReviewEvaluationKey1Delete>
  >;
  deleteByProbationReviewEvaluationResultScoreValueId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationResultScoreValuePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewMasterValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationRPerformanceReviewMasterVaFkeyNodeIdUpdate>>;
  updateByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationRPerformanceReviewMasterVaFkeyUsingProbationReviewEvaluationRProbationReviewEvaluationKey1Update>
  >;
  updateByProbationReviewEvaluationResultScoreValueId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationRPerformanceReviewMasterVaFkeyUsingProbationReviewEvaluationResultScoreValuePkeyUpdate>
  >;
}

export interface ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyPerformanceReviewMasterValueCreateInput {
  displayOrder: Scalars['Int']['input'];
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyInverseInput>;
  performanceReviewMasterValueDescriptions?: InputMaybe<PerformanceReviewMasterValPerformanceReviewMasterVaFkeyInverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyInverseInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
}

export interface ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyProbationReviewEvaluationResultScoreValueCreateInput {
  assessment?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInput>;
  performanceReviewMasterValue?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyInput>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey1Input>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationRProbationReviewEvaluationFkeyInput {
  connectByEmployeeIdAndReviewDatePlanned?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyConnect>;
  connectByNodeId?: InputMaybe<ProbationReviewEvaluationNodeIdConnect>;
  connectByProbationReviewEvaluationId?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationPkeyConnect>;
  create?: InputMaybe<ProbationReviewEvaluationRProbationReviewEvaluationFkeyProbationReviewEvaluationCreateInput>;
  deleteByEmployeeIdAndReviewDatePlanned?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyDelete>;
  deleteByNodeId?: InputMaybe<ProbationReviewEvaluationNodeIdDelete>;
  deleteByProbationReviewEvaluationId?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationPkeyDelete>;
  updateByEmployeeIdAndReviewDatePlanned?: InputMaybe<ProbationReviewEvaluationOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewEvaluationFkeyUsingProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyUpdate>;
  updateByNodeId?: InputMaybe<ProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewEvaluationFkeyNodeIdUpdate>;
  updateByProbationReviewEvaluationId?: InputMaybe<ProbationReviewEvaluationOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewEvaluationFkeyUsingProbationReviewEvaluationPkeyUpdate>;
}

export interface ProbationReviewEvaluationRProbationReviewEvaluationFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultItemNodeIdConnect>>;
  connectByProbationReviewEvaluationIdAndProbationReviewMasterItemId?: InputMaybe<Array<ProbationReviewEvaluationResultItemProbationReviewEvaluationRProbationReviewEvaluationIKeyConnect>>;
  connectByProbationReviewEvaluationResultItemId?: InputMaybe<Array<ProbationReviewEvaluationResultItemProbationReviewEvaluationResultItemPkeyConnect>>;
  create?: InputMaybe<Array<ProbationReviewEvaluationRProbationReviewEvaluationFkeyProbationReviewEvaluationResultItemCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultItemNodeIdDelete>>;
  deleteByProbationReviewEvaluationIdAndProbationReviewMasterItemId?: InputMaybe<Array<ProbationReviewEvaluationResultItemProbationReviewEvaluationRProbationReviewEvaluationIKeyDelete>>;
  deleteByProbationReviewEvaluationResultItemId?: InputMaybe<Array<ProbationReviewEvaluationResultItemProbationReviewEvaluationResultItemPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<ProbationReviewEvaluationOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewEvaluationFkeyNodeIdUpdate>>;
  updateByProbationReviewEvaluationIdAndProbationReviewMasterItemId?: InputMaybe<
    Array<ProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewEvaluationFkeyUsingProbationReviewEvaluationRProbationReviewEvaluationIKeyUpdate>
  >;
  updateByProbationReviewEvaluationResultItemId?: InputMaybe<
    Array<ProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewEvaluationFkeyUsingProbationReviewEvaluationResultItemPkeyUpdate>
  >;
}

export interface ProbationReviewEvaluationRProbationReviewEvaluationFkeyProbationReviewEvaluationCreateInput {
  dateReminderSent?: InputMaybe<Scalars['Datetime']['input']>;
  employee?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  googleCalendarInfo?: InputMaybe<Scalars['JSON']['input']>;
  isFinalOrExtended: Scalars['Boolean']['input'];
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewEvaluationFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1InverseInput>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey3InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<ProbationReviewEvaluationResult>;
  reviewDate?: InputMaybe<Scalars['Date']['input']>;
  reviewDatePlanned: Scalars['Date']['input'];
  submitDate?: InputMaybe<Scalars['Datetime']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationRProbationReviewEvaluationFkeyProbationReviewEvaluationResultItemCreateInput {
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationRProbationReviewEvaluationFkeyInput>;
  probationReviewEvaluationResultItemId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationRProbationReviewMasterItemFkeyInput>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  valueInput?: InputMaybe<Scalars['String']['input']>;
  valueOutput?: InputMaybe<Scalars['String']['input']>;
  valueSingle?: InputMaybe<Scalars['String']['input']>;
}

export interface ProbationReviewEvaluationRProbationReviewMasterItemFkeyInput {
  connectByNodeId?: InputMaybe<ProbationReviewMasterItemNodeIdConnect>;
  connectByProbationReviewMasterItemId?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemPkeyConnect>;
  connectByYearAndQuarterAndTitleAndInputUser?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemYearQuarterTitleInputUserKeyConnect>;
  create?: InputMaybe<ProbationReviewEvaluationRProbationReviewMasterItemFkeyProbationReviewMasterItemCreateInput>;
  deleteByNodeId?: InputMaybe<ProbationReviewMasterItemNodeIdDelete>;
  deleteByProbationReviewMasterItemId?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemPkeyDelete>;
  deleteByYearAndQuarterAndTitleAndInputUser?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemYearQuarterTitleInputUserKeyDelete>;
  updateByNodeId?: InputMaybe<ProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewMasterItemFkeyNodeIdUpdate>;
  updateByProbationReviewMasterItemId?: InputMaybe<ProbationReviewMasterItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewMasterItemFkeyUsingProbationReviewMasterItemPkeyUpdate>;
  updateByYearAndQuarterAndTitleAndInputUser?: InputMaybe<ProbationReviewMasterItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewMasterItemFkeyUsingProbationReviewMasterItemYearQuarterTitleInputUserKeyUpdate>;
}

export interface ProbationReviewEvaluationRProbationReviewMasterItemFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultItemNodeIdConnect>>;
  connectByProbationReviewEvaluationIdAndProbationReviewMasterItemId?: InputMaybe<Array<ProbationReviewEvaluationResultItemProbationReviewEvaluationRProbationReviewEvaluationIKeyConnect>>;
  connectByProbationReviewEvaluationResultItemId?: InputMaybe<Array<ProbationReviewEvaluationResultItemProbationReviewEvaluationResultItemPkeyConnect>>;
  create?: InputMaybe<Array<ProbationReviewEvaluationRProbationReviewMasterItemFkeyProbationReviewEvaluationResultItemCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultItemNodeIdDelete>>;
  deleteByProbationReviewEvaluationIdAndProbationReviewMasterItemId?: InputMaybe<Array<ProbationReviewEvaluationResultItemProbationReviewEvaluationRProbationReviewEvaluationIKeyDelete>>;
  deleteByProbationReviewEvaluationResultItemId?: InputMaybe<Array<ProbationReviewEvaluationResultItemProbationReviewEvaluationResultItemPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<ProbationReviewMasterItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewMasterItemFkeyNodeIdUpdate>>;
  updateByProbationReviewEvaluationIdAndProbationReviewMasterItemId?: InputMaybe<
    Array<ProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewMasterItemFkeyUsingProbationReviewEvaluationRProbationReviewEvaluationIKeyUpdate>
  >;
  updateByProbationReviewEvaluationResultItemId?: InputMaybe<
    Array<ProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewMasterItemFkeyUsingProbationReviewEvaluationResultItemPkeyUpdate>
  >;
}

export interface ProbationReviewEvaluationRProbationReviewMasterItemFkeyProbationReviewEvaluationResultItemCreateInput {
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationRProbationReviewEvaluationFkeyInput>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultItemId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationRProbationReviewMasterItemFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  valueInput?: InputMaybe<Scalars['String']['input']>;
  valueOutput?: InputMaybe<Scalars['String']['input']>;
  valueSingle?: InputMaybe<Scalars['String']['input']>;
}

export interface ProbationReviewEvaluationRProbationReviewMasterItemFkeyProbationReviewMasterItemCreateInput {
  dateDisabled?: InputMaybe<Scalars['Date']['input']>;
  dateEnabled?: InputMaybe<Scalars['Date']['input']>;
  displayOrder: Scalars['Int']['input'];
  inputMode: ProbationReviewInputMode;
  inputUser: ProbationReviewInputUser;
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewMasterItemFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey1InverseInput>;
  probationReviewMasterItemDescriptions?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemFkeyInverseInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  showOnSummary: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
  userAccount?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export enum ProbationReviewEvaluationResult {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  EXTENDED = 'EXTENDED',
}

export interface ProbationReviewEvaluationResultFilter {
  distinctFrom?: InputMaybe<ProbationReviewEvaluationResult>;
  equalTo?: InputMaybe<ProbationReviewEvaluationResult>;
  greaterThan?: InputMaybe<ProbationReviewEvaluationResult>;
  greaterThanOrEqualTo?: InputMaybe<ProbationReviewEvaluationResult>;
  in?: InputMaybe<Array<ProbationReviewEvaluationResult>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<ProbationReviewEvaluationResult>;
  lessThanOrEqualTo?: InputMaybe<ProbationReviewEvaluationResult>;
  notDistinctFrom?: InputMaybe<ProbationReviewEvaluationResult>;
  notEqualTo?: InputMaybe<ProbationReviewEvaluationResult>;
  notIn?: InputMaybe<Array<ProbationReviewEvaluationResult>>;
}

export interface ProbationReviewEvaluationResultItem extends Node {
  __typename?: 'ProbationReviewEvaluationResultItem';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  nodeId: Scalars['ID']['output'];
  probationReviewEvaluation: ProbationReviewEvaluation;
  probationReviewEvaluationId: Scalars['BigInt']['output'];
  probationReviewEvaluationResultItemId: Scalars['BigInt']['output'];
  probationReviewMasterItem: ProbationReviewMasterItem;
  probationReviewMasterItemId: Scalars['BigInt']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
  valueInput?: Maybe<Scalars['String']['output']>;
  valueOutput?: Maybe<Scalars['String']['output']>;
  valueSingle?: Maybe<Scalars['String']['output']>;
}

export interface ProbationReviewEvaluationResultItemCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultItemId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  valueInput?: InputMaybe<Scalars['String']['input']>;
  valueOutput?: InputMaybe<Scalars['String']['input']>;
  valueSingle?: InputMaybe<Scalars['String']['input']>;
}

export interface ProbationReviewEvaluationResultItemFilter {
  and?: InputMaybe<Array<ProbationReviewEvaluationResultItemFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  not?: InputMaybe<ProbationReviewEvaluationResultItemFilter>;
  or?: InputMaybe<Array<ProbationReviewEvaluationResultItemFilter>>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationFilter>;
  probationReviewEvaluationId?: InputMaybe<BigIntFilter>;
  probationReviewEvaluationResultItemId?: InputMaybe<BigIntFilter>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewMasterItemFilter>;
  probationReviewMasterItemId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
  valueInput?: InputMaybe<StringFilter>;
  valueOutput?: InputMaybe<StringFilter>;
  valueSingle?: InputMaybe<StringFilter>;
}

export interface ProbationReviewEvaluationResultItemInput {
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationRProbationReviewEvaluationFkeyInput>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultItemId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationRProbationReviewMasterItemFkeyInput>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  valueInput?: InputMaybe<Scalars['String']['input']>;
  valueOutput?: InputMaybe<Scalars['String']['input']>;
  valueSingle?: InputMaybe<Scalars['String']['input']>;
}

export interface ProbationReviewEvaluationResultItemNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface ProbationReviewEvaluationResultItemNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface ProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewEvaluationFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationPatch;
}

export interface ProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewEvaluationFkeyUsingProbationReviewEvaluationRProbationReviewEvaluationIKeyUpdate {
  patch: UpdateProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewEvaluationFkeyPatch;
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewEvaluationFkeyUsingProbationReviewEvaluationResultItemPkeyUpdate {
  patch: UpdateProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewEvaluationFkeyPatch;
  probationReviewEvaluationResultItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewMasterItemFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewMasterItemPatch;
}

export interface ProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewMasterItemFkeyUsingProbationReviewEvaluationRProbationReviewEvaluationIKeyUpdate {
  patch: UpdateProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewMasterItemFkeyPatch;
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewMasterItemFkeyUsingProbationReviewEvaluationResultItemPkeyUpdate {
  patch: UpdateProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewMasterItemFkeyPatch;
  probationReviewEvaluationResultItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserCreatedFkeyUsingProbationReviewEvaluationRProbationReviewEvaluationIKeyUpdate {
  patch: UpdateProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserCreatedFkeyPatch;
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserCreatedFkeyUsingProbationReviewEvaluationResultItemPkeyUpdate {
  patch: UpdateProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserCreatedFkeyPatch;
  probationReviewEvaluationResultItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface ProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserUpdatedFkeyUsingProbationReviewEvaluationRProbationReviewEvaluationIKeyUpdate {
  patch: UpdateProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserUpdatedFkeyPatch;
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserUpdatedFkeyUsingProbationReviewEvaluationResultItemPkeyUpdate {
  patch: UpdateProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserUpdatedFkeyPatch;
  probationReviewEvaluationResultItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultItemPatch {
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationRProbationReviewEvaluationFkeyInput>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultItemId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationRProbationReviewMasterItemFkeyInput>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  valueInput?: InputMaybe<Scalars['String']['input']>;
  valueOutput?: InputMaybe<Scalars['String']['input']>;
  valueSingle?: InputMaybe<Scalars['String']['input']>;
}

export interface ProbationReviewEvaluationResultItemProbationReviewEvaluationRProbationReviewEvaluationIKeyConnect {
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultItemProbationReviewEvaluationRProbationReviewEvaluationIKeyDelete {
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultItemProbationReviewEvaluationResultItemPkeyConnect {
  probationReviewEvaluationResultItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultItemProbationReviewEvaluationResultItemPkeyDelete {
  probationReviewEvaluationResultItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultItemNodeIdConnect>>;
  connectByProbationReviewEvaluationIdAndProbationReviewMasterItemId?: InputMaybe<Array<ProbationReviewEvaluationResultItemProbationReviewEvaluationRProbationReviewEvaluationIKeyConnect>>;
  connectByProbationReviewEvaluationResultItemId?: InputMaybe<Array<ProbationReviewEvaluationResultItemProbationReviewEvaluationResultItemPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultItemNodeIdDelete>>;
  deleteByProbationReviewEvaluationIdAndProbationReviewMasterItemId?: InputMaybe<Array<ProbationReviewEvaluationResultItemProbationReviewEvaluationRProbationReviewEvaluationIKeyDelete>>;
  deleteByProbationReviewEvaluationResultItemId?: InputMaybe<Array<ProbationReviewEvaluationResultItemProbationReviewEvaluationResultItemPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserCreatedFkeyNodeIdUpdate>>;
  updateByProbationReviewEvaluationIdAndProbationReviewMasterItemId?: InputMaybe<
    Array<ProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserCreatedFkeyUsingProbationReviewEvaluationRProbationReviewEvaluationIKeyUpdate>
  >;
  updateByProbationReviewEvaluationResultItemId?: InputMaybe<
    Array<ProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserCreatedFkeyUsingProbationReviewEvaluationResultItemPkeyUpdate>
  >;
}

export interface ProbationReviewEvaluationResultItemUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<ProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultItemNodeIdConnect>>;
  connectByProbationReviewEvaluationIdAndProbationReviewMasterItemId?: InputMaybe<Array<ProbationReviewEvaluationResultItemProbationReviewEvaluationRProbationReviewEvaluationIKeyConnect>>;
  connectByProbationReviewEvaluationResultItemId?: InputMaybe<Array<ProbationReviewEvaluationResultItemProbationReviewEvaluationResultItemPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultItemNodeIdDelete>>;
  deleteByProbationReviewEvaluationIdAndProbationReviewMasterItemId?: InputMaybe<Array<ProbationReviewEvaluationResultItemProbationReviewEvaluationRProbationReviewEvaluationIKeyDelete>>;
  deleteByProbationReviewEvaluationResultItemId?: InputMaybe<Array<ProbationReviewEvaluationResultItemProbationReviewEvaluationResultItemPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserUpdatedFkeyNodeIdUpdate>>;
  updateByProbationReviewEvaluationIdAndProbationReviewMasterItemId?: InputMaybe<
    Array<ProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserUpdatedFkeyUsingProbationReviewEvaluationRProbationReviewEvaluationIKeyUpdate>
  >;
  updateByProbationReviewEvaluationResultItemId?: InputMaybe<
    Array<ProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserUpdatedFkeyUsingProbationReviewEvaluationResultItemPkeyUpdate>
  >;
}

export interface ProbationReviewEvaluationResultItemsConnection {
  __typename?: 'ProbationReviewEvaluationResultItemsConnection';
  edges: Array<ProbationReviewEvaluationResultItemsEdge>;
  nodes: Array<ProbationReviewEvaluationResultItem>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ProbationReviewEvaluationResultItemsEdge {
  __typename?: 'ProbationReviewEvaluationResultItemsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ProbationReviewEvaluationResultItem;
}

export enum ProbationReviewEvaluationResultItemsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_REMINDER_SENT_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_REMINDER_SENT_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_REMINDER_SENT_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_REMINDER_SENT_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__GOOGLE_CALENDAR_INFO_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__GOOGLE_CALENDAR_INFO_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__GOOGLE_CALENDAR_INFO_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__GOOGLE_CALENDAR_INFO_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__IS_FINAL_OR_EXTENDED_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__IS_FINAL_OR_EXTENDED_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__IS_FINAL_OR_EXTENDED_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__IS_FINAL_OR_EXTENDED_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__RESULT_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__RESULT_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__RESULT_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__RESULT_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_PLANNED_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_PLANNED_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_PLANNED_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_PLANNED_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__SUBMIT_DATE_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__SUBMIT_DATE_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__SUBMIT_DATE_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__SUBMIT_DATE_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_DISABLED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_DISABLED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_DISABLED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_DISABLED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_ENABLED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_ENABLED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_ENABLED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_ENABLED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DISPLAY_ORDER_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DISPLAY_ORDER_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DISPLAY_ORDER_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DISPLAY_ORDER_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_MODE_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_MODE_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_MODE_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_MODE_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_USER_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_USER_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_USER_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_USER_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__QUARTER_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__QUARTER_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__QUARTER_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__QUARTER_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__REMARK_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__REMARK_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__REMARK_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__REMARK_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__SHOW_ON_SUMMARY_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__SHOW_ON_SUMMARY_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__SHOW_ON_SUMMARY_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__SHOW_ON_SUMMARY_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__TITLE_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__TITLE_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__TITLE_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__TITLE_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__YEAR_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__YEAR_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__YEAR_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__YEAR_DESC',
  PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
  VALUE_INPUT_ASC = 'VALUE_INPUT_ASC',
  VALUE_INPUT_DESC = 'VALUE_INPUT_DESC',
  VALUE_OUTPUT_ASC = 'VALUE_OUTPUT_ASC',
  VALUE_OUTPUT_DESC = 'VALUE_OUTPUT_DESC',
  VALUE_SINGLE_ASC = 'VALUE_SINGLE_ASC',
  VALUE_SINGLE_DESC = 'VALUE_SINGLE_DESC',
}

export interface ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyNodeIdConnect>>;
  connectByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationRProbationReviewEvaluationKey2Connect>
  >;
  connectByProbationReviewEvaluationResultScoreCompetencyId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationResultScoreCompetencyPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyNodeIdDelete>>;
  deleteByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationRProbationReviewEvaluationKey2Delete>
  >;
  deleteByProbationReviewEvaluationResultScoreCompetencyId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationResultScoreCompetencyPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserCreatedFkeyNodeIdUpdate>>;
  updateByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserCreatedFkeyUsingProbationReviewEvaluationRProbationReviewEvaluationKey2Update>
  >;
  updateByProbationReviewEvaluationResultScoreCompetencyId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserCreatedFkeyUsingProbationReviewEvaluationResultScoreCompetencyPkeyUpdate>
  >;
}

export interface ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyNodeIdConnect>>;
  connectByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationRProbationReviewEvaluationKey2Connect>
  >;
  connectByProbationReviewEvaluationResultScoreCompetencyId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationResultScoreCompetencyPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyNodeIdDelete>>;
  deleteByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationRProbationReviewEvaluationKey2Delete>
  >;
  deleteByProbationReviewEvaluationResultScoreCompetencyId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationResultScoreCompetencyPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserUpdatedFkeyNodeIdUpdate>>;
  updateByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserUpdatedFkeyUsingProbationReviewEvaluationRProbationReviewEvaluationKey2Update>
  >;
  updateByProbationReviewEvaluationResultScoreCompetencyId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserUpdatedFkeyUsingProbationReviewEvaluationResultScoreCompetencyPkeyUpdate>
  >;
}

export interface ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInput {
  connectByNodeId?: InputMaybe<PerformanceReviewMasterRatingNodeIdConnect>;
  connectByPerformanceReviewMasterRatingId?: InputMaybe<PerformanceReviewMasterRatingPerformanceReviewMasterRatingPkeyConnect>;
  create?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyPerformanceReviewMasterRatingCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewMasterRatingNodeIdDelete>;
  deleteByPerformanceReviewMasterRatingId?: InputMaybe<PerformanceReviewMasterRatingPerformanceReviewMasterRatingPkeyDelete>;
  updateByNodeId?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompetAssessmentFkeyNodeIdUpdate>;
  updateByPerformanceReviewMasterRatingId?: InputMaybe<PerformanceReviewMasterRatingOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompetAssessmentFkeyUsingPerformanceReviewMasterRatingPkeyUpdate>;
}

export interface ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyNodeIdConnect>>;
  connectByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationRProbationReviewEvaluationKey2Connect>
  >;
  connectByProbationReviewEvaluationResultScoreCompetencyId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationResultScoreCompetencyPkeyConnect>>;
  create?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyProbationReviewEvaluationResultScoreCompetencyCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyNodeIdDelete>>;
  deleteByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationRProbationReviewEvaluationKey2Delete>
  >;
  deleteByProbationReviewEvaluationResultScoreCompetencyId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationResultScoreCompetencyPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewMasterRatingOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompetAssessmentFkeyNodeIdUpdate>>;
  updateByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompetAssessmentFkeyUsingProbationReviewEvaluationRProbationReviewEvaluationKey2Update>
  >;
  updateByProbationReviewEvaluationResultScoreCompetencyId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompetAssessmentFkeyUsingProbationReviewEvaluationResultScoreCompetencyPkeyUpdate>
  >;
}

export interface ProbationReviewEvaluationResultScoreCompetAssessmentFkeyPerformanceReviewMasterRatingCreateInput {
  calculationValue: Scalars['BigFloat']['input'];
  displayValue: Scalars['Int']['input'];
  percentageLowerBound?: InputMaybe<Scalars['Int']['input']>;
  percentageUpperBound?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInverseInput>;
  performanceReviewMasterRatingDescriptions?: InputMaybe<PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyInverseInput>;
  performanceReviewMasterRatingId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInverseInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface ProbationReviewEvaluationResultScoreCompetAssessmentFkeyProbationReviewEvaluationResultScoreCompetencyCreateInput {
  performanceReviewMasterCompetencyCommon?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInput>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey2Input>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationResultScoreCompetenciesConnection {
  __typename?: 'ProbationReviewEvaluationResultScoreCompetenciesConnection';
  edges: Array<ProbationReviewEvaluationResultScoreCompetenciesEdge>;
  nodes: Array<ProbationReviewEvaluationResultScoreCompetency>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ProbationReviewEvaluationResultScoreCompetenciesEdge {
  __typename?: 'ProbationReviewEvaluationResultScoreCompetenciesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ProbationReviewEvaluationResultScoreCompetency;
}

export enum ProbationReviewEvaluationResultScoreCompetenciesOrderBy {
  ASSESSMENT_ASC = 'ASSESSMENT_ASC',
  ASSESSMENT_DESC = 'ASSESSMENT_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__OVERRIDABLE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__OVERRIDABLE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__OVERRIDABLE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__OVERRIDABLE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__WEIGHT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__WEIGHT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__WEIGHT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__WEIGHT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_BY_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__CALCULATION_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__CALCULATION_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__CALCULATION_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__CALCULATION_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__DISPLAY_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__DISPLAY_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__DISPLAY_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__DISPLAY_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__PERCENTAGE_LOWER_BOUND_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__PERCENTAGE_LOWER_BOUND_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__PERCENTAGE_LOWER_BOUND_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__PERCENTAGE_LOWER_BOUND_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__PERCENTAGE_UPPER_BOUND_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__PERCENTAGE_UPPER_BOUND_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__PERCENTAGE_UPPER_BOUND_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__PERCENTAGE_UPPER_BOUND_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__YEAR_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_REMINDER_SENT_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_REMINDER_SENT_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_REMINDER_SENT_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_REMINDER_SENT_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__GOOGLE_CALENDAR_INFO_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__GOOGLE_CALENDAR_INFO_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__GOOGLE_CALENDAR_INFO_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__GOOGLE_CALENDAR_INFO_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__IS_FINAL_OR_EXTENDED_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__IS_FINAL_OR_EXTENDED_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__IS_FINAL_OR_EXTENDED_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__IS_FINAL_OR_EXTENDED_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__RESULT_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__RESULT_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__RESULT_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__RESULT_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_PLANNED_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_PLANNED_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_PLANNED_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_PLANNED_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__SUBMIT_DATE_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__SUBMIT_DATE_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__SUBMIT_DATE_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__SUBMIT_DATE_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_DISABLED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_DISABLED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_DISABLED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_DISABLED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_ENABLED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_ENABLED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_ENABLED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_ENABLED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DISPLAY_ORDER_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DISPLAY_ORDER_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DISPLAY_ORDER_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DISPLAY_ORDER_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_MODE_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_MODE_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_MODE_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_MODE_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_USER_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_USER_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_USER_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_USER_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__QUARTER_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__QUARTER_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__QUARTER_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__QUARTER_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__REMARK_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__REMARK_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__REMARK_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__REMARK_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__SHOW_ON_SUMMARY_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__SHOW_ON_SUMMARY_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__SHOW_ON_SUMMARY_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__SHOW_ON_SUMMARY_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__TITLE_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__TITLE_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__TITLE_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__TITLE_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__YEAR_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__YEAR_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__YEAR_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__YEAR_DESC',
  PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface ProbationReviewEvaluationResultScoreCompetency extends Node {
  __typename?: 'ProbationReviewEvaluationResultScoreCompetency';
  assessment?: Maybe<Scalars['BigInt']['output']>;
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  nodeId: Scalars['ID']['output'];
  performanceReviewMasterCompetency: PerformanceReviewMasterCompetencyCommon;
  performanceReviewMasterCompetencyId: Scalars['BigInt']['output'];
  performanceReviewMasterRatingByAssessment?: Maybe<PerformanceReviewMasterRating>;
  probationReviewEvaluation: ProbationReviewEvaluation;
  probationReviewEvaluationId: Scalars['BigInt']['output'];
  probationReviewEvaluationResultScoreCompetencyId: Scalars['BigInt']['output'];
  probationReviewMasterItem: ProbationReviewMasterItem;
  probationReviewMasterItemId: Scalars['BigInt']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface ProbationReviewEvaluationResultScoreCompetencyCondition {
  assessment?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationResultScoreCompetencyFilter {
  and?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyFilter>>;
  assessment?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  not?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyFilter>;
  or?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetencyFilter>>;
  performanceReviewMasterCompetency?: InputMaybe<PerformanceReviewMasterCompetencyCommonFilter>;
  performanceReviewMasterCompetencyId?: InputMaybe<BigIntFilter>;
  performanceReviewMasterRatingByAssessment?: InputMaybe<PerformanceReviewMasterRatingFilter>;
  performanceReviewMasterRatingByAssessmentExists?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationFilter>;
  probationReviewEvaluationId?: InputMaybe<BigIntFilter>;
  probationReviewEvaluationResultScoreCompetencyId?: InputMaybe<BigIntFilter>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewMasterItemFilter>;
  probationReviewMasterItemId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface ProbationReviewEvaluationResultScoreCompetencyInput {
  assessment?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInput>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey2Input>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationResultScoreCompetencyNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface ProbationReviewEvaluationResultScoreCompetencyNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewEvaluationFkey2NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationPatch;
}

export interface ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewEvaluationFkey2UsingProbationReviewEvaluationRProbationReviewEvaluationKey2Update {
  patch: UpdateProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewEvaluationFkey2Patch;
  performanceReviewMasterCompetencyId: Scalars['BigInt']['input'];
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewEvaluationFkey2UsingProbationReviewEvaluationResultScoreCompetencyPkeyUpdate {
  patch: UpdateProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewEvaluationFkey2Patch;
  probationReviewEvaluationResultScoreCompetencyId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewMasterItemFkey2NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewMasterItemPatch;
}

export interface ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewMasterItemFkey2UsingProbationReviewEvaluationRProbationReviewEvaluationKey2Update {
  patch: UpdateProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewMasterItemFkey2Patch;
  performanceReviewMasterCompetencyId: Scalars['BigInt']['input'];
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewMasterItemFkey2UsingProbationReviewEvaluationResultScoreCompetencyPkeyUpdate {
  patch: UpdateProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewMasterItemFkey2Patch;
  probationReviewEvaluationResultScoreCompetencyId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationRPerformanceReviewMasterCoFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyCommonPatch;
}

export interface ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationRPerformanceReviewMasterCoFkeyUsingProbationReviewEvaluationRProbationReviewEvaluationKey2Update {
  patch: UpdateProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationRPerformanceReviewMasterCoFkeyPatch;
  performanceReviewMasterCompetencyId: Scalars['BigInt']['input'];
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationRPerformanceReviewMasterCoFkeyUsingProbationReviewEvaluationResultScoreCompetencyPkeyUpdate {
  patch: UpdateProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationRPerformanceReviewMasterCoFkeyPatch;
  probationReviewEvaluationResultScoreCompetencyId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserCreatedFkeyUsingProbationReviewEvaluationRProbationReviewEvaluationKey2Update {
  patch: UpdateProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserCreatedFkeyPatch;
  performanceReviewMasterCompetencyId: Scalars['BigInt']['input'];
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserCreatedFkeyUsingProbationReviewEvaluationResultScoreCompetencyPkeyUpdate {
  patch: UpdateProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserCreatedFkeyPatch;
  probationReviewEvaluationResultScoreCompetencyId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserUpdatedFkeyUsingProbationReviewEvaluationRProbationReviewEvaluationKey2Update {
  patch: UpdateProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserUpdatedFkeyPatch;
  performanceReviewMasterCompetencyId: Scalars['BigInt']['input'];
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserUpdatedFkeyUsingProbationReviewEvaluationResultScoreCompetencyPkeyUpdate {
  patch: UpdateProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserUpdatedFkeyPatch;
  probationReviewEvaluationResultScoreCompetencyId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompetAssessmentFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterRatingPatch;
}

export interface ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompetAssessmentFkeyUsingProbationReviewEvaluationRProbationReviewEvaluationKey2Update {
  patch: UpdateProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompetAssessmentFkeyPatch;
  performanceReviewMasterCompetencyId: Scalars['BigInt']['input'];
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompetAssessmentFkeyUsingProbationReviewEvaluationResultScoreCompetencyPkeyUpdate {
  patch: UpdateProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompetAssessmentFkeyPatch;
  probationReviewEvaluationResultScoreCompetencyId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreCompetencyPatch {
  assessment?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInput>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey2Input>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationRProbationReviewEvaluationKey2Connect {
  performanceReviewMasterCompetencyId: Scalars['BigInt']['input'];
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationRProbationReviewEvaluationKey2Delete {
  performanceReviewMasterCompetencyId: Scalars['BigInt']['input'];
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationResultScoreCompetencyPkeyConnect {
  probationReviewEvaluationResultScoreCompetencyId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreCompetencyProbationReviewEvaluationResultScoreCompetencyPkeyDelete {
  probationReviewEvaluationResultScoreCompetencyId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueNodeIdConnect>>;
  connectByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationRProbationReviewEvaluationKey1Connect>
  >;
  connectByProbationReviewEvaluationResultScoreValueId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationResultScoreValuePkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueNodeIdDelete>>;
  deleteByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationRProbationReviewEvaluationKey1Delete>
  >;
  deleteByProbationReviewEvaluationResultScoreValueId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationResultScoreValuePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserCreatedFkeyNodeIdUpdate>>;
  updateByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserCreatedFkeyUsingProbationReviewEvaluationRProbationReviewEvaluationKey1Update>
  >;
  updateByProbationReviewEvaluationResultScoreValueId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserCreatedFkeyUsingProbationReviewEvaluationResultScoreValuePkeyUpdate>
  >;
}

export interface ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueNodeIdConnect>>;
  connectByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationRProbationReviewEvaluationKey1Connect>
  >;
  connectByProbationReviewEvaluationResultScoreValueId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationResultScoreValuePkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueNodeIdDelete>>;
  deleteByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationRProbationReviewEvaluationKey1Delete>
  >;
  deleteByProbationReviewEvaluationResultScoreValueId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationResultScoreValuePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserUpdatedFkeyNodeIdUpdate>>;
  updateByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserUpdatedFkeyUsingProbationReviewEvaluationRProbationReviewEvaluationKey1Update>
  >;
  updateByProbationReviewEvaluationResultScoreValueId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserUpdatedFkeyUsingProbationReviewEvaluationResultScoreValuePkeyUpdate>
  >;
}

export interface ProbationReviewEvaluationResultScoreValue extends Node {
  __typename?: 'ProbationReviewEvaluationResultScoreValue';
  assessment?: Maybe<Scalars['BigInt']['output']>;
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  nodeId: Scalars['ID']['output'];
  performanceReviewMasterRatingByAssessment?: Maybe<PerformanceReviewMasterRating>;
  performanceReviewMasterValue: PerformanceReviewMasterValue;
  performanceReviewMasterValueId: Scalars['BigInt']['output'];
  probationReviewEvaluation: ProbationReviewEvaluation;
  probationReviewEvaluationId: Scalars['BigInt']['output'];
  probationReviewEvaluationResultScoreValueId: Scalars['BigInt']['output'];
  probationReviewMasterItem: ProbationReviewMasterItem;
  probationReviewMasterItemId: Scalars['BigInt']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface ProbationReviewEvaluationResultScoreValueAssessmentFkeyInput {
  connectByNodeId?: InputMaybe<PerformanceReviewMasterRatingNodeIdConnect>;
  connectByPerformanceReviewMasterRatingId?: InputMaybe<PerformanceReviewMasterRatingPerformanceReviewMasterRatingPkeyConnect>;
  create?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyPerformanceReviewMasterRatingCreateInput>;
  deleteByNodeId?: InputMaybe<PerformanceReviewMasterRatingNodeIdDelete>;
  deleteByPerformanceReviewMasterRatingId?: InputMaybe<PerformanceReviewMasterRatingPerformanceReviewMasterRatingPkeyDelete>;
  updateByNodeId?: InputMaybe<ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValueAssessmentFkeyNodeIdUpdate>;
  updateByPerformanceReviewMasterRatingId?: InputMaybe<PerformanceReviewMasterRatingOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValueAssessmentFkeyUsingPerformanceReviewMasterRatingPkeyUpdate>;
}

export interface ProbationReviewEvaluationResultScoreValueAssessmentFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueNodeIdConnect>>;
  connectByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationRProbationReviewEvaluationKey1Connect>
  >;
  connectByProbationReviewEvaluationResultScoreValueId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationResultScoreValuePkeyConnect>>;
  create?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueAssessmentFkeyProbationReviewEvaluationResultScoreValueCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueNodeIdDelete>>;
  deleteByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationRProbationReviewEvaluationKey1Delete>
  >;
  deleteByProbationReviewEvaluationResultScoreValueId?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationResultScoreValuePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<PerformanceReviewMasterRatingOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValueAssessmentFkeyNodeIdUpdate>>;
  updateByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValueAssessmentFkeyUsingProbationReviewEvaluationRProbationReviewEvaluationKey1Update>
  >;
  updateByProbationReviewEvaluationResultScoreValueId?: InputMaybe<
    Array<ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValueAssessmentFkeyUsingProbationReviewEvaluationResultScoreValuePkeyUpdate>
  >;
}

export interface ProbationReviewEvaluationResultScoreValueAssessmentFkeyPerformanceReviewMasterRatingCreateInput {
  calculationValue: Scalars['BigFloat']['input'];
  displayValue: Scalars['Int']['input'];
  percentageLowerBound?: InputMaybe<Scalars['Int']['input']>;
  percentageUpperBound?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInverseInput>;
  performanceReviewMasterRatingDescriptions?: InputMaybe<PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyInverseInput>;
  performanceReviewMasterRatingId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInverseInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface ProbationReviewEvaluationResultScoreValueAssessmentFkeyProbationReviewEvaluationResultScoreValueCreateInput {
  performanceReviewMasterRating?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInput>;
  performanceReviewMasterValue?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey1Input>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationResultScoreValueCondition {
  assessment?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationResultScoreValueFilter {
  and?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueFilter>>;
  assessment?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  not?: InputMaybe<ProbationReviewEvaluationResultScoreValueFilter>;
  or?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValueFilter>>;
  performanceReviewMasterRatingByAssessment?: InputMaybe<PerformanceReviewMasterRatingFilter>;
  performanceReviewMasterRatingByAssessmentExists?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterValue?: InputMaybe<PerformanceReviewMasterValueFilter>;
  performanceReviewMasterValueId?: InputMaybe<BigIntFilter>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationFilter>;
  probationReviewEvaluationId?: InputMaybe<BigIntFilter>;
  probationReviewEvaluationResultScoreValueId?: InputMaybe<BigIntFilter>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewMasterItemFilter>;
  probationReviewMasterItemId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface ProbationReviewEvaluationResultScoreValueInput {
  assessment?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInput>;
  performanceReviewMasterValue?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey1Input>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationResultScoreValueNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface ProbationReviewEvaluationResultScoreValueNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewEvaluationFkey1NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationPatch;
}

export interface ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewEvaluationFkey1UsingProbationReviewEvaluationRProbationReviewEvaluationKey1Update {
  patch: UpdateProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewEvaluationFkey1Patch;
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewEvaluationFkey1UsingProbationReviewEvaluationResultScoreValuePkeyUpdate {
  patch: UpdateProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewEvaluationFkey1Patch;
  probationReviewEvaluationResultScoreValueId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewMasterItemFkey1NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewMasterItemPatch;
}

export interface ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewMasterItemFkey1UsingProbationReviewEvaluationRProbationReviewEvaluationKey1Update {
  patch: UpdateProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewMasterItemFkey1Patch;
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewMasterItemFkey1UsingProbationReviewEvaluationResultScoreValuePkeyUpdate {
  patch: UpdateProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewMasterItemFkey1Patch;
  probationReviewEvaluationResultScoreValueId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationRPerformanceReviewMasterVaFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterValuePatch;
}

export interface ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationRPerformanceReviewMasterVaFkeyUsingProbationReviewEvaluationRProbationReviewEvaluationKey1Update {
  patch: UpdateProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationRPerformanceReviewMasterVaFkeyPatch;
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationRPerformanceReviewMasterVaFkeyUsingProbationReviewEvaluationResultScoreValuePkeyUpdate {
  patch: UpdateProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationRPerformanceReviewMasterVaFkeyPatch;
  probationReviewEvaluationResultScoreValueId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserCreatedFkeyUsingProbationReviewEvaluationRProbationReviewEvaluationKey1Update {
  patch: UpdateProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserCreatedFkeyPatch;
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserCreatedFkeyUsingProbationReviewEvaluationResultScoreValuePkeyUpdate {
  patch: UpdateProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserCreatedFkeyPatch;
  probationReviewEvaluationResultScoreValueId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserUpdatedFkeyUsingProbationReviewEvaluationRProbationReviewEvaluationKey1Update {
  patch: UpdateProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserUpdatedFkeyPatch;
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserUpdatedFkeyUsingProbationReviewEvaluationResultScoreValuePkeyUpdate {
  patch: UpdateProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserUpdatedFkeyPatch;
  probationReviewEvaluationResultScoreValueId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValueAssessmentFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterRatingPatch;
}

export interface ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValueAssessmentFkeyUsingProbationReviewEvaluationRProbationReviewEvaluationKey1Update {
  patch: UpdateProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValueAssessmentFkeyPatch;
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValueAssessmentFkeyUsingProbationReviewEvaluationResultScoreValuePkeyUpdate {
  patch: UpdateProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValueAssessmentFkeyPatch;
  probationReviewEvaluationResultScoreValueId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreValuePatch {
  assessment?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInput>;
  performanceReviewMasterValue?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey1Input>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationRProbationReviewEvaluationKey1Connect {
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationRProbationReviewEvaluationKey1Delete {
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationResultScoreValuePkeyConnect {
  probationReviewEvaluationResultScoreValueId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreValueProbationReviewEvaluationResultScoreValuePkeyDelete {
  probationReviewEvaluationResultScoreValueId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationResultScoreValuesConnection {
  __typename?: 'ProbationReviewEvaluationResultScoreValuesConnection';
  edges: Array<ProbationReviewEvaluationResultScoreValuesEdge>;
  nodes: Array<ProbationReviewEvaluationResultScoreValue>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ProbationReviewEvaluationResultScoreValuesEdge {
  __typename?: 'ProbationReviewEvaluationResultScoreValuesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ProbationReviewEvaluationResultScoreValue;
}

export enum ProbationReviewEvaluationResultScoreValuesOrderBy {
  ASSESSMENT_ASC = 'ASSESSMENT_ASC',
  ASSESSMENT_DESC = 'ASSESSMENT_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__CALCULATION_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__CALCULATION_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__CALCULATION_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__CALCULATION_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__DISPLAY_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__DISPLAY_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__DISPLAY_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__DISPLAY_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__PERCENTAGE_LOWER_BOUND_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__PERCENTAGE_LOWER_BOUND_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__PERCENTAGE_LOWER_BOUND_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__PERCENTAGE_LOWER_BOUND_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__PERCENTAGE_UPPER_BOUND_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__PERCENTAGE_UPPER_BOUND_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__PERCENTAGE_UPPER_BOUND_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__PERCENTAGE_UPPER_BOUND_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_BY_ASSESSMENT__YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__WEIGHT_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__WEIGHT_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__WEIGHT_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__WEIGHT_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_BY_PERFORMANCE_REVIEW_MASTER_VALUE_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_REMINDER_SENT_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_REMINDER_SENT_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_REMINDER_SENT_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_REMINDER_SENT_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__GOOGLE_CALENDAR_INFO_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__GOOGLE_CALENDAR_INFO_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__GOOGLE_CALENDAR_INFO_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__GOOGLE_CALENDAR_INFO_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__IS_FINAL_OR_EXTENDED_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__IS_FINAL_OR_EXTENDED_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__IS_FINAL_OR_EXTENDED_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__IS_FINAL_OR_EXTENDED_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__RESULT_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__RESULT_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__RESULT_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__RESULT_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_PLANNED_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_PLANNED_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_PLANNED_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_PLANNED_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__SUBMIT_DATE_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__SUBMIT_DATE_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__SUBMIT_DATE_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__SUBMIT_DATE_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_DISABLED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_DISABLED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_DISABLED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_DISABLED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_ENABLED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_ENABLED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_ENABLED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_ENABLED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DISPLAY_ORDER_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DISPLAY_ORDER_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DISPLAY_ORDER_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DISPLAY_ORDER_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_MODE_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_MODE_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_MODE_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_MODE_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_USER_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_USER_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_USER_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_USER_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__QUARTER_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__QUARTER_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__QUARTER_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__QUARTER_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__REMARK_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__REMARK_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__REMARK_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__REMARK_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__SHOW_ON_SUMMARY_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__SHOW_ON_SUMMARY_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__SHOW_ON_SUMMARY_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__SHOW_ON_SUMMARY_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__TITLE_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__TITLE_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__TITLE_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__TITLE_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__YEAR_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__YEAR_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__YEAR_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__YEAR_DESC',
  PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface ProbationReviewEvaluationReviewer extends Node {
  __typename?: 'ProbationReviewEvaluationReviewer';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  nodeId: Scalars['ID']['output'];
  probationReviewEvaluation: ProbationReviewEvaluation;
  probationReviewEvaluationId: Scalars['BigInt']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  reviewerEmployee: Employee;
  reviewerEmployeeId: Scalars['BigInt']['output'];
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface ProbationReviewEvaluationReviewerCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  reviewerEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationReviewerFilter {
  and?: InputMaybe<Array<ProbationReviewEvaluationReviewerFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  not?: InputMaybe<ProbationReviewEvaluationReviewerFilter>;
  or?: InputMaybe<Array<ProbationReviewEvaluationReviewerFilter>>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationFilter>;
  probationReviewEvaluationId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<StringFilter>;
  reviewerEmployee?: InputMaybe<EmployeeFilter>;
  reviewerEmployeeId?: InputMaybe<BigIntFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface ProbationReviewEvaluationReviewerInput {
  employee?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInput>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey3Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  reviewerEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationReviewerNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface ProbationReviewEvaluationReviewerNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface ProbationReviewEvaluationReviewerOnProbationReviewEvaluationReviewerForProbationReviewEvaluationProbationReviewEvaluationFkey3NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationPatch;
}

export interface ProbationReviewEvaluationReviewerOnProbationReviewEvaluationReviewerForProbationReviewEvaluationProbationReviewEvaluationFkey3UsingProbationReviewEvaluationReviewerPkeyUpdate {
  patch: UpdateProbationReviewEvaluationReviewerOnProbationReviewEvaluationReviewerForProbationReviewEvaluationProbationReviewEvaluationFkey3Patch;
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  reviewerEmployeeId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationReviewerOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface ProbationReviewEvaluationReviewerOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyUsingProbationReviewEvaluationReviewerPkeyUpdate {
  patch: UpdateProbationReviewEvaluationReviewerOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyPatch;
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  reviewerEmployeeId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationReviewerOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerUserCreatedFkeyUsingProbationReviewEvaluationReviewerPkeyUpdate {
  patch: UpdateProbationReviewEvaluationReviewerOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerUserCreatedFkeyPatch;
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  reviewerEmployeeId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationReviewerOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface ProbationReviewEvaluationReviewerOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerUserUpdatedFkeyUsingProbationReviewEvaluationReviewerPkeyUpdate {
  patch: UpdateProbationReviewEvaluationReviewerOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerUserUpdatedFkeyPatch;
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  reviewerEmployeeId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationReviewerPatch {
  employee?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInput>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey3Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  reviewerEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationReviewerProbationReviewEvaluationReviewerPkeyConnect {
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  reviewerEmployeeId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationReviewerProbationReviewEvaluationReviewerPkeyDelete {
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  reviewerEmployeeId: Scalars['BigInt']['input'];
}

export interface ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInput {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyEmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyUsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<ProbationReviewEvaluationReviewerOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyNodeIdUpdate>;
}

export interface ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<ProbationReviewEvaluationReviewerNodeIdConnect>>;
  connectByProbationReviewEvaluationIdAndReviewerEmployeeId?: InputMaybe<Array<ProbationReviewEvaluationReviewerProbationReviewEvaluationReviewerPkeyConnect>>;
  create?: InputMaybe<Array<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyProbationReviewEvaluationReviewerCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEvaluationReviewerNodeIdDelete>>;
  deleteByProbationReviewEvaluationIdAndReviewerEmployeeId?: InputMaybe<Array<ProbationReviewEvaluationReviewerProbationReviewEvaluationReviewerPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<EmployeeOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyNodeIdUpdate>>;
  updateByProbationReviewEvaluationIdAndReviewerEmployeeId?: InputMaybe<
    Array<ProbationReviewEvaluationReviewerOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyUsingProbationReviewEvaluationReviewerPkeyUpdate>
  >;
}

export interface ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyProbationReviewEvaluationReviewerCreateInput {
  employee?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInput>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey3Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<ProbationReviewEvaluationReviewerNodeIdConnect>>;
  connectByProbationReviewEvaluationIdAndReviewerEmployeeId?: InputMaybe<Array<ProbationReviewEvaluationReviewerProbationReviewEvaluationReviewerPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEvaluationReviewerNodeIdDelete>>;
  deleteByProbationReviewEvaluationIdAndReviewerEmployeeId?: InputMaybe<Array<ProbationReviewEvaluationReviewerProbationReviewEvaluationReviewerPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerUserCreatedFkeyNodeIdUpdate>>;
  updateByProbationReviewEvaluationIdAndReviewerEmployeeId?: InputMaybe<
    Array<ProbationReviewEvaluationReviewerOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerUserCreatedFkeyUsingProbationReviewEvaluationReviewerPkeyUpdate>
  >;
}

export interface ProbationReviewEvaluationReviewerUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<ProbationReviewEvaluationReviewerOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<ProbationReviewEvaluationReviewerNodeIdConnect>>;
  connectByProbationReviewEvaluationIdAndReviewerEmployeeId?: InputMaybe<Array<ProbationReviewEvaluationReviewerProbationReviewEvaluationReviewerPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEvaluationReviewerNodeIdDelete>>;
  deleteByProbationReviewEvaluationIdAndReviewerEmployeeId?: InputMaybe<Array<ProbationReviewEvaluationReviewerProbationReviewEvaluationReviewerPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerUserUpdatedFkeyNodeIdUpdate>>;
  updateByProbationReviewEvaluationIdAndReviewerEmployeeId?: InputMaybe<
    Array<ProbationReviewEvaluationReviewerOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerUserUpdatedFkeyUsingProbationReviewEvaluationReviewerPkeyUpdate>
  >;
}

export interface ProbationReviewEvaluationReviewersConnection {
  __typename?: 'ProbationReviewEvaluationReviewersConnection';
  edges: Array<ProbationReviewEvaluationReviewersEdge>;
  nodes: Array<ProbationReviewEvaluationReviewer>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ProbationReviewEvaluationReviewersEdge {
  __typename?: 'ProbationReviewEvaluationReviewersEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ProbationReviewEvaluationReviewer;
}

export enum ProbationReviewEvaluationReviewersOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_REVIEWER_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_REMINDER_SENT_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_REMINDER_SENT_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_REMINDER_SENT_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_REMINDER_SENT_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__GOOGLE_CALENDAR_INFO_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__GOOGLE_CALENDAR_INFO_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__GOOGLE_CALENDAR_INFO_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__GOOGLE_CALENDAR_INFO_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__IS_FINAL_OR_EXTENDED_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__IS_FINAL_OR_EXTENDED_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__IS_FINAL_OR_EXTENDED_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__IS_FINAL_OR_EXTENDED_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__RESULT_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__RESULT_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__RESULT_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__RESULT_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_PLANNED_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_PLANNED_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_PLANNED_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__REVIEW_DATE_PLANNED_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__SUBMIT_DATE_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__SUBMIT_DATE_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__SUBMIT_DATE_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__SUBMIT_DATE_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_BY_PROBATION_REVIEW_EVALUATION_ID__USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_ID_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  REVIEWER_EMPLOYEE_ID_ASC = 'REVIEWER_EMPLOYEE_ID_ASC',
  REVIEWER_EMPLOYEE_ID_DESC = 'REVIEWER_EMPLOYEE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface ProbationReviewEvaluationToManyProbationReviewEvaluationResultItemFilter {
  every?: InputMaybe<ProbationReviewEvaluationResultItemFilter>;
  none?: InputMaybe<ProbationReviewEvaluationResultItemFilter>;
  some?: InputMaybe<ProbationReviewEvaluationResultItemFilter>;
}

export interface ProbationReviewEvaluationToManyProbationReviewEvaluationResultScoreCompetencyFilter {
  every?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyFilter>;
  none?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyFilter>;
  some?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyFilter>;
}

export interface ProbationReviewEvaluationToManyProbationReviewEvaluationResultScoreValueFilter {
  every?: InputMaybe<ProbationReviewEvaluationResultScoreValueFilter>;
  none?: InputMaybe<ProbationReviewEvaluationResultScoreValueFilter>;
  some?: InputMaybe<ProbationReviewEvaluationResultScoreValueFilter>;
}

export interface ProbationReviewEvaluationToManyProbationReviewEvaluationReviewerFilter {
  every?: InputMaybe<ProbationReviewEvaluationReviewerFilter>;
  none?: InputMaybe<ProbationReviewEvaluationReviewerFilter>;
  some?: InputMaybe<ProbationReviewEvaluationReviewerFilter>;
}

export interface ProbationReviewEvaluationUserCreatedFkeyInverseInput {
  connectByEmployeeIdAndReviewDatePlanned?: InputMaybe<Array<ProbationReviewEvaluationProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<ProbationReviewEvaluationNodeIdConnect>>;
  connectByProbationReviewEvaluationId?: InputMaybe<Array<ProbationReviewEvaluationProbationReviewEvaluationPkeyConnect>>;
  deleteByEmployeeIdAndReviewDatePlanned?: InputMaybe<Array<ProbationReviewEvaluationProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEvaluationNodeIdDelete>>;
  deleteByProbationReviewEvaluationId?: InputMaybe<Array<ProbationReviewEvaluationProbationReviewEvaluationPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeIdAndReviewDatePlanned?: InputMaybe<
    Array<ProbationReviewEvaluationOnProbationReviewEvaluationForProbationReviewEvaluationUserCreatedFkeyUsingProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<UserAccountOnProbationReviewEvaluationForProbationReviewEvaluationUserCreatedFkeyNodeIdUpdate>>;
  updateByProbationReviewEvaluationId?: InputMaybe<Array<ProbationReviewEvaluationOnProbationReviewEvaluationForProbationReviewEvaluationUserCreatedFkeyUsingProbationReviewEvaluationPkeyUpdate>>;
}

export interface ProbationReviewEvaluationUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnProbationReviewEvaluationForProbationReviewEvaluationUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<ProbationReviewEvaluationOnProbationReviewEvaluationForProbationReviewEvaluationUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnProbationReviewEvaluationForProbationReviewEvaluationUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface ProbationReviewEvaluationUserUpdatedFkeyInverseInput {
  connectByEmployeeIdAndReviewDatePlanned?: InputMaybe<Array<ProbationReviewEvaluationProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<ProbationReviewEvaluationNodeIdConnect>>;
  connectByProbationReviewEvaluationId?: InputMaybe<Array<ProbationReviewEvaluationProbationReviewEvaluationPkeyConnect>>;
  deleteByEmployeeIdAndReviewDatePlanned?: InputMaybe<Array<ProbationReviewEvaluationProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewEvaluationNodeIdDelete>>;
  deleteByProbationReviewEvaluationId?: InputMaybe<Array<ProbationReviewEvaluationProbationReviewEvaluationPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmployeeIdAndReviewDatePlanned?: InputMaybe<
    Array<ProbationReviewEvaluationOnProbationReviewEvaluationForProbationReviewEvaluationUserUpdatedFkeyUsingProbationReviewEvaluationEmployeeIdReviewDatePlannedKeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<UserAccountOnProbationReviewEvaluationForProbationReviewEvaluationUserUpdatedFkeyNodeIdUpdate>>;
  updateByProbationReviewEvaluationId?: InputMaybe<Array<ProbationReviewEvaluationOnProbationReviewEvaluationForProbationReviewEvaluationUserUpdatedFkeyUsingProbationReviewEvaluationPkeyUpdate>>;
}

export interface ProbationReviewEvaluationsConnection {
  __typename?: 'ProbationReviewEvaluationsConnection';
  edges: Array<ProbationReviewEvaluationsEdge>;
  nodes: Array<ProbationReviewEvaluation>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ProbationReviewEvaluationsEdge {
  __typename?: 'ProbationReviewEvaluationsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ProbationReviewEvaluation;
}

export enum ProbationReviewEvaluationsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_REMINDER_SENT_ASC = 'DATE_REMINDER_SENT_ASC',
  DATE_REMINDER_SENT_DESC = 'DATE_REMINDER_SENT_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  GOOGLE_CALENDAR_INFO_ASC = 'GOOGLE_CALENDAR_INFO_ASC',
  GOOGLE_CALENDAR_INFO_DESC = 'GOOGLE_CALENDAR_INFO_DESC',
  IS_FINAL_OR_EXTENDED_ASC = 'IS_FINAL_OR_EXTENDED_ASC',
  IS_FINAL_OR_EXTENDED_DESC = 'IS_FINAL_OR_EXTENDED_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__COUNT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__COUNT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__COUNT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__COUNT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_VALUE_INPUT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_VALUE_INPUT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_VALUE_INPUT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_VALUE_INPUT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_VALUE_OUTPUT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_VALUE_OUTPUT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_VALUE_OUTPUT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_VALUE_OUTPUT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_VALUE_SINGLE_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_VALUE_SINGLE_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_VALUE_SINGLE_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_VALUE_SINGLE_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_VALUE_INPUT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_VALUE_INPUT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_VALUE_INPUT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_VALUE_INPUT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_VALUE_OUTPUT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_VALUE_OUTPUT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_VALUE_OUTPUT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_VALUE_OUTPUT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_VALUE_SINGLE_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_VALUE_SINGLE_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_VALUE_SINGLE_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_VALUE_SINGLE_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__COUNT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__COUNT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__COUNT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__COUNT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__COUNT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__COUNT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__COUNT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__COUNT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_ASSESSMENT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_ASSESSMENT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__COUNT_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__COUNT_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__COUNT_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__COUNT_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_REVIEWER_EMPLOYEE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_REVIEWER_EMPLOYEE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_REVIEWER_EMPLOYEE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_REVIEWER_EMPLOYEE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_REVIEWER_EMPLOYEE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_REVIEWER_EMPLOYEE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_REVIEWER_EMPLOYEE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_REVIEWER_EMPLOYEE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_PROBATION_REVIEW_EVALUATION_ID__MIN_USER_UPDATED_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  RESULT_ASC = 'RESULT_ASC',
  RESULT_DESC = 'RESULT_DESC',
  REVIEW_DATE_ASC = 'REVIEW_DATE_ASC',
  REVIEW_DATE_DESC = 'REVIEW_DATE_DESC',
  REVIEW_DATE_PLANNED_ASC = 'REVIEW_DATE_PLANNED_ASC',
  REVIEW_DATE_PLANNED_DESC = 'REVIEW_DATE_PLANNED_DESC',
  SUBMIT_DATE_ASC = 'SUBMIT_DATE_ASC',
  SUBMIT_DATE_DESC = 'SUBMIT_DATE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export enum ProbationReviewInputMode {
  INPUT_OUTPUT = 'INPUT_OUTPUT',
  RESULT = 'RESULT',
  SCORE = 'SCORE',
  SINGLE = 'SINGLE',
}

export interface ProbationReviewInputModeFilter {
  distinctFrom?: InputMaybe<ProbationReviewInputMode>;
  equalTo?: InputMaybe<ProbationReviewInputMode>;
  greaterThan?: InputMaybe<ProbationReviewInputMode>;
  greaterThanOrEqualTo?: InputMaybe<ProbationReviewInputMode>;
  in?: InputMaybe<Array<ProbationReviewInputMode>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<ProbationReviewInputMode>;
  lessThanOrEqualTo?: InputMaybe<ProbationReviewInputMode>;
  notDistinctFrom?: InputMaybe<ProbationReviewInputMode>;
  notEqualTo?: InputMaybe<ProbationReviewInputMode>;
  notIn?: InputMaybe<Array<ProbationReviewInputMode>>;
}

export enum ProbationReviewInputUser {
  HR = 'HR',
  REVIEWEE = 'REVIEWEE',
  REVIEWER = 'REVIEWER',
}

export interface ProbationReviewInputUserFilter {
  distinctFrom?: InputMaybe<ProbationReviewInputUser>;
  equalTo?: InputMaybe<ProbationReviewInputUser>;
  greaterThan?: InputMaybe<ProbationReviewInputUser>;
  greaterThanOrEqualTo?: InputMaybe<ProbationReviewInputUser>;
  in?: InputMaybe<Array<ProbationReviewInputUser>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<ProbationReviewInputUser>;
  lessThanOrEqualTo?: InputMaybe<ProbationReviewInputUser>;
  notDistinctFrom?: InputMaybe<ProbationReviewInputUser>;
  notEqualTo?: InputMaybe<ProbationReviewInputUser>;
  notIn?: InputMaybe<Array<ProbationReviewInputUser>>;
}

export interface ProbationReviewMasterItem extends Node {
  __typename?: 'ProbationReviewMasterItem';
  dateCreated: Scalars['Datetime']['output'];
  dateDisabled?: Maybe<Scalars['Date']['output']>;
  dateEnabled?: Maybe<Scalars['Date']['output']>;
  dateUpdated: Scalars['Datetime']['output'];
  displayOrder: Scalars['Int']['output'];
  inputMode: ProbationReviewInputMode;
  inputUser: ProbationReviewInputUser;
  nodeId: Scalars['ID']['output'];
  probationReviewEvaluationResultItems: ProbationReviewEvaluationResultItemsConnection;
  probationReviewEvaluationResultScoreCompetencies: ProbationReviewEvaluationResultScoreCompetenciesConnection;
  probationReviewEvaluationResultScoreValues: ProbationReviewEvaluationResultScoreValuesConnection;
  probationReviewMasterItemDescriptions: ProbationReviewMasterItemDescriptionsConnection;
  probationReviewMasterItemId: Scalars['BigInt']['output'];
  quarter: PerformanceReviewQuarter;
  remark?: Maybe<Scalars['String']['output']>;
  showOnSummary: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
  year: Scalars['Int']['output'];
}

export type ProbationReviewMasterItemProbationReviewEvaluationResultItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationResultItemCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationResultItemFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultItemsOrderBy>>;
};

export type ProbationReviewMasterItemProbationReviewEvaluationResultScoreCompetenciesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetenciesOrderBy>>;
};

export type ProbationReviewMasterItemProbationReviewEvaluationResultScoreValuesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationResultScoreValueCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationResultScoreValueFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValuesOrderBy>>;
};

export type ProbationReviewMasterItemProbationReviewMasterItemDescriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewMasterItemDescriptionCondition>;
  filter?: InputMaybe<ProbationReviewMasterItemDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewMasterItemDescriptionsOrderBy>>;
};

export interface ProbationReviewMasterItemCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateDisabled?: InputMaybe<Scalars['Date']['input']>;
  dateEnabled?: InputMaybe<Scalars['Date']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  inputMode?: InputMaybe<ProbationReviewInputMode>;
  inputUser?: InputMaybe<ProbationReviewInputUser>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  showOnSummary?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProbationReviewMasterItemDescription extends Node {
  __typename?: 'ProbationReviewMasterItemDescription';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  language: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  probationReviewMasterItem: ProbationReviewMasterItem;
  probationReviewMasterItemDescriptionId: Scalars['BigInt']['output'];
  probationReviewMasterItemId: Scalars['BigInt']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface ProbationReviewMasterItemDescriptionCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  probationReviewMasterItemDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewMasterItemDescriptionFilter {
  and?: InputMaybe<Array<ProbationReviewMasterItemDescriptionFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  description?: InputMaybe<StringFilter>;
  language?: InputMaybe<StringFilter>;
  not?: InputMaybe<ProbationReviewMasterItemDescriptionFilter>;
  or?: InputMaybe<Array<ProbationReviewMasterItemDescriptionFilter>>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewMasterItemFilter>;
  probationReviewMasterItemDescriptionId?: InputMaybe<BigIntFilter>;
  probationReviewMasterItemId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface ProbationReviewMasterItemDescriptionInput {
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemFkeyInput>;
  probationReviewMasterItemDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewMasterItemDescriptionNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface ProbationReviewMasterItemDescriptionNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface ProbationReviewMasterItemDescriptionOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserCreatedFkeyUsingProbationReviewMasterItemDescriptionPkeyUpdate {
  patch: UpdateProbationReviewMasterItemDescriptionOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserCreatedFkeyPatch;
  probationReviewMasterItemDescriptionId: Scalars['BigInt']['input'];
}

export interface ProbationReviewMasterItemDescriptionOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserCreatedFkeyUsingProbationReviewMasterItemProbationReviewMasterItemKeyUpdate {
  language: Scalars['String']['input'];
  patch: UpdateProbationReviewMasterItemDescriptionOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserCreatedFkeyPatch;
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewMasterItemDescriptionOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface ProbationReviewMasterItemDescriptionOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserUpdatedFkeyUsingProbationReviewMasterItemDescriptionPkeyUpdate {
  patch: UpdateProbationReviewMasterItemDescriptionOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserUpdatedFkeyPatch;
  probationReviewMasterItemDescriptionId: Scalars['BigInt']['input'];
}

export interface ProbationReviewMasterItemDescriptionOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserUpdatedFkeyUsingProbationReviewMasterItemProbationReviewMasterItemKeyUpdate {
  language: Scalars['String']['input'];
  patch: UpdateProbationReviewMasterItemDescriptionOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserUpdatedFkeyPatch;
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewMasterItemDescriptionOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemProbationReviewMasterItemFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewMasterItemPatch;
}

export interface ProbationReviewMasterItemDescriptionOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemProbationReviewMasterItemFkeyUsingProbationReviewMasterItemDescriptionPkeyUpdate {
  patch: UpdateProbationReviewMasterItemDescriptionOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemProbationReviewMasterItemFkeyPatch;
  probationReviewMasterItemDescriptionId: Scalars['BigInt']['input'];
}

export interface ProbationReviewMasterItemDescriptionOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemProbationReviewMasterItemFkeyUsingProbationReviewMasterItemProbationReviewMasterItemKeyUpdate {
  language: Scalars['String']['input'];
  patch: UpdateProbationReviewMasterItemDescriptionOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemProbationReviewMasterItemFkeyPatch;
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewMasterItemDescriptionPatch {
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemFkeyInput>;
  probationReviewMasterItemDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewMasterItemDescriptionProbationReviewMasterItemDescriptionPkeyConnect {
  probationReviewMasterItemDescriptionId: Scalars['BigInt']['input'];
}

export interface ProbationReviewMasterItemDescriptionProbationReviewMasterItemDescriptionPkeyDelete {
  probationReviewMasterItemDescriptionId: Scalars['BigInt']['input'];
}

export interface ProbationReviewMasterItemDescriptionProbationReviewMasterItemProbationReviewMasterItemKeyConnect {
  language: Scalars['String']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewMasterItemDescriptionProbationReviewMasterItemProbationReviewMasterItemKeyDelete {
  language: Scalars['String']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<ProbationReviewMasterItemDescriptionNodeIdConnect>>;
  connectByProbationReviewMasterItemDescriptionId?: InputMaybe<Array<ProbationReviewMasterItemDescriptionProbationReviewMasterItemDescriptionPkeyConnect>>;
  connectByProbationReviewMasterItemIdAndLanguage?: InputMaybe<Array<ProbationReviewMasterItemDescriptionProbationReviewMasterItemProbationReviewMasterItemKeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewMasterItemDescriptionNodeIdDelete>>;
  deleteByProbationReviewMasterItemDescriptionId?: InputMaybe<Array<ProbationReviewMasterItemDescriptionProbationReviewMasterItemDescriptionPkeyDelete>>;
  deleteByProbationReviewMasterItemIdAndLanguage?: InputMaybe<Array<ProbationReviewMasterItemDescriptionProbationReviewMasterItemProbationReviewMasterItemKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserCreatedFkeyNodeIdUpdate>>;
  updateByProbationReviewMasterItemDescriptionId?: InputMaybe<
    Array<ProbationReviewMasterItemDescriptionOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserCreatedFkeyUsingProbationReviewMasterItemDescriptionPkeyUpdate>
  >;
  updateByProbationReviewMasterItemIdAndLanguage?: InputMaybe<
    Array<ProbationReviewMasterItemDescriptionOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserCreatedFkeyUsingProbationReviewMasterItemProbationReviewMasterItemKeyUpdate>
  >;
}

export interface ProbationReviewMasterItemDescriptionUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<ProbationReviewMasterItemDescriptionOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<ProbationReviewMasterItemDescriptionNodeIdConnect>>;
  connectByProbationReviewMasterItemDescriptionId?: InputMaybe<Array<ProbationReviewMasterItemDescriptionProbationReviewMasterItemDescriptionPkeyConnect>>;
  connectByProbationReviewMasterItemIdAndLanguage?: InputMaybe<Array<ProbationReviewMasterItemDescriptionProbationReviewMasterItemProbationReviewMasterItemKeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewMasterItemDescriptionNodeIdDelete>>;
  deleteByProbationReviewMasterItemDescriptionId?: InputMaybe<Array<ProbationReviewMasterItemDescriptionProbationReviewMasterItemDescriptionPkeyDelete>>;
  deleteByProbationReviewMasterItemIdAndLanguage?: InputMaybe<Array<ProbationReviewMasterItemDescriptionProbationReviewMasterItemProbationReviewMasterItemKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserUpdatedFkeyNodeIdUpdate>>;
  updateByProbationReviewMasterItemDescriptionId?: InputMaybe<
    Array<ProbationReviewMasterItemDescriptionOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserUpdatedFkeyUsingProbationReviewMasterItemDescriptionPkeyUpdate>
  >;
  updateByProbationReviewMasterItemIdAndLanguage?: InputMaybe<
    Array<ProbationReviewMasterItemDescriptionOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserUpdatedFkeyUsingProbationReviewMasterItemProbationReviewMasterItemKeyUpdate>
  >;
}

export interface ProbationReviewMasterItemDescriptionsConnection {
  __typename?: 'ProbationReviewMasterItemDescriptionsConnection';
  edges: Array<ProbationReviewMasterItemDescriptionsEdge>;
  nodes: Array<ProbationReviewMasterItemDescription>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ProbationReviewMasterItemDescriptionsEdge {
  __typename?: 'ProbationReviewMasterItemDescriptionsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ProbationReviewMasterItemDescription;
}

export enum ProbationReviewMasterItemDescriptionsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DESCRIPTION_ASC = 'DESCRIPTION_ASC',
  DESCRIPTION_DESC = 'DESCRIPTION_DESC',
  LANGUAGE_ASC = 'LANGUAGE_ASC',
  LANGUAGE_DESC = 'LANGUAGE_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_DISABLED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_DISABLED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_DISABLED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_DISABLED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_ENABLED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_ENABLED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_ENABLED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_ENABLED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DATE_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DISPLAY_ORDER_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DISPLAY_ORDER_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DISPLAY_ORDER_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__DISPLAY_ORDER_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_MODE_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_MODE_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_MODE_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_MODE_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_USER_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_USER_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_USER_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__INPUT_USER_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__QUARTER_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__QUARTER_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__QUARTER_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__QUARTER_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__REMARK_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__REMARK_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__REMARK_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__REMARK_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__SHOW_ON_SUMMARY_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__SHOW_ON_SUMMARY_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__SHOW_ON_SUMMARY_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__SHOW_ON_SUMMARY_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__TITLE_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__TITLE_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__TITLE_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__TITLE_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__USER_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__YEAR_ASC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__YEAR_ASC',
  PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__YEAR_DESC = 'PROBATION_REVIEW_MASTER_ITEM_BY_PROBATION_REVIEW_MASTER_ITEM_ID__YEAR_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_DESC',
  PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface ProbationReviewMasterItemFilter {
  and?: InputMaybe<Array<ProbationReviewMasterItemFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateDisabled?: InputMaybe<DateFilter>;
  dateEnabled?: InputMaybe<DateFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  inputMode?: InputMaybe<ProbationReviewInputModeFilter>;
  inputUser?: InputMaybe<ProbationReviewInputUserFilter>;
  not?: InputMaybe<ProbationReviewMasterItemFilter>;
  or?: InputMaybe<Array<ProbationReviewMasterItemFilter>>;
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewMasterItemToManyProbationReviewEvaluationResultItemFilter>;
  probationReviewEvaluationResultItemsExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewMasterItemToManyProbationReviewEvaluationResultScoreCompetencyFilter>;
  probationReviewEvaluationResultScoreCompetenciesExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewMasterItemToManyProbationReviewEvaluationResultScoreValueFilter>;
  probationReviewEvaluationResultScoreValuesExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewMasterItemDescriptions?: InputMaybe<ProbationReviewMasterItemToManyProbationReviewMasterItemDescriptionFilter>;
  probationReviewMasterItemDescriptionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewMasterItemId?: InputMaybe<BigIntFilter>;
  quarter?: InputMaybe<PerformanceReviewQuarterFilter>;
  remark?: InputMaybe<StringFilter>;
  showOnSummary?: InputMaybe<BooleanFilter>;
  title?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
  year?: InputMaybe<IntFilter>;
}

export interface ProbationReviewMasterItemInput {
  dateDisabled?: InputMaybe<Scalars['Date']['input']>;
  dateEnabled?: InputMaybe<Scalars['Date']['input']>;
  displayOrder: Scalars['Int']['input'];
  inputMode: ProbationReviewInputMode;
  inputUser: ProbationReviewInputUser;
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewMasterItemFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey1InverseInput>;
  probationReviewMasterItemDescriptions?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemFkeyInverseInput>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  showOnSummary: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
  userAccount?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface ProbationReviewMasterItemNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface ProbationReviewMasterItemNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface ProbationReviewMasterItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewMasterItemFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationResultItemPatch;
}

export interface ProbationReviewMasterItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewMasterItemFkeyUsingProbationReviewMasterItemPkeyUpdate {
  patch: UpdateProbationReviewMasterItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewMasterItemFkeyPatch;
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewMasterItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewMasterItemFkeyUsingProbationReviewMasterItemYearQuarterTitleInputUserKeyUpdate {
  inputUser: ProbationReviewInputUser;
  patch: UpdateProbationReviewMasterItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewMasterItemFkeyPatch;
  quarter: PerformanceReviewQuarter;
  title: Scalars['String']['input'];
  year: Scalars['Int']['input'];
}

export interface ProbationReviewMasterItemOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewMasterItemFkey2NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationResultScoreCompetencyPatch;
}

export interface ProbationReviewMasterItemOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewMasterItemFkey2UsingProbationReviewMasterItemPkeyUpdate {
  patch: UpdateProbationReviewMasterItemOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewMasterItemFkey2Patch;
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewMasterItemOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewMasterItemFkey2UsingProbationReviewMasterItemYearQuarterTitleInputUserKeyUpdate {
  inputUser: ProbationReviewInputUser;
  patch: UpdateProbationReviewMasterItemOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewMasterItemFkey2Patch;
  quarter: PerformanceReviewQuarter;
  title: Scalars['String']['input'];
  year: Scalars['Int']['input'];
}

export interface ProbationReviewMasterItemOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewMasterItemFkey1NodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationResultScoreValuePatch;
}

export interface ProbationReviewMasterItemOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewMasterItemFkey1UsingProbationReviewMasterItemPkeyUpdate {
  patch: UpdateProbationReviewMasterItemOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewMasterItemFkey1Patch;
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewMasterItemOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewMasterItemFkey1UsingProbationReviewMasterItemYearQuarterTitleInputUserKeyUpdate {
  inputUser: ProbationReviewInputUser;
  patch: UpdateProbationReviewMasterItemOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewMasterItemFkey1Patch;
  quarter: PerformanceReviewQuarter;
  title: Scalars['String']['input'];
  year: Scalars['Int']['input'];
}

export interface ProbationReviewMasterItemOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemProbationReviewMasterItemFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewMasterItemDescriptionPatch;
}

export interface ProbationReviewMasterItemOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemProbationReviewMasterItemFkeyUsingProbationReviewMasterItemPkeyUpdate {
  patch: UpdateProbationReviewMasterItemOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemProbationReviewMasterItemFkeyPatch;
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewMasterItemOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemProbationReviewMasterItemFkeyUsingProbationReviewMasterItemYearQuarterTitleInputUserKeyUpdate {
  inputUser: ProbationReviewInputUser;
  patch: UpdateProbationReviewMasterItemOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemProbationReviewMasterItemFkeyPatch;
  quarter: PerformanceReviewQuarter;
  title: Scalars['String']['input'];
  year: Scalars['Int']['input'];
}

export interface ProbationReviewMasterItemOnProbationReviewMasterItemForProbationReviewMasterItemUserCreatedFkeyUsingProbationReviewMasterItemPkeyUpdate {
  patch: UpdateProbationReviewMasterItemOnProbationReviewMasterItemForProbationReviewMasterItemUserCreatedFkeyPatch;
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewMasterItemOnProbationReviewMasterItemForProbationReviewMasterItemUserCreatedFkeyUsingProbationReviewMasterItemYearQuarterTitleInputUserKeyUpdate {
  inputUser: ProbationReviewInputUser;
  patch: UpdateProbationReviewMasterItemOnProbationReviewMasterItemForProbationReviewMasterItemUserCreatedFkeyPatch;
  quarter: PerformanceReviewQuarter;
  title: Scalars['String']['input'];
  year: Scalars['Int']['input'];
}

export interface ProbationReviewMasterItemOnProbationReviewMasterItemForProbationReviewMasterItemUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface ProbationReviewMasterItemOnProbationReviewMasterItemForProbationReviewMasterItemUserUpdatedFkeyUsingProbationReviewMasterItemPkeyUpdate {
  patch: UpdateProbationReviewMasterItemOnProbationReviewMasterItemForProbationReviewMasterItemUserUpdatedFkeyPatch;
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewMasterItemOnProbationReviewMasterItemForProbationReviewMasterItemUserUpdatedFkeyUsingProbationReviewMasterItemYearQuarterTitleInputUserKeyUpdate {
  inputUser: ProbationReviewInputUser;
  patch: UpdateProbationReviewMasterItemOnProbationReviewMasterItemForProbationReviewMasterItemUserUpdatedFkeyPatch;
  quarter: PerformanceReviewQuarter;
  title: Scalars['String']['input'];
  year: Scalars['Int']['input'];
}

export interface ProbationReviewMasterItemPatch {
  dateDisabled?: InputMaybe<Scalars['Date']['input']>;
  dateEnabled?: InputMaybe<Scalars['Date']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  inputMode?: InputMaybe<ProbationReviewInputMode>;
  inputUser?: InputMaybe<ProbationReviewInputUser>;
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewMasterItemFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey1InverseInput>;
  probationReviewMasterItemDescriptions?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemFkeyInverseInput>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  showOnSummary?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProbationReviewMasterItemProbationReviewMasterItemFkeyInput {
  connectByNodeId?: InputMaybe<ProbationReviewMasterItemNodeIdConnect>;
  connectByProbationReviewMasterItemId?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemPkeyConnect>;
  connectByYearAndQuarterAndTitleAndInputUser?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemYearQuarterTitleInputUserKeyConnect>;
  create?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemFkeyProbationReviewMasterItemCreateInput>;
  deleteByNodeId?: InputMaybe<ProbationReviewMasterItemNodeIdDelete>;
  deleteByProbationReviewMasterItemId?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemPkeyDelete>;
  deleteByYearAndQuarterAndTitleAndInputUser?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemYearQuarterTitleInputUserKeyDelete>;
  updateByNodeId?: InputMaybe<ProbationReviewMasterItemDescriptionOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemProbationReviewMasterItemFkeyNodeIdUpdate>;
  updateByProbationReviewMasterItemId?: InputMaybe<ProbationReviewMasterItemOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemProbationReviewMasterItemFkeyUsingProbationReviewMasterItemPkeyUpdate>;
  updateByYearAndQuarterAndTitleAndInputUser?: InputMaybe<ProbationReviewMasterItemOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemProbationReviewMasterItemFkeyUsingProbationReviewMasterItemYearQuarterTitleInputUserKeyUpdate>;
}

export interface ProbationReviewMasterItemProbationReviewMasterItemFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<ProbationReviewMasterItemDescriptionNodeIdConnect>>;
  connectByProbationReviewMasterItemDescriptionId?: InputMaybe<Array<ProbationReviewMasterItemDescriptionProbationReviewMasterItemDescriptionPkeyConnect>>;
  connectByProbationReviewMasterItemIdAndLanguage?: InputMaybe<Array<ProbationReviewMasterItemDescriptionProbationReviewMasterItemProbationReviewMasterItemKeyConnect>>;
  create?: InputMaybe<Array<ProbationReviewMasterItemProbationReviewMasterItemFkeyProbationReviewMasterItemDescriptionCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewMasterItemDescriptionNodeIdDelete>>;
  deleteByProbationReviewMasterItemDescriptionId?: InputMaybe<Array<ProbationReviewMasterItemDescriptionProbationReviewMasterItemDescriptionPkeyDelete>>;
  deleteByProbationReviewMasterItemIdAndLanguage?: InputMaybe<Array<ProbationReviewMasterItemDescriptionProbationReviewMasterItemProbationReviewMasterItemKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<ProbationReviewMasterItemOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemProbationReviewMasterItemFkeyNodeIdUpdate>>;
  updateByProbationReviewMasterItemDescriptionId?: InputMaybe<
    Array<ProbationReviewMasterItemDescriptionOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemProbationReviewMasterItemFkeyUsingProbationReviewMasterItemDescriptionPkeyUpdate>
  >;
  updateByProbationReviewMasterItemIdAndLanguage?: InputMaybe<
    Array<ProbationReviewMasterItemDescriptionOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemProbationReviewMasterItemFkeyUsingProbationReviewMasterItemProbationReviewMasterItemKeyUpdate>
  >;
}

export interface ProbationReviewMasterItemProbationReviewMasterItemFkeyProbationReviewMasterItemCreateInput {
  dateDisabled?: InputMaybe<Scalars['Date']['input']>;
  dateEnabled?: InputMaybe<Scalars['Date']['input']>;
  displayOrder: Scalars['Int']['input'];
  inputMode: ProbationReviewInputMode;
  inputUser: ProbationReviewInputUser;
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewMasterItemFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey1InverseInput>;
  probationReviewMasterItemDescriptions?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemFkeyInverseInput>;
  quarter: PerformanceReviewQuarter;
  remark?: InputMaybe<Scalars['String']['input']>;
  showOnSummary: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
  userAccount?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year: Scalars['Int']['input'];
}

export interface ProbationReviewMasterItemProbationReviewMasterItemFkeyProbationReviewMasterItemDescriptionCreateInput {
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemFkeyInput>;
  probationReviewMasterItemDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface ProbationReviewMasterItemProbationReviewMasterItemPkeyConnect {
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewMasterItemProbationReviewMasterItemPkeyDelete {
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface ProbationReviewMasterItemProbationReviewMasterItemYearQuarterTitleInputUserKeyConnect {
  inputUser: ProbationReviewInputUser;
  quarter: PerformanceReviewQuarter;
  title: Scalars['String']['input'];
  year: Scalars['Int']['input'];
}

export interface ProbationReviewMasterItemProbationReviewMasterItemYearQuarterTitleInputUserKeyDelete {
  inputUser: ProbationReviewInputUser;
  quarter: PerformanceReviewQuarter;
  title: Scalars['String']['input'];
  year: Scalars['Int']['input'];
}

export interface ProbationReviewMasterItemToManyProbationReviewEvaluationResultItemFilter {
  every?: InputMaybe<ProbationReviewEvaluationResultItemFilter>;
  none?: InputMaybe<ProbationReviewEvaluationResultItemFilter>;
  some?: InputMaybe<ProbationReviewEvaluationResultItemFilter>;
}

export interface ProbationReviewMasterItemToManyProbationReviewEvaluationResultScoreCompetencyFilter {
  every?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyFilter>;
  none?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyFilter>;
  some?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyFilter>;
}

export interface ProbationReviewMasterItemToManyProbationReviewEvaluationResultScoreValueFilter {
  every?: InputMaybe<ProbationReviewEvaluationResultScoreValueFilter>;
  none?: InputMaybe<ProbationReviewEvaluationResultScoreValueFilter>;
  some?: InputMaybe<ProbationReviewEvaluationResultScoreValueFilter>;
}

export interface ProbationReviewMasterItemToManyProbationReviewMasterItemDescriptionFilter {
  every?: InputMaybe<ProbationReviewMasterItemDescriptionFilter>;
  none?: InputMaybe<ProbationReviewMasterItemDescriptionFilter>;
  some?: InputMaybe<ProbationReviewMasterItemDescriptionFilter>;
}

export interface ProbationReviewMasterItemUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<ProbationReviewMasterItemNodeIdConnect>>;
  connectByProbationReviewMasterItemId?: InputMaybe<Array<ProbationReviewMasterItemProbationReviewMasterItemPkeyConnect>>;
  connectByYearAndQuarterAndTitleAndInputUser?: InputMaybe<Array<ProbationReviewMasterItemProbationReviewMasterItemYearQuarterTitleInputUserKeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewMasterItemNodeIdDelete>>;
  deleteByProbationReviewMasterItemId?: InputMaybe<Array<ProbationReviewMasterItemProbationReviewMasterItemPkeyDelete>>;
  deleteByYearAndQuarterAndTitleAndInputUser?: InputMaybe<Array<ProbationReviewMasterItemProbationReviewMasterItemYearQuarterTitleInputUserKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnProbationReviewMasterItemForProbationReviewMasterItemUserCreatedFkeyNodeIdUpdate>>;
  updateByProbationReviewMasterItemId?: InputMaybe<Array<ProbationReviewMasterItemOnProbationReviewMasterItemForProbationReviewMasterItemUserCreatedFkeyUsingProbationReviewMasterItemPkeyUpdate>>;
  updateByYearAndQuarterAndTitleAndInputUser?: InputMaybe<
    Array<ProbationReviewMasterItemOnProbationReviewMasterItemForProbationReviewMasterItemUserCreatedFkeyUsingProbationReviewMasterItemYearQuarterTitleInputUserKeyUpdate>
  >;
}

export interface ProbationReviewMasterItemUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnProbationReviewMasterItemForProbationReviewMasterItemUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<ProbationReviewMasterItemOnProbationReviewMasterItemForProbationReviewMasterItemUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnProbationReviewMasterItemForProbationReviewMasterItemUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface ProbationReviewMasterItemUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<ProbationReviewMasterItemNodeIdConnect>>;
  connectByProbationReviewMasterItemId?: InputMaybe<Array<ProbationReviewMasterItemProbationReviewMasterItemPkeyConnect>>;
  connectByYearAndQuarterAndTitleAndInputUser?: InputMaybe<Array<ProbationReviewMasterItemProbationReviewMasterItemYearQuarterTitleInputUserKeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<ProbationReviewMasterItemNodeIdDelete>>;
  deleteByProbationReviewMasterItemId?: InputMaybe<Array<ProbationReviewMasterItemProbationReviewMasterItemPkeyDelete>>;
  deleteByYearAndQuarterAndTitleAndInputUser?: InputMaybe<Array<ProbationReviewMasterItemProbationReviewMasterItemYearQuarterTitleInputUserKeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnProbationReviewMasterItemForProbationReviewMasterItemUserUpdatedFkeyNodeIdUpdate>>;
  updateByProbationReviewMasterItemId?: InputMaybe<Array<ProbationReviewMasterItemOnProbationReviewMasterItemForProbationReviewMasterItemUserUpdatedFkeyUsingProbationReviewMasterItemPkeyUpdate>>;
  updateByYearAndQuarterAndTitleAndInputUser?: InputMaybe<
    Array<ProbationReviewMasterItemOnProbationReviewMasterItemForProbationReviewMasterItemUserUpdatedFkeyUsingProbationReviewMasterItemYearQuarterTitleInputUserKeyUpdate>
  >;
}

export interface ProbationReviewMasterItemsConnection {
  __typename?: 'ProbationReviewMasterItemsConnection';
  edges: Array<ProbationReviewMasterItemsEdge>;
  nodes: Array<ProbationReviewMasterItem>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ProbationReviewMasterItemsEdge {
  __typename?: 'ProbationReviewMasterItemsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ProbationReviewMasterItem;
}

export enum ProbationReviewMasterItemsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_DISABLED_ASC = 'DATE_DISABLED_ASC',
  DATE_DISABLED_DESC = 'DATE_DISABLED_DESC',
  DATE_ENABLED_ASC = 'DATE_ENABLED_ASC',
  DATE_ENABLED_DESC = 'DATE_ENABLED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DISPLAY_ORDER_ASC = 'DISPLAY_ORDER_ASC',
  DISPLAY_ORDER_DESC = 'DISPLAY_ORDER_DESC',
  INPUT_MODE_ASC = 'INPUT_MODE_ASC',
  INPUT_MODE_DESC = 'INPUT_MODE_DESC',
  INPUT_USER_ASC = 'INPUT_USER_ASC',
  INPUT_USER_DESC = 'INPUT_USER_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__COUNT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__COUNT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__COUNT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__COUNT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_VALUE_INPUT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_VALUE_INPUT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_VALUE_INPUT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_VALUE_INPUT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_VALUE_OUTPUT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_VALUE_OUTPUT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_VALUE_OUTPUT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_VALUE_OUTPUT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_VALUE_SINGLE_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_VALUE_SINGLE_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_VALUE_SINGLE_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_VALUE_SINGLE_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_VALUE_INPUT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_VALUE_INPUT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_VALUE_INPUT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_VALUE_INPUT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_VALUE_OUTPUT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_VALUE_OUTPUT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_VALUE_OUTPUT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_VALUE_OUTPUT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_VALUE_SINGLE_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_VALUE_SINGLE_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_VALUE_SINGLE_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_VALUE_SINGLE_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__COUNT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__COUNT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__COUNT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__COUNT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_ASSESSMENT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_ASSESSMENT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_ASSESSMENT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_ASSESSMENT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_ASSESSMENT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_ASSESSMENT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_ASSESSMENT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_ASSESSMENT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__COUNT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__COUNT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__COUNT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__COUNT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_ASSESSMENT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_ASSESSMENT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_ASSESSMENT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_ASSESSMENT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_ASSESSMENT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_ASSESSMENT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_ASSESSMENT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_ASSESSMENT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__COUNT_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__COUNT_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__COUNT_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__COUNT_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DESCRIPTION_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DESCRIPTION_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DESCRIPTION_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_DESCRIPTION_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_LANGUAGE_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_LANGUAGE_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_LANGUAGE_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_LANGUAGE_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_REMARK_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_REMARK_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_REMARK_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_REMARK_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DESCRIPTION_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DESCRIPTION_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DESCRIPTION_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_DESCRIPTION_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_LANGUAGE_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_LANGUAGE_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_LANGUAGE_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_LANGUAGE_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_REMARK_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_REMARK_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_REMARK_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_REMARK_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_PROBATION_REVIEW_MASTER_ITEM_ID__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  QUARTER_ASC = 'QUARTER_ASC',
  QUARTER_DESC = 'QUARTER_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  SHOW_ON_SUMMARY_ASC = 'SHOW_ON_SUMMARY_ASC',
  SHOW_ON_SUMMARY_DESC = 'SHOW_ON_SUMMARY_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
}

export interface Query extends Node {
  __typename?: 'Query';
  allEmployeeWithYearQuarters?: Maybe<AllEmployeeWithYearQuartersConnection>;
  allYearQuarters?: Maybe<AllYearQuartersConnection>;
  appForceReload?: Maybe<AppForceReload>;
  appForceReloadByNodeId?: Maybe<AppForceReload>;
  appForceReloads?: Maybe<AppForceReloadsConnection>;
  bank?: Maybe<Bank>;
  bankByCountryCodeAlpha3AndFullName?: Maybe<Bank>;
  bankByNodeId?: Maybe<Bank>;
  bankBySwiftCode?: Maybe<Bank>;
  banks?: Maybe<BanksConnection>;
  businessUnit?: Maybe<BusinessUnit>;
  businessUnitByName?: Maybe<BusinessUnit>;
  businessUnitByNodeId?: Maybe<BusinessUnit>;
  businessUnits?: Maybe<BusinessUnitsConnection>;
  cities?: Maybe<CitiesConnection>;
  city?: Maybe<City>;
  cityByCountryCodeAlpha2AndName?: Maybe<City>;
  cityByNodeId?: Maybe<City>;
  companies?: Maybe<CompaniesConnection>;
  company?: Maybe<Company>;
  companyByAbbreviation?: Maybe<Company>;
  companyByCountryCodeAlpha2AndFullName?: Maybe<Company>;
  companyByNodeId?: Maybe<Company>;
  companyExternalAccountDisable?: Maybe<CompanyExternalAccountDisable>;
  companyExternalAccountDisableByNodeId?: Maybe<CompanyExternalAccountDisable>;
  companyExternalAccountDisables?: Maybe<CompanyExternalAccountDisablesConnection>;
  companyGlobalHr?: Maybe<CompanyGlobalHr>;
  companyGlobalHrByEmployeeId?: Maybe<CompanyGlobalHr>;
  companyGlobalHrByNodeId?: Maybe<CompanyGlobalHr>;
  companyGlobalHrs?: Maybe<CompanyGlobalHrsConnection>;
  companyGroup?: Maybe<CompanyGroup>;
  companyGroupByName?: Maybe<CompanyGroup>;
  companyGroupByNodeId?: Maybe<CompanyGroup>;
  companyGroups?: Maybe<CompanyGroupsConnection>;
  companyLocalHr?: Maybe<CompanyLocalHr>;
  companyLocalHrByCompanyIdAndEmployeeId?: Maybe<CompanyLocalHr>;
  companyLocalHrByNodeId?: Maybe<CompanyLocalHr>;
  companyLocalHrs?: Maybe<CompanyLocalHrsConnection>;
  contractType?: Maybe<ContractType>;
  contractTypeByCode?: Maybe<ContractType>;
  contractTypeByDisplayName?: Maybe<ContractType>;
  contractTypeByNodeId?: Maybe<ContractType>;
  contractTypes?: Maybe<ContractTypesConnection>;
  countries?: Maybe<CountriesConnection>;
  country?: Maybe<Country>;
  countryByCountryCodeAlpha3?: Maybe<Country>;
  countryByCountryCodeNumeric?: Maybe<Country>;
  countryByDisplayOrder?: Maybe<Country>;
  countryByNodeId?: Maybe<Country>;
  currencies?: Maybe<CurrenciesConnection>;
  currency?: Maybe<Currency>;
  currencyByNodeId?: Maybe<Currency>;
  currentEmployee?: Maybe<Employee>;
  currentUserAccount?: Maybe<UserAccount>;
  department?: Maybe<Department>;
  departmentByName?: Maybe<Department>;
  departmentByNodeId?: Maybe<Department>;
  departments?: Maybe<DepartmentsConnection>;
  division?: Maybe<Division>;
  divisionByName?: Maybe<Division>;
  divisionByNodeId?: Maybe<Division>;
  divisions?: Maybe<DivisionsConnection>;
  emailDomain?: Maybe<EmailDomain>;
  emailDomainByDomainName?: Maybe<EmailDomain>;
  emailDomainByNodeId?: Maybe<EmailDomain>;
  emailDomains?: Maybe<EmailDomainsConnection>;
  employee?: Maybe<Employee>;
  employeeAgeStatistics?: Maybe<EmployeeAgeStatisticsConnection>;
  employeeAgeStatisticsDetails?: Maybe<EmployeeAgeStatisticsDetailsConnection>;
  employeeAllManagerList?: Maybe<ReturnTypeEmployeeAllManagerListsConnection>;
  employeeAllSubordinateList?: Maybe<ReturnTypeEmployeeAllSubordinateListsConnection>;
  employeeApproverList?: Maybe<EmployeeApproverListConnection>;
  employeeByCompanyEmail?: Maybe<Employee>;
  employeeByNodeId?: Maybe<Employee>;
  employeeCurrentPosition?: Maybe<EmployeeCurrentPosition>;
  employeeCurrentPositionByNodeId?: Maybe<EmployeeCurrentPosition>;
  employeeCurrentPositions?: Maybe<EmployeeCurrentPositionsConnection>;
  employeeDaysByContractType?: Maybe<EmployeeDaysByContractType>;
  employeeDaysByContractTypeByNodeId?: Maybe<EmployeeDaysByContractType>;
  employeeDaysByContractTypes?: Maybe<EmployeeDaysByContractTypesConnection>;
  employeeDlmList?: Maybe<EmployeeDlmListConnection>;
  employeeEmergencyContact?: Maybe<EmployeeEmergencyContact>;
  employeeEmergencyContactByEmployeeIdAndRelationIdAndName?: Maybe<EmployeeEmergencyContact>;
  employeeEmergencyContactByNodeId?: Maybe<EmployeeEmergencyContact>;
  employeeEmergencyContacts?: Maybe<EmployeeEmergencyContactsConnection>;
  employeeFile?: Maybe<EmployeeFile>;
  employeeFileByNodeId?: Maybe<EmployeeFile>;
  employeeFileByS3Path?: Maybe<EmployeeFile>;
  employeeFiles?: Maybe<EmployeeFilesConnection>;
  employeeGenderStatistics?: Maybe<EmployeeGenderStatisticsRecordsConnection>;
  employeeGroup?: Maybe<EmployeeGroup>;
  employeeGroupByNodeId?: Maybe<EmployeeGroup>;
  employeeGroupLatest?: Maybe<EmployeeGroupLatest>;
  employeeGroupLatestByNodeId?: Maybe<EmployeeGroupLatest>;
  employeeGroupLatests?: Maybe<EmployeeGroupLatestsConnection>;
  employeeGroupList?: Maybe<EmployeeGroupList>;
  employeeGroupListByNodeId?: Maybe<EmployeeGroupList>;
  employeeGroupLists?: Maybe<EmployeeGroupListsConnection>;
  employeeGroups?: Maybe<EmployeeGroupsConnection>;
  employeeHeadcountByBusinessUnits?: Maybe<EmployeeHeadcountByBusinessUnitsConnection>;
  employeeHeadcountByCompanies?: Maybe<EmployeeHeadcountByCompaniesConnection>;
  employeeHeadcountByCountries?: Maybe<EmployeeHeadcountByCountriesConnection>;
  employeeHeadcountTransferredByContractTypes?: Maybe<EmployeeHeadcountTransferredByContractTypesConnection>;
  employeeHeadcountTransferreds?: Maybe<EmployeeHeadcountTransferredsConnection>;
  employeeIdType?: Maybe<EmployeeIdType>;
  employeeIdTypeByCode?: Maybe<EmployeeIdType>;
  employeeIdTypeByNodeId?: Maybe<EmployeeIdType>;
  employeeIdTypes?: Maybe<EmployeeIdTypesConnection>;
  employeeLatestTpHistoryByYearQuarters?: Maybe<EmployeeLatestTpHistoryByYearQuartersConnection>;
  employeeLevel?: Maybe<EmployeeLevel>;
  employeeLevelByDisplayName?: Maybe<EmployeeLevel>;
  employeeLevelByNodeId?: Maybe<EmployeeLevel>;
  employeeLevels?: Maybe<EmployeeLevelsConnection>;
  employeeListAtPoint?: Maybe<EmployeeListAtPointConnection>;
  employeeListCustomFilter?: Maybe<EmployeeListCustomFilter>;
  employeeListCustomFilterByNodeId?: Maybe<EmployeeListCustomFilter>;
  employeeListCustomFilterRole?: Maybe<EmployeeListCustomFilterRole>;
  employeeListCustomFilterRoleByEmployeeListCustomFilterIdAndRoleName?: Maybe<EmployeeListCustomFilterRole>;
  employeeListCustomFilterRoleByNodeId?: Maybe<EmployeeListCustomFilterRole>;
  employeeListCustomFilterRoles?: Maybe<EmployeeListCustomFilterRolesConnection>;
  employeeListCustomFilters?: Maybe<EmployeeListCustomFiltersConnection>;
  employeePrimaryManagerList?: Maybe<EmployeePrimaryManagerListConnection>;
  employeeResignationReason?: Maybe<EmployeeResignationReason>;
  employeeResignationReasonByDisplayName?: Maybe<EmployeeResignationReason>;
  employeeResignationReasonByNodeId?: Maybe<EmployeeResignationReason>;
  employeeResignationReasons?: Maybe<EmployeeResignationReasonsConnection>;
  employeeResignationType?: Maybe<EmployeeResignationType>;
  employeeResignationTypeByDisplayName?: Maybe<EmployeeResignationType>;
  employeeResignationTypeByNodeId?: Maybe<EmployeeResignationType>;
  employeeResignationTypes?: Maybe<EmployeeResignationTypesConnection>;
  employeeStatus?: Maybe<EmployeeStatus>;
  employeeStatusByEmployeeStatusOrder?: Maybe<EmployeeStatus>;
  employeeStatusByNodeId?: Maybe<EmployeeStatus>;
  employeeStatuses?: Maybe<EmployeeStatusesConnection>;
  employeeTpHistories?: Maybe<EmployeeTpHistoriesConnection>;
  employeeTpHistory?: Maybe<EmployeeTpHistory>;
  employeeTpHistoryByEmployeeIdAndEffectiveDate?: Maybe<EmployeeTpHistory>;
  employeeTpHistoryByNodeId?: Maybe<EmployeeTpHistory>;
  employeeTransferHistories?: Maybe<EmployeeTransferHistoriesConnection>;
  employeeTransferHistory?: Maybe<EmployeeTransferHistory>;
  employeeTransferHistoryByNodeId?: Maybe<EmployeeTransferHistory>;
  employeeWorkingDayStatistics?: Maybe<EmployeeWorkingDayStatisticsRecordsConnection>;
  employeeWorkingDayStatisticsAll?: Maybe<EmployeeWorkingDayStatisticsAll>;
  employeeWorkingDayStatisticsAllByNodeId?: Maybe<EmployeeWorkingDayStatisticsAll>;
  employeeWorkingDayStatisticsAlls?: Maybe<EmployeeWorkingDayStatisticsAllsConnection>;
  employees?: Maybe<EmployeesConnection>;
  exportEmployeeStatus?: Maybe<ExportEmployeeStatus>;
  exportEmployeeStatusByNodeId?: Maybe<ExportEmployeeStatus>;
  exportEmployeeStatusByUuid4?: Maybe<ExportEmployeeStatus>;
  exportEmployeeStatuses?: Maybe<ExportEmployeeStatusesConnection>;
  externalAccountCreationFaileds?: Maybe<ExternalAccountCreationFailedsConnection>;
  externalAccountEmployee?: Maybe<ExternalAccountEmployee>;
  externalAccountEmployeeByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKey?: Maybe<ExternalAccountEmployee>;
  externalAccountEmployeeByNodeId?: Maybe<ExternalAccountEmployee>;
  externalAccountEmployees?: Maybe<ExternalAccountEmployeesConnection>;
  externalAccountType?: Maybe<ExternalAccountType>;
  externalAccountTypeByName?: Maybe<ExternalAccountType>;
  externalAccountTypeByNodeId?: Maybe<ExternalAccountType>;
  externalAccountTypes?: Maybe<ExternalAccountTypesConnection>;
  flywaySchemaHistories?: Maybe<FlywaySchemaHistoriesConnection>;
  flywaySchemaHistory?: Maybe<FlywaySchemaHistory>;
  flywaySchemaHistoryByNodeId?: Maybe<FlywaySchemaHistory>;
  getBankUsage?: Maybe<Scalars['Int']['output']>;
  getCountAdministrator?: Maybe<Scalars['BigInt']['output']>;
  getCurrentEmployeeId?: Maybe<Scalars['BigInt']['output']>;
  getCurrentEmployeesByCompanyIds?: Maybe<EmployeeTpHistoriesConnection>;
  getCurrentUserAccountId?: Maybe<Scalars['BigInt']['output']>;
  getCurrentUserAccountRole?: Maybe<Scalars['String']['output']>;
  googleJwk?: Maybe<GoogleJwk>;
  googleJwkByKeyTypeAndKeyId?: Maybe<GoogleJwk>;
  googleJwkByNodeId?: Maybe<GoogleJwk>;
  googleJwks?: Maybe<GoogleJwksConnection>;
  googleWorkspaceOrgUnit?: Maybe<GoogleWorkspaceOrgUnit>;
  googleWorkspaceOrgUnitByCompanyIdAndBusinessUnitId?: Maybe<GoogleWorkspaceOrgUnit>;
  googleWorkspaceOrgUnitByNodeId?: Maybe<GoogleWorkspaceOrgUnit>;
  googleWorkspaceOrgUnits?: Maybe<GoogleWorkspaceOrgUnitsConnection>;
  importEmployeeStatus?: Maybe<ImportEmployeeStatus>;
  importEmployeeStatusByNodeId?: Maybe<ImportEmployeeStatus>;
  importEmployeeStatusByUuid4?: Maybe<ImportEmployeeStatus>;
  importEmployeeStatuses?: Maybe<ImportEmployeeStatusesConnection>;
  node?: Maybe<Node>;
  nodeId: Scalars['ID']['output'];
  office?: Maybe<Office>;
  officeByCityIdAndName?: Maybe<Office>;
  officeByNodeId?: Maybe<Office>;
  officeWeather?: Maybe<OfficeWeather>;
  officeWeatherByNodeId?: Maybe<OfficeWeather>;
  officeWeathers?: Maybe<OfficeWeathersConnection>;
  offices?: Maybe<OfficesConnection>;
  performanceReviewEvaluation?: Maybe<PerformanceReviewEvaluation>;
  performanceReviewEvaluationByNodeId?: Maybe<PerformanceReviewEvaluation>;
  performanceReviewEvaluationCompetencies?: Maybe<PerformanceReviewEvaluationCompetenciesConnection>;
  performanceReviewEvaluationCompetency?: Maybe<PerformanceReviewEvaluationCompetency>;
  performanceReviewEvaluationCompetencyByNodeId?: Maybe<PerformanceReviewEvaluationCompetency>;
  performanceReviewEvaluationCompetencyByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeId?: Maybe<PerformanceReviewEvaluationCompetency>;
  performanceReviewEvaluationCounts?: Maybe<PerformanceReviewEvaluationCountsConnection>;
  performanceReviewEvaluationEvaluator?: Maybe<PerformanceReviewEvaluationEvaluator>;
  performanceReviewEvaluationEvaluatorByNodeId?: Maybe<PerformanceReviewEvaluationEvaluator>;
  performanceReviewEvaluationEvaluatorByPerformanceReviewEvaluationIdAndEvaluatorId?: Maybe<PerformanceReviewEvaluationEvaluator>;
  performanceReviewEvaluationEvaluators?: Maybe<PerformanceReviewEvaluationEvaluatorsConnection>;
  performanceReviewEvaluationFeedback?: Maybe<PerformanceReviewEvaluationFeedback>;
  performanceReviewEvaluationFeedbackByNodeId?: Maybe<PerformanceReviewEvaluationFeedback>;
  performanceReviewEvaluationFeedbackByPerformanceReviewEvaluationIdAndFeedbackEmployeeId?: Maybe<PerformanceReviewEvaluationFeedback>;
  performanceReviewEvaluationFeedbacks?: Maybe<PerformanceReviewEvaluationFeedbacksConnection>;
  performanceReviewEvaluationKpiAssessment?: Maybe<PerformanceReviewEvaluationKpiAssessment>;
  performanceReviewEvaluationKpiAssessmentByNodeId?: Maybe<PerformanceReviewEvaluationKpiAssessment>;
  performanceReviewEvaluationKpiAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeId?: Maybe<PerformanceReviewEvaluationKpiAssessment>;
  performanceReviewEvaluationKpiAssessments?: Maybe<PerformanceReviewEvaluationKpiAssessmentsConnection>;
  performanceReviewEvaluationKpiDescription?: Maybe<PerformanceReviewEvaluationKpiDescription>;
  performanceReviewEvaluationKpiDescriptionByNodeId?: Maybe<PerformanceReviewEvaluationKpiDescription>;
  performanceReviewEvaluationKpiDescriptionByPerformanceReviewEvaluationIdAndTitle?: Maybe<PerformanceReviewEvaluationKpiDescription>;
  performanceReviewEvaluationKpiDescriptions?: Maybe<PerformanceReviewEvaluationKpiDescriptionsConnection>;
  performanceReviewEvaluationKpiResult?: Maybe<PerformanceReviewEvaluationKpiResult>;
  performanceReviewEvaluationKpiResultByNodeId?: Maybe<PerformanceReviewEvaluationKpiResult>;
  performanceReviewEvaluationKpiResultByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetId?: Maybe<PerformanceReviewEvaluationKpiResult>;
  performanceReviewEvaluationKpiResults?: Maybe<PerformanceReviewEvaluationKpiResultsConnection>;
  performanceReviewEvaluationKpiTarget?: Maybe<PerformanceReviewEvaluationKpiTarget>;
  performanceReviewEvaluationKpiTargetByNodeId?: Maybe<PerformanceReviewEvaluationKpiTarget>;
  performanceReviewEvaluationKpiTargetByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndName?: Maybe<PerformanceReviewEvaluationKpiTarget>;
  performanceReviewEvaluationKpiTargets?: Maybe<PerformanceReviewEvaluationKpiTargetsConnection>;
  performanceReviewEvaluationList?: Maybe<ReturnTypePerformanceReviewEvaluationListsConnection>;
  performanceReviewEvaluationOneOnOne?: Maybe<PerformanceReviewEvaluationOneOnOne>;
  performanceReviewEvaluationOneOnOneByNodeId?: Maybe<PerformanceReviewEvaluationOneOnOne>;
  performanceReviewEvaluationOneOnOneSelfLists?: Maybe<PerformanceReviewEvaluationOneOnOneSelfListsConnection>;
  performanceReviewEvaluationOneOnOnes?: Maybe<PerformanceReviewEvaluationOneOnOnesConnection>;
  performanceReviewEvaluationQualitativeAssessment?: Maybe<PerformanceReviewEvaluationQualitativeAssessment>;
  performanceReviewEvaluationQualitativeAssessmentByNodeId?: Maybe<PerformanceReviewEvaluationQualitativeAssessment>;
  performanceReviewEvaluationQualitativeAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeId?: Maybe<PerformanceReviewEvaluationQualitativeAssessment>;
  performanceReviewEvaluationQualitativeAssessments?: Maybe<PerformanceReviewEvaluationQualitativeAssessmentsConnection>;
  performanceReviewEvaluationQualitativeDescription?: Maybe<PerformanceReviewEvaluationQualitativeDescription>;
  performanceReviewEvaluationQualitativeDescriptionByNodeId?: Maybe<PerformanceReviewEvaluationQualitativeDescription>;
  performanceReviewEvaluationQualitativeDescriptionByPerformanceReviewEvaluationIdAndTitle?: Maybe<PerformanceReviewEvaluationQualitativeDescription>;
  performanceReviewEvaluationQualitativeDescriptions?: Maybe<PerformanceReviewEvaluationQualitativeDescriptionsConnection>;
  performanceReviewEvaluationValue?: Maybe<PerformanceReviewEvaluationValue>;
  performanceReviewEvaluationValueByNodeId?: Maybe<PerformanceReviewEvaluationValue>;
  performanceReviewEvaluationValueByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeId?: Maybe<PerformanceReviewEvaluationValue>;
  performanceReviewEvaluationValues?: Maybe<PerformanceReviewEvaluationValuesConnection>;
  performanceReviewEvaluations?: Maybe<PerformanceReviewEvaluationsConnection>;
  performanceReviewMasterCompetencies?: Maybe<PerformanceReviewMasterCompetenciesConnection>;
  performanceReviewMasterCompetency?: Maybe<PerformanceReviewMasterCompetency>;
  performanceReviewMasterCompetencyByNodeId?: Maybe<PerformanceReviewMasterCompetency>;
  performanceReviewMasterCompetencyCommon?: Maybe<PerformanceReviewMasterCompetencyCommon>;
  performanceReviewMasterCompetencyCommonByNodeId?: Maybe<PerformanceReviewMasterCompetencyCommon>;
  performanceReviewMasterCompetencyCommonDescription?: Maybe<PerformanceReviewMasterCompetencyCommonDescription>;
  performanceReviewMasterCompetencyCommonDescriptionByNodeId?: Maybe<PerformanceReviewMasterCompetencyCommonDescription>;
  performanceReviewMasterCompetencyCommonDescriptionByPerformanceReviewMasterCompetencyCommonIdAndLanguage?: Maybe<PerformanceReviewMasterCompetencyCommonDescription>;
  performanceReviewMasterCompetencyCommonDescriptions?: Maybe<PerformanceReviewMasterCompetencyCommonDescriptionsConnection>;
  performanceReviewMasterCompetencyCommons?: Maybe<PerformanceReviewMasterCompetencyCommonsConnection>;
  performanceReviewMasterCompetencyCount?: Maybe<PerformanceReviewMasterCompetencyCount>;
  performanceReviewMasterCompetencyCountByNodeId?: Maybe<PerformanceReviewMasterCompetencyCount>;
  performanceReviewMasterCompetencyCountByRoleIds?: Maybe<PerformanceReviewMasterCompetencyCountByRoleIdsConnection>;
  performanceReviewMasterCompetencyCounts?: Maybe<PerformanceReviewMasterCompetencyCountsConnection>;
  performanceReviewMasterCompetencyDetail?: Maybe<PerformanceReviewMasterCompetencyDetail>;
  performanceReviewMasterCompetencyDetailByNodeId?: Maybe<PerformanceReviewMasterCompetencyDetail>;
  performanceReviewMasterCompetencyDetailByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonId?: Maybe<PerformanceReviewMasterCompetencyDetail>;
  performanceReviewMasterCompetencyDetailDescription?: Maybe<PerformanceReviewMasterCompetencyDetailDescription>;
  performanceReviewMasterCompetencyDetailDescriptionByNodeId?: Maybe<PerformanceReviewMasterCompetencyDetailDescription>;
  performanceReviewMasterCompetencyDetailDescriptionByPerformanceReviewMasterCompetencyDetailIdAndLanguage?: Maybe<PerformanceReviewMasterCompetencyDetailDescription>;
  performanceReviewMasterCompetencyDetailDescriptions?: Maybe<PerformanceReviewMasterCompetencyDetailDescriptionsConnection>;
  performanceReviewMasterCompetencyDetails?: Maybe<PerformanceReviewMasterCompetencyDetailsConnection>;
  performanceReviewMasterCompetencyRole?: Maybe<PerformanceReviewMasterCompetencyRole>;
  performanceReviewMasterCompetencyRoleByNodeId?: Maybe<PerformanceReviewMasterCompetencyRole>;
  performanceReviewMasterCompetencyRoleByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleName?: Maybe<PerformanceReviewMasterCompetencyRole>;
  performanceReviewMasterCompetencyRoles?: Maybe<PerformanceReviewMasterCompetencyRolesConnection>;
  performanceReviewMasterDefault?: Maybe<PerformanceReviewMasterDefault>;
  performanceReviewMasterDefaultByNodeId?: Maybe<PerformanceReviewMasterDefault>;
  performanceReviewMasterDefaults?: Maybe<PerformanceReviewMasterDefaultsConnection>;
  performanceReviewMasterEvaluationViewer?: Maybe<PerformanceReviewMasterEvaluationViewer>;
  performanceReviewMasterEvaluationViewerByEmployeeIdAndYearAndQuarterAndViewerEmployeeId?: Maybe<PerformanceReviewMasterEvaluationViewer>;
  performanceReviewMasterEvaluationViewerByNodeId?: Maybe<PerformanceReviewMasterEvaluationViewer>;
  performanceReviewMasterEvaluationViewers?: Maybe<PerformanceReviewMasterEvaluationViewersConnection>;
  performanceReviewMasterExcludedEmployee?: Maybe<PerformanceReviewMasterExcludedEmployee>;
  performanceReviewMasterExcludedEmployeeByNodeId?: Maybe<PerformanceReviewMasterExcludedEmployee>;
  performanceReviewMasterExcludedEmployees?: Maybe<PerformanceReviewMasterExcludedEmployeesConnection>;
  performanceReviewMasterOneOnOne?: Maybe<PerformanceReviewMasterOneOnOne>;
  performanceReviewMasterOneOnOneByNodeId?: Maybe<PerformanceReviewMasterOneOnOne>;
  performanceReviewMasterOneOnOneDetail?: Maybe<PerformanceReviewMasterOneOnOneDetail>;
  performanceReviewMasterOneOnOneDetailByNodeId?: Maybe<PerformanceReviewMasterOneOnOneDetail>;
  performanceReviewMasterOneOnOneDetails?: Maybe<PerformanceReviewMasterOneOnOneDetailsConnection>;
  performanceReviewMasterOneOnOnes?: Maybe<PerformanceReviewMasterOneOnOnesConnection>;
  performanceReviewMasterRating?: Maybe<PerformanceReviewMasterRating>;
  performanceReviewMasterRatingByNodeId?: Maybe<PerformanceReviewMasterRating>;
  performanceReviewMasterRatingDescription?: Maybe<PerformanceReviewMasterRatingDescription>;
  performanceReviewMasterRatingDescriptionByNodeId?: Maybe<PerformanceReviewMasterRatingDescription>;
  performanceReviewMasterRatingDescriptionByPerformanceReviewMasterRatingIdAndLanguage?: Maybe<PerformanceReviewMasterRatingDescription>;
  performanceReviewMasterRatingDescriptions?: Maybe<PerformanceReviewMasterRatingDescriptionsConnection>;
  performanceReviewMasterRatings?: Maybe<PerformanceReviewMasterRatingsConnection>;
  performanceReviewMasterUsage?: Maybe<PerformanceReviewMasterUsage>;
  performanceReviewMasterUsageByNodeId?: Maybe<PerformanceReviewMasterUsage>;
  performanceReviewMasterUsageByYearAndQuarterAndCompanyId?: Maybe<PerformanceReviewMasterUsage>;
  performanceReviewMasterUsages?: Maybe<PerformanceReviewMasterUsagesConnection>;
  performanceReviewMasterValue?: Maybe<PerformanceReviewMasterValue>;
  performanceReviewMasterValueByNodeId?: Maybe<PerformanceReviewMasterValue>;
  performanceReviewMasterValueCount?: Maybe<PerformanceReviewMasterValueCount>;
  performanceReviewMasterValueCountByNodeId?: Maybe<PerformanceReviewMasterValueCount>;
  performanceReviewMasterValueCounts?: Maybe<PerformanceReviewMasterValueCountsConnection>;
  performanceReviewMasterValueDescription?: Maybe<PerformanceReviewMasterValueDescription>;
  performanceReviewMasterValueDescriptionByNodeId?: Maybe<PerformanceReviewMasterValueDescription>;
  performanceReviewMasterValueDescriptionByPerformanceReviewMasterValueIdAndLanguage?: Maybe<PerformanceReviewMasterValueDescription>;
  performanceReviewMasterValueDescriptions?: Maybe<PerformanceReviewMasterValueDescriptionsConnection>;
  performanceReviewMasterValues?: Maybe<PerformanceReviewMasterValuesConnection>;
  performanceReviewMasterWeighting?: Maybe<PerformanceReviewMasterWeighting>;
  performanceReviewMasterWeightingByNodeId?: Maybe<PerformanceReviewMasterWeighting>;
  performanceReviewMasterWeightingByYearAndQuarterAndCompanyId?: Maybe<PerformanceReviewMasterWeighting>;
  performanceReviewMasterWeightingByYearAndQuarterAndName?: Maybe<PerformanceReviewMasterWeighting>;
  performanceReviewMasterWeightingDetail?: Maybe<PerformanceReviewMasterWeightingDetail>;
  performanceReviewMasterWeightingDetailByNodeId?: Maybe<PerformanceReviewMasterWeightingDetail>;
  performanceReviewMasterWeightingDetailByPerformanceReviewMasterWeightingIdAndEmployeeLevelId?: Maybe<PerformanceReviewMasterWeightingDetail>;
  performanceReviewMasterWeightingDetails?: Maybe<PerformanceReviewMasterWeightingDetailsConnection>;
  performanceReviewMasterWeightings?: Maybe<PerformanceReviewMasterWeightingsConnection>;
  performanceReviewResults?: Maybe<PerformanceReviewResultsConnection>;
  personalIdentityType?: Maybe<PersonalIdentityType>;
  personalIdentityTypeByCode?: Maybe<PersonalIdentityType>;
  personalIdentityTypeByNodeId?: Maybe<PersonalIdentityType>;
  personalIdentityTypes?: Maybe<PersonalIdentityTypesConnection>;
  probationReviewEnable?: Maybe<ProbationReviewEnable>;
  probationReviewEnableByCompanyIdAndContractTypeId?: Maybe<ProbationReviewEnable>;
  probationReviewEnableByNodeId?: Maybe<ProbationReviewEnable>;
  probationReviewEnableView?: Maybe<ProbationReviewEnableView>;
  probationReviewEnableViewByNodeId?: Maybe<ProbationReviewEnableView>;
  probationReviewEnableViews?: Maybe<ProbationReviewEnableViewsConnection>;
  probationReviewEnables?: Maybe<ProbationReviewEnablesConnection>;
  probationReviewEvaluation?: Maybe<ProbationReviewEvaluation>;
  probationReviewEvaluationByEmployeeIdAndReviewDatePlanned?: Maybe<ProbationReviewEvaluation>;
  probationReviewEvaluationByNodeId?: Maybe<ProbationReviewEvaluation>;
  probationReviewEvaluationList?: Maybe<ReturnTypeProbationReviewEvaluationListsConnection>;
  probationReviewEvaluationResultItem?: Maybe<ProbationReviewEvaluationResultItem>;
  probationReviewEvaluationResultItemByNodeId?: Maybe<ProbationReviewEvaluationResultItem>;
  probationReviewEvaluationResultItemByProbationReviewEvaluationIdAndProbationReviewMasterItemId?: Maybe<ProbationReviewEvaluationResultItem>;
  probationReviewEvaluationResultItems?: Maybe<ProbationReviewEvaluationResultItemsConnection>;
  probationReviewEvaluationResultScoreCompetencies?: Maybe<ProbationReviewEvaluationResultScoreCompetenciesConnection>;
  probationReviewEvaluationResultScoreCompetency?: Maybe<ProbationReviewEvaluationResultScoreCompetency>;
  probationReviewEvaluationResultScoreCompetencyByNodeId?: Maybe<ProbationReviewEvaluationResultScoreCompetency>;
  probationReviewEvaluationResultScoreCompetencyByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyId?: Maybe<ProbationReviewEvaluationResultScoreCompetency>;
  probationReviewEvaluationResultScoreValue?: Maybe<ProbationReviewEvaluationResultScoreValue>;
  probationReviewEvaluationResultScoreValueByNodeId?: Maybe<ProbationReviewEvaluationResultScoreValue>;
  probationReviewEvaluationResultScoreValueByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueId?: Maybe<ProbationReviewEvaluationResultScoreValue>;
  probationReviewEvaluationResultScoreValues?: Maybe<ProbationReviewEvaluationResultScoreValuesConnection>;
  probationReviewEvaluationReviewer?: Maybe<ProbationReviewEvaluationReviewer>;
  probationReviewEvaluationReviewerByNodeId?: Maybe<ProbationReviewEvaluationReviewer>;
  probationReviewEvaluationReviewers?: Maybe<ProbationReviewEvaluationReviewersConnection>;
  probationReviewEvaluations?: Maybe<ProbationReviewEvaluationsConnection>;
  probationReviewMasterItem?: Maybe<ProbationReviewMasterItem>;
  probationReviewMasterItemByNodeId?: Maybe<ProbationReviewMasterItem>;
  probationReviewMasterItemByYearAndQuarterAndTitleAndInputUser?: Maybe<ProbationReviewMasterItem>;
  probationReviewMasterItemDescription?: Maybe<ProbationReviewMasterItemDescription>;
  probationReviewMasterItemDescriptionByNodeId?: Maybe<ProbationReviewMasterItemDescription>;
  probationReviewMasterItemDescriptionByProbationReviewMasterItemIdAndLanguage?: Maybe<ProbationReviewMasterItemDescription>;
  probationReviewMasterItemDescriptions?: Maybe<ProbationReviewMasterItemDescriptionsConnection>;
  probationReviewMasterItems?: Maybe<ProbationReviewMasterItemsConnection>;
  query: Query;
  relationBusinessUnitDivision?: Maybe<RelationBusinessUnitDivision>;
  relationBusinessUnitDivisionByNodeId?: Maybe<RelationBusinessUnitDivision>;
  relationBusinessUnitDivisionDepartment?: Maybe<RelationBusinessUnitDivisionDepartment>;
  relationBusinessUnitDivisionDepartmentByNodeId?: Maybe<RelationBusinessUnitDivisionDepartment>;
  relationBusinessUnitDivisionDepartments?: Maybe<RelationBusinessUnitDivisionDepartmentsConnection>;
  relationBusinessUnitDivisions?: Maybe<RelationBusinessUnitDivisionsConnection>;
  relationCityCompanies?: Maybe<RelationCityCompaniesConnection>;
  relationCityCompany?: Maybe<RelationCityCompany>;
  relationCityCompanyByNodeId?: Maybe<RelationCityCompany>;
  relationCompanyBusinessUnit?: Maybe<RelationCompanyBusinessUnit>;
  relationCompanyBusinessUnitByNodeId?: Maybe<RelationCompanyBusinessUnit>;
  relationCompanyBusinessUnits?: Maybe<RelationCompanyBusinessUnitsConnection>;
  relationCompanyGroupEmailDomain?: Maybe<RelationCompanyGroupEmailDomain>;
  relationCompanyGroupEmailDomainByNodeId?: Maybe<RelationCompanyGroupEmailDomain>;
  relationCompanyGroupEmailDomains?: Maybe<RelationCompanyGroupEmailDomainsConnection>;
  relationCompanyOffice?: Maybe<RelationCompanyOffice>;
  relationCompanyOfficeByNodeId?: Maybe<RelationCompanyOffice>;
  relationCompanyOffices?: Maybe<RelationCompanyOfficesConnection>;
  relationCurrencyCountries?: Maybe<RelationCurrencyCountriesConnection>;
  relationCurrencyCountry?: Maybe<RelationCurrencyCountry>;
  relationCurrencyCountryByNodeId?: Maybe<RelationCurrencyCountry>;
  relationEmergencyContact?: Maybe<RelationEmergencyContact>;
  relationEmergencyContactByDisplayName?: Maybe<RelationEmergencyContact>;
  relationEmergencyContactByNodeId?: Maybe<RelationEmergencyContact>;
  relationEmergencyContacts?: Maybe<RelationEmergencyContactsConnection>;
  relationEmployeeUserAccount?: Maybe<RelationEmployeeUserAccount>;
  relationEmployeeUserAccountByNodeId?: Maybe<RelationEmployeeUserAccount>;
  relationEmployeeUserAccounts?: Maybe<RelationEmployeeUserAccountsConnection>;
  reportEmployeeTotalCountAllByContractTypes?: Maybe<ReportEmployeeTotalCountAllByContractTypesConnection>;
  reportEmployeeTotalCountAlls?: Maybe<ReportEmployeeTotalCountAllsConnection>;
  reportEmployeeTotalCountCities?: Maybe<ReportEmployeeTotalCountCitiesConnection>;
  reportEmployeeTotalCountCityByContractTypes?: Maybe<ReportEmployeeTotalCountCityByContractTypesConnection>;
  reportEmployeeTotalCountCompanies?: Maybe<ReportEmployeeTotalCountCompaniesConnection>;
  reportEmployeeTotalCountCompanyByContractTypes?: Maybe<ReportEmployeeTotalCountCompanyByContractTypesConnection>;
  reportEmployeeTpHistoryByFlags?: Maybe<ReportEmployeeTpHistoryByFlagsConnection>;
  tableAudits?: Maybe<TableAuditsConnection>;
  tablePrimaryKeys?: Maybe<TablePrimaryKeysConnection>;
  userAccount?: Maybe<UserAccount>;
  userAccountAccessHistories?: Maybe<UserAccountAccessHistoriesConnection>;
  userAccountAccessHistory?: Maybe<UserAccountAccessHistory>;
  userAccountAccessHistoryByNodeId?: Maybe<UserAccountAccessHistory>;
  userAccountByEmailAddress?: Maybe<UserAccount>;
  userAccountByNodeId?: Maybe<UserAccount>;
  userAccountPasswordCount?: Maybe<UserAccountPasswordCountConnection>;
  userAccountPin?: Maybe<UserAccountPin>;
  userAccountPinByNodeId?: Maybe<UserAccountPin>;
  userAccountPins?: Maybe<UserAccountPinsConnection>;
  userAccounts?: Maybe<UserAccountsConnection>;
  userForceLogout?: Maybe<UserForceLogout>;
  userForceLogoutByNodeId?: Maybe<UserForceLogout>;
  userForceLogouts?: Maybe<UserForceLogoutsConnection>;
  userHrManagingCompanies?: Maybe<UserHrManagingCompaniesConnection>;
  userHrManagingCompany?: Maybe<UserHrManagingCompany>;
  userHrManagingCompanyByNodeId?: Maybe<UserHrManagingCompany>;
  userHrManagingCompanyByUserAccountIdAndCompanyId?: Maybe<UserHrManagingCompany>;
  userRoleByRoleName?: Maybe<UserRole>;
  userRoles?: Maybe<UserRolesConnection>;
  worldPlace?: Maybe<WorldPlace>;
  worldPlaceByDisplayOrder?: Maybe<WorldPlace>;
  worldPlaceByNodeId?: Maybe<WorldPlace>;
  worldPlaceWeather?: Maybe<WorldPlaceWeather>;
  worldPlaceWeatherByNodeId?: Maybe<WorldPlaceWeather>;
  worldPlaceWeathers?: Maybe<WorldPlaceWeathersConnection>;
  worldPlaces?: Maybe<WorldPlacesConnection>;
}

export type QueryAllEmployeeWithYearQuartersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AllEmployeeWithYearQuarterCondition>;
  filter?: InputMaybe<AllEmployeeWithYearQuarterFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AllEmployeeWithYearQuartersOrderBy>>;
};

export type QueryAllYearQuartersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AllYearQuarterCondition>;
  filter?: InputMaybe<AllYearQuarterFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AllYearQuartersOrderBy>>;
};

export type QueryAppForceReloadArgs = {
  appForceReloadId: Scalars['BigInt']['input'];
};

export type QueryAppForceReloadByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryAppForceReloadsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AppForceReloadCondition>;
  filter?: InputMaybe<AppForceReloadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AppForceReloadsOrderBy>>;
};

export type QueryBankArgs = {
  bankId: Scalars['BigInt']['input'];
};

export type QueryBankByCountryCodeAlpha3AndFullNameArgs = {
  countryCodeAlpha3: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
};

export type QueryBankByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryBankBySwiftCodeArgs = {
  swiftCode: Scalars['String']['input'];
};

export type QueryBanksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<BankCondition>;
  filter?: InputMaybe<BankFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BanksOrderBy>>;
};

export type QueryBusinessUnitArgs = {
  businessUnitId: Scalars['BigInt']['input'];
};

export type QueryBusinessUnitByNameArgs = {
  name: Scalars['String']['input'];
};

export type QueryBusinessUnitByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryBusinessUnitsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<BusinessUnitCondition>;
  filter?: InputMaybe<BusinessUnitFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BusinessUnitsOrderBy>>;
};

export type QueryCitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CityCondition>;
  filter?: InputMaybe<CityFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CitiesOrderBy>>;
};

export type QueryCityArgs = {
  cityId: Scalars['BigInt']['input'];
};

export type QueryCityByCountryCodeAlpha2AndNameArgs = {
  countryCodeAlpha2: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type QueryCityByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryCompaniesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CompanyCondition>;
  filter?: InputMaybe<CompanyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompaniesOrderBy>>;
};

export type QueryCompanyArgs = {
  companyId: Scalars['BigInt']['input'];
};

export type QueryCompanyByAbbreviationArgs = {
  abbreviation: Scalars['String']['input'];
};

export type QueryCompanyByCountryCodeAlpha2AndFullNameArgs = {
  countryCodeAlpha2: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
};

export type QueryCompanyByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryCompanyExternalAccountDisableArgs = {
  companyId: Scalars['BigInt']['input'];
  externalAccountTypeKey: ExternalAccountEmployeeType;
};

export type QueryCompanyExternalAccountDisableByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryCompanyExternalAccountDisablesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CompanyExternalAccountDisableCondition>;
  filter?: InputMaybe<CompanyExternalAccountDisableFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyExternalAccountDisablesOrderBy>>;
};

export type QueryCompanyGlobalHrArgs = {
  companyGlobalHrId: Scalars['BigInt']['input'];
};

export type QueryCompanyGlobalHrByEmployeeIdArgs = {
  employeeId: Scalars['BigInt']['input'];
};

export type QueryCompanyGlobalHrByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryCompanyGlobalHrsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CompanyGlobalHrCondition>;
  filter?: InputMaybe<CompanyGlobalHrFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyGlobalHrsOrderBy>>;
};

export type QueryCompanyGroupArgs = {
  companyGroupId: Scalars['BigInt']['input'];
};

export type QueryCompanyGroupByNameArgs = {
  name: Scalars['String']['input'];
};

export type QueryCompanyGroupByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryCompanyGroupsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CompanyGroupCondition>;
  filter?: InputMaybe<CompanyGroupFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyGroupsOrderBy>>;
};

export type QueryCompanyLocalHrArgs = {
  companyLocalHrId: Scalars['BigInt']['input'];
};

export type QueryCompanyLocalHrByCompanyIdAndEmployeeIdArgs = {
  companyId: Scalars['BigInt']['input'];
  employeeId: Scalars['BigInt']['input'];
};

export type QueryCompanyLocalHrByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryCompanyLocalHrsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CompanyLocalHrCondition>;
  filter?: InputMaybe<CompanyLocalHrFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyLocalHrsOrderBy>>;
};

export type QueryContractTypeArgs = {
  contractTypeId: Scalars['BigInt']['input'];
};

export type QueryContractTypeByCodeArgs = {
  code: Scalars['String']['input'];
};

export type QueryContractTypeByDisplayNameArgs = {
  displayName: Scalars['String']['input'];
};

export type QueryContractTypeByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryContractTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ContractTypeCondition>;
  filter?: InputMaybe<ContractTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContractTypesOrderBy>>;
};

export type QueryCountriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CountryCondition>;
  filter?: InputMaybe<CountryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CountriesOrderBy>>;
};

export type QueryCountryArgs = {
  countryCodeAlpha2: Scalars['String']['input'];
};

export type QueryCountryByCountryCodeAlpha3Args = {
  countryCodeAlpha3: Scalars['String']['input'];
};

export type QueryCountryByCountryCodeNumericArgs = {
  countryCodeNumeric: Scalars['String']['input'];
};

export type QueryCountryByDisplayOrderArgs = {
  displayOrder: Scalars['Int']['input'];
};

export type QueryCountryByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryCurrenciesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CurrencyCondition>;
  filter?: InputMaybe<CurrencyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CurrenciesOrderBy>>;
};

export type QueryCurrencyArgs = {
  currencyCode: Scalars['String']['input'];
};

export type QueryCurrencyByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryDepartmentArgs = {
  departmentId: Scalars['BigInt']['input'];
};

export type QueryDepartmentByNameArgs = {
  name: Scalars['String']['input'];
};

export type QueryDepartmentByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryDepartmentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DepartmentCondition>;
  filter?: InputMaybe<DepartmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DepartmentsOrderBy>>;
};

export type QueryDivisionArgs = {
  divisionId: Scalars['BigInt']['input'];
};

export type QueryDivisionByNameArgs = {
  name: Scalars['String']['input'];
};

export type QueryDivisionByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryDivisionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DivisionCondition>;
  filter?: InputMaybe<DivisionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DivisionsOrderBy>>;
};

export type QueryEmailDomainArgs = {
  emailDomainId: Scalars['BigInt']['input'];
};

export type QueryEmailDomainByDomainNameArgs = {
  domainName: Scalars['String']['input'];
};

export type QueryEmailDomainByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryEmailDomainsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmailDomainCondition>;
  filter?: InputMaybe<EmailDomainFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmailDomainsOrderBy>>;
};

export type QueryEmployeeArgs = {
  employeeId: Scalars['BigInt']['input'];
};

export type QueryEmployeeAgeStatisticsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeAgeStatisticCondition>;
  filter?: InputMaybe<EmployeeAgeStatisticFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeAgeStatisticsOrderBy>>;
};

export type QueryEmployeeAgeStatisticsDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeAgeStatisticsDetailCondition>;
  filter?: InputMaybe<EmployeeAgeStatisticsDetailFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeAgeStatisticsDetailsOrderBy>>;
};

export type QueryEmployeeAllManagerListArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  baseEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<ReturnTypeEmployeeAllManagerListFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReturnTypeEmployeeAllManagerListsOrderBy>>;
};

export type QueryEmployeeAllSubordinateListArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  baseEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<ReturnTypeEmployeeAllSubordinateListFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReturnTypeEmployeeAllSubordinateListsOrderBy>>;
};

export type QueryEmployeeApproverListArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  baseEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<EmployeeApproverListRecordFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryEmployeeByCompanyEmailArgs = {
  companyEmail: Scalars['String']['input'];
};

export type QueryEmployeeByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryEmployeeCurrentPositionArgs = {
  employeeId: Scalars['BigInt']['input'];
};

export type QueryEmployeeCurrentPositionByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryEmployeeCurrentPositionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCurrentPositionCondition>;
  filter?: InputMaybe<EmployeeCurrentPositionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeCurrentPositionsOrderBy>>;
};

export type QueryEmployeeDaysByContractTypeArgs = {
  effectiveDate: Scalars['Date']['input'];
  employeeId: Scalars['BigInt']['input'];
};

export type QueryEmployeeDaysByContractTypeByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryEmployeeDaysByContractTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeDaysByContractTypeCondition>;
  filter?: InputMaybe<EmployeeDaysByContractTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeDaysByContractTypesOrderBy>>;
};

export type QueryEmployeeDlmListArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  baseEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<EmployeeDlmListRecordFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryEmployeeEmergencyContactArgs = {
  employeeEmergencyContactId: Scalars['BigInt']['input'];
};

export type QueryEmployeeEmergencyContactByEmployeeIdAndRelationIdAndNameArgs = {
  employeeId: Scalars['BigInt']['input'];
  name: Scalars['String']['input'];
  relationId: Scalars['BigInt']['input'];
};

export type QueryEmployeeEmergencyContactByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryEmployeeEmergencyContactsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeEmergencyContactCondition>;
  filter?: InputMaybe<EmployeeEmergencyContactFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeEmergencyContactsOrderBy>>;
};

export type QueryEmployeeFileArgs = {
  employeeFileId: Scalars['BigInt']['input'];
};

export type QueryEmployeeFileByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryEmployeeFileByS3PathArgs = {
  s3Path: Scalars['String']['input'];
};

export type QueryEmployeeFilesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeFileCondition>;
  filter?: InputMaybe<EmployeeFileFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeFilesOrderBy>>;
};

export type QueryEmployeeGenderStatisticsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  baseDate?: InputMaybe<Scalars['Date']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<EmployeeGenderStatisticsRecordFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryEmployeeGroupArgs = {
  companyEmail: Scalars['String']['input'];
  employeeId: Scalars['BigInt']['input'];
};

export type QueryEmployeeGroupByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryEmployeeGroupLatestArgs = {
  employeeId: Scalars['BigInt']['input'];
};

export type QueryEmployeeGroupLatestByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryEmployeeGroupLatestsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeGroupLatestCondition>;
  filter?: InputMaybe<EmployeeGroupLatestFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeGroupLatestsOrderBy>>;
};

export type QueryEmployeeGroupListArgs = {
  companyEmail: Scalars['String']['input'];
  employeeId: Scalars['BigInt']['input'];
};

export type QueryEmployeeGroupListByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryEmployeeGroupListsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeGroupListCondition>;
  filter?: InputMaybe<EmployeeGroupListFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeGroupListsOrderBy>>;
};

export type QueryEmployeeGroupsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeGroupCondition>;
  filter?: InputMaybe<EmployeeGroupFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeGroupsOrderBy>>;
};

export type QueryEmployeeHeadcountByBusinessUnitsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeHeadcountByBusinessUnitCondition>;
  filter?: InputMaybe<EmployeeHeadcountByBusinessUnitFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeHeadcountByBusinessUnitsOrderBy>>;
};

export type QueryEmployeeHeadcountByCompaniesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeHeadcountByCompanyCondition>;
  filter?: InputMaybe<EmployeeHeadcountByCompanyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeHeadcountByCompaniesOrderBy>>;
};

export type QueryEmployeeHeadcountByCountriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeHeadcountByCountryCondition>;
  filter?: InputMaybe<EmployeeHeadcountByCountryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeHeadcountByCountriesOrderBy>>;
};

export type QueryEmployeeHeadcountTransferredByContractTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeHeadcountTransferredByContractTypeCondition>;
  filter?: InputMaybe<EmployeeHeadcountTransferredByContractTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeHeadcountTransferredByContractTypesOrderBy>>;
};

export type QueryEmployeeHeadcountTransferredsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeHeadcountTransferredCondition>;
  filter?: InputMaybe<EmployeeHeadcountTransferredFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeHeadcountTransferredsOrderBy>>;
};

export type QueryEmployeeIdTypeArgs = {
  employeeIdTypeId: Scalars['BigInt']['input'];
};

export type QueryEmployeeIdTypeByCodeArgs = {
  code: Scalars['String']['input'];
};

export type QueryEmployeeIdTypeByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryEmployeeIdTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeIdTypeCondition>;
  filter?: InputMaybe<EmployeeIdTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeIdTypesOrderBy>>;
};

export type QueryEmployeeLatestTpHistoryByYearQuartersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeLatestTpHistoryByYearQuarterCondition>;
  filter?: InputMaybe<EmployeeLatestTpHistoryByYearQuarterFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeLatestTpHistoryByYearQuartersOrderBy>>;
};

export type QueryEmployeeLevelArgs = {
  employeeLevelId: Scalars['BigInt']['input'];
};

export type QueryEmployeeLevelByDisplayNameArgs = {
  displayName: Scalars['String']['input'];
};

export type QueryEmployeeLevelByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryEmployeeLevelsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeLevelCondition>;
  filter?: InputMaybe<EmployeeLevelFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeLevelsOrderBy>>;
};

export type QueryEmployeeListAtPointArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  baseDate?: InputMaybe<Scalars['Date']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<EmployeeListAtPointRecordFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryEmployeeListCustomFilterArgs = {
  employeeListCustomFilterId: Scalars['BigInt']['input'];
};

export type QueryEmployeeListCustomFilterByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryEmployeeListCustomFilterRoleArgs = {
  employeeListCustomFilterRoleId: Scalars['BigInt']['input'];
};

export type QueryEmployeeListCustomFilterRoleByEmployeeListCustomFilterIdAndRoleNameArgs = {
  employeeListCustomFilterId: Scalars['BigInt']['input'];
  roleName: Scalars['String']['input'];
};

export type QueryEmployeeListCustomFilterRoleByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryEmployeeListCustomFilterRolesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeListCustomFilterRoleCondition>;
  filter?: InputMaybe<EmployeeListCustomFilterRoleFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeListCustomFilterRolesOrderBy>>;
};

export type QueryEmployeeListCustomFiltersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeListCustomFilterCondition>;
  filter?: InputMaybe<EmployeeListCustomFilterFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeListCustomFiltersOrderBy>>;
};

export type QueryEmployeePrimaryManagerListArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  baseEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<EmployeePrimaryManagerListRecordFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryEmployeeResignationReasonArgs = {
  employeeResignationReasonId: Scalars['BigInt']['input'];
};

export type QueryEmployeeResignationReasonByDisplayNameArgs = {
  displayName: Scalars['String']['input'];
};

export type QueryEmployeeResignationReasonByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryEmployeeResignationReasonsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeResignationReasonCondition>;
  filter?: InputMaybe<EmployeeResignationReasonFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeResignationReasonsOrderBy>>;
};

export type QueryEmployeeResignationTypeArgs = {
  employeeResignationTypeId: Scalars['BigInt']['input'];
};

export type QueryEmployeeResignationTypeByDisplayNameArgs = {
  displayName: Scalars['String']['input'];
};

export type QueryEmployeeResignationTypeByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryEmployeeResignationTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeResignationTypeCondition>;
  filter?: InputMaybe<EmployeeResignationTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeResignationTypesOrderBy>>;
};

export type QueryEmployeeStatusArgs = {
  employeeStatusKey: EmployeeStatusKey;
};

export type QueryEmployeeStatusByEmployeeStatusOrderArgs = {
  employeeStatusOrder: Scalars['BigInt']['input'];
};

export type QueryEmployeeStatusByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryEmployeeStatusesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeStatusCondition>;
  filter?: InputMaybe<EmployeeStatusFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeStatusesOrderBy>>;
};

export type QueryEmployeeTpHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeTpHistoryCondition>;
  filter?: InputMaybe<EmployeeTpHistoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeTpHistoriesOrderBy>>;
};

export type QueryEmployeeTpHistoryArgs = {
  employeeTpHistoryId: Scalars['BigInt']['input'];
};

export type QueryEmployeeTpHistoryByEmployeeIdAndEffectiveDateArgs = {
  effectiveDate: Scalars['Date']['input'];
  employeeId: Scalars['BigInt']['input'];
};

export type QueryEmployeeTpHistoryByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryEmployeeTransferHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeTransferHistoryCondition>;
  filter?: InputMaybe<EmployeeTransferHistoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeTransferHistoriesOrderBy>>;
};

export type QueryEmployeeTransferHistoryArgs = {
  employeeId: Scalars['BigInt']['input'];
  employeeTpHistoryId: Scalars['BigInt']['input'];
};

export type QueryEmployeeTransferHistoryByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryEmployeeWorkingDayStatisticsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  baseDate?: InputMaybe<Scalars['Date']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<EmployeeWorkingDayStatisticsRecordFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryEmployeeWorkingDayStatisticsAllArgs = {
  employeeId: Scalars['BigInt']['input'];
};

export type QueryEmployeeWorkingDayStatisticsAllByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryEmployeeWorkingDayStatisticsAllsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeWorkingDayStatisticsAllCondition>;
  filter?: InputMaybe<EmployeeWorkingDayStatisticsAllFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeWorkingDayStatisticsAllsOrderBy>>;
};

export type QueryEmployeesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCondition>;
  filter?: InputMaybe<EmployeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeesOrderBy>>;
};

export type QueryExportEmployeeStatusArgs = {
  exportEmployeeStatusId: Scalars['BigInt']['input'];
};

export type QueryExportEmployeeStatusByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryExportEmployeeStatusByUuid4Args = {
  uuid4: Scalars['String']['input'];
};

export type QueryExportEmployeeStatusesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ExportEmployeeStatusCondition>;
  filter?: InputMaybe<ExportEmployeeStatusFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ExportEmployeeStatusesOrderBy>>;
};

export type QueryExternalAccountCreationFailedsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ExternalAccountCreationFailedCondition>;
  filter?: InputMaybe<ExternalAccountCreationFailedFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ExternalAccountCreationFailedsOrderBy>>;
};

export type QueryExternalAccountEmployeeArgs = {
  externalAccountEmployeeId: Scalars['BigInt']['input'];
};

export type QueryExternalAccountEmployeeByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKeyArgs = {
  employeeId: Scalars['BigInt']['input'];
  externalAccountKey: Scalars['String']['input'];
  externalAccountTypeKey: ExternalAccountEmployeeType;
};

export type QueryExternalAccountEmployeeByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryExternalAccountEmployeesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ExternalAccountEmployeeCondition>;
  filter?: InputMaybe<ExternalAccountEmployeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ExternalAccountEmployeesOrderBy>>;
};

export type QueryExternalAccountTypeArgs = {
  key: ExternalAccountEmployeeType;
};

export type QueryExternalAccountTypeByNameArgs = {
  name: Scalars['String']['input'];
};

export type QueryExternalAccountTypeByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryExternalAccountTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ExternalAccountTypeCondition>;
  filter?: InputMaybe<ExternalAccountTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ExternalAccountTypesOrderBy>>;
};

export type QueryFlywaySchemaHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FlywaySchemaHistoryCondition>;
  filter?: InputMaybe<FlywaySchemaHistoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FlywaySchemaHistoriesOrderBy>>;
};

export type QueryFlywaySchemaHistoryArgs = {
  installedRank: Scalars['Int']['input'];
};

export type QueryFlywaySchemaHistoryByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryGetBankUsageArgs = {
  _bankId?: InputMaybe<Scalars['BigInt']['input']>;
};

export type QueryGetCurrentEmployeesByCompanyIdsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  filter?: InputMaybe<EmployeeTpHistoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGoogleJwkArgs = {
  googleJwkId: Scalars['BigInt']['input'];
};

export type QueryGoogleJwkByKeyTypeAndKeyIdArgs = {
  keyId: Scalars['String']['input'];
  keyType: Scalars['String']['input'];
};

export type QueryGoogleJwkByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryGoogleJwksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GoogleJwkCondition>;
  filter?: InputMaybe<GoogleJwkFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GoogleJwksOrderBy>>;
};

export type QueryGoogleWorkspaceOrgUnitArgs = {
  googleWorkspaceOrgUnitId: Scalars['BigInt']['input'];
};

export type QueryGoogleWorkspaceOrgUnitByCompanyIdAndBusinessUnitIdArgs = {
  businessUnitId: Scalars['BigInt']['input'];
  companyId: Scalars['BigInt']['input'];
};

export type QueryGoogleWorkspaceOrgUnitByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryGoogleWorkspaceOrgUnitsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GoogleWorkspaceOrgUnitCondition>;
  filter?: InputMaybe<GoogleWorkspaceOrgUnitFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GoogleWorkspaceOrgUnitsOrderBy>>;
};

export type QueryImportEmployeeStatusArgs = {
  importEmployeeStatusId: Scalars['BigInt']['input'];
};

export type QueryImportEmployeeStatusByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryImportEmployeeStatusByUuid4Args = {
  uuid4: Scalars['String']['input'];
};

export type QueryImportEmployeeStatusesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ImportEmployeeStatusCondition>;
  filter?: InputMaybe<ImportEmployeeStatusFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ImportEmployeeStatusesOrderBy>>;
};

export type QueryNodeArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryOfficeArgs = {
  officeId: Scalars['BigInt']['input'];
};

export type QueryOfficeByCityIdAndNameArgs = {
  cityId: Scalars['BigInt']['input'];
  name: Scalars['String']['input'];
};

export type QueryOfficeByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryOfficeWeatherArgs = {
  day: Scalars['Date']['input'];
  officeId: Scalars['BigInt']['input'];
};

export type QueryOfficeWeatherByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryOfficeWeathersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<OfficeWeatherCondition>;
  filter?: InputMaybe<OfficeWeatherFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OfficeWeathersOrderBy>>;
};

export type QueryOfficesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<OfficeCondition>;
  filter?: InputMaybe<OfficeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OfficesOrderBy>>;
};

export type QueryPerformanceReviewEvaluationArgs = {
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewEvaluationByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewEvaluationCompetenciesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationCompetencyCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationCompetencyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationCompetenciesOrderBy>>;
};

export type QueryPerformanceReviewEvaluationCompetencyArgs = {
  performanceReviewEvaluationCompetencyId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewEvaluationCompetencyByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewEvaluationCompetencyByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeIdArgs = {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewMasterCompetencyId: Scalars['String']['input'];
};

export type QueryPerformanceReviewEvaluationCountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationCountCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationCountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationCountsOrderBy>>;
};

export type QueryPerformanceReviewEvaluationEvaluatorArgs = {
  performanceReviewEvaluationEvaluatorId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewEvaluationEvaluatorByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewEvaluationEvaluatorByPerformanceReviewEvaluationIdAndEvaluatorIdArgs = {
  evaluatorId: Scalars['BigInt']['input'];
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewEvaluationEvaluatorsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationEvaluatorCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationEvaluatorFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorsOrderBy>>;
};

export type QueryPerformanceReviewEvaluationFeedbackArgs = {
  performanceReviewEvaluationFeedbackId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewEvaluationFeedbackByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewEvaluationFeedbackByPerformanceReviewEvaluationIdAndFeedbackEmployeeIdArgs = {
  feedbackEmployeeId: Scalars['BigInt']['input'];
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewEvaluationFeedbacksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationFeedbackCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationFeedbackFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationFeedbacksOrderBy>>;
};

export type QueryPerformanceReviewEvaluationKpiAssessmentArgs = {
  performanceReviewEvaluationKpiAssessmentId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewEvaluationKpiAssessmentByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewEvaluationKpiAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeIdArgs = {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewEvaluationKpiAssessmentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentsOrderBy>>;
};

export type QueryPerformanceReviewEvaluationKpiDescriptionArgs = {
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewEvaluationKpiDescriptionByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewEvaluationKpiDescriptionByPerformanceReviewEvaluationIdAndTitleArgs = {
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  title: Scalars['String']['input'];
};

export type QueryPerformanceReviewEvaluationKpiDescriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionsOrderBy>>;
};

export type QueryPerformanceReviewEvaluationKpiResultArgs = {
  performanceReviewEvaluationKpiResultId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewEvaluationKpiResultByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewEvaluationKpiResultByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetIdArgs = {
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiTargetId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewEvaluationKpiResultsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationKpiResultCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationKpiResultFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultsOrderBy>>;
};

export type QueryPerformanceReviewEvaluationKpiTargetArgs = {
  performanceReviewEvaluationKpiTargetId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewEvaluationKpiTargetByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewEvaluationKpiTargetByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndNameArgs = {
  name: Scalars['String']['input'];
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewEvaluationKpiTargetsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationKpiTargetCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationKpiTargetFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetsOrderBy>>;
};

export type QueryPerformanceReviewEvaluationListArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<ReturnTypePerformanceReviewEvaluationListFilter>;
  filterIsEvaluator?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hideResigned?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReturnTypePerformanceReviewEvaluationListsOrderBy>>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryPerformanceReviewEvaluationOneOnOneArgs = {
  performanceReviewEvaluationOneOnOneId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewEvaluationOneOnOneByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewEvaluationOneOnOneSelfListsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationOneOnOneSelfListCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationOneOnOneSelfListFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOneSelfListsOrderBy>>;
};

export type QueryPerformanceReviewEvaluationOneOnOnesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationOneOnOneCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationOneOnOneFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOnesOrderBy>>;
};

export type QueryPerformanceReviewEvaluationQualitativeAssessmentArgs = {
  performanceReviewEvaluationQualitativeAssessmentId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewEvaluationQualitativeAssessmentByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewEvaluationQualitativeAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeIdArgs = {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationQualitativeDescriptionId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewEvaluationQualitativeAssessmentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentsOrderBy>>;
};

export type QueryPerformanceReviewEvaluationQualitativeDescriptionArgs = {
  performanceReviewEvaluationQualitativeDescriptionId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewEvaluationQualitativeDescriptionByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewEvaluationQualitativeDescriptionByPerformanceReviewEvaluationIdAndTitleArgs = {
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  title: Scalars['String']['input'];
};

export type QueryPerformanceReviewEvaluationQualitativeDescriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionsOrderBy>>;
};

export type QueryPerformanceReviewEvaluationValueArgs = {
  performanceReviewEvaluationValueId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewEvaluationValueByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewEvaluationValueByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeIdArgs = {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewEvaluationValuesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationValueCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationValueFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationValuesOrderBy>>;
};

export type QueryPerformanceReviewEvaluationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationsOrderBy>>;
};

export type QueryPerformanceReviewMasterCompetenciesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetenciesOrderBy>>;
};

export type QueryPerformanceReviewMasterCompetencyArgs = {
  performanceReviewMasterCompetencyId: Scalars['String']['input'];
};

export type QueryPerformanceReviewMasterCompetencyByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewMasterCompetencyCommonArgs = {
  performanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewMasterCompetencyCommonByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewMasterCompetencyCommonDescriptionArgs = {
  performanceReviewMasterCompetencyCommonDescriptionId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewMasterCompetencyCommonDescriptionByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewMasterCompetencyCommonDescriptionByPerformanceReviewMasterCompetencyCommonIdAndLanguageArgs = {
  language: Scalars['String']['input'];
  performanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewMasterCompetencyCommonDescriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyCommonDescriptionCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyCommonDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionsOrderBy>>;
};

export type QueryPerformanceReviewMasterCompetencyCommonsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyCommonCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyCommonFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonsOrderBy>>;
};

export type QueryPerformanceReviewMasterCompetencyCountArgs = {
  language: Scalars['String']['input'];
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
};

export type QueryPerformanceReviewMasterCompetencyCountByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewMasterCompetencyCountByRoleIdsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyCountByRoleIdCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyCountByRoleIdFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyCountByRoleIdsOrderBy>>;
};

export type QueryPerformanceReviewMasterCompetencyCountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyCountCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyCountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyCountsOrderBy>>;
};

export type QueryPerformanceReviewMasterCompetencyDetailArgs = {
  performanceReviewMasterCompetencyDetailId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewMasterCompetencyDetailByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewMasterCompetencyDetailByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonIdArgs = {
  overridingPerformanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
  performanceReviewMasterCompetencyRoleId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewMasterCompetencyDetailDescriptionArgs = {
  performanceReviewMasterCompetencyDetailDescriptionId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewMasterCompetencyDetailDescriptionByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewMasterCompetencyDetailDescriptionByPerformanceReviewMasterCompetencyDetailIdAndLanguageArgs = {
  language: Scalars['String']['input'];
  performanceReviewMasterCompetencyDetailId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewMasterCompetencyDetailDescriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyDetailDescriptionCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyDetailDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionsOrderBy>>;
};

export type QueryPerformanceReviewMasterCompetencyDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyDetailCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyDetailFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailsOrderBy>>;
};

export type QueryPerformanceReviewMasterCompetencyRoleArgs = {
  performanceReviewMasterCompetencyRoleId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewMasterCompetencyRoleByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewMasterCompetencyRoleByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleNameArgs = {
  businessUnitId: Scalars['BigInt']['input'];
  departmentId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
  quarter: PerformanceReviewQuarter;
  roleName: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type QueryPerformanceReviewMasterCompetencyRolesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyRoleCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyRoleFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyRolesOrderBy>>;
};

export type QueryPerformanceReviewMasterDefaultArgs = {
  performanceReviewMasterDefaultId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewMasterDefaultByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewMasterDefaultsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterDefaultCondition>;
  filter?: InputMaybe<PerformanceReviewMasterDefaultFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterDefaultsOrderBy>>;
};

export type QueryPerformanceReviewMasterEvaluationViewerArgs = {
  performanceReviewMasterEvaluationViewerId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewMasterEvaluationViewerByEmployeeIdAndYearAndQuarterAndViewerEmployeeIdArgs = {
  employeeId: Scalars['BigInt']['input'];
  quarter: PerformanceReviewQuarter;
  viewerEmployeeId: Scalars['BigInt']['input'];
  year: Scalars['Int']['input'];
};

export type QueryPerformanceReviewMasterEvaluationViewerByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewMasterEvaluationViewersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterEvaluationViewerCondition>;
  filter?: InputMaybe<PerformanceReviewMasterEvaluationViewerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewersOrderBy>>;
};

export type QueryPerformanceReviewMasterExcludedEmployeeArgs = {
  employeeId: Scalars['BigInt']['input'];
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
};

export type QueryPerformanceReviewMasterExcludedEmployeeByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewMasterExcludedEmployeesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterExcludedEmployeeCondition>;
  filter?: InputMaybe<PerformanceReviewMasterExcludedEmployeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterExcludedEmployeesOrderBy>>;
};

export type QueryPerformanceReviewMasterOneOnOneArgs = {
  performanceReviewMasterOneOnOneId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewMasterOneOnOneByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewMasterOneOnOneDetailArgs = {
  performanceReviewMasterOneOnOneDetailId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewMasterOneOnOneDetailByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewMasterOneOnOneDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterOneOnOneDetailCondition>;
  filter?: InputMaybe<PerformanceReviewMasterOneOnOneDetailFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterOneOnOneDetailsOrderBy>>;
};

export type QueryPerformanceReviewMasterOneOnOnesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterOneOnOneCondition>;
  filter?: InputMaybe<PerformanceReviewMasterOneOnOneFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterOneOnOnesOrderBy>>;
};

export type QueryPerformanceReviewMasterRatingArgs = {
  performanceReviewMasterRatingId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewMasterRatingByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewMasterRatingDescriptionArgs = {
  performanceReviewMasterRatingDescriptionId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewMasterRatingDescriptionByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewMasterRatingDescriptionByPerformanceReviewMasterRatingIdAndLanguageArgs = {
  language: Scalars['String']['input'];
  performanceReviewMasterRatingId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewMasterRatingDescriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterRatingDescriptionCondition>;
  filter?: InputMaybe<PerformanceReviewMasterRatingDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionsOrderBy>>;
};

export type QueryPerformanceReviewMasterRatingsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterRatingCondition>;
  filter?: InputMaybe<PerformanceReviewMasterRatingFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterRatingsOrderBy>>;
};

export type QueryPerformanceReviewMasterUsageArgs = {
  performanceReviewMasterUsageId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewMasterUsageByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewMasterUsageByYearAndQuarterAndCompanyIdArgs = {
  companyId: Scalars['BigInt']['input'];
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
};

export type QueryPerformanceReviewMasterUsagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterUsageCondition>;
  filter?: InputMaybe<PerformanceReviewMasterUsageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterUsagesOrderBy>>;
};

export type QueryPerformanceReviewMasterValueArgs = {
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewMasterValueByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewMasterValueCountArgs = {
  language: Scalars['String']['input'];
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
};

export type QueryPerformanceReviewMasterValueCountByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewMasterValueCountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterValueCountCondition>;
  filter?: InputMaybe<PerformanceReviewMasterValueCountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterValueCountsOrderBy>>;
};

export type QueryPerformanceReviewMasterValueDescriptionArgs = {
  performanceReviewMasterValueDescriptionId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewMasterValueDescriptionByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewMasterValueDescriptionByPerformanceReviewMasterValueIdAndLanguageArgs = {
  language: Scalars['String']['input'];
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewMasterValueDescriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterValueDescriptionCondition>;
  filter?: InputMaybe<PerformanceReviewMasterValueDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionsOrderBy>>;
};

export type QueryPerformanceReviewMasterValuesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterValueCondition>;
  filter?: InputMaybe<PerformanceReviewMasterValueFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterValuesOrderBy>>;
};

export type QueryPerformanceReviewMasterWeightingArgs = {
  performanceReviewMasterWeightingId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewMasterWeightingByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewMasterWeightingByYearAndQuarterAndCompanyIdArgs = {
  companyId: Scalars['BigInt']['input'];
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
};

export type QueryPerformanceReviewMasterWeightingByYearAndQuarterAndNameArgs = {
  name: Scalars['String']['input'];
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
};

export type QueryPerformanceReviewMasterWeightingDetailArgs = {
  performanceReviewMasterWeightingDetailId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewMasterWeightingDetailByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPerformanceReviewMasterWeightingDetailByPerformanceReviewMasterWeightingIdAndEmployeeLevelIdArgs = {
  employeeLevelId: Scalars['BigInt']['input'];
  performanceReviewMasterWeightingId: Scalars['BigInt']['input'];
};

export type QueryPerformanceReviewMasterWeightingDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterWeightingDetailCondition>;
  filter?: InputMaybe<PerformanceReviewMasterWeightingDetailFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailsOrderBy>>;
};

export type QueryPerformanceReviewMasterWeightingsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterWeightingCondition>;
  filter?: InputMaybe<PerformanceReviewMasterWeightingFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterWeightingsOrderBy>>;
};

export type QueryPerformanceReviewResultsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewResultCondition>;
  filter?: InputMaybe<PerformanceReviewResultFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewResultsOrderBy>>;
};

export type QueryPersonalIdentityTypeArgs = {
  personalIdentityTypeId: Scalars['BigInt']['input'];
};

export type QueryPersonalIdentityTypeByCodeArgs = {
  code: Scalars['String']['input'];
};

export type QueryPersonalIdentityTypeByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryPersonalIdentityTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PersonalIdentityTypeCondition>;
  filter?: InputMaybe<PersonalIdentityTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PersonalIdentityTypesOrderBy>>;
};

export type QueryProbationReviewEnableArgs = {
  probationReviewEnableId: Scalars['BigInt']['input'];
};

export type QueryProbationReviewEnableByCompanyIdAndContractTypeIdArgs = {
  companyId: Scalars['BigInt']['input'];
  contractTypeId: Scalars['BigInt']['input'];
};

export type QueryProbationReviewEnableByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryProbationReviewEnableViewArgs = {
  companyId: Scalars['BigInt']['input'];
  contractTypeId: Scalars['BigInt']['input'];
};

export type QueryProbationReviewEnableViewByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryProbationReviewEnableViewsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEnableViewCondition>;
  filter?: InputMaybe<ProbationReviewEnableViewFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEnableViewsOrderBy>>;
};

export type QueryProbationReviewEnablesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEnableCondition>;
  filter?: InputMaybe<ProbationReviewEnableFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEnablesOrderBy>>;
};

export type QueryProbationReviewEvaluationArgs = {
  probationReviewEvaluationId: Scalars['BigInt']['input'];
};

export type QueryProbationReviewEvaluationByEmployeeIdAndReviewDatePlannedArgs = {
  employeeId: Scalars['BigInt']['input'];
  reviewDatePlanned: Scalars['Date']['input'];
};

export type QueryProbationReviewEvaluationByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryProbationReviewEvaluationListArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  baseDate?: InputMaybe<Scalars['Date']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<ReturnTypeProbationReviewEvaluationListFilter>;
  filterOverdueOnly?: InputMaybe<Scalars['Boolean']['input']>;
  filterResultUse?: InputMaybe<Scalars['Boolean']['input']>;
  filterResultValue?: InputMaybe<ProbationReviewEvaluationResult>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryProbationReviewEvaluationResultItemArgs = {
  probationReviewEvaluationResultItemId: Scalars['BigInt']['input'];
};

export type QueryProbationReviewEvaluationResultItemByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryProbationReviewEvaluationResultItemByProbationReviewEvaluationIdAndProbationReviewMasterItemIdArgs = {
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
};

export type QueryProbationReviewEvaluationResultItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationResultItemCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationResultItemFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultItemsOrderBy>>;
};

export type QueryProbationReviewEvaluationResultScoreCompetenciesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetenciesOrderBy>>;
};

export type QueryProbationReviewEvaluationResultScoreCompetencyArgs = {
  probationReviewEvaluationResultScoreCompetencyId: Scalars['BigInt']['input'];
};

export type QueryProbationReviewEvaluationResultScoreCompetencyByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryProbationReviewEvaluationResultScoreCompetencyByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyIdArgs = {
  performanceReviewMasterCompetencyId: Scalars['BigInt']['input'];
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
};

export type QueryProbationReviewEvaluationResultScoreValueArgs = {
  probationReviewEvaluationResultScoreValueId: Scalars['BigInt']['input'];
};

export type QueryProbationReviewEvaluationResultScoreValueByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryProbationReviewEvaluationResultScoreValueByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueIdArgs = {
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
};

export type QueryProbationReviewEvaluationResultScoreValuesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationResultScoreValueCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationResultScoreValueFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValuesOrderBy>>;
};

export type QueryProbationReviewEvaluationReviewerArgs = {
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  reviewerEmployeeId: Scalars['BigInt']['input'];
};

export type QueryProbationReviewEvaluationReviewerByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryProbationReviewEvaluationReviewersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationReviewerCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationReviewerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationReviewersOrderBy>>;
};

export type QueryProbationReviewEvaluationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationsOrderBy>>;
};

export type QueryProbationReviewMasterItemArgs = {
  probationReviewMasterItemId: Scalars['BigInt']['input'];
};

export type QueryProbationReviewMasterItemByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryProbationReviewMasterItemByYearAndQuarterAndTitleAndInputUserArgs = {
  inputUser: ProbationReviewInputUser;
  quarter: PerformanceReviewQuarter;
  title: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type QueryProbationReviewMasterItemDescriptionArgs = {
  probationReviewMasterItemDescriptionId: Scalars['BigInt']['input'];
};

export type QueryProbationReviewMasterItemDescriptionByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryProbationReviewMasterItemDescriptionByProbationReviewMasterItemIdAndLanguageArgs = {
  language: Scalars['String']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
};

export type QueryProbationReviewMasterItemDescriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewMasterItemDescriptionCondition>;
  filter?: InputMaybe<ProbationReviewMasterItemDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewMasterItemDescriptionsOrderBy>>;
};

export type QueryProbationReviewMasterItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewMasterItemCondition>;
  filter?: InputMaybe<ProbationReviewMasterItemFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewMasterItemsOrderBy>>;
};

export type QueryRelationBusinessUnitDivisionArgs = {
  businessUnitId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
};

export type QueryRelationBusinessUnitDivisionByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryRelationBusinessUnitDivisionDepartmentArgs = {
  businessUnitId: Scalars['BigInt']['input'];
  departmentId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
};

export type QueryRelationBusinessUnitDivisionDepartmentByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryRelationBusinessUnitDivisionDepartmentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationBusinessUnitDivisionDepartmentCondition>;
  filter?: InputMaybe<RelationBusinessUnitDivisionDepartmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentsOrderBy>>;
};

export type QueryRelationBusinessUnitDivisionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationBusinessUnitDivisionCondition>;
  filter?: InputMaybe<RelationBusinessUnitDivisionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationBusinessUnitDivisionsOrderBy>>;
};

export type QueryRelationCityCompaniesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationCityCompanyCondition>;
  filter?: InputMaybe<RelationCityCompanyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationCityCompaniesOrderBy>>;
};

export type QueryRelationCityCompanyArgs = {
  cityId: Scalars['BigInt']['input'];
  companyId: Scalars['BigInt']['input'];
};

export type QueryRelationCityCompanyByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryRelationCompanyBusinessUnitArgs = {
  businessUnitId: Scalars['BigInt']['input'];
  companyId: Scalars['BigInt']['input'];
};

export type QueryRelationCompanyBusinessUnitByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryRelationCompanyBusinessUnitsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationCompanyBusinessUnitCondition>;
  filter?: InputMaybe<RelationCompanyBusinessUnitFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationCompanyBusinessUnitsOrderBy>>;
};

export type QueryRelationCompanyGroupEmailDomainArgs = {
  companyGroupId: Scalars['BigInt']['input'];
  emailDomainId: Scalars['BigInt']['input'];
};

export type QueryRelationCompanyGroupEmailDomainByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryRelationCompanyGroupEmailDomainsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationCompanyGroupEmailDomainCondition>;
  filter?: InputMaybe<RelationCompanyGroupEmailDomainFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationCompanyGroupEmailDomainsOrderBy>>;
};

export type QueryRelationCompanyOfficeArgs = {
  companyId: Scalars['BigInt']['input'];
  officeId: Scalars['BigInt']['input'];
};

export type QueryRelationCompanyOfficeByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryRelationCompanyOfficesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationCompanyOfficeCondition>;
  filter?: InputMaybe<RelationCompanyOfficeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationCompanyOfficesOrderBy>>;
};

export type QueryRelationCurrencyCountriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationCurrencyCountryCondition>;
  filter?: InputMaybe<RelationCurrencyCountryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationCurrencyCountriesOrderBy>>;
};

export type QueryRelationCurrencyCountryArgs = {
  countryCodeAlpha2: Scalars['String']['input'];
  currencyCode: Scalars['String']['input'];
};

export type QueryRelationCurrencyCountryByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryRelationEmergencyContactArgs = {
  emergencyContactRelationId: Scalars['BigInt']['input'];
};

export type QueryRelationEmergencyContactByDisplayNameArgs = {
  displayName: Scalars['String']['input'];
};

export type QueryRelationEmergencyContactByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryRelationEmergencyContactsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationEmergencyContactCondition>;
  filter?: InputMaybe<RelationEmergencyContactFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationEmergencyContactsOrderBy>>;
};

export type QueryRelationEmployeeUserAccountArgs = {
  userAccountId: Scalars['BigInt']['input'];
};

export type QueryRelationEmployeeUserAccountByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryRelationEmployeeUserAccountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationEmployeeUserAccountCondition>;
  filter?: InputMaybe<RelationEmployeeUserAccountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationEmployeeUserAccountsOrderBy>>;
};

export type QueryReportEmployeeTotalCountAllByContractTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ReportEmployeeTotalCountAllByContractTypeCondition>;
  filter?: InputMaybe<ReportEmployeeTotalCountAllByContractTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReportEmployeeTotalCountAllByContractTypesOrderBy>>;
};

export type QueryReportEmployeeTotalCountAllsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ReportEmployeeTotalCountAllCondition>;
  filter?: InputMaybe<ReportEmployeeTotalCountAllFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReportEmployeeTotalCountAllsOrderBy>>;
};

export type QueryReportEmployeeTotalCountCitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ReportEmployeeTotalCountCityCondition>;
  filter?: InputMaybe<ReportEmployeeTotalCountCityFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReportEmployeeTotalCountCitiesOrderBy>>;
};

export type QueryReportEmployeeTotalCountCityByContractTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ReportEmployeeTotalCountCityByContractTypeCondition>;
  filter?: InputMaybe<ReportEmployeeTotalCountCityByContractTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReportEmployeeTotalCountCityByContractTypesOrderBy>>;
};

export type QueryReportEmployeeTotalCountCompaniesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ReportEmployeeTotalCountCompanyCondition>;
  filter?: InputMaybe<ReportEmployeeTotalCountCompanyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReportEmployeeTotalCountCompaniesOrderBy>>;
};

export type QueryReportEmployeeTotalCountCompanyByContractTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ReportEmployeeTotalCountCompanyByContractTypeCondition>;
  filter?: InputMaybe<ReportEmployeeTotalCountCompanyByContractTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReportEmployeeTotalCountCompanyByContractTypesOrderBy>>;
};

export type QueryReportEmployeeTpHistoryByFlagsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ReportEmployeeTpHistoryByFlagCondition>;
  filter?: InputMaybe<ReportEmployeeTpHistoryByFlagFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReportEmployeeTpHistoryByFlagsOrderBy>>;
};

export type QueryTableAuditsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TableAuditCondition>;
  filter?: InputMaybe<TableAuditFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TableAuditsOrderBy>>;
};

export type QueryTablePrimaryKeysArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TablePrimaryKeyCondition>;
  filter?: InputMaybe<TablePrimaryKeyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TablePrimaryKeysOrderBy>>;
};

export type QueryUserAccountArgs = {
  userAccountId: Scalars['BigInt']['input'];
};

export type QueryUserAccountAccessHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserAccountAccessHistoryCondition>;
  filter?: InputMaybe<UserAccountAccessHistoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserAccountAccessHistoriesOrderBy>>;
};

export type QueryUserAccountAccessHistoryArgs = {
  userAccountAccessHistoryId: Scalars['BigInt']['input'];
};

export type QueryUserAccountAccessHistoryByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryUserAccountByEmailAddressArgs = {
  emailAddress: Scalars['String']['input'];
};

export type QueryUserAccountByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryUserAccountPasswordCountArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<UserAccountPasswordCountRecordFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mode?: InputMaybe<UserAccountPasswordCountMode>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  olderDays?: InputMaybe<IntervalInput>;
};

export type QueryUserAccountPinArgs = {
  ref: Scalars['String']['input'];
  userAccountId: Scalars['BigInt']['input'];
};

export type QueryUserAccountPinByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryUserAccountPinsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserAccountPinCondition>;
  filter?: InputMaybe<UserAccountPinFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserAccountPinsOrderBy>>;
};

export type QueryUserAccountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserAccountCondition>;
  filter?: InputMaybe<UserAccountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserAccountsOrderBy>>;
};

export type QueryUserForceLogoutArgs = {
  userForceLogoutId: Scalars['BigInt']['input'];
};

export type QueryUserForceLogoutByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryUserForceLogoutsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserForceLogoutCondition>;
  filter?: InputMaybe<UserForceLogoutFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserForceLogoutsOrderBy>>;
};

export type QueryUserHrManagingCompaniesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserHrManagingCompanyCondition>;
  filter?: InputMaybe<UserHrManagingCompanyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserHrManagingCompaniesOrderBy>>;
};

export type QueryUserHrManagingCompanyArgs = {
  userHrManagingCompanyId: Scalars['BigInt']['input'];
};

export type QueryUserHrManagingCompanyByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryUserHrManagingCompanyByUserAccountIdAndCompanyIdArgs = {
  companyId: Scalars['BigInt']['input'];
  userAccountId: Scalars['BigInt']['input'];
};

export type QueryUserRoleByRoleNameArgs = {
  roleName: Scalars['String']['input'];
};

export type QueryUserRolesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserRoleCondition>;
  filter?: InputMaybe<UserRoleFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserRolesOrderBy>>;
};

export type QueryWorldPlaceArgs = {
  worldPlaceId: Scalars['BigInt']['input'];
};

export type QueryWorldPlaceByDisplayOrderArgs = {
  displayOrder: Scalars['Int']['input'];
};

export type QueryWorldPlaceByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryWorldPlaceWeatherArgs = {
  day: Scalars['Date']['input'];
  worldPlaceId: Scalars['BigInt']['input'];
};

export type QueryWorldPlaceWeatherByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryWorldPlaceWeathersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<WorldPlaceWeatherCondition>;
  filter?: InputMaybe<WorldPlaceWeatherFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<WorldPlaceWeathersOrderBy>>;
};

export type QueryWorldPlacesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<WorldPlaceCondition>;
  filter?: InputMaybe<WorldPlaceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<WorldPlacesOrderBy>>;
};

export interface RelationBusinessUnitDivision extends Node {
  __typename?: 'RelationBusinessUnitDivision';
  businessUnit: BusinessUnit;
  businessUnitId: Scalars['BigInt']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  division: Division;
  divisionId: Scalars['BigInt']['output'];
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface RelationBusinessUnitDivisionAggregates {
  __typename?: 'RelationBusinessUnitDivisionAggregates';
  average?: Maybe<RelationBusinessUnitDivisionAverageAggregates>;
  distinctCount?: Maybe<RelationBusinessUnitDivisionDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
  max?: Maybe<RelationBusinessUnitDivisionMaxAggregates>;
  min?: Maybe<RelationBusinessUnitDivisionMinAggregates>;
  stddevPopulation?: Maybe<RelationBusinessUnitDivisionStddevPopulationAggregates>;
  stddevSample?: Maybe<RelationBusinessUnitDivisionStddevSampleAggregates>;
  sum?: Maybe<RelationBusinessUnitDivisionSumAggregates>;
  variancePopulation?: Maybe<RelationBusinessUnitDivisionVariancePopulationAggregates>;
  varianceSample?: Maybe<RelationBusinessUnitDivisionVarianceSampleAggregates>;
}

export interface RelationBusinessUnitDivisionAggregatesFilter {
  average?: InputMaybe<RelationBusinessUnitDivisionAverageAggregateFilter>;
  distinctCount?: InputMaybe<RelationBusinessUnitDivisionDistinctCountAggregateFilter>;
  filter?: InputMaybe<RelationBusinessUnitDivisionFilter>;
  max?: InputMaybe<RelationBusinessUnitDivisionMaxAggregateFilter>;
  min?: InputMaybe<RelationBusinessUnitDivisionMinAggregateFilter>;
  stddevPopulation?: InputMaybe<RelationBusinessUnitDivisionStddevPopulationAggregateFilter>;
  stddevSample?: InputMaybe<RelationBusinessUnitDivisionStddevSampleAggregateFilter>;
  sum?: InputMaybe<RelationBusinessUnitDivisionSumAggregateFilter>;
  variancePopulation?: InputMaybe<RelationBusinessUnitDivisionVariancePopulationAggregateFilter>;
  varianceSample?: InputMaybe<RelationBusinessUnitDivisionVarianceSampleAggregateFilter>;
}

export interface RelationBusinessUnitDivisionAverageAggregateFilter {
  businessUnitId?: InputMaybe<BigFloatFilter>;
  divisionId?: InputMaybe<BigFloatFilter>;
  userCreated?: InputMaybe<BigFloatFilter>;
  userUpdated?: InputMaybe<BigFloatFilter>;
}

export interface RelationBusinessUnitDivisionAverageAggregates {
  __typename?: 'RelationBusinessUnitDivisionAverageAggregates';
  businessUnitId?: Maybe<Scalars['BigFloat']['output']>;
  divisionId?: Maybe<Scalars['BigFloat']['output']>;
  userCreated?: Maybe<Scalars['BigFloat']['output']>;
  userUpdated?: Maybe<Scalars['BigFloat']['output']>;
}

export interface RelationBusinessUnitDivisionBusinessUnitIdFkeyBusinessUnitCreateInput {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInverseInput>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BusinessUnitUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationBusinessUnitDivisionBusinessUnitIdFkeyInput {
  connectByBusinessUnitId?: InputMaybe<BusinessUnitBusinessUnitPkeyConnect>;
  connectByName?: InputMaybe<BusinessUnitBusinessUnitNameKeyConnect>;
  connectByNodeId?: InputMaybe<BusinessUnitNodeIdConnect>;
  create?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyBusinessUnitCreateInput>;
  deleteByBusinessUnitId?: InputMaybe<BusinessUnitBusinessUnitPkeyDelete>;
  deleteByName?: InputMaybe<BusinessUnitBusinessUnitNameKeyDelete>;
  deleteByNodeId?: InputMaybe<BusinessUnitNodeIdDelete>;
  updateByBusinessUnitId?: InputMaybe<BusinessUnitOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionBusinessUnitIdFkeyUsingBusinessUnitPkeyUpdate>;
  updateByName?: InputMaybe<BusinessUnitOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionBusinessUnitIdFkeyUsingBusinessUnitNameKeyUpdate>;
  updateByNodeId?: InputMaybe<RelationBusinessUnitDivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionBusinessUnitIdFkeyNodeIdUpdate>;
}

export interface RelationBusinessUnitDivisionBusinessUnitIdFkeyInverseInput {
  connectByBusinessUnitIdAndDivisionId?: InputMaybe<Array<RelationBusinessUnitDivisionRelationBusinessUnitDivisionPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationBusinessUnitDivisionNodeIdConnect>>;
  create?: InputMaybe<Array<RelationBusinessUnitDivisionBusinessUnitIdFkeyRelationBusinessUnitDivisionCreateInput>>;
  deleteByBusinessUnitIdAndDivisionId?: InputMaybe<Array<RelationBusinessUnitDivisionRelationBusinessUnitDivisionPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationBusinessUnitDivisionNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByBusinessUnitIdAndDivisionId?: InputMaybe<
    Array<RelationBusinessUnitDivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionBusinessUnitIdFkeyUsingRelationBusinessUnitDivisionPkeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<BusinessUnitOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionBusinessUnitIdFkeyNodeIdUpdate>>;
}

export interface RelationBusinessUnitDivisionBusinessUnitIdFkeyRelationBusinessUnitDivisionCreateInput {
  businessUnit?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInput>;
  division?: InputMaybe<RelationBusinessUnitDivisionDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationBusinessUnitDivisionCondition {
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyBusinessUnitCreateInput {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInverseInput>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BusinessUnitUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInput {
  connectByBusinessUnitId?: InputMaybe<BusinessUnitBusinessUnitPkeyConnect>;
  connectByName?: InputMaybe<BusinessUnitBusinessUnitNameKeyConnect>;
  connectByNodeId?: InputMaybe<BusinessUnitNodeIdConnect>;
  create?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyBusinessUnitCreateInput>;
  deleteByBusinessUnitId?: InputMaybe<BusinessUnitBusinessUnitPkeyDelete>;
  deleteByName?: InputMaybe<BusinessUnitBusinessUnitNameKeyDelete>;
  deleteByNodeId?: InputMaybe<BusinessUnitNodeIdDelete>;
  updateByBusinessUnitId?: InputMaybe<BusinessUnitOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyUsingBusinessUnitPkeyUpdate>;
  updateByName?: InputMaybe<BusinessUnitOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyUsingBusinessUnitNameKeyUpdate>;
  updateByNodeId?: InputMaybe<RelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyNodeIdUpdate>;
}

export interface RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInverseInput {
  connectByBusinessUnitIdAndDivisionIdAndDepartmentId?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentRelationBusinessUnitDivisionDepartmentPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentNodeIdConnect>>;
  create?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyRelationBusinessUnitDivisionDepartmentCreateInput>>;
  deleteByBusinessUnitIdAndDivisionIdAndDepartmentId?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentRelationBusinessUnitDivisionDepartmentPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByBusinessUnitIdAndDivisionIdAndDepartmentId?: InputMaybe<
    Array<RelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyUsingRelationBusinessUnitDivisionDepartmentPkeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<BusinessUnitOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyNodeIdUpdate>>;
}

export interface RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyRelationBusinessUnitDivisionDepartmentCreateInput {
  businessUnit?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInput>;
  department?: InputMaybe<RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationBusinessUnitDivisionDepartment extends Node {
  __typename?: 'RelationBusinessUnitDivisionDepartment';
  businessUnit: BusinessUnit;
  businessUnitId: Scalars['BigInt']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  department: Department;
  departmentId: Scalars['BigInt']['output'];
  division: Division;
  divisionId: Scalars['BigInt']['output'];
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface RelationBusinessUnitDivisionDepartmentAggregates {
  __typename?: 'RelationBusinessUnitDivisionDepartmentAggregates';
  average?: Maybe<RelationBusinessUnitDivisionDepartmentAverageAggregates>;
  distinctCount?: Maybe<RelationBusinessUnitDivisionDepartmentDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
  max?: Maybe<RelationBusinessUnitDivisionDepartmentMaxAggregates>;
  min?: Maybe<RelationBusinessUnitDivisionDepartmentMinAggregates>;
  stddevPopulation?: Maybe<RelationBusinessUnitDivisionDepartmentStddevPopulationAggregates>;
  stddevSample?: Maybe<RelationBusinessUnitDivisionDepartmentStddevSampleAggregates>;
  sum?: Maybe<RelationBusinessUnitDivisionDepartmentSumAggregates>;
  variancePopulation?: Maybe<RelationBusinessUnitDivisionDepartmentVariancePopulationAggregates>;
  varianceSample?: Maybe<RelationBusinessUnitDivisionDepartmentVarianceSampleAggregates>;
}

export interface RelationBusinessUnitDivisionDepartmentAggregatesFilter {
  average?: InputMaybe<RelationBusinessUnitDivisionDepartmentAverageAggregateFilter>;
  distinctCount?: InputMaybe<RelationBusinessUnitDivisionDepartmentDistinctCountAggregateFilter>;
  filter?: InputMaybe<RelationBusinessUnitDivisionDepartmentFilter>;
  max?: InputMaybe<RelationBusinessUnitDivisionDepartmentMaxAggregateFilter>;
  min?: InputMaybe<RelationBusinessUnitDivisionDepartmentMinAggregateFilter>;
  stddevPopulation?: InputMaybe<RelationBusinessUnitDivisionDepartmentStddevPopulationAggregateFilter>;
  stddevSample?: InputMaybe<RelationBusinessUnitDivisionDepartmentStddevSampleAggregateFilter>;
  sum?: InputMaybe<RelationBusinessUnitDivisionDepartmentSumAggregateFilter>;
  variancePopulation?: InputMaybe<RelationBusinessUnitDivisionDepartmentVariancePopulationAggregateFilter>;
  varianceSample?: InputMaybe<RelationBusinessUnitDivisionDepartmentVarianceSampleAggregateFilter>;
}

export interface RelationBusinessUnitDivisionDepartmentAverageAggregateFilter {
  businessUnitId?: InputMaybe<BigFloatFilter>;
  departmentId?: InputMaybe<BigFloatFilter>;
  divisionId?: InputMaybe<BigFloatFilter>;
  userCreated?: InputMaybe<BigFloatFilter>;
  userUpdated?: InputMaybe<BigFloatFilter>;
}

export interface RelationBusinessUnitDivisionDepartmentAverageAggregates {
  __typename?: 'RelationBusinessUnitDivisionDepartmentAverageAggregates';
  businessUnitId?: Maybe<Scalars['BigFloat']['output']>;
  departmentId?: Maybe<Scalars['BigFloat']['output']>;
  divisionId?: Maybe<Scalars['BigFloat']['output']>;
  userCreated?: Maybe<Scalars['BigFloat']['output']>;
  userUpdated?: Maybe<Scalars['BigFloat']['output']>;
}

export interface RelationBusinessUnitDivisionDepartmentCondition {
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyDepartmentCreateInput {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey10InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DepartmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyInput {
  connectByDepartmentId?: InputMaybe<DepartmentDepartmentPkeyConnect>;
  connectByName?: InputMaybe<DepartmentDepartmentNameKeyConnect>;
  connectByNodeId?: InputMaybe<DepartmentNodeIdConnect>;
  create?: InputMaybe<RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyDepartmentCreateInput>;
  deleteByDepartmentId?: InputMaybe<DepartmentDepartmentPkeyDelete>;
  deleteByName?: InputMaybe<DepartmentDepartmentNameKeyDelete>;
  deleteByNodeId?: InputMaybe<DepartmentNodeIdDelete>;
  updateByDepartmentId?: InputMaybe<DepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDepartmentIdFkeyUsingDepartmentPkeyUpdate>;
  updateByName?: InputMaybe<DepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDepartmentIdFkeyUsingDepartmentNameKeyUpdate>;
  updateByNodeId?: InputMaybe<RelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDepartmentIdFkeyNodeIdUpdate>;
}

export interface RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyInverseInput {
  connectByBusinessUnitIdAndDivisionIdAndDepartmentId?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentRelationBusinessUnitDivisionDepartmentPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentNodeIdConnect>>;
  create?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyRelationBusinessUnitDivisionDepartmentCreateInput>>;
  deleteByBusinessUnitIdAndDivisionIdAndDepartmentId?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentRelationBusinessUnitDivisionDepartmentPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByBusinessUnitIdAndDivisionIdAndDepartmentId?: InputMaybe<
    Array<RelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDepartmentIdFkeyUsingRelationBusinessUnitDivisionDepartmentPkeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<DepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDepartmentIdFkeyNodeIdUpdate>>;
}

export interface RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyRelationBusinessUnitDivisionDepartmentCreateInput {
  businessUnit?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyInput>;
  division?: InputMaybe<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationBusinessUnitDivisionDepartmentDistinctCountAggregateFilter {
  businessUnitId?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<BigIntFilter>;
  dateUpdated?: InputMaybe<BigIntFilter>;
  departmentId?: InputMaybe<BigIntFilter>;
  divisionId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<BigIntFilter>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface RelationBusinessUnitDivisionDepartmentDistinctCountAggregates {
  __typename?: 'RelationBusinessUnitDivisionDepartmentDistinctCountAggregates';
  businessUnitId?: Maybe<Scalars['BigInt']['output']>;
  dateCreated?: Maybe<Scalars['BigInt']['output']>;
  dateUpdated?: Maybe<Scalars['BigInt']['output']>;
  departmentId?: Maybe<Scalars['BigInt']['output']>;
  divisionId?: Maybe<Scalars['BigInt']['output']>;
  remark?: Maybe<Scalars['BigInt']['output']>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface RelationBusinessUnitDivisionDepartmentDivisionIdFkeyDivisionCreateInput {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionDivisionIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DivisionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInput {
  connectByDivisionId?: InputMaybe<DivisionDivisionPkeyConnect>;
  connectByName?: InputMaybe<DivisionDivisionNameKeyConnect>;
  connectByNodeId?: InputMaybe<DivisionNodeIdConnect>;
  create?: InputMaybe<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyDivisionCreateInput>;
  deleteByDivisionId?: InputMaybe<DivisionDivisionPkeyDelete>;
  deleteByName?: InputMaybe<DivisionDivisionNameKeyDelete>;
  deleteByNodeId?: InputMaybe<DivisionNodeIdDelete>;
  updateByDivisionId?: InputMaybe<DivisionOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDivisionIdFkeyUsingDivisionPkeyUpdate>;
  updateByName?: InputMaybe<DivisionOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDivisionIdFkeyUsingDivisionNameKeyUpdate>;
  updateByNodeId?: InputMaybe<RelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDivisionIdFkeyNodeIdUpdate>;
}

export interface RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInverseInput {
  connectByBusinessUnitIdAndDivisionIdAndDepartmentId?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentRelationBusinessUnitDivisionDepartmentPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentNodeIdConnect>>;
  create?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyRelationBusinessUnitDivisionDepartmentCreateInput>>;
  deleteByBusinessUnitIdAndDivisionIdAndDepartmentId?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentRelationBusinessUnitDivisionDepartmentPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByBusinessUnitIdAndDivisionIdAndDepartmentId?: InputMaybe<
    Array<RelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDivisionIdFkeyUsingRelationBusinessUnitDivisionDepartmentPkeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<DivisionOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDivisionIdFkeyNodeIdUpdate>>;
}

export interface RelationBusinessUnitDivisionDepartmentDivisionIdFkeyRelationBusinessUnitDivisionDepartmentCreateInput {
  businessUnit?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationBusinessUnitDivisionDepartmentFilter {
  and?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentFilter>>;
  businessUnit?: InputMaybe<BusinessUnitFilter>;
  businessUnitId?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  department?: InputMaybe<DepartmentFilter>;
  departmentId?: InputMaybe<BigIntFilter>;
  division?: InputMaybe<DivisionFilter>;
  divisionId?: InputMaybe<BigIntFilter>;
  not?: InputMaybe<RelationBusinessUnitDivisionDepartmentFilter>;
  or?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentFilter>>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export enum RelationBusinessUnitDivisionDepartmentGroupBy {
  BUSINESS_UNIT_ID = 'BUSINESS_UNIT_ID',
  DATE_CREATED = 'DATE_CREATED',
  DATE_CREATED_TRUNCATED_TO_DAY = 'DATE_CREATED_TRUNCATED_TO_DAY',
  DATE_CREATED_TRUNCATED_TO_HOUR = 'DATE_CREATED_TRUNCATED_TO_HOUR',
  DATE_UPDATED = 'DATE_UPDATED',
  DATE_UPDATED_TRUNCATED_TO_DAY = 'DATE_UPDATED_TRUNCATED_TO_DAY',
  DATE_UPDATED_TRUNCATED_TO_HOUR = 'DATE_UPDATED_TRUNCATED_TO_HOUR',
  DEPARTMENT_ID = 'DEPARTMENT_ID',
  DIVISION_ID = 'DIVISION_ID',
  REMARK = 'REMARK',
  USER_CREATED = 'USER_CREATED',
  USER_UPDATED = 'USER_UPDATED',
}

export interface RelationBusinessUnitDivisionDepartmentHavingAverageInput {
  businessUnitId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  departmentId?: InputMaybe<HavingBigintFilter>;
  divisionId?: InputMaybe<HavingBigintFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface RelationBusinessUnitDivisionDepartmentHavingDistinctCountInput {
  businessUnitId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  departmentId?: InputMaybe<HavingBigintFilter>;
  divisionId?: InputMaybe<HavingBigintFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface RelationBusinessUnitDivisionDepartmentHavingInput {
  AND?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentHavingInput>>;
  OR?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentHavingInput>>;
  average?: InputMaybe<RelationBusinessUnitDivisionDepartmentHavingAverageInput>;
  distinctCount?: InputMaybe<RelationBusinessUnitDivisionDepartmentHavingDistinctCountInput>;
  max?: InputMaybe<RelationBusinessUnitDivisionDepartmentHavingMaxInput>;
  min?: InputMaybe<RelationBusinessUnitDivisionDepartmentHavingMinInput>;
  stddevPopulation?: InputMaybe<RelationBusinessUnitDivisionDepartmentHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<RelationBusinessUnitDivisionDepartmentHavingStddevSampleInput>;
  sum?: InputMaybe<RelationBusinessUnitDivisionDepartmentHavingSumInput>;
  variancePopulation?: InputMaybe<RelationBusinessUnitDivisionDepartmentHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<RelationBusinessUnitDivisionDepartmentHavingVarianceSampleInput>;
}

export interface RelationBusinessUnitDivisionDepartmentHavingMaxInput {
  businessUnitId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  departmentId?: InputMaybe<HavingBigintFilter>;
  divisionId?: InputMaybe<HavingBigintFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface RelationBusinessUnitDivisionDepartmentHavingMinInput {
  businessUnitId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  departmentId?: InputMaybe<HavingBigintFilter>;
  divisionId?: InputMaybe<HavingBigintFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface RelationBusinessUnitDivisionDepartmentHavingStddevPopulationInput {
  businessUnitId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  departmentId?: InputMaybe<HavingBigintFilter>;
  divisionId?: InputMaybe<HavingBigintFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface RelationBusinessUnitDivisionDepartmentHavingStddevSampleInput {
  businessUnitId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  departmentId?: InputMaybe<HavingBigintFilter>;
  divisionId?: InputMaybe<HavingBigintFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface RelationBusinessUnitDivisionDepartmentHavingSumInput {
  businessUnitId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  departmentId?: InputMaybe<HavingBigintFilter>;
  divisionId?: InputMaybe<HavingBigintFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface RelationBusinessUnitDivisionDepartmentHavingVariancePopulationInput {
  businessUnitId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  departmentId?: InputMaybe<HavingBigintFilter>;
  divisionId?: InputMaybe<HavingBigintFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface RelationBusinessUnitDivisionDepartmentHavingVarianceSampleInput {
  businessUnitId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  departmentId?: InputMaybe<HavingBigintFilter>;
  divisionId?: InputMaybe<HavingBigintFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface RelationBusinessUnitDivisionDepartmentInput {
  businessUnit?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationBusinessUnitDivisionDepartmentMaxAggregateFilter {
  businessUnitId?: InputMaybe<BigIntFilter>;
  departmentId?: InputMaybe<BigIntFilter>;
  divisionId?: InputMaybe<BigIntFilter>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface RelationBusinessUnitDivisionDepartmentMaxAggregates {
  __typename?: 'RelationBusinessUnitDivisionDepartmentMaxAggregates';
  businessUnitId?: Maybe<Scalars['BigInt']['output']>;
  departmentId?: Maybe<Scalars['BigInt']['output']>;
  divisionId?: Maybe<Scalars['BigInt']['output']>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface RelationBusinessUnitDivisionDepartmentMinAggregateFilter {
  businessUnitId?: InputMaybe<BigIntFilter>;
  departmentId?: InputMaybe<BigIntFilter>;
  divisionId?: InputMaybe<BigIntFilter>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface RelationBusinessUnitDivisionDepartmentMinAggregates {
  __typename?: 'RelationBusinessUnitDivisionDepartmentMinAggregates';
  businessUnitId?: Maybe<Scalars['BigInt']['output']>;
  departmentId?: Maybe<Scalars['BigInt']['output']>;
  divisionId?: Maybe<Scalars['BigInt']['output']>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface RelationBusinessUnitDivisionDepartmentNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface RelationBusinessUnitDivisionDepartmentNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface RelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: BusinessUnitPatch;
}

export interface RelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyUsingRelationBusinessUnitDivisionDepartmentPkeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  departmentId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
  patch: UpdateRelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyPatch;
}

export interface RelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDepartmentIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: DepartmentPatch;
}

export interface RelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDepartmentIdFkeyUsingRelationBusinessUnitDivisionDepartmentPkeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  departmentId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
  patch: UpdateRelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDepartmentIdFkeyPatch;
}

export interface RelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDivisionIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: DivisionPatch;
}

export interface RelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDivisionIdFkeyUsingRelationBusinessUnitDivisionDepartmentPkeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  departmentId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
  patch: UpdateRelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDivisionIdFkeyPatch;
}

export interface RelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentUserCreatedFkeyUsingRelationBusinessUnitDivisionDepartmentPkeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  departmentId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
  patch: UpdateRelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentUserCreatedFkeyPatch;
}

export interface RelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface RelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentUserUpdatedFkeyUsingRelationBusinessUnitDivisionDepartmentPkeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  departmentId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
  patch: UpdateRelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentUserUpdatedFkeyPatch;
}

export interface RelationBusinessUnitDivisionDepartmentPatch {
  businessUnit?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationBusinessUnitDivisionDepartmentRelationBusinessUnitDivisionDepartmentPkeyConnect {
  businessUnitId: Scalars['BigInt']['input'];
  departmentId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
}

export interface RelationBusinessUnitDivisionDepartmentRelationBusinessUnitDivisionDepartmentPkeyDelete {
  businessUnitId: Scalars['BigInt']['input'];
  departmentId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
}

export interface RelationBusinessUnitDivisionDepartmentStddevPopulationAggregateFilter {
  businessUnitId?: InputMaybe<BigFloatFilter>;
  departmentId?: InputMaybe<BigFloatFilter>;
  divisionId?: InputMaybe<BigFloatFilter>;
  userCreated?: InputMaybe<BigFloatFilter>;
  userUpdated?: InputMaybe<BigFloatFilter>;
}

export interface RelationBusinessUnitDivisionDepartmentStddevPopulationAggregates {
  __typename?: 'RelationBusinessUnitDivisionDepartmentStddevPopulationAggregates';
  businessUnitId?: Maybe<Scalars['BigFloat']['output']>;
  departmentId?: Maybe<Scalars['BigFloat']['output']>;
  divisionId?: Maybe<Scalars['BigFloat']['output']>;
  userCreated?: Maybe<Scalars['BigFloat']['output']>;
  userUpdated?: Maybe<Scalars['BigFloat']['output']>;
}

export interface RelationBusinessUnitDivisionDepartmentStddevSampleAggregateFilter {
  businessUnitId?: InputMaybe<BigFloatFilter>;
  departmentId?: InputMaybe<BigFloatFilter>;
  divisionId?: InputMaybe<BigFloatFilter>;
  userCreated?: InputMaybe<BigFloatFilter>;
  userUpdated?: InputMaybe<BigFloatFilter>;
}

export interface RelationBusinessUnitDivisionDepartmentStddevSampleAggregates {
  __typename?: 'RelationBusinessUnitDivisionDepartmentStddevSampleAggregates';
  businessUnitId?: Maybe<Scalars['BigFloat']['output']>;
  departmentId?: Maybe<Scalars['BigFloat']['output']>;
  divisionId?: Maybe<Scalars['BigFloat']['output']>;
  userCreated?: Maybe<Scalars['BigFloat']['output']>;
  userUpdated?: Maybe<Scalars['BigFloat']['output']>;
}

export interface RelationBusinessUnitDivisionDepartmentSumAggregateFilter {
  businessUnitId?: InputMaybe<BigFloatFilter>;
  departmentId?: InputMaybe<BigFloatFilter>;
  divisionId?: InputMaybe<BigFloatFilter>;
  userCreated?: InputMaybe<BigFloatFilter>;
  userUpdated?: InputMaybe<BigFloatFilter>;
}

export interface RelationBusinessUnitDivisionDepartmentSumAggregates {
  __typename?: 'RelationBusinessUnitDivisionDepartmentSumAggregates';
  businessUnitId: Scalars['BigFloat']['output'];
  departmentId: Scalars['BigFloat']['output'];
  divisionId: Scalars['BigFloat']['output'];
  userCreated: Scalars['BigFloat']['output'];
  userUpdated: Scalars['BigFloat']['output'];
}

export interface RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput {
  connectByBusinessUnitIdAndDivisionIdAndDepartmentId?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentRelationBusinessUnitDivisionDepartmentPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentNodeIdConnect>>;
  deleteByBusinessUnitIdAndDivisionIdAndDepartmentId?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentRelationBusinessUnitDivisionDepartmentPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByBusinessUnitIdAndDivisionIdAndDepartmentId?: InputMaybe<
    Array<RelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentUserCreatedFkeyUsingRelationBusinessUnitDivisionDepartmentPkeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<UserAccountOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentUserCreatedFkeyNodeIdUpdate>>;
}

export interface RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<RelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput {
  connectByBusinessUnitIdAndDivisionIdAndDepartmentId?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentRelationBusinessUnitDivisionDepartmentPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentNodeIdConnect>>;
  deleteByBusinessUnitIdAndDivisionIdAndDepartmentId?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentRelationBusinessUnitDivisionDepartmentPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByBusinessUnitIdAndDivisionIdAndDepartmentId?: InputMaybe<
    Array<RelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentUserUpdatedFkeyUsingRelationBusinessUnitDivisionDepartmentPkeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<UserAccountOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentUserUpdatedFkeyNodeIdUpdate>>;
}

export interface RelationBusinessUnitDivisionDepartmentVariancePopulationAggregateFilter {
  businessUnitId?: InputMaybe<BigFloatFilter>;
  departmentId?: InputMaybe<BigFloatFilter>;
  divisionId?: InputMaybe<BigFloatFilter>;
  userCreated?: InputMaybe<BigFloatFilter>;
  userUpdated?: InputMaybe<BigFloatFilter>;
}

export interface RelationBusinessUnitDivisionDepartmentVariancePopulationAggregates {
  __typename?: 'RelationBusinessUnitDivisionDepartmentVariancePopulationAggregates';
  businessUnitId?: Maybe<Scalars['BigFloat']['output']>;
  departmentId?: Maybe<Scalars['BigFloat']['output']>;
  divisionId?: Maybe<Scalars['BigFloat']['output']>;
  userCreated?: Maybe<Scalars['BigFloat']['output']>;
  userUpdated?: Maybe<Scalars['BigFloat']['output']>;
}

export interface RelationBusinessUnitDivisionDepartmentVarianceSampleAggregateFilter {
  businessUnitId?: InputMaybe<BigFloatFilter>;
  departmentId?: InputMaybe<BigFloatFilter>;
  divisionId?: InputMaybe<BigFloatFilter>;
  userCreated?: InputMaybe<BigFloatFilter>;
  userUpdated?: InputMaybe<BigFloatFilter>;
}

export interface RelationBusinessUnitDivisionDepartmentVarianceSampleAggregates {
  __typename?: 'RelationBusinessUnitDivisionDepartmentVarianceSampleAggregates';
  businessUnitId?: Maybe<Scalars['BigFloat']['output']>;
  departmentId?: Maybe<Scalars['BigFloat']['output']>;
  divisionId?: Maybe<Scalars['BigFloat']['output']>;
  userCreated?: Maybe<Scalars['BigFloat']['output']>;
  userUpdated?: Maybe<Scalars['BigFloat']['output']>;
}

export interface RelationBusinessUnitDivisionDepartmentsConnection {
  __typename?: 'RelationBusinessUnitDivisionDepartmentsConnection';
  aggregates?: Maybe<RelationBusinessUnitDivisionDepartmentAggregates>;
  edges: Array<RelationBusinessUnitDivisionDepartmentsEdge>;
  groupedAggregates?: Maybe<Array<RelationBusinessUnitDivisionDepartmentAggregates>>;
  nodes: Array<RelationBusinessUnitDivisionDepartment>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export type RelationBusinessUnitDivisionDepartmentsConnectionGroupedAggregatesArgs = {
  groupBy: Array<RelationBusinessUnitDivisionDepartmentGroupBy>;
  having?: InputMaybe<RelationBusinessUnitDivisionDepartmentHavingInput>;
};

export interface RelationBusinessUnitDivisionDepartmentsEdge {
  __typename?: 'RelationBusinessUnitDivisionDepartmentsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: RelationBusinessUnitDivisionDepartment;
}

export enum RelationBusinessUnitDivisionDepartmentsOrderBy {
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_DESC',
  BUSINESS_UNIT_ID_ASC = 'BUSINESS_UNIT_ID_ASC',
  BUSINESS_UNIT_ID_DESC = 'BUSINESS_UNIT_ID_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__DATE_CREATED_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__DATE_CREATED_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__DATE_CREATED_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__DATE_CREATED_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__DATE_UPDATED_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__DATE_UPDATED_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__DATE_UPDATED_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__DATE_UPDATED_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_ID_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_ID_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_ID_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_ID_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__IS_ACTIVE_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__IS_ACTIVE_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__IS_ACTIVE_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__IS_ACTIVE_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__REMARK_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__REMARK_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__REMARK_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__REMARK_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__USER_CREATED_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__USER_CREATED_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__USER_CREATED_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__USER_CREATED_DESC',
  DEPARTMENT_BY_DEPARTMENT_ID__USER_UPDATED_ASC = 'DEPARTMENT_BY_DEPARTMENT_ID__USER_UPDATED_ASC',
  DEPARTMENT_BY_DEPARTMENT_ID__USER_UPDATED_DESC = 'DEPARTMENT_BY_DEPARTMENT_ID__USER_UPDATED_DESC',
  DEPARTMENT_ID_ASC = 'DEPARTMENT_ID_ASC',
  DEPARTMENT_ID_DESC = 'DEPARTMENT_ID_DESC',
  DIVISION_BY_DIVISION_ID__DATE_CREATED_ASC = 'DIVISION_BY_DIVISION_ID__DATE_CREATED_ASC',
  DIVISION_BY_DIVISION_ID__DATE_CREATED_DESC = 'DIVISION_BY_DIVISION_ID__DATE_CREATED_DESC',
  DIVISION_BY_DIVISION_ID__DATE_UPDATED_ASC = 'DIVISION_BY_DIVISION_ID__DATE_UPDATED_ASC',
  DIVISION_BY_DIVISION_ID__DATE_UPDATED_DESC = 'DIVISION_BY_DIVISION_ID__DATE_UPDATED_DESC',
  DIVISION_BY_DIVISION_ID__DIVISION_ID_ASC = 'DIVISION_BY_DIVISION_ID__DIVISION_ID_ASC',
  DIVISION_BY_DIVISION_ID__DIVISION_ID_DESC = 'DIVISION_BY_DIVISION_ID__DIVISION_ID_DESC',
  DIVISION_BY_DIVISION_ID__IS_ACTIVE_ASC = 'DIVISION_BY_DIVISION_ID__IS_ACTIVE_ASC',
  DIVISION_BY_DIVISION_ID__IS_ACTIVE_DESC = 'DIVISION_BY_DIVISION_ID__IS_ACTIVE_DESC',
  DIVISION_BY_DIVISION_ID__NAME_ASC = 'DIVISION_BY_DIVISION_ID__NAME_ASC',
  DIVISION_BY_DIVISION_ID__NAME_DESC = 'DIVISION_BY_DIVISION_ID__NAME_DESC',
  DIVISION_BY_DIVISION_ID__REMARK_ASC = 'DIVISION_BY_DIVISION_ID__REMARK_ASC',
  DIVISION_BY_DIVISION_ID__REMARK_DESC = 'DIVISION_BY_DIVISION_ID__REMARK_DESC',
  DIVISION_BY_DIVISION_ID__USER_CREATED_ASC = 'DIVISION_BY_DIVISION_ID__USER_CREATED_ASC',
  DIVISION_BY_DIVISION_ID__USER_CREATED_DESC = 'DIVISION_BY_DIVISION_ID__USER_CREATED_DESC',
  DIVISION_BY_DIVISION_ID__USER_UPDATED_ASC = 'DIVISION_BY_DIVISION_ID__USER_UPDATED_ASC',
  DIVISION_BY_DIVISION_ID__USER_UPDATED_DESC = 'DIVISION_BY_DIVISION_ID__USER_UPDATED_DESC',
  DIVISION_ID_ASC = 'DIVISION_ID_ASC',
  DIVISION_ID_DESC = 'DIVISION_ID_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface RelationBusinessUnitDivisionDistinctCountAggregateFilter {
  businessUnitId?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<BigIntFilter>;
  dateUpdated?: InputMaybe<BigIntFilter>;
  divisionId?: InputMaybe<BigIntFilter>;
  remark?: InputMaybe<BigIntFilter>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface RelationBusinessUnitDivisionDistinctCountAggregates {
  __typename?: 'RelationBusinessUnitDivisionDistinctCountAggregates';
  businessUnitId?: Maybe<Scalars['BigInt']['output']>;
  dateCreated?: Maybe<Scalars['BigInt']['output']>;
  dateUpdated?: Maybe<Scalars['BigInt']['output']>;
  divisionId?: Maybe<Scalars['BigInt']['output']>;
  remark?: Maybe<Scalars['BigInt']['output']>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface RelationBusinessUnitDivisionDivisionIdFkeyDivisionCreateInput {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionDivisionIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DivisionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationBusinessUnitDivisionDivisionIdFkeyInput {
  connectByDivisionId?: InputMaybe<DivisionDivisionPkeyConnect>;
  connectByName?: InputMaybe<DivisionDivisionNameKeyConnect>;
  connectByNodeId?: InputMaybe<DivisionNodeIdConnect>;
  create?: InputMaybe<RelationBusinessUnitDivisionDivisionIdFkeyDivisionCreateInput>;
  deleteByDivisionId?: InputMaybe<DivisionDivisionPkeyDelete>;
  deleteByName?: InputMaybe<DivisionDivisionNameKeyDelete>;
  deleteByNodeId?: InputMaybe<DivisionNodeIdDelete>;
  updateByDivisionId?: InputMaybe<DivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionDivisionIdFkeyUsingDivisionPkeyUpdate>;
  updateByName?: InputMaybe<DivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionDivisionIdFkeyUsingDivisionNameKeyUpdate>;
  updateByNodeId?: InputMaybe<RelationBusinessUnitDivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionDivisionIdFkeyNodeIdUpdate>;
}

export interface RelationBusinessUnitDivisionDivisionIdFkeyInverseInput {
  connectByBusinessUnitIdAndDivisionId?: InputMaybe<Array<RelationBusinessUnitDivisionRelationBusinessUnitDivisionPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationBusinessUnitDivisionNodeIdConnect>>;
  create?: InputMaybe<Array<RelationBusinessUnitDivisionDivisionIdFkeyRelationBusinessUnitDivisionCreateInput>>;
  deleteByBusinessUnitIdAndDivisionId?: InputMaybe<Array<RelationBusinessUnitDivisionRelationBusinessUnitDivisionPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationBusinessUnitDivisionNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByBusinessUnitIdAndDivisionId?: InputMaybe<
    Array<RelationBusinessUnitDivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionDivisionIdFkeyUsingRelationBusinessUnitDivisionPkeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<DivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionDivisionIdFkeyNodeIdUpdate>>;
}

export interface RelationBusinessUnitDivisionDivisionIdFkeyRelationBusinessUnitDivisionCreateInput {
  businessUnit?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<RelationBusinessUnitDivisionDivisionIdFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationBusinessUnitDivisionFilter {
  and?: InputMaybe<Array<RelationBusinessUnitDivisionFilter>>;
  businessUnit?: InputMaybe<BusinessUnitFilter>;
  businessUnitId?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  division?: InputMaybe<DivisionFilter>;
  divisionId?: InputMaybe<BigIntFilter>;
  not?: InputMaybe<RelationBusinessUnitDivisionFilter>;
  or?: InputMaybe<Array<RelationBusinessUnitDivisionFilter>>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export enum RelationBusinessUnitDivisionGroupBy {
  BUSINESS_UNIT_ID = 'BUSINESS_UNIT_ID',
  DATE_CREATED = 'DATE_CREATED',
  DATE_CREATED_TRUNCATED_TO_DAY = 'DATE_CREATED_TRUNCATED_TO_DAY',
  DATE_CREATED_TRUNCATED_TO_HOUR = 'DATE_CREATED_TRUNCATED_TO_HOUR',
  DATE_UPDATED = 'DATE_UPDATED',
  DATE_UPDATED_TRUNCATED_TO_DAY = 'DATE_UPDATED_TRUNCATED_TO_DAY',
  DATE_UPDATED_TRUNCATED_TO_HOUR = 'DATE_UPDATED_TRUNCATED_TO_HOUR',
  DIVISION_ID = 'DIVISION_ID',
  REMARK = 'REMARK',
  USER_CREATED = 'USER_CREATED',
  USER_UPDATED = 'USER_UPDATED',
}

export interface RelationBusinessUnitDivisionHavingAverageInput {
  businessUnitId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  divisionId?: InputMaybe<HavingBigintFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface RelationBusinessUnitDivisionHavingDistinctCountInput {
  businessUnitId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  divisionId?: InputMaybe<HavingBigintFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface RelationBusinessUnitDivisionHavingInput {
  AND?: InputMaybe<Array<RelationBusinessUnitDivisionHavingInput>>;
  OR?: InputMaybe<Array<RelationBusinessUnitDivisionHavingInput>>;
  average?: InputMaybe<RelationBusinessUnitDivisionHavingAverageInput>;
  distinctCount?: InputMaybe<RelationBusinessUnitDivisionHavingDistinctCountInput>;
  max?: InputMaybe<RelationBusinessUnitDivisionHavingMaxInput>;
  min?: InputMaybe<RelationBusinessUnitDivisionHavingMinInput>;
  stddevPopulation?: InputMaybe<RelationBusinessUnitDivisionHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<RelationBusinessUnitDivisionHavingStddevSampleInput>;
  sum?: InputMaybe<RelationBusinessUnitDivisionHavingSumInput>;
  variancePopulation?: InputMaybe<RelationBusinessUnitDivisionHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<RelationBusinessUnitDivisionHavingVarianceSampleInput>;
}

export interface RelationBusinessUnitDivisionHavingMaxInput {
  businessUnitId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  divisionId?: InputMaybe<HavingBigintFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface RelationBusinessUnitDivisionHavingMinInput {
  businessUnitId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  divisionId?: InputMaybe<HavingBigintFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface RelationBusinessUnitDivisionHavingStddevPopulationInput {
  businessUnitId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  divisionId?: InputMaybe<HavingBigintFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface RelationBusinessUnitDivisionHavingStddevSampleInput {
  businessUnitId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  divisionId?: InputMaybe<HavingBigintFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface RelationBusinessUnitDivisionHavingSumInput {
  businessUnitId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  divisionId?: InputMaybe<HavingBigintFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface RelationBusinessUnitDivisionHavingVariancePopulationInput {
  businessUnitId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  divisionId?: InputMaybe<HavingBigintFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface RelationBusinessUnitDivisionHavingVarianceSampleInput {
  businessUnitId?: InputMaybe<HavingBigintFilter>;
  dateCreated?: InputMaybe<HavingDatetimeFilter>;
  dateUpdated?: InputMaybe<HavingDatetimeFilter>;
  divisionId?: InputMaybe<HavingBigintFilter>;
  userCreated?: InputMaybe<HavingBigintFilter>;
  userUpdated?: InputMaybe<HavingBigintFilter>;
}

export interface RelationBusinessUnitDivisionInput {
  businessUnit?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<RelationBusinessUnitDivisionDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationBusinessUnitDivisionMaxAggregateFilter {
  businessUnitId?: InputMaybe<BigIntFilter>;
  divisionId?: InputMaybe<BigIntFilter>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface RelationBusinessUnitDivisionMaxAggregates {
  __typename?: 'RelationBusinessUnitDivisionMaxAggregates';
  businessUnitId?: Maybe<Scalars['BigInt']['output']>;
  divisionId?: Maybe<Scalars['BigInt']['output']>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface RelationBusinessUnitDivisionMinAggregateFilter {
  businessUnitId?: InputMaybe<BigIntFilter>;
  divisionId?: InputMaybe<BigIntFilter>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface RelationBusinessUnitDivisionMinAggregates {
  __typename?: 'RelationBusinessUnitDivisionMinAggregates';
  businessUnitId?: Maybe<Scalars['BigInt']['output']>;
  divisionId?: Maybe<Scalars['BigInt']['output']>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface RelationBusinessUnitDivisionNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface RelationBusinessUnitDivisionNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface RelationBusinessUnitDivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionBusinessUnitIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: BusinessUnitPatch;
}

export interface RelationBusinessUnitDivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionBusinessUnitIdFkeyUsingRelationBusinessUnitDivisionPkeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
  patch: UpdateRelationBusinessUnitDivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionBusinessUnitIdFkeyPatch;
}

export interface RelationBusinessUnitDivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionDivisionIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: DivisionPatch;
}

export interface RelationBusinessUnitDivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionDivisionIdFkeyUsingRelationBusinessUnitDivisionPkeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
  patch: UpdateRelationBusinessUnitDivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionDivisionIdFkeyPatch;
}

export interface RelationBusinessUnitDivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionUserCreatedFkeyUsingRelationBusinessUnitDivisionPkeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
  patch: UpdateRelationBusinessUnitDivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionUserCreatedFkeyPatch;
}

export interface RelationBusinessUnitDivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface RelationBusinessUnitDivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionUserUpdatedFkeyUsingRelationBusinessUnitDivisionPkeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
  patch: UpdateRelationBusinessUnitDivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionUserUpdatedFkeyPatch;
}

export interface RelationBusinessUnitDivisionPatch {
  businessUnit?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<RelationBusinessUnitDivisionDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationBusinessUnitDivisionRelationBusinessUnitDivisionPkeyConnect {
  businessUnitId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
}

export interface RelationBusinessUnitDivisionRelationBusinessUnitDivisionPkeyDelete {
  businessUnitId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
}

export interface RelationBusinessUnitDivisionStddevPopulationAggregateFilter {
  businessUnitId?: InputMaybe<BigFloatFilter>;
  divisionId?: InputMaybe<BigFloatFilter>;
  userCreated?: InputMaybe<BigFloatFilter>;
  userUpdated?: InputMaybe<BigFloatFilter>;
}

export interface RelationBusinessUnitDivisionStddevPopulationAggregates {
  __typename?: 'RelationBusinessUnitDivisionStddevPopulationAggregates';
  businessUnitId?: Maybe<Scalars['BigFloat']['output']>;
  divisionId?: Maybe<Scalars['BigFloat']['output']>;
  userCreated?: Maybe<Scalars['BigFloat']['output']>;
  userUpdated?: Maybe<Scalars['BigFloat']['output']>;
}

export interface RelationBusinessUnitDivisionStddevSampleAggregateFilter {
  businessUnitId?: InputMaybe<BigFloatFilter>;
  divisionId?: InputMaybe<BigFloatFilter>;
  userCreated?: InputMaybe<BigFloatFilter>;
  userUpdated?: InputMaybe<BigFloatFilter>;
}

export interface RelationBusinessUnitDivisionStddevSampleAggregates {
  __typename?: 'RelationBusinessUnitDivisionStddevSampleAggregates';
  businessUnitId?: Maybe<Scalars['BigFloat']['output']>;
  divisionId?: Maybe<Scalars['BigFloat']['output']>;
  userCreated?: Maybe<Scalars['BigFloat']['output']>;
  userUpdated?: Maybe<Scalars['BigFloat']['output']>;
}

export interface RelationBusinessUnitDivisionSumAggregateFilter {
  businessUnitId?: InputMaybe<BigFloatFilter>;
  divisionId?: InputMaybe<BigFloatFilter>;
  userCreated?: InputMaybe<BigFloatFilter>;
  userUpdated?: InputMaybe<BigFloatFilter>;
}

export interface RelationBusinessUnitDivisionSumAggregates {
  __typename?: 'RelationBusinessUnitDivisionSumAggregates';
  businessUnitId: Scalars['BigFloat']['output'];
  divisionId: Scalars['BigFloat']['output'];
  userCreated: Scalars['BigFloat']['output'];
  userUpdated: Scalars['BigFloat']['output'];
}

export interface RelationBusinessUnitDivisionUserCreatedFkeyInverseInput {
  connectByBusinessUnitIdAndDivisionId?: InputMaybe<Array<RelationBusinessUnitDivisionRelationBusinessUnitDivisionPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationBusinessUnitDivisionNodeIdConnect>>;
  deleteByBusinessUnitIdAndDivisionId?: InputMaybe<Array<RelationBusinessUnitDivisionRelationBusinessUnitDivisionPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationBusinessUnitDivisionNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByBusinessUnitIdAndDivisionId?: InputMaybe<
    Array<RelationBusinessUnitDivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionUserCreatedFkeyUsingRelationBusinessUnitDivisionPkeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<UserAccountOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionUserCreatedFkeyNodeIdUpdate>>;
}

export interface RelationBusinessUnitDivisionUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<RelationBusinessUnitDivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput {
  connectByBusinessUnitIdAndDivisionId?: InputMaybe<Array<RelationBusinessUnitDivisionRelationBusinessUnitDivisionPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationBusinessUnitDivisionNodeIdConnect>>;
  deleteByBusinessUnitIdAndDivisionId?: InputMaybe<Array<RelationBusinessUnitDivisionRelationBusinessUnitDivisionPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationBusinessUnitDivisionNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByBusinessUnitIdAndDivisionId?: InputMaybe<
    Array<RelationBusinessUnitDivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionUserUpdatedFkeyUsingRelationBusinessUnitDivisionPkeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<UserAccountOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionUserUpdatedFkeyNodeIdUpdate>>;
}

export interface RelationBusinessUnitDivisionVariancePopulationAggregateFilter {
  businessUnitId?: InputMaybe<BigFloatFilter>;
  divisionId?: InputMaybe<BigFloatFilter>;
  userCreated?: InputMaybe<BigFloatFilter>;
  userUpdated?: InputMaybe<BigFloatFilter>;
}

export interface RelationBusinessUnitDivisionVariancePopulationAggregates {
  __typename?: 'RelationBusinessUnitDivisionVariancePopulationAggregates';
  businessUnitId?: Maybe<Scalars['BigFloat']['output']>;
  divisionId?: Maybe<Scalars['BigFloat']['output']>;
  userCreated?: Maybe<Scalars['BigFloat']['output']>;
  userUpdated?: Maybe<Scalars['BigFloat']['output']>;
}

export interface RelationBusinessUnitDivisionVarianceSampleAggregateFilter {
  businessUnitId?: InputMaybe<BigFloatFilter>;
  divisionId?: InputMaybe<BigFloatFilter>;
  userCreated?: InputMaybe<BigFloatFilter>;
  userUpdated?: InputMaybe<BigFloatFilter>;
}

export interface RelationBusinessUnitDivisionVarianceSampleAggregates {
  __typename?: 'RelationBusinessUnitDivisionVarianceSampleAggregates';
  businessUnitId?: Maybe<Scalars['BigFloat']['output']>;
  divisionId?: Maybe<Scalars['BigFloat']['output']>;
  userCreated?: Maybe<Scalars['BigFloat']['output']>;
  userUpdated?: Maybe<Scalars['BigFloat']['output']>;
}

export interface RelationBusinessUnitDivisionsConnection {
  __typename?: 'RelationBusinessUnitDivisionsConnection';
  aggregates?: Maybe<RelationBusinessUnitDivisionAggregates>;
  edges: Array<RelationBusinessUnitDivisionsEdge>;
  groupedAggregates?: Maybe<Array<RelationBusinessUnitDivisionAggregates>>;
  nodes: Array<RelationBusinessUnitDivision>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export type RelationBusinessUnitDivisionsConnectionGroupedAggregatesArgs = {
  groupBy: Array<RelationBusinessUnitDivisionGroupBy>;
  having?: InputMaybe<RelationBusinessUnitDivisionHavingInput>;
};

export interface RelationBusinessUnitDivisionsEdge {
  __typename?: 'RelationBusinessUnitDivisionsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: RelationBusinessUnitDivision;
}

export enum RelationBusinessUnitDivisionsOrderBy {
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_DESC',
  BUSINESS_UNIT_ID_ASC = 'BUSINESS_UNIT_ID_ASC',
  BUSINESS_UNIT_ID_DESC = 'BUSINESS_UNIT_ID_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DIVISION_BY_DIVISION_ID__DATE_CREATED_ASC = 'DIVISION_BY_DIVISION_ID__DATE_CREATED_ASC',
  DIVISION_BY_DIVISION_ID__DATE_CREATED_DESC = 'DIVISION_BY_DIVISION_ID__DATE_CREATED_DESC',
  DIVISION_BY_DIVISION_ID__DATE_UPDATED_ASC = 'DIVISION_BY_DIVISION_ID__DATE_UPDATED_ASC',
  DIVISION_BY_DIVISION_ID__DATE_UPDATED_DESC = 'DIVISION_BY_DIVISION_ID__DATE_UPDATED_DESC',
  DIVISION_BY_DIVISION_ID__DIVISION_ID_ASC = 'DIVISION_BY_DIVISION_ID__DIVISION_ID_ASC',
  DIVISION_BY_DIVISION_ID__DIVISION_ID_DESC = 'DIVISION_BY_DIVISION_ID__DIVISION_ID_DESC',
  DIVISION_BY_DIVISION_ID__IS_ACTIVE_ASC = 'DIVISION_BY_DIVISION_ID__IS_ACTIVE_ASC',
  DIVISION_BY_DIVISION_ID__IS_ACTIVE_DESC = 'DIVISION_BY_DIVISION_ID__IS_ACTIVE_DESC',
  DIVISION_BY_DIVISION_ID__NAME_ASC = 'DIVISION_BY_DIVISION_ID__NAME_ASC',
  DIVISION_BY_DIVISION_ID__NAME_DESC = 'DIVISION_BY_DIVISION_ID__NAME_DESC',
  DIVISION_BY_DIVISION_ID__REMARK_ASC = 'DIVISION_BY_DIVISION_ID__REMARK_ASC',
  DIVISION_BY_DIVISION_ID__REMARK_DESC = 'DIVISION_BY_DIVISION_ID__REMARK_DESC',
  DIVISION_BY_DIVISION_ID__USER_CREATED_ASC = 'DIVISION_BY_DIVISION_ID__USER_CREATED_ASC',
  DIVISION_BY_DIVISION_ID__USER_CREATED_DESC = 'DIVISION_BY_DIVISION_ID__USER_CREATED_DESC',
  DIVISION_BY_DIVISION_ID__USER_UPDATED_ASC = 'DIVISION_BY_DIVISION_ID__USER_UPDATED_ASC',
  DIVISION_BY_DIVISION_ID__USER_UPDATED_DESC = 'DIVISION_BY_DIVISION_ID__USER_UPDATED_DESC',
  DIVISION_ID_ASC = 'DIVISION_ID_ASC',
  DIVISION_ID_DESC = 'DIVISION_ID_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface RelationCityCompaniesConnection {
  __typename?: 'RelationCityCompaniesConnection';
  edges: Array<RelationCityCompaniesEdge>;
  nodes: Array<RelationCityCompany>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface RelationCityCompaniesEdge {
  __typename?: 'RelationCityCompaniesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: RelationCityCompany;
}

export enum RelationCityCompaniesOrderBy {
  CITY_BY_CITY_ID__CITY_ID_ASC = 'CITY_BY_CITY_ID__CITY_ID_ASC',
  CITY_BY_CITY_ID__CITY_ID_DESC = 'CITY_BY_CITY_ID__CITY_ID_DESC',
  CITY_BY_CITY_ID__COUNTRY_CODE_ALPHA2_ASC = 'CITY_BY_CITY_ID__COUNTRY_CODE_ALPHA2_ASC',
  CITY_BY_CITY_ID__COUNTRY_CODE_ALPHA2_DESC = 'CITY_BY_CITY_ID__COUNTRY_CODE_ALPHA2_DESC',
  CITY_BY_CITY_ID__DATE_CREATED_ASC = 'CITY_BY_CITY_ID__DATE_CREATED_ASC',
  CITY_BY_CITY_ID__DATE_CREATED_DESC = 'CITY_BY_CITY_ID__DATE_CREATED_DESC',
  CITY_BY_CITY_ID__DATE_UPDATED_ASC = 'CITY_BY_CITY_ID__DATE_UPDATED_ASC',
  CITY_BY_CITY_ID__DATE_UPDATED_DESC = 'CITY_BY_CITY_ID__DATE_UPDATED_DESC',
  CITY_BY_CITY_ID__NAME_ASC = 'CITY_BY_CITY_ID__NAME_ASC',
  CITY_BY_CITY_ID__NAME_DESC = 'CITY_BY_CITY_ID__NAME_DESC',
  CITY_BY_CITY_ID__REMARK_ASC = 'CITY_BY_CITY_ID__REMARK_ASC',
  CITY_BY_CITY_ID__REMARK_DESC = 'CITY_BY_CITY_ID__REMARK_DESC',
  CITY_BY_CITY_ID__USER_CREATED_ASC = 'CITY_BY_CITY_ID__USER_CREATED_ASC',
  CITY_BY_CITY_ID__USER_CREATED_DESC = 'CITY_BY_CITY_ID__USER_CREATED_DESC',
  CITY_BY_CITY_ID__USER_UPDATED_ASC = 'CITY_BY_CITY_ID__USER_UPDATED_ASC',
  CITY_BY_CITY_ID__USER_UPDATED_DESC = 'CITY_BY_CITY_ID__USER_UPDATED_DESC',
  CITY_BY_CITY_ID__UTC_OFFSET_ASC = 'CITY_BY_CITY_ID__UTC_OFFSET_ASC',
  CITY_BY_CITY_ID__UTC_OFFSET_DESC = 'CITY_BY_CITY_ID__UTC_OFFSET_DESC',
  CITY_ID_ASC = 'CITY_ID_ASC',
  CITY_ID_DESC = 'CITY_ID_DESC',
  COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC',
  COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_ASC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_ASC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_DESC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_DESC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANY_BY_COMPANY_ID__REMARK_ASC = 'COMPANY_BY_COMPANY_ID__REMARK_ASC',
  COMPANY_BY_COMPANY_ID__REMARK_DESC = 'COMPANY_BY_COMPANY_ID__REMARK_DESC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC',
  COMPANY_ID_ASC = 'COMPANY_ID_ASC',
  COMPANY_ID_DESC = 'COMPANY_ID_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface RelationCityCompany extends Node {
  __typename?: 'RelationCityCompany';
  city: City;
  cityId: Scalars['BigInt']['output'];
  company: Company;
  companyId: Scalars['BigInt']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface RelationCityCompanyCityIdFkeyCityCreateInput {
  country?: InputMaybe<CityCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey6InverseInput>;
  name: Scalars['String']['input'];
  offices?: InputMaybe<OfficeCityIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCityIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CityUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  utcOffset?: InputMaybe<IntervalInput>;
}

export interface RelationCityCompanyCityIdFkeyInput {
  connectByCityId?: InputMaybe<CityCityPkeyConnect>;
  connectByCountryCodeAlpha2AndName?: InputMaybe<CityCityCountryCodeAlpha2NameKeyConnect>;
  connectByNodeId?: InputMaybe<CityNodeIdConnect>;
  create?: InputMaybe<RelationCityCompanyCityIdFkeyCityCreateInput>;
  deleteByCityId?: InputMaybe<CityCityPkeyDelete>;
  deleteByCountryCodeAlpha2AndName?: InputMaybe<CityCityCountryCodeAlpha2NameKeyDelete>;
  deleteByNodeId?: InputMaybe<CityNodeIdDelete>;
  updateByCityId?: InputMaybe<CityOnRelationCityCompanyForRelationCityCompanyCityIdFkeyUsingCityPkeyUpdate>;
  updateByCountryCodeAlpha2AndName?: InputMaybe<CityOnRelationCityCompanyForRelationCityCompanyCityIdFkeyUsingCityCountryCodeAlpha2NameKeyUpdate>;
  updateByNodeId?: InputMaybe<RelationCityCompanyOnRelationCityCompanyForRelationCityCompanyCityIdFkeyNodeIdUpdate>;
}

export interface RelationCityCompanyCityIdFkeyInverseInput {
  connectByCityIdAndCompanyId?: InputMaybe<Array<RelationCityCompanyRelationCityCompanyPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationCityCompanyNodeIdConnect>>;
  create?: InputMaybe<Array<RelationCityCompanyCityIdFkeyRelationCityCompanyCreateInput>>;
  deleteByCityIdAndCompanyId?: InputMaybe<Array<RelationCityCompanyRelationCityCompanyPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationCityCompanyNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCityIdAndCompanyId?: InputMaybe<Array<RelationCityCompanyOnRelationCityCompanyForRelationCityCompanyCityIdFkeyUsingRelationCityCompanyPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<CityOnRelationCityCompanyForRelationCityCompanyCityIdFkeyNodeIdUpdate>>;
}

export interface RelationCityCompanyCityIdFkeyRelationCityCompanyCreateInput {
  city?: InputMaybe<RelationCityCompanyCityIdFkeyInput>;
  company?: InputMaybe<RelationCityCompanyCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCityCompanyCompanyIdFkeyCompanyCreateInput {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName: Scalars['String']['input'];
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCityCompanyCompanyIdFkeyInput {
  connectByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyConnect>;
  connectByCompanyId?: InputMaybe<CompanyCompanyPkeyConnect>;
  connectByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyConnect>;
  connectByNodeId?: InputMaybe<CompanyNodeIdConnect>;
  create?: InputMaybe<RelationCityCompanyCompanyIdFkeyCompanyCreateInput>;
  deleteByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyDelete>;
  deleteByCompanyId?: InputMaybe<CompanyCompanyPkeyDelete>;
  deleteByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyDelete>;
  deleteByNodeId?: InputMaybe<CompanyNodeIdDelete>;
  updateByAbbreviation?: InputMaybe<CompanyOnRelationCityCompanyForRelationCityCompanyCompanyIdFkeyUsingCompanyAbbreviationKeyUpdate>;
  updateByCompanyId?: InputMaybe<CompanyOnRelationCityCompanyForRelationCityCompanyCompanyIdFkeyUsingCompanyPkeyUpdate>;
  updateByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyOnRelationCityCompanyForRelationCityCompanyCompanyIdFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate>;
  updateByNodeId?: InputMaybe<RelationCityCompanyOnRelationCityCompanyForRelationCityCompanyCompanyIdFkeyNodeIdUpdate>;
}

export interface RelationCityCompanyCompanyIdFkeyInverseInput {
  connectByCityIdAndCompanyId?: InputMaybe<Array<RelationCityCompanyRelationCityCompanyPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationCityCompanyNodeIdConnect>>;
  create?: InputMaybe<Array<RelationCityCompanyCompanyIdFkeyRelationCityCompanyCreateInput>>;
  deleteByCityIdAndCompanyId?: InputMaybe<Array<RelationCityCompanyRelationCityCompanyPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationCityCompanyNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCityIdAndCompanyId?: InputMaybe<Array<RelationCityCompanyOnRelationCityCompanyForRelationCityCompanyCompanyIdFkeyUsingRelationCityCompanyPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<CompanyOnRelationCityCompanyForRelationCityCompanyCompanyIdFkeyNodeIdUpdate>>;
}

export interface RelationCityCompanyCompanyIdFkeyRelationCityCompanyCreateInput {
  city?: InputMaybe<RelationCityCompanyCityIdFkeyInput>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<RelationCityCompanyCompanyIdFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCityCompanyCondition {
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCityCompanyFilter {
  and?: InputMaybe<Array<RelationCityCompanyFilter>>;
  city?: InputMaybe<CityFilter>;
  cityId?: InputMaybe<BigIntFilter>;
  company?: InputMaybe<CompanyFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  not?: InputMaybe<RelationCityCompanyFilter>;
  or?: InputMaybe<Array<RelationCityCompanyFilter>>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface RelationCityCompanyInput {
  city?: InputMaybe<RelationCityCompanyCityIdFkeyInput>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<RelationCityCompanyCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCityCompanyNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface RelationCityCompanyNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface RelationCityCompanyOnRelationCityCompanyForRelationCityCompanyCityIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CityPatch;
}

export interface RelationCityCompanyOnRelationCityCompanyForRelationCityCompanyCityIdFkeyUsingRelationCityCompanyPkeyUpdate {
  cityId: Scalars['BigInt']['input'];
  companyId: Scalars['BigInt']['input'];
  patch: UpdateRelationCityCompanyOnRelationCityCompanyForRelationCityCompanyCityIdFkeyPatch;
}

export interface RelationCityCompanyOnRelationCityCompanyForRelationCityCompanyCompanyIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyPatch;
}

export interface RelationCityCompanyOnRelationCityCompanyForRelationCityCompanyCompanyIdFkeyUsingRelationCityCompanyPkeyUpdate {
  cityId: Scalars['BigInt']['input'];
  companyId: Scalars['BigInt']['input'];
  patch: UpdateRelationCityCompanyOnRelationCityCompanyForRelationCityCompanyCompanyIdFkeyPatch;
}

export interface RelationCityCompanyOnRelationCityCompanyForRelationCityCompanyUserCreatedFkeyUsingRelationCityCompanyPkeyUpdate {
  cityId: Scalars['BigInt']['input'];
  companyId: Scalars['BigInt']['input'];
  patch: UpdateRelationCityCompanyOnRelationCityCompanyForRelationCityCompanyUserCreatedFkeyPatch;
}

export interface RelationCityCompanyOnRelationCityCompanyForRelationCityCompanyUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface RelationCityCompanyOnRelationCityCompanyForRelationCityCompanyUserUpdatedFkeyUsingRelationCityCompanyPkeyUpdate {
  cityId: Scalars['BigInt']['input'];
  companyId: Scalars['BigInt']['input'];
  patch: UpdateRelationCityCompanyOnRelationCityCompanyForRelationCityCompanyUserUpdatedFkeyPatch;
}

export interface RelationCityCompanyPatch {
  city?: InputMaybe<RelationCityCompanyCityIdFkeyInput>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<RelationCityCompanyCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCityCompanyRelationCityCompanyPkeyConnect {
  cityId: Scalars['BigInt']['input'];
  companyId: Scalars['BigInt']['input'];
}

export interface RelationCityCompanyRelationCityCompanyPkeyDelete {
  cityId: Scalars['BigInt']['input'];
  companyId: Scalars['BigInt']['input'];
}

export interface RelationCityCompanyUserCreatedFkeyInverseInput {
  connectByCityIdAndCompanyId?: InputMaybe<Array<RelationCityCompanyRelationCityCompanyPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationCityCompanyNodeIdConnect>>;
  deleteByCityIdAndCompanyId?: InputMaybe<Array<RelationCityCompanyRelationCityCompanyPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationCityCompanyNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCityIdAndCompanyId?: InputMaybe<Array<RelationCityCompanyOnRelationCityCompanyForRelationCityCompanyUserCreatedFkeyUsingRelationCityCompanyPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnRelationCityCompanyForRelationCityCompanyUserCreatedFkeyNodeIdUpdate>>;
}

export interface RelationCityCompanyUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnRelationCityCompanyForRelationCityCompanyUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<RelationCityCompanyOnRelationCityCompanyForRelationCityCompanyUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnRelationCityCompanyForRelationCityCompanyUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface RelationCityCompanyUserUpdatedFkeyInverseInput {
  connectByCityIdAndCompanyId?: InputMaybe<Array<RelationCityCompanyRelationCityCompanyPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationCityCompanyNodeIdConnect>>;
  deleteByCityIdAndCompanyId?: InputMaybe<Array<RelationCityCompanyRelationCityCompanyPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationCityCompanyNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCityIdAndCompanyId?: InputMaybe<Array<RelationCityCompanyOnRelationCityCompanyForRelationCityCompanyUserUpdatedFkeyUsingRelationCityCompanyPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnRelationCityCompanyForRelationCityCompanyUserUpdatedFkeyNodeIdUpdate>>;
}

export interface RelationCompanyBusinessUnit extends Node {
  __typename?: 'RelationCompanyBusinessUnit';
  businessUnit: BusinessUnit;
  businessUnitId: Scalars['BigInt']['output'];
  company: Company;
  companyId: Scalars['BigInt']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface RelationCompanyBusinessUnitBusinessUnitIdFkeyBusinessUnitCreateInput {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInverseInput>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BusinessUnitUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCompanyBusinessUnitBusinessUnitIdFkeyInput {
  connectByBusinessUnitId?: InputMaybe<BusinessUnitBusinessUnitPkeyConnect>;
  connectByName?: InputMaybe<BusinessUnitBusinessUnitNameKeyConnect>;
  connectByNodeId?: InputMaybe<BusinessUnitNodeIdConnect>;
  create?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyBusinessUnitCreateInput>;
  deleteByBusinessUnitId?: InputMaybe<BusinessUnitBusinessUnitPkeyDelete>;
  deleteByName?: InputMaybe<BusinessUnitBusinessUnitNameKeyDelete>;
  deleteByNodeId?: InputMaybe<BusinessUnitNodeIdDelete>;
  updateByBusinessUnitId?: InputMaybe<BusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitBusinessUnitIdFkeyUsingBusinessUnitPkeyUpdate>;
  updateByName?: InputMaybe<BusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitBusinessUnitIdFkeyUsingBusinessUnitNameKeyUpdate>;
  updateByNodeId?: InputMaybe<RelationCompanyBusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitBusinessUnitIdFkeyNodeIdUpdate>;
}

export interface RelationCompanyBusinessUnitBusinessUnitIdFkeyInverseInput {
  connectByCompanyIdAndBusinessUnitId?: InputMaybe<Array<RelationCompanyBusinessUnitRelationCompanyBusinessUnitPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationCompanyBusinessUnitNodeIdConnect>>;
  create?: InputMaybe<Array<RelationCompanyBusinessUnitBusinessUnitIdFkeyRelationCompanyBusinessUnitCreateInput>>;
  deleteByCompanyIdAndBusinessUnitId?: InputMaybe<Array<RelationCompanyBusinessUnitRelationCompanyBusinessUnitPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationCompanyBusinessUnitNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyIdAndBusinessUnitId?: InputMaybe<
    Array<RelationCompanyBusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitBusinessUnitIdFkeyUsingRelationCompanyBusinessUnitPkeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<BusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitBusinessUnitIdFkeyNodeIdUpdate>>;
}

export interface RelationCompanyBusinessUnitBusinessUnitIdFkeyRelationCompanyBusinessUnitCreateInput {
  businessUnit?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInput>;
  company?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCompanyBusinessUnitCompanyIdFkeyCompanyCreateInput {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName: Scalars['String']['input'];
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCompanyBusinessUnitCompanyIdFkeyInput {
  connectByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyConnect>;
  connectByCompanyId?: InputMaybe<CompanyCompanyPkeyConnect>;
  connectByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyConnect>;
  connectByNodeId?: InputMaybe<CompanyNodeIdConnect>;
  create?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyCompanyCreateInput>;
  deleteByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyDelete>;
  deleteByCompanyId?: InputMaybe<CompanyCompanyPkeyDelete>;
  deleteByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyDelete>;
  deleteByNodeId?: InputMaybe<CompanyNodeIdDelete>;
  updateByAbbreviation?: InputMaybe<CompanyOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitCompanyIdFkeyUsingCompanyAbbreviationKeyUpdate>;
  updateByCompanyId?: InputMaybe<CompanyOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitCompanyIdFkeyUsingCompanyPkeyUpdate>;
  updateByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitCompanyIdFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate>;
  updateByNodeId?: InputMaybe<RelationCompanyBusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitCompanyIdFkeyNodeIdUpdate>;
}

export interface RelationCompanyBusinessUnitCompanyIdFkeyInverseInput {
  connectByCompanyIdAndBusinessUnitId?: InputMaybe<Array<RelationCompanyBusinessUnitRelationCompanyBusinessUnitPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationCompanyBusinessUnitNodeIdConnect>>;
  create?: InputMaybe<Array<RelationCompanyBusinessUnitCompanyIdFkeyRelationCompanyBusinessUnitCreateInput>>;
  deleteByCompanyIdAndBusinessUnitId?: InputMaybe<Array<RelationCompanyBusinessUnitRelationCompanyBusinessUnitPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationCompanyBusinessUnitNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyIdAndBusinessUnitId?: InputMaybe<Array<RelationCompanyBusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitCompanyIdFkeyUsingRelationCompanyBusinessUnitPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<CompanyOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitCompanyIdFkeyNodeIdUpdate>>;
}

export interface RelationCompanyBusinessUnitCompanyIdFkeyRelationCompanyBusinessUnitCreateInput {
  businessUnit?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCompanyBusinessUnitCondition {
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCompanyBusinessUnitFilter {
  and?: InputMaybe<Array<RelationCompanyBusinessUnitFilter>>;
  businessUnit?: InputMaybe<BusinessUnitFilter>;
  businessUnitId?: InputMaybe<BigIntFilter>;
  company?: InputMaybe<CompanyFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  not?: InputMaybe<RelationCompanyBusinessUnitFilter>;
  or?: InputMaybe<Array<RelationCompanyBusinessUnitFilter>>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface RelationCompanyBusinessUnitInput {
  businessUnit?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCompanyBusinessUnitNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface RelationCompanyBusinessUnitNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface RelationCompanyBusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitBusinessUnitIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: BusinessUnitPatch;
}

export interface RelationCompanyBusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitBusinessUnitIdFkeyUsingRelationCompanyBusinessUnitPkeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  companyId: Scalars['BigInt']['input'];
  patch: UpdateRelationCompanyBusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitBusinessUnitIdFkeyPatch;
}

export interface RelationCompanyBusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitCompanyIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyPatch;
}

export interface RelationCompanyBusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitCompanyIdFkeyUsingRelationCompanyBusinessUnitPkeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  companyId: Scalars['BigInt']['input'];
  patch: UpdateRelationCompanyBusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitCompanyIdFkeyPatch;
}

export interface RelationCompanyBusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitUserCreatedFkeyUsingRelationCompanyBusinessUnitPkeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  companyId: Scalars['BigInt']['input'];
  patch: UpdateRelationCompanyBusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitUserCreatedFkeyPatch;
}

export interface RelationCompanyBusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface RelationCompanyBusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitUserUpdatedFkeyUsingRelationCompanyBusinessUnitPkeyUpdate {
  businessUnitId: Scalars['BigInt']['input'];
  companyId: Scalars['BigInt']['input'];
  patch: UpdateRelationCompanyBusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitUserUpdatedFkeyPatch;
}

export interface RelationCompanyBusinessUnitPatch {
  businessUnit?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCompanyBusinessUnitRelationCompanyBusinessUnitPkeyConnect {
  businessUnitId: Scalars['BigInt']['input'];
  companyId: Scalars['BigInt']['input'];
}

export interface RelationCompanyBusinessUnitRelationCompanyBusinessUnitPkeyDelete {
  businessUnitId: Scalars['BigInt']['input'];
  companyId: Scalars['BigInt']['input'];
}

export interface RelationCompanyBusinessUnitUserCreatedFkeyInverseInput {
  connectByCompanyIdAndBusinessUnitId?: InputMaybe<Array<RelationCompanyBusinessUnitRelationCompanyBusinessUnitPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationCompanyBusinessUnitNodeIdConnect>>;
  deleteByCompanyIdAndBusinessUnitId?: InputMaybe<Array<RelationCompanyBusinessUnitRelationCompanyBusinessUnitPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationCompanyBusinessUnitNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyIdAndBusinessUnitId?: InputMaybe<
    Array<RelationCompanyBusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitUserCreatedFkeyUsingRelationCompanyBusinessUnitPkeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<UserAccountOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitUserCreatedFkeyNodeIdUpdate>>;
}

export interface RelationCompanyBusinessUnitUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<RelationCompanyBusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput {
  connectByCompanyIdAndBusinessUnitId?: InputMaybe<Array<RelationCompanyBusinessUnitRelationCompanyBusinessUnitPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationCompanyBusinessUnitNodeIdConnect>>;
  deleteByCompanyIdAndBusinessUnitId?: InputMaybe<Array<RelationCompanyBusinessUnitRelationCompanyBusinessUnitPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationCompanyBusinessUnitNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyIdAndBusinessUnitId?: InputMaybe<
    Array<RelationCompanyBusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitUserUpdatedFkeyUsingRelationCompanyBusinessUnitPkeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<UserAccountOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitUserUpdatedFkeyNodeIdUpdate>>;
}

export interface RelationCompanyBusinessUnitsConnection {
  __typename?: 'RelationCompanyBusinessUnitsConnection';
  edges: Array<RelationCompanyBusinessUnitsEdge>;
  nodes: Array<RelationCompanyBusinessUnit>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface RelationCompanyBusinessUnitsEdge {
  __typename?: 'RelationCompanyBusinessUnitsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: RelationCompanyBusinessUnit;
}

export enum RelationCompanyBusinessUnitsOrderBy {
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_UNIT_ID_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_CREATED_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DATE_UPDATED_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_ACTIVE_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__REMARK_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_CREATED_DESC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_ASC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_ASC',
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_DESC = 'BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__USER_UPDATED_DESC',
  BUSINESS_UNIT_ID_ASC = 'BUSINESS_UNIT_ID_ASC',
  BUSINESS_UNIT_ID_DESC = 'BUSINESS_UNIT_ID_DESC',
  COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC',
  COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_ASC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_ASC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_DESC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_DESC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANY_BY_COMPANY_ID__REMARK_ASC = 'COMPANY_BY_COMPANY_ID__REMARK_ASC',
  COMPANY_BY_COMPANY_ID__REMARK_DESC = 'COMPANY_BY_COMPANY_ID__REMARK_DESC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC',
  COMPANY_ID_ASC = 'COMPANY_ID_ASC',
  COMPANY_ID_DESC = 'COMPANY_ID_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface RelationCompanyGroupEmailDomain extends Node {
  __typename?: 'RelationCompanyGroupEmailDomain';
  companyGroup: CompanyGroup;
  companyGroupId: Scalars['BigInt']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  emailDomain: EmailDomain;
  emailDomainId: Scalars['BigInt']['output'];
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface RelationCompanyGroupEmailDomainCompanyGroupIdFkeyCompanyGroupCreateInput {
  companies?: InputMaybe<CompanyCompanyGroupIdFkeyInverseInput>;
  name: Scalars['String']['input'];
  relationCompanyGroupEmailDomains?: InputMaybe<RelationCompanyGroupEmailDomainCompanyGroupIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyGroupUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCompanyGroupEmailDomainCompanyGroupIdFkeyInput {
  connectByCompanyGroupId?: InputMaybe<CompanyGroupCompanyGroupPkeyConnect>;
  connectByName?: InputMaybe<CompanyGroupCompanyGroupNameKeyConnect>;
  connectByNodeId?: InputMaybe<CompanyGroupNodeIdConnect>;
  create?: InputMaybe<RelationCompanyGroupEmailDomainCompanyGroupIdFkeyCompanyGroupCreateInput>;
  deleteByCompanyGroupId?: InputMaybe<CompanyGroupCompanyGroupPkeyDelete>;
  deleteByName?: InputMaybe<CompanyGroupCompanyGroupNameKeyDelete>;
  deleteByNodeId?: InputMaybe<CompanyGroupNodeIdDelete>;
  updateByCompanyGroupId?: InputMaybe<CompanyGroupOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainCompanyGroupIdFkeyUsingCompanyGroupPkeyUpdate>;
  updateByName?: InputMaybe<CompanyGroupOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainCompanyGroupIdFkeyUsingCompanyGroupNameKeyUpdate>;
  updateByNodeId?: InputMaybe<RelationCompanyGroupEmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainCompanyGroupIdFkeyNodeIdUpdate>;
}

export interface RelationCompanyGroupEmailDomainCompanyGroupIdFkeyInverseInput {
  connectByCompanyGroupIdAndEmailDomainId?: InputMaybe<Array<RelationCompanyGroupEmailDomainRelationCompanyGroupEmailDomainPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationCompanyGroupEmailDomainNodeIdConnect>>;
  create?: InputMaybe<Array<RelationCompanyGroupEmailDomainCompanyGroupIdFkeyRelationCompanyGroupEmailDomainCreateInput>>;
  deleteByCompanyGroupIdAndEmailDomainId?: InputMaybe<Array<RelationCompanyGroupEmailDomainRelationCompanyGroupEmailDomainPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationCompanyGroupEmailDomainNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyGroupIdAndEmailDomainId?: InputMaybe<
    Array<RelationCompanyGroupEmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainCompanyGroupIdFkeyUsingRelationCompanyGroupEmailDomainPkeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<CompanyGroupOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainCompanyGroupIdFkeyNodeIdUpdate>>;
}

export interface RelationCompanyGroupEmailDomainCompanyGroupIdFkeyRelationCompanyGroupEmailDomainCreateInput {
  companyGroup?: InputMaybe<RelationCompanyGroupEmailDomainCompanyGroupIdFkeyInput>;
  emailDomain?: InputMaybe<RelationCompanyGroupEmailDomainEmailDomainIdFkeyInput>;
  emailDomainId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCompanyGroupEmailDomainCondition {
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  emailDomainId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCompanyGroupEmailDomainEmailDomainIdFkeyEmailDomainCreateInput {
  domainName: Scalars['String']['input'];
  isSsoLoginAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  relationCompanyGroupEmailDomains?: InputMaybe<RelationCompanyGroupEmailDomainEmailDomainIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmailDomainUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCompanyGroupEmailDomainEmailDomainIdFkeyInput {
  connectByDomainName?: InputMaybe<EmailDomainEmailDomainDomainNameKeyConnect>;
  connectByEmailDomainId?: InputMaybe<EmailDomainEmailDomainPkeyConnect>;
  connectByNodeId?: InputMaybe<EmailDomainNodeIdConnect>;
  create?: InputMaybe<RelationCompanyGroupEmailDomainEmailDomainIdFkeyEmailDomainCreateInput>;
  deleteByDomainName?: InputMaybe<EmailDomainEmailDomainDomainNameKeyDelete>;
  deleteByEmailDomainId?: InputMaybe<EmailDomainEmailDomainPkeyDelete>;
  deleteByNodeId?: InputMaybe<EmailDomainNodeIdDelete>;
  updateByDomainName?: InputMaybe<EmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainEmailDomainIdFkeyUsingEmailDomainDomainNameKeyUpdate>;
  updateByEmailDomainId?: InputMaybe<EmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainEmailDomainIdFkeyUsingEmailDomainPkeyUpdate>;
  updateByNodeId?: InputMaybe<RelationCompanyGroupEmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainEmailDomainIdFkeyNodeIdUpdate>;
}

export interface RelationCompanyGroupEmailDomainEmailDomainIdFkeyInverseInput {
  connectByCompanyGroupIdAndEmailDomainId?: InputMaybe<Array<RelationCompanyGroupEmailDomainRelationCompanyGroupEmailDomainPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationCompanyGroupEmailDomainNodeIdConnect>>;
  create?: InputMaybe<Array<RelationCompanyGroupEmailDomainEmailDomainIdFkeyRelationCompanyGroupEmailDomainCreateInput>>;
  deleteByCompanyGroupIdAndEmailDomainId?: InputMaybe<Array<RelationCompanyGroupEmailDomainRelationCompanyGroupEmailDomainPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationCompanyGroupEmailDomainNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyGroupIdAndEmailDomainId?: InputMaybe<
    Array<RelationCompanyGroupEmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainEmailDomainIdFkeyUsingRelationCompanyGroupEmailDomainPkeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<EmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainEmailDomainIdFkeyNodeIdUpdate>>;
}

export interface RelationCompanyGroupEmailDomainEmailDomainIdFkeyRelationCompanyGroupEmailDomainCreateInput {
  companyGroup?: InputMaybe<RelationCompanyGroupEmailDomainCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  emailDomain?: InputMaybe<RelationCompanyGroupEmailDomainEmailDomainIdFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCompanyGroupEmailDomainFilter {
  and?: InputMaybe<Array<RelationCompanyGroupEmailDomainFilter>>;
  companyGroup?: InputMaybe<CompanyGroupFilter>;
  companyGroupId?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  emailDomain?: InputMaybe<EmailDomainFilter>;
  emailDomainId?: InputMaybe<BigIntFilter>;
  not?: InputMaybe<RelationCompanyGroupEmailDomainFilter>;
  or?: InputMaybe<Array<RelationCompanyGroupEmailDomainFilter>>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface RelationCompanyGroupEmailDomainInput {
  companyGroup?: InputMaybe<RelationCompanyGroupEmailDomainCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  emailDomain?: InputMaybe<RelationCompanyGroupEmailDomainEmailDomainIdFkeyInput>;
  emailDomainId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCompanyGroupEmailDomainNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface RelationCompanyGroupEmailDomainNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface RelationCompanyGroupEmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainCompanyGroupIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyGroupPatch;
}

export interface RelationCompanyGroupEmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainCompanyGroupIdFkeyUsingRelationCompanyGroupEmailDomainPkeyUpdate {
  companyGroupId: Scalars['BigInt']['input'];
  emailDomainId: Scalars['BigInt']['input'];
  patch: UpdateRelationCompanyGroupEmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainCompanyGroupIdFkeyPatch;
}

export interface RelationCompanyGroupEmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainEmailDomainIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmailDomainPatch;
}

export interface RelationCompanyGroupEmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainEmailDomainIdFkeyUsingRelationCompanyGroupEmailDomainPkeyUpdate {
  companyGroupId: Scalars['BigInt']['input'];
  emailDomainId: Scalars['BigInt']['input'];
  patch: UpdateRelationCompanyGroupEmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainEmailDomainIdFkeyPatch;
}

export interface RelationCompanyGroupEmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainUserCreatedFkeyUsingRelationCompanyGroupEmailDomainPkeyUpdate {
  companyGroupId: Scalars['BigInt']['input'];
  emailDomainId: Scalars['BigInt']['input'];
  patch: UpdateRelationCompanyGroupEmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainUserCreatedFkeyPatch;
}

export interface RelationCompanyGroupEmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface RelationCompanyGroupEmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainUserUpdatedFkeyUsingRelationCompanyGroupEmailDomainPkeyUpdate {
  companyGroupId: Scalars['BigInt']['input'];
  emailDomainId: Scalars['BigInt']['input'];
  patch: UpdateRelationCompanyGroupEmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainUserUpdatedFkeyPatch;
}

export interface RelationCompanyGroupEmailDomainPatch {
  companyGroup?: InputMaybe<RelationCompanyGroupEmailDomainCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  emailDomain?: InputMaybe<RelationCompanyGroupEmailDomainEmailDomainIdFkeyInput>;
  emailDomainId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCompanyGroupEmailDomainRelationCompanyGroupEmailDomainPkeyConnect {
  companyGroupId: Scalars['BigInt']['input'];
  emailDomainId: Scalars['BigInt']['input'];
}

export interface RelationCompanyGroupEmailDomainRelationCompanyGroupEmailDomainPkeyDelete {
  companyGroupId: Scalars['BigInt']['input'];
  emailDomainId: Scalars['BigInt']['input'];
}

export interface RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput {
  connectByCompanyGroupIdAndEmailDomainId?: InputMaybe<Array<RelationCompanyGroupEmailDomainRelationCompanyGroupEmailDomainPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationCompanyGroupEmailDomainNodeIdConnect>>;
  deleteByCompanyGroupIdAndEmailDomainId?: InputMaybe<Array<RelationCompanyGroupEmailDomainRelationCompanyGroupEmailDomainPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationCompanyGroupEmailDomainNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyGroupIdAndEmailDomainId?: InputMaybe<
    Array<RelationCompanyGroupEmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainUserCreatedFkeyUsingRelationCompanyGroupEmailDomainPkeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<UserAccountOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainUserCreatedFkeyNodeIdUpdate>>;
}

export interface RelationCompanyGroupEmailDomainUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<RelationCompanyGroupEmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput {
  connectByCompanyGroupIdAndEmailDomainId?: InputMaybe<Array<RelationCompanyGroupEmailDomainRelationCompanyGroupEmailDomainPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationCompanyGroupEmailDomainNodeIdConnect>>;
  deleteByCompanyGroupIdAndEmailDomainId?: InputMaybe<Array<RelationCompanyGroupEmailDomainRelationCompanyGroupEmailDomainPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationCompanyGroupEmailDomainNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyGroupIdAndEmailDomainId?: InputMaybe<
    Array<RelationCompanyGroupEmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainUserUpdatedFkeyUsingRelationCompanyGroupEmailDomainPkeyUpdate>
  >;
  updateByNodeId?: InputMaybe<Array<UserAccountOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainUserUpdatedFkeyNodeIdUpdate>>;
}

export interface RelationCompanyGroupEmailDomainsConnection {
  __typename?: 'RelationCompanyGroupEmailDomainsConnection';
  edges: Array<RelationCompanyGroupEmailDomainsEdge>;
  nodes: Array<RelationCompanyGroupEmailDomain>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface RelationCompanyGroupEmailDomainsEdge {
  __typename?: 'RelationCompanyGroupEmailDomainsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: RelationCompanyGroupEmailDomain;
}

export enum RelationCompanyGroupEmailDomainsOrderBy {
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__COMPANY_GROUP_ID_ASC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__COMPANY_GROUP_ID_ASC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__COMPANY_GROUP_ID_DESC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__COMPANY_GROUP_ID_DESC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__DATE_CREATED_ASC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__DATE_CREATED_ASC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__DATE_CREATED_DESC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__DATE_CREATED_DESC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__DATE_UPDATED_ASC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__DATE_UPDATED_ASC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__DATE_UPDATED_DESC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__DATE_UPDATED_DESC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__NAME_ASC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__NAME_ASC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__NAME_DESC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__NAME_DESC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__REMARK_ASC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__REMARK_ASC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__REMARK_DESC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__REMARK_DESC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__USER_CREATED_ASC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__USER_CREATED_ASC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__USER_CREATED_DESC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__USER_CREATED_DESC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__USER_UPDATED_ASC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__USER_UPDATED_ASC',
  COMPANY_GROUP_BY_COMPANY_GROUP_ID__USER_UPDATED_DESC = 'COMPANY_GROUP_BY_COMPANY_GROUP_ID__USER_UPDATED_DESC',
  COMPANY_GROUP_ID_ASC = 'COMPANY_GROUP_ID_ASC',
  COMPANY_GROUP_ID_DESC = 'COMPANY_GROUP_ID_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__DATE_CREATED_ASC = 'EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__DATE_CREATED_ASC',
  EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__DATE_CREATED_DESC = 'EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__DATE_CREATED_DESC',
  EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__DATE_UPDATED_ASC = 'EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__DATE_UPDATED_ASC',
  EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__DATE_UPDATED_DESC = 'EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__DATE_UPDATED_DESC',
  EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__DOMAIN_NAME_ASC = 'EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__DOMAIN_NAME_ASC',
  EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__DOMAIN_NAME_DESC = 'EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__DOMAIN_NAME_DESC',
  EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__EMAIL_DOMAIN_ID_ASC = 'EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__EMAIL_DOMAIN_ID_ASC',
  EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__EMAIL_DOMAIN_ID_DESC = 'EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__EMAIL_DOMAIN_ID_DESC',
  EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__IS_SSO_LOGIN_AVAILABLE_ASC = 'EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__IS_SSO_LOGIN_AVAILABLE_ASC',
  EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__IS_SSO_LOGIN_AVAILABLE_DESC = 'EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__IS_SSO_LOGIN_AVAILABLE_DESC',
  EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__REMARK_ASC = 'EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__REMARK_ASC',
  EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__REMARK_DESC = 'EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__REMARK_DESC',
  EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__USER_CREATED_ASC = 'EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__USER_CREATED_ASC',
  EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__USER_CREATED_DESC = 'EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__USER_CREATED_DESC',
  EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__USER_UPDATED_ASC = 'EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__USER_UPDATED_ASC',
  EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__USER_UPDATED_DESC = 'EMAIL_DOMAIN_BY_EMAIL_DOMAIN_ID__USER_UPDATED_DESC',
  EMAIL_DOMAIN_ID_ASC = 'EMAIL_DOMAIN_ID_ASC',
  EMAIL_DOMAIN_ID_DESC = 'EMAIL_DOMAIN_ID_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface RelationCompanyOffice extends Node {
  __typename?: 'RelationCompanyOffice';
  company: Company;
  companyId: Scalars['BigInt']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  nodeId: Scalars['ID']['output'];
  office: Office;
  officeId: Scalars['BigInt']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface RelationCompanyOfficeCompanyIdFkeyCompanyCreateInput {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName: Scalars['String']['input'];
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCompanyOfficeCompanyIdFkeyInput {
  connectByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyConnect>;
  connectByCompanyId?: InputMaybe<CompanyCompanyPkeyConnect>;
  connectByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyConnect>;
  connectByNodeId?: InputMaybe<CompanyNodeIdConnect>;
  create?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyCompanyCreateInput>;
  deleteByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyDelete>;
  deleteByCompanyId?: InputMaybe<CompanyCompanyPkeyDelete>;
  deleteByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyDelete>;
  deleteByNodeId?: InputMaybe<CompanyNodeIdDelete>;
  updateByAbbreviation?: InputMaybe<CompanyOnRelationCompanyOfficeForRelationCompanyOfficeCompanyIdFkeyUsingCompanyAbbreviationKeyUpdate>;
  updateByCompanyId?: InputMaybe<CompanyOnRelationCompanyOfficeForRelationCompanyOfficeCompanyIdFkeyUsingCompanyPkeyUpdate>;
  updateByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyOnRelationCompanyOfficeForRelationCompanyOfficeCompanyIdFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate>;
  updateByNodeId?: InputMaybe<RelationCompanyOfficeOnRelationCompanyOfficeForRelationCompanyOfficeCompanyIdFkeyNodeIdUpdate>;
}

export interface RelationCompanyOfficeCompanyIdFkeyInverseInput {
  connectByCompanyIdAndOfficeId?: InputMaybe<Array<RelationCompanyOfficeRelationCompanyOfficePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationCompanyOfficeNodeIdConnect>>;
  create?: InputMaybe<Array<RelationCompanyOfficeCompanyIdFkeyRelationCompanyOfficeCreateInput>>;
  deleteByCompanyIdAndOfficeId?: InputMaybe<Array<RelationCompanyOfficeRelationCompanyOfficePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationCompanyOfficeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyIdAndOfficeId?: InputMaybe<Array<RelationCompanyOfficeOnRelationCompanyOfficeForRelationCompanyOfficeCompanyIdFkeyUsingRelationCompanyOfficePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<CompanyOnRelationCompanyOfficeForRelationCompanyOfficeCompanyIdFkeyNodeIdUpdate>>;
}

export interface RelationCompanyOfficeCompanyIdFkeyRelationCompanyOfficeCreateInput {
  company?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInput>;
  office?: InputMaybe<RelationCompanyOfficeOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCompanyOfficeCondition {
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCompanyOfficeFilter {
  and?: InputMaybe<Array<RelationCompanyOfficeFilter>>;
  company?: InputMaybe<CompanyFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  not?: InputMaybe<RelationCompanyOfficeFilter>;
  office?: InputMaybe<OfficeFilter>;
  officeId?: InputMaybe<BigIntFilter>;
  or?: InputMaybe<Array<RelationCompanyOfficeFilter>>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface RelationCompanyOfficeInput {
  company?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  office?: InputMaybe<RelationCompanyOfficeOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCompanyOfficeNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface RelationCompanyOfficeNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface RelationCompanyOfficeOfficeIdFkeyInput {
  connectByCityIdAndName?: InputMaybe<OfficeOfficeCityIdNameKeyConnect>;
  connectByNodeId?: InputMaybe<OfficeNodeIdConnect>;
  connectByOfficeId?: InputMaybe<OfficeOfficePkeyConnect>;
  create?: InputMaybe<RelationCompanyOfficeOfficeIdFkeyOfficeCreateInput>;
  deleteByCityIdAndName?: InputMaybe<OfficeOfficeCityIdNameKeyDelete>;
  deleteByNodeId?: InputMaybe<OfficeNodeIdDelete>;
  deleteByOfficeId?: InputMaybe<OfficeOfficePkeyDelete>;
  updateByCityIdAndName?: InputMaybe<OfficeOnRelationCompanyOfficeForRelationCompanyOfficeOfficeIdFkeyUsingOfficeCityIdNameKeyUpdate>;
  updateByNodeId?: InputMaybe<RelationCompanyOfficeOnRelationCompanyOfficeForRelationCompanyOfficeOfficeIdFkeyNodeIdUpdate>;
  updateByOfficeId?: InputMaybe<OfficeOnRelationCompanyOfficeForRelationCompanyOfficeOfficeIdFkeyUsingOfficePkeyUpdate>;
}

export interface RelationCompanyOfficeOfficeIdFkeyInverseInput {
  connectByCompanyIdAndOfficeId?: InputMaybe<Array<RelationCompanyOfficeRelationCompanyOfficePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationCompanyOfficeNodeIdConnect>>;
  create?: InputMaybe<Array<RelationCompanyOfficeOfficeIdFkeyRelationCompanyOfficeCreateInput>>;
  deleteByCompanyIdAndOfficeId?: InputMaybe<Array<RelationCompanyOfficeRelationCompanyOfficePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationCompanyOfficeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyIdAndOfficeId?: InputMaybe<Array<RelationCompanyOfficeOnRelationCompanyOfficeForRelationCompanyOfficeOfficeIdFkeyUsingRelationCompanyOfficePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<OfficeOnRelationCompanyOfficeForRelationCompanyOfficeOfficeIdFkeyNodeIdUpdate>>;
}

export interface RelationCompanyOfficeOfficeIdFkeyOfficeCreateInput {
  city?: InputMaybe<OfficeCityIdFkeyInput>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInverseInput>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  officeWeathers?: InputMaybe<OfficeWeatherOfficeIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeOfficeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<OfficeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCompanyOfficeOfficeIdFkeyRelationCompanyOfficeCreateInput {
  company?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  office?: InputMaybe<RelationCompanyOfficeOfficeIdFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCompanyOfficeOnRelationCompanyOfficeForRelationCompanyOfficeCompanyIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyPatch;
}

export interface RelationCompanyOfficeOnRelationCompanyOfficeForRelationCompanyOfficeCompanyIdFkeyUsingRelationCompanyOfficePkeyUpdate {
  companyId: Scalars['BigInt']['input'];
  officeId: Scalars['BigInt']['input'];
  patch: UpdateRelationCompanyOfficeOnRelationCompanyOfficeForRelationCompanyOfficeCompanyIdFkeyPatch;
}

export interface RelationCompanyOfficeOnRelationCompanyOfficeForRelationCompanyOfficeOfficeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: OfficePatch;
}

export interface RelationCompanyOfficeOnRelationCompanyOfficeForRelationCompanyOfficeOfficeIdFkeyUsingRelationCompanyOfficePkeyUpdate {
  companyId: Scalars['BigInt']['input'];
  officeId: Scalars['BigInt']['input'];
  patch: UpdateRelationCompanyOfficeOnRelationCompanyOfficeForRelationCompanyOfficeOfficeIdFkeyPatch;
}

export interface RelationCompanyOfficeOnRelationCompanyOfficeForRelationCompanyOfficeUserCreatedFkeyUsingRelationCompanyOfficePkeyUpdate {
  companyId: Scalars['BigInt']['input'];
  officeId: Scalars['BigInt']['input'];
  patch: UpdateRelationCompanyOfficeOnRelationCompanyOfficeForRelationCompanyOfficeUserCreatedFkeyPatch;
}

export interface RelationCompanyOfficeOnRelationCompanyOfficeForRelationCompanyOfficeUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface RelationCompanyOfficeOnRelationCompanyOfficeForRelationCompanyOfficeUserUpdatedFkeyUsingRelationCompanyOfficePkeyUpdate {
  companyId: Scalars['BigInt']['input'];
  officeId: Scalars['BigInt']['input'];
  patch: UpdateRelationCompanyOfficeOnRelationCompanyOfficeForRelationCompanyOfficeUserUpdatedFkeyPatch;
}

export interface RelationCompanyOfficePatch {
  company?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  office?: InputMaybe<RelationCompanyOfficeOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCompanyOfficeRelationCompanyOfficePkeyConnect {
  companyId: Scalars['BigInt']['input'];
  officeId: Scalars['BigInt']['input'];
}

export interface RelationCompanyOfficeRelationCompanyOfficePkeyDelete {
  companyId: Scalars['BigInt']['input'];
  officeId: Scalars['BigInt']['input'];
}

export interface RelationCompanyOfficeUserCreatedFkeyInverseInput {
  connectByCompanyIdAndOfficeId?: InputMaybe<Array<RelationCompanyOfficeRelationCompanyOfficePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationCompanyOfficeNodeIdConnect>>;
  deleteByCompanyIdAndOfficeId?: InputMaybe<Array<RelationCompanyOfficeRelationCompanyOfficePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationCompanyOfficeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyIdAndOfficeId?: InputMaybe<Array<RelationCompanyOfficeOnRelationCompanyOfficeForRelationCompanyOfficeUserCreatedFkeyUsingRelationCompanyOfficePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnRelationCompanyOfficeForRelationCompanyOfficeUserCreatedFkeyNodeIdUpdate>>;
}

export interface RelationCompanyOfficeUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnRelationCompanyOfficeForRelationCompanyOfficeUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<RelationCompanyOfficeOnRelationCompanyOfficeForRelationCompanyOfficeUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnRelationCompanyOfficeForRelationCompanyOfficeUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface RelationCompanyOfficeUserUpdatedFkeyInverseInput {
  connectByCompanyIdAndOfficeId?: InputMaybe<Array<RelationCompanyOfficeRelationCompanyOfficePkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationCompanyOfficeNodeIdConnect>>;
  deleteByCompanyIdAndOfficeId?: InputMaybe<Array<RelationCompanyOfficeRelationCompanyOfficePkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationCompanyOfficeNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCompanyIdAndOfficeId?: InputMaybe<Array<RelationCompanyOfficeOnRelationCompanyOfficeForRelationCompanyOfficeUserUpdatedFkeyUsingRelationCompanyOfficePkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnRelationCompanyOfficeForRelationCompanyOfficeUserUpdatedFkeyNodeIdUpdate>>;
}

export interface RelationCompanyOfficesConnection {
  __typename?: 'RelationCompanyOfficesConnection';
  edges: Array<RelationCompanyOfficesEdge>;
  nodes: Array<RelationCompanyOffice>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface RelationCompanyOfficesEdge {
  __typename?: 'RelationCompanyOfficesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: RelationCompanyOffice;
}

export enum RelationCompanyOfficesOrderBy {
  COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC',
  COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_ASC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_ASC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_DESC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_DESC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANY_BY_COMPANY_ID__REMARK_ASC = 'COMPANY_BY_COMPANY_ID__REMARK_ASC',
  COMPANY_BY_COMPANY_ID__REMARK_DESC = 'COMPANY_BY_COMPANY_ID__REMARK_DESC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC',
  COMPANY_ID_ASC = 'COMPANY_ID_ASC',
  COMPANY_ID_DESC = 'COMPANY_ID_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  NATURAL = 'NATURAL',
  OFFICE_BY_OFFICE_ID__CITY_ID_ASC = 'OFFICE_BY_OFFICE_ID__CITY_ID_ASC',
  OFFICE_BY_OFFICE_ID__CITY_ID_DESC = 'OFFICE_BY_OFFICE_ID__CITY_ID_DESC',
  OFFICE_BY_OFFICE_ID__DATE_CREATED_ASC = 'OFFICE_BY_OFFICE_ID__DATE_CREATED_ASC',
  OFFICE_BY_OFFICE_ID__DATE_CREATED_DESC = 'OFFICE_BY_OFFICE_ID__DATE_CREATED_DESC',
  OFFICE_BY_OFFICE_ID__DATE_UPDATED_ASC = 'OFFICE_BY_OFFICE_ID__DATE_UPDATED_ASC',
  OFFICE_BY_OFFICE_ID__DATE_UPDATED_DESC = 'OFFICE_BY_OFFICE_ID__DATE_UPDATED_DESC',
  OFFICE_BY_OFFICE_ID__LATITUDE_ASC = 'OFFICE_BY_OFFICE_ID__LATITUDE_ASC',
  OFFICE_BY_OFFICE_ID__LATITUDE_DESC = 'OFFICE_BY_OFFICE_ID__LATITUDE_DESC',
  OFFICE_BY_OFFICE_ID__LONGITUDE_ASC = 'OFFICE_BY_OFFICE_ID__LONGITUDE_ASC',
  OFFICE_BY_OFFICE_ID__LONGITUDE_DESC = 'OFFICE_BY_OFFICE_ID__LONGITUDE_DESC',
  OFFICE_BY_OFFICE_ID__NAME_ASC = 'OFFICE_BY_OFFICE_ID__NAME_ASC',
  OFFICE_BY_OFFICE_ID__NAME_DESC = 'OFFICE_BY_OFFICE_ID__NAME_DESC',
  OFFICE_BY_OFFICE_ID__OFFICE_ID_ASC = 'OFFICE_BY_OFFICE_ID__OFFICE_ID_ASC',
  OFFICE_BY_OFFICE_ID__OFFICE_ID_DESC = 'OFFICE_BY_OFFICE_ID__OFFICE_ID_DESC',
  OFFICE_BY_OFFICE_ID__REMARK_ASC = 'OFFICE_BY_OFFICE_ID__REMARK_ASC',
  OFFICE_BY_OFFICE_ID__REMARK_DESC = 'OFFICE_BY_OFFICE_ID__REMARK_DESC',
  OFFICE_BY_OFFICE_ID__USER_CREATED_ASC = 'OFFICE_BY_OFFICE_ID__USER_CREATED_ASC',
  OFFICE_BY_OFFICE_ID__USER_CREATED_DESC = 'OFFICE_BY_OFFICE_ID__USER_CREATED_DESC',
  OFFICE_BY_OFFICE_ID__USER_UPDATED_ASC = 'OFFICE_BY_OFFICE_ID__USER_UPDATED_ASC',
  OFFICE_BY_OFFICE_ID__USER_UPDATED_DESC = 'OFFICE_BY_OFFICE_ID__USER_UPDATED_DESC',
  OFFICE_ID_ASC = 'OFFICE_ID_ASC',
  OFFICE_ID_DESC = 'OFFICE_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface RelationCurrencyCountriesConnection {
  __typename?: 'RelationCurrencyCountriesConnection';
  edges: Array<RelationCurrencyCountriesEdge>;
  nodes: Array<RelationCurrencyCountry>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface RelationCurrencyCountriesEdge {
  __typename?: 'RelationCurrencyCountriesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: RelationCurrencyCountry;
}

export enum RelationCurrencyCountriesOrderBy {
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA2_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_ALPHA3_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__COUNTRY_CODE_NUMERIC_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_CREATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_CREATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_CREATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_CREATED_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_UPDATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_UPDATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_UPDATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DATE_UPDATED_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__DISPLAY_ORDER_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__REMARK_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__REMARK_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__REMARK_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__REMARK_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__SHORT_NAME_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__SHORT_NAME_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__SHORT_NAME_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__SHORT_NAME_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__TELEPHONE_CODE_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_CREATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_CREATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_CREATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_CREATED_DESC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_UPDATED_ASC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_UPDATED_ASC',
  COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_UPDATED_DESC = 'COUNTRY_BY_COUNTRY_CODE_ALPHA2__USER_UPDATED_DESC',
  COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_CODE_ALPHA2_DESC',
  CURRENCY_BY_CURRENCY_CODE__ABBREVIATION_ASC = 'CURRENCY_BY_CURRENCY_CODE__ABBREVIATION_ASC',
  CURRENCY_BY_CURRENCY_CODE__ABBREVIATION_DESC = 'CURRENCY_BY_CURRENCY_CODE__ABBREVIATION_DESC',
  CURRENCY_BY_CURRENCY_CODE__CURRENCY_CODE_ASC = 'CURRENCY_BY_CURRENCY_CODE__CURRENCY_CODE_ASC',
  CURRENCY_BY_CURRENCY_CODE__CURRENCY_CODE_DESC = 'CURRENCY_BY_CURRENCY_CODE__CURRENCY_CODE_DESC',
  CURRENCY_BY_CURRENCY_CODE__DATE_CREATED_ASC = 'CURRENCY_BY_CURRENCY_CODE__DATE_CREATED_ASC',
  CURRENCY_BY_CURRENCY_CODE__DATE_CREATED_DESC = 'CURRENCY_BY_CURRENCY_CODE__DATE_CREATED_DESC',
  CURRENCY_BY_CURRENCY_CODE__DATE_UPDATED_ASC = 'CURRENCY_BY_CURRENCY_CODE__DATE_UPDATED_ASC',
  CURRENCY_BY_CURRENCY_CODE__DATE_UPDATED_DESC = 'CURRENCY_BY_CURRENCY_CODE__DATE_UPDATED_DESC',
  CURRENCY_BY_CURRENCY_CODE__NAME_ASC = 'CURRENCY_BY_CURRENCY_CODE__NAME_ASC',
  CURRENCY_BY_CURRENCY_CODE__NAME_DESC = 'CURRENCY_BY_CURRENCY_CODE__NAME_DESC',
  CURRENCY_BY_CURRENCY_CODE__REMARK_ASC = 'CURRENCY_BY_CURRENCY_CODE__REMARK_ASC',
  CURRENCY_BY_CURRENCY_CODE__REMARK_DESC = 'CURRENCY_BY_CURRENCY_CODE__REMARK_DESC',
  CURRENCY_BY_CURRENCY_CODE__USER_CREATED_ASC = 'CURRENCY_BY_CURRENCY_CODE__USER_CREATED_ASC',
  CURRENCY_BY_CURRENCY_CODE__USER_CREATED_DESC = 'CURRENCY_BY_CURRENCY_CODE__USER_CREATED_DESC',
  CURRENCY_BY_CURRENCY_CODE__USER_UPDATED_ASC = 'CURRENCY_BY_CURRENCY_CODE__USER_UPDATED_ASC',
  CURRENCY_BY_CURRENCY_CODE__USER_UPDATED_DESC = 'CURRENCY_BY_CURRENCY_CODE__USER_UPDATED_DESC',
  CURRENCY_CODE_ASC = 'CURRENCY_CODE_ASC',
  CURRENCY_CODE_DESC = 'CURRENCY_CODE_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface RelationCurrencyCountry extends Node {
  __typename?: 'RelationCurrencyCountry';
  countryByCountryCodeAlpha2: Country;
  countryCodeAlpha2: Scalars['String']['output'];
  currencyByCurrencyCode: Currency;
  currencyCode: Scalars['String']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface RelationCurrencyCountryCondition {
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCurrencyCountryCountryCodeAlpha2FkeyCountryCreateInput {
  banks?: InputMaybe<BankCountryCodeAlpha3FkeyInverseInput>;
  cities?: InputMaybe<CityCountryCodeAlpha2FkeyInverseInput>;
  companies?: InputMaybe<CompanyCountryCodeAlpha2FkeyInverseInput>;
  countryCodeAlpha3: Scalars['String']['input'];
  countryCodeNumeric?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInverseInput>;
  employeeIdTypes?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInverseInput>;
  employeesToMobileNumberCountryIdUsingCountryCodeAlpha2?: InputMaybe<EmployeeMobileNumberCountryIdFkeyInverseInput>;
  employeesToNationalityUsingCountryCodeAlpha3?: InputMaybe<EmployeeNationalityFkeyInverseInput>;
  employeesToWorkingLocationCountryCodeAlpha2UsingCountryCodeAlpha2?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0InverseInput>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName: Scalars['String']['input'];
  telephoneCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCurrencyCountryCountryCodeAlpha2FkeyInput {
  connectByCountryCodeAlpha2?: InputMaybe<CountryCountryPkeyConnect>;
  connectByCountryCodeAlpha3?: InputMaybe<CountryCountryCountryCodeAlpha3KeyConnect>;
  connectByCountryCodeNumeric?: InputMaybe<CountryCountryCountryCodeNumericKeyConnect>;
  connectByDisplayOrder?: InputMaybe<CountryCountryDisplayOrderKeyConnect>;
  connectByNodeId?: InputMaybe<CountryNodeIdConnect>;
  create?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyCountryCreateInput>;
  deleteByCountryCodeAlpha2?: InputMaybe<CountryCountryPkeyDelete>;
  deleteByCountryCodeAlpha3?: InputMaybe<CountryCountryCountryCodeAlpha3KeyDelete>;
  deleteByCountryCodeNumeric?: InputMaybe<CountryCountryCountryCodeNumericKeyDelete>;
  deleteByDisplayOrder?: InputMaybe<CountryCountryDisplayOrderKeyDelete>;
  deleteByNodeId?: InputMaybe<CountryNodeIdDelete>;
  updateByCountryCodeAlpha2?: InputMaybe<CountryOnRelationCurrencyCountryForRelationCurrencyCountryCountryCodeAlpha2FkeyUsingCountryPkeyUpdate>;
  updateByCountryCodeAlpha3?: InputMaybe<CountryOnRelationCurrencyCountryForRelationCurrencyCountryCountryCodeAlpha2FkeyUsingCountryCountryCodeAlpha3KeyUpdate>;
  updateByCountryCodeNumeric?: InputMaybe<CountryOnRelationCurrencyCountryForRelationCurrencyCountryCountryCodeAlpha2FkeyUsingCountryCountryCodeNumericKeyUpdate>;
  updateByDisplayOrder?: InputMaybe<CountryOnRelationCurrencyCountryForRelationCurrencyCountryCountryCodeAlpha2FkeyUsingCountryDisplayOrderKeyUpdate>;
  updateByNodeId?: InputMaybe<RelationCurrencyCountryOnRelationCurrencyCountryForRelationCurrencyCountryCountryCodeAlpha2FkeyNodeIdUpdate>;
}

export interface RelationCurrencyCountryCountryCodeAlpha2FkeyInverseInput {
  connectByCountryCodeAlpha2AndCurrencyCode?: InputMaybe<Array<RelationCurrencyCountryRelationCurrencyCountryPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationCurrencyCountryNodeIdConnect>>;
  create?: InputMaybe<Array<RelationCurrencyCountryCountryCodeAlpha2FkeyRelationCurrencyCountryCreateInput>>;
  deleteByCountryCodeAlpha2AndCurrencyCode?: InputMaybe<Array<RelationCurrencyCountryRelationCurrencyCountryPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationCurrencyCountryNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCountryCodeAlpha2AndCurrencyCode?: InputMaybe<Array<RelationCurrencyCountryOnRelationCurrencyCountryForRelationCurrencyCountryCountryCodeAlpha2FkeyUsingRelationCurrencyCountryPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<CountryOnRelationCurrencyCountryForRelationCurrencyCountryCountryCodeAlpha2FkeyNodeIdUpdate>>;
}

export interface RelationCurrencyCountryCountryCodeAlpha2FkeyRelationCurrencyCountryCreateInput {
  country?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<RelationCurrencyCountryCurrencyCodeFkeyInput>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCurrencyCountryCurrencyCodeFkeyCurrencyCreateInput {
  abbreviation: Scalars['String']['input'];
  employees?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInverseInput>;
  name: Scalars['String']['input'];
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCurrencyCodeFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CurrencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCurrencyCountryCurrencyCodeFkeyInput {
  connectByCurrencyCode?: InputMaybe<CurrencyCurrencyPkeyConnect>;
  connectByNodeId?: InputMaybe<CurrencyNodeIdConnect>;
  create?: InputMaybe<RelationCurrencyCountryCurrencyCodeFkeyCurrencyCreateInput>;
  deleteByCurrencyCode?: InputMaybe<CurrencyCurrencyPkeyDelete>;
  deleteByNodeId?: InputMaybe<CurrencyNodeIdDelete>;
  updateByCurrencyCode?: InputMaybe<CurrencyOnRelationCurrencyCountryForRelationCurrencyCountryCurrencyCodeFkeyUsingCurrencyPkeyUpdate>;
  updateByNodeId?: InputMaybe<RelationCurrencyCountryOnRelationCurrencyCountryForRelationCurrencyCountryCurrencyCodeFkeyNodeIdUpdate>;
}

export interface RelationCurrencyCountryCurrencyCodeFkeyInverseInput {
  connectByCountryCodeAlpha2AndCurrencyCode?: InputMaybe<Array<RelationCurrencyCountryRelationCurrencyCountryPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationCurrencyCountryNodeIdConnect>>;
  create?: InputMaybe<Array<RelationCurrencyCountryCurrencyCodeFkeyRelationCurrencyCountryCreateInput>>;
  deleteByCountryCodeAlpha2AndCurrencyCode?: InputMaybe<Array<RelationCurrencyCountryRelationCurrencyCountryPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationCurrencyCountryNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCountryCodeAlpha2AndCurrencyCode?: InputMaybe<Array<RelationCurrencyCountryOnRelationCurrencyCountryForRelationCurrencyCountryCurrencyCodeFkeyUsingRelationCurrencyCountryPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<CurrencyOnRelationCurrencyCountryForRelationCurrencyCountryCurrencyCodeFkeyNodeIdUpdate>>;
}

export interface RelationCurrencyCountryCurrencyCodeFkeyRelationCurrencyCountryCreateInput {
  country?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<RelationCurrencyCountryCurrencyCodeFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCurrencyCountryFilter {
  and?: InputMaybe<Array<RelationCurrencyCountryFilter>>;
  countryByCountryCodeAlpha2?: InputMaybe<CountryFilter>;
  countryCodeAlpha2?: InputMaybe<StringFilter>;
  currencyByCurrencyCode?: InputMaybe<CurrencyFilter>;
  currencyCode?: InputMaybe<StringFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  not?: InputMaybe<RelationCurrencyCountryFilter>;
  or?: InputMaybe<Array<RelationCurrencyCountryFilter>>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface RelationCurrencyCountryInput {
  country?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<RelationCurrencyCountryCurrencyCodeFkeyInput>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCurrencyCountryNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface RelationCurrencyCountryNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface RelationCurrencyCountryOnRelationCurrencyCountryForRelationCurrencyCountryCountryCodeAlpha2FkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CountryPatch;
}

export interface RelationCurrencyCountryOnRelationCurrencyCountryForRelationCurrencyCountryCountryCodeAlpha2FkeyUsingRelationCurrencyCountryPkeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  currencyCode: Scalars['String']['input'];
  patch: UpdateRelationCurrencyCountryOnRelationCurrencyCountryForRelationCurrencyCountryCountryCodeAlpha2FkeyPatch;
}

export interface RelationCurrencyCountryOnRelationCurrencyCountryForRelationCurrencyCountryCurrencyCodeFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CurrencyPatch;
}

export interface RelationCurrencyCountryOnRelationCurrencyCountryForRelationCurrencyCountryCurrencyCodeFkeyUsingRelationCurrencyCountryPkeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  currencyCode: Scalars['String']['input'];
  patch: UpdateRelationCurrencyCountryOnRelationCurrencyCountryForRelationCurrencyCountryCurrencyCodeFkeyPatch;
}

export interface RelationCurrencyCountryOnRelationCurrencyCountryForRelationCurrencyCountryUserCreatedFkeyUsingRelationCurrencyCountryPkeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  currencyCode: Scalars['String']['input'];
  patch: UpdateRelationCurrencyCountryOnRelationCurrencyCountryForRelationCurrencyCountryUserCreatedFkeyPatch;
}

export interface RelationCurrencyCountryOnRelationCurrencyCountryForRelationCurrencyCountryUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface RelationCurrencyCountryOnRelationCurrencyCountryForRelationCurrencyCountryUserUpdatedFkeyUsingRelationCurrencyCountryPkeyUpdate {
  countryCodeAlpha2: Scalars['String']['input'];
  currencyCode: Scalars['String']['input'];
  patch: UpdateRelationCurrencyCountryOnRelationCurrencyCountryForRelationCurrencyCountryUserUpdatedFkeyPatch;
}

export interface RelationCurrencyCountryPatch {
  country?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<RelationCurrencyCountryCurrencyCodeFkeyInput>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationCurrencyCountryRelationCurrencyCountryPkeyConnect {
  countryCodeAlpha2: Scalars['String']['input'];
  currencyCode: Scalars['String']['input'];
}

export interface RelationCurrencyCountryRelationCurrencyCountryPkeyDelete {
  countryCodeAlpha2: Scalars['String']['input'];
  currencyCode: Scalars['String']['input'];
}

export interface RelationCurrencyCountryUserCreatedFkeyInverseInput {
  connectByCountryCodeAlpha2AndCurrencyCode?: InputMaybe<Array<RelationCurrencyCountryRelationCurrencyCountryPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationCurrencyCountryNodeIdConnect>>;
  deleteByCountryCodeAlpha2AndCurrencyCode?: InputMaybe<Array<RelationCurrencyCountryRelationCurrencyCountryPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationCurrencyCountryNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCountryCodeAlpha2AndCurrencyCode?: InputMaybe<Array<RelationCurrencyCountryOnRelationCurrencyCountryForRelationCurrencyCountryUserCreatedFkeyUsingRelationCurrencyCountryPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnRelationCurrencyCountryForRelationCurrencyCountryUserCreatedFkeyNodeIdUpdate>>;
}

export interface RelationCurrencyCountryUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnRelationCurrencyCountryForRelationCurrencyCountryUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<RelationCurrencyCountryOnRelationCurrencyCountryForRelationCurrencyCountryUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnRelationCurrencyCountryForRelationCurrencyCountryUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface RelationCurrencyCountryUserUpdatedFkeyInverseInput {
  connectByCountryCodeAlpha2AndCurrencyCode?: InputMaybe<Array<RelationCurrencyCountryRelationCurrencyCountryPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationCurrencyCountryNodeIdConnect>>;
  deleteByCountryCodeAlpha2AndCurrencyCode?: InputMaybe<Array<RelationCurrencyCountryRelationCurrencyCountryPkeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<RelationCurrencyCountryNodeIdDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByCountryCodeAlpha2AndCurrencyCode?: InputMaybe<Array<RelationCurrencyCountryOnRelationCurrencyCountryForRelationCurrencyCountryUserUpdatedFkeyUsingRelationCurrencyCountryPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnRelationCurrencyCountryForRelationCurrencyCountryUserUpdatedFkeyNodeIdUpdate>>;
}

export interface RelationEmergencyContact extends Node {
  __typename?: 'RelationEmergencyContact';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  displayName: Scalars['String']['output'];
  displayOrder: Scalars['BigInt']['output'];
  emergencyContactRelationId: Scalars['BigInt']['output'];
  employeeEmergencyContactsByRelationId: EmployeeEmergencyContactsConnection;
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export type RelationEmergencyContactEmployeeEmergencyContactsByRelationIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeEmergencyContactCondition>;
  filter?: InputMaybe<EmployeeEmergencyContactFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeEmergencyContactsOrderBy>>;
};

export interface RelationEmergencyContactCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['BigInt']['input']>;
  emergencyContactRelationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationEmergencyContactFilter {
  and?: InputMaybe<Array<RelationEmergencyContactFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  displayName?: InputMaybe<StringFilter>;
  displayOrder?: InputMaybe<BigIntFilter>;
  emergencyContactRelationId?: InputMaybe<BigIntFilter>;
  employeeEmergencyContactsByRelationId?: InputMaybe<RelationEmergencyContactToManyEmployeeEmergencyContactFilter>;
  employeeEmergencyContactsByRelationIdExist?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<RelationEmergencyContactFilter>;
  or?: InputMaybe<Array<RelationEmergencyContactFilter>>;
  remark?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface RelationEmergencyContactNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface RelationEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactRelationIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeEmergencyContactPatch;
}

export interface RelationEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactRelationIdFkeyUsingRelationEmergencyContactDisplayNameKeyUpdate {
  displayName: Scalars['String']['input'];
  patch: UpdateRelationEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactRelationIdFkeyPatch;
}

export interface RelationEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactRelationIdFkeyUsingRelationEmergencyContactPkeyUpdate {
  emergencyContactRelationId: Scalars['BigInt']['input'];
  patch: UpdateRelationEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactRelationIdFkeyPatch;
}

export interface RelationEmergencyContactOnRelationEmergencyContactForRelationEmergencyContactUserCreatedFkeyUsingRelationEmergencyContactDisplayNameKeyUpdate {
  displayName: Scalars['String']['input'];
  patch: UpdateRelationEmergencyContactOnRelationEmergencyContactForRelationEmergencyContactUserCreatedFkeyPatch;
}

export interface RelationEmergencyContactOnRelationEmergencyContactForRelationEmergencyContactUserCreatedFkeyUsingRelationEmergencyContactPkeyUpdate {
  emergencyContactRelationId: Scalars['BigInt']['input'];
  patch: UpdateRelationEmergencyContactOnRelationEmergencyContactForRelationEmergencyContactUserCreatedFkeyPatch;
}

export interface RelationEmergencyContactOnRelationEmergencyContactForRelationEmergencyContactUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface RelationEmergencyContactOnRelationEmergencyContactForRelationEmergencyContactUserUpdatedFkeyUsingRelationEmergencyContactDisplayNameKeyUpdate {
  displayName: Scalars['String']['input'];
  patch: UpdateRelationEmergencyContactOnRelationEmergencyContactForRelationEmergencyContactUserUpdatedFkeyPatch;
}

export interface RelationEmergencyContactOnRelationEmergencyContactForRelationEmergencyContactUserUpdatedFkeyUsingRelationEmergencyContactPkeyUpdate {
  emergencyContactRelationId: Scalars['BigInt']['input'];
  patch: UpdateRelationEmergencyContactOnRelationEmergencyContactForRelationEmergencyContactUserUpdatedFkeyPatch;
}

export interface RelationEmergencyContactPatch {
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['BigInt']['input']>;
  emergencyContactRelationId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactRelationIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationEmergencyContactRelationEmergencyContactDisplayNameKeyConnect {
  displayName: Scalars['String']['input'];
}

export interface RelationEmergencyContactRelationEmergencyContactPkeyConnect {
  emergencyContactRelationId: Scalars['BigInt']['input'];
}

export interface RelationEmergencyContactToManyEmployeeEmergencyContactFilter {
  every?: InputMaybe<EmployeeEmergencyContactFilter>;
  none?: InputMaybe<EmployeeEmergencyContactFilter>;
  some?: InputMaybe<EmployeeEmergencyContactFilter>;
}

export interface RelationEmergencyContactUserCreatedFkeyInverseInput {
  connectByDisplayName?: InputMaybe<Array<RelationEmergencyContactRelationEmergencyContactDisplayNameKeyConnect>>;
  connectByEmergencyContactRelationId?: InputMaybe<Array<RelationEmergencyContactRelationEmergencyContactPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationEmergencyContactNodeIdConnect>>;
  updateByDisplayName?: InputMaybe<Array<RelationEmergencyContactOnRelationEmergencyContactForRelationEmergencyContactUserCreatedFkeyUsingRelationEmergencyContactDisplayNameKeyUpdate>>;
  updateByEmergencyContactRelationId?: InputMaybe<Array<RelationEmergencyContactOnRelationEmergencyContactForRelationEmergencyContactUserCreatedFkeyUsingRelationEmergencyContactPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnRelationEmergencyContactForRelationEmergencyContactUserCreatedFkeyNodeIdUpdate>>;
}

export interface RelationEmergencyContactUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnRelationEmergencyContactForRelationEmergencyContactUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<RelationEmergencyContactOnRelationEmergencyContactForRelationEmergencyContactUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnRelationEmergencyContactForRelationEmergencyContactUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface RelationEmergencyContactUserUpdatedFkeyInverseInput {
  connectByDisplayName?: InputMaybe<Array<RelationEmergencyContactRelationEmergencyContactDisplayNameKeyConnect>>;
  connectByEmergencyContactRelationId?: InputMaybe<Array<RelationEmergencyContactRelationEmergencyContactPkeyConnect>>;
  connectByNodeId?: InputMaybe<Array<RelationEmergencyContactNodeIdConnect>>;
  updateByDisplayName?: InputMaybe<Array<RelationEmergencyContactOnRelationEmergencyContactForRelationEmergencyContactUserUpdatedFkeyUsingRelationEmergencyContactDisplayNameKeyUpdate>>;
  updateByEmergencyContactRelationId?: InputMaybe<Array<RelationEmergencyContactOnRelationEmergencyContactForRelationEmergencyContactUserUpdatedFkeyUsingRelationEmergencyContactPkeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnRelationEmergencyContactForRelationEmergencyContactUserUpdatedFkeyNodeIdUpdate>>;
}

export interface RelationEmergencyContactsConnection {
  __typename?: 'RelationEmergencyContactsConnection';
  edges: Array<RelationEmergencyContactsEdge>;
  nodes: Array<RelationEmergencyContact>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface RelationEmergencyContactsEdge {
  __typename?: 'RelationEmergencyContactsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: RelationEmergencyContact;
}

export enum RelationEmergencyContactsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DISPLAY_NAME_ASC = 'DISPLAY_NAME_ASC',
  DISPLAY_NAME_DESC = 'DISPLAY_NAME_DESC',
  DISPLAY_ORDER_ASC = 'DISPLAY_ORDER_ASC',
  DISPLAY_ORDER_DESC = 'DISPLAY_ORDER_DESC',
  EMERGENCY_CONTACT_RELATION_ID_ASC = 'EMERGENCY_CONTACT_RELATION_ID_ASC',
  EMERGENCY_CONTACT_RELATION_ID_DESC = 'EMERGENCY_CONTACT_RELATION_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__COUNT_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__COUNT_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__COUNT_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__COUNT_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_DATE_CREATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_DATE_CREATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_DATE_CREATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_DATE_CREATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_EMPLOYEE_EMERGENCY_CONTACT_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_EMPLOYEE_EMERGENCY_CONTACT_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_EMPLOYEE_EMERGENCY_CONTACT_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_EMPLOYEE_EMERGENCY_CONTACT_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_NAME_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_NAME_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_NAME_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_NAME_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_RELATION_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_RELATION_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_RELATION_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_RELATION_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_REMARK_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_REMARK_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_REMARK_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_REMARK_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_TELEPHONE_NUMBER_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_TELEPHONE_NUMBER_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_TELEPHONE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_TELEPHONE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_TELEPHONE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_TELEPHONE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_TELEPHONE_NUMBER_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_TELEPHONE_NUMBER_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_USER_CREATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_USER_CREATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_USER_CREATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_USER_CREATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_USER_UPDATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_USER_UPDATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_USER_UPDATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MAX_USER_UPDATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_DATE_CREATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_DATE_CREATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_DATE_CREATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_DATE_CREATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_EMPLOYEE_EMERGENCY_CONTACT_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_EMPLOYEE_EMERGENCY_CONTACT_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_EMPLOYEE_EMERGENCY_CONTACT_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_EMPLOYEE_EMERGENCY_CONTACT_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_NAME_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_NAME_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_NAME_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_NAME_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_RELATION_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_RELATION_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_RELATION_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_RELATION_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_REMARK_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_REMARK_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_REMARK_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_REMARK_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_TELEPHONE_NUMBER_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_TELEPHONE_NUMBER_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_TELEPHONE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_TELEPHONE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_TELEPHONE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_TELEPHONE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_TELEPHONE_NUMBER_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_TELEPHONE_NUMBER_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_USER_CREATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_USER_CREATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_USER_CREATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_USER_CREATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_USER_UPDATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_USER_UPDATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_USER_UPDATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_RELATION_ID__MIN_USER_UPDATED_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface RelationEmployeeUserAccount extends Node {
  __typename?: 'RelationEmployeeUserAccount';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  employee: Employee;
  employeeId: Scalars['BigInt']['output'];
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccount: UserAccount;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userAccountId: Scalars['BigInt']['output'];
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface RelationEmployeeUserAccountCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationEmployeeUserAccountEmployeeIdFkeyEmployeeCreateInput {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName: Scalars['String']['input'];
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate: Scalars['Date']['input'];
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface RelationEmployeeUserAccountEmployeeIdFkeyInput {
  connectByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyConnect>;
  connectByEmployeeId?: InputMaybe<EmployeeEmployeePkeyConnect>;
  connectByNodeId?: InputMaybe<EmployeeNodeIdConnect>;
  create?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyEmployeeCreateInput>;
  deleteByCompanyEmail?: InputMaybe<EmployeeEmployeeCompanyEmailKeyDelete>;
  deleteByEmployeeId?: InputMaybe<EmployeeEmployeePkeyDelete>;
  deleteByNodeId?: InputMaybe<EmployeeNodeIdDelete>;
  updateByCompanyEmail?: InputMaybe<EmployeeOnRelationEmployeeUserAccountForRelationEmployeeUserAccountEmployeeIdFkeyUsingEmployeeCompanyEmailKeyUpdate>;
  updateByEmployeeId?: InputMaybe<EmployeeOnRelationEmployeeUserAccountForRelationEmployeeUserAccountEmployeeIdFkeyUsingEmployeePkeyUpdate>;
  updateByNodeId?: InputMaybe<RelationEmployeeUserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountEmployeeIdFkeyNodeIdUpdate>;
}

export interface RelationEmployeeUserAccountEmployeeIdFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<RelationEmployeeUserAccountNodeIdConnect>>;
  connectByUserAccountId?: InputMaybe<Array<RelationEmployeeUserAccountRelationEmployeeUserAccountPkeyConnect>>;
  create?: InputMaybe<Array<RelationEmployeeUserAccountEmployeeIdFkeyRelationEmployeeUserAccountCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<RelationEmployeeUserAccountNodeIdDelete>>;
  deleteByUserAccountId?: InputMaybe<Array<RelationEmployeeUserAccountRelationEmployeeUserAccountPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<EmployeeOnRelationEmployeeUserAccountForRelationEmployeeUserAccountEmployeeIdFkeyNodeIdUpdate>>;
  updateByUserAccountId?: InputMaybe<Array<RelationEmployeeUserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountEmployeeIdFkeyUsingRelationEmployeeUserAccountPkeyUpdate>>;
}

export interface RelationEmployeeUserAccountEmployeeIdFkeyRelationEmployeeUserAccountCreateInput {
  employee?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationEmployeeUserAccountFilter {
  and?: InputMaybe<Array<RelationEmployeeUserAccountFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  employee?: InputMaybe<EmployeeFilter>;
  employeeId?: InputMaybe<BigIntFilter>;
  not?: InputMaybe<RelationEmployeeUserAccountFilter>;
  or?: InputMaybe<Array<RelationEmployeeUserAccountFilter>>;
  remark?: InputMaybe<StringFilter>;
  userAccount?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountId?: InputMaybe<BigIntFilter>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface RelationEmployeeUserAccountInput {
  employee?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationEmployeeUserAccountNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface RelationEmployeeUserAccountNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface RelationEmployeeUserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountEmployeeIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface RelationEmployeeUserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountEmployeeIdFkeyUsingRelationEmployeeUserAccountPkeyUpdate {
  patch: UpdateRelationEmployeeUserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountEmployeeIdFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface RelationEmployeeUserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserAccountIdFkeyUsingRelationEmployeeUserAccountPkeyUpdate {
  patch: UpdateRelationEmployeeUserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserAccountIdFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface RelationEmployeeUserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserCreatedFkeyUsingRelationEmployeeUserAccountPkeyUpdate {
  patch: UpdateRelationEmployeeUserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserCreatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface RelationEmployeeUserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface RelationEmployeeUserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserUpdatedFkeyUsingRelationEmployeeUserAccountPkeyUpdate {
  patch: UpdateRelationEmployeeUserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface RelationEmployeeUserAccountPatch {
  employee?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationEmployeeUserAccountRelationEmployeeUserAccountPkeyConnect {
  userAccountId: Scalars['BigInt']['input'];
}

export interface RelationEmployeeUserAccountRelationEmployeeUserAccountPkeyDelete {
  userAccountId: Scalars['BigInt']['input'];
}

export interface RelationEmployeeUserAccountUserAccountIdFkeyInverseInput {
  connectByNodeId?: InputMaybe<RelationEmployeeUserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<RelationEmployeeUserAccountRelationEmployeeUserAccountPkeyConnect>;
  create?: InputMaybe<Array<RelationEmployeeUserAccountUserAccountIdFkeyRelationEmployeeUserAccountCreateInput>>;
  deleteByNodeId?: InputMaybe<RelationEmployeeUserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<RelationEmployeeUserAccountRelationEmployeeUserAccountPkeyDelete>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<UserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserAccountIdFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<RelationEmployeeUserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserAccountIdFkeyUsingRelationEmployeeUserAccountPkeyUpdate>;
}

export interface RelationEmployeeUserAccountUserAccountIdFkeyRelationEmployeeUserAccountCreateInput {
  employee?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface RelationEmployeeUserAccountUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<RelationEmployeeUserAccountNodeIdConnect>>;
  connectByUserAccountId?: InputMaybe<Array<RelationEmployeeUserAccountRelationEmployeeUserAccountPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<RelationEmployeeUserAccountNodeIdDelete>>;
  deleteByUserAccountId?: InputMaybe<Array<RelationEmployeeUserAccountRelationEmployeeUserAccountPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserCreatedFkeyNodeIdUpdate>>;
  updateByUserAccountId?: InputMaybe<Array<RelationEmployeeUserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserCreatedFkeyUsingRelationEmployeeUserAccountPkeyUpdate>>;
}

export interface RelationEmployeeUserAccountUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<RelationEmployeeUserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface RelationEmployeeUserAccountUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<RelationEmployeeUserAccountNodeIdConnect>>;
  connectByUserAccountId?: InputMaybe<Array<RelationEmployeeUserAccountRelationEmployeeUserAccountPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<RelationEmployeeUserAccountNodeIdDelete>>;
  deleteByUserAccountId?: InputMaybe<Array<RelationEmployeeUserAccountRelationEmployeeUserAccountPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserUpdatedFkeyNodeIdUpdate>>;
  updateByUserAccountId?: InputMaybe<Array<RelationEmployeeUserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserUpdatedFkeyUsingRelationEmployeeUserAccountPkeyUpdate>>;
}

export interface RelationEmployeeUserAccountsConnection {
  __typename?: 'RelationEmployeeUserAccountsConnection';
  edges: Array<RelationEmployeeUserAccountsEdge>;
  nodes: Array<RelationEmployeeUserAccount>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface RelationEmployeeUserAccountsEdge {
  __typename?: 'RelationEmployeeUserAccountsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: RelationEmployeeUserAccount;
}

export enum RelationEmployeeUserAccountsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__REMARK_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__REMARK_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__REMARK_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__REMARK_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__ROLE_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__ROLE_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__ROLE_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__ROLE_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_ID_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface ReportEmployeeTotalCountAll {
  __typename?: 'ReportEmployeeTotalCountAll';
  delta: Scalars['Int']['output'];
  joined: Scalars['Int']['output'];
  resigned: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  year: Scalars['Float']['output'];
  yearMonth: Scalars['String']['output'];
}

export interface ReportEmployeeTotalCountAllByContractType {
  __typename?: 'ReportEmployeeTotalCountAllByContractType';
  contractTypeId?: Maybe<Scalars['BigInt']['output']>;
  delta?: Maybe<Scalars['Int']['output']>;
  joined?: Maybe<Scalars['Int']['output']>;
  resigned?: Maybe<Scalars['Int']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
  yearMonth?: Maybe<Scalars['String']['output']>;
}

export interface ReportEmployeeTotalCountAllByContractTypeCondition {
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  delta?: InputMaybe<Scalars['Int']['input']>;
  joined?: InputMaybe<Scalars['Int']['input']>;
  resigned?: InputMaybe<Scalars['Int']['input']>;
  totalCount?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Float']['input']>;
  yearMonth?: InputMaybe<Scalars['String']['input']>;
}

export interface ReportEmployeeTotalCountAllByContractTypeFilter {
  and?: InputMaybe<Array<ReportEmployeeTotalCountAllByContractTypeFilter>>;
  contractTypeId?: InputMaybe<BigIntFilter>;
  delta?: InputMaybe<IntFilter>;
  joined?: InputMaybe<IntFilter>;
  not?: InputMaybe<ReportEmployeeTotalCountAllByContractTypeFilter>;
  or?: InputMaybe<Array<ReportEmployeeTotalCountAllByContractTypeFilter>>;
  resigned?: InputMaybe<IntFilter>;
  totalCount?: InputMaybe<IntFilter>;
  year?: InputMaybe<FloatFilter>;
  yearMonth?: InputMaybe<StringFilter>;
}

export interface ReportEmployeeTotalCountAllByContractTypesConnection {
  __typename?: 'ReportEmployeeTotalCountAllByContractTypesConnection';
  edges: Array<ReportEmployeeTotalCountAllByContractTypesEdge>;
  nodes: Array<ReportEmployeeTotalCountAllByContractType>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ReportEmployeeTotalCountAllByContractTypesEdge {
  __typename?: 'ReportEmployeeTotalCountAllByContractTypesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ReportEmployeeTotalCountAllByContractType;
}

export enum ReportEmployeeTotalCountAllByContractTypesOrderBy {
  CONTRACT_TYPE_ID_ASC = 'CONTRACT_TYPE_ID_ASC',
  CONTRACT_TYPE_ID_DESC = 'CONTRACT_TYPE_ID_DESC',
  DELTA_ASC = 'DELTA_ASC',
  DELTA_DESC = 'DELTA_DESC',
  JOINED_ASC = 'JOINED_ASC',
  JOINED_DESC = 'JOINED_DESC',
  NATURAL = 'NATURAL',
  RESIGNED_ASC = 'RESIGNED_ASC',
  RESIGNED_DESC = 'RESIGNED_DESC',
  TOTAL_COUNT_ASC = 'TOTAL_COUNT_ASC',
  TOTAL_COUNT_DESC = 'TOTAL_COUNT_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
  YEAR_MONTH_ASC = 'YEAR_MONTH_ASC',
  YEAR_MONTH_DESC = 'YEAR_MONTH_DESC',
}

export interface ReportEmployeeTotalCountAllCondition {
  delta?: InputMaybe<Scalars['Int']['input']>;
  joined?: InputMaybe<Scalars['Int']['input']>;
  resigned?: InputMaybe<Scalars['Int']['input']>;
  totalCount?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Float']['input']>;
  yearMonth?: InputMaybe<Scalars['String']['input']>;
}

export interface ReportEmployeeTotalCountAllFilter {
  and?: InputMaybe<Array<ReportEmployeeTotalCountAllFilter>>;
  delta?: InputMaybe<IntFilter>;
  joined?: InputMaybe<IntFilter>;
  not?: InputMaybe<ReportEmployeeTotalCountAllFilter>;
  or?: InputMaybe<Array<ReportEmployeeTotalCountAllFilter>>;
  resigned?: InputMaybe<IntFilter>;
  totalCount?: InputMaybe<IntFilter>;
  year?: InputMaybe<FloatFilter>;
  yearMonth?: InputMaybe<StringFilter>;
}

export interface ReportEmployeeTotalCountAllsConnection {
  __typename?: 'ReportEmployeeTotalCountAllsConnection';
  edges: Array<ReportEmployeeTotalCountAllsEdge>;
  nodes: Array<ReportEmployeeTotalCountAll>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ReportEmployeeTotalCountAllsEdge {
  __typename?: 'ReportEmployeeTotalCountAllsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ReportEmployeeTotalCountAll;
}

export enum ReportEmployeeTotalCountAllsOrderBy {
  DELTA_ASC = 'DELTA_ASC',
  DELTA_DESC = 'DELTA_DESC',
  JOINED_ASC = 'JOINED_ASC',
  JOINED_DESC = 'JOINED_DESC',
  NATURAL = 'NATURAL',
  RESIGNED_ASC = 'RESIGNED_ASC',
  RESIGNED_DESC = 'RESIGNED_DESC',
  TOTAL_COUNT_ASC = 'TOTAL_COUNT_ASC',
  TOTAL_COUNT_DESC = 'TOTAL_COUNT_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
  YEAR_MONTH_ASC = 'YEAR_MONTH_ASC',
  YEAR_MONTH_DESC = 'YEAR_MONTH_DESC',
}

export interface ReportEmployeeTotalCountCitiesConnection {
  __typename?: 'ReportEmployeeTotalCountCitiesConnection';
  edges: Array<ReportEmployeeTotalCountCitiesEdge>;
  nodes: Array<ReportEmployeeTotalCountCity>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ReportEmployeeTotalCountCitiesEdge {
  __typename?: 'ReportEmployeeTotalCountCitiesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ReportEmployeeTotalCountCity;
}

export enum ReportEmployeeTotalCountCitiesOrderBy {
  CITY_ID_ASC = 'CITY_ID_ASC',
  CITY_ID_DESC = 'CITY_ID_DESC',
  CITY_NAME_ASC = 'CITY_NAME_ASC',
  CITY_NAME_DESC = 'CITY_NAME_DESC',
  COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_CODE_ALPHA2_DESC',
  NATURAL = 'NATURAL',
  TOTAL_COUNT_ASC = 'TOTAL_COUNT_ASC',
  TOTAL_COUNT_DESC = 'TOTAL_COUNT_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
  YEAR_MONTH_ASC = 'YEAR_MONTH_ASC',
  YEAR_MONTH_DESC = 'YEAR_MONTH_DESC',
}

export interface ReportEmployeeTotalCountCity {
  __typename?: 'ReportEmployeeTotalCountCity';
  cityId: Scalars['BigInt']['output'];
  cityName: Scalars['String']['output'];
  countryCodeAlpha2?: Maybe<Scalars['String']['output']>;
  totalCount: Scalars['Int']['output'];
  year: Scalars['Float']['output'];
  yearMonth: Scalars['String']['output'];
}

export interface ReportEmployeeTotalCountCityByContractType {
  __typename?: 'ReportEmployeeTotalCountCityByContractType';
  cityId: Scalars['BigInt']['output'];
  cityName: Scalars['String']['output'];
  contractTypeId: Scalars['BigInt']['output'];
  countryCodeAlpha2?: Maybe<Scalars['String']['output']>;
  totalCount: Scalars['Int']['output'];
  year: Scalars['Float']['output'];
  yearMonth: Scalars['String']['output'];
}

export interface ReportEmployeeTotalCountCityByContractTypeCondition {
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  cityName?: InputMaybe<Scalars['String']['input']>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  totalCount?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Float']['input']>;
  yearMonth?: InputMaybe<Scalars['String']['input']>;
}

export interface ReportEmployeeTotalCountCityByContractTypeFilter {
  and?: InputMaybe<Array<ReportEmployeeTotalCountCityByContractTypeFilter>>;
  cityId?: InputMaybe<BigIntFilter>;
  cityName?: InputMaybe<StringFilter>;
  contractTypeId?: InputMaybe<BigIntFilter>;
  countryCodeAlpha2?: InputMaybe<StringFilter>;
  not?: InputMaybe<ReportEmployeeTotalCountCityByContractTypeFilter>;
  or?: InputMaybe<Array<ReportEmployeeTotalCountCityByContractTypeFilter>>;
  totalCount?: InputMaybe<IntFilter>;
  year?: InputMaybe<FloatFilter>;
  yearMonth?: InputMaybe<StringFilter>;
}

export interface ReportEmployeeTotalCountCityByContractTypesConnection {
  __typename?: 'ReportEmployeeTotalCountCityByContractTypesConnection';
  edges: Array<ReportEmployeeTotalCountCityByContractTypesEdge>;
  nodes: Array<ReportEmployeeTotalCountCityByContractType>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ReportEmployeeTotalCountCityByContractTypesEdge {
  __typename?: 'ReportEmployeeTotalCountCityByContractTypesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ReportEmployeeTotalCountCityByContractType;
}

export enum ReportEmployeeTotalCountCityByContractTypesOrderBy {
  CITY_ID_ASC = 'CITY_ID_ASC',
  CITY_ID_DESC = 'CITY_ID_DESC',
  CITY_NAME_ASC = 'CITY_NAME_ASC',
  CITY_NAME_DESC = 'CITY_NAME_DESC',
  CONTRACT_TYPE_ID_ASC = 'CONTRACT_TYPE_ID_ASC',
  CONTRACT_TYPE_ID_DESC = 'CONTRACT_TYPE_ID_DESC',
  COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_CODE_ALPHA2_DESC',
  NATURAL = 'NATURAL',
  TOTAL_COUNT_ASC = 'TOTAL_COUNT_ASC',
  TOTAL_COUNT_DESC = 'TOTAL_COUNT_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
  YEAR_MONTH_ASC = 'YEAR_MONTH_ASC',
  YEAR_MONTH_DESC = 'YEAR_MONTH_DESC',
}

export interface ReportEmployeeTotalCountCityCondition {
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  cityName?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  totalCount?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Float']['input']>;
  yearMonth?: InputMaybe<Scalars['String']['input']>;
}

export interface ReportEmployeeTotalCountCityFilter {
  and?: InputMaybe<Array<ReportEmployeeTotalCountCityFilter>>;
  cityId?: InputMaybe<BigIntFilter>;
  cityName?: InputMaybe<StringFilter>;
  countryCodeAlpha2?: InputMaybe<StringFilter>;
  not?: InputMaybe<ReportEmployeeTotalCountCityFilter>;
  or?: InputMaybe<Array<ReportEmployeeTotalCountCityFilter>>;
  totalCount?: InputMaybe<IntFilter>;
  year?: InputMaybe<FloatFilter>;
  yearMonth?: InputMaybe<StringFilter>;
}

export interface ReportEmployeeTotalCountCompaniesConnection {
  __typename?: 'ReportEmployeeTotalCountCompaniesConnection';
  edges: Array<ReportEmployeeTotalCountCompaniesEdge>;
  nodes: Array<ReportEmployeeTotalCountCompany>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ReportEmployeeTotalCountCompaniesEdge {
  __typename?: 'ReportEmployeeTotalCountCompaniesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ReportEmployeeTotalCountCompany;
}

export enum ReportEmployeeTotalCountCompaniesOrderBy {
  COMPANY_ID_ASC = 'COMPANY_ID_ASC',
  COMPANY_ID_DESC = 'COMPANY_ID_DESC',
  COMPANY_NAME_ASC = 'COMPANY_NAME_ASC',
  COMPANY_NAME_DESC = 'COMPANY_NAME_DESC',
  COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_CODE_ALPHA2_DESC',
  NATURAL = 'NATURAL',
  TOTAL_COUNT_ASC = 'TOTAL_COUNT_ASC',
  TOTAL_COUNT_DESC = 'TOTAL_COUNT_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
  YEAR_MONTH_ASC = 'YEAR_MONTH_ASC',
  YEAR_MONTH_DESC = 'YEAR_MONTH_DESC',
}

export interface ReportEmployeeTotalCountCompany {
  __typename?: 'ReportEmployeeTotalCountCompany';
  companyId: Scalars['BigInt']['output'];
  companyName: Scalars['String']['output'];
  countryCodeAlpha2?: Maybe<Scalars['String']['output']>;
  totalCount: Scalars['Int']['output'];
  year: Scalars['Float']['output'];
  yearMonth: Scalars['String']['output'];
}

export interface ReportEmployeeTotalCountCompanyByContractType {
  __typename?: 'ReportEmployeeTotalCountCompanyByContractType';
  companyId: Scalars['BigInt']['output'];
  companyName: Scalars['String']['output'];
  contractTypeId: Scalars['BigInt']['output'];
  countryCodeAlpha2?: Maybe<Scalars['String']['output']>;
  totalCount: Scalars['Int']['output'];
  year: Scalars['Float']['output'];
  yearMonth: Scalars['String']['output'];
}

export interface ReportEmployeeTotalCountCompanyByContractTypeCondition {
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  totalCount?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Float']['input']>;
  yearMonth?: InputMaybe<Scalars['String']['input']>;
}

export interface ReportEmployeeTotalCountCompanyByContractTypeFilter {
  and?: InputMaybe<Array<ReportEmployeeTotalCountCompanyByContractTypeFilter>>;
  companyId?: InputMaybe<BigIntFilter>;
  companyName?: InputMaybe<StringFilter>;
  contractTypeId?: InputMaybe<BigIntFilter>;
  countryCodeAlpha2?: InputMaybe<StringFilter>;
  not?: InputMaybe<ReportEmployeeTotalCountCompanyByContractTypeFilter>;
  or?: InputMaybe<Array<ReportEmployeeTotalCountCompanyByContractTypeFilter>>;
  totalCount?: InputMaybe<IntFilter>;
  year?: InputMaybe<FloatFilter>;
  yearMonth?: InputMaybe<StringFilter>;
}

export interface ReportEmployeeTotalCountCompanyByContractTypesConnection {
  __typename?: 'ReportEmployeeTotalCountCompanyByContractTypesConnection';
  edges: Array<ReportEmployeeTotalCountCompanyByContractTypesEdge>;
  nodes: Array<ReportEmployeeTotalCountCompanyByContractType>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ReportEmployeeTotalCountCompanyByContractTypesEdge {
  __typename?: 'ReportEmployeeTotalCountCompanyByContractTypesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ReportEmployeeTotalCountCompanyByContractType;
}

export enum ReportEmployeeTotalCountCompanyByContractTypesOrderBy {
  COMPANY_ID_ASC = 'COMPANY_ID_ASC',
  COMPANY_ID_DESC = 'COMPANY_ID_DESC',
  COMPANY_NAME_ASC = 'COMPANY_NAME_ASC',
  COMPANY_NAME_DESC = 'COMPANY_NAME_DESC',
  CONTRACT_TYPE_ID_ASC = 'CONTRACT_TYPE_ID_ASC',
  CONTRACT_TYPE_ID_DESC = 'CONTRACT_TYPE_ID_DESC',
  COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_CODE_ALPHA2_DESC',
  NATURAL = 'NATURAL',
  TOTAL_COUNT_ASC = 'TOTAL_COUNT_ASC',
  TOTAL_COUNT_DESC = 'TOTAL_COUNT_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
  YEAR_MONTH_ASC = 'YEAR_MONTH_ASC',
  YEAR_MONTH_DESC = 'YEAR_MONTH_DESC',
}

export interface ReportEmployeeTotalCountCompanyCondition {
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  totalCount?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Float']['input']>;
  yearMonth?: InputMaybe<Scalars['String']['input']>;
}

export interface ReportEmployeeTotalCountCompanyFilter {
  and?: InputMaybe<Array<ReportEmployeeTotalCountCompanyFilter>>;
  companyId?: InputMaybe<BigIntFilter>;
  companyName?: InputMaybe<StringFilter>;
  countryCodeAlpha2?: InputMaybe<StringFilter>;
  not?: InputMaybe<ReportEmployeeTotalCountCompanyFilter>;
  or?: InputMaybe<Array<ReportEmployeeTotalCountCompanyFilter>>;
  totalCount?: InputMaybe<IntFilter>;
  year?: InputMaybe<FloatFilter>;
  yearMonth?: InputMaybe<StringFilter>;
}

export interface ReportEmployeeTpHistoryByFlag {
  __typename?: 'ReportEmployeeTpHistoryByFlag';
  countDemotion: Scalars['Int']['output'];
  countPromotion: Scalars['Int']['output'];
  countTransfer: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
  yearMonth: Scalars['String']['output'];
}

export interface ReportEmployeeTpHistoryByFlagCondition {
  countDemotion?: InputMaybe<Scalars['Int']['input']>;
  countPromotion?: InputMaybe<Scalars['Int']['input']>;
  countTransfer?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
  yearMonth?: InputMaybe<Scalars['String']['input']>;
}

export interface ReportEmployeeTpHistoryByFlagFilter {
  and?: InputMaybe<Array<ReportEmployeeTpHistoryByFlagFilter>>;
  countDemotion?: InputMaybe<IntFilter>;
  countPromotion?: InputMaybe<IntFilter>;
  countTransfer?: InputMaybe<IntFilter>;
  not?: InputMaybe<ReportEmployeeTpHistoryByFlagFilter>;
  or?: InputMaybe<Array<ReportEmployeeTpHistoryByFlagFilter>>;
  year?: InputMaybe<IntFilter>;
  yearMonth?: InputMaybe<StringFilter>;
}

export interface ReportEmployeeTpHistoryByFlagsConnection {
  __typename?: 'ReportEmployeeTpHistoryByFlagsConnection';
  edges: Array<ReportEmployeeTpHistoryByFlagsEdge>;
  nodes: Array<ReportEmployeeTpHistoryByFlag>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ReportEmployeeTpHistoryByFlagsEdge {
  __typename?: 'ReportEmployeeTpHistoryByFlagsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ReportEmployeeTpHistoryByFlag;
}

export enum ReportEmployeeTpHistoryByFlagsOrderBy {
  COUNT_DEMOTION_ASC = 'COUNT_DEMOTION_ASC',
  COUNT_DEMOTION_DESC = 'COUNT_DEMOTION_DESC',
  COUNT_PROMOTION_ASC = 'COUNT_PROMOTION_ASC',
  COUNT_PROMOTION_DESC = 'COUNT_PROMOTION_DESC',
  COUNT_TRANSFER_ASC = 'COUNT_TRANSFER_ASC',
  COUNT_TRANSFER_DESC = 'COUNT_TRANSFER_DESC',
  NATURAL = 'NATURAL',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
  YEAR_MONTH_ASC = 'YEAR_MONTH_ASC',
  YEAR_MONTH_DESC = 'YEAR_MONTH_DESC',
}

export interface ReturnTypeCopyPerformanceReviewMasterCompetencyCommon {
  __typename?: 'ReturnTypeCopyPerformanceReviewMasterCompetencyCommon';
  inputValues?: Maybe<InputTypeCopyPerformanceReviewMasterCompetencyCommon>;
  outputValues?: Maybe<OutputTypeCopyPerformanceReviewMasterCompetencyCommon>;
}

export interface ReturnTypeCopyPerformanceReviewMasterCompetencyDetail {
  __typename?: 'ReturnTypeCopyPerformanceReviewMasterCompetencyDetail';
  inputValues?: Maybe<InputTypeCopyPerformanceReviewMasterCompetencyDetail>;
  outputValues?: Maybe<OutputTypeCopyPerformanceReviewMasterCompetencyDetail>;
}

export interface ReturnTypeCopyPerformanceReviewMasterRating {
  __typename?: 'ReturnTypeCopyPerformanceReviewMasterRating';
  inputValues?: Maybe<InputTypeCopyPerformanceReviewMasterRating>;
  outputValues?: Maybe<OutputTypeCopyPerformanceReviewMasterRating>;
}

export interface ReturnTypeCopyPerformanceReviewMasterValue {
  __typename?: 'ReturnTypeCopyPerformanceReviewMasterValue';
  inputValues?: Maybe<InputTypeCopyPerformanceReviewMasterValue>;
  outputValues?: Maybe<OutputTypeCopyPerformanceReviewMasterValue>;
}

export interface ReturnTypeCopyPerformanceReviewMasterWeighting {
  __typename?: 'ReturnTypeCopyPerformanceReviewMasterWeighting';
  inputValues?: Maybe<InputTypeCopyPerformanceReviewMasterWeighting>;
  outputValues?: Maybe<OutputTypeCopyPerformanceReviewMasterWeighting>;
}

export interface ReturnTypeEmployeeAllManagerList {
  __typename?: 'ReturnTypeEmployeeAllManagerList';
  depth: Scalars['Int']['output'];
  employee: Employee;
  employeeId: Scalars['BigInt']['output'];
  type: EmployeeManagerType;
}

export interface ReturnTypeEmployeeAllManagerListFilter {
  and?: InputMaybe<Array<ReturnTypeEmployeeAllManagerListFilter>>;
  depth?: InputMaybe<IntFilter>;
  employee?: InputMaybe<EmployeeFilter>;
  employeeExists?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<BigIntFilter>;
  not?: InputMaybe<ReturnTypeEmployeeAllManagerListFilter>;
  or?: InputMaybe<Array<ReturnTypeEmployeeAllManagerListFilter>>;
  type?: InputMaybe<EmployeeManagerTypeFilter>;
}

export interface ReturnTypeEmployeeAllManagerListsConnection {
  __typename?: 'ReturnTypeEmployeeAllManagerListsConnection';
  edges: Array<ReturnTypeEmployeeAllManagerListsEdge>;
  nodes: Array<ReturnTypeEmployeeAllManagerList>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ReturnTypeEmployeeAllManagerListsEdge {
  __typename?: 'ReturnTypeEmployeeAllManagerListsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ReturnTypeEmployeeAllManagerList;
}

export enum ReturnTypeEmployeeAllManagerListsOrderBy {
  DEPTH_ASC = 'DEPTH_ASC',
  DEPTH_DESC = 'DEPTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  NATURAL = 'NATURAL',
  TYPE_ASC = 'TYPE_ASC',
  TYPE_DESC = 'TYPE_DESC',
}

export interface ReturnTypeEmployeeAllSubordinateList {
  __typename?: 'ReturnTypeEmployeeAllSubordinateList';
  depth: Scalars['Int']['output'];
  employee: Employee;
  employeeId: Scalars['BigInt']['output'];
}

export interface ReturnTypeEmployeeAllSubordinateListFilter {
  and?: InputMaybe<Array<ReturnTypeEmployeeAllSubordinateListFilter>>;
  depth?: InputMaybe<IntFilter>;
  employee?: InputMaybe<EmployeeFilter>;
  employeeExists?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<BigIntFilter>;
  not?: InputMaybe<ReturnTypeEmployeeAllSubordinateListFilter>;
  or?: InputMaybe<Array<ReturnTypeEmployeeAllSubordinateListFilter>>;
}

export interface ReturnTypeEmployeeAllSubordinateListsConnection {
  __typename?: 'ReturnTypeEmployeeAllSubordinateListsConnection';
  edges: Array<ReturnTypeEmployeeAllSubordinateListsEdge>;
  nodes: Array<ReturnTypeEmployeeAllSubordinateList>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ReturnTypeEmployeeAllSubordinateListsEdge {
  __typename?: 'ReturnTypeEmployeeAllSubordinateListsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ReturnTypeEmployeeAllSubordinateList;
}

export enum ReturnTypeEmployeeAllSubordinateListsOrderBy {
  DEPTH_ASC = 'DEPTH_ASC',
  DEPTH_DESC = 'DEPTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  NATURAL = 'NATURAL',
}

export interface ReturnTypePerformanceReviewEvaluationList {
  __typename?: 'ReturnTypePerformanceReviewEvaluationList';
  employee: Employee;
  employeeId: Scalars['BigInt']['output'];
  employeeTpHistory: EmployeeTpHistory;
  employeeTpHistoryId: Scalars['BigInt']['output'];
  isLockedAssessment: Scalars['Boolean']['output'];
  isLockedDescription: Scalars['Boolean']['output'];
  isSelf: Scalars['Boolean']['output'];
  isViewOnly: Scalars['Boolean']['output'];
  performanceReviewEvaluation?: Maybe<PerformanceReviewEvaluation>;
  performanceReviewEvaluationId?: Maybe<Scalars['BigInt']['output']>;
  performanceReviewEvaluationStatus: PerformanceReviewEvaluationStatus;
}

export interface ReturnTypePerformanceReviewEvaluationListFilter {
  and?: InputMaybe<Array<ReturnTypePerformanceReviewEvaluationListFilter>>;
  employee?: InputMaybe<EmployeeFilter>;
  employeeExists?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<BigIntFilter>;
  employeeTpHistory?: InputMaybe<EmployeeTpHistoryFilter>;
  employeeTpHistoryExists?: InputMaybe<Scalars['Boolean']['input']>;
  employeeTpHistoryId?: InputMaybe<BigIntFilter>;
  isLockedAssessment?: InputMaybe<BooleanFilter>;
  isLockedDescription?: InputMaybe<BooleanFilter>;
  isSelf?: InputMaybe<BooleanFilter>;
  isViewOnly?: InputMaybe<BooleanFilter>;
  not?: InputMaybe<ReturnTypePerformanceReviewEvaluationListFilter>;
  or?: InputMaybe<Array<ReturnTypePerformanceReviewEvaluationListFilter>>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluationFilter>;
  performanceReviewEvaluationExists?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationId?: InputMaybe<BigIntFilter>;
  performanceReviewEvaluationStatus?: InputMaybe<PerformanceReviewEvaluationStatusFilter>;
}

export interface ReturnTypePerformanceReviewEvaluationListsConnection {
  __typename?: 'ReturnTypePerformanceReviewEvaluationListsConnection';
  edges: Array<ReturnTypePerformanceReviewEvaluationListsEdge>;
  nodes: Array<ReturnTypePerformanceReviewEvaluationList>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ReturnTypePerformanceReviewEvaluationListsEdge {
  __typename?: 'ReturnTypePerformanceReviewEvaluationListsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ReturnTypePerformanceReviewEvaluationList;
}

export enum ReturnTypePerformanceReviewEvaluationListsOrderBy {
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ALL_SUBORDINATE_COUNT_NOT_RESIGNED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__APPROVER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__COMPANY_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CONSULTING_FEE_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DATE_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__FULL_NAME_KANA_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__GENDER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__ID_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__JOIN_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__LAST_WORKING_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MARITAL_STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__MOBILE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NATIONALITY_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NHI_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NICKNAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NAME_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PASSPORT_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERMANENT_ADDRESS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PERSONAL_EMAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PLACE_OF_BIRTH_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PRIMARY_MANAGER_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__PROBATION_END_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__REMARK_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__STATUS_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_CODE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_CREATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__USER_UPDATED_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_NUMBER_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__VISA_TYPE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_ASC',
  EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC = 'EMPLOYEE_BY_EMPLOYEE_ID__WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_ID_ASC = 'EMPLOYEE_ID_ASC',
  EMPLOYEE_ID_DESC = 'EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__COMPANY_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__COMPANY_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__COMPANY_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__COMPANY_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_CREATED_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_CREATED_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_CREATED_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_CREATED_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_UPDATED_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_UPDATED_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_UPDATED_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DATE_UPDATED_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DEPARTMENT_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DEPARTMENT_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DEPARTMENT_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DEPARTMENT_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DIVISION_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DIVISION_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DIVISION_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__DIVISION_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_CODE_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_CODE_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_CODE_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_CODE_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__JOB_TITLE_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__JOB_TITLE_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__JOB_TITLE_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__JOB_TITLE_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__OFFICE_ID_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__OFFICE_ID_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__OFFICE_ID_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__OFFICE_ID_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__REMARK_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__REMARK_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__REMARK_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__REMARK_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__TEAM_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__TEAM_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__TEAM_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__TEAM_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_CREATED_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_CREATED_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_CREATED_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_CREATED_DESC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_UPDATED_ASC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_UPDATED_ASC',
  EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_UPDATED_DESC = 'EMPLOYEE_TP_HISTORY_BY_EMPLOYEE_TP_HISTORY_ID__USER_UPDATED_DESC',
  EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORY_ID_DESC',
  IS_LOCKED_ASSESSMENT_ASC = 'IS_LOCKED_ASSESSMENT_ASC',
  IS_LOCKED_ASSESSMENT_DESC = 'IS_LOCKED_ASSESSMENT_DESC',
  IS_LOCKED_DESCRIPTION_ASC = 'IS_LOCKED_DESCRIPTION_ASC',
  IS_LOCKED_DESCRIPTION_DESC = 'IS_LOCKED_DESCRIPTION_DESC',
  IS_SELF_ASC = 'IS_SELF_ASC',
  IS_SELF_DESC = 'IS_SELF_DESC',
  IS_VIEW_ONLY_ASC = 'IS_VIEW_ONLY_ASC',
  IS_VIEW_ONLY_DESC = 'IS_VIEW_ONLY_DESC',
  NATURAL = 'NATURAL',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__EMPLOYEE_TP_HISTORY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__IS_LOCKED_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__MID_LONG_TERM_GOAL_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_KPI_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__NEED_INPUT_TARGET_QUALITATIVE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATION_BY_PERFORMANCE_REVIEW_EVALUATION_ID__YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_STATUS_ASC = 'PERFORMANCE_REVIEW_EVALUATION_STATUS_ASC',
  PERFORMANCE_REVIEW_EVALUATION_STATUS_DESC = 'PERFORMANCE_REVIEW_EVALUATION_STATUS_DESC',
}

export interface ReturnTypeProbationReviewEvaluationList {
  __typename?: 'ReturnTypeProbationReviewEvaluationList';
  businessUnitId?: Maybe<Scalars['BigInt']['output']>;
  businessUnitName?: Maybe<Scalars['String']['output']>;
  company: Company;
  companyId: Scalars['BigInt']['output'];
  companyName: Scalars['String']['output'];
  countryByCountryCodeAlpha2: Country;
  countryCodeAlpha2: Scalars['String']['output'];
  countryName: Scalars['String']['output'];
  departmentId?: Maybe<Scalars['BigInt']['output']>;
  departmentName?: Maybe<Scalars['String']['output']>;
  divisionId?: Maybe<Scalars['BigInt']['output']>;
  divisionName?: Maybe<Scalars['String']['output']>;
  employee: Employee;
  employeeId: Scalars['BigInt']['output'];
  employeeLevel: EmployeeLevel;
  employeeLevelId: Scalars['BigInt']['output'];
  employeeLevelName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  joinDate: Scalars['Date']['output'];
  nickname?: Maybe<Scalars['String']['output']>;
  oldestUnreviewedDate?: Maybe<Scalars['Date']['output']>;
  reviews: Array<Maybe<ProbationReviewEvaluationListReview>>;
  selfFirstOrder: Scalars['Int']['output'];
  status: EmployeeStatusKey;
}

export interface ReturnTypeProbationReviewEvaluationListFilter {
  and?: InputMaybe<Array<ReturnTypeProbationReviewEvaluationListFilter>>;
  businessUnitId?: InputMaybe<BigIntFilter>;
  businessUnitName?: InputMaybe<StringFilter>;
  company?: InputMaybe<CompanyFilter>;
  companyExists?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<BigIntFilter>;
  companyName?: InputMaybe<StringFilter>;
  countryByCountryCodeAlpha2?: InputMaybe<CountryFilter>;
  countryByCountryCodeAlpha2Exists?: InputMaybe<Scalars['Boolean']['input']>;
  countryCodeAlpha2?: InputMaybe<StringFilter>;
  countryName?: InputMaybe<StringFilter>;
  departmentId?: InputMaybe<BigIntFilter>;
  departmentName?: InputMaybe<StringFilter>;
  divisionId?: InputMaybe<BigIntFilter>;
  divisionName?: InputMaybe<StringFilter>;
  employee?: InputMaybe<EmployeeFilter>;
  employeeExists?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<BigIntFilter>;
  employeeLevel?: InputMaybe<EmployeeLevelFilter>;
  employeeLevelExists?: InputMaybe<Scalars['Boolean']['input']>;
  employeeLevelId?: InputMaybe<BigIntFilter>;
  employeeLevelName?: InputMaybe<StringFilter>;
  fullName?: InputMaybe<StringFilter>;
  joinDate?: InputMaybe<DateFilter>;
  nickname?: InputMaybe<StringFilter>;
  not?: InputMaybe<ReturnTypeProbationReviewEvaluationListFilter>;
  oldestUnreviewedDate?: InputMaybe<DateFilter>;
  or?: InputMaybe<Array<ReturnTypeProbationReviewEvaluationListFilter>>;
  selfFirstOrder?: InputMaybe<IntFilter>;
  status?: InputMaybe<EmployeeStatusKeyFilter>;
}

export interface ReturnTypeProbationReviewEvaluationListsConnection {
  __typename?: 'ReturnTypeProbationReviewEvaluationListsConnection';
  edges: Array<ReturnTypeProbationReviewEvaluationListsEdge>;
  nodes: Array<ReturnTypeProbationReviewEvaluationList>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface ReturnTypeProbationReviewEvaluationListsEdge {
  __typename?: 'ReturnTypeProbationReviewEvaluationListsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: ReturnTypeProbationReviewEvaluationList;
}

export interface SqlIdentifierFilter {
  distinctFrom?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  distinctFromInsensitive?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  endsWith?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  endsWithInsensitive?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  equalTo?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  equalToInsensitive?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  greaterThan?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  greaterThanInsensitive?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  in?: InputMaybe<Array<Scalars['SqlIdentifier']['input']>>;
  inInsensitive?: InputMaybe<Array<Scalars['SqlIdentifier']['input']>>;
  includes?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  includesInsensitive?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  lessThanInsensitive?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  like?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  likeInsensitive?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  notDistinctFrom?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  notDistinctFromInsensitive?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  notEndsWith?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  notEndsWithInsensitive?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  notEqualTo?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  notEqualToInsensitive?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  notIn?: InputMaybe<Array<Scalars['SqlIdentifier']['input']>>;
  notInInsensitive?: InputMaybe<Array<Scalars['SqlIdentifier']['input']>>;
  notIncludes?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  notIncludesInsensitive?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  notLike?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  notLikeInsensitive?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  notStartsWith?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  notStartsWithInsensitive?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  startsWith?: InputMaybe<Scalars['SqlIdentifier']['input']>;
  startsWithInsensitive?: InputMaybe<Scalars['SqlIdentifier']['input']>;
}

export interface StringFilter {
  distinctFrom?: InputMaybe<Scalars['String']['input']>;
  distinctFromInsensitive?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  endsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
  equalTo?: InputMaybe<Scalars['String']['input']>;
  equalToInsensitive?: InputMaybe<Scalars['String']['input']>;
  greaterThan?: InputMaybe<Scalars['String']['input']>;
  greaterThanInsensitive?: InputMaybe<Scalars['String']['input']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['String']['input']>;
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  inInsensitive?: InputMaybe<Array<Scalars['String']['input']>>;
  includes?: InputMaybe<Scalars['String']['input']>;
  includesInsensitive?: InputMaybe<Scalars['String']['input']>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<Scalars['String']['input']>;
  lessThanInsensitive?: InputMaybe<Scalars['String']['input']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['String']['input']>;
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  likeInsensitive?: InputMaybe<Scalars['String']['input']>;
  notDistinctFrom?: InputMaybe<Scalars['String']['input']>;
  notDistinctFromInsensitive?: InputMaybe<Scalars['String']['input']>;
  notEndsWith?: InputMaybe<Scalars['String']['input']>;
  notEndsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
  notEqualTo?: InputMaybe<Scalars['String']['input']>;
  notEqualToInsensitive?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notInInsensitive?: InputMaybe<Array<Scalars['String']['input']>>;
  notIncludes?: InputMaybe<Scalars['String']['input']>;
  notIncludesInsensitive?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
  notLikeInsensitive?: InputMaybe<Scalars['String']['input']>;
  notStartsWith?: InputMaybe<Scalars['String']['input']>;
  notStartsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
  startsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
}

export interface StringListFilter {
  anyEqualTo?: InputMaybe<Scalars['String']['input']>;
  anyGreaterThan?: InputMaybe<Scalars['String']['input']>;
  anyGreaterThanOrEqualTo?: InputMaybe<Scalars['String']['input']>;
  anyLessThan?: InputMaybe<Scalars['String']['input']>;
  anyLessThanOrEqualTo?: InputMaybe<Scalars['String']['input']>;
  anyNotEqualTo?: InputMaybe<Scalars['String']['input']>;
  containedBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  distinctFrom?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  equalTo?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  greaterThan?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  greaterThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notDistinctFrom?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notEqualTo?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  overlaps?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface Subscription {
  __typename?: 'Subscription';
  listen: ListenPayload;
}

export type SubscriptionListenArgs = {
  topic: Scalars['String']['input'];
};

export interface SwitchTriggerInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  tableName?: InputMaybe<Scalars['String']['input']>;
  triggerName?: InputMaybe<Scalars['String']['input']>;
}

export interface SwitchTriggerPayload {
  __typename?: 'SwitchTriggerPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
}

export interface TableAudit {
  __typename?: 'TableAudit';
  dataNew?: Maybe<Scalars['JSON']['output']>;
  dataOld?: Maybe<Scalars['JSON']['output']>;
  dateCreated: Scalars['Datetime']['output'];
  dateTransaction: Scalars['Datetime']['output'];
  tableName: Scalars['String']['output'];
  type: Scalars['String']['output'];
  userAccountByUserCreated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userRole?: Maybe<Scalars['String']['output']>;
}

export interface TableAuditCondition {
  dataNew?: InputMaybe<Scalars['JSON']['input']>;
  dataOld?: InputMaybe<Scalars['JSON']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateTransaction?: InputMaybe<Scalars['Datetime']['input']>;
  tableName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userRole?: InputMaybe<Scalars['String']['input']>;
}

export interface TableAuditFilter {
  and?: InputMaybe<Array<TableAuditFilter>>;
  dataNew?: InputMaybe<JsonFilter>;
  dataOld?: InputMaybe<JsonFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateTransaction?: InputMaybe<DatetimeFilter>;
  not?: InputMaybe<TableAuditFilter>;
  or?: InputMaybe<Array<TableAuditFilter>>;
  tableName?: InputMaybe<StringFilter>;
  type?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userRole?: InputMaybe<StringFilter>;
}

export interface TableAuditsConnection {
  __typename?: 'TableAuditsConnection';
  edges: Array<TableAuditsEdge>;
  nodes: Array<TableAudit>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface TableAuditsEdge {
  __typename?: 'TableAuditsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: TableAudit;
}

export enum TableAuditsOrderBy {
  DATA_NEW_ASC = 'DATA_NEW_ASC',
  DATA_NEW_DESC = 'DATA_NEW_DESC',
  DATA_OLD_ASC = 'DATA_OLD_ASC',
  DATA_OLD_DESC = 'DATA_OLD_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_TRANSACTION_ASC = 'DATE_TRANSACTION_ASC',
  DATE_TRANSACTION_DESC = 'DATE_TRANSACTION_DESC',
  NATURAL = 'NATURAL',
  TABLE_NAME_ASC = 'TABLE_NAME_ASC',
  TABLE_NAME_DESC = 'TABLE_NAME_DESC',
  TYPE_ASC = 'TYPE_ASC',
  TYPE_DESC = 'TYPE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_ROLE_ASC = 'USER_ROLE_ASC',
  USER_ROLE_DESC = 'USER_ROLE_DESC',
}

export interface TablePrimaryKey {
  __typename?: 'TablePrimaryKey';
  keyColumns?: Maybe<Scalars['JSON']['output']>;
  tableName?: Maybe<Scalars['SqlIdentifier']['output']>;
}

export interface TablePrimaryKeyCondition {
  keyColumns?: InputMaybe<Scalars['JSON']['input']>;
  tableName?: InputMaybe<Scalars['SqlIdentifier']['input']>;
}

export interface TablePrimaryKeyFilter {
  and?: InputMaybe<Array<TablePrimaryKeyFilter>>;
  keyColumns?: InputMaybe<JsonFilter>;
  not?: InputMaybe<TablePrimaryKeyFilter>;
  or?: InputMaybe<Array<TablePrimaryKeyFilter>>;
  tableName?: InputMaybe<SqlIdentifierFilter>;
}

export interface TablePrimaryKeysConnection {
  __typename?: 'TablePrimaryKeysConnection';
  edges: Array<TablePrimaryKeysEdge>;
  nodes: Array<TablePrimaryKey>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface TablePrimaryKeysEdge {
  __typename?: 'TablePrimaryKeysEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: TablePrimaryKey;
}

export enum TablePrimaryKeysOrderBy {
  KEY_COLUMNS_ASC = 'KEY_COLUMNS_ASC',
  KEY_COLUMNS_DESC = 'KEY_COLUMNS_DESC',
  NATURAL = 'NATURAL',
  TABLE_NAME_ASC = 'TABLE_NAME_ASC',
  TABLE_NAME_DESC = 'TABLE_NAME_DESC',
}

export interface TimeFilter {
  distinctFrom?: InputMaybe<Scalars['Time']['input']>;
  equalTo?: InputMaybe<Scalars['Time']['input']>;
  greaterThan?: InputMaybe<Scalars['Time']['input']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['Time']['input']>;
  in?: InputMaybe<Array<Scalars['Time']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<Scalars['Time']['input']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['Time']['input']>;
  notDistinctFrom?: InputMaybe<Scalars['Time']['input']>;
  notEqualTo?: InputMaybe<Scalars['Time']['input']>;
  notIn?: InputMaybe<Array<Scalars['Time']['input']>>;
}

export interface UpdateBankByCountryCodeAlpha3AndFullNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha3: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  patch: BankPatch;
}

export interface UpdateBankByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: BankPatch;
}

export interface UpdateBankBySwiftCodeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: BankPatch;
  swiftCode: Scalars['String']['input'];
}

export interface UpdateBankInput {
  bankId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: BankPatch;
}

export interface UpdateBankPayload {
  __typename?: 'UpdateBankPayload';
  bank?: Maybe<Bank>;
  bankEdge?: Maybe<BanksEdge>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  countryByCountryCodeAlpha3: Country;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateBankPayloadBankEdgeArgs = {
  orderBy?: InputMaybe<Array<BanksOrderBy>>;
};

export interface UpdateBusinessUnitByNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  patch: BusinessUnitPatch;
}

export interface UpdateBusinessUnitByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: BusinessUnitPatch;
}

export interface UpdateBusinessUnitInput {
  businessUnitId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: BusinessUnitPatch;
}

export interface UpdateBusinessUnitPayload {
  __typename?: 'UpdateBusinessUnitPayload';
  businessUnit?: Maybe<BusinessUnit>;
  businessUnitEdge?: Maybe<BusinessUnitsEdge>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateBusinessUnitPayloadBusinessUnitEdgeArgs = {
  orderBy?: InputMaybe<Array<BusinessUnitsOrderBy>>;
};

export interface UpdateCityByCountryCodeAlpha2AndNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha2: Scalars['String']['input'];
  name: Scalars['String']['input'];
  patch: CityPatch;
}

export interface UpdateCityByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: CityPatch;
}

export interface UpdateCityInput {
  cityId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: CityPatch;
}

export interface UpdateCityPayload {
  __typename?: 'UpdateCityPayload';
  city?: Maybe<City>;
  cityEdge?: Maybe<CitiesEdge>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  countryByCountryCodeAlpha2?: Maybe<Country>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateCityPayloadCityEdgeArgs = {
  orderBy?: InputMaybe<Array<CitiesOrderBy>>;
};

export interface UpdateCompanyByAbbreviationInput {
  abbreviation: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: CompanyPatch;
}

export interface UpdateCompanyByCountryCodeAlpha2AndFullNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha2: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  patch: CompanyPatch;
}

export interface UpdateCompanyByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: CompanyPatch;
}

export interface UpdateCompanyExternalAccountDisableByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: CompanyExternalAccountDisablePatch;
}

export interface UpdateCompanyExternalAccountDisableInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['BigInt']['input'];
  externalAccountTypeKey: ExternalAccountEmployeeType;
  patch: CompanyExternalAccountDisablePatch;
}

export interface UpdateCompanyExternalAccountDisablePayload {
  __typename?: 'UpdateCompanyExternalAccountDisablePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  companyExternalAccountDisable?: Maybe<CompanyExternalAccountDisable>;
  companyExternalAccountDisableEdge?: Maybe<CompanyExternalAccountDisablesEdge>;
  externalAccountTypeByExternalAccountTypeKey: ExternalAccountType;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
}

export type UpdateCompanyExternalAccountDisablePayloadCompanyExternalAccountDisableEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyExternalAccountDisablesOrderBy>>;
};

export interface UpdateCompanyGlobalHrByEmployeeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeId: Scalars['BigInt']['input'];
  patch: CompanyGlobalHrPatch;
}

export interface UpdateCompanyGlobalHrByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: CompanyGlobalHrPatch;
}

export interface UpdateCompanyGlobalHrInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHrId: Scalars['BigInt']['input'];
  patch: CompanyGlobalHrPatch;
}

export interface UpdateCompanyGlobalHrPayload {
  __typename?: 'UpdateCompanyGlobalHrPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  companyGlobalHr?: Maybe<CompanyGlobalHr>;
  companyGlobalHrEdge?: Maybe<CompanyGlobalHrsEdge>;
  employee: Employee;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateCompanyGlobalHrPayloadCompanyGlobalHrEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyGlobalHrsOrderBy>>;
};

export interface UpdateCompanyGroupByNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  patch: CompanyGroupPatch;
}

export interface UpdateCompanyGroupByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: CompanyGroupPatch;
}

export interface UpdateCompanyGroupInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyGroupId: Scalars['BigInt']['input'];
  patch: CompanyGroupPatch;
}

export interface UpdateCompanyGroupPayload {
  __typename?: 'UpdateCompanyGroupPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  companyGroup?: Maybe<CompanyGroup>;
  companyGroupEdge?: Maybe<CompanyGroupsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateCompanyGroupPayloadCompanyGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyGroupsOrderBy>>;
};

export interface UpdateCompanyInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['BigInt']['input'];
  patch: CompanyPatch;
}

export interface UpdateCompanyLocalHrByCompanyIdAndEmployeeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['BigInt']['input'];
  employeeId: Scalars['BigInt']['input'];
  patch: CompanyLocalHrPatch;
}

export interface UpdateCompanyLocalHrByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: CompanyLocalHrPatch;
}

export interface UpdateCompanyLocalHrInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyLocalHrId: Scalars['BigInt']['input'];
  patch: CompanyLocalHrPatch;
}

export interface UpdateCompanyLocalHrPayload {
  __typename?: 'UpdateCompanyLocalHrPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  companyLocalHr?: Maybe<CompanyLocalHr>;
  companyLocalHrEdge?: Maybe<CompanyLocalHrsEdge>;
  employee: Employee;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateCompanyLocalHrPayloadCompanyLocalHrEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyLocalHrsOrderBy>>;
};

export interface UpdateCompanyPayload {
  __typename?: 'UpdateCompanyPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Company>;
  companyEdge?: Maybe<CompaniesEdge>;
  companyGroup?: Maybe<CompanyGroup>;
  countryByCountryCodeAlpha2?: Maybe<Country>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateCompanyPayloadCompanyEdgeArgs = {
  orderBy?: InputMaybe<Array<CompaniesOrderBy>>;
};

export interface UpdateContractTypeByCodeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  patch: ContractTypePatch;
}

export interface UpdateContractTypeByDisplayNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  patch: ContractTypePatch;
}

export interface UpdateContractTypeByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: ContractTypePatch;
}

export interface UpdateContractTypeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contractTypeId: Scalars['BigInt']['input'];
  patch: ContractTypePatch;
}

export interface UpdateContractTypePayload {
  __typename?: 'UpdateContractTypePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contractType?: Maybe<ContractType>;
  contractTypeEdge?: Maybe<ContractTypesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateContractTypePayloadContractTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<ContractTypesOrderBy>>;
};

export interface UpdateCountryByCountryCodeAlpha3Input {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha3: Scalars['String']['input'];
  patch: CountryPatch;
}

export interface UpdateCountryByCountryCodeNumericInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  countryCodeNumeric: Scalars['String']['input'];
  patch: CountryPatch;
}

export interface UpdateCountryByDisplayOrderInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  displayOrder: Scalars['Int']['input'];
  patch: CountryPatch;
}

export interface UpdateCountryByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: CountryPatch;
}

export interface UpdateCountryInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha2: Scalars['String']['input'];
  patch: CountryPatch;
}

export interface UpdateCountryPayload {
  __typename?: 'UpdateCountryPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  countryEdge?: Maybe<CountriesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateCountryPayloadCountryEdgeArgs = {
  orderBy?: InputMaybe<Array<CountriesOrderBy>>;
};

export interface UpdateCurrencyByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: CurrencyPatch;
}

export interface UpdateCurrencyInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currencyCode: Scalars['String']['input'];
  patch: CurrencyPatch;
}

export interface UpdateCurrencyPayload {
  __typename?: 'UpdateCurrencyPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Currency>;
  currencyEdge?: Maybe<CurrenciesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateCurrencyPayloadCurrencyEdgeArgs = {
  orderBy?: InputMaybe<Array<CurrenciesOrderBy>>;
};

export interface UpdateDepartmentByNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  patch: DepartmentPatch;
}

export interface UpdateDepartmentByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: DepartmentPatch;
}

export interface UpdateDepartmentInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  departmentId: Scalars['BigInt']['input'];
  patch: DepartmentPatch;
}

export interface UpdateDepartmentPayload {
  __typename?: 'UpdateDepartmentPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  department?: Maybe<Department>;
  departmentEdge?: Maybe<DepartmentsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateDepartmentPayloadDepartmentEdgeArgs = {
  orderBy?: InputMaybe<Array<DepartmentsOrderBy>>;
};

export interface UpdateDivisionByNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  patch: DivisionPatch;
}

export interface UpdateDivisionByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: DivisionPatch;
}

export interface UpdateDivisionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  divisionId: Scalars['BigInt']['input'];
  patch: DivisionPatch;
}

export interface UpdateDivisionPayload {
  __typename?: 'UpdateDivisionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  division?: Maybe<Division>;
  divisionEdge?: Maybe<DivisionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateDivisionPayloadDivisionEdgeArgs = {
  orderBy?: InputMaybe<Array<DivisionsOrderBy>>;
};

export interface UpdateEmailDomainByDomainNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  domainName: Scalars['String']['input'];
  patch: EmailDomainPatch;
}

export interface UpdateEmailDomainByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: EmailDomainPatch;
}

export interface UpdateEmailDomainInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  emailDomainId: Scalars['BigInt']['input'];
  patch: EmailDomainPatch;
}

export interface UpdateEmailDomainPayload {
  __typename?: 'UpdateEmailDomainPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  emailDomain?: Maybe<EmailDomain>;
  emailDomainEdge?: Maybe<EmailDomainsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateEmailDomainPayloadEmailDomainEdgeArgs = {
  orderBy?: InputMaybe<Array<EmailDomainsOrderBy>>;
};

export interface UpdateEmployeeByCompanyEmailInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyEmail: Scalars['String']['input'];
  patch: EmployeePatch;
}

export interface UpdateEmployeeByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface UpdateEmployeeEmergencyContactByEmployeeIdAndRelationIdAndNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeId: Scalars['BigInt']['input'];
  name: Scalars['String']['input'];
  patch: EmployeeEmergencyContactPatch;
  relationId: Scalars['BigInt']['input'];
}

export interface UpdateEmployeeEmergencyContactByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: EmployeeEmergencyContactPatch;
}

export interface UpdateEmployeeEmergencyContactInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeEmergencyContactId: Scalars['BigInt']['input'];
  patch: EmployeeEmergencyContactPatch;
}

export interface UpdateEmployeeEmergencyContactPayload {
  __typename?: 'UpdateEmployeeEmergencyContactPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employee: Employee;
  employeeEmergencyContact?: Maybe<EmployeeEmergencyContact>;
  employeeEmergencyContactEdge?: Maybe<EmployeeEmergencyContactsEdge>;
  query?: Maybe<Query>;
  relation?: Maybe<RelationEmergencyContact>;
  telephoneNumberCountry?: Maybe<Country>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateEmployeeEmergencyContactPayloadEmployeeEmergencyContactEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeEmergencyContactsOrderBy>>;
};

export interface UpdateEmployeeFileByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: EmployeeFilePatch;
}

export interface UpdateEmployeeFileByS3PathInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: EmployeeFilePatch;
  s3Path: Scalars['String']['input'];
}

export interface UpdateEmployeeFileInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeFileId: Scalars['BigInt']['input'];
  patch: EmployeeFilePatch;
}

export interface UpdateEmployeeFilePayload {
  __typename?: 'UpdateEmployeeFilePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employee?: Maybe<Employee>;
  employeeFile?: Maybe<EmployeeFile>;
  employeeFileEdge?: Maybe<EmployeeFilesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userRoleByVisibleRole?: Maybe<UserRole>;
}

export type UpdateEmployeeFilePayloadEmployeeFileEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeFilesOrderBy>>;
};

export interface UpdateEmployeeIdTypeByCodeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  patch: EmployeeIdTypePatch;
}

export interface UpdateEmployeeIdTypeByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: EmployeeIdTypePatch;
}

export interface UpdateEmployeeIdTypeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeIdTypeId: Scalars['BigInt']['input'];
  patch: EmployeeIdTypePatch;
}

export interface UpdateEmployeeIdTypePayload {
  __typename?: 'UpdateEmployeeIdTypePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  countryByCountryCodeAlpha2?: Maybe<Country>;
  employeeIdType?: Maybe<EmployeeIdType>;
  employeeIdTypeEdge?: Maybe<EmployeeIdTypesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateEmployeeIdTypePayloadEmployeeIdTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeIdTypesOrderBy>>;
};

export interface UpdateEmployeeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeId: Scalars['BigInt']['input'];
  patch: EmployeePatch;
}

export interface UpdateEmployeeLevelByDisplayNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  patch: EmployeeLevelPatch;
}

export interface UpdateEmployeeLevelByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: EmployeeLevelPatch;
}

export interface UpdateEmployeeLevelInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeLevelId: Scalars['BigInt']['input'];
  patch: EmployeeLevelPatch;
}

export interface UpdateEmployeeLevelPayload {
  __typename?: 'UpdateEmployeeLevelPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employeeLevel?: Maybe<EmployeeLevel>;
  employeeLevelEdge?: Maybe<EmployeeLevelsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateEmployeeLevelPayloadEmployeeLevelEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeLevelsOrderBy>>;
};

export interface UpdateEmployeeListCustomFilterByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: EmployeeListCustomFilterPatch;
}

export interface UpdateEmployeeListCustomFilterInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeListCustomFilterId: Scalars['BigInt']['input'];
  patch: EmployeeListCustomFilterPatch;
}

export interface UpdateEmployeeListCustomFilterPayload {
  __typename?: 'UpdateEmployeeListCustomFilterPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employeeListCustomFilter?: Maybe<EmployeeListCustomFilter>;
  employeeListCustomFilterEdge?: Maybe<EmployeeListCustomFiltersEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateEmployeeListCustomFilterPayloadEmployeeListCustomFilterEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeListCustomFiltersOrderBy>>;
};

export interface UpdateEmployeeListCustomFilterRoleByEmployeeListCustomFilterIdAndRoleNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeListCustomFilterId: Scalars['BigInt']['input'];
  patch: EmployeeListCustomFilterRolePatch;
  roleName: Scalars['String']['input'];
}

export interface UpdateEmployeeListCustomFilterRoleByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: EmployeeListCustomFilterRolePatch;
}

export interface UpdateEmployeeListCustomFilterRoleInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeListCustomFilterRoleId: Scalars['BigInt']['input'];
  patch: EmployeeListCustomFilterRolePatch;
}

export interface UpdateEmployeeListCustomFilterRolePayload {
  __typename?: 'UpdateEmployeeListCustomFilterRolePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employeeListCustomFilter: EmployeeListCustomFilter;
  employeeListCustomFilterRole?: Maybe<EmployeeListCustomFilterRole>;
  employeeListCustomFilterRoleEdge?: Maybe<EmployeeListCustomFilterRolesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userRoleByRoleName: UserRole;
}

export type UpdateEmployeeListCustomFilterRolePayloadEmployeeListCustomFilterRoleEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeListCustomFilterRolesOrderBy>>;
};

export interface UpdateEmployeePayload {
  __typename?: 'UpdateEmployeePayload';
  approver?: Maybe<Employee>;
  bank?: Maybe<Bank>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  consultingFeeBank?: Maybe<Bank>;
  consultingFeeCurrency?: Maybe<Currency>;
  countryByNationality?: Maybe<Country>;
  countryByWorkingLocationCountryCodeAlpha2: Country;
  dottedLineManager?: Maybe<Employee>;
  employee?: Maybe<Employee>;
  employeeEdge?: Maybe<EmployeesEdge>;
  employeeIdTypeByIdType?: Maybe<EmployeeIdType>;
  employeeStatusByStatus: EmployeeStatus;
  mobileNumberCountry?: Maybe<Country>;
  primaryManager?: Maybe<Employee>;
  query?: Maybe<Query>;
  resignationReason?: Maybe<EmployeeResignationReason>;
  resignationType?: Maybe<EmployeeResignationType>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateEmployeePayloadEmployeeEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeesOrderBy>>;
};

export interface UpdateEmployeeResignationReasonByDisplayNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  patch: EmployeeResignationReasonPatch;
}

export interface UpdateEmployeeResignationReasonByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: EmployeeResignationReasonPatch;
}

export interface UpdateEmployeeResignationReasonInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeResignationReasonId: Scalars['BigInt']['input'];
  patch: EmployeeResignationReasonPatch;
}

export interface UpdateEmployeeResignationReasonPayload {
  __typename?: 'UpdateEmployeeResignationReasonPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employeeResignationReason?: Maybe<EmployeeResignationReason>;
  employeeResignationReasonEdge?: Maybe<EmployeeResignationReasonsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateEmployeeResignationReasonPayloadEmployeeResignationReasonEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeResignationReasonsOrderBy>>;
};

export interface UpdateEmployeeResignationTypeByDisplayNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  patch: EmployeeResignationTypePatch;
}

export interface UpdateEmployeeResignationTypeByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: EmployeeResignationTypePatch;
}

export interface UpdateEmployeeResignationTypeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeResignationTypeId: Scalars['BigInt']['input'];
  patch: EmployeeResignationTypePatch;
}

export interface UpdateEmployeeResignationTypePayload {
  __typename?: 'UpdateEmployeeResignationTypePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employeeResignationType?: Maybe<EmployeeResignationType>;
  employeeResignationTypeEdge?: Maybe<EmployeeResignationTypesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateEmployeeResignationTypePayloadEmployeeResignationTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeResignationTypesOrderBy>>;
};

export interface UpdateEmployeeTpHistoryByEmployeeIdAndEffectiveDateInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  effectiveDate: Scalars['Date']['input'];
  employeeId: Scalars['BigInt']['input'];
  patch: EmployeeTpHistoryPatch;
}

export interface UpdateEmployeeTpHistoryByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: EmployeeTpHistoryPatch;
}

export interface UpdateEmployeeTpHistoryInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeTpHistoryId: Scalars['BigInt']['input'];
  patch: EmployeeTpHistoryPatch;
}

export interface UpdateEmployeeTpHistoryPayload {
  __typename?: 'UpdateEmployeeTpHistoryPayload';
  businessUnit?: Maybe<BusinessUnit>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  contractType: ContractType;
  department?: Maybe<Department>;
  division?: Maybe<Division>;
  employee: Employee;
  employeeLevel: EmployeeLevel;
  employeeTpHistory?: Maybe<EmployeeTpHistory>;
  employeeTpHistoryEdge?: Maybe<EmployeeTpHistoriesEdge>;
  office?: Maybe<Office>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateEmployeeTpHistoryPayloadEmployeeTpHistoryEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeTpHistoriesOrderBy>>;
};

export interface UpdateExportEmployeeStatusByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: ExportEmployeeStatusPatch;
}

export interface UpdateExportEmployeeStatusByUuid4Input {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: ExportEmployeeStatusPatch;
  uuid4: Scalars['String']['input'];
}

export interface UpdateExportEmployeeStatusInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  exportEmployeeStatusId: Scalars['BigInt']['input'];
  patch: ExportEmployeeStatusPatch;
}

export interface UpdateExportEmployeeStatusPayload {
  __typename?: 'UpdateExportEmployeeStatusPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exportEmployeeStatus?: Maybe<ExportEmployeeStatus>;
  exportEmployeeStatusEdge?: Maybe<ExportEmployeeStatusesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateExportEmployeeStatusPayloadExportEmployeeStatusEdgeArgs = {
  orderBy?: InputMaybe<Array<ExportEmployeeStatusesOrderBy>>;
};

export interface UpdateExternalAccountEmployeeByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKeyInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeId: Scalars['BigInt']['input'];
  externalAccountKey: Scalars['String']['input'];
  externalAccountTypeKey: ExternalAccountEmployeeType;
  patch: ExternalAccountEmployeePatch;
}

export interface UpdateExternalAccountEmployeeByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: ExternalAccountEmployeePatch;
}

export interface UpdateExternalAccountEmployeeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  externalAccountEmployeeId: Scalars['BigInt']['input'];
  patch: ExternalAccountEmployeePatch;
}

export interface UpdateExternalAccountEmployeePayload {
  __typename?: 'UpdateExternalAccountEmployeePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employee: Employee;
  externalAccountEmployee?: Maybe<ExternalAccountEmployee>;
  externalAccountEmployeeEdge?: Maybe<ExternalAccountEmployeesEdge>;
  externalAccountTypeByExternalAccountTypeKey: ExternalAccountType;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateExternalAccountEmployeePayloadExternalAccountEmployeeEdgeArgs = {
  orderBy?: InputMaybe<Array<ExternalAccountEmployeesOrderBy>>;
};

export interface UpdateExternalAccountTypeByNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  patch: ExternalAccountTypePatch;
}

export interface UpdateExternalAccountTypeByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: ExternalAccountTypePatch;
}

export interface UpdateExternalAccountTypeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  key: ExternalAccountEmployeeType;
  patch: ExternalAccountTypePatch;
}

export interface UpdateExternalAccountTypePayload {
  __typename?: 'UpdateExternalAccountTypePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  externalAccountType?: Maybe<ExternalAccountType>;
  externalAccountTypeEdge?: Maybe<ExternalAccountTypesEdge>;
  query?: Maybe<Query>;
}

export type UpdateExternalAccountTypePayloadExternalAccountTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<ExternalAccountTypesOrderBy>>;
};

export interface UpdateGoogleWorkspaceOrgUnitByCompanyIdAndBusinessUnitIdInput {
  businessUnitId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['BigInt']['input'];
  patch: GoogleWorkspaceOrgUnitPatch;
}

export interface UpdateGoogleWorkspaceOrgUnitByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: GoogleWorkspaceOrgUnitPatch;
}

export interface UpdateGoogleWorkspaceOrgUnitInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  googleWorkspaceOrgUnitId: Scalars['BigInt']['input'];
  patch: GoogleWorkspaceOrgUnitPatch;
}

export interface UpdateGoogleWorkspaceOrgUnitPayload {
  __typename?: 'UpdateGoogleWorkspaceOrgUnitPayload';
  businessUnit?: Maybe<BusinessUnit>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Company>;
  googleWorkspaceOrgUnit?: Maybe<GoogleWorkspaceOrgUnit>;
  googleWorkspaceOrgUnitEdge?: Maybe<GoogleWorkspaceOrgUnitsEdge>;
  query?: Maybe<Query>;
}

export type UpdateGoogleWorkspaceOrgUnitPayloadGoogleWorkspaceOrgUnitEdgeArgs = {
  orderBy?: InputMaybe<Array<GoogleWorkspaceOrgUnitsOrderBy>>;
};

export interface UpdateImportEmployeeStatusByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: ImportEmployeeStatusPatch;
}

export interface UpdateImportEmployeeStatusByUuid4Input {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: ImportEmployeeStatusPatch;
  uuid4: Scalars['String']['input'];
}

export interface UpdateImportEmployeeStatusInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusId: Scalars['BigInt']['input'];
  patch: ImportEmployeeStatusPatch;
}

export interface UpdateImportEmployeeStatusPayload {
  __typename?: 'UpdateImportEmployeeStatusPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  importEmployeeStatus?: Maybe<ImportEmployeeStatus>;
  importEmployeeStatusEdge?: Maybe<ImportEmployeeStatusesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateImportEmployeeStatusPayloadImportEmployeeStatusEdgeArgs = {
  orderBy?: InputMaybe<Array<ImportEmployeeStatusesOrderBy>>;
};

export interface UpdateLatestEmployeeTransferPromotionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateLatestEmployeeTransferPromotionPayload {
  __typename?: 'UpdateLatestEmployeeTransferPromotionPayload';
  bigInts?: Maybe<Array<Maybe<Scalars['BigInt']['output']>>>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
}

export interface UpdateOfficeByCityIdAndNameInput {
  cityId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  patch: OfficePatch;
}

export interface UpdateOfficeByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: OfficePatch;
}

export interface UpdateOfficeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  officeId: Scalars['BigInt']['input'];
  patch: OfficePatch;
}

export interface UpdateOfficePayload {
  __typename?: 'UpdateOfficePayload';
  city: City;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  office?: Maybe<Office>;
  officeEdge?: Maybe<OfficesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateOfficePayloadOfficeEdgeArgs = {
  orderBy?: InputMaybe<Array<OfficesOrderBy>>;
};

export interface UpdatePerformanceReviewEvaluationByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationPatch;
}

export interface UpdatePerformanceReviewEvaluationCompetencyByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationCompetencyPatch;
}

export interface UpdatePerformanceReviewEvaluationCompetencyByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeIdInput {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewEvaluationCompetencyPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewMasterCompetencyId: Scalars['String']['input'];
}

export interface UpdatePerformanceReviewEvaluationCompetencyInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewEvaluationCompetencyPatch;
  performanceReviewEvaluationCompetencyId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewEvaluationCompetencyPayload {
  __typename?: 'UpdatePerformanceReviewEvaluationCompetencyPayload';
  assessmentEmployee: Employee;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationCompetency?: Maybe<PerformanceReviewEvaluationCompetency>;
  performanceReviewEvaluationCompetencyEdge?: Maybe<PerformanceReviewEvaluationCompetenciesEdge>;
  performanceReviewMasterCompetency: PerformanceReviewMasterCompetency;
  performanceReviewMasterRatingByAssessmentValue?: Maybe<PerformanceReviewMasterRating>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewEvaluationCompetencyPayloadPerformanceReviewEvaluationCompetencyEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationCompetenciesOrderBy>>;
};

export interface UpdatePerformanceReviewEvaluationEvaluatorByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationEvaluatorPatch;
}

export interface UpdatePerformanceReviewEvaluationEvaluatorByPerformanceReviewEvaluationIdAndEvaluatorIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  evaluatorId: Scalars['BigInt']['input'];
  patch: PerformanceReviewEvaluationEvaluatorPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewEvaluationEvaluatorInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewEvaluationEvaluatorPatch;
  performanceReviewEvaluationEvaluatorId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewEvaluationEvaluatorPayload {
  __typename?: 'UpdatePerformanceReviewEvaluationEvaluatorPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  evaluator: Employee;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationEvaluator?: Maybe<PerformanceReviewEvaluationEvaluator>;
  performanceReviewEvaluationEvaluatorEdge?: Maybe<PerformanceReviewEvaluationEvaluatorsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewEvaluationEvaluatorPayloadPerformanceReviewEvaluationEvaluatorEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorsOrderBy>>;
};

export interface UpdatePerformanceReviewEvaluationFeedbackByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationFeedbackPatch;
}

export interface UpdatePerformanceReviewEvaluationFeedbackByPerformanceReviewEvaluationIdAndFeedbackEmployeeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feedbackEmployeeId: Scalars['BigInt']['input'];
  patch: PerformanceReviewEvaluationFeedbackPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewEvaluationFeedbackInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewEvaluationFeedbackPatch;
  performanceReviewEvaluationFeedbackId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewEvaluationFeedbackPayload {
  __typename?: 'UpdatePerformanceReviewEvaluationFeedbackPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  feedbackEmployee?: Maybe<Employee>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationFeedback?: Maybe<PerformanceReviewEvaluationFeedback>;
  performanceReviewEvaluationFeedbackEdge?: Maybe<PerformanceReviewEvaluationFeedbacksEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewEvaluationFeedbackPayloadPerformanceReviewEvaluationFeedbackEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationFeedbacksOrderBy>>;
};

export interface UpdatePerformanceReviewEvaluationInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewEvaluationPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewEvaluationKpiAssessmentByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationKpiAssessmentPatch;
}

export interface UpdatePerformanceReviewEvaluationKpiAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeIdInput {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewEvaluationKpiAssessmentPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewEvaluationKpiAssessmentInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewEvaluationKpiAssessmentPatch;
  performanceReviewEvaluationKpiAssessmentId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewEvaluationKpiAssessmentPayload {
  __typename?: 'UpdatePerformanceReviewEvaluationKpiAssessmentPayload';
  assessmentEmployee: Employee;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationKpiAssessment?: Maybe<PerformanceReviewEvaluationKpiAssessment>;
  performanceReviewEvaluationKpiAssessmentEdge?: Maybe<PerformanceReviewEvaluationKpiAssessmentsEdge>;
  performanceReviewEvaluationKpiDescription: PerformanceReviewEvaluationKpiDescription;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewEvaluationKpiAssessmentPayloadPerformanceReviewEvaluationKpiAssessmentEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentsOrderBy>>;
};

export interface UpdatePerformanceReviewEvaluationKpiDescriptionByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationKpiDescriptionPatch;
}

export interface UpdatePerformanceReviewEvaluationKpiDescriptionByPerformanceReviewEvaluationIdAndTitleInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewEvaluationKpiDescriptionPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  title: Scalars['String']['input'];
}

export interface UpdatePerformanceReviewEvaluationKpiDescriptionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewEvaluationKpiDescriptionPatch;
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewEvaluationKpiDescriptionPayload {
  __typename?: 'UpdatePerformanceReviewEvaluationKpiDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationKpiDescription?: Maybe<PerformanceReviewEvaluationKpiDescription>;
  performanceReviewEvaluationKpiDescriptionEdge?: Maybe<PerformanceReviewEvaluationKpiDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewEvaluationKpiDescriptionPayloadPerformanceReviewEvaluationKpiDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionsOrderBy>>;
};

export interface UpdatePerformanceReviewEvaluationKpiResultByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationKpiResultPatch;
}

export interface UpdatePerformanceReviewEvaluationKpiResultByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewEvaluationKpiResultPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiTargetId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewEvaluationKpiResultInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewEvaluationKpiResultPatch;
  performanceReviewEvaluationKpiResultId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewEvaluationKpiResultPayload {
  __typename?: 'UpdatePerformanceReviewEvaluationKpiResultPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationKpiResult?: Maybe<PerformanceReviewEvaluationKpiResult>;
  performanceReviewEvaluationKpiResultEdge?: Maybe<PerformanceReviewEvaluationKpiResultsEdge>;
  performanceReviewEvaluationKpiTarget: PerformanceReviewEvaluationKpiTarget;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewEvaluationKpiResultPayloadPerformanceReviewEvaluationKpiResultEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultsOrderBy>>;
};

export interface UpdatePerformanceReviewEvaluationKpiTargetByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationKpiTargetPatch;
}

export interface UpdatePerformanceReviewEvaluationKpiTargetByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  patch: PerformanceReviewEvaluationKpiTargetPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationKpiDescriptionId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewEvaluationKpiTargetInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewEvaluationKpiTargetPatch;
  performanceReviewEvaluationKpiTargetId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewEvaluationKpiTargetPayload {
  __typename?: 'UpdatePerformanceReviewEvaluationKpiTargetPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationKpiDescription: PerformanceReviewEvaluationKpiDescription;
  performanceReviewEvaluationKpiTarget?: Maybe<PerformanceReviewEvaluationKpiTarget>;
  performanceReviewEvaluationKpiTargetEdge?: Maybe<PerformanceReviewEvaluationKpiTargetsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewEvaluationKpiTargetPayloadPerformanceReviewEvaluationKpiTargetEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetsOrderBy>>;
};

export interface UpdatePerformanceReviewEvaluationOneOnOneByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationOneOnOnePatch;
}

export interface UpdatePerformanceReviewEvaluationOneOnOneInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewEvaluationOneOnOnePatch;
  performanceReviewEvaluationOneOnOneId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewEvaluationOneOnOnePayload {
  __typename?: 'UpdatePerformanceReviewEvaluationOneOnOnePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationOneOnOne?: Maybe<PerformanceReviewEvaluationOneOnOne>;
  performanceReviewEvaluationOneOnOneEdge?: Maybe<PerformanceReviewEvaluationOneOnOnesEdge>;
  performanceReviewMasterOneOnOne: PerformanceReviewMasterOneOnOne;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewEvaluationOneOnOnePayloadPerformanceReviewEvaluationOneOnOneEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOnesOrderBy>>;
};

export interface UpdatePerformanceReviewEvaluationPayload {
  __typename?: 'UpdatePerformanceReviewEvaluationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employee: Employee;
  employeeTpHistory?: Maybe<EmployeeTpHistory>;
  performanceReviewEvaluation?: Maybe<PerformanceReviewEvaluation>;
  performanceReviewEvaluationEdge?: Maybe<PerformanceReviewEvaluationsEdge>;
  performanceReviewMasterCompetencyRole?: Maybe<PerformanceReviewMasterCompetencyRole>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewEvaluationPayloadPerformanceReviewEvaluationEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationsOrderBy>>;
};

export interface UpdatePerformanceReviewEvaluationQualitativeAssessmentByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationQualitativeAssessmentPatch;
}

export interface UpdatePerformanceReviewEvaluationQualitativeAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeIdInput {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewEvaluationQualitativeAssessmentPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewEvaluationQualitativeDescriptionId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewEvaluationQualitativeAssessmentInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewEvaluationQualitativeAssessmentPatch;
  performanceReviewEvaluationQualitativeAssessmentId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewEvaluationQualitativeAssessmentPayload {
  __typename?: 'UpdatePerformanceReviewEvaluationQualitativeAssessmentPayload';
  assessmentEmployee: Employee;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationQualitativeAssessment?: Maybe<PerformanceReviewEvaluationQualitativeAssessment>;
  performanceReviewEvaluationQualitativeAssessmentEdge?: Maybe<PerformanceReviewEvaluationQualitativeAssessmentsEdge>;
  performanceReviewEvaluationQualitativeDescription: PerformanceReviewEvaluationQualitativeDescription;
  performanceReviewMasterRatingByAssessmentValue?: Maybe<PerformanceReviewMasterRating>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewEvaluationQualitativeAssessmentPayloadPerformanceReviewEvaluationQualitativeAssessmentEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentsOrderBy>>;
};

export interface UpdatePerformanceReviewEvaluationQualitativeDescriptionByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationQualitativeDescriptionPatch;
}

export interface UpdatePerformanceReviewEvaluationQualitativeDescriptionByPerformanceReviewEvaluationIdAndTitleInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewEvaluationQualitativeDescriptionPatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  title: Scalars['String']['input'];
}

export interface UpdatePerformanceReviewEvaluationQualitativeDescriptionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewEvaluationQualitativeDescriptionPatch;
  performanceReviewEvaluationQualitativeDescriptionId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewEvaluationQualitativeDescriptionPayload {
  __typename?: 'UpdatePerformanceReviewEvaluationQualitativeDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationQualitativeDescription?: Maybe<PerformanceReviewEvaluationQualitativeDescription>;
  performanceReviewEvaluationQualitativeDescriptionEdge?: Maybe<PerformanceReviewEvaluationQualitativeDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewEvaluationQualitativeDescriptionPayloadPerformanceReviewEvaluationQualitativeDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionsOrderBy>>;
};

export interface UpdatePerformanceReviewEvaluationValueByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationValuePatch;
}

export interface UpdatePerformanceReviewEvaluationValueByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeIdInput {
  assessmentEmployeeId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewEvaluationValuePatch;
  performanceReviewEvaluationId: Scalars['BigInt']['input'];
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewEvaluationValueInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewEvaluationValuePatch;
  performanceReviewEvaluationValueId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewEvaluationValuePayload {
  __typename?: 'UpdatePerformanceReviewEvaluationValuePayload';
  assessmentEmployee: Employee;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationValue?: Maybe<PerformanceReviewEvaluationValue>;
  performanceReviewEvaluationValueEdge?: Maybe<PerformanceReviewEvaluationValuesEdge>;
  performanceReviewMasterRatingByAssessmentValue?: Maybe<PerformanceReviewMasterRating>;
  performanceReviewMasterValue: PerformanceReviewMasterValue;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewEvaluationValuePayloadPerformanceReviewEvaluationValueEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationValuesOrderBy>>;
};

export interface UpdatePerformanceReviewMasterCompetencyByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyPatch;
}

export interface UpdatePerformanceReviewMasterCompetencyCommonByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyCommonPatch;
}

export interface UpdatePerformanceReviewMasterCompetencyCommonDescriptionByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyCommonDescriptionPatch;
}

export interface UpdatePerformanceReviewMasterCompetencyCommonDescriptionByPerformanceReviewMasterCompetencyCommonIdAndLanguageInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  language: Scalars['String']['input'];
  patch: PerformanceReviewMasterCompetencyCommonDescriptionPatch;
  performanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewMasterCompetencyCommonDescriptionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewMasterCompetencyCommonDescriptionPatch;
  performanceReviewMasterCompetencyCommonDescriptionId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewMasterCompetencyCommonDescriptionPayload {
  __typename?: 'UpdatePerformanceReviewMasterCompetencyCommonDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterCompetencyCommon: PerformanceReviewMasterCompetencyCommon;
  performanceReviewMasterCompetencyCommonDescription?: Maybe<PerformanceReviewMasterCompetencyCommonDescription>;
  performanceReviewMasterCompetencyCommonDescriptionEdge?: Maybe<PerformanceReviewMasterCompetencyCommonDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewMasterCompetencyCommonDescriptionPayloadPerformanceReviewMasterCompetencyCommonDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionsOrderBy>>;
};

export interface UpdatePerformanceReviewMasterCompetencyCommonInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewMasterCompetencyCommonPatch;
  performanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewMasterCompetencyCommonPayload {
  __typename?: 'UpdatePerformanceReviewMasterCompetencyCommonPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterCompetencyCommon?: Maybe<PerformanceReviewMasterCompetencyCommon>;
  performanceReviewMasterCompetencyCommonEdge?: Maybe<PerformanceReviewMasterCompetencyCommonsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewMasterCompetencyCommonPayloadPerformanceReviewMasterCompetencyCommonEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonsOrderBy>>;
};

export interface UpdatePerformanceReviewMasterCompetencyDetailByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyDetailPatch;
}

export interface UpdatePerformanceReviewMasterCompetencyDetailByPerformanceReviewMasterCompetencyRoleIdAndOverridingPerformanceReviewMasterCompetencyCommonIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  overridingPerformanceReviewMasterCompetencyCommonId: Scalars['BigInt']['input'];
  patch: PerformanceReviewMasterCompetencyDetailPatch;
  performanceReviewMasterCompetencyRoleId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewMasterCompetencyDetailDescriptionByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyDetailDescriptionPatch;
}

export interface UpdatePerformanceReviewMasterCompetencyDetailDescriptionByPerformanceReviewMasterCompetencyDetailIdAndLanguageInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  language: Scalars['String']['input'];
  patch: PerformanceReviewMasterCompetencyDetailDescriptionPatch;
  performanceReviewMasterCompetencyDetailId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewMasterCompetencyDetailDescriptionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewMasterCompetencyDetailDescriptionPatch;
  performanceReviewMasterCompetencyDetailDescriptionId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewMasterCompetencyDetailDescriptionPayload {
  __typename?: 'UpdatePerformanceReviewMasterCompetencyDetailDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterCompetencyDetail: PerformanceReviewMasterCompetencyDetail;
  performanceReviewMasterCompetencyDetailDescription?: Maybe<PerformanceReviewMasterCompetencyDetailDescription>;
  performanceReviewMasterCompetencyDetailDescriptionEdge?: Maybe<PerformanceReviewMasterCompetencyDetailDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewMasterCompetencyDetailDescriptionPayloadPerformanceReviewMasterCompetencyDetailDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionsOrderBy>>;
};

export interface UpdatePerformanceReviewMasterCompetencyDetailInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewMasterCompetencyDetailPatch;
  performanceReviewMasterCompetencyDetailId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewMasterCompetencyDetailPayload {
  __typename?: 'UpdatePerformanceReviewMasterCompetencyDetailPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  overridingPerformanceReviewMasterCompetencyCommon?: Maybe<PerformanceReviewMasterCompetencyCommon>;
  performanceReviewMasterCompetencyDetail?: Maybe<PerformanceReviewMasterCompetencyDetail>;
  performanceReviewMasterCompetencyDetailEdge?: Maybe<PerformanceReviewMasterCompetencyDetailsEdge>;
  performanceReviewMasterCompetencyRole: PerformanceReviewMasterCompetencyRole;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewMasterCompetencyDetailPayloadPerformanceReviewMasterCompetencyDetailEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailsOrderBy>>;
};

export interface UpdatePerformanceReviewMasterCompetencyInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewMasterCompetencyPatch;
  performanceReviewMasterCompetencyId: Scalars['String']['input'];
}

export interface UpdatePerformanceReviewMasterCompetencyPayload {
  __typename?: 'UpdatePerformanceReviewMasterCompetencyPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterCompetency?: Maybe<PerformanceReviewMasterCompetency>;
  performanceReviewMasterCompetencyCommon?: Maybe<PerformanceReviewMasterCompetencyCommon>;
  performanceReviewMasterCompetencyDetail?: Maybe<PerformanceReviewMasterCompetencyDetail>;
  performanceReviewMasterCompetencyEdge?: Maybe<PerformanceReviewMasterCompetenciesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewMasterCompetencyPayloadPerformanceReviewMasterCompetencyEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetenciesOrderBy>>;
};

export interface UpdatePerformanceReviewMasterCompetencyRoleByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyRolePatch;
}

export interface UpdatePerformanceReviewMasterCompetencyRoleByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleNameInput {
  businessUnitId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  departmentId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
  patch: PerformanceReviewMasterCompetencyRolePatch;
  quarter: PerformanceReviewQuarter;
  roleName: Scalars['String']['input'];
  year: Scalars['Int']['input'];
}

export interface UpdatePerformanceReviewMasterCompetencyRoleInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewMasterCompetencyRolePatch;
  performanceReviewMasterCompetencyRoleId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewMasterCompetencyRolePayload {
  __typename?: 'UpdatePerformanceReviewMasterCompetencyRolePayload';
  businessUnit: BusinessUnit;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  department: Department;
  division: Division;
  performanceReviewMasterCompetencyRole?: Maybe<PerformanceReviewMasterCompetencyRole>;
  performanceReviewMasterCompetencyRoleEdge?: Maybe<PerformanceReviewMasterCompetencyRolesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewMasterCompetencyRolePayloadPerformanceReviewMasterCompetencyRoleEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyRolesOrderBy>>;
};

export interface UpdatePerformanceReviewMasterDefaultByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterDefaultPatch;
}

export interface UpdatePerformanceReviewMasterDefaultInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewMasterDefaultPatch;
  performanceReviewMasterDefaultId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewMasterDefaultPayload {
  __typename?: 'UpdatePerformanceReviewMasterDefaultPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterDefault?: Maybe<PerformanceReviewMasterDefault>;
  performanceReviewMasterDefaultEdge?: Maybe<PerformanceReviewMasterDefaultsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewMasterDefaultPayloadPerformanceReviewMasterDefaultEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterDefaultsOrderBy>>;
};

export interface UpdatePerformanceReviewMasterEvaluationViewerByEmployeeIdAndYearAndQuarterAndViewerEmployeeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeId: Scalars['BigInt']['input'];
  patch: PerformanceReviewMasterEvaluationViewerPatch;
  quarter: PerformanceReviewQuarter;
  viewerEmployeeId: Scalars['BigInt']['input'];
  year: Scalars['Int']['input'];
}

export interface UpdatePerformanceReviewMasterEvaluationViewerByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterEvaluationViewerPatch;
}

export interface UpdatePerformanceReviewMasterEvaluationViewerInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewMasterEvaluationViewerPatch;
  performanceReviewMasterEvaluationViewerId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewMasterEvaluationViewerPayload {
  __typename?: 'UpdatePerformanceReviewMasterEvaluationViewerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employee: Employee;
  performanceReviewMasterEvaluationViewer?: Maybe<PerformanceReviewMasterEvaluationViewer>;
  performanceReviewMasterEvaluationViewerEdge?: Maybe<PerformanceReviewMasterEvaluationViewersEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  viewerEmployee: Employee;
}

export type UpdatePerformanceReviewMasterEvaluationViewerPayloadPerformanceReviewMasterEvaluationViewerEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewersOrderBy>>;
};

export interface UpdatePerformanceReviewMasterExcludedEmployeeByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterExcludedEmployeePatch;
}

export interface UpdatePerformanceReviewMasterExcludedEmployeeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeId: Scalars['BigInt']['input'];
  patch: PerformanceReviewMasterExcludedEmployeePatch;
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface UpdatePerformanceReviewMasterExcludedEmployeePayload {
  __typename?: 'UpdatePerformanceReviewMasterExcludedEmployeePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employee: Employee;
  performanceReviewMasterExcludedEmployee?: Maybe<PerformanceReviewMasterExcludedEmployee>;
  performanceReviewMasterExcludedEmployeeEdge?: Maybe<PerformanceReviewMasterExcludedEmployeesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewMasterExcludedEmployeePayloadPerformanceReviewMasterExcludedEmployeeEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterExcludedEmployeesOrderBy>>;
};

export interface UpdatePerformanceReviewMasterOneOnOneByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterOneOnOnePatch;
}

export interface UpdatePerformanceReviewMasterOneOnOneDetailByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterOneOnOneDetailPatch;
}

export interface UpdatePerformanceReviewMasterOneOnOneDetailInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewMasterOneOnOneDetailPatch;
  performanceReviewMasterOneOnOneDetailId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewMasterOneOnOneDetailPayload {
  __typename?: 'UpdatePerformanceReviewMasterOneOnOneDetailPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterOneOnOne: PerformanceReviewMasterOneOnOne;
  performanceReviewMasterOneOnOneDetail?: Maybe<PerformanceReviewMasterOneOnOneDetail>;
  performanceReviewMasterOneOnOneDetailEdge?: Maybe<PerformanceReviewMasterOneOnOneDetailsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewMasterOneOnOneDetailPayloadPerformanceReviewMasterOneOnOneDetailEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterOneOnOneDetailsOrderBy>>;
};

export interface UpdatePerformanceReviewMasterOneOnOneInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewMasterOneOnOnePatch;
  performanceReviewMasterOneOnOneId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewMasterOneOnOnePayload {
  __typename?: 'UpdatePerformanceReviewMasterOneOnOnePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterOneOnOne?: Maybe<PerformanceReviewMasterOneOnOne>;
  performanceReviewMasterOneOnOneEdge?: Maybe<PerformanceReviewMasterOneOnOnesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewMasterOneOnOnePayloadPerformanceReviewMasterOneOnOneEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterOneOnOnesOrderBy>>;
};

export interface UpdatePerformanceReviewMasterRatingByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterRatingPatch;
}

export interface UpdatePerformanceReviewMasterRatingDescriptionByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterRatingDescriptionPatch;
}

export interface UpdatePerformanceReviewMasterRatingDescriptionByPerformanceReviewMasterRatingIdAndLanguageInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  language: Scalars['String']['input'];
  patch: PerformanceReviewMasterRatingDescriptionPatch;
  performanceReviewMasterRatingId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewMasterRatingDescriptionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewMasterRatingDescriptionPatch;
  performanceReviewMasterRatingDescriptionId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewMasterRatingDescriptionPayload {
  __typename?: 'UpdatePerformanceReviewMasterRatingDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterRating: PerformanceReviewMasterRating;
  performanceReviewMasterRatingDescription?: Maybe<PerformanceReviewMasterRatingDescription>;
  performanceReviewMasterRatingDescriptionEdge?: Maybe<PerformanceReviewMasterRatingDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewMasterRatingDescriptionPayloadPerformanceReviewMasterRatingDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionsOrderBy>>;
};

export interface UpdatePerformanceReviewMasterRatingInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewMasterRatingPatch;
  performanceReviewMasterRatingId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewMasterRatingPayload {
  __typename?: 'UpdatePerformanceReviewMasterRatingPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterRating?: Maybe<PerformanceReviewMasterRating>;
  performanceReviewMasterRatingEdge?: Maybe<PerformanceReviewMasterRatingsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewMasterRatingPayloadPerformanceReviewMasterRatingEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterRatingsOrderBy>>;
};

export interface UpdatePerformanceReviewMasterUsageByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterUsagePatch;
}

export interface UpdatePerformanceReviewMasterUsageByYearAndQuarterAndCompanyIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['BigInt']['input'];
  patch: PerformanceReviewMasterUsagePatch;
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface UpdatePerformanceReviewMasterUsageInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewMasterUsagePatch;
  performanceReviewMasterUsageId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewMasterUsagePayload {
  __typename?: 'UpdatePerformanceReviewMasterUsagePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  performanceReviewMasterUsage?: Maybe<PerformanceReviewMasterUsage>;
  performanceReviewMasterUsageEdge?: Maybe<PerformanceReviewMasterUsagesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewMasterUsagePayloadPerformanceReviewMasterUsageEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterUsagesOrderBy>>;
};

export interface UpdatePerformanceReviewMasterValueByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterValuePatch;
}

export interface UpdatePerformanceReviewMasterValueDescriptionByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterValueDescriptionPatch;
}

export interface UpdatePerformanceReviewMasterValueDescriptionByPerformanceReviewMasterValueIdAndLanguageInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  language: Scalars['String']['input'];
  patch: PerformanceReviewMasterValueDescriptionPatch;
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewMasterValueDescriptionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewMasterValueDescriptionPatch;
  performanceReviewMasterValueDescriptionId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewMasterValueDescriptionPayload {
  __typename?: 'UpdatePerformanceReviewMasterValueDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterValue: PerformanceReviewMasterValue;
  performanceReviewMasterValueDescription?: Maybe<PerformanceReviewMasterValueDescription>;
  performanceReviewMasterValueDescriptionEdge?: Maybe<PerformanceReviewMasterValueDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewMasterValueDescriptionPayloadPerformanceReviewMasterValueDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionsOrderBy>>;
};

export interface UpdatePerformanceReviewMasterValueInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewMasterValuePatch;
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewMasterValuePayload {
  __typename?: 'UpdatePerformanceReviewMasterValuePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterValue?: Maybe<PerformanceReviewMasterValue>;
  performanceReviewMasterValueEdge?: Maybe<PerformanceReviewMasterValuesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewMasterValuePayloadPerformanceReviewMasterValueEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterValuesOrderBy>>;
};

export interface UpdatePerformanceReviewMasterWeightingByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterWeightingPatch;
}

export interface UpdatePerformanceReviewMasterWeightingByYearAndQuarterAndCompanyIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['BigInt']['input'];
  patch: PerformanceReviewMasterWeightingPatch;
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface UpdatePerformanceReviewMasterWeightingByYearAndQuarterAndNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  patch: PerformanceReviewMasterWeightingPatch;
  quarter: PerformanceReviewQuarter;
  year: Scalars['Int']['input'];
}

export interface UpdatePerformanceReviewMasterWeightingDetailByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterWeightingDetailPatch;
}

export interface UpdatePerformanceReviewMasterWeightingDetailByPerformanceReviewMasterWeightingIdAndEmployeeLevelIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeLevelId: Scalars['BigInt']['input'];
  patch: PerformanceReviewMasterWeightingDetailPatch;
  performanceReviewMasterWeightingId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewMasterWeightingDetailInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewMasterWeightingDetailPatch;
  performanceReviewMasterWeightingDetailId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewMasterWeightingDetailPayload {
  __typename?: 'UpdatePerformanceReviewMasterWeightingDetailPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employeeLevel: EmployeeLevel;
  performanceReviewMasterWeighting: PerformanceReviewMasterWeighting;
  performanceReviewMasterWeightingDetail?: Maybe<PerformanceReviewMasterWeightingDetail>;
  performanceReviewMasterWeightingDetailEdge?: Maybe<PerformanceReviewMasterWeightingDetailsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewMasterWeightingDetailPayloadPerformanceReviewMasterWeightingDetailEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailsOrderBy>>;
};

export interface UpdatePerformanceReviewMasterWeightingInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: PerformanceReviewMasterWeightingPatch;
  performanceReviewMasterWeightingId: Scalars['BigInt']['input'];
}

export interface UpdatePerformanceReviewMasterWeightingPayload {
  __typename?: 'UpdatePerformanceReviewMasterWeightingPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Company>;
  performanceReviewMasterWeighting?: Maybe<PerformanceReviewMasterWeighting>;
  performanceReviewMasterWeightingEdge?: Maybe<PerformanceReviewMasterWeightingsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdatePerformanceReviewMasterWeightingPayloadPerformanceReviewMasterWeightingEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterWeightingsOrderBy>>;
};

export interface UpdateProbationReviewEnableByCompanyIdAndContractTypeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['BigInt']['input'];
  contractTypeId: Scalars['BigInt']['input'];
  patch: ProbationReviewEnablePatch;
}

export interface UpdateProbationReviewEnableByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEnablePatch;
}

export interface UpdateProbationReviewEnableInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: ProbationReviewEnablePatch;
  probationReviewEnableId: Scalars['BigInt']['input'];
}

export interface UpdateProbationReviewEnablePayload {
  __typename?: 'UpdateProbationReviewEnablePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  contractType: ContractType;
  probationReviewEnable?: Maybe<ProbationReviewEnable>;
  probationReviewEnableEdge?: Maybe<ProbationReviewEnablesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateProbationReviewEnablePayloadProbationReviewEnableEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewEnablesOrderBy>>;
};

export interface UpdateProbationReviewEvaluationByEmployeeIdAndReviewDatePlannedInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeId: Scalars['BigInt']['input'];
  patch: ProbationReviewEvaluationPatch;
  reviewDatePlanned: Scalars['Date']['input'];
}

export interface UpdateProbationReviewEvaluationByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationPatch;
}

export interface UpdateProbationReviewEvaluationInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: ProbationReviewEvaluationPatch;
  probationReviewEvaluationId: Scalars['BigInt']['input'];
}

export interface UpdateProbationReviewEvaluationPayload {
  __typename?: 'UpdateProbationReviewEvaluationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employee: Employee;
  probationReviewEvaluation?: Maybe<ProbationReviewEvaluation>;
  probationReviewEvaluationEdge?: Maybe<ProbationReviewEvaluationsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateProbationReviewEvaluationPayloadProbationReviewEvaluationEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationsOrderBy>>;
};

export interface UpdateProbationReviewEvaluationResultItemByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationResultItemPatch;
}

export interface UpdateProbationReviewEvaluationResultItemByProbationReviewEvaluationIdAndProbationReviewMasterItemIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: ProbationReviewEvaluationResultItemPatch;
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface UpdateProbationReviewEvaluationResultItemInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: ProbationReviewEvaluationResultItemPatch;
  probationReviewEvaluationResultItemId: Scalars['BigInt']['input'];
}

export interface UpdateProbationReviewEvaluationResultItemPayload {
  __typename?: 'UpdateProbationReviewEvaluationResultItemPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  probationReviewEvaluation: ProbationReviewEvaluation;
  probationReviewEvaluationResultItem?: Maybe<ProbationReviewEvaluationResultItem>;
  probationReviewEvaluationResultItemEdge?: Maybe<ProbationReviewEvaluationResultItemsEdge>;
  probationReviewMasterItem: ProbationReviewMasterItem;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateProbationReviewEvaluationResultItemPayloadProbationReviewEvaluationResultItemEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultItemsOrderBy>>;
};

export interface UpdateProbationReviewEvaluationResultScoreCompetencyByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationResultScoreCompetencyPatch;
}

export interface UpdateProbationReviewEvaluationResultScoreCompetencyByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: ProbationReviewEvaluationResultScoreCompetencyPatch;
  performanceReviewMasterCompetencyId: Scalars['BigInt']['input'];
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface UpdateProbationReviewEvaluationResultScoreCompetencyInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: ProbationReviewEvaluationResultScoreCompetencyPatch;
  probationReviewEvaluationResultScoreCompetencyId: Scalars['BigInt']['input'];
}

export interface UpdateProbationReviewEvaluationResultScoreCompetencyPayload {
  __typename?: 'UpdateProbationReviewEvaluationResultScoreCompetencyPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterCompetency: PerformanceReviewMasterCompetencyCommon;
  performanceReviewMasterRatingByAssessment?: Maybe<PerformanceReviewMasterRating>;
  probationReviewEvaluation: ProbationReviewEvaluation;
  probationReviewEvaluationResultScoreCompetency?: Maybe<ProbationReviewEvaluationResultScoreCompetency>;
  probationReviewEvaluationResultScoreCompetencyEdge?: Maybe<ProbationReviewEvaluationResultScoreCompetenciesEdge>;
  probationReviewMasterItem: ProbationReviewMasterItem;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateProbationReviewEvaluationResultScoreCompetencyPayloadProbationReviewEvaluationResultScoreCompetencyEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetenciesOrderBy>>;
};

export interface UpdateProbationReviewEvaluationResultScoreValueByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationResultScoreValuePatch;
}

export interface UpdateProbationReviewEvaluationResultScoreValueByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: ProbationReviewEvaluationResultScoreValuePatch;
  performanceReviewMasterValueId: Scalars['BigInt']['input'];
  probationReviewEvaluationId: Scalars['BigInt']['input'];
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface UpdateProbationReviewEvaluationResultScoreValueInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: ProbationReviewEvaluationResultScoreValuePatch;
  probationReviewEvaluationResultScoreValueId: Scalars['BigInt']['input'];
}

export interface UpdateProbationReviewEvaluationResultScoreValuePayload {
  __typename?: 'UpdateProbationReviewEvaluationResultScoreValuePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterRatingByAssessment?: Maybe<PerformanceReviewMasterRating>;
  performanceReviewMasterValue: PerformanceReviewMasterValue;
  probationReviewEvaluation: ProbationReviewEvaluation;
  probationReviewEvaluationResultScoreValue?: Maybe<ProbationReviewEvaluationResultScoreValue>;
  probationReviewEvaluationResultScoreValueEdge?: Maybe<ProbationReviewEvaluationResultScoreValuesEdge>;
  probationReviewMasterItem: ProbationReviewMasterItem;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateProbationReviewEvaluationResultScoreValuePayloadProbationReviewEvaluationResultScoreValueEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValuesOrderBy>>;
};

export interface UpdateProbationReviewMasterItemByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewMasterItemPatch;
}

export interface UpdateProbationReviewMasterItemByYearAndQuarterAndTitleAndInputUserInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  inputUser: ProbationReviewInputUser;
  patch: ProbationReviewMasterItemPatch;
  quarter: PerformanceReviewQuarter;
  title: Scalars['String']['input'];
  year: Scalars['Int']['input'];
}

export interface UpdateProbationReviewMasterItemDescriptionByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewMasterItemDescriptionPatch;
}

export interface UpdateProbationReviewMasterItemDescriptionByProbationReviewMasterItemIdAndLanguageInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  language: Scalars['String']['input'];
  patch: ProbationReviewMasterItemDescriptionPatch;
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface UpdateProbationReviewMasterItemDescriptionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: ProbationReviewMasterItemDescriptionPatch;
  probationReviewMasterItemDescriptionId: Scalars['BigInt']['input'];
}

export interface UpdateProbationReviewMasterItemDescriptionPayload {
  __typename?: 'UpdateProbationReviewMasterItemDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  probationReviewMasterItem: ProbationReviewMasterItem;
  probationReviewMasterItemDescription?: Maybe<ProbationReviewMasterItemDescription>;
  probationReviewMasterItemDescriptionEdge?: Maybe<ProbationReviewMasterItemDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateProbationReviewMasterItemDescriptionPayloadProbationReviewMasterItemDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewMasterItemDescriptionsOrderBy>>;
};

export interface UpdateProbationReviewMasterItemInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: ProbationReviewMasterItemPatch;
  probationReviewMasterItemId: Scalars['BigInt']['input'];
}

export interface UpdateProbationReviewMasterItemPayload {
  __typename?: 'UpdateProbationReviewMasterItemPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  probationReviewMasterItem?: Maybe<ProbationReviewMasterItem>;
  probationReviewMasterItemEdge?: Maybe<ProbationReviewMasterItemsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateProbationReviewMasterItemPayloadProbationReviewMasterItemEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewMasterItemsOrderBy>>;
};

export interface UpdateRelationBusinessUnitDivisionByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: RelationBusinessUnitDivisionPatch;
}

export interface UpdateRelationBusinessUnitDivisionDepartmentByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: RelationBusinessUnitDivisionDepartmentPatch;
}

export interface UpdateRelationBusinessUnitDivisionDepartmentInput {
  businessUnitId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  departmentId: Scalars['BigInt']['input'];
  divisionId: Scalars['BigInt']['input'];
  patch: RelationBusinessUnitDivisionDepartmentPatch;
}

export interface UpdateRelationBusinessUnitDivisionDepartmentPayload {
  __typename?: 'UpdateRelationBusinessUnitDivisionDepartmentPayload';
  businessUnit: BusinessUnit;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  department: Department;
  division: Division;
  query?: Maybe<Query>;
  relationBusinessUnitDivisionDepartment?: Maybe<RelationBusinessUnitDivisionDepartment>;
  relationBusinessUnitDivisionDepartmentEdge?: Maybe<RelationBusinessUnitDivisionDepartmentsEdge>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateRelationBusinessUnitDivisionDepartmentPayloadRelationBusinessUnitDivisionDepartmentEdgeArgs = {
  orderBy?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentsOrderBy>>;
};

export interface UpdateRelationBusinessUnitDivisionInput {
  businessUnitId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  divisionId: Scalars['BigInt']['input'];
  patch: RelationBusinessUnitDivisionPatch;
}

export interface UpdateRelationBusinessUnitDivisionPayload {
  __typename?: 'UpdateRelationBusinessUnitDivisionPayload';
  businessUnit: BusinessUnit;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  division: Division;
  query?: Maybe<Query>;
  relationBusinessUnitDivision?: Maybe<RelationBusinessUnitDivision>;
  relationBusinessUnitDivisionEdge?: Maybe<RelationBusinessUnitDivisionsEdge>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateRelationBusinessUnitDivisionPayloadRelationBusinessUnitDivisionEdgeArgs = {
  orderBy?: InputMaybe<Array<RelationBusinessUnitDivisionsOrderBy>>;
};

export interface UpdateRelationCityCompanyByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: RelationCityCompanyPatch;
}

export interface UpdateRelationCityCompanyInput {
  cityId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['BigInt']['input'];
  patch: RelationCityCompanyPatch;
}

export interface UpdateRelationCityCompanyPayload {
  __typename?: 'UpdateRelationCityCompanyPayload';
  city: City;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  query?: Maybe<Query>;
  relationCityCompany?: Maybe<RelationCityCompany>;
  relationCityCompanyEdge?: Maybe<RelationCityCompaniesEdge>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateRelationCityCompanyPayloadRelationCityCompanyEdgeArgs = {
  orderBy?: InputMaybe<Array<RelationCityCompaniesOrderBy>>;
};

export interface UpdateRelationCompanyBusinessUnitByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: RelationCompanyBusinessUnitPatch;
}

export interface UpdateRelationCompanyBusinessUnitInput {
  businessUnitId: Scalars['BigInt']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['BigInt']['input'];
  patch: RelationCompanyBusinessUnitPatch;
}

export interface UpdateRelationCompanyBusinessUnitPayload {
  __typename?: 'UpdateRelationCompanyBusinessUnitPayload';
  businessUnit: BusinessUnit;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  query?: Maybe<Query>;
  relationCompanyBusinessUnit?: Maybe<RelationCompanyBusinessUnit>;
  relationCompanyBusinessUnitEdge?: Maybe<RelationCompanyBusinessUnitsEdge>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateRelationCompanyBusinessUnitPayloadRelationCompanyBusinessUnitEdgeArgs = {
  orderBy?: InputMaybe<Array<RelationCompanyBusinessUnitsOrderBy>>;
};

export interface UpdateRelationCompanyGroupEmailDomainByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: RelationCompanyGroupEmailDomainPatch;
}

export interface UpdateRelationCompanyGroupEmailDomainInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyGroupId: Scalars['BigInt']['input'];
  emailDomainId: Scalars['BigInt']['input'];
  patch: RelationCompanyGroupEmailDomainPatch;
}

export interface UpdateRelationCompanyGroupEmailDomainPayload {
  __typename?: 'UpdateRelationCompanyGroupEmailDomainPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  companyGroup: CompanyGroup;
  emailDomain: EmailDomain;
  query?: Maybe<Query>;
  relationCompanyGroupEmailDomain?: Maybe<RelationCompanyGroupEmailDomain>;
  relationCompanyGroupEmailDomainEdge?: Maybe<RelationCompanyGroupEmailDomainsEdge>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateRelationCompanyGroupEmailDomainPayloadRelationCompanyGroupEmailDomainEdgeArgs = {
  orderBy?: InputMaybe<Array<RelationCompanyGroupEmailDomainsOrderBy>>;
};

export interface UpdateRelationCompanyOfficeByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: RelationCompanyOfficePatch;
}

export interface UpdateRelationCompanyOfficeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['BigInt']['input'];
  officeId: Scalars['BigInt']['input'];
  patch: RelationCompanyOfficePatch;
}

export interface UpdateRelationCompanyOfficePayload {
  __typename?: 'UpdateRelationCompanyOfficePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  office: Office;
  query?: Maybe<Query>;
  relationCompanyOffice?: Maybe<RelationCompanyOffice>;
  relationCompanyOfficeEdge?: Maybe<RelationCompanyOfficesEdge>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateRelationCompanyOfficePayloadRelationCompanyOfficeEdgeArgs = {
  orderBy?: InputMaybe<Array<RelationCompanyOfficesOrderBy>>;
};

export interface UpdateRelationCurrencyCountryByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: RelationCurrencyCountryPatch;
}

export interface UpdateRelationCurrencyCountryInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha2: Scalars['String']['input'];
  currencyCode: Scalars['String']['input'];
  patch: RelationCurrencyCountryPatch;
}

export interface UpdateRelationCurrencyCountryPayload {
  __typename?: 'UpdateRelationCurrencyCountryPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  countryByCountryCodeAlpha2: Country;
  currencyByCurrencyCode: Currency;
  query?: Maybe<Query>;
  relationCurrencyCountry?: Maybe<RelationCurrencyCountry>;
  relationCurrencyCountryEdge?: Maybe<RelationCurrencyCountriesEdge>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateRelationCurrencyCountryPayloadRelationCurrencyCountryEdgeArgs = {
  orderBy?: InputMaybe<Array<RelationCurrencyCountriesOrderBy>>;
};

export interface UpdateRelationEmployeeUserAccountByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: RelationEmployeeUserAccountPatch;
}

export interface UpdateRelationEmployeeUserAccountInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: RelationEmployeeUserAccountPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UpdateRelationEmployeeUserAccountPayload {
  __typename?: 'UpdateRelationEmployeeUserAccountPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employee: Employee;
  query?: Maybe<Query>;
  relationEmployeeUserAccount?: Maybe<RelationEmployeeUserAccount>;
  relationEmployeeUserAccountEdge?: Maybe<RelationEmployeeUserAccountsEdge>;
  userAccount: UserAccount;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpdateRelationEmployeeUserAccountPayloadRelationEmployeeUserAccountEdgeArgs = {
  orderBy?: InputMaybe<Array<RelationEmployeeUserAccountsOrderBy>>;
};

export interface UpdateUserAccountAccessHistoryByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: UserAccountAccessHistoryPatch;
}

export interface UpdateUserAccountAccessHistoryInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: UserAccountAccessHistoryPatch;
  userAccountAccessHistoryId: Scalars['BigInt']['input'];
}

export interface UpdateUserAccountAccessHistoryPayload {
  __typename?: 'UpdateUserAccountAccessHistoryPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
  userAccount: UserAccount;
  userAccountAccessHistory?: Maybe<UserAccountAccessHistory>;
  userAccountAccessHistoryEdge?: Maybe<UserAccountAccessHistoriesEdge>;
}

export type UpdateUserAccountAccessHistoryPayloadUserAccountAccessHistoryEdgeArgs = {
  orderBy?: InputMaybe<Array<UserAccountAccessHistoriesOrderBy>>;
};

export interface UpdateUserAccountByEmailAddressInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  emailAddress: Scalars['String']['input'];
  patch: UserAccountPatch;
}

export interface UpdateUserAccountByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface UpdateUserAccountInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: UserAccountPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UpdateUserAccountPayload {
  __typename?: 'UpdateUserAccountPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
  userAccount?: Maybe<UserAccount>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userAccountEdge?: Maybe<UserAccountsEdge>;
  userRoleByRole: UserRole;
}

export type UpdateUserAccountPayloadUserAccountEdgeArgs = {
  orderBy?: InputMaybe<Array<UserAccountsOrderBy>>;
};

export interface UpdateUserAccountPinByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPinPatch;
}

export interface UpdateUserAccountPinInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: UserAccountPinPatch;
  ref: Scalars['String']['input'];
  userAccountId: Scalars['BigInt']['input'];
}

export interface UpdateUserAccountPinPayload {
  __typename?: 'UpdateUserAccountPinPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
  userAccount: UserAccount;
  userAccountPin?: Maybe<UserAccountPin>;
  userAccountPinEdge?: Maybe<UserAccountPinsEdge>;
}

export type UpdateUserAccountPinPayloadUserAccountPinEdgeArgs = {
  orderBy?: InputMaybe<Array<UserAccountPinsOrderBy>>;
};

export interface UpdateUserHrManagingCompanyByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: UserHrManagingCompanyPatch;
}

export interface UpdateUserHrManagingCompanyByUserAccountIdAndCompanyIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['BigInt']['input'];
  patch: UserHrManagingCompanyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UpdateUserHrManagingCompanyInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: UserHrManagingCompanyPatch;
  userHrManagingCompanyId: Scalars['BigInt']['input'];
}

export interface UpdateUserHrManagingCompanyPayload {
  __typename?: 'UpdateUserHrManagingCompanyPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  query?: Maybe<Query>;
  userAccount: UserAccount;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userHrManagingCompany?: Maybe<UserHrManagingCompany>;
  userHrManagingCompanyEdge?: Maybe<UserHrManagingCompaniesEdge>;
}

export type UpdateUserHrManagingCompanyPayloadUserHrManagingCompanyEdgeArgs = {
  orderBy?: InputMaybe<Array<UserHrManagingCompaniesOrderBy>>;
};

export interface UpdateWorldPlaceByDisplayOrderInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  displayOrder: Scalars['Int']['input'];
  patch: WorldPlacePatch;
}

export interface UpdateWorldPlaceByNodeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nodeId: Scalars['ID']['input'];
  patch: WorldPlacePatch;
}

export interface UpdateWorldPlaceInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch: WorldPlacePatch;
  worldPlaceId: Scalars['BigInt']['input'];
}

export interface UpdateWorldPlacePayload {
  __typename?: 'UpdateWorldPlacePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  worldPlace?: Maybe<WorldPlace>;
  worldPlaceEdge?: Maybe<WorldPlacesEdge>;
}

export type UpdateWorldPlacePayloadWorldPlaceEdgeArgs = {
  orderBy?: InputMaybe<Array<WorldPlacesOrderBy>>;
};

export interface UpsertBankPayload {
  __typename?: 'UpsertBankPayload';
  bank?: Maybe<Bank>;
  bankEdge?: Maybe<BanksEdge>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  countryByCountryCodeAlpha3: Country;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertBankPayloadBankEdgeArgs = {
  orderBy?: InputMaybe<Array<BanksOrderBy>>;
};

export interface UpsertBusinessUnitPayload {
  __typename?: 'UpsertBusinessUnitPayload';
  businessUnit?: Maybe<BusinessUnit>;
  businessUnitEdge?: Maybe<BusinessUnitsEdge>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertBusinessUnitPayloadBusinessUnitEdgeArgs = {
  orderBy?: InputMaybe<Array<BusinessUnitsOrderBy>>;
};

export interface UpsertCityPayload {
  __typename?: 'UpsertCityPayload';
  city?: Maybe<City>;
  cityEdge?: Maybe<CitiesEdge>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  countryByCountryCodeAlpha2?: Maybe<Country>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertCityPayloadCityEdgeArgs = {
  orderBy?: InputMaybe<Array<CitiesOrderBy>>;
};

export interface UpsertCompanyGlobalHrPayload {
  __typename?: 'UpsertCompanyGlobalHrPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  companyGlobalHr?: Maybe<CompanyGlobalHr>;
  companyGlobalHrEdge?: Maybe<CompanyGlobalHrsEdge>;
  employee: Employee;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertCompanyGlobalHrPayloadCompanyGlobalHrEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyGlobalHrsOrderBy>>;
};

export interface UpsertCompanyGroupPayload {
  __typename?: 'UpsertCompanyGroupPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  companyGroup?: Maybe<CompanyGroup>;
  companyGroupEdge?: Maybe<CompanyGroupsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertCompanyGroupPayloadCompanyGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyGroupsOrderBy>>;
};

export interface UpsertCompanyLocalHrPayload {
  __typename?: 'UpsertCompanyLocalHrPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  companyLocalHr?: Maybe<CompanyLocalHr>;
  companyLocalHrEdge?: Maybe<CompanyLocalHrsEdge>;
  employee: Employee;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertCompanyLocalHrPayloadCompanyLocalHrEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyLocalHrsOrderBy>>;
};

export interface UpsertCompanyPayload {
  __typename?: 'UpsertCompanyPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Company>;
  companyEdge?: Maybe<CompaniesEdge>;
  companyGroup?: Maybe<CompanyGroup>;
  countryByCountryCodeAlpha2?: Maybe<Country>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertCompanyPayloadCompanyEdgeArgs = {
  orderBy?: InputMaybe<Array<CompaniesOrderBy>>;
};

export interface UpsertContractTypePayload {
  __typename?: 'UpsertContractTypePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contractType?: Maybe<ContractType>;
  contractTypeEdge?: Maybe<ContractTypesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertContractTypePayloadContractTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<ContractTypesOrderBy>>;
};

export interface UpsertCountryPayload {
  __typename?: 'UpsertCountryPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  countryEdge?: Maybe<CountriesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertCountryPayloadCountryEdgeArgs = {
  orderBy?: InputMaybe<Array<CountriesOrderBy>>;
};

export interface UpsertCurrencyPayload {
  __typename?: 'UpsertCurrencyPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Currency>;
  currencyEdge?: Maybe<CurrenciesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertCurrencyPayloadCurrencyEdgeArgs = {
  orderBy?: InputMaybe<Array<CurrenciesOrderBy>>;
};

export interface UpsertDepartmentPayload {
  __typename?: 'UpsertDepartmentPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  department?: Maybe<Department>;
  departmentEdge?: Maybe<DepartmentsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertDepartmentPayloadDepartmentEdgeArgs = {
  orderBy?: InputMaybe<Array<DepartmentsOrderBy>>;
};

export interface UpsertDivisionPayload {
  __typename?: 'UpsertDivisionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  division?: Maybe<Division>;
  divisionEdge?: Maybe<DivisionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertDivisionPayloadDivisionEdgeArgs = {
  orderBy?: InputMaybe<Array<DivisionsOrderBy>>;
};

export interface UpsertEmailDomainPayload {
  __typename?: 'UpsertEmailDomainPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  emailDomain?: Maybe<EmailDomain>;
  emailDomainEdge?: Maybe<EmailDomainsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertEmailDomainPayloadEmailDomainEdgeArgs = {
  orderBy?: InputMaybe<Array<EmailDomainsOrderBy>>;
};

export interface UpsertEmployeeEmergencyContactPayload {
  __typename?: 'UpsertEmployeeEmergencyContactPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employee: Employee;
  employeeEmergencyContact?: Maybe<EmployeeEmergencyContact>;
  employeeEmergencyContactEdge?: Maybe<EmployeeEmergencyContactsEdge>;
  query?: Maybe<Query>;
  relation?: Maybe<RelationEmergencyContact>;
  telephoneNumberCountry?: Maybe<Country>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertEmployeeEmergencyContactPayloadEmployeeEmergencyContactEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeEmergencyContactsOrderBy>>;
};

export interface UpsertEmployeeIdTypePayload {
  __typename?: 'UpsertEmployeeIdTypePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  countryByCountryCodeAlpha2?: Maybe<Country>;
  employeeIdType?: Maybe<EmployeeIdType>;
  employeeIdTypeEdge?: Maybe<EmployeeIdTypesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertEmployeeIdTypePayloadEmployeeIdTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeIdTypesOrderBy>>;
};

export interface UpsertEmployeeLevelPayload {
  __typename?: 'UpsertEmployeeLevelPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employeeLevel?: Maybe<EmployeeLevel>;
  employeeLevelEdge?: Maybe<EmployeeLevelsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertEmployeeLevelPayloadEmployeeLevelEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeLevelsOrderBy>>;
};

export interface UpsertEmployeePayload {
  __typename?: 'UpsertEmployeePayload';
  approver?: Maybe<Employee>;
  bank?: Maybe<Bank>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  consultingFeeBank?: Maybe<Bank>;
  consultingFeeCurrency?: Maybe<Currency>;
  countryByNationality?: Maybe<Country>;
  countryByWorkingLocationCountryCodeAlpha2: Country;
  dottedLineManager?: Maybe<Employee>;
  employee?: Maybe<Employee>;
  employeeEdge?: Maybe<EmployeesEdge>;
  employeeIdTypeByIdType?: Maybe<EmployeeIdType>;
  employeeStatusByStatus: EmployeeStatus;
  mobileNumberCountry?: Maybe<Country>;
  primaryManager?: Maybe<Employee>;
  query?: Maybe<Query>;
  resignationReason?: Maybe<EmployeeResignationReason>;
  resignationType?: Maybe<EmployeeResignationType>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertEmployeePayloadEmployeeEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeesOrderBy>>;
};

export interface UpsertEmployeeResignationReasonPayload {
  __typename?: 'UpsertEmployeeResignationReasonPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employeeResignationReason?: Maybe<EmployeeResignationReason>;
  employeeResignationReasonEdge?: Maybe<EmployeeResignationReasonsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertEmployeeResignationReasonPayloadEmployeeResignationReasonEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeResignationReasonsOrderBy>>;
};

export interface UpsertEmployeeResignationTypePayload {
  __typename?: 'UpsertEmployeeResignationTypePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employeeResignationType?: Maybe<EmployeeResignationType>;
  employeeResignationTypeEdge?: Maybe<EmployeeResignationTypesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertEmployeeResignationTypePayloadEmployeeResignationTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeResignationTypesOrderBy>>;
};

export interface UpsertEmployeeTpHistoryPayload {
  __typename?: 'UpsertEmployeeTpHistoryPayload';
  businessUnit?: Maybe<BusinessUnit>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  contractType: ContractType;
  department?: Maybe<Department>;
  division?: Maybe<Division>;
  employee: Employee;
  employeeLevel: EmployeeLevel;
  employeeTpHistory?: Maybe<EmployeeTpHistory>;
  employeeTpHistoryEdge?: Maybe<EmployeeTpHistoriesEdge>;
  office?: Maybe<Office>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertEmployeeTpHistoryPayloadEmployeeTpHistoryEdgeArgs = {
  orderBy?: InputMaybe<Array<EmployeeTpHistoriesOrderBy>>;
};

export interface UpsertExternalAccountEmployeePayload {
  __typename?: 'UpsertExternalAccountEmployeePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employee: Employee;
  externalAccountEmployee?: Maybe<ExternalAccountEmployee>;
  externalAccountEmployeeEdge?: Maybe<ExternalAccountEmployeesEdge>;
  externalAccountTypeByExternalAccountTypeKey: ExternalAccountType;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertExternalAccountEmployeePayloadExternalAccountEmployeeEdgeArgs = {
  orderBy?: InputMaybe<Array<ExternalAccountEmployeesOrderBy>>;
};

export interface UpsertGoogleWorkspaceOrgUnitPayload {
  __typename?: 'UpsertGoogleWorkspaceOrgUnitPayload';
  businessUnit?: Maybe<BusinessUnit>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Company>;
  googleWorkspaceOrgUnit?: Maybe<GoogleWorkspaceOrgUnit>;
  googleWorkspaceOrgUnitEdge?: Maybe<GoogleWorkspaceOrgUnitsEdge>;
  query?: Maybe<Query>;
}

export type UpsertGoogleWorkspaceOrgUnitPayloadGoogleWorkspaceOrgUnitEdgeArgs = {
  orderBy?: InputMaybe<Array<GoogleWorkspaceOrgUnitsOrderBy>>;
};

export interface UpsertOfficePayload {
  __typename?: 'UpsertOfficePayload';
  city: City;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  office?: Maybe<Office>;
  officeEdge?: Maybe<OfficesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertOfficePayloadOfficeEdgeArgs = {
  orderBy?: InputMaybe<Array<OfficesOrderBy>>;
};

export interface UpsertPerformanceReviewEvaluationCompetencyPayload {
  __typename?: 'UpsertPerformanceReviewEvaluationCompetencyPayload';
  assessmentEmployee: Employee;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationCompetency?: Maybe<PerformanceReviewEvaluationCompetency>;
  performanceReviewEvaluationCompetencyEdge?: Maybe<PerformanceReviewEvaluationCompetenciesEdge>;
  performanceReviewMasterCompetency: PerformanceReviewMasterCompetency;
  performanceReviewMasterRatingByAssessmentValue?: Maybe<PerformanceReviewMasterRating>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertPerformanceReviewEvaluationCompetencyPayloadPerformanceReviewEvaluationCompetencyEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationCompetenciesOrderBy>>;
};

export interface UpsertPerformanceReviewEvaluationFeedbackPayload {
  __typename?: 'UpsertPerformanceReviewEvaluationFeedbackPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  feedbackEmployee?: Maybe<Employee>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationFeedback?: Maybe<PerformanceReviewEvaluationFeedback>;
  performanceReviewEvaluationFeedbackEdge?: Maybe<PerformanceReviewEvaluationFeedbacksEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertPerformanceReviewEvaluationFeedbackPayloadPerformanceReviewEvaluationFeedbackEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationFeedbacksOrderBy>>;
};

export interface UpsertPerformanceReviewEvaluationKpiAssessmentPayload {
  __typename?: 'UpsertPerformanceReviewEvaluationKpiAssessmentPayload';
  assessmentEmployee: Employee;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationKpiAssessment?: Maybe<PerformanceReviewEvaluationKpiAssessment>;
  performanceReviewEvaluationKpiAssessmentEdge?: Maybe<PerformanceReviewEvaluationKpiAssessmentsEdge>;
  performanceReviewEvaluationKpiDescription: PerformanceReviewEvaluationKpiDescription;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertPerformanceReviewEvaluationKpiAssessmentPayloadPerformanceReviewEvaluationKpiAssessmentEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentsOrderBy>>;
};

export interface UpsertPerformanceReviewEvaluationKpiDescriptionPayload {
  __typename?: 'UpsertPerformanceReviewEvaluationKpiDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationKpiDescription?: Maybe<PerformanceReviewEvaluationKpiDescription>;
  performanceReviewEvaluationKpiDescriptionEdge?: Maybe<PerformanceReviewEvaluationKpiDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertPerformanceReviewEvaluationKpiDescriptionPayloadPerformanceReviewEvaluationKpiDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionsOrderBy>>;
};

export interface UpsertPerformanceReviewEvaluationKpiResultPayload {
  __typename?: 'UpsertPerformanceReviewEvaluationKpiResultPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationKpiResult?: Maybe<PerformanceReviewEvaluationKpiResult>;
  performanceReviewEvaluationKpiResultEdge?: Maybe<PerformanceReviewEvaluationKpiResultsEdge>;
  performanceReviewEvaluationKpiTarget: PerformanceReviewEvaluationKpiTarget;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertPerformanceReviewEvaluationKpiResultPayloadPerformanceReviewEvaluationKpiResultEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultsOrderBy>>;
};

export interface UpsertPerformanceReviewEvaluationKpiTargetPayload {
  __typename?: 'UpsertPerformanceReviewEvaluationKpiTargetPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationKpiDescription: PerformanceReviewEvaluationKpiDescription;
  performanceReviewEvaluationKpiTarget?: Maybe<PerformanceReviewEvaluationKpiTarget>;
  performanceReviewEvaluationKpiTargetEdge?: Maybe<PerformanceReviewEvaluationKpiTargetsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertPerformanceReviewEvaluationKpiTargetPayloadPerformanceReviewEvaluationKpiTargetEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetsOrderBy>>;
};

export interface UpsertPerformanceReviewEvaluationPayload {
  __typename?: 'UpsertPerformanceReviewEvaluationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employee: Employee;
  employeeTpHistory?: Maybe<EmployeeTpHistory>;
  performanceReviewEvaluation?: Maybe<PerformanceReviewEvaluation>;
  performanceReviewEvaluationEdge?: Maybe<PerformanceReviewEvaluationsEdge>;
  performanceReviewMasterCompetencyRole?: Maybe<PerformanceReviewMasterCompetencyRole>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertPerformanceReviewEvaluationPayloadPerformanceReviewEvaluationEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationsOrderBy>>;
};

export interface UpsertPerformanceReviewEvaluationQualitativeAssessmentPayload {
  __typename?: 'UpsertPerformanceReviewEvaluationQualitativeAssessmentPayload';
  assessmentEmployee: Employee;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationQualitativeAssessment?: Maybe<PerformanceReviewEvaluationQualitativeAssessment>;
  performanceReviewEvaluationQualitativeAssessmentEdge?: Maybe<PerformanceReviewEvaluationQualitativeAssessmentsEdge>;
  performanceReviewEvaluationQualitativeDescription: PerformanceReviewEvaluationQualitativeDescription;
  performanceReviewMasterRatingByAssessmentValue?: Maybe<PerformanceReviewMasterRating>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertPerformanceReviewEvaluationQualitativeAssessmentPayloadPerformanceReviewEvaluationQualitativeAssessmentEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentsOrderBy>>;
};

export interface UpsertPerformanceReviewEvaluationQualitativeDescriptionPayload {
  __typename?: 'UpsertPerformanceReviewEvaluationQualitativeDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationQualitativeDescription?: Maybe<PerformanceReviewEvaluationQualitativeDescription>;
  performanceReviewEvaluationQualitativeDescriptionEdge?: Maybe<PerformanceReviewEvaluationQualitativeDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertPerformanceReviewEvaluationQualitativeDescriptionPayloadPerformanceReviewEvaluationQualitativeDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionsOrderBy>>;
};

export interface UpsertPerformanceReviewEvaluationValuePayload {
  __typename?: 'UpsertPerformanceReviewEvaluationValuePayload';
  assessmentEmployee: Employee;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewEvaluation: PerformanceReviewEvaluation;
  performanceReviewEvaluationValue?: Maybe<PerformanceReviewEvaluationValue>;
  performanceReviewEvaluationValueEdge?: Maybe<PerformanceReviewEvaluationValuesEdge>;
  performanceReviewMasterRatingByAssessmentValue?: Maybe<PerformanceReviewMasterRating>;
  performanceReviewMasterValue: PerformanceReviewMasterValue;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertPerformanceReviewEvaluationValuePayloadPerformanceReviewEvaluationValueEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationValuesOrderBy>>;
};

export interface UpsertPerformanceReviewMasterCompetencyCommonDescriptionPayload {
  __typename?: 'UpsertPerformanceReviewMasterCompetencyCommonDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterCompetencyCommon: PerformanceReviewMasterCompetencyCommon;
  performanceReviewMasterCompetencyCommonDescription?: Maybe<PerformanceReviewMasterCompetencyCommonDescription>;
  performanceReviewMasterCompetencyCommonDescriptionEdge?: Maybe<PerformanceReviewMasterCompetencyCommonDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertPerformanceReviewMasterCompetencyCommonDescriptionPayloadPerformanceReviewMasterCompetencyCommonDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionsOrderBy>>;
};

export interface UpsertPerformanceReviewMasterCompetencyDetailDescriptionPayload {
  __typename?: 'UpsertPerformanceReviewMasterCompetencyDetailDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterCompetencyDetail: PerformanceReviewMasterCompetencyDetail;
  performanceReviewMasterCompetencyDetailDescription?: Maybe<PerformanceReviewMasterCompetencyDetailDescription>;
  performanceReviewMasterCompetencyDetailDescriptionEdge?: Maybe<PerformanceReviewMasterCompetencyDetailDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertPerformanceReviewMasterCompetencyDetailDescriptionPayloadPerformanceReviewMasterCompetencyDetailDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionsOrderBy>>;
};

export interface UpsertPerformanceReviewMasterCompetencyRolePayload {
  __typename?: 'UpsertPerformanceReviewMasterCompetencyRolePayload';
  businessUnit: BusinessUnit;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  department: Department;
  division: Division;
  performanceReviewMasterCompetencyRole?: Maybe<PerformanceReviewMasterCompetencyRole>;
  performanceReviewMasterCompetencyRoleEdge?: Maybe<PerformanceReviewMasterCompetencyRolesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertPerformanceReviewMasterCompetencyRolePayloadPerformanceReviewMasterCompetencyRoleEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyRolesOrderBy>>;
};

export interface UpsertPerformanceReviewMasterExcludedEmployeePayload {
  __typename?: 'UpsertPerformanceReviewMasterExcludedEmployeePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employee: Employee;
  performanceReviewMasterExcludedEmployee?: Maybe<PerformanceReviewMasterExcludedEmployee>;
  performanceReviewMasterExcludedEmployeeEdge?: Maybe<PerformanceReviewMasterExcludedEmployeesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertPerformanceReviewMasterExcludedEmployeePayloadPerformanceReviewMasterExcludedEmployeeEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterExcludedEmployeesOrderBy>>;
};

export interface UpsertPerformanceReviewMasterRatingDescriptionPayload {
  __typename?: 'UpsertPerformanceReviewMasterRatingDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterRating: PerformanceReviewMasterRating;
  performanceReviewMasterRatingDescription?: Maybe<PerformanceReviewMasterRatingDescription>;
  performanceReviewMasterRatingDescriptionEdge?: Maybe<PerformanceReviewMasterRatingDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertPerformanceReviewMasterRatingDescriptionPayloadPerformanceReviewMasterRatingDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionsOrderBy>>;
};

export interface UpsertPerformanceReviewMasterUsagePayload {
  __typename?: 'UpsertPerformanceReviewMasterUsagePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company: Company;
  performanceReviewMasterUsage?: Maybe<PerformanceReviewMasterUsage>;
  performanceReviewMasterUsageEdge?: Maybe<PerformanceReviewMasterUsagesEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertPerformanceReviewMasterUsagePayloadPerformanceReviewMasterUsageEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterUsagesOrderBy>>;
};

export interface UpsertPerformanceReviewMasterValueDescriptionPayload {
  __typename?: 'UpsertPerformanceReviewMasterValueDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterValue: PerformanceReviewMasterValue;
  performanceReviewMasterValueDescription?: Maybe<PerformanceReviewMasterValueDescription>;
  performanceReviewMasterValueDescriptionEdge?: Maybe<PerformanceReviewMasterValueDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertPerformanceReviewMasterValueDescriptionPayloadPerformanceReviewMasterValueDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionsOrderBy>>;
};

export interface UpsertPerformanceReviewMasterWeightingDetailPayload {
  __typename?: 'UpsertPerformanceReviewMasterWeightingDetailPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employeeLevel: EmployeeLevel;
  performanceReviewMasterWeighting: PerformanceReviewMasterWeighting;
  performanceReviewMasterWeightingDetail?: Maybe<PerformanceReviewMasterWeightingDetail>;
  performanceReviewMasterWeightingDetailEdge?: Maybe<PerformanceReviewMasterWeightingDetailsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertPerformanceReviewMasterWeightingDetailPayloadPerformanceReviewMasterWeightingDetailEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailsOrderBy>>;
};

export interface UpsertPerformanceReviewMasterWeightingPayload {
  __typename?: 'UpsertPerformanceReviewMasterWeightingPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Company>;
  performanceReviewMasterWeighting?: Maybe<PerformanceReviewMasterWeighting>;
  performanceReviewMasterWeightingEdge?: Maybe<PerformanceReviewMasterWeightingsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertPerformanceReviewMasterWeightingPayloadPerformanceReviewMasterWeightingEdgeArgs = {
  orderBy?: InputMaybe<Array<PerformanceReviewMasterWeightingsOrderBy>>;
};

export interface UpsertProbationReviewEvaluationPayload {
  __typename?: 'UpsertProbationReviewEvaluationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employee: Employee;
  probationReviewEvaluation?: Maybe<ProbationReviewEvaluation>;
  probationReviewEvaluationEdge?: Maybe<ProbationReviewEvaluationsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertProbationReviewEvaluationPayloadProbationReviewEvaluationEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationsOrderBy>>;
};

export interface UpsertProbationReviewEvaluationResultItemPayload {
  __typename?: 'UpsertProbationReviewEvaluationResultItemPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  probationReviewEvaluation: ProbationReviewEvaluation;
  probationReviewEvaluationResultItem?: Maybe<ProbationReviewEvaluationResultItem>;
  probationReviewEvaluationResultItemEdge?: Maybe<ProbationReviewEvaluationResultItemsEdge>;
  probationReviewMasterItem: ProbationReviewMasterItem;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertProbationReviewEvaluationResultItemPayloadProbationReviewEvaluationResultItemEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultItemsOrderBy>>;
};

export interface UpsertProbationReviewEvaluationResultScoreCompetencyPayload {
  __typename?: 'UpsertProbationReviewEvaluationResultScoreCompetencyPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterCompetency: PerformanceReviewMasterCompetencyCommon;
  performanceReviewMasterRatingByAssessment?: Maybe<PerformanceReviewMasterRating>;
  probationReviewEvaluation: ProbationReviewEvaluation;
  probationReviewEvaluationResultScoreCompetency?: Maybe<ProbationReviewEvaluationResultScoreCompetency>;
  probationReviewEvaluationResultScoreCompetencyEdge?: Maybe<ProbationReviewEvaluationResultScoreCompetenciesEdge>;
  probationReviewMasterItem: ProbationReviewMasterItem;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertProbationReviewEvaluationResultScoreCompetencyPayloadProbationReviewEvaluationResultScoreCompetencyEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetenciesOrderBy>>;
};

export interface UpsertProbationReviewEvaluationResultScoreValuePayload {
  __typename?: 'UpsertProbationReviewEvaluationResultScoreValuePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  performanceReviewMasterRatingByAssessment?: Maybe<PerformanceReviewMasterRating>;
  performanceReviewMasterValue: PerformanceReviewMasterValue;
  probationReviewEvaluation: ProbationReviewEvaluation;
  probationReviewEvaluationResultScoreValue?: Maybe<ProbationReviewEvaluationResultScoreValue>;
  probationReviewEvaluationResultScoreValueEdge?: Maybe<ProbationReviewEvaluationResultScoreValuesEdge>;
  probationReviewMasterItem: ProbationReviewMasterItem;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertProbationReviewEvaluationResultScoreValuePayloadProbationReviewEvaluationResultScoreValueEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValuesOrderBy>>;
};

export interface UpsertProbationReviewEvaluationReviewerPayload {
  __typename?: 'UpsertProbationReviewEvaluationReviewerPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  probationReviewEvaluation: ProbationReviewEvaluation;
  probationReviewEvaluationReviewer?: Maybe<ProbationReviewEvaluationReviewer>;
  probationReviewEvaluationReviewerEdge?: Maybe<ProbationReviewEvaluationReviewersEdge>;
  query?: Maybe<Query>;
  reviewerEmployee: Employee;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertProbationReviewEvaluationReviewerPayloadProbationReviewEvaluationReviewerEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationReviewersOrderBy>>;
};

export interface UpsertProbationReviewMasterItemDescriptionPayload {
  __typename?: 'UpsertProbationReviewMasterItemDescriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  probationReviewMasterItem: ProbationReviewMasterItem;
  probationReviewMasterItemDescription?: Maybe<ProbationReviewMasterItemDescription>;
  probationReviewMasterItemDescriptionEdge?: Maybe<ProbationReviewMasterItemDescriptionsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertProbationReviewMasterItemDescriptionPayloadProbationReviewMasterItemDescriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewMasterItemDescriptionsOrderBy>>;
};

export interface UpsertProbationReviewMasterItemPayload {
  __typename?: 'UpsertProbationReviewMasterItemPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  probationReviewMasterItem?: Maybe<ProbationReviewMasterItem>;
  probationReviewMasterItemEdge?: Maybe<ProbationReviewMasterItemsEdge>;
  query?: Maybe<Query>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertProbationReviewMasterItemPayloadProbationReviewMasterItemEdgeArgs = {
  orderBy?: InputMaybe<Array<ProbationReviewMasterItemsOrderBy>>;
};

export interface UpsertRelationEmployeeUserAccountPayload {
  __typename?: 'UpsertRelationEmployeeUserAccountPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  employee: Employee;
  query?: Maybe<Query>;
  relationEmployeeUserAccount?: Maybe<RelationEmployeeUserAccount>;
  relationEmployeeUserAccountEdge?: Maybe<RelationEmployeeUserAccountsEdge>;
  userAccount: UserAccount;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
}

export type UpsertRelationEmployeeUserAccountPayloadRelationEmployeeUserAccountEdgeArgs = {
  orderBy?: InputMaybe<Array<RelationEmployeeUserAccountsOrderBy>>;
};

export interface UserAccount extends Node {
  __typename?: 'UserAccount';
  banksByUserCreated: BanksConnection;
  banksByUserUpdated: BanksConnection;
  businessUnitsByUserCreated: BusinessUnitsConnection;
  businessUnitsByUserUpdated: BusinessUnitsConnection;
  citiesByUserCreated: CitiesConnection;
  citiesByUserUpdated: CitiesConnection;
  companiesByUserCreated: CompaniesConnection;
  companiesByUserUpdated: CompaniesConnection;
  companyExternalAccountDisablesByUserCreated: CompanyExternalAccountDisablesConnection;
  companyGlobalHrsByUserCreated: CompanyGlobalHrsConnection;
  companyGlobalHrsByUserUpdated: CompanyGlobalHrsConnection;
  companyGroupsByUserCreated: CompanyGroupsConnection;
  companyGroupsByUserUpdated: CompanyGroupsConnection;
  companyLocalHrsByUserCreated: CompanyLocalHrsConnection;
  companyLocalHrsByUserUpdated: CompanyLocalHrsConnection;
  contractTypesByUserCreated: ContractTypesConnection;
  contractTypesByUserUpdated: ContractTypesConnection;
  countriesByUserCreated: CountriesConnection;
  countriesByUserUpdated: CountriesConnection;
  currenciesByUserCreated: CurrenciesConnection;
  currenciesByUserUpdated: CurrenciesConnection;
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  departmentsByUserCreated: DepartmentsConnection;
  departmentsByUserUpdated: DepartmentsConnection;
  displayName: Scalars['String']['output'];
  divisionsByUserCreated: DivisionsConnection;
  divisionsByUserUpdated: DivisionsConnection;
  emailAddress: Scalars['String']['output'];
  emailDomainsByUserCreated: EmailDomainsConnection;
  emailDomainsByUserUpdated: EmailDomainsConnection;
  employeeEmergencyContactsByUserCreated: EmployeeEmergencyContactsConnection;
  employeeEmergencyContactsByUserUpdated: EmployeeEmergencyContactsConnection;
  employeeFilesByUserCreated: EmployeeFilesConnection;
  employeeFilesByUserUpdated: EmployeeFilesConnection;
  employeeGroupsByUserCreated: EmployeeGroupsConnection;
  employeeGroupsByUserUpdated: EmployeeGroupsConnection;
  employeeIdTypesByUserCreated: EmployeeIdTypesConnection;
  employeeIdTypesByUserUpdated: EmployeeIdTypesConnection;
  employeeLevelsByUserCreated: EmployeeLevelsConnection;
  employeeLevelsByUserUpdated: EmployeeLevelsConnection;
  employeeListCustomFilterRolesByUserCreated: EmployeeListCustomFilterRolesConnection;
  employeeListCustomFilterRolesByUserUpdated: EmployeeListCustomFilterRolesConnection;
  employeeListCustomFiltersByUserCreated: EmployeeListCustomFiltersConnection;
  employeeListCustomFiltersByUserUpdated: EmployeeListCustomFiltersConnection;
  employeeResignationReasonsByUserCreated: EmployeeResignationReasonsConnection;
  employeeResignationReasonsByUserUpdated: EmployeeResignationReasonsConnection;
  employeeResignationTypesByUserCreated: EmployeeResignationTypesConnection;
  employeeResignationTypesByUserUpdated: EmployeeResignationTypesConnection;
  employeeStatusesByUserCreated: EmployeeStatusesConnection;
  employeeStatusesByUserUpdated: EmployeeStatusesConnection;
  employeeTpHistoriesByUserCreated: EmployeeTpHistoriesConnection;
  employeeTpHistoriesByUserUpdated: EmployeeTpHistoriesConnection;
  employeesByUserCreated: EmployeesConnection;
  employeesByUserUpdated: EmployeesConnection;
  exportEmployeeStatusesByUserCreated: ExportEmployeeStatusesConnection;
  exportEmployeeStatusesByUserUpdated: ExportEmployeeStatusesConnection;
  externalAccountEmployeesByUserCreated: ExternalAccountEmployeesConnection;
  externalAccountEmployeesByUserUpdated: ExternalAccountEmployeesConnection;
  googleId?: Maybe<Scalars['String']['output']>;
  googleProfilePictureUrl?: Maybe<Scalars['String']['output']>;
  importEmployeeStatusesByUserCreated: ImportEmployeeStatusesConnection;
  importEmployeeStatusesByUserUpdated: ImportEmployeeStatusesConnection;
  isEnabled: Scalars['Boolean']['output'];
  nodeId: Scalars['ID']['output'];
  officeWeathersByUserCreated: OfficeWeathersConnection;
  officeWeathersByUserUpdated: OfficeWeathersConnection;
  officesByUserCreated: OfficesConnection;
  officesByUserUpdated: OfficesConnection;
  performanceReviewEvaluationCompetenciesByUserCreated: PerformanceReviewEvaluationCompetenciesConnection;
  performanceReviewEvaluationCompetenciesByUserUpdated: PerformanceReviewEvaluationCompetenciesConnection;
  performanceReviewEvaluationEvaluatorsByUserCreated: PerformanceReviewEvaluationEvaluatorsConnection;
  performanceReviewEvaluationEvaluatorsByUserUpdated: PerformanceReviewEvaluationEvaluatorsConnection;
  performanceReviewEvaluationFeedbacksByUserCreated: PerformanceReviewEvaluationFeedbacksConnection;
  performanceReviewEvaluationFeedbacksByUserUpdated: PerformanceReviewEvaluationFeedbacksConnection;
  performanceReviewEvaluationKpiAssessmentsByUserCreated: PerformanceReviewEvaluationKpiAssessmentsConnection;
  performanceReviewEvaluationKpiAssessmentsByUserUpdated: PerformanceReviewEvaluationKpiAssessmentsConnection;
  performanceReviewEvaluationKpiDescriptionsByUserCreated: PerformanceReviewEvaluationKpiDescriptionsConnection;
  performanceReviewEvaluationKpiDescriptionsByUserUpdated: PerformanceReviewEvaluationKpiDescriptionsConnection;
  performanceReviewEvaluationKpiResultsByUserCreated: PerformanceReviewEvaluationKpiResultsConnection;
  performanceReviewEvaluationKpiResultsByUserUpdated: PerformanceReviewEvaluationKpiResultsConnection;
  performanceReviewEvaluationKpiTargetsByUserCreated: PerformanceReviewEvaluationKpiTargetsConnection;
  performanceReviewEvaluationKpiTargetsByUserUpdated: PerformanceReviewEvaluationKpiTargetsConnection;
  performanceReviewEvaluationOneOnOnesByUserCreated: PerformanceReviewEvaluationOneOnOnesConnection;
  performanceReviewEvaluationQualitativeAssessmentsByUserCreated: PerformanceReviewEvaluationQualitativeAssessmentsConnection;
  performanceReviewEvaluationQualitativeAssessmentsByUserUpdated: PerformanceReviewEvaluationQualitativeAssessmentsConnection;
  performanceReviewEvaluationQualitativeDescriptionsByUserCreated: PerformanceReviewEvaluationQualitativeDescriptionsConnection;
  performanceReviewEvaluationQualitativeDescriptionsByUserUpdated: PerformanceReviewEvaluationQualitativeDescriptionsConnection;
  performanceReviewEvaluationValuesByUserCreated: PerformanceReviewEvaluationValuesConnection;
  performanceReviewEvaluationValuesByUserUpdated: PerformanceReviewEvaluationValuesConnection;
  performanceReviewEvaluationsByUserCreated: PerformanceReviewEvaluationsConnection;
  performanceReviewEvaluationsByUserUpdated: PerformanceReviewEvaluationsConnection;
  performanceReviewMasterCompetenciesByUserCreated: PerformanceReviewMasterCompetenciesConnection;
  performanceReviewMasterCompetenciesByUserUpdated: PerformanceReviewMasterCompetenciesConnection;
  performanceReviewMasterCompetencyCommonDescriptionsByUserCreated: PerformanceReviewMasterCompetencyCommonDescriptionsConnection;
  performanceReviewMasterCompetencyCommonDescriptionsByUserUpdated: PerformanceReviewMasterCompetencyCommonDescriptionsConnection;
  performanceReviewMasterCompetencyCommonsByUserCreated: PerformanceReviewMasterCompetencyCommonsConnection;
  performanceReviewMasterCompetencyCommonsByUserUpdated: PerformanceReviewMasterCompetencyCommonsConnection;
  performanceReviewMasterCompetencyDetailDescriptionsByUserCreated: PerformanceReviewMasterCompetencyDetailDescriptionsConnection;
  performanceReviewMasterCompetencyDetailDescriptionsByUserUpdated: PerformanceReviewMasterCompetencyDetailDescriptionsConnection;
  performanceReviewMasterCompetencyDetailsByUserCreated: PerformanceReviewMasterCompetencyDetailsConnection;
  performanceReviewMasterCompetencyDetailsByUserUpdated: PerformanceReviewMasterCompetencyDetailsConnection;
  performanceReviewMasterCompetencyRolesByUserCreated: PerformanceReviewMasterCompetencyRolesConnection;
  performanceReviewMasterCompetencyRolesByUserUpdated: PerformanceReviewMasterCompetencyRolesConnection;
  performanceReviewMasterDefaultsByUserCreated: PerformanceReviewMasterDefaultsConnection;
  performanceReviewMasterEvaluationViewersByUserCreated: PerformanceReviewMasterEvaluationViewersConnection;
  performanceReviewMasterEvaluationViewersByUserUpdated: PerformanceReviewMasterEvaluationViewersConnection;
  performanceReviewMasterExcludedEmployeesByUserCreated: PerformanceReviewMasterExcludedEmployeesConnection;
  performanceReviewMasterExcludedEmployeesByUserUpdated: PerformanceReviewMasterExcludedEmployeesConnection;
  performanceReviewMasterOneOnOneDetailsByUserCreated: PerformanceReviewMasterOneOnOneDetailsConnection;
  performanceReviewMasterOneOnOneDetailsByUserUpdated: PerformanceReviewMasterOneOnOneDetailsConnection;
  performanceReviewMasterOneOnOnesByUserCreated: PerformanceReviewMasterOneOnOnesConnection;
  performanceReviewMasterOneOnOnesByUserUpdated: PerformanceReviewMasterOneOnOnesConnection;
  performanceReviewMasterRatingDescriptionsByUserCreated: PerformanceReviewMasterRatingDescriptionsConnection;
  performanceReviewMasterRatingDescriptionsByUserUpdated: PerformanceReviewMasterRatingDescriptionsConnection;
  performanceReviewMasterRatingsByUserCreated: PerformanceReviewMasterRatingsConnection;
  performanceReviewMasterRatingsByUserUpdated: PerformanceReviewMasterRatingsConnection;
  performanceReviewMasterUsagesByUserCreated: PerformanceReviewMasterUsagesConnection;
  performanceReviewMasterUsagesByUserUpdated: PerformanceReviewMasterUsagesConnection;
  performanceReviewMasterValueDescriptionsByUserCreated: PerformanceReviewMasterValueDescriptionsConnection;
  performanceReviewMasterValueDescriptionsByUserUpdated: PerformanceReviewMasterValueDescriptionsConnection;
  performanceReviewMasterValuesByUserCreated: PerformanceReviewMasterValuesConnection;
  performanceReviewMasterValuesByUserUpdated: PerformanceReviewMasterValuesConnection;
  performanceReviewMasterWeightingDetailsByUserCreated: PerformanceReviewMasterWeightingDetailsConnection;
  performanceReviewMasterWeightingDetailsByUserUpdated: PerformanceReviewMasterWeightingDetailsConnection;
  performanceReviewMasterWeightingsByUserCreated: PerformanceReviewMasterWeightingsConnection;
  performanceReviewMasterWeightingsByUserUpdated: PerformanceReviewMasterWeightingsConnection;
  personalIdentityTypesByUserCreated: PersonalIdentityTypesConnection;
  personalIdentityTypesByUserUpdated: PersonalIdentityTypesConnection;
  probationReviewEnablesByUserCreated: ProbationReviewEnablesConnection;
  probationReviewEnablesByUserUpdated: ProbationReviewEnablesConnection;
  probationReviewEvaluationResultItemsByUserCreated: ProbationReviewEvaluationResultItemsConnection;
  probationReviewEvaluationResultItemsByUserUpdated: ProbationReviewEvaluationResultItemsConnection;
  probationReviewEvaluationResultScoreCompetenciesByUserCreated: ProbationReviewEvaluationResultScoreCompetenciesConnection;
  probationReviewEvaluationResultScoreCompetenciesByUserUpdated: ProbationReviewEvaluationResultScoreCompetenciesConnection;
  probationReviewEvaluationResultScoreValuesByUserCreated: ProbationReviewEvaluationResultScoreValuesConnection;
  probationReviewEvaluationResultScoreValuesByUserUpdated: ProbationReviewEvaluationResultScoreValuesConnection;
  probationReviewEvaluationReviewersByUserCreated: ProbationReviewEvaluationReviewersConnection;
  probationReviewEvaluationReviewersByUserUpdated: ProbationReviewEvaluationReviewersConnection;
  probationReviewEvaluationsByUserCreated: ProbationReviewEvaluationsConnection;
  probationReviewEvaluationsByUserUpdated: ProbationReviewEvaluationsConnection;
  probationReviewMasterItemDescriptionsByUserCreated: ProbationReviewMasterItemDescriptionsConnection;
  probationReviewMasterItemDescriptionsByUserUpdated: ProbationReviewMasterItemDescriptionsConnection;
  probationReviewMasterItemsByUserCreated: ProbationReviewMasterItemsConnection;
  probationReviewMasterItemsByUserUpdated: ProbationReviewMasterItemsConnection;
  relationBusinessUnitDivisionDepartmentsByUserCreated: RelationBusinessUnitDivisionDepartmentsConnection;
  relationBusinessUnitDivisionDepartmentsByUserUpdated: RelationBusinessUnitDivisionDepartmentsConnection;
  relationBusinessUnitDivisionsByUserCreated: RelationBusinessUnitDivisionsConnection;
  relationBusinessUnitDivisionsByUserUpdated: RelationBusinessUnitDivisionsConnection;
  relationCityCompaniesByUserCreated: RelationCityCompaniesConnection;
  relationCityCompaniesByUserUpdated: RelationCityCompaniesConnection;
  relationCompanyBusinessUnitsByUserCreated: RelationCompanyBusinessUnitsConnection;
  relationCompanyBusinessUnitsByUserUpdated: RelationCompanyBusinessUnitsConnection;
  relationCompanyGroupEmailDomainsByUserCreated: RelationCompanyGroupEmailDomainsConnection;
  relationCompanyGroupEmailDomainsByUserUpdated: RelationCompanyGroupEmailDomainsConnection;
  relationCompanyOfficesByUserCreated: RelationCompanyOfficesConnection;
  relationCompanyOfficesByUserUpdated: RelationCompanyOfficesConnection;
  relationCurrencyCountriesByUserCreated: RelationCurrencyCountriesConnection;
  relationCurrencyCountriesByUserUpdated: RelationCurrencyCountriesConnection;
  relationEmergencyContactsByUserCreated: RelationEmergencyContactsConnection;
  relationEmergencyContactsByUserUpdated: RelationEmergencyContactsConnection;
  relationEmployeeUserAccount?: Maybe<RelationEmployeeUserAccount>;
  relationEmployeeUserAccountsByUserCreated: RelationEmployeeUserAccountsConnection;
  relationEmployeeUserAccountsByUserUpdated: RelationEmployeeUserAccountsConnection;
  remark?: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
  tableAuditsByUserCreated: TableAuditsConnection;
  userAccountAccessHistories: UserAccountAccessHistoriesConnection;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userAccountId: Scalars['BigInt']['output'];
  userAccountPins: UserAccountPinsConnection;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userForceLogouts: UserForceLogoutsConnection;
  userHrManagingCompanies: UserHrManagingCompaniesConnection;
  userHrManagingCompaniesByUserCreated: UserHrManagingCompaniesConnection;
  userHrManagingCompaniesByUserUpdated: UserHrManagingCompaniesConnection;
  userRoleByRole: UserRole;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
  worldPlaceWeathersByUserCreated: WorldPlaceWeathersConnection;
  worldPlaceWeathersByUserUpdated: WorldPlaceWeathersConnection;
  worldPlacesByUserCreated: WorldPlacesConnection;
  worldPlacesByUserUpdated: WorldPlacesConnection;
}

export type UserAccountBanksByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<BankCondition>;
  filter?: InputMaybe<BankFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BanksOrderBy>>;
};

export type UserAccountBanksByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<BankCondition>;
  filter?: InputMaybe<BankFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BanksOrderBy>>;
};

export type UserAccountBusinessUnitsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<BusinessUnitCondition>;
  filter?: InputMaybe<BusinessUnitFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BusinessUnitsOrderBy>>;
};

export type UserAccountBusinessUnitsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<BusinessUnitCondition>;
  filter?: InputMaybe<BusinessUnitFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BusinessUnitsOrderBy>>;
};

export type UserAccountCitiesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CityCondition>;
  filter?: InputMaybe<CityFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CitiesOrderBy>>;
};

export type UserAccountCitiesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CityCondition>;
  filter?: InputMaybe<CityFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CitiesOrderBy>>;
};

export type UserAccountCompaniesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CompanyCondition>;
  filter?: InputMaybe<CompanyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompaniesOrderBy>>;
};

export type UserAccountCompaniesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CompanyCondition>;
  filter?: InputMaybe<CompanyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompaniesOrderBy>>;
};

export type UserAccountCompanyExternalAccountDisablesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CompanyExternalAccountDisableCondition>;
  filter?: InputMaybe<CompanyExternalAccountDisableFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyExternalAccountDisablesOrderBy>>;
};

export type UserAccountCompanyGlobalHrsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CompanyGlobalHrCondition>;
  filter?: InputMaybe<CompanyGlobalHrFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyGlobalHrsOrderBy>>;
};

export type UserAccountCompanyGlobalHrsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CompanyGlobalHrCondition>;
  filter?: InputMaybe<CompanyGlobalHrFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyGlobalHrsOrderBy>>;
};

export type UserAccountCompanyGroupsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CompanyGroupCondition>;
  filter?: InputMaybe<CompanyGroupFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyGroupsOrderBy>>;
};

export type UserAccountCompanyGroupsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CompanyGroupCondition>;
  filter?: InputMaybe<CompanyGroupFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyGroupsOrderBy>>;
};

export type UserAccountCompanyLocalHrsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CompanyLocalHrCondition>;
  filter?: InputMaybe<CompanyLocalHrFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyLocalHrsOrderBy>>;
};

export type UserAccountCompanyLocalHrsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CompanyLocalHrCondition>;
  filter?: InputMaybe<CompanyLocalHrFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyLocalHrsOrderBy>>;
};

export type UserAccountContractTypesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ContractTypeCondition>;
  filter?: InputMaybe<ContractTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContractTypesOrderBy>>;
};

export type UserAccountContractTypesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ContractTypeCondition>;
  filter?: InputMaybe<ContractTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContractTypesOrderBy>>;
};

export type UserAccountCountriesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CountryCondition>;
  filter?: InputMaybe<CountryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CountriesOrderBy>>;
};

export type UserAccountCountriesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CountryCondition>;
  filter?: InputMaybe<CountryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CountriesOrderBy>>;
};

export type UserAccountCurrenciesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CurrencyCondition>;
  filter?: InputMaybe<CurrencyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CurrenciesOrderBy>>;
};

export type UserAccountCurrenciesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CurrencyCondition>;
  filter?: InputMaybe<CurrencyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CurrenciesOrderBy>>;
};

export type UserAccountDepartmentsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DepartmentCondition>;
  filter?: InputMaybe<DepartmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DepartmentsOrderBy>>;
};

export type UserAccountDepartmentsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DepartmentCondition>;
  filter?: InputMaybe<DepartmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DepartmentsOrderBy>>;
};

export type UserAccountDivisionsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DivisionCondition>;
  filter?: InputMaybe<DivisionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DivisionsOrderBy>>;
};

export type UserAccountDivisionsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DivisionCondition>;
  filter?: InputMaybe<DivisionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DivisionsOrderBy>>;
};

export type UserAccountEmailDomainsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmailDomainCondition>;
  filter?: InputMaybe<EmailDomainFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmailDomainsOrderBy>>;
};

export type UserAccountEmailDomainsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmailDomainCondition>;
  filter?: InputMaybe<EmailDomainFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmailDomainsOrderBy>>;
};

export type UserAccountEmployeeEmergencyContactsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeEmergencyContactCondition>;
  filter?: InputMaybe<EmployeeEmergencyContactFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeEmergencyContactsOrderBy>>;
};

export type UserAccountEmployeeEmergencyContactsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeEmergencyContactCondition>;
  filter?: InputMaybe<EmployeeEmergencyContactFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeEmergencyContactsOrderBy>>;
};

export type UserAccountEmployeeFilesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeFileCondition>;
  filter?: InputMaybe<EmployeeFileFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeFilesOrderBy>>;
};

export type UserAccountEmployeeFilesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeFileCondition>;
  filter?: InputMaybe<EmployeeFileFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeFilesOrderBy>>;
};

export type UserAccountEmployeeGroupsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeGroupCondition>;
  filter?: InputMaybe<EmployeeGroupFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeGroupsOrderBy>>;
};

export type UserAccountEmployeeGroupsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeGroupCondition>;
  filter?: InputMaybe<EmployeeGroupFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeGroupsOrderBy>>;
};

export type UserAccountEmployeeIdTypesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeIdTypeCondition>;
  filter?: InputMaybe<EmployeeIdTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeIdTypesOrderBy>>;
};

export type UserAccountEmployeeIdTypesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeIdTypeCondition>;
  filter?: InputMaybe<EmployeeIdTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeIdTypesOrderBy>>;
};

export type UserAccountEmployeeLevelsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeLevelCondition>;
  filter?: InputMaybe<EmployeeLevelFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeLevelsOrderBy>>;
};

export type UserAccountEmployeeLevelsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeLevelCondition>;
  filter?: InputMaybe<EmployeeLevelFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeLevelsOrderBy>>;
};

export type UserAccountEmployeeListCustomFilterRolesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeListCustomFilterRoleCondition>;
  filter?: InputMaybe<EmployeeListCustomFilterRoleFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeListCustomFilterRolesOrderBy>>;
};

export type UserAccountEmployeeListCustomFilterRolesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeListCustomFilterRoleCondition>;
  filter?: InputMaybe<EmployeeListCustomFilterRoleFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeListCustomFilterRolesOrderBy>>;
};

export type UserAccountEmployeeListCustomFiltersByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeListCustomFilterCondition>;
  filter?: InputMaybe<EmployeeListCustomFilterFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeListCustomFiltersOrderBy>>;
};

export type UserAccountEmployeeListCustomFiltersByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeListCustomFilterCondition>;
  filter?: InputMaybe<EmployeeListCustomFilterFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeListCustomFiltersOrderBy>>;
};

export type UserAccountEmployeeResignationReasonsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeResignationReasonCondition>;
  filter?: InputMaybe<EmployeeResignationReasonFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeResignationReasonsOrderBy>>;
};

export type UserAccountEmployeeResignationReasonsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeResignationReasonCondition>;
  filter?: InputMaybe<EmployeeResignationReasonFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeResignationReasonsOrderBy>>;
};

export type UserAccountEmployeeResignationTypesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeResignationTypeCondition>;
  filter?: InputMaybe<EmployeeResignationTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeResignationTypesOrderBy>>;
};

export type UserAccountEmployeeResignationTypesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeResignationTypeCondition>;
  filter?: InputMaybe<EmployeeResignationTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeResignationTypesOrderBy>>;
};

export type UserAccountEmployeeStatusesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeStatusCondition>;
  filter?: InputMaybe<EmployeeStatusFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeStatusesOrderBy>>;
};

export type UserAccountEmployeeStatusesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeStatusCondition>;
  filter?: InputMaybe<EmployeeStatusFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeStatusesOrderBy>>;
};

export type UserAccountEmployeeTpHistoriesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeTpHistoryCondition>;
  filter?: InputMaybe<EmployeeTpHistoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeTpHistoriesOrderBy>>;
};

export type UserAccountEmployeeTpHistoriesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeTpHistoryCondition>;
  filter?: InputMaybe<EmployeeTpHistoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeTpHistoriesOrderBy>>;
};

export type UserAccountEmployeesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCondition>;
  filter?: InputMaybe<EmployeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeesOrderBy>>;
};

export type UserAccountEmployeesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeCondition>;
  filter?: InputMaybe<EmployeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeesOrderBy>>;
};

export type UserAccountExportEmployeeStatusesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ExportEmployeeStatusCondition>;
  filter?: InputMaybe<ExportEmployeeStatusFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ExportEmployeeStatusesOrderBy>>;
};

export type UserAccountExportEmployeeStatusesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ExportEmployeeStatusCondition>;
  filter?: InputMaybe<ExportEmployeeStatusFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ExportEmployeeStatusesOrderBy>>;
};

export type UserAccountExternalAccountEmployeesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ExternalAccountEmployeeCondition>;
  filter?: InputMaybe<ExternalAccountEmployeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ExternalAccountEmployeesOrderBy>>;
};

export type UserAccountExternalAccountEmployeesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ExternalAccountEmployeeCondition>;
  filter?: InputMaybe<ExternalAccountEmployeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ExternalAccountEmployeesOrderBy>>;
};

export type UserAccountImportEmployeeStatusesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ImportEmployeeStatusCondition>;
  filter?: InputMaybe<ImportEmployeeStatusFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ImportEmployeeStatusesOrderBy>>;
};

export type UserAccountImportEmployeeStatusesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ImportEmployeeStatusCondition>;
  filter?: InputMaybe<ImportEmployeeStatusFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ImportEmployeeStatusesOrderBy>>;
};

export type UserAccountOfficeWeathersByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<OfficeWeatherCondition>;
  filter?: InputMaybe<OfficeWeatherFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OfficeWeathersOrderBy>>;
};

export type UserAccountOfficeWeathersByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<OfficeWeatherCondition>;
  filter?: InputMaybe<OfficeWeatherFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OfficeWeathersOrderBy>>;
};

export type UserAccountOfficesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<OfficeCondition>;
  filter?: InputMaybe<OfficeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OfficesOrderBy>>;
};

export type UserAccountOfficesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<OfficeCondition>;
  filter?: InputMaybe<OfficeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OfficesOrderBy>>;
};

export type UserAccountPerformanceReviewEvaluationCompetenciesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationCompetencyCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationCompetencyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationCompetenciesOrderBy>>;
};

export type UserAccountPerformanceReviewEvaluationCompetenciesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationCompetencyCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationCompetencyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationCompetenciesOrderBy>>;
};

export type UserAccountPerformanceReviewEvaluationEvaluatorsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationEvaluatorCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationEvaluatorFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorsOrderBy>>;
};

export type UserAccountPerformanceReviewEvaluationEvaluatorsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationEvaluatorCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationEvaluatorFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationEvaluatorsOrderBy>>;
};

export type UserAccountPerformanceReviewEvaluationFeedbacksByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationFeedbackCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationFeedbackFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationFeedbacksOrderBy>>;
};

export type UserAccountPerformanceReviewEvaluationFeedbacksByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationFeedbackCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationFeedbackFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationFeedbacksOrderBy>>;
};

export type UserAccountPerformanceReviewEvaluationKpiAssessmentsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentsOrderBy>>;
};

export type UserAccountPerformanceReviewEvaluationKpiAssessmentsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiAssessmentsOrderBy>>;
};

export type UserAccountPerformanceReviewEvaluationKpiDescriptionsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionsOrderBy>>;
};

export type UserAccountPerformanceReviewEvaluationKpiDescriptionsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiDescriptionsOrderBy>>;
};

export type UserAccountPerformanceReviewEvaluationKpiResultsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationKpiResultCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationKpiResultFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultsOrderBy>>;
};

export type UserAccountPerformanceReviewEvaluationKpiResultsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationKpiResultCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationKpiResultFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiResultsOrderBy>>;
};

export type UserAccountPerformanceReviewEvaluationKpiTargetsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationKpiTargetCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationKpiTargetFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetsOrderBy>>;
};

export type UserAccountPerformanceReviewEvaluationKpiTargetsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationKpiTargetCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationKpiTargetFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationKpiTargetsOrderBy>>;
};

export type UserAccountPerformanceReviewEvaluationOneOnOnesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationOneOnOneCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationOneOnOneFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationOneOnOnesOrderBy>>;
};

export type UserAccountPerformanceReviewEvaluationQualitativeAssessmentsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentsOrderBy>>;
};

export type UserAccountPerformanceReviewEvaluationQualitativeAssessmentsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeAssessmentsOrderBy>>;
};

export type UserAccountPerformanceReviewEvaluationQualitativeDescriptionsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionsOrderBy>>;
};

export type UserAccountPerformanceReviewEvaluationQualitativeDescriptionsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationQualitativeDescriptionsOrderBy>>;
};

export type UserAccountPerformanceReviewEvaluationValuesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationValueCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationValueFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationValuesOrderBy>>;
};

export type UserAccountPerformanceReviewEvaluationValuesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationValueCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationValueFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationValuesOrderBy>>;
};

export type UserAccountPerformanceReviewEvaluationsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationsOrderBy>>;
};

export type UserAccountPerformanceReviewEvaluationsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewEvaluationCondition>;
  filter?: InputMaybe<PerformanceReviewEvaluationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewEvaluationsOrderBy>>;
};

export type UserAccountPerformanceReviewMasterCompetenciesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetenciesOrderBy>>;
};

export type UserAccountPerformanceReviewMasterCompetenciesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetenciesOrderBy>>;
};

export type UserAccountPerformanceReviewMasterCompetencyCommonDescriptionsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyCommonDescriptionCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyCommonDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionsOrderBy>>;
};

export type UserAccountPerformanceReviewMasterCompetencyCommonDescriptionsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyCommonDescriptionCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyCommonDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonDescriptionsOrderBy>>;
};

export type UserAccountPerformanceReviewMasterCompetencyCommonsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyCommonCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyCommonFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonsOrderBy>>;
};

export type UserAccountPerformanceReviewMasterCompetencyCommonsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyCommonCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyCommonFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyCommonsOrderBy>>;
};

export type UserAccountPerformanceReviewMasterCompetencyDetailDescriptionsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyDetailDescriptionCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyDetailDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionsOrderBy>>;
};

export type UserAccountPerformanceReviewMasterCompetencyDetailDescriptionsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyDetailDescriptionCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyDetailDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailDescriptionsOrderBy>>;
};

export type UserAccountPerformanceReviewMasterCompetencyDetailsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyDetailCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyDetailFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailsOrderBy>>;
};

export type UserAccountPerformanceReviewMasterCompetencyDetailsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyDetailCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyDetailFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyDetailsOrderBy>>;
};

export type UserAccountPerformanceReviewMasterCompetencyRolesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyRoleCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyRoleFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyRolesOrderBy>>;
};

export type UserAccountPerformanceReviewMasterCompetencyRolesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterCompetencyRoleCondition>;
  filter?: InputMaybe<PerformanceReviewMasterCompetencyRoleFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterCompetencyRolesOrderBy>>;
};

export type UserAccountPerformanceReviewMasterDefaultsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterDefaultCondition>;
  filter?: InputMaybe<PerformanceReviewMasterDefaultFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterDefaultsOrderBy>>;
};

export type UserAccountPerformanceReviewMasterEvaluationViewersByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterEvaluationViewerCondition>;
  filter?: InputMaybe<PerformanceReviewMasterEvaluationViewerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewersOrderBy>>;
};

export type UserAccountPerformanceReviewMasterEvaluationViewersByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterEvaluationViewerCondition>;
  filter?: InputMaybe<PerformanceReviewMasterEvaluationViewerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterEvaluationViewersOrderBy>>;
};

export type UserAccountPerformanceReviewMasterExcludedEmployeesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterExcludedEmployeeCondition>;
  filter?: InputMaybe<PerformanceReviewMasterExcludedEmployeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterExcludedEmployeesOrderBy>>;
};

export type UserAccountPerformanceReviewMasterExcludedEmployeesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterExcludedEmployeeCondition>;
  filter?: InputMaybe<PerformanceReviewMasterExcludedEmployeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterExcludedEmployeesOrderBy>>;
};

export type UserAccountPerformanceReviewMasterOneOnOneDetailsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterOneOnOneDetailCondition>;
  filter?: InputMaybe<PerformanceReviewMasterOneOnOneDetailFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterOneOnOneDetailsOrderBy>>;
};

export type UserAccountPerformanceReviewMasterOneOnOneDetailsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterOneOnOneDetailCondition>;
  filter?: InputMaybe<PerformanceReviewMasterOneOnOneDetailFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterOneOnOneDetailsOrderBy>>;
};

export type UserAccountPerformanceReviewMasterOneOnOnesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterOneOnOneCondition>;
  filter?: InputMaybe<PerformanceReviewMasterOneOnOneFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterOneOnOnesOrderBy>>;
};

export type UserAccountPerformanceReviewMasterOneOnOnesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterOneOnOneCondition>;
  filter?: InputMaybe<PerformanceReviewMasterOneOnOneFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterOneOnOnesOrderBy>>;
};

export type UserAccountPerformanceReviewMasterRatingDescriptionsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterRatingDescriptionCondition>;
  filter?: InputMaybe<PerformanceReviewMasterRatingDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionsOrderBy>>;
};

export type UserAccountPerformanceReviewMasterRatingDescriptionsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterRatingDescriptionCondition>;
  filter?: InputMaybe<PerformanceReviewMasterRatingDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterRatingDescriptionsOrderBy>>;
};

export type UserAccountPerformanceReviewMasterRatingsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterRatingCondition>;
  filter?: InputMaybe<PerformanceReviewMasterRatingFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterRatingsOrderBy>>;
};

export type UserAccountPerformanceReviewMasterRatingsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterRatingCondition>;
  filter?: InputMaybe<PerformanceReviewMasterRatingFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterRatingsOrderBy>>;
};

export type UserAccountPerformanceReviewMasterUsagesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterUsageCondition>;
  filter?: InputMaybe<PerformanceReviewMasterUsageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterUsagesOrderBy>>;
};

export type UserAccountPerformanceReviewMasterUsagesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterUsageCondition>;
  filter?: InputMaybe<PerformanceReviewMasterUsageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterUsagesOrderBy>>;
};

export type UserAccountPerformanceReviewMasterValueDescriptionsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterValueDescriptionCondition>;
  filter?: InputMaybe<PerformanceReviewMasterValueDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionsOrderBy>>;
};

export type UserAccountPerformanceReviewMasterValueDescriptionsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterValueDescriptionCondition>;
  filter?: InputMaybe<PerformanceReviewMasterValueDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterValueDescriptionsOrderBy>>;
};

export type UserAccountPerformanceReviewMasterValuesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterValueCondition>;
  filter?: InputMaybe<PerformanceReviewMasterValueFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterValuesOrderBy>>;
};

export type UserAccountPerformanceReviewMasterValuesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterValueCondition>;
  filter?: InputMaybe<PerformanceReviewMasterValueFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterValuesOrderBy>>;
};

export type UserAccountPerformanceReviewMasterWeightingDetailsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterWeightingDetailCondition>;
  filter?: InputMaybe<PerformanceReviewMasterWeightingDetailFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailsOrderBy>>;
};

export type UserAccountPerformanceReviewMasterWeightingDetailsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterWeightingDetailCondition>;
  filter?: InputMaybe<PerformanceReviewMasterWeightingDetailFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterWeightingDetailsOrderBy>>;
};

export type UserAccountPerformanceReviewMasterWeightingsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterWeightingCondition>;
  filter?: InputMaybe<PerformanceReviewMasterWeightingFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterWeightingsOrderBy>>;
};

export type UserAccountPerformanceReviewMasterWeightingsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PerformanceReviewMasterWeightingCondition>;
  filter?: InputMaybe<PerformanceReviewMasterWeightingFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PerformanceReviewMasterWeightingsOrderBy>>;
};

export type UserAccountPersonalIdentityTypesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PersonalIdentityTypeCondition>;
  filter?: InputMaybe<PersonalIdentityTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PersonalIdentityTypesOrderBy>>;
};

export type UserAccountPersonalIdentityTypesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PersonalIdentityTypeCondition>;
  filter?: InputMaybe<PersonalIdentityTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PersonalIdentityTypesOrderBy>>;
};

export type UserAccountProbationReviewEnablesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEnableCondition>;
  filter?: InputMaybe<ProbationReviewEnableFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEnablesOrderBy>>;
};

export type UserAccountProbationReviewEnablesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEnableCondition>;
  filter?: InputMaybe<ProbationReviewEnableFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEnablesOrderBy>>;
};

export type UserAccountProbationReviewEvaluationResultItemsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationResultItemCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationResultItemFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultItemsOrderBy>>;
};

export type UserAccountProbationReviewEvaluationResultItemsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationResultItemCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationResultItemFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultItemsOrderBy>>;
};

export type UserAccountProbationReviewEvaluationResultScoreCompetenciesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetenciesOrderBy>>;
};

export type UserAccountProbationReviewEvaluationResultScoreCompetenciesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultScoreCompetenciesOrderBy>>;
};

export type UserAccountProbationReviewEvaluationResultScoreValuesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationResultScoreValueCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationResultScoreValueFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValuesOrderBy>>;
};

export type UserAccountProbationReviewEvaluationResultScoreValuesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationResultScoreValueCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationResultScoreValueFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationResultScoreValuesOrderBy>>;
};

export type UserAccountProbationReviewEvaluationReviewersByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationReviewerCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationReviewerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationReviewersOrderBy>>;
};

export type UserAccountProbationReviewEvaluationReviewersByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationReviewerCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationReviewerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationReviewersOrderBy>>;
};

export type UserAccountProbationReviewEvaluationsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationsOrderBy>>;
};

export type UserAccountProbationReviewEvaluationsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewEvaluationCondition>;
  filter?: InputMaybe<ProbationReviewEvaluationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewEvaluationsOrderBy>>;
};

export type UserAccountProbationReviewMasterItemDescriptionsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewMasterItemDescriptionCondition>;
  filter?: InputMaybe<ProbationReviewMasterItemDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewMasterItemDescriptionsOrderBy>>;
};

export type UserAccountProbationReviewMasterItemDescriptionsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewMasterItemDescriptionCondition>;
  filter?: InputMaybe<ProbationReviewMasterItemDescriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewMasterItemDescriptionsOrderBy>>;
};

export type UserAccountProbationReviewMasterItemsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewMasterItemCondition>;
  filter?: InputMaybe<ProbationReviewMasterItemFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewMasterItemsOrderBy>>;
};

export type UserAccountProbationReviewMasterItemsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProbationReviewMasterItemCondition>;
  filter?: InputMaybe<ProbationReviewMasterItemFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProbationReviewMasterItemsOrderBy>>;
};

export type UserAccountRelationBusinessUnitDivisionDepartmentsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationBusinessUnitDivisionDepartmentCondition>;
  filter?: InputMaybe<RelationBusinessUnitDivisionDepartmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentsOrderBy>>;
};

export type UserAccountRelationBusinessUnitDivisionDepartmentsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationBusinessUnitDivisionDepartmentCondition>;
  filter?: InputMaybe<RelationBusinessUnitDivisionDepartmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationBusinessUnitDivisionDepartmentsOrderBy>>;
};

export type UserAccountRelationBusinessUnitDivisionsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationBusinessUnitDivisionCondition>;
  filter?: InputMaybe<RelationBusinessUnitDivisionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationBusinessUnitDivisionsOrderBy>>;
};

export type UserAccountRelationBusinessUnitDivisionsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationBusinessUnitDivisionCondition>;
  filter?: InputMaybe<RelationBusinessUnitDivisionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationBusinessUnitDivisionsOrderBy>>;
};

export type UserAccountRelationCityCompaniesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationCityCompanyCondition>;
  filter?: InputMaybe<RelationCityCompanyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationCityCompaniesOrderBy>>;
};

export type UserAccountRelationCityCompaniesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationCityCompanyCondition>;
  filter?: InputMaybe<RelationCityCompanyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationCityCompaniesOrderBy>>;
};

export type UserAccountRelationCompanyBusinessUnitsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationCompanyBusinessUnitCondition>;
  filter?: InputMaybe<RelationCompanyBusinessUnitFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationCompanyBusinessUnitsOrderBy>>;
};

export type UserAccountRelationCompanyBusinessUnitsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationCompanyBusinessUnitCondition>;
  filter?: InputMaybe<RelationCompanyBusinessUnitFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationCompanyBusinessUnitsOrderBy>>;
};

export type UserAccountRelationCompanyGroupEmailDomainsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationCompanyGroupEmailDomainCondition>;
  filter?: InputMaybe<RelationCompanyGroupEmailDomainFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationCompanyGroupEmailDomainsOrderBy>>;
};

export type UserAccountRelationCompanyGroupEmailDomainsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationCompanyGroupEmailDomainCondition>;
  filter?: InputMaybe<RelationCompanyGroupEmailDomainFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationCompanyGroupEmailDomainsOrderBy>>;
};

export type UserAccountRelationCompanyOfficesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationCompanyOfficeCondition>;
  filter?: InputMaybe<RelationCompanyOfficeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationCompanyOfficesOrderBy>>;
};

export type UserAccountRelationCompanyOfficesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationCompanyOfficeCondition>;
  filter?: InputMaybe<RelationCompanyOfficeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationCompanyOfficesOrderBy>>;
};

export type UserAccountRelationCurrencyCountriesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationCurrencyCountryCondition>;
  filter?: InputMaybe<RelationCurrencyCountryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationCurrencyCountriesOrderBy>>;
};

export type UserAccountRelationCurrencyCountriesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationCurrencyCountryCondition>;
  filter?: InputMaybe<RelationCurrencyCountryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationCurrencyCountriesOrderBy>>;
};

export type UserAccountRelationEmergencyContactsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationEmergencyContactCondition>;
  filter?: InputMaybe<RelationEmergencyContactFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationEmergencyContactsOrderBy>>;
};

export type UserAccountRelationEmergencyContactsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationEmergencyContactCondition>;
  filter?: InputMaybe<RelationEmergencyContactFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationEmergencyContactsOrderBy>>;
};

export type UserAccountRelationEmployeeUserAccountsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationEmployeeUserAccountCondition>;
  filter?: InputMaybe<RelationEmployeeUserAccountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationEmployeeUserAccountsOrderBy>>;
};

export type UserAccountRelationEmployeeUserAccountsByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RelationEmployeeUserAccountCondition>;
  filter?: InputMaybe<RelationEmployeeUserAccountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RelationEmployeeUserAccountsOrderBy>>;
};

export type UserAccountTableAuditsByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TableAuditCondition>;
  filter?: InputMaybe<TableAuditFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TableAuditsOrderBy>>;
};

export type UserAccountUserAccountAccessHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserAccountAccessHistoryCondition>;
  filter?: InputMaybe<UserAccountAccessHistoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserAccountAccessHistoriesOrderBy>>;
};

export type UserAccountUserAccountPinsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserAccountPinCondition>;
  filter?: InputMaybe<UserAccountPinFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserAccountPinsOrderBy>>;
};

export type UserAccountUserForceLogoutsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserForceLogoutCondition>;
  filter?: InputMaybe<UserForceLogoutFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserForceLogoutsOrderBy>>;
};

export type UserAccountUserHrManagingCompaniesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserHrManagingCompanyCondition>;
  filter?: InputMaybe<UserHrManagingCompanyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserHrManagingCompaniesOrderBy>>;
};

export type UserAccountUserHrManagingCompaniesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserHrManagingCompanyCondition>;
  filter?: InputMaybe<UserHrManagingCompanyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserHrManagingCompaniesOrderBy>>;
};

export type UserAccountUserHrManagingCompaniesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserHrManagingCompanyCondition>;
  filter?: InputMaybe<UserHrManagingCompanyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserHrManagingCompaniesOrderBy>>;
};

export type UserAccountWorldPlaceWeathersByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<WorldPlaceWeatherCondition>;
  filter?: InputMaybe<WorldPlaceWeatherFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<WorldPlaceWeathersOrderBy>>;
};

export type UserAccountWorldPlaceWeathersByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<WorldPlaceWeatherCondition>;
  filter?: InputMaybe<WorldPlaceWeatherFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<WorldPlaceWeathersOrderBy>>;
};

export type UserAccountWorldPlacesByUserCreatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<WorldPlaceCondition>;
  filter?: InputMaybe<WorldPlaceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<WorldPlacesOrderBy>>;
};

export type UserAccountWorldPlacesByUserUpdatedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<WorldPlaceCondition>;
  filter?: InputMaybe<WorldPlaceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<WorldPlacesOrderBy>>;
};

export interface UserAccountAccessHistoriesConnection {
  __typename?: 'UserAccountAccessHistoriesConnection';
  edges: Array<UserAccountAccessHistoriesEdge>;
  nodes: Array<UserAccountAccessHistory>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface UserAccountAccessHistoriesEdge {
  __typename?: 'UserAccountAccessHistoriesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: UserAccountAccessHistory;
}

export enum UserAccountAccessHistoriesOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  GEOIP_CITY_ASC = 'GEOIP_CITY_ASC',
  GEOIP_CITY_DESC = 'GEOIP_CITY_DESC',
  GEOIP_COUNTRY_CODE_ALPHA2_ASC = 'GEOIP_COUNTRY_CODE_ALPHA2_ASC',
  GEOIP_COUNTRY_CODE_ALPHA2_DESC = 'GEOIP_COUNTRY_CODE_ALPHA2_DESC',
  GEOIP_LAT_LNG_ASC = 'GEOIP_LAT_LNG_ASC',
  GEOIP_LAT_LNG_DESC = 'GEOIP_LAT_LNG_DESC',
  GEOIP_REGION_ASC = 'GEOIP_REGION_ASC',
  GEOIP_REGION_DESC = 'GEOIP_REGION_DESC',
  GEOIP_TIMEZONE_ASC = 'GEOIP_TIMEZONE_ASC',
  GEOIP_TIMEZONE_DESC = 'GEOIP_TIMEZONE_DESC',
  HOST_NAME_ASC = 'HOST_NAME_ASC',
  HOST_NAME_DESC = 'HOST_NAME_DESC',
  IP_ADDRESS_ASC = 'IP_ADDRESS_ASC',
  IP_ADDRESS_DESC = 'IP_ADDRESS_DESC',
  LANGUAGES_ASC = 'LANGUAGES_ASC',
  LANGUAGES_DESC = 'LANGUAGES_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REFERRER_ASC = 'REFERRER_ASC',
  REFERRER_DESC = 'REFERRER_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  TIMEZONE_OFFSET_ASC = 'TIMEZONE_OFFSET_ASC',
  TIMEZONE_OFFSET_DESC = 'TIMEZONE_OFFSET_DESC',
  USER_ACCOUNT_ACCESS_HISTORY_ID_ASC = 'USER_ACCOUNT_ACCESS_HISTORY_ID_ASC',
  USER_ACCOUNT_ACCESS_HISTORY_ID_DESC = 'USER_ACCOUNT_ACCESS_HISTORY_ID_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__REMARK_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__REMARK_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__REMARK_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__REMARK_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__ROLE_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__ROLE_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__ROLE_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__ROLE_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_UPDATED_DESC',
  USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_ID_DESC',
  USER_AGENT_ASC = 'USER_AGENT_ASC',
  USER_AGENT_DESC = 'USER_AGENT_DESC',
}

export interface UserAccountAccessHistory extends Node {
  __typename?: 'UserAccountAccessHistory';
  dateCreated: Scalars['Datetime']['output'];
  geoipCity?: Maybe<Scalars['String']['output']>;
  geoipCountryCodeAlpha2?: Maybe<Scalars['String']['output']>;
  geoipLatLng?: Maybe<Point>;
  geoipRegion?: Maybe<Scalars['String']['output']>;
  geoipTimezone?: Maybe<Scalars['String']['output']>;
  hostName?: Maybe<Scalars['String']['output']>;
  ipAddress: Scalars['InternetAddress']['output'];
  languages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  nodeId: Scalars['ID']['output'];
  referrer?: Maybe<Scalars['String']['output']>;
  remark?: Maybe<Scalars['String']['output']>;
  timezoneOffset?: Maybe<Scalars['Int']['output']>;
  userAccount: UserAccount;
  userAccountAccessHistoryId: Scalars['BigInt']['output'];
  userAccountId: Scalars['BigInt']['output'];
  userAgent?: Maybe<Scalars['String']['output']>;
}

export interface UserAccountAccessHistoryCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  geoipCity?: InputMaybe<Scalars['String']['input']>;
  geoipCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  geoipLatLng?: InputMaybe<PointInput>;
  geoipRegion?: InputMaybe<Scalars['String']['input']>;
  geoipTimezone?: InputMaybe<Scalars['String']['input']>;
  hostName?: InputMaybe<Scalars['String']['input']>;
  ipAddress?: InputMaybe<Scalars['InternetAddress']['input']>;
  languages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  referrer?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  timezoneOffset?: InputMaybe<Scalars['Int']['input']>;
  userAccountAccessHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
}

export interface UserAccountAccessHistoryFilter {
  and?: InputMaybe<Array<UserAccountAccessHistoryFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  geoipCity?: InputMaybe<StringFilter>;
  geoipCountryCodeAlpha2?: InputMaybe<StringFilter>;
  geoipRegion?: InputMaybe<StringFilter>;
  geoipTimezone?: InputMaybe<StringFilter>;
  hostName?: InputMaybe<StringFilter>;
  ipAddress?: InputMaybe<InternetAddressFilter>;
  languages?: InputMaybe<StringListFilter>;
  not?: InputMaybe<UserAccountAccessHistoryFilter>;
  or?: InputMaybe<Array<UserAccountAccessHistoryFilter>>;
  referrer?: InputMaybe<StringFilter>;
  remark?: InputMaybe<StringFilter>;
  timezoneOffset?: InputMaybe<IntFilter>;
  userAccount?: InputMaybe<UserAccountFilter>;
  userAccountAccessHistoryId?: InputMaybe<BigIntFilter>;
  userAccountId?: InputMaybe<BigIntFilter>;
  userAgent?: InputMaybe<StringFilter>;
}

export interface UserAccountAccessHistoryInput {
  geoipCity?: InputMaybe<Scalars['String']['input']>;
  geoipCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  geoipLatLng?: InputMaybe<PointInput>;
  geoipRegion?: InputMaybe<Scalars['String']['input']>;
  geoipTimezone?: InputMaybe<Scalars['String']['input']>;
  hostName?: InputMaybe<Scalars['String']['input']>;
  ipAddress: Scalars['InternetAddress']['input'];
  languages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  referrer?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  timezoneOffset?: InputMaybe<Scalars['Int']['input']>;
  userAccount?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInput>;
  userAccountAccessHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
}

export interface UserAccountAccessHistoryNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface UserAccountAccessHistoryNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface UserAccountAccessHistoryOnUserAccountAccessHistoryForUserAccountAccessHistoryUserAccountIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface UserAccountAccessHistoryOnUserAccountAccessHistoryForUserAccountAccessHistoryUserAccountIdFkeyUsingUserAccountAccessHistoryPkeyUpdate {
  patch: UpdateUserAccountAccessHistoryOnUserAccountAccessHistoryForUserAccountAccessHistoryUserAccountIdFkeyPatch;
  userAccountAccessHistoryId: Scalars['BigInt']['input'];
}

export interface UserAccountAccessHistoryPatch {
  geoipCity?: InputMaybe<Scalars['String']['input']>;
  geoipCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  geoipLatLng?: InputMaybe<PointInput>;
  geoipRegion?: InputMaybe<Scalars['String']['input']>;
  geoipTimezone?: InputMaybe<Scalars['String']['input']>;
  hostName?: InputMaybe<Scalars['String']['input']>;
  ipAddress?: InputMaybe<Scalars['InternetAddress']['input']>;
  languages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  referrer?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  timezoneOffset?: InputMaybe<Scalars['Int']['input']>;
  userAccount?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInput>;
  userAccountAccessHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
}

export interface UserAccountAccessHistoryUserAccountAccessHistoryPkeyConnect {
  userAccountAccessHistoryId: Scalars['BigInt']['input'];
}

export interface UserAccountAccessHistoryUserAccountAccessHistoryPkeyDelete {
  userAccountAccessHistoryId: Scalars['BigInt']['input'];
}

export interface UserAccountAccessHistoryUserAccountIdFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  create?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyUserAccountCreateInput>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnUserAccountAccessHistoryForUserAccountAccessHistoryUserAccountIdFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<UserAccountAccessHistoryOnUserAccountAccessHistoryForUserAccountAccessHistoryUserAccountIdFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnUserAccountAccessHistoryForUserAccountAccessHistoryUserAccountIdFkeyUsingUserAccountPkeyUpdate>;
}

export interface UserAccountAccessHistoryUserAccountIdFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<UserAccountAccessHistoryNodeIdConnect>>;
  connectByUserAccountAccessHistoryId?: InputMaybe<Array<UserAccountAccessHistoryUserAccountAccessHistoryPkeyConnect>>;
  create?: InputMaybe<Array<UserAccountAccessHistoryUserAccountIdFkeyUserAccountAccessHistoryCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<UserAccountAccessHistoryNodeIdDelete>>;
  deleteByUserAccountAccessHistoryId?: InputMaybe<Array<UserAccountAccessHistoryUserAccountAccessHistoryPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnUserAccountAccessHistoryForUserAccountAccessHistoryUserAccountIdFkeyNodeIdUpdate>>;
  updateByUserAccountAccessHistoryId?: InputMaybe<Array<UserAccountAccessHistoryOnUserAccountAccessHistoryForUserAccountAccessHistoryUserAccountIdFkeyUsingUserAccountAccessHistoryPkeyUpdate>>;
}

export interface UserAccountAccessHistoryUserAccountIdFkeyUserAccountAccessHistoryCreateInput {
  geoipCity?: InputMaybe<Scalars['String']['input']>;
  geoipCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  geoipLatLng?: InputMaybe<PointInput>;
  geoipRegion?: InputMaybe<Scalars['String']['input']>;
  geoipTimezone?: InputMaybe<Scalars['String']['input']>;
  hostName?: InputMaybe<Scalars['String']['input']>;
  ipAddress: Scalars['InternetAddress']['input'];
  languages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  referrer?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  timezoneOffset?: InputMaybe<Scalars['Int']['input']>;
  userAccount?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInput>;
  userAccountAccessHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
}

export interface UserAccountAccessHistoryUserAccountIdFkeyUserAccountCreateInput {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName: Scalars['String']['input'];
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress: Scalars['String']['input'];
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UserAccountCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UserAccountFilter {
  and?: InputMaybe<Array<UserAccountFilter>>;
  banksByUserCreated?: InputMaybe<UserAccountToManyBankFilter>;
  banksByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  banksByUserUpdated?: InputMaybe<UserAccountToManyBankFilter>;
  banksByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  businessUnitsByUserCreated?: InputMaybe<UserAccountToManyBusinessUnitFilter>;
  businessUnitsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  businessUnitsByUserUpdated?: InputMaybe<UserAccountToManyBusinessUnitFilter>;
  businessUnitsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  citiesByUserCreated?: InputMaybe<UserAccountToManyCityFilter>;
  citiesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  citiesByUserUpdated?: InputMaybe<UserAccountToManyCityFilter>;
  citiesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  companiesByUserCreated?: InputMaybe<UserAccountToManyCompanyFilter>;
  companiesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  companiesByUserUpdated?: InputMaybe<UserAccountToManyCompanyFilter>;
  companiesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  companyExternalAccountDisablesByUserCreated?: InputMaybe<UserAccountToManyCompanyExternalAccountDisableFilter>;
  companyExternalAccountDisablesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  companyGlobalHrsByUserCreated?: InputMaybe<UserAccountToManyCompanyGlobalHrFilter>;
  companyGlobalHrsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  companyGlobalHrsByUserUpdated?: InputMaybe<UserAccountToManyCompanyGlobalHrFilter>;
  companyGlobalHrsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  companyGroupsByUserCreated?: InputMaybe<UserAccountToManyCompanyGroupFilter>;
  companyGroupsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  companyGroupsByUserUpdated?: InputMaybe<UserAccountToManyCompanyGroupFilter>;
  companyGroupsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  companyLocalHrsByUserCreated?: InputMaybe<UserAccountToManyCompanyLocalHrFilter>;
  companyLocalHrsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  companyLocalHrsByUserUpdated?: InputMaybe<UserAccountToManyCompanyLocalHrFilter>;
  companyLocalHrsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  contractTypesByUserCreated?: InputMaybe<UserAccountToManyContractTypeFilter>;
  contractTypesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  contractTypesByUserUpdated?: InputMaybe<UserAccountToManyContractTypeFilter>;
  contractTypesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  countriesByUserCreated?: InputMaybe<UserAccountToManyCountryFilter>;
  countriesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  countriesByUserUpdated?: InputMaybe<UserAccountToManyCountryFilter>;
  countriesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  currenciesByUserCreated?: InputMaybe<UserAccountToManyCurrencyFilter>;
  currenciesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  currenciesByUserUpdated?: InputMaybe<UserAccountToManyCurrencyFilter>;
  currenciesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  departmentsByUserCreated?: InputMaybe<UserAccountToManyDepartmentFilter>;
  departmentsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  departmentsByUserUpdated?: InputMaybe<UserAccountToManyDepartmentFilter>;
  departmentsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<StringFilter>;
  divisionsByUserCreated?: InputMaybe<UserAccountToManyDivisionFilter>;
  divisionsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  divisionsByUserUpdated?: InputMaybe<UserAccountToManyDivisionFilter>;
  divisionsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  emailAddress?: InputMaybe<StringFilter>;
  emailDomainsByUserCreated?: InputMaybe<UserAccountToManyEmailDomainFilter>;
  emailDomainsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  emailDomainsByUserUpdated?: InputMaybe<UserAccountToManyEmailDomainFilter>;
  emailDomainsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeEmergencyContactsByUserCreated?: InputMaybe<UserAccountToManyEmployeeEmergencyContactFilter>;
  employeeEmergencyContactsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeEmergencyContactsByUserUpdated?: InputMaybe<UserAccountToManyEmployeeEmergencyContactFilter>;
  employeeEmergencyContactsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeFilesByUserCreated?: InputMaybe<UserAccountToManyEmployeeFileFilter>;
  employeeFilesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeFilesByUserUpdated?: InputMaybe<UserAccountToManyEmployeeFileFilter>;
  employeeFilesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeGroupsByUserCreated?: InputMaybe<UserAccountToManyEmployeeGroupFilter>;
  employeeGroupsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeGroupsByUserUpdated?: InputMaybe<UserAccountToManyEmployeeGroupFilter>;
  employeeGroupsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeIdTypesByUserCreated?: InputMaybe<UserAccountToManyEmployeeIdTypeFilter>;
  employeeIdTypesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeIdTypesByUserUpdated?: InputMaybe<UserAccountToManyEmployeeIdTypeFilter>;
  employeeIdTypesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeLevelsByUserCreated?: InputMaybe<UserAccountToManyEmployeeLevelFilter>;
  employeeLevelsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeLevelsByUserUpdated?: InputMaybe<UserAccountToManyEmployeeLevelFilter>;
  employeeLevelsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeListCustomFilterRolesByUserCreated?: InputMaybe<UserAccountToManyEmployeeListCustomFilterRoleFilter>;
  employeeListCustomFilterRolesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeListCustomFilterRolesByUserUpdated?: InputMaybe<UserAccountToManyEmployeeListCustomFilterRoleFilter>;
  employeeListCustomFilterRolesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeListCustomFiltersByUserCreated?: InputMaybe<UserAccountToManyEmployeeListCustomFilterFilter>;
  employeeListCustomFiltersByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeListCustomFiltersByUserUpdated?: InputMaybe<UserAccountToManyEmployeeListCustomFilterFilter>;
  employeeListCustomFiltersByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeResignationReasonsByUserCreated?: InputMaybe<UserAccountToManyEmployeeResignationReasonFilter>;
  employeeResignationReasonsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeResignationReasonsByUserUpdated?: InputMaybe<UserAccountToManyEmployeeResignationReasonFilter>;
  employeeResignationReasonsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeResignationTypesByUserCreated?: InputMaybe<UserAccountToManyEmployeeResignationTypeFilter>;
  employeeResignationTypesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeResignationTypesByUserUpdated?: InputMaybe<UserAccountToManyEmployeeResignationTypeFilter>;
  employeeResignationTypesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeStatusesByUserCreated?: InputMaybe<UserAccountToManyEmployeeStatusFilter>;
  employeeStatusesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeStatusesByUserUpdated?: InputMaybe<UserAccountToManyEmployeeStatusFilter>;
  employeeStatusesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeTpHistoriesByUserCreated?: InputMaybe<UserAccountToManyEmployeeTpHistoryFilter>;
  employeeTpHistoriesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeTpHistoriesByUserUpdated?: InputMaybe<UserAccountToManyEmployeeTpHistoryFilter>;
  employeeTpHistoriesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeesByUserCreated?: InputMaybe<UserAccountToManyEmployeeFilter>;
  employeesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeesByUserUpdated?: InputMaybe<UserAccountToManyEmployeeFilter>;
  employeesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  exportEmployeeStatusesByUserCreated?: InputMaybe<UserAccountToManyExportEmployeeStatusFilter>;
  exportEmployeeStatusesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  exportEmployeeStatusesByUserUpdated?: InputMaybe<UserAccountToManyExportEmployeeStatusFilter>;
  exportEmployeeStatusesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  externalAccountEmployeesByUserCreated?: InputMaybe<UserAccountToManyExternalAccountEmployeeFilter>;
  externalAccountEmployeesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  externalAccountEmployeesByUserUpdated?: InputMaybe<UserAccountToManyExternalAccountEmployeeFilter>;
  externalAccountEmployeesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  googleId?: InputMaybe<StringFilter>;
  googleProfilePictureUrl?: InputMaybe<StringFilter>;
  importEmployeeStatusesByUserCreated?: InputMaybe<UserAccountToManyImportEmployeeStatusFilter>;
  importEmployeeStatusesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  importEmployeeStatusesByUserUpdated?: InputMaybe<UserAccountToManyImportEmployeeStatusFilter>;
  importEmployeeStatusesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabled?: InputMaybe<BooleanFilter>;
  not?: InputMaybe<UserAccountFilter>;
  officeWeathersByUserCreated?: InputMaybe<UserAccountToManyOfficeWeatherFilter>;
  officeWeathersByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersByUserUpdated?: InputMaybe<UserAccountToManyOfficeWeatherFilter>;
  officeWeathersByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  officesByUserCreated?: InputMaybe<UserAccountToManyOfficeFilter>;
  officesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  officesByUserUpdated?: InputMaybe<UserAccountToManyOfficeFilter>;
  officesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<UserAccountFilter>>;
  performanceReviewEvaluationCompetenciesByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewEvaluationCompetencyFilter>;
  performanceReviewEvaluationCompetenciesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetenciesByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewEvaluationCompetencyFilter>;
  performanceReviewEvaluationCompetenciesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationEvaluatorsByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewEvaluationEvaluatorFilter>;
  performanceReviewEvaluationEvaluatorsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationEvaluatorsByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewEvaluationEvaluatorFilter>;
  performanceReviewEvaluationEvaluatorsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationFeedbacksByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewEvaluationFeedbackFilter>;
  performanceReviewEvaluationFeedbacksByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationFeedbacksByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewEvaluationFeedbackFilter>;
  performanceReviewEvaluationFeedbacksByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationKpiAssessmentsByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewEvaluationKpiAssessmentFilter>;
  performanceReviewEvaluationKpiAssessmentsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationKpiAssessmentsByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewEvaluationKpiAssessmentFilter>;
  performanceReviewEvaluationKpiAssessmentsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationKpiDescriptionsByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewEvaluationKpiDescriptionFilter>;
  performanceReviewEvaluationKpiDescriptionsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationKpiDescriptionsByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewEvaluationKpiDescriptionFilter>;
  performanceReviewEvaluationKpiDescriptionsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationKpiResultsByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewEvaluationKpiResultFilter>;
  performanceReviewEvaluationKpiResultsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationKpiResultsByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewEvaluationKpiResultFilter>;
  performanceReviewEvaluationKpiResultsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationKpiTargetsByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewEvaluationKpiTargetFilter>;
  performanceReviewEvaluationKpiTargetsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationKpiTargetsByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewEvaluationKpiTargetFilter>;
  performanceReviewEvaluationKpiTargetsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationOneOnOnesByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewEvaluationOneOnOneFilter>;
  performanceReviewEvaluationOneOnOnesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationQualitativeAssessmentsByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewEvaluationQualitativeAssessmentFilter>;
  performanceReviewEvaluationQualitativeAssessmentsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationQualitativeAssessmentsByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewEvaluationQualitativeAssessmentFilter>;
  performanceReviewEvaluationQualitativeAssessmentsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationQualitativeDescriptionsByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewEvaluationQualitativeDescriptionFilter>;
  performanceReviewEvaluationQualitativeDescriptionsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationQualitativeDescriptionsByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewEvaluationQualitativeDescriptionFilter>;
  performanceReviewEvaluationQualitativeDescriptionsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationValuesByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewEvaluationValueFilter>;
  performanceReviewEvaluationValuesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationValuesByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewEvaluationValueFilter>;
  performanceReviewEvaluationValuesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationsByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewEvaluationFilter>;
  performanceReviewEvaluationsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationsByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewEvaluationFilter>;
  performanceReviewEvaluationsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetenciesByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewMasterCompetencyFilter>;
  performanceReviewMasterCompetenciesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetenciesByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewMasterCompetencyFilter>;
  performanceReviewMasterCompetenciesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencyCommonDescriptionsByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewMasterCompetencyCommonDescriptionFilter>;
  performanceReviewMasterCompetencyCommonDescriptionsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencyCommonDescriptionsByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewMasterCompetencyCommonDescriptionFilter>;
  performanceReviewMasterCompetencyCommonDescriptionsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencyCommonsByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewMasterCompetencyCommonFilter>;
  performanceReviewMasterCompetencyCommonsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencyCommonsByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewMasterCompetencyCommonFilter>;
  performanceReviewMasterCompetencyCommonsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencyDetailDescriptionsByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewMasterCompetencyDetailDescriptionFilter>;
  performanceReviewMasterCompetencyDetailDescriptionsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencyDetailDescriptionsByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewMasterCompetencyDetailDescriptionFilter>;
  performanceReviewMasterCompetencyDetailDescriptionsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencyDetailsByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewMasterCompetencyDetailFilter>;
  performanceReviewMasterCompetencyDetailsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencyDetailsByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewMasterCompetencyDetailFilter>;
  performanceReviewMasterCompetencyDetailsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencyRolesByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewMasterCompetencyRoleFilter>;
  performanceReviewMasterCompetencyRolesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencyRolesByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewMasterCompetencyRoleFilter>;
  performanceReviewMasterCompetencyRolesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterDefaultsByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewMasterDefaultFilter>;
  performanceReviewMasterDefaultsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterEvaluationViewersByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewMasterEvaluationViewerFilter>;
  performanceReviewMasterEvaluationViewersByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterEvaluationViewersByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewMasterEvaluationViewerFilter>;
  performanceReviewMasterEvaluationViewersByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterExcludedEmployeesByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewMasterExcludedEmployeeFilter>;
  performanceReviewMasterExcludedEmployeesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterExcludedEmployeesByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewMasterExcludedEmployeeFilter>;
  performanceReviewMasterExcludedEmployeesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterOneOnOneDetailsByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewMasterOneOnOneDetailFilter>;
  performanceReviewMasterOneOnOneDetailsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterOneOnOneDetailsByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewMasterOneOnOneDetailFilter>;
  performanceReviewMasterOneOnOneDetailsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterOneOnOnesByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewMasterOneOnOneFilter>;
  performanceReviewMasterOneOnOnesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterOneOnOnesByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewMasterOneOnOneFilter>;
  performanceReviewMasterOneOnOnesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterRatingDescriptionsByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewMasterRatingDescriptionFilter>;
  performanceReviewMasterRatingDescriptionsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterRatingDescriptionsByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewMasterRatingDescriptionFilter>;
  performanceReviewMasterRatingDescriptionsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterRatingsByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewMasterRatingFilter>;
  performanceReviewMasterRatingsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterRatingsByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewMasterRatingFilter>;
  performanceReviewMasterRatingsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterUsagesByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewMasterUsageFilter>;
  performanceReviewMasterUsagesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterUsagesByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewMasterUsageFilter>;
  performanceReviewMasterUsagesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterValueDescriptionsByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewMasterValueDescriptionFilter>;
  performanceReviewMasterValueDescriptionsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterValueDescriptionsByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewMasterValueDescriptionFilter>;
  performanceReviewMasterValueDescriptionsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterValuesByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewMasterValueFilter>;
  performanceReviewMasterValuesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterValuesByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewMasterValueFilter>;
  performanceReviewMasterValuesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterWeightingDetailsByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewMasterWeightingDetailFilter>;
  performanceReviewMasterWeightingDetailsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterWeightingDetailsByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewMasterWeightingDetailFilter>;
  performanceReviewMasterWeightingDetailsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterWeightingsByUserCreated?: InputMaybe<UserAccountToManyPerformanceReviewMasterWeightingFilter>;
  performanceReviewMasterWeightingsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterWeightingsByUserUpdated?: InputMaybe<UserAccountToManyPerformanceReviewMasterWeightingFilter>;
  performanceReviewMasterWeightingsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  personalIdentityTypesByUserCreated?: InputMaybe<UserAccountToManyPersonalIdentityTypeFilter>;
  personalIdentityTypesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  personalIdentityTypesByUserUpdated?: InputMaybe<UserAccountToManyPersonalIdentityTypeFilter>;
  personalIdentityTypesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEnablesByUserCreated?: InputMaybe<UserAccountToManyProbationReviewEnableFilter>;
  probationReviewEnablesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEnablesByUserUpdated?: InputMaybe<UserAccountToManyProbationReviewEnableFilter>;
  probationReviewEnablesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationResultItemsByUserCreated?: InputMaybe<UserAccountToManyProbationReviewEvaluationResultItemFilter>;
  probationReviewEvaluationResultItemsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationResultItemsByUserUpdated?: InputMaybe<UserAccountToManyProbationReviewEvaluationResultItemFilter>;
  probationReviewEvaluationResultItemsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationResultScoreCompetenciesByUserCreated?: InputMaybe<UserAccountToManyProbationReviewEvaluationResultScoreCompetencyFilter>;
  probationReviewEvaluationResultScoreCompetenciesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationResultScoreCompetenciesByUserUpdated?: InputMaybe<UserAccountToManyProbationReviewEvaluationResultScoreCompetencyFilter>;
  probationReviewEvaluationResultScoreCompetenciesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationResultScoreValuesByUserCreated?: InputMaybe<UserAccountToManyProbationReviewEvaluationResultScoreValueFilter>;
  probationReviewEvaluationResultScoreValuesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationResultScoreValuesByUserUpdated?: InputMaybe<UserAccountToManyProbationReviewEvaluationResultScoreValueFilter>;
  probationReviewEvaluationResultScoreValuesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationReviewersByUserCreated?: InputMaybe<UserAccountToManyProbationReviewEvaluationReviewerFilter>;
  probationReviewEvaluationReviewersByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationReviewersByUserUpdated?: InputMaybe<UserAccountToManyProbationReviewEvaluationReviewerFilter>;
  probationReviewEvaluationReviewersByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationsByUserCreated?: InputMaybe<UserAccountToManyProbationReviewEvaluationFilter>;
  probationReviewEvaluationsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationsByUserUpdated?: InputMaybe<UserAccountToManyProbationReviewEvaluationFilter>;
  probationReviewEvaluationsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewMasterItemDescriptionsByUserCreated?: InputMaybe<UserAccountToManyProbationReviewMasterItemDescriptionFilter>;
  probationReviewMasterItemDescriptionsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewMasterItemDescriptionsByUserUpdated?: InputMaybe<UserAccountToManyProbationReviewMasterItemDescriptionFilter>;
  probationReviewMasterItemDescriptionsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewMasterItemsByUserCreated?: InputMaybe<UserAccountToManyProbationReviewMasterItemFilter>;
  probationReviewMasterItemsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewMasterItemsByUserUpdated?: InputMaybe<UserAccountToManyProbationReviewMasterItemFilter>;
  probationReviewMasterItemsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationBusinessUnitDivisionDepartmentsByUserCreated?: InputMaybe<UserAccountToManyRelationBusinessUnitDivisionDepartmentFilter>;
  relationBusinessUnitDivisionDepartmentsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationBusinessUnitDivisionDepartmentsByUserUpdated?: InputMaybe<UserAccountToManyRelationBusinessUnitDivisionDepartmentFilter>;
  relationBusinessUnitDivisionDepartmentsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationBusinessUnitDivisionsByUserCreated?: InputMaybe<UserAccountToManyRelationBusinessUnitDivisionFilter>;
  relationBusinessUnitDivisionsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationBusinessUnitDivisionsByUserUpdated?: InputMaybe<UserAccountToManyRelationBusinessUnitDivisionFilter>;
  relationBusinessUnitDivisionsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationCityCompaniesByUserCreated?: InputMaybe<UserAccountToManyRelationCityCompanyFilter>;
  relationCityCompaniesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationCityCompaniesByUserUpdated?: InputMaybe<UserAccountToManyRelationCityCompanyFilter>;
  relationCityCompaniesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationCompanyBusinessUnitsByUserCreated?: InputMaybe<UserAccountToManyRelationCompanyBusinessUnitFilter>;
  relationCompanyBusinessUnitsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationCompanyBusinessUnitsByUserUpdated?: InputMaybe<UserAccountToManyRelationCompanyBusinessUnitFilter>;
  relationCompanyBusinessUnitsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationCompanyGroupEmailDomainsByUserCreated?: InputMaybe<UserAccountToManyRelationCompanyGroupEmailDomainFilter>;
  relationCompanyGroupEmailDomainsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationCompanyGroupEmailDomainsByUserUpdated?: InputMaybe<UserAccountToManyRelationCompanyGroupEmailDomainFilter>;
  relationCompanyGroupEmailDomainsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationCompanyOfficesByUserCreated?: InputMaybe<UserAccountToManyRelationCompanyOfficeFilter>;
  relationCompanyOfficesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationCompanyOfficesByUserUpdated?: InputMaybe<UserAccountToManyRelationCompanyOfficeFilter>;
  relationCompanyOfficesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationCurrencyCountriesByUserCreated?: InputMaybe<UserAccountToManyRelationCurrencyCountryFilter>;
  relationCurrencyCountriesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationCurrencyCountriesByUserUpdated?: InputMaybe<UserAccountToManyRelationCurrencyCountryFilter>;
  relationCurrencyCountriesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationEmergencyContactsByUserCreated?: InputMaybe<UserAccountToManyRelationEmergencyContactFilter>;
  relationEmergencyContactsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationEmergencyContactsByUserUpdated?: InputMaybe<UserAccountToManyRelationEmergencyContactFilter>;
  relationEmergencyContactsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationEmployeeUserAccount?: InputMaybe<RelationEmployeeUserAccountFilter>;
  relationEmployeeUserAccountExists?: InputMaybe<Scalars['Boolean']['input']>;
  relationEmployeeUserAccountsByUserCreated?: InputMaybe<UserAccountToManyRelationEmployeeUserAccountFilter>;
  relationEmployeeUserAccountsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  relationEmployeeUserAccountsByUserUpdated?: InputMaybe<UserAccountToManyRelationEmployeeUserAccountFilter>;
  relationEmployeeUserAccountsByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<StringFilter>;
  role?: InputMaybe<StringFilter>;
  tableAuditsByUserCreated?: InputMaybe<UserAccountToManyTableAuditFilter>;
  tableAuditsByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountToManyUserAccountAccessHistoryFilter>;
  userAccountAccessHistoriesExist?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountId?: InputMaybe<BigIntFilter>;
  userAccountPins?: InputMaybe<UserAccountToManyUserAccountPinFilter>;
  userAccountPinsExist?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userForceLogouts?: InputMaybe<UserAccountToManyUserForceLogoutFilter>;
  userForceLogoutsExist?: InputMaybe<Scalars['Boolean']['input']>;
  userHrManagingCompanies?: InputMaybe<UserAccountToManyUserHrManagingCompanyFilter>;
  userHrManagingCompaniesByUserCreated?: InputMaybe<UserAccountToManyUserHrManagingCompanyFilter>;
  userHrManagingCompaniesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  userHrManagingCompaniesByUserUpdated?: InputMaybe<UserAccountToManyUserHrManagingCompanyFilter>;
  userHrManagingCompaniesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  userHrManagingCompaniesExist?: InputMaybe<Scalars['Boolean']['input']>;
  userRoleByRole?: InputMaybe<UserRoleFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
  worldPlaceWeathersByUserCreated?: InputMaybe<UserAccountToManyWorldPlaceWeatherFilter>;
  worldPlaceWeathersByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  worldPlaceWeathersByUserUpdated?: InputMaybe<UserAccountToManyWorldPlaceWeatherFilter>;
  worldPlaceWeathersByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  worldPlacesByUserCreated?: InputMaybe<UserAccountToManyWorldPlaceFilter>;
  worldPlacesByUserCreatedExist?: InputMaybe<Scalars['Boolean']['input']>;
  worldPlacesByUserUpdated?: InputMaybe<UserAccountToManyWorldPlaceFilter>;
  worldPlacesByUserUpdatedExist?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface UserAccountInput {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName: Scalars['String']['input'];
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress: Scalars['String']['input'];
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UserAccountNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface UserAccountNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface UserAccountOnBankForBankUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: BankPatch;
}

export interface UserAccountOnBankForBankUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: BankPatch;
}

export interface UserAccountOnBankForBankUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnBankForBankUserUpdatedFkeyPatch;
}

export interface UserAccountOnBankForBankUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnBankForBankUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnBusinessUnitForBusinessUnitUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: BusinessUnitPatch;
}

export interface UserAccountOnBusinessUnitForBusinessUnitUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: BusinessUnitPatch;
}

export interface UserAccountOnBusinessUnitForBusinessUnitUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnBusinessUnitForBusinessUnitUserUpdatedFkeyPatch;
}

export interface UserAccountOnBusinessUnitForBusinessUnitUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnBusinessUnitForBusinessUnitUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnCityForCityUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CityPatch;
}

export interface UserAccountOnCityForCityUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CityPatch;
}

export interface UserAccountOnCityForCityUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnCityForCityUserUpdatedFkeyPatch;
}

export interface UserAccountOnCityForCityUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnCityForCityUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnCompanyExternalAccountDisableForCompanyExternalAccountDisableUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyExternalAccountDisablePatch;
}

export interface UserAccountOnCompanyExternalAccountDisableForCompanyExternalAccountDisableUserCreatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnCompanyExternalAccountDisableForCompanyExternalAccountDisableUserCreatedFkeyPatch;
}

export interface UserAccountOnCompanyExternalAccountDisableForCompanyExternalAccountDisableUserCreatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnCompanyExternalAccountDisableForCompanyExternalAccountDisableUserCreatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnCompanyForCompanyUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyPatch;
}

export interface UserAccountOnCompanyForCompanyUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyPatch;
}

export interface UserAccountOnCompanyForCompanyUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnCompanyForCompanyUserUpdatedFkeyPatch;
}

export interface UserAccountOnCompanyForCompanyUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnCompanyForCompanyUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnCompanyGlobalHrForCompanyGlobalHrUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyGlobalHrPatch;
}

export interface UserAccountOnCompanyGlobalHrForCompanyGlobalHrUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyGlobalHrPatch;
}

export interface UserAccountOnCompanyGlobalHrForCompanyGlobalHrUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnCompanyGlobalHrForCompanyGlobalHrUserUpdatedFkeyPatch;
}

export interface UserAccountOnCompanyGlobalHrForCompanyGlobalHrUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnCompanyGlobalHrForCompanyGlobalHrUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnCompanyGroupForCompanyGroupUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyGroupPatch;
}

export interface UserAccountOnCompanyGroupForCompanyGroupUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyGroupPatch;
}

export interface UserAccountOnCompanyGroupForCompanyGroupUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnCompanyGroupForCompanyGroupUserUpdatedFkeyPatch;
}

export interface UserAccountOnCompanyGroupForCompanyGroupUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnCompanyGroupForCompanyGroupUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnCompanyLocalHrForCompanyLocalHrUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyLocalHrPatch;
}

export interface UserAccountOnCompanyLocalHrForCompanyLocalHrUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyLocalHrPatch;
}

export interface UserAccountOnCompanyLocalHrForCompanyLocalHrUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnCompanyLocalHrForCompanyLocalHrUserUpdatedFkeyPatch;
}

export interface UserAccountOnCompanyLocalHrForCompanyLocalHrUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnCompanyLocalHrForCompanyLocalHrUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnContractTypeForContractTypeUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ContractTypePatch;
}

export interface UserAccountOnContractTypeForContractTypeUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ContractTypePatch;
}

export interface UserAccountOnContractTypeForContractTypeUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnContractTypeForContractTypeUserUpdatedFkeyPatch;
}

export interface UserAccountOnContractTypeForContractTypeUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnContractTypeForContractTypeUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnCountryForCountryUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CountryPatch;
}

export interface UserAccountOnCountryForCountryUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CountryPatch;
}

export interface UserAccountOnCountryForCountryUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnCountryForCountryUserUpdatedFkeyPatch;
}

export interface UserAccountOnCountryForCountryUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnCountryForCountryUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnCurrencyForCurrencyUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CurrencyPatch;
}

export interface UserAccountOnCurrencyForCurrencyUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CurrencyPatch;
}

export interface UserAccountOnCurrencyForCurrencyUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnCurrencyForCurrencyUserUpdatedFkeyPatch;
}

export interface UserAccountOnCurrencyForCurrencyUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnCurrencyForCurrencyUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnDepartmentForDepartmentUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: DepartmentPatch;
}

export interface UserAccountOnDepartmentForDepartmentUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: DepartmentPatch;
}

export interface UserAccountOnDepartmentForDepartmentUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnDepartmentForDepartmentUserUpdatedFkeyPatch;
}

export interface UserAccountOnDepartmentForDepartmentUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnDepartmentForDepartmentUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnDivisionForDivisionUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: DivisionPatch;
}

export interface UserAccountOnDivisionForDivisionUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: DivisionPatch;
}

export interface UserAccountOnDivisionForDivisionUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnDivisionForDivisionUserUpdatedFkeyPatch;
}

export interface UserAccountOnDivisionForDivisionUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnDivisionForDivisionUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnEmailDomainForEmailDomainUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmailDomainPatch;
}

export interface UserAccountOnEmailDomainForEmailDomainUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmailDomainPatch;
}

export interface UserAccountOnEmailDomainForEmailDomainUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnEmailDomainForEmailDomainUserUpdatedFkeyPatch;
}

export interface UserAccountOnEmailDomainForEmailDomainUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnEmailDomainForEmailDomainUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnEmployeeEmergencyContactForEmployeeEmergencyContactUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeEmergencyContactPatch;
}

export interface UserAccountOnEmployeeEmergencyContactForEmployeeEmergencyContactUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeEmergencyContactPatch;
}

export interface UserAccountOnEmployeeEmergencyContactForEmployeeEmergencyContactUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnEmployeeEmergencyContactForEmployeeEmergencyContactUserUpdatedFkeyPatch;
}

export interface UserAccountOnEmployeeEmergencyContactForEmployeeEmergencyContactUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnEmployeeEmergencyContactForEmployeeEmergencyContactUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnEmployeeFileForEmployeeFileUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeFilePatch;
}

export interface UserAccountOnEmployeeFileForEmployeeFileUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeFilePatch;
}

export interface UserAccountOnEmployeeFileForEmployeeFileUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnEmployeeFileForEmployeeFileUserUpdatedFkeyPatch;
}

export interface UserAccountOnEmployeeFileForEmployeeFileUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnEmployeeFileForEmployeeFileUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnEmployeeForEmployeeUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface UserAccountOnEmployeeForEmployeeUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeePatch;
}

export interface UserAccountOnEmployeeForEmployeeUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnEmployeeForEmployeeUserUpdatedFkeyPatch;
}

export interface UserAccountOnEmployeeForEmployeeUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnEmployeeForEmployeeUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnEmployeeGroupForEmployeeGroupUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeGroupPatch;
}

export interface UserAccountOnEmployeeGroupForEmployeeGroupUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeGroupPatch;
}

export interface UserAccountOnEmployeeGroupForEmployeeGroupUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnEmployeeGroupForEmployeeGroupUserUpdatedFkeyPatch;
}

export interface UserAccountOnEmployeeGroupForEmployeeGroupUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnEmployeeGroupForEmployeeGroupUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnEmployeeIdTypeForEmployeeIdTypeUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeIdTypePatch;
}

export interface UserAccountOnEmployeeIdTypeForEmployeeIdTypeUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeIdTypePatch;
}

export interface UserAccountOnEmployeeIdTypeForEmployeeIdTypeUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnEmployeeIdTypeForEmployeeIdTypeUserUpdatedFkeyPatch;
}

export interface UserAccountOnEmployeeIdTypeForEmployeeIdTypeUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnEmployeeIdTypeForEmployeeIdTypeUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnEmployeeLevelForEmployeeLevelUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeLevelPatch;
}

export interface UserAccountOnEmployeeLevelForEmployeeLevelUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeLevelPatch;
}

export interface UserAccountOnEmployeeLevelForEmployeeLevelUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnEmployeeLevelForEmployeeLevelUserUpdatedFkeyPatch;
}

export interface UserAccountOnEmployeeLevelForEmployeeLevelUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnEmployeeLevelForEmployeeLevelUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnEmployeeListCustomFilterForEmployeeListCustomFilterUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeListCustomFilterPatch;
}

export interface UserAccountOnEmployeeListCustomFilterForEmployeeListCustomFilterUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeListCustomFilterPatch;
}

export interface UserAccountOnEmployeeListCustomFilterForEmployeeListCustomFilterUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnEmployeeListCustomFilterForEmployeeListCustomFilterUserUpdatedFkeyPatch;
}

export interface UserAccountOnEmployeeListCustomFilterForEmployeeListCustomFilterUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnEmployeeListCustomFilterForEmployeeListCustomFilterUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeListCustomFilterRolePatch;
}

export interface UserAccountOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeListCustomFilterRolePatch;
}

export interface UserAccountOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserUpdatedFkeyPatch;
}

export interface UserAccountOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnEmployeeResignationReasonForEmployeeResignationReasonUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeResignationReasonPatch;
}

export interface UserAccountOnEmployeeResignationReasonForEmployeeResignationReasonUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeResignationReasonPatch;
}

export interface UserAccountOnEmployeeResignationReasonForEmployeeResignationReasonUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnEmployeeResignationReasonForEmployeeResignationReasonUserUpdatedFkeyPatch;
}

export interface UserAccountOnEmployeeResignationReasonForEmployeeResignationReasonUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnEmployeeResignationReasonForEmployeeResignationReasonUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnEmployeeResignationTypeForEmployeeResignationTypeUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeResignationTypePatch;
}

export interface UserAccountOnEmployeeResignationTypeForEmployeeResignationTypeUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeResignationTypePatch;
}

export interface UserAccountOnEmployeeResignationTypeForEmployeeResignationTypeUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnEmployeeResignationTypeForEmployeeResignationTypeUserUpdatedFkeyPatch;
}

export interface UserAccountOnEmployeeResignationTypeForEmployeeResignationTypeUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnEmployeeResignationTypeForEmployeeResignationTypeUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnEmployeeStatusForEmployeeStatusUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeStatusPatch;
}

export interface UserAccountOnEmployeeStatusForEmployeeStatusUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeStatusPatch;
}

export interface UserAccountOnEmployeeStatusForEmployeeStatusUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnEmployeeStatusForEmployeeStatusUserUpdatedFkeyPatch;
}

export interface UserAccountOnEmployeeStatusForEmployeeStatusUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnEmployeeStatusForEmployeeStatusUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnEmployeeTpHistoryForEmployeeTpHistoryUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeTpHistoryPatch;
}

export interface UserAccountOnEmployeeTpHistoryForEmployeeTpHistoryUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeTpHistoryPatch;
}

export interface UserAccountOnEmployeeTpHistoryForEmployeeTpHistoryUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnEmployeeTpHistoryForEmployeeTpHistoryUserUpdatedFkeyPatch;
}

export interface UserAccountOnEmployeeTpHistoryForEmployeeTpHistoryUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnEmployeeTpHistoryForEmployeeTpHistoryUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnExportEmployeeStatusForExportEmployeeStatusUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ExportEmployeeStatusPatch;
}

export interface UserAccountOnExportEmployeeStatusForExportEmployeeStatusUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ExportEmployeeStatusPatch;
}

export interface UserAccountOnExportEmployeeStatusForExportEmployeeStatusUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnExportEmployeeStatusForExportEmployeeStatusUserUpdatedFkeyPatch;
}

export interface UserAccountOnExportEmployeeStatusForExportEmployeeStatusUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnExportEmployeeStatusForExportEmployeeStatusUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnExternalAccountEmployeeForExternalAccountEmployeeUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ExternalAccountEmployeePatch;
}

export interface UserAccountOnExternalAccountEmployeeForExternalAccountEmployeeUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ExternalAccountEmployeePatch;
}

export interface UserAccountOnExternalAccountEmployeeForExternalAccountEmployeeUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnExternalAccountEmployeeForExternalAccountEmployeeUserUpdatedFkeyPatch;
}

export interface UserAccountOnExternalAccountEmployeeForExternalAccountEmployeeUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnExternalAccountEmployeeForExternalAccountEmployeeUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnImportEmployeeStatusForImportEmployeeStatusUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ImportEmployeeStatusPatch;
}

export interface UserAccountOnImportEmployeeStatusForImportEmployeeStatusUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ImportEmployeeStatusPatch;
}

export interface UserAccountOnImportEmployeeStatusForImportEmployeeStatusUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnImportEmployeeStatusForImportEmployeeStatusUserUpdatedFkeyPatch;
}

export interface UserAccountOnImportEmployeeStatusForImportEmployeeStatusUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnImportEmployeeStatusForImportEmployeeStatusUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnOfficeForOfficeUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: OfficePatch;
}

export interface UserAccountOnOfficeForOfficeUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: OfficePatch;
}

export interface UserAccountOnOfficeForOfficeUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnOfficeForOfficeUserUpdatedFkeyPatch;
}

export interface UserAccountOnOfficeForOfficeUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnOfficeForOfficeUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnOfficeWeatherForOfficeWeatherUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: OfficeWeatherPatch;
}

export interface UserAccountOnOfficeWeatherForOfficeWeatherUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: OfficeWeatherPatch;
}

export interface UserAccountOnOfficeWeatherForOfficeWeatherUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnOfficeWeatherForOfficeWeatherUserUpdatedFkeyPatch;
}

export interface UserAccountOnOfficeWeatherForOfficeWeatherUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnOfficeWeatherForOfficeWeatherUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationCompetencyPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationCompetencyPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationEvaluatorPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationEvaluatorPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationFeedbackPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationFeedbackPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewEvaluationForPerformanceReviewEvaluationUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationForPerformanceReviewEvaluationUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationForPerformanceReviewEvaluationUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewEvaluationForPerformanceReviewEvaluationUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationForPerformanceReviewEvaluationUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewEvaluationForPerformanceReviewEvaluationUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationKpiAssessmentPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationKpiAssessmentPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationKpiDescriptionPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationKpiDescriptionPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationKpiResultPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationKpiResultPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationKpiTargetPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationKpiTargetPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationOneOnOneUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationOneOnOnePatch;
}

export interface UserAccountOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationOneOnOneUserCreatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationOneOnOneUserCreatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationOneOnOneUserCreatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationOneOnOneUserCreatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationQualitativeAssessmentPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationQualitativeAssessmentPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationQualitativeDescriptionPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationQualitativeDescriptionPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationValuePatch;
}

export interface UserAccountOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewEvaluationValuePatch;
}

export interface UserAccountOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyCommonDescriptionPatch;
}

export interface UserAccountOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyCommonDescriptionPatch;
}

export interface UserAccountOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewMasterCompetencyCommonForPerformanceReviewMasterCompetencyCommonUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyCommonPatch;
}

export interface UserAccountOnPerformanceReviewMasterCompetencyCommonForPerformanceReviewMasterCompetencyCommonUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyCommonPatch;
}

export interface UserAccountOnPerformanceReviewMasterCompetencyCommonForPerformanceReviewMasterCompetencyCommonUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewMasterCompetencyCommonForPerformanceReviewMasterCompetencyCommonUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewMasterCompetencyCommonForPerformanceReviewMasterCompetencyCommonUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewMasterCompetencyCommonForPerformanceReviewMasterCompetencyCommonUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyDetailDescriptionPatch;
}

export interface UserAccountOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyDetailDescriptionPatch;
}

export interface UserAccountOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyDetailPatch;
}

export interface UserAccountOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyDetailPatch;
}

export interface UserAccountOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCompetencyUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyPatch;
}

export interface UserAccountOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCompetencyUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyPatch;
}

export interface UserAccountOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCompetencyUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCompetencyUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCompetencyUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCompetencyUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyRolePatch;
}

export interface UserAccountOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterCompetencyRolePatch;
}

export interface UserAccountOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewMasterDefaultForPerformanceReviewMasterDefaultUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterDefaultPatch;
}

export interface UserAccountOnPerformanceReviewMasterDefaultForPerformanceReviewMasterDefaultUserCreatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewMasterDefaultForPerformanceReviewMasterDefaultUserCreatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewMasterDefaultForPerformanceReviewMasterDefaultUserCreatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewMasterDefaultForPerformanceReviewMasterDefaultUserCreatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterEvaluationViewerPatch;
}

export interface UserAccountOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterEvaluationViewerPatch;
}

export interface UserAccountOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterExcludedEmployeePatch;
}

export interface UserAccountOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterExcludedEmployeePatch;
}

export interface UserAccountOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOneOnOneDetailUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterOneOnOneDetailPatch;
}

export interface UserAccountOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterOneOnOneDetailPatch;
}

export interface UserAccountOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewMasterOneOnOneForPerformanceReviewMasterOneOnOneUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterOneOnOnePatch;
}

export interface UserAccountOnPerformanceReviewMasterOneOnOneForPerformanceReviewMasterOneOnOneUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterOneOnOnePatch;
}

export interface UserAccountOnPerformanceReviewMasterOneOnOneForPerformanceReviewMasterOneOnOneUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewMasterOneOnOneForPerformanceReviewMasterOneOnOneUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewMasterOneOnOneForPerformanceReviewMasterOneOnOneUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewMasterOneOnOneForPerformanceReviewMasterOneOnOneUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterRatingDescriptionPatch;
}

export interface UserAccountOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterRatingDescriptionPatch;
}

export interface UserAccountOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewMasterRatingForPerformanceReviewMasterRatingUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterRatingPatch;
}

export interface UserAccountOnPerformanceReviewMasterRatingForPerformanceReviewMasterRatingUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterRatingPatch;
}

export interface UserAccountOnPerformanceReviewMasterRatingForPerformanceReviewMasterRatingUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewMasterRatingForPerformanceReviewMasterRatingUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewMasterRatingForPerformanceReviewMasterRatingUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewMasterRatingForPerformanceReviewMasterRatingUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterUsagePatch;
}

export interface UserAccountOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterUsagePatch;
}

export interface UserAccountOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterValueDescriptionPatch;
}

export interface UserAccountOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterValueDescriptionPatch;
}

export interface UserAccountOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewMasterValueForPerformanceReviewMasterValueUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterValuePatch;
}

export interface UserAccountOnPerformanceReviewMasterValueForPerformanceReviewMasterValueUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterValuePatch;
}

export interface UserAccountOnPerformanceReviewMasterValueForPerformanceReviewMasterValueUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewMasterValueForPerformanceReviewMasterValueUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewMasterValueForPerformanceReviewMasterValueUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewMasterValueForPerformanceReviewMasterValueUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterWeightingDetailPatch;
}

export interface UserAccountOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterWeightingDetailPatch;
}

export interface UserAccountOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterWeightingPatch;
}

export interface UserAccountOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PerformanceReviewMasterWeightingPatch;
}

export interface UserAccountOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserUpdatedFkeyPatch;
}

export interface UserAccountOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnPersonalIdentityTypeForPersonalIdentityTypeUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PersonalIdentityTypePatch;
}

export interface UserAccountOnPersonalIdentityTypeForPersonalIdentityTypeUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: PersonalIdentityTypePatch;
}

export interface UserAccountOnPersonalIdentityTypeForPersonalIdentityTypeUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnPersonalIdentityTypeForPersonalIdentityTypeUserUpdatedFkeyPatch;
}

export interface UserAccountOnPersonalIdentityTypeForPersonalIdentityTypeUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnPersonalIdentityTypeForPersonalIdentityTypeUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnProbationReviewEnableForProbationReviewEnableUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEnablePatch;
}

export interface UserAccountOnProbationReviewEnableForProbationReviewEnableUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEnablePatch;
}

export interface UserAccountOnProbationReviewEnableForProbationReviewEnableUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnProbationReviewEnableForProbationReviewEnableUserUpdatedFkeyPatch;
}

export interface UserAccountOnProbationReviewEnableForProbationReviewEnableUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnProbationReviewEnableForProbationReviewEnableUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnProbationReviewEvaluationForProbationReviewEvaluationUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationPatch;
}

export interface UserAccountOnProbationReviewEvaluationForProbationReviewEvaluationUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationPatch;
}

export interface UserAccountOnProbationReviewEvaluationForProbationReviewEvaluationUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnProbationReviewEvaluationForProbationReviewEvaluationUserUpdatedFkeyPatch;
}

export interface UserAccountOnProbationReviewEvaluationForProbationReviewEvaluationUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnProbationReviewEvaluationForProbationReviewEvaluationUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationResultItemPatch;
}

export interface UserAccountOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationResultItemPatch;
}

export interface UserAccountOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserUpdatedFkeyPatch;
}

export interface UserAccountOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationResultScoreCompetencyPatch;
}

export interface UserAccountOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationResultScoreCompetencyPatch;
}

export interface UserAccountOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserUpdatedFkeyPatch;
}

export interface UserAccountOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationResultScoreValuePatch;
}

export interface UserAccountOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationResultScoreValuePatch;
}

export interface UserAccountOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserUpdatedFkeyPatch;
}

export interface UserAccountOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationReviewerPatch;
}

export interface UserAccountOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewEvaluationReviewerPatch;
}

export interface UserAccountOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerUserUpdatedFkeyPatch;
}

export interface UserAccountOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewMasterItemDescriptionPatch;
}

export interface UserAccountOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewMasterItemDescriptionPatch;
}

export interface UserAccountOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserUpdatedFkeyPatch;
}

export interface UserAccountOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnProbationReviewMasterItemForProbationReviewMasterItemUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewMasterItemPatch;
}

export interface UserAccountOnProbationReviewMasterItemForProbationReviewMasterItemUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: ProbationReviewMasterItemPatch;
}

export interface UserAccountOnProbationReviewMasterItemForProbationReviewMasterItemUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnProbationReviewMasterItemForProbationReviewMasterItemUserUpdatedFkeyPatch;
}

export interface UserAccountOnProbationReviewMasterItemForProbationReviewMasterItemUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnProbationReviewMasterItemForProbationReviewMasterItemUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationBusinessUnitDivisionDepartmentPatch;
}

export interface UserAccountOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationBusinessUnitDivisionDepartmentPatch;
}

export interface UserAccountOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentUserUpdatedFkeyPatch;
}

export interface UserAccountOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationBusinessUnitDivisionPatch;
}

export interface UserAccountOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationBusinessUnitDivisionPatch;
}

export interface UserAccountOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionUserUpdatedFkeyPatch;
}

export interface UserAccountOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnRelationCityCompanyForRelationCityCompanyUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationCityCompanyPatch;
}

export interface UserAccountOnRelationCityCompanyForRelationCityCompanyUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationCityCompanyPatch;
}

export interface UserAccountOnRelationCityCompanyForRelationCityCompanyUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnRelationCityCompanyForRelationCityCompanyUserUpdatedFkeyPatch;
}

export interface UserAccountOnRelationCityCompanyForRelationCityCompanyUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnRelationCityCompanyForRelationCityCompanyUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationCompanyBusinessUnitPatch;
}

export interface UserAccountOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationCompanyBusinessUnitPatch;
}

export interface UserAccountOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitUserUpdatedFkeyPatch;
}

export interface UserAccountOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationCompanyGroupEmailDomainPatch;
}

export interface UserAccountOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationCompanyGroupEmailDomainPatch;
}

export interface UserAccountOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainUserUpdatedFkeyPatch;
}

export interface UserAccountOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnRelationCompanyOfficeForRelationCompanyOfficeUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationCompanyOfficePatch;
}

export interface UserAccountOnRelationCompanyOfficeForRelationCompanyOfficeUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationCompanyOfficePatch;
}

export interface UserAccountOnRelationCompanyOfficeForRelationCompanyOfficeUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnRelationCompanyOfficeForRelationCompanyOfficeUserUpdatedFkeyPatch;
}

export interface UserAccountOnRelationCompanyOfficeForRelationCompanyOfficeUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnRelationCompanyOfficeForRelationCompanyOfficeUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnRelationCurrencyCountryForRelationCurrencyCountryUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationCurrencyCountryPatch;
}

export interface UserAccountOnRelationCurrencyCountryForRelationCurrencyCountryUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationCurrencyCountryPatch;
}

export interface UserAccountOnRelationCurrencyCountryForRelationCurrencyCountryUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnRelationCurrencyCountryForRelationCurrencyCountryUserUpdatedFkeyPatch;
}

export interface UserAccountOnRelationCurrencyCountryForRelationCurrencyCountryUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnRelationCurrencyCountryForRelationCurrencyCountryUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnRelationEmergencyContactForRelationEmergencyContactUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationEmergencyContactPatch;
}

export interface UserAccountOnRelationEmergencyContactForRelationEmergencyContactUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationEmergencyContactPatch;
}

export interface UserAccountOnRelationEmergencyContactForRelationEmergencyContactUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnRelationEmergencyContactForRelationEmergencyContactUserUpdatedFkeyPatch;
}

export interface UserAccountOnRelationEmergencyContactForRelationEmergencyContactUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnRelationEmergencyContactForRelationEmergencyContactUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserAccountIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationEmployeeUserAccountPatch;
}

export interface UserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationEmployeeUserAccountPatch;
}

export interface UserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: RelationEmployeeUserAccountPatch;
}

export interface UserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserUpdatedFkeyPatch;
}

export interface UserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnUserAccountAccessHistoryForUserAccountAccessHistoryUserAccountIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountAccessHistoryPatch;
}

export interface UserAccountOnUserAccountAccessHistoryForUserAccountAccessHistoryUserAccountIdFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnUserAccountAccessHistoryForUserAccountAccessHistoryUserAccountIdFkeyPatch;
}

export interface UserAccountOnUserAccountAccessHistoryForUserAccountAccessHistoryUserAccountIdFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnUserAccountAccessHistoryForUserAccountAccessHistoryUserAccountIdFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnUserAccountForUserAccountRoleFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnUserAccountForUserAccountRoleFkeyPatch;
}

export interface UserAccountOnUserAccountForUserAccountRoleFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnUserAccountForUserAccountRoleFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnUserAccountForUserAccountUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface UserAccountOnUserAccountForUserAccountUserCreatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnUserAccountForUserAccountUserCreatedFkeyPatch;
}

export interface UserAccountOnUserAccountForUserAccountUserCreatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnUserAccountForUserAccountUserCreatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnUserAccountForUserAccountUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface UserAccountOnUserAccountForUserAccountUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnUserAccountForUserAccountUserUpdatedFkeyPatch;
}

export interface UserAccountOnUserAccountForUserAccountUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnUserAccountForUserAccountUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnUserAccountPinForUserAccountPinUserAccountIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPinPatch;
}

export interface UserAccountOnUserAccountPinForUserAccountPinUserAccountIdFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnUserAccountPinForUserAccountPinUserAccountIdFkeyPatch;
}

export interface UserAccountOnUserAccountPinForUserAccountPinUserAccountIdFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnUserAccountPinForUserAccountPinUserAccountIdFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnUserForceLogoutForUserForceLogoutUserAccountIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserForceLogoutPatch;
}

export interface UserAccountOnUserHrManagingCompanyForUserHrManagingCompanyUserAccountIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserHrManagingCompanyPatch;
}

export interface UserAccountOnUserHrManagingCompanyForUserHrManagingCompanyUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserHrManagingCompanyPatch;
}

export interface UserAccountOnUserHrManagingCompanyForUserHrManagingCompanyUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserHrManagingCompanyPatch;
}

export interface UserAccountOnUserHrManagingCompanyForUserHrManagingCompanyUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnUserHrManagingCompanyForUserHrManagingCompanyUserUpdatedFkeyPatch;
}

export interface UserAccountOnUserHrManagingCompanyForUserHrManagingCompanyUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnUserHrManagingCompanyForUserHrManagingCompanyUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnWorldPlaceForWorldPlaceUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: WorldPlacePatch;
}

export interface UserAccountOnWorldPlaceForWorldPlaceUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: WorldPlacePatch;
}

export interface UserAccountOnWorldPlaceForWorldPlaceUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnWorldPlaceForWorldPlaceUserUpdatedFkeyPatch;
}

export interface UserAccountOnWorldPlaceForWorldPlaceUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnWorldPlaceForWorldPlaceUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountOnWorldPlaceWeatherForWorldPlaceWeatherUserCreatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: WorldPlaceWeatherPatch;
}

export interface UserAccountOnWorldPlaceWeatherForWorldPlaceWeatherUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: WorldPlaceWeatherPatch;
}

export interface UserAccountOnWorldPlaceWeatherForWorldPlaceWeatherUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate {
  emailAddress: Scalars['String']['input'];
  patch: UpdateUserAccountOnWorldPlaceWeatherForWorldPlaceWeatherUserUpdatedFkeyPatch;
}

export interface UserAccountOnWorldPlaceWeatherForWorldPlaceWeatherUserUpdatedFkeyUsingUserAccountPkeyUpdate {
  patch: UpdateUserAccountOnWorldPlaceWeatherForWorldPlaceWeatherUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountPasswordCountConnection {
  __typename?: 'UserAccountPasswordCountConnection';
  edges: Array<UserAccountPasswordCountEdge>;
  nodes: Array<UserAccountPasswordCountRecord>;
  totalCount: Scalars['Int']['output'];
}

export interface UserAccountPasswordCountEdge {
  __typename?: 'UserAccountPasswordCountEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: UserAccountPasswordCountRecord;
}

export enum UserAccountPasswordCountMode {
  NOT_LOGGED_IN = 'NOT_LOGGED_IN',
  NO_PASSWORD = 'NO_PASSWORD',
  OLDER_PASSWORD = 'OLDER_PASSWORD',
}

export interface UserAccountPasswordCountRecord {
  __typename?: 'UserAccountPasswordCountRecord';
  count?: Maybe<Scalars['Int']['output']>;
  emailAddresses?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
}

export interface UserAccountPasswordCountRecordFilter {
  and?: InputMaybe<Array<UserAccountPasswordCountRecordFilter>>;
  count?: InputMaybe<IntFilter>;
  emailAddresses?: InputMaybe<StringListFilter>;
  not?: InputMaybe<UserAccountPasswordCountRecordFilter>;
  or?: InputMaybe<Array<UserAccountPasswordCountRecordFilter>>;
}

export interface UserAccountPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UserAccountPin extends Node {
  __typename?: 'UserAccountPin';
  dateCreated: Scalars['Datetime']['output'];
  nodeId: Scalars['ID']['output'];
  pin: Scalars['String']['output'];
  ref: Scalars['String']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccount: UserAccount;
  userAccountId: Scalars['BigInt']['output'];
}

export interface UserAccountPinCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  pin?: InputMaybe<Scalars['String']['input']>;
  ref?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UserAccountPinFilter {
  and?: InputMaybe<Array<UserAccountPinFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  not?: InputMaybe<UserAccountPinFilter>;
  or?: InputMaybe<Array<UserAccountPinFilter>>;
  pin?: InputMaybe<StringFilter>;
  ref?: InputMaybe<StringFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccount?: InputMaybe<UserAccountFilter>;
  userAccountId?: InputMaybe<BigIntFilter>;
}

export interface UserAccountPinInput {
  pin: Scalars['String']['input'];
  ref: Scalars['String']['input'];
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<UserAccountPinUserAccountIdFkeyInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UserAccountPinNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface UserAccountPinNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface UserAccountPinOnUserAccountPinForUserAccountPinUserAccountIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface UserAccountPinOnUserAccountPinForUserAccountPinUserAccountIdFkeyUsingUserAccountPinPkeyUpdate {
  patch: UpdateUserAccountPinOnUserAccountPinForUserAccountPinUserAccountIdFkeyPatch;
  ref: Scalars['String']['input'];
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountPinPatch {
  pin?: InputMaybe<Scalars['String']['input']>;
  ref?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<UserAccountPinUserAccountIdFkeyInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UserAccountPinUserAccountIdFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  create?: InputMaybe<UserAccountPinUserAccountIdFkeyUserAccountCreateInput>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnUserAccountPinForUserAccountPinUserAccountIdFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<UserAccountPinOnUserAccountPinForUserAccountPinUserAccountIdFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnUserAccountPinForUserAccountPinUserAccountIdFkeyUsingUserAccountPkeyUpdate>;
}

export interface UserAccountPinUserAccountIdFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<UserAccountPinNodeIdConnect>>;
  connectByUserAccountIdAndRef?: InputMaybe<Array<UserAccountPinUserAccountPinPkeyConnect>>;
  create?: InputMaybe<Array<UserAccountPinUserAccountIdFkeyUserAccountPinCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<UserAccountPinNodeIdDelete>>;
  deleteByUserAccountIdAndRef?: InputMaybe<Array<UserAccountPinUserAccountPinPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnUserAccountPinForUserAccountPinUserAccountIdFkeyNodeIdUpdate>>;
  updateByUserAccountIdAndRef?: InputMaybe<Array<UserAccountPinOnUserAccountPinForUserAccountPinUserAccountIdFkeyUsingUserAccountPinPkeyUpdate>>;
}

export interface UserAccountPinUserAccountIdFkeyUserAccountCreateInput {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName: Scalars['String']['input'];
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress: Scalars['String']['input'];
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UserAccountPinUserAccountIdFkeyUserAccountPinCreateInput {
  pin: Scalars['String']['input'];
  ref: Scalars['String']['input'];
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<UserAccountPinUserAccountIdFkeyInput>;
}

export interface UserAccountPinUserAccountPinPkeyConnect {
  ref: Scalars['String']['input'];
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountPinUserAccountPinPkeyDelete {
  ref: Scalars['String']['input'];
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountPinsConnection {
  __typename?: 'UserAccountPinsConnection';
  edges: Array<UserAccountPinsEdge>;
  nodes: Array<UserAccountPin>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface UserAccountPinsEdge {
  __typename?: 'UserAccountPinsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: UserAccountPin;
}

export enum UserAccountPinsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  NATURAL = 'NATURAL',
  PIN_ASC = 'PIN_ASC',
  PIN_DESC = 'PIN_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REF_ASC = 'REF_ASC',
  REF_DESC = 'REF_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__REMARK_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__REMARK_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__REMARK_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__REMARK_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__ROLE_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__ROLE_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__ROLE_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__ROLE_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_UPDATED_DESC',
  USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_ID_DESC',
}

export interface UserAccountRoleFkeyInput {
  connectByRoleName?: InputMaybe<UserRoleUserRoleRoleNameKeyConnect>;
  updateByRoleName?: InputMaybe<UserRoleOnUserAccountForUserAccountRoleFkeyUsingUserRoleRoleNameKeyUpdate>;
}

export interface UserAccountRoleFkeyInverseInput {
  connectByEmailAddress?: InputMaybe<Array<UserAccountUserAccountEmailAddressKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<UserAccountNodeIdConnect>>;
  connectByUserAccountId?: InputMaybe<Array<UserAccountUserAccountPkeyConnect>>;
  create?: InputMaybe<Array<UserAccountRoleFkeyUserAccountCreateInput>>;
  deleteByEmailAddress?: InputMaybe<Array<UserAccountUserAccountEmailAddressKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<UserAccountNodeIdDelete>>;
  deleteByUserAccountId?: InputMaybe<Array<UserAccountUserAccountPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByEmailAddress?: InputMaybe<Array<UserAccountOnUserAccountForUserAccountRoleFkeyUsingUserAccountEmailAddressKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserRoleOnUserAccountForUserAccountRoleFkeyNodeIdUpdate>>;
  updateByUserAccountId?: InputMaybe<Array<UserAccountOnUserAccountForUserAccountRoleFkeyUsingUserAccountPkeyUpdate>>;
}

export interface UserAccountRoleFkeyUserAccountCreateInput {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName: Scalars['String']['input'];
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress: Scalars['String']['input'];
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UserAccountToManyBankFilter {
  every?: InputMaybe<BankFilter>;
  none?: InputMaybe<BankFilter>;
  some?: InputMaybe<BankFilter>;
}

export interface UserAccountToManyBusinessUnitFilter {
  every?: InputMaybe<BusinessUnitFilter>;
  none?: InputMaybe<BusinessUnitFilter>;
  some?: InputMaybe<BusinessUnitFilter>;
}

export interface UserAccountToManyCityFilter {
  every?: InputMaybe<CityFilter>;
  none?: InputMaybe<CityFilter>;
  some?: InputMaybe<CityFilter>;
}

export interface UserAccountToManyCompanyExternalAccountDisableFilter {
  every?: InputMaybe<CompanyExternalAccountDisableFilter>;
  none?: InputMaybe<CompanyExternalAccountDisableFilter>;
  some?: InputMaybe<CompanyExternalAccountDisableFilter>;
}

export interface UserAccountToManyCompanyFilter {
  aggregates?: InputMaybe<CompanyAggregatesFilter>;
  every?: InputMaybe<CompanyFilter>;
  none?: InputMaybe<CompanyFilter>;
  some?: InputMaybe<CompanyFilter>;
}

export interface UserAccountToManyCompanyGlobalHrFilter {
  every?: InputMaybe<CompanyGlobalHrFilter>;
  none?: InputMaybe<CompanyGlobalHrFilter>;
  some?: InputMaybe<CompanyGlobalHrFilter>;
}

export interface UserAccountToManyCompanyGroupFilter {
  every?: InputMaybe<CompanyGroupFilter>;
  none?: InputMaybe<CompanyGroupFilter>;
  some?: InputMaybe<CompanyGroupFilter>;
}

export interface UserAccountToManyCompanyLocalHrFilter {
  every?: InputMaybe<CompanyLocalHrFilter>;
  none?: InputMaybe<CompanyLocalHrFilter>;
  some?: InputMaybe<CompanyLocalHrFilter>;
}

export interface UserAccountToManyContractTypeFilter {
  every?: InputMaybe<ContractTypeFilter>;
  none?: InputMaybe<ContractTypeFilter>;
  some?: InputMaybe<ContractTypeFilter>;
}

export interface UserAccountToManyCountryFilter {
  every?: InputMaybe<CountryFilter>;
  none?: InputMaybe<CountryFilter>;
  some?: InputMaybe<CountryFilter>;
}

export interface UserAccountToManyCurrencyFilter {
  every?: InputMaybe<CurrencyFilter>;
  none?: InputMaybe<CurrencyFilter>;
  some?: InputMaybe<CurrencyFilter>;
}

export interface UserAccountToManyDepartmentFilter {
  every?: InputMaybe<DepartmentFilter>;
  none?: InputMaybe<DepartmentFilter>;
  some?: InputMaybe<DepartmentFilter>;
}

export interface UserAccountToManyDivisionFilter {
  every?: InputMaybe<DivisionFilter>;
  none?: InputMaybe<DivisionFilter>;
  some?: InputMaybe<DivisionFilter>;
}

export interface UserAccountToManyEmailDomainFilter {
  every?: InputMaybe<EmailDomainFilter>;
  none?: InputMaybe<EmailDomainFilter>;
  some?: InputMaybe<EmailDomainFilter>;
}

export interface UserAccountToManyEmployeeEmergencyContactFilter {
  every?: InputMaybe<EmployeeEmergencyContactFilter>;
  none?: InputMaybe<EmployeeEmergencyContactFilter>;
  some?: InputMaybe<EmployeeEmergencyContactFilter>;
}

export interface UserAccountToManyEmployeeFileFilter {
  every?: InputMaybe<EmployeeFileFilter>;
  none?: InputMaybe<EmployeeFileFilter>;
  some?: InputMaybe<EmployeeFileFilter>;
}

export interface UserAccountToManyEmployeeFilter {
  every?: InputMaybe<EmployeeFilter>;
  none?: InputMaybe<EmployeeFilter>;
  some?: InputMaybe<EmployeeFilter>;
}

export interface UserAccountToManyEmployeeGroupFilter {
  every?: InputMaybe<EmployeeGroupFilter>;
  none?: InputMaybe<EmployeeGroupFilter>;
  some?: InputMaybe<EmployeeGroupFilter>;
}

export interface UserAccountToManyEmployeeIdTypeFilter {
  every?: InputMaybe<EmployeeIdTypeFilter>;
  none?: InputMaybe<EmployeeIdTypeFilter>;
  some?: InputMaybe<EmployeeIdTypeFilter>;
}

export interface UserAccountToManyEmployeeLevelFilter {
  every?: InputMaybe<EmployeeLevelFilter>;
  none?: InputMaybe<EmployeeLevelFilter>;
  some?: InputMaybe<EmployeeLevelFilter>;
}

export interface UserAccountToManyEmployeeListCustomFilterFilter {
  every?: InputMaybe<EmployeeListCustomFilterFilter>;
  none?: InputMaybe<EmployeeListCustomFilterFilter>;
  some?: InputMaybe<EmployeeListCustomFilterFilter>;
}

export interface UserAccountToManyEmployeeListCustomFilterRoleFilter {
  every?: InputMaybe<EmployeeListCustomFilterRoleFilter>;
  none?: InputMaybe<EmployeeListCustomFilterRoleFilter>;
  some?: InputMaybe<EmployeeListCustomFilterRoleFilter>;
}

export interface UserAccountToManyEmployeeResignationReasonFilter {
  every?: InputMaybe<EmployeeResignationReasonFilter>;
  none?: InputMaybe<EmployeeResignationReasonFilter>;
  some?: InputMaybe<EmployeeResignationReasonFilter>;
}

export interface UserAccountToManyEmployeeResignationTypeFilter {
  every?: InputMaybe<EmployeeResignationTypeFilter>;
  none?: InputMaybe<EmployeeResignationTypeFilter>;
  some?: InputMaybe<EmployeeResignationTypeFilter>;
}

export interface UserAccountToManyEmployeeStatusFilter {
  every?: InputMaybe<EmployeeStatusFilter>;
  none?: InputMaybe<EmployeeStatusFilter>;
  some?: InputMaybe<EmployeeStatusFilter>;
}

export interface UserAccountToManyEmployeeTpHistoryFilter {
  every?: InputMaybe<EmployeeTpHistoryFilter>;
  none?: InputMaybe<EmployeeTpHistoryFilter>;
  some?: InputMaybe<EmployeeTpHistoryFilter>;
}

export interface UserAccountToManyExportEmployeeStatusFilter {
  every?: InputMaybe<ExportEmployeeStatusFilter>;
  none?: InputMaybe<ExportEmployeeStatusFilter>;
  some?: InputMaybe<ExportEmployeeStatusFilter>;
}

export interface UserAccountToManyExternalAccountEmployeeFilter {
  every?: InputMaybe<ExternalAccountEmployeeFilter>;
  none?: InputMaybe<ExternalAccountEmployeeFilter>;
  some?: InputMaybe<ExternalAccountEmployeeFilter>;
}

export interface UserAccountToManyImportEmployeeStatusFilter {
  every?: InputMaybe<ImportEmployeeStatusFilter>;
  none?: InputMaybe<ImportEmployeeStatusFilter>;
  some?: InputMaybe<ImportEmployeeStatusFilter>;
}

export interface UserAccountToManyOfficeFilter {
  every?: InputMaybe<OfficeFilter>;
  none?: InputMaybe<OfficeFilter>;
  some?: InputMaybe<OfficeFilter>;
}

export interface UserAccountToManyOfficeWeatherFilter {
  every?: InputMaybe<OfficeWeatherFilter>;
  none?: InputMaybe<OfficeWeatherFilter>;
  some?: InputMaybe<OfficeWeatherFilter>;
}

export interface UserAccountToManyPerformanceReviewEvaluationCompetencyFilter {
  every?: InputMaybe<PerformanceReviewEvaluationCompetencyFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationCompetencyFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationCompetencyFilter>;
}

export interface UserAccountToManyPerformanceReviewEvaluationEvaluatorFilter {
  every?: InputMaybe<PerformanceReviewEvaluationEvaluatorFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationEvaluatorFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationEvaluatorFilter>;
}

export interface UserAccountToManyPerformanceReviewEvaluationFeedbackFilter {
  every?: InputMaybe<PerformanceReviewEvaluationFeedbackFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationFeedbackFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationFeedbackFilter>;
}

export interface UserAccountToManyPerformanceReviewEvaluationFilter {
  every?: InputMaybe<PerformanceReviewEvaluationFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationFilter>;
}

export interface UserAccountToManyPerformanceReviewEvaluationKpiAssessmentFilter {
  every?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentFilter>;
}

export interface UserAccountToManyPerformanceReviewEvaluationKpiDescriptionFilter {
  every?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionFilter>;
}

export interface UserAccountToManyPerformanceReviewEvaluationKpiResultFilter {
  every?: InputMaybe<PerformanceReviewEvaluationKpiResultFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationKpiResultFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationKpiResultFilter>;
}

export interface UserAccountToManyPerformanceReviewEvaluationKpiTargetFilter {
  every?: InputMaybe<PerformanceReviewEvaluationKpiTargetFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationKpiTargetFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationKpiTargetFilter>;
}

export interface UserAccountToManyPerformanceReviewEvaluationOneOnOneFilter {
  every?: InputMaybe<PerformanceReviewEvaluationOneOnOneFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationOneOnOneFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationOneOnOneFilter>;
}

export interface UserAccountToManyPerformanceReviewEvaluationQualitativeAssessmentFilter {
  every?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentFilter>;
}

export interface UserAccountToManyPerformanceReviewEvaluationQualitativeDescriptionFilter {
  every?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionFilter>;
}

export interface UserAccountToManyPerformanceReviewEvaluationValueFilter {
  every?: InputMaybe<PerformanceReviewEvaluationValueFilter>;
  none?: InputMaybe<PerformanceReviewEvaluationValueFilter>;
  some?: InputMaybe<PerformanceReviewEvaluationValueFilter>;
}

export interface UserAccountToManyPerformanceReviewMasterCompetencyCommonDescriptionFilter {
  every?: InputMaybe<PerformanceReviewMasterCompetencyCommonDescriptionFilter>;
  none?: InputMaybe<PerformanceReviewMasterCompetencyCommonDescriptionFilter>;
  some?: InputMaybe<PerformanceReviewMasterCompetencyCommonDescriptionFilter>;
}

export interface UserAccountToManyPerformanceReviewMasterCompetencyCommonFilter {
  every?: InputMaybe<PerformanceReviewMasterCompetencyCommonFilter>;
  none?: InputMaybe<PerformanceReviewMasterCompetencyCommonFilter>;
  some?: InputMaybe<PerformanceReviewMasterCompetencyCommonFilter>;
}

export interface UserAccountToManyPerformanceReviewMasterCompetencyDetailDescriptionFilter {
  every?: InputMaybe<PerformanceReviewMasterCompetencyDetailDescriptionFilter>;
  none?: InputMaybe<PerformanceReviewMasterCompetencyDetailDescriptionFilter>;
  some?: InputMaybe<PerformanceReviewMasterCompetencyDetailDescriptionFilter>;
}

export interface UserAccountToManyPerformanceReviewMasterCompetencyDetailFilter {
  every?: InputMaybe<PerformanceReviewMasterCompetencyDetailFilter>;
  none?: InputMaybe<PerformanceReviewMasterCompetencyDetailFilter>;
  some?: InputMaybe<PerformanceReviewMasterCompetencyDetailFilter>;
}

export interface UserAccountToManyPerformanceReviewMasterCompetencyFilter {
  every?: InputMaybe<PerformanceReviewMasterCompetencyFilter>;
  none?: InputMaybe<PerformanceReviewMasterCompetencyFilter>;
  some?: InputMaybe<PerformanceReviewMasterCompetencyFilter>;
}

export interface UserAccountToManyPerformanceReviewMasterCompetencyRoleFilter {
  every?: InputMaybe<PerformanceReviewMasterCompetencyRoleFilter>;
  none?: InputMaybe<PerformanceReviewMasterCompetencyRoleFilter>;
  some?: InputMaybe<PerformanceReviewMasterCompetencyRoleFilter>;
}

export interface UserAccountToManyPerformanceReviewMasterDefaultFilter {
  every?: InputMaybe<PerformanceReviewMasterDefaultFilter>;
  none?: InputMaybe<PerformanceReviewMasterDefaultFilter>;
  some?: InputMaybe<PerformanceReviewMasterDefaultFilter>;
}

export interface UserAccountToManyPerformanceReviewMasterEvaluationViewerFilter {
  every?: InputMaybe<PerformanceReviewMasterEvaluationViewerFilter>;
  none?: InputMaybe<PerformanceReviewMasterEvaluationViewerFilter>;
  some?: InputMaybe<PerformanceReviewMasterEvaluationViewerFilter>;
}

export interface UserAccountToManyPerformanceReviewMasterExcludedEmployeeFilter {
  every?: InputMaybe<PerformanceReviewMasterExcludedEmployeeFilter>;
  none?: InputMaybe<PerformanceReviewMasterExcludedEmployeeFilter>;
  some?: InputMaybe<PerformanceReviewMasterExcludedEmployeeFilter>;
}

export interface UserAccountToManyPerformanceReviewMasterOneOnOneDetailFilter {
  every?: InputMaybe<PerformanceReviewMasterOneOnOneDetailFilter>;
  none?: InputMaybe<PerformanceReviewMasterOneOnOneDetailFilter>;
  some?: InputMaybe<PerformanceReviewMasterOneOnOneDetailFilter>;
}

export interface UserAccountToManyPerformanceReviewMasterOneOnOneFilter {
  every?: InputMaybe<PerformanceReviewMasterOneOnOneFilter>;
  none?: InputMaybe<PerformanceReviewMasterOneOnOneFilter>;
  some?: InputMaybe<PerformanceReviewMasterOneOnOneFilter>;
}

export interface UserAccountToManyPerformanceReviewMasterRatingDescriptionFilter {
  every?: InputMaybe<PerformanceReviewMasterRatingDescriptionFilter>;
  none?: InputMaybe<PerformanceReviewMasterRatingDescriptionFilter>;
  some?: InputMaybe<PerformanceReviewMasterRatingDescriptionFilter>;
}

export interface UserAccountToManyPerformanceReviewMasterRatingFilter {
  every?: InputMaybe<PerformanceReviewMasterRatingFilter>;
  none?: InputMaybe<PerformanceReviewMasterRatingFilter>;
  some?: InputMaybe<PerformanceReviewMasterRatingFilter>;
}

export interface UserAccountToManyPerformanceReviewMasterUsageFilter {
  every?: InputMaybe<PerformanceReviewMasterUsageFilter>;
  none?: InputMaybe<PerformanceReviewMasterUsageFilter>;
  some?: InputMaybe<PerformanceReviewMasterUsageFilter>;
}

export interface UserAccountToManyPerformanceReviewMasterValueDescriptionFilter {
  every?: InputMaybe<PerformanceReviewMasterValueDescriptionFilter>;
  none?: InputMaybe<PerformanceReviewMasterValueDescriptionFilter>;
  some?: InputMaybe<PerformanceReviewMasterValueDescriptionFilter>;
}

export interface UserAccountToManyPerformanceReviewMasterValueFilter {
  every?: InputMaybe<PerformanceReviewMasterValueFilter>;
  none?: InputMaybe<PerformanceReviewMasterValueFilter>;
  some?: InputMaybe<PerformanceReviewMasterValueFilter>;
}

export interface UserAccountToManyPerformanceReviewMasterWeightingDetailFilter {
  every?: InputMaybe<PerformanceReviewMasterWeightingDetailFilter>;
  none?: InputMaybe<PerformanceReviewMasterWeightingDetailFilter>;
  some?: InputMaybe<PerformanceReviewMasterWeightingDetailFilter>;
}

export interface UserAccountToManyPerformanceReviewMasterWeightingFilter {
  every?: InputMaybe<PerformanceReviewMasterWeightingFilter>;
  none?: InputMaybe<PerformanceReviewMasterWeightingFilter>;
  some?: InputMaybe<PerformanceReviewMasterWeightingFilter>;
}

export interface UserAccountToManyPersonalIdentityTypeFilter {
  every?: InputMaybe<PersonalIdentityTypeFilter>;
  none?: InputMaybe<PersonalIdentityTypeFilter>;
  some?: InputMaybe<PersonalIdentityTypeFilter>;
}

export interface UserAccountToManyProbationReviewEnableFilter {
  every?: InputMaybe<ProbationReviewEnableFilter>;
  none?: InputMaybe<ProbationReviewEnableFilter>;
  some?: InputMaybe<ProbationReviewEnableFilter>;
}

export interface UserAccountToManyProbationReviewEvaluationFilter {
  every?: InputMaybe<ProbationReviewEvaluationFilter>;
  none?: InputMaybe<ProbationReviewEvaluationFilter>;
  some?: InputMaybe<ProbationReviewEvaluationFilter>;
}

export interface UserAccountToManyProbationReviewEvaluationResultItemFilter {
  every?: InputMaybe<ProbationReviewEvaluationResultItemFilter>;
  none?: InputMaybe<ProbationReviewEvaluationResultItemFilter>;
  some?: InputMaybe<ProbationReviewEvaluationResultItemFilter>;
}

export interface UserAccountToManyProbationReviewEvaluationResultScoreCompetencyFilter {
  every?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyFilter>;
  none?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyFilter>;
  some?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyFilter>;
}

export interface UserAccountToManyProbationReviewEvaluationResultScoreValueFilter {
  every?: InputMaybe<ProbationReviewEvaluationResultScoreValueFilter>;
  none?: InputMaybe<ProbationReviewEvaluationResultScoreValueFilter>;
  some?: InputMaybe<ProbationReviewEvaluationResultScoreValueFilter>;
}

export interface UserAccountToManyProbationReviewEvaluationReviewerFilter {
  every?: InputMaybe<ProbationReviewEvaluationReviewerFilter>;
  none?: InputMaybe<ProbationReviewEvaluationReviewerFilter>;
  some?: InputMaybe<ProbationReviewEvaluationReviewerFilter>;
}

export interface UserAccountToManyProbationReviewMasterItemDescriptionFilter {
  every?: InputMaybe<ProbationReviewMasterItemDescriptionFilter>;
  none?: InputMaybe<ProbationReviewMasterItemDescriptionFilter>;
  some?: InputMaybe<ProbationReviewMasterItemDescriptionFilter>;
}

export interface UserAccountToManyProbationReviewMasterItemFilter {
  every?: InputMaybe<ProbationReviewMasterItemFilter>;
  none?: InputMaybe<ProbationReviewMasterItemFilter>;
  some?: InputMaybe<ProbationReviewMasterItemFilter>;
}

export interface UserAccountToManyRelationBusinessUnitDivisionDepartmentFilter {
  aggregates?: InputMaybe<RelationBusinessUnitDivisionDepartmentAggregatesFilter>;
  every?: InputMaybe<RelationBusinessUnitDivisionDepartmentFilter>;
  none?: InputMaybe<RelationBusinessUnitDivisionDepartmentFilter>;
  some?: InputMaybe<RelationBusinessUnitDivisionDepartmentFilter>;
}

export interface UserAccountToManyRelationBusinessUnitDivisionFilter {
  aggregates?: InputMaybe<RelationBusinessUnitDivisionAggregatesFilter>;
  every?: InputMaybe<RelationBusinessUnitDivisionFilter>;
  none?: InputMaybe<RelationBusinessUnitDivisionFilter>;
  some?: InputMaybe<RelationBusinessUnitDivisionFilter>;
}

export interface UserAccountToManyRelationCityCompanyFilter {
  every?: InputMaybe<RelationCityCompanyFilter>;
  none?: InputMaybe<RelationCityCompanyFilter>;
  some?: InputMaybe<RelationCityCompanyFilter>;
}

export interface UserAccountToManyRelationCompanyBusinessUnitFilter {
  every?: InputMaybe<RelationCompanyBusinessUnitFilter>;
  none?: InputMaybe<RelationCompanyBusinessUnitFilter>;
  some?: InputMaybe<RelationCompanyBusinessUnitFilter>;
}

export interface UserAccountToManyRelationCompanyGroupEmailDomainFilter {
  every?: InputMaybe<RelationCompanyGroupEmailDomainFilter>;
  none?: InputMaybe<RelationCompanyGroupEmailDomainFilter>;
  some?: InputMaybe<RelationCompanyGroupEmailDomainFilter>;
}

export interface UserAccountToManyRelationCompanyOfficeFilter {
  every?: InputMaybe<RelationCompanyOfficeFilter>;
  none?: InputMaybe<RelationCompanyOfficeFilter>;
  some?: InputMaybe<RelationCompanyOfficeFilter>;
}

export interface UserAccountToManyRelationCurrencyCountryFilter {
  every?: InputMaybe<RelationCurrencyCountryFilter>;
  none?: InputMaybe<RelationCurrencyCountryFilter>;
  some?: InputMaybe<RelationCurrencyCountryFilter>;
}

export interface UserAccountToManyRelationEmergencyContactFilter {
  every?: InputMaybe<RelationEmergencyContactFilter>;
  none?: InputMaybe<RelationEmergencyContactFilter>;
  some?: InputMaybe<RelationEmergencyContactFilter>;
}

export interface UserAccountToManyRelationEmployeeUserAccountFilter {
  every?: InputMaybe<RelationEmployeeUserAccountFilter>;
  none?: InputMaybe<RelationEmployeeUserAccountFilter>;
  some?: InputMaybe<RelationEmployeeUserAccountFilter>;
}

export interface UserAccountToManyTableAuditFilter {
  every?: InputMaybe<TableAuditFilter>;
  none?: InputMaybe<TableAuditFilter>;
  some?: InputMaybe<TableAuditFilter>;
}

export interface UserAccountToManyUserAccountAccessHistoryFilter {
  every?: InputMaybe<UserAccountAccessHistoryFilter>;
  none?: InputMaybe<UserAccountAccessHistoryFilter>;
  some?: InputMaybe<UserAccountAccessHistoryFilter>;
}

export interface UserAccountToManyUserAccountPinFilter {
  every?: InputMaybe<UserAccountPinFilter>;
  none?: InputMaybe<UserAccountPinFilter>;
  some?: InputMaybe<UserAccountPinFilter>;
}

export interface UserAccountToManyUserForceLogoutFilter {
  every?: InputMaybe<UserForceLogoutFilter>;
  none?: InputMaybe<UserForceLogoutFilter>;
  some?: InputMaybe<UserForceLogoutFilter>;
}

export interface UserAccountToManyUserHrManagingCompanyFilter {
  every?: InputMaybe<UserHrManagingCompanyFilter>;
  none?: InputMaybe<UserHrManagingCompanyFilter>;
  some?: InputMaybe<UserHrManagingCompanyFilter>;
}

export interface UserAccountToManyWorldPlaceFilter {
  every?: InputMaybe<WorldPlaceFilter>;
  none?: InputMaybe<WorldPlaceFilter>;
  some?: InputMaybe<WorldPlaceFilter>;
}

export interface UserAccountToManyWorldPlaceWeatherFilter {
  every?: InputMaybe<WorldPlaceWeatherFilter>;
  none?: InputMaybe<WorldPlaceWeatherFilter>;
  some?: InputMaybe<WorldPlaceWeatherFilter>;
}

export interface UserAccountUserAccountEmailAddressKeyConnect {
  emailAddress: Scalars['String']['input'];
}

export interface UserAccountUserAccountEmailAddressKeyDelete {
  emailAddress: Scalars['String']['input'];
}

export interface UserAccountUserAccountPkeyConnect {
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountUserAccountPkeyDelete {
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserAccountUserCreatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnUserAccountForUserAccountUserCreatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<UserAccountOnUserAccountForUserAccountUserCreatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnUserAccountForUserAccountUserCreatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface UserAccountUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnUserAccountForUserAccountUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<UserAccountOnUserAccountForUserAccountUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnUserAccountForUserAccountUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface UserAccountsConnection {
  __typename?: 'UserAccountsConnection';
  edges: Array<UserAccountsEdge>;
  nodes: Array<UserAccount>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface UserAccountsEdge {
  __typename?: 'UserAccountsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: UserAccount;
}

export enum UserAccountsOrderBy {
  BANKS_BY_USER_CREATED__COUNT_ASC = 'BANKS_BY_USER_CREATED__COUNT_ASC',
  BANKS_BY_USER_CREATED__COUNT_DESC = 'BANKS_BY_USER_CREATED__COUNT_DESC',
  BANKS_BY_USER_CREATED__MAX_BANK_CODE_ASC = 'BANKS_BY_USER_CREATED__MAX_BANK_CODE_ASC',
  BANKS_BY_USER_CREATED__MAX_BANK_CODE_DESC = 'BANKS_BY_USER_CREATED__MAX_BANK_CODE_DESC',
  BANKS_BY_USER_CREATED__MAX_BANK_ID_ASC = 'BANKS_BY_USER_CREATED__MAX_BANK_ID_ASC',
  BANKS_BY_USER_CREATED__MAX_BANK_ID_DESC = 'BANKS_BY_USER_CREATED__MAX_BANK_ID_DESC',
  BANKS_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA3_ASC = 'BANKS_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA3_ASC',
  BANKS_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA3_DESC = 'BANKS_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA3_DESC',
  BANKS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'BANKS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  BANKS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'BANKS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  BANKS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'BANKS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  BANKS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'BANKS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  BANKS_BY_USER_CREATED__MAX_ENABLED_ASC = 'BANKS_BY_USER_CREATED__MAX_ENABLED_ASC',
  BANKS_BY_USER_CREATED__MAX_ENABLED_DESC = 'BANKS_BY_USER_CREATED__MAX_ENABLED_DESC',
  BANKS_BY_USER_CREATED__MAX_FULL_NAME_ASC = 'BANKS_BY_USER_CREATED__MAX_FULL_NAME_ASC',
  BANKS_BY_USER_CREATED__MAX_FULL_NAME_DESC = 'BANKS_BY_USER_CREATED__MAX_FULL_NAME_DESC',
  BANKS_BY_USER_CREATED__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'BANKS_BY_USER_CREATED__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  BANKS_BY_USER_CREATED__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'BANKS_BY_USER_CREATED__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  BANKS_BY_USER_CREATED__MAX_REMARK_ASC = 'BANKS_BY_USER_CREATED__MAX_REMARK_ASC',
  BANKS_BY_USER_CREATED__MAX_REMARK_DESC = 'BANKS_BY_USER_CREATED__MAX_REMARK_DESC',
  BANKS_BY_USER_CREATED__MAX_SHORT_NAME_ASC = 'BANKS_BY_USER_CREATED__MAX_SHORT_NAME_ASC',
  BANKS_BY_USER_CREATED__MAX_SHORT_NAME_DESC = 'BANKS_BY_USER_CREATED__MAX_SHORT_NAME_DESC',
  BANKS_BY_USER_CREATED__MAX_SWIFT_CODE_ASC = 'BANKS_BY_USER_CREATED__MAX_SWIFT_CODE_ASC',
  BANKS_BY_USER_CREATED__MAX_SWIFT_CODE_DESC = 'BANKS_BY_USER_CREATED__MAX_SWIFT_CODE_DESC',
  BANKS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'BANKS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  BANKS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'BANKS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  BANKS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'BANKS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  BANKS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'BANKS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  BANKS_BY_USER_CREATED__MIN_BANK_CODE_ASC = 'BANKS_BY_USER_CREATED__MIN_BANK_CODE_ASC',
  BANKS_BY_USER_CREATED__MIN_BANK_CODE_DESC = 'BANKS_BY_USER_CREATED__MIN_BANK_CODE_DESC',
  BANKS_BY_USER_CREATED__MIN_BANK_ID_ASC = 'BANKS_BY_USER_CREATED__MIN_BANK_ID_ASC',
  BANKS_BY_USER_CREATED__MIN_BANK_ID_DESC = 'BANKS_BY_USER_CREATED__MIN_BANK_ID_DESC',
  BANKS_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA3_ASC = 'BANKS_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA3_ASC',
  BANKS_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA3_DESC = 'BANKS_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA3_DESC',
  BANKS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'BANKS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  BANKS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'BANKS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  BANKS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'BANKS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  BANKS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'BANKS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  BANKS_BY_USER_CREATED__MIN_ENABLED_ASC = 'BANKS_BY_USER_CREATED__MIN_ENABLED_ASC',
  BANKS_BY_USER_CREATED__MIN_ENABLED_DESC = 'BANKS_BY_USER_CREATED__MIN_ENABLED_DESC',
  BANKS_BY_USER_CREATED__MIN_FULL_NAME_ASC = 'BANKS_BY_USER_CREATED__MIN_FULL_NAME_ASC',
  BANKS_BY_USER_CREATED__MIN_FULL_NAME_DESC = 'BANKS_BY_USER_CREATED__MIN_FULL_NAME_DESC',
  BANKS_BY_USER_CREATED__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'BANKS_BY_USER_CREATED__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  BANKS_BY_USER_CREATED__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'BANKS_BY_USER_CREATED__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  BANKS_BY_USER_CREATED__MIN_REMARK_ASC = 'BANKS_BY_USER_CREATED__MIN_REMARK_ASC',
  BANKS_BY_USER_CREATED__MIN_REMARK_DESC = 'BANKS_BY_USER_CREATED__MIN_REMARK_DESC',
  BANKS_BY_USER_CREATED__MIN_SHORT_NAME_ASC = 'BANKS_BY_USER_CREATED__MIN_SHORT_NAME_ASC',
  BANKS_BY_USER_CREATED__MIN_SHORT_NAME_DESC = 'BANKS_BY_USER_CREATED__MIN_SHORT_NAME_DESC',
  BANKS_BY_USER_CREATED__MIN_SWIFT_CODE_ASC = 'BANKS_BY_USER_CREATED__MIN_SWIFT_CODE_ASC',
  BANKS_BY_USER_CREATED__MIN_SWIFT_CODE_DESC = 'BANKS_BY_USER_CREATED__MIN_SWIFT_CODE_DESC',
  BANKS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'BANKS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  BANKS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'BANKS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  BANKS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'BANKS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  BANKS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'BANKS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  BANKS_BY_USER_UPDATED__COUNT_ASC = 'BANKS_BY_USER_UPDATED__COUNT_ASC',
  BANKS_BY_USER_UPDATED__COUNT_DESC = 'BANKS_BY_USER_UPDATED__COUNT_DESC',
  BANKS_BY_USER_UPDATED__MAX_BANK_CODE_ASC = 'BANKS_BY_USER_UPDATED__MAX_BANK_CODE_ASC',
  BANKS_BY_USER_UPDATED__MAX_BANK_CODE_DESC = 'BANKS_BY_USER_UPDATED__MAX_BANK_CODE_DESC',
  BANKS_BY_USER_UPDATED__MAX_BANK_ID_ASC = 'BANKS_BY_USER_UPDATED__MAX_BANK_ID_ASC',
  BANKS_BY_USER_UPDATED__MAX_BANK_ID_DESC = 'BANKS_BY_USER_UPDATED__MAX_BANK_ID_DESC',
  BANKS_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA3_ASC = 'BANKS_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA3_ASC',
  BANKS_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA3_DESC = 'BANKS_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA3_DESC',
  BANKS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'BANKS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  BANKS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'BANKS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  BANKS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'BANKS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  BANKS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'BANKS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  BANKS_BY_USER_UPDATED__MAX_ENABLED_ASC = 'BANKS_BY_USER_UPDATED__MAX_ENABLED_ASC',
  BANKS_BY_USER_UPDATED__MAX_ENABLED_DESC = 'BANKS_BY_USER_UPDATED__MAX_ENABLED_DESC',
  BANKS_BY_USER_UPDATED__MAX_FULL_NAME_ASC = 'BANKS_BY_USER_UPDATED__MAX_FULL_NAME_ASC',
  BANKS_BY_USER_UPDATED__MAX_FULL_NAME_DESC = 'BANKS_BY_USER_UPDATED__MAX_FULL_NAME_DESC',
  BANKS_BY_USER_UPDATED__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'BANKS_BY_USER_UPDATED__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  BANKS_BY_USER_UPDATED__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'BANKS_BY_USER_UPDATED__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  BANKS_BY_USER_UPDATED__MAX_REMARK_ASC = 'BANKS_BY_USER_UPDATED__MAX_REMARK_ASC',
  BANKS_BY_USER_UPDATED__MAX_REMARK_DESC = 'BANKS_BY_USER_UPDATED__MAX_REMARK_DESC',
  BANKS_BY_USER_UPDATED__MAX_SHORT_NAME_ASC = 'BANKS_BY_USER_UPDATED__MAX_SHORT_NAME_ASC',
  BANKS_BY_USER_UPDATED__MAX_SHORT_NAME_DESC = 'BANKS_BY_USER_UPDATED__MAX_SHORT_NAME_DESC',
  BANKS_BY_USER_UPDATED__MAX_SWIFT_CODE_ASC = 'BANKS_BY_USER_UPDATED__MAX_SWIFT_CODE_ASC',
  BANKS_BY_USER_UPDATED__MAX_SWIFT_CODE_DESC = 'BANKS_BY_USER_UPDATED__MAX_SWIFT_CODE_DESC',
  BANKS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'BANKS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  BANKS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'BANKS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  BANKS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'BANKS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  BANKS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'BANKS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  BANKS_BY_USER_UPDATED__MIN_BANK_CODE_ASC = 'BANKS_BY_USER_UPDATED__MIN_BANK_CODE_ASC',
  BANKS_BY_USER_UPDATED__MIN_BANK_CODE_DESC = 'BANKS_BY_USER_UPDATED__MIN_BANK_CODE_DESC',
  BANKS_BY_USER_UPDATED__MIN_BANK_ID_ASC = 'BANKS_BY_USER_UPDATED__MIN_BANK_ID_ASC',
  BANKS_BY_USER_UPDATED__MIN_BANK_ID_DESC = 'BANKS_BY_USER_UPDATED__MIN_BANK_ID_DESC',
  BANKS_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA3_ASC = 'BANKS_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA3_ASC',
  BANKS_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA3_DESC = 'BANKS_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA3_DESC',
  BANKS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'BANKS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  BANKS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'BANKS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  BANKS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'BANKS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  BANKS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'BANKS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  BANKS_BY_USER_UPDATED__MIN_ENABLED_ASC = 'BANKS_BY_USER_UPDATED__MIN_ENABLED_ASC',
  BANKS_BY_USER_UPDATED__MIN_ENABLED_DESC = 'BANKS_BY_USER_UPDATED__MIN_ENABLED_DESC',
  BANKS_BY_USER_UPDATED__MIN_FULL_NAME_ASC = 'BANKS_BY_USER_UPDATED__MIN_FULL_NAME_ASC',
  BANKS_BY_USER_UPDATED__MIN_FULL_NAME_DESC = 'BANKS_BY_USER_UPDATED__MIN_FULL_NAME_DESC',
  BANKS_BY_USER_UPDATED__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'BANKS_BY_USER_UPDATED__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  BANKS_BY_USER_UPDATED__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'BANKS_BY_USER_UPDATED__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  BANKS_BY_USER_UPDATED__MIN_REMARK_ASC = 'BANKS_BY_USER_UPDATED__MIN_REMARK_ASC',
  BANKS_BY_USER_UPDATED__MIN_REMARK_DESC = 'BANKS_BY_USER_UPDATED__MIN_REMARK_DESC',
  BANKS_BY_USER_UPDATED__MIN_SHORT_NAME_ASC = 'BANKS_BY_USER_UPDATED__MIN_SHORT_NAME_ASC',
  BANKS_BY_USER_UPDATED__MIN_SHORT_NAME_DESC = 'BANKS_BY_USER_UPDATED__MIN_SHORT_NAME_DESC',
  BANKS_BY_USER_UPDATED__MIN_SWIFT_CODE_ASC = 'BANKS_BY_USER_UPDATED__MIN_SWIFT_CODE_ASC',
  BANKS_BY_USER_UPDATED__MIN_SWIFT_CODE_DESC = 'BANKS_BY_USER_UPDATED__MIN_SWIFT_CODE_DESC',
  BANKS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'BANKS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  BANKS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'BANKS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  BANKS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'BANKS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  BANKS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'BANKS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  BUSINESS_UNITS_BY_USER_CREATED__COUNT_ASC = 'BUSINESS_UNITS_BY_USER_CREATED__COUNT_ASC',
  BUSINESS_UNITS_BY_USER_CREATED__COUNT_DESC = 'BUSINESS_UNITS_BY_USER_CREATED__COUNT_DESC',
  BUSINESS_UNITS_BY_USER_CREATED__MAX_BUSINESS_UNIT_ID_ASC = 'BUSINESS_UNITS_BY_USER_CREATED__MAX_BUSINESS_UNIT_ID_ASC',
  BUSINESS_UNITS_BY_USER_CREATED__MAX_BUSINESS_UNIT_ID_DESC = 'BUSINESS_UNITS_BY_USER_CREATED__MAX_BUSINESS_UNIT_ID_DESC',
  BUSINESS_UNITS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'BUSINESS_UNITS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  BUSINESS_UNITS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'BUSINESS_UNITS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  BUSINESS_UNITS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'BUSINESS_UNITS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  BUSINESS_UNITS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'BUSINESS_UNITS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  BUSINESS_UNITS_BY_USER_CREATED__MAX_IS_ACTIVE_ASC = 'BUSINESS_UNITS_BY_USER_CREATED__MAX_IS_ACTIVE_ASC',
  BUSINESS_UNITS_BY_USER_CREATED__MAX_IS_ACTIVE_DESC = 'BUSINESS_UNITS_BY_USER_CREATED__MAX_IS_ACTIVE_DESC',
  BUSINESS_UNITS_BY_USER_CREATED__MAX_NAME_ASC = 'BUSINESS_UNITS_BY_USER_CREATED__MAX_NAME_ASC',
  BUSINESS_UNITS_BY_USER_CREATED__MAX_NAME_DESC = 'BUSINESS_UNITS_BY_USER_CREATED__MAX_NAME_DESC',
  BUSINESS_UNITS_BY_USER_CREATED__MAX_REMARK_ASC = 'BUSINESS_UNITS_BY_USER_CREATED__MAX_REMARK_ASC',
  BUSINESS_UNITS_BY_USER_CREATED__MAX_REMARK_DESC = 'BUSINESS_UNITS_BY_USER_CREATED__MAX_REMARK_DESC',
  BUSINESS_UNITS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'BUSINESS_UNITS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  BUSINESS_UNITS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'BUSINESS_UNITS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  BUSINESS_UNITS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'BUSINESS_UNITS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  BUSINESS_UNITS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'BUSINESS_UNITS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  BUSINESS_UNITS_BY_USER_CREATED__MIN_BUSINESS_UNIT_ID_ASC = 'BUSINESS_UNITS_BY_USER_CREATED__MIN_BUSINESS_UNIT_ID_ASC',
  BUSINESS_UNITS_BY_USER_CREATED__MIN_BUSINESS_UNIT_ID_DESC = 'BUSINESS_UNITS_BY_USER_CREATED__MIN_BUSINESS_UNIT_ID_DESC',
  BUSINESS_UNITS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'BUSINESS_UNITS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  BUSINESS_UNITS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'BUSINESS_UNITS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  BUSINESS_UNITS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'BUSINESS_UNITS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  BUSINESS_UNITS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'BUSINESS_UNITS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  BUSINESS_UNITS_BY_USER_CREATED__MIN_IS_ACTIVE_ASC = 'BUSINESS_UNITS_BY_USER_CREATED__MIN_IS_ACTIVE_ASC',
  BUSINESS_UNITS_BY_USER_CREATED__MIN_IS_ACTIVE_DESC = 'BUSINESS_UNITS_BY_USER_CREATED__MIN_IS_ACTIVE_DESC',
  BUSINESS_UNITS_BY_USER_CREATED__MIN_NAME_ASC = 'BUSINESS_UNITS_BY_USER_CREATED__MIN_NAME_ASC',
  BUSINESS_UNITS_BY_USER_CREATED__MIN_NAME_DESC = 'BUSINESS_UNITS_BY_USER_CREATED__MIN_NAME_DESC',
  BUSINESS_UNITS_BY_USER_CREATED__MIN_REMARK_ASC = 'BUSINESS_UNITS_BY_USER_CREATED__MIN_REMARK_ASC',
  BUSINESS_UNITS_BY_USER_CREATED__MIN_REMARK_DESC = 'BUSINESS_UNITS_BY_USER_CREATED__MIN_REMARK_DESC',
  BUSINESS_UNITS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'BUSINESS_UNITS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  BUSINESS_UNITS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'BUSINESS_UNITS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  BUSINESS_UNITS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'BUSINESS_UNITS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  BUSINESS_UNITS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'BUSINESS_UNITS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  BUSINESS_UNITS_BY_USER_UPDATED__COUNT_ASC = 'BUSINESS_UNITS_BY_USER_UPDATED__COUNT_ASC',
  BUSINESS_UNITS_BY_USER_UPDATED__COUNT_DESC = 'BUSINESS_UNITS_BY_USER_UPDATED__COUNT_DESC',
  BUSINESS_UNITS_BY_USER_UPDATED__MAX_BUSINESS_UNIT_ID_ASC = 'BUSINESS_UNITS_BY_USER_UPDATED__MAX_BUSINESS_UNIT_ID_ASC',
  BUSINESS_UNITS_BY_USER_UPDATED__MAX_BUSINESS_UNIT_ID_DESC = 'BUSINESS_UNITS_BY_USER_UPDATED__MAX_BUSINESS_UNIT_ID_DESC',
  BUSINESS_UNITS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'BUSINESS_UNITS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  BUSINESS_UNITS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'BUSINESS_UNITS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  BUSINESS_UNITS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'BUSINESS_UNITS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  BUSINESS_UNITS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'BUSINESS_UNITS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  BUSINESS_UNITS_BY_USER_UPDATED__MAX_IS_ACTIVE_ASC = 'BUSINESS_UNITS_BY_USER_UPDATED__MAX_IS_ACTIVE_ASC',
  BUSINESS_UNITS_BY_USER_UPDATED__MAX_IS_ACTIVE_DESC = 'BUSINESS_UNITS_BY_USER_UPDATED__MAX_IS_ACTIVE_DESC',
  BUSINESS_UNITS_BY_USER_UPDATED__MAX_NAME_ASC = 'BUSINESS_UNITS_BY_USER_UPDATED__MAX_NAME_ASC',
  BUSINESS_UNITS_BY_USER_UPDATED__MAX_NAME_DESC = 'BUSINESS_UNITS_BY_USER_UPDATED__MAX_NAME_DESC',
  BUSINESS_UNITS_BY_USER_UPDATED__MAX_REMARK_ASC = 'BUSINESS_UNITS_BY_USER_UPDATED__MAX_REMARK_ASC',
  BUSINESS_UNITS_BY_USER_UPDATED__MAX_REMARK_DESC = 'BUSINESS_UNITS_BY_USER_UPDATED__MAX_REMARK_DESC',
  BUSINESS_UNITS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'BUSINESS_UNITS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  BUSINESS_UNITS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'BUSINESS_UNITS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  BUSINESS_UNITS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'BUSINESS_UNITS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  BUSINESS_UNITS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'BUSINESS_UNITS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  BUSINESS_UNITS_BY_USER_UPDATED__MIN_BUSINESS_UNIT_ID_ASC = 'BUSINESS_UNITS_BY_USER_UPDATED__MIN_BUSINESS_UNIT_ID_ASC',
  BUSINESS_UNITS_BY_USER_UPDATED__MIN_BUSINESS_UNIT_ID_DESC = 'BUSINESS_UNITS_BY_USER_UPDATED__MIN_BUSINESS_UNIT_ID_DESC',
  BUSINESS_UNITS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'BUSINESS_UNITS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  BUSINESS_UNITS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'BUSINESS_UNITS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  BUSINESS_UNITS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'BUSINESS_UNITS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  BUSINESS_UNITS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'BUSINESS_UNITS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  BUSINESS_UNITS_BY_USER_UPDATED__MIN_IS_ACTIVE_ASC = 'BUSINESS_UNITS_BY_USER_UPDATED__MIN_IS_ACTIVE_ASC',
  BUSINESS_UNITS_BY_USER_UPDATED__MIN_IS_ACTIVE_DESC = 'BUSINESS_UNITS_BY_USER_UPDATED__MIN_IS_ACTIVE_DESC',
  BUSINESS_UNITS_BY_USER_UPDATED__MIN_NAME_ASC = 'BUSINESS_UNITS_BY_USER_UPDATED__MIN_NAME_ASC',
  BUSINESS_UNITS_BY_USER_UPDATED__MIN_NAME_DESC = 'BUSINESS_UNITS_BY_USER_UPDATED__MIN_NAME_DESC',
  BUSINESS_UNITS_BY_USER_UPDATED__MIN_REMARK_ASC = 'BUSINESS_UNITS_BY_USER_UPDATED__MIN_REMARK_ASC',
  BUSINESS_UNITS_BY_USER_UPDATED__MIN_REMARK_DESC = 'BUSINESS_UNITS_BY_USER_UPDATED__MIN_REMARK_DESC',
  BUSINESS_UNITS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'BUSINESS_UNITS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  BUSINESS_UNITS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'BUSINESS_UNITS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  BUSINESS_UNITS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'BUSINESS_UNITS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  BUSINESS_UNITS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'BUSINESS_UNITS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  CITIES_BY_USER_CREATED__COUNT_ASC = 'CITIES_BY_USER_CREATED__COUNT_ASC',
  CITIES_BY_USER_CREATED__COUNT_DESC = 'CITIES_BY_USER_CREATED__COUNT_DESC',
  CITIES_BY_USER_CREATED__MAX_CITY_ID_ASC = 'CITIES_BY_USER_CREATED__MAX_CITY_ID_ASC',
  CITIES_BY_USER_CREATED__MAX_CITY_ID_DESC = 'CITIES_BY_USER_CREATED__MAX_CITY_ID_DESC',
  CITIES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA2_ASC = 'CITIES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA2_ASC',
  CITIES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA2_DESC = 'CITIES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA2_DESC',
  CITIES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'CITIES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  CITIES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'CITIES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  CITIES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'CITIES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  CITIES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'CITIES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  CITIES_BY_USER_CREATED__MAX_NAME_ASC = 'CITIES_BY_USER_CREATED__MAX_NAME_ASC',
  CITIES_BY_USER_CREATED__MAX_NAME_DESC = 'CITIES_BY_USER_CREATED__MAX_NAME_DESC',
  CITIES_BY_USER_CREATED__MAX_REMARK_ASC = 'CITIES_BY_USER_CREATED__MAX_REMARK_ASC',
  CITIES_BY_USER_CREATED__MAX_REMARK_DESC = 'CITIES_BY_USER_CREATED__MAX_REMARK_DESC',
  CITIES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'CITIES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  CITIES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'CITIES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  CITIES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'CITIES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  CITIES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'CITIES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  CITIES_BY_USER_CREATED__MAX_UTC_OFFSET_ASC = 'CITIES_BY_USER_CREATED__MAX_UTC_OFFSET_ASC',
  CITIES_BY_USER_CREATED__MAX_UTC_OFFSET_DESC = 'CITIES_BY_USER_CREATED__MAX_UTC_OFFSET_DESC',
  CITIES_BY_USER_CREATED__MIN_CITY_ID_ASC = 'CITIES_BY_USER_CREATED__MIN_CITY_ID_ASC',
  CITIES_BY_USER_CREATED__MIN_CITY_ID_DESC = 'CITIES_BY_USER_CREATED__MIN_CITY_ID_DESC',
  CITIES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA2_ASC = 'CITIES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA2_ASC',
  CITIES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA2_DESC = 'CITIES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA2_DESC',
  CITIES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'CITIES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  CITIES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'CITIES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  CITIES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'CITIES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  CITIES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'CITIES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  CITIES_BY_USER_CREATED__MIN_NAME_ASC = 'CITIES_BY_USER_CREATED__MIN_NAME_ASC',
  CITIES_BY_USER_CREATED__MIN_NAME_DESC = 'CITIES_BY_USER_CREATED__MIN_NAME_DESC',
  CITIES_BY_USER_CREATED__MIN_REMARK_ASC = 'CITIES_BY_USER_CREATED__MIN_REMARK_ASC',
  CITIES_BY_USER_CREATED__MIN_REMARK_DESC = 'CITIES_BY_USER_CREATED__MIN_REMARK_DESC',
  CITIES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'CITIES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  CITIES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'CITIES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  CITIES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'CITIES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  CITIES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'CITIES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  CITIES_BY_USER_CREATED__MIN_UTC_OFFSET_ASC = 'CITIES_BY_USER_CREATED__MIN_UTC_OFFSET_ASC',
  CITIES_BY_USER_CREATED__MIN_UTC_OFFSET_DESC = 'CITIES_BY_USER_CREATED__MIN_UTC_OFFSET_DESC',
  CITIES_BY_USER_UPDATED__COUNT_ASC = 'CITIES_BY_USER_UPDATED__COUNT_ASC',
  CITIES_BY_USER_UPDATED__COUNT_DESC = 'CITIES_BY_USER_UPDATED__COUNT_DESC',
  CITIES_BY_USER_UPDATED__MAX_CITY_ID_ASC = 'CITIES_BY_USER_UPDATED__MAX_CITY_ID_ASC',
  CITIES_BY_USER_UPDATED__MAX_CITY_ID_DESC = 'CITIES_BY_USER_UPDATED__MAX_CITY_ID_DESC',
  CITIES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA2_ASC = 'CITIES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA2_ASC',
  CITIES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA2_DESC = 'CITIES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA2_DESC',
  CITIES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'CITIES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  CITIES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'CITIES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  CITIES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'CITIES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  CITIES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'CITIES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  CITIES_BY_USER_UPDATED__MAX_NAME_ASC = 'CITIES_BY_USER_UPDATED__MAX_NAME_ASC',
  CITIES_BY_USER_UPDATED__MAX_NAME_DESC = 'CITIES_BY_USER_UPDATED__MAX_NAME_DESC',
  CITIES_BY_USER_UPDATED__MAX_REMARK_ASC = 'CITIES_BY_USER_UPDATED__MAX_REMARK_ASC',
  CITIES_BY_USER_UPDATED__MAX_REMARK_DESC = 'CITIES_BY_USER_UPDATED__MAX_REMARK_DESC',
  CITIES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'CITIES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  CITIES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'CITIES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  CITIES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'CITIES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  CITIES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'CITIES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  CITIES_BY_USER_UPDATED__MAX_UTC_OFFSET_ASC = 'CITIES_BY_USER_UPDATED__MAX_UTC_OFFSET_ASC',
  CITIES_BY_USER_UPDATED__MAX_UTC_OFFSET_DESC = 'CITIES_BY_USER_UPDATED__MAX_UTC_OFFSET_DESC',
  CITIES_BY_USER_UPDATED__MIN_CITY_ID_ASC = 'CITIES_BY_USER_UPDATED__MIN_CITY_ID_ASC',
  CITIES_BY_USER_UPDATED__MIN_CITY_ID_DESC = 'CITIES_BY_USER_UPDATED__MIN_CITY_ID_DESC',
  CITIES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA2_ASC = 'CITIES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA2_ASC',
  CITIES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA2_DESC = 'CITIES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA2_DESC',
  CITIES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'CITIES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  CITIES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'CITIES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  CITIES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'CITIES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  CITIES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'CITIES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  CITIES_BY_USER_UPDATED__MIN_NAME_ASC = 'CITIES_BY_USER_UPDATED__MIN_NAME_ASC',
  CITIES_BY_USER_UPDATED__MIN_NAME_DESC = 'CITIES_BY_USER_UPDATED__MIN_NAME_DESC',
  CITIES_BY_USER_UPDATED__MIN_REMARK_ASC = 'CITIES_BY_USER_UPDATED__MIN_REMARK_ASC',
  CITIES_BY_USER_UPDATED__MIN_REMARK_DESC = 'CITIES_BY_USER_UPDATED__MIN_REMARK_DESC',
  CITIES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'CITIES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  CITIES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'CITIES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  CITIES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'CITIES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  CITIES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'CITIES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  CITIES_BY_USER_UPDATED__MIN_UTC_OFFSET_ASC = 'CITIES_BY_USER_UPDATED__MIN_UTC_OFFSET_ASC',
  CITIES_BY_USER_UPDATED__MIN_UTC_OFFSET_DESC = 'CITIES_BY_USER_UPDATED__MIN_UTC_OFFSET_DESC',
  COMPANIES_BY_USER_CREATED_AVERAGE_ABBREVIATION_ASC = 'COMPANIES_BY_USER_CREATED_AVERAGE_ABBREVIATION_ASC',
  COMPANIES_BY_USER_CREATED_AVERAGE_ABBREVIATION_DESC = 'COMPANIES_BY_USER_CREATED_AVERAGE_ABBREVIATION_DESC',
  COMPANIES_BY_USER_CREATED_AVERAGE_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_USER_CREATED_AVERAGE_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_USER_CREATED_AVERAGE_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_USER_CREATED_AVERAGE_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_USER_CREATED_AVERAGE_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_USER_CREATED_AVERAGE_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_USER_CREATED_AVERAGE_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_USER_CREATED_AVERAGE_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_USER_CREATED_AVERAGE_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_USER_CREATED_AVERAGE_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_USER_CREATED_AVERAGE_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_USER_CREATED_AVERAGE_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_USER_CREATED_AVERAGE_COMPANY_ID_ASC = 'COMPANIES_BY_USER_CREATED_AVERAGE_COMPANY_ID_ASC',
  COMPANIES_BY_USER_CREATED_AVERAGE_COMPANY_ID_DESC = 'COMPANIES_BY_USER_CREATED_AVERAGE_COMPANY_ID_DESC',
  COMPANIES_BY_USER_CREATED_AVERAGE_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_USER_CREATED_AVERAGE_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_USER_CREATED_AVERAGE_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_USER_CREATED_AVERAGE_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_USER_CREATED_AVERAGE_DATE_CREATED_ASC = 'COMPANIES_BY_USER_CREATED_AVERAGE_DATE_CREATED_ASC',
  COMPANIES_BY_USER_CREATED_AVERAGE_DATE_CREATED_DESC = 'COMPANIES_BY_USER_CREATED_AVERAGE_DATE_CREATED_DESC',
  COMPANIES_BY_USER_CREATED_AVERAGE_DATE_UPDATED_ASC = 'COMPANIES_BY_USER_CREATED_AVERAGE_DATE_UPDATED_ASC',
  COMPANIES_BY_USER_CREATED_AVERAGE_DATE_UPDATED_DESC = 'COMPANIES_BY_USER_CREATED_AVERAGE_DATE_UPDATED_DESC',
  COMPANIES_BY_USER_CREATED_AVERAGE_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_USER_CREATED_AVERAGE_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_USER_CREATED_AVERAGE_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_USER_CREATED_AVERAGE_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_USER_CREATED_AVERAGE_FULL_NAME_ASC = 'COMPANIES_BY_USER_CREATED_AVERAGE_FULL_NAME_ASC',
  COMPANIES_BY_USER_CREATED_AVERAGE_FULL_NAME_DESC = 'COMPANIES_BY_USER_CREATED_AVERAGE_FULL_NAME_DESC',
  COMPANIES_BY_USER_CREATED_AVERAGE_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_USER_CREATED_AVERAGE_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_USER_CREATED_AVERAGE_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_USER_CREATED_AVERAGE_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_USER_CREATED_AVERAGE_REMARK_ASC = 'COMPANIES_BY_USER_CREATED_AVERAGE_REMARK_ASC',
  COMPANIES_BY_USER_CREATED_AVERAGE_REMARK_DESC = 'COMPANIES_BY_USER_CREATED_AVERAGE_REMARK_DESC',
  COMPANIES_BY_USER_CREATED_AVERAGE_USER_CREATED_ASC = 'COMPANIES_BY_USER_CREATED_AVERAGE_USER_CREATED_ASC',
  COMPANIES_BY_USER_CREATED_AVERAGE_USER_CREATED_DESC = 'COMPANIES_BY_USER_CREATED_AVERAGE_USER_CREATED_DESC',
  COMPANIES_BY_USER_CREATED_AVERAGE_USER_UPDATED_ASC = 'COMPANIES_BY_USER_CREATED_AVERAGE_USER_UPDATED_ASC',
  COMPANIES_BY_USER_CREATED_AVERAGE_USER_UPDATED_DESC = 'COMPANIES_BY_USER_CREATED_AVERAGE_USER_UPDATED_DESC',
  COMPANIES_BY_USER_CREATED_COUNT_ASC = 'COMPANIES_BY_USER_CREATED_COUNT_ASC',
  COMPANIES_BY_USER_CREATED_COUNT_DESC = 'COMPANIES_BY_USER_CREATED_COUNT_DESC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_ABBREVIATION_ASC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_ABBREVIATION_ASC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_ABBREVIATION_DESC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_ABBREVIATION_DESC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_COMPANY_ID_ASC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_COMPANY_ID_ASC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_COMPANY_ID_DESC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_COMPANY_ID_DESC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_DATE_CREATED_ASC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_DATE_CREATED_ASC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_DATE_CREATED_DESC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_DATE_CREATED_DESC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_DATE_UPDATED_ASC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_DATE_UPDATED_ASC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_DATE_UPDATED_DESC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_DATE_UPDATED_DESC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_FULL_NAME_ASC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_FULL_NAME_ASC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_FULL_NAME_DESC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_FULL_NAME_DESC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_REMARK_ASC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_REMARK_ASC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_REMARK_DESC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_REMARK_DESC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_USER_CREATED_ASC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_USER_CREATED_ASC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_USER_CREATED_DESC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_USER_CREATED_DESC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_USER_UPDATED_ASC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_USER_UPDATED_ASC',
  COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_USER_UPDATED_DESC = 'COMPANIES_BY_USER_CREATED_DISTINCT_COUNT_USER_UPDATED_DESC',
  COMPANIES_BY_USER_CREATED_MAX_ABBREVIATION_ASC = 'COMPANIES_BY_USER_CREATED_MAX_ABBREVIATION_ASC',
  COMPANIES_BY_USER_CREATED_MAX_ABBREVIATION_DESC = 'COMPANIES_BY_USER_CREATED_MAX_ABBREVIATION_DESC',
  COMPANIES_BY_USER_CREATED_MAX_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_USER_CREATED_MAX_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_USER_CREATED_MAX_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_USER_CREATED_MAX_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_USER_CREATED_MAX_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_USER_CREATED_MAX_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_USER_CREATED_MAX_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_USER_CREATED_MAX_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_USER_CREATED_MAX_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_USER_CREATED_MAX_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_USER_CREATED_MAX_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_USER_CREATED_MAX_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_USER_CREATED_MAX_COMPANY_ID_ASC = 'COMPANIES_BY_USER_CREATED_MAX_COMPANY_ID_ASC',
  COMPANIES_BY_USER_CREATED_MAX_COMPANY_ID_DESC = 'COMPANIES_BY_USER_CREATED_MAX_COMPANY_ID_DESC',
  COMPANIES_BY_USER_CREATED_MAX_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_USER_CREATED_MAX_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_USER_CREATED_MAX_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_USER_CREATED_MAX_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_USER_CREATED_MAX_DATE_CREATED_ASC = 'COMPANIES_BY_USER_CREATED_MAX_DATE_CREATED_ASC',
  COMPANIES_BY_USER_CREATED_MAX_DATE_CREATED_DESC = 'COMPANIES_BY_USER_CREATED_MAX_DATE_CREATED_DESC',
  COMPANIES_BY_USER_CREATED_MAX_DATE_UPDATED_ASC = 'COMPANIES_BY_USER_CREATED_MAX_DATE_UPDATED_ASC',
  COMPANIES_BY_USER_CREATED_MAX_DATE_UPDATED_DESC = 'COMPANIES_BY_USER_CREATED_MAX_DATE_UPDATED_DESC',
  COMPANIES_BY_USER_CREATED_MAX_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_USER_CREATED_MAX_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_USER_CREATED_MAX_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_USER_CREATED_MAX_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_USER_CREATED_MAX_FULL_NAME_ASC = 'COMPANIES_BY_USER_CREATED_MAX_FULL_NAME_ASC',
  COMPANIES_BY_USER_CREATED_MAX_FULL_NAME_DESC = 'COMPANIES_BY_USER_CREATED_MAX_FULL_NAME_DESC',
  COMPANIES_BY_USER_CREATED_MAX_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_USER_CREATED_MAX_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_USER_CREATED_MAX_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_USER_CREATED_MAX_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_USER_CREATED_MAX_REMARK_ASC = 'COMPANIES_BY_USER_CREATED_MAX_REMARK_ASC',
  COMPANIES_BY_USER_CREATED_MAX_REMARK_DESC = 'COMPANIES_BY_USER_CREATED_MAX_REMARK_DESC',
  COMPANIES_BY_USER_CREATED_MAX_USER_CREATED_ASC = 'COMPANIES_BY_USER_CREATED_MAX_USER_CREATED_ASC',
  COMPANIES_BY_USER_CREATED_MAX_USER_CREATED_DESC = 'COMPANIES_BY_USER_CREATED_MAX_USER_CREATED_DESC',
  COMPANIES_BY_USER_CREATED_MAX_USER_UPDATED_ASC = 'COMPANIES_BY_USER_CREATED_MAX_USER_UPDATED_ASC',
  COMPANIES_BY_USER_CREATED_MAX_USER_UPDATED_DESC = 'COMPANIES_BY_USER_CREATED_MAX_USER_UPDATED_DESC',
  COMPANIES_BY_USER_CREATED_MIN_ABBREVIATION_ASC = 'COMPANIES_BY_USER_CREATED_MIN_ABBREVIATION_ASC',
  COMPANIES_BY_USER_CREATED_MIN_ABBREVIATION_DESC = 'COMPANIES_BY_USER_CREATED_MIN_ABBREVIATION_DESC',
  COMPANIES_BY_USER_CREATED_MIN_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_USER_CREATED_MIN_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_USER_CREATED_MIN_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_USER_CREATED_MIN_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_USER_CREATED_MIN_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_USER_CREATED_MIN_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_USER_CREATED_MIN_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_USER_CREATED_MIN_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_USER_CREATED_MIN_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_USER_CREATED_MIN_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_USER_CREATED_MIN_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_USER_CREATED_MIN_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_USER_CREATED_MIN_COMPANY_ID_ASC = 'COMPANIES_BY_USER_CREATED_MIN_COMPANY_ID_ASC',
  COMPANIES_BY_USER_CREATED_MIN_COMPANY_ID_DESC = 'COMPANIES_BY_USER_CREATED_MIN_COMPANY_ID_DESC',
  COMPANIES_BY_USER_CREATED_MIN_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_USER_CREATED_MIN_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_USER_CREATED_MIN_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_USER_CREATED_MIN_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_USER_CREATED_MIN_DATE_CREATED_ASC = 'COMPANIES_BY_USER_CREATED_MIN_DATE_CREATED_ASC',
  COMPANIES_BY_USER_CREATED_MIN_DATE_CREATED_DESC = 'COMPANIES_BY_USER_CREATED_MIN_DATE_CREATED_DESC',
  COMPANIES_BY_USER_CREATED_MIN_DATE_UPDATED_ASC = 'COMPANIES_BY_USER_CREATED_MIN_DATE_UPDATED_ASC',
  COMPANIES_BY_USER_CREATED_MIN_DATE_UPDATED_DESC = 'COMPANIES_BY_USER_CREATED_MIN_DATE_UPDATED_DESC',
  COMPANIES_BY_USER_CREATED_MIN_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_USER_CREATED_MIN_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_USER_CREATED_MIN_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_USER_CREATED_MIN_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_USER_CREATED_MIN_FULL_NAME_ASC = 'COMPANIES_BY_USER_CREATED_MIN_FULL_NAME_ASC',
  COMPANIES_BY_USER_CREATED_MIN_FULL_NAME_DESC = 'COMPANIES_BY_USER_CREATED_MIN_FULL_NAME_DESC',
  COMPANIES_BY_USER_CREATED_MIN_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_USER_CREATED_MIN_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_USER_CREATED_MIN_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_USER_CREATED_MIN_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_USER_CREATED_MIN_REMARK_ASC = 'COMPANIES_BY_USER_CREATED_MIN_REMARK_ASC',
  COMPANIES_BY_USER_CREATED_MIN_REMARK_DESC = 'COMPANIES_BY_USER_CREATED_MIN_REMARK_DESC',
  COMPANIES_BY_USER_CREATED_MIN_USER_CREATED_ASC = 'COMPANIES_BY_USER_CREATED_MIN_USER_CREATED_ASC',
  COMPANIES_BY_USER_CREATED_MIN_USER_CREATED_DESC = 'COMPANIES_BY_USER_CREATED_MIN_USER_CREATED_DESC',
  COMPANIES_BY_USER_CREATED_MIN_USER_UPDATED_ASC = 'COMPANIES_BY_USER_CREATED_MIN_USER_UPDATED_ASC',
  COMPANIES_BY_USER_CREATED_MIN_USER_UPDATED_DESC = 'COMPANIES_BY_USER_CREATED_MIN_USER_UPDATED_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_ABBREVIATION_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_ABBREVIATION_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_ABBREVIATION_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_ABBREVIATION_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_COMPANY_ID_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_COMPANY_ID_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_COMPANY_ID_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_COMPANY_ID_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_DATE_CREATED_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_DATE_CREATED_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_DATE_CREATED_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_DATE_CREATED_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_DATE_UPDATED_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_DATE_UPDATED_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_DATE_UPDATED_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_DATE_UPDATED_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_FULL_NAME_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_FULL_NAME_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_FULL_NAME_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_FULL_NAME_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_REMARK_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_REMARK_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_REMARK_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_REMARK_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_USER_CREATED_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_USER_CREATED_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_USER_CREATED_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_USER_CREATED_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_USER_UPDATED_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_USER_UPDATED_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_USER_UPDATED_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_POPULATION_USER_UPDATED_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_ABBREVIATION_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_ABBREVIATION_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_ABBREVIATION_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_ABBREVIATION_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_COMPANY_ID_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_COMPANY_ID_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_COMPANY_ID_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_COMPANY_ID_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_DATE_CREATED_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_DATE_CREATED_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_DATE_CREATED_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_DATE_CREATED_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_DATE_UPDATED_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_DATE_UPDATED_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_DATE_UPDATED_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_DATE_UPDATED_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_FULL_NAME_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_FULL_NAME_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_FULL_NAME_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_FULL_NAME_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_REMARK_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_REMARK_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_REMARK_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_REMARK_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_USER_CREATED_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_USER_CREATED_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_USER_CREATED_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_USER_CREATED_DESC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_USER_UPDATED_ASC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_USER_UPDATED_ASC',
  COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_USER_UPDATED_DESC = 'COMPANIES_BY_USER_CREATED_STDDEV_SAMPLE_USER_UPDATED_DESC',
  COMPANIES_BY_USER_CREATED_SUM_ABBREVIATION_ASC = 'COMPANIES_BY_USER_CREATED_SUM_ABBREVIATION_ASC',
  COMPANIES_BY_USER_CREATED_SUM_ABBREVIATION_DESC = 'COMPANIES_BY_USER_CREATED_SUM_ABBREVIATION_DESC',
  COMPANIES_BY_USER_CREATED_SUM_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_USER_CREATED_SUM_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_USER_CREATED_SUM_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_USER_CREATED_SUM_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_USER_CREATED_SUM_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_USER_CREATED_SUM_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_USER_CREATED_SUM_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_USER_CREATED_SUM_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_USER_CREATED_SUM_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_USER_CREATED_SUM_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_USER_CREATED_SUM_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_USER_CREATED_SUM_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_USER_CREATED_SUM_COMPANY_ID_ASC = 'COMPANIES_BY_USER_CREATED_SUM_COMPANY_ID_ASC',
  COMPANIES_BY_USER_CREATED_SUM_COMPANY_ID_DESC = 'COMPANIES_BY_USER_CREATED_SUM_COMPANY_ID_DESC',
  COMPANIES_BY_USER_CREATED_SUM_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_USER_CREATED_SUM_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_USER_CREATED_SUM_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_USER_CREATED_SUM_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_USER_CREATED_SUM_DATE_CREATED_ASC = 'COMPANIES_BY_USER_CREATED_SUM_DATE_CREATED_ASC',
  COMPANIES_BY_USER_CREATED_SUM_DATE_CREATED_DESC = 'COMPANIES_BY_USER_CREATED_SUM_DATE_CREATED_DESC',
  COMPANIES_BY_USER_CREATED_SUM_DATE_UPDATED_ASC = 'COMPANIES_BY_USER_CREATED_SUM_DATE_UPDATED_ASC',
  COMPANIES_BY_USER_CREATED_SUM_DATE_UPDATED_DESC = 'COMPANIES_BY_USER_CREATED_SUM_DATE_UPDATED_DESC',
  COMPANIES_BY_USER_CREATED_SUM_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_USER_CREATED_SUM_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_USER_CREATED_SUM_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_USER_CREATED_SUM_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_USER_CREATED_SUM_FULL_NAME_ASC = 'COMPANIES_BY_USER_CREATED_SUM_FULL_NAME_ASC',
  COMPANIES_BY_USER_CREATED_SUM_FULL_NAME_DESC = 'COMPANIES_BY_USER_CREATED_SUM_FULL_NAME_DESC',
  COMPANIES_BY_USER_CREATED_SUM_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_USER_CREATED_SUM_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_USER_CREATED_SUM_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_USER_CREATED_SUM_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_USER_CREATED_SUM_REMARK_ASC = 'COMPANIES_BY_USER_CREATED_SUM_REMARK_ASC',
  COMPANIES_BY_USER_CREATED_SUM_REMARK_DESC = 'COMPANIES_BY_USER_CREATED_SUM_REMARK_DESC',
  COMPANIES_BY_USER_CREATED_SUM_USER_CREATED_ASC = 'COMPANIES_BY_USER_CREATED_SUM_USER_CREATED_ASC',
  COMPANIES_BY_USER_CREATED_SUM_USER_CREATED_DESC = 'COMPANIES_BY_USER_CREATED_SUM_USER_CREATED_DESC',
  COMPANIES_BY_USER_CREATED_SUM_USER_UPDATED_ASC = 'COMPANIES_BY_USER_CREATED_SUM_USER_UPDATED_ASC',
  COMPANIES_BY_USER_CREATED_SUM_USER_UPDATED_DESC = 'COMPANIES_BY_USER_CREATED_SUM_USER_UPDATED_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_ABBREVIATION_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_ABBREVIATION_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_ABBREVIATION_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_ABBREVIATION_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_COMPANY_ID_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_COMPANY_ID_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_COMPANY_ID_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_COMPANY_ID_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_DATE_CREATED_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_DATE_CREATED_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_DATE_CREATED_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_DATE_CREATED_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_DATE_UPDATED_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_DATE_UPDATED_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_DATE_UPDATED_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_DATE_UPDATED_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_FULL_NAME_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_FULL_NAME_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_FULL_NAME_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_FULL_NAME_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_REMARK_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_REMARK_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_REMARK_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_REMARK_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_USER_CREATED_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_USER_CREATED_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_USER_CREATED_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_USER_CREATED_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_USER_UPDATED_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_USER_UPDATED_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_USER_UPDATED_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_POPULATION_USER_UPDATED_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_ABBREVIATION_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_ABBREVIATION_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_ABBREVIATION_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_ABBREVIATION_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_COMPANY_ID_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_COMPANY_ID_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_DATE_CREATED_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_DATE_CREATED_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_DATE_CREATED_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_DATE_CREATED_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_DATE_UPDATED_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_DATE_UPDATED_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_DATE_UPDATED_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_DATE_UPDATED_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_FULL_NAME_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_FULL_NAME_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_FULL_NAME_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_FULL_NAME_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_REMARK_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_REMARK_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_REMARK_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_REMARK_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_USER_CREATED_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_USER_CREATED_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_USER_CREATED_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_USER_CREATED_DESC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_USER_UPDATED_ASC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_USER_UPDATED_ASC',
  COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_USER_UPDATED_DESC = 'COMPANIES_BY_USER_CREATED_VARIANCE_SAMPLE_USER_UPDATED_DESC',
  COMPANIES_BY_USER_CREATED__COUNT_ASC = 'COMPANIES_BY_USER_CREATED__COUNT_ASC',
  COMPANIES_BY_USER_CREATED__COUNT_DESC = 'COMPANIES_BY_USER_CREATED__COUNT_DESC',
  COMPANIES_BY_USER_CREATED__MAX_ABBREVIATION_ASC = 'COMPANIES_BY_USER_CREATED__MAX_ABBREVIATION_ASC',
  COMPANIES_BY_USER_CREATED__MAX_ABBREVIATION_DESC = 'COMPANIES_BY_USER_CREATED__MAX_ABBREVIATION_DESC',
  COMPANIES_BY_USER_CREATED__MAX_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_USER_CREATED__MAX_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_USER_CREATED__MAX_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_USER_CREATED__MAX_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_USER_CREATED__MAX_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_USER_CREATED__MAX_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_USER_CREATED__MAX_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_USER_CREATED__MAX_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_USER_CREATED__MAX_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_USER_CREATED__MAX_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_USER_CREATED__MAX_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_USER_CREATED__MAX_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_USER_CREATED__MAX_COMPANY_ID_ASC = 'COMPANIES_BY_USER_CREATED__MAX_COMPANY_ID_ASC',
  COMPANIES_BY_USER_CREATED__MAX_COMPANY_ID_DESC = 'COMPANIES_BY_USER_CREATED__MAX_COMPANY_ID_DESC',
  COMPANIES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'COMPANIES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  COMPANIES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'COMPANIES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  COMPANIES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'COMPANIES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  COMPANIES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'COMPANIES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  COMPANIES_BY_USER_CREATED__MAX_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_USER_CREATED__MAX_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_USER_CREATED__MAX_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_USER_CREATED__MAX_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_USER_CREATED__MAX_FULL_NAME_ASC = 'COMPANIES_BY_USER_CREATED__MAX_FULL_NAME_ASC',
  COMPANIES_BY_USER_CREATED__MAX_FULL_NAME_DESC = 'COMPANIES_BY_USER_CREATED__MAX_FULL_NAME_DESC',
  COMPANIES_BY_USER_CREATED__MAX_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_USER_CREATED__MAX_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_USER_CREATED__MAX_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_USER_CREATED__MAX_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_USER_CREATED__MAX_REMARK_ASC = 'COMPANIES_BY_USER_CREATED__MAX_REMARK_ASC',
  COMPANIES_BY_USER_CREATED__MAX_REMARK_DESC = 'COMPANIES_BY_USER_CREATED__MAX_REMARK_DESC',
  COMPANIES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'COMPANIES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  COMPANIES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'COMPANIES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  COMPANIES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'COMPANIES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  COMPANIES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'COMPANIES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  COMPANIES_BY_USER_CREATED__MIN_ABBREVIATION_ASC = 'COMPANIES_BY_USER_CREATED__MIN_ABBREVIATION_ASC',
  COMPANIES_BY_USER_CREATED__MIN_ABBREVIATION_DESC = 'COMPANIES_BY_USER_CREATED__MIN_ABBREVIATION_DESC',
  COMPANIES_BY_USER_CREATED__MIN_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_USER_CREATED__MIN_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_USER_CREATED__MIN_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_USER_CREATED__MIN_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_USER_CREATED__MIN_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_USER_CREATED__MIN_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_USER_CREATED__MIN_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_USER_CREATED__MIN_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_USER_CREATED__MIN_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_USER_CREATED__MIN_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_USER_CREATED__MIN_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_USER_CREATED__MIN_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_USER_CREATED__MIN_COMPANY_ID_ASC = 'COMPANIES_BY_USER_CREATED__MIN_COMPANY_ID_ASC',
  COMPANIES_BY_USER_CREATED__MIN_COMPANY_ID_DESC = 'COMPANIES_BY_USER_CREATED__MIN_COMPANY_ID_DESC',
  COMPANIES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'COMPANIES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  COMPANIES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'COMPANIES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  COMPANIES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'COMPANIES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  COMPANIES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'COMPANIES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  COMPANIES_BY_USER_CREATED__MIN_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_USER_CREATED__MIN_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_USER_CREATED__MIN_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_USER_CREATED__MIN_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_USER_CREATED__MIN_FULL_NAME_ASC = 'COMPANIES_BY_USER_CREATED__MIN_FULL_NAME_ASC',
  COMPANIES_BY_USER_CREATED__MIN_FULL_NAME_DESC = 'COMPANIES_BY_USER_CREATED__MIN_FULL_NAME_DESC',
  COMPANIES_BY_USER_CREATED__MIN_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_USER_CREATED__MIN_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_USER_CREATED__MIN_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_USER_CREATED__MIN_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_USER_CREATED__MIN_REMARK_ASC = 'COMPANIES_BY_USER_CREATED__MIN_REMARK_ASC',
  COMPANIES_BY_USER_CREATED__MIN_REMARK_DESC = 'COMPANIES_BY_USER_CREATED__MIN_REMARK_DESC',
  COMPANIES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'COMPANIES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  COMPANIES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'COMPANIES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  COMPANIES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'COMPANIES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  COMPANIES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'COMPANIES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_ABBREVIATION_ASC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_ABBREVIATION_ASC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_ABBREVIATION_DESC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_ABBREVIATION_DESC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_COMPANY_ID_ASC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_COMPANY_ID_ASC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_COMPANY_ID_DESC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_COMPANY_ID_DESC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_DATE_CREATED_ASC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_DATE_CREATED_ASC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_DATE_CREATED_DESC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_DATE_CREATED_DESC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_DATE_UPDATED_ASC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_DATE_UPDATED_ASC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_DATE_UPDATED_DESC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_DATE_UPDATED_DESC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_FULL_NAME_ASC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_FULL_NAME_ASC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_FULL_NAME_DESC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_FULL_NAME_DESC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_REMARK_ASC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_REMARK_ASC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_REMARK_DESC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_REMARK_DESC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_USER_CREATED_ASC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_USER_CREATED_ASC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_USER_CREATED_DESC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_USER_CREATED_DESC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_USER_UPDATED_ASC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_USER_UPDATED_ASC',
  COMPANIES_BY_USER_UPDATED_AVERAGE_USER_UPDATED_DESC = 'COMPANIES_BY_USER_UPDATED_AVERAGE_USER_UPDATED_DESC',
  COMPANIES_BY_USER_UPDATED_COUNT_ASC = 'COMPANIES_BY_USER_UPDATED_COUNT_ASC',
  COMPANIES_BY_USER_UPDATED_COUNT_DESC = 'COMPANIES_BY_USER_UPDATED_COUNT_DESC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_ABBREVIATION_ASC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_ABBREVIATION_ASC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_ABBREVIATION_DESC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_ABBREVIATION_DESC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_COMPANY_ID_ASC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_COMPANY_ID_ASC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_COMPANY_ID_DESC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_COMPANY_ID_DESC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_DATE_CREATED_ASC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_DATE_CREATED_ASC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_DATE_CREATED_DESC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_DATE_CREATED_DESC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_DATE_UPDATED_ASC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_DATE_UPDATED_ASC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_DATE_UPDATED_DESC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_DATE_UPDATED_DESC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_FULL_NAME_ASC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_FULL_NAME_ASC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_FULL_NAME_DESC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_FULL_NAME_DESC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_REMARK_ASC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_REMARK_ASC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_REMARK_DESC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_REMARK_DESC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_USER_CREATED_ASC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_USER_CREATED_ASC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_USER_CREATED_DESC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_USER_CREATED_DESC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_USER_UPDATED_ASC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_USER_UPDATED_ASC',
  COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_USER_UPDATED_DESC = 'COMPANIES_BY_USER_UPDATED_DISTINCT_COUNT_USER_UPDATED_DESC',
  COMPANIES_BY_USER_UPDATED_MAX_ABBREVIATION_ASC = 'COMPANIES_BY_USER_UPDATED_MAX_ABBREVIATION_ASC',
  COMPANIES_BY_USER_UPDATED_MAX_ABBREVIATION_DESC = 'COMPANIES_BY_USER_UPDATED_MAX_ABBREVIATION_DESC',
  COMPANIES_BY_USER_UPDATED_MAX_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_USER_UPDATED_MAX_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_USER_UPDATED_MAX_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_USER_UPDATED_MAX_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_USER_UPDATED_MAX_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_USER_UPDATED_MAX_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_USER_UPDATED_MAX_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_USER_UPDATED_MAX_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_USER_UPDATED_MAX_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_USER_UPDATED_MAX_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_USER_UPDATED_MAX_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_USER_UPDATED_MAX_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_USER_UPDATED_MAX_COMPANY_ID_ASC = 'COMPANIES_BY_USER_UPDATED_MAX_COMPANY_ID_ASC',
  COMPANIES_BY_USER_UPDATED_MAX_COMPANY_ID_DESC = 'COMPANIES_BY_USER_UPDATED_MAX_COMPANY_ID_DESC',
  COMPANIES_BY_USER_UPDATED_MAX_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_USER_UPDATED_MAX_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_USER_UPDATED_MAX_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_USER_UPDATED_MAX_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_USER_UPDATED_MAX_DATE_CREATED_ASC = 'COMPANIES_BY_USER_UPDATED_MAX_DATE_CREATED_ASC',
  COMPANIES_BY_USER_UPDATED_MAX_DATE_CREATED_DESC = 'COMPANIES_BY_USER_UPDATED_MAX_DATE_CREATED_DESC',
  COMPANIES_BY_USER_UPDATED_MAX_DATE_UPDATED_ASC = 'COMPANIES_BY_USER_UPDATED_MAX_DATE_UPDATED_ASC',
  COMPANIES_BY_USER_UPDATED_MAX_DATE_UPDATED_DESC = 'COMPANIES_BY_USER_UPDATED_MAX_DATE_UPDATED_DESC',
  COMPANIES_BY_USER_UPDATED_MAX_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_USER_UPDATED_MAX_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_USER_UPDATED_MAX_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_USER_UPDATED_MAX_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_USER_UPDATED_MAX_FULL_NAME_ASC = 'COMPANIES_BY_USER_UPDATED_MAX_FULL_NAME_ASC',
  COMPANIES_BY_USER_UPDATED_MAX_FULL_NAME_DESC = 'COMPANIES_BY_USER_UPDATED_MAX_FULL_NAME_DESC',
  COMPANIES_BY_USER_UPDATED_MAX_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_USER_UPDATED_MAX_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_USER_UPDATED_MAX_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_USER_UPDATED_MAX_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_USER_UPDATED_MAX_REMARK_ASC = 'COMPANIES_BY_USER_UPDATED_MAX_REMARK_ASC',
  COMPANIES_BY_USER_UPDATED_MAX_REMARK_DESC = 'COMPANIES_BY_USER_UPDATED_MAX_REMARK_DESC',
  COMPANIES_BY_USER_UPDATED_MAX_USER_CREATED_ASC = 'COMPANIES_BY_USER_UPDATED_MAX_USER_CREATED_ASC',
  COMPANIES_BY_USER_UPDATED_MAX_USER_CREATED_DESC = 'COMPANIES_BY_USER_UPDATED_MAX_USER_CREATED_DESC',
  COMPANIES_BY_USER_UPDATED_MAX_USER_UPDATED_ASC = 'COMPANIES_BY_USER_UPDATED_MAX_USER_UPDATED_ASC',
  COMPANIES_BY_USER_UPDATED_MAX_USER_UPDATED_DESC = 'COMPANIES_BY_USER_UPDATED_MAX_USER_UPDATED_DESC',
  COMPANIES_BY_USER_UPDATED_MIN_ABBREVIATION_ASC = 'COMPANIES_BY_USER_UPDATED_MIN_ABBREVIATION_ASC',
  COMPANIES_BY_USER_UPDATED_MIN_ABBREVIATION_DESC = 'COMPANIES_BY_USER_UPDATED_MIN_ABBREVIATION_DESC',
  COMPANIES_BY_USER_UPDATED_MIN_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_USER_UPDATED_MIN_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_USER_UPDATED_MIN_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_USER_UPDATED_MIN_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_USER_UPDATED_MIN_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_USER_UPDATED_MIN_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_USER_UPDATED_MIN_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_USER_UPDATED_MIN_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_USER_UPDATED_MIN_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_USER_UPDATED_MIN_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_USER_UPDATED_MIN_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_USER_UPDATED_MIN_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_USER_UPDATED_MIN_COMPANY_ID_ASC = 'COMPANIES_BY_USER_UPDATED_MIN_COMPANY_ID_ASC',
  COMPANIES_BY_USER_UPDATED_MIN_COMPANY_ID_DESC = 'COMPANIES_BY_USER_UPDATED_MIN_COMPANY_ID_DESC',
  COMPANIES_BY_USER_UPDATED_MIN_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_USER_UPDATED_MIN_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_USER_UPDATED_MIN_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_USER_UPDATED_MIN_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_USER_UPDATED_MIN_DATE_CREATED_ASC = 'COMPANIES_BY_USER_UPDATED_MIN_DATE_CREATED_ASC',
  COMPANIES_BY_USER_UPDATED_MIN_DATE_CREATED_DESC = 'COMPANIES_BY_USER_UPDATED_MIN_DATE_CREATED_DESC',
  COMPANIES_BY_USER_UPDATED_MIN_DATE_UPDATED_ASC = 'COMPANIES_BY_USER_UPDATED_MIN_DATE_UPDATED_ASC',
  COMPANIES_BY_USER_UPDATED_MIN_DATE_UPDATED_DESC = 'COMPANIES_BY_USER_UPDATED_MIN_DATE_UPDATED_DESC',
  COMPANIES_BY_USER_UPDATED_MIN_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_USER_UPDATED_MIN_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_USER_UPDATED_MIN_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_USER_UPDATED_MIN_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_USER_UPDATED_MIN_FULL_NAME_ASC = 'COMPANIES_BY_USER_UPDATED_MIN_FULL_NAME_ASC',
  COMPANIES_BY_USER_UPDATED_MIN_FULL_NAME_DESC = 'COMPANIES_BY_USER_UPDATED_MIN_FULL_NAME_DESC',
  COMPANIES_BY_USER_UPDATED_MIN_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_USER_UPDATED_MIN_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_USER_UPDATED_MIN_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_USER_UPDATED_MIN_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_USER_UPDATED_MIN_REMARK_ASC = 'COMPANIES_BY_USER_UPDATED_MIN_REMARK_ASC',
  COMPANIES_BY_USER_UPDATED_MIN_REMARK_DESC = 'COMPANIES_BY_USER_UPDATED_MIN_REMARK_DESC',
  COMPANIES_BY_USER_UPDATED_MIN_USER_CREATED_ASC = 'COMPANIES_BY_USER_UPDATED_MIN_USER_CREATED_ASC',
  COMPANIES_BY_USER_UPDATED_MIN_USER_CREATED_DESC = 'COMPANIES_BY_USER_UPDATED_MIN_USER_CREATED_DESC',
  COMPANIES_BY_USER_UPDATED_MIN_USER_UPDATED_ASC = 'COMPANIES_BY_USER_UPDATED_MIN_USER_UPDATED_ASC',
  COMPANIES_BY_USER_UPDATED_MIN_USER_UPDATED_DESC = 'COMPANIES_BY_USER_UPDATED_MIN_USER_UPDATED_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_ABBREVIATION_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_ABBREVIATION_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_ABBREVIATION_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_ABBREVIATION_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_COMPANY_ID_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_COMPANY_ID_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_COMPANY_ID_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_COMPANY_ID_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_DATE_CREATED_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_DATE_CREATED_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_DATE_CREATED_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_DATE_CREATED_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_DATE_UPDATED_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_DATE_UPDATED_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_DATE_UPDATED_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_DATE_UPDATED_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_FULL_NAME_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_FULL_NAME_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_FULL_NAME_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_FULL_NAME_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_REMARK_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_REMARK_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_REMARK_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_REMARK_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_USER_CREATED_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_USER_CREATED_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_USER_CREATED_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_USER_CREATED_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_USER_UPDATED_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_USER_UPDATED_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_USER_UPDATED_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_POPULATION_USER_UPDATED_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_ABBREVIATION_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_ABBREVIATION_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_ABBREVIATION_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_ABBREVIATION_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_COMPANY_ID_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_COMPANY_ID_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_COMPANY_ID_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_COMPANY_ID_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_DATE_CREATED_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_DATE_CREATED_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_DATE_CREATED_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_DATE_CREATED_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_DATE_UPDATED_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_DATE_UPDATED_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_DATE_UPDATED_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_DATE_UPDATED_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_FULL_NAME_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_FULL_NAME_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_FULL_NAME_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_FULL_NAME_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_REMARK_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_REMARK_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_REMARK_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_REMARK_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_USER_CREATED_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_USER_CREATED_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_USER_CREATED_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_USER_CREATED_DESC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_USER_UPDATED_ASC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_USER_UPDATED_ASC',
  COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_USER_UPDATED_DESC = 'COMPANIES_BY_USER_UPDATED_STDDEV_SAMPLE_USER_UPDATED_DESC',
  COMPANIES_BY_USER_UPDATED_SUM_ABBREVIATION_ASC = 'COMPANIES_BY_USER_UPDATED_SUM_ABBREVIATION_ASC',
  COMPANIES_BY_USER_UPDATED_SUM_ABBREVIATION_DESC = 'COMPANIES_BY_USER_UPDATED_SUM_ABBREVIATION_DESC',
  COMPANIES_BY_USER_UPDATED_SUM_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_USER_UPDATED_SUM_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_USER_UPDATED_SUM_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_USER_UPDATED_SUM_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_USER_UPDATED_SUM_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_USER_UPDATED_SUM_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_USER_UPDATED_SUM_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_USER_UPDATED_SUM_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_USER_UPDATED_SUM_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_USER_UPDATED_SUM_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_USER_UPDATED_SUM_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_USER_UPDATED_SUM_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_USER_UPDATED_SUM_COMPANY_ID_ASC = 'COMPANIES_BY_USER_UPDATED_SUM_COMPANY_ID_ASC',
  COMPANIES_BY_USER_UPDATED_SUM_COMPANY_ID_DESC = 'COMPANIES_BY_USER_UPDATED_SUM_COMPANY_ID_DESC',
  COMPANIES_BY_USER_UPDATED_SUM_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_USER_UPDATED_SUM_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_USER_UPDATED_SUM_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_USER_UPDATED_SUM_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_USER_UPDATED_SUM_DATE_CREATED_ASC = 'COMPANIES_BY_USER_UPDATED_SUM_DATE_CREATED_ASC',
  COMPANIES_BY_USER_UPDATED_SUM_DATE_CREATED_DESC = 'COMPANIES_BY_USER_UPDATED_SUM_DATE_CREATED_DESC',
  COMPANIES_BY_USER_UPDATED_SUM_DATE_UPDATED_ASC = 'COMPANIES_BY_USER_UPDATED_SUM_DATE_UPDATED_ASC',
  COMPANIES_BY_USER_UPDATED_SUM_DATE_UPDATED_DESC = 'COMPANIES_BY_USER_UPDATED_SUM_DATE_UPDATED_DESC',
  COMPANIES_BY_USER_UPDATED_SUM_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_USER_UPDATED_SUM_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_USER_UPDATED_SUM_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_USER_UPDATED_SUM_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_USER_UPDATED_SUM_FULL_NAME_ASC = 'COMPANIES_BY_USER_UPDATED_SUM_FULL_NAME_ASC',
  COMPANIES_BY_USER_UPDATED_SUM_FULL_NAME_DESC = 'COMPANIES_BY_USER_UPDATED_SUM_FULL_NAME_DESC',
  COMPANIES_BY_USER_UPDATED_SUM_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_USER_UPDATED_SUM_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_USER_UPDATED_SUM_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_USER_UPDATED_SUM_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_USER_UPDATED_SUM_REMARK_ASC = 'COMPANIES_BY_USER_UPDATED_SUM_REMARK_ASC',
  COMPANIES_BY_USER_UPDATED_SUM_REMARK_DESC = 'COMPANIES_BY_USER_UPDATED_SUM_REMARK_DESC',
  COMPANIES_BY_USER_UPDATED_SUM_USER_CREATED_ASC = 'COMPANIES_BY_USER_UPDATED_SUM_USER_CREATED_ASC',
  COMPANIES_BY_USER_UPDATED_SUM_USER_CREATED_DESC = 'COMPANIES_BY_USER_UPDATED_SUM_USER_CREATED_DESC',
  COMPANIES_BY_USER_UPDATED_SUM_USER_UPDATED_ASC = 'COMPANIES_BY_USER_UPDATED_SUM_USER_UPDATED_ASC',
  COMPANIES_BY_USER_UPDATED_SUM_USER_UPDATED_DESC = 'COMPANIES_BY_USER_UPDATED_SUM_USER_UPDATED_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_ABBREVIATION_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_ABBREVIATION_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_ABBREVIATION_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_ABBREVIATION_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_COMPANY_ID_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_COMPANY_ID_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_COMPANY_ID_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_COMPANY_ID_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_DATE_CREATED_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_DATE_CREATED_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_DATE_CREATED_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_DATE_CREATED_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_DATE_UPDATED_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_DATE_UPDATED_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_DATE_UPDATED_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_DATE_UPDATED_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_FULL_NAME_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_FULL_NAME_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_FULL_NAME_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_FULL_NAME_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_REMARK_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_REMARK_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_REMARK_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_REMARK_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_USER_CREATED_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_USER_CREATED_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_USER_CREATED_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_USER_CREATED_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_USER_UPDATED_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_USER_UPDATED_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_USER_UPDATED_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_POPULATION_USER_UPDATED_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_ABBREVIATION_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_ABBREVIATION_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_ABBREVIATION_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_ABBREVIATION_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_COMPANY_ID_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_COMPANY_ID_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_DATE_CREATED_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_DATE_CREATED_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_DATE_CREATED_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_DATE_CREATED_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_DATE_UPDATED_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_DATE_UPDATED_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_DATE_UPDATED_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_DATE_UPDATED_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_FULL_NAME_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_FULL_NAME_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_FULL_NAME_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_FULL_NAME_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_REMARK_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_REMARK_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_REMARK_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_REMARK_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_USER_CREATED_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_USER_CREATED_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_USER_CREATED_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_USER_CREATED_DESC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_USER_UPDATED_ASC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_USER_UPDATED_ASC',
  COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_USER_UPDATED_DESC = 'COMPANIES_BY_USER_UPDATED_VARIANCE_SAMPLE_USER_UPDATED_DESC',
  COMPANIES_BY_USER_UPDATED__COUNT_ASC = 'COMPANIES_BY_USER_UPDATED__COUNT_ASC',
  COMPANIES_BY_USER_UPDATED__COUNT_DESC = 'COMPANIES_BY_USER_UPDATED__COUNT_DESC',
  COMPANIES_BY_USER_UPDATED__MAX_ABBREVIATION_ASC = 'COMPANIES_BY_USER_UPDATED__MAX_ABBREVIATION_ASC',
  COMPANIES_BY_USER_UPDATED__MAX_ABBREVIATION_DESC = 'COMPANIES_BY_USER_UPDATED__MAX_ABBREVIATION_DESC',
  COMPANIES_BY_USER_UPDATED__MAX_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_USER_UPDATED__MAX_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_USER_UPDATED__MAX_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_USER_UPDATED__MAX_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_USER_UPDATED__MAX_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_USER_UPDATED__MAX_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_USER_UPDATED__MAX_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_USER_UPDATED__MAX_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_USER_UPDATED__MAX_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_USER_UPDATED__MAX_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_USER_UPDATED__MAX_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_USER_UPDATED__MAX_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_USER_UPDATED__MAX_COMPANY_ID_ASC = 'COMPANIES_BY_USER_UPDATED__MAX_COMPANY_ID_ASC',
  COMPANIES_BY_USER_UPDATED__MAX_COMPANY_ID_DESC = 'COMPANIES_BY_USER_UPDATED__MAX_COMPANY_ID_DESC',
  COMPANIES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'COMPANIES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  COMPANIES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'COMPANIES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  COMPANIES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'COMPANIES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  COMPANIES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'COMPANIES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  COMPANIES_BY_USER_UPDATED__MAX_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_USER_UPDATED__MAX_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_USER_UPDATED__MAX_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_USER_UPDATED__MAX_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_USER_UPDATED__MAX_FULL_NAME_ASC = 'COMPANIES_BY_USER_UPDATED__MAX_FULL_NAME_ASC',
  COMPANIES_BY_USER_UPDATED__MAX_FULL_NAME_DESC = 'COMPANIES_BY_USER_UPDATED__MAX_FULL_NAME_DESC',
  COMPANIES_BY_USER_UPDATED__MAX_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_USER_UPDATED__MAX_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_USER_UPDATED__MAX_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_USER_UPDATED__MAX_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_USER_UPDATED__MAX_REMARK_ASC = 'COMPANIES_BY_USER_UPDATED__MAX_REMARK_ASC',
  COMPANIES_BY_USER_UPDATED__MAX_REMARK_DESC = 'COMPANIES_BY_USER_UPDATED__MAX_REMARK_DESC',
  COMPANIES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'COMPANIES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  COMPANIES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'COMPANIES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  COMPANIES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'COMPANIES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  COMPANIES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'COMPANIES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  COMPANIES_BY_USER_UPDATED__MIN_ABBREVIATION_ASC = 'COMPANIES_BY_USER_UPDATED__MIN_ABBREVIATION_ASC',
  COMPANIES_BY_USER_UPDATED__MIN_ABBREVIATION_DESC = 'COMPANIES_BY_USER_UPDATED__MIN_ABBREVIATION_DESC',
  COMPANIES_BY_USER_UPDATED__MIN_COMPANY_CLOSED_DATE_ASC = 'COMPANIES_BY_USER_UPDATED__MIN_COMPANY_CLOSED_DATE_ASC',
  COMPANIES_BY_USER_UPDATED__MIN_COMPANY_CLOSED_DATE_DESC = 'COMPANIES_BY_USER_UPDATED__MIN_COMPANY_CLOSED_DATE_DESC',
  COMPANIES_BY_USER_UPDATED__MIN_COMPANY_ESTABLISHED_DATE_ASC = 'COMPANIES_BY_USER_UPDATED__MIN_COMPANY_ESTABLISHED_DATE_ASC',
  COMPANIES_BY_USER_UPDATED__MIN_COMPANY_ESTABLISHED_DATE_DESC = 'COMPANIES_BY_USER_UPDATED__MIN_COMPANY_ESTABLISHED_DATE_DESC',
  COMPANIES_BY_USER_UPDATED__MIN_COMPANY_GROUP_ID_ASC = 'COMPANIES_BY_USER_UPDATED__MIN_COMPANY_GROUP_ID_ASC',
  COMPANIES_BY_USER_UPDATED__MIN_COMPANY_GROUP_ID_DESC = 'COMPANIES_BY_USER_UPDATED__MIN_COMPANY_GROUP_ID_DESC',
  COMPANIES_BY_USER_UPDATED__MIN_COMPANY_ID_ASC = 'COMPANIES_BY_USER_UPDATED__MIN_COMPANY_ID_ASC',
  COMPANIES_BY_USER_UPDATED__MIN_COMPANY_ID_DESC = 'COMPANIES_BY_USER_UPDATED__MIN_COMPANY_ID_DESC',
  COMPANIES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA2_ASC = 'COMPANIES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA2_ASC',
  COMPANIES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA2_DESC = 'COMPANIES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA2_DESC',
  COMPANIES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'COMPANIES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  COMPANIES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'COMPANIES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  COMPANIES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'COMPANIES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  COMPANIES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'COMPANIES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  COMPANIES_BY_USER_UPDATED__MIN_EMPLOYEE_CODE_PREFIX_ASC = 'COMPANIES_BY_USER_UPDATED__MIN_EMPLOYEE_CODE_PREFIX_ASC',
  COMPANIES_BY_USER_UPDATED__MIN_EMPLOYEE_CODE_PREFIX_DESC = 'COMPANIES_BY_USER_UPDATED__MIN_EMPLOYEE_CODE_PREFIX_DESC',
  COMPANIES_BY_USER_UPDATED__MIN_FULL_NAME_ASC = 'COMPANIES_BY_USER_UPDATED__MIN_FULL_NAME_ASC',
  COMPANIES_BY_USER_UPDATED__MIN_FULL_NAME_DESC = 'COMPANIES_BY_USER_UPDATED__MIN_FULL_NAME_DESC',
  COMPANIES_BY_USER_UPDATED__MIN_NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANIES_BY_USER_UPDATED__MIN_NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANIES_BY_USER_UPDATED__MIN_NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANIES_BY_USER_UPDATED__MIN_NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANIES_BY_USER_UPDATED__MIN_REMARK_ASC = 'COMPANIES_BY_USER_UPDATED__MIN_REMARK_ASC',
  COMPANIES_BY_USER_UPDATED__MIN_REMARK_DESC = 'COMPANIES_BY_USER_UPDATED__MIN_REMARK_DESC',
  COMPANIES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'COMPANIES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  COMPANIES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'COMPANIES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  COMPANIES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'COMPANIES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  COMPANIES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'COMPANIES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__COUNT_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__COUNT_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__COUNT_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__COUNT_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MAX_COMPANY_ID_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MAX_COMPANY_ID_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MAX_COMPANY_ID_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MAX_COMPANY_ID_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MAX_REMARK_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MAX_REMARK_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MAX_REMARK_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MAX_REMARK_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MIN_COMPANY_ID_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MIN_COMPANY_ID_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MIN_COMPANY_ID_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MIN_COMPANY_ID_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MIN_REMARK_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MIN_REMARK_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MIN_REMARK_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MIN_REMARK_DESC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'COMPANY_EXTERNAL_ACCOUNT_DISABLES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__COUNT_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__COUNT_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__COUNT_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__COUNT_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_COMPANY_GLOBAL_HR_ID_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_COMPANY_GLOBAL_HR_ID_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_COMPANY_GLOBAL_HR_ID_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_COMPANY_GLOBAL_HR_ID_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_REMARK_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_REMARK_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_REMARK_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_REMARK_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_COMPANY_GLOBAL_HR_ID_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_COMPANY_GLOBAL_HR_ID_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_COMPANY_GLOBAL_HR_ID_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_COMPANY_GLOBAL_HR_ID_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_REMARK_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_REMARK_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_REMARK_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_REMARK_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__COUNT_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__COUNT_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__COUNT_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__COUNT_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_COMPANY_GLOBAL_HR_ID_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_COMPANY_GLOBAL_HR_ID_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_COMPANY_GLOBAL_HR_ID_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_COMPANY_GLOBAL_HR_ID_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_REMARK_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_REMARK_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_REMARK_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_REMARK_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_COMPANY_GLOBAL_HR_ID_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_COMPANY_GLOBAL_HR_ID_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_COMPANY_GLOBAL_HR_ID_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_COMPANY_GLOBAL_HR_ID_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_REMARK_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_REMARK_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_REMARK_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_REMARK_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'COMPANY_GLOBAL_HRS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  COMPANY_GROUPS_BY_USER_CREATED__COUNT_ASC = 'COMPANY_GROUPS_BY_USER_CREATED__COUNT_ASC',
  COMPANY_GROUPS_BY_USER_CREATED__COUNT_DESC = 'COMPANY_GROUPS_BY_USER_CREATED__COUNT_DESC',
  COMPANY_GROUPS_BY_USER_CREATED__MAX_COMPANY_GROUP_ID_ASC = 'COMPANY_GROUPS_BY_USER_CREATED__MAX_COMPANY_GROUP_ID_ASC',
  COMPANY_GROUPS_BY_USER_CREATED__MAX_COMPANY_GROUP_ID_DESC = 'COMPANY_GROUPS_BY_USER_CREATED__MAX_COMPANY_GROUP_ID_DESC',
  COMPANY_GROUPS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'COMPANY_GROUPS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  COMPANY_GROUPS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'COMPANY_GROUPS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  COMPANY_GROUPS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'COMPANY_GROUPS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  COMPANY_GROUPS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'COMPANY_GROUPS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  COMPANY_GROUPS_BY_USER_CREATED__MAX_NAME_ASC = 'COMPANY_GROUPS_BY_USER_CREATED__MAX_NAME_ASC',
  COMPANY_GROUPS_BY_USER_CREATED__MAX_NAME_DESC = 'COMPANY_GROUPS_BY_USER_CREATED__MAX_NAME_DESC',
  COMPANY_GROUPS_BY_USER_CREATED__MAX_REMARK_ASC = 'COMPANY_GROUPS_BY_USER_CREATED__MAX_REMARK_ASC',
  COMPANY_GROUPS_BY_USER_CREATED__MAX_REMARK_DESC = 'COMPANY_GROUPS_BY_USER_CREATED__MAX_REMARK_DESC',
  COMPANY_GROUPS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'COMPANY_GROUPS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  COMPANY_GROUPS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'COMPANY_GROUPS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  COMPANY_GROUPS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'COMPANY_GROUPS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  COMPANY_GROUPS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'COMPANY_GROUPS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  COMPANY_GROUPS_BY_USER_CREATED__MIN_COMPANY_GROUP_ID_ASC = 'COMPANY_GROUPS_BY_USER_CREATED__MIN_COMPANY_GROUP_ID_ASC',
  COMPANY_GROUPS_BY_USER_CREATED__MIN_COMPANY_GROUP_ID_DESC = 'COMPANY_GROUPS_BY_USER_CREATED__MIN_COMPANY_GROUP_ID_DESC',
  COMPANY_GROUPS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'COMPANY_GROUPS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  COMPANY_GROUPS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'COMPANY_GROUPS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  COMPANY_GROUPS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'COMPANY_GROUPS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  COMPANY_GROUPS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'COMPANY_GROUPS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  COMPANY_GROUPS_BY_USER_CREATED__MIN_NAME_ASC = 'COMPANY_GROUPS_BY_USER_CREATED__MIN_NAME_ASC',
  COMPANY_GROUPS_BY_USER_CREATED__MIN_NAME_DESC = 'COMPANY_GROUPS_BY_USER_CREATED__MIN_NAME_DESC',
  COMPANY_GROUPS_BY_USER_CREATED__MIN_REMARK_ASC = 'COMPANY_GROUPS_BY_USER_CREATED__MIN_REMARK_ASC',
  COMPANY_GROUPS_BY_USER_CREATED__MIN_REMARK_DESC = 'COMPANY_GROUPS_BY_USER_CREATED__MIN_REMARK_DESC',
  COMPANY_GROUPS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'COMPANY_GROUPS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  COMPANY_GROUPS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'COMPANY_GROUPS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  COMPANY_GROUPS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'COMPANY_GROUPS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  COMPANY_GROUPS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'COMPANY_GROUPS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  COMPANY_GROUPS_BY_USER_UPDATED__COUNT_ASC = 'COMPANY_GROUPS_BY_USER_UPDATED__COUNT_ASC',
  COMPANY_GROUPS_BY_USER_UPDATED__COUNT_DESC = 'COMPANY_GROUPS_BY_USER_UPDATED__COUNT_DESC',
  COMPANY_GROUPS_BY_USER_UPDATED__MAX_COMPANY_GROUP_ID_ASC = 'COMPANY_GROUPS_BY_USER_UPDATED__MAX_COMPANY_GROUP_ID_ASC',
  COMPANY_GROUPS_BY_USER_UPDATED__MAX_COMPANY_GROUP_ID_DESC = 'COMPANY_GROUPS_BY_USER_UPDATED__MAX_COMPANY_GROUP_ID_DESC',
  COMPANY_GROUPS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'COMPANY_GROUPS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  COMPANY_GROUPS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'COMPANY_GROUPS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  COMPANY_GROUPS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'COMPANY_GROUPS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  COMPANY_GROUPS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'COMPANY_GROUPS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  COMPANY_GROUPS_BY_USER_UPDATED__MAX_NAME_ASC = 'COMPANY_GROUPS_BY_USER_UPDATED__MAX_NAME_ASC',
  COMPANY_GROUPS_BY_USER_UPDATED__MAX_NAME_DESC = 'COMPANY_GROUPS_BY_USER_UPDATED__MAX_NAME_DESC',
  COMPANY_GROUPS_BY_USER_UPDATED__MAX_REMARK_ASC = 'COMPANY_GROUPS_BY_USER_UPDATED__MAX_REMARK_ASC',
  COMPANY_GROUPS_BY_USER_UPDATED__MAX_REMARK_DESC = 'COMPANY_GROUPS_BY_USER_UPDATED__MAX_REMARK_DESC',
  COMPANY_GROUPS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'COMPANY_GROUPS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  COMPANY_GROUPS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'COMPANY_GROUPS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  COMPANY_GROUPS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'COMPANY_GROUPS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  COMPANY_GROUPS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'COMPANY_GROUPS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  COMPANY_GROUPS_BY_USER_UPDATED__MIN_COMPANY_GROUP_ID_ASC = 'COMPANY_GROUPS_BY_USER_UPDATED__MIN_COMPANY_GROUP_ID_ASC',
  COMPANY_GROUPS_BY_USER_UPDATED__MIN_COMPANY_GROUP_ID_DESC = 'COMPANY_GROUPS_BY_USER_UPDATED__MIN_COMPANY_GROUP_ID_DESC',
  COMPANY_GROUPS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'COMPANY_GROUPS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  COMPANY_GROUPS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'COMPANY_GROUPS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  COMPANY_GROUPS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'COMPANY_GROUPS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  COMPANY_GROUPS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'COMPANY_GROUPS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  COMPANY_GROUPS_BY_USER_UPDATED__MIN_NAME_ASC = 'COMPANY_GROUPS_BY_USER_UPDATED__MIN_NAME_ASC',
  COMPANY_GROUPS_BY_USER_UPDATED__MIN_NAME_DESC = 'COMPANY_GROUPS_BY_USER_UPDATED__MIN_NAME_DESC',
  COMPANY_GROUPS_BY_USER_UPDATED__MIN_REMARK_ASC = 'COMPANY_GROUPS_BY_USER_UPDATED__MIN_REMARK_ASC',
  COMPANY_GROUPS_BY_USER_UPDATED__MIN_REMARK_DESC = 'COMPANY_GROUPS_BY_USER_UPDATED__MIN_REMARK_DESC',
  COMPANY_GROUPS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'COMPANY_GROUPS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  COMPANY_GROUPS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'COMPANY_GROUPS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  COMPANY_GROUPS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'COMPANY_GROUPS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  COMPANY_GROUPS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'COMPANY_GROUPS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__COUNT_ASC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__COUNT_ASC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__COUNT_DESC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__COUNT_DESC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_COMPANY_ID_ASC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_COMPANY_ID_ASC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_COMPANY_ID_DESC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_COMPANY_ID_DESC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_COMPANY_LOCAL_HR_ID_ASC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_COMPANY_LOCAL_HR_ID_ASC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_COMPANY_LOCAL_HR_ID_DESC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_COMPANY_LOCAL_HR_ID_DESC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_REMARK_ASC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_REMARK_ASC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_REMARK_DESC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_REMARK_DESC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_COMPANY_ID_ASC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_COMPANY_ID_ASC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_COMPANY_ID_DESC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_COMPANY_ID_DESC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_COMPANY_LOCAL_HR_ID_ASC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_COMPANY_LOCAL_HR_ID_ASC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_COMPANY_LOCAL_HR_ID_DESC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_COMPANY_LOCAL_HR_ID_DESC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_REMARK_ASC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_REMARK_ASC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_REMARK_DESC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_REMARK_DESC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'COMPANY_LOCAL_HRS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__COUNT_ASC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__COUNT_ASC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__COUNT_DESC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__COUNT_DESC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_COMPANY_ID_ASC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_COMPANY_ID_ASC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_COMPANY_ID_DESC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_COMPANY_ID_DESC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_COMPANY_LOCAL_HR_ID_ASC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_COMPANY_LOCAL_HR_ID_ASC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_COMPANY_LOCAL_HR_ID_DESC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_COMPANY_LOCAL_HR_ID_DESC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_REMARK_ASC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_REMARK_ASC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_REMARK_DESC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_REMARK_DESC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_COMPANY_ID_ASC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_COMPANY_ID_ASC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_COMPANY_ID_DESC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_COMPANY_ID_DESC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_COMPANY_LOCAL_HR_ID_ASC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_COMPANY_LOCAL_HR_ID_ASC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_COMPANY_LOCAL_HR_ID_DESC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_COMPANY_LOCAL_HR_ID_DESC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_REMARK_ASC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_REMARK_ASC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_REMARK_DESC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_REMARK_DESC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'COMPANY_LOCAL_HRS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  CONTRACT_TYPES_BY_USER_CREATED__COUNT_ASC = 'CONTRACT_TYPES_BY_USER_CREATED__COUNT_ASC',
  CONTRACT_TYPES_BY_USER_CREATED__COUNT_DESC = 'CONTRACT_TYPES_BY_USER_CREATED__COUNT_DESC',
  CONTRACT_TYPES_BY_USER_CREATED__MAX_CODE_ASC = 'CONTRACT_TYPES_BY_USER_CREATED__MAX_CODE_ASC',
  CONTRACT_TYPES_BY_USER_CREATED__MAX_CODE_DESC = 'CONTRACT_TYPES_BY_USER_CREATED__MAX_CODE_DESC',
  CONTRACT_TYPES_BY_USER_CREATED__MAX_CONTRACT_TYPE_ID_ASC = 'CONTRACT_TYPES_BY_USER_CREATED__MAX_CONTRACT_TYPE_ID_ASC',
  CONTRACT_TYPES_BY_USER_CREATED__MAX_CONTRACT_TYPE_ID_DESC = 'CONTRACT_TYPES_BY_USER_CREATED__MAX_CONTRACT_TYPE_ID_DESC',
  CONTRACT_TYPES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'CONTRACT_TYPES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  CONTRACT_TYPES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'CONTRACT_TYPES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  CONTRACT_TYPES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'CONTRACT_TYPES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  CONTRACT_TYPES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'CONTRACT_TYPES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  CONTRACT_TYPES_BY_USER_CREATED__MAX_DISPLAY_NAME_ASC = 'CONTRACT_TYPES_BY_USER_CREATED__MAX_DISPLAY_NAME_ASC',
  CONTRACT_TYPES_BY_USER_CREATED__MAX_DISPLAY_NAME_DESC = 'CONTRACT_TYPES_BY_USER_CREATED__MAX_DISPLAY_NAME_DESC',
  CONTRACT_TYPES_BY_USER_CREATED__MAX_REMARK_ASC = 'CONTRACT_TYPES_BY_USER_CREATED__MAX_REMARK_ASC',
  CONTRACT_TYPES_BY_USER_CREATED__MAX_REMARK_DESC = 'CONTRACT_TYPES_BY_USER_CREATED__MAX_REMARK_DESC',
  CONTRACT_TYPES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'CONTRACT_TYPES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  CONTRACT_TYPES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'CONTRACT_TYPES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  CONTRACT_TYPES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'CONTRACT_TYPES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  CONTRACT_TYPES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'CONTRACT_TYPES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  CONTRACT_TYPES_BY_USER_CREATED__MIN_CODE_ASC = 'CONTRACT_TYPES_BY_USER_CREATED__MIN_CODE_ASC',
  CONTRACT_TYPES_BY_USER_CREATED__MIN_CODE_DESC = 'CONTRACT_TYPES_BY_USER_CREATED__MIN_CODE_DESC',
  CONTRACT_TYPES_BY_USER_CREATED__MIN_CONTRACT_TYPE_ID_ASC = 'CONTRACT_TYPES_BY_USER_CREATED__MIN_CONTRACT_TYPE_ID_ASC',
  CONTRACT_TYPES_BY_USER_CREATED__MIN_CONTRACT_TYPE_ID_DESC = 'CONTRACT_TYPES_BY_USER_CREATED__MIN_CONTRACT_TYPE_ID_DESC',
  CONTRACT_TYPES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'CONTRACT_TYPES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  CONTRACT_TYPES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'CONTRACT_TYPES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  CONTRACT_TYPES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'CONTRACT_TYPES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  CONTRACT_TYPES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'CONTRACT_TYPES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  CONTRACT_TYPES_BY_USER_CREATED__MIN_DISPLAY_NAME_ASC = 'CONTRACT_TYPES_BY_USER_CREATED__MIN_DISPLAY_NAME_ASC',
  CONTRACT_TYPES_BY_USER_CREATED__MIN_DISPLAY_NAME_DESC = 'CONTRACT_TYPES_BY_USER_CREATED__MIN_DISPLAY_NAME_DESC',
  CONTRACT_TYPES_BY_USER_CREATED__MIN_REMARK_ASC = 'CONTRACT_TYPES_BY_USER_CREATED__MIN_REMARK_ASC',
  CONTRACT_TYPES_BY_USER_CREATED__MIN_REMARK_DESC = 'CONTRACT_TYPES_BY_USER_CREATED__MIN_REMARK_DESC',
  CONTRACT_TYPES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'CONTRACT_TYPES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  CONTRACT_TYPES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'CONTRACT_TYPES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  CONTRACT_TYPES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'CONTRACT_TYPES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  CONTRACT_TYPES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'CONTRACT_TYPES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  CONTRACT_TYPES_BY_USER_UPDATED__COUNT_ASC = 'CONTRACT_TYPES_BY_USER_UPDATED__COUNT_ASC',
  CONTRACT_TYPES_BY_USER_UPDATED__COUNT_DESC = 'CONTRACT_TYPES_BY_USER_UPDATED__COUNT_DESC',
  CONTRACT_TYPES_BY_USER_UPDATED__MAX_CODE_ASC = 'CONTRACT_TYPES_BY_USER_UPDATED__MAX_CODE_ASC',
  CONTRACT_TYPES_BY_USER_UPDATED__MAX_CODE_DESC = 'CONTRACT_TYPES_BY_USER_UPDATED__MAX_CODE_DESC',
  CONTRACT_TYPES_BY_USER_UPDATED__MAX_CONTRACT_TYPE_ID_ASC = 'CONTRACT_TYPES_BY_USER_UPDATED__MAX_CONTRACT_TYPE_ID_ASC',
  CONTRACT_TYPES_BY_USER_UPDATED__MAX_CONTRACT_TYPE_ID_DESC = 'CONTRACT_TYPES_BY_USER_UPDATED__MAX_CONTRACT_TYPE_ID_DESC',
  CONTRACT_TYPES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'CONTRACT_TYPES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  CONTRACT_TYPES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'CONTRACT_TYPES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  CONTRACT_TYPES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'CONTRACT_TYPES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  CONTRACT_TYPES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'CONTRACT_TYPES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  CONTRACT_TYPES_BY_USER_UPDATED__MAX_DISPLAY_NAME_ASC = 'CONTRACT_TYPES_BY_USER_UPDATED__MAX_DISPLAY_NAME_ASC',
  CONTRACT_TYPES_BY_USER_UPDATED__MAX_DISPLAY_NAME_DESC = 'CONTRACT_TYPES_BY_USER_UPDATED__MAX_DISPLAY_NAME_DESC',
  CONTRACT_TYPES_BY_USER_UPDATED__MAX_REMARK_ASC = 'CONTRACT_TYPES_BY_USER_UPDATED__MAX_REMARK_ASC',
  CONTRACT_TYPES_BY_USER_UPDATED__MAX_REMARK_DESC = 'CONTRACT_TYPES_BY_USER_UPDATED__MAX_REMARK_DESC',
  CONTRACT_TYPES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'CONTRACT_TYPES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  CONTRACT_TYPES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'CONTRACT_TYPES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  CONTRACT_TYPES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'CONTRACT_TYPES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  CONTRACT_TYPES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'CONTRACT_TYPES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  CONTRACT_TYPES_BY_USER_UPDATED__MIN_CODE_ASC = 'CONTRACT_TYPES_BY_USER_UPDATED__MIN_CODE_ASC',
  CONTRACT_TYPES_BY_USER_UPDATED__MIN_CODE_DESC = 'CONTRACT_TYPES_BY_USER_UPDATED__MIN_CODE_DESC',
  CONTRACT_TYPES_BY_USER_UPDATED__MIN_CONTRACT_TYPE_ID_ASC = 'CONTRACT_TYPES_BY_USER_UPDATED__MIN_CONTRACT_TYPE_ID_ASC',
  CONTRACT_TYPES_BY_USER_UPDATED__MIN_CONTRACT_TYPE_ID_DESC = 'CONTRACT_TYPES_BY_USER_UPDATED__MIN_CONTRACT_TYPE_ID_DESC',
  CONTRACT_TYPES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'CONTRACT_TYPES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  CONTRACT_TYPES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'CONTRACT_TYPES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  CONTRACT_TYPES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'CONTRACT_TYPES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  CONTRACT_TYPES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'CONTRACT_TYPES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  CONTRACT_TYPES_BY_USER_UPDATED__MIN_DISPLAY_NAME_ASC = 'CONTRACT_TYPES_BY_USER_UPDATED__MIN_DISPLAY_NAME_ASC',
  CONTRACT_TYPES_BY_USER_UPDATED__MIN_DISPLAY_NAME_DESC = 'CONTRACT_TYPES_BY_USER_UPDATED__MIN_DISPLAY_NAME_DESC',
  CONTRACT_TYPES_BY_USER_UPDATED__MIN_REMARK_ASC = 'CONTRACT_TYPES_BY_USER_UPDATED__MIN_REMARK_ASC',
  CONTRACT_TYPES_BY_USER_UPDATED__MIN_REMARK_DESC = 'CONTRACT_TYPES_BY_USER_UPDATED__MIN_REMARK_DESC',
  CONTRACT_TYPES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'CONTRACT_TYPES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  CONTRACT_TYPES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'CONTRACT_TYPES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  CONTRACT_TYPES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'CONTRACT_TYPES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  CONTRACT_TYPES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'CONTRACT_TYPES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  COUNTRIES_BY_USER_CREATED__COUNT_ASC = 'COUNTRIES_BY_USER_CREATED__COUNT_ASC',
  COUNTRIES_BY_USER_CREATED__COUNT_DESC = 'COUNTRIES_BY_USER_CREATED__COUNT_DESC',
  COUNTRIES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA2_ASC = 'COUNTRIES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA2_ASC',
  COUNTRIES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA2_DESC = 'COUNTRIES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA2_DESC',
  COUNTRIES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA3_ASC = 'COUNTRIES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA3_ASC',
  COUNTRIES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA3_DESC = 'COUNTRIES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA3_DESC',
  COUNTRIES_BY_USER_CREATED__MAX_COUNTRY_CODE_NUMERIC_ASC = 'COUNTRIES_BY_USER_CREATED__MAX_COUNTRY_CODE_NUMERIC_ASC',
  COUNTRIES_BY_USER_CREATED__MAX_COUNTRY_CODE_NUMERIC_DESC = 'COUNTRIES_BY_USER_CREATED__MAX_COUNTRY_CODE_NUMERIC_DESC',
  COUNTRIES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'COUNTRIES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  COUNTRIES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'COUNTRIES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  COUNTRIES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'COUNTRIES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  COUNTRIES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'COUNTRIES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  COUNTRIES_BY_USER_CREATED__MAX_DISPLAY_ORDER_ASC = 'COUNTRIES_BY_USER_CREATED__MAX_DISPLAY_ORDER_ASC',
  COUNTRIES_BY_USER_CREATED__MAX_DISPLAY_ORDER_DESC = 'COUNTRIES_BY_USER_CREATED__MAX_DISPLAY_ORDER_DESC',
  COUNTRIES_BY_USER_CREATED__MAX_REMARK_ASC = 'COUNTRIES_BY_USER_CREATED__MAX_REMARK_ASC',
  COUNTRIES_BY_USER_CREATED__MAX_REMARK_DESC = 'COUNTRIES_BY_USER_CREATED__MAX_REMARK_DESC',
  COUNTRIES_BY_USER_CREATED__MAX_SHORT_NAME_ASC = 'COUNTRIES_BY_USER_CREATED__MAX_SHORT_NAME_ASC',
  COUNTRIES_BY_USER_CREATED__MAX_SHORT_NAME_DESC = 'COUNTRIES_BY_USER_CREATED__MAX_SHORT_NAME_DESC',
  COUNTRIES_BY_USER_CREATED__MAX_TELEPHONE_CODE_ASC = 'COUNTRIES_BY_USER_CREATED__MAX_TELEPHONE_CODE_ASC',
  COUNTRIES_BY_USER_CREATED__MAX_TELEPHONE_CODE_DESC = 'COUNTRIES_BY_USER_CREATED__MAX_TELEPHONE_CODE_DESC',
  COUNTRIES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'COUNTRIES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  COUNTRIES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'COUNTRIES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  COUNTRIES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'COUNTRIES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  COUNTRIES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'COUNTRIES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  COUNTRIES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA2_ASC = 'COUNTRIES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA2_ASC',
  COUNTRIES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA2_DESC = 'COUNTRIES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA2_DESC',
  COUNTRIES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA3_ASC = 'COUNTRIES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA3_ASC',
  COUNTRIES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA3_DESC = 'COUNTRIES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA3_DESC',
  COUNTRIES_BY_USER_CREATED__MIN_COUNTRY_CODE_NUMERIC_ASC = 'COUNTRIES_BY_USER_CREATED__MIN_COUNTRY_CODE_NUMERIC_ASC',
  COUNTRIES_BY_USER_CREATED__MIN_COUNTRY_CODE_NUMERIC_DESC = 'COUNTRIES_BY_USER_CREATED__MIN_COUNTRY_CODE_NUMERIC_DESC',
  COUNTRIES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'COUNTRIES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  COUNTRIES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'COUNTRIES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  COUNTRIES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'COUNTRIES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  COUNTRIES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'COUNTRIES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  COUNTRIES_BY_USER_CREATED__MIN_DISPLAY_ORDER_ASC = 'COUNTRIES_BY_USER_CREATED__MIN_DISPLAY_ORDER_ASC',
  COUNTRIES_BY_USER_CREATED__MIN_DISPLAY_ORDER_DESC = 'COUNTRIES_BY_USER_CREATED__MIN_DISPLAY_ORDER_DESC',
  COUNTRIES_BY_USER_CREATED__MIN_REMARK_ASC = 'COUNTRIES_BY_USER_CREATED__MIN_REMARK_ASC',
  COUNTRIES_BY_USER_CREATED__MIN_REMARK_DESC = 'COUNTRIES_BY_USER_CREATED__MIN_REMARK_DESC',
  COUNTRIES_BY_USER_CREATED__MIN_SHORT_NAME_ASC = 'COUNTRIES_BY_USER_CREATED__MIN_SHORT_NAME_ASC',
  COUNTRIES_BY_USER_CREATED__MIN_SHORT_NAME_DESC = 'COUNTRIES_BY_USER_CREATED__MIN_SHORT_NAME_DESC',
  COUNTRIES_BY_USER_CREATED__MIN_TELEPHONE_CODE_ASC = 'COUNTRIES_BY_USER_CREATED__MIN_TELEPHONE_CODE_ASC',
  COUNTRIES_BY_USER_CREATED__MIN_TELEPHONE_CODE_DESC = 'COUNTRIES_BY_USER_CREATED__MIN_TELEPHONE_CODE_DESC',
  COUNTRIES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'COUNTRIES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  COUNTRIES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'COUNTRIES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  COUNTRIES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'COUNTRIES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  COUNTRIES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'COUNTRIES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  COUNTRIES_BY_USER_UPDATED__COUNT_ASC = 'COUNTRIES_BY_USER_UPDATED__COUNT_ASC',
  COUNTRIES_BY_USER_UPDATED__COUNT_DESC = 'COUNTRIES_BY_USER_UPDATED__COUNT_DESC',
  COUNTRIES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA2_ASC = 'COUNTRIES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA2_ASC',
  COUNTRIES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA2_DESC = 'COUNTRIES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA2_DESC',
  COUNTRIES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA3_ASC = 'COUNTRIES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA3_ASC',
  COUNTRIES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA3_DESC = 'COUNTRIES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA3_DESC',
  COUNTRIES_BY_USER_UPDATED__MAX_COUNTRY_CODE_NUMERIC_ASC = 'COUNTRIES_BY_USER_UPDATED__MAX_COUNTRY_CODE_NUMERIC_ASC',
  COUNTRIES_BY_USER_UPDATED__MAX_COUNTRY_CODE_NUMERIC_DESC = 'COUNTRIES_BY_USER_UPDATED__MAX_COUNTRY_CODE_NUMERIC_DESC',
  COUNTRIES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'COUNTRIES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  COUNTRIES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'COUNTRIES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  COUNTRIES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'COUNTRIES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  COUNTRIES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'COUNTRIES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  COUNTRIES_BY_USER_UPDATED__MAX_DISPLAY_ORDER_ASC = 'COUNTRIES_BY_USER_UPDATED__MAX_DISPLAY_ORDER_ASC',
  COUNTRIES_BY_USER_UPDATED__MAX_DISPLAY_ORDER_DESC = 'COUNTRIES_BY_USER_UPDATED__MAX_DISPLAY_ORDER_DESC',
  COUNTRIES_BY_USER_UPDATED__MAX_REMARK_ASC = 'COUNTRIES_BY_USER_UPDATED__MAX_REMARK_ASC',
  COUNTRIES_BY_USER_UPDATED__MAX_REMARK_DESC = 'COUNTRIES_BY_USER_UPDATED__MAX_REMARK_DESC',
  COUNTRIES_BY_USER_UPDATED__MAX_SHORT_NAME_ASC = 'COUNTRIES_BY_USER_UPDATED__MAX_SHORT_NAME_ASC',
  COUNTRIES_BY_USER_UPDATED__MAX_SHORT_NAME_DESC = 'COUNTRIES_BY_USER_UPDATED__MAX_SHORT_NAME_DESC',
  COUNTRIES_BY_USER_UPDATED__MAX_TELEPHONE_CODE_ASC = 'COUNTRIES_BY_USER_UPDATED__MAX_TELEPHONE_CODE_ASC',
  COUNTRIES_BY_USER_UPDATED__MAX_TELEPHONE_CODE_DESC = 'COUNTRIES_BY_USER_UPDATED__MAX_TELEPHONE_CODE_DESC',
  COUNTRIES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'COUNTRIES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  COUNTRIES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'COUNTRIES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  COUNTRIES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'COUNTRIES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  COUNTRIES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'COUNTRIES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  COUNTRIES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA2_ASC = 'COUNTRIES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA2_ASC',
  COUNTRIES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA2_DESC = 'COUNTRIES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA2_DESC',
  COUNTRIES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA3_ASC = 'COUNTRIES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA3_ASC',
  COUNTRIES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA3_DESC = 'COUNTRIES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA3_DESC',
  COUNTRIES_BY_USER_UPDATED__MIN_COUNTRY_CODE_NUMERIC_ASC = 'COUNTRIES_BY_USER_UPDATED__MIN_COUNTRY_CODE_NUMERIC_ASC',
  COUNTRIES_BY_USER_UPDATED__MIN_COUNTRY_CODE_NUMERIC_DESC = 'COUNTRIES_BY_USER_UPDATED__MIN_COUNTRY_CODE_NUMERIC_DESC',
  COUNTRIES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'COUNTRIES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  COUNTRIES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'COUNTRIES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  COUNTRIES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'COUNTRIES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  COUNTRIES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'COUNTRIES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  COUNTRIES_BY_USER_UPDATED__MIN_DISPLAY_ORDER_ASC = 'COUNTRIES_BY_USER_UPDATED__MIN_DISPLAY_ORDER_ASC',
  COUNTRIES_BY_USER_UPDATED__MIN_DISPLAY_ORDER_DESC = 'COUNTRIES_BY_USER_UPDATED__MIN_DISPLAY_ORDER_DESC',
  COUNTRIES_BY_USER_UPDATED__MIN_REMARK_ASC = 'COUNTRIES_BY_USER_UPDATED__MIN_REMARK_ASC',
  COUNTRIES_BY_USER_UPDATED__MIN_REMARK_DESC = 'COUNTRIES_BY_USER_UPDATED__MIN_REMARK_DESC',
  COUNTRIES_BY_USER_UPDATED__MIN_SHORT_NAME_ASC = 'COUNTRIES_BY_USER_UPDATED__MIN_SHORT_NAME_ASC',
  COUNTRIES_BY_USER_UPDATED__MIN_SHORT_NAME_DESC = 'COUNTRIES_BY_USER_UPDATED__MIN_SHORT_NAME_DESC',
  COUNTRIES_BY_USER_UPDATED__MIN_TELEPHONE_CODE_ASC = 'COUNTRIES_BY_USER_UPDATED__MIN_TELEPHONE_CODE_ASC',
  COUNTRIES_BY_USER_UPDATED__MIN_TELEPHONE_CODE_DESC = 'COUNTRIES_BY_USER_UPDATED__MIN_TELEPHONE_CODE_DESC',
  COUNTRIES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'COUNTRIES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  COUNTRIES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'COUNTRIES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  COUNTRIES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'COUNTRIES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  COUNTRIES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'COUNTRIES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  CURRENCIES_BY_USER_CREATED__COUNT_ASC = 'CURRENCIES_BY_USER_CREATED__COUNT_ASC',
  CURRENCIES_BY_USER_CREATED__COUNT_DESC = 'CURRENCIES_BY_USER_CREATED__COUNT_DESC',
  CURRENCIES_BY_USER_CREATED__MAX_ABBREVIATION_ASC = 'CURRENCIES_BY_USER_CREATED__MAX_ABBREVIATION_ASC',
  CURRENCIES_BY_USER_CREATED__MAX_ABBREVIATION_DESC = 'CURRENCIES_BY_USER_CREATED__MAX_ABBREVIATION_DESC',
  CURRENCIES_BY_USER_CREATED__MAX_CURRENCY_CODE_ASC = 'CURRENCIES_BY_USER_CREATED__MAX_CURRENCY_CODE_ASC',
  CURRENCIES_BY_USER_CREATED__MAX_CURRENCY_CODE_DESC = 'CURRENCIES_BY_USER_CREATED__MAX_CURRENCY_CODE_DESC',
  CURRENCIES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'CURRENCIES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  CURRENCIES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'CURRENCIES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  CURRENCIES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'CURRENCIES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  CURRENCIES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'CURRENCIES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  CURRENCIES_BY_USER_CREATED__MAX_NAME_ASC = 'CURRENCIES_BY_USER_CREATED__MAX_NAME_ASC',
  CURRENCIES_BY_USER_CREATED__MAX_NAME_DESC = 'CURRENCIES_BY_USER_CREATED__MAX_NAME_DESC',
  CURRENCIES_BY_USER_CREATED__MAX_REMARK_ASC = 'CURRENCIES_BY_USER_CREATED__MAX_REMARK_ASC',
  CURRENCIES_BY_USER_CREATED__MAX_REMARK_DESC = 'CURRENCIES_BY_USER_CREATED__MAX_REMARK_DESC',
  CURRENCIES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'CURRENCIES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  CURRENCIES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'CURRENCIES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  CURRENCIES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'CURRENCIES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  CURRENCIES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'CURRENCIES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  CURRENCIES_BY_USER_CREATED__MIN_ABBREVIATION_ASC = 'CURRENCIES_BY_USER_CREATED__MIN_ABBREVIATION_ASC',
  CURRENCIES_BY_USER_CREATED__MIN_ABBREVIATION_DESC = 'CURRENCIES_BY_USER_CREATED__MIN_ABBREVIATION_DESC',
  CURRENCIES_BY_USER_CREATED__MIN_CURRENCY_CODE_ASC = 'CURRENCIES_BY_USER_CREATED__MIN_CURRENCY_CODE_ASC',
  CURRENCIES_BY_USER_CREATED__MIN_CURRENCY_CODE_DESC = 'CURRENCIES_BY_USER_CREATED__MIN_CURRENCY_CODE_DESC',
  CURRENCIES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'CURRENCIES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  CURRENCIES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'CURRENCIES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  CURRENCIES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'CURRENCIES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  CURRENCIES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'CURRENCIES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  CURRENCIES_BY_USER_CREATED__MIN_NAME_ASC = 'CURRENCIES_BY_USER_CREATED__MIN_NAME_ASC',
  CURRENCIES_BY_USER_CREATED__MIN_NAME_DESC = 'CURRENCIES_BY_USER_CREATED__MIN_NAME_DESC',
  CURRENCIES_BY_USER_CREATED__MIN_REMARK_ASC = 'CURRENCIES_BY_USER_CREATED__MIN_REMARK_ASC',
  CURRENCIES_BY_USER_CREATED__MIN_REMARK_DESC = 'CURRENCIES_BY_USER_CREATED__MIN_REMARK_DESC',
  CURRENCIES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'CURRENCIES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  CURRENCIES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'CURRENCIES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  CURRENCIES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'CURRENCIES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  CURRENCIES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'CURRENCIES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  CURRENCIES_BY_USER_UPDATED__COUNT_ASC = 'CURRENCIES_BY_USER_UPDATED__COUNT_ASC',
  CURRENCIES_BY_USER_UPDATED__COUNT_DESC = 'CURRENCIES_BY_USER_UPDATED__COUNT_DESC',
  CURRENCIES_BY_USER_UPDATED__MAX_ABBREVIATION_ASC = 'CURRENCIES_BY_USER_UPDATED__MAX_ABBREVIATION_ASC',
  CURRENCIES_BY_USER_UPDATED__MAX_ABBREVIATION_DESC = 'CURRENCIES_BY_USER_UPDATED__MAX_ABBREVIATION_DESC',
  CURRENCIES_BY_USER_UPDATED__MAX_CURRENCY_CODE_ASC = 'CURRENCIES_BY_USER_UPDATED__MAX_CURRENCY_CODE_ASC',
  CURRENCIES_BY_USER_UPDATED__MAX_CURRENCY_CODE_DESC = 'CURRENCIES_BY_USER_UPDATED__MAX_CURRENCY_CODE_DESC',
  CURRENCIES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'CURRENCIES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  CURRENCIES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'CURRENCIES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  CURRENCIES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'CURRENCIES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  CURRENCIES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'CURRENCIES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  CURRENCIES_BY_USER_UPDATED__MAX_NAME_ASC = 'CURRENCIES_BY_USER_UPDATED__MAX_NAME_ASC',
  CURRENCIES_BY_USER_UPDATED__MAX_NAME_DESC = 'CURRENCIES_BY_USER_UPDATED__MAX_NAME_DESC',
  CURRENCIES_BY_USER_UPDATED__MAX_REMARK_ASC = 'CURRENCIES_BY_USER_UPDATED__MAX_REMARK_ASC',
  CURRENCIES_BY_USER_UPDATED__MAX_REMARK_DESC = 'CURRENCIES_BY_USER_UPDATED__MAX_REMARK_DESC',
  CURRENCIES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'CURRENCIES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  CURRENCIES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'CURRENCIES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  CURRENCIES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'CURRENCIES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  CURRENCIES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'CURRENCIES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  CURRENCIES_BY_USER_UPDATED__MIN_ABBREVIATION_ASC = 'CURRENCIES_BY_USER_UPDATED__MIN_ABBREVIATION_ASC',
  CURRENCIES_BY_USER_UPDATED__MIN_ABBREVIATION_DESC = 'CURRENCIES_BY_USER_UPDATED__MIN_ABBREVIATION_DESC',
  CURRENCIES_BY_USER_UPDATED__MIN_CURRENCY_CODE_ASC = 'CURRENCIES_BY_USER_UPDATED__MIN_CURRENCY_CODE_ASC',
  CURRENCIES_BY_USER_UPDATED__MIN_CURRENCY_CODE_DESC = 'CURRENCIES_BY_USER_UPDATED__MIN_CURRENCY_CODE_DESC',
  CURRENCIES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'CURRENCIES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  CURRENCIES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'CURRENCIES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  CURRENCIES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'CURRENCIES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  CURRENCIES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'CURRENCIES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  CURRENCIES_BY_USER_UPDATED__MIN_NAME_ASC = 'CURRENCIES_BY_USER_UPDATED__MIN_NAME_ASC',
  CURRENCIES_BY_USER_UPDATED__MIN_NAME_DESC = 'CURRENCIES_BY_USER_UPDATED__MIN_NAME_DESC',
  CURRENCIES_BY_USER_UPDATED__MIN_REMARK_ASC = 'CURRENCIES_BY_USER_UPDATED__MIN_REMARK_ASC',
  CURRENCIES_BY_USER_UPDATED__MIN_REMARK_DESC = 'CURRENCIES_BY_USER_UPDATED__MIN_REMARK_DESC',
  CURRENCIES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'CURRENCIES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  CURRENCIES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'CURRENCIES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  CURRENCIES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'CURRENCIES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  CURRENCIES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'CURRENCIES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DEPARTMENTS_BY_USER_CREATED__COUNT_ASC = 'DEPARTMENTS_BY_USER_CREATED__COUNT_ASC',
  DEPARTMENTS_BY_USER_CREATED__COUNT_DESC = 'DEPARTMENTS_BY_USER_CREATED__COUNT_DESC',
  DEPARTMENTS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'DEPARTMENTS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  DEPARTMENTS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'DEPARTMENTS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  DEPARTMENTS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'DEPARTMENTS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  DEPARTMENTS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'DEPARTMENTS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  DEPARTMENTS_BY_USER_CREATED__MAX_DEPARTMENT_ID_ASC = 'DEPARTMENTS_BY_USER_CREATED__MAX_DEPARTMENT_ID_ASC',
  DEPARTMENTS_BY_USER_CREATED__MAX_DEPARTMENT_ID_DESC = 'DEPARTMENTS_BY_USER_CREATED__MAX_DEPARTMENT_ID_DESC',
  DEPARTMENTS_BY_USER_CREATED__MAX_IS_ACTIVE_ASC = 'DEPARTMENTS_BY_USER_CREATED__MAX_IS_ACTIVE_ASC',
  DEPARTMENTS_BY_USER_CREATED__MAX_IS_ACTIVE_DESC = 'DEPARTMENTS_BY_USER_CREATED__MAX_IS_ACTIVE_DESC',
  DEPARTMENTS_BY_USER_CREATED__MAX_NAME_ASC = 'DEPARTMENTS_BY_USER_CREATED__MAX_NAME_ASC',
  DEPARTMENTS_BY_USER_CREATED__MAX_NAME_DESC = 'DEPARTMENTS_BY_USER_CREATED__MAX_NAME_DESC',
  DEPARTMENTS_BY_USER_CREATED__MAX_REMARK_ASC = 'DEPARTMENTS_BY_USER_CREATED__MAX_REMARK_ASC',
  DEPARTMENTS_BY_USER_CREATED__MAX_REMARK_DESC = 'DEPARTMENTS_BY_USER_CREATED__MAX_REMARK_DESC',
  DEPARTMENTS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'DEPARTMENTS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  DEPARTMENTS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'DEPARTMENTS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  DEPARTMENTS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'DEPARTMENTS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  DEPARTMENTS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'DEPARTMENTS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  DEPARTMENTS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'DEPARTMENTS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  DEPARTMENTS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'DEPARTMENTS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  DEPARTMENTS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'DEPARTMENTS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  DEPARTMENTS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'DEPARTMENTS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  DEPARTMENTS_BY_USER_CREATED__MIN_DEPARTMENT_ID_ASC = 'DEPARTMENTS_BY_USER_CREATED__MIN_DEPARTMENT_ID_ASC',
  DEPARTMENTS_BY_USER_CREATED__MIN_DEPARTMENT_ID_DESC = 'DEPARTMENTS_BY_USER_CREATED__MIN_DEPARTMENT_ID_DESC',
  DEPARTMENTS_BY_USER_CREATED__MIN_IS_ACTIVE_ASC = 'DEPARTMENTS_BY_USER_CREATED__MIN_IS_ACTIVE_ASC',
  DEPARTMENTS_BY_USER_CREATED__MIN_IS_ACTIVE_DESC = 'DEPARTMENTS_BY_USER_CREATED__MIN_IS_ACTIVE_DESC',
  DEPARTMENTS_BY_USER_CREATED__MIN_NAME_ASC = 'DEPARTMENTS_BY_USER_CREATED__MIN_NAME_ASC',
  DEPARTMENTS_BY_USER_CREATED__MIN_NAME_DESC = 'DEPARTMENTS_BY_USER_CREATED__MIN_NAME_DESC',
  DEPARTMENTS_BY_USER_CREATED__MIN_REMARK_ASC = 'DEPARTMENTS_BY_USER_CREATED__MIN_REMARK_ASC',
  DEPARTMENTS_BY_USER_CREATED__MIN_REMARK_DESC = 'DEPARTMENTS_BY_USER_CREATED__MIN_REMARK_DESC',
  DEPARTMENTS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'DEPARTMENTS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  DEPARTMENTS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'DEPARTMENTS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  DEPARTMENTS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'DEPARTMENTS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  DEPARTMENTS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'DEPARTMENTS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  DEPARTMENTS_BY_USER_UPDATED__COUNT_ASC = 'DEPARTMENTS_BY_USER_UPDATED__COUNT_ASC',
  DEPARTMENTS_BY_USER_UPDATED__COUNT_DESC = 'DEPARTMENTS_BY_USER_UPDATED__COUNT_DESC',
  DEPARTMENTS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'DEPARTMENTS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  DEPARTMENTS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'DEPARTMENTS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  DEPARTMENTS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'DEPARTMENTS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  DEPARTMENTS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'DEPARTMENTS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  DEPARTMENTS_BY_USER_UPDATED__MAX_DEPARTMENT_ID_ASC = 'DEPARTMENTS_BY_USER_UPDATED__MAX_DEPARTMENT_ID_ASC',
  DEPARTMENTS_BY_USER_UPDATED__MAX_DEPARTMENT_ID_DESC = 'DEPARTMENTS_BY_USER_UPDATED__MAX_DEPARTMENT_ID_DESC',
  DEPARTMENTS_BY_USER_UPDATED__MAX_IS_ACTIVE_ASC = 'DEPARTMENTS_BY_USER_UPDATED__MAX_IS_ACTIVE_ASC',
  DEPARTMENTS_BY_USER_UPDATED__MAX_IS_ACTIVE_DESC = 'DEPARTMENTS_BY_USER_UPDATED__MAX_IS_ACTIVE_DESC',
  DEPARTMENTS_BY_USER_UPDATED__MAX_NAME_ASC = 'DEPARTMENTS_BY_USER_UPDATED__MAX_NAME_ASC',
  DEPARTMENTS_BY_USER_UPDATED__MAX_NAME_DESC = 'DEPARTMENTS_BY_USER_UPDATED__MAX_NAME_DESC',
  DEPARTMENTS_BY_USER_UPDATED__MAX_REMARK_ASC = 'DEPARTMENTS_BY_USER_UPDATED__MAX_REMARK_ASC',
  DEPARTMENTS_BY_USER_UPDATED__MAX_REMARK_DESC = 'DEPARTMENTS_BY_USER_UPDATED__MAX_REMARK_DESC',
  DEPARTMENTS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'DEPARTMENTS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  DEPARTMENTS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'DEPARTMENTS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  DEPARTMENTS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'DEPARTMENTS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  DEPARTMENTS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'DEPARTMENTS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  DEPARTMENTS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'DEPARTMENTS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  DEPARTMENTS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'DEPARTMENTS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  DEPARTMENTS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'DEPARTMENTS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  DEPARTMENTS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'DEPARTMENTS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  DEPARTMENTS_BY_USER_UPDATED__MIN_DEPARTMENT_ID_ASC = 'DEPARTMENTS_BY_USER_UPDATED__MIN_DEPARTMENT_ID_ASC',
  DEPARTMENTS_BY_USER_UPDATED__MIN_DEPARTMENT_ID_DESC = 'DEPARTMENTS_BY_USER_UPDATED__MIN_DEPARTMENT_ID_DESC',
  DEPARTMENTS_BY_USER_UPDATED__MIN_IS_ACTIVE_ASC = 'DEPARTMENTS_BY_USER_UPDATED__MIN_IS_ACTIVE_ASC',
  DEPARTMENTS_BY_USER_UPDATED__MIN_IS_ACTIVE_DESC = 'DEPARTMENTS_BY_USER_UPDATED__MIN_IS_ACTIVE_DESC',
  DEPARTMENTS_BY_USER_UPDATED__MIN_NAME_ASC = 'DEPARTMENTS_BY_USER_UPDATED__MIN_NAME_ASC',
  DEPARTMENTS_BY_USER_UPDATED__MIN_NAME_DESC = 'DEPARTMENTS_BY_USER_UPDATED__MIN_NAME_DESC',
  DEPARTMENTS_BY_USER_UPDATED__MIN_REMARK_ASC = 'DEPARTMENTS_BY_USER_UPDATED__MIN_REMARK_ASC',
  DEPARTMENTS_BY_USER_UPDATED__MIN_REMARK_DESC = 'DEPARTMENTS_BY_USER_UPDATED__MIN_REMARK_DESC',
  DEPARTMENTS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'DEPARTMENTS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  DEPARTMENTS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'DEPARTMENTS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  DEPARTMENTS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'DEPARTMENTS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  DEPARTMENTS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'DEPARTMENTS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  DISPLAY_NAME_ASC = 'DISPLAY_NAME_ASC',
  DISPLAY_NAME_DESC = 'DISPLAY_NAME_DESC',
  DIVISIONS_BY_USER_CREATED__COUNT_ASC = 'DIVISIONS_BY_USER_CREATED__COUNT_ASC',
  DIVISIONS_BY_USER_CREATED__COUNT_DESC = 'DIVISIONS_BY_USER_CREATED__COUNT_DESC',
  DIVISIONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'DIVISIONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  DIVISIONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'DIVISIONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  DIVISIONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'DIVISIONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  DIVISIONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'DIVISIONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  DIVISIONS_BY_USER_CREATED__MAX_DIVISION_ID_ASC = 'DIVISIONS_BY_USER_CREATED__MAX_DIVISION_ID_ASC',
  DIVISIONS_BY_USER_CREATED__MAX_DIVISION_ID_DESC = 'DIVISIONS_BY_USER_CREATED__MAX_DIVISION_ID_DESC',
  DIVISIONS_BY_USER_CREATED__MAX_IS_ACTIVE_ASC = 'DIVISIONS_BY_USER_CREATED__MAX_IS_ACTIVE_ASC',
  DIVISIONS_BY_USER_CREATED__MAX_IS_ACTIVE_DESC = 'DIVISIONS_BY_USER_CREATED__MAX_IS_ACTIVE_DESC',
  DIVISIONS_BY_USER_CREATED__MAX_NAME_ASC = 'DIVISIONS_BY_USER_CREATED__MAX_NAME_ASC',
  DIVISIONS_BY_USER_CREATED__MAX_NAME_DESC = 'DIVISIONS_BY_USER_CREATED__MAX_NAME_DESC',
  DIVISIONS_BY_USER_CREATED__MAX_REMARK_ASC = 'DIVISIONS_BY_USER_CREATED__MAX_REMARK_ASC',
  DIVISIONS_BY_USER_CREATED__MAX_REMARK_DESC = 'DIVISIONS_BY_USER_CREATED__MAX_REMARK_DESC',
  DIVISIONS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'DIVISIONS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  DIVISIONS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'DIVISIONS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  DIVISIONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'DIVISIONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  DIVISIONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'DIVISIONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  DIVISIONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'DIVISIONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  DIVISIONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'DIVISIONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  DIVISIONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'DIVISIONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  DIVISIONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'DIVISIONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  DIVISIONS_BY_USER_CREATED__MIN_DIVISION_ID_ASC = 'DIVISIONS_BY_USER_CREATED__MIN_DIVISION_ID_ASC',
  DIVISIONS_BY_USER_CREATED__MIN_DIVISION_ID_DESC = 'DIVISIONS_BY_USER_CREATED__MIN_DIVISION_ID_DESC',
  DIVISIONS_BY_USER_CREATED__MIN_IS_ACTIVE_ASC = 'DIVISIONS_BY_USER_CREATED__MIN_IS_ACTIVE_ASC',
  DIVISIONS_BY_USER_CREATED__MIN_IS_ACTIVE_DESC = 'DIVISIONS_BY_USER_CREATED__MIN_IS_ACTIVE_DESC',
  DIVISIONS_BY_USER_CREATED__MIN_NAME_ASC = 'DIVISIONS_BY_USER_CREATED__MIN_NAME_ASC',
  DIVISIONS_BY_USER_CREATED__MIN_NAME_DESC = 'DIVISIONS_BY_USER_CREATED__MIN_NAME_DESC',
  DIVISIONS_BY_USER_CREATED__MIN_REMARK_ASC = 'DIVISIONS_BY_USER_CREATED__MIN_REMARK_ASC',
  DIVISIONS_BY_USER_CREATED__MIN_REMARK_DESC = 'DIVISIONS_BY_USER_CREATED__MIN_REMARK_DESC',
  DIVISIONS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'DIVISIONS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  DIVISIONS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'DIVISIONS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  DIVISIONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'DIVISIONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  DIVISIONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'DIVISIONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  DIVISIONS_BY_USER_UPDATED__COUNT_ASC = 'DIVISIONS_BY_USER_UPDATED__COUNT_ASC',
  DIVISIONS_BY_USER_UPDATED__COUNT_DESC = 'DIVISIONS_BY_USER_UPDATED__COUNT_DESC',
  DIVISIONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'DIVISIONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  DIVISIONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'DIVISIONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  DIVISIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'DIVISIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  DIVISIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'DIVISIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  DIVISIONS_BY_USER_UPDATED__MAX_DIVISION_ID_ASC = 'DIVISIONS_BY_USER_UPDATED__MAX_DIVISION_ID_ASC',
  DIVISIONS_BY_USER_UPDATED__MAX_DIVISION_ID_DESC = 'DIVISIONS_BY_USER_UPDATED__MAX_DIVISION_ID_DESC',
  DIVISIONS_BY_USER_UPDATED__MAX_IS_ACTIVE_ASC = 'DIVISIONS_BY_USER_UPDATED__MAX_IS_ACTIVE_ASC',
  DIVISIONS_BY_USER_UPDATED__MAX_IS_ACTIVE_DESC = 'DIVISIONS_BY_USER_UPDATED__MAX_IS_ACTIVE_DESC',
  DIVISIONS_BY_USER_UPDATED__MAX_NAME_ASC = 'DIVISIONS_BY_USER_UPDATED__MAX_NAME_ASC',
  DIVISIONS_BY_USER_UPDATED__MAX_NAME_DESC = 'DIVISIONS_BY_USER_UPDATED__MAX_NAME_DESC',
  DIVISIONS_BY_USER_UPDATED__MAX_REMARK_ASC = 'DIVISIONS_BY_USER_UPDATED__MAX_REMARK_ASC',
  DIVISIONS_BY_USER_UPDATED__MAX_REMARK_DESC = 'DIVISIONS_BY_USER_UPDATED__MAX_REMARK_DESC',
  DIVISIONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'DIVISIONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  DIVISIONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'DIVISIONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  DIVISIONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'DIVISIONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  DIVISIONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'DIVISIONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  DIVISIONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'DIVISIONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  DIVISIONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'DIVISIONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  DIVISIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'DIVISIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  DIVISIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'DIVISIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  DIVISIONS_BY_USER_UPDATED__MIN_DIVISION_ID_ASC = 'DIVISIONS_BY_USER_UPDATED__MIN_DIVISION_ID_ASC',
  DIVISIONS_BY_USER_UPDATED__MIN_DIVISION_ID_DESC = 'DIVISIONS_BY_USER_UPDATED__MIN_DIVISION_ID_DESC',
  DIVISIONS_BY_USER_UPDATED__MIN_IS_ACTIVE_ASC = 'DIVISIONS_BY_USER_UPDATED__MIN_IS_ACTIVE_ASC',
  DIVISIONS_BY_USER_UPDATED__MIN_IS_ACTIVE_DESC = 'DIVISIONS_BY_USER_UPDATED__MIN_IS_ACTIVE_DESC',
  DIVISIONS_BY_USER_UPDATED__MIN_NAME_ASC = 'DIVISIONS_BY_USER_UPDATED__MIN_NAME_ASC',
  DIVISIONS_BY_USER_UPDATED__MIN_NAME_DESC = 'DIVISIONS_BY_USER_UPDATED__MIN_NAME_DESC',
  DIVISIONS_BY_USER_UPDATED__MIN_REMARK_ASC = 'DIVISIONS_BY_USER_UPDATED__MIN_REMARK_ASC',
  DIVISIONS_BY_USER_UPDATED__MIN_REMARK_DESC = 'DIVISIONS_BY_USER_UPDATED__MIN_REMARK_DESC',
  DIVISIONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'DIVISIONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  DIVISIONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'DIVISIONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  DIVISIONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'DIVISIONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  DIVISIONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'DIVISIONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  EMAIL_ADDRESS_ASC = 'EMAIL_ADDRESS_ASC',
  EMAIL_ADDRESS_DESC = 'EMAIL_ADDRESS_DESC',
  EMAIL_DOMAINS_BY_USER_CREATED__COUNT_ASC = 'EMAIL_DOMAINS_BY_USER_CREATED__COUNT_ASC',
  EMAIL_DOMAINS_BY_USER_CREATED__COUNT_DESC = 'EMAIL_DOMAINS_BY_USER_CREATED__COUNT_DESC',
  EMAIL_DOMAINS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'EMAIL_DOMAINS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  EMAIL_DOMAINS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'EMAIL_DOMAINS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  EMAIL_DOMAINS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'EMAIL_DOMAINS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  EMAIL_DOMAINS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'EMAIL_DOMAINS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  EMAIL_DOMAINS_BY_USER_CREATED__MAX_DOMAIN_NAME_ASC = 'EMAIL_DOMAINS_BY_USER_CREATED__MAX_DOMAIN_NAME_ASC',
  EMAIL_DOMAINS_BY_USER_CREATED__MAX_DOMAIN_NAME_DESC = 'EMAIL_DOMAINS_BY_USER_CREATED__MAX_DOMAIN_NAME_DESC',
  EMAIL_DOMAINS_BY_USER_CREATED__MAX_EMAIL_DOMAIN_ID_ASC = 'EMAIL_DOMAINS_BY_USER_CREATED__MAX_EMAIL_DOMAIN_ID_ASC',
  EMAIL_DOMAINS_BY_USER_CREATED__MAX_EMAIL_DOMAIN_ID_DESC = 'EMAIL_DOMAINS_BY_USER_CREATED__MAX_EMAIL_DOMAIN_ID_DESC',
  EMAIL_DOMAINS_BY_USER_CREATED__MAX_IS_SSO_LOGIN_AVAILABLE_ASC = 'EMAIL_DOMAINS_BY_USER_CREATED__MAX_IS_SSO_LOGIN_AVAILABLE_ASC',
  EMAIL_DOMAINS_BY_USER_CREATED__MAX_IS_SSO_LOGIN_AVAILABLE_DESC = 'EMAIL_DOMAINS_BY_USER_CREATED__MAX_IS_SSO_LOGIN_AVAILABLE_DESC',
  EMAIL_DOMAINS_BY_USER_CREATED__MAX_REMARK_ASC = 'EMAIL_DOMAINS_BY_USER_CREATED__MAX_REMARK_ASC',
  EMAIL_DOMAINS_BY_USER_CREATED__MAX_REMARK_DESC = 'EMAIL_DOMAINS_BY_USER_CREATED__MAX_REMARK_DESC',
  EMAIL_DOMAINS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'EMAIL_DOMAINS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  EMAIL_DOMAINS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'EMAIL_DOMAINS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  EMAIL_DOMAINS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'EMAIL_DOMAINS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  EMAIL_DOMAINS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'EMAIL_DOMAINS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  EMAIL_DOMAINS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'EMAIL_DOMAINS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  EMAIL_DOMAINS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'EMAIL_DOMAINS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  EMAIL_DOMAINS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'EMAIL_DOMAINS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  EMAIL_DOMAINS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'EMAIL_DOMAINS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  EMAIL_DOMAINS_BY_USER_CREATED__MIN_DOMAIN_NAME_ASC = 'EMAIL_DOMAINS_BY_USER_CREATED__MIN_DOMAIN_NAME_ASC',
  EMAIL_DOMAINS_BY_USER_CREATED__MIN_DOMAIN_NAME_DESC = 'EMAIL_DOMAINS_BY_USER_CREATED__MIN_DOMAIN_NAME_DESC',
  EMAIL_DOMAINS_BY_USER_CREATED__MIN_EMAIL_DOMAIN_ID_ASC = 'EMAIL_DOMAINS_BY_USER_CREATED__MIN_EMAIL_DOMAIN_ID_ASC',
  EMAIL_DOMAINS_BY_USER_CREATED__MIN_EMAIL_DOMAIN_ID_DESC = 'EMAIL_DOMAINS_BY_USER_CREATED__MIN_EMAIL_DOMAIN_ID_DESC',
  EMAIL_DOMAINS_BY_USER_CREATED__MIN_IS_SSO_LOGIN_AVAILABLE_ASC = 'EMAIL_DOMAINS_BY_USER_CREATED__MIN_IS_SSO_LOGIN_AVAILABLE_ASC',
  EMAIL_DOMAINS_BY_USER_CREATED__MIN_IS_SSO_LOGIN_AVAILABLE_DESC = 'EMAIL_DOMAINS_BY_USER_CREATED__MIN_IS_SSO_LOGIN_AVAILABLE_DESC',
  EMAIL_DOMAINS_BY_USER_CREATED__MIN_REMARK_ASC = 'EMAIL_DOMAINS_BY_USER_CREATED__MIN_REMARK_ASC',
  EMAIL_DOMAINS_BY_USER_CREATED__MIN_REMARK_DESC = 'EMAIL_DOMAINS_BY_USER_CREATED__MIN_REMARK_DESC',
  EMAIL_DOMAINS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'EMAIL_DOMAINS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  EMAIL_DOMAINS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'EMAIL_DOMAINS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  EMAIL_DOMAINS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'EMAIL_DOMAINS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  EMAIL_DOMAINS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'EMAIL_DOMAINS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  EMAIL_DOMAINS_BY_USER_UPDATED__COUNT_ASC = 'EMAIL_DOMAINS_BY_USER_UPDATED__COUNT_ASC',
  EMAIL_DOMAINS_BY_USER_UPDATED__COUNT_DESC = 'EMAIL_DOMAINS_BY_USER_UPDATED__COUNT_DESC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MAX_DOMAIN_NAME_ASC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MAX_DOMAIN_NAME_ASC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MAX_DOMAIN_NAME_DESC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MAX_DOMAIN_NAME_DESC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MAX_EMAIL_DOMAIN_ID_ASC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MAX_EMAIL_DOMAIN_ID_ASC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MAX_EMAIL_DOMAIN_ID_DESC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MAX_EMAIL_DOMAIN_ID_DESC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MAX_IS_SSO_LOGIN_AVAILABLE_ASC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MAX_IS_SSO_LOGIN_AVAILABLE_ASC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MAX_IS_SSO_LOGIN_AVAILABLE_DESC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MAX_IS_SSO_LOGIN_AVAILABLE_DESC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MAX_REMARK_ASC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MAX_REMARK_ASC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MAX_REMARK_DESC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MAX_REMARK_DESC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MIN_DOMAIN_NAME_ASC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MIN_DOMAIN_NAME_ASC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MIN_DOMAIN_NAME_DESC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MIN_DOMAIN_NAME_DESC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MIN_EMAIL_DOMAIN_ID_ASC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MIN_EMAIL_DOMAIN_ID_ASC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MIN_EMAIL_DOMAIN_ID_DESC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MIN_EMAIL_DOMAIN_ID_DESC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MIN_IS_SSO_LOGIN_AVAILABLE_ASC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MIN_IS_SSO_LOGIN_AVAILABLE_ASC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MIN_IS_SSO_LOGIN_AVAILABLE_DESC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MIN_IS_SSO_LOGIN_AVAILABLE_DESC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MIN_REMARK_ASC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MIN_REMARK_ASC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MIN_REMARK_DESC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MIN_REMARK_DESC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  EMAIL_DOMAINS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'EMAIL_DOMAINS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  EMPLOYEES_BY_USER_CREATED__COUNT_ASC = 'EMPLOYEES_BY_USER_CREATED__COUNT_ASC',
  EMPLOYEES_BY_USER_CREATED__COUNT_DESC = 'EMPLOYEES_BY_USER_CREATED__COUNT_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_APPROVER_ID_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_APPROVER_ID_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_APPROVER_ID_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_APPROVER_ID_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_BANK_ID_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_BANK_ID_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_BANK_ID_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_BANK_ID_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_BANK_REMARKS_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_BANK_REMARKS_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_BANK_REMARKS_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_BANK_REMARKS_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_FULL_NAME_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_FULL_NAME_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_FULL_NAME_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_FULL_NAME_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_GENDER_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_GENDER_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_GENDER_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_GENDER_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_ID_NUMBER_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_ID_NUMBER_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_ID_NUMBER_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_ID_NUMBER_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_ID_TYPE_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_ID_TYPE_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_ID_TYPE_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_ID_TYPE_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_JOIN_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_JOIN_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_JOIN_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_JOIN_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_NATIONALITY_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_NATIONALITY_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_NATIONALITY_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_NATIONALITY_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_NICKNAME_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_NICKNAME_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_NICKNAME_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_NICKNAME_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_PROBATION_END_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_PROBATION_END_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_PROBATION_END_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_PROBATION_END_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_REMARK_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_REMARK_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_REMARK_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_REMARK_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_STATUS_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_STATUS_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_STATUS_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_STATUS_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_TAX_CODE_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_TAX_CODE_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_TAX_CODE_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_TAX_CODE_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_VISA_NUMBER_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_VISA_NUMBER_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_VISA_NUMBER_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_VISA_NUMBER_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_VISA_TYPE_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_VISA_TYPE_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_VISA_TYPE_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_VISA_TYPE_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MAX_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_USER_CREATED__MAX_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_USER_CREATED__MAX_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_USER_CREATED__MAX_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_APPROVER_ID_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_APPROVER_ID_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_APPROVER_ID_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_APPROVER_ID_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_BANK_ID_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_BANK_ID_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_BANK_ID_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_BANK_ID_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_BANK_REMARKS_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_BANK_REMARKS_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_BANK_REMARKS_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_BANK_REMARKS_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_FULL_NAME_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_FULL_NAME_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_FULL_NAME_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_FULL_NAME_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_GENDER_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_GENDER_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_GENDER_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_GENDER_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_ID_NUMBER_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_ID_NUMBER_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_ID_NUMBER_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_ID_NUMBER_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_ID_TYPE_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_ID_TYPE_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_ID_TYPE_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_ID_TYPE_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_JOIN_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_JOIN_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_JOIN_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_JOIN_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_NATIONALITY_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_NATIONALITY_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_NATIONALITY_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_NATIONALITY_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_NICKNAME_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_NICKNAME_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_NICKNAME_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_NICKNAME_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_PROBATION_END_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_PROBATION_END_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_PROBATION_END_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_PROBATION_END_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_REMARK_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_REMARK_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_REMARK_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_REMARK_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_STATUS_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_STATUS_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_STATUS_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_STATUS_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_TAX_CODE_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_TAX_CODE_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_TAX_CODE_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_TAX_CODE_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_VISA_NUMBER_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_VISA_NUMBER_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_VISA_NUMBER_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_VISA_NUMBER_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_VISA_TYPE_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_VISA_TYPE_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_VISA_TYPE_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_VISA_TYPE_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_USER_CREATED__MIN_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_USER_CREATED__MIN_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_USER_CREATED__MIN_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_USER_CREATED__MIN_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEES_BY_USER_UPDATED__COUNT_ASC = 'EMPLOYEES_BY_USER_UPDATED__COUNT_ASC',
  EMPLOYEES_BY_USER_UPDATED__COUNT_DESC = 'EMPLOYEES_BY_USER_UPDATED__COUNT_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_APPROVER_ID_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_APPROVER_ID_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_APPROVER_ID_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_APPROVER_ID_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_BANK_ID_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_BANK_ID_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_BANK_ID_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_BANK_ID_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_BANK_REMARKS_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_BANK_REMARKS_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_BANK_REMARKS_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_BANK_REMARKS_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_FULL_NAME_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_FULL_NAME_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_FULL_NAME_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_FULL_NAME_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_GENDER_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_GENDER_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_GENDER_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_GENDER_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_ID_NUMBER_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_ID_NUMBER_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_ID_NUMBER_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_ID_NUMBER_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_ID_TYPE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_ID_TYPE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_ID_TYPE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_ID_TYPE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_JOIN_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_JOIN_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_JOIN_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_JOIN_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_NATIONALITY_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_NATIONALITY_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_NATIONALITY_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_NATIONALITY_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_NICKNAME_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_NICKNAME_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_NICKNAME_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_NICKNAME_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_PROBATION_END_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_PROBATION_END_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_PROBATION_END_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_PROBATION_END_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_REMARK_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_REMARK_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_REMARK_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_REMARK_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_STATUS_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_STATUS_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_STATUS_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_STATUS_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_TAX_CODE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_TAX_CODE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_TAX_CODE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_TAX_CODE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_VISA_NUMBER_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_VISA_NUMBER_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_VISA_NUMBER_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_VISA_NUMBER_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_VISA_TYPE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_VISA_TYPE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_VISA_TYPE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_VISA_TYPE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MAX_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_USER_UPDATED__MAX_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_USER_UPDATED__MAX_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_USER_UPDATED__MAX_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_APPROVER_ID_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_APPROVER_ID_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_APPROVER_ID_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_APPROVER_ID_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_BANK_ID_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_BANK_ID_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_BANK_ID_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_BANK_ID_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_BANK_REMARKS_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_BANK_REMARKS_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_BANK_REMARKS_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_BANK_REMARKS_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_COMPANY_EMAIL_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_COMPANY_EMAIL_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_COMPANY_EMAIL_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_COMPANY_EMAIL_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_BANK_ACCOUNT_HOLDER_NAME_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_BANK_ACCOUNT_NUMBER_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_BANK_BRANCH_NAME_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_BANK_ID_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_BANK_ID_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_BANK_ID_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_BANK_ID_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_BANK_REMARKS_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_BANK_REMARKS_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_BANK_REMARKS_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_BANK_REMARKS_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_CURRENCY_ID_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_CURRENCY_ID_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_CURRENCY_ID_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_CURRENCY_ID_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_TYPE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_TYPE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_TYPE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_CONSULTING_FEE_TYPE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_CURRENT_ADDRESS_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_CURRENT_ADDRESS_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_CURRENT_ADDRESS_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_CURRENT_ADDRESS_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_CURRENT_ADDRESS_ZIPCODE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_CURRENT_ADDRESS_ZIPCODE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_CURRENT_ADDRESS_ZIPCODE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_CURRENT_ADDRESS_ZIPCODE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_DATE_OF_BIRTH_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_DATE_OF_BIRTH_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_DATE_OF_BIRTH_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_DATE_OF_BIRTH_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_DOTTED_LINE_MANAGER_ID_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_DOTTED_LINE_MANAGER_ID_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_DOTTED_LINE_MANAGER_ID_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_DOTTED_LINE_MANAGER_ID_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_FULL_NAME_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_FULL_NAME_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_FULL_NAME_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_FULL_NAME_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_FULL_NAME_IN_LOCAL_LANGUAGE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_FULL_NAME_KANA_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_FULL_NAME_KANA_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_FULL_NAME_KANA_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_FULL_NAME_KANA_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_GENDER_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_GENDER_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_GENDER_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_GENDER_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_ID_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_ID_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_ID_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_ID_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_ID_ISSUED_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_ID_ISSUED_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_ID_ISSUED_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_ID_ISSUED_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_ID_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_ID_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_ID_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_ID_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_ID_NUMBER_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_ID_NUMBER_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_ID_NUMBER_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_ID_NUMBER_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_ID_TYPE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_ID_TYPE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_ID_TYPE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_ID_TYPE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_JOIN_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_JOIN_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_JOIN_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_JOIN_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_LAST_WORKING_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_LAST_WORKING_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_LAST_WORKING_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_LAST_WORKING_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_MARITAL_STATUS_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_MARITAL_STATUS_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_MARITAL_STATUS_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_MARITAL_STATUS_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_MOBILE_NUMBER_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_MOBILE_NUMBER_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_MOBILE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_MOBILE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_MOBILE_NUMBER_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_MOBILE_NUMBER_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_NATIONALITY_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_NATIONALITY_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_NATIONALITY_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_NATIONALITY_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_NHI_DEPENDENCIES_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_NHI_DEPENDENCIES_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_NHI_DEPENDENCIES_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_NHI_DEPENDENCIES_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_NICKNAME_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_NICKNAME_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_NICKNAME_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_NICKNAME_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_NUMBER_OF_DEPENDENCIES_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_NUMBER_OF_DEPENDENCIES_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_NUMBER_OF_DEPENDENCIES_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_NUMBER_OF_DEPENDENCIES_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_PASSPORT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_PASSPORT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_PASSPORT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_PASSPORT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_PASSPORT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_PASSPORT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_PASSPORT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_PASSPORT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_PASSPORT_ISSUED_PLACE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_PASSPORT_ISSUED_PLACE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_PASSPORT_ISSUED_PLACE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_PASSPORT_ISSUED_PLACE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_PASSPORT_NAME_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_PASSPORT_NAME_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_PASSPORT_NAME_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_PASSPORT_NAME_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_PASSPORT_NUMBER_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_PASSPORT_NUMBER_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_PASSPORT_NUMBER_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_PASSPORT_NUMBER_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_PENSION_VOLUNTARY_CONTRIBUTION_RATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_PERMANENT_ADDRESS_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_PERMANENT_ADDRESS_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_PERMANENT_ADDRESS_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_PERMANENT_ADDRESS_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_PERSONAL_EMAIL_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_PERSONAL_EMAIL_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_PERSONAL_EMAIL_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_PERSONAL_EMAIL_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_PLACE_OF_BIRTH_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_PLACE_OF_BIRTH_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_PLACE_OF_BIRTH_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_PLACE_OF_BIRTH_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_PRIMARY_MANAGER_ID_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_PRIMARY_MANAGER_ID_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_PRIMARY_MANAGER_ID_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_PRIMARY_MANAGER_ID_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_PROBATION_END_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_PROBATION_END_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_PROBATION_END_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_PROBATION_END_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_REMARK_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_REMARK_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_REMARK_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_REMARK_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_RESIGNATION_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_RESIGNATION_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_RESIGNATION_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_RESIGNATION_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_RESIGNATION_REASON_DETAIL_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_RESIGNATION_REASON_DETAIL_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_RESIGNATION_REASON_DETAIL_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_RESIGNATION_REASON_DETAIL_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_RESIGNATION_REASON_ID_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_RESIGNATION_REASON_ID_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_RESIGNATION_REASON_ID_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_RESIGNATION_REASON_ID_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_SENT_ACCOUNT_REQUEST_EMAIL_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_SENT_CONTRACT_END_EMAIL_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_SENT_CONTRACT_END_EMAIL_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_SOCIAL_INSURANCE_NUMBER_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_SOCIAL_INSURANCE_NUMBER_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_SOCIAL_INSURANCE_NUMBER_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_SOCIAL_INSURANCE_NUMBER_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_STATUS_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_STATUS_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_STATUS_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_STATUS_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_TAX_CODE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_TAX_CODE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_TAX_CODE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_TAX_CODE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_TAX_WITHHOLDING_RATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_TAX_WITHHOLDING_RATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_TAX_WITHHOLDING_RATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_TAX_WITHHOLDING_RATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_VISA_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_VISA_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_VISA_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_VISA_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_VISA_ISSUED_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_VISA_ISSUED_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_VISA_ISSUED_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_VISA_ISSUED_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_VISA_NUMBER_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_VISA_NUMBER_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_VISA_NUMBER_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_VISA_NUMBER_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_VISA_TYPE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_VISA_TYPE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_VISA_TYPE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_VISA_TYPE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_WORKING_LOCATION_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_WORK_PERMIT_EXPIRY_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_WORK_PERMIT_EXPIRY_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_WORK_PERMIT_EXPIRY_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_WORK_PERMIT_EXPIRY_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_WORK_PERMIT_ISSUED_DATE_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_WORK_PERMIT_ISSUED_DATE_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_WORK_PERMIT_ISSUED_DATE_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_WORK_PERMIT_ISSUED_DATE_DESC',
  EMPLOYEES_BY_USER_UPDATED__MIN_WORK_PERMIT_NUMBER_ASC = 'EMPLOYEES_BY_USER_UPDATED__MIN_WORK_PERMIT_NUMBER_ASC',
  EMPLOYEES_BY_USER_UPDATED__MIN_WORK_PERMIT_NUMBER_DESC = 'EMPLOYEES_BY_USER_UPDATED__MIN_WORK_PERMIT_NUMBER_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__COUNT_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__COUNT_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__COUNT_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__COUNT_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_EMPLOYEE_EMERGENCY_CONTACT_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_EMPLOYEE_EMERGENCY_CONTACT_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_EMPLOYEE_EMERGENCY_CONTACT_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_EMPLOYEE_EMERGENCY_CONTACT_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_NAME_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_NAME_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_NAME_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_NAME_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_RELATION_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_RELATION_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_RELATION_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_RELATION_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_REMARK_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_REMARK_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_REMARK_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_REMARK_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_TELEPHONE_NUMBER_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_TELEPHONE_NUMBER_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_TELEPHONE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_TELEPHONE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_TELEPHONE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_TELEPHONE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_TELEPHONE_NUMBER_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_TELEPHONE_NUMBER_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_EMPLOYEE_EMERGENCY_CONTACT_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_EMPLOYEE_EMERGENCY_CONTACT_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_EMPLOYEE_EMERGENCY_CONTACT_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_EMPLOYEE_EMERGENCY_CONTACT_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_NAME_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_NAME_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_NAME_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_NAME_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_RELATION_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_RELATION_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_RELATION_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_RELATION_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_REMARK_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_REMARK_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_REMARK_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_REMARK_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_TELEPHONE_NUMBER_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_TELEPHONE_NUMBER_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_TELEPHONE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_TELEPHONE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_TELEPHONE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_TELEPHONE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_TELEPHONE_NUMBER_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_TELEPHONE_NUMBER_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__COUNT_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__COUNT_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__COUNT_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__COUNT_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_EMPLOYEE_EMERGENCY_CONTACT_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_EMPLOYEE_EMERGENCY_CONTACT_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_EMPLOYEE_EMERGENCY_CONTACT_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_EMPLOYEE_EMERGENCY_CONTACT_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_NAME_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_NAME_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_NAME_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_NAME_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_RELATION_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_RELATION_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_RELATION_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_RELATION_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_REMARK_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_REMARK_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_REMARK_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_REMARK_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_TELEPHONE_NUMBER_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_TELEPHONE_NUMBER_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_TELEPHONE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_TELEPHONE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_TELEPHONE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_TELEPHONE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_TELEPHONE_NUMBER_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_TELEPHONE_NUMBER_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_EMPLOYEE_EMERGENCY_CONTACT_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_EMPLOYEE_EMERGENCY_CONTACT_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_EMPLOYEE_EMERGENCY_CONTACT_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_EMPLOYEE_EMERGENCY_CONTACT_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_NAME_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_NAME_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_NAME_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_NAME_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_RELATION_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_RELATION_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_RELATION_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_RELATION_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_REMARK_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_REMARK_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_REMARK_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_REMARK_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_TELEPHONE_NUMBER_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_TELEPHONE_NUMBER_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_TELEPHONE_NUMBER_COUNTRY_ID_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_TELEPHONE_NUMBER_COUNTRY_ID_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_TELEPHONE_NUMBER_COUNTRY_ID_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_TELEPHONE_NUMBER_COUNTRY_ID_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_TELEPHONE_NUMBER_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_TELEPHONE_NUMBER_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'EMPLOYEE_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__COUNT_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__COUNT_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__COUNT_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__COUNT_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_EMPLOYEE_FILE_ID_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_EMPLOYEE_FILE_ID_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_EMPLOYEE_FILE_ID_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_EMPLOYEE_FILE_ID_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_FILE_NAME_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_FILE_NAME_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_FILE_NAME_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_FILE_NAME_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_FILE_SIZE_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_FILE_SIZE_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_FILE_SIZE_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_FILE_SIZE_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_IS_HIDDEN_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_IS_HIDDEN_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_IS_HIDDEN_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_IS_HIDDEN_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_IS_PRIVATE_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_IS_PRIVATE_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_IS_PRIVATE_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_IS_PRIVATE_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_MIME_TYPE_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_MIME_TYPE_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_MIME_TYPE_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_MIME_TYPE_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_REMARK_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_REMARK_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_REMARK_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_REMARK_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_S3_PATH_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_S3_PATH_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_S3_PATH_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_S3_PATH_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_TITLE_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_TITLE_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_TITLE_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_TITLE_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_VISIBLE_ROLE_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_VISIBLE_ROLE_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MAX_VISIBLE_ROLE_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MAX_VISIBLE_ROLE_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_EMPLOYEE_FILE_ID_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_EMPLOYEE_FILE_ID_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_EMPLOYEE_FILE_ID_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_EMPLOYEE_FILE_ID_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_FILE_NAME_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_FILE_NAME_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_FILE_NAME_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_FILE_NAME_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_FILE_SIZE_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_FILE_SIZE_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_FILE_SIZE_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_FILE_SIZE_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_IS_HIDDEN_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_IS_HIDDEN_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_IS_HIDDEN_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_IS_HIDDEN_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_IS_PRIVATE_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_IS_PRIVATE_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_IS_PRIVATE_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_IS_PRIVATE_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_MIME_TYPE_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_MIME_TYPE_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_MIME_TYPE_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_MIME_TYPE_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_REMARK_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_REMARK_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_REMARK_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_REMARK_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_S3_PATH_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_S3_PATH_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_S3_PATH_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_S3_PATH_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_TITLE_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_TITLE_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_TITLE_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_TITLE_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_VISIBLE_ROLE_ASC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_VISIBLE_ROLE_ASC',
  EMPLOYEE_FILES_BY_USER_CREATED__MIN_VISIBLE_ROLE_DESC = 'EMPLOYEE_FILES_BY_USER_CREATED__MIN_VISIBLE_ROLE_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__COUNT_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__COUNT_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__COUNT_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__COUNT_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_EMPLOYEE_FILE_ID_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_EMPLOYEE_FILE_ID_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_EMPLOYEE_FILE_ID_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_EMPLOYEE_FILE_ID_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_FILE_NAME_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_FILE_NAME_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_FILE_NAME_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_FILE_NAME_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_FILE_SIZE_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_FILE_SIZE_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_FILE_SIZE_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_FILE_SIZE_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_IS_HIDDEN_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_IS_HIDDEN_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_IS_HIDDEN_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_IS_HIDDEN_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_IS_PRIVATE_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_IS_PRIVATE_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_IS_PRIVATE_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_IS_PRIVATE_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_MIME_TYPE_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_MIME_TYPE_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_MIME_TYPE_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_MIME_TYPE_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_REMARK_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_REMARK_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_REMARK_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_REMARK_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_S3_PATH_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_S3_PATH_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_S3_PATH_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_S3_PATH_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_TITLE_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_TITLE_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_TITLE_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_TITLE_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_VISIBLE_ROLE_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_VISIBLE_ROLE_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MAX_VISIBLE_ROLE_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MAX_VISIBLE_ROLE_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_EMPLOYEE_FILE_ID_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_EMPLOYEE_FILE_ID_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_EMPLOYEE_FILE_ID_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_EMPLOYEE_FILE_ID_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_FILE_NAME_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_FILE_NAME_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_FILE_NAME_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_FILE_NAME_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_FILE_SIZE_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_FILE_SIZE_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_FILE_SIZE_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_FILE_SIZE_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_IS_HIDDEN_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_IS_HIDDEN_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_IS_HIDDEN_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_IS_HIDDEN_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_IS_PRIVATE_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_IS_PRIVATE_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_IS_PRIVATE_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_IS_PRIVATE_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_MIME_TYPE_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_MIME_TYPE_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_MIME_TYPE_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_MIME_TYPE_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_REMARK_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_REMARK_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_REMARK_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_REMARK_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_S3_PATH_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_S3_PATH_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_S3_PATH_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_S3_PATH_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_TITLE_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_TITLE_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_TITLE_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_TITLE_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_VISIBLE_ROLE_ASC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_VISIBLE_ROLE_ASC',
  EMPLOYEE_FILES_BY_USER_UPDATED__MIN_VISIBLE_ROLE_DESC = 'EMPLOYEE_FILES_BY_USER_UPDATED__MIN_VISIBLE_ROLE_DESC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__COUNT_ASC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__COUNT_ASC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__COUNT_DESC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__COUNT_DESC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_COMPANY_EMAIL_ASC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_COMPANY_EMAIL_ASC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_COMPANY_EMAIL_DESC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_COMPANY_EMAIL_DESC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_REMARK_ASC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_REMARK_ASC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_REMARK_DESC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_REMARK_DESC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_COMPANY_EMAIL_ASC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_COMPANY_EMAIL_ASC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_COMPANY_EMAIL_DESC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_COMPANY_EMAIL_DESC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_REMARK_ASC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_REMARK_ASC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_REMARK_DESC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_REMARK_DESC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'EMPLOYEE_GROUPS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__COUNT_ASC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__COUNT_ASC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__COUNT_DESC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__COUNT_DESC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_COMPANY_EMAIL_ASC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_COMPANY_EMAIL_ASC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_COMPANY_EMAIL_DESC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_COMPANY_EMAIL_DESC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_REMARK_ASC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_REMARK_ASC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_REMARK_DESC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_REMARK_DESC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_COMPANY_EMAIL_ASC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_COMPANY_EMAIL_ASC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_COMPANY_EMAIL_DESC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_COMPANY_EMAIL_DESC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_REMARK_ASC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_REMARK_ASC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_REMARK_DESC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_REMARK_DESC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'EMPLOYEE_GROUPS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__COUNT_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__COUNT_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__COUNT_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__COUNT_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_CODE_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_CODE_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_CODE_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_CODE_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_DISPLAY_NAME_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_DISPLAY_NAME_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_DISPLAY_NAME_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_DISPLAY_NAME_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_DISPLAY_ORDER_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_DISPLAY_ORDER_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_DISPLAY_ORDER_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_DISPLAY_ORDER_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_EMPLOYEE_ID_TYPE_ID_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_EMPLOYEE_ID_TYPE_ID_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_EMPLOYEE_ID_TYPE_ID_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_EMPLOYEE_ID_TYPE_ID_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_REMARK_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_REMARK_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_REMARK_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_REMARK_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_CODE_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_CODE_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_CODE_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_CODE_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_DISPLAY_NAME_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_DISPLAY_NAME_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_DISPLAY_NAME_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_DISPLAY_NAME_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_DISPLAY_ORDER_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_DISPLAY_ORDER_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_DISPLAY_ORDER_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_DISPLAY_ORDER_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_EMPLOYEE_ID_TYPE_ID_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_EMPLOYEE_ID_TYPE_ID_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_EMPLOYEE_ID_TYPE_ID_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_EMPLOYEE_ID_TYPE_ID_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_REMARK_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_REMARK_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_REMARK_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_REMARK_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__COUNT_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__COUNT_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__COUNT_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__COUNT_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_CODE_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_CODE_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_CODE_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_CODE_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_DISPLAY_NAME_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_DISPLAY_NAME_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_DISPLAY_NAME_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_DISPLAY_NAME_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_DISPLAY_ORDER_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_DISPLAY_ORDER_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_DISPLAY_ORDER_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_DISPLAY_ORDER_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_EMPLOYEE_ID_TYPE_ID_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_EMPLOYEE_ID_TYPE_ID_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_EMPLOYEE_ID_TYPE_ID_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_EMPLOYEE_ID_TYPE_ID_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_REMARK_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_REMARK_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_REMARK_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_REMARK_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_CODE_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_CODE_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_CODE_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_CODE_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA2_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA2_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA2_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA2_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_DISPLAY_NAME_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_DISPLAY_NAME_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_DISPLAY_NAME_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_DISPLAY_NAME_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_DISPLAY_ORDER_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_DISPLAY_ORDER_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_DISPLAY_ORDER_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_DISPLAY_ORDER_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_EMPLOYEE_ID_TYPE_ID_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_EMPLOYEE_ID_TYPE_ID_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_EMPLOYEE_ID_TYPE_ID_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_EMPLOYEE_ID_TYPE_ID_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_REMARK_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_REMARK_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_REMARK_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_REMARK_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'EMPLOYEE_ID_TYPES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__COUNT_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__COUNT_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__COUNT_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__COUNT_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_EDIT_TARGET_COUNTRY_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_EDIT_TARGET_COUNTRY_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_EDIT_TARGET_COUNTRY_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_EDIT_TARGET_COUNTRY_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_EDIT_TARGET_INDIVIDUAL_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_EDIT_TARGET_INDIVIDUAL_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_EDIT_TARGET_INDIVIDUAL_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_EDIT_TARGET_INDIVIDUAL_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_EDIT_TARGET_TEAM_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_EDIT_TARGET_TEAM_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_EDIT_TARGET_TEAM_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_EDIT_TARGET_TEAM_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_SEE_PROBATION_REVIEW_OF_ALL_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_SEE_PROBATION_REVIEW_OF_ALL_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_SEE_PROBATION_REVIEW_OF_ALL_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_SEE_PROBATION_REVIEW_OF_ALL_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_DISPLAY_NAME_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_DISPLAY_NAME_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_DISPLAY_NAME_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_DISPLAY_NAME_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_EMPLOYEE_LEVEL_ORDER_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_EMPLOYEE_LEVEL_ORDER_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_EMPLOYEE_LEVEL_ORDER_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_EMPLOYEE_LEVEL_ORDER_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_REMARK_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_REMARK_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_REMARK_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_REMARK_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_TITLE_CONTRIBUTOR_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_TITLE_CONTRIBUTOR_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_TITLE_CONTRIBUTOR_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_TITLE_CONTRIBUTOR_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_TITLE_MANAGER_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_TITLE_MANAGER_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_TITLE_MANAGER_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_TITLE_MANAGER_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_EDIT_TARGET_COUNTRY_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_EDIT_TARGET_COUNTRY_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_EDIT_TARGET_COUNTRY_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_EDIT_TARGET_COUNTRY_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_EDIT_TARGET_INDIVIDUAL_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_EDIT_TARGET_INDIVIDUAL_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_EDIT_TARGET_INDIVIDUAL_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_EDIT_TARGET_INDIVIDUAL_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_EDIT_TARGET_TEAM_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_EDIT_TARGET_TEAM_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_EDIT_TARGET_TEAM_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_EDIT_TARGET_TEAM_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_SEE_PROBATION_REVIEW_OF_ALL_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_SEE_PROBATION_REVIEW_OF_ALL_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_SEE_PROBATION_REVIEW_OF_ALL_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_SEE_PROBATION_REVIEW_OF_ALL_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_DISPLAY_NAME_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_DISPLAY_NAME_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_DISPLAY_NAME_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_DISPLAY_NAME_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_EMPLOYEE_LEVEL_ORDER_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_EMPLOYEE_LEVEL_ORDER_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_EMPLOYEE_LEVEL_ORDER_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_EMPLOYEE_LEVEL_ORDER_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_REMARK_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_REMARK_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_REMARK_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_REMARK_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_TITLE_CONTRIBUTOR_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_TITLE_CONTRIBUTOR_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_TITLE_CONTRIBUTOR_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_TITLE_CONTRIBUTOR_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_TITLE_MANAGER_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_TITLE_MANAGER_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_TITLE_MANAGER_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_TITLE_MANAGER_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'EMPLOYEE_LEVELS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__COUNT_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__COUNT_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__COUNT_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__COUNT_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_EDIT_TARGET_COUNTRY_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_EDIT_TARGET_COUNTRY_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_EDIT_TARGET_COUNTRY_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_EDIT_TARGET_COUNTRY_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_EDIT_TARGET_INDIVIDUAL_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_EDIT_TARGET_INDIVIDUAL_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_EDIT_TARGET_INDIVIDUAL_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_EDIT_TARGET_INDIVIDUAL_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_EDIT_TARGET_TEAM_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_EDIT_TARGET_TEAM_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_EDIT_TARGET_TEAM_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_EDIT_TARGET_TEAM_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_SEE_PROBATION_REVIEW_OF_ALL_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_SEE_PROBATION_REVIEW_OF_ALL_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_SEE_PROBATION_REVIEW_OF_ALL_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_SEE_PROBATION_REVIEW_OF_ALL_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_DISPLAY_NAME_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_DISPLAY_NAME_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_DISPLAY_NAME_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_DISPLAY_NAME_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_EMPLOYEE_LEVEL_ORDER_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_EMPLOYEE_LEVEL_ORDER_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_EMPLOYEE_LEVEL_ORDER_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_EMPLOYEE_LEVEL_ORDER_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_REMARK_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_REMARK_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_REMARK_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_REMARK_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_TITLE_CONTRIBUTOR_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_TITLE_CONTRIBUTOR_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_TITLE_CONTRIBUTOR_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_TITLE_CONTRIBUTOR_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_TITLE_MANAGER_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_TITLE_MANAGER_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_TITLE_MANAGER_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_TITLE_MANAGER_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_EDIT_TARGET_COUNTRY_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_EDIT_TARGET_COUNTRY_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_EDIT_TARGET_COUNTRY_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_EDIT_TARGET_COUNTRY_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_EDIT_TARGET_INDIVIDUAL_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_EDIT_TARGET_INDIVIDUAL_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_EDIT_TARGET_INDIVIDUAL_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_EDIT_TARGET_INDIVIDUAL_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_EDIT_TARGET_TEAM_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_EDIT_TARGET_TEAM_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_EDIT_TARGET_TEAM_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_EDIT_TARGET_TEAM_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_ALL_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_SEE_PERFORMANCE_REVIEW_OVERALL_OF_COUNTRY_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_SEE_PROBATION_REVIEW_OF_ALL_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_SEE_PROBATION_REVIEW_OF_ALL_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_SEE_PROBATION_REVIEW_OF_ALL_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_SEE_PROBATION_REVIEW_OF_ALL_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_CAN_SEE_PROBATION_REVIEW_OF_COUNTRY_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_DISPLAY_NAME_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_DISPLAY_NAME_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_DISPLAY_NAME_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_DISPLAY_NAME_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_EMPLOYEE_LEVEL_ORDER_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_EMPLOYEE_LEVEL_ORDER_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_EMPLOYEE_LEVEL_ORDER_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_EMPLOYEE_LEVEL_ORDER_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_REMARK_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_REMARK_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_REMARK_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_REMARK_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_TITLE_CONTRIBUTOR_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_TITLE_CONTRIBUTOR_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_TITLE_CONTRIBUTOR_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_TITLE_CONTRIBUTOR_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_TITLE_MANAGER_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_TITLE_MANAGER_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_TITLE_MANAGER_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_TITLE_MANAGER_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'EMPLOYEE_LEVELS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__COUNT_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__COUNT_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__COUNT_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__COUNT_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_FILTER_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_FILTER_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_FILTER_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_FILTER_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_IS_PRIVATE_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_IS_PRIVATE_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_IS_PRIVATE_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_IS_PRIVATE_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_REMARK_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_REMARK_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_REMARK_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_REMARK_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_SORT_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_SORT_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_SORT_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_SORT_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_TITLE_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_TITLE_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_TITLE_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_TITLE_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_FILTER_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_FILTER_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_FILTER_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_FILTER_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_IS_PRIVATE_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_IS_PRIVATE_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_IS_PRIVATE_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_IS_PRIVATE_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_REMARK_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_REMARK_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_REMARK_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_REMARK_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_SORT_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_SORT_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_SORT_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_SORT_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_TITLE_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_TITLE_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_TITLE_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_TITLE_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__COUNT_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__COUNT_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__COUNT_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__COUNT_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_FILTER_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_FILTER_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_FILTER_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_FILTER_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_IS_PRIVATE_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_IS_PRIVATE_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_IS_PRIVATE_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_IS_PRIVATE_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_REMARK_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_REMARK_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_REMARK_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_REMARK_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_SORT_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_SORT_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_SORT_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_SORT_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_TITLE_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_TITLE_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_TITLE_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_TITLE_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_FILTER_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_FILTER_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_FILTER_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_FILTER_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_IS_PRIVATE_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_IS_PRIVATE_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_IS_PRIVATE_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_IS_PRIVATE_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_REMARK_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_REMARK_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_REMARK_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_REMARK_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_SORT_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_SORT_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_SORT_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_SORT_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_TITLE_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_TITLE_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_TITLE_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_TITLE_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTERS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__COUNT_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__COUNT_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__COUNT_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__COUNT_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_REMARK_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_REMARK_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_REMARK_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_REMARK_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_ROLE_NAME_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_ROLE_NAME_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_ROLE_NAME_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_ROLE_NAME_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_REMARK_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_REMARK_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_REMARK_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_REMARK_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_ROLE_NAME_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_ROLE_NAME_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_ROLE_NAME_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_ROLE_NAME_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__COUNT_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__COUNT_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__COUNT_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__COUNT_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_REMARK_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_REMARK_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_REMARK_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_REMARK_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_ROLE_NAME_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_ROLE_NAME_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_ROLE_NAME_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_ROLE_NAME_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_REMARK_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_REMARK_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_REMARK_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_REMARK_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_ROLE_NAME_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_ROLE_NAME_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_ROLE_NAME_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_ROLE_NAME_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__COUNT_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__COUNT_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__COUNT_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__COUNT_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_DISPLAY_NAME_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_DISPLAY_NAME_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_DISPLAY_NAME_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_DISPLAY_NAME_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_EMPLOYEE_RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_EMPLOYEE_RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_EMPLOYEE_RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_EMPLOYEE_RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_EMPLOYEE_RESIGNATION_REASON_ORDER_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_EMPLOYEE_RESIGNATION_REASON_ORDER_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_EMPLOYEE_RESIGNATION_REASON_ORDER_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_EMPLOYEE_RESIGNATION_REASON_ORDER_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_IS_FOR_CONTRACT_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_IS_FOR_CONTRACT_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_IS_FOR_CONTRACT_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_IS_FOR_CONTRACT_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_IS_FOR_TRANSFER_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_IS_FOR_TRANSFER_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_IS_FOR_TRANSFER_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_IS_FOR_TRANSFER_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_REMARK_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_REMARK_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_REMARK_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_REMARK_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_DISPLAY_NAME_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_DISPLAY_NAME_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_DISPLAY_NAME_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_DISPLAY_NAME_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_EMPLOYEE_RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_EMPLOYEE_RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_EMPLOYEE_RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_EMPLOYEE_RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_EMPLOYEE_RESIGNATION_REASON_ORDER_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_EMPLOYEE_RESIGNATION_REASON_ORDER_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_EMPLOYEE_RESIGNATION_REASON_ORDER_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_EMPLOYEE_RESIGNATION_REASON_ORDER_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_IS_FOR_CONTRACT_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_IS_FOR_CONTRACT_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_IS_FOR_CONTRACT_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_IS_FOR_CONTRACT_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_IS_FOR_TRANSFER_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_IS_FOR_TRANSFER_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_IS_FOR_TRANSFER_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_IS_FOR_TRANSFER_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_REMARK_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_REMARK_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_REMARK_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_REMARK_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__COUNT_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__COUNT_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__COUNT_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__COUNT_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_DISPLAY_NAME_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_DISPLAY_NAME_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_DISPLAY_NAME_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_DISPLAY_NAME_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_EMPLOYEE_RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_EMPLOYEE_RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_EMPLOYEE_RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_EMPLOYEE_RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_EMPLOYEE_RESIGNATION_REASON_ORDER_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_EMPLOYEE_RESIGNATION_REASON_ORDER_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_EMPLOYEE_RESIGNATION_REASON_ORDER_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_EMPLOYEE_RESIGNATION_REASON_ORDER_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_IS_FOR_CONTRACT_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_IS_FOR_CONTRACT_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_IS_FOR_CONTRACT_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_IS_FOR_CONTRACT_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_IS_FOR_TRANSFER_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_IS_FOR_TRANSFER_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_IS_FOR_TRANSFER_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_IS_FOR_TRANSFER_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_REMARK_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_REMARK_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_REMARK_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_REMARK_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_DISPLAY_NAME_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_DISPLAY_NAME_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_DISPLAY_NAME_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_DISPLAY_NAME_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_EMPLOYEE_RESIGNATION_REASON_ID_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_EMPLOYEE_RESIGNATION_REASON_ID_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_EMPLOYEE_RESIGNATION_REASON_ID_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_EMPLOYEE_RESIGNATION_REASON_ID_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_EMPLOYEE_RESIGNATION_REASON_ORDER_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_EMPLOYEE_RESIGNATION_REASON_ORDER_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_EMPLOYEE_RESIGNATION_REASON_ORDER_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_EMPLOYEE_RESIGNATION_REASON_ORDER_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_IS_FOR_CONTRACT_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_IS_FOR_CONTRACT_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_IS_FOR_CONTRACT_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_IS_FOR_CONTRACT_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_IS_FOR_TRANSFER_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_IS_FOR_TRANSFER_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_IS_FOR_TRANSFER_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_IS_FOR_TRANSFER_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_REMARK_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_REMARK_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_REMARK_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_REMARK_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'EMPLOYEE_RESIGNATION_REASONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__COUNT_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__COUNT_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__COUNT_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__COUNT_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_DISPLAY_NAME_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_DISPLAY_NAME_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_DISPLAY_NAME_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_DISPLAY_NAME_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_EMPLOYEE_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_EMPLOYEE_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_EMPLOYEE_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_EMPLOYEE_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_EMPLOYEE_RESIGNATION_TYPE_ORDER_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_EMPLOYEE_RESIGNATION_TYPE_ORDER_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_EMPLOYEE_RESIGNATION_TYPE_ORDER_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_EMPLOYEE_RESIGNATION_TYPE_ORDER_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_IS_FOR_CONTRACT_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_IS_FOR_CONTRACT_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_IS_FOR_CONTRACT_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_IS_FOR_CONTRACT_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_IS_FOR_TRANSFER_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_IS_FOR_TRANSFER_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_IS_FOR_TRANSFER_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_IS_FOR_TRANSFER_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_REMARK_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_REMARK_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_REMARK_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_REMARK_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_DISPLAY_NAME_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_DISPLAY_NAME_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_DISPLAY_NAME_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_DISPLAY_NAME_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_EMPLOYEE_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_EMPLOYEE_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_EMPLOYEE_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_EMPLOYEE_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_EMPLOYEE_RESIGNATION_TYPE_ORDER_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_EMPLOYEE_RESIGNATION_TYPE_ORDER_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_EMPLOYEE_RESIGNATION_TYPE_ORDER_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_EMPLOYEE_RESIGNATION_TYPE_ORDER_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_IS_FOR_CONTRACT_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_IS_FOR_CONTRACT_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_IS_FOR_CONTRACT_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_IS_FOR_CONTRACT_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_IS_FOR_TRANSFER_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_IS_FOR_TRANSFER_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_IS_FOR_TRANSFER_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_IS_FOR_TRANSFER_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_REMARK_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_REMARK_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_REMARK_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_REMARK_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__COUNT_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__COUNT_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__COUNT_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__COUNT_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_DISPLAY_NAME_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_DISPLAY_NAME_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_DISPLAY_NAME_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_DISPLAY_NAME_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_EMPLOYEE_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_EMPLOYEE_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_EMPLOYEE_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_EMPLOYEE_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_EMPLOYEE_RESIGNATION_TYPE_ORDER_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_EMPLOYEE_RESIGNATION_TYPE_ORDER_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_EMPLOYEE_RESIGNATION_TYPE_ORDER_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_EMPLOYEE_RESIGNATION_TYPE_ORDER_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_IS_FOR_CONTRACT_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_IS_FOR_CONTRACT_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_IS_FOR_CONTRACT_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_IS_FOR_CONTRACT_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_IS_FOR_TRANSFER_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_IS_FOR_TRANSFER_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_IS_FOR_TRANSFER_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_IS_FOR_TRANSFER_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_REMARK_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_REMARK_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_REMARK_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_REMARK_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_DISPLAY_NAME_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_DISPLAY_NAME_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_DISPLAY_NAME_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_DISPLAY_NAME_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_EMPLOYEE_RESIGNATION_TYPE_ID_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_EMPLOYEE_RESIGNATION_TYPE_ID_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_EMPLOYEE_RESIGNATION_TYPE_ID_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_EMPLOYEE_RESIGNATION_TYPE_ID_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_EMPLOYEE_RESIGNATION_TYPE_ORDER_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_EMPLOYEE_RESIGNATION_TYPE_ORDER_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_EMPLOYEE_RESIGNATION_TYPE_ORDER_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_EMPLOYEE_RESIGNATION_TYPE_ORDER_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_IS_FOR_CONTRACT_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_IS_FOR_CONTRACT_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_IS_FOR_CONTRACT_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_IS_FOR_CONTRACT_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_IS_FOR_TRANSFER_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_IS_FOR_TRANSFER_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_IS_FOR_TRANSFER_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_IS_FOR_TRANSFER_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_REMARK_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_REMARK_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_REMARK_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_REMARK_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'EMPLOYEE_RESIGNATION_TYPES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__COUNT_ASC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__COUNT_ASC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__COUNT_DESC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__COUNT_DESC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DISPLAY_NAME_ASC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DISPLAY_NAME_ASC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DISPLAY_NAME_DESC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DISPLAY_NAME_DESC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_EMPLOYEE_STATUS_KEY_ASC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_EMPLOYEE_STATUS_KEY_ASC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_EMPLOYEE_STATUS_KEY_DESC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_EMPLOYEE_STATUS_KEY_DESC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_EMPLOYEE_STATUS_ORDER_ASC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_EMPLOYEE_STATUS_ORDER_ASC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_EMPLOYEE_STATUS_ORDER_DESC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_EMPLOYEE_STATUS_ORDER_DESC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_REMARK_ASC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_REMARK_ASC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_REMARK_DESC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_REMARK_DESC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_SHORT_NAME_ASC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_SHORT_NAME_ASC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_SHORT_NAME_DESC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_SHORT_NAME_DESC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DISPLAY_NAME_ASC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DISPLAY_NAME_ASC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DISPLAY_NAME_DESC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DISPLAY_NAME_DESC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_EMPLOYEE_STATUS_KEY_ASC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_EMPLOYEE_STATUS_KEY_ASC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_EMPLOYEE_STATUS_KEY_DESC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_EMPLOYEE_STATUS_KEY_DESC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_EMPLOYEE_STATUS_ORDER_ASC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_EMPLOYEE_STATUS_ORDER_ASC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_EMPLOYEE_STATUS_ORDER_DESC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_EMPLOYEE_STATUS_ORDER_DESC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_REMARK_ASC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_REMARK_ASC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_REMARK_DESC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_REMARK_DESC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_SHORT_NAME_ASC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_SHORT_NAME_ASC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_SHORT_NAME_DESC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_SHORT_NAME_DESC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__COUNT_ASC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__COUNT_ASC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__COUNT_DESC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__COUNT_DESC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DISPLAY_NAME_ASC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DISPLAY_NAME_ASC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DISPLAY_NAME_DESC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DISPLAY_NAME_DESC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_EMPLOYEE_STATUS_KEY_ASC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_EMPLOYEE_STATUS_KEY_ASC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_EMPLOYEE_STATUS_KEY_DESC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_EMPLOYEE_STATUS_KEY_DESC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_EMPLOYEE_STATUS_ORDER_ASC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_EMPLOYEE_STATUS_ORDER_ASC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_EMPLOYEE_STATUS_ORDER_DESC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_EMPLOYEE_STATUS_ORDER_DESC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_REMARK_ASC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_REMARK_ASC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_REMARK_DESC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_REMARK_DESC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_SHORT_NAME_ASC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_SHORT_NAME_ASC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_SHORT_NAME_DESC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_SHORT_NAME_DESC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DISPLAY_NAME_ASC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DISPLAY_NAME_ASC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DISPLAY_NAME_DESC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DISPLAY_NAME_DESC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_EMPLOYEE_STATUS_KEY_ASC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_EMPLOYEE_STATUS_KEY_ASC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_EMPLOYEE_STATUS_KEY_DESC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_EMPLOYEE_STATUS_KEY_DESC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_EMPLOYEE_STATUS_ORDER_ASC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_EMPLOYEE_STATUS_ORDER_ASC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_EMPLOYEE_STATUS_ORDER_DESC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_EMPLOYEE_STATUS_ORDER_DESC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_REMARK_ASC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_REMARK_ASC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_REMARK_DESC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_REMARK_DESC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_SHORT_NAME_ASC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_SHORT_NAME_ASC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_SHORT_NAME_DESC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_SHORT_NAME_DESC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__COUNT_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__COUNT_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__COUNT_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__COUNT_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_COMPANY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_COMPANY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_COMPANY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_COMPANY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_DEPARTMENT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_DEPARTMENT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_DEPARTMENT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_DEPARTMENT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_DIVISION_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_DIVISION_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_DIVISION_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_DIVISION_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_EFFECTIVE_DATE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_EFFECTIVE_DATE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_EFFECTIVE_DATE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_EFFECTIVE_DATE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_JOB_TITLE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_JOB_TITLE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_JOB_TITLE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_JOB_TITLE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_OFFICE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_OFFICE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_OFFICE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_OFFICE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_REMARK_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_REMARK_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_REMARK_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_REMARK_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_TEAM_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_TEAM_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_TEAM_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_COMPANY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_COMPANY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_COMPANY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_COMPANY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_DEPARTMENT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_DEPARTMENT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_DEPARTMENT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_DEPARTMENT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_DIVISION_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_DIVISION_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_DIVISION_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_DIVISION_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_EFFECTIVE_DATE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_EFFECTIVE_DATE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_EFFECTIVE_DATE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_EFFECTIVE_DATE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_JOB_TITLE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_JOB_TITLE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_JOB_TITLE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_JOB_TITLE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_OFFICE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_OFFICE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_OFFICE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_OFFICE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_REMARK_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_REMARK_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_REMARK_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_REMARK_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_TEAM_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_TEAM_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_TEAM_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__COUNT_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__COUNT_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__COUNT_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__COUNT_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_COMPANY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_COMPANY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_COMPANY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_COMPANY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_DEPARTMENT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_DEPARTMENT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_DEPARTMENT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_DEPARTMENT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_DIVISION_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_DIVISION_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_DIVISION_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_DIVISION_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_EFFECTIVE_DATE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_EFFECTIVE_DATE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_EFFECTIVE_DATE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_EFFECTIVE_DATE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_JOB_TITLE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_JOB_TITLE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_JOB_TITLE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_JOB_TITLE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_OFFICE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_OFFICE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_OFFICE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_OFFICE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_REMARK_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_REMARK_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_REMARK_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_REMARK_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_TEAM_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_TEAM_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_TEAM_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_BUSINESS_UNIT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_BUSINESS_UNIT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_BUSINESS_UNIT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_BUSINESS_UNIT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_COMPANY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_COMPANY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_COMPANY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_COMPANY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_CONTRACT_TYPE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_CONTRACT_TYPE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_CONTRACT_TYPE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_CONTRACT_TYPE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_DEPARTMENT_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_DEPARTMENT_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_DEPARTMENT_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_DEPARTMENT_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_DIVISION_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_DIVISION_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_DIVISION_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_DIVISION_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_EFFECTIVE_DATE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_EFFECTIVE_DATE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_EFFECTIVE_DATE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_EFFECTIVE_DATE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_EFFECTIVE_DATE_UNTIL_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_EFFECTIVE_DATE_UNTIL_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_EFFECTIVE_DATE_UNTIL_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_EFFECTIVE_DATE_UNTIL_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_EMPLOYEE_CODE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_EMPLOYEE_CODE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_EMPLOYEE_CODE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_EMPLOYEE_CODE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_EMPLOYEE_LEVEL_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_EMPLOYEE_LEVEL_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_EMPLOYEE_LEVEL_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_EMPLOYEE_LEVEL_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_JOB_TITLE_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_JOB_TITLE_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_JOB_TITLE_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_JOB_TITLE_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_OFFICE_ID_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_OFFICE_ID_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_OFFICE_ID_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_OFFICE_ID_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_REMARK_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_REMARK_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_REMARK_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_REMARK_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_TEAM_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_TEAM_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_TEAM_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_TEAM_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'EMPLOYEE_TP_HISTORIES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__COUNT_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__COUNT_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__COUNT_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__COUNT_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_EXPORT_EMPLOYEE_STATUS_ID_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_EXPORT_EMPLOYEE_STATUS_ID_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_EXPORT_EMPLOYEE_STATUS_ID_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_EXPORT_EMPLOYEE_STATUS_ID_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_FILE_MODE_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_FILE_MODE_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_FILE_MODE_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_FILE_MODE_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_IN_PROGRESS_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_IN_PROGRESS_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_IN_PROGRESS_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_IN_PROGRESS_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_REQUEST_BODY_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_REQUEST_BODY_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_REQUEST_BODY_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_REQUEST_BODY_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_DOWNLOAD_URL_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_DOWNLOAD_URL_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_DOWNLOAD_URL_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_DOWNLOAD_URL_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_DOWNLOAD_URL_UNTIL_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_DOWNLOAD_URL_UNTIL_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_DOWNLOAD_URL_UNTIL_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_DOWNLOAD_URL_UNTIL_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_ERROR_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_ERROR_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_ERROR_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_ERROR_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_FILE_PASSWORD_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_FILE_PASSWORD_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_FILE_PASSWORD_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_FILE_PASSWORD_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_SUCCESS_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_SUCCESS_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_SUCCESS_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_SUCCESS_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_UUID4_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_UUID4_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_UUID4_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_UUID4_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_EXPORT_EMPLOYEE_STATUS_ID_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_EXPORT_EMPLOYEE_STATUS_ID_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_EXPORT_EMPLOYEE_STATUS_ID_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_EXPORT_EMPLOYEE_STATUS_ID_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_FILE_MODE_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_FILE_MODE_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_FILE_MODE_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_FILE_MODE_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_IN_PROGRESS_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_IN_PROGRESS_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_IN_PROGRESS_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_IN_PROGRESS_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_REQUEST_BODY_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_REQUEST_BODY_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_REQUEST_BODY_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_REQUEST_BODY_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_DOWNLOAD_URL_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_DOWNLOAD_URL_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_DOWNLOAD_URL_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_DOWNLOAD_URL_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_DOWNLOAD_URL_UNTIL_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_DOWNLOAD_URL_UNTIL_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_DOWNLOAD_URL_UNTIL_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_DOWNLOAD_URL_UNTIL_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_ERROR_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_ERROR_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_ERROR_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_ERROR_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_FILE_PASSWORD_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_FILE_PASSWORD_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_FILE_PASSWORD_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_FILE_PASSWORD_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_SUCCESS_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_SUCCESS_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_SUCCESS_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_SUCCESS_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_UUID4_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_UUID4_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_UUID4_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_UUID4_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__COUNT_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__COUNT_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__COUNT_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__COUNT_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_EXPORT_EMPLOYEE_STATUS_ID_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_EXPORT_EMPLOYEE_STATUS_ID_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_EXPORT_EMPLOYEE_STATUS_ID_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_EXPORT_EMPLOYEE_STATUS_ID_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_FILE_MODE_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_FILE_MODE_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_FILE_MODE_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_FILE_MODE_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_IN_PROGRESS_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_IN_PROGRESS_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_IN_PROGRESS_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_IN_PROGRESS_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_REQUEST_BODY_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_REQUEST_BODY_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_REQUEST_BODY_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_REQUEST_BODY_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_DOWNLOAD_URL_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_DOWNLOAD_URL_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_DOWNLOAD_URL_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_DOWNLOAD_URL_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_DOWNLOAD_URL_UNTIL_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_DOWNLOAD_URL_UNTIL_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_DOWNLOAD_URL_UNTIL_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_DOWNLOAD_URL_UNTIL_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_ERROR_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_ERROR_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_ERROR_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_ERROR_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_FILE_PASSWORD_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_FILE_PASSWORD_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_FILE_PASSWORD_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_FILE_PASSWORD_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_SUCCESS_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_SUCCESS_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_SUCCESS_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_SUCCESS_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_UUID4_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_UUID4_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_UUID4_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_UUID4_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_EXPORT_EMPLOYEE_STATUS_ID_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_EXPORT_EMPLOYEE_STATUS_ID_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_EXPORT_EMPLOYEE_STATUS_ID_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_EXPORT_EMPLOYEE_STATUS_ID_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_FILE_MODE_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_FILE_MODE_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_FILE_MODE_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_FILE_MODE_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_IN_PROGRESS_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_IN_PROGRESS_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_IN_PROGRESS_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_IN_PROGRESS_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_REQUEST_BODY_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_REQUEST_BODY_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_REQUEST_BODY_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_REQUEST_BODY_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_DOWNLOAD_URL_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_DOWNLOAD_URL_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_DOWNLOAD_URL_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_DOWNLOAD_URL_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_DOWNLOAD_URL_UNTIL_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_DOWNLOAD_URL_UNTIL_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_DOWNLOAD_URL_UNTIL_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_DOWNLOAD_URL_UNTIL_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_ERROR_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_ERROR_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_ERROR_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_ERROR_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_FILE_PASSWORD_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_FILE_PASSWORD_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_FILE_PASSWORD_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_FILE_PASSWORD_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_SUCCESS_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_SUCCESS_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_SUCCESS_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_SUCCESS_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_UUID4_ASC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_UUID4_ASC',
  EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_UUID4_DESC = 'EXPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_UUID4_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__COUNT_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__COUNT_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__COUNT_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__COUNT_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_EXTERNAL_ACCOUNT_EMPLOYEE_ID_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_EXTERNAL_ACCOUNT_EMPLOYEE_ID_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_EXTERNAL_ACCOUNT_EMPLOYEE_ID_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_EXTERNAL_ACCOUNT_EMPLOYEE_ID_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_EXTERNAL_ACCOUNT_KEY_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_EXTERNAL_ACCOUNT_KEY_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_EXTERNAL_ACCOUNT_KEY_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_EXTERNAL_ACCOUNT_KEY_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_EXTERNAL_ACCOUNT_STATUS_CACHED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_EXTERNAL_ACCOUNT_STATUS_CACHED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_EXTERNAL_ACCOUNT_STATUS_CACHED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_EXTERNAL_ACCOUNT_STATUS_CACHED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_REMARK_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_REMARK_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_REMARK_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_REMARK_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_EXTERNAL_ACCOUNT_EMPLOYEE_ID_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_EXTERNAL_ACCOUNT_EMPLOYEE_ID_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_EXTERNAL_ACCOUNT_EMPLOYEE_ID_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_EXTERNAL_ACCOUNT_EMPLOYEE_ID_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_EXTERNAL_ACCOUNT_KEY_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_EXTERNAL_ACCOUNT_KEY_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_EXTERNAL_ACCOUNT_KEY_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_EXTERNAL_ACCOUNT_KEY_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_EXTERNAL_ACCOUNT_STATUS_CACHED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_EXTERNAL_ACCOUNT_STATUS_CACHED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_EXTERNAL_ACCOUNT_STATUS_CACHED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_EXTERNAL_ACCOUNT_STATUS_CACHED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_REMARK_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_REMARK_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_REMARK_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_REMARK_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__COUNT_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__COUNT_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__COUNT_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__COUNT_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_EXTERNAL_ACCOUNT_EMPLOYEE_ID_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_EXTERNAL_ACCOUNT_EMPLOYEE_ID_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_EXTERNAL_ACCOUNT_EMPLOYEE_ID_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_EXTERNAL_ACCOUNT_EMPLOYEE_ID_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_EXTERNAL_ACCOUNT_KEY_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_EXTERNAL_ACCOUNT_KEY_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_EXTERNAL_ACCOUNT_KEY_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_EXTERNAL_ACCOUNT_KEY_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_EXTERNAL_ACCOUNT_STATUS_CACHED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_EXTERNAL_ACCOUNT_STATUS_CACHED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_EXTERNAL_ACCOUNT_STATUS_CACHED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_EXTERNAL_ACCOUNT_STATUS_CACHED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_EXTERNAL_ACCOUNT_TYPE_KEY_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_REMARK_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_REMARK_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_REMARK_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_REMARK_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_EXTERNAL_ACCOUNT_EMPLOYEE_ID_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_EXTERNAL_ACCOUNT_EMPLOYEE_ID_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_EXTERNAL_ACCOUNT_EMPLOYEE_ID_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_EXTERNAL_ACCOUNT_EMPLOYEE_ID_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_EXTERNAL_ACCOUNT_KEY_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_EXTERNAL_ACCOUNT_KEY_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_EXTERNAL_ACCOUNT_KEY_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_EXTERNAL_ACCOUNT_KEY_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_EXTERNAL_ACCOUNT_STATUS_CACHED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_EXTERNAL_ACCOUNT_STATUS_CACHED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_EXTERNAL_ACCOUNT_STATUS_CACHED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_EXTERNAL_ACCOUNT_STATUS_CACHED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_EXTERNAL_ACCOUNT_TYPE_KEY_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_REMARK_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_REMARK_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_REMARK_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_REMARK_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'EXTERNAL_ACCOUNT_EMPLOYEES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  GOOGLE_ID_ASC = 'GOOGLE_ID_ASC',
  GOOGLE_ID_DESC = 'GOOGLE_ID_DESC',
  GOOGLE_PROFILE_PICTURE_URL_ASC = 'GOOGLE_PROFILE_PICTURE_URL_ASC',
  GOOGLE_PROFILE_PICTURE_URL_DESC = 'GOOGLE_PROFILE_PICTURE_URL_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__COUNT_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__COUNT_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__COUNT_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__COUNT_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_FILE_MODE_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_FILE_MODE_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_FILE_MODE_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_FILE_MODE_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_GOOGLE_AUTH_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_GOOGLE_AUTH_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_GOOGLE_AUTH_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_GOOGLE_AUTH_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_IMPORT_EMPLOYEE_STATUS_ID_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_IMPORT_EMPLOYEE_STATUS_ID_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_IMPORT_EMPLOYEE_STATUS_ID_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_IMPORT_EMPLOYEE_STATUS_ID_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_IN_PROGRESS_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_IN_PROGRESS_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_IN_PROGRESS_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_IN_PROGRESS_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_PROGRESS_LOOP_COUNT_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_PROGRESS_LOOP_COUNT_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_PROGRESS_LOOP_COUNT_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_PROGRESS_LOOP_COUNT_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_PROGRESS_PROCESSED_COUNT_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_PROGRESS_PROCESSED_COUNT_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_PROGRESS_PROCESSED_COUNT_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_PROGRESS_PROCESSED_COUNT_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_PROGRESS_PROCESSED_COUNT_OF_LOOP_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_PROGRESS_PROCESSED_COUNT_OF_LOOP_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_PROGRESS_PROCESSED_COUNT_OF_LOOP_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_PROGRESS_PROCESSED_COUNT_OF_LOOP_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_PROGRESS_SHOULD_STOP_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_PROGRESS_SHOULD_STOP_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_PROGRESS_SHOULD_STOP_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_PROGRESS_SHOULD_STOP_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_PROGRESS_TOTAL_COUNT_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_PROGRESS_TOTAL_COUNT_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_PROGRESS_TOTAL_COUNT_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_PROGRESS_TOTAL_COUNT_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_PROGRESS_TOTAL_COUNT_OF_LOOP_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_PROGRESS_TOTAL_COUNT_OF_LOOP_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_PROGRESS_TOTAL_COUNT_OF_LOOP_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_PROGRESS_TOTAL_COUNT_OF_LOOP_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_REQUEST_CREATE_UNSATISFIED_MASTERS_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_REQUEST_CREATE_UNSATISFIED_MASTERS_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_REQUEST_CREATE_UNSATISFIED_MASTERS_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_REQUEST_CREATE_UNSATISFIED_MASTERS_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_REQUEST_OVERWRITE_EXISTING_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_REQUEST_OVERWRITE_EXISTING_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_REQUEST_OVERWRITE_EXISTING_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_REQUEST_OVERWRITE_EXISTING_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_REQUEST_URL_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_REQUEST_URL_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_REQUEST_URL_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_REQUEST_URL_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_ERROR_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_ERROR_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_ERROR_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_ERROR_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_SUCCESS_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_SUCCESS_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_SUCCESS_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_RESPONSE_SUCCESS_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_UUID4_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_UUID4_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_UUID4_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MAX_UUID4_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_FILE_MODE_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_FILE_MODE_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_FILE_MODE_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_FILE_MODE_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_GOOGLE_AUTH_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_GOOGLE_AUTH_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_GOOGLE_AUTH_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_GOOGLE_AUTH_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_IMPORT_EMPLOYEE_STATUS_ID_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_IMPORT_EMPLOYEE_STATUS_ID_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_IMPORT_EMPLOYEE_STATUS_ID_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_IMPORT_EMPLOYEE_STATUS_ID_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_IN_PROGRESS_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_IN_PROGRESS_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_IN_PROGRESS_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_IN_PROGRESS_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_PROGRESS_LOOP_COUNT_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_PROGRESS_LOOP_COUNT_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_PROGRESS_LOOP_COUNT_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_PROGRESS_LOOP_COUNT_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_PROGRESS_PROCESSED_COUNT_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_PROGRESS_PROCESSED_COUNT_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_PROGRESS_PROCESSED_COUNT_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_PROGRESS_PROCESSED_COUNT_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_PROGRESS_PROCESSED_COUNT_OF_LOOP_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_PROGRESS_PROCESSED_COUNT_OF_LOOP_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_PROGRESS_PROCESSED_COUNT_OF_LOOP_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_PROGRESS_PROCESSED_COUNT_OF_LOOP_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_PROGRESS_SHOULD_STOP_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_PROGRESS_SHOULD_STOP_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_PROGRESS_SHOULD_STOP_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_PROGRESS_SHOULD_STOP_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_PROGRESS_TOTAL_COUNT_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_PROGRESS_TOTAL_COUNT_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_PROGRESS_TOTAL_COUNT_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_PROGRESS_TOTAL_COUNT_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_PROGRESS_TOTAL_COUNT_OF_LOOP_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_PROGRESS_TOTAL_COUNT_OF_LOOP_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_PROGRESS_TOTAL_COUNT_OF_LOOP_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_PROGRESS_TOTAL_COUNT_OF_LOOP_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_REQUEST_CREATE_UNSATISFIED_MASTERS_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_REQUEST_CREATE_UNSATISFIED_MASTERS_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_REQUEST_CREATE_UNSATISFIED_MASTERS_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_REQUEST_CREATE_UNSATISFIED_MASTERS_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_REQUEST_OVERWRITE_EXISTING_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_REQUEST_OVERWRITE_EXISTING_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_REQUEST_OVERWRITE_EXISTING_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_REQUEST_OVERWRITE_EXISTING_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_REQUEST_URL_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_REQUEST_URL_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_REQUEST_URL_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_REQUEST_URL_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_ERROR_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_ERROR_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_ERROR_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_ERROR_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_SUCCESS_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_SUCCESS_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_SUCCESS_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_RESPONSE_SUCCESS_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_UUID4_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_UUID4_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_UUID4_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_CREATED__MIN_UUID4_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__COUNT_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__COUNT_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__COUNT_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__COUNT_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_FILE_MODE_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_FILE_MODE_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_FILE_MODE_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_FILE_MODE_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_GOOGLE_AUTH_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_GOOGLE_AUTH_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_GOOGLE_AUTH_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_GOOGLE_AUTH_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_IMPORT_EMPLOYEE_STATUS_ID_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_IMPORT_EMPLOYEE_STATUS_ID_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_IMPORT_EMPLOYEE_STATUS_ID_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_IMPORT_EMPLOYEE_STATUS_ID_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_IN_PROGRESS_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_IN_PROGRESS_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_IN_PROGRESS_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_IN_PROGRESS_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_PROGRESS_LOOP_COUNT_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_PROGRESS_LOOP_COUNT_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_PROGRESS_LOOP_COUNT_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_PROGRESS_LOOP_COUNT_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_PROGRESS_PROCESSED_COUNT_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_PROGRESS_PROCESSED_COUNT_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_PROGRESS_PROCESSED_COUNT_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_PROGRESS_PROCESSED_COUNT_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_PROGRESS_PROCESSED_COUNT_OF_LOOP_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_PROGRESS_PROCESSED_COUNT_OF_LOOP_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_PROGRESS_PROCESSED_COUNT_OF_LOOP_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_PROGRESS_PROCESSED_COUNT_OF_LOOP_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_PROGRESS_SHOULD_STOP_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_PROGRESS_SHOULD_STOP_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_PROGRESS_SHOULD_STOP_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_PROGRESS_SHOULD_STOP_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_PROGRESS_TOTAL_COUNT_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_PROGRESS_TOTAL_COUNT_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_PROGRESS_TOTAL_COUNT_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_PROGRESS_TOTAL_COUNT_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_PROGRESS_TOTAL_COUNT_OF_LOOP_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_PROGRESS_TOTAL_COUNT_OF_LOOP_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_PROGRESS_TOTAL_COUNT_OF_LOOP_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_PROGRESS_TOTAL_COUNT_OF_LOOP_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_REQUEST_CREATE_UNSATISFIED_MASTERS_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_REQUEST_CREATE_UNSATISFIED_MASTERS_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_REQUEST_CREATE_UNSATISFIED_MASTERS_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_REQUEST_CREATE_UNSATISFIED_MASTERS_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_REQUEST_OVERWRITE_EXISTING_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_REQUEST_OVERWRITE_EXISTING_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_REQUEST_OVERWRITE_EXISTING_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_REQUEST_OVERWRITE_EXISTING_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_REQUEST_URL_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_REQUEST_URL_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_REQUEST_URL_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_REQUEST_URL_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_ERROR_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_ERROR_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_ERROR_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_ERROR_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_SUCCESS_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_SUCCESS_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_SUCCESS_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_RESPONSE_SUCCESS_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_UUID4_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_UUID4_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_UUID4_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MAX_UUID4_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_FILE_MODE_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_FILE_MODE_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_FILE_MODE_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_FILE_MODE_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_GOOGLE_AUTH_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_GOOGLE_AUTH_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_GOOGLE_AUTH_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_GOOGLE_AUTH_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_IMPORT_EMPLOYEE_STATUS_ID_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_IMPORT_EMPLOYEE_STATUS_ID_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_IMPORT_EMPLOYEE_STATUS_ID_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_IMPORT_EMPLOYEE_STATUS_ID_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_IN_PROGRESS_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_IN_PROGRESS_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_IN_PROGRESS_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_IN_PROGRESS_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_PROGRESS_LOOP_COUNT_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_PROGRESS_LOOP_COUNT_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_PROGRESS_LOOP_COUNT_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_PROGRESS_LOOP_COUNT_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_PROGRESS_PROCESSED_COUNT_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_PROGRESS_PROCESSED_COUNT_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_PROGRESS_PROCESSED_COUNT_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_PROGRESS_PROCESSED_COUNT_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_PROGRESS_PROCESSED_COUNT_OF_LOOP_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_PROGRESS_PROCESSED_COUNT_OF_LOOP_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_PROGRESS_PROCESSED_COUNT_OF_LOOP_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_PROGRESS_PROCESSED_COUNT_OF_LOOP_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_PROGRESS_SHOULD_STOP_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_PROGRESS_SHOULD_STOP_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_PROGRESS_SHOULD_STOP_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_PROGRESS_SHOULD_STOP_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_PROGRESS_TOTAL_COUNT_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_PROGRESS_TOTAL_COUNT_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_PROGRESS_TOTAL_COUNT_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_PROGRESS_TOTAL_COUNT_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_PROGRESS_TOTAL_COUNT_OF_LOOP_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_PROGRESS_TOTAL_COUNT_OF_LOOP_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_PROGRESS_TOTAL_COUNT_OF_LOOP_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_PROGRESS_TOTAL_COUNT_OF_LOOP_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_REQUEST_CREATE_UNSATISFIED_MASTERS_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_REQUEST_CREATE_UNSATISFIED_MASTERS_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_REQUEST_CREATE_UNSATISFIED_MASTERS_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_REQUEST_CREATE_UNSATISFIED_MASTERS_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_REQUEST_OVERWRITE_EXISTING_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_REQUEST_OVERWRITE_EXISTING_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_REQUEST_OVERWRITE_EXISTING_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_REQUEST_OVERWRITE_EXISTING_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_REQUEST_URL_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_REQUEST_URL_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_REQUEST_URL_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_REQUEST_URL_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_ERROR_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_ERROR_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_ERROR_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_ERROR_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_SUCCESS_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_SUCCESS_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_SUCCESS_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_RESPONSE_SUCCESS_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_UUID4_ASC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_UUID4_ASC',
  IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_UUID4_DESC = 'IMPORT_EMPLOYEE_STATUSES_BY_USER_UPDATED__MIN_UUID4_DESC',
  IS_ENABLED_ASC = 'IS_ENABLED_ASC',
  IS_ENABLED_DESC = 'IS_ENABLED_DESC',
  NATURAL = 'NATURAL',
  OFFICES_BY_USER_CREATED__COUNT_ASC = 'OFFICES_BY_USER_CREATED__COUNT_ASC',
  OFFICES_BY_USER_CREATED__COUNT_DESC = 'OFFICES_BY_USER_CREATED__COUNT_DESC',
  OFFICES_BY_USER_CREATED__MAX_CITY_ID_ASC = 'OFFICES_BY_USER_CREATED__MAX_CITY_ID_ASC',
  OFFICES_BY_USER_CREATED__MAX_CITY_ID_DESC = 'OFFICES_BY_USER_CREATED__MAX_CITY_ID_DESC',
  OFFICES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'OFFICES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  OFFICES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'OFFICES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  OFFICES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'OFFICES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  OFFICES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'OFFICES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  OFFICES_BY_USER_CREATED__MAX_LATITUDE_ASC = 'OFFICES_BY_USER_CREATED__MAX_LATITUDE_ASC',
  OFFICES_BY_USER_CREATED__MAX_LATITUDE_DESC = 'OFFICES_BY_USER_CREATED__MAX_LATITUDE_DESC',
  OFFICES_BY_USER_CREATED__MAX_LONGITUDE_ASC = 'OFFICES_BY_USER_CREATED__MAX_LONGITUDE_ASC',
  OFFICES_BY_USER_CREATED__MAX_LONGITUDE_DESC = 'OFFICES_BY_USER_CREATED__MAX_LONGITUDE_DESC',
  OFFICES_BY_USER_CREATED__MAX_NAME_ASC = 'OFFICES_BY_USER_CREATED__MAX_NAME_ASC',
  OFFICES_BY_USER_CREATED__MAX_NAME_DESC = 'OFFICES_BY_USER_CREATED__MAX_NAME_DESC',
  OFFICES_BY_USER_CREATED__MAX_OFFICE_ID_ASC = 'OFFICES_BY_USER_CREATED__MAX_OFFICE_ID_ASC',
  OFFICES_BY_USER_CREATED__MAX_OFFICE_ID_DESC = 'OFFICES_BY_USER_CREATED__MAX_OFFICE_ID_DESC',
  OFFICES_BY_USER_CREATED__MAX_REMARK_ASC = 'OFFICES_BY_USER_CREATED__MAX_REMARK_ASC',
  OFFICES_BY_USER_CREATED__MAX_REMARK_DESC = 'OFFICES_BY_USER_CREATED__MAX_REMARK_DESC',
  OFFICES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'OFFICES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  OFFICES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'OFFICES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  OFFICES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'OFFICES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  OFFICES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'OFFICES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  OFFICES_BY_USER_CREATED__MIN_CITY_ID_ASC = 'OFFICES_BY_USER_CREATED__MIN_CITY_ID_ASC',
  OFFICES_BY_USER_CREATED__MIN_CITY_ID_DESC = 'OFFICES_BY_USER_CREATED__MIN_CITY_ID_DESC',
  OFFICES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'OFFICES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  OFFICES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'OFFICES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  OFFICES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'OFFICES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  OFFICES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'OFFICES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  OFFICES_BY_USER_CREATED__MIN_LATITUDE_ASC = 'OFFICES_BY_USER_CREATED__MIN_LATITUDE_ASC',
  OFFICES_BY_USER_CREATED__MIN_LATITUDE_DESC = 'OFFICES_BY_USER_CREATED__MIN_LATITUDE_DESC',
  OFFICES_BY_USER_CREATED__MIN_LONGITUDE_ASC = 'OFFICES_BY_USER_CREATED__MIN_LONGITUDE_ASC',
  OFFICES_BY_USER_CREATED__MIN_LONGITUDE_DESC = 'OFFICES_BY_USER_CREATED__MIN_LONGITUDE_DESC',
  OFFICES_BY_USER_CREATED__MIN_NAME_ASC = 'OFFICES_BY_USER_CREATED__MIN_NAME_ASC',
  OFFICES_BY_USER_CREATED__MIN_NAME_DESC = 'OFFICES_BY_USER_CREATED__MIN_NAME_DESC',
  OFFICES_BY_USER_CREATED__MIN_OFFICE_ID_ASC = 'OFFICES_BY_USER_CREATED__MIN_OFFICE_ID_ASC',
  OFFICES_BY_USER_CREATED__MIN_OFFICE_ID_DESC = 'OFFICES_BY_USER_CREATED__MIN_OFFICE_ID_DESC',
  OFFICES_BY_USER_CREATED__MIN_REMARK_ASC = 'OFFICES_BY_USER_CREATED__MIN_REMARK_ASC',
  OFFICES_BY_USER_CREATED__MIN_REMARK_DESC = 'OFFICES_BY_USER_CREATED__MIN_REMARK_DESC',
  OFFICES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'OFFICES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  OFFICES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'OFFICES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  OFFICES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'OFFICES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  OFFICES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'OFFICES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  OFFICES_BY_USER_UPDATED__COUNT_ASC = 'OFFICES_BY_USER_UPDATED__COUNT_ASC',
  OFFICES_BY_USER_UPDATED__COUNT_DESC = 'OFFICES_BY_USER_UPDATED__COUNT_DESC',
  OFFICES_BY_USER_UPDATED__MAX_CITY_ID_ASC = 'OFFICES_BY_USER_UPDATED__MAX_CITY_ID_ASC',
  OFFICES_BY_USER_UPDATED__MAX_CITY_ID_DESC = 'OFFICES_BY_USER_UPDATED__MAX_CITY_ID_DESC',
  OFFICES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'OFFICES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  OFFICES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'OFFICES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  OFFICES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'OFFICES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  OFFICES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'OFFICES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  OFFICES_BY_USER_UPDATED__MAX_LATITUDE_ASC = 'OFFICES_BY_USER_UPDATED__MAX_LATITUDE_ASC',
  OFFICES_BY_USER_UPDATED__MAX_LATITUDE_DESC = 'OFFICES_BY_USER_UPDATED__MAX_LATITUDE_DESC',
  OFFICES_BY_USER_UPDATED__MAX_LONGITUDE_ASC = 'OFFICES_BY_USER_UPDATED__MAX_LONGITUDE_ASC',
  OFFICES_BY_USER_UPDATED__MAX_LONGITUDE_DESC = 'OFFICES_BY_USER_UPDATED__MAX_LONGITUDE_DESC',
  OFFICES_BY_USER_UPDATED__MAX_NAME_ASC = 'OFFICES_BY_USER_UPDATED__MAX_NAME_ASC',
  OFFICES_BY_USER_UPDATED__MAX_NAME_DESC = 'OFFICES_BY_USER_UPDATED__MAX_NAME_DESC',
  OFFICES_BY_USER_UPDATED__MAX_OFFICE_ID_ASC = 'OFFICES_BY_USER_UPDATED__MAX_OFFICE_ID_ASC',
  OFFICES_BY_USER_UPDATED__MAX_OFFICE_ID_DESC = 'OFFICES_BY_USER_UPDATED__MAX_OFFICE_ID_DESC',
  OFFICES_BY_USER_UPDATED__MAX_REMARK_ASC = 'OFFICES_BY_USER_UPDATED__MAX_REMARK_ASC',
  OFFICES_BY_USER_UPDATED__MAX_REMARK_DESC = 'OFFICES_BY_USER_UPDATED__MAX_REMARK_DESC',
  OFFICES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'OFFICES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  OFFICES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'OFFICES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  OFFICES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'OFFICES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  OFFICES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'OFFICES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  OFFICES_BY_USER_UPDATED__MIN_CITY_ID_ASC = 'OFFICES_BY_USER_UPDATED__MIN_CITY_ID_ASC',
  OFFICES_BY_USER_UPDATED__MIN_CITY_ID_DESC = 'OFFICES_BY_USER_UPDATED__MIN_CITY_ID_DESC',
  OFFICES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'OFFICES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  OFFICES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'OFFICES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  OFFICES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'OFFICES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  OFFICES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'OFFICES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  OFFICES_BY_USER_UPDATED__MIN_LATITUDE_ASC = 'OFFICES_BY_USER_UPDATED__MIN_LATITUDE_ASC',
  OFFICES_BY_USER_UPDATED__MIN_LATITUDE_DESC = 'OFFICES_BY_USER_UPDATED__MIN_LATITUDE_DESC',
  OFFICES_BY_USER_UPDATED__MIN_LONGITUDE_ASC = 'OFFICES_BY_USER_UPDATED__MIN_LONGITUDE_ASC',
  OFFICES_BY_USER_UPDATED__MIN_LONGITUDE_DESC = 'OFFICES_BY_USER_UPDATED__MIN_LONGITUDE_DESC',
  OFFICES_BY_USER_UPDATED__MIN_NAME_ASC = 'OFFICES_BY_USER_UPDATED__MIN_NAME_ASC',
  OFFICES_BY_USER_UPDATED__MIN_NAME_DESC = 'OFFICES_BY_USER_UPDATED__MIN_NAME_DESC',
  OFFICES_BY_USER_UPDATED__MIN_OFFICE_ID_ASC = 'OFFICES_BY_USER_UPDATED__MIN_OFFICE_ID_ASC',
  OFFICES_BY_USER_UPDATED__MIN_OFFICE_ID_DESC = 'OFFICES_BY_USER_UPDATED__MIN_OFFICE_ID_DESC',
  OFFICES_BY_USER_UPDATED__MIN_REMARK_ASC = 'OFFICES_BY_USER_UPDATED__MIN_REMARK_ASC',
  OFFICES_BY_USER_UPDATED__MIN_REMARK_DESC = 'OFFICES_BY_USER_UPDATED__MIN_REMARK_DESC',
  OFFICES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'OFFICES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  OFFICES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'OFFICES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  OFFICES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'OFFICES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  OFFICES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'OFFICES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__COUNT_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__COUNT_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__COUNT_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__COUNT_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_CONDITION_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_CONDITION_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_CONDITION_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_CONDITION_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_DAY_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_DAY_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_DAY_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_DAY_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_HUMIDITY_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_HUMIDITY_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_HUMIDITY_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_HUMIDITY_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_ICON_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_ICON_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_ICON_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_ICON_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_OFFICE_ID_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_OFFICE_ID_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_OFFICE_ID_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_OFFICE_ID_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_REMARK_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_REMARK_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_REMARK_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_REMARK_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_TEMP_MAX_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_TEMP_MAX_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_TEMP_MAX_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_TEMP_MAX_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_TEMP_MIN_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_TEMP_MIN_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_TEMP_MIN_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_TEMP_MIN_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_TIME_SUNRISE_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_TIME_SUNRISE_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_TIME_SUNRISE_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_TIME_SUNRISE_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_TIME_SUNSET_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_TIME_SUNSET_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_TIME_SUNSET_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_TIME_SUNSET_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_CONDITION_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_CONDITION_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_CONDITION_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_CONDITION_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_DAY_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_DAY_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_DAY_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_DAY_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_HUMIDITY_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_HUMIDITY_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_HUMIDITY_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_HUMIDITY_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_ICON_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_ICON_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_ICON_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_ICON_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_OFFICE_ID_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_OFFICE_ID_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_OFFICE_ID_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_OFFICE_ID_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_REMARK_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_REMARK_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_REMARK_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_REMARK_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_TEMP_MAX_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_TEMP_MAX_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_TEMP_MAX_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_TEMP_MAX_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_TEMP_MIN_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_TEMP_MIN_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_TEMP_MIN_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_TEMP_MIN_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_TIME_SUNRISE_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_TIME_SUNRISE_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_TIME_SUNRISE_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_TIME_SUNRISE_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_TIME_SUNSET_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_TIME_SUNSET_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_TIME_SUNSET_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_TIME_SUNSET_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  OFFICE_WEATHERS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'OFFICE_WEATHERS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__COUNT_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__COUNT_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__COUNT_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__COUNT_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_CONDITION_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_CONDITION_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_CONDITION_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_CONDITION_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_DAY_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_DAY_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_DAY_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_DAY_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_HUMIDITY_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_HUMIDITY_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_HUMIDITY_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_HUMIDITY_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_ICON_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_ICON_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_ICON_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_ICON_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_OFFICE_ID_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_OFFICE_ID_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_OFFICE_ID_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_OFFICE_ID_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_REMARK_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_REMARK_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_REMARK_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_REMARK_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_TEMP_MAX_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_TEMP_MAX_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_TEMP_MAX_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_TEMP_MAX_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_TEMP_MIN_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_TEMP_MIN_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_TEMP_MIN_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_TEMP_MIN_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_TIME_SUNRISE_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_TIME_SUNRISE_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_TIME_SUNRISE_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_TIME_SUNRISE_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_TIME_SUNSET_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_TIME_SUNSET_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_TIME_SUNSET_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_TIME_SUNSET_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_CONDITION_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_CONDITION_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_CONDITION_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_CONDITION_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_DAY_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_DAY_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_DAY_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_DAY_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_HUMIDITY_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_HUMIDITY_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_HUMIDITY_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_HUMIDITY_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_ICON_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_ICON_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_ICON_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_ICON_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_OFFICE_ID_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_OFFICE_ID_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_OFFICE_ID_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_OFFICE_ID_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_REMARK_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_REMARK_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_REMARK_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_REMARK_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_TEMP_MAX_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_TEMP_MAX_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_TEMP_MAX_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_TEMP_MAX_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_TEMP_MIN_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_TEMP_MIN_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_TEMP_MIN_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_TEMP_MIN_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_TIME_SUNRISE_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_TIME_SUNRISE_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_TIME_SUNRISE_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_TIME_SUNRISE_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_TIME_SUNSET_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_TIME_SUNSET_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_TIME_SUNSET_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_TIME_SUNSET_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  OFFICE_WEATHERS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'OFFICE_WEATHERS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_IS_LOCKED_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_IS_LOCKED_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_IS_LOCKED_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_IS_LOCKED_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_IS_LOCKED_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_IS_LOCKED_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_IS_LOCKED_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_IS_LOCKED_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_MID_LONG_TERM_GOAL_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_MID_LONG_TERM_GOAL_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_MID_LONG_TERM_GOAL_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_MID_LONG_TERM_GOAL_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_NEED_INPUT_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_NEED_INPUT_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_NEED_INPUT_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_NEED_INPUT_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_NEED_INPUT_TARGET_KPI_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_NEED_INPUT_TARGET_KPI_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_NEED_INPUT_TARGET_KPI_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_NEED_INPUT_TARGET_KPI_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_NEED_INPUT_TARGET_QUALITATIVE_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_NEED_INPUT_TARGET_QUALITATIVE_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_NEED_INPUT_TARGET_QUALITATIVE_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_NEED_INPUT_TARGET_QUALITATIVE_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_IS_LOCKED_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_IS_LOCKED_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_IS_LOCKED_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_IS_LOCKED_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_IS_LOCKED_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_IS_LOCKED_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_IS_LOCKED_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_IS_LOCKED_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_MID_LONG_TERM_GOAL_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_MID_LONG_TERM_GOAL_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_MID_LONG_TERM_GOAL_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_MID_LONG_TERM_GOAL_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_NEED_INPUT_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_NEED_INPUT_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_NEED_INPUT_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_NEED_INPUT_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_NEED_INPUT_TARGET_KPI_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_NEED_INPUT_TARGET_KPI_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_NEED_INPUT_TARGET_KPI_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_NEED_INPUT_TARGET_KPI_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_NEED_INPUT_TARGET_QUALITATIVE_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_NEED_INPUT_TARGET_QUALITATIVE_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_NEED_INPUT_TARGET_QUALITATIVE_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_NEED_INPUT_TARGET_QUALITATIVE_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_EMPLOYEE_TP_HISTORY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_EMPLOYEE_TP_HISTORY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_EMPLOYEE_TP_HISTORY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_EMPLOYEE_TP_HISTORY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_IS_LOCKED_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_IS_LOCKED_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_IS_LOCKED_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_IS_LOCKED_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_IS_LOCKED_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_IS_LOCKED_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_IS_LOCKED_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_IS_LOCKED_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_MID_LONG_TERM_GOAL_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_MID_LONG_TERM_GOAL_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_MID_LONG_TERM_GOAL_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_MID_LONG_TERM_GOAL_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_NEED_INPUT_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_NEED_INPUT_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_NEED_INPUT_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_NEED_INPUT_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_NEED_INPUT_TARGET_KPI_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_NEED_INPUT_TARGET_KPI_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_NEED_INPUT_TARGET_KPI_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_NEED_INPUT_TARGET_KPI_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_NEED_INPUT_TARGET_QUALITATIVE_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_NEED_INPUT_TARGET_QUALITATIVE_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_NEED_INPUT_TARGET_QUALITATIVE_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_NEED_INPUT_TARGET_QUALITATIVE_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_EMPLOYEE_TP_HISTORY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_EMPLOYEE_TP_HISTORY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_EMPLOYEE_TP_HISTORY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_EMPLOYEE_TP_HISTORY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_IS_LOCKED_ASSESSMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_IS_LOCKED_ASSESSMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_IS_LOCKED_ASSESSMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_IS_LOCKED_ASSESSMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_IS_LOCKED_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_IS_LOCKED_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_IS_LOCKED_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_IS_LOCKED_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_MID_LONG_TERM_GOAL_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_MID_LONG_TERM_GOAL_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_MID_LONG_TERM_GOAL_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_MID_LONG_TERM_GOAL_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_NEED_INPUT_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_NEED_INPUT_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_NEED_INPUT_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_NEED_INPUT_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_NEED_INPUT_TARGET_KPI_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_NEED_INPUT_TARGET_KPI_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_NEED_INPUT_TARGET_KPI_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_NEED_INPUT_TARGET_KPI_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_NEED_INPUT_TARGET_QUALITATIVE_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_NEED_INPUT_TARGET_QUALITATIVE_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_NEED_INPUT_TARGET_QUALITATIVE_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_NEED_INPUT_TARGET_QUALITATIVE_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_COMPETENCIES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_EVALUATOR_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_EVALUATOR_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_EVALUATOR_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_EVALUATOR_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_EVALUATOR_TYPE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_EVALUATOR_TYPE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_EVALUATOR_TYPE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_EVALUATOR_TYPE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_EVALUATOR_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_EVALUATOR_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_EVALUATOR_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_EVALUATOR_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_EVALUATOR_TYPE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_EVALUATOR_TYPE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_EVALUATOR_TYPE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_EVALUATOR_TYPE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_EVALUATOR_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_EVALUATOR_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_EVALUATOR_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_EVALUATOR_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_EVALUATOR_TYPE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_EVALUATOR_TYPE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_EVALUATOR_TYPE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_EVALUATOR_TYPE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_EVALUATOR_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_EVALUATOR_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_EVALUATOR_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_EVALUATOR_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_EVALUATOR_TYPE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_EVALUATOR_TYPE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_EVALUATOR_TYPE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_EVALUATOR_TYPE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_EVALUATOR_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_EVALUATORS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_FEEDBACK_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_FEEDBACK_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_FEEDBACK_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_FEEDBACK_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_FEEDBACK_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_FEEDBACK_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_FEEDBACK_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_FEEDBACK_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_FEEDBACK_EMPLOYEE_NAME_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_FEEDBACK_EMPLOYEE_NAME_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_FEEDBACK_EMPLOYEE_NAME_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_FEEDBACK_EMPLOYEE_NAME_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_FEEDBACK_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_FEEDBACK_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_FEEDBACK_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_FEEDBACK_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_FEEDBACK_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_FEEDBACK_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_FEEDBACK_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_FEEDBACK_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_FEEDBACK_EMPLOYEE_NAME_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_FEEDBACK_EMPLOYEE_NAME_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_FEEDBACK_EMPLOYEE_NAME_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_FEEDBACK_EMPLOYEE_NAME_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_FEEDBACK_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_FEEDBACK_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_FEEDBACK_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_FEEDBACK_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_FEEDBACK_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_FEEDBACK_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_FEEDBACK_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_FEEDBACK_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_FEEDBACK_EMPLOYEE_NAME_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_FEEDBACK_EMPLOYEE_NAME_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_FEEDBACK_EMPLOYEE_NAME_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_FEEDBACK_EMPLOYEE_NAME_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_FEEDBACK_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_FEEDBACK_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_FEEDBACK_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_FEEDBACK_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_FEEDBACK_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_FEEDBACK_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_FEEDBACK_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_FEEDBACK_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_FEEDBACK_EMPLOYEE_NAME_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_FEEDBACK_EMPLOYEE_NAME_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_FEEDBACK_EMPLOYEE_NAME_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_FEEDBACK_EMPLOYEE_NAME_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_FEEDBACK_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_FEEDBACKS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_ASSESSMENTS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_INVERTED_ACHIEVEMENT_RATE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_INVERTED_ACHIEVEMENT_RATE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_INVERTED_ACHIEVEMENT_RATE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_INVERTED_ACHIEVEMENT_RATE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_TITLE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_TITLE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_TITLE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_TITLE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_WEIGHT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_WEIGHT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_WEIGHT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MAX_WEIGHT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_INVERTED_ACHIEVEMENT_RATE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_INVERTED_ACHIEVEMENT_RATE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_INVERTED_ACHIEVEMENT_RATE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_INVERTED_ACHIEVEMENT_RATE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_TITLE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_TITLE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_TITLE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_TITLE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_WEIGHT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_WEIGHT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_WEIGHT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_CREATED__MIN_WEIGHT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_INVERTED_ACHIEVEMENT_RATE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_INVERTED_ACHIEVEMENT_RATE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_INVERTED_ACHIEVEMENT_RATE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_INVERTED_ACHIEVEMENT_RATE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_TITLE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_TITLE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_TITLE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_TITLE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_WEIGHT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_WEIGHT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_WEIGHT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MAX_WEIGHT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_INVERTED_ACHIEVEMENT_RATE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_INVERTED_ACHIEVEMENT_RATE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_INVERTED_ACHIEVEMENT_RATE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_INVERTED_ACHIEVEMENT_RATE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_TITLE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_TITLE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_TITLE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_TITLE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_WEIGHT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_WEIGHT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_WEIGHT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTIONS_BY_USER_UPDATED__MIN_WEIGHT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_VALUE_UNIT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_VALUE_UNIT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_VALUE_UNIT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MAX_VALUE_UNIT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_VALUE_UNIT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_VALUE_UNIT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_VALUE_UNIT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_CREATED__MIN_VALUE_UNIT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_VALUE_UNIT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_VALUE_UNIT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_VALUE_UNIT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MAX_VALUE_UNIT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_RESULT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_VALUE_UNIT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_VALUE_UNIT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_VALUE_UNIT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_RESULTS_BY_USER_UPDATED__MIN_VALUE_UNIT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_INVERTED_ACHIEVEMENT_RATE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_INVERTED_ACHIEVEMENT_RATE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_INVERTED_ACHIEVEMENT_RATE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_INVERTED_ACHIEVEMENT_RATE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_NAME_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_NAME_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_NAME_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_NAME_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_VALUE_UNIT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_VALUE_UNIT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_VALUE_UNIT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MAX_VALUE_UNIT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_INVERTED_ACHIEVEMENT_RATE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_INVERTED_ACHIEVEMENT_RATE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_INVERTED_ACHIEVEMENT_RATE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_INVERTED_ACHIEVEMENT_RATE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_NAME_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_NAME_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_NAME_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_NAME_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_VALUE_UNIT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_VALUE_UNIT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_VALUE_UNIT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_CREATED__MIN_VALUE_UNIT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_INVERTED_ACHIEVEMENT_RATE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_INVERTED_ACHIEVEMENT_RATE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_INVERTED_ACHIEVEMENT_RATE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_INVERTED_ACHIEVEMENT_RATE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_NAME_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_NAME_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_NAME_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_NAME_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_VALUE_UNIT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_VALUE_UNIT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_VALUE_UNIT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MAX_VALUE_UNIT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_INVERTED_ACHIEVEMENT_RATE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_INVERTED_ACHIEVEMENT_RATE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_INVERTED_ACHIEVEMENT_RATE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_INVERTED_ACHIEVEMENT_RATE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_NAME_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_NAME_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_NAME_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_NAME_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_KPI_TARGET_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_VALUE_UNIT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_VALUE_UNIT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_VALUE_UNIT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_KPI_TARGETS_BY_USER_UPDATED__MIN_VALUE_UNIT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_MONTH_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_MONTH_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_MONTH_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_MONTH_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_MONTH_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_MONTH_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_MONTH_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_MONTH_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_EVALUATION_ONE_ON_ONES_BY_USER_CREATED__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENT_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_ASSESSMENTS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_DEFINITION_FOR_SCORE_100_PERCENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_DEFINITION_FOR_SCORE_100_PERCENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_DEFINITION_FOR_SCORE_100_PERCENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_DEFINITION_FOR_SCORE_100_PERCENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_EVALUATOR_EXPECTATION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_EVALUATOR_EXPECTATION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_EVALUATOR_EXPECTATION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_EVALUATOR_EXPECTATION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_TITLE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_TITLE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_TITLE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_TITLE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_WEIGHT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_WEIGHT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_WEIGHT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MAX_WEIGHT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_DEFINITION_FOR_SCORE_100_PERCENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_DEFINITION_FOR_SCORE_100_PERCENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_DEFINITION_FOR_SCORE_100_PERCENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_DEFINITION_FOR_SCORE_100_PERCENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_EVALUATOR_EXPECTATION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_EVALUATOR_EXPECTATION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_EVALUATOR_EXPECTATION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_EVALUATOR_EXPECTATION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_TITLE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_TITLE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_TITLE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_TITLE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_WEIGHT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_WEIGHT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_WEIGHT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_CREATED__MIN_WEIGHT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DEFINITION_FOR_SCORE_100_PERCENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DEFINITION_FOR_SCORE_100_PERCENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DEFINITION_FOR_SCORE_100_PERCENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DEFINITION_FOR_SCORE_100_PERCENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_EVALUATOR_EXPECTATION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_EVALUATOR_EXPECTATION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_EVALUATOR_EXPECTATION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_EVALUATOR_EXPECTATION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_TITLE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_TITLE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_TITLE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_TITLE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_WEIGHT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_WEIGHT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_WEIGHT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MAX_WEIGHT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DEFINITION_FOR_SCORE_100_PERCENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DEFINITION_FOR_SCORE_100_PERCENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DEFINITION_FOR_SCORE_100_PERCENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DEFINITION_FOR_SCORE_100_PERCENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_EVALUATOR_EXPECTATION_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_EVALUATOR_EXPECTATION_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_EVALUATOR_EXPECTATION_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_EVALUATOR_EXPECTATION_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_TITLE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_TITLE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_TITLE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_TITLE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_WEIGHT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_WEIGHT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_WEIGHT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_QUALITATIVE_DESCRIPTIONS_BY_USER_UPDATED__MIN_WEIGHT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_ASSESSMENT_COMMENT_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_ASSESSMENT_COMMENT_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_ASSESSMENT_COMMENT_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_ASSESSMENT_COMMENT_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_ASSESSMENT_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_ASSESSMENT_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_ASSESSMENT_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_ASSESSMENT_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_ASSESSMENT_VALUE_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_ASSESSMENT_VALUE_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_ASSESSMENT_VALUE_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_ASSESSMENT_VALUE_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_EVALUATION_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_EVALUATION_VALUES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCIES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_OVERRIDABLE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_OVERRIDABLE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_OVERRIDABLE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_OVERRIDABLE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_WEIGHT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_WEIGHT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_WEIGHT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_WEIGHT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_OVERRIDABLE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_OVERRIDABLE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_OVERRIDABLE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_OVERRIDABLE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_WEIGHT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_WEIGHT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_WEIGHT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_WEIGHT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_CREATED__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_OVERRIDABLE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_OVERRIDABLE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_OVERRIDABLE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_OVERRIDABLE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_WEIGHT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_WEIGHT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_WEIGHT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_WEIGHT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_OVERRIDABLE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_OVERRIDABLE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_OVERRIDABLE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_OVERRIDABLE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_WEIGHT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_WEIGHT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_WEIGHT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_WEIGHT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMONS_BY_USER_UPDATED__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_TITLE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_TITLE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_TITLE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_TITLE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_TITLE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_TITLE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_TITLE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_TITLE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_TITLE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_TITLE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_TITLE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_TITLE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_TITLE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_TITLE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_TITLE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_TITLE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_WEIGHT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_WEIGHT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_WEIGHT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MAX_WEIGHT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_WEIGHT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_WEIGHT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_WEIGHT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_CREATED__MIN_WEIGHT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_WEIGHT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_WEIGHT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_WEIGHT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MAX_WEIGHT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_OVERRIDING_PERFORMANCE_REVIEW_MASTER_COMPETENCY_COMMON_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_WEIGHT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_WEIGHT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_WEIGHT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAILS_BY_USER_UPDATED__MIN_WEIGHT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_TITLE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_TITLE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_TITLE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_TITLE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_TITLE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_TITLE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_TITLE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_TITLE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_TITLE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_TITLE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_TITLE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_TITLE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_TITLE_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_TITLE_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_TITLE_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_TITLE_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_DETAIL_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_BUSINESS_UNIT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_BUSINESS_UNIT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_BUSINESS_UNIT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_BUSINESS_UNIT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_DEPARTMENT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_DEPARTMENT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_DEPARTMENT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_DEPARTMENT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_DIVISION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_DIVISION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_DIVISION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_DIVISION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_ROLE_NAME_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_ROLE_NAME_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_ROLE_NAME_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_ROLE_NAME_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_BUSINESS_UNIT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_BUSINESS_UNIT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_BUSINESS_UNIT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_BUSINESS_UNIT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_DEPARTMENT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_DEPARTMENT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_DEPARTMENT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_DEPARTMENT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_DIVISION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_DIVISION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_DIVISION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_DIVISION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_ROLE_NAME_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_ROLE_NAME_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_ROLE_NAME_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_ROLE_NAME_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_CREATED__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_BUSINESS_UNIT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_BUSINESS_UNIT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_BUSINESS_UNIT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_BUSINESS_UNIT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_DEPARTMENT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_DEPARTMENT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_DEPARTMENT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_DEPARTMENT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_DIVISION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_DIVISION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_DIVISION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_DIVISION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_ROLE_NAME_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_ROLE_NAME_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_ROLE_NAME_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_ROLE_NAME_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_BUSINESS_UNIT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_BUSINESS_UNIT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_BUSINESS_UNIT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_BUSINESS_UNIT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_DEPARTMENT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_DEPARTMENT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_DEPARTMENT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_DEPARTMENT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_DIVISION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_DIVISION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_DIVISION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_DIVISION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_ROLE_NAME_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_ROLE_NAME_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_ROLE_NAME_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_ROLE_NAME_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_COMPETENCY_ROLES_BY_USER_UPDATED__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_DEFAULT_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_DEFAULT_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_DEFAULT_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_DEFAULT_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_DEFAULT_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_DEFAULT_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_DEFAULT_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_DEFAULT_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_LOCK_YEAR_QUARTER_FOR_EVERYONE_ASC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_LOCK_YEAR_QUARTER_FOR_EVERYONE_ASC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_LOCK_YEAR_QUARTER_FOR_EVERYONE_DESC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_LOCK_YEAR_QUARTER_FOR_EVERYONE_DESC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_DEFAULT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_DEFAULT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_DEFAULT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_DEFAULT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_DEFAULT_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_DEFAULT_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_DEFAULT_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_DEFAULT_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_DEFAULT_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_DEFAULT_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_DEFAULT_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_DEFAULT_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_LOCK_YEAR_QUARTER_FOR_EVERYONE_ASC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_LOCK_YEAR_QUARTER_FOR_EVERYONE_ASC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_LOCK_YEAR_QUARTER_FOR_EVERYONE_DESC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_LOCK_YEAR_QUARTER_FOR_EVERYONE_DESC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_DEFAULT_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_DEFAULT_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_DEFAULT_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_DEFAULT_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_DEFAULTS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_CAN_MODIFY_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_CAN_MODIFY_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_CAN_MODIFY_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_CAN_MODIFY_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_VIEWER_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_VIEWER_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_VIEWER_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_VIEWER_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_CAN_MODIFY_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_CAN_MODIFY_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_CAN_MODIFY_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_CAN_MODIFY_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_VIEWER_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_VIEWER_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_VIEWER_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_VIEWER_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_CREATED__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_CAN_MODIFY_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_CAN_MODIFY_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_CAN_MODIFY_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_CAN_MODIFY_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_VIEWER_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_VIEWER_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_VIEWER_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_VIEWER_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_CAN_MODIFY_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_CAN_MODIFY_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_CAN_MODIFY_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_CAN_MODIFY_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWER_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_VIEWER_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_VIEWER_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_VIEWER_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_VIEWER_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_EVALUATION_VIEWERS_BY_USER_UPDATED__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_CREATED__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_EXCLUDED_EMPLOYEES_BY_USER_UPDATED__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_HIDDEN_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_HIDDEN_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_HIDDEN_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_HIDDEN_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_MODE_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_MODE_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_MODE_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_MODE_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_REQUIRED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_REQUIRED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_REQUIRED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_REQUIRED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_HIDDEN_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_HIDDEN_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_HIDDEN_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_HIDDEN_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_MODE_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_MODE_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_MODE_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_MODE_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_REQUIRED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_REQUIRED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_REQUIRED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_REQUIRED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_CREATED__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_HIDDEN_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_HIDDEN_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_HIDDEN_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_HIDDEN_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_MODE_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_MODE_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_MODE_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_MODE_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_REQUIRED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_REQUIRED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_REQUIRED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_REQUIRED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_HIDDEN_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_HIDDEN_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_HIDDEN_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_HIDDEN_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_MODE_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_MODE_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_MODE_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_MODE_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_REQUIRED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_REQUIRED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_REQUIRED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_REQUIRED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONES_BY_USER_UPDATED__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_ITEM_NAME_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_ITEM_NAME_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_ITEM_NAME_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_ITEM_NAME_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_ITEM_NAME_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_ITEM_NAME_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_ITEM_NAME_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_ITEM_NAME_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_ITEM_NAME_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_ITEM_NAME_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_ITEM_NAME_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_ITEM_NAME_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_ITEM_NAME_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_ITEM_NAME_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_ITEM_NAME_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_ITEM_NAME_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_ONE_ON_ONE_DETAILS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_CALCULATION_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_CALCULATION_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_CALCULATION_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_CALCULATION_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_DISPLAY_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_DISPLAY_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_DISPLAY_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_DISPLAY_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_PERCENTAGE_LOWER_BOUND_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_PERCENTAGE_LOWER_BOUND_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_PERCENTAGE_LOWER_BOUND_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_PERCENTAGE_LOWER_BOUND_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_PERCENTAGE_UPPER_BOUND_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_PERCENTAGE_UPPER_BOUND_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_PERCENTAGE_UPPER_BOUND_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_PERCENTAGE_UPPER_BOUND_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_CALCULATION_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_CALCULATION_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_CALCULATION_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_CALCULATION_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_DISPLAY_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_DISPLAY_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_DISPLAY_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_DISPLAY_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_PERCENTAGE_LOWER_BOUND_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_PERCENTAGE_LOWER_BOUND_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_PERCENTAGE_LOWER_BOUND_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_PERCENTAGE_LOWER_BOUND_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_PERCENTAGE_UPPER_BOUND_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_PERCENTAGE_UPPER_BOUND_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_PERCENTAGE_UPPER_BOUND_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_PERCENTAGE_UPPER_BOUND_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_CREATED__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_CALCULATION_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_CALCULATION_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_CALCULATION_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_CALCULATION_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_DISPLAY_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_DISPLAY_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_DISPLAY_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_DISPLAY_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_PERCENTAGE_LOWER_BOUND_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_PERCENTAGE_LOWER_BOUND_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_PERCENTAGE_LOWER_BOUND_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_PERCENTAGE_LOWER_BOUND_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_PERCENTAGE_UPPER_BOUND_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_PERCENTAGE_UPPER_BOUND_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_PERCENTAGE_UPPER_BOUND_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_PERCENTAGE_UPPER_BOUND_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_CALCULATION_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_CALCULATION_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_CALCULATION_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_CALCULATION_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_DISPLAY_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_DISPLAY_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_DISPLAY_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_DISPLAY_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_PERCENTAGE_LOWER_BOUND_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_PERCENTAGE_LOWER_BOUND_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_PERCENTAGE_LOWER_BOUND_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_PERCENTAGE_LOWER_BOUND_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_PERCENTAGE_UPPER_BOUND_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_PERCENTAGE_UPPER_BOUND_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_PERCENTAGE_UPPER_BOUND_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_PERCENTAGE_UPPER_BOUND_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_RATINGS_BY_USER_UPDATED__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_EXPECTATION_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_EXPECTATION_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_EXPECTATION_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_EXPECTATION_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_RATING_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_RATING_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_RATING_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_RATING_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_EXPECTATION_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_EXPECTATION_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_EXPECTATION_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_EXPECTATION_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_RATING_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_RATING_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_RATING_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_RATING_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_EXPECTATION_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_EXPECTATION_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_EXPECTATION_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_EXPECTATION_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_RATING_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_RATING_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_RATING_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_RATING_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_EXPECTATION_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_EXPECTATION_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_EXPECTATION_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_EXPECTATION_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_RATING_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_RATING_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_RATING_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_RATING_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_RATING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_RATING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_RATING_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_COMPANY_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_COMPANY_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_COMPANY_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_COMPANY_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_USAGE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_USAGE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_USAGE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_USAGE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_USE_PERFORMANCE_REVIEW_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_USE_PERFORMANCE_REVIEW_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_USE_PERFORMANCE_REVIEW_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_USE_PERFORMANCE_REVIEW_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_COMPANY_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_COMPANY_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_COMPANY_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_COMPANY_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_USAGE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_USAGE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_USAGE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_USAGE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_USE_PERFORMANCE_REVIEW_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_USE_PERFORMANCE_REVIEW_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_USE_PERFORMANCE_REVIEW_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_USE_PERFORMANCE_REVIEW_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_CREATED__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_COMPANY_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_COMPANY_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_COMPANY_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_COMPANY_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_USAGE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_USAGE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_USAGE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_USAGE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_USE_PERFORMANCE_REVIEW_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_USE_PERFORMANCE_REVIEW_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_USE_PERFORMANCE_REVIEW_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_USE_PERFORMANCE_REVIEW_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_COMPANY_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_COMPANY_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_COMPANY_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_COMPANY_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_USAGE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_USAGE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_USAGE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_USAGE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_USE_PERFORMANCE_REVIEW_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_USE_PERFORMANCE_REVIEW_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_USE_PERFORMANCE_REVIEW_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_USE_PERFORMANCE_REVIEW_DESC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_USAGES_BY_USER_UPDATED__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_WEIGHT_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_WEIGHT_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_WEIGHT_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_WEIGHT_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_WEIGHT_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_WEIGHT_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_WEIGHT_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_WEIGHT_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_CREATED__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_WEIGHT_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_WEIGHT_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_WEIGHT_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_WEIGHT_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_DISPLAY_ORDER_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_DISPLAY_ORDER_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_DISPLAY_ORDER_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_DISPLAY_ORDER_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_WEIGHT_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_WEIGHT_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_WEIGHT_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_WEIGHT_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUES_BY_USER_UPDATED__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_TITLE_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_TITLE_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_TITLE_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_TITLE_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_TITLE_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_TITLE_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_TITLE_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_TITLE_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_TITLE_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_TITLE_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_TITLE_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_TITLE_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_LANGUAGE_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_LANGUAGE_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_LANGUAGE_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_LANGUAGE_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTION_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_TITLE_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_TITLE_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_TITLE_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_TITLE_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_VALUE_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_COMPANY_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_COMPANY_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_COMPANY_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_COMPANY_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_NAME_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_NAME_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_NAME_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_NAME_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_COMPANY_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_COMPANY_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_COMPANY_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_COMPANY_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_NAME_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_NAME_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_NAME_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_NAME_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_CREATED__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_COMPANY_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_COMPANY_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_COMPANY_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_COMPANY_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_NAME_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_NAME_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_NAME_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_NAME_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MAX_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_COMPANY_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_COMPANY_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_COMPANY_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_COMPANY_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_NAME_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_NAME_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_NAME_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_NAME_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_QUARTER_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_QUARTER_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_QUARTER_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_QUARTER_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_YEAR_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_YEAR_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_YEAR_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTINGS_BY_USER_UPDATED__MIN_YEAR_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_EMPLOYEE_LEVEL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_EMPLOYEE_LEVEL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_EMPLOYEE_LEVEL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_EMPLOYEE_LEVEL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_PERCENTAGE_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_PERCENTAGE_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_PERCENTAGE_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_PERCENTAGE_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_PERCENTAGE_MULTI_SOURCE_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_PERCENTAGE_MULTI_SOURCE_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_PERCENTAGE_MULTI_SOURCE_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_PERCENTAGE_MULTI_SOURCE_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_PERCENTAGE_TARGET_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_PERCENTAGE_TARGET_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_PERCENTAGE_TARGET_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_PERCENTAGE_TARGET_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_PERCENTAGE_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_PERCENTAGE_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_PERCENTAGE_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_PERCENTAGE_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_EMPLOYEE_LEVEL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_EMPLOYEE_LEVEL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_EMPLOYEE_LEVEL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_EMPLOYEE_LEVEL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_PERCENTAGE_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_PERCENTAGE_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_PERCENTAGE_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_PERCENTAGE_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_PERCENTAGE_MULTI_SOURCE_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_PERCENTAGE_MULTI_SOURCE_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_PERCENTAGE_MULTI_SOURCE_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_PERCENTAGE_MULTI_SOURCE_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_PERCENTAGE_TARGET_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_PERCENTAGE_TARGET_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_PERCENTAGE_TARGET_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_PERCENTAGE_TARGET_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_PERCENTAGE_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_PERCENTAGE_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_PERCENTAGE_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_PERCENTAGE_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__COUNT_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__COUNT_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__COUNT_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__COUNT_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_EMPLOYEE_LEVEL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_EMPLOYEE_LEVEL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_EMPLOYEE_LEVEL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_EMPLOYEE_LEVEL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_PERCENTAGE_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_PERCENTAGE_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_PERCENTAGE_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_PERCENTAGE_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_PERCENTAGE_MULTI_SOURCE_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_PERCENTAGE_MULTI_SOURCE_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_PERCENTAGE_MULTI_SOURCE_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_PERCENTAGE_MULTI_SOURCE_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_PERCENTAGE_TARGET_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_PERCENTAGE_TARGET_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_PERCENTAGE_TARGET_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_PERCENTAGE_TARGET_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_PERCENTAGE_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_PERCENTAGE_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_PERCENTAGE_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_PERCENTAGE_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_EMPLOYEE_LEVEL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_EMPLOYEE_LEVEL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_EMPLOYEE_LEVEL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_EMPLOYEE_LEVEL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_PERCENTAGE_COMPETENCY_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_PERCENTAGE_COMPETENCY_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_PERCENTAGE_COMPETENCY_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_PERCENTAGE_COMPETENCY_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_PERCENTAGE_MULTI_SOURCE_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_PERCENTAGE_MULTI_SOURCE_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_PERCENTAGE_MULTI_SOURCE_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_PERCENTAGE_MULTI_SOURCE_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_PERCENTAGE_TARGET_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_PERCENTAGE_TARGET_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_PERCENTAGE_TARGET_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_PERCENTAGE_TARGET_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_PERCENTAGE_VALUE_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_PERCENTAGE_VALUE_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_PERCENTAGE_VALUE_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_PERCENTAGE_VALUE_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAIL_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_WEIGHTING_ID_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERFORMANCE_REVIEW_MASTER_WEIGHTING_DETAILS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__COUNT_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__COUNT_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__COUNT_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__COUNT_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_CODE_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_CODE_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_CODE_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_CODE_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_PERSONAL_IDENTITY_TYPE_ID_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_PERSONAL_IDENTITY_TYPE_ID_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_PERSONAL_IDENTITY_TYPE_ID_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_PERSONAL_IDENTITY_TYPE_ID_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_REMARK_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_REMARK_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_REMARK_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_REMARK_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_CODE_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_CODE_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_CODE_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_CODE_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_PERSONAL_IDENTITY_TYPE_ID_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_PERSONAL_IDENTITY_TYPE_ID_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_PERSONAL_IDENTITY_TYPE_ID_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_PERSONAL_IDENTITY_TYPE_ID_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_REMARK_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_REMARK_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_REMARK_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_REMARK_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__COUNT_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__COUNT_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__COUNT_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__COUNT_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_CODE_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_CODE_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_CODE_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_CODE_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_PERSONAL_IDENTITY_TYPE_ID_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_PERSONAL_IDENTITY_TYPE_ID_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_PERSONAL_IDENTITY_TYPE_ID_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_PERSONAL_IDENTITY_TYPE_ID_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_REMARK_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_REMARK_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_REMARK_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_REMARK_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_CODE_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_CODE_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_CODE_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_CODE_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_PERSONAL_IDENTITY_TYPE_ID_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_PERSONAL_IDENTITY_TYPE_ID_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_PERSONAL_IDENTITY_TYPE_ID_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_PERSONAL_IDENTITY_TYPE_ID_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_REMARK_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_REMARK_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_REMARK_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_REMARK_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PERSONAL_IDENTITY_TYPES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__COUNT_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__COUNT_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__COUNT_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__COUNT_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_COMPANY_ID_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_COMPANY_ID_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_COMPANY_ID_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_COMPANY_ID_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_CONTRACT_TYPE_ID_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_CONTRACT_TYPE_ID_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_CONTRACT_TYPE_ID_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_CONTRACT_TYPE_ID_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_DEFAULT_PROBATION_PERIOD_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_DEFAULT_PROBATION_PERIOD_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_DEFAULT_PROBATION_PERIOD_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_DEFAULT_PROBATION_PERIOD_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_ENABLED_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_ENABLED_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_ENABLED_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_ENABLED_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_PROBATION_DATE_INTERVAL_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_PROBATION_DATE_INTERVAL_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_PROBATION_DATE_INTERVAL_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_PROBATION_DATE_INTERVAL_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_PROBATION_REVIEW_ENABLE_ID_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_PROBATION_REVIEW_ENABLE_ID_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_PROBATION_REVIEW_ENABLE_ID_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_PROBATION_REVIEW_ENABLE_ID_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_REMARK_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_REMARK_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_REMARK_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_REMARK_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_COMPANY_ID_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_COMPANY_ID_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_COMPANY_ID_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_COMPANY_ID_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_CONTRACT_TYPE_ID_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_CONTRACT_TYPE_ID_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_CONTRACT_TYPE_ID_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_CONTRACT_TYPE_ID_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_DEFAULT_PROBATION_PERIOD_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_DEFAULT_PROBATION_PERIOD_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_DEFAULT_PROBATION_PERIOD_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_DEFAULT_PROBATION_PERIOD_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_ENABLED_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_ENABLED_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_ENABLED_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_ENABLED_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_PROBATION_DATE_INTERVAL_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_PROBATION_DATE_INTERVAL_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_PROBATION_DATE_INTERVAL_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_PROBATION_DATE_INTERVAL_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_PROBATION_REVIEW_ENABLE_ID_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_PROBATION_REVIEW_ENABLE_ID_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_PROBATION_REVIEW_ENABLE_ID_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_PROBATION_REVIEW_ENABLE_ID_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_REMARK_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_REMARK_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_REMARK_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_REMARK_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__COUNT_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__COUNT_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__COUNT_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__COUNT_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_COMPANY_ID_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_COMPANY_ID_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_COMPANY_ID_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_COMPANY_ID_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_CONTRACT_TYPE_ID_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_CONTRACT_TYPE_ID_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_CONTRACT_TYPE_ID_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_CONTRACT_TYPE_ID_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_DEFAULT_PROBATION_PERIOD_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_DEFAULT_PROBATION_PERIOD_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_DEFAULT_PROBATION_PERIOD_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_DEFAULT_PROBATION_PERIOD_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_ENABLED_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_ENABLED_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_ENABLED_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_ENABLED_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_PROBATION_DATE_INTERVAL_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_PROBATION_DATE_INTERVAL_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_PROBATION_DATE_INTERVAL_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_PROBATION_DATE_INTERVAL_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_ENABLE_ID_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_ENABLE_ID_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_ENABLE_ID_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_ENABLE_ID_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_REMARK_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_REMARK_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_REMARK_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_REMARK_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_COMPANY_ID_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_COMPANY_ID_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_COMPANY_ID_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_COMPANY_ID_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_CONTRACT_TYPE_ID_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_CONTRACT_TYPE_ID_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_CONTRACT_TYPE_ID_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_CONTRACT_TYPE_ID_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_DEFAULT_PROBATION_PERIOD_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_DEFAULT_PROBATION_PERIOD_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_DEFAULT_PROBATION_PERIOD_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_DEFAULT_PROBATION_PERIOD_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_ENABLED_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_ENABLED_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_ENABLED_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_ENABLED_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_PROBATION_DATE_INTERVAL_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_PROBATION_DATE_INTERVAL_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_PROBATION_DATE_INTERVAL_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_PROBATION_DATE_INTERVAL_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_ENABLE_ID_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_ENABLE_ID_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_ENABLE_ID_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_ENABLE_ID_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_REMARK_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_REMARK_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_REMARK_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_REMARK_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_ENABLES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__COUNT_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__COUNT_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__COUNT_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__COUNT_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_DATE_REMINDER_SENT_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_DATE_REMINDER_SENT_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_DATE_REMINDER_SENT_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_DATE_REMINDER_SENT_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_GOOGLE_CALENDAR_INFO_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_GOOGLE_CALENDAR_INFO_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_GOOGLE_CALENDAR_INFO_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_GOOGLE_CALENDAR_INFO_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_IS_FINAL_OR_EXTENDED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_IS_FINAL_OR_EXTENDED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_IS_FINAL_OR_EXTENDED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_IS_FINAL_OR_EXTENDED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_REMARK_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_REMARK_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_REMARK_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_REMARK_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_RESULT_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_RESULT_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_RESULT_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_RESULT_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_REVIEW_DATE_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_REVIEW_DATE_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_REVIEW_DATE_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_REVIEW_DATE_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_REVIEW_DATE_PLANNED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_REVIEW_DATE_PLANNED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_REVIEW_DATE_PLANNED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_REVIEW_DATE_PLANNED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_SUBMIT_DATE_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_SUBMIT_DATE_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_SUBMIT_DATE_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_SUBMIT_DATE_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_DATE_REMINDER_SENT_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_DATE_REMINDER_SENT_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_DATE_REMINDER_SENT_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_DATE_REMINDER_SENT_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_GOOGLE_CALENDAR_INFO_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_GOOGLE_CALENDAR_INFO_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_GOOGLE_CALENDAR_INFO_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_GOOGLE_CALENDAR_INFO_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_IS_FINAL_OR_EXTENDED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_IS_FINAL_OR_EXTENDED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_IS_FINAL_OR_EXTENDED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_IS_FINAL_OR_EXTENDED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_REMARK_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_REMARK_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_REMARK_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_REMARK_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_RESULT_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_RESULT_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_RESULT_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_RESULT_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_REVIEW_DATE_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_REVIEW_DATE_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_REVIEW_DATE_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_REVIEW_DATE_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_REVIEW_DATE_PLANNED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_REVIEW_DATE_PLANNED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_REVIEW_DATE_PLANNED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_REVIEW_DATE_PLANNED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_SUBMIT_DATE_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_SUBMIT_DATE_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_SUBMIT_DATE_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_SUBMIT_DATE_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__COUNT_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__COUNT_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__COUNT_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__COUNT_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_DATE_REMINDER_SENT_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_DATE_REMINDER_SENT_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_DATE_REMINDER_SENT_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_DATE_REMINDER_SENT_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_GOOGLE_CALENDAR_INFO_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_GOOGLE_CALENDAR_INFO_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_GOOGLE_CALENDAR_INFO_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_GOOGLE_CALENDAR_INFO_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_IS_FINAL_OR_EXTENDED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_IS_FINAL_OR_EXTENDED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_IS_FINAL_OR_EXTENDED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_IS_FINAL_OR_EXTENDED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_REMARK_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_REMARK_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_REMARK_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_REMARK_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_RESULT_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_RESULT_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_RESULT_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_RESULT_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_REVIEW_DATE_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_REVIEW_DATE_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_REVIEW_DATE_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_REVIEW_DATE_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_REVIEW_DATE_PLANNED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_REVIEW_DATE_PLANNED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_REVIEW_DATE_PLANNED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_REVIEW_DATE_PLANNED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_SUBMIT_DATE_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_SUBMIT_DATE_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_SUBMIT_DATE_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_SUBMIT_DATE_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_DATE_REMINDER_SENT_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_DATE_REMINDER_SENT_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_DATE_REMINDER_SENT_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_DATE_REMINDER_SENT_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_GOOGLE_CALENDAR_INFO_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_GOOGLE_CALENDAR_INFO_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_GOOGLE_CALENDAR_INFO_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_GOOGLE_CALENDAR_INFO_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_IS_FINAL_OR_EXTENDED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_IS_FINAL_OR_EXTENDED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_IS_FINAL_OR_EXTENDED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_IS_FINAL_OR_EXTENDED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_REMARK_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_REMARK_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_REMARK_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_REMARK_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_RESULT_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_RESULT_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_RESULT_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_RESULT_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_REVIEW_DATE_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_REVIEW_DATE_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_REVIEW_DATE_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_REVIEW_DATE_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_REVIEW_DATE_PLANNED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_REVIEW_DATE_PLANNED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_REVIEW_DATE_PLANNED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_REVIEW_DATE_PLANNED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_SUBMIT_DATE_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_SUBMIT_DATE_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_SUBMIT_DATE_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_SUBMIT_DATE_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATIONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__COUNT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__COUNT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__COUNT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__COUNT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_VALUE_INPUT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_VALUE_INPUT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_VALUE_INPUT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_VALUE_INPUT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_VALUE_OUTPUT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_VALUE_OUTPUT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_VALUE_OUTPUT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_VALUE_OUTPUT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_VALUE_SINGLE_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_VALUE_SINGLE_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_VALUE_SINGLE_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MAX_VALUE_SINGLE_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_VALUE_INPUT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_VALUE_INPUT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_VALUE_INPUT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_VALUE_INPUT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_VALUE_OUTPUT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_VALUE_OUTPUT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_VALUE_OUTPUT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_VALUE_OUTPUT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_VALUE_SINGLE_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_VALUE_SINGLE_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_VALUE_SINGLE_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_CREATED__MIN_VALUE_SINGLE_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__COUNT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__COUNT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__COUNT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__COUNT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_VALUE_INPUT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_VALUE_INPUT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_VALUE_INPUT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_VALUE_INPUT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_VALUE_OUTPUT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_VALUE_OUTPUT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_VALUE_OUTPUT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_VALUE_OUTPUT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_VALUE_SINGLE_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_VALUE_SINGLE_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_VALUE_SINGLE_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MAX_VALUE_SINGLE_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_RESULT_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_VALUE_INPUT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_VALUE_INPUT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_VALUE_INPUT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_VALUE_INPUT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_VALUE_OUTPUT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_VALUE_OUTPUT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_VALUE_OUTPUT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_VALUE_OUTPUT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_VALUE_SINGLE_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_VALUE_SINGLE_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_VALUE_SINGLE_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_ITEMS_BY_USER_UPDATED__MIN_VALUE_SINGLE_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__COUNT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__COUNT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__COUNT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__COUNT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_ASSESSMENT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_ASSESSMENT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_ASSESSMENT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_ASSESSMENT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_ASSESSMENT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_ASSESSMENT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_ASSESSMENT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_ASSESSMENT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__COUNT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__COUNT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__COUNT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__COUNT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_ASSESSMENT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_ASSESSMENT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_ASSESSMENT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_ASSESSMENT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_ASSESSMENT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_ASSESSMENT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_ASSESSMENT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_ASSESSMENT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_COMPETENCY_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCY_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_COMPETENCIES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__COUNT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__COUNT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__COUNT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__COUNT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_ASSESSMENT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_ASSESSMENT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_ASSESSMENT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_ASSESSMENT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_ASSESSMENT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_ASSESSMENT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_ASSESSMENT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_ASSESSMENT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__COUNT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__COUNT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__COUNT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__COUNT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_ASSESSMENT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_ASSESSMENT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_ASSESSMENT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_ASSESSMENT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_ASSESSMENT_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_ASSESSMENT_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_ASSESSMENT_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_ASSESSMENT_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_PERFORMANCE_REVIEW_MASTER_VALUE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_RESULT_SCORE_VALUES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__COUNT_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__COUNT_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__COUNT_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__COUNT_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_REVIEWER_EMPLOYEE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_REVIEWER_EMPLOYEE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_REVIEWER_EMPLOYEE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_REVIEWER_EMPLOYEE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_REVIEWER_EMPLOYEE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_REVIEWER_EMPLOYEE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_REVIEWER_EMPLOYEE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_REVIEWER_EMPLOYEE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__COUNT_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__COUNT_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__COUNT_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__COUNT_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_REVIEWER_EMPLOYEE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_REVIEWER_EMPLOYEE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_REVIEWER_EMPLOYEE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_REVIEWER_EMPLOYEE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_ID_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_EVALUATION_ID_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_REMARK_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_REMARK_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_REMARK_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_REMARK_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_REVIEWER_EMPLOYEE_ID_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_REVIEWER_EMPLOYEE_ID_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_REVIEWER_EMPLOYEE_ID_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_REVIEWER_EMPLOYEE_ID_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_EVALUATION_REVIEWERS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__COUNT_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__COUNT_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__COUNT_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__COUNT_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_DATE_DISABLED_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_DATE_DISABLED_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_DATE_DISABLED_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_DATE_DISABLED_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_DATE_ENABLED_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_DATE_ENABLED_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_DATE_ENABLED_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_DATE_ENABLED_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_DISPLAY_ORDER_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_DISPLAY_ORDER_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_DISPLAY_ORDER_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_DISPLAY_ORDER_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_INPUT_MODE_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_INPUT_MODE_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_INPUT_MODE_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_INPUT_MODE_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_INPUT_USER_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_INPUT_USER_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_INPUT_USER_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_INPUT_USER_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_QUARTER_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_QUARTER_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_QUARTER_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_QUARTER_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_REMARK_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_REMARK_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_REMARK_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_REMARK_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_SHOW_ON_SUMMARY_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_SHOW_ON_SUMMARY_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_SHOW_ON_SUMMARY_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_SHOW_ON_SUMMARY_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_TITLE_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_TITLE_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_TITLE_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_TITLE_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_YEAR_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_YEAR_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_YEAR_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MAX_YEAR_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_DATE_DISABLED_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_DATE_DISABLED_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_DATE_DISABLED_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_DATE_DISABLED_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_DATE_ENABLED_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_DATE_ENABLED_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_DATE_ENABLED_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_DATE_ENABLED_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_DISPLAY_ORDER_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_DISPLAY_ORDER_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_DISPLAY_ORDER_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_DISPLAY_ORDER_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_INPUT_MODE_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_INPUT_MODE_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_INPUT_MODE_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_INPUT_MODE_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_INPUT_USER_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_INPUT_USER_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_INPUT_USER_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_INPUT_USER_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_QUARTER_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_QUARTER_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_QUARTER_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_QUARTER_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_REMARK_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_REMARK_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_REMARK_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_REMARK_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_SHOW_ON_SUMMARY_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_SHOW_ON_SUMMARY_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_SHOW_ON_SUMMARY_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_SHOW_ON_SUMMARY_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_TITLE_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_TITLE_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_TITLE_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_TITLE_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_YEAR_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_YEAR_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_YEAR_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_CREATED__MIN_YEAR_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__COUNT_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__COUNT_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__COUNT_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__COUNT_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_DATE_DISABLED_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_DATE_DISABLED_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_DATE_DISABLED_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_DATE_DISABLED_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_DATE_ENABLED_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_DATE_ENABLED_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_DATE_ENABLED_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_DATE_ENABLED_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_DISPLAY_ORDER_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_DISPLAY_ORDER_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_DISPLAY_ORDER_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_DISPLAY_ORDER_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_INPUT_MODE_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_INPUT_MODE_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_INPUT_MODE_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_INPUT_MODE_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_INPUT_USER_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_INPUT_USER_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_INPUT_USER_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_INPUT_USER_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_QUARTER_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_QUARTER_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_QUARTER_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_QUARTER_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_REMARK_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_REMARK_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_REMARK_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_REMARK_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_SHOW_ON_SUMMARY_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_SHOW_ON_SUMMARY_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_SHOW_ON_SUMMARY_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_SHOW_ON_SUMMARY_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_TITLE_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_TITLE_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_TITLE_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_TITLE_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_YEAR_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_YEAR_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_YEAR_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MAX_YEAR_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_DATE_DISABLED_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_DATE_DISABLED_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_DATE_DISABLED_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_DATE_DISABLED_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_DATE_ENABLED_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_DATE_ENABLED_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_DATE_ENABLED_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_DATE_ENABLED_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_DISPLAY_ORDER_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_DISPLAY_ORDER_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_DISPLAY_ORDER_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_DISPLAY_ORDER_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_INPUT_MODE_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_INPUT_MODE_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_INPUT_MODE_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_INPUT_MODE_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_INPUT_USER_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_INPUT_USER_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_INPUT_USER_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_INPUT_USER_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_QUARTER_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_QUARTER_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_QUARTER_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_QUARTER_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_REMARK_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_REMARK_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_REMARK_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_REMARK_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_SHOW_ON_SUMMARY_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_SHOW_ON_SUMMARY_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_SHOW_ON_SUMMARY_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_SHOW_ON_SUMMARY_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_TITLE_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_TITLE_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_TITLE_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_TITLE_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_YEAR_ASC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_YEAR_ASC',
  PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_YEAR_DESC = 'PROBATION_REVIEW_MASTER_ITEMS_BY_USER_UPDATED__MIN_YEAR_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__COUNT_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__COUNT_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__COUNT_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__COUNT_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_DESCRIPTION_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_LANGUAGE_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_LANGUAGE_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_LANGUAGE_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_LANGUAGE_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_REMARK_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_DESCRIPTION_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_LANGUAGE_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_LANGUAGE_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_LANGUAGE_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_LANGUAGE_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_REMARK_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__COUNT_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__COUNT_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__COUNT_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__COUNT_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_DESCRIPTION_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_LANGUAGE_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_LANGUAGE_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_LANGUAGE_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_LANGUAGE_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_REMARK_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_DESCRIPTION_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_LANGUAGE_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_LANGUAGE_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_LANGUAGE_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_LANGUAGE_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_MASTER_ITEM_DESCRIPTION_ID_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_PROBATION_REVIEW_MASTER_ITEM_ID_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_REMARK_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'PROBATION_REVIEW_MASTER_ITEM_DESCRIPTIONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_AVERAGE_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_COUNT_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_COUNT_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_COUNT_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_COUNT_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_DISTINCT_COUNT_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MAX_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_MIN_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_POPULATION_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_STDDEV_SAMPLE_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_SUM_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_POPULATION_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED_VARIANCE_SAMPLE_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__COUNT_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__COUNT_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__COUNT_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__COUNT_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_AVERAGE_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_COUNT_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_COUNT_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_COUNT_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_COUNT_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_DISTINCT_COUNT_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MAX_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_MIN_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_POPULATION_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_STDDEV_SAMPLE_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_SUM_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_POPULATION_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED_VARIANCE_SAMPLE_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__COUNT_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__COUNT_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__COUNT_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__COUNT_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISIONS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_AVERAGE_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_COUNT_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_COUNT_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_COUNT_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_COUNT_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_DISTINCT_COUNT_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MAX_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_MIN_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_POPULATION_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_STDDEV_SAMPLE_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_SUM_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_POPULATION_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED_VARIANCE_SAMPLE_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__COUNT_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__COUNT_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__COUNT_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__COUNT_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_AVERAGE_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_COUNT_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_COUNT_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_COUNT_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_COUNT_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_DISTINCT_COUNT_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MAX_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_MIN_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_POPULATION_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_STDDEV_SAMPLE_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_SUM_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_POPULATION_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED_VARIANCE_SAMPLE_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__COUNT_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__COUNT_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__COUNT_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__COUNT_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_BUSINESS_UNIT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_BUSINESS_UNIT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_BUSINESS_UNIT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_BUSINESS_UNIT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_DEPARTMENT_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_DEPARTMENT_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_DEPARTMENT_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_DEPARTMENT_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_DIVISION_ID_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_DIVISION_ID_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_DIVISION_ID_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_DIVISION_ID_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_REMARK_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_REMARK_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_REMARK_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_REMARK_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'RELATION_BUSINESS_UNIT_DIVISION_DEPARTMENTS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__COUNT_ASC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__COUNT_ASC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__COUNT_DESC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__COUNT_DESC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_CITY_ID_ASC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_CITY_ID_ASC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_CITY_ID_DESC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_CITY_ID_DESC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_COMPANY_ID_ASC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_COMPANY_ID_ASC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_COMPANY_ID_DESC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_COMPANY_ID_DESC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_REMARK_ASC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_REMARK_ASC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_REMARK_DESC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_REMARK_DESC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_CITY_ID_ASC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_CITY_ID_ASC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_CITY_ID_DESC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_CITY_ID_DESC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_COMPANY_ID_ASC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_COMPANY_ID_ASC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_COMPANY_ID_DESC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_COMPANY_ID_DESC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_REMARK_ASC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_REMARK_ASC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_REMARK_DESC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_REMARK_DESC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'RELATION_CITY_COMPANIES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__COUNT_ASC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__COUNT_ASC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__COUNT_DESC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__COUNT_DESC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_CITY_ID_ASC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_CITY_ID_ASC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_CITY_ID_DESC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_CITY_ID_DESC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_COMPANY_ID_ASC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_COMPANY_ID_ASC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_COMPANY_ID_DESC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_COMPANY_ID_DESC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_REMARK_ASC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_REMARK_ASC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_REMARK_DESC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_REMARK_DESC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_CITY_ID_ASC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_CITY_ID_ASC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_CITY_ID_DESC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_CITY_ID_DESC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_COMPANY_ID_ASC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_COMPANY_ID_ASC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_COMPANY_ID_DESC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_COMPANY_ID_DESC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_REMARK_ASC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_REMARK_ASC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_REMARK_DESC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_REMARK_DESC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'RELATION_CITY_COMPANIES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__COUNT_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__COUNT_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__COUNT_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__COUNT_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_BUSINESS_UNIT_ID_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_BUSINESS_UNIT_ID_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_BUSINESS_UNIT_ID_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_BUSINESS_UNIT_ID_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_COMPANY_ID_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_COMPANY_ID_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_COMPANY_ID_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_COMPANY_ID_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_REMARK_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_REMARK_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_REMARK_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_REMARK_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_BUSINESS_UNIT_ID_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_BUSINESS_UNIT_ID_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_BUSINESS_UNIT_ID_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_BUSINESS_UNIT_ID_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_COMPANY_ID_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_COMPANY_ID_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_COMPANY_ID_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_COMPANY_ID_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_REMARK_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_REMARK_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_REMARK_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_REMARK_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__COUNT_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__COUNT_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__COUNT_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__COUNT_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_BUSINESS_UNIT_ID_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_BUSINESS_UNIT_ID_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_BUSINESS_UNIT_ID_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_BUSINESS_UNIT_ID_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_COMPANY_ID_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_COMPANY_ID_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_COMPANY_ID_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_COMPANY_ID_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_REMARK_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_REMARK_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_REMARK_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_REMARK_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_BUSINESS_UNIT_ID_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_BUSINESS_UNIT_ID_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_BUSINESS_UNIT_ID_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_BUSINESS_UNIT_ID_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_COMPANY_ID_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_COMPANY_ID_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_COMPANY_ID_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_COMPANY_ID_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_REMARK_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_REMARK_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_REMARK_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_REMARK_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'RELATION_COMPANY_BUSINESS_UNITS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__COUNT_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__COUNT_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__COUNT_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__COUNT_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_COMPANY_GROUP_ID_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_COMPANY_GROUP_ID_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_COMPANY_GROUP_ID_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_COMPANY_GROUP_ID_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_EMAIL_DOMAIN_ID_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_EMAIL_DOMAIN_ID_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_EMAIL_DOMAIN_ID_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_EMAIL_DOMAIN_ID_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_REMARK_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_REMARK_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_REMARK_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_REMARK_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_COMPANY_GROUP_ID_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_COMPANY_GROUP_ID_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_COMPANY_GROUP_ID_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_COMPANY_GROUP_ID_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_EMAIL_DOMAIN_ID_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_EMAIL_DOMAIN_ID_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_EMAIL_DOMAIN_ID_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_EMAIL_DOMAIN_ID_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_REMARK_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_REMARK_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_REMARK_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_REMARK_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__COUNT_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__COUNT_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__COUNT_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__COUNT_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_COMPANY_GROUP_ID_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_COMPANY_GROUP_ID_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_COMPANY_GROUP_ID_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_COMPANY_GROUP_ID_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_EMAIL_DOMAIN_ID_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_EMAIL_DOMAIN_ID_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_EMAIL_DOMAIN_ID_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_EMAIL_DOMAIN_ID_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_REMARK_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_REMARK_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_REMARK_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_REMARK_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_COMPANY_GROUP_ID_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_COMPANY_GROUP_ID_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_COMPANY_GROUP_ID_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_COMPANY_GROUP_ID_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_EMAIL_DOMAIN_ID_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_EMAIL_DOMAIN_ID_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_EMAIL_DOMAIN_ID_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_EMAIL_DOMAIN_ID_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_REMARK_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_REMARK_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_REMARK_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_REMARK_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'RELATION_COMPANY_GROUP_EMAIL_DOMAINS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__COUNT_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__COUNT_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__COUNT_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__COUNT_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_COMPANY_ID_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_COMPANY_ID_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_COMPANY_ID_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_COMPANY_ID_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_OFFICE_ID_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_OFFICE_ID_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_OFFICE_ID_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_OFFICE_ID_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_REMARK_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_REMARK_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_REMARK_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_REMARK_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_COMPANY_ID_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_COMPANY_ID_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_COMPANY_ID_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_COMPANY_ID_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_OFFICE_ID_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_OFFICE_ID_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_OFFICE_ID_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_OFFICE_ID_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_REMARK_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_REMARK_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_REMARK_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_REMARK_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__COUNT_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__COUNT_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__COUNT_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__COUNT_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_COMPANY_ID_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_COMPANY_ID_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_COMPANY_ID_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_COMPANY_ID_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_OFFICE_ID_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_OFFICE_ID_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_OFFICE_ID_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_OFFICE_ID_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_REMARK_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_REMARK_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_REMARK_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_REMARK_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_COMPANY_ID_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_COMPANY_ID_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_COMPANY_ID_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_COMPANY_ID_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_OFFICE_ID_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_OFFICE_ID_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_OFFICE_ID_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_OFFICE_ID_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_REMARK_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_REMARK_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_REMARK_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_REMARK_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'RELATION_COMPANY_OFFICES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__COUNT_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__COUNT_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__COUNT_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__COUNT_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA2_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA2_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA2_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA2_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_CURRENCY_CODE_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_CURRENCY_CODE_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_CURRENCY_CODE_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_CURRENCY_CODE_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_REMARK_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_REMARK_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_REMARK_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_REMARK_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA2_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA2_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA2_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA2_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_CURRENCY_CODE_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_CURRENCY_CODE_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_CURRENCY_CODE_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_CURRENCY_CODE_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_REMARK_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_REMARK_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_REMARK_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_REMARK_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__COUNT_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__COUNT_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__COUNT_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__COUNT_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA2_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA2_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA2_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA2_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_CURRENCY_CODE_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_CURRENCY_CODE_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_CURRENCY_CODE_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_CURRENCY_CODE_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_REMARK_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_REMARK_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_REMARK_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_REMARK_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA2_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA2_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA2_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA2_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_CURRENCY_CODE_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_CURRENCY_CODE_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_CURRENCY_CODE_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_CURRENCY_CODE_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_REMARK_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_REMARK_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_REMARK_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_REMARK_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'RELATION_CURRENCY_COUNTRIES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__COUNT_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__COUNT_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__COUNT_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__COUNT_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_DISPLAY_NAME_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_DISPLAY_NAME_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_DISPLAY_NAME_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_DISPLAY_NAME_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_DISPLAY_ORDER_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_DISPLAY_ORDER_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_DISPLAY_ORDER_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_DISPLAY_ORDER_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_EMERGENCY_CONTACT_RELATION_ID_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_EMERGENCY_CONTACT_RELATION_ID_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_EMERGENCY_CONTACT_RELATION_ID_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_EMERGENCY_CONTACT_RELATION_ID_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_REMARK_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_REMARK_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_REMARK_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_REMARK_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_DISPLAY_NAME_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_DISPLAY_NAME_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_DISPLAY_NAME_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_DISPLAY_NAME_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_DISPLAY_ORDER_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_DISPLAY_ORDER_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_DISPLAY_ORDER_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_DISPLAY_ORDER_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_EMERGENCY_CONTACT_RELATION_ID_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_EMERGENCY_CONTACT_RELATION_ID_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_EMERGENCY_CONTACT_RELATION_ID_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_EMERGENCY_CONTACT_RELATION_ID_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_REMARK_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_REMARK_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_REMARK_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_REMARK_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__COUNT_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__COUNT_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__COUNT_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__COUNT_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_DISPLAY_NAME_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_DISPLAY_NAME_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_DISPLAY_NAME_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_DISPLAY_NAME_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_DISPLAY_ORDER_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_DISPLAY_ORDER_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_DISPLAY_ORDER_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_DISPLAY_ORDER_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_EMERGENCY_CONTACT_RELATION_ID_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_EMERGENCY_CONTACT_RELATION_ID_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_EMERGENCY_CONTACT_RELATION_ID_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_EMERGENCY_CONTACT_RELATION_ID_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_REMARK_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_REMARK_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_REMARK_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_REMARK_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_DISPLAY_NAME_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_DISPLAY_NAME_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_DISPLAY_NAME_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_DISPLAY_NAME_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_DISPLAY_ORDER_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_DISPLAY_ORDER_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_DISPLAY_ORDER_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_DISPLAY_ORDER_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_EMERGENCY_CONTACT_RELATION_ID_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_EMERGENCY_CONTACT_RELATION_ID_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_EMERGENCY_CONTACT_RELATION_ID_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_EMERGENCY_CONTACT_RELATION_ID_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_REMARK_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_REMARK_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_REMARK_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_REMARK_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'RELATION_EMERGENCY_CONTACTS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__COUNT_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__COUNT_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__COUNT_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__COUNT_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_EMPLOYEE_ID_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_EMPLOYEE_ID_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_REMARK_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_REMARK_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_REMARK_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_REMARK_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_USER_ACCOUNT_ID_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_USER_ACCOUNT_ID_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_USER_ACCOUNT_ID_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_USER_ACCOUNT_ID_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_EMPLOYEE_ID_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_EMPLOYEE_ID_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_REMARK_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_REMARK_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_REMARK_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_REMARK_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_USER_ACCOUNT_ID_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_USER_ACCOUNT_ID_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_USER_ACCOUNT_ID_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_USER_ACCOUNT_ID_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__COUNT_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__COUNT_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__COUNT_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__COUNT_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_EMPLOYEE_ID_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_REMARK_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_REMARK_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_REMARK_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_REMARK_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_USER_ACCOUNT_ID_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_USER_ACCOUNT_ID_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_USER_ACCOUNT_ID_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_USER_ACCOUNT_ID_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_EMPLOYEE_ID_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_REMARK_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_REMARK_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_REMARK_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_REMARK_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_USER_ACCOUNT_ID_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_USER_ACCOUNT_ID_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_USER_ACCOUNT_ID_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_USER_ACCOUNT_ID_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNTS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_CREATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_CREATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_CREATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_CREATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_UPDATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_UPDATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_UPDATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_UPDATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__EMPLOYEE_ID_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__EMPLOYEE_ID_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__EMPLOYEE_ID_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__EMPLOYEE_ID_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__REMARK_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__REMARK_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__REMARK_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__REMARK_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_ACCOUNT_ID_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_ACCOUNT_ID_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_ACCOUNT_ID_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_ACCOUNT_ID_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_CREATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_CREATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_CREATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_CREATED_DESC',
  RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_UPDATED_ASC = 'RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_UPDATED_ASC',
  RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_UPDATED_DESC = 'RELATION_EMPLOYEE_USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_UPDATED_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  ROLE_ASC = 'ROLE_ASC',
  ROLE_DESC = 'ROLE_DESC',
  TABLE_AUDITS_BY_USER_CREATED__COUNT_ASC = 'TABLE_AUDITS_BY_USER_CREATED__COUNT_ASC',
  TABLE_AUDITS_BY_USER_CREATED__COUNT_DESC = 'TABLE_AUDITS_BY_USER_CREATED__COUNT_DESC',
  TABLE_AUDITS_BY_USER_CREATED__MAX_DATA_NEW_ASC = 'TABLE_AUDITS_BY_USER_CREATED__MAX_DATA_NEW_ASC',
  TABLE_AUDITS_BY_USER_CREATED__MAX_DATA_NEW_DESC = 'TABLE_AUDITS_BY_USER_CREATED__MAX_DATA_NEW_DESC',
  TABLE_AUDITS_BY_USER_CREATED__MAX_DATA_OLD_ASC = 'TABLE_AUDITS_BY_USER_CREATED__MAX_DATA_OLD_ASC',
  TABLE_AUDITS_BY_USER_CREATED__MAX_DATA_OLD_DESC = 'TABLE_AUDITS_BY_USER_CREATED__MAX_DATA_OLD_DESC',
  TABLE_AUDITS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'TABLE_AUDITS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  TABLE_AUDITS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'TABLE_AUDITS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  TABLE_AUDITS_BY_USER_CREATED__MAX_DATE_TRANSACTION_ASC = 'TABLE_AUDITS_BY_USER_CREATED__MAX_DATE_TRANSACTION_ASC',
  TABLE_AUDITS_BY_USER_CREATED__MAX_DATE_TRANSACTION_DESC = 'TABLE_AUDITS_BY_USER_CREATED__MAX_DATE_TRANSACTION_DESC',
  TABLE_AUDITS_BY_USER_CREATED__MAX_TABLE_NAME_ASC = 'TABLE_AUDITS_BY_USER_CREATED__MAX_TABLE_NAME_ASC',
  TABLE_AUDITS_BY_USER_CREATED__MAX_TABLE_NAME_DESC = 'TABLE_AUDITS_BY_USER_CREATED__MAX_TABLE_NAME_DESC',
  TABLE_AUDITS_BY_USER_CREATED__MAX_TYPE_ASC = 'TABLE_AUDITS_BY_USER_CREATED__MAX_TYPE_ASC',
  TABLE_AUDITS_BY_USER_CREATED__MAX_TYPE_DESC = 'TABLE_AUDITS_BY_USER_CREATED__MAX_TYPE_DESC',
  TABLE_AUDITS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'TABLE_AUDITS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  TABLE_AUDITS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'TABLE_AUDITS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  TABLE_AUDITS_BY_USER_CREATED__MAX_USER_ROLE_ASC = 'TABLE_AUDITS_BY_USER_CREATED__MAX_USER_ROLE_ASC',
  TABLE_AUDITS_BY_USER_CREATED__MAX_USER_ROLE_DESC = 'TABLE_AUDITS_BY_USER_CREATED__MAX_USER_ROLE_DESC',
  TABLE_AUDITS_BY_USER_CREATED__MIN_DATA_NEW_ASC = 'TABLE_AUDITS_BY_USER_CREATED__MIN_DATA_NEW_ASC',
  TABLE_AUDITS_BY_USER_CREATED__MIN_DATA_NEW_DESC = 'TABLE_AUDITS_BY_USER_CREATED__MIN_DATA_NEW_DESC',
  TABLE_AUDITS_BY_USER_CREATED__MIN_DATA_OLD_ASC = 'TABLE_AUDITS_BY_USER_CREATED__MIN_DATA_OLD_ASC',
  TABLE_AUDITS_BY_USER_CREATED__MIN_DATA_OLD_DESC = 'TABLE_AUDITS_BY_USER_CREATED__MIN_DATA_OLD_DESC',
  TABLE_AUDITS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'TABLE_AUDITS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  TABLE_AUDITS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'TABLE_AUDITS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  TABLE_AUDITS_BY_USER_CREATED__MIN_DATE_TRANSACTION_ASC = 'TABLE_AUDITS_BY_USER_CREATED__MIN_DATE_TRANSACTION_ASC',
  TABLE_AUDITS_BY_USER_CREATED__MIN_DATE_TRANSACTION_DESC = 'TABLE_AUDITS_BY_USER_CREATED__MIN_DATE_TRANSACTION_DESC',
  TABLE_AUDITS_BY_USER_CREATED__MIN_TABLE_NAME_ASC = 'TABLE_AUDITS_BY_USER_CREATED__MIN_TABLE_NAME_ASC',
  TABLE_AUDITS_BY_USER_CREATED__MIN_TABLE_NAME_DESC = 'TABLE_AUDITS_BY_USER_CREATED__MIN_TABLE_NAME_DESC',
  TABLE_AUDITS_BY_USER_CREATED__MIN_TYPE_ASC = 'TABLE_AUDITS_BY_USER_CREATED__MIN_TYPE_ASC',
  TABLE_AUDITS_BY_USER_CREATED__MIN_TYPE_DESC = 'TABLE_AUDITS_BY_USER_CREATED__MIN_TYPE_DESC',
  TABLE_AUDITS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'TABLE_AUDITS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  TABLE_AUDITS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'TABLE_AUDITS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  TABLE_AUDITS_BY_USER_CREATED__MIN_USER_ROLE_ASC = 'TABLE_AUDITS_BY_USER_CREATED__MIN_USER_ROLE_ASC',
  TABLE_AUDITS_BY_USER_CREATED__MIN_USER_ROLE_DESC = 'TABLE_AUDITS_BY_USER_CREATED__MIN_USER_ROLE_DESC',
  USER_ACCOUNTS_BY_USER_CREATED__COUNT_ASC = 'USER_ACCOUNTS_BY_USER_CREATED__COUNT_ASC',
  USER_ACCOUNTS_BY_USER_CREATED__COUNT_DESC = 'USER_ACCOUNTS_BY_USER_CREATED__COUNT_DESC',
  USER_ACCOUNTS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'USER_ACCOUNTS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  USER_ACCOUNTS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'USER_ACCOUNTS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  USER_ACCOUNTS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'USER_ACCOUNTS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  USER_ACCOUNTS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'USER_ACCOUNTS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  USER_ACCOUNTS_BY_USER_CREATED__MAX_DISPLAY_NAME_ASC = 'USER_ACCOUNTS_BY_USER_CREATED__MAX_DISPLAY_NAME_ASC',
  USER_ACCOUNTS_BY_USER_CREATED__MAX_DISPLAY_NAME_DESC = 'USER_ACCOUNTS_BY_USER_CREATED__MAX_DISPLAY_NAME_DESC',
  USER_ACCOUNTS_BY_USER_CREATED__MAX_EMAIL_ADDRESS_ASC = 'USER_ACCOUNTS_BY_USER_CREATED__MAX_EMAIL_ADDRESS_ASC',
  USER_ACCOUNTS_BY_USER_CREATED__MAX_EMAIL_ADDRESS_DESC = 'USER_ACCOUNTS_BY_USER_CREATED__MAX_EMAIL_ADDRESS_DESC',
  USER_ACCOUNTS_BY_USER_CREATED__MAX_GOOGLE_ID_ASC = 'USER_ACCOUNTS_BY_USER_CREATED__MAX_GOOGLE_ID_ASC',
  USER_ACCOUNTS_BY_USER_CREATED__MAX_GOOGLE_ID_DESC = 'USER_ACCOUNTS_BY_USER_CREATED__MAX_GOOGLE_ID_DESC',
  USER_ACCOUNTS_BY_USER_CREATED__MAX_GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNTS_BY_USER_CREATED__MAX_GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNTS_BY_USER_CREATED__MAX_GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNTS_BY_USER_CREATED__MAX_GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNTS_BY_USER_CREATED__MAX_IS_ENABLED_ASC = 'USER_ACCOUNTS_BY_USER_CREATED__MAX_IS_ENABLED_ASC',
  USER_ACCOUNTS_BY_USER_CREATED__MAX_IS_ENABLED_DESC = 'USER_ACCOUNTS_BY_USER_CREATED__MAX_IS_ENABLED_DESC',
  USER_ACCOUNTS_BY_USER_CREATED__MAX_REMARK_ASC = 'USER_ACCOUNTS_BY_USER_CREATED__MAX_REMARK_ASC',
  USER_ACCOUNTS_BY_USER_CREATED__MAX_REMARK_DESC = 'USER_ACCOUNTS_BY_USER_CREATED__MAX_REMARK_DESC',
  USER_ACCOUNTS_BY_USER_CREATED__MAX_ROLE_ASC = 'USER_ACCOUNTS_BY_USER_CREATED__MAX_ROLE_ASC',
  USER_ACCOUNTS_BY_USER_CREATED__MAX_ROLE_DESC = 'USER_ACCOUNTS_BY_USER_CREATED__MAX_ROLE_DESC',
  USER_ACCOUNTS_BY_USER_CREATED__MAX_USER_ACCOUNT_ID_ASC = 'USER_ACCOUNTS_BY_USER_CREATED__MAX_USER_ACCOUNT_ID_ASC',
  USER_ACCOUNTS_BY_USER_CREATED__MAX_USER_ACCOUNT_ID_DESC = 'USER_ACCOUNTS_BY_USER_CREATED__MAX_USER_ACCOUNT_ID_DESC',
  USER_ACCOUNTS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'USER_ACCOUNTS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  USER_ACCOUNTS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'USER_ACCOUNTS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  USER_ACCOUNTS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'USER_ACCOUNTS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  USER_ACCOUNTS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'USER_ACCOUNTS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  USER_ACCOUNTS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'USER_ACCOUNTS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  USER_ACCOUNTS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'USER_ACCOUNTS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  USER_ACCOUNTS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'USER_ACCOUNTS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  USER_ACCOUNTS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'USER_ACCOUNTS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  USER_ACCOUNTS_BY_USER_CREATED__MIN_DISPLAY_NAME_ASC = 'USER_ACCOUNTS_BY_USER_CREATED__MIN_DISPLAY_NAME_ASC',
  USER_ACCOUNTS_BY_USER_CREATED__MIN_DISPLAY_NAME_DESC = 'USER_ACCOUNTS_BY_USER_CREATED__MIN_DISPLAY_NAME_DESC',
  USER_ACCOUNTS_BY_USER_CREATED__MIN_EMAIL_ADDRESS_ASC = 'USER_ACCOUNTS_BY_USER_CREATED__MIN_EMAIL_ADDRESS_ASC',
  USER_ACCOUNTS_BY_USER_CREATED__MIN_EMAIL_ADDRESS_DESC = 'USER_ACCOUNTS_BY_USER_CREATED__MIN_EMAIL_ADDRESS_DESC',
  USER_ACCOUNTS_BY_USER_CREATED__MIN_GOOGLE_ID_ASC = 'USER_ACCOUNTS_BY_USER_CREATED__MIN_GOOGLE_ID_ASC',
  USER_ACCOUNTS_BY_USER_CREATED__MIN_GOOGLE_ID_DESC = 'USER_ACCOUNTS_BY_USER_CREATED__MIN_GOOGLE_ID_DESC',
  USER_ACCOUNTS_BY_USER_CREATED__MIN_GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNTS_BY_USER_CREATED__MIN_GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNTS_BY_USER_CREATED__MIN_GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNTS_BY_USER_CREATED__MIN_GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNTS_BY_USER_CREATED__MIN_IS_ENABLED_ASC = 'USER_ACCOUNTS_BY_USER_CREATED__MIN_IS_ENABLED_ASC',
  USER_ACCOUNTS_BY_USER_CREATED__MIN_IS_ENABLED_DESC = 'USER_ACCOUNTS_BY_USER_CREATED__MIN_IS_ENABLED_DESC',
  USER_ACCOUNTS_BY_USER_CREATED__MIN_REMARK_ASC = 'USER_ACCOUNTS_BY_USER_CREATED__MIN_REMARK_ASC',
  USER_ACCOUNTS_BY_USER_CREATED__MIN_REMARK_DESC = 'USER_ACCOUNTS_BY_USER_CREATED__MIN_REMARK_DESC',
  USER_ACCOUNTS_BY_USER_CREATED__MIN_ROLE_ASC = 'USER_ACCOUNTS_BY_USER_CREATED__MIN_ROLE_ASC',
  USER_ACCOUNTS_BY_USER_CREATED__MIN_ROLE_DESC = 'USER_ACCOUNTS_BY_USER_CREATED__MIN_ROLE_DESC',
  USER_ACCOUNTS_BY_USER_CREATED__MIN_USER_ACCOUNT_ID_ASC = 'USER_ACCOUNTS_BY_USER_CREATED__MIN_USER_ACCOUNT_ID_ASC',
  USER_ACCOUNTS_BY_USER_CREATED__MIN_USER_ACCOUNT_ID_DESC = 'USER_ACCOUNTS_BY_USER_CREATED__MIN_USER_ACCOUNT_ID_DESC',
  USER_ACCOUNTS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'USER_ACCOUNTS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  USER_ACCOUNTS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'USER_ACCOUNTS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  USER_ACCOUNTS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'USER_ACCOUNTS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  USER_ACCOUNTS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'USER_ACCOUNTS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  USER_ACCOUNTS_BY_USER_UPDATED__COUNT_ASC = 'USER_ACCOUNTS_BY_USER_UPDATED__COUNT_ASC',
  USER_ACCOUNTS_BY_USER_UPDATED__COUNT_DESC = 'USER_ACCOUNTS_BY_USER_UPDATED__COUNT_DESC',
  USER_ACCOUNTS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'USER_ACCOUNTS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  USER_ACCOUNTS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'USER_ACCOUNTS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  USER_ACCOUNTS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'USER_ACCOUNTS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  USER_ACCOUNTS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'USER_ACCOUNTS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  USER_ACCOUNTS_BY_USER_UPDATED__MAX_DISPLAY_NAME_ASC = 'USER_ACCOUNTS_BY_USER_UPDATED__MAX_DISPLAY_NAME_ASC',
  USER_ACCOUNTS_BY_USER_UPDATED__MAX_DISPLAY_NAME_DESC = 'USER_ACCOUNTS_BY_USER_UPDATED__MAX_DISPLAY_NAME_DESC',
  USER_ACCOUNTS_BY_USER_UPDATED__MAX_EMAIL_ADDRESS_ASC = 'USER_ACCOUNTS_BY_USER_UPDATED__MAX_EMAIL_ADDRESS_ASC',
  USER_ACCOUNTS_BY_USER_UPDATED__MAX_EMAIL_ADDRESS_DESC = 'USER_ACCOUNTS_BY_USER_UPDATED__MAX_EMAIL_ADDRESS_DESC',
  USER_ACCOUNTS_BY_USER_UPDATED__MAX_GOOGLE_ID_ASC = 'USER_ACCOUNTS_BY_USER_UPDATED__MAX_GOOGLE_ID_ASC',
  USER_ACCOUNTS_BY_USER_UPDATED__MAX_GOOGLE_ID_DESC = 'USER_ACCOUNTS_BY_USER_UPDATED__MAX_GOOGLE_ID_DESC',
  USER_ACCOUNTS_BY_USER_UPDATED__MAX_GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNTS_BY_USER_UPDATED__MAX_GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNTS_BY_USER_UPDATED__MAX_GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNTS_BY_USER_UPDATED__MAX_GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNTS_BY_USER_UPDATED__MAX_IS_ENABLED_ASC = 'USER_ACCOUNTS_BY_USER_UPDATED__MAX_IS_ENABLED_ASC',
  USER_ACCOUNTS_BY_USER_UPDATED__MAX_IS_ENABLED_DESC = 'USER_ACCOUNTS_BY_USER_UPDATED__MAX_IS_ENABLED_DESC',
  USER_ACCOUNTS_BY_USER_UPDATED__MAX_REMARK_ASC = 'USER_ACCOUNTS_BY_USER_UPDATED__MAX_REMARK_ASC',
  USER_ACCOUNTS_BY_USER_UPDATED__MAX_REMARK_DESC = 'USER_ACCOUNTS_BY_USER_UPDATED__MAX_REMARK_DESC',
  USER_ACCOUNTS_BY_USER_UPDATED__MAX_ROLE_ASC = 'USER_ACCOUNTS_BY_USER_UPDATED__MAX_ROLE_ASC',
  USER_ACCOUNTS_BY_USER_UPDATED__MAX_ROLE_DESC = 'USER_ACCOUNTS_BY_USER_UPDATED__MAX_ROLE_DESC',
  USER_ACCOUNTS_BY_USER_UPDATED__MAX_USER_ACCOUNT_ID_ASC = 'USER_ACCOUNTS_BY_USER_UPDATED__MAX_USER_ACCOUNT_ID_ASC',
  USER_ACCOUNTS_BY_USER_UPDATED__MAX_USER_ACCOUNT_ID_DESC = 'USER_ACCOUNTS_BY_USER_UPDATED__MAX_USER_ACCOUNT_ID_DESC',
  USER_ACCOUNTS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'USER_ACCOUNTS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  USER_ACCOUNTS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'USER_ACCOUNTS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  USER_ACCOUNTS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'USER_ACCOUNTS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  USER_ACCOUNTS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'USER_ACCOUNTS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  USER_ACCOUNTS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'USER_ACCOUNTS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  USER_ACCOUNTS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'USER_ACCOUNTS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  USER_ACCOUNTS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'USER_ACCOUNTS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  USER_ACCOUNTS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'USER_ACCOUNTS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  USER_ACCOUNTS_BY_USER_UPDATED__MIN_DISPLAY_NAME_ASC = 'USER_ACCOUNTS_BY_USER_UPDATED__MIN_DISPLAY_NAME_ASC',
  USER_ACCOUNTS_BY_USER_UPDATED__MIN_DISPLAY_NAME_DESC = 'USER_ACCOUNTS_BY_USER_UPDATED__MIN_DISPLAY_NAME_DESC',
  USER_ACCOUNTS_BY_USER_UPDATED__MIN_EMAIL_ADDRESS_ASC = 'USER_ACCOUNTS_BY_USER_UPDATED__MIN_EMAIL_ADDRESS_ASC',
  USER_ACCOUNTS_BY_USER_UPDATED__MIN_EMAIL_ADDRESS_DESC = 'USER_ACCOUNTS_BY_USER_UPDATED__MIN_EMAIL_ADDRESS_DESC',
  USER_ACCOUNTS_BY_USER_UPDATED__MIN_GOOGLE_ID_ASC = 'USER_ACCOUNTS_BY_USER_UPDATED__MIN_GOOGLE_ID_ASC',
  USER_ACCOUNTS_BY_USER_UPDATED__MIN_GOOGLE_ID_DESC = 'USER_ACCOUNTS_BY_USER_UPDATED__MIN_GOOGLE_ID_DESC',
  USER_ACCOUNTS_BY_USER_UPDATED__MIN_GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNTS_BY_USER_UPDATED__MIN_GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNTS_BY_USER_UPDATED__MIN_GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNTS_BY_USER_UPDATED__MIN_GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNTS_BY_USER_UPDATED__MIN_IS_ENABLED_ASC = 'USER_ACCOUNTS_BY_USER_UPDATED__MIN_IS_ENABLED_ASC',
  USER_ACCOUNTS_BY_USER_UPDATED__MIN_IS_ENABLED_DESC = 'USER_ACCOUNTS_BY_USER_UPDATED__MIN_IS_ENABLED_DESC',
  USER_ACCOUNTS_BY_USER_UPDATED__MIN_REMARK_ASC = 'USER_ACCOUNTS_BY_USER_UPDATED__MIN_REMARK_ASC',
  USER_ACCOUNTS_BY_USER_UPDATED__MIN_REMARK_DESC = 'USER_ACCOUNTS_BY_USER_UPDATED__MIN_REMARK_DESC',
  USER_ACCOUNTS_BY_USER_UPDATED__MIN_ROLE_ASC = 'USER_ACCOUNTS_BY_USER_UPDATED__MIN_ROLE_ASC',
  USER_ACCOUNTS_BY_USER_UPDATED__MIN_ROLE_DESC = 'USER_ACCOUNTS_BY_USER_UPDATED__MIN_ROLE_DESC',
  USER_ACCOUNTS_BY_USER_UPDATED__MIN_USER_ACCOUNT_ID_ASC = 'USER_ACCOUNTS_BY_USER_UPDATED__MIN_USER_ACCOUNT_ID_ASC',
  USER_ACCOUNTS_BY_USER_UPDATED__MIN_USER_ACCOUNT_ID_DESC = 'USER_ACCOUNTS_BY_USER_UPDATED__MIN_USER_ACCOUNT_ID_DESC',
  USER_ACCOUNTS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'USER_ACCOUNTS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  USER_ACCOUNTS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'USER_ACCOUNTS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  USER_ACCOUNTS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'USER_ACCOUNTS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  USER_ACCOUNTS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'USER_ACCOUNTS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__COUNT_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__COUNT_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__COUNT_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__COUNT_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_DATE_CREATED_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_DATE_CREATED_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_DATE_CREATED_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_DATE_CREATED_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_GEOIP_CITY_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_GEOIP_CITY_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_GEOIP_CITY_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_GEOIP_CITY_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_GEOIP_COUNTRY_CODE_ALPHA2_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_GEOIP_COUNTRY_CODE_ALPHA2_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_GEOIP_COUNTRY_CODE_ALPHA2_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_GEOIP_COUNTRY_CODE_ALPHA2_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_GEOIP_LAT_LNG_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_GEOIP_LAT_LNG_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_GEOIP_LAT_LNG_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_GEOIP_LAT_LNG_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_GEOIP_REGION_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_GEOIP_REGION_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_GEOIP_REGION_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_GEOIP_REGION_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_GEOIP_TIMEZONE_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_GEOIP_TIMEZONE_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_GEOIP_TIMEZONE_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_GEOIP_TIMEZONE_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_HOST_NAME_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_HOST_NAME_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_HOST_NAME_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_HOST_NAME_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_IP_ADDRESS_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_IP_ADDRESS_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_IP_ADDRESS_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_IP_ADDRESS_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_LANGUAGES_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_LANGUAGES_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_LANGUAGES_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_LANGUAGES_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_REFERRER_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_REFERRER_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_REFERRER_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_REFERRER_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_REMARK_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_REMARK_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_REMARK_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_REMARK_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_TIMEZONE_OFFSET_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_TIMEZONE_OFFSET_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_TIMEZONE_OFFSET_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_TIMEZONE_OFFSET_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_USER_ACCOUNT_ACCESS_HISTORY_ID_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_USER_ACCOUNT_ACCESS_HISTORY_ID_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_USER_ACCOUNT_ACCESS_HISTORY_ID_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_USER_ACCOUNT_ACCESS_HISTORY_ID_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_USER_AGENT_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_USER_AGENT_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_USER_AGENT_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MAX_USER_AGENT_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_DATE_CREATED_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_DATE_CREATED_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_DATE_CREATED_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_DATE_CREATED_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_GEOIP_CITY_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_GEOIP_CITY_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_GEOIP_CITY_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_GEOIP_CITY_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_GEOIP_COUNTRY_CODE_ALPHA2_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_GEOIP_COUNTRY_CODE_ALPHA2_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_GEOIP_COUNTRY_CODE_ALPHA2_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_GEOIP_COUNTRY_CODE_ALPHA2_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_GEOIP_LAT_LNG_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_GEOIP_LAT_LNG_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_GEOIP_LAT_LNG_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_GEOIP_LAT_LNG_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_GEOIP_REGION_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_GEOIP_REGION_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_GEOIP_REGION_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_GEOIP_REGION_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_GEOIP_TIMEZONE_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_GEOIP_TIMEZONE_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_GEOIP_TIMEZONE_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_GEOIP_TIMEZONE_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_HOST_NAME_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_HOST_NAME_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_HOST_NAME_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_HOST_NAME_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_IP_ADDRESS_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_IP_ADDRESS_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_IP_ADDRESS_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_IP_ADDRESS_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_LANGUAGES_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_LANGUAGES_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_LANGUAGES_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_LANGUAGES_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_REFERRER_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_REFERRER_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_REFERRER_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_REFERRER_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_REMARK_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_REMARK_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_REMARK_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_REMARK_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_TIMEZONE_OFFSET_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_TIMEZONE_OFFSET_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_TIMEZONE_OFFSET_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_TIMEZONE_OFFSET_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_USER_ACCOUNT_ACCESS_HISTORY_ID_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_USER_ACCOUNT_ACCESS_HISTORY_ID_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_USER_ACCOUNT_ACCESS_HISTORY_ID_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_USER_ACCOUNT_ACCESS_HISTORY_ID_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_USER_AGENT_ASC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_USER_AGENT_ASC',
  USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_USER_AGENT_DESC = 'USER_ACCOUNT_ACCESS_HISTORIES_BY_USER_ACCOUNT_ID__MIN_USER_AGENT_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__COUNT_ASC = 'USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__COUNT_ASC',
  USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__COUNT_DESC = 'USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__COUNT_DESC',
  USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MAX_DATE_CREATED_ASC = 'USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MAX_DATE_CREATED_ASC',
  USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MAX_DATE_CREATED_DESC = 'USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MAX_DATE_CREATED_DESC',
  USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MAX_PIN_ASC = 'USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MAX_PIN_ASC',
  USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MAX_PIN_DESC = 'USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MAX_PIN_DESC',
  USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MAX_REF_ASC = 'USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MAX_REF_ASC',
  USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MAX_REF_DESC = 'USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MAX_REF_DESC',
  USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MAX_REMARK_ASC = 'USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MAX_REMARK_ASC',
  USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MAX_REMARK_DESC = 'USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MAX_REMARK_DESC',
  USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MAX_USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MAX_USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MAX_USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MAX_USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MIN_DATE_CREATED_ASC = 'USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MIN_DATE_CREATED_ASC',
  USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MIN_DATE_CREATED_DESC = 'USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MIN_DATE_CREATED_DESC',
  USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MIN_PIN_ASC = 'USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MIN_PIN_ASC',
  USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MIN_PIN_DESC = 'USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MIN_PIN_DESC',
  USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MIN_REF_ASC = 'USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MIN_REF_ASC',
  USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MIN_REF_DESC = 'USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MIN_REF_DESC',
  USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MIN_REMARK_ASC = 'USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MIN_REMARK_ASC',
  USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MIN_REMARK_DESC = 'USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MIN_REMARK_DESC',
  USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MIN_USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MIN_USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MIN_USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_PINS_BY_USER_ACCOUNT_ID__MIN_USER_ACCOUNT_ID_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__COUNT_ASC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__COUNT_ASC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__COUNT_DESC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__COUNT_DESC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_DATE_CREATED_ASC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_DATE_CREATED_ASC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_DATE_CREATED_DESC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_DATE_CREATED_DESC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_DATE_UPDATED_ASC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_DATE_UPDATED_ASC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_DATE_UPDATED_DESC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_DATE_UPDATED_DESC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_REASON_ASC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_REASON_ASC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_REASON_DESC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_REASON_DESC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_REMARK_ASC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_REMARK_ASC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_REMARK_DESC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_REMARK_DESC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_USER_ACCOUNT_ID_ASC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_USER_ACCOUNT_ID_ASC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_USER_ACCOUNT_ID_DESC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_USER_ACCOUNT_ID_DESC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_USER_CREATED_ASC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_USER_CREATED_ASC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_USER_CREATED_DESC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_USER_CREATED_DESC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_USER_FORCE_LOGOUT_ID_ASC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_USER_FORCE_LOGOUT_ID_ASC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_USER_FORCE_LOGOUT_ID_DESC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_USER_FORCE_LOGOUT_ID_DESC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_USER_UPDATED_ASC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_USER_UPDATED_ASC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_USER_UPDATED_DESC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MAX_USER_UPDATED_DESC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_DATE_CREATED_ASC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_DATE_CREATED_ASC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_DATE_CREATED_DESC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_DATE_CREATED_DESC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_DATE_UPDATED_ASC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_DATE_UPDATED_ASC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_DATE_UPDATED_DESC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_DATE_UPDATED_DESC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_REASON_ASC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_REASON_ASC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_REASON_DESC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_REASON_DESC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_REMARK_ASC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_REMARK_ASC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_REMARK_DESC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_REMARK_DESC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_USER_ACCOUNT_ID_ASC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_USER_ACCOUNT_ID_ASC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_USER_ACCOUNT_ID_DESC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_USER_ACCOUNT_ID_DESC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_USER_CREATED_ASC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_USER_CREATED_ASC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_USER_CREATED_DESC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_USER_CREATED_DESC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_USER_FORCE_LOGOUT_ID_ASC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_USER_FORCE_LOGOUT_ID_ASC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_USER_FORCE_LOGOUT_ID_DESC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_USER_FORCE_LOGOUT_ID_DESC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_USER_UPDATED_ASC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_USER_UPDATED_ASC',
  USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_USER_UPDATED_DESC = 'USER_FORCE_LOGOUTS_BY_USER_ACCOUNT_ID__MIN_USER_UPDATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__COUNT_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__COUNT_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__COUNT_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__COUNT_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_COMPANY_ID_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_COMPANY_ID_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_COMPANY_ID_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_COMPANY_ID_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_DATE_CREATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_DATE_CREATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_DATE_CREATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_DATE_CREATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_DATE_UPDATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_DATE_UPDATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_DATE_UPDATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_DATE_UPDATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_REMARK_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_REMARK_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_REMARK_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_REMARK_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_USER_ACCOUNT_ID_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_USER_ACCOUNT_ID_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_USER_ACCOUNT_ID_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_USER_ACCOUNT_ID_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_USER_CREATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_USER_CREATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_USER_CREATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_USER_CREATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_USER_HR_MANAGING_COMPANY_ID_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_USER_HR_MANAGING_COMPANY_ID_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_USER_HR_MANAGING_COMPANY_ID_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_USER_HR_MANAGING_COMPANY_ID_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_USER_UPDATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_USER_UPDATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_USER_UPDATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MAX_USER_UPDATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_COMPANY_ID_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_COMPANY_ID_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_COMPANY_ID_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_COMPANY_ID_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_DATE_CREATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_DATE_CREATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_DATE_CREATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_DATE_CREATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_DATE_UPDATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_DATE_UPDATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_DATE_UPDATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_DATE_UPDATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_REMARK_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_REMARK_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_REMARK_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_REMARK_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_USER_ACCOUNT_ID_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_USER_ACCOUNT_ID_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_USER_ACCOUNT_ID_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_USER_ACCOUNT_ID_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_USER_CREATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_USER_CREATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_USER_CREATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_USER_CREATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_USER_HR_MANAGING_COMPANY_ID_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_USER_HR_MANAGING_COMPANY_ID_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_USER_HR_MANAGING_COMPANY_ID_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_USER_HR_MANAGING_COMPANY_ID_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_USER_UPDATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_USER_UPDATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_USER_UPDATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_ACCOUNT_ID__MIN_USER_UPDATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__COUNT_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__COUNT_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__COUNT_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__COUNT_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_COMPANY_ID_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_COMPANY_ID_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_COMPANY_ID_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_COMPANY_ID_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_REMARK_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_REMARK_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_REMARK_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_REMARK_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_USER_ACCOUNT_ID_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_USER_ACCOUNT_ID_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_USER_ACCOUNT_ID_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_USER_ACCOUNT_ID_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_USER_HR_MANAGING_COMPANY_ID_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_USER_HR_MANAGING_COMPANY_ID_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_USER_HR_MANAGING_COMPANY_ID_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_USER_HR_MANAGING_COMPANY_ID_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_COMPANY_ID_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_COMPANY_ID_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_COMPANY_ID_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_COMPANY_ID_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_REMARK_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_REMARK_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_REMARK_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_REMARK_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_USER_ACCOUNT_ID_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_USER_ACCOUNT_ID_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_USER_ACCOUNT_ID_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_USER_ACCOUNT_ID_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_USER_HR_MANAGING_COMPANY_ID_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_USER_HR_MANAGING_COMPANY_ID_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_USER_HR_MANAGING_COMPANY_ID_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_USER_HR_MANAGING_COMPANY_ID_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__COUNT_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__COUNT_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__COUNT_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__COUNT_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_COMPANY_ID_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_COMPANY_ID_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_COMPANY_ID_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_COMPANY_ID_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_REMARK_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_REMARK_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_REMARK_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_REMARK_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_USER_ACCOUNT_ID_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_USER_ACCOUNT_ID_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_USER_ACCOUNT_ID_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_USER_ACCOUNT_ID_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_USER_HR_MANAGING_COMPANY_ID_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_USER_HR_MANAGING_COMPANY_ID_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_USER_HR_MANAGING_COMPANY_ID_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_USER_HR_MANAGING_COMPANY_ID_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_COMPANY_ID_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_COMPANY_ID_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_COMPANY_ID_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_COMPANY_ID_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_REMARK_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_REMARK_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_REMARK_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_REMARK_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_USER_ACCOUNT_ID_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_USER_ACCOUNT_ID_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_USER_ACCOUNT_ID_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_USER_ACCOUNT_ID_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_USER_HR_MANAGING_COMPANY_ID_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_USER_HR_MANAGING_COMPANY_ID_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_USER_HR_MANAGING_COMPANY_ID_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_USER_HR_MANAGING_COMPANY_ID_DESC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'USER_HR_MANAGING_COMPANIES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  USER_ROLE_BY_ROLE__DISPLAY_NAME_ASC = 'USER_ROLE_BY_ROLE__DISPLAY_NAME_ASC',
  USER_ROLE_BY_ROLE__DISPLAY_NAME_DESC = 'USER_ROLE_BY_ROLE__DISPLAY_NAME_DESC',
  USER_ROLE_BY_ROLE__IS_DEFAULT_ROLE_ASC = 'USER_ROLE_BY_ROLE__IS_DEFAULT_ROLE_ASC',
  USER_ROLE_BY_ROLE__IS_DEFAULT_ROLE_DESC = 'USER_ROLE_BY_ROLE__IS_DEFAULT_ROLE_DESC',
  USER_ROLE_BY_ROLE__PRIORITY_ASC = 'USER_ROLE_BY_ROLE__PRIORITY_ASC',
  USER_ROLE_BY_ROLE__PRIORITY_DESC = 'USER_ROLE_BY_ROLE__PRIORITY_DESC',
  USER_ROLE_BY_ROLE__ROLE_NAME_ASC = 'USER_ROLE_BY_ROLE__ROLE_NAME_ASC',
  USER_ROLE_BY_ROLE__ROLE_NAME_DESC = 'USER_ROLE_BY_ROLE__ROLE_NAME_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
  WORLD_PLACES_BY_USER_CREATED__COUNT_ASC = 'WORLD_PLACES_BY_USER_CREATED__COUNT_ASC',
  WORLD_PLACES_BY_USER_CREATED__COUNT_DESC = 'WORLD_PLACES_BY_USER_CREATED__COUNT_DESC',
  WORLD_PLACES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA2_ASC = 'WORLD_PLACES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA2_ASC',
  WORLD_PLACES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA2_DESC = 'WORLD_PLACES_BY_USER_CREATED__MAX_COUNTRY_CODE_ALPHA2_DESC',
  WORLD_PLACES_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'WORLD_PLACES_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  WORLD_PLACES_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'WORLD_PLACES_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  WORLD_PLACES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'WORLD_PLACES_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  WORLD_PLACES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'WORLD_PLACES_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  WORLD_PLACES_BY_USER_CREATED__MAX_DISPLAY_ORDER_ASC = 'WORLD_PLACES_BY_USER_CREATED__MAX_DISPLAY_ORDER_ASC',
  WORLD_PLACES_BY_USER_CREATED__MAX_DISPLAY_ORDER_DESC = 'WORLD_PLACES_BY_USER_CREATED__MAX_DISPLAY_ORDER_DESC',
  WORLD_PLACES_BY_USER_CREATED__MAX_LATITUDE_ASC = 'WORLD_PLACES_BY_USER_CREATED__MAX_LATITUDE_ASC',
  WORLD_PLACES_BY_USER_CREATED__MAX_LATITUDE_DESC = 'WORLD_PLACES_BY_USER_CREATED__MAX_LATITUDE_DESC',
  WORLD_PLACES_BY_USER_CREATED__MAX_LONGITUDE_ASC = 'WORLD_PLACES_BY_USER_CREATED__MAX_LONGITUDE_ASC',
  WORLD_PLACES_BY_USER_CREATED__MAX_LONGITUDE_DESC = 'WORLD_PLACES_BY_USER_CREATED__MAX_LONGITUDE_DESC',
  WORLD_PLACES_BY_USER_CREATED__MAX_NAME_ASC = 'WORLD_PLACES_BY_USER_CREATED__MAX_NAME_ASC',
  WORLD_PLACES_BY_USER_CREATED__MAX_NAME_DESC = 'WORLD_PLACES_BY_USER_CREATED__MAX_NAME_DESC',
  WORLD_PLACES_BY_USER_CREATED__MAX_REMARK_ASC = 'WORLD_PLACES_BY_USER_CREATED__MAX_REMARK_ASC',
  WORLD_PLACES_BY_USER_CREATED__MAX_REMARK_DESC = 'WORLD_PLACES_BY_USER_CREATED__MAX_REMARK_DESC',
  WORLD_PLACES_BY_USER_CREATED__MAX_TIMEZONE_ASC = 'WORLD_PLACES_BY_USER_CREATED__MAX_TIMEZONE_ASC',
  WORLD_PLACES_BY_USER_CREATED__MAX_TIMEZONE_DESC = 'WORLD_PLACES_BY_USER_CREATED__MAX_TIMEZONE_DESC',
  WORLD_PLACES_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'WORLD_PLACES_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  WORLD_PLACES_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'WORLD_PLACES_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  WORLD_PLACES_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'WORLD_PLACES_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  WORLD_PLACES_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'WORLD_PLACES_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  WORLD_PLACES_BY_USER_CREATED__MAX_WORLD_PLACE_ID_ASC = 'WORLD_PLACES_BY_USER_CREATED__MAX_WORLD_PLACE_ID_ASC',
  WORLD_PLACES_BY_USER_CREATED__MAX_WORLD_PLACE_ID_DESC = 'WORLD_PLACES_BY_USER_CREATED__MAX_WORLD_PLACE_ID_DESC',
  WORLD_PLACES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA2_ASC = 'WORLD_PLACES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA2_ASC',
  WORLD_PLACES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA2_DESC = 'WORLD_PLACES_BY_USER_CREATED__MIN_COUNTRY_CODE_ALPHA2_DESC',
  WORLD_PLACES_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'WORLD_PLACES_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  WORLD_PLACES_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'WORLD_PLACES_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  WORLD_PLACES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'WORLD_PLACES_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  WORLD_PLACES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'WORLD_PLACES_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  WORLD_PLACES_BY_USER_CREATED__MIN_DISPLAY_ORDER_ASC = 'WORLD_PLACES_BY_USER_CREATED__MIN_DISPLAY_ORDER_ASC',
  WORLD_PLACES_BY_USER_CREATED__MIN_DISPLAY_ORDER_DESC = 'WORLD_PLACES_BY_USER_CREATED__MIN_DISPLAY_ORDER_DESC',
  WORLD_PLACES_BY_USER_CREATED__MIN_LATITUDE_ASC = 'WORLD_PLACES_BY_USER_CREATED__MIN_LATITUDE_ASC',
  WORLD_PLACES_BY_USER_CREATED__MIN_LATITUDE_DESC = 'WORLD_PLACES_BY_USER_CREATED__MIN_LATITUDE_DESC',
  WORLD_PLACES_BY_USER_CREATED__MIN_LONGITUDE_ASC = 'WORLD_PLACES_BY_USER_CREATED__MIN_LONGITUDE_ASC',
  WORLD_PLACES_BY_USER_CREATED__MIN_LONGITUDE_DESC = 'WORLD_PLACES_BY_USER_CREATED__MIN_LONGITUDE_DESC',
  WORLD_PLACES_BY_USER_CREATED__MIN_NAME_ASC = 'WORLD_PLACES_BY_USER_CREATED__MIN_NAME_ASC',
  WORLD_PLACES_BY_USER_CREATED__MIN_NAME_DESC = 'WORLD_PLACES_BY_USER_CREATED__MIN_NAME_DESC',
  WORLD_PLACES_BY_USER_CREATED__MIN_REMARK_ASC = 'WORLD_PLACES_BY_USER_CREATED__MIN_REMARK_ASC',
  WORLD_PLACES_BY_USER_CREATED__MIN_REMARK_DESC = 'WORLD_PLACES_BY_USER_CREATED__MIN_REMARK_DESC',
  WORLD_PLACES_BY_USER_CREATED__MIN_TIMEZONE_ASC = 'WORLD_PLACES_BY_USER_CREATED__MIN_TIMEZONE_ASC',
  WORLD_PLACES_BY_USER_CREATED__MIN_TIMEZONE_DESC = 'WORLD_PLACES_BY_USER_CREATED__MIN_TIMEZONE_DESC',
  WORLD_PLACES_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'WORLD_PLACES_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  WORLD_PLACES_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'WORLD_PLACES_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  WORLD_PLACES_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'WORLD_PLACES_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  WORLD_PLACES_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'WORLD_PLACES_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  WORLD_PLACES_BY_USER_CREATED__MIN_WORLD_PLACE_ID_ASC = 'WORLD_PLACES_BY_USER_CREATED__MIN_WORLD_PLACE_ID_ASC',
  WORLD_PLACES_BY_USER_CREATED__MIN_WORLD_PLACE_ID_DESC = 'WORLD_PLACES_BY_USER_CREATED__MIN_WORLD_PLACE_ID_DESC',
  WORLD_PLACES_BY_USER_UPDATED__COUNT_ASC = 'WORLD_PLACES_BY_USER_UPDATED__COUNT_ASC',
  WORLD_PLACES_BY_USER_UPDATED__COUNT_DESC = 'WORLD_PLACES_BY_USER_UPDATED__COUNT_DESC',
  WORLD_PLACES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA2_ASC = 'WORLD_PLACES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA2_ASC',
  WORLD_PLACES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA2_DESC = 'WORLD_PLACES_BY_USER_UPDATED__MAX_COUNTRY_CODE_ALPHA2_DESC',
  WORLD_PLACES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'WORLD_PLACES_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  WORLD_PLACES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'WORLD_PLACES_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  WORLD_PLACES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'WORLD_PLACES_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  WORLD_PLACES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'WORLD_PLACES_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  WORLD_PLACES_BY_USER_UPDATED__MAX_DISPLAY_ORDER_ASC = 'WORLD_PLACES_BY_USER_UPDATED__MAX_DISPLAY_ORDER_ASC',
  WORLD_PLACES_BY_USER_UPDATED__MAX_DISPLAY_ORDER_DESC = 'WORLD_PLACES_BY_USER_UPDATED__MAX_DISPLAY_ORDER_DESC',
  WORLD_PLACES_BY_USER_UPDATED__MAX_LATITUDE_ASC = 'WORLD_PLACES_BY_USER_UPDATED__MAX_LATITUDE_ASC',
  WORLD_PLACES_BY_USER_UPDATED__MAX_LATITUDE_DESC = 'WORLD_PLACES_BY_USER_UPDATED__MAX_LATITUDE_DESC',
  WORLD_PLACES_BY_USER_UPDATED__MAX_LONGITUDE_ASC = 'WORLD_PLACES_BY_USER_UPDATED__MAX_LONGITUDE_ASC',
  WORLD_PLACES_BY_USER_UPDATED__MAX_LONGITUDE_DESC = 'WORLD_PLACES_BY_USER_UPDATED__MAX_LONGITUDE_DESC',
  WORLD_PLACES_BY_USER_UPDATED__MAX_NAME_ASC = 'WORLD_PLACES_BY_USER_UPDATED__MAX_NAME_ASC',
  WORLD_PLACES_BY_USER_UPDATED__MAX_NAME_DESC = 'WORLD_PLACES_BY_USER_UPDATED__MAX_NAME_DESC',
  WORLD_PLACES_BY_USER_UPDATED__MAX_REMARK_ASC = 'WORLD_PLACES_BY_USER_UPDATED__MAX_REMARK_ASC',
  WORLD_PLACES_BY_USER_UPDATED__MAX_REMARK_DESC = 'WORLD_PLACES_BY_USER_UPDATED__MAX_REMARK_DESC',
  WORLD_PLACES_BY_USER_UPDATED__MAX_TIMEZONE_ASC = 'WORLD_PLACES_BY_USER_UPDATED__MAX_TIMEZONE_ASC',
  WORLD_PLACES_BY_USER_UPDATED__MAX_TIMEZONE_DESC = 'WORLD_PLACES_BY_USER_UPDATED__MAX_TIMEZONE_DESC',
  WORLD_PLACES_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'WORLD_PLACES_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  WORLD_PLACES_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'WORLD_PLACES_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  WORLD_PLACES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'WORLD_PLACES_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  WORLD_PLACES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'WORLD_PLACES_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  WORLD_PLACES_BY_USER_UPDATED__MAX_WORLD_PLACE_ID_ASC = 'WORLD_PLACES_BY_USER_UPDATED__MAX_WORLD_PLACE_ID_ASC',
  WORLD_PLACES_BY_USER_UPDATED__MAX_WORLD_PLACE_ID_DESC = 'WORLD_PLACES_BY_USER_UPDATED__MAX_WORLD_PLACE_ID_DESC',
  WORLD_PLACES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA2_ASC = 'WORLD_PLACES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA2_ASC',
  WORLD_PLACES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA2_DESC = 'WORLD_PLACES_BY_USER_UPDATED__MIN_COUNTRY_CODE_ALPHA2_DESC',
  WORLD_PLACES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'WORLD_PLACES_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  WORLD_PLACES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'WORLD_PLACES_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  WORLD_PLACES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'WORLD_PLACES_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  WORLD_PLACES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'WORLD_PLACES_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  WORLD_PLACES_BY_USER_UPDATED__MIN_DISPLAY_ORDER_ASC = 'WORLD_PLACES_BY_USER_UPDATED__MIN_DISPLAY_ORDER_ASC',
  WORLD_PLACES_BY_USER_UPDATED__MIN_DISPLAY_ORDER_DESC = 'WORLD_PLACES_BY_USER_UPDATED__MIN_DISPLAY_ORDER_DESC',
  WORLD_PLACES_BY_USER_UPDATED__MIN_LATITUDE_ASC = 'WORLD_PLACES_BY_USER_UPDATED__MIN_LATITUDE_ASC',
  WORLD_PLACES_BY_USER_UPDATED__MIN_LATITUDE_DESC = 'WORLD_PLACES_BY_USER_UPDATED__MIN_LATITUDE_DESC',
  WORLD_PLACES_BY_USER_UPDATED__MIN_LONGITUDE_ASC = 'WORLD_PLACES_BY_USER_UPDATED__MIN_LONGITUDE_ASC',
  WORLD_PLACES_BY_USER_UPDATED__MIN_LONGITUDE_DESC = 'WORLD_PLACES_BY_USER_UPDATED__MIN_LONGITUDE_DESC',
  WORLD_PLACES_BY_USER_UPDATED__MIN_NAME_ASC = 'WORLD_PLACES_BY_USER_UPDATED__MIN_NAME_ASC',
  WORLD_PLACES_BY_USER_UPDATED__MIN_NAME_DESC = 'WORLD_PLACES_BY_USER_UPDATED__MIN_NAME_DESC',
  WORLD_PLACES_BY_USER_UPDATED__MIN_REMARK_ASC = 'WORLD_PLACES_BY_USER_UPDATED__MIN_REMARK_ASC',
  WORLD_PLACES_BY_USER_UPDATED__MIN_REMARK_DESC = 'WORLD_PLACES_BY_USER_UPDATED__MIN_REMARK_DESC',
  WORLD_PLACES_BY_USER_UPDATED__MIN_TIMEZONE_ASC = 'WORLD_PLACES_BY_USER_UPDATED__MIN_TIMEZONE_ASC',
  WORLD_PLACES_BY_USER_UPDATED__MIN_TIMEZONE_DESC = 'WORLD_PLACES_BY_USER_UPDATED__MIN_TIMEZONE_DESC',
  WORLD_PLACES_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'WORLD_PLACES_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  WORLD_PLACES_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'WORLD_PLACES_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  WORLD_PLACES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'WORLD_PLACES_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  WORLD_PLACES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'WORLD_PLACES_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  WORLD_PLACES_BY_USER_UPDATED__MIN_WORLD_PLACE_ID_ASC = 'WORLD_PLACES_BY_USER_UPDATED__MIN_WORLD_PLACE_ID_ASC',
  WORLD_PLACES_BY_USER_UPDATED__MIN_WORLD_PLACE_ID_DESC = 'WORLD_PLACES_BY_USER_UPDATED__MIN_WORLD_PLACE_ID_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__COUNT_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__COUNT_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__COUNT_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__COUNT_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_CONDITION_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_CONDITION_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_CONDITION_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_CONDITION_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_DATE_CREATED_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_DATE_CREATED_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_DATE_CREATED_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_DATE_CREATED_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_DATE_UPDATED_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_DATE_UPDATED_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_DAY_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_DAY_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_DAY_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_DAY_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_HUMIDITY_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_HUMIDITY_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_HUMIDITY_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_HUMIDITY_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_ICON_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_ICON_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_ICON_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_ICON_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_REMARK_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_REMARK_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_REMARK_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_REMARK_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_TEMP_MAX_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_TEMP_MAX_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_TEMP_MAX_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_TEMP_MAX_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_TEMP_MIN_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_TEMP_MIN_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_TEMP_MIN_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_TEMP_MIN_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_TIME_SUNRISE_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_TIME_SUNRISE_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_TIME_SUNRISE_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_TIME_SUNRISE_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_TIME_SUNSET_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_TIME_SUNSET_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_TIME_SUNSET_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_TIME_SUNSET_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_USER_CREATED_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_USER_CREATED_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_USER_CREATED_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_USER_CREATED_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_USER_UPDATED_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_USER_UPDATED_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_USER_UPDATED_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_USER_UPDATED_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_WORLD_PLACE_ID_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_WORLD_PLACE_ID_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_WORLD_PLACE_ID_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MAX_WORLD_PLACE_ID_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_CONDITION_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_CONDITION_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_CONDITION_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_CONDITION_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_DATE_CREATED_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_DATE_CREATED_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_DATE_CREATED_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_DATE_CREATED_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_DATE_UPDATED_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_DATE_UPDATED_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_DAY_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_DAY_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_DAY_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_DAY_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_HUMIDITY_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_HUMIDITY_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_HUMIDITY_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_HUMIDITY_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_ICON_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_ICON_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_ICON_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_ICON_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_REMARK_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_REMARK_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_REMARK_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_REMARK_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_TEMP_MAX_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_TEMP_MAX_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_TEMP_MAX_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_TEMP_MAX_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_TEMP_MIN_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_TEMP_MIN_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_TEMP_MIN_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_TEMP_MIN_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_TIME_SUNRISE_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_TIME_SUNRISE_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_TIME_SUNRISE_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_TIME_SUNRISE_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_TIME_SUNSET_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_TIME_SUNSET_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_TIME_SUNSET_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_TIME_SUNSET_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_USER_CREATED_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_USER_CREATED_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_USER_CREATED_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_USER_CREATED_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_USER_UPDATED_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_USER_UPDATED_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_USER_UPDATED_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_USER_UPDATED_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_WORLD_PLACE_ID_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_WORLD_PLACE_ID_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_WORLD_PLACE_ID_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_CREATED__MIN_WORLD_PLACE_ID_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__COUNT_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__COUNT_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__COUNT_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__COUNT_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_CONDITION_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_CONDITION_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_CONDITION_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_CONDITION_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_DATE_CREATED_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_DATE_CREATED_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_DATE_UPDATED_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_DATE_UPDATED_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_DAY_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_DAY_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_DAY_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_DAY_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_HUMIDITY_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_HUMIDITY_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_HUMIDITY_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_HUMIDITY_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_ICON_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_ICON_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_ICON_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_ICON_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_REMARK_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_REMARK_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_REMARK_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_REMARK_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_TEMP_MAX_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_TEMP_MAX_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_TEMP_MAX_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_TEMP_MAX_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_TEMP_MIN_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_TEMP_MIN_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_TEMP_MIN_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_TEMP_MIN_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_TIME_SUNRISE_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_TIME_SUNRISE_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_TIME_SUNRISE_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_TIME_SUNRISE_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_TIME_SUNSET_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_TIME_SUNSET_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_TIME_SUNSET_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_TIME_SUNSET_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_USER_CREATED_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_USER_CREATED_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_USER_CREATED_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_USER_CREATED_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_USER_UPDATED_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_USER_UPDATED_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_WORLD_PLACE_ID_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_WORLD_PLACE_ID_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_WORLD_PLACE_ID_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MAX_WORLD_PLACE_ID_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_CONDITION_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_CONDITION_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_CONDITION_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_CONDITION_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_DATE_CREATED_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_DATE_CREATED_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_DATE_UPDATED_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_DATE_UPDATED_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_DAY_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_DAY_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_DAY_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_DAY_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_HUMIDITY_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_HUMIDITY_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_HUMIDITY_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_HUMIDITY_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_ICON_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_ICON_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_ICON_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_ICON_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_REMARK_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_REMARK_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_REMARK_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_REMARK_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_TEMP_MAX_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_TEMP_MAX_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_TEMP_MAX_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_TEMP_MAX_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_TEMP_MIN_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_TEMP_MIN_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_TEMP_MIN_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_TEMP_MIN_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_TIME_SUNRISE_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_TIME_SUNRISE_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_TIME_SUNRISE_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_TIME_SUNRISE_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_TIME_SUNSET_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_TIME_SUNSET_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_TIME_SUNSET_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_TIME_SUNSET_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_USER_CREATED_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_USER_CREATED_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_USER_CREATED_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_USER_CREATED_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_USER_UPDATED_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_USER_UPDATED_DESC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_WORLD_PLACE_ID_ASC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_WORLD_PLACE_ID_ASC',
  WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_WORLD_PLACE_ID_DESC = 'WORLD_PLACE_WEATHERS_BY_USER_UPDATED__MIN_WORLD_PLACE_ID_DESC',
}

export interface UserForceLogout extends Node {
  __typename?: 'UserForceLogout';
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  nodeId: Scalars['ID']['output'];
  reason: Scalars['String']['output'];
  userAccount: UserAccount;
  userAccountId: Scalars['BigInt']['output'];
  userForceLogoutId: Scalars['BigInt']['output'];
}

export interface UserForceLogoutCondition {
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutId?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UserForceLogoutFilter {
  and?: InputMaybe<Array<UserForceLogoutFilter>>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  not?: InputMaybe<UserForceLogoutFilter>;
  or?: InputMaybe<Array<UserForceLogoutFilter>>;
  reason?: InputMaybe<StringFilter>;
  remark?: InputMaybe<StringFilter>;
  userAccount?: InputMaybe<UserAccountFilter>;
  userAccountId?: InputMaybe<BigIntFilter>;
  userCreated?: InputMaybe<BigIntFilter>;
  userForceLogoutId?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface UserForceLogoutNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface UserForceLogoutOnUserForceLogoutForUserForceLogoutUserAccountIdFkeyUsingUserForceLogoutPkeyUpdate {
  patch: UpdateUserForceLogoutOnUserForceLogoutForUserForceLogoutUserAccountIdFkeyPatch;
  userForceLogoutId: Scalars['BigInt']['input'];
}

export interface UserForceLogoutPatch {
  reason?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<UserForceLogoutUserUpdatedFkeyInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutId?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UserForceLogoutUserAccountIdFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<UserForceLogoutNodeIdConnect>>;
  connectByUserForceLogoutId?: InputMaybe<Array<UserForceLogoutUserForceLogoutPkeyConnect>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnUserForceLogoutForUserForceLogoutUserAccountIdFkeyNodeIdUpdate>>;
  updateByUserForceLogoutId?: InputMaybe<Array<UserForceLogoutOnUserForceLogoutForUserForceLogoutUserAccountIdFkeyUsingUserForceLogoutPkeyUpdate>>;
}

export interface UserForceLogoutUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<UserForceLogoutNodeIdConnect>>;
  connectByUserForceLogoutId?: InputMaybe<Array<UserForceLogoutUserForceLogoutPkeyConnect>>;
}

export interface UserForceLogoutUserForceLogoutPkeyConnect {
  userForceLogoutId: Scalars['BigInt']['input'];
}

export interface UserForceLogoutUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
}

export interface UserForceLogoutUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<UserForceLogoutNodeIdConnect>>;
  connectByUserForceLogoutId?: InputMaybe<Array<UserForceLogoutUserForceLogoutPkeyConnect>>;
}

export interface UserForceLogoutsConnection {
  __typename?: 'UserForceLogoutsConnection';
  edges: Array<UserForceLogoutsEdge>;
  nodes: Array<UserForceLogout>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface UserForceLogoutsEdge {
  __typename?: 'UserForceLogoutsEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: UserForceLogout;
}

export enum UserForceLogoutsOrderBy {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REASON_ASC = 'REASON_ASC',
  REASON_DESC = 'REASON_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__REMARK_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__REMARK_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__REMARK_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__REMARK_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__ROLE_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__ROLE_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__ROLE_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__ROLE_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_UPDATED_DESC',
  USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_ID_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_FORCE_LOGOUT_ID_ASC = 'USER_FORCE_LOGOUT_ID_ASC',
  USER_FORCE_LOGOUT_ID_DESC = 'USER_FORCE_LOGOUT_ID_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface UserHrManagingCompaniesConnection {
  __typename?: 'UserHrManagingCompaniesConnection';
  edges: Array<UserHrManagingCompaniesEdge>;
  nodes: Array<UserHrManagingCompany>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface UserHrManagingCompaniesEdge {
  __typename?: 'UserHrManagingCompaniesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: UserHrManagingCompany;
}

export enum UserHrManagingCompaniesOrderBy {
  COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC',
  COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC = 'COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_CLOSED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ESTABLISHED_DATE_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_GROUP_ID_DESC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_ASC',
  COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC = 'COMPANY_BY_COMPANY_ID__COMPANY_ID_DESC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_ASC',
  COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC = 'COMPANY_BY_COMPANY_ID__COUNTRY_CODE_ALPHA2_DESC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__DATE_UPDATED_DESC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_ASC',
  COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC = 'COMPANY_BY_COMPANY_ID__EMPLOYEE_CODE_PREFIX_DESC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_ASC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_ASC',
  COMPANY_BY_COMPANY_ID__FULL_NAME_DESC = 'COMPANY_BY_COMPANY_ID__FULL_NAME_DESC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_ASC',
  COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC = 'COMPANY_BY_COMPANY_ID__NOTIFY_PERIOD_CONTRACT_END_DESC',
  COMPANY_BY_COMPANY_ID__REMARK_ASC = 'COMPANY_BY_COMPANY_ID__REMARK_ASC',
  COMPANY_BY_COMPANY_ID__REMARK_DESC = 'COMPANY_BY_COMPANY_ID__REMARK_DESC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_CREATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_CREATED_DESC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_ASC',
  COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC = 'COMPANY_BY_COMPANY_ID__USER_UPDATED_DESC',
  COMPANY_ID_ASC = 'COMPANY_ID_ASC',
  COMPANY_ID_DESC = 'COMPANY_ID_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__REMARK_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__REMARK_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__REMARK_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__REMARK_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__ROLE_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__ROLE_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__ROLE_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__ROLE_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_ACCOUNT_ID__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_ID_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_HR_MANAGING_COMPANY_ID_ASC = 'USER_HR_MANAGING_COMPANY_ID_ASC',
  USER_HR_MANAGING_COMPANY_ID_DESC = 'USER_HR_MANAGING_COMPANY_ID_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
}

export interface UserHrManagingCompany extends Node {
  __typename?: 'UserHrManagingCompany';
  company: Company;
  companyId: Scalars['BigInt']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  userAccount: UserAccount;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userAccountId: Scalars['BigInt']['output'];
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userHrManagingCompanyId: Scalars['BigInt']['output'];
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
}

export interface UserHrManagingCompanyCompanyIdFkeyCompanyCreateInput {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName: Scalars['String']['input'];
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UserHrManagingCompanyCompanyIdFkeyInput {
  connectByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyConnect>;
  connectByCompanyId?: InputMaybe<CompanyCompanyPkeyConnect>;
  connectByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyConnect>;
  connectByNodeId?: InputMaybe<CompanyNodeIdConnect>;
  create?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyCompanyCreateInput>;
  deleteByAbbreviation?: InputMaybe<CompanyCompanyAbbreviationKeyDelete>;
  deleteByCompanyId?: InputMaybe<CompanyCompanyPkeyDelete>;
  deleteByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyCompanyCountryCodeAlpha2FullNameKeyDelete>;
  deleteByNodeId?: InputMaybe<CompanyNodeIdDelete>;
  updateByAbbreviation?: InputMaybe<CompanyOnUserHrManagingCompanyForUserHrManagingCompanyCompanyIdFkeyUsingCompanyAbbreviationKeyUpdate>;
  updateByCompanyId?: InputMaybe<CompanyOnUserHrManagingCompanyForUserHrManagingCompanyCompanyIdFkeyUsingCompanyPkeyUpdate>;
  updateByCountryCodeAlpha2AndFullName?: InputMaybe<CompanyOnUserHrManagingCompanyForUserHrManagingCompanyCompanyIdFkeyUsingCompanyCountryCodeAlpha2FullNameKeyUpdate>;
  updateByNodeId?: InputMaybe<UserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyCompanyIdFkeyNodeIdUpdate>;
}

export interface UserHrManagingCompanyCompanyIdFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<UserHrManagingCompanyNodeIdConnect>>;
  connectByUserAccountIdAndCompanyId?: InputMaybe<Array<UserHrManagingCompanyUserHrManagingCompanyUserAccountIdCompanyIdKeyConnect>>;
  connectByUserHrManagingCompanyId?: InputMaybe<Array<UserHrManagingCompanyUserHrManagingCompanyPkeyConnect>>;
  create?: InputMaybe<Array<UserHrManagingCompanyCompanyIdFkeyUserHrManagingCompanyCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<UserHrManagingCompanyNodeIdDelete>>;
  deleteByUserAccountIdAndCompanyId?: InputMaybe<Array<UserHrManagingCompanyUserHrManagingCompanyUserAccountIdCompanyIdKeyDelete>>;
  deleteByUserHrManagingCompanyId?: InputMaybe<Array<UserHrManagingCompanyUserHrManagingCompanyPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<CompanyOnUserHrManagingCompanyForUserHrManagingCompanyCompanyIdFkeyNodeIdUpdate>>;
  updateByUserAccountIdAndCompanyId?: InputMaybe<Array<UserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyCompanyIdFkeyUsingUserHrManagingCompanyUserAccountIdCompanyIdKeyUpdate>>;
  updateByUserHrManagingCompanyId?: InputMaybe<Array<UserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyCompanyIdFkeyUsingUserHrManagingCompanyPkeyUpdate>>;
}

export interface UserHrManagingCompanyCompanyIdFkeyUserHrManagingCompanyCreateInput {
  company?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanyId?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UserHrManagingCompanyCondition {
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanyId?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UserHrManagingCompanyFilter {
  and?: InputMaybe<Array<UserHrManagingCompanyFilter>>;
  company?: InputMaybe<CompanyFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  not?: InputMaybe<UserHrManagingCompanyFilter>;
  or?: InputMaybe<Array<UserHrManagingCompanyFilter>>;
  remark?: InputMaybe<StringFilter>;
  userAccount?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountId?: InputMaybe<BigIntFilter>;
  userCreated?: InputMaybe<BigIntFilter>;
  userHrManagingCompanyId?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
}

export interface UserHrManagingCompanyInput {
  company?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanyId?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UserHrManagingCompanyNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface UserHrManagingCompanyNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface UserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyCompanyIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: CompanyPatch;
}

export interface UserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyCompanyIdFkeyUsingUserHrManagingCompanyPkeyUpdate {
  patch: UpdateUserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyCompanyIdFkeyPatch;
  userHrManagingCompanyId: Scalars['BigInt']['input'];
}

export interface UserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyCompanyIdFkeyUsingUserHrManagingCompanyUserAccountIdCompanyIdKeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdateUserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyCompanyIdFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyUserAccountIdFkeyUsingUserHrManagingCompanyPkeyUpdate {
  patch: UpdateUserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyUserAccountIdFkeyPatch;
  userHrManagingCompanyId: Scalars['BigInt']['input'];
}

export interface UserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyUserAccountIdFkeyUsingUserHrManagingCompanyUserAccountIdCompanyIdKeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdateUserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyUserAccountIdFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyUserCreatedFkeyUsingUserHrManagingCompanyPkeyUpdate {
  patch: UpdateUserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyUserCreatedFkeyPatch;
  userHrManagingCompanyId: Scalars['BigInt']['input'];
}

export interface UserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyUserCreatedFkeyUsingUserHrManagingCompanyUserAccountIdCompanyIdKeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdateUserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyUserCreatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface UserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyUserUpdatedFkeyUsingUserHrManagingCompanyPkeyUpdate {
  patch: UpdateUserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyUserUpdatedFkeyPatch;
  userHrManagingCompanyId: Scalars['BigInt']['input'];
}

export interface UserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyUserUpdatedFkeyUsingUserHrManagingCompanyUserAccountIdCompanyIdKeyUpdate {
  companyId: Scalars['BigInt']['input'];
  patch: UpdateUserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyUserUpdatedFkeyPatch;
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserHrManagingCompanyPatch {
  company?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanyId?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UserHrManagingCompanyUserAccountIdFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<UserHrManagingCompanyNodeIdConnect>>;
  connectByUserAccountIdAndCompanyId?: InputMaybe<Array<UserHrManagingCompanyUserHrManagingCompanyUserAccountIdCompanyIdKeyConnect>>;
  connectByUserHrManagingCompanyId?: InputMaybe<Array<UserHrManagingCompanyUserHrManagingCompanyPkeyConnect>>;
  create?: InputMaybe<Array<UserHrManagingCompanyUserAccountIdFkeyUserHrManagingCompanyCreateInput>>;
  deleteByNodeId?: InputMaybe<Array<UserHrManagingCompanyNodeIdDelete>>;
  deleteByUserAccountIdAndCompanyId?: InputMaybe<Array<UserHrManagingCompanyUserHrManagingCompanyUserAccountIdCompanyIdKeyDelete>>;
  deleteByUserHrManagingCompanyId?: InputMaybe<Array<UserHrManagingCompanyUserHrManagingCompanyPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnUserHrManagingCompanyForUserHrManagingCompanyUserAccountIdFkeyNodeIdUpdate>>;
  updateByUserAccountIdAndCompanyId?: InputMaybe<Array<UserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyUserAccountIdFkeyUsingUserHrManagingCompanyUserAccountIdCompanyIdKeyUpdate>>;
  updateByUserHrManagingCompanyId?: InputMaybe<Array<UserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyUserAccountIdFkeyUsingUserHrManagingCompanyPkeyUpdate>>;
}

export interface UserHrManagingCompanyUserAccountIdFkeyUserHrManagingCompanyCreateInput {
  company?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanyId?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UserHrManagingCompanyUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<UserHrManagingCompanyNodeIdConnect>>;
  connectByUserAccountIdAndCompanyId?: InputMaybe<Array<UserHrManagingCompanyUserHrManagingCompanyUserAccountIdCompanyIdKeyConnect>>;
  connectByUserHrManagingCompanyId?: InputMaybe<Array<UserHrManagingCompanyUserHrManagingCompanyPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<UserHrManagingCompanyNodeIdDelete>>;
  deleteByUserAccountIdAndCompanyId?: InputMaybe<Array<UserHrManagingCompanyUserHrManagingCompanyUserAccountIdCompanyIdKeyDelete>>;
  deleteByUserHrManagingCompanyId?: InputMaybe<Array<UserHrManagingCompanyUserHrManagingCompanyPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnUserHrManagingCompanyForUserHrManagingCompanyUserCreatedFkeyNodeIdUpdate>>;
  updateByUserAccountIdAndCompanyId?: InputMaybe<Array<UserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyUserCreatedFkeyUsingUserHrManagingCompanyUserAccountIdCompanyIdKeyUpdate>>;
  updateByUserHrManagingCompanyId?: InputMaybe<Array<UserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyUserCreatedFkeyUsingUserHrManagingCompanyPkeyUpdate>>;
}

export interface UserHrManagingCompanyUserHrManagingCompanyPkeyConnect {
  userHrManagingCompanyId: Scalars['BigInt']['input'];
}

export interface UserHrManagingCompanyUserHrManagingCompanyPkeyDelete {
  userHrManagingCompanyId: Scalars['BigInt']['input'];
}

export interface UserHrManagingCompanyUserHrManagingCompanyUserAccountIdCompanyIdKeyConnect {
  companyId: Scalars['BigInt']['input'];
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserHrManagingCompanyUserHrManagingCompanyUserAccountIdCompanyIdKeyDelete {
  companyId: Scalars['BigInt']['input'];
  userAccountId: Scalars['BigInt']['input'];
}

export interface UserHrManagingCompanyUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnUserHrManagingCompanyForUserHrManagingCompanyUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<UserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnUserHrManagingCompanyForUserHrManagingCompanyUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface UserHrManagingCompanyUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<UserHrManagingCompanyNodeIdConnect>>;
  connectByUserAccountIdAndCompanyId?: InputMaybe<Array<UserHrManagingCompanyUserHrManagingCompanyUserAccountIdCompanyIdKeyConnect>>;
  connectByUserHrManagingCompanyId?: InputMaybe<Array<UserHrManagingCompanyUserHrManagingCompanyPkeyConnect>>;
  deleteByNodeId?: InputMaybe<Array<UserHrManagingCompanyNodeIdDelete>>;
  deleteByUserAccountIdAndCompanyId?: InputMaybe<Array<UserHrManagingCompanyUserHrManagingCompanyUserAccountIdCompanyIdKeyDelete>>;
  deleteByUserHrManagingCompanyId?: InputMaybe<Array<UserHrManagingCompanyUserHrManagingCompanyPkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnUserHrManagingCompanyForUserHrManagingCompanyUserUpdatedFkeyNodeIdUpdate>>;
  updateByUserAccountIdAndCompanyId?: InputMaybe<Array<UserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyUserUpdatedFkeyUsingUserHrManagingCompanyUserAccountIdCompanyIdKeyUpdate>>;
  updateByUserHrManagingCompanyId?: InputMaybe<Array<UserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyUserUpdatedFkeyUsingUserHrManagingCompanyPkeyUpdate>>;
}

export interface UserRole {
  __typename?: 'UserRole';
  displayName: Scalars['String']['output'];
  employeeFilesByVisibleRole: EmployeeFilesConnection;
  employeeListCustomFilterRolesByRoleName: EmployeeListCustomFilterRolesConnection;
  isDefaultRole: Scalars['Boolean']['output'];
  priority: Scalars['Int']['output'];
  roleName: Scalars['String']['output'];
}

export type UserRoleEmployeeFilesByVisibleRoleArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeFileCondition>;
  filter?: InputMaybe<EmployeeFileFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeFilesOrderBy>>;
};

export type UserRoleEmployeeListCustomFilterRolesByRoleNameArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmployeeListCustomFilterRoleCondition>;
  filter?: InputMaybe<EmployeeListCustomFilterRoleFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeListCustomFilterRolesOrderBy>>;
};

export interface UserRoleCondition {
  displayName?: InputMaybe<Scalars['String']['input']>;
  isDefaultRole?: InputMaybe<Scalars['Boolean']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  roleName?: InputMaybe<Scalars['String']['input']>;
}

export interface UserRoleFilter {
  and?: InputMaybe<Array<UserRoleFilter>>;
  displayName?: InputMaybe<StringFilter>;
  employeeFilesByVisibleRole?: InputMaybe<UserRoleToManyEmployeeFileFilter>;
  employeeFilesByVisibleRoleExist?: InputMaybe<Scalars['Boolean']['input']>;
  employeeListCustomFilterRolesByRoleName?: InputMaybe<UserRoleToManyEmployeeListCustomFilterRoleFilter>;
  employeeListCustomFilterRolesByRoleNameExist?: InputMaybe<Scalars['Boolean']['input']>;
  isDefaultRole?: InputMaybe<BooleanFilter>;
  not?: InputMaybe<UserRoleFilter>;
  or?: InputMaybe<Array<UserRoleFilter>>;
  priority?: InputMaybe<IntFilter>;
  roleName?: InputMaybe<StringFilter>;
}

export interface UserRoleOnEmployeeFileForEmployeeFileVisibleRoleFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeFilePatch;
}

export interface UserRoleOnEmployeeFileForEmployeeFileVisibleRoleFkeyUsingUserRoleRoleNameKeyUpdate {
  patch: UpdateUserRoleOnEmployeeFileForEmployeeFileVisibleRoleFkeyPatch;
  roleName: Scalars['String']['input'];
}

export interface UserRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleRoleNameFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: EmployeeListCustomFilterRolePatch;
}

export interface UserRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleRoleNameFkeyUsingUserRoleRoleNameKeyUpdate {
  patch: UpdateUserRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleRoleNameFkeyPatch;
  roleName: Scalars['String']['input'];
}

export interface UserRoleOnUserAccountForUserAccountRoleFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface UserRoleOnUserAccountForUserAccountRoleFkeyUsingUserRoleRoleNameKeyUpdate {
  patch: UpdateUserRoleOnUserAccountForUserAccountRoleFkeyPatch;
  roleName: Scalars['String']['input'];
}

export interface UserRoleToManyEmployeeFileFilter {
  every?: InputMaybe<EmployeeFileFilter>;
  none?: InputMaybe<EmployeeFileFilter>;
  some?: InputMaybe<EmployeeFileFilter>;
}

export interface UserRoleToManyEmployeeListCustomFilterRoleFilter {
  every?: InputMaybe<EmployeeListCustomFilterRoleFilter>;
  none?: InputMaybe<EmployeeListCustomFilterRoleFilter>;
  some?: InputMaybe<EmployeeListCustomFilterRoleFilter>;
}

export interface UserRoleUserRoleRoleNameKeyConnect {
  roleName: Scalars['String']['input'];
}

export interface UserRolesConnection {
  __typename?: 'UserRolesConnection';
  edges: Array<UserRolesEdge>;
  nodes: Array<UserRole>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface UserRolesEdge {
  __typename?: 'UserRolesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: UserRole;
}

export enum UserRolesOrderBy {
  DISPLAY_NAME_ASC = 'DISPLAY_NAME_ASC',
  DISPLAY_NAME_DESC = 'DISPLAY_NAME_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__COUNT_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__COUNT_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__COUNT_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__COUNT_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_DATE_CREATED_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_DATE_CREATED_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_DATE_CREATED_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_DATE_CREATED_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_EMPLOYEE_FILE_ID_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_EMPLOYEE_FILE_ID_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_EMPLOYEE_FILE_ID_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_EMPLOYEE_FILE_ID_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_EMPLOYEE_ID_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_EMPLOYEE_ID_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_EMPLOYEE_ID_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_EMPLOYEE_ID_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_FILE_NAME_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_FILE_NAME_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_FILE_NAME_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_FILE_NAME_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_FILE_SIZE_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_FILE_SIZE_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_FILE_SIZE_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_FILE_SIZE_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_IS_HIDDEN_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_IS_HIDDEN_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_IS_HIDDEN_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_IS_HIDDEN_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_IS_PRIVATE_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_IS_PRIVATE_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_IS_PRIVATE_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_IS_PRIVATE_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_MIME_TYPE_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_MIME_TYPE_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_MIME_TYPE_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_MIME_TYPE_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_REMARK_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_REMARK_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_REMARK_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_REMARK_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_S3_PATH_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_S3_PATH_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_S3_PATH_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_S3_PATH_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_TITLE_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_TITLE_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_TITLE_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_TITLE_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_USER_CREATED_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_USER_CREATED_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_USER_CREATED_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_USER_CREATED_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_USER_UPDATED_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_USER_UPDATED_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_USER_UPDATED_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_USER_UPDATED_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_VISIBLE_ROLE_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_VISIBLE_ROLE_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_VISIBLE_ROLE_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MAX_VISIBLE_ROLE_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_DATE_CREATED_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_DATE_CREATED_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_DATE_CREATED_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_DATE_CREATED_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_EMPLOYEE_FILE_ID_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_EMPLOYEE_FILE_ID_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_EMPLOYEE_FILE_ID_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_EMPLOYEE_FILE_ID_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_EMPLOYEE_ID_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_EMPLOYEE_ID_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_EMPLOYEE_ID_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_EMPLOYEE_ID_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_FILE_NAME_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_FILE_NAME_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_FILE_NAME_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_FILE_NAME_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_FILE_SIZE_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_FILE_SIZE_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_FILE_SIZE_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_FILE_SIZE_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_IS_HIDDEN_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_IS_HIDDEN_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_IS_HIDDEN_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_IS_HIDDEN_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_IS_PRIVATE_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_IS_PRIVATE_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_IS_PRIVATE_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_IS_PRIVATE_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_MIME_TYPE_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_MIME_TYPE_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_MIME_TYPE_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_MIME_TYPE_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_REMARK_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_REMARK_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_REMARK_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_REMARK_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_S3_PATH_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_S3_PATH_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_S3_PATH_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_S3_PATH_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_TITLE_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_TITLE_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_TITLE_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_TITLE_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_USER_CREATED_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_USER_CREATED_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_USER_CREATED_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_USER_CREATED_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_USER_UPDATED_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_USER_UPDATED_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_USER_UPDATED_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_USER_UPDATED_DESC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_VISIBLE_ROLE_ASC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_VISIBLE_ROLE_ASC',
  EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_VISIBLE_ROLE_DESC = 'EMPLOYEE_FILES_BY_VISIBLE_ROLE__MIN_VISIBLE_ROLE_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__COUNT_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__COUNT_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__COUNT_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__COUNT_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_DATE_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_DATE_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_DATE_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_DATE_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_DATE_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_DATE_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_DATE_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_DATE_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_REMARK_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_REMARK_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_REMARK_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_REMARK_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_ROLE_NAME_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_ROLE_NAME_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_ROLE_NAME_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_ROLE_NAME_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_USER_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_USER_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_USER_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_USER_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_USER_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_USER_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_USER_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MAX_USER_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_DATE_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_DATE_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_DATE_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_DATE_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_DATE_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_DATE_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_DATE_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_DATE_UPDATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ID_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ID_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_EMPLOYEE_LIST_CUSTOM_FILTER_ROLE_ID_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_REMARK_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_REMARK_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_REMARK_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_REMARK_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_ROLE_NAME_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_ROLE_NAME_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_ROLE_NAME_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_ROLE_NAME_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_USER_CREATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_USER_CREATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_USER_CREATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_USER_CREATED_DESC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_USER_UPDATED_ASC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_USER_UPDATED_ASC',
  EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_USER_UPDATED_DESC = 'EMPLOYEE_LIST_CUSTOM_FILTER_ROLES_BY_ROLE_NAME__MIN_USER_UPDATED_DESC',
  IS_DEFAULT_ROLE_ASC = 'IS_DEFAULT_ROLE_ASC',
  IS_DEFAULT_ROLE_DESC = 'IS_DEFAULT_ROLE_DESC',
  NATURAL = 'NATURAL',
  PRIORITY_ASC = 'PRIORITY_ASC',
  PRIORITY_DESC = 'PRIORITY_DESC',
  ROLE_NAME_ASC = 'ROLE_NAME_ASC',
  ROLE_NAME_DESC = 'ROLE_NAME_DESC',
  USER_ACCOUNTS_BY_ROLE__COUNT_ASC = 'USER_ACCOUNTS_BY_ROLE__COUNT_ASC',
  USER_ACCOUNTS_BY_ROLE__COUNT_DESC = 'USER_ACCOUNTS_BY_ROLE__COUNT_DESC',
  USER_ACCOUNTS_BY_ROLE__MAX_DATE_CREATED_ASC = 'USER_ACCOUNTS_BY_ROLE__MAX_DATE_CREATED_ASC',
  USER_ACCOUNTS_BY_ROLE__MAX_DATE_CREATED_DESC = 'USER_ACCOUNTS_BY_ROLE__MAX_DATE_CREATED_DESC',
  USER_ACCOUNTS_BY_ROLE__MAX_DATE_UPDATED_ASC = 'USER_ACCOUNTS_BY_ROLE__MAX_DATE_UPDATED_ASC',
  USER_ACCOUNTS_BY_ROLE__MAX_DATE_UPDATED_DESC = 'USER_ACCOUNTS_BY_ROLE__MAX_DATE_UPDATED_DESC',
  USER_ACCOUNTS_BY_ROLE__MAX_DISPLAY_NAME_ASC = 'USER_ACCOUNTS_BY_ROLE__MAX_DISPLAY_NAME_ASC',
  USER_ACCOUNTS_BY_ROLE__MAX_DISPLAY_NAME_DESC = 'USER_ACCOUNTS_BY_ROLE__MAX_DISPLAY_NAME_DESC',
  USER_ACCOUNTS_BY_ROLE__MAX_EMAIL_ADDRESS_ASC = 'USER_ACCOUNTS_BY_ROLE__MAX_EMAIL_ADDRESS_ASC',
  USER_ACCOUNTS_BY_ROLE__MAX_EMAIL_ADDRESS_DESC = 'USER_ACCOUNTS_BY_ROLE__MAX_EMAIL_ADDRESS_DESC',
  USER_ACCOUNTS_BY_ROLE__MAX_GOOGLE_ID_ASC = 'USER_ACCOUNTS_BY_ROLE__MAX_GOOGLE_ID_ASC',
  USER_ACCOUNTS_BY_ROLE__MAX_GOOGLE_ID_DESC = 'USER_ACCOUNTS_BY_ROLE__MAX_GOOGLE_ID_DESC',
  USER_ACCOUNTS_BY_ROLE__MAX_GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNTS_BY_ROLE__MAX_GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNTS_BY_ROLE__MAX_GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNTS_BY_ROLE__MAX_GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNTS_BY_ROLE__MAX_IS_ENABLED_ASC = 'USER_ACCOUNTS_BY_ROLE__MAX_IS_ENABLED_ASC',
  USER_ACCOUNTS_BY_ROLE__MAX_IS_ENABLED_DESC = 'USER_ACCOUNTS_BY_ROLE__MAX_IS_ENABLED_DESC',
  USER_ACCOUNTS_BY_ROLE__MAX_REMARK_ASC = 'USER_ACCOUNTS_BY_ROLE__MAX_REMARK_ASC',
  USER_ACCOUNTS_BY_ROLE__MAX_REMARK_DESC = 'USER_ACCOUNTS_BY_ROLE__MAX_REMARK_DESC',
  USER_ACCOUNTS_BY_ROLE__MAX_ROLE_ASC = 'USER_ACCOUNTS_BY_ROLE__MAX_ROLE_ASC',
  USER_ACCOUNTS_BY_ROLE__MAX_ROLE_DESC = 'USER_ACCOUNTS_BY_ROLE__MAX_ROLE_DESC',
  USER_ACCOUNTS_BY_ROLE__MAX_USER_ACCOUNT_ID_ASC = 'USER_ACCOUNTS_BY_ROLE__MAX_USER_ACCOUNT_ID_ASC',
  USER_ACCOUNTS_BY_ROLE__MAX_USER_ACCOUNT_ID_DESC = 'USER_ACCOUNTS_BY_ROLE__MAX_USER_ACCOUNT_ID_DESC',
  USER_ACCOUNTS_BY_ROLE__MAX_USER_CREATED_ASC = 'USER_ACCOUNTS_BY_ROLE__MAX_USER_CREATED_ASC',
  USER_ACCOUNTS_BY_ROLE__MAX_USER_CREATED_DESC = 'USER_ACCOUNTS_BY_ROLE__MAX_USER_CREATED_DESC',
  USER_ACCOUNTS_BY_ROLE__MAX_USER_UPDATED_ASC = 'USER_ACCOUNTS_BY_ROLE__MAX_USER_UPDATED_ASC',
  USER_ACCOUNTS_BY_ROLE__MAX_USER_UPDATED_DESC = 'USER_ACCOUNTS_BY_ROLE__MAX_USER_UPDATED_DESC',
  USER_ACCOUNTS_BY_ROLE__MIN_DATE_CREATED_ASC = 'USER_ACCOUNTS_BY_ROLE__MIN_DATE_CREATED_ASC',
  USER_ACCOUNTS_BY_ROLE__MIN_DATE_CREATED_DESC = 'USER_ACCOUNTS_BY_ROLE__MIN_DATE_CREATED_DESC',
  USER_ACCOUNTS_BY_ROLE__MIN_DATE_UPDATED_ASC = 'USER_ACCOUNTS_BY_ROLE__MIN_DATE_UPDATED_ASC',
  USER_ACCOUNTS_BY_ROLE__MIN_DATE_UPDATED_DESC = 'USER_ACCOUNTS_BY_ROLE__MIN_DATE_UPDATED_DESC',
  USER_ACCOUNTS_BY_ROLE__MIN_DISPLAY_NAME_ASC = 'USER_ACCOUNTS_BY_ROLE__MIN_DISPLAY_NAME_ASC',
  USER_ACCOUNTS_BY_ROLE__MIN_DISPLAY_NAME_DESC = 'USER_ACCOUNTS_BY_ROLE__MIN_DISPLAY_NAME_DESC',
  USER_ACCOUNTS_BY_ROLE__MIN_EMAIL_ADDRESS_ASC = 'USER_ACCOUNTS_BY_ROLE__MIN_EMAIL_ADDRESS_ASC',
  USER_ACCOUNTS_BY_ROLE__MIN_EMAIL_ADDRESS_DESC = 'USER_ACCOUNTS_BY_ROLE__MIN_EMAIL_ADDRESS_DESC',
  USER_ACCOUNTS_BY_ROLE__MIN_GOOGLE_ID_ASC = 'USER_ACCOUNTS_BY_ROLE__MIN_GOOGLE_ID_ASC',
  USER_ACCOUNTS_BY_ROLE__MIN_GOOGLE_ID_DESC = 'USER_ACCOUNTS_BY_ROLE__MIN_GOOGLE_ID_DESC',
  USER_ACCOUNTS_BY_ROLE__MIN_GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNTS_BY_ROLE__MIN_GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNTS_BY_ROLE__MIN_GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNTS_BY_ROLE__MIN_GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNTS_BY_ROLE__MIN_IS_ENABLED_ASC = 'USER_ACCOUNTS_BY_ROLE__MIN_IS_ENABLED_ASC',
  USER_ACCOUNTS_BY_ROLE__MIN_IS_ENABLED_DESC = 'USER_ACCOUNTS_BY_ROLE__MIN_IS_ENABLED_DESC',
  USER_ACCOUNTS_BY_ROLE__MIN_REMARK_ASC = 'USER_ACCOUNTS_BY_ROLE__MIN_REMARK_ASC',
  USER_ACCOUNTS_BY_ROLE__MIN_REMARK_DESC = 'USER_ACCOUNTS_BY_ROLE__MIN_REMARK_DESC',
  USER_ACCOUNTS_BY_ROLE__MIN_ROLE_ASC = 'USER_ACCOUNTS_BY_ROLE__MIN_ROLE_ASC',
  USER_ACCOUNTS_BY_ROLE__MIN_ROLE_DESC = 'USER_ACCOUNTS_BY_ROLE__MIN_ROLE_DESC',
  USER_ACCOUNTS_BY_ROLE__MIN_USER_ACCOUNT_ID_ASC = 'USER_ACCOUNTS_BY_ROLE__MIN_USER_ACCOUNT_ID_ASC',
  USER_ACCOUNTS_BY_ROLE__MIN_USER_ACCOUNT_ID_DESC = 'USER_ACCOUNTS_BY_ROLE__MIN_USER_ACCOUNT_ID_DESC',
  USER_ACCOUNTS_BY_ROLE__MIN_USER_CREATED_ASC = 'USER_ACCOUNTS_BY_ROLE__MIN_USER_CREATED_ASC',
  USER_ACCOUNTS_BY_ROLE__MIN_USER_CREATED_DESC = 'USER_ACCOUNTS_BY_ROLE__MIN_USER_CREATED_DESC',
  USER_ACCOUNTS_BY_ROLE__MIN_USER_UPDATED_ASC = 'USER_ACCOUNTS_BY_ROLE__MIN_USER_UPDATED_ASC',
  USER_ACCOUNTS_BY_ROLE__MIN_USER_UPDATED_DESC = 'USER_ACCOUNTS_BY_ROLE__MIN_USER_UPDATED_DESC',
}

export interface ValidateSubscriptionInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  topic?: InputMaybe<Scalars['String']['input']>;
}

export interface ValidateSubscriptionPayload {
  __typename?: 'ValidateSubscriptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Query>;
  string?: Maybe<Scalars['String']['output']>;
}

export interface WorldPlace extends Node {
  __typename?: 'WorldPlace';
  countryCodeAlpha2: Scalars['String']['output'];
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  displayOrder: Scalars['Int']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  timezone: Scalars['String']['output'];
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
  worldPlaceId: Scalars['BigInt']['output'];
  worldPlaceWeathers: WorldPlaceWeathersConnection;
}

export type WorldPlaceWorldPlaceWeathersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<WorldPlaceWeatherCondition>;
  filter?: InputMaybe<WorldPlaceWeatherFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<WorldPlaceWeathersOrderBy>>;
};

export interface WorldPlaceCondition {
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlaceId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface WorldPlaceFilter {
  and?: InputMaybe<Array<WorldPlaceFilter>>;
  countryCodeAlpha2?: InputMaybe<StringFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  latitude?: InputMaybe<FloatFilter>;
  longitude?: InputMaybe<FloatFilter>;
  name?: InputMaybe<StringFilter>;
  not?: InputMaybe<WorldPlaceFilter>;
  or?: InputMaybe<Array<WorldPlaceFilter>>;
  remark?: InputMaybe<StringFilter>;
  timezone?: InputMaybe<StringFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
  worldPlaceId?: InputMaybe<BigIntFilter>;
  worldPlaceWeathers?: InputMaybe<WorldPlaceToManyWorldPlaceWeatherFilter>;
  worldPlaceWeathersExist?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface WorldPlaceInput {
  countryCodeAlpha2: Scalars['String']['input'];
  displayOrder: Scalars['Int']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  remark?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
  userAccount?: InputMaybe<WorldPlaceUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlaceId?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlaceWeathers?: InputMaybe<WorldPlaceWeatherWorldPlaceIdFkeyInverseInput>;
}

export interface WorldPlaceNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface WorldPlaceNodeIdDelete {
  nodeId: Scalars['ID']['input'];
}

export interface WorldPlaceOnWorldPlaceForWorldPlaceUserCreatedFkeyUsingWorldPlaceDisplayOrderKeyUpdate {
  displayOrder: Scalars['Int']['input'];
  patch: UpdateWorldPlaceOnWorldPlaceForWorldPlaceUserCreatedFkeyPatch;
}

export interface WorldPlaceOnWorldPlaceForWorldPlaceUserCreatedFkeyUsingWorldPlacePkeyUpdate {
  patch: UpdateWorldPlaceOnWorldPlaceForWorldPlaceUserCreatedFkeyPatch;
  worldPlaceId: Scalars['BigInt']['input'];
}

export interface WorldPlaceOnWorldPlaceForWorldPlaceUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface WorldPlaceOnWorldPlaceForWorldPlaceUserUpdatedFkeyUsingWorldPlaceDisplayOrderKeyUpdate {
  displayOrder: Scalars['Int']['input'];
  patch: UpdateWorldPlaceOnWorldPlaceForWorldPlaceUserUpdatedFkeyPatch;
}

export interface WorldPlaceOnWorldPlaceForWorldPlaceUserUpdatedFkeyUsingWorldPlacePkeyUpdate {
  patch: UpdateWorldPlaceOnWorldPlaceForWorldPlaceUserUpdatedFkeyPatch;
  worldPlaceId: Scalars['BigInt']['input'];
}

export interface WorldPlaceOnWorldPlaceWeatherForWorldPlaceWeatherWorldPlaceIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: WorldPlaceWeatherPatch;
}

export interface WorldPlaceOnWorldPlaceWeatherForWorldPlaceWeatherWorldPlaceIdFkeyUsingWorldPlaceDisplayOrderKeyUpdate {
  displayOrder: Scalars['Int']['input'];
  patch: UpdateWorldPlaceOnWorldPlaceWeatherForWorldPlaceWeatherWorldPlaceIdFkeyPatch;
}

export interface WorldPlaceOnWorldPlaceWeatherForWorldPlaceWeatherWorldPlaceIdFkeyUsingWorldPlacePkeyUpdate {
  patch: UpdateWorldPlaceOnWorldPlaceWeatherForWorldPlaceWeatherWorldPlaceIdFkeyPatch;
  worldPlaceId: Scalars['BigInt']['input'];
}

export interface WorldPlacePatch {
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<WorldPlaceUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlaceId?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlaceWeathers?: InputMaybe<WorldPlaceWeatherWorldPlaceIdFkeyInverseInput>;
}

export interface WorldPlaceToManyWorldPlaceWeatherFilter {
  every?: InputMaybe<WorldPlaceWeatherFilter>;
  none?: InputMaybe<WorldPlaceWeatherFilter>;
  some?: InputMaybe<WorldPlaceWeatherFilter>;
}

export interface WorldPlaceUserCreatedFkeyInverseInput {
  connectByDisplayOrder?: InputMaybe<Array<WorldPlaceWorldPlaceDisplayOrderKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<WorldPlaceNodeIdConnect>>;
  connectByWorldPlaceId?: InputMaybe<Array<WorldPlaceWorldPlacePkeyConnect>>;
  deleteByDisplayOrder?: InputMaybe<Array<WorldPlaceWorldPlaceDisplayOrderKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<WorldPlaceNodeIdDelete>>;
  deleteByWorldPlaceId?: InputMaybe<Array<WorldPlaceWorldPlacePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByDisplayOrder?: InputMaybe<Array<WorldPlaceOnWorldPlaceForWorldPlaceUserCreatedFkeyUsingWorldPlaceDisplayOrderKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnWorldPlaceForWorldPlaceUserCreatedFkeyNodeIdUpdate>>;
  updateByWorldPlaceId?: InputMaybe<Array<WorldPlaceOnWorldPlaceForWorldPlaceUserCreatedFkeyUsingWorldPlacePkeyUpdate>>;
}

export interface WorldPlaceUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnWorldPlaceForWorldPlaceUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<WorldPlaceOnWorldPlaceForWorldPlaceUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnWorldPlaceForWorldPlaceUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface WorldPlaceUserUpdatedFkeyInverseInput {
  connectByDisplayOrder?: InputMaybe<Array<WorldPlaceWorldPlaceDisplayOrderKeyConnect>>;
  connectByNodeId?: InputMaybe<Array<WorldPlaceNodeIdConnect>>;
  connectByWorldPlaceId?: InputMaybe<Array<WorldPlaceWorldPlacePkeyConnect>>;
  deleteByDisplayOrder?: InputMaybe<Array<WorldPlaceWorldPlaceDisplayOrderKeyDelete>>;
  deleteByNodeId?: InputMaybe<Array<WorldPlaceNodeIdDelete>>;
  deleteByWorldPlaceId?: InputMaybe<Array<WorldPlaceWorldPlacePkeyDelete>>;
  deleteOthers?: InputMaybe<Scalars['Boolean']['input']>;
  updateByDisplayOrder?: InputMaybe<Array<WorldPlaceOnWorldPlaceForWorldPlaceUserUpdatedFkeyUsingWorldPlaceDisplayOrderKeyUpdate>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnWorldPlaceForWorldPlaceUserUpdatedFkeyNodeIdUpdate>>;
  updateByWorldPlaceId?: InputMaybe<Array<WorldPlaceOnWorldPlaceForWorldPlaceUserUpdatedFkeyUsingWorldPlacePkeyUpdate>>;
}

export interface WorldPlaceWeather extends Node {
  __typename?: 'WorldPlaceWeather';
  condition?: Maybe<Scalars['String']['output']>;
  dateCreated: Scalars['Datetime']['output'];
  dateUpdated: Scalars['Datetime']['output'];
  day: Scalars['Date']['output'];
  humidity?: Maybe<Scalars['Float']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  nodeId: Scalars['ID']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  tempMax?: Maybe<Scalars['Float']['output']>;
  tempMin?: Maybe<Scalars['Float']['output']>;
  timeSunrise?: Maybe<Scalars['Time']['output']>;
  timeSunset?: Maybe<Scalars['Time']['output']>;
  userAccountByUserCreated?: Maybe<UserAccount>;
  userAccountByUserUpdated?: Maybe<UserAccount>;
  userCreated?: Maybe<Scalars['BigInt']['output']>;
  userUpdated?: Maybe<Scalars['BigInt']['output']>;
  worldPlace: WorldPlace;
  worldPlaceId: Scalars['BigInt']['output'];
}

export interface WorldPlaceWeatherCondition {
  condition?: InputMaybe<Scalars['String']['input']>;
  dateCreated?: InputMaybe<Scalars['Datetime']['input']>;
  dateUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  day?: InputMaybe<Scalars['Date']['input']>;
  humidity?: InputMaybe<Scalars['Float']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  tempMax?: InputMaybe<Scalars['Float']['input']>;
  tempMin?: InputMaybe<Scalars['Float']['input']>;
  timeSunrise?: InputMaybe<Scalars['Time']['input']>;
  timeSunset?: InputMaybe<Scalars['Time']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlaceId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface WorldPlaceWeatherFilter {
  and?: InputMaybe<Array<WorldPlaceWeatherFilter>>;
  condition?: InputMaybe<StringFilter>;
  dateCreated?: InputMaybe<DatetimeFilter>;
  dateUpdated?: InputMaybe<DatetimeFilter>;
  day?: InputMaybe<DateFilter>;
  humidity?: InputMaybe<FloatFilter>;
  icon?: InputMaybe<StringFilter>;
  not?: InputMaybe<WorldPlaceWeatherFilter>;
  or?: InputMaybe<Array<WorldPlaceWeatherFilter>>;
  remark?: InputMaybe<StringFilter>;
  tempMax?: InputMaybe<FloatFilter>;
  tempMin?: InputMaybe<FloatFilter>;
  timeSunrise?: InputMaybe<TimeFilter>;
  timeSunset?: InputMaybe<TimeFilter>;
  userAccountByUserCreated?: InputMaybe<UserAccountFilter>;
  userAccountByUserCreatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountByUserUpdated?: InputMaybe<UserAccountFilter>;
  userAccountByUserUpdatedExists?: InputMaybe<Scalars['Boolean']['input']>;
  userCreated?: InputMaybe<BigIntFilter>;
  userUpdated?: InputMaybe<BigIntFilter>;
  worldPlace?: InputMaybe<WorldPlaceFilter>;
  worldPlaceId?: InputMaybe<BigIntFilter>;
}

export interface WorldPlaceWeatherNodeIdConnect {
  nodeId: Scalars['ID']['input'];
}

export interface WorldPlaceWeatherOnWorldPlaceWeatherForWorldPlaceWeatherUserCreatedFkeyUsingWorldPlaceWeatherPkeyUpdate {
  day: Scalars['Date']['input'];
  patch: UpdateWorldPlaceWeatherOnWorldPlaceWeatherForWorldPlaceWeatherUserCreatedFkeyPatch;
  worldPlaceId: Scalars['BigInt']['input'];
}

export interface WorldPlaceWeatherOnWorldPlaceWeatherForWorldPlaceWeatherUserUpdatedFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: UserAccountPatch;
}

export interface WorldPlaceWeatherOnWorldPlaceWeatherForWorldPlaceWeatherUserUpdatedFkeyUsingWorldPlaceWeatherPkeyUpdate {
  day: Scalars['Date']['input'];
  patch: UpdateWorldPlaceWeatherOnWorldPlaceWeatherForWorldPlaceWeatherUserUpdatedFkeyPatch;
  worldPlaceId: Scalars['BigInt']['input'];
}

export interface WorldPlaceWeatherOnWorldPlaceWeatherForWorldPlaceWeatherWorldPlaceIdFkeyNodeIdUpdate {
  nodeId: Scalars['ID']['input'];
  patch: WorldPlacePatch;
}

export interface WorldPlaceWeatherOnWorldPlaceWeatherForWorldPlaceWeatherWorldPlaceIdFkeyUsingWorldPlaceWeatherPkeyUpdate {
  day: Scalars['Date']['input'];
  patch: UpdateWorldPlaceWeatherOnWorldPlaceWeatherForWorldPlaceWeatherWorldPlaceIdFkeyPatch;
  worldPlaceId: Scalars['BigInt']['input'];
}

export interface WorldPlaceWeatherPatch {
  condition?: InputMaybe<Scalars['String']['input']>;
  day?: InputMaybe<Scalars['Date']['input']>;
  humidity?: InputMaybe<Scalars['Float']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  tempMax?: InputMaybe<Scalars['Float']['input']>;
  tempMin?: InputMaybe<Scalars['Float']['input']>;
  timeSunrise?: InputMaybe<Scalars['Time']['input']>;
  timeSunset?: InputMaybe<Scalars['Time']['input']>;
  userAccount?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlace?: InputMaybe<WorldPlaceWeatherWorldPlaceIdFkeyInput>;
  worldPlaceId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface WorldPlaceWeatherUserCreatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<WorldPlaceWeatherNodeIdConnect>>;
  connectByWorldPlaceIdAndDay?: InputMaybe<Array<WorldPlaceWeatherWorldPlaceWeatherPkeyConnect>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnWorldPlaceWeatherForWorldPlaceWeatherUserCreatedFkeyNodeIdUpdate>>;
  updateByWorldPlaceIdAndDay?: InputMaybe<Array<WorldPlaceWeatherOnWorldPlaceWeatherForWorldPlaceWeatherUserCreatedFkeyUsingWorldPlaceWeatherPkeyUpdate>>;
}

export interface WorldPlaceWeatherUserUpdatedFkeyInput {
  connectByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyConnect>;
  connectByNodeId?: InputMaybe<UserAccountNodeIdConnect>;
  connectByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyConnect>;
  deleteByEmailAddress?: InputMaybe<UserAccountUserAccountEmailAddressKeyDelete>;
  deleteByNodeId?: InputMaybe<UserAccountNodeIdDelete>;
  deleteByUserAccountId?: InputMaybe<UserAccountUserAccountPkeyDelete>;
  updateByEmailAddress?: InputMaybe<UserAccountOnWorldPlaceWeatherForWorldPlaceWeatherUserUpdatedFkeyUsingUserAccountEmailAddressKeyUpdate>;
  updateByNodeId?: InputMaybe<WorldPlaceWeatherOnWorldPlaceWeatherForWorldPlaceWeatherUserUpdatedFkeyNodeIdUpdate>;
  updateByUserAccountId?: InputMaybe<UserAccountOnWorldPlaceWeatherForWorldPlaceWeatherUserUpdatedFkeyUsingUserAccountPkeyUpdate>;
}

export interface WorldPlaceWeatherUserUpdatedFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<WorldPlaceWeatherNodeIdConnect>>;
  connectByWorldPlaceIdAndDay?: InputMaybe<Array<WorldPlaceWeatherWorldPlaceWeatherPkeyConnect>>;
  updateByNodeId?: InputMaybe<Array<UserAccountOnWorldPlaceWeatherForWorldPlaceWeatherUserUpdatedFkeyNodeIdUpdate>>;
  updateByWorldPlaceIdAndDay?: InputMaybe<Array<WorldPlaceWeatherOnWorldPlaceWeatherForWorldPlaceWeatherUserUpdatedFkeyUsingWorldPlaceWeatherPkeyUpdate>>;
}

export interface WorldPlaceWeatherWorldPlaceIdFkeyInput {
  connectByDisplayOrder?: InputMaybe<WorldPlaceWorldPlaceDisplayOrderKeyConnect>;
  connectByNodeId?: InputMaybe<WorldPlaceNodeIdConnect>;
  connectByWorldPlaceId?: InputMaybe<WorldPlaceWorldPlacePkeyConnect>;
  create?: InputMaybe<WorldPlaceWeatherWorldPlaceIdFkeyWorldPlaceCreateInput>;
  deleteByDisplayOrder?: InputMaybe<WorldPlaceWorldPlaceDisplayOrderKeyDelete>;
  deleteByNodeId?: InputMaybe<WorldPlaceNodeIdDelete>;
  deleteByWorldPlaceId?: InputMaybe<WorldPlaceWorldPlacePkeyDelete>;
  updateByDisplayOrder?: InputMaybe<WorldPlaceOnWorldPlaceWeatherForWorldPlaceWeatherWorldPlaceIdFkeyUsingWorldPlaceDisplayOrderKeyUpdate>;
  updateByNodeId?: InputMaybe<WorldPlaceWeatherOnWorldPlaceWeatherForWorldPlaceWeatherWorldPlaceIdFkeyNodeIdUpdate>;
  updateByWorldPlaceId?: InputMaybe<WorldPlaceOnWorldPlaceWeatherForWorldPlaceWeatherWorldPlaceIdFkeyUsingWorldPlacePkeyUpdate>;
}

export interface WorldPlaceWeatherWorldPlaceIdFkeyInverseInput {
  connectByNodeId?: InputMaybe<Array<WorldPlaceWeatherNodeIdConnect>>;
  connectByWorldPlaceIdAndDay?: InputMaybe<Array<WorldPlaceWeatherWorldPlaceWeatherPkeyConnect>>;
  updateByNodeId?: InputMaybe<Array<WorldPlaceOnWorldPlaceWeatherForWorldPlaceWeatherWorldPlaceIdFkeyNodeIdUpdate>>;
  updateByWorldPlaceIdAndDay?: InputMaybe<Array<WorldPlaceWeatherOnWorldPlaceWeatherForWorldPlaceWeatherWorldPlaceIdFkeyUsingWorldPlaceWeatherPkeyUpdate>>;
}

export interface WorldPlaceWeatherWorldPlaceIdFkeyWorldPlaceCreateInput {
  countryCodeAlpha2: Scalars['String']['input'];
  displayOrder: Scalars['Int']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  remark?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
  userAccount?: InputMaybe<WorldPlaceUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlaceWeathers?: InputMaybe<WorldPlaceWeatherWorldPlaceIdFkeyInverseInput>;
}

export interface WorldPlaceWeatherWorldPlaceWeatherPkeyConnect {
  day: Scalars['Date']['input'];
  worldPlaceId: Scalars['BigInt']['input'];
}

export interface WorldPlaceWeathersConnection {
  __typename?: 'WorldPlaceWeathersConnection';
  edges: Array<WorldPlaceWeathersEdge>;
  nodes: Array<WorldPlaceWeather>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface WorldPlaceWeathersEdge {
  __typename?: 'WorldPlaceWeathersEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: WorldPlaceWeather;
}

export enum WorldPlaceWeathersOrderBy {
  CONDITION_ASC = 'CONDITION_ASC',
  CONDITION_DESC = 'CONDITION_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DAY_ASC = 'DAY_ASC',
  DAY_DESC = 'DAY_DESC',
  HUMIDITY_ASC = 'HUMIDITY_ASC',
  HUMIDITY_DESC = 'HUMIDITY_DESC',
  ICON_ASC = 'ICON_ASC',
  ICON_DESC = 'ICON_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  TEMP_MAX_ASC = 'TEMP_MAX_ASC',
  TEMP_MAX_DESC = 'TEMP_MAX_DESC',
  TEMP_MIN_ASC = 'TEMP_MIN_ASC',
  TEMP_MIN_DESC = 'TEMP_MIN_DESC',
  TIME_SUNRISE_ASC = 'TIME_SUNRISE_ASC',
  TIME_SUNRISE_DESC = 'TIME_SUNRISE_DESC',
  TIME_SUNSET_ASC = 'TIME_SUNSET_ASC',
  TIME_SUNSET_DESC = 'TIME_SUNSET_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
  WORLD_PLACE_BY_WORLD_PLACE_ID__COUNTRY_CODE_ALPHA2_ASC = 'WORLD_PLACE_BY_WORLD_PLACE_ID__COUNTRY_CODE_ALPHA2_ASC',
  WORLD_PLACE_BY_WORLD_PLACE_ID__COUNTRY_CODE_ALPHA2_DESC = 'WORLD_PLACE_BY_WORLD_PLACE_ID__COUNTRY_CODE_ALPHA2_DESC',
  WORLD_PLACE_BY_WORLD_PLACE_ID__DATE_CREATED_ASC = 'WORLD_PLACE_BY_WORLD_PLACE_ID__DATE_CREATED_ASC',
  WORLD_PLACE_BY_WORLD_PLACE_ID__DATE_CREATED_DESC = 'WORLD_PLACE_BY_WORLD_PLACE_ID__DATE_CREATED_DESC',
  WORLD_PLACE_BY_WORLD_PLACE_ID__DATE_UPDATED_ASC = 'WORLD_PLACE_BY_WORLD_PLACE_ID__DATE_UPDATED_ASC',
  WORLD_PLACE_BY_WORLD_PLACE_ID__DATE_UPDATED_DESC = 'WORLD_PLACE_BY_WORLD_PLACE_ID__DATE_UPDATED_DESC',
  WORLD_PLACE_BY_WORLD_PLACE_ID__DISPLAY_ORDER_ASC = 'WORLD_PLACE_BY_WORLD_PLACE_ID__DISPLAY_ORDER_ASC',
  WORLD_PLACE_BY_WORLD_PLACE_ID__DISPLAY_ORDER_DESC = 'WORLD_PLACE_BY_WORLD_PLACE_ID__DISPLAY_ORDER_DESC',
  WORLD_PLACE_BY_WORLD_PLACE_ID__LATITUDE_ASC = 'WORLD_PLACE_BY_WORLD_PLACE_ID__LATITUDE_ASC',
  WORLD_PLACE_BY_WORLD_PLACE_ID__LATITUDE_DESC = 'WORLD_PLACE_BY_WORLD_PLACE_ID__LATITUDE_DESC',
  WORLD_PLACE_BY_WORLD_PLACE_ID__LONGITUDE_ASC = 'WORLD_PLACE_BY_WORLD_PLACE_ID__LONGITUDE_ASC',
  WORLD_PLACE_BY_WORLD_PLACE_ID__LONGITUDE_DESC = 'WORLD_PLACE_BY_WORLD_PLACE_ID__LONGITUDE_DESC',
  WORLD_PLACE_BY_WORLD_PLACE_ID__NAME_ASC = 'WORLD_PLACE_BY_WORLD_PLACE_ID__NAME_ASC',
  WORLD_PLACE_BY_WORLD_PLACE_ID__NAME_DESC = 'WORLD_PLACE_BY_WORLD_PLACE_ID__NAME_DESC',
  WORLD_PLACE_BY_WORLD_PLACE_ID__REMARK_ASC = 'WORLD_PLACE_BY_WORLD_PLACE_ID__REMARK_ASC',
  WORLD_PLACE_BY_WORLD_PLACE_ID__REMARK_DESC = 'WORLD_PLACE_BY_WORLD_PLACE_ID__REMARK_DESC',
  WORLD_PLACE_BY_WORLD_PLACE_ID__TIMEZONE_ASC = 'WORLD_PLACE_BY_WORLD_PLACE_ID__TIMEZONE_ASC',
  WORLD_PLACE_BY_WORLD_PLACE_ID__TIMEZONE_DESC = 'WORLD_PLACE_BY_WORLD_PLACE_ID__TIMEZONE_DESC',
  WORLD_PLACE_BY_WORLD_PLACE_ID__USER_CREATED_ASC = 'WORLD_PLACE_BY_WORLD_PLACE_ID__USER_CREATED_ASC',
  WORLD_PLACE_BY_WORLD_PLACE_ID__USER_CREATED_DESC = 'WORLD_PLACE_BY_WORLD_PLACE_ID__USER_CREATED_DESC',
  WORLD_PLACE_BY_WORLD_PLACE_ID__USER_UPDATED_ASC = 'WORLD_PLACE_BY_WORLD_PLACE_ID__USER_UPDATED_ASC',
  WORLD_PLACE_BY_WORLD_PLACE_ID__USER_UPDATED_DESC = 'WORLD_PLACE_BY_WORLD_PLACE_ID__USER_UPDATED_DESC',
  WORLD_PLACE_BY_WORLD_PLACE_ID__WORLD_PLACE_ID_ASC = 'WORLD_PLACE_BY_WORLD_PLACE_ID__WORLD_PLACE_ID_ASC',
  WORLD_PLACE_BY_WORLD_PLACE_ID__WORLD_PLACE_ID_DESC = 'WORLD_PLACE_BY_WORLD_PLACE_ID__WORLD_PLACE_ID_DESC',
  WORLD_PLACE_ID_ASC = 'WORLD_PLACE_ID_ASC',
  WORLD_PLACE_ID_DESC = 'WORLD_PLACE_ID_DESC',
}

export interface WorldPlaceWorldPlaceDisplayOrderKeyConnect {
  displayOrder: Scalars['Int']['input'];
}

export interface WorldPlaceWorldPlaceDisplayOrderKeyDelete {
  displayOrder: Scalars['Int']['input'];
}

export interface WorldPlaceWorldPlacePkeyConnect {
  worldPlaceId: Scalars['BigInt']['input'];
}

export interface WorldPlaceWorldPlacePkeyDelete {
  worldPlaceId: Scalars['BigInt']['input'];
}

export interface WorldPlacesConnection {
  __typename?: 'WorldPlacesConnection';
  edges: Array<WorldPlacesEdge>;
  nodes: Array<WorldPlace>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface WorldPlacesEdge {
  __typename?: 'WorldPlacesEdge';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  node: WorldPlace;
}

export enum WorldPlacesOrderBy {
  COUNTRY_CODE_ALPHA2_ASC = 'COUNTRY_CODE_ALPHA2_ASC',
  COUNTRY_CODE_ALPHA2_DESC = 'COUNTRY_CODE_ALPHA2_DESC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  DATE_UPDATED_ASC = 'DATE_UPDATED_ASC',
  DATE_UPDATED_DESC = 'DATE_UPDATED_DESC',
  DISPLAY_ORDER_ASC = 'DISPLAY_ORDER_ASC',
  DISPLAY_ORDER_DESC = 'DISPLAY_ORDER_DESC',
  LATITUDE_ASC = 'LATITUDE_ASC',
  LATITUDE_DESC = 'LATITUDE_DESC',
  LONGITUDE_ASC = 'LONGITUDE_ASC',
  LONGITUDE_DESC = 'LONGITUDE_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  REMARK_ASC = 'REMARK_ASC',
  REMARK_DESC = 'REMARK_DESC',
  TIMEZONE_ASC = 'TIMEZONE_ASC',
  TIMEZONE_DESC = 'TIMEZONE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_CREATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_CREATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_CREATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_CREATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_CREATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_CREATED__USER_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DATE_UPDATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__DISPLAY_NAME_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__EMAIL_ADDRESS_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__GOOGLE_PROFILE_PICTURE_URL_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__IS_ENABLED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__REMARK_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__ROLE_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_ACCOUNT_ID_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_CREATED_DESC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_ASC',
  USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC = 'USER_ACCOUNT_BY_USER_UPDATED__USER_UPDATED_DESC',
  USER_CREATED_ASC = 'USER_CREATED_ASC',
  USER_CREATED_DESC = 'USER_CREATED_DESC',
  USER_UPDATED_ASC = 'USER_UPDATED_ASC',
  USER_UPDATED_DESC = 'USER_UPDATED_DESC',
  WORLD_PLACE_ID_ASC = 'WORLD_PLACE_ID_ASC',
  WORLD_PLACE_ID_DESC = 'WORLD_PLACE_ID_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__COUNT_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__COUNT_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__COUNT_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__COUNT_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_CONDITION_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_CONDITION_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_CONDITION_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_CONDITION_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_DATE_CREATED_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_DATE_CREATED_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_DATE_CREATED_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_DATE_CREATED_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_DATE_UPDATED_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_DATE_UPDATED_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_DATE_UPDATED_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_DATE_UPDATED_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_DAY_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_DAY_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_DAY_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_DAY_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_HUMIDITY_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_HUMIDITY_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_HUMIDITY_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_HUMIDITY_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_ICON_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_ICON_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_ICON_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_ICON_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_REMARK_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_REMARK_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_REMARK_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_REMARK_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_TEMP_MAX_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_TEMP_MAX_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_TEMP_MAX_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_TEMP_MAX_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_TEMP_MIN_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_TEMP_MIN_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_TEMP_MIN_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_TEMP_MIN_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_TIME_SUNRISE_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_TIME_SUNRISE_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_TIME_SUNRISE_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_TIME_SUNRISE_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_TIME_SUNSET_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_TIME_SUNSET_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_TIME_SUNSET_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_TIME_SUNSET_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_USER_CREATED_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_USER_CREATED_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_USER_CREATED_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_USER_CREATED_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_USER_UPDATED_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_USER_UPDATED_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_USER_UPDATED_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_USER_UPDATED_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_WORLD_PLACE_ID_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_WORLD_PLACE_ID_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_WORLD_PLACE_ID_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MAX_WORLD_PLACE_ID_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_CONDITION_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_CONDITION_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_CONDITION_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_CONDITION_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_DATE_CREATED_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_DATE_CREATED_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_DATE_CREATED_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_DATE_CREATED_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_DATE_UPDATED_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_DATE_UPDATED_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_DATE_UPDATED_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_DATE_UPDATED_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_DAY_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_DAY_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_DAY_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_DAY_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_HUMIDITY_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_HUMIDITY_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_HUMIDITY_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_HUMIDITY_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_ICON_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_ICON_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_ICON_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_ICON_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_REMARK_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_REMARK_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_REMARK_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_REMARK_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_TEMP_MAX_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_TEMP_MAX_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_TEMP_MAX_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_TEMP_MAX_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_TEMP_MIN_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_TEMP_MIN_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_TEMP_MIN_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_TEMP_MIN_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_TIME_SUNRISE_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_TIME_SUNRISE_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_TIME_SUNRISE_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_TIME_SUNRISE_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_TIME_SUNSET_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_TIME_SUNSET_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_TIME_SUNSET_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_TIME_SUNSET_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_USER_CREATED_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_USER_CREATED_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_USER_CREATED_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_USER_CREATED_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_USER_UPDATED_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_USER_UPDATED_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_USER_UPDATED_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_USER_UPDATED_DESC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_WORLD_PLACE_ID_ASC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_WORLD_PLACE_ID_ASC',
  WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_WORLD_PLACE_ID_DESC = 'WORLD_PLACE_WEATHERS_BY_WORLD_PLACE_ID__MIN_WORLD_PLACE_ID_DESC',
}

export interface YearQuarter {
  __typename?: 'YearQuarter';
  dateEnd?: Maybe<Scalars['Date']['output']>;
  dateStart?: Maybe<Scalars['Date']['output']>;
  quarter?: Maybe<PerformanceReviewQuarter>;
  year?: Maybe<Scalars['Int']['output']>;
}

export interface UpdateBankOnBankForBankCountryCodeAlpha3FkeyPatch {
  bankCode?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<BankCountryCodeAlpha3FkeyInput>;
  employeesToBankIdUsingBankId?: InputMaybe<EmployeeBankIdFkeyInverseInput>;
  employeesToConsultingFeeBankIdUsingBankId?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInverseInput>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BankUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateBankOnBankForBankUserCreatedFkeyPatch {
  bankCode?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<BankCountryCodeAlpha3FkeyInput>;
  countryCodeAlpha3?: InputMaybe<Scalars['String']['input']>;
  employeesToBankIdUsingBankId?: InputMaybe<EmployeeBankIdFkeyInverseInput>;
  employeesToConsultingFeeBankIdUsingBankId?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInverseInput>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BankUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateBankOnBankForBankUserUpdatedFkeyPatch {
  bankCode?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<BankCountryCodeAlpha3FkeyInput>;
  countryCodeAlpha3?: InputMaybe<Scalars['String']['input']>;
  employeesToBankIdUsingBankId?: InputMaybe<EmployeeBankIdFkeyInverseInput>;
  employeesToConsultingFeeBankIdUsingBankId?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInverseInput>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BankUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateBankOnEmployeeForEmployeeConsultingFeeBankIdFkeyPatch {
  bankCode?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<BankCountryCodeAlpha3FkeyInput>;
  countryCodeAlpha3?: InputMaybe<Scalars['String']['input']>;
  employeesToBankIdUsingBankId?: InputMaybe<EmployeeBankIdFkeyInverseInput>;
  employeesToConsultingFeeBankIdUsingBankId?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInverseInput>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BankUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateBusinessUnitOnBusinessUnitForBusinessUnitUserCreatedFkeyPatch {
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInverseInput>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BusinessUnitUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateBusinessUnitOnBusinessUnitForBusinessUnitUserUpdatedFkeyPatch {
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInverseInput>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BusinessUnitUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateBusinessUnitOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey8Patch {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInverseInput>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BusinessUnitUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateBusinessUnitOnEmployeeTpHistoryForEmployeeTpHistoryBusinessUnitIdFkeyPatch {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInverseInput>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BusinessUnitUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateBusinessUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitBusinessUnitIdFkeyPatch {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInverseInput>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BusinessUnitUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateBusinessUnitOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyPatch {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInverseInput>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BusinessUnitUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateBusinessUnitOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyPatch {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInverseInput>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BusinessUnitUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateBusinessUnitOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionBusinessUnitIdFkeyPatch {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInverseInput>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BusinessUnitUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateBusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitBusinessUnitIdFkeyPatch {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInverseInput>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<BusinessUnitUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCityOnCityForCityCountryCodeAlpha2FkeyPatch {
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<CityCountryCodeAlpha2FkeyInput>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey6InverseInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  offices?: InputMaybe<OfficeCityIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCityIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CityUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  utcOffset?: InputMaybe<IntervalInput>;
}

export interface UpdateCityOnCityForCityUserCreatedFkeyPatch {
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<CityCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey6InverseInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  offices?: InputMaybe<OfficeCityIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCityIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CityUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  utcOffset?: InputMaybe<IntervalInput>;
}

export interface UpdateCityOnCityForCityUserUpdatedFkeyPatch {
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<CityCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey6InverseInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  offices?: InputMaybe<OfficeCityIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCityIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CityUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  utcOffset?: InputMaybe<IntervalInput>;
}

export interface UpdateCityOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey6Patch {
  country?: InputMaybe<CityCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey6InverseInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  offices?: InputMaybe<OfficeCityIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCityIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CityUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  utcOffset?: InputMaybe<IntervalInput>;
}

export interface UpdateCityOnOfficeForOfficeCityIdFkeyPatch {
  country?: InputMaybe<CityCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey6InverseInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  offices?: InputMaybe<OfficeCityIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCityIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CityUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  utcOffset?: InputMaybe<IntervalInput>;
}

export interface UpdateCityOnRelationCityCompanyForRelationCityCompanyCityIdFkeyPatch {
  country?: InputMaybe<CityCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey6InverseInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  offices?: InputMaybe<OfficeCityIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCityIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CityUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  utcOffset?: InputMaybe<IntervalInput>;
}

export interface UpdateCompanyExternalAccountDisableOnCompanyExternalAccountDisableForCompanyExternalAccountDisableCompanyIdFkeyPatch {
  company?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInput>;
  externalAccountType?: InputMaybe<CompanyExternalAccountDisableExternalAccountTypeKeyFkeyInput>;
  externalAccountTypeKey?: InputMaybe<ExternalAccountEmployeeType>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyExternalAccountDisableOnCompanyExternalAccountDisableForCompanyExternalAccountDisableExternalAccountTypeKeyFkeyPatch {
  company?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  externalAccountType?: InputMaybe<CompanyExternalAccountDisableExternalAccountTypeKeyFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyExternalAccountDisableOnCompanyExternalAccountDisableForCompanyExternalAccountDisableUserCreatedFkeyPatch {
  company?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  externalAccountType?: InputMaybe<CompanyExternalAccountDisableExternalAccountTypeKeyFkeyInput>;
  externalAccountTypeKey?: InputMaybe<ExternalAccountEmployeeType>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInput>;
}

export interface UpdateCompanyGlobalHrOnCompanyGlobalHrForCompanyGlobalHrEmployeeIdFkeyPatch {
  companyGlobalHrId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyGlobalHrOnCompanyGlobalHrForCompanyGlobalHrUserCreatedFkeyPatch {
  companyGlobalHrId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyGlobalHrOnCompanyGlobalHrForCompanyGlobalHrUserUpdatedFkeyPatch {
  companyGlobalHrId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyGroupOnCompanyForCompanyCompanyGroupIdFkeyPatch {
  companies?: InputMaybe<CompanyCompanyGroupIdFkeyInverseInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  relationCompanyGroupEmailDomains?: InputMaybe<RelationCompanyGroupEmailDomainCompanyGroupIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyGroupUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyGroupOnCompanyGroupForCompanyGroupUserCreatedFkeyPatch {
  companies?: InputMaybe<CompanyCompanyGroupIdFkeyInverseInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  relationCompanyGroupEmailDomains?: InputMaybe<RelationCompanyGroupEmailDomainCompanyGroupIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyGroupUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyGroupOnCompanyGroupForCompanyGroupUserUpdatedFkeyPatch {
  companies?: InputMaybe<CompanyCompanyGroupIdFkeyInverseInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  relationCompanyGroupEmailDomains?: InputMaybe<RelationCompanyGroupEmailDomainCompanyGroupIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyGroupUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyGroupOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainCompanyGroupIdFkeyPatch {
  companies?: InputMaybe<CompanyCompanyGroupIdFkeyInverseInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  relationCompanyGroupEmailDomains?: InputMaybe<RelationCompanyGroupEmailDomainCompanyGroupIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyGroupUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyLocalHrOnCompanyLocalHrForCompanyLocalHrCompanyIdFkeyPatch {
  company?: InputMaybe<CompanyLocalHrCompanyIdFkeyInput>;
  companyLocalHrId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyLocalHrOnCompanyLocalHrForCompanyLocalHrEmployeeIdFkeyPatch {
  company?: InputMaybe<CompanyLocalHrCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyLocalHrOnCompanyLocalHrForCompanyLocalHrUserCreatedFkeyPatch {
  company?: InputMaybe<CompanyLocalHrCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyLocalHrOnCompanyLocalHrForCompanyLocalHrUserUpdatedFkeyPatch {
  company?: InputMaybe<CompanyLocalHrCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyOnCompanyExternalAccountDisableForCompanyExternalAccountDisableCompanyIdFkeyPatch {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyOnCompanyForCompanyCompanyGroupIdFkeyPatch {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyOnCompanyForCompanyCountryCodeAlpha2FkeyPatch {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyOnCompanyForCompanyUserCreatedFkeyPatch {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyOnCompanyForCompanyUserUpdatedFkeyPatch {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
}

export interface UpdateCompanyOnCompanyLocalHrForCompanyLocalHrCompanyIdFkeyPatch {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey5Patch {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyOnEmployeeTpHistoryForEmployeeTpHistoryCompanyIdFkeyPatch {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey2Patch {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitCompanyIdFkeyPatch {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageCompanyIdFkeyPatch {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingCompanyIdFkeyPatch {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyOnProbationReviewEnableForProbationReviewEnableCompanyIdFkeyPatch {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey1Patch {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyOnRelationCityCompanyForRelationCityCompanyCompanyIdFkeyPatch {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitCompanyIdFkeyPatch {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyOnRelationCompanyOfficeForRelationCompanyOfficeCompanyIdFkeyPatch {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCompanyOnUserHrManagingCompanyForUserHrManagingCompanyCompanyIdFkeyPatch {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  companyClosedDate?: InputMaybe<Scalars['Date']['input']>;
  companyEstablishedDate?: InputMaybe<Scalars['Date']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableCompanyIdFkeyInverseInput>;
  companyGroup?: InputMaybe<CompanyCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrCompanyIdFkeyInverseInput>;
  country?: InputMaybe<CompanyCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  employeeCodePrefix?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2InverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  googleWorkspaceOrgUnits?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInverseInput>;
  notifyPeriodContractEnd?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterUsages?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInverseInput>;
  performanceReviewMasterWeightings?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInverseInput>;
  relationCityCompanies?: InputMaybe<RelationCityCompanyCompanyIdFkeyInverseInput>;
  relationCompanyBusinessUnits?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanies?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInverseInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateContractTypeOnContractTypeForContractTypeUserCreatedFkeyPatch {
  code?: InputMaybe<Scalars['String']['input']>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey1InverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey2InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableContractTypeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ContractTypeUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateContractTypeOnContractTypeForContractTypeUserUpdatedFkeyPatch {
  code?: InputMaybe<Scalars['String']['input']>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey1InverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey2InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableContractTypeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ContractTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateContractTypeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey2Patch {
  code?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey1InverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey2InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableContractTypeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ContractTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateContractTypeOnEmployeeTpHistoryForEmployeeTpHistoryContractTypeIdFkeyPatch {
  code?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey1InverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey2InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableContractTypeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ContractTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateContractTypeOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey1Patch {
  code?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey1InverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey2InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableContractTypeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ContractTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateContractTypeOnProbationReviewEnableForProbationReviewEnableContractTypeIdFkeyPatch {
  code?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey1InverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey2InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableContractTypeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ContractTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateContractTypeOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey2Patch {
  code?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInverseInput>;
  employeeWorkingDayStatisticsAlls?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey1InverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey2InverseInput>;
  probationReviewEnables?: InputMaybe<ProbationReviewEnableContractTypeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ContractTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCountryOnBankForBankCountryCodeAlpha3FkeyPatch {
  banks?: InputMaybe<BankCountryCodeAlpha3FkeyInverseInput>;
  cities?: InputMaybe<CityCountryCodeAlpha2FkeyInverseInput>;
  companies?: InputMaybe<CompanyCountryCodeAlpha2FkeyInverseInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  countryCodeNumeric?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInverseInput>;
  employeeIdTypes?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInverseInput>;
  employeesToMobileNumberCountryIdUsingCountryCodeAlpha2?: InputMaybe<EmployeeMobileNumberCountryIdFkeyInverseInput>;
  employeesToNationalityUsingCountryCodeAlpha3?: InputMaybe<EmployeeNationalityFkeyInverseInput>;
  employeesToWorkingLocationCountryCodeAlpha2UsingCountryCodeAlpha2?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0InverseInput>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  telephoneCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCountryOnCityForCityCountryCodeAlpha2FkeyPatch {
  banks?: InputMaybe<BankCountryCodeAlpha3FkeyInverseInput>;
  cities?: InputMaybe<CityCountryCodeAlpha2FkeyInverseInput>;
  companies?: InputMaybe<CompanyCountryCodeAlpha2FkeyInverseInput>;
  countryCodeAlpha3?: InputMaybe<Scalars['String']['input']>;
  countryCodeNumeric?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInverseInput>;
  employeeIdTypes?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInverseInput>;
  employeesToMobileNumberCountryIdUsingCountryCodeAlpha2?: InputMaybe<EmployeeMobileNumberCountryIdFkeyInverseInput>;
  employeesToNationalityUsingCountryCodeAlpha3?: InputMaybe<EmployeeNationalityFkeyInverseInput>;
  employeesToWorkingLocationCountryCodeAlpha2UsingCountryCodeAlpha2?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0InverseInput>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  telephoneCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCountryOnCompanyForCompanyCountryCodeAlpha2FkeyPatch {
  banks?: InputMaybe<BankCountryCodeAlpha3FkeyInverseInput>;
  cities?: InputMaybe<CityCountryCodeAlpha2FkeyInverseInput>;
  companies?: InputMaybe<CompanyCountryCodeAlpha2FkeyInverseInput>;
  countryCodeAlpha3?: InputMaybe<Scalars['String']['input']>;
  countryCodeNumeric?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInverseInput>;
  employeeIdTypes?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInverseInput>;
  employeesToMobileNumberCountryIdUsingCountryCodeAlpha2?: InputMaybe<EmployeeMobileNumberCountryIdFkeyInverseInput>;
  employeesToNationalityUsingCountryCodeAlpha3?: InputMaybe<EmployeeNationalityFkeyInverseInput>;
  employeesToWorkingLocationCountryCodeAlpha2UsingCountryCodeAlpha2?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0InverseInput>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  telephoneCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCountryOnCountryForCountryUserCreatedFkeyPatch {
  banks?: InputMaybe<BankCountryCodeAlpha3FkeyInverseInput>;
  cities?: InputMaybe<CityCountryCodeAlpha2FkeyInverseInput>;
  companies?: InputMaybe<CompanyCountryCodeAlpha2FkeyInverseInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha3?: InputMaybe<Scalars['String']['input']>;
  countryCodeNumeric?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInverseInput>;
  employeeIdTypes?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInverseInput>;
  employeesToMobileNumberCountryIdUsingCountryCodeAlpha2?: InputMaybe<EmployeeMobileNumberCountryIdFkeyInverseInput>;
  employeesToNationalityUsingCountryCodeAlpha3?: InputMaybe<EmployeeNationalityFkeyInverseInput>;
  employeesToWorkingLocationCountryCodeAlpha2UsingCountryCodeAlpha2?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0InverseInput>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  telephoneCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CountryUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCountryOnCountryForCountryUserUpdatedFkeyPatch {
  banks?: InputMaybe<BankCountryCodeAlpha3FkeyInverseInput>;
  cities?: InputMaybe<CityCountryCodeAlpha2FkeyInverseInput>;
  companies?: InputMaybe<CompanyCountryCodeAlpha2FkeyInverseInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha3?: InputMaybe<Scalars['String']['input']>;
  countryCodeNumeric?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInverseInput>;
  employeeIdTypes?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInverseInput>;
  employeesToMobileNumberCountryIdUsingCountryCodeAlpha2?: InputMaybe<EmployeeMobileNumberCountryIdFkeyInverseInput>;
  employeesToNationalityUsingCountryCodeAlpha3?: InputMaybe<EmployeeNationalityFkeyInverseInput>;
  employeesToWorkingLocationCountryCodeAlpha2UsingCountryCodeAlpha2?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0InverseInput>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  telephoneCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCountryOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey4Patch {
  banks?: InputMaybe<BankCountryCodeAlpha3FkeyInverseInput>;
  cities?: InputMaybe<CityCountryCodeAlpha2FkeyInverseInput>;
  companies?: InputMaybe<CompanyCountryCodeAlpha2FkeyInverseInput>;
  countryCodeAlpha3?: InputMaybe<Scalars['String']['input']>;
  countryCodeNumeric?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInverseInput>;
  employeeIdTypes?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInverseInput>;
  employeesToMobileNumberCountryIdUsingCountryCodeAlpha2?: InputMaybe<EmployeeMobileNumberCountryIdFkeyInverseInput>;
  employeesToNationalityUsingCountryCodeAlpha3?: InputMaybe<EmployeeNationalityFkeyInverseInput>;
  employeesToWorkingLocationCountryCodeAlpha2UsingCountryCodeAlpha2?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0InverseInput>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  telephoneCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCountryOnEmployeeEmergencyContactForEmployeeEmergencyContactTelephoneNumberCountryIdFkeyPatch {
  banks?: InputMaybe<BankCountryCodeAlpha3FkeyInverseInput>;
  cities?: InputMaybe<CityCountryCodeAlpha2FkeyInverseInput>;
  companies?: InputMaybe<CompanyCountryCodeAlpha2FkeyInverseInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha3?: InputMaybe<Scalars['String']['input']>;
  countryCodeNumeric?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInverseInput>;
  employeeIdTypes?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInverseInput>;
  employeesToMobileNumberCountryIdUsingCountryCodeAlpha2?: InputMaybe<EmployeeMobileNumberCountryIdFkeyInverseInput>;
  employeesToNationalityUsingCountryCodeAlpha3?: InputMaybe<EmployeeNationalityFkeyInverseInput>;
  employeesToWorkingLocationCountryCodeAlpha2UsingCountryCodeAlpha2?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0InverseInput>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  telephoneCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCountryOnEmployeeForEmployeeWorkingLocationCountryCodeAlpha2FkeyPatch {
  banks?: InputMaybe<BankCountryCodeAlpha3FkeyInverseInput>;
  cities?: InputMaybe<CityCountryCodeAlpha2FkeyInverseInput>;
  companies?: InputMaybe<CompanyCountryCodeAlpha2FkeyInverseInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha3?: InputMaybe<Scalars['String']['input']>;
  countryCodeNumeric?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInverseInput>;
  employeeIdTypes?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInverseInput>;
  employeesToMobileNumberCountryIdUsingCountryCodeAlpha2?: InputMaybe<EmployeeMobileNumberCountryIdFkeyInverseInput>;
  employeesToNationalityUsingCountryCodeAlpha3?: InputMaybe<EmployeeNationalityFkeyInverseInput>;
  employeesToWorkingLocationCountryCodeAlpha2UsingCountryCodeAlpha2?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0InverseInput>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  telephoneCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCountryOnEmployeeIdTypeForEmployeeIdTypeCountryCodeAlpha2FkeyPatch {
  banks?: InputMaybe<BankCountryCodeAlpha3FkeyInverseInput>;
  cities?: InputMaybe<CityCountryCodeAlpha2FkeyInverseInput>;
  companies?: InputMaybe<CompanyCountryCodeAlpha2FkeyInverseInput>;
  countryCodeAlpha3?: InputMaybe<Scalars['String']['input']>;
  countryCodeNumeric?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInverseInput>;
  employeeIdTypes?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInverseInput>;
  employeesToMobileNumberCountryIdUsingCountryCodeAlpha2?: InputMaybe<EmployeeMobileNumberCountryIdFkeyInverseInput>;
  employeesToNationalityUsingCountryCodeAlpha3?: InputMaybe<EmployeeNationalityFkeyInverseInput>;
  employeesToWorkingLocationCountryCodeAlpha2UsingCountryCodeAlpha2?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0InverseInput>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  telephoneCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCountryOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey0Patch {
  banks?: InputMaybe<BankCountryCodeAlpha3FkeyInverseInput>;
  cities?: InputMaybe<CityCountryCodeAlpha2FkeyInverseInput>;
  companies?: InputMaybe<CompanyCountryCodeAlpha2FkeyInverseInput>;
  countryCodeAlpha3?: InputMaybe<Scalars['String']['input']>;
  countryCodeNumeric?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInverseInput>;
  employeeIdTypes?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInverseInput>;
  employeesToMobileNumberCountryIdUsingCountryCodeAlpha2?: InputMaybe<EmployeeMobileNumberCountryIdFkeyInverseInput>;
  employeesToNationalityUsingCountryCodeAlpha3?: InputMaybe<EmployeeNationalityFkeyInverseInput>;
  employeesToWorkingLocationCountryCodeAlpha2UsingCountryCodeAlpha2?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0InverseInput>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  telephoneCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCountryOnRelationCurrencyCountryForRelationCurrencyCountryCountryCodeAlpha2FkeyPatch {
  banks?: InputMaybe<BankCountryCodeAlpha3FkeyInverseInput>;
  cities?: InputMaybe<CityCountryCodeAlpha2FkeyInverseInput>;
  companies?: InputMaybe<CompanyCountryCodeAlpha2FkeyInverseInput>;
  countryCodeAlpha3?: InputMaybe<Scalars['String']['input']>;
  countryCodeNumeric?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInverseInput>;
  employeeIdTypes?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInverseInput>;
  employeesToMobileNumberCountryIdUsingCountryCodeAlpha2?: InputMaybe<EmployeeMobileNumberCountryIdFkeyInverseInput>;
  employeesToNationalityUsingCountryCodeAlpha3?: InputMaybe<EmployeeNationalityFkeyInverseInput>;
  employeesToWorkingLocationCountryCodeAlpha2UsingCountryCodeAlpha2?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInverseInput>;
  probationReviewEnableViews?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0InverseInput>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  telephoneCode?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCurrencyOnCurrencyForCurrencyUserCreatedFkeyPatch {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  employees?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInverseInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCurrencyCodeFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CurrencyUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCurrencyOnCurrencyForCurrencyUserUpdatedFkeyPatch {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  employees?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInverseInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCurrencyCodeFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CurrencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCurrencyOnEmployeeForEmployeeConsultingFeeCurrencyIdFkeyPatch {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  employees?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInverseInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCurrencyCodeFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CurrencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateCurrencyOnRelationCurrencyCountryForRelationCurrencyCountryCurrencyCodeFkeyPatch {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  employees?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInverseInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  relationCurrencyCountries?: InputMaybe<RelationCurrencyCountryCurrencyCodeFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<CurrencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateDepartmentOnDepartmentForDepartmentUserCreatedFkeyPatch {
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey10InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DepartmentUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateDepartmentOnDepartmentForDepartmentUserUpdatedFkeyPatch {
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey10InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DepartmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateDepartmentOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey10Patch {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey10InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DepartmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateDepartmentOnEmployeeTpHistoryForEmployeeTpHistoryDepartmentIdFkeyPatch {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey10InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DepartmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateDepartmentOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDepartmentIdFkeyPatch {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey10InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DepartmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDepartmentIdFkeyPatch {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey10InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DepartmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateDivisionOnDivisionForDivisionUserCreatedFkeyPatch {
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionDivisionIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DivisionUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateDivisionOnDivisionForDivisionUserUpdatedFkeyPatch {
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionDivisionIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DivisionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateDivisionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey9Patch {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionDivisionIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DivisionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateDivisionOnEmployeeTpHistoryForEmployeeTpHistoryDivisionIdFkeyPatch {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionDivisionIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DivisionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateDivisionOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDivisionIdFkeyPatch {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionDivisionIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DivisionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateDivisionOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDivisionIdFkeyPatch {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionDivisionIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DivisionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateDivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionDivisionIdFkeyPatch {
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInverseInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyRoles?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisionDepartments?: InputMaybe<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInverseInput>;
  relationBusinessUnitDivisions?: InputMaybe<RelationBusinessUnitDivisionDivisionIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<DivisionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmailDomainOnEmailDomainForEmailDomainUserCreatedFkeyPatch {
  domainName?: InputMaybe<Scalars['String']['input']>;
  emailDomainId?: InputMaybe<Scalars['BigInt']['input']>;
  isSsoLoginAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  relationCompanyGroupEmailDomains?: InputMaybe<RelationCompanyGroupEmailDomainEmailDomainIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmailDomainUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmailDomainOnEmailDomainForEmailDomainUserUpdatedFkeyPatch {
  domainName?: InputMaybe<Scalars['String']['input']>;
  emailDomainId?: InputMaybe<Scalars['BigInt']['input']>;
  isSsoLoginAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  relationCompanyGroupEmailDomains?: InputMaybe<RelationCompanyGroupEmailDomainEmailDomainIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmailDomainUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainEmailDomainIdFkeyPatch {
  domainName?: InputMaybe<Scalars['String']['input']>;
  isSsoLoginAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  relationCompanyGroupEmailDomains?: InputMaybe<RelationCompanyGroupEmailDomainEmailDomainIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmailDomainUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey0Patch {
  businessUnit?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8Input>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  city?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey6Input>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5Input>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2Input>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4Input>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey10Input>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9Input>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0Input>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeLevel?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey3Input>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1Input>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7Input>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey1Patch {
  businessUnit?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8Input>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  city?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey6Input>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5Input>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2Input>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4Input>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey10Input>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9Input>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0Input>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey3Input>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1Input>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7Input>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey2Patch {
  businessUnit?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8Input>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  city?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey6Input>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5Input>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2Input>;
  country?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4Input>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey10Input>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9Input>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0Input>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey3Input>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1Input>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7Input>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey3Patch {
  businessUnit?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8Input>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  city?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey6Input>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5Input>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2Input>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4Input>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey10Input>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9Input>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0Input>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey3Input>;
  employeeTpHistory?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1Input>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7Input>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey4Patch {
  businessUnit?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8Input>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  city?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey6Input>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5Input>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2Input>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4Input>;
  department?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey10Input>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9Input>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0Input>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey3Input>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1Input>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7Input>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey5Patch {
  businessUnit?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8Input>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  city?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey6Input>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5Input>;
  contractType?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2Input>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4Input>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey10Input>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9Input>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0Input>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey3Input>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1Input>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7Input>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey6Patch {
  businessUnit?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8Input>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  city?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey6Input>;
  company?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5Input>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2Input>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4Input>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey10Input>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9Input>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0Input>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey3Input>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1Input>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7Input>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey7Patch {
  businessUnit?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8Input>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  city?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey6Input>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5Input>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2Input>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4Input>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey10Input>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9Input>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0Input>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey3Input>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1Input>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7Input>;
  team?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey8Patch {
  businessUnit?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8Input>;
  city?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey6Input>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5Input>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2Input>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4Input>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey10Input>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9Input>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0Input>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey3Input>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1Input>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7Input>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey9Patch {
  businessUnit?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8Input>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  city?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey6Input>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5Input>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2Input>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4Input>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey10Input>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9Input>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0Input>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey3Input>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1Input>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7Input>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeCurrentPositionOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey10Patch {
  businessUnit?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey8Input>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  city?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey6Input>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey5Input>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey2Input>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey4Input>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey10Input>;
  division?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey9Input>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0Input>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey3Input>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1Input>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7Input>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeDaysByContractTypeOnEmployeeDaysByContractTypeForFakePublicEmployeeDaysByContractTypeForeignKey0Patch {
  contractTypeCode?: InputMaybe<Scalars['String']['input']>;
  contractTypeName?: InputMaybe<Scalars['String']['input']>;
  days?: InputMaybe<Scalars['Int']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0Input>;
  flag?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactEmployeeIdFkeyPatch {
  country?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInput>;
  employee?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInput>;
  employeeEmergencyContactId?: InputMaybe<Scalars['BigInt']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  relationEmergencyContact?: InputMaybe<EmployeeEmergencyContactRelationIdFkeyInput>;
  relationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  telephoneNumber?: InputMaybe<Scalars['String']['input']>;
  telephoneNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactRelationIdFkeyPatch {
  country?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInput>;
  employee?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInput>;
  employeeEmergencyContactId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  relationEmergencyContact?: InputMaybe<EmployeeEmergencyContactRelationIdFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  telephoneNumber?: InputMaybe<Scalars['String']['input']>;
  telephoneNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactTelephoneNumberCountryIdFkeyPatch {
  country?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInput>;
  employee?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInput>;
  employeeEmergencyContactId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  relationEmergencyContact?: InputMaybe<EmployeeEmergencyContactRelationIdFkeyInput>;
  relationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  telephoneNumber?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactUserCreatedFkeyPatch {
  country?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInput>;
  employee?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInput>;
  employeeEmergencyContactId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  relationEmergencyContact?: InputMaybe<EmployeeEmergencyContactRelationIdFkeyInput>;
  relationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  telephoneNumber?: InputMaybe<Scalars['String']['input']>;
  telephoneNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactUserUpdatedFkeyPatch {
  country?: InputMaybe<EmployeeEmergencyContactTelephoneNumberCountryIdFkeyInput>;
  employee?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInput>;
  employeeEmergencyContactId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  relationEmergencyContact?: InputMaybe<EmployeeEmergencyContactRelationIdFkeyInput>;
  relationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  telephoneNumber?: InputMaybe<Scalars['String']['input']>;
  telephoneNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeFileOnEmployeeFileForEmployeeFileEmployeeIdFkeyPatch {
  employee?: InputMaybe<EmployeeFileEmployeeIdFkeyInput>;
  employeeFileId?: InputMaybe<Scalars['BigInt']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileSize?: InputMaybe<Scalars['BigFloat']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  s3Path?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeFileUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userRole?: InputMaybe<EmployeeFileVisibleRoleFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visibleRole?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeFileOnEmployeeFileForEmployeeFileUserCreatedFkeyPatch {
  employee?: InputMaybe<EmployeeFileEmployeeIdFkeyInput>;
  employeeFileId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileSize?: InputMaybe<Scalars['BigFloat']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  s3Path?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeFileUserUpdatedFkeyInput>;
  userRole?: InputMaybe<EmployeeFileVisibleRoleFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visibleRole?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeFileOnEmployeeFileForEmployeeFileUserUpdatedFkeyPatch {
  employee?: InputMaybe<EmployeeFileEmployeeIdFkeyInput>;
  employeeFileId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileSize?: InputMaybe<Scalars['BigFloat']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  s3Path?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeFileUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userRole?: InputMaybe<EmployeeFileVisibleRoleFkeyInput>;
  visibleRole?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeFileOnEmployeeFileForEmployeeFileVisibleRoleFkeyPatch {
  employee?: InputMaybe<EmployeeFileEmployeeIdFkeyInput>;
  employeeFileId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileSize?: InputMaybe<Scalars['BigFloat']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  s3Path?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeFileUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userRole?: InputMaybe<EmployeeFileVisibleRoleFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeGroupLatestOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey0Patch {
  allEmployeeIdList?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  employee?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0Input>;
  employeeStatus?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey1Input>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
}

export interface UpdateEmployeeGroupLatestOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey1Patch {
  allEmployeeIdList?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  employee?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0Input>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeStatus?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey1Input>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface UpdateEmployeeGroupListOnEmployeeGroupListForFakePublicEmployeeGroupListForeignKey0Patch {
  allEmployeeIdList?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  employee?: InputMaybe<FakePublicEmployeeGroupListForeignKey0Input>;
  remark?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeGroupOnEmployeeGroupForEmployeeGroupEmployeeIdFkeyPatch {
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  employee?: InputMaybe<EmployeeGroupEmployeeIdFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeGroupUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeGroupOnEmployeeGroupForEmployeeGroupUserCreatedFkeyPatch {
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  employee?: InputMaybe<EmployeeGroupEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeGroupUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeGroupOnEmployeeGroupForEmployeeGroupUserUpdatedFkeyPatch {
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  employee?: InputMaybe<EmployeeGroupEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeGroupUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeIdTypeOnEmployeeForEmployeeIdTypeFkeyPatch {
  code?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  employees?: InputMaybe<EmployeeIdTypeFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeIdTypeOnEmployeeIdTypeForEmployeeIdTypeCountryCodeAlpha2FkeyPatch {
  code?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  employees?: InputMaybe<EmployeeIdTypeFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeIdTypeOnEmployeeIdTypeForEmployeeIdTypeUserCreatedFkeyPatch {
  code?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  employees?: InputMaybe<EmployeeIdTypeFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeIdTypeOnEmployeeIdTypeForEmployeeIdTypeUserUpdatedFkeyPatch {
  code?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<EmployeeIdTypeCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  employees?: InputMaybe<EmployeeIdTypeFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeLevelOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey3Patch {
  canEditTargetCountry?: InputMaybe<Scalars['Boolean']['input']>;
  canEditTargetIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  canEditTargetTeam?: InputMaybe<Scalars['Boolean']['input']>;
  canSeePerformanceReviewOverallOfAll?: InputMaybe<Scalars['Boolean']['input']>;
  canSeePerformanceReviewOverallOfCountry?: InputMaybe<Scalars['Boolean']['input']>;
  canSeeProbationReviewOfAll?: InputMaybe<Scalars['Boolean']['input']>;
  canSeeProbationReviewOfCountry?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey3InverseInput>;
  employeeLevelOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInverseInput>;
  performanceReviewMasterWeightingDetails?: InputMaybe<PerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  titleContributor?: InputMaybe<Scalars['String']['input']>;
  titleManager?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeLevelUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeLevelOnEmployeeLevelForEmployeeLevelUserCreatedFkeyPatch {
  canEditTargetCountry?: InputMaybe<Scalars['Boolean']['input']>;
  canEditTargetIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  canEditTargetTeam?: InputMaybe<Scalars['Boolean']['input']>;
  canSeePerformanceReviewOverallOfAll?: InputMaybe<Scalars['Boolean']['input']>;
  canSeePerformanceReviewOverallOfCountry?: InputMaybe<Scalars['Boolean']['input']>;
  canSeeProbationReviewOfAll?: InputMaybe<Scalars['Boolean']['input']>;
  canSeeProbationReviewOfCountry?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey3InverseInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevelOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInverseInput>;
  performanceReviewMasterWeightingDetails?: InputMaybe<PerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  titleContributor?: InputMaybe<Scalars['String']['input']>;
  titleManager?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeLevelUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeLevelOnEmployeeLevelForEmployeeLevelUserUpdatedFkeyPatch {
  canEditTargetCountry?: InputMaybe<Scalars['Boolean']['input']>;
  canEditTargetIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  canEditTargetTeam?: InputMaybe<Scalars['Boolean']['input']>;
  canSeePerformanceReviewOverallOfAll?: InputMaybe<Scalars['Boolean']['input']>;
  canSeePerformanceReviewOverallOfCountry?: InputMaybe<Scalars['Boolean']['input']>;
  canSeeProbationReviewOfAll?: InputMaybe<Scalars['Boolean']['input']>;
  canSeeProbationReviewOfCountry?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey3InverseInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevelOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInverseInput>;
  performanceReviewMasterWeightingDetails?: InputMaybe<PerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  titleContributor?: InputMaybe<Scalars['String']['input']>;
  titleManager?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeLevelUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeLevelOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeLevelIdFkeyPatch {
  canEditTargetCountry?: InputMaybe<Scalars['Boolean']['input']>;
  canEditTargetIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  canEditTargetTeam?: InputMaybe<Scalars['Boolean']['input']>;
  canSeePerformanceReviewOverallOfAll?: InputMaybe<Scalars['Boolean']['input']>;
  canSeePerformanceReviewOverallOfCountry?: InputMaybe<Scalars['Boolean']['input']>;
  canSeeProbationReviewOfAll?: InputMaybe<Scalars['Boolean']['input']>;
  canSeeProbationReviewOfCountry?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey3InverseInput>;
  employeeLevelOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInverseInput>;
  performanceReviewMasterWeightingDetails?: InputMaybe<PerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  titleContributor?: InputMaybe<Scalars['String']['input']>;
  titleManager?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeLevelUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeLevelOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyPatch {
  canEditTargetCountry?: InputMaybe<Scalars['Boolean']['input']>;
  canEditTargetIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  canEditTargetTeam?: InputMaybe<Scalars['Boolean']['input']>;
  canSeePerformanceReviewOverallOfAll?: InputMaybe<Scalars['Boolean']['input']>;
  canSeePerformanceReviewOverallOfCountry?: InputMaybe<Scalars['Boolean']['input']>;
  canSeeProbationReviewOfAll?: InputMaybe<Scalars['Boolean']['input']>;
  canSeeProbationReviewOfCountry?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey3InverseInput>;
  employeeLevelOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInverseInput>;
  performanceReviewMasterWeightingDetails?: InputMaybe<PerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  titleContributor?: InputMaybe<Scalars['String']['input']>;
  titleManager?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeLevelUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeListCustomFilterOnEmployeeListCustomFilterForEmployeeListCustomFilterUserCreatedFkeyPatch {
  employeeListCustomFilterId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeListCustomFilterRoles?: InputMaybe<EmployeeListCustomFilterREmployeeListCustomFilterFkeyInverseInput>;
  filter?: InputMaybe<Scalars['JSON']['input']>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeListCustomFilterOnEmployeeListCustomFilterForEmployeeListCustomFilterUserUpdatedFkeyPatch {
  employeeListCustomFilterId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeListCustomFilterRoles?: InputMaybe<EmployeeListCustomFilterREmployeeListCustomFilterFkeyInverseInput>;
  filter?: InputMaybe<Scalars['JSON']['input']>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeListCustomFilterOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterREmployeeListCustomFilterFkeyPatch {
  employeeListCustomFilterRoles?: InputMaybe<EmployeeListCustomFilterREmployeeListCustomFilterFkeyInverseInput>;
  filter?: InputMaybe<Scalars['JSON']['input']>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterREmployeeListCustomFilterFkeyPatch {
  employeeListCustomFilter?: InputMaybe<EmployeeListCustomFilterREmployeeListCustomFilterFkeyInput>;
  employeeListCustomFilterRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  roleName?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userRole?: InputMaybe<EmployeeListCustomFilterRoleRoleNameFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleRoleNameFkeyPatch {
  employeeListCustomFilter?: InputMaybe<EmployeeListCustomFilterREmployeeListCustomFilterFkeyInput>;
  employeeListCustomFilterId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeListCustomFilterRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userRole?: InputMaybe<EmployeeListCustomFilterRoleRoleNameFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserCreatedFkeyPatch {
  employeeListCustomFilter?: InputMaybe<EmployeeListCustomFilterREmployeeListCustomFilterFkeyInput>;
  employeeListCustomFilterId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeListCustomFilterRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  roleName?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInput>;
  userRole?: InputMaybe<EmployeeListCustomFilterRoleRoleNameFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeListCustomFilterRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserUpdatedFkeyPatch {
  employeeListCustomFilter?: InputMaybe<EmployeeListCustomFilterREmployeeListCustomFilterFkeyInput>;
  employeeListCustomFilterId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeListCustomFilterRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  roleName?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userRole?: InputMaybe<EmployeeListCustomFilterRoleRoleNameFkeyInput>;
}

export interface UpdateEmployeeOnCompanyGlobalHrForCompanyGlobalHrEmployeeIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnCompanyLocalHrForCompanyLocalHrEmployeeIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey0Patch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnEmployeeDaysByContractTypeForFakePublicEmployeeDaysByContractTypeForeignKey0Patch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnEmployeeEmergencyContactForEmployeeEmergencyContactEmployeeIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnEmployeeFileForEmployeeFileEmployeeIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnEmployeeForEmployeeApproverIdFkeyPatch {
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnEmployeeForEmployeeBankIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnEmployeeForEmployeeConsultingFeeBankIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnEmployeeForEmployeeConsultingFeeCurrencyIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnEmployeeForEmployeeDottedLineManagerIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnEmployeeForEmployeeIdTypeFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnEmployeeForEmployeeMobileNumberCountryIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnEmployeeForEmployeeNationalityFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnEmployeeForEmployeePrimaryManagerIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnEmployeeForEmployeeResignationReasonIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnEmployeeForEmployeeResignationTypeIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnEmployeeForEmployeeStatusFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnEmployeeForEmployeeUserCreatedFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnEmployeeForEmployeeUserUpdatedFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnEmployeeForEmployeeWorkingLocationCountryCodeAlpha2FkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnEmployeeGroupForEmployeeGroupEmployeeIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey0Patch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnEmployeeGroupListForFakePublicEmployeeGroupListForeignKey0Patch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey0Patch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey0Patch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeEmployeeIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnProbationReviewEvaluationForProbationReviewEvaluationEmployeeIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeOnRelationEmployeeUserAccountForRelationEmployeeUserAccountEmployeeIdFkeyPatch {
  approverId?: InputMaybe<Scalars['BigInt']['input']>;
  bank?: InputMaybe<EmployeeConsultingFeeBankIdFkeyInput>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankBranchName?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['BigInt']['input']>;
  bankRemarks?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr?: InputMaybe<CompanyGlobalHrEmployeeIdFkeyInverseInput>;
  companyLocalHrs?: InputMaybe<CompanyLocalHrEmployeeIdFkeyInverseInput>;
  consultingFeeBankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankBranchName?: InputMaybe<Scalars['String']['input']>;
  consultingFeeBankId?: InputMaybe<Scalars['BigInt']['input']>;
  consultingFeeBankRemarks?: InputMaybe<Scalars['String']['input']>;
  consultingFeeCurrencyId?: InputMaybe<Scalars['String']['input']>;
  consultingFeeType?: InputMaybe<Array<InputMaybe<EmployeeConsultingFeeType>>>;
  country?: InputMaybe<EmployeeWorkingLocationCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<EmployeeConsultingFeeCurrencyIdFkeyInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  currentAddressZipcode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dottedLineManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPosition?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey0InverseInput>;
  employeeDaysByContractTypes?: InputMaybe<FakePublicEmployeeDaysByContractTypeForeignKey0InverseInput>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactEmployeeIdFkeyInverseInput>;
  employeeFiles?: InputMaybe<EmployeeFileEmployeeIdFkeyInverseInput>;
  employeeGroupLatest?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey0InverseInput>;
  employeeGroupLists?: InputMaybe<FakePublicEmployeeGroupListForeignKey0InverseInput>;
  employeeGroups?: InputMaybe<EmployeeGroupEmployeeIdFkeyInverseInput>;
  employeeIdType?: InputMaybe<EmployeeIdTypeFkeyInput>;
  employeeResignationReason?: InputMaybe<EmployeeResignationReasonIdFkeyInput>;
  employeeResignationType?: InputMaybe<EmployeeResignationTypeIdFkeyInput>;
  employeeStatus?: InputMaybe<EmployeeStatusFkeyInput>;
  employeeToApproverId?: InputMaybe<EmployeeApproverIdFkeyInput>;
  employeeToDottedLineManagerId?: InputMaybe<EmployeeDottedLineManagerIdFkeyInput>;
  employeeToPrimaryManagerId?: InputMaybe<EmployeePrimaryManagerIdFkeyInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInverseInput>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0InverseInput>;
  employeeWorkingDayStatisticsAll?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0InverseInput>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInverseInput>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  fullNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<EmployeeGender>;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['BigInt']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  mobileNumberCountryId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nhiDependencies?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  numberOfDependencies?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInverseInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToViewerEmployeeIdUsingEmployeeId?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInverseInput>;
  performanceReviewMasterExcludedEmployees?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInverseInput>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  primaryManagerId?: InputMaybe<Scalars['BigInt']['input']>;
  probationEnd?: InputMaybe<Scalars['Date']['input']>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInverseInput>;
  probationReviewEvaluations?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInverseInput>;
  relationEmployeeUserAccounts?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  resignationDate?: InputMaybe<Scalars['Date']['input']>;
  resignationReasonDetail?: InputMaybe<Scalars['String']['input']>;
  resignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  resignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  sentAccountRequestEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  sentContractEndEmailDate?: InputMaybe<Scalars['Datetime']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  visaExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  visaIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  visaNumber?: InputMaybe<Scalars['String']['input']>;
  visaType?: InputMaybe<Scalars['String']['input']>;
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
  workingLocationCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeResignationReasonOnEmployeeForEmployeeResignationReasonIdFkeyPatch {
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeResignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeResignationReasonOrder?: InputMaybe<Scalars['BigInt']['input']>;
  employees?: InputMaybe<EmployeeResignationReasonIdFkeyInverseInput>;
  isForContract?: InputMaybe<Scalars['Boolean']['input']>;
  isForTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeResignationReasonOnEmployeeResignationReasonForEmployeeResignationReasonUserCreatedFkeyPatch {
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeResignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeResignationReasonOrder?: InputMaybe<Scalars['BigInt']['input']>;
  employees?: InputMaybe<EmployeeResignationReasonIdFkeyInverseInput>;
  isForContract?: InputMaybe<Scalars['Boolean']['input']>;
  isForTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeResignationReasonOnEmployeeResignationReasonForEmployeeResignationReasonUserUpdatedFkeyPatch {
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeResignationReasonId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeResignationReasonOrder?: InputMaybe<Scalars['BigInt']['input']>;
  employees?: InputMaybe<EmployeeResignationReasonIdFkeyInverseInput>;
  isForContract?: InputMaybe<Scalars['Boolean']['input']>;
  isForTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeResignationTypeOnEmployeeForEmployeeResignationTypeIdFkeyPatch {
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeResignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeResignationTypeOrder?: InputMaybe<Scalars['BigInt']['input']>;
  employees?: InputMaybe<EmployeeResignationTypeIdFkeyInverseInput>;
  isForContract?: InputMaybe<Scalars['Boolean']['input']>;
  isForTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeResignationTypeOnEmployeeResignationTypeForEmployeeResignationTypeUserCreatedFkeyPatch {
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeResignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeResignationTypeOrder?: InputMaybe<Scalars['BigInt']['input']>;
  employees?: InputMaybe<EmployeeResignationTypeIdFkeyInverseInput>;
  isForContract?: InputMaybe<Scalars['Boolean']['input']>;
  isForTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeResignationTypeOnEmployeeResignationTypeForEmployeeResignationTypeUserUpdatedFkeyPatch {
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeResignationTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeResignationTypeOrder?: InputMaybe<Scalars['BigInt']['input']>;
  employees?: InputMaybe<EmployeeResignationTypeIdFkeyInverseInput>;
  isForContract?: InputMaybe<Scalars['Boolean']['input']>;
  isForTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeStatusOnEmployeeForEmployeeStatusFkeyPatch {
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeGroupLatests?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey1InverseInput>;
  employeeStatusKey?: InputMaybe<EmployeeStatusKey>;
  employeeStatusOrder?: InputMaybe<Scalars['BigInt']['input']>;
  employees?: InputMaybe<EmployeeStatusFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeStatusUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeStatusOnEmployeeGroupLatestForFakePublicEmployeeGroupLatestForeignKey1Patch {
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeGroupLatests?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey1InverseInput>;
  employeeStatusKey?: InputMaybe<EmployeeStatusKey>;
  employeeStatusOrder?: InputMaybe<Scalars['BigInt']['input']>;
  employees?: InputMaybe<EmployeeStatusFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeStatusUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeStatusOnEmployeeStatusForEmployeeStatusUserCreatedFkeyPatch {
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeGroupLatests?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey1InverseInput>;
  employeeStatusKey?: InputMaybe<EmployeeStatusKey>;
  employeeStatusOrder?: InputMaybe<Scalars['BigInt']['input']>;
  employees?: InputMaybe<EmployeeStatusFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeStatusUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeStatusOnEmployeeStatusForEmployeeStatusUserUpdatedFkeyPatch {
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeGroupLatests?: InputMaybe<FakePublicEmployeeGroupLatestForeignKey1InverseInput>;
  employeeStatusKey?: InputMaybe<EmployeeStatusKey>;
  employeeStatusOrder?: InputMaybe<Scalars['BigInt']['input']>;
  employees?: InputMaybe<EmployeeStatusFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeStatusUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeTpHistoryOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey1Patch {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryBusinessUnitIdFkeyPatch {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryCompanyIdFkeyPatch {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryContractTypeIdFkeyPatch {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryDepartmentIdFkeyPatch {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryDivisionIdFkeyPatch {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeIdFkeyPatch {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryEmployeeLevelIdFkeyPatch {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryOfficeIdFkeyPatch {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryUserCreatedFkeyPatch {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeTpHistoryOnEmployeeTpHistoryForEmployeeTpHistoryUserUpdatedFkeyPatch {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeTpHistoryOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey1Patch {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeTpHistoryOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeTpHistoryIdFkeyPatch {
  businessUnit?: InputMaybe<EmployeeTpHistoryBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<EmployeeTpHistoryCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<EmployeeTpHistoryContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<EmployeeTpHistoryDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<EmployeeTpHistoryDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<EmployeeTpHistoryEmployeeIdFkeyInput>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey1InverseInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevel?: InputMaybe<EmployeeTpHistoryEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTransferHistories?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1InverseInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateEmployeeTransferHistoryOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey0Patch {
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  businessUnitName?: InputMaybe<Scalars['String']['input']>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  cityName?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  contractTypeName?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  departmentName?: InputMaybe<Scalars['String']['input']>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  divisionName?: InputMaybe<Scalars['String']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0Input>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevelName?: InputMaybe<Scalars['String']['input']>;
  employeeLevelOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeTpHistory?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1Input>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  flagDemotion?: InputMaybe<Scalars['Boolean']['input']>;
  flagPromotion?: InputMaybe<Scalars['Boolean']['input']>;
  flagTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  officeName?: InputMaybe<Scalars['String']['input']>;
  previousBusinessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  previousBusinessUnitName?: InputMaybe<Scalars['String']['input']>;
  previousCityId?: InputMaybe<Scalars['BigInt']['input']>;
  previousCityName?: InputMaybe<Scalars['String']['input']>;
  previousCompanyId?: InputMaybe<Scalars['BigInt']['input']>;
  previousCompanyName?: InputMaybe<Scalars['String']['input']>;
  previousContractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  previousContractTypeName?: InputMaybe<Scalars['String']['input']>;
  previousCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  previousDepartmentId?: InputMaybe<Scalars['BigInt']['input']>;
  previousDepartmentName?: InputMaybe<Scalars['String']['input']>;
  previousDivisionId?: InputMaybe<Scalars['BigInt']['input']>;
  previousDivisionName?: InputMaybe<Scalars['String']['input']>;
  previousEmployeeCode?: InputMaybe<Scalars['String']['input']>;
  previousEmployeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  previousEmployeeLevelName?: InputMaybe<Scalars['String']['input']>;
  previousEmployeeLevelOrder?: InputMaybe<Scalars['Int']['input']>;
  previousJobTitle?: InputMaybe<Scalars['String']['input']>;
  previousOfficeId?: InputMaybe<Scalars['BigInt']['input']>;
  previousOfficeName?: InputMaybe<Scalars['String']['input']>;
  previousTeam?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeTransferHistoryOnEmployeeTransferHistoryForFakePublicEmployeeTransferHistoryForeignKey1Patch {
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  businessUnitName?: InputMaybe<Scalars['String']['input']>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  cityName?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  contractTypeName?: InputMaybe<Scalars['String']['input']>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  departmentName?: InputMaybe<Scalars['String']['input']>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  divisionName?: InputMaybe<Scalars['String']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  employee?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey0Input>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeLevelName?: InputMaybe<Scalars['String']['input']>;
  employeeLevelOrder?: InputMaybe<Scalars['Int']['input']>;
  employeeTpHistory?: InputMaybe<FakePublicEmployeeTransferHistoryForeignKey1Input>;
  flagDemotion?: InputMaybe<Scalars['Boolean']['input']>;
  flagPromotion?: InputMaybe<Scalars['Boolean']['input']>;
  flagTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  officeName?: InputMaybe<Scalars['String']['input']>;
  previousBusinessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  previousBusinessUnitName?: InputMaybe<Scalars['String']['input']>;
  previousCityId?: InputMaybe<Scalars['BigInt']['input']>;
  previousCityName?: InputMaybe<Scalars['String']['input']>;
  previousCompanyId?: InputMaybe<Scalars['BigInt']['input']>;
  previousCompanyName?: InputMaybe<Scalars['String']['input']>;
  previousContractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  previousContractTypeName?: InputMaybe<Scalars['String']['input']>;
  previousCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  previousDepartmentId?: InputMaybe<Scalars['BigInt']['input']>;
  previousDepartmentName?: InputMaybe<Scalars['String']['input']>;
  previousDivisionId?: InputMaybe<Scalars['BigInt']['input']>;
  previousDivisionName?: InputMaybe<Scalars['String']['input']>;
  previousEmployeeCode?: InputMaybe<Scalars['String']['input']>;
  previousEmployeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  previousEmployeeLevelName?: InputMaybe<Scalars['String']['input']>;
  previousEmployeeLevelOrder?: InputMaybe<Scalars['Int']['input']>;
  previousJobTitle?: InputMaybe<Scalars['String']['input']>;
  previousOfficeId?: InputMaybe<Scalars['BigInt']['input']>;
  previousOfficeName?: InputMaybe<Scalars['String']['input']>;
  previousTeam?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateEmployeeWorkingDayStatisticsAllOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey0Patch {
  allDates?: InputMaybe<Array<InputMaybe<EmployeeWorkingDayStatisticsRecordAllDateInput>>>;
  baseDate?: InputMaybe<Scalars['Date']['input']>;
  company?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2Input>;
  companyCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey1Input>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0Input>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  workingDays?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdateEmployeeWorkingDayStatisticsAllOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey1Patch {
  allDates?: InputMaybe<Array<InputMaybe<EmployeeWorkingDayStatisticsRecordAllDateInput>>>;
  baseDate?: InputMaybe<Scalars['Date']['input']>;
  company?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2Input>;
  companyCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey1Input>;
  employee?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0Input>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  workingDays?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdateEmployeeWorkingDayStatisticsAllOnEmployeeWorkingDayStatisticsAllForFakePublicEmployeeWorkingDayStatisticsAllForeignKey2Patch {
  allDates?: InputMaybe<Array<InputMaybe<EmployeeWorkingDayStatisticsRecordAllDateInput>>>;
  baseDate?: InputMaybe<Scalars['Date']['input']>;
  company?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey2Input>;
  companyCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  contractType?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey1Input>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<FakePublicEmployeeWorkingDayStatisticsAllForeignKey0Input>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<EmployeeStatusKey>;
  workingDays?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdateExportEmployeeStatusOnExportEmployeeStatusForExportEmployeeStatusUserCreatedFkeyPatch {
  exportEmployeeStatusId?: InputMaybe<Scalars['BigInt']['input']>;
  fileMode?: InputMaybe<Scalars['String']['input']>;
  inProgress?: InputMaybe<Scalars['Boolean']['input']>;
  requestBody?: InputMaybe<Scalars['JSON']['input']>;
  responseDownloadUrl?: InputMaybe<Scalars['String']['input']>;
  responseDownloadUrlUntil?: InputMaybe<Scalars['Datetime']['input']>;
  responseError?: InputMaybe<Scalars['JSON']['input']>;
  responseFilePassword?: InputMaybe<Scalars['String']['input']>;
  responseSuccess?: InputMaybe<Scalars['Boolean']['input']>;
  userAccount?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  uuid4?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateExportEmployeeStatusOnExportEmployeeStatusForExportEmployeeStatusUserUpdatedFkeyPatch {
  exportEmployeeStatusId?: InputMaybe<Scalars['BigInt']['input']>;
  fileMode?: InputMaybe<Scalars['String']['input']>;
  inProgress?: InputMaybe<Scalars['Boolean']['input']>;
  requestBody?: InputMaybe<Scalars['JSON']['input']>;
  responseDownloadUrl?: InputMaybe<Scalars['String']['input']>;
  responseDownloadUrlUntil?: InputMaybe<Scalars['Datetime']['input']>;
  responseError?: InputMaybe<Scalars['JSON']['input']>;
  responseFilePassword?: InputMaybe<Scalars['String']['input']>;
  responseSuccess?: InputMaybe<Scalars['Boolean']['input']>;
  userAccount?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  uuid4?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeEmployeeIdFkeyPatch {
  employee?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInput>;
  externalAccountEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  externalAccountKey?: InputMaybe<Scalars['String']['input']>;
  externalAccountStatusCached?: InputMaybe<Scalars['JSON']['input']>;
  externalAccountType?: InputMaybe<ExternalAccountEmployeeExternalAccountTypeKeyFkeyInput>;
  externalAccountTypeKey?: InputMaybe<ExternalAccountEmployeeType>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeExternalAccountTypeKeyFkeyPatch {
  employee?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  externalAccountEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  externalAccountKey?: InputMaybe<Scalars['String']['input']>;
  externalAccountStatusCached?: InputMaybe<Scalars['JSON']['input']>;
  externalAccountType?: InputMaybe<ExternalAccountEmployeeExternalAccountTypeKeyFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeUserCreatedFkeyPatch {
  employee?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  externalAccountEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  externalAccountKey?: InputMaybe<Scalars['String']['input']>;
  externalAccountStatusCached?: InputMaybe<Scalars['JSON']['input']>;
  externalAccountType?: InputMaybe<ExternalAccountEmployeeExternalAccountTypeKeyFkeyInput>;
  externalAccountTypeKey?: InputMaybe<ExternalAccountEmployeeType>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateExternalAccountEmployeeOnExternalAccountEmployeeForExternalAccountEmployeeUserUpdatedFkeyPatch {
  employee?: InputMaybe<ExternalAccountEmployeeEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  externalAccountEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  externalAccountKey?: InputMaybe<Scalars['String']['input']>;
  externalAccountStatusCached?: InputMaybe<Scalars['JSON']['input']>;
  externalAccountType?: InputMaybe<ExternalAccountEmployeeExternalAccountTypeKeyFkeyInput>;
  externalAccountTypeKey?: InputMaybe<ExternalAccountEmployeeType>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateExternalAccountTypeOnCompanyExternalAccountDisableForCompanyExternalAccountDisableExternalAccountTypeKeyFkeyPatch {
  apiReferenceUrl?: InputMaybe<Scalars['String']['input']>;
  apiSettingUrl?: InputMaybe<Scalars['String']['input']>;
  authInfo?: InputMaybe<Scalars['JSON']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableExternalAccountTypeKeyFkeyInverseInput>;
  consoleReferenceUrl?: InputMaybe<Scalars['String']['input']>;
  consoleSettingUrl?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeExternalAccountTypeKeyFkeyInverseInput>;
  iconBase64?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<ExternalAccountEmployeeType>;
  name?: InputMaybe<Scalars['String']['input']>;
  supportAutomaticallyCreated?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface UpdateExternalAccountTypeOnExternalAccountEmployeeForExternalAccountEmployeeExternalAccountTypeKeyFkeyPatch {
  apiReferenceUrl?: InputMaybe<Scalars['String']['input']>;
  apiSettingUrl?: InputMaybe<Scalars['String']['input']>;
  authInfo?: InputMaybe<Scalars['JSON']['input']>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableExternalAccountTypeKeyFkeyInverseInput>;
  consoleReferenceUrl?: InputMaybe<Scalars['String']['input']>;
  consoleSettingUrl?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  externalAccountEmployees?: InputMaybe<ExternalAccountEmployeeExternalAccountTypeKeyFkeyInverseInput>;
  iconBase64?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<ExternalAccountEmployeeType>;
  name?: InputMaybe<Scalars['String']['input']>;
  supportAutomaticallyCreated?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface UpdateGoogleWorkspaceOrgUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitBusinessUnitIdFkeyPatch {
  businessUnit?: InputMaybe<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInput>;
  company?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  googleWorkspaceOrgUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  orgUnitPath?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdateGoogleWorkspaceOrgUnitOnGoogleWorkspaceOrgUnitForGoogleWorkspaceOrgUnitCompanyIdFkeyPatch {
  businessUnit?: InputMaybe<GoogleWorkspaceOrgUnitBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<GoogleWorkspaceOrgUnitCompanyIdFkeyInput>;
  googleWorkspaceOrgUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  orgUnitPath?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdateImportEmployeeStatusOnImportEmployeeStatusForImportEmployeeStatusUserCreatedFkeyPatch {
  fileMode?: InputMaybe<Scalars['String']['input']>;
  googleAuth?: InputMaybe<Scalars['JSON']['input']>;
  importEmployeeStatusId?: InputMaybe<Scalars['BigInt']['input']>;
  inProgress?: InputMaybe<Scalars['Boolean']['input']>;
  progressLoopCount?: InputMaybe<Scalars['Int']['input']>;
  progressProcessedCount?: InputMaybe<Scalars['Int']['input']>;
  progressProcessedCountOfLoop?: InputMaybe<Scalars['Int']['input']>;
  progressShouldStop?: InputMaybe<Scalars['Boolean']['input']>;
  progressTotalCount?: InputMaybe<Scalars['Int']['input']>;
  progressTotalCountOfLoop?: InputMaybe<Scalars['Int']['input']>;
  requestCreateUnsatisfiedMasters?: InputMaybe<Scalars['Boolean']['input']>;
  requestOverwriteExisting?: InputMaybe<Scalars['Boolean']['input']>;
  requestUrl?: InputMaybe<Scalars['String']['input']>;
  responseError?: InputMaybe<Scalars['JSON']['input']>;
  responseSuccess?: InputMaybe<Scalars['Boolean']['input']>;
  userAccount?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  uuid4?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateImportEmployeeStatusOnImportEmployeeStatusForImportEmployeeStatusUserUpdatedFkeyPatch {
  fileMode?: InputMaybe<Scalars['String']['input']>;
  googleAuth?: InputMaybe<Scalars['JSON']['input']>;
  importEmployeeStatusId?: InputMaybe<Scalars['BigInt']['input']>;
  inProgress?: InputMaybe<Scalars['Boolean']['input']>;
  progressLoopCount?: InputMaybe<Scalars['Int']['input']>;
  progressProcessedCount?: InputMaybe<Scalars['Int']['input']>;
  progressProcessedCountOfLoop?: InputMaybe<Scalars['Int']['input']>;
  progressShouldStop?: InputMaybe<Scalars['Boolean']['input']>;
  progressTotalCount?: InputMaybe<Scalars['Int']['input']>;
  progressTotalCountOfLoop?: InputMaybe<Scalars['Int']['input']>;
  requestCreateUnsatisfiedMasters?: InputMaybe<Scalars['Boolean']['input']>;
  requestOverwriteExisting?: InputMaybe<Scalars['Boolean']['input']>;
  requestUrl?: InputMaybe<Scalars['String']['input']>;
  responseError?: InputMaybe<Scalars['JSON']['input']>;
  responseSuccess?: InputMaybe<Scalars['Boolean']['input']>;
  userAccount?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  uuid4?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateOfficeOnEmployeeCurrentPositionForFakePublicEmployeeCurrentPositionForeignKey7Patch {
  city?: InputMaybe<OfficeCityIdFkeyInput>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInverseInput>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  officeWeathers?: InputMaybe<OfficeWeatherOfficeIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeOfficeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<OfficeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateOfficeOnEmployeeTpHistoryForEmployeeTpHistoryOfficeIdFkeyPatch {
  city?: InputMaybe<OfficeCityIdFkeyInput>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInverseInput>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  officeWeathers?: InputMaybe<OfficeWeatherOfficeIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeOfficeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<OfficeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateOfficeOnOfficeForOfficeCityIdFkeyPatch {
  city?: InputMaybe<OfficeCityIdFkeyInput>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInverseInput>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  officeWeathers?: InputMaybe<OfficeWeatherOfficeIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeOfficeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<OfficeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateOfficeOnOfficeForOfficeUserCreatedFkeyPatch {
  city?: InputMaybe<OfficeCityIdFkeyInput>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInverseInput>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  officeWeathers?: InputMaybe<OfficeWeatherOfficeIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeOfficeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<OfficeUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateOfficeOnOfficeForOfficeUserUpdatedFkeyPatch {
  city?: InputMaybe<OfficeCityIdFkeyInput>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInverseInput>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  officeWeathers?: InputMaybe<OfficeWeatherOfficeIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeOfficeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<OfficeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateOfficeOnOfficeWeatherForOfficeWeatherOfficeIdFkeyPatch {
  city?: InputMaybe<OfficeCityIdFkeyInput>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInverseInput>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  officeWeathers?: InputMaybe<OfficeWeatherOfficeIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeOfficeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<OfficeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateOfficeOnRelationCompanyOfficeForRelationCompanyOfficeOfficeIdFkeyPatch {
  city?: InputMaybe<OfficeCityIdFkeyInput>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeCurrentPositions?: InputMaybe<FakePublicEmployeeCurrentPositionForeignKey7InverseInput>;
  employeeTpHistories?: InputMaybe<EmployeeTpHistoryOfficeIdFkeyInverseInput>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  officeWeathers?: InputMaybe<OfficeWeatherOfficeIdFkeyInverseInput>;
  relationCompanyOffices?: InputMaybe<RelationCompanyOfficeOfficeIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<OfficeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateOfficeWeatherOnOfficeWeatherForOfficeWeatherOfficeIdFkeyPatch {
  condition?: InputMaybe<Scalars['String']['input']>;
  day?: InputMaybe<Scalars['Date']['input']>;
  humidity?: InputMaybe<Scalars['Float']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<OfficeWeatherOfficeIdFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  tempMax?: InputMaybe<Scalars['Float']['input']>;
  tempMin?: InputMaybe<Scalars['Float']['input']>;
  timeSunrise?: InputMaybe<Scalars['Time']['input']>;
  timeSunset?: InputMaybe<Scalars['Time']['input']>;
  userAccount?: InputMaybe<OfficeWeatherUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateOfficeWeatherOnOfficeWeatherForOfficeWeatherUserCreatedFkeyPatch {
  condition?: InputMaybe<Scalars['String']['input']>;
  day?: InputMaybe<Scalars['Date']['input']>;
  humidity?: InputMaybe<Scalars['Float']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<OfficeWeatherOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  tempMax?: InputMaybe<Scalars['Float']['input']>;
  tempMin?: InputMaybe<Scalars['Float']['input']>;
  timeSunrise?: InputMaybe<Scalars['Time']['input']>;
  timeSunset?: InputMaybe<Scalars['Time']['input']>;
  userAccount?: InputMaybe<OfficeWeatherUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateOfficeWeatherOnOfficeWeatherForOfficeWeatherUserUpdatedFkeyPatch {
  condition?: InputMaybe<Scalars['String']['input']>;
  day?: InputMaybe<Scalars['Date']['input']>;
  humidity?: InputMaybe<Scalars['Float']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<OfficeWeatherOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  tempMax?: InputMaybe<Scalars['Float']['input']>;
  tempMin?: InputMaybe<Scalars['Float']['input']>;
  timeSunrise?: InputMaybe<Scalars['Time']['input']>;
  timeSunset?: InputMaybe<Scalars['Time']['input']>;
  userAccount?: InputMaybe<OfficeWeatherUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10Patch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1Input>;
  performanceReviewEvaluationCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetency?: InputMaybe<PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10Input>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1Patch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1Input>;
  performanceReviewEvaluationCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetency?: InputMaybe<PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10Input>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyPatch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1Input>;
  performanceReviewEvaluationCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetency?: InputMaybe<PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10Input>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyAssessmentValueFkeyPatch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1Input>;
  performanceReviewEvaluationCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetency?: InputMaybe<PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10Input>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserCreatedFkeyPatch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1Input>;
  performanceReviewEvaluationCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetency?: InputMaybe<PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10Input>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserUpdatedFkeyPatch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationCompeAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1Input>;
  performanceReviewEvaluationCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetency?: InputMaybe<PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10Input>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13Patch {
  employee?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInput>;
  evaluatorId?: InputMaybe<Scalars['BigInt']['input']>;
  evaluatorType?: InputMaybe<PerformanceReviewEvaluationEvaluatorType>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13Input>;
  performanceReviewEvaluationEvaluatorId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyPatch {
  employee?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInput>;
  evaluatorType?: InputMaybe<PerformanceReviewEvaluationEvaluatorType>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13Input>;
  performanceReviewEvaluationEvaluatorId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserCreatedFkeyPatch {
  employee?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInput>;
  evaluatorId?: InputMaybe<Scalars['BigInt']['input']>;
  evaluatorType?: InputMaybe<PerformanceReviewEvaluationEvaluatorType>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13Input>;
  performanceReviewEvaluationEvaluatorId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationEvaluatorOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserUpdatedFkeyPatch {
  employee?: InputMaybe<PerformanceReviewEvaluationEvaluatorEvaluatorIdFkeyInput>;
  evaluatorId?: InputMaybe<Scalars['BigInt']['input']>;
  evaluatorType?: InputMaybe<PerformanceReviewEvaluationEvaluatorType>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13Input>;
  performanceReviewEvaluationEvaluatorId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12Patch {
  employee?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInput>;
  feedbackComment?: InputMaybe<Scalars['String']['input']>;
  feedbackEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  feedbackEmployeeName?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12Input>;
  performanceReviewEvaluationFeedbackId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyPatch {
  employee?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInput>;
  feedbackComment?: InputMaybe<Scalars['String']['input']>;
  feedbackEmployeeName?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12Input>;
  performanceReviewEvaluationFeedbackId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserCreatedFkeyPatch {
  employee?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInput>;
  feedbackComment?: InputMaybe<Scalars['String']['input']>;
  feedbackEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  feedbackEmployeeName?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12Input>;
  performanceReviewEvaluationFeedbackId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationFeedbackOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserUpdatedFkeyPatch {
  employee?: InputMaybe<PerformanceReviewEvaluationFeedbacFeedbackEmployeeIdFkeyInput>;
  feedbackComment?: InputMaybe<Scalars['String']['input']>;
  feedbackEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  feedbackEmployeeName?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12Input>;
  performanceReviewEvaluationFeedbackId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8Patch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8Input>;
  performanceReviewEvaluationKpiAssessmentId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9Input>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9Patch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessmentId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9Input>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyPatch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessmentId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9Input>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyPatch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessmentId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9Input>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationKpiAssessmentOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyPatch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationKpiAAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessmentId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9Input>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9Patch {
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  invertedAchievementRate?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5Patch {
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  invertedAchievementRate?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5Input>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9InverseInput>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyPatch {
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  invertedAchievementRate?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9InverseInput>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyPatch {
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  invertedAchievementRate?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9InverseInput>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationKpiDescriptionOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7Patch {
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  invertedAchievementRate?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey9InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10Patch {
  description?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10Input>;
  performanceReviewEvaluationKpiResultId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiTarget?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11Input>;
  performanceReviewEvaluationKpiTargetId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  value?: InputMaybe<Scalars['BigFloat']['input']>;
  valueUnit?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdatePerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11Patch {
  description?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiResultId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiTarget?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11Input>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  value?: InputMaybe<Scalars['BigFloat']['input']>;
  valueUnit?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdatePerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserCreatedFkeyPatch {
  description?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiResultId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiTarget?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11Input>;
  performanceReviewEvaluationKpiTargetId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  value?: InputMaybe<Scalars['BigFloat']['input']>;
  valueUnit?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdatePerformanceReviewEvaluationKpiResultOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserUpdatedFkeyPatch {
  description?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiResultId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiTarget?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11Input>;
  performanceReviewEvaluationKpiTargetId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  value?: InputMaybe<Scalars['BigFloat']['input']>;
  valueUnit?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdatePerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11Patch {
  description?: InputMaybe<Scalars['String']['input']>;
  invertedAchievementRate?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7Input>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  value?: InputMaybe<Scalars['BigFloat']['input']>;
  valueUnit?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdatePerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6Patch {
  description?: InputMaybe<Scalars['String']['input']>;
  invertedAchievementRate?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6Input>;
  performanceReviewEvaluationKpiDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7Input>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11InverseInput>;
  performanceReviewEvaluationKpiTargetId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  value?: InputMaybe<Scalars['BigFloat']['input']>;
  valueUnit?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdatePerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7Patch {
  description?: InputMaybe<Scalars['String']['input']>;
  invertedAchievementRate?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7Input>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11InverseInput>;
  performanceReviewEvaluationKpiTargetId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  value?: InputMaybe<Scalars['BigFloat']['input']>;
  valueUnit?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdatePerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserCreatedFkeyPatch {
  description?: InputMaybe<Scalars['String']['input']>;
  invertedAchievementRate?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7Input>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11InverseInput>;
  performanceReviewEvaluationKpiTargetId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  value?: InputMaybe<Scalars['BigFloat']['input']>;
  valueUnit?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdatePerformanceReviewEvaluationKpiTargetOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserUpdatedFkeyPatch {
  description?: InputMaybe<Scalars['String']['input']>;
  invertedAchievementRate?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey7Input>;
  performanceReviewEvaluationKpiDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey11InverseInput>;
  performanceReviewEvaluationKpiTargetId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  value?: InputMaybe<Scalars['BigFloat']['input']>;
  valueUnit?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1Patch {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13Patch {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12Patch {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeIdFkeyPatch {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationEmployeeTpHistoryIdFkeyPatch {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationPerformanceReviewMasterCoFkeyPatch {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationUserCreatedFkeyPatch {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationForPerformanceReviewEvaluationUserUpdatedFkeyPatch {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8Patch {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5Patch {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10Patch {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6Patch {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14Patch {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3Patch {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2Patch {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyPatch {
  employee?: InputMaybe<PerformanceReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeTpHistory?: InputMaybe<PerformanceReviewEvaluationEmployeeTpHistoryIdFkeyInput>;
  employeeTpHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  isLockedAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  isLockedDescription?: InputMaybe<Scalars['Boolean']['input']>;
  midLongTermGoal?: InputMaybe<Scalars['String']['input']>;
  needInputCompetency?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetKpi?: InputMaybe<Scalars['Boolean']['input']>;
  needInputTargetQualitative?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey1InverseInput>;
  performanceReviewEvaluationEvaluators?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey13InverseInput>;
  performanceReviewEvaluationFeedbacks?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey12InverseInput>;
  performanceReviewEvaluationKpiAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey8InverseInput>;
  performanceReviewEvaluationKpiDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey5InverseInput>;
  performanceReviewEvaluationKpiResults?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey10InverseInput>;
  performanceReviewEvaluationKpiTargets?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey6InverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14InverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3InverseInput>;
  performanceReviewEvaluationQualitativeDescriptions?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2InverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationOneOnOneOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14Patch {
  month?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14Input>;
  performanceReviewEvaluationOneOnOneId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterOneOnOne?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterOnFkeyInput>;
  performanceReviewMasterOneOnOneId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationOneOnOneOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationOneOnOneUserCreatedFkeyPatch {
  month?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationOneOnOneId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterOneOnOne?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterOnFkeyInput>;
  performanceReviewMasterOneOnOneId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInput>;
  value?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationOneOnOneOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationPerformanceReviewMasterOnFkeyPatch {
  month?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatiFkey14Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationOneOnOneId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterOneOnOne?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterOnFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3Patch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3Input>;
  performanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4Input>;
  performanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4Patch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4Input>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyPatch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4Input>;
  performanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserCreatedFkeyPatch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4Input>;
  performanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyPatch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4Input>;
  performanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationQualitativeAssessmentOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssessmentValueFkeyPatch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationQualiAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey3Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeAssessmentId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeDescription?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4Input>;
  performanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4Patch {
  definitionForScore100Percent?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  evaluatorExpectation?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2Patch {
  definitionForScore100Percent?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  evaluatorExpectation?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2Input>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4InverseInput>;
  performanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserCreatedFkeyPatch {
  definitionForScore100Percent?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  evaluatorExpectation?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4InverseInput>;
  performanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationQualitativeDescriptionOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyPatch {
  definitionForScore100Percent?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  evaluatorExpectation?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey2Input>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluatioPerformanceReviewEvaluatioFkey4InverseInput>;
  performanceReviewEvaluationQualitativeDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyPatch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInput>;
  performanceReviewEvaluationValueId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInput>;
  performanceReviewMasterValue?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewMasterVaFkeyPatch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInput>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationValueId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInput>;
  performanceReviewMasterValue?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyPatch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInput>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationValueId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInput>;
  performanceReviewMasterValue?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentValueFkeyPatch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInput>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationValueId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInput>;
  performanceReviewMasterValue?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserCreatedFkeyPatch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInput>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationValueId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInput>;
  performanceReviewMasterValue?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewEvaluationValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserUpdatedFkeyPatch {
  assessmentComment?: InputMaybe<Scalars['String']['input']>;
  assessmentEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  assessmentValue?: InputMaybe<Scalars['BigInt']['input']>;
  employee?: InputMaybe<PerformanceReviewEvaluationValueAssessmentEmployeeIdFkeyInput>;
  performanceReviewEvaluation?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewEvaluatioFkeyInput>;
  performanceReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluationValueId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInput>;
  performanceReviewMasterValue?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyCommonDescriptionOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterComPerformanceReviewMasterCoFkeyPatch {
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterComPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyCommonDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyCommonDescriptionOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserCreatedFkeyPatch {
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterComPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyCommonDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyCommonDescriptionOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyPatch {
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterComPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyCommonDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterComPerformanceReviewMasterCoFkeyPatch {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  overridable?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4InverseInput>;
  performanceReviewMasterCompetencyCommonDescriptions?: InputMaybe<PerformanceReviewMasterComPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterComOverridingPerformanceRevieFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyCommonForPerformanceReviewMasterCompetencyCommonUserCreatedFkeyPatch {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  overridable?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4InverseInput>;
  performanceReviewMasterCompetencyCommonDescriptions?: InputMaybe<PerformanceReviewMasterComPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterComOverridingPerformanceRevieFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyCommonForPerformanceReviewMasterCompetencyCommonUserUpdatedFkeyPatch {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  overridable?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4InverseInput>;
  performanceReviewMasterCompetencyCommonDescriptions?: InputMaybe<PerformanceReviewMasterComPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterComOverridingPerformanceRevieFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterComOverridingPerformanceRevieFkeyPatch {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  overridable?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4InverseInput>;
  performanceReviewMasterCompetencyCommonDescriptions?: InputMaybe<PerformanceReviewMasterComPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterComOverridingPerformanceRevieFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyCommonOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey4Patch {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  overridable?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4InverseInput>;
  performanceReviewMasterCompetencyCommonDescriptions?: InputMaybe<PerformanceReviewMasterComPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterComOverridingPerformanceRevieFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyCommonOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationRPerformanceReviewMasterCoFkeyPatch {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  overridable?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4InverseInput>;
  performanceReviewMasterCompetencyCommonDescriptions?: InputMaybe<PerformanceReviewMasterComPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterComOverridingPerformanceRevieFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyDetailDescriptionOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey2Patch {
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyDetail?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey2Input>;
  performanceReviewMasterCompetencyDetailDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyDetailDescriptionOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserCreatedFkeyPatch {
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyDetail?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey2Input>;
  performanceReviewMasterCompetencyDetailDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyDetailDescriptionOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyPatch {
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterCompetencyDetail?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey2Input>;
  performanceReviewMasterCompetencyDetailDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey2Patch {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  overridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3InverseInput>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterComOverridingPerformanceRevieFkeyInput>;
  performanceReviewMasterCompetencyDetailDescriptions?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey2InverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1Input>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey1Patch {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  overridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3InverseInput>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterComOverridingPerformanceRevieFkeyInput>;
  performanceReviewMasterCompetencyDetailDescriptions?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey2InverseInput>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1Input>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterComOverridingPerformanceRevieFkeyPatch {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3InverseInput>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterComOverridingPerformanceRevieFkeyInput>;
  performanceReviewMasterCompetencyDetailDescriptions?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey2InverseInput>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1Input>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserCreatedFkeyPatch {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  overridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3InverseInput>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterComOverridingPerformanceRevieFkeyInput>;
  performanceReviewMasterCompetencyDetailDescriptions?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey2InverseInput>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1Input>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserUpdatedFkeyPatch {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  overridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3InverseInput>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterComOverridingPerformanceRevieFkeyInput>;
  performanceReviewMasterCompetencyDetailDescriptions?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey2InverseInput>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1Input>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyDetailOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey3Patch {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  overridingPerformanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencies?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3InverseInput>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterComOverridingPerformanceRevieFkeyInput>;
  performanceReviewMasterCompetencyDetailDescriptions?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey2InverseInput>;
  performanceReviewMasterCompetencyRole?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1Input>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10Patch {
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10InverseInput>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4Input>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyDetail?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3Input>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey3Patch {
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10InverseInput>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4Input>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyDetail?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3Input>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey4Patch {
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10InverseInput>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4Input>;
  performanceReviewMasterCompetencyDetail?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3Input>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCompetencyUserCreatedFkeyPatch {
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10InverseInput>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4Input>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyDetail?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3Input>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCompetencyUserUpdatedFkeyPatch {
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluatiPerformanceReviewEvaluatioFkey10InverseInput>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey4Input>;
  performanceReviewMasterCompetencyCommonId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyDetail?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey3Input>;
  performanceReviewMasterCompetencyDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyRoleOnPerformanceReviewEvaluationForPerformanceReviewEvaluationPerformanceReviewMasterCoFkeyPatch {
  businessUnit?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1InverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  roleName?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCoPerformanceReviewMasterCoFkey1Patch {
  businessUnit?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1InverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  roleName?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyPatch {
  businessUnit?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInput>;
  department?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1InverseInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  roleName?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDepartmentIdFkeyPatch {
  businessUnit?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInput>;
  division?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1InverseInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  roleName?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleDivisionIdFkeyPatch {
  businessUnit?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInput>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1InverseInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  roleName?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserCreatedFkeyPatch {
  businessUnit?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1InverseInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  roleName?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterCompetencyRoleOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserUpdatedFkeyPatch {
  businessUnit?: InputMaybe<PerformanceReviewMasterCompetencyRoleBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<PerformanceReviewMasterCompetencyRoleDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<PerformanceReviewMasterCompetencyRoleDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewEvaluations?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterCoFkeyInverseInput>;
  performanceReviewMasterCompetencyDetails?: InputMaybe<PerformanceReviewMasterCoPerformanceReviewMasterCoFkey1InverseInput>;
  performanceReviewMasterCompetencyRoleId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  roleName?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterDefaultOnPerformanceReviewMasterDefaultForPerformanceReviewMasterDefaultUserCreatedFkeyPatch {
  defaultQuarter?: InputMaybe<PerformanceReviewQuarter>;
  defaultYear?: InputMaybe<Scalars['Int']['input']>;
  lockYearQuarterForEveryone?: InputMaybe<Scalars['Boolean']['input']>;
  performanceReviewMasterDefaultId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInput>;
}

export interface UpdatePerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyPatch {
  canModify?: InputMaybe<Scalars['Boolean']['input']>;
  employee?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterEvaluationViewerId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerEmployeeIdFkeyPatch {
  canModify?: InputMaybe<Scalars['Boolean']['input']>;
  employee?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInput>;
  performanceReviewMasterEvaluationViewerId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  viewerEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserCreatedFkeyPatch {
  canModify?: InputMaybe<Scalars['Boolean']['input']>;
  employee?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterEvaluationViewerId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  viewerEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterEvaluationViewerOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserUpdatedFkeyPatch {
  canModify?: InputMaybe<Scalars['Boolean']['input']>;
  employee?: InputMaybe<PerformanceReviewMasterEvaluationViViewerEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterEvaluationViewerId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  viewerEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterExcludedEmployeeOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyPatch {
  employee?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterExcludedEmployeeOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeUserCreatedFkeyPatch {
  employee?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterExcludedEmployeeOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyPatch {
  employee?: InputMaybe<PerformanceReviewMasterExcludedEmployeeEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterOneOnOneDetailOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOneOnOneDetailUserCreatedFkeyPatch {
  itemName?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterOneOnOne?: InputMaybe<PerformanceReviewMasterOnePerformanceReviewMasterOnFkeyInput>;
  performanceReviewMasterOneOnOneDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterOneOnOneId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewMasterOneOnOneDetailOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyPatch {
  itemName?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterOneOnOne?: InputMaybe<PerformanceReviewMasterOnePerformanceReviewMasterOnFkeyInput>;
  performanceReviewMasterOneOnOneDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterOneOnOneId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewMasterOneOnOneDetailOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOnePerformanceReviewMasterOnFkeyPatch {
  itemName?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterOneOnOne?: InputMaybe<PerformanceReviewMasterOnePerformanceReviewMasterOnFkeyInput>;
  performanceReviewMasterOneOnOneDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewMasterOneOnOneOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationPerformanceReviewMasterOnFkeyPatch {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterOnFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetails?: InputMaybe<PerformanceReviewMasterOnePerformanceReviewMasterOnFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterOneOnOneOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOnePerformanceReviewMasterOnFkeyPatch {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterOnFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetails?: InputMaybe<PerformanceReviewMasterOnePerformanceReviewMasterOnFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterOneOnOneOnPerformanceReviewMasterOneOnOneForPerformanceReviewMasterOneOnOneUserCreatedFkeyPatch {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterOnFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetails?: InputMaybe<PerformanceReviewMasterOnePerformanceReviewMasterOnFkeyInverseInput>;
  performanceReviewMasterOneOnOneId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterOneOnOneOnPerformanceReviewMasterOneOnOneForPerformanceReviewMasterOneOnOneUserUpdatedFkeyPatch {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterOnFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetails?: InputMaybe<PerformanceReviewMasterOnePerformanceReviewMasterOnFkeyInverseInput>;
  performanceReviewMasterOneOnOneId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterRatingDescriptionOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatPerformanceReviewMasterRaFkeyPatch {
  descriptionExpectation?: InputMaybe<Scalars['String']['input']>;
  descriptionRating?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyInput>;
  performanceReviewMasterRatingDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewMasterRatingDescriptionOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserCreatedFkeyPatch {
  descriptionExpectation?: InputMaybe<Scalars['String']['input']>;
  descriptionRating?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyInput>;
  performanceReviewMasterRatingDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRatingId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewMasterRatingDescriptionOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserUpdatedFkeyPatch {
  descriptionExpectation?: InputMaybe<Scalars['String']['input']>;
  descriptionRating?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterRating?: InputMaybe<PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyInput>;
  performanceReviewMasterRatingDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRatingId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewMasterRatingOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyAssessmentValueFkeyPatch {
  calculationValue?: InputMaybe<Scalars['BigFloat']['input']>;
  displayValue?: InputMaybe<Scalars['Int']['input']>;
  percentageLowerBound?: InputMaybe<Scalars['Int']['input']>;
  percentageUpperBound?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInverseInput>;
  performanceReviewMasterRatingDescriptions?: InputMaybe<PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyInverseInput>;
  performanceReviewMasterRatingId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterRatingOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssessmentValueFkeyPatch {
  calculationValue?: InputMaybe<Scalars['BigFloat']['input']>;
  displayValue?: InputMaybe<Scalars['Int']['input']>;
  percentageLowerBound?: InputMaybe<Scalars['Int']['input']>;
  percentageUpperBound?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInverseInput>;
  performanceReviewMasterRatingDescriptions?: InputMaybe<PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyInverseInput>;
  performanceReviewMasterRatingId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterRatingOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueAssessmentValueFkeyPatch {
  calculationValue?: InputMaybe<Scalars['BigFloat']['input']>;
  displayValue?: InputMaybe<Scalars['Int']['input']>;
  percentageLowerBound?: InputMaybe<Scalars['Int']['input']>;
  percentageUpperBound?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInverseInput>;
  performanceReviewMasterRatingDescriptions?: InputMaybe<PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyInverseInput>;
  performanceReviewMasterRatingId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterRatingOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatPerformanceReviewMasterRaFkeyPatch {
  calculationValue?: InputMaybe<Scalars['BigFloat']['input']>;
  displayValue?: InputMaybe<Scalars['Int']['input']>;
  percentageLowerBound?: InputMaybe<Scalars['Int']['input']>;
  percentageUpperBound?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInverseInput>;
  performanceReviewMasterRatingDescriptions?: InputMaybe<PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterRatingOnPerformanceReviewMasterRatingForPerformanceReviewMasterRatingUserCreatedFkeyPatch {
  calculationValue?: InputMaybe<Scalars['BigFloat']['input']>;
  displayValue?: InputMaybe<Scalars['Int']['input']>;
  percentageLowerBound?: InputMaybe<Scalars['Int']['input']>;
  percentageUpperBound?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInverseInput>;
  performanceReviewMasterRatingDescriptions?: InputMaybe<PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyInverseInput>;
  performanceReviewMasterRatingId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterRatingOnPerformanceReviewMasterRatingForPerformanceReviewMasterRatingUserUpdatedFkeyPatch {
  calculationValue?: InputMaybe<Scalars['BigFloat']['input']>;
  displayValue?: InputMaybe<Scalars['Int']['input']>;
  percentageLowerBound?: InputMaybe<Scalars['Int']['input']>;
  percentageUpperBound?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInverseInput>;
  performanceReviewMasterRatingDescriptions?: InputMaybe<PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyInverseInput>;
  performanceReviewMasterRatingId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterRatingOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompetAssessmentFkeyPatch {
  calculationValue?: InputMaybe<Scalars['BigFloat']['input']>;
  displayValue?: InputMaybe<Scalars['Int']['input']>;
  percentageLowerBound?: InputMaybe<Scalars['Int']['input']>;
  percentageUpperBound?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInverseInput>;
  performanceReviewMasterRatingDescriptions?: InputMaybe<PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyInverseInput>;
  performanceReviewMasterRatingId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterRatingOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValueAssessmentFkeyPatch {
  calculationValue?: InputMaybe<Scalars['BigFloat']['input']>;
  displayValue?: InputMaybe<Scalars['Int']['input']>;
  percentageLowerBound?: InputMaybe<Scalars['Int']['input']>;
  percentageUpperBound?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluationCompetencies?: InputMaybe<PerformanceReviewEvaluationCompetencyAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessments?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentValueFkeyInverseInput>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationValueAssessmentValueFkeyInverseInput>;
  performanceReviewMasterRatingDescriptions?: InputMaybe<PerformanceReviewMasterRatPerformanceReviewMasterRaFkeyInverseInput>;
  performanceReviewMasterRatingId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterUsageOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageCompanyIdFkeyPatch {
  company?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInput>;
  performanceReviewMasterUsageId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  usePerformanceReview?: InputMaybe<Scalars['Boolean']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterUsageOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserCreatedFkeyPatch {
  company?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterUsageId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  usePerformanceReview?: InputMaybe<Scalars['Boolean']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterUsageOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserUpdatedFkeyPatch {
  company?: InputMaybe<PerformanceReviewMasterUsageCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterUsageId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  usePerformanceReview?: InputMaybe<Scalars['Boolean']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterValueDescriptionOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValPerformanceReviewMasterVaFkeyPatch {
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterValue?: InputMaybe<PerformanceReviewMasterValPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewMasterValueDescriptionOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserCreatedFkeyPatch {
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterValue?: InputMaybe<PerformanceReviewMasterValPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewMasterValueDescriptionOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserUpdatedFkeyPatch {
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterValue?: InputMaybe<PerformanceReviewMasterValPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewMasterValueOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationPerformanceReviewMasterVaFkeyPatch {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyInverseInput>;
  performanceReviewMasterValueDescriptions?: InputMaybe<PerformanceReviewMasterValPerformanceReviewMasterVaFkeyInverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterValueOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValPerformanceReviewMasterVaFkeyPatch {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyInverseInput>;
  performanceReviewMasterValueDescriptions?: InputMaybe<PerformanceReviewMasterValPerformanceReviewMasterVaFkeyInverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterValueOnPerformanceReviewMasterValueForPerformanceReviewMasterValueUserCreatedFkeyPatch {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyInverseInput>;
  performanceReviewMasterValueDescriptions?: InputMaybe<PerformanceReviewMasterValPerformanceReviewMasterVaFkeyInverseInput>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterValueOnPerformanceReviewMasterValueForPerformanceReviewMasterValueUserUpdatedFkeyPatch {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyInverseInput>;
  performanceReviewMasterValueDescriptions?: InputMaybe<PerformanceReviewMasterValPerformanceReviewMasterVaFkeyInverseInput>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationRPerformanceReviewMasterVaFkeyPatch {
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewEvaluationValues?: InputMaybe<PerformanceReviewEvaluationPerformanceReviewMasterVaFkeyInverseInput>;
  performanceReviewMasterValueDescriptions?: InputMaybe<PerformanceReviewMasterValPerformanceReviewMasterVaFkeyInverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyPatch {
  employeeLevel?: InputMaybe<PerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  percentageCompetency?: InputMaybe<Scalars['Int']['input']>;
  percentageMultiSource?: InputMaybe<Scalars['Int']['input']>;
  percentageTarget?: InputMaybe<Scalars['Int']['input']>;
  percentageValue?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterWeighting?: InputMaybe<PerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyInput>;
  performanceReviewMasterWeightingDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyPatch {
  employeeLevel?: InputMaybe<PerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyInput>;
  percentageCompetency?: InputMaybe<Scalars['Int']['input']>;
  percentageMultiSource?: InputMaybe<Scalars['Int']['input']>;
  percentageTarget?: InputMaybe<Scalars['Int']['input']>;
  percentageValue?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterWeighting?: InputMaybe<PerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyInput>;
  performanceReviewMasterWeightingDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterWeightingId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserCreatedFkeyPatch {
  employeeLevel?: InputMaybe<PerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  percentageCompetency?: InputMaybe<Scalars['Int']['input']>;
  percentageMultiSource?: InputMaybe<Scalars['Int']['input']>;
  percentageTarget?: InputMaybe<Scalars['Int']['input']>;
  percentageValue?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterWeighting?: InputMaybe<PerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyInput>;
  performanceReviewMasterWeightingDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterWeightingId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewMasterWeightingDetailOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserUpdatedFkeyPatch {
  employeeLevel?: InputMaybe<PerformanceReviewMasterWeightingDetaEmployeeLevelIdFkeyInput>;
  employeeLevelId?: InputMaybe<Scalars['BigInt']['input']>;
  percentageCompetency?: InputMaybe<Scalars['Int']['input']>;
  percentageMultiSource?: InputMaybe<Scalars['Int']['input']>;
  percentageTarget?: InputMaybe<Scalars['Int']['input']>;
  percentageValue?: InputMaybe<Scalars['Int']['input']>;
  performanceReviewMasterWeighting?: InputMaybe<PerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyInput>;
  performanceReviewMasterWeightingDetailId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterWeightingId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyPatch {
  company?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterWeightingDetails?: InputMaybe<PerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingCompanyIdFkeyPatch {
  company?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterWeightingDetails?: InputMaybe<PerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyInverseInput>;
  performanceReviewMasterWeightingId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserCreatedFkeyPatch {
  company?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterWeightingDetails?: InputMaybe<PerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyInverseInput>;
  performanceReviewMasterWeightingId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePerformanceReviewMasterWeightingOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserUpdatedFkeyPatch {
  company?: InputMaybe<PerformanceReviewMasterWeightingCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReviewMasterWeightingDetails?: InputMaybe<PerformanceReviewMasterWeiPerformanceReviewMasterWeFkeyInverseInput>;
  performanceReviewMasterWeightingId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePersonalIdentityTypeOnPersonalIdentityTypeForPersonalIdentityTypeUserCreatedFkeyPatch {
  code?: InputMaybe<Scalars['String']['input']>;
  personalIdentityTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdatePersonalIdentityTypeOnPersonalIdentityTypeForPersonalIdentityTypeUserUpdatedFkeyPatch {
  code?: InputMaybe<Scalars['String']['input']>;
  personalIdentityTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableCompanyIdFkeyPatch {
  company?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInput>;
  contractType?: InputMaybe<ProbationReviewEnableContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  defaultProbationPeriod?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  probationDateInterval?: InputMaybe<Scalars['Int']['input']>;
  probationReviewEnableId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableContractTypeIdFkeyPatch {
  company?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<ProbationReviewEnableContractTypeIdFkeyInput>;
  defaultProbationPeriod?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  probationDateInterval?: InputMaybe<Scalars['Int']['input']>;
  probationReviewEnableId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableUserCreatedFkeyPatch {
  company?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<ProbationReviewEnableContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  defaultProbationPeriod?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  probationDateInterval?: InputMaybe<Scalars['Int']['input']>;
  probationReviewEnableId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEnableOnProbationReviewEnableForProbationReviewEnableUserUpdatedFkeyPatch {
  company?: InputMaybe<ProbationReviewEnableCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<ProbationReviewEnableContractTypeIdFkeyInput>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  defaultProbationPeriod?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  probationDateInterval?: InputMaybe<Scalars['Int']['input']>;
  probationReviewEnableId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEnableViewOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey0Patch {
  company?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1Input>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey2Input>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0Input>;
  defaultProbationPeriod?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  probationDateInterval?: InputMaybe<Scalars['Int']['input']>;
  probationReviewEnableId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEnableViewOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey1Patch {
  company?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1Input>;
  contractType?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey2Input>;
  contractTypeId?: InputMaybe<Scalars['BigInt']['input']>;
  country?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0Input>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  defaultProbationPeriod?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  probationDateInterval?: InputMaybe<Scalars['Int']['input']>;
  probationReviewEnableId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEnableViewOnProbationReviewEnableViewForFakePublicProbationReviewEnableViewForeignKey2Patch {
  company?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey1Input>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  contractType?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey2Input>;
  country?: InputMaybe<FakePublicProbationReviewEnableViewForeignKey0Input>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  defaultProbationPeriod?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  probationDateInterval?: InputMaybe<Scalars['Int']['input']>;
  probationReviewEnableId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEvaluationOnProbationReviewEvaluationForProbationReviewEvaluationEmployeeIdFkeyPatch {
  dateReminderSent?: InputMaybe<Scalars['Datetime']['input']>;
  employee?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInput>;
  googleCalendarInfo?: InputMaybe<Scalars['JSON']['input']>;
  isFinalOrExtended?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewEvaluationFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1InverseInput>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey3InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<ProbationReviewEvaluationResult>;
  reviewDate?: InputMaybe<Scalars['Date']['input']>;
  reviewDatePlanned?: InputMaybe<Scalars['Date']['input']>;
  submitDate?: InputMaybe<Scalars['Datetime']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEvaluationOnProbationReviewEvaluationForProbationReviewEvaluationUserCreatedFkeyPatch {
  dateReminderSent?: InputMaybe<Scalars['Datetime']['input']>;
  employee?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  googleCalendarInfo?: InputMaybe<Scalars['JSON']['input']>;
  isFinalOrExtended?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewEvaluationFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1InverseInput>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey3InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<ProbationReviewEvaluationResult>;
  reviewDate?: InputMaybe<Scalars['Date']['input']>;
  reviewDatePlanned?: InputMaybe<Scalars['Date']['input']>;
  submitDate?: InputMaybe<Scalars['Datetime']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEvaluationOnProbationReviewEvaluationForProbationReviewEvaluationUserUpdatedFkeyPatch {
  dateReminderSent?: InputMaybe<Scalars['Datetime']['input']>;
  employee?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  googleCalendarInfo?: InputMaybe<Scalars['JSON']['input']>;
  isFinalOrExtended?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewEvaluationFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1InverseInput>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey3InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<ProbationReviewEvaluationResult>;
  reviewDate?: InputMaybe<Scalars['Date']['input']>;
  reviewDatePlanned?: InputMaybe<Scalars['Date']['input']>;
  submitDate?: InputMaybe<Scalars['Datetime']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEvaluationOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewEvaluationFkeyPatch {
  dateReminderSent?: InputMaybe<Scalars['Datetime']['input']>;
  employee?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  googleCalendarInfo?: InputMaybe<Scalars['JSON']['input']>;
  isFinalOrExtended?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewEvaluationFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1InverseInput>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey3InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<ProbationReviewEvaluationResult>;
  reviewDate?: InputMaybe<Scalars['Date']['input']>;
  reviewDatePlanned?: InputMaybe<Scalars['Date']['input']>;
  submitDate?: InputMaybe<Scalars['Datetime']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEvaluationOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewEvaluationFkey2Patch {
  dateReminderSent?: InputMaybe<Scalars['Datetime']['input']>;
  employee?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  googleCalendarInfo?: InputMaybe<Scalars['JSON']['input']>;
  isFinalOrExtended?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewEvaluationFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1InverseInput>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey3InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<ProbationReviewEvaluationResult>;
  reviewDate?: InputMaybe<Scalars['Date']['input']>;
  reviewDatePlanned?: InputMaybe<Scalars['Date']['input']>;
  submitDate?: InputMaybe<Scalars['Datetime']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEvaluationOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewEvaluationFkey1Patch {
  dateReminderSent?: InputMaybe<Scalars['Datetime']['input']>;
  employee?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  googleCalendarInfo?: InputMaybe<Scalars['JSON']['input']>;
  isFinalOrExtended?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewEvaluationFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1InverseInput>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey3InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<ProbationReviewEvaluationResult>;
  reviewDate?: InputMaybe<Scalars['Date']['input']>;
  reviewDatePlanned?: InputMaybe<Scalars['Date']['input']>;
  submitDate?: InputMaybe<Scalars['Datetime']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEvaluationOnProbationReviewEvaluationReviewerForProbationReviewEvaluationProbationReviewEvaluationFkey3Patch {
  dateReminderSent?: InputMaybe<Scalars['Datetime']['input']>;
  employee?: InputMaybe<ProbationReviewEvaluationEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  googleCalendarInfo?: InputMaybe<Scalars['JSON']['input']>;
  isFinalOrExtended?: InputMaybe<Scalars['Boolean']['input']>;
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewEvaluationFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1InverseInput>;
  probationReviewEvaluationReviewers?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey3InverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<ProbationReviewEvaluationResult>;
  reviewDate?: InputMaybe<Scalars['Date']['input']>;
  reviewDatePlanned?: InputMaybe<Scalars['Date']['input']>;
  submitDate?: InputMaybe<Scalars['Datetime']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewEvaluationFkeyPatch {
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationRProbationReviewEvaluationFkeyInput>;
  probationReviewEvaluationResultItemId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationRProbationReviewMasterItemFkeyInput>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  valueInput?: InputMaybe<Scalars['String']['input']>;
  valueOutput?: InputMaybe<Scalars['String']['input']>;
  valueSingle?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewMasterItemFkeyPatch {
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationRProbationReviewEvaluationFkeyInput>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultItemId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationRProbationReviewMasterItemFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  valueInput?: InputMaybe<Scalars['String']['input']>;
  valueOutput?: InputMaybe<Scalars['String']['input']>;
  valueSingle?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserCreatedFkeyPatch {
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationRProbationReviewEvaluationFkeyInput>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultItemId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationRProbationReviewMasterItemFkeyInput>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  valueInput?: InputMaybe<Scalars['String']['input']>;
  valueOutput?: InputMaybe<Scalars['String']['input']>;
  valueSingle?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateProbationReviewEvaluationResultItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserUpdatedFkeyPatch {
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationRProbationReviewEvaluationFkeyInput>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultItemId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationRProbationReviewMasterItemFkeyInput>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  valueInput?: InputMaybe<Scalars['String']['input']>;
  valueOutput?: InputMaybe<Scalars['String']['input']>;
  valueSingle?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewEvaluationFkey2Patch {
  assessment?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInput>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2Input>;
  probationReviewEvaluationResultScoreCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey2Input>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewMasterItemFkey2Patch {
  assessment?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInput>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey2Input>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationRPerformanceReviewMasterCoFkeyPatch {
  assessment?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterRating?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInput>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey2Input>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserCreatedFkeyPatch {
  assessment?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInput>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey2Input>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserUpdatedFkeyPatch {
  assessment?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterCompetencyCommon?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInput>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey2Input>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEvaluationResultScoreCompetencyOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompetAssessmentFkeyPatch {
  performanceReviewMasterCompetencyCommon?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterCoFkeyInput>;
  performanceReviewMasterCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<ProbationReviewEvaluationResultScoreCompetAssessmentFkeyInput>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey2Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreCompetencyId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey2Input>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewEvaluationFkey1Patch {
  assessment?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInput>;
  performanceReviewMasterValue?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1Input>;
  probationReviewEvaluationResultScoreValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey1Input>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewMasterItemFkey1Patch {
  assessment?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInput>;
  performanceReviewMasterValue?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey1Input>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationRPerformanceReviewMasterVaFkeyPatch {
  assessment?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInput>;
  performanceReviewMasterValue?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyInput>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey1Input>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserCreatedFkeyPatch {
  assessment?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInput>;
  performanceReviewMasterValue?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey1Input>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserUpdatedFkeyPatch {
  assessment?: InputMaybe<Scalars['BigInt']['input']>;
  performanceReviewMasterRating?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInput>;
  performanceReviewMasterValue?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey1Input>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEvaluationResultScoreValueOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValueAssessmentFkeyPatch {
  performanceReviewMasterRating?: InputMaybe<ProbationReviewEvaluationResultScoreValueAssessmentFkeyInput>;
  performanceReviewMasterValue?: InputMaybe<ProbationReviewEvaluationRPerformanceReviewMasterVaFkeyInput>;
  performanceReviewMasterValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey1Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewEvaluationResultScoreValueId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey1Input>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEvaluationReviewerOnProbationReviewEvaluationReviewerForProbationReviewEvaluationProbationReviewEvaluationFkey3Patch {
  employee?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInput>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey3Input>;
  remark?: InputMaybe<Scalars['String']['input']>;
  reviewerEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEvaluationReviewerOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyPatch {
  employee?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInput>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey3Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEvaluationReviewerOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerUserCreatedFkeyPatch {
  employee?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInput>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey3Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  reviewerEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewEvaluationReviewerOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerUserUpdatedFkeyPatch {
  employee?: InputMaybe<ProbationReviewEvaluationReviewerReviewerEmployeeIdFkeyInput>;
  probationReviewEvaluation?: InputMaybe<ProbationReviewEvaluationProbationReviewEvaluationFkey3Input>;
  probationReviewEvaluationId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  reviewerEmployeeId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccount?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewMasterItemDescriptionOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserCreatedFkeyPatch {
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemFkeyInput>;
  probationReviewMasterItemDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewMasterItemDescriptionOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserUpdatedFkeyPatch {
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemFkeyInput>;
  probationReviewMasterItemDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewMasterItemDescriptionOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemProbationReviewMasterItemFkeyPatch {
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  probationReviewMasterItem?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemFkeyInput>;
  probationReviewMasterItemDescriptionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateProbationReviewMasterItemOnProbationReviewEvaluationResultItemForProbationReviewEvaluationRProbationReviewMasterItemFkeyPatch {
  dateDisabled?: InputMaybe<Scalars['Date']['input']>;
  dateEnabled?: InputMaybe<Scalars['Date']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  inputMode?: InputMaybe<ProbationReviewInputMode>;
  inputUser?: InputMaybe<ProbationReviewInputUser>;
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewMasterItemFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey1InverseInput>;
  probationReviewMasterItemDescriptions?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  showOnSummary?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdateProbationReviewMasterItemOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationProbationReviewMasterItemFkey2Patch {
  dateDisabled?: InputMaybe<Scalars['Date']['input']>;
  dateEnabled?: InputMaybe<Scalars['Date']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  inputMode?: InputMaybe<ProbationReviewInputMode>;
  inputUser?: InputMaybe<ProbationReviewInputUser>;
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewMasterItemFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey1InverseInput>;
  probationReviewMasterItemDescriptions?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  showOnSummary?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdateProbationReviewMasterItemOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationProbationReviewMasterItemFkey1Patch {
  dateDisabled?: InputMaybe<Scalars['Date']['input']>;
  dateEnabled?: InputMaybe<Scalars['Date']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  inputMode?: InputMaybe<ProbationReviewInputMode>;
  inputUser?: InputMaybe<ProbationReviewInputUser>;
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewMasterItemFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey1InverseInput>;
  probationReviewMasterItemDescriptions?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  showOnSummary?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdateProbationReviewMasterItemOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemProbationReviewMasterItemFkeyPatch {
  dateDisabled?: InputMaybe<Scalars['Date']['input']>;
  dateEnabled?: InputMaybe<Scalars['Date']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  inputMode?: InputMaybe<ProbationReviewInputMode>;
  inputUser?: InputMaybe<ProbationReviewInputUser>;
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewMasterItemFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey1InverseInput>;
  probationReviewMasterItemDescriptions?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemFkeyInverseInput>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  showOnSummary?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdateProbationReviewMasterItemOnProbationReviewMasterItemForProbationReviewMasterItemUserCreatedFkeyPatch {
  dateDisabled?: InputMaybe<Scalars['Date']['input']>;
  dateEnabled?: InputMaybe<Scalars['Date']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  inputMode?: InputMaybe<ProbationReviewInputMode>;
  inputUser?: InputMaybe<ProbationReviewInputUser>;
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewMasterItemFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey1InverseInput>;
  probationReviewMasterItemDescriptions?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemFkeyInverseInput>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  showOnSummary?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdateProbationReviewMasterItemOnProbationReviewMasterItemForProbationReviewMasterItemUserUpdatedFkeyPatch {
  dateDisabled?: InputMaybe<Scalars['Date']['input']>;
  dateEnabled?: InputMaybe<Scalars['Date']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  inputMode?: InputMaybe<ProbationReviewInputMode>;
  inputUser?: InputMaybe<ProbationReviewInputUser>;
  probationReviewEvaluationResultItems?: InputMaybe<ProbationReviewEvaluationRProbationReviewMasterItemFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetencies?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey2InverseInput>;
  probationReviewEvaluationResultScoreValues?: InputMaybe<ProbationReviewEvaluationProbationReviewMasterItemFkey1InverseInput>;
  probationReviewMasterItemDescriptions?: InputMaybe<ProbationReviewMasterItemProbationReviewMasterItemFkeyInverseInput>;
  probationReviewMasterItemId?: InputMaybe<Scalars['BigInt']['input']>;
  quarter?: InputMaybe<PerformanceReviewQuarter>;
  remark?: InputMaybe<Scalars['String']['input']>;
  showOnSummary?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdateRelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyPatch {
  businessUnit?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInput>;
  department?: InputMaybe<RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDepartmentIdFkeyPatch {
  businessUnit?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyInput>;
  division?: InputMaybe<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentDivisionIdFkeyPatch {
  businessUnit?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentUserCreatedFkeyPatch {
  businessUnit?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationBusinessUnitDivisionDepartmentOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentUserUpdatedFkeyPatch {
  businessUnit?: InputMaybe<RelationBusinessUnitDivisionDepartmenBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  department?: InputMaybe<RelationBusinessUnitDivisionDepartmentDepartmentIdFkeyInput>;
  departmentId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<RelationBusinessUnitDivisionDepartmentDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationBusinessUnitDivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionBusinessUnitIdFkeyPatch {
  businessUnit?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInput>;
  division?: InputMaybe<RelationBusinessUnitDivisionDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationBusinessUnitDivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionDivisionIdFkeyPatch {
  businessUnit?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<RelationBusinessUnitDivisionDivisionIdFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationBusinessUnitDivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionUserCreatedFkeyPatch {
  businessUnit?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<RelationBusinessUnitDivisionDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationBusinessUnitDivisionOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionUserUpdatedFkeyPatch {
  businessUnit?: InputMaybe<RelationBusinessUnitDivisionBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  division?: InputMaybe<RelationBusinessUnitDivisionDivisionIdFkeyInput>;
  divisionId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationCityCompanyOnRelationCityCompanyForRelationCityCompanyCityIdFkeyPatch {
  city?: InputMaybe<RelationCityCompanyCityIdFkeyInput>;
  company?: InputMaybe<RelationCityCompanyCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationCityCompanyOnRelationCityCompanyForRelationCityCompanyCompanyIdFkeyPatch {
  city?: InputMaybe<RelationCityCompanyCityIdFkeyInput>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<RelationCityCompanyCompanyIdFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationCityCompanyOnRelationCityCompanyForRelationCityCompanyUserCreatedFkeyPatch {
  city?: InputMaybe<RelationCityCompanyCityIdFkeyInput>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<RelationCityCompanyCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationCityCompanyOnRelationCityCompanyForRelationCityCompanyUserUpdatedFkeyPatch {
  city?: InputMaybe<RelationCityCompanyCityIdFkeyInput>;
  cityId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<RelationCityCompanyCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationCompanyBusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitBusinessUnitIdFkeyPatch {
  businessUnit?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInput>;
  company?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationCompanyBusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitCompanyIdFkeyPatch {
  businessUnit?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationCompanyBusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitUserCreatedFkeyPatch {
  businessUnit?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationCompanyBusinessUnitOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitUserUpdatedFkeyPatch {
  businessUnit?: InputMaybe<RelationCompanyBusinessUnitBusinessUnitIdFkeyInput>;
  businessUnitId?: InputMaybe<Scalars['BigInt']['input']>;
  company?: InputMaybe<RelationCompanyBusinessUnitCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationCompanyGroupEmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainCompanyGroupIdFkeyPatch {
  companyGroup?: InputMaybe<RelationCompanyGroupEmailDomainCompanyGroupIdFkeyInput>;
  emailDomain?: InputMaybe<RelationCompanyGroupEmailDomainEmailDomainIdFkeyInput>;
  emailDomainId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationCompanyGroupEmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainEmailDomainIdFkeyPatch {
  companyGroup?: InputMaybe<RelationCompanyGroupEmailDomainCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  emailDomain?: InputMaybe<RelationCompanyGroupEmailDomainEmailDomainIdFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationCompanyGroupEmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainUserCreatedFkeyPatch {
  companyGroup?: InputMaybe<RelationCompanyGroupEmailDomainCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  emailDomain?: InputMaybe<RelationCompanyGroupEmailDomainEmailDomainIdFkeyInput>;
  emailDomainId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationCompanyGroupEmailDomainOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainUserUpdatedFkeyPatch {
  companyGroup?: InputMaybe<RelationCompanyGroupEmailDomainCompanyGroupIdFkeyInput>;
  companyGroupId?: InputMaybe<Scalars['BigInt']['input']>;
  emailDomain?: InputMaybe<RelationCompanyGroupEmailDomainEmailDomainIdFkeyInput>;
  emailDomainId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationCompanyOfficeOnRelationCompanyOfficeForRelationCompanyOfficeCompanyIdFkeyPatch {
  company?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInput>;
  office?: InputMaybe<RelationCompanyOfficeOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationCompanyOfficeOnRelationCompanyOfficeForRelationCompanyOfficeOfficeIdFkeyPatch {
  company?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  office?: InputMaybe<RelationCompanyOfficeOfficeIdFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationCompanyOfficeOnRelationCompanyOfficeForRelationCompanyOfficeUserCreatedFkeyPatch {
  company?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  office?: InputMaybe<RelationCompanyOfficeOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationCompanyOfficeOnRelationCompanyOfficeForRelationCompanyOfficeUserUpdatedFkeyPatch {
  company?: InputMaybe<RelationCompanyOfficeCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  office?: InputMaybe<RelationCompanyOfficeOfficeIdFkeyInput>;
  officeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationCurrencyCountryOnRelationCurrencyCountryForRelationCurrencyCountryCountryCodeAlpha2FkeyPatch {
  country?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInput>;
  currency?: InputMaybe<RelationCurrencyCountryCurrencyCodeFkeyInput>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationCurrencyCountryOnRelationCurrencyCountryForRelationCurrencyCountryCurrencyCodeFkeyPatch {
  country?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<RelationCurrencyCountryCurrencyCodeFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationCurrencyCountryOnRelationCurrencyCountryForRelationCurrencyCountryUserCreatedFkeyPatch {
  country?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<RelationCurrencyCountryCurrencyCodeFkeyInput>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationCurrencyCountryOnRelationCurrencyCountryForRelationCurrencyCountryUserUpdatedFkeyPatch {
  country?: InputMaybe<RelationCurrencyCountryCountryCodeAlpha2FkeyInput>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<RelationCurrencyCountryCurrencyCodeFkeyInput>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationEmergencyContactOnEmployeeEmergencyContactForEmployeeEmergencyContactRelationIdFkeyPatch {
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['BigInt']['input']>;
  emergencyContactRelationId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactRelationIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationEmergencyContactOnRelationEmergencyContactForRelationEmergencyContactUserCreatedFkeyPatch {
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['BigInt']['input']>;
  emergencyContactRelationId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactRelationIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationEmergencyContactOnRelationEmergencyContactForRelationEmergencyContactUserUpdatedFkeyPatch {
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['BigInt']['input']>;
  emergencyContactRelationId?: InputMaybe<Scalars['BigInt']['input']>;
  employeeEmergencyContacts?: InputMaybe<EmployeeEmergencyContactRelationIdFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationEmployeeUserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountEmployeeIdFkeyPatch {
  employee?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationEmployeeUserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserAccountIdFkeyPatch {
  employee?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationEmployeeUserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserCreatedFkeyPatch {
  employee?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateRelationEmployeeUserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserUpdatedFkeyPatch {
  employee?: InputMaybe<RelationEmployeeUserAccountEmployeeIdFkeyInput>;
  employeeId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateUserAccountAccessHistoryOnUserAccountAccessHistoryForUserAccountAccessHistoryUserAccountIdFkeyPatch {
  geoipCity?: InputMaybe<Scalars['String']['input']>;
  geoipCountryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  geoipLatLng?: InputMaybe<PointInput>;
  geoipRegion?: InputMaybe<Scalars['String']['input']>;
  geoipTimezone?: InputMaybe<Scalars['String']['input']>;
  hostName?: InputMaybe<Scalars['String']['input']>;
  ipAddress?: InputMaybe<Scalars['InternetAddress']['input']>;
  languages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  referrer?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  timezoneOffset?: InputMaybe<Scalars['Int']['input']>;
  userAccount?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInput>;
  userAccountAccessHistoryId?: InputMaybe<Scalars['BigInt']['input']>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateUserAccountOnBankForBankUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnBusinessUnitForBusinessUnitUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnCityForCityUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnCompanyExternalAccountDisableForCompanyExternalAccountDisableUserCreatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnCompanyForCompanyUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnCompanyGlobalHrForCompanyGlobalHrUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnCompanyGroupForCompanyGroupUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnCompanyLocalHrForCompanyLocalHrUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnContractTypeForContractTypeUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnCountryForCountryUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnCurrencyForCurrencyUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnDepartmentForDepartmentUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnDivisionForDivisionUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnEmailDomainForEmailDomainUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnEmployeeEmergencyContactForEmployeeEmergencyContactUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnEmployeeFileForEmployeeFileUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnEmployeeForEmployeeUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnEmployeeGroupForEmployeeGroupUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnEmployeeIdTypeForEmployeeIdTypeUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnEmployeeLevelForEmployeeLevelUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnEmployeeListCustomFilterForEmployeeListCustomFilterUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnEmployeeResignationReasonForEmployeeResignationReasonUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnEmployeeResignationTypeForEmployeeResignationTypeUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnEmployeeStatusForEmployeeStatusUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnEmployeeTpHistoryForEmployeeTpHistoryUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnExportEmployeeStatusForExportEmployeeStatusUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnExternalAccountEmployeeForExternalAccountEmployeeUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnImportEmployeeStatusForImportEmployeeStatusUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnOfficeForOfficeUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnOfficeWeatherForOfficeWeatherUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewEvaluationCompetencyForPerformanceReviewEvaluationCompetencyUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewEvaluationEvaluatorForPerformanceReviewEvaluationEvaluatorUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewEvaluationFeedbackForPerformanceReviewEvaluationFeedbackUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewEvaluationForPerformanceReviewEvaluationUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewEvaluationKpiAssessmentForPerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewEvaluationKpiDescriptionForPerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewEvaluationKpiResultForPerformanceReviewEvaluationKpiResultUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewEvaluationKpiTargetForPerformanceReviewEvaluationKpiTargetUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewEvaluationOneOnOneForPerformanceReviewEvaluationOneOnOneUserCreatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewEvaluationQualitativeAssessmentForPerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewEvaluationQualitativeDescriptionForPerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewEvaluationValueForPerformanceReviewEvaluationValueUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewMasterCompetencyCommonDescriptionForPerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewMasterCompetencyCommonForPerformanceReviewMasterCompetencyCommonUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewMasterCompetencyDetailDescriptionForPerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewMasterCompetencyDetailForPerformanceReviewMasterCompetencyDetailUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewMasterCompetencyForPerformanceReviewMasterCompetencyUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewMasterCompetencyRoleForPerformanceReviewMasterCompetencyRoleUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewMasterDefaultForPerformanceReviewMasterDefaultUserCreatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewMasterEvaluationViewerForPerformanceReviewMasterEvaluationViewerUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewMasterExcludedEmployeeForPerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewMasterOneOnOneDetailForPerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewMasterOneOnOneForPerformanceReviewMasterOneOnOneUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewMasterRatingDescriptionForPerformanceReviewMasterRatingDescriptionUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewMasterRatingForPerformanceReviewMasterRatingUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewMasterUsageForPerformanceReviewMasterUsageUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewMasterValueDescriptionForPerformanceReviewMasterValueDescriptionUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewMasterValueForPerformanceReviewMasterValueUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewMasterWeightingDetailForPerformanceReviewMasterWeightingDetailUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPerformanceReviewMasterWeightingForPerformanceReviewMasterWeightingUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnPersonalIdentityTypeForPersonalIdentityTypeUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnProbationReviewEnableForProbationReviewEnableUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnProbationReviewEvaluationForProbationReviewEvaluationUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnProbationReviewEvaluationResultItemForProbationReviewEvaluationResultItemUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnProbationReviewEvaluationResultScoreCompetencyForProbationReviewEvaluationResultScoreCompUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnProbationReviewEvaluationResultScoreValueForProbationReviewEvaluationResultScoreValuUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnProbationReviewEvaluationReviewerForProbationReviewEvaluationReviewerUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnProbationReviewMasterItemDescriptionForProbationReviewMasterItemDescriptionUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnProbationReviewMasterItemForProbationReviewMasterItemUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnRelationBusinessUnitDivisionDepartmentForRelationBusinessUnitDivisionDepartmentUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnRelationBusinessUnitDivisionForRelationBusinessUnitDivisionUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnRelationCityCompanyForRelationCityCompanyUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnRelationCompanyBusinessUnitForRelationCompanyBusinessUnitUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnRelationCompanyGroupEmailDomainForRelationCompanyGroupEmailDomainUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnRelationCompanyOfficeForRelationCompanyOfficeUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnRelationCurrencyCountryForRelationCurrencyCountryUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnRelationEmergencyContactForRelationEmergencyContactUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnRelationEmployeeUserAccountForRelationEmployeeUserAccountUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnUserAccountAccessHistoryForUserAccountAccessHistoryUserAccountIdFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnUserAccountForUserAccountRoleFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnUserAccountForUserAccountUserCreatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnUserAccountForUserAccountUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnUserAccountPinForUserAccountPinUserAccountIdFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnUserHrManagingCompanyForUserHrManagingCompanyUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnWorldPlaceForWorldPlaceUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountOnWorldPlaceWeatherForWorldPlaceWeatherUserUpdatedFkeyPatch {
  appForceReloadsToUserCreatedUsingUserAccountId?: InputMaybe<AppForceReloadUserCreatedFkeyInverseInput>;
  appForceReloadsToUserUpdatedUsingUserAccountId?: InputMaybe<AppForceReloadUserUpdatedFkeyInverseInput>;
  banksToUserCreatedUsingUserAccountId?: InputMaybe<BankUserCreatedFkeyInverseInput>;
  banksToUserUpdatedUsingUserAccountId?: InputMaybe<BankUserUpdatedFkeyInverseInput>;
  businessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<BusinessUnitUserCreatedFkeyInverseInput>;
  businessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<BusinessUnitUserUpdatedFkeyInverseInput>;
  citiesToUserCreatedUsingUserAccountId?: InputMaybe<CityUserCreatedFkeyInverseInput>;
  citiesToUserUpdatedUsingUserAccountId?: InputMaybe<CityUserUpdatedFkeyInverseInput>;
  companiesToUserCreatedUsingUserAccountId?: InputMaybe<CompanyUserCreatedFkeyInverseInput>;
  companiesToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyUserUpdatedFkeyInverseInput>;
  companyExternalAccountDisables?: InputMaybe<CompanyExternalAccountDisableUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserCreatedFkeyInverseInput>;
  companyGlobalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGlobalHrUserUpdatedFkeyInverseInput>;
  companyGroupsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyGroupUserCreatedFkeyInverseInput>;
  companyGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyGroupUserUpdatedFkeyInverseInput>;
  companyLocalHrsToUserCreatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserCreatedFkeyInverseInput>;
  companyLocalHrsToUserUpdatedUsingUserAccountId?: InputMaybe<CompanyLocalHrUserUpdatedFkeyInverseInput>;
  contractTypesToUserCreatedUsingUserAccountId?: InputMaybe<ContractTypeUserCreatedFkeyInverseInput>;
  contractTypesToUserUpdatedUsingUserAccountId?: InputMaybe<ContractTypeUserUpdatedFkeyInverseInput>;
  countriesToUserCreatedUsingUserAccountId?: InputMaybe<CountryUserCreatedFkeyInverseInput>;
  countriesToUserUpdatedUsingUserAccountId?: InputMaybe<CountryUserUpdatedFkeyInverseInput>;
  currenciesToUserCreatedUsingUserAccountId?: InputMaybe<CurrencyUserCreatedFkeyInverseInput>;
  currenciesToUserUpdatedUsingUserAccountId?: InputMaybe<CurrencyUserUpdatedFkeyInverseInput>;
  departmentsToUserCreatedUsingUserAccountId?: InputMaybe<DepartmentUserCreatedFkeyInverseInput>;
  departmentsToUserUpdatedUsingUserAccountId?: InputMaybe<DepartmentUserUpdatedFkeyInverseInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  divisionsToUserCreatedUsingUserAccountId?: InputMaybe<DivisionUserCreatedFkeyInverseInput>;
  divisionsToUserUpdatedUsingUserAccountId?: InputMaybe<DivisionUserUpdatedFkeyInverseInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<EmailDomainUserCreatedFkeyInverseInput>;
  emailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<EmailDomainUserUpdatedFkeyInverseInput>;
  employeeEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserCreatedFkeyInverseInput>;
  employeeEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeEmergencyContactUserUpdatedFkeyInverseInput>;
  employeeFilesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeFileUserCreatedFkeyInverseInput>;
  employeeFilesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeFileUserUpdatedFkeyInverseInput>;
  employeeGroupsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserCreatedFkeyInverseInput>;
  employeeGroupsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeGroupUserUpdatedFkeyInverseInput>;
  employeeIdTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserCreatedFkeyInverseInput>;
  employeeIdTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeIdTypeUserUpdatedFkeyInverseInput>;
  employeeLevelsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserCreatedFkeyInverseInput>;
  employeeLevelsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeLevelUserUpdatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserCreatedFkeyInverseInput>;
  employeeListCustomFilterRolesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterRoleUserUpdatedFkeyInverseInput>;
  employeeListCustomFiltersToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserCreatedFkeyInverseInput>;
  employeeListCustomFiltersToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeListCustomFilterUserUpdatedFkeyInverseInput>;
  employeeResignationReasonsToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserCreatedFkeyInverseInput>;
  employeeResignationReasonsToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationReasonUserUpdatedFkeyInverseInput>;
  employeeResignationTypesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserCreatedFkeyInverseInput>;
  employeeResignationTypesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeResignationTypeUserUpdatedFkeyInverseInput>;
  employeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserCreatedFkeyInverseInput>;
  employeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeStatusUserUpdatedFkeyInverseInput>;
  employeeTpHistoriesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserCreatedFkeyInverseInput>;
  employeeTpHistoriesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeTpHistoryUserUpdatedFkeyInverseInput>;
  employeesToUserCreatedUsingUserAccountId?: InputMaybe<EmployeeUserCreatedFkeyInverseInput>;
  employeesToUserUpdatedUsingUserAccountId?: InputMaybe<EmployeeUserUpdatedFkeyInverseInput>;
  exportEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserCreatedFkeyInverseInput>;
  exportEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ExportEmployeeStatusUserUpdatedFkeyInverseInput>;
  externalAccountEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserCreatedFkeyInverseInput>;
  externalAccountEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<ExternalAccountEmployeeUserUpdatedFkeyInverseInput>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  importEmployeeStatusesToUserCreatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserCreatedFkeyInverseInput>;
  importEmployeeStatusesToUserUpdatedUsingUserAccountId?: InputMaybe<ImportEmployeeStatusUserUpdatedFkeyInverseInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  officeWeathersToUserCreatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserCreatedFkeyInverseInput>;
  officeWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeWeatherUserUpdatedFkeyInverseInput>;
  officesToUserCreatedUsingUserAccountId?: InputMaybe<OfficeUserCreatedFkeyInverseInput>;
  officesToUserUpdatedUsingUserAccountId?: InputMaybe<OfficeUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationEvaluatorsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationEvaluatorUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationFeedbacksToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationFeedbackUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiResultsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiResultUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationKpiTargetsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationKpiTargetUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationOneOnOnes?: InputMaybe<PerformanceReviewEvaluationOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeAssessmentsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeAssUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationQualitativeDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationQualitativeDesUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationValueUserUpdatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserCreatedFkeyInverseInput>;
  performanceReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewEvaluationUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyCommonsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyCommonUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailDUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserCreatedFkeyInverseInput>;
  performanceReviewMasterCompetencyRolesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterCompetencyRoleUserUpdatedFkeyInverseInput>;
  performanceReviewMasterDefaults?: InputMaybe<PerformanceReviewMasterDefaultUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserCreatedFkeyInverseInput>;
  performanceReviewMasterEvaluationViewersToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterEvaluationViewerUserUpdatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserCreatedFkeyInverseInput>;
  performanceReviewMasterExcludedEmployeesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterExcludedEmployeeUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOneDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserCreatedFkeyInverseInput>;
  performanceReviewMasterOneOnOnesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterOneOnOneUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserCreatedFkeyInverseInput>;
  performanceReviewMasterRatingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterRatingUserUpdatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserCreatedFkeyInverseInput>;
  performanceReviewMasterUsagesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterUsageUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserCreatedFkeyInverseInput>;
  performanceReviewMasterValueDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueDescriptionUserUpdatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserCreatedFkeyInverseInput>;
  performanceReviewMasterValuesToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterValueUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingDetailsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingDetailUserUpdatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserCreatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserCreatedFkeyInverseInput>;
  performanceReviewMasterWeightingsToUserUpdatedUsingUserAccountId?: InputMaybe<PerformanceReviewMasterWeightingUserUpdatedFkeyInverseInput>;
  personalIdentityTypesToUserCreatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserCreatedFkeyInverseInput>;
  personalIdentityTypesToUserUpdatedUsingUserAccountId?: InputMaybe<PersonalIdentityTypeUserUpdatedFkeyInverseInput>;
  probationReviewEnablesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserCreatedFkeyInverseInput>;
  probationReviewEnablesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEnableUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultItemUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreCompetenciesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreCompUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserCreatedFkeyInverseInput>;
  probationReviewEvaluationResultScoreValuesToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationResultScoreValuUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserCreatedFkeyInverseInput>;
  probationReviewEvaluationReviewersToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationReviewerUserUpdatedFkeyInverseInput>;
  probationReviewEvaluationsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserCreatedFkeyInverseInput>;
  probationReviewEvaluationsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewEvaluationUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserCreatedFkeyInverseInput>;
  probationReviewMasterItemDescriptionsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemDescriptionUserUpdatedFkeyInverseInput>;
  probationReviewMasterItemsToUserCreatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserCreatedFkeyInverseInput>;
  probationReviewMasterItemsToUserUpdatedUsingUserAccountId?: InputMaybe<ProbationReviewMasterItemUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionDepartmentsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionDepartmentUserUpdatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserCreatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserCreatedFkeyInverseInput>;
  relationBusinessUnitDivisionsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationBusinessUnitDivisionUserUpdatedFkeyInverseInput>;
  relationCityCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserCreatedFkeyInverseInput>;
  relationCityCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCityCompanyUserUpdatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserCreatedFkeyInverseInput>;
  relationCompanyBusinessUnitsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyBusinessUnitUserUpdatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserCreatedFkeyInverseInput>;
  relationCompanyGroupEmailDomainsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyGroupEmailDomainUserUpdatedFkeyInverseInput>;
  relationCompanyOfficesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserCreatedFkeyInverseInput>;
  relationCompanyOfficesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCompanyOfficeUserUpdatedFkeyInverseInput>;
  relationCurrencyCountriesToUserCreatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserCreatedFkeyInverseInput>;
  relationCurrencyCountriesToUserUpdatedUsingUserAccountId?: InputMaybe<RelationCurrencyCountryUserUpdatedFkeyInverseInput>;
  relationEmergencyContactsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserCreatedFkeyInverseInput>;
  relationEmergencyContactsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmergencyContactUserUpdatedFkeyInverseInput>;
  relationEmployeeUserAccountToUserAccountIdUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserAccountIdFkeyInverseInput>;
  relationEmployeeUserAccountsToUserCreatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserCreatedFkeyInverseInput>;
  relationEmployeeUserAccountsToUserUpdatedUsingUserAccountId?: InputMaybe<RelationEmployeeUserAccountUserUpdatedFkeyInverseInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userAccountAccessHistories?: InputMaybe<UserAccountAccessHistoryUserAccountIdFkeyInverseInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userAccountPins?: InputMaybe<UserAccountPinUserAccountIdFkeyInverseInput>;
  userAccountToUserCreated?: InputMaybe<UserAccountUserCreatedFkeyInput>;
  userAccountToUserUpdated?: InputMaybe<UserAccountUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutsToUserAccountIdUsingUserAccountId?: InputMaybe<UserForceLogoutUserAccountIdFkeyInverseInput>;
  userForceLogoutsToUserCreatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserCreatedFkeyInverseInput>;
  userForceLogoutsToUserUpdatedUsingUserAccountId?: InputMaybe<UserForceLogoutUserUpdatedFkeyInverseInput>;
  userHrManagingCompaniesToUserAccountIdUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserAccountIdFkeyInverseInput>;
  userHrManagingCompaniesToUserCreatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserCreatedFkeyInverseInput>;
  userHrManagingCompaniesToUserUpdatedUsingUserAccountId?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInverseInput>;
  userRole?: InputMaybe<UserAccountRoleFkeyInput>;
  worldPlaceWeathersToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserCreatedFkeyInverseInput>;
  worldPlaceWeathersToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInverseInput>;
  worldPlacesToUserCreatedUsingUserAccountId?: InputMaybe<WorldPlaceUserCreatedFkeyInverseInput>;
  worldPlacesToUserUpdatedUsingUserAccountId?: InputMaybe<WorldPlaceUserUpdatedFkeyInverseInput>;
}

export interface UpdateUserAccountPinOnUserAccountPinForUserAccountPinUserAccountIdFkeyPatch {
  pin?: InputMaybe<Scalars['String']['input']>;
  ref?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<UserAccountPinUserAccountIdFkeyInput>;
}

export interface UpdateUserForceLogoutOnUserForceLogoutForUserForceLogoutUserAccountIdFkeyPatch {
  reason?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<UserForceLogoutUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userForceLogoutId?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateUserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyCompanyIdFkeyPatch {
  company?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInput>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanyId?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateUserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyUserAccountIdFkeyPatch {
  company?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanyId?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateUserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyUserCreatedFkeyPatch {
  company?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanyId?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateUserHrManagingCompanyOnUserHrManagingCompanyForUserHrManagingCompanyUserUpdatedFkeyPatch {
  company?: InputMaybe<UserHrManagingCompanyCompanyIdFkeyInput>;
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<UserHrManagingCompanyUserUpdatedFkeyInput>;
  userAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userHrManagingCompanyId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateUserRoleOnEmployeeFileForEmployeeFileVisibleRoleFkeyPatch {
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeFiles?: InputMaybe<EmployeeFileVisibleRoleFkeyInverseInput>;
  employeeListCustomFilterRoles?: InputMaybe<EmployeeListCustomFilterRoleRoleNameFkeyInverseInput>;
  isDefaultRole?: InputMaybe<Scalars['Boolean']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  roleName?: InputMaybe<Scalars['String']['input']>;
  userAccounts?: InputMaybe<UserAccountRoleFkeyInverseInput>;
}

export interface UpdateUserRoleOnEmployeeListCustomFilterRoleForEmployeeListCustomFilterRoleRoleNameFkeyPatch {
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeFiles?: InputMaybe<EmployeeFileVisibleRoleFkeyInverseInput>;
  employeeListCustomFilterRoles?: InputMaybe<EmployeeListCustomFilterRoleRoleNameFkeyInverseInput>;
  isDefaultRole?: InputMaybe<Scalars['Boolean']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  userAccounts?: InputMaybe<UserAccountRoleFkeyInverseInput>;
}

export interface UpdateUserRoleOnUserAccountForUserAccountRoleFkeyPatch {
  displayName?: InputMaybe<Scalars['String']['input']>;
  employeeFiles?: InputMaybe<EmployeeFileVisibleRoleFkeyInverseInput>;
  employeeListCustomFilterRoles?: InputMaybe<EmployeeListCustomFilterRoleRoleNameFkeyInverseInput>;
  isDefaultRole?: InputMaybe<Scalars['Boolean']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  roleName?: InputMaybe<Scalars['String']['input']>;
  userAccounts?: InputMaybe<UserAccountRoleFkeyInverseInput>;
}

export interface UpdateWorldPlaceOnWorldPlaceForWorldPlaceUserCreatedFkeyPatch {
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<WorldPlaceUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlaceId?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlaceWeathers?: InputMaybe<WorldPlaceWeatherWorldPlaceIdFkeyInverseInput>;
}

export interface UpdateWorldPlaceOnWorldPlaceForWorldPlaceUserUpdatedFkeyPatch {
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<WorldPlaceUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlaceId?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlaceWeathers?: InputMaybe<WorldPlaceWeatherWorldPlaceIdFkeyInverseInput>;
}

export interface UpdateWorldPlaceOnWorldPlaceWeatherForWorldPlaceWeatherWorldPlaceIdFkeyPatch {
  countryCodeAlpha2?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<WorldPlaceUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlaceWeathers?: InputMaybe<WorldPlaceWeatherWorldPlaceIdFkeyInverseInput>;
}

export interface UpdateWorldPlaceWeatherOnWorldPlaceWeatherForWorldPlaceWeatherUserCreatedFkeyPatch {
  condition?: InputMaybe<Scalars['String']['input']>;
  day?: InputMaybe<Scalars['Date']['input']>;
  humidity?: InputMaybe<Scalars['Float']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  tempMax?: InputMaybe<Scalars['Float']['input']>;
  tempMin?: InputMaybe<Scalars['Float']['input']>;
  timeSunrise?: InputMaybe<Scalars['Time']['input']>;
  timeSunset?: InputMaybe<Scalars['Time']['input']>;
  userAccount?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInput>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlace?: InputMaybe<WorldPlaceWeatherWorldPlaceIdFkeyInput>;
  worldPlaceId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateWorldPlaceWeatherOnWorldPlaceWeatherForWorldPlaceWeatherUserUpdatedFkeyPatch {
  condition?: InputMaybe<Scalars['String']['input']>;
  day?: InputMaybe<Scalars['Date']['input']>;
  humidity?: InputMaybe<Scalars['Float']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  tempMax?: InputMaybe<Scalars['Float']['input']>;
  tempMin?: InputMaybe<Scalars['Float']['input']>;
  timeSunrise?: InputMaybe<Scalars['Time']['input']>;
  timeSunset?: InputMaybe<Scalars['Time']['input']>;
  userAccount?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlace?: InputMaybe<WorldPlaceWeatherWorldPlaceIdFkeyInput>;
  worldPlaceId?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface UpdateWorldPlaceWeatherOnWorldPlaceWeatherForWorldPlaceWeatherWorldPlaceIdFkeyPatch {
  condition?: InputMaybe<Scalars['String']['input']>;
  day?: InputMaybe<Scalars['Date']['input']>;
  humidity?: InputMaybe<Scalars['Float']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  tempMax?: InputMaybe<Scalars['Float']['input']>;
  tempMin?: InputMaybe<Scalars['Float']['input']>;
  timeSunrise?: InputMaybe<Scalars['Time']['input']>;
  timeSunset?: InputMaybe<Scalars['Time']['input']>;
  userAccount?: InputMaybe<WorldPlaceWeatherUserUpdatedFkeyInput>;
  userCreated?: InputMaybe<Scalars['BigInt']['input']>;
  userUpdated?: InputMaybe<Scalars['BigInt']['input']>;
  worldPlace?: InputMaybe<WorldPlaceWeatherWorldPlaceIdFkeyInput>;
}

export interface UpsertBankByBankIdInput {
  bank: BankInput;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<BankPatch>;
}

export interface UpsertBankByCountryCodeAlpha3AndFullNameInput {
  bank: BankInput;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<BankPatch>;
}

export interface UpsertBankBySwiftCodeInput {
  bank: BankInput;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<BankPatch>;
}

export interface UpsertBusinessUnitByBusinessUnitIdInput {
  businessUnit: BusinessUnitInput;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<BusinessUnitPatch>;
}

export interface UpsertBusinessUnitByNameInput {
  businessUnit: BusinessUnitInput;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<BusinessUnitPatch>;
}

export interface UpsertCityByCityIdInput {
  city: CityInput;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<CityPatch>;
}

export interface UpsertCityByCountryCodeAlpha2AndNameInput {
  city: CityInput;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<CityPatch>;
}

export interface UpsertCompanyByAbbreviationInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  company: CompanyInput;
  patch?: InputMaybe<CompanyPatch>;
}

export interface UpsertCompanyByCompanyIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  company: CompanyInput;
  patch?: InputMaybe<CompanyPatch>;
}

export interface UpsertCompanyByCountryCodeAlpha2AndFullNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  company: CompanyInput;
  patch?: InputMaybe<CompanyPatch>;
}

export interface UpsertCompanyGlobalHrByCompanyGlobalHrIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr: CompanyGlobalHrInput;
  patch?: InputMaybe<CompanyGlobalHrPatch>;
}

export interface UpsertCompanyGlobalHrByEmployeeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyGlobalHr: CompanyGlobalHrInput;
  patch?: InputMaybe<CompanyGlobalHrPatch>;
}

export interface UpsertCompanyGroupByCompanyGroupIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyGroup: CompanyGroupInput;
  patch?: InputMaybe<CompanyGroupPatch>;
}

export interface UpsertCompanyGroupByNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyGroup: CompanyGroupInput;
  patch?: InputMaybe<CompanyGroupPatch>;
}

export interface UpsertCompanyLocalHrByCompanyIdAndEmployeeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyLocalHr: CompanyLocalHrInput;
  patch?: InputMaybe<CompanyLocalHrPatch>;
}

export interface UpsertCompanyLocalHrByCompanyLocalHrIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyLocalHr: CompanyLocalHrInput;
  patch?: InputMaybe<CompanyLocalHrPatch>;
}

export interface UpsertContractTypeByCodeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contractType: ContractTypeInput;
  patch?: InputMaybe<ContractTypePatch>;
}

export interface UpsertContractTypeByContractTypeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contractType: ContractTypeInput;
  patch?: InputMaybe<ContractTypePatch>;
}

export interface UpsertContractTypeByDisplayNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contractType: ContractTypeInput;
  patch?: InputMaybe<ContractTypePatch>;
}

export interface UpsertCountryByCountryCodeAlpha2Input {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  country: CountryInput;
  patch?: InputMaybe<CountryPatch>;
}

export interface UpsertCountryByCountryCodeAlpha3Input {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  country: CountryInput;
  patch?: InputMaybe<CountryPatch>;
}

export interface UpsertCountryByCountryCodeNumericInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  country: CountryInput;
  patch?: InputMaybe<CountryPatch>;
}

export interface UpsertCountryByDisplayOrderInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  country: CountryInput;
  patch?: InputMaybe<CountryPatch>;
}

export interface UpsertCurrencyByCurrencyCodeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currency: CurrencyInput;
  patch?: InputMaybe<CurrencyPatch>;
}

export interface UpsertDepartmentByDepartmentIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  department: DepartmentInput;
  patch?: InputMaybe<DepartmentPatch>;
}

export interface UpsertDepartmentByNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  department: DepartmentInput;
  patch?: InputMaybe<DepartmentPatch>;
}

export interface UpsertDivisionByDivisionIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  division: DivisionInput;
  patch?: InputMaybe<DivisionPatch>;
}

export interface UpsertDivisionByNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  division: DivisionInput;
  patch?: InputMaybe<DivisionPatch>;
}

export interface UpsertEmailDomainByDomainNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  emailDomain: EmailDomainInput;
  patch?: InputMaybe<EmailDomainPatch>;
}

export interface UpsertEmailDomainByEmailDomainIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  emailDomain: EmailDomainInput;
  patch?: InputMaybe<EmailDomainPatch>;
}

export interface UpsertEmployeeByCompanyEmailInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employee: EmployeeInput;
  patch?: InputMaybe<EmployeePatch>;
}

export interface UpsertEmployeeByEmployeeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employee: EmployeeInput;
  patch?: InputMaybe<EmployeePatch>;
}

export interface UpsertEmployeeEmergencyContactByEmployeeEmergencyContactIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeEmergencyContact: EmployeeEmergencyContactInput;
  patch?: InputMaybe<EmployeeEmergencyContactPatch>;
}

export interface UpsertEmployeeEmergencyContactByEmployeeIdAndRelationIdAndNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeEmergencyContact: EmployeeEmergencyContactInput;
  patch?: InputMaybe<EmployeeEmergencyContactPatch>;
}

export interface UpsertEmployeeIdTypeByCodeInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeIdType: EmployeeIdTypeInput;
  patch?: InputMaybe<EmployeeIdTypePatch>;
}

export interface UpsertEmployeeIdTypeByEmployeeIdTypeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeIdType: EmployeeIdTypeInput;
  patch?: InputMaybe<EmployeeIdTypePatch>;
}

export interface UpsertEmployeeLevelByDisplayNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeLevel: EmployeeLevelInput;
  patch?: InputMaybe<EmployeeLevelPatch>;
}

export interface UpsertEmployeeLevelByEmployeeLevelIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeLevel: EmployeeLevelInput;
  patch?: InputMaybe<EmployeeLevelPatch>;
}

export interface UpsertEmployeeResignationReasonByDisplayNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeResignationReason: EmployeeResignationReasonInput;
  patch?: InputMaybe<EmployeeResignationReasonPatch>;
}

export interface UpsertEmployeeResignationReasonByEmployeeResignationReasonIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeResignationReason: EmployeeResignationReasonInput;
  patch?: InputMaybe<EmployeeResignationReasonPatch>;
}

export interface UpsertEmployeeResignationTypeByDisplayNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeResignationType: EmployeeResignationTypeInput;
  patch?: InputMaybe<EmployeeResignationTypePatch>;
}

export interface UpsertEmployeeResignationTypeByEmployeeResignationTypeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeResignationType: EmployeeResignationTypeInput;
  patch?: InputMaybe<EmployeeResignationTypePatch>;
}

export interface UpsertEmployeeTpHistoryByEmployeeIdAndEffectiveDateInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeTpHistory: EmployeeTpHistoryInput;
  patch?: InputMaybe<EmployeeTpHistoryPatch>;
}

export interface UpsertEmployeeTpHistoryByEmployeeTpHistoryIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeeTpHistory: EmployeeTpHistoryInput;
  patch?: InputMaybe<EmployeeTpHistoryPatch>;
}

export interface UpsertExternalAccountEmployeeByEmployeeIdAndExternalAccountTypeKeyAndExternalAccountKeyInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  externalAccountEmployee: ExternalAccountEmployeeInput;
  patch?: InputMaybe<ExternalAccountEmployeePatch>;
}

export interface UpsertExternalAccountEmployeeByExternalAccountEmployeeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  externalAccountEmployee: ExternalAccountEmployeeInput;
  patch?: InputMaybe<ExternalAccountEmployeePatch>;
}

export interface UpsertGoogleWorkspaceOrgUnitByCompanyIdAndBusinessUnitIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  googleWorkspaceOrgUnit: GoogleWorkspaceOrgUnitInput;
  patch?: InputMaybe<GoogleWorkspaceOrgUnitPatch>;
}

export interface UpsertGoogleWorkspaceOrgUnitByGoogleWorkspaceOrgUnitIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  googleWorkspaceOrgUnit: GoogleWorkspaceOrgUnitInput;
  patch?: InputMaybe<GoogleWorkspaceOrgUnitPatch>;
}

export interface UpsertOfficeByCityIdAndNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  office: OfficeInput;
  patch?: InputMaybe<OfficePatch>;
}

export interface UpsertOfficeByOfficeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  office: OfficeInput;
  patch?: InputMaybe<OfficePatch>;
}

export interface UpsertPerformanceReviewEvaluationByPerformanceReviewEvaluationIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewEvaluationPatch>;
  performanceReviewEvaluation: PerformanceReviewEvaluationInput;
}

export interface UpsertPerformanceReviewEvaluationCompetencyByPerformanceReviewEvaluationCompetencyIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewEvaluationCompetencyPatch>;
  performanceReviewEvaluationCompetency: PerformanceReviewEvaluationCompetencyInput;
}

export interface UpsertPerformanceReviewEvaluationCompetencyByPerformanceReviewEvaluationIdAndPerformanceReviewMasterCompetencyIdAndAssessmentEmployeeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewEvaluationCompetencyPatch>;
  performanceReviewEvaluationCompetency: PerformanceReviewEvaluationCompetencyInput;
}

export interface UpsertPerformanceReviewEvaluationFeedbackByPerformanceReviewEvaluationFeedbackIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewEvaluationFeedbackPatch>;
  performanceReviewEvaluationFeedback: PerformanceReviewEvaluationFeedbackInput;
}

export interface UpsertPerformanceReviewEvaluationFeedbackByPerformanceReviewEvaluationIdAndFeedbackEmployeeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewEvaluationFeedbackPatch>;
  performanceReviewEvaluationFeedback: PerformanceReviewEvaluationFeedbackInput;
}

export interface UpsertPerformanceReviewEvaluationKpiAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndAssessmentEmployeeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentPatch>;
  performanceReviewEvaluationKpiAssessment: PerformanceReviewEvaluationKpiAssessmentInput;
}

export interface UpsertPerformanceReviewEvaluationKpiAssessmentByPerformanceReviewEvaluationKpiAssessmentIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewEvaluationKpiAssessmentPatch>;
  performanceReviewEvaluationKpiAssessment: PerformanceReviewEvaluationKpiAssessmentInput;
}

export interface UpsertPerformanceReviewEvaluationKpiDescriptionByPerformanceReviewEvaluationIdAndTitleInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionPatch>;
  performanceReviewEvaluationKpiDescription: PerformanceReviewEvaluationKpiDescriptionInput;
}

export interface UpsertPerformanceReviewEvaluationKpiDescriptionByPerformanceReviewEvaluationKpiDescriptionIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewEvaluationKpiDescriptionPatch>;
  performanceReviewEvaluationKpiDescription: PerformanceReviewEvaluationKpiDescriptionInput;
}

export interface UpsertPerformanceReviewEvaluationKpiResultByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiTargetIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewEvaluationKpiResultPatch>;
  performanceReviewEvaluationKpiResult: PerformanceReviewEvaluationKpiResultInput;
}

export interface UpsertPerformanceReviewEvaluationKpiResultByPerformanceReviewEvaluationKpiResultIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewEvaluationKpiResultPatch>;
  performanceReviewEvaluationKpiResult: PerformanceReviewEvaluationKpiResultInput;
}

export interface UpsertPerformanceReviewEvaluationKpiTargetByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationKpiDescriptionIdAndNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewEvaluationKpiTargetPatch>;
  performanceReviewEvaluationKpiTarget: PerformanceReviewEvaluationKpiTargetInput;
}

export interface UpsertPerformanceReviewEvaluationKpiTargetByPerformanceReviewEvaluationKpiTargetIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewEvaluationKpiTargetPatch>;
  performanceReviewEvaluationKpiTarget: PerformanceReviewEvaluationKpiTargetInput;
}

export interface UpsertPerformanceReviewEvaluationQualitativeAssessmentByPerformanceReviewEvaluationIdAndPerformanceReviewEvaluationQualitativeDescriptionIdAndAssessmentEmployeeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentPatch>;
  performanceReviewEvaluationQualitativeAssessment: PerformanceReviewEvaluationQualitativeAssessmentInput;
}

export interface UpsertPerformanceReviewEvaluationQualitativeAssessmentByPerformanceReviewEvaluationQualitativeAssessmentIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewEvaluationQualitativeAssessmentPatch>;
  performanceReviewEvaluationQualitativeAssessment: PerformanceReviewEvaluationQualitativeAssessmentInput;
}

export interface UpsertPerformanceReviewEvaluationQualitativeDescriptionByPerformanceReviewEvaluationIdAndTitleInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionPatch>;
  performanceReviewEvaluationQualitativeDescription: PerformanceReviewEvaluationQualitativeDescriptionInput;
}

export interface UpsertPerformanceReviewEvaluationQualitativeDescriptionByPerformanceReviewEvaluationQualitativeDescriptionIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewEvaluationQualitativeDescriptionPatch>;
  performanceReviewEvaluationQualitativeDescription: PerformanceReviewEvaluationQualitativeDescriptionInput;
}

export interface UpsertPerformanceReviewEvaluationValueByPerformanceReviewEvaluationIdAndPerformanceReviewMasterValueIdAndAssessmentEmployeeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewEvaluationValuePatch>;
  performanceReviewEvaluationValue: PerformanceReviewEvaluationValueInput;
}

export interface UpsertPerformanceReviewEvaluationValueByPerformanceReviewEvaluationValueIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewEvaluationValuePatch>;
  performanceReviewEvaluationValue: PerformanceReviewEvaluationValueInput;
}

export interface UpsertPerformanceReviewMasterCompetencyCommonDescriptionByPerformanceReviewMasterCompetencyCommonDescriptionIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewMasterCompetencyCommonDescriptionPatch>;
  performanceReviewMasterCompetencyCommonDescription: PerformanceReviewMasterCompetencyCommonDescriptionInput;
}

export interface UpsertPerformanceReviewMasterCompetencyCommonDescriptionByPerformanceReviewMasterCompetencyCommonIdAndLanguageInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewMasterCompetencyCommonDescriptionPatch>;
  performanceReviewMasterCompetencyCommonDescription: PerformanceReviewMasterCompetencyCommonDescriptionInput;
}

export interface UpsertPerformanceReviewMasterCompetencyDetailDescriptionByPerformanceReviewMasterCompetencyDetailDescriptionIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewMasterCompetencyDetailDescriptionPatch>;
  performanceReviewMasterCompetencyDetailDescription: PerformanceReviewMasterCompetencyDetailDescriptionInput;
}

export interface UpsertPerformanceReviewMasterCompetencyDetailDescriptionByPerformanceReviewMasterCompetencyDetailIdAndLanguageInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewMasterCompetencyDetailDescriptionPatch>;
  performanceReviewMasterCompetencyDetailDescription: PerformanceReviewMasterCompetencyDetailDescriptionInput;
}

export interface UpsertPerformanceReviewMasterCompetencyRoleByPerformanceReviewMasterCompetencyRoleIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewMasterCompetencyRolePatch>;
  performanceReviewMasterCompetencyRole: PerformanceReviewMasterCompetencyRoleInput;
}

export interface UpsertPerformanceReviewMasterCompetencyRoleByYearAndQuarterAndBusinessUnitIdAndDivisionIdAndDepartmentIdAndRoleNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewMasterCompetencyRolePatch>;
  performanceReviewMasterCompetencyRole: PerformanceReviewMasterCompetencyRoleInput;
}

export interface UpsertPerformanceReviewMasterExcludedEmployeeByYearAndQuarterAndEmployeeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewMasterExcludedEmployeePatch>;
  performanceReviewMasterExcludedEmployee: PerformanceReviewMasterExcludedEmployeeInput;
}

export interface UpsertPerformanceReviewMasterRatingDescriptionByPerformanceReviewMasterRatingDescriptionIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewMasterRatingDescriptionPatch>;
  performanceReviewMasterRatingDescription: PerformanceReviewMasterRatingDescriptionInput;
}

export interface UpsertPerformanceReviewMasterRatingDescriptionByPerformanceReviewMasterRatingIdAndLanguageInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewMasterRatingDescriptionPatch>;
  performanceReviewMasterRatingDescription: PerformanceReviewMasterRatingDescriptionInput;
}

export interface UpsertPerformanceReviewMasterUsageByPerformanceReviewMasterUsageIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewMasterUsagePatch>;
  performanceReviewMasterUsage: PerformanceReviewMasterUsageInput;
}

export interface UpsertPerformanceReviewMasterUsageByYearAndQuarterAndCompanyIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewMasterUsagePatch>;
  performanceReviewMasterUsage: PerformanceReviewMasterUsageInput;
}

export interface UpsertPerformanceReviewMasterValueDescriptionByPerformanceReviewMasterValueDescriptionIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewMasterValueDescriptionPatch>;
  performanceReviewMasterValueDescription: PerformanceReviewMasterValueDescriptionInput;
}

export interface UpsertPerformanceReviewMasterValueDescriptionByPerformanceReviewMasterValueIdAndLanguageInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewMasterValueDescriptionPatch>;
  performanceReviewMasterValueDescription: PerformanceReviewMasterValueDescriptionInput;
}

export interface UpsertPerformanceReviewMasterWeightingByPerformanceReviewMasterWeightingIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewMasterWeightingPatch>;
  performanceReviewMasterWeighting: PerformanceReviewMasterWeightingInput;
}

export interface UpsertPerformanceReviewMasterWeightingByYearAndQuarterAndCompanyIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewMasterWeightingPatch>;
  performanceReviewMasterWeighting: PerformanceReviewMasterWeightingInput;
}

export interface UpsertPerformanceReviewMasterWeightingByYearAndQuarterAndNameInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewMasterWeightingPatch>;
  performanceReviewMasterWeighting: PerformanceReviewMasterWeightingInput;
}

export interface UpsertPerformanceReviewMasterWeightingDetailByPerformanceReviewMasterWeightingDetailIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewMasterWeightingDetailPatch>;
  performanceReviewMasterWeightingDetail: PerformanceReviewMasterWeightingDetailInput;
}

export interface UpsertPerformanceReviewMasterWeightingDetailByPerformanceReviewMasterWeightingIdAndEmployeeLevelIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<PerformanceReviewMasterWeightingDetailPatch>;
  performanceReviewMasterWeightingDetail: PerformanceReviewMasterWeightingDetailInput;
}

export interface UpsertProbationReviewEvaluationByEmployeeIdAndReviewDatePlannedInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<ProbationReviewEvaluationPatch>;
  probationReviewEvaluation: ProbationReviewEvaluationInput;
}

export interface UpsertProbationReviewEvaluationByProbationReviewEvaluationIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<ProbationReviewEvaluationPatch>;
  probationReviewEvaluation: ProbationReviewEvaluationInput;
}

export interface UpsertProbationReviewEvaluationResultItemByProbationReviewEvaluationIdAndProbationReviewMasterItemIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<ProbationReviewEvaluationResultItemPatch>;
  probationReviewEvaluationResultItem: ProbationReviewEvaluationResultItemInput;
}

export interface UpsertProbationReviewEvaluationResultItemByProbationReviewEvaluationResultItemIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<ProbationReviewEvaluationResultItemPatch>;
  probationReviewEvaluationResultItem: ProbationReviewEvaluationResultItemInput;
}

export interface UpsertProbationReviewEvaluationResultScoreCompetencyByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterCompetencyIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyPatch>;
  probationReviewEvaluationResultScoreCompetency: ProbationReviewEvaluationResultScoreCompetencyInput;
}

export interface UpsertProbationReviewEvaluationResultScoreCompetencyByProbationReviewEvaluationResultScoreCompetencyIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<ProbationReviewEvaluationResultScoreCompetencyPatch>;
  probationReviewEvaluationResultScoreCompetency: ProbationReviewEvaluationResultScoreCompetencyInput;
}

export interface UpsertProbationReviewEvaluationResultScoreValueByProbationReviewEvaluationIdAndProbationReviewMasterItemIdAndPerformanceReviewMasterValueIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<ProbationReviewEvaluationResultScoreValuePatch>;
  probationReviewEvaluationResultScoreValue: ProbationReviewEvaluationResultScoreValueInput;
}

export interface UpsertProbationReviewEvaluationResultScoreValueByProbationReviewEvaluationResultScoreValueIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<ProbationReviewEvaluationResultScoreValuePatch>;
  probationReviewEvaluationResultScoreValue: ProbationReviewEvaluationResultScoreValueInput;
}

export interface UpsertProbationReviewEvaluationReviewerByProbationReviewEvaluationIdAndReviewerEmployeeIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<ProbationReviewEvaluationReviewerPatch>;
  probationReviewEvaluationReviewer: ProbationReviewEvaluationReviewerInput;
}

export interface UpsertProbationReviewMasterItemByProbationReviewMasterItemIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<ProbationReviewMasterItemPatch>;
  probationReviewMasterItem: ProbationReviewMasterItemInput;
}

export interface UpsertProbationReviewMasterItemByYearAndQuarterAndTitleAndInputUserInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<ProbationReviewMasterItemPatch>;
  probationReviewMasterItem: ProbationReviewMasterItemInput;
}

export interface UpsertProbationReviewMasterItemDescriptionByProbationReviewMasterItemDescriptionIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<ProbationReviewMasterItemDescriptionPatch>;
  probationReviewMasterItemDescription: ProbationReviewMasterItemDescriptionInput;
}

export interface UpsertProbationReviewMasterItemDescriptionByProbationReviewMasterItemIdAndLanguageInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<ProbationReviewMasterItemDescriptionPatch>;
  probationReviewMasterItemDescription: ProbationReviewMasterItemDescriptionInput;
}

export interface UpsertRelationEmployeeUserAccountByUserAccountIdInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<RelationEmployeeUserAccountPatch>;
  relationEmployeeUserAccount: RelationEmployeeUserAccountInput;
}
